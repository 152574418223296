import { FC, ReactNode } from 'react'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'
import { StyledTab } from '.'

type Props = {
  label: string
  icon?: ReactNode
  path?: string
  amount?: number
  tourId?: string
  isDisabled?: boolean
  isActive?: boolean
  isExact?: boolean
  onClick?: () => void
}

const Tab: FC<Props> = ({
  label,
  icon,
  path,
  amount,
  tourId,
  isDisabled,
  isActive,
  isExact = true,
  onClick
}) => {
  if (!!onClick) {
    return (
      <StyledTab data-tour-id={tourId} isDisabled={isDisabled} className="tab">
        <button className={classNames({ active: isActive })} onClick={() => onClick()}>
          {icon}
          <span>{label}</span>
          {amount !== undefined && <span className="tab-flag">{Math.max(amount, 0)}</span>}
        </button>
      </StyledTab>
    )
  }

  return (
    <StyledTab data-tour-id={tourId} isDisabled={isDisabled} className="tab">
      <NavLink to={path || ''} exact={isExact}>
        {icon}
        <span>{label}</span>
        {amount !== undefined && <span className="tab-flag">{Math.max(amount, 0)}</span>}
      </NavLink>
    </StyledTab>
  )
}

export default Tab
