import styled from 'styled-components'

const StyledDesignerFontSizeTool = styled.div`
  border-radius: 4px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.color.gray[900]};
  cursor: pointer;
  max-width: 100%;
  display: flex;
  align-items: center;
  vertical-align: middle;
  overflow: hidden;
  height: 32px;
  margin-right: 6px;
  border: 1px solid ${({ theme }) => theme.color.gray[300]};

  input {
    font-size: 15px;
    width: 50px;
    height: 32px;
    transition: 0.2s;
    text-align: center;
    font-weight: 500;
    background-color: transparent;

    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.color.gray[100]};
    }
  }

  button {
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.color.gray[800]};
    background-color: transparent;
    transition: 0.2s;

    svg {
      height: 16px;
      stroke-width: 2.25px;
    }

    &:first-child {
      border-right: 1px solid ${({ theme }) => theme.color.gray[300]};
    }

    &:last-child {
      border-left: 1px solid ${({ theme }) => theme.color.gray[300]};
    }

    &:hover {
      background-color: ${({ theme }) => theme.color.gray[100]};
    }
  }
`

export default StyledDesignerFontSizeTool
