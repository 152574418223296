import { FC, createRef } from 'react'
import { HeartIcon, PlusIcon } from '@heroicons/react/24/outline'
import { HeartIcon as SolidHeartIcon } from '@heroicons/react/24/solid'
import { ChatIcon } from '../../../../assets'
import { setShowChat, updateCreatePost, useAppDispatch, useAppSelector } from '../../../../redux'
import { CaptionType } from '../../../../types'
import { animateAddItemToPost, theme, useCaption, useChat, useSegment } from '../../../../utils'
import { ReadMore, RenderSlate } from '../..'
import { StyledCaptionListItem } from '.'

type Props = {
  caption: CaptionType
}

const CaptionListItem: FC<Props> = ({ caption }) => {
  const dispatch = useAppDispatch()
  const captionRef = createRef<HTMLDivElement>()
  const { sendMessage } = useChat()
  const { toggleCaptionUsed, toggleCaptionFavorited, copyCaptionToClipboard } = useCaption()
  const { post } = useAppSelector((state) => state.create)
  const { track } = useSegment()

  const addCaptionToPost = async () => {
    animateAddItemToPost(captionRef)
    toggleCaptionUsed(caption)
    dispatch(updateCreatePost({ captionText: caption.captionPlainText || '' }))
    track('Added Caption to Post', { ...caption, post_id: post?._id })
  }

  const openInChat = () => {
    sendMessage(caption.captionPlainText || '', 'blanks')
    dispatch(setShowChat(true))
  }

  return (
    <StyledCaptionListItem>
      <div className="caption-header">
        <span className="caption-title">{caption.title}</span>
        {(caption.category || !!caption.userCaption?.used) && (
          <span className="caption-category">
            {!caption.userCaption?.used ? caption.category : 'Used Caption'}
          </span>
        )}
      </div>
      <ReadMore>
        <span className="caption-text">
          <RenderSlate html={caption.caption} />
        </span>
      </ReadMore>
      <div className="caption-actions">
        <div className="caption-action-group">
          <button className="fill-btn" onClick={() => openInChat()}>
            <ChatIcon /> FILL
          </button>
          <button className="use-btn" onClick={() => addCaptionToPost()}>
            <PlusIcon /> USE
          </button>
        </div>
        <button className="fav-btn" onClick={() => toggleCaptionFavorited(caption)}>
          {caption.userCaption?.liked ? (
            <SolidHeartIcon fill={theme.color.red[600]} />
          ) : (
            <HeartIcon />
          )}
        </button>
      </div>
    </StyledCaptionListItem>
  )
}

export default CaptionListItem
