import styled from 'styled-components'

const StyledToggle = styled.div`
  display: flex;
  position: relative;

  input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    &:hover {
      cursor: pointer;
    }

    &:checked + .toggle-box {
      background-color: ${({ theme }) => theme.color.brand};
      border-color: ${({ theme }) => theme.color.brand};

      .toggle-indicator {
        left: 32px;
        background-color: white;
      }
    }
  }

  .toggle-box {
    height: 26px;
    width: 55px;
    transition: 0.2s;
    background-color: ${({ theme }) => theme.color.background};
    border-radius: 50rem;
    border: 1px solid ${({ theme }) => theme.color.gray[300]};

    .toggle-indicator {
      transition: 0.2s;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 4px;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.color.gray[200]};
    }
  }
`

export default StyledToggle
