import { FC, useEffect } from 'react'
import Sheet from 'react-modal-sheet'
import { setShowChat, useAppDispatch, useAppSelector } from '../../../../redux'
import { useHaptics, useKeyboardHeight } from '../../../../utils'
import { ChatHeader, ChatHistorySheet, ChatInput, ChatMessages } from '..'
import { StyledChatSheet } from '.'

const ChatSheet: FC = () => {
  const dispatch = useAppDispatch()
  const { trigger } = useHaptics()
  const { height } = useKeyboardHeight()
  const { showChat } = useAppSelector((state) => state.state)

  useEffect(() => {
    if (showChat) {
      trigger('impact-light')
    }

    return () => {
      trigger('impact-light')
    }
  }, [showChat])

  return (
    <>
      <Sheet rootId="root" isOpen={showChat} onClose={() => dispatch(setShowChat(false))}>
        <Sheet.Container>
          <Sheet.Header>
            <ChatHeader />
          </Sheet.Header>
          <Sheet.Content disableDrag>
            <StyledChatSheet keyboardHeight={height} data-chat-sheet-content>
              <ChatMessages />
              <ChatInput />
            </StyledChatSheet>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => dispatch(setShowChat(false))} />
      </Sheet>
      <ChatHistorySheet />
    </>
  )
}

export default ChatSheet
