import { FC } from 'react'
import { ReactComponent as SCMark } from '../../../assets/sc-logo-icon.svg'
import { StyledMobileBlockView } from '.'

const MobileBlockView: FC = () => {
  return (
    <StyledMobileBlockView>
      <div className="icon">
        <SCMark />
      </div>
      <span className="title">Get the app & create on mobile</span>
      <span className="body">Download the app to access Dottie on the go</span>
      <a
        target="_blank"
        href="https://apps.apple.com/us/app/social-curator/id1620026092?itsct=apps_box_badge&itscg=30200"
        className="apple-btn"
      >
        <img
          src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/white/en-us?size=250x83&releaseDate=1656374400"
          alt="Download on the App Store"
        />
      </a>
    </StyledMobileBlockView>
  )
}

export default MobileBlockView
