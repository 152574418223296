import { FC } from 'react'
import { ReactComponent as HexagonIcon } from '../../../assets/hexagon-icon.svg'
import { ReactComponent as ParallelogramIcon } from '../../../assets/parallelogram-icon.svg'
import { ReactComponent as TetragonIcon } from '../../../assets/tetragon-icon.svg'
import { addCreatePostBit, useAppDispatch } from '../../../redux'
import { BitType } from '../../../types'
import { StyledBitListItem } from '.'

type Props = {
  bit: BitType
}

const BitListItem: FC<Props> = ({ bit }) => {
  const dispatch = useAppDispatch()

  return (
    <StyledBitListItem onClick={() => dispatch(addCreatePostBit(bit.text || ''))}>
      <div className="bit-list-item-header">
        <div className="bit-list-item-icon">
          {bit.type === 'hook' && <TetragonIcon />}
          {bit.type === 'insight' && <ParallelogramIcon />}
          {bit.type === 'call-to-action' && <HexagonIcon />}
        </div>
        <span className="bit-list-item-category">
          {bit?.type?.replaceAll('-', ' ')?.toUpperCase()}
        </span>
      </div>
      <div className="bit-list-item-content">
        <span>{bit.text}</span>
      </div>
    </StyledBitListItem>
  )
}

export default BitListItem
