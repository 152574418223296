import styled from 'styled-components'

const StyledChatHistoryListItem = styled.div`
  padding: 14px;
  text-align: left;
  background-color: transparent;

  .conversation-title {
    display: block;
    font-weight: 600;
    font-size: 15px;
    color: ${({ theme }) => theme.color.gray[800]};
    display: -webkit-box;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-align: left;
    -webkit-box-orient: vertical;
  }

  .conversation-last-message {
    font-size: 14px;
    color: ${({ theme }) => theme.color.gray[500]};
    display: -webkit-box;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-align: left;
    -webkit-box-orient: vertical;
  }

  .conversation-date {
    display: block;
    font-size: 13px;
    margin-top: 6px;
    font-weight: 400;
    color: ${({ theme }) => theme.color.gray[400]};
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
  }
`

export default StyledChatHistoryListItem
