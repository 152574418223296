import { ChangeEvent, FC, createRef, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'

type Props = {
  value: string
  meridiem: string
  onSelect: (value: string) => void
}

const TimeSelectValues: FC<Props> = ({ value, meridiem, onSelect }) => {
  const [valueList, setValueList] = useState<JSX.Element[]>([])
  const scrollRef = useRef(createRef<HTMLLIElement>())
  const listRef = createRef<HTMLUListElement>()

  const handleSelect = (e: ChangeEvent<HTMLInputElement>) => {
    onSelect(e.target.value)
  }

  // Returns the human-readable meridiem of the timestamp
  const getMeridiem = (value: string) => {
    if (value.toLowerCase().includes('p')) {
      return 'PM'
    }

    if (value.toLowerCase().includes('a')) {
      return 'AM'
    }

    return meridiem.toUpperCase()
  }

  // Generates the dropdown list
  const generateValues = () => {
    const values: JSX.Element[] = []

    const inputMeridiem = getMeridiem(value)
    const inputFormatted = value.replace(/\D/g, '').trim()

    // Loop through the two meridiems (AM / PM)
    for (let x = 0; x < 2; x++) {
      // Loop through the 12 hours in a day
      for (let y = 0; y < 12; y++) {
        // Loops through the 4 time intervals (00 / 15 / 30 / 45)
        for (let z = 0; z < 4; z++) {
          const meridiem = x ? 'PM' : 'AM'
          const minute = z * 15
          const hour = y || 12
          const paddedHour = hour.toString().padStart(2, '0')
          const paddedInput = inputFormatted.padEnd(3, '0').padStart(4, '0')
          const isActive =
            paddedInput === `${paddedHour}${minute || '00'}` && inputMeridiem == meridiem

          const isClosest =
            !isActive &&
            paddedInput.slice(0, 2) === paddedHour &&
            !minute &&
            inputMeridiem == meridiem

          values.push(
            <li
              className={classNames({ active: isActive })}
              ref={isActive || isClosest ? scrollRef.current : undefined}
              key={`${paddedHour}${minute || '00'}${meridiem}`}
            >
              <label>
                <input
                  type="radio"
                  name="time-interval"
                  value={`${paddedHour}${minute || '00'}${meridiem}`}
                  onChange={(e) => handleSelect(e)}
                />
                {hour}
                {minute ? `:${minute}` : ''}
                {meridiem}
              </label>
            </li>
          )
        }
      }
    }

    setValueList(values)
  }

  useEffect(() => {
    generateValues()
  }, [value, meridiem])

  useEffect(() => {
    if (!listRef.current || !scrollRef.current.current) {
      return
    }

    const container = listRef.current
    const element = scrollRef.current.current
    const PADDING = 4

    const top = element.offsetTop - PADDING

    // Scroll the dropdown to the highlighted timestamp
    setTimeout(() => {
      container.scrollTo({
        top,
        behavior: 'smooth'
      })
    }, 100)
  }, [listRef, scrollRef])

  return <ul ref={listRef}>{valueList}</ul>
}

export default TimeSelectValues
