import styled from 'styled-components'

const StyledUploadsView = styled.div`
  display: grid;
  height: 100%;

  &[data-in-folder='false'] {
    grid-template-rows: auto minmax(0, 1fr);
  }

  .action-bar {
    padding: 14px;
    display: flex;

    .upload-btn {
      height: 52px;
      display: flex;
      box-sizing: border-box;
      width: 100%;
      align-items: center;
      justify-content: center;
      padding: 0 20px 0 16px;
      border-radius: 12px;
      font-weight: 600;
      position: relative;
      background-color: ${({ theme }) => theme.color.gray[800]};
      color: ${({ theme }) => theme.color.background};

      input {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
      }

      svg {
        height: 24px;
        margin-right: 8px;
      }
    }

    .new-folder-btn {
      height: 52px;
      flex-shrink: 0;
      display: flex;
      box-sizing: border-box;
      align-items: center;
      justify-content: center;
      padding: 0 16px;
      border-radius: 12px;
      font-weight: 600;
      background-color: ${({ theme }) => theme.color.gray[200]};
      color: ${({ theme }) => theme.color.gray[600]};
      margin-left: 10px;
    }
  }

  .upload-list-scroll {
    height: 100%;
    overflow-y: auto;
  }
`

export default StyledUploadsView
