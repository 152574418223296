import { FC, useEffect } from 'react'
import { useApolloClient } from '@apollo/client'
import { captionSetGetTemplates } from '../../../graphql'
import { useForm } from '../../../utils'
import { PostTemplateRow } from '..'
import { StyledPostTemplateList } from '.'

const PostTemplateList: FC = () => {
  const apollo = useApolloClient()
  const { form, updateFormValue } = useForm({
    popular: [],
    behindTheScenes: [],
    myWhy: [],
    insight: [],
    inspiration: [],
    aboutMe: [],
    benefits: [],
    product: [],
    holiday: []
  })

  const loadTemplates = async () => {
    const { data } = await apollo.query({
      query: captionSetGetTemplates,
      fetchPolicy: 'no-cache',
      variables: {}
    })

    updateFormValue(data?.captionSetGetTemplates)
  }

  useEffect(() => {
    loadTemplates()
  }, [])

  return (
    <StyledPostTemplateList>
      <PostTemplateRow title="Popular" templates={form.popular} />
      <PostTemplateRow
        title="About Me"
        description="Share individual details, like favorite snacks or weekend activities, to help your audience get to know and trust you better."
        templates={form.aboutMe}
      />
      <PostTemplateRow
        title="Benefits"
        description={`Sell your business without sounding "sales-y" by sharing the long-term, emotional, or unexpected benefits of your product or service.`}
        templates={form.benefits}
      />
      <PostTemplateRow
        title="Showcase Product/Service"
        description={`Introduce your audience (new and old) to a product or service you offer.`}
        templates={form.product}
      />
      <PostTemplateRow
        title="Inspiration/Motivation/Encouragement"
        description={`Encourage your ideal client with a funny lesson you learned, a silly meme, or an uplifting quote.`}
        templates={form.inspiration}
      />
      <PostTemplateRow
        title="Personal Insight"
        description={`Share your tips and perspective in a way your potential clients or customers can benefit from.`}
        templates={form.insight}
      />
      <PostTemplateRow
        title="My Why"
        description={`Talk about the reason you are in your line of work & invite your audience to connect with you.`}
        templates={form.myWhy}
      />
      <PostTemplateRow
        title="Behind the Scenes/Tutorial"
        description={`Invite your audience to see the "real life" behind your business.`}
        templates={form.behindTheScenes}
      />
      <PostTemplateRow
        title="Holiday"
        description={`Share details about your favorite holiday traditions, go-to recipes, or family activities.`}
        templates={form.holiday}
      />
    </StyledPostTemplateList>
  )
}

export default PostTemplateList
