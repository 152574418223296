import { createRef, FC, useEffect, useState } from 'react'
import { useActiveObject, useEditor } from '@layerhub-io/react'
import { CubeTransparentIcon } from '@heroicons/react/24/outline'
import { OpacityPopover } from '../..'
import { DesignerToolButton } from '.'

const DesignerTransparencyTool: FC = () => {
  const btnRef = createRef<HTMLButtonElement>()
  const editor = useEditor()
  const activeObject = useActiveObject() as any
  const [showOptions, setShowOptions] = useState(false)
  const [opacity, setOpacity] = useState(0)

  const handleChange = (value: number) => {
    const offsetVal = Math.min(100, value)
    setOpacity(offsetVal)
    editor.objects.update({ opacity: offsetVal / 100 })
  }

  useEffect(() => {
    if (activeObject) {
      setOpacity(activeObject.opacity * 100)
    }
  }, [activeObject])

  return (
    <>
      <DesignerToolButton
        className="transparency-btn"
        data-tooltip="Transparency"
        isActive={showOptions}
        isIcon
        onClick={() => setShowOptions(!showOptions)}
        ref={btnRef}
      >
        <CubeTransparentIcon />
      </DesignerToolButton>
      <OpacityPopover
        value={opacity}
        position="bottom-left"
        buttonRef={btnRef}
        classToAvoid="transparency-btn"
        isOpen={showOptions}
        onClose={() => setShowOptions(false)}
        onChange={(value) => handleChange(value)}
      />
    </>
  )
}

export default DesignerTransparencyTool
