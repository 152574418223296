import { createGlobalStyle } from 'styled-components'
import '../fonts/index.css'

export const Global = createGlobalStyle`
  :root {
    --focus-border: ${({ theme }) => theme.color.brand};
    --em-color-border: ${({ theme }) => theme.color.brand};
    --em-rgb-accent: ${({ theme }) => theme.color.brand} !important;
    --separator-border: ${({ theme }) => theme.color.gray[300]};
  }

  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    // overscroll-behavior-y: none;
  }

  body {
    background-color: ${({ theme }) => theme.color.background};
    color: ${({ theme }) => theme.color.foreground};
    font-size: 16px;
    margin: 0;
    font-family: ${({ theme }) => theme.typeface.sans};
    overscroll-behavior-y: none;
  }

  h1, h2, h3, h4, h5, h6, p, ul, li {
    margin: 0;
  }

  label {
    cursor: default;
  }

  input, button, select, textarea {
    -webkit-appearance: none;
    outline: none;
    font-family: ${({ theme }) => theme.typeface.sans};
    margin: 0;
    padding: 0;
    font-size: 16px;
    border: 0;
  }

  input::placeholder {
    color: ${({ theme }) => theme.color.gray[500]};
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px ${({ theme }) => theme.color.brand}10 inset;
    box-shadow: 0 0 0px 1000px ${({ theme }) => theme.color.brand}10 inset;
    transition: background-color 5000s ease-in-out 0s;
    color: ${({ theme }) => theme.color.brand};
    -webkit-text-fill-color: ${({ theme }) => theme.color.brand};
  }

  input:-webkit-autofill::first-line {
    font-family: ${({ theme }) => theme.typeface.sans};
    font-size: 16px !important;
    color: ${({ theme }) => theme.color.brand};
  }

  button:hover, a:hover {
    cursor: pointer;
  }

  button, a {
    user-select: none;
  }

  .react-draggable [data-chat-handle], .smooth-dnd-ghost {
    cursor: grab;
  }

  .react-draggable-dragging {
    cursor: grabbing !important;

    [data-chat-handle] {
      cursor: grabbing !important;
    }
  }

  .show-xs {
    display: inherit !important;
  }

  .hide-m {
    display: none !important;
  }

  .hide-xs {
    display: none !important;
  }

  .infinite-scroll-component {
    overflow: hidden !important;
  }

  .confetti {
    position: fixed;
    top: -250px;
    left: 50%;
    pointer-events: none;
    transform: translateX(-50%);
    z-index: 9999999;
  }

  .sortable {
    margin: 0;
    padding: 0;
    list-style-type: none;

    .sortable-item {
      margin: 0;
      padding: 0;

      &.disabled {
        display: contents;
      }
    }
  }

  .action-toast {
    margin: 10px 0;

    button {
      margin-top: 16px;
    }
  }

  .react-trello-lane .smooth-dnd-container {
    min-height: 82px;
  }

  .loading-screen {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.color.gray[100]};
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.color.gray[500]};

    .spinner {
      margin-right: 8px;
    }
  }

  [data-rsbs-has-header='false'] [data-rsbs-header] {
    padding-top: 12px !important;

    &:before {
      background-color: ${({ theme }) => theme.color.gray[300]};
      top: 12px;
      width: 60px;
    }
  }

  [data-rsbs-state="open"] {
    [data-rsbs-backdrop="true"] {
      pointer-events: all;
    }
  }

  [data-rsbs-overlay] {
    z-index: 5 !important;
  }

  .bottom-sheet-sibling {
    -ms-scroll-chaining: none;
    overscroll-behavior: none;
    touch-action: none;
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    z-index: 4;
  }

  [data-rsbs-overlay], [data-rsbs-root]:after {
    background-color: ${({ theme }) => theme.color.background} !important;
  }

  .photo-view {
    .PhotoView-Slider__BannerWrap {
      padding-top: env(safe-area-inset-bottom);
      background-color: transparent;

      .PhotoView-Slider__Counter {
        opacity: 0;
      }
    }

    .PhotoView-Slider__ArrowLeft, .PhotoView-Slider__ArrowRight {
      svg {
        border-radius: 50%;
      box-shadow: 0 0 0 1px rgb(255, 255, 255, 0.1);
      }
    }

    .spinner {
      svg {
        height: 55px;
        width: 55px;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.xs}px) {
    * {
      overscroll-behavior-y: none;

      &:not(.app-content) {
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.xs}px) {
    .hide-xs {
      display: inherit !important;
    }

    .show-xs {
      display: none !important;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    .hide-m {
      display: inherit !important;
    }

    body {
      overscroll-behavior-y: none;
      overflow-x: hidden;
      overflow-y: auto;
    }

    ::-webkit-scrollbar {
      background: transparent;
    }
  
    ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
  
    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.color.gray[400]}90;
    }
  
    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.color.gray[200]};
    }
  }
`
