import styled from 'styled-components'

type StyledProps = {
  size: 'xs' | 's' | 'm'
}

const StyledDropdown = styled.button<StyledProps>`
  flex-shrink: 0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;

  .chevron-icon {
    color: ${({ theme }) => theme.color.gray[500]};
    margin: 0 -2px 0 10px;
  }

  .dropdown-label {
    display: flex;
    align-items: center;

    .selected-count {
      height: 20px;
      width: 20px;
      background-color: ${({ theme }) => theme.color.brand};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      color: white;
      margin-right: 6px;
      font-size: 14px;
      font-weight: 500;
    }

    svg {
      margin-right: 6px;
    }
  }

  ${({ size, theme }) =>
    size === 'm' &&
    `
    height: 38px;
    padding: 0 12px;
    background-color: ${theme.color.background};
    color: ${theme.color.gray[600]};
    font-size: 16px;
    box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.1),
      0px 2px 3px rgba(0, 0, 0, 0.1);

    .chevron-icon {
      color: ${theme.color.gray[500]};
      margin: 0 -2px 0 10px;
      height: 20px;
      stroke-width: 2px;
    }

    &:not(:last-child) {
      margin-right: 10px;
    }

    &:hover {
      background-color: ${theme.color.gray[100]};
      box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.2), 0px 0px 0px 1px rgba(0, 0, 0, 0.15),
        0px 2px 3px rgba(0, 0, 0, 0.15);
    }
  `}

  ${({ size, theme }) =>
    size === 'xs' &&
    `
    height: 24px;
    padding: 0 6px;
    color: ${theme.color.gray[500]};
    background-color: transparent;
    font-size: 15px;
    margin: 0 -6px;

    .chevron-icon {
      color: ${theme.color.gray[400]};
      margin: 0 -2px 0 4px;
      height: 14px;
      stroke-width: 3px;
      transition: 0.2s;
    }

    &:not(:last-child) {
      margin-right: 10px;
    }

    &:hover {
      color: ${theme.color.gray[800]};

      .chevron-icon {
        color: ${theme.color.gray[800]};
      }
    }
  `}

  &[aria-expanded='true'] {
    background-color: ${({ theme }) => theme.color.brandLight};
    color: ${({ theme }) => theme.color.brand};
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.brand}10;

    .chevron-icon {
      color: ${({ theme }) => theme.color.brand};
      transform: rotate(180deg);
    }
  }
`

export default StyledDropdown
