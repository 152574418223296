import { FC } from 'react'
import { useDesigner } from '../../../../utils'
import { DesignerPanel } from '../..'
import { StyledDesignerElementsPanel } from '.'

const shapes = [
  'square',
  'rounded-square',
  'circle',
  'triangle',
  'triangle-down',
  'diamond',
  'pentagon',
  'hexagon-vertical',
  'hexagon-horizontal',
  'octagon',
  '4-pointed-star',
  'star',
  '6-pointed-star',
  '8-pointed-star',
  'star-burst-1',
  'star-burst-2',
  '8-pointed-star-inflated',
  'star-burst-3',
  'star-burst-4',
  'arrow-right',
  'arrow-left',
  'arrow-up',
  'arrow-down',
  'arrow-horizontal',
  'arrow-block-right',
  'arrow-block-2-right',
  'arrow-block-concave',
  'arrow-block-convex',
  'pill',
  'square-speech-bubble',
  'round-speech-bubble',
  'heart'
]

const DesignerElementsPanel: FC = () => {
  const { addObject } = useDesigner()

  const addVector = (url: string) => {
    addObject({
      type: 'StaticVector',
      src: `/images/elements/shapes/${url}.svg`
    })
  }

  return (
    <DesignerPanel>
      <StyledDesignerElementsPanel>
        <div className="element-list">
          {shapes.map((shape, index) => (
            <button className="shape-element" key={index} onClick={() => addVector(shape)}>
              <img src={`/images/elements/shapes/${shape}.svg`} alt="" />
            </button>
          ))}
        </div>
      </StyledDesignerElementsPanel>
    </DesignerPanel>
  )
}

export default DesignerElementsPanel
