import { FC } from 'react'
import { removeFromDialogStack, useAppDispatch, useAppSelector } from '../../redux'
import { isMobile } from '../../utils'
import { GoProDialog, MediaByUrlDialog, PublishDialog } from '..'

const DialogManager: FC = () => {
  const dispatch = useAppDispatch()
  const { curr, stack } = useAppSelector((state) => state.dialog)

  const isOpen = (dialog: NonNullable<typeof curr>) => {
    if (isMobile()) {
      return curr === dialog
    }

    return stack.includes(dialog)
  }

  return (
    <>
      <GoProDialog
        isOpen={isOpen('go-pro')}
        onClose={() => dispatch(removeFromDialogStack('go-pro'))}
      />
      <PublishDialog
        isOpen={isOpen('publish')}
        onClose={() => dispatch(removeFromDialogStack('publish'))}
      />
      <MediaByUrlDialog
        isOpen={isOpen('media-by-url')}
        onClose={() => dispatch(removeFromDialogStack('media-by-url'))}
      />
    </>
  )
}

export default DialogManager
