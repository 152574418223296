import styled from 'styled-components'

const StyledHomeStats = styled.div`
  overflow-x: auto;
  padding: 0 16px;
  display: flex;

  .card {
    flex-shrink: 0;
    height: 60px;
    border-radius: 8px;
    padding: 0 12px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.color.gray[100]};

    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
`

export default StyledHomeStats
