import { gql } from '@apollo/client'

export const generateContentPillars = gql`
  query GenerateContentPillars {
    generateContentPillars {
      _id
      icon
      title
      content
    }
  }
`
