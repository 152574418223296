import { FC } from 'react'
import { ChatConversationType } from '../../../types'
import { getRelativeString, useChat } from '../../../utils'
import { StyledChatHistoryListItem } from '.'

type Props = {
  conversation: ChatConversationType
}

const ChatHistoryListItem: FC<Props> = ({ conversation }) => {
  const { loadConversation } = useChat()

  return (
    <StyledChatHistoryListItem onClick={() => loadConversation(conversation._id)}>
      <span className="conversation-title">{conversation.topic}</span>
      <span className="conversation-last-message">{conversation.lastMessage.content}</span>
      <span className="conversation-date">
        {getRelativeString(conversation.lastMessage.createdAt, true)}
      </span>
    </StyledChatHistoryListItem>
  )
}

export default ChatHistoryListItem
