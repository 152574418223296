import styled, { createGlobalStyle, keyframes } from 'styled-components'

const headerAnim = keyframes`
  from {
    transform: translateY(6px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
`

const StyledInsidersView = styled.div`
  height: 100vh;
  user-select: none;
  background: linear-gradient(#021539, ${({ theme }) => theme.color.brandDark});

  .insiders-content {
    max-width: 500px;
    margin: 0 auto;
    padding: 80px 20px;

    .sc-logo {
      height: 55px;
      width: 55px;
      object-fit: contain;
      margin: 0 auto;
      display: block;
    }

    h1 {
      margin-top: 30px;
      opacity: 0;
      text-align: center;
      font-size: 42px;
      color: ${({ theme }) => theme.color.background};
      font-family: ${({ theme }) => theme.typeface.polySans};
      font-weight: 400;
      animation: forwards ease-in-out ${headerAnim} 0.5s;
    }

    h2 {
      margin: 20px auto 0 auto;
      text-align: center;
      font-size: 20px;
      line-height: 28px;
      max-width: 380px;
      color: #71adff;
      font-weight: 400;
      opacity: 0;
      animation: forwards ease-in-out ${headerAnim} 0.5s;
      animation-delay: 0.2s;
    }

    form {
      margin-top: 70px;
      display: flex;

      .input {
        width: 100%;
        border: 0;
        box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1);
        background-color: rgb(255, 255, 255, 0.05);

        svg {
          transition: 0.2s;
          color: rgb(255, 255, 255, 0.5);
        }

        input {
          color: ${({ theme }) => theme.color.background};

          &::placeholder {
            color: rgb(255, 255, 255, 0.4);
          }
        }

        &:hover,
        &:focus-within {
          background-color: rgb(255, 255, 255, 0.1);

          svg {
            color: rgb(255, 255, 255, 0.8);
          }
        }
      }

      button {
        margin-left: 12px;
        min-width: 100px;
      }
    }
  }
`

export const GlobalInsiderStyles = createGlobalStyle`
  body {
    background-color: #021539;
  }
`

export default StyledInsidersView
