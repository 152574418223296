import { FC, useState } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { XMarkIcon } from '@heroicons/react/24/outline'
import {
  setDesignerAddToPost,
  setDesignerMediaId,
  setDesignerPhoto,
  setDesignerTemplate,
  setShowDesigner,
  useAppDispatch
} from '../../../redux'
import { PostType } from '../../../types'
import { conditionalStage, useCreate } from '../../../utils'
import { ActionDialog, CloudMediaPreview } from '../..'
import { StyledCreateEditorMediaItem } from '.'

type Props = {
  media: NonNullable<PostType['media']>[number]
  index?: number
  isOverlay?: boolean
}

const CreateEditorMediaItem: FC<Props> = ({ media, index = 0, isOverlay }) => {
  const {
    overIndex,
    activeIndex,
    attributes,
    listeners,
    isDragging,
    isOver,
    setNodeRef,
    transform,
    transition
  } = useSortable({
    animateLayoutChanges: () => true,
    id: media._id,
    data: {
      media,
      index
    }
  })

  const dispatch = useAppDispatch()
  const { removeMediaFromPost } = useCreate()
  const [showDeletionConfirmation, setShowDeletionConfirmation] = useState(false)

  const style = {
    transform: CSS.Translate.toString(transform),
    transition
  }

  const handleOpenDesigner = () => {
    if (!media?.design) {
      dispatch(setShowDesigner(true))
      dispatch(setDesignerAddToPost(true))
      dispatch(setDesignerPhoto(media.url))
      dispatch(setDesignerMediaId(media._id))
      return
    }

    dispatch(setShowDesigner(true))
    dispatch(setDesignerAddToPost(true))
    dispatch(setDesignerMediaId(media.ref))
    dispatch(setDesignerTemplate(media.design as any))
  }

  const handleDelete = () => {
    if (!media) {
      return
    }

    removeMediaFromPost(media._id)
  }

  return (
    <>
      <StyledCreateEditorMediaItem
        style={{
          ...conditionalStage(!!isOverlay, style),
          width: (230 / (media.dimensions?.y || 0)) * (media.dimensions?.x || 0)
        }}
        ref={setNodeRef}
        handleBefore={activeIndex > overIndex}
        isDragging={!isOverlay && isDragging}
        isOverlay={isOverlay}
        {...attributes}
        {...listeners}
      >
        {isOver && activeIndex !== index && <div className="drop-indicator" />}
        <button className="delete-btn" onClick={() => setShowDeletionConfirmation(true)}>
          <XMarkIcon />
        </button>
        {media.type !== 'video' && (
          <div className="media-actions">
            {/* <button className="action-btn" onClick={() => handleOpenDesigner()}>
              Edit
            </button> */}
            {/* <button className="action-btn icon" onClick={() => handleOpenDesigner()}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                <path
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M4.25 1.75v10h10M11.8 14.2v-2.5m0-2.5v-5h-5m-2.5 0H1.8"
                />
              </svg>
            </button> */}
          </div>
        )}
        <CloudMediaPreview url={media.url} type={media.type} isInteractive />
      </StyledCreateEditorMediaItem>
      <ActionDialog
        title={`Remove this ${media.type}`}
        body={`Are you sure you want to remove this ${media.type}?`}
        type="error"
        confirmButtonText="Remove"
        isOpen={showDeletionConfirmation}
        onClose={() => setShowDeletionConfirmation(false)}
        onConfirm={() => handleDelete()}
      />
    </>
  )
}

export default CreateEditorMediaItem
