import styled from 'styled-components'

const StyledDateTimeDialog = styled.div`
  width: 100vw;
  box-sizing: border-box;

  .content-container {
    .day-section {
      padding: 30px;

      .calendar-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        button {
          height: 45px;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.2s;
        }

        .month-btn {
          padding: 0 14px;
          font-weight: 500;
          font-size: 15px;
          pointer-events: none;
          background-color: ${({ theme }) => theme.color.gray[100]};
          color: ${({ theme }) => theme.color.gray[600]};

          svg {
            height: 16px;
            stroke-width: 2.75px;
            margin-left: 10px;
            flex-shrink: 0;
          }
        }

        .page-btn-group {
          display: flex;

          .page-btn {
            width: 45px;
            color: ${({ theme }) => theme.color.gray[600]};
            background-color: ${({ theme }) => theme.color.gray[100]};

            svg {
              height: 22px;
              stroke-width: 2.25px;
              flex-shrink: 0;
              position: relative;
            }

            &:not(:last-child) {
              margin-right: 10px;
            }

            &:first-child {
              svg {
                left: -1px;
              }
            }

            &:last-child {
              svg {
                right: -1px;
              }
            }

            &:hover {
              background-color: ${({ theme }) => theme.color.background};
              box-shadow: 0 1px 3px rgb(0, 0, 0, 0.1), 0 0 10px rgb(0, 0, 0, 0.1);
            }
          }
        }
      }

      .calendar-week {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        gap: 6px;
        margin-bottom: 10px;

        span {
          text-align: center;
          font-size: 12px;
          font-weight: 600;
          color: ${({ theme }) => theme.color.gray[400]};
        }
      }

      .calendar {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        gap: 6px;

        .calendar-day {
          text-align: center;
          height: 50px;
          width: 100%;
          position: relative;
          box-sizing: border-box;
          font-weight: 500;
          font-size: 15px;
          border-radius: 10px;
          background-color: transparent;
          transition: 0.2s;
          border: 1px solid ${({ theme }) => theme.color.gray[200]};
          color: ${({ theme }) => theme.color.gray[600]};

          .calendar-day-number {
            font-size: 20px;
            font-weight: 300;
            margin-top: 2px;
          }

          &:not(:last-child) {
            margin-right: 10px;
          }

          &:hover {
            border-color: ${({ theme }) => theme.color.gray[400]};
          }

          &[data-other-month='true'] {
            background-color: ${({ theme }) => theme.color.gray[100]};
            color: ${({ theme }) => theme.color.gray[300]};
            border-color: ${({ theme }) => theme.color.gray[100]};
          }

          &[data-today='true'] {
            &::before {
              content: '';
              height: 4px;
              width: 4px;
              position: absolute;
              right: 8px;
              top: 8px;
              border-radius: 50%;
              flex-shrink: 0;
              background-color: ${({ theme }) => theme.color.red[500]};
            }
          }

          &[data-selected='true'] {
            background-color: ${({ theme }) => theme.color.gray[900]};
            color: ${({ theme }) => theme.color.background};
            border-color: ${({ theme }) => theme.color.gray[900]};
          }
        }
      }
    }

    .time-section {
      padding: 0 30px 30px 30px;

      .time-input-container {
        border-radius: 10px;
        height: 45px;
        position: relative;
        display: flex;
        transition: border-color 0.2s;
        border: 1px solid ${({ theme }) => theme.color.gray[200]};

        .clock-icon {
          height: 24px;
          position: absolute;
          left: 10px;
          transform: translateY(-50%);
          top: 50%;
          color: ${({ theme }) => theme.color.gray[400]};
          pointer-events: none;
        }

        input {
          height: 100%;
          width: 100%;
          box-sizing: border-box;
          padding: 0 70px 0 44px;
          background-color: transparent;
          color: ${({ theme }) => theme.color.gray[600]};

          &::-webkit-calendar-picker-indicator {
            background: transparent;
            bottom: 0;
            color: transparent;
            cursor: pointer;
            height: auto;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            width: auto;
          }
        }

        .label-group {
          display: flex;
          align-items: center;
          position: absolute;
          right: 10px;
          transform: translateY(-50%);
          top: 50%;
          pointer-events: none;
          color: ${({ theme }) => theme.color.gray[400]};

          .zone {
            font-size: 14px;
            font-weight: 500;
          }

          .chevron-icon {
            height: 16px;
            stroke-width: 2.75px;
            margin-left: 10px;
            flex-shrink: 0;
          }
        }

        &:focus-within,
        &:hover {
          border-color: ${({ theme }) => theme.color.gray[400]};
        }
      }
    }
  }

  .action-bar {
    padding: 30px;
    border-top: 1px solid ${({ theme }) => theme.color.gray[200]};
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      height: 45px;
      padding: 0 16px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 15px;
      transition: 0.2s;

      svg {
        height: 22px;
        flex-shrink: 0;
        margin: 0 10px 0 -2px;
        stroke-width: 2px;
      }

      &.fade-btn {
        background-color: ${({ theme }) => theme.color.gray[100]};
        color: ${({ theme }) => theme.color.gray[600]};

        &:hover {
          background-color: ${({ theme }) => theme.color.gray[200]};
        }
      }

      &.outline-btn {
        background-color: ${({ theme }) => theme.color.background};
        color: ${({ theme }) => theme.color.gray[700]};
        box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.gray[200]};

        &:hover {
          box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.gray[400]};
        }
      }

      &.solid-btn {
        background-color: ${({ theme }) => theme.color.gray[900]};
        color: ${({ theme }) => theme.color.background};

        &:hover {
          background-color: ${({ theme }) => theme.color.gray[700]};
        }
      }

      &:disabled,
      &:disabled:hover {
        cursor: not-allowed;
        color: ${({ theme }) => theme.color.gray[300]};
        background-color: ${({ theme }) => theme.color.gray[100]};
      }
    }

    .btn-group {
      display: flex;

      button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    max-width: 460px;
  }
`

export default StyledDateTimeDialog
