import { FC } from 'react'
import { toast } from 'react-hot-toast'
import { useReward } from 'react-rewards'
import { sample } from 'lodash'
import { ArrowUturnRightIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import {
  setCaptionsFavoritesFilter,
  setCaptionsQuery,
  setCaptionsSort,
  setCaptionsUsedFilter,
  toggleCaptionsFilter,
  updateCreatePost,
  useAppDispatch,
  useAppSelector
} from '../../../redux'
import { CAPTION_CATEGORIES } from '../../../types'
import { debounceFilter, useCaption, useSegment, waitForElementExists } from '../../../utils'
import { Bubble, Bubbles, Button, Input, Select } from '../..'
import { StyledCaptionsViewHeader } from '.'

const CaptionsViewHeader: FC = () => {
  const dispatch = useAppDispatch()
  const { toggleCaptionUsed } = useCaption()
  const { track } = useSegment()
  const { post } = useAppSelector((state) => state.create)
  const { captions, search, liked, categories, sort, hideUsed } = useAppSelector(
    (state) => state.captions
  )

  const { reward } = useReward('sidebar-post-btn', 'emoji', {
    emoji: ['✨']
  })

  const addRandomCaption = async () => {
    const caption = sample(captions)

    if (!caption) {
      return
    }

    toggleCaptionUsed(caption, true)
    dispatch(updateCreatePost({ captionText: caption.captionPlainText || '' }))
    track('Added Caption to Post', { ...caption, post_id: post?._id, is_random: true })
    toast.success('Added random caption to post')

    await waitForElementExists('#sidebar-post-btn', 100)
    reward()
  }

  return (
    <StyledCaptionsViewHeader>
      <div className="captions-view-header-top">
        <Input
          value={search}
          icon={<MagnifyingGlassIcon />}
          placeholder="Search Captions"
          isDebounced
          onChange={(e) => debounceFilter(() => dispatch(setCaptionsQuery(e.target.value)))}
        />
        <Select
          value={sort}
          options={[
            { label: 'For You', value: 'for-you' },
            { label: 'Recently Added', value: 'added' }
          ]}
          onChange={(e) => dispatch(setCaptionsSort(e.target.value as any))}
        />
        <Button iconPos="left" variant="emphasis-outline" onClick={() => addRandomCaption()}>
          <ArrowUturnRightIcon />
          Use Random Caption
        </Button>
      </div>
      <Bubbles
        value={categories}
        options={CAPTION_CATEGORIES}
        onSelect={(value) => dispatch(toggleCaptionsFilter(value.label))}
      >
        <Bubble
          label="Only Favorites"
          isSelected={liked}
          onClick={() => dispatch(setCaptionsFavoritesFilter(!liked))}
          key="only-favorites"
        />
        <Bubble
          label="Hide Used"
          isSelected={hideUsed}
          onClick={() => dispatch(setCaptionsUsedFilter(!hideUsed))}
          key="hide-used"
        />
      </Bubbles>
    </StyledCaptionsViewHeader>
  )
}

export default CaptionsViewHeader
