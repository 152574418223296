import { gql } from '@apollo/client'

export const getPersonas = gql`
  query GetPersonas {
    getPersonas {
      _id
      title
      occupation
      company
      ages
      genders
      occupations
      voices
    }
  }
`
