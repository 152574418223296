import styled from 'styled-components'

const StyledLinkPageLayoutTab = styled.div`
  .block-list {
    overflow: hidden;

    .add-block-btn {
      height: 50px;
      margin: 20px;
      background-color: ${({ theme }) => theme.color.brandLight};
      color: ${({ theme }) => theme.color.brand};
      display: flex;
      border-radius: 6px;
      align-items: center;
      justify-content: center;
      width: calc(100% - 40px);
      font-weight: 500;
      transition: 0.2s;

      svg {
        height: 24px;
        stroke-width: 2.2px;
        margin-right: 10px;
      }

      &:hover {
        box-shadow: inset 0 0 0 2px ${({ theme }) => theme.color.brand};
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    .block-list {
      .add-block-btn {
        margin: 35px;
        width: calc(100% - 70px);
      }
    }
  }
`

export default StyledLinkPageLayoutTab
