import { FC, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import InfiniteScroll from 'react-infinite-scroll-component'
import { times, uniqBy } from 'lodash'
import { useApolloClient } from '@apollo/client'
import { loadFilesFromCloud } from '../../../../graphql'
import {
  setCloudFiles,
  setCloudFolders,
  setCloudParent,
  useAppDispatch,
  useAppSelector
} from '../../../../redux'
import { useCreate } from '../../../../utils'
import { FolderListItem, UploadListItem, UploadListItem_Skeleton } from '../..'
import { StyledUploadsList } from '.'

const UploadsList: FC = () => {
  const apollo = useApolloClient()
  const dispatch = useAppDispatch()
  const { parent, folders, files } = useAppSelector((state) => state.cloud)
  const { addMediaToPost } = useCreate()
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(true)

  const loadFolders = async () => {
    try {
      const { data } = await apollo.query({
        fetchPolicy: 'no-cache',
        query: loadFilesFromCloud,
        variables: {
          page: 0,
          parent,
          limit: 100,
          types: ['folder']
        }
      })

      const docs = data?.loadFilesFromCloud?.docs || []
      dispatch(setCloudFolders(docs))
    } catch (err) {
      console.log(err)
      toast.error((err as any).message)
    } finally {
      setLoading(false)
    }
  }

  const loadFiles = async () => {
    try {
      setLoading(true)

      const { data } = await apollo.query({
        fetchPolicy: 'no-cache',
        query: loadFilesFromCloud,
        variables: {
          page,
          parent,
          types: ['file'],
          limit: 20
        }
      })

      const docs = data?.loadFilesFromCloud?.docs || []
      dispatch(setCloudFiles(uniqBy([...files, ...docs], '_id')))
      setTotal(data?.loadFilesFromCloud?.totalDocs || 0)
    } catch (err) {
      console.log(err)
      toast.error((err as any).message)
    } finally {
      setLoading(false)
    }
  }

  const handleChangeParent = (parent: string) => {
    dispatch(setCloudFiles([]))
    dispatch(setCloudFolders([]))
    dispatch(setCloudParent(parent))
  }

  useEffect(() => {
    loadFiles()
    loadFolders()
  }, [page, parent])

  useEffect(() => {
    dispatch(setCloudFiles([]))
    dispatch(setCloudFolders([]))
    dispatch(setCloudParent(''))

    return () => {
      dispatch(setCloudFiles([]))
      dispatch(setCloudFolders([]))
      dispatch(setCloudParent(''))
    }
  }, [])

  return (
    <StyledUploadsList>
      {parent && (
        <div className="parent-header">
          <button onClick={() => handleChangeParent('')}>All uploads</button>
          <span>/</span>
          <button>{parent}</button>
        </div>
      )}
      <InfiniteScroll
        scrollThreshold={0.7}
        dataLength={files.length}
        next={() => setPage(page + 1)}
        hasMore={files.length < total}
        scrollableTarget="upload-list-scroll"
        loader={null}
      >
        <div className="uploads-file-list">
          {!parent &&
            folders.map((folder) => {
              return (
                <FolderListItem
                  folder={folder}
                  onClick={() => handleChangeParent(folder.name)}
                  key={folder._id}
                />
              )
            })}
          {loading &&
            page === 0 &&
            times(10).map((index) => {
              return <UploadListItem_Skeleton key={index} />
            })}
          {files.map((file) => {
            return <UploadListItem content={file} key={file._id} />
          })}
        </div>
      </InfiniteScroll>
    </StyledUploadsList>
  )
}

export default UploadsList
