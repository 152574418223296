import { ShepherdOptionsWithType } from 'react-shepherd'
import { waitForElementExists } from '../../../utils'
import { removeEmptySteps } from '..'

export const generateTour = (isMobile: boolean): ShepherdOptionsWithType[] => {
  const steps: ShepherdOptionsWithType[] = [
    {
      text: `Complete a Daily Engagement Action Each Day and Start a Streak.`,
      attachTo: {
        element: '[data-tour-id="daily-action"]',
        on: 'auto'
      },
      canClickTarget: false,
      buttons: [
        {
          text: 'Go it!',
          type: 'next'
        },
        {
          text: 'Skip tour',
          type: 'cancel'
        }
      ],
      beforeShowPromise: async () => {
        return waitForElementExists('[data-tour-id="daily-action"]')
      }
    },
    {
      text: 'You can track your progress here.',
      attachTo: {
        element: '[data-tour-id="daily-action-streak"]',
        on: 'auto'
      },
      canClickTarget: false,
      buttons: [
        {
          text: 'Awesome!',
          type: 'next'
        }
      ]
    }
  ]
  return removeEmptySteps(steps as any)
}
