import styled from 'styled-components'

const StyledChatSuggestions = styled.div`
  margin-bottom: 10px;

  .suggestion {
    flex-shrink: 0;
    white-space: nowrap;
    border-radius: 50rem;
    height: 30px;
    padding: 0 12px;
    background-color: ${({ theme }) => theme.color.brandLight};
    color: ${({ theme }) => theme.color.brand};
  }

  .arrow-background .arrow-btn {
    transition: 0.2s;
    height: 32px;
    width: 32px;
    background-color: ${({ theme }) => theme.color.background};
    color: ${({ theme }) => theme.color.gray[400]};
    box-shadow: 0 0 0 1px ${({ theme }) => theme.color.foreground}10, 0 2px 6px rgb(0, 0, 0, 0.15);

    svg {
      height: 18px;
    }

    &:hover {
      background-color: ${({ theme }) => theme.color.background};
      color: ${({ theme }) => theme.color.gray[600]};
      box-shadow: 0 0 0 1px ${({ theme }) => theme.color.foreground}20, 0 2px 6px rgb(0, 0, 0, 0.15);
    }
  }
`

export default StyledChatSuggestions
