import { FC, createRef, useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { ArrowUpTrayIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Spinner } from '../../../mobile/components'
import { updateCreatePost, useAppDispatch, useAppSelector } from '../../../redux'
import { formatTimestamp, useUploadMedia } from '../../../utils'
import { Range } from '../..'
import { StyledCreateEditorCoverPhotoPicker } from '.'

const CreateEditorCoverPhotoPicker: FC = () => {
  const inputRef = createRef<HTMLInputElement>()
  const videoRef = createRef<HTMLVideoElement>()
  const dispatch = useAppDispatch()
  const [value, setValue] = useState(0)
  const [duration, setDuration] = useState(0)
  const { loading, uploadMedia } = useUploadMedia()
  const { post } = useAppSelector((state) => state.create)

  const handleUpload = async (files: FileList | null) => {
    if (!files?.length) {
      return
    }

    const file = await uploadMedia({
      _id: uuid(),
      status: 'loading',
      type: 'file',
      addToPost: false,
      raw: files[0]
    })

    const url = file?.file?.url

    if (!url) {
      return
    }

    dispatch(
      updateCreatePost({
        coverPhotoUrl: url
      })
    )
  }

  useEffect(() => {
    const video = videoRef.current

    if (!video) {
      return
    }

    video.currentTime = value
  }, [videoRef, value])

  useEffect(() => {
    setValue(post?.coverPhotoOffset || 0)
  }, [post?.coverPhotoOffset])

  if (!post?.media?.length) {
    return null
  }

  return (
    <StyledCreateEditorCoverPhotoPicker>
      <div className="cover-photo">
        <div className="video-preview">
          <video
            src={post.media[0].url}
            controls={false}
            muted
            ref={videoRef}
            onLoadedData={(e) => setDuration(e.currentTarget.duration)}
          />
        </div>
        <button className="upload-btn" onClick={() => inputRef.current?.click()}>
          {post.coverPhotoUrl && (
            <button
              className="remove-upload-btn"
              onClick={(e) => {
                e.stopPropagation()
                dispatch(updateCreatePost({ coverPhotoUrl: '' }))
              }}
            >
              <XMarkIcon />
            </button>
          )}
          {!loading && !post.coverPhotoUrl && <ArrowUpTrayIcon />}
          {post.coverPhotoUrl && <img src={post.coverPhotoUrl} alt="" />}
          {loading && <Spinner />}
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleUpload(e.target.files)}
            ref={inputRef}
            hidden
          />
        </button>
      </div>
      {!!duration && (
        <div className="video-timestamp-container">
          <Range
            value={value}
            step={0.1}
            max={Math.max(duration, 1)}
            onChange={(value) => dispatch(updateCreatePost({ coverPhotoOffset: value }))}
            renderThumb={(props, state) => (
              <div {...props}>
                <div className="video-timestamp-offset-label" data-id="cover-photo-thumb">
                  {formatTimestamp(value)}/{formatTimestamp(duration)}
                </div>
              </div>
            )}
          />
        </div>
      )}
    </StyledCreateEditorCoverPhotoPicker>
  )
}

export default CreateEditorCoverPhotoPicker
