import styled from 'styled-components'

type StyledProps = {
  isSubTab?: boolean
  isExpanded?: boolean
}

const StyledDesignerSidebar = styled.div<StyledProps>`
  background-color: ${({ isSubTab }) => (isSubTab ? '#fff' : '#252627')};
  box-shadow: inset -1px 0 ${({ isSubTab }) => (isSubTab ? 'rgb(0, 0, 0, 0.1)' : 'rgb(255, 255, 255, 0.1)')};
  position: relative;
  height: 100%;
  z-index: 9;
  width: 80px;

  .sidebar {
    overflow: hidden;
    display: grid;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  .designer-tab-button {
    position: absolute;
    right: -13px;
    display: flex;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;

    .designer-tab {
      fill: ${({ isSubTab }) => (isSubTab ? '#fff' : '#252627')};
    }

    .chevron-icon {
      height: 20px;
      color: ${({ isSubTab }) => (isSubTab ? 'rgb(0, 0, 0, 0.3)' : 'rgb(255, 255, 255, 0.5)')};
      transition: 0.2s;
      position: absolute;
      right: 2px;
      top: 50%;
      transform: translateY(-50%);
    }

    &:hover {
      .chevron-icon {
        color: ${({ isSubTab }) => (isSubTab ? 'rgb(0, 0, 0, 0.6)' : 'rgb(255, 255, 255, 0.9)')};
      }
    }
  }

  ${({ isExpanded }) =>
    isExpanded &&
    `
    width: 400px;

    .sidebar {
      width: 400px;
      grid-template-columns: auto minmax(0, 1fr);
    }
  `}
`

export default StyledDesignerSidebar
