import styled from 'styled-components'

const StyledEditorMedia = styled.div`
  .add-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    height: 50px;
    border-radius: 14px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 14px;
    background-color: ${({ theme }) => theme.color.gray[100]};
    user-select: none;
    color: ${({ theme }) => theme.color.gray[500]};
    transition: 0.2s;

    svg {
      height: 20px;
      stroke-width: 2px;
      margin-right: 10px;
    }

    &:hover {
      background-color: ${({ theme }) => theme.color.gray[200]}c4;
    }

    &:not(:last-child) {
      margin-top: 20px;
    }
  }
`

export default StyledEditorMedia
