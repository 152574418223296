import { sample } from 'lodash'
import { DateTime } from 'luxon'

const INSTAGRAM_TIMES = [
  [9, 9.5, 10],
  [9, 9.5, 10],
  [5, 11, 15],
  [12, 17],
  [9, 9.5, 10, 17, 17.5, 18],
  [8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12],
  [10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14]
]

const getSuggestion = (day: number, times: number[] = INSTAGRAM_TIMES[day]): number | undefined => {
  if (!times.length) {
    return undefined
  }

  const now = DateTime.now()
  const nowHour = now.hour
  const suggestion = sample(times) || 0
  const today = now.day - 1 === day

  if (today && suggestion < nowHour) {
    return getSuggestion(
      day,
      times.filter((time) => time !== suggestion)
    )
  }

  return suggestion
}

export const getTimesToPost = () => {
  const today = DateTime.now().startOf('day')
  const times: DateTime[] = []
  let end = 3

  for (let i = 0; i < end; i++) {
    const date = today.plus({ days: i })
    const day = date.weekday
    const suggestion = getSuggestion(day - 1)

    if (!suggestion) {
      end++
    }

    if (!!suggestion) {
      const time = date.set({ hour: suggestion })
      times.push(time)
    }
  }

  return times
}
