import { FC } from 'react'
import { Keyboard } from '@capacitor/keyboard'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { setPhotosQuery, useAppDispatch, useAppSelector } from '../../../../redux'
import { StyledPhotosFilters } from '.'

const PhotosFilters: FC = () => {
  const dispatch = useAppDispatch()
  const { search } = useAppSelector((state) => state.photos)

  return (
    <StyledPhotosFilters>
      <div className="input-container">
        <MagnifyingGlassIcon />
        <input
          type="search"
          placeholder="Search photos"
          enterKeyHint="search"
          value={search}
          onChange={(e) => dispatch(setPhotosQuery(e.target.value))}
          onKeyDown={(e) => e.key === 'Enter' && Keyboard.hide()}
        />
      </div>
      {/* <button className="filters-btn">
        <FunnelIcon />
      </button> */}
    </StyledPhotosFilters>
  )
}

export default PhotosFilters
