import { FC, useState } from 'react'
import {
  ComputerDesktopIcon,
  DeviceTabletIcon,
  MagnifyingGlassIcon
} from '@heroicons/react/24/outline'
import { useAppSelector } from '../../../redux'
import { PagePreview } from '../../page'
import { StyledLinkPagePreview } from '.'

const LinkPagePreview: FC = () => {
  const { page } = useAppSelector((state) => state.page)
  const [view, setView] = useState<'mobile' | 'desktop'>('mobile')

  if (!page?._id) {
    return <></>
  }

  return (
    <StyledLinkPagePreview view={view}>
      <div className="link-page-preview-header">
        <div className="browser-actions">
          <div />
          <div />
          <div />
        </div>
        <div className="browser-search-bar">
          <MagnifyingGlassIcon />
          {window.origin}/@{page?.username}
        </div>
        <div className="link-page-view-switch">
          <button aria-selected={view === 'mobile'} onClick={() => setView('mobile')}>
            <DeviceTabletIcon />
          </button>
          <button aria-selected={view === 'desktop'} onClick={() => setView('desktop')}>
            <ComputerDesktopIcon />
          </button>
        </div>
      </div>
      <div className="page-preview-container">
        <div className="page-preview-sizer">{page && <PagePreview page={page} />}</div>
      </div>
    </StyledLinkPagePreview>
  )
}

export default LinkPagePreview
