import { FC } from 'react'
import { PostType } from '../../../../types'
import { CloudMediaPreview } from '../..'
import { StyledCreateMediaItem } from '.'

type Props = {
  media: NonNullable<PostType['media']>[number]
}

const CreateMediaItem: FC<Props> = ({ media }) => {
  return (
    <StyledCreateMediaItem>
      <CloudMediaPreview url={media.url} type={media.type} preview={media.preview} isInteractive />
    </StyledCreateMediaItem>
  )
}

export default CreateMediaItem
