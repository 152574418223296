import { SocialProfileType } from '../../types'
import { formatUsername } from '.'

export const getProfileListString = (selectedProfiles: SocialProfileType[], asString?: boolean) => {
  if (selectedProfiles?.length === 1 && !asString) {
    return <mark>{formatUsername(selectedProfiles[0].username, selectedProfiles[0].type)}</mark>
  }

  if (selectedProfiles?.length === 1 && asString) {
    return formatUsername(selectedProfiles[0].username, selectedProfiles[0].type)
  }

  if (asString) {
    return selectedProfiles
      ?.map((profile, index) => {
        if (index === selectedProfiles.length - 1) {
          return `and ${formatUsername(profile.username, profile.type)}`
        }

        if (index === selectedProfiles.length - 2) {
          return `${formatUsername(profile.username, profile.type)} `
        }

        return `${formatUsername(profile.username, profile.type)}, `
      })
      .join(' ')
  }

  return (
    <>
      {selectedProfiles?.map((profile, index) => {
        if (index === selectedProfiles.length - 1) {
          return (
            <>
              and <mark>{formatUsername(profile.username, profile.type)}</mark>
            </>
          )
        }

        if (index === selectedProfiles.length - 2) {
          return (
            <>
              <mark>{formatUsername(profile.username, profile.type)}</mark>{' '}
            </>
          )
        }

        return (
          <>
            <mark>{formatUsername(profile.username, profile.type)}</mark>,{' '}
          </>
        )
      })}
    </>
  )
}
