import { MouseSensor, TouchSensor, useSensor, useSensors as useDndSensors } from '@dnd-kit/core'

export const useSensors = () => {
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 5
    }
  })
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 250,
      tolerance: 5
    }
  })
  const sensors = useDndSensors(mouseSensor, touchSensor)

  return sensors
}
