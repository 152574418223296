import { FC, useEffect, useState } from 'react'
import { times } from 'lodash'
import { DateTime } from 'luxon'
import {
  ArrowUturnLeftIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ClockIcon,
  XMarkIcon
} from '@heroicons/react/24/outline'
import { UnscheduleIcon } from '../../../assets'
import { updatePlanPost, useAppDispatch, useAppSelector } from '../../../redux'
import { dateIsToday, getRelativeString } from '../../../utils'
import { Dialog } from '../..'
import { StyledDateTimeDialog } from '.'

type Props = {
  value: string
  isOpen: boolean
  onClose: () => void
  onChange: (value?: string) => void
}

const DAY_COUNT = 6 * 7

const DateTimeDialog: FC<Props> = ({ value, isOpen, onClose, onChange }) => {
  const dispatch = useAppDispatch()
  const [pickedDay, setPickedDay] = useState<DateTime>()
  const [pickedTime, setPickedTime] = useState<DateTime>()
  const [start, setStart] = useState(
    value ? DateTime.fromISO(value).startOf('month') : DateTime.now().startOf('month')
  )

  const handleSchedule = () => {
    const date = DateTime.now().startOf('day').set({
      year: pickedDay?.year,
      month: pickedDay?.month,
      day: pickedDay?.day,
      hour: pickedTime?.hour,
      minute: pickedTime?.minute
    })

    onChange(date.toISO())
    onClose()
  }

  const handleUnschedule = () => {
    onChange(undefined)
    onClose()
  }

  const handlePg = (pg: 'next' | 'prev' | 'today') => {
    if (pg === 'today') {
      return setStart(DateTime.now().startOf('month'))
    }
    if (pg === 'next') {
      return setStart(start.plus({ months: 1 }))
    }
    return setStart(start.minus({ months: 1 }))
  }

  useEffect(() => {
    if (!value) {
      setPickedDay(undefined)
      setPickedTime(undefined)
      return
    }

    const picked = DateTime.fromISO(value)

    setPickedDay(picked)
    setPickedTime(picked)
  }, [value, isOpen])

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <StyledDateTimeDialog>
        <div className="content-container">
          <div className="day-section">
            <div className="calendar-header">
              <button className="month-btn">
                {start.toFormat('LLLL')} {start.toFormat('yyyy')}
              </button>
              <div className="page-btn-group">
                {(start.year !== DateTime.now().year || start.month !== DateTime.now().month) && (
                  <button className="page-btn" onClick={() => handlePg('today')}>
                    <ArrowUturnLeftIcon />
                  </button>
                )}
                <button className="page-btn" onClick={() => handlePg('prev')}>
                  <ChevronLeftIcon />
                </button>
                <button className="page-btn" onClick={() => handlePg('next')}>
                  <ChevronRightIcon />
                </button>
              </div>
            </div>
            <div className="calendar-week">
              <span>Sun</span>
              <span>Mon</span>
              <span>Tue</span>
              <span>Wed</span>
              <span>Thu</span>
              <span>Fri</span>
              <span>Sat</span>
            </div>
            <div className="calendar">
              {times(DAY_COUNT, (index) => {
                const offset = index - start.weekday
                const day = start.plus({ days: offset })
                const isSelected = !!pickedDay ? dateIsToday(day, pickedDay) : false

                return (
                  <button
                    className="calendar-day"
                    data-other-month={offset < 0 || offset >= start.daysInMonth}
                    data-today={dateIsToday(day)}
                    data-selected={isSelected}
                    key={`${day.month}-${day.day}`}
                    onClick={() => setPickedDay(day)}
                  >
                    <div className="calendar-day-number">{day.toFormat('d')}</div>
                  </button>
                )
              })}
            </div>
          </div>
          <div className="time-section">
            <div className="time-input-container">
              <ClockIcon className="clock-icon" />
              <input
                type="time"
                value={pickedTime?.toFormat('HH:mm')}
                onChange={(e) => setPickedTime(DateTime.fromFormat(e.target.value, 'HH:mm'))}
              />
              <div className="label-group">
                <span className="zone">{DateTime.now().toFormat('ZZZZ')}</span>
                <ChevronDownIcon className="chevron-icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="action-bar">
          <button className="fade-btn" onClick={() => onClose()}>
            Cancel
          </button>
          <div className="btn-group">
            {!!value && (
              <button className="outline-btn" onClick={() => handleUnschedule()}>
                <UnscheduleIcon />
                Unschedule
              </button>
            )}
            <button
              className="solid-btn"
              disabled={!pickedDay || !pickedTime}
              onClick={() => handleSchedule()}
            >
              Schedule
            </button>
          </div>
        </div>
      </StyledDateTimeDialog>
    </Dialog>
  )
}

export default DateTimeDialog
