import { UserType } from '../../types'

export const isPro = (user?: UserType | null) => {
  if (!user) {
    return false
  }

  if (user.stripeSubscriptionStatus === 'Inactive') {
    return false
  }

  if (!!user.trial) {
    return false
  }

  if (user.subscriptionInterval !== 'Year' && user.subscriptionInterval !== 'Month') {
    return false
  }

  return true
}
