import styled from 'styled-components'

const StyledDesignerToolbar = styled.div`
  height: 48px;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 0 6px;
  background-color: ${({ theme }) => theme.color.background};
  box-shadow: 0 1px ${({ theme }) => theme.color.foreground}05,
    0 1px 6px ${({ theme }) => theme.color.foreground}10;
`

export default StyledDesignerToolbar
