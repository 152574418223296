import styled from 'styled-components'

const StyledDesignerTextPanel = styled.div`
  .text-options {
    display: grid;
    gap: 6px;
    padding: 0 20px;
    margin-top: 20px;

    button {
      text-align: left;
      padding: 14px;
      border-radius: 4px;
      transition: 0.2s;
      background-color: #ffffff15;
      color: #ffffff;

      &:hover {
        background-color: #ffffff20;
      }
    }
  }
`

export default StyledDesignerTextPanel
