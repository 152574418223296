import styled from 'styled-components'

type StyledProps = {
  isOwned?: boolean
  isCompleted?: boolean
}

const StyledAcademyCourseListItem = styled.div<StyledProps>`
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 1px 3px rgb(0, 0, 0, 0.1);
  display: grid;
  align-content: space-between;
  transition: 0.2s;

  .course-preview-img {
    padding-top: 56.25%;
    width: 100%;
    position: relative;
    flex-shrink: 0;
    pointer-events: none;
    user-select: none;
    background-color: ${({ theme }) => theme.color.gray[100]};

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    ${({ isOwned }) =>
      !isOwned &&
      `
        img {
          opacity: 0.5;
          filter: grayscale(1);
        }

        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          height: 36px;
          color: white;
          filter: drop-shadow(0px 2px 1px rgb(0, 0, 0, 0.3));
        }
    `}
  }

  .course-details {
    padding: 16px;

    .course-title {
      font-weight: 500;
      display: block;
      color: ${({ theme }) => theme.color.gray[700]};
    }

    .course-description {
      display: block;
      margin-top: 10px;
      color: ${({ theme }) => theme.color.gray[500]};
      display: -webkit-box;
      -webkit-line-clamp: 3;
      overflow: hidden;
      text-align: left;
      font-size: 15px;
      -webkit-box-orient: vertical;
    }

    .course-stats {
      display: flex;
      margin-top: 10px;
      align-items: center;

      span {
        display: flex;
        border-radius: 50rem;
        color: ${({ theme }) => theme.color.gray[500]};
        padding: 4px 8px;
        font-size: 15px;
        background-color: ${({ theme }) => theme.color.gray[100]};

        &:not(:last-child) {
          margin-right: 6px;
        }
      }
    }
  }

  .course-actions-container {
    padding: 0 16px 16px 16px;

    .course-actions {
      a {
        text-decoration: none;

        button {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 500;
          height: 42px;
          border-radius: 4px;
          padding: 0 10px;
          width: 100%;
          background-color: transparent;
          color: ${({ theme }) => theme.color.gray[600]};
          border: 1px solid ${({ theme }) => theme.color.gray[300]};

          svg {
            height: 20px;
          }
        }
      }
    }

    .learn-more-btn {
      display: flex;
      justify-content: center;
      margin-top: 8px;
      text-align: center;
      color: ${({ theme }) => theme.color.gray[600]};
    }
  }

  &::after {
    content: '';
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    box-shadow: inset 0 0 0 1px rgb(0, 0, 0, 0.1);
  }

  &:hover {
    cursor: pointer;

    &::after {
      box-shadow: inset 0 0 0 1px rgb(0, 0, 0, 0.2);
    }
  }
`

export default StyledAcademyCourseListItem
