import styled from 'styled-components'

const StyledContextDialog = styled.div`
  width: 100vw;
  box-sizing: border-box;

  .dialog-image {
    position: relative;
    display: flex;
    pointer-events: none;
    user-select: none;

    img {
      width: 100%;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      box-shadow: inset 0 -1px ${({ theme }) => theme.color.foreground}10;
    }
  }

  .dialog-content {
    padding: 20px 22px;
    line-height: 22px;
    color: ${({ theme }) => theme.color.gray[700]};

    a {
      color: ${({ theme }) => theme.color.gray[500]};
      font-weight: 400;
    }
  }

  .dialog-actions {
    padding: 0 22px 20px 22px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    max-width: 325px;
  }
`

export default StyledContextDialog
