import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { StorySetType } from '../../types'

type InitialState = {
  stories: StorySetType[]
  page: number
  loading: boolean
  totalDocs: number
}

const initialState: InitialState = {
  stories: [],
  page: 0,
  loading: true,
  totalDocs: 0
}

export const storiesSlice = createSlice({
  name: 'stories',
  initialState,
  reducers: {
    setStories: (state, action: PayloadAction<StorySetType[]>) => {
      state.stories = action.payload
      state.loading = false
    },
    addStory: (state, action: PayloadAction<StorySetType>) => {
      state.stories = state.stories.concat([action.payload])
    },
    updateStory: (state, action: PayloadAction<StorySetType>) => {
      state.stories = state.stories.map((story) => {
        if (story._id === action.payload._id) {
          return action.payload
        }

        return story
      })
    },
    removeStory: (state, action: PayloadAction<string>) => {
      state.stories = state.stories.filter((story) => story._id !== action.payload)
    },
    clearStories: (state) => {
      state.stories = []
      state.loading = true
      state.page = 0
    },
    changeStoriesPage: (state, action: PayloadAction<number>) => {
      state.loading = true
      state.page = action.payload
    },
    changeStoriesTotalDocs: (state, action: PayloadAction<number>) => {
      state.totalDocs = action.payload
    }
  }
})

export const {
  setStories,
  addStory,
  updateStory,
  removeStory,
  clearStories,
  changeStoriesPage,
  changeStoriesTotalDocs
} = storiesSlice.actions
export default storiesSlice.reducer
