import { gql } from '@apollo/client'

export const fetchWorkspaceInvite = gql`
  query FetchWorkspaceInvite($wid: String!, $uid: String!, $tid: String!) {
    fetchWorkspaceInvite(wid: $wid, uid: $uid, tid: $tid) {
      workspace {
        _id
        name
        avatar
      }
      user {
        displayName
      }
    }
  }
`
