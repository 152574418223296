import styled from 'styled-components'

const StyledChatMessages = styled.div`
  overflow-y: auto;
  height: 100%;
  box-sizing: border-box;
  display: grid;
  padding: 7px 0 14px 0;
  align-content: start;

  .prompt-list {
    display: grid;
    gap: 8px;
    margin-top: 10px;
    margin-left: 66px;
    margin-right: 14px;

    button {
      height: 40px;
      border-radius: 8px;
      background-color: ${({ theme }) => theme.color.brandLight};
      color: ${({ theme }) => theme.color.brand};
      width: 100%;
      box-sizing: border-box;
      padding: 0 10px;
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 15px;
      position: relative;

      svg {
        height: 26px;
        margin-right: 10px;
      }

      .chevron-icon {
        margin: 0;
        position: absolute;
        right: 5px;
        stroke-width: 1.65px;
        height: 24px;
      }

      &.all-prompts-btn {
        background-color: ${({ theme }) => theme.color.gray[100]};
        color: ${({ theme }) => theme.color.gray[500]};
        justify-content: center;

        svg {
          height: 20px;
          margin-right: 6px;
        }
      }
    }
  }
`

export default StyledChatMessages
