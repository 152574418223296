export const day4 = {
  _id: 'day4',
  title: 'DAY 4',
  tasks: [
    {
      label: 'Sign in to your Social Curator account',
      steps: [
        {
          label: "Use the same email & password you've used before"
        }
      ]
    },
    {
      label: 'Complete Dottie onboarding',
      steps: [
        {
          label: 'Follow the steps to complete onboarding with Dottie'
        }
      ]
    },
    {
      label: 'Use Dottie to create a caption',
      steps: []
    }
  ],
  form: {
    url: 'https://notionforms.io/forms/day-4-feedback',
    keys: {
      FULL_NAME: '26cee997-0b7a-474e-a2ce-ab9b58664dd2',
      EMAIL: 'bf7f2615-b6ad-4ea8-8ede-54f12374f75b',
      SCREEN_RECORDING: '2183bf47-388a-4878-ba84-e80f6b2a61f8',
      USER_ID: '0e0fb071-0903-410b-9db7-cebd01bc5b6f'
    }
  }
}
