import { FC } from 'react'
import { CloudContentType } from '../../../types'
import { linkToSizedImage } from '../../../utils'
import { CloudMediaPlayer, StyledCloudMediaPreview } from '.'

type Props = {
  url: string
  type: CloudContentType['type']
  mediaWidth?: number
  preview?: string
  isInteractive?: boolean
}

const CloudMediaPreview: FC<Props> = ({ url, type, preview, mediaWidth, isInteractive }) => {
  return (
    <StyledCloudMediaPreview className="media-preview">
      {type !== 'video' && <img src={linkToSizedImage(url, { width: mediaWidth || 800 })} alt="" />}
      {type === 'video' && (
        <CloudMediaPlayer url={url} preview={preview} isInteractive={isInteractive} />
      )}
    </StyledCloudMediaPreview>
  )
}

export default CloudMediaPreview
