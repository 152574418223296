import styled from 'styled-components'

type StyledProps = {
  isActive?: boolean
}

const StyledNavButton = styled.button<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 42px;
  width: 42px;
  border-radius: 6px;
  background-color: transparent;
  color: ${({ theme }) => theme.color.gray[400]};

  svg {
    height: 36px;
    width: 36px;
  }

  ${({ isActive, theme }) =>
    isActive &&
    `
    color: ${theme.color.gray[900]};
  `}
`

export default StyledNavButton
