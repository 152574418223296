import { FC, createRef, useContext, useEffect, useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { updateCreatePost, useAppDispatch, useAppSelector } from '../../../redux'
import { hydrateSocialProfiles, userStore } from '../../../utils'
import { StyledCreateEditorCaptionTextarea } from '.'

const CreateEditorCaptionTextarea: FC = () => {
  const dummyRef = createRef<HTMLDivElement>()
  const dispatch = useAppDispatch()
  const { user } = useContext(userStore)
  const { post } = useAppSelector((state) => state.create)
  const [HTMLString, setHTMLString] = useState('')
  const [scroll, setScroll] = useState(0)

  const buildHTMLString = (value: string) => {
    const profile = hydrateSocialProfiles(post?.socialProfiles, user)[0]

    const hashtagRegex = /(?!\s*#\w+\s*<\/a>)(#(\w+))/g
    const mentionRegex = /(?!\s*@\w+\s*<\/a>)(@(\w+))/g

    let hashtagString = '<a class="hashtag" spellcheck="false" data-disabled>$1</a>'
    let mentionString = '<a class="mention" spellcheck="false" data-disabled>$1</a>'

    if (profile?.type === 'instagram') {
      hashtagString =
        '<a class="hashtag" target="_blank" spellcheck="false" href="https://www.instagram.com/explore/tags/$2/">$1</a>'
      mentionString =
        '<a class="mention" target="_blank" spellcheck="false" href="https://www.instagram.com/$2/">$1</a>'
    }

    if (profile?.type === 'facebook') {
      hashtagString =
        '<a class="hashtag" target="_blank" spellcheck="false" href="https://www.facebook.com/hashtag/$2">$1</a>'
      mentionString =
        '<a class="mention" target="_blank" spellcheck="false" href="https://www.facebook.com/$2">$1</a>'
    }

    if (profile?.type === 'twitter') {
      hashtagString =
        '<a class="hashtag" target="_blank" spellcheck="false" href="https://twitter.com/hashtag/$2">$1</a>'
      mentionString =
        '<a class="mention" target="_blank" spellcheck="false" href="https://twitter.com/$2">$1</a>'
    }

    if (profile?.type === 'linkedin') {
      hashtagString =
        '<a class="hashtag" target="_blank" spellcheck="false" href="https://www.linkedin.com/feed/hashtag/?keywords=$2">$1</a>'
      mentionString =
        '<a class="mention" target="_blank" spellcheck="false" href="https://www.linkedin.com/in/$2">$1</a>'
    }

    return value.replaceAll(hashtagRegex, hashtagString).replaceAll(mentionRegex, mentionString)
  }

  // useEffect(() => {
  //   const dummy = dummyRef.current

  //   if (!dummy) {
  //     return
  //   }

  //   dummy.scrollTop = scroll
  // }, [dummyRef, scroll])

  useEffect(() => {
    setHTMLString(buildHTMLString(post?.captionText || ''))
  }, [post?.captionText, post?.socialProfiles])

  return (
    <StyledCreateEditorCaptionTextarea>
      <div className="dummy-text-overlay">
        <div
          className="dummy-text"
          dangerouslySetInnerHTML={{
            __html: HTMLString
          }}
          ref={dummyRef}
          style={{ top: `-${scroll}px` }}
        />
      </div>
      <TextareaAutosize
        id="create-caption-area"
        value={post?.captionText || ''}
        className="textarea"
        minRows={4}
        maxRows={10}
        onChange={(e) => dispatch(updateCreatePost({ captionText: e.target.value }))}
        onScroll={(e) => setScroll(e.currentTarget.scrollTop)}
      />
    </StyledCreateEditorCaptionTextarea>
  )
}

export default CreateEditorCaptionTextarea
