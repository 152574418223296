export const typeface = {
  sans: '\'Public Sans\', \'SF Pro\', ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", Helvetica, Arial, sans-serif',
  polySans:
    '\'PolySans\', ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif',
  sansSerif:
    '\'New York\', ui-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", serif',
  monospace:
    '\'JetBrains Mono\', \'SF Mono\', ui-monospace, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", monospace',
  faktum:
    '\'Faktum\', ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", Helvetica, Arial, sans-serif',
  faktumXCon:
    '\'Faktum XCon\', ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", Helvetica, Arial, sans-serif',
  garnett:
    '\'Garnett\', ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", Helvetica, Arial, sans-serif',
  // LINK PAGE
  publicaSans:
    '\'PublicaSans\', ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", Helvetica, Arial, sans-serif',
  bookerly:
    '\'Bookerly\', ui-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", Helvetica, Arial, serif',
  jetBrainsMono:
    '\'JetBrains Mono\', ui-monospace, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", monospace',
  playfairDisplay:
    '\'Playfair Display\', ui-monospace, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", monospace',
  capriola:
    '\'Capriola\', ui-monospace, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", monospace'
}
