import styled from 'styled-components'

const StyledChatInput = styled.label`
  padding: 0 14px 14px 14px;
  position: relative;

  .chat-input-magic-indicator {
    position: absolute;
    bottom: calc(100% + 1px);
    background-color: ${({ theme }) => theme.color.background};
    left: 0;
    right: 0;
    width: 100%;
    padding: 20px 10px;
    box-sizing: border-box;
    text-align: center;

    .chat-input-magic-indicator-title {
      font-weight: 600;
      font-family: ${({ theme }) => theme.typeface.faktumXCon};
      color: ${({ theme }) => theme.color.brand};
      font-size: 22px;
      display: block;
    }

    .chat-input-magic-indicator-body {
      color: ${({ theme }) => theme.color.brand};
      margin-top: 6px;
      display: block;

      a {
        color: ${({ theme }) => theme.color.brand};
      }
    }

    svg {
      height: 40px;
      margin-top: 20px;
      color: ${({ theme }) => theme.color.brand};
    }
  }

  .chat-input-container {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.color.foreground}15;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.color.background};

    .chat-reply {
      padding: 0 4px 0 14px;
      border-radius: 6px 6px 0 0;
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid ${({ theme }) => theme.color.gray[200]};
      background-color: ${({ theme }) => theme.color.gray[100]};

      .chat-reply-label {
        display: flex;
        align-items: center;
        color: ${({ theme }) => theme.color.gray[600]};
        font-weight: 500;
        font-size: 14px;

        svg {
          height: 17px;
          position: relative;
          stroke-width: 2.4px;
          top: -1px;
          margin-right: 4px;
        }
      }

      button {
        background-color: transparent;
        color: ${({ theme }) => theme.color.gray[400]};

        svg {
          height: 24px;
          width: 24px;
        }

        &:hover {
          color: ${({ theme }) => theme.color.gray[600]};
        }
      }
    }

    .chat-input {
      padding: 14px;

      textarea {
        resize: none;
        background-color: transparent;
        width: 100%;
        box-sizing: border-box;
        font-size: 17px;
        line-height: 22px;
        color: ${({ theme }) => theme.color.gray[800]};

        &::placeholder {
          user-select: none;
          color: ${({ theme }) => theme.color.gray[400]};
        }
      }

      .chat-input-actions {
        display: flex;
        padding-top: 14px;
        align-items: center;
        justify-content: space-between;

        .chat-input-actions-group {
          display: flex;
          align-items: center;
        }

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          height: 34px;
          width: 34px;
          padding: 0;
          border-radius: 6px;
          cursor: pointer;
          transition: 0.2s;
          background-color: ${({ theme }) => theme.color.background};
          color: ${({ theme }) => theme.color.gray[400]};

          svg {
            height: 20px;
            flex-shrink: 0;
          }

          &:not(:last-child) {
            margin-right: 0px;
          }

          &:first-child {
            margin-right: 4px;
          }

          &:hover {
            color: ${({ theme }) => theme.color.gray[600]};
          }

          &.chat-template-select {
            position: relative;
            transition: unset;
            background-color: ${({ theme }) => theme.color.brandLight};
            color: ${({ theme }) => theme.color.brand};

            .border-gradient {
              border-radius: 50px;
              border: 2px solid transparent;
              background: linear-gradient(
                  25deg,
                  ${({ theme }) => theme.color.brand},
                  ${({ theme }) => theme.color.blue[500]}
                )
                border-box;
              -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
              -webkit-mask-composite: xor;
              mask-composite: exclude;
              position: absolute;
              left: 0;
              right: 0;
              bottom: 0;
              height: calc(100% - 4px);
              width: calc(100% - 4px);
              border-radius: 6px;
              display: none;
            }

            .template-name {
              max-width: 0;
              white-space: nowrap;
              overflow: hidden;
              transition: 0.2s;
              color: transparent;
            }

            &[data-has-template='true'] {
              background-color: ${({ theme }) => theme.color.background};
              color: ${({ theme }) => theme.color.brand};
              width: fit-content;
              padding: 0 10px;
              border-radius: 20px;
              background: linear-gradient(
                25deg,
                ${({ theme }) => theme.color.brand},
                ${({ theme }) => theme.color.blue[500]}
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;

              .border-gradient {
                border-radius: 50rem;
                display: flex;
              }

              svg {
                margin-right: 8px;
              }

              .template-name {
                max-width: 200px;
              }
            }
          }

          &.send-btn {
            margin-right: -4px;

            &:focus {
              box-shadow: 0 0 0 3px ${({ theme }) => theme.color.background},
                0 0 0 6px ${({ theme }) => theme.color.brand};
            }

            &:disabled {
              cursor: not-allowed;

              &:hover {
                color: ${({ theme }) => theme.color.gray[400]};
              }
            }

            &:not(:disabled) {
              color: ${({ theme }) => theme.color.brand};
            }
          }

          &[data-active='true'] {
            color: ${({ theme }) => theme.color.brand};
          }
        }
      }
    }

    &:focus-within {
      box-shadow: 0 0 0 1px ${({ theme }) => theme.color.foreground}20,
        0 2px 4px ${({ theme }) => theme.color.foreground}10;
    }
  }
`

export default StyledChatInput
