import styled from 'styled-components'

const StyledConnectionSidebar = styled.div`
  background-color: ${({ theme }) => theme.color.gray[100]};
  border-right: 1px solid ${({ theme }) => theme.color.gray[300]};
  overflow-y: auto;
  display: grid;
  user-select: none;
  cursor: default;
  align-content: space-between;

  .sidebar-top,
  .sidebar-bottom {
    overflow: hidden;
  }

  .sidebar-top {
    overflow-y: auto;

    &::-webkit-scrollbar {
      height: 0;
      position: absolute;
      width: 16px;
    }

    &::-webkit-scrollbar-thumb {
      border: 4px solid ${({ theme }) => theme.color.gray[100]};
      background: ${({ theme }) => theme.color.foreground}10;
      border-radius: 50rem;

      &:hover {
        background: ${({ theme }) => theme.color.foreground}20;
      }
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      border: solid 3px transparent;
      width: 8px;
    }
  }

  .sidebar-bottom {
    padding: 14px;
    border-top: 1px solid ${({ theme }) => theme.color.gray[300]};
  }

  .sidebar-group {
    padding: 14px;

    .sidebar-group-label {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      color: ${({ theme }) => theme.color.gray[600]};

      svg {
        height: 20px;
        margin-right: 6px;
        stroke-width: 2px;
      }
    }

    .sidebar-group-profile-list {
      padding: 0;
      margin: 14px 0 0 0;
      list-style-type: none;
      display: grid;
      gap: 8px;
      user-select: none;
    }

    &:not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
    }

    &.expired {
      background-color: ${({ theme }) => theme.color.red[100]};

      .sidebar-group-label {
        color: ${({ theme }) => theme.color.red[800]};
      }

      &:not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.color.red[300]};
      }
    }
  }

  .connection-limits {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;

    .connection-limit {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: ${({ theme }) => theme.color.gray[600]};

      svg {
        height: 16px;
        margin-right: 8px;
      }
    }
  }
`

export default StyledConnectionSidebar
