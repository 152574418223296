import styled from 'styled-components'

const StyledHomeLevel = styled.div`
  .level-icon {
    height: 38px;
    width: 38px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.color.brand};
    color: white;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;

    svg {
      height: 16px;
    }
  }

  .level-details {
    width: 100%;

    .level-label {
      font-weight: 600;
      font-size: 15px;
      display: flex;
      align-items: center;
      width: fit-content;
      cursor: help;
      color: ${({ theme }) => theme.color.gray[800]};

      svg {
        height: 18px;
        margin-left: 3px;
        color: ${({ theme }) => theme.color.gray[400]};
      }
    }

    .level-progress {
      font-size: 13px;
      font-weight: 500;
      margin-top: 2px;
      display: flex;
      align-items: center;

      .level-post-count {
        flex-shrink: 0;
        color: ${({ theme }) => theme.color.brand};
      }

      progress {
        width: 100%;
        overflow: hidden;
        border-radius: 50rem;
        height: 4px;
        margin: 0 10px;

        &::-webkit-progress-bar {
          background-color: ${({ theme }) => theme.color.gray[200]};
          border-radius: 50rem;
        }

        &::-webkit-progress-value {
          background-color: ${({ theme }) => theme.color.brand};
          transition: 0.2s;
          border-radius: 50rem;
        }
      }

      .level-post-limit {
        flex-shrink: 0;
        color: ${({ theme }) => theme.color.gray[500]};

        svg {
          height: 20px;
        }
      }
    }
  }
`

export default StyledHomeLevel
