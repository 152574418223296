import { FC, createRef, useState } from 'react'
import classNames from 'classnames'
import { ArrowDownTrayIcon, EllipsisHorizontalIcon, PlusIcon } from '@heroicons/react/24/outline'
import { PaintBrushIcon } from '@heroicons/react/24/solid'
import {
  setDesignerMediaId,
  setDesignerTemplate,
  setDesignerTitle,
  setShowDesigner,
  useAppDispatch
} from '../../../redux'
import { CloudContentType } from '../../../types'
import { useCloud } from '../../../utils'
import { AddToPostDialog, CloudMediaPreview, OptionsPopover } from '../..'
import { StyledDesignGridItem } from '.'

type Props = {
  design: CloudContentType
}

const DesignGridItem: FC<Props> = ({ design }) => {
  const dispatch = useAppDispatch()
  const optionsBtnRef = createRef<HTMLButtonElement>()
  const [showOptions, setShowOptions] = useState(false)
  const [showAddToPostDialog, setShowAddToPostDialog] = useState(false)
  const { downloadCloudFile } = useCloud()

  const handleDownload = async () => {
    await downloadCloudFile(design)
  }

  const handleOpenDesigner = () => {
    dispatch(setShowDesigner(true))
    dispatch(setDesignerMediaId(design._id))
    dispatch(setDesignerTemplate(design.file?.metadata?.design))
    dispatch(setDesignerTitle(design.name))
  }

  return (
    <>
      <StyledDesignGridItem onClick={() => handleOpenDesigner()}>
        <div className="design-preview">
          <CloudMediaPreview url={design.file?.url || ''} type={design.file?.type || ''} />
          <button
            className={classNames('options-btn', `design-${design._id}`)}
            ref={optionsBtnRef}
            onClick={(e) => {
              e.stopPropagation()
              setShowOptions(!showOptions)
            }}
          >
            <EllipsisHorizontalIcon />
          </button>
        </div>
        <div className="design-details">
          <span className="design-title">{design.name || 'Untitled Design'}</span>
          <span className="design-status">Created 4 days ago</span>
        </div>
      </StyledDesignGridItem>
      <OptionsPopover
        options={[
          {
            icon: <PaintBrushIcon />,
            label: `Open in Designer`,
            action: () => handleOpenDesigner()
          },
          {
            icon: <PlusIcon />,
            label: 'Add to post',
            action: () => setShowAddToPostDialog(true)
          },
          {
            icon: <ArrowDownTrayIcon />,
            label: `Download ${design.type}`,
            action: () => handleDownload()
          }
        ]}
        classToAvoid={`design-${design._id}`}
        buttonRef={optionsBtnRef}
        isOpen={showOptions}
        onClose={() => setShowOptions(false)}
      />
      <AddToPostDialog
        photo={design}
        isOpen={showAddToPostDialog}
        onClose={() => setShowAddToPostDialog(false)}
      />
    </>
  )
}

export default DesignGridItem
