import { useContext } from 'react'
import { toast } from 'react-hot-toast'
import { useApolloClient } from '@apollo/client'
import { createWorkspace as createWorkspaceMutation } from '../../graphql'
import {
  clearActionPlans,
  clearCaptions,
  clearCreatePost,
  clearHashtags,
  clearPhotos,
  clearPosts,
  setWorkspace,
  useAppDispatch
} from '../../redux'
import { userStore } from '..'

export const useWorkspace = () => {
  const dispatch = useAppDispatch()
  const apollo = useApolloClient()
  const { refresh } = useContext(userStore)

  const createWorkspace = async (name: string) => {
    try {
      const { data } = await apollo.mutate({
        fetchPolicy: 'no-cache',
        mutation: createWorkspaceMutation,
        variables: {
          name
        }
      })
      await refresh()

      return data.createWorkspace
    } catch (err) {
      console.error(err)
      toast.error((err as any).message)
    }
  }

  const changeWorkspace = (_id?: string) => {
    dispatch(clearCreatePost())
    dispatch(setWorkspace(_id))
    dispatch(clearHashtags())
    dispatch(clearPosts())
    // dispatch(clearCaptions())
    // dispatch(clearActionPlans())
    // dispatch(clearPhotos())
  }

  return {
    createWorkspace,
    changeWorkspace
  }
}
