import styled from 'styled-components'

const StyledChatTooltip = styled.button`
  position: absolute;
  margin-left: -65px;
  width: 130px;
  background-color: ${({ theme }) => theme.color.background};
  box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.2), 0px 0px 0px 1px rgba(0, 0, 0, 0.075),
    0px 2px 3px rgba(0, 0, 0, 0.1);
  border: none;
  user-select: none;
  color: ${({ theme }) => theme.color.brand};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 500;

  span {
    background: linear-gradient(
      25deg,
      ${({ theme }) => theme.color.brand},
      ${({ theme }) => theme.color.blue[500]}
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  svg {
    height: 20px;
    stroke-width: 2px;
    margin-right: 6px;
    margin-left: -4px;
  }

  .border-gradient {
    border-radius: 50px;
    border: 2px solid transparent;
    background: linear-gradient(
        25deg,
        ${({ theme }) => theme.color.brand},
        ${({ theme }) => theme.color.blue[500]}
      )
      border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: calc(100% - 4px);
    width: calc(100% - 4px);
    border-radius: 6px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.brandLight};
  }
`

export default StyledChatTooltip
