import { xor } from 'lodash'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { CaptionType } from '../../types'

type InitialState = {
  captions: CaptionType[]
  search: string
  categories: string[]
  sort: 'for-you' | 'added' | 'suggested'
  liked: boolean
  hideUsed: boolean
  page: number
  loading: boolean
  totalDocs: number
  caption?: string
}

const initialState: InitialState = {
  captions: [],
  categories: [],
  hideUsed: false,
  loading: true,
  search: '',
  liked: false,
  sort: 'for-you',
  page: 0,
  totalDocs: 0
}

export const captionsSlice = createSlice({
  name: 'captions',
  initialState,
  reducers: {
    setCaptions: (state, action: PayloadAction<CaptionType[]>) => {
      state.captions = action.payload
      state.loading = false
    },
    addCaption: (state, action: PayloadAction<CaptionType>) => {
      state.captions = state.captions.concat([action.payload])
    },
    updateCaption: (state, action: PayloadAction<CaptionType>) => {
      state.captions = state.captions.map((caption) => {
        if (caption._id === action.payload._id) {
          return action.payload
        }

        return caption
      })
    },
    removeCaption: (state, action: PayloadAction<string>) => {
      state.captions = state.captions.filter((caption) => caption._id !== action.payload)
    },
    setCaptionsLiked: (state, action: PayloadAction<{ isLiked: boolean; _id: string }>) => {
      state.captions = state.captions.map((caption) => {
        if (caption._id === action.payload._id) {
          return {
            ...caption,
            userCaption: {
              ...caption.userCaption!,
              liked: action.payload.isLiked
            }
          }
        }

        return caption
      })
    },
    setCaptionsUsed: (state, action: PayloadAction<{ isUsed: boolean; _id: string }>) => {
      state.captions = state.captions.map((caption) => {
        if (caption._id === action.payload._id) {
          return {
            ...caption,
            userCaption: {
              ...caption.userCaption!,
              used: action.payload.isUsed
            }
          }
        }

        return caption
      })
    },
    clearCaptions: (state) => {
      state.captions = []
      state.loading = true
      state.page = 0
    },
    setCaptionsQueryState: (state, action: PayloadAction<Partial<InitialState>>) => {
      return (state = {
        ...state,
        ...action.payload,
        captions: [],
        loading: true
      })
    },
    setCaptionsQuery: (state, action: PayloadAction<string>) => {
      state.search = action.payload
      state.caption = ''
      state.captions = []
      state.loading = true
      state.page = 0
    },
    setCaptionsUsedFilter: (state, action: PayloadAction<boolean>) => {
      state.hideUsed = action.payload
      state.caption = ''
      state.captions = []
      state.loading = true
      state.page = 0
    },
    setCaptionsFavoritesFilter: (state, action: PayloadAction<boolean>) => {
      state.liked = action.payload
      state.caption = ''
      state.captions = []
      state.loading = true
      state.page = 0
    },
    toggleCaptionsFilter: (state, action: PayloadAction<string>) => {
      state.categories = xor(state.categories, [action.payload])
      state.caption = ''
      state.captions = []
      state.loading = true
      state.page = 0
    },
    setCaptionsSort: (state, action: PayloadAction<'for-you' | 'added' | 'suggested'>) => {
      state.sort = action.payload
      state.caption = ''
      state.captions = []
      state.loading = true
      state.page = 0
    },
    changeCaptionsPage: (state, action: PayloadAction<number>) => {
      state.loading = true
      state.page = action.payload
    },
    changeCaptionsTotalDocs: (state, action: PayloadAction<number>) => {
      state.totalDocs = action.payload
    }
  }
})

export const {
  setCaptions,
  addCaption,
  updateCaption,
  removeCaption,
  setCaptionsLiked,
  setCaptionsUsed,
  clearCaptions,
  setCaptionsQuery,
  setCaptionsQueryState,
  changeCaptionsPage,
  setCaptionsFavoritesFilter,
  setCaptionsUsedFilter,
  toggleCaptionsFilter,
  setCaptionsSort,
  changeCaptionsTotalDocs
} = captionsSlice.actions
export default captionsSlice.reducer
