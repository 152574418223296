import { FC, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import InfiniteScroll from 'react-infinite-scroll-component'
import { v4 as uuid } from 'uuid'
import { useApolloClient } from '@apollo/client'
import { CheckIcon } from '@heroicons/react/24/outline'
import { loadFilesFromCloud } from '../../../../../graphql'
import { CloudContentType, PostType } from '../../../../../types'
import { CloudMediaPreview } from '../../../../cloud'
import { Spinner } from '../../../../system'
import { StyledUploadsPage } from '.'

type Props = {
  selected: NonNullable<PostType['media']>
  onSelect: (file: NonNullable<PostType['media']>[number]) => void
}

const UploadsPage: FC<Props> = ({ selected, onSelect }) => {
  const apollo = useApolloClient()
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [files, setFiles] = useState<CloudContentType[]>([])

  const loadData = async () => {
    try {
      setLoading(true)
      const { data } = await apollo.query({
        fetchPolicy: 'no-cache',
        query: loadFilesFromCloud,
        variables: {
          page,
          parent: undefined,
          limit: 16,
          types: ['file']
        }
      })

      const docs = data?.loadFilesFromCloud?.docs || []
      const total = data?.loadFilesFromCloud?.totalDocs || 0

      setFiles([...files, ...docs])
      setTotal(total)
    } catch (err) {
      console.log(err)
      toast.error((err as any).message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadData()
  }, [page])

  return (
    <StyledUploadsPage id="uploads-page">
      {loading && page === 0 && (
        <div className="loading-indicator">
          <Spinner />
        </div>
      )}
      <InfiniteScroll
        scrollThreshold={0.7}
        dataLength={files.length}
        next={() => setPage(page + 1)}
        hasMore={files.length < total}
        loader={null}
        scrollableTarget="uploads-page"
        className="infinite-scroll"
      >
        {files.map((file) => {
          const isSelected = !!selected.find((media) => media.ref === file._id)

          return (
            <button
              className="upload-list-item"
              data-selected={isSelected}
              onClick={() =>
                onSelect({
                  _id: uuid(),
                  url: file.file?.url || '',
                  type: file.file?.type as any,
                  uploaded: true,
                  ref: file._id,
                  dimensions: {
                    x: file.file?.dimensions.x || 0,
                    y: file.file?.dimensions.y || 0
                  }
                })
              }
            >
              <div className="upload-list-item-preview">
                {isSelected && <CheckIcon />}
                {!isSelected && (
                  <CloudMediaPreview url={file.file?.url || ''} type={file.file?.type || ''} />
                )}
              </div>
              <div className="upload-list-item-details">
                <span>{file.name}</span>
              </div>
            </button>
          )
        })}
      </InfiniteScroll>
    </StyledUploadsPage>
  )
}

export default UploadsPage
