import { FC, MouseEvent, createRef, useEffect, useState } from 'react'
import { useDroppable } from '@dnd-kit/core'
import { ArrowUpCircleIcon } from '@heroicons/react/20/solid'
import { EllipsisHorizontalIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline'
import { removeCloudFolder, updateCloudFolder, useAppDispatch } from '../../../redux'
import { CloudContentType } from '../../../types'
import { ROUTES, getRelativeString, useCloud } from '../../../utils'
import { ActionDialog, Button, InputDialog, OptionsPopover } from '../..'
import { StyledCloudFolderListItem } from '.'

type Props = {
  folder: CloudContentType
  onClick?: () => void
}

const CloudFolderListItem: FC<Props> = ({ folder, onClick }) => {
  const optionsBtnRef = createRef<HTMLButtonElement>()
  const dispatch = useAppDispatch()
  const [newName, setNewName] = useState('')
  const [showOptions, setShowOptions] = useState(false)
  const [showNameInput, setShowNameInput] = useState(false)
  const [showDeletionConfirmation, setShowDeletionConfirmation] = useState(false)
  const { deleteFromCloud, updateCloudFileData } = useCloud()
  const { isOver, setNodeRef } = useDroppable({
    id: folder._id,
    data: {
      folder
    }
  })

  const handleShowOptions = (e: MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
    setShowOptions(!showOptions)
  }

  const handleRename = () => {
    updateCloudFileData({ ...folder, name: newName })
    dispatch(updateCloudFolder({ ...folder, name: newName }))
  }

  const handleDelete = () => {
    deleteFromCloud([folder._id])
    dispatch(removeCloudFolder(folder._id))
  }

  const handleClick = (e: MouseEvent) => {
    if (onClick) {
      e.stopPropagation()
      e.preventDefault()
      onClick()
    }
  }

  useEffect(() => {
    setNewName(folder.name)
  }, [showNameInput])

  return (
    <>
      <StyledCloudFolderListItem
        isOver={isOver}
        draggable={false}
        to={ROUTES.cloud.pathByPath(folder.name)}
        onClick={(e: MouseEvent) => handleClick(e)}
        ref={setNodeRef}
      >
        {isOver && (
          <div className="cloud-folder-upload-icon">
            <ArrowUpCircleIcon />
          </div>
        )}
        <div className="cloud-folder-details-container">
          <div className="cloud-folder-icon">
            <div className="cloud-folder-photo-count">{folder.fileCount || 0}</div>
            <img src="/images/cloud-folder-icon.png" alt="" />
          </div>
          <div className="cloud-folder-details">
            <span className="cloud-folder-title">{folder.name}</span>
            <span className="cloud-folder-detail">{getRelativeString(folder.createdAt)}</span>
          </div>
        </div>
        <Button
          className={`folder-options-btn folder-options-btn-${folder._id}`}
          isIcon
          ref={optionsBtnRef}
          onClick={(e) => handleShowOptions(e)}
        >
          <EllipsisHorizontalIcon />
        </Button>
      </StyledCloudFolderListItem>
      <OptionsPopover
        options={[
          {
            icon: <PencilIcon />,
            label: `Rename folder`,
            action: () => setShowNameInput(true)
          },
          {
            icon: <TrashIcon />,
            label: `Delete folder`,
            action: () => setShowDeletionConfirmation(true)
          }
        ]}
        classToAvoid={`folder-options-btn-${folder._id}`}
        buttonRef={optionsBtnRef}
        isOpen={showOptions}
        onClose={() => setShowOptions(false)}
      />
      <InputDialog
        isOpen={showNameInput}
        title={`Rename folder`}
        value={newName}
        confirmButtonText="Rename"
        onConfirm={() => handleRename()}
        onChange={(value) => setNewName(value)}
        onClose={() => setShowNameInput(false)}
      />
      <ActionDialog
        title={`Delete folder`}
        body={`Are you sure you want to delete folder?`}
        type="error"
        confirmButtonText="Delete"
        isOpen={showDeletionConfirmation}
        onClose={() => setShowDeletionConfirmation(false)}
        onConfirm={() => handleDelete()}
      />
    </>
  )
}

export default CloudFolderListItem
