import styled from 'styled-components'

type StyledProps = {
  isOver?: boolean
  isActive?: boolean
}

const StyledUploadsFolderListItem = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  user-select: none;

  .folder-details-container {
    display: flex;
    align-items: center;
    padding: 6px;
    border-radius: 6px;
    transition: 0.2s;

    .folder-icon {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      margin-right: 10px;
      transition: color 0.2s;
      color: ${({ theme }) => theme.color.gray[600]};

      svg {
        height: 24px;
      }
    }

    .folder-name {
      color: ${({ theme }) => theme.color.gray[600]};
      transition: color 0.2s;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-align: left;
      -webkit-box-orient: vertical;
    }
  }

  .options-btn {
    display: none;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: ${({ theme }) => theme.color.gray[500]};
    background-color: ${({ theme }) => theme.color.gray[100]};
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-left: 10px;

    svg {
      height: 20px;
      stroke-width: 2px;
    }

    &:hover {
      background-color: ${({ theme }) => theme.color.gray[200]};
    }

    &[data-active='true'] {
      display: flex;
      background-color: ${({ theme }) => theme.color.gray[200]};
    }
  }

  &:hover {
    cursor: pointer;

    .folder-details-container {
      .folder-icon,
      .folder-name {
        color: ${({ theme }) => theme.color.gray[800]};
      }
    }

    .options-btn {
      display: ${({ isOver }) => (isOver ? 'none' : 'flex')};
    }
  }

  ${({ isOver, isActive, theme }) =>
    (isOver || isActive) &&
    `
    .folder-details-container {
      background-color: ${theme.color.brandLight} !important;

      .folder-icon, .folder-name {
        color: ${theme.color.brand} !important;
      }
    }
  `}
`

export default StyledUploadsFolderListItem
