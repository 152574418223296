import styled from 'styled-components'

const StyledDesignerNav = styled.nav`
  width: 80px;
  display: grid;
  align-content: start;
  background: #18191b;
  height: 100%;
  overflow-y: auto;
`

export default StyledDesignerNav
