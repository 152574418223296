import { FC } from 'react'
import { useDraggable } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import { PaperClipIcon } from '@heroicons/react/24/outline'
import { PostType } from '../../../types'
import { CloudMediaPreview } from '../..'
import { StyledCalendarPostListItem } from '.'

type Props = {
  post: PostType
  isOverlay?: boolean
}

const CalendarPostListItem: FC<Props> = ({ post, isOverlay }) => {
  const { attributes, isDragging, listeners, setNodeRef, transform } = useDraggable({
    id: `post-list-${post._id}`,
    data: {
      post: post,
      isSidebarItem: true
    }
  })

  const style = {
    transform: CSS.Translate.toString(transform)
  }

  return (
    <StyledCalendarPostListItem
      // style={style}
      isDragging={isDragging}
      isOverlay={isOverlay}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
    >
      <div className="media-container">
        {!!post.media?.length && (
          <CloudMediaPreview url={post.media[0].url} type={post.media[0].type} />
        )}
        {!post.media?.length && !!post.captionText?.length && (
          <div className="post-text-preview">{post.captionText}</div>
        )}
        {!post.media?.length && !post.captionText?.length && (
          <PaperClipIcon className="empty-icon" />
        )}
      </div>
      <div className="post-title">{post.title || 'Untitled Post'}</div>
    </StyledCalendarPostListItem>
  )
}

export default CalendarPostListItem
