import { FC, useEffect } from 'react'
import { toast } from 'react-hot-toast'
import InfiniteScroll from 'react-infinite-scroll-component'
import { uniqBy } from 'lodash'
import { useApolloClient } from '@apollo/client'
import { generateCaptionFeed } from '../../../../graphql'
import {
  changeCaptionsPage,
  changeCaptionsTotalDocs,
  setCaptions,
  useAppDispatch,
  useAppSelector
} from '../../../../redux'
import { CaptionListItem } from '..'
import { StyledCaptionList } from '.'

const CaptionList: FC = () => {
  const apollo = useApolloClient()
  const dispatch = useAppDispatch()
  const { loading, captions, totalDocs, page, search, liked, categories, sort, hideUsed, caption } =
    useAppSelector((state) => state.captions)

  const loadCaptions = async () => {
    try {
      const { data } = await apollo.query({
        fetchPolicy: 'no-cache',
        query: generateCaptionFeed,
        variables: {
          page,
          search,
          liked,
          categories,
          sort,
          hideUsed,
          items: 15,
          caption
        }
      })

      const captionList = data?.generateCaptionFeed?.docs || []
      const total = data?.generateCaptionFeed?.totalDocs || 0

      dispatch(setCaptions(uniqBy([...captions, ...captionList], '_id')))
      dispatch(changeCaptionsTotalDocs(total))
    } catch (err) {
      console.log(err)
      toast.error((err as any).message)
    }
  }

  useEffect(() => {
    if (loading) {
      loadCaptions()
    }
  }, [loading])

  return (
    <StyledCaptionList>
      <InfiniteScroll
        scrollThreshold={0.7}
        dataLength={captions.length}
        next={() => dispatch(changeCaptionsPage(page + 1))}
        hasMore={captions.length < totalDocs}
        loader={null}
        scrollableTarget="caption-list-scroll"
      >
        {captions.map((caption) => {
          return <CaptionListItem caption={caption} key={caption._id} />
        })}
      </InfiniteScroll>
    </StyledCaptionList>
  )
}

export default CaptionList
