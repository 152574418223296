import { v4 as uuidv4 } from 'uuid'
import { addUploadFiles, setShowUploadManager, useAppDispatch } from '../../redux'
import { UploadType } from '../../types'

export const useUploadManager = () => {
  const dispatch = useAppDispatch()

  const addToUploads = async (
    fileInput: FileList | null | File[],
    addToPost?: boolean,
    parent?: string
  ) => {
    if (!fileInput) {
      return
    }

    const files = Array.isArray(fileInput) ? fileInput : Array.from(fileInput)
    const hydratedFiles = files.map((file) => ({
      _id: uuidv4(),
      status: 'loading',
      type: 'file',
      addToPost: addToPost,
      raw: file,
      parent
    })) as UploadType[]

    dispatch(setShowUploadManager(true))
    dispatch(addUploadFiles(hydratedFiles))
  }

  const uploadDesign = async (
    image: string,
    design: Object,
    name: string,
    oldMediaId?: string,
    addToPost?: boolean
  ) => {
    const file = {
      _id: uuidv4(),
      status: 'loading',
      type: 'design',
      addToPost: addToPost,
      raw: {
        name,
        oldMediaId: oldMediaId,
        size: 0,
        image,
        design
      }
    } as UploadType

    dispatch(setShowUploadManager(true))
    dispatch(addUploadFiles([file]))
  }

  return {
    addToUploads,
    uploadDesign
  }
}
