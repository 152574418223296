import styled from 'styled-components'

const StyledHashtagFolderEdit = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr) auto;

  .hashtag-folder-edit-header {
    display: flex;
    align-items: center;
    font-weight: 500;
    padding: 0 20px;
    user-select: none;
    height: 60px;
    justify-content: space-between;
    color: ${({ theme }) => theme.color.gray[800]};
    border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
  }

  .form {
    overflow-y: auto;
    padding: 20px;
    box-sizing: border-box;
    display: grid;
    align-content: start;
    grid-gap: 24px;

    .hashtag-list {
      padding: 12px 8px 8px 12px;
      border-radius: 6px;
      min-height: 150px;
      box-sizing: border-box;
      border: 1px dashed ${({ theme }) => theme.color.gray[300]};
      background-color: ${({ theme }) => theme.color.gray[100]};

      button {
        padding: 6px;
        margin: 0 4px 4px 0;
        border-radius: 4px;
        font-size: 15px;
        transition: 0.2s;
        display: inline-flex;
        align-items: center;
        box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
          0px 2px 3px rgba(0, 0, 0, 0.05);
        color: ${({ theme }) => theme.color.gray[800]};
        background-color: ${({ theme }) => theme.color.background};

        svg {
          height: 16px;
          stroke-width: 2px;
          margin: 0 -2px 0 4px;
          color: ${({ theme }) => theme.color.gray[600]};
        }

        &:hover {
          color: ${({ theme }) => theme.color.red[800]};
          box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.2), 0px 0px 0px 1px rgba(0, 0, 0, 0.075),
            0px 2px 3px rgba(0, 0, 0, 0.1);

          svg {
            color: ${({ theme }) => theme.color.red[800]};
          }
        }
      }
    }

    hr {
      border: 0;
      height: 1px;
      margin: 0;
      background-color: ${({ theme }) => theme.color.gray[300]};
      width: 100%;
    }

    label,
    .input-container {
      display: grid;
      grid-gap: 8px;
      cursor: default;

      .input-label {
        font-weight: 500;
        user-select: none;
        font-size: 15px;
        color: ${({ theme }) => theme.color.gray[600]};
      }

      .input-subtext {
        font-size: 14px;
        user-select: none;
        color: ${({ theme }) => theme.color.gray[500]};
      }

      textarea {
        background-color: transparent;
        resize: none;
        border-radius: 4px;
        padding: 12px;
        box-sizing: border-box;
        transition: border 0.2s;
        border: 1px solid ${({ theme }) => theme.color.gray[300]};
        color: ${({ theme }) => theme.color.gray[800]};

        &::placeholder {
          color: ${({ theme }) => theme.color.gray[400]};
        }

        &:hover,
        &:focus {
          border: 1px solid ${({ theme }) => theme.color.gray[400]};
        }
      }
    }
  }

  .hashtag-folder-edit-actions {
    padding: 20px;
    display: flex;

    .save-btn {
      width: 100%;
      margin-left: 8px;
    }
  }
`

export default StyledHashtagFolderEdit
