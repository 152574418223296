import styled from 'styled-components'

const StyledCardInput = styled.label`
  display: block;
  width: 100%;
  min-height: 60px;

  .card-input {
    padding: 0 12px;
    height: 60px;
    border: 1px solid ${({ theme }) => theme.color.gray[400]};
    display: flex;
    align-items: center;
    border-radius: 6px;
    transition: 0.2s;
    cursor: text;
    width: 100%;
    box-sizing: border-box;

    .__PrivateStripeElement {
      width: 100%;
    }

    &.error {
      border: 1px solid ${({ theme }) => theme.color.red[800]};
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.red[800]};
    }

    &.complete {
      border: 1px solid ${({ theme }) => theme.color.green[600]};
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.green[600]};
    }

    &.focus {
      border: 1px solid ${({ theme }) => theme.color.brand};
      box-shadow: 0 0 0 4px ${({ theme }) => theme.color.blue[200]};
    }
  }
`

export default StyledCardInput
