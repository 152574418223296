import styled, { keyframes } from 'styled-components'

const checkAnim = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`

type Props = {
  isSelected?: boolean
}

const StyledBubble = styled.button<Props>`
  border-radius: 50rem;
  padding: 0 14px;
  height: 36px;
  flex-shrink: 0;
  display: flex;
  position: relative;
  align-items: center;
  white-space: nowrap;
  background-color: ${({ theme }) => theme.color.gray[100]};
  color: ${({ theme }) => theme.color.gray[500]};
  transition: 0.2s color, 0.2s background-color, 0.2s padding;

  svg {
    height: 20px;
    left: 10px;
    position: absolute;

    path {
      stroke-width: 2.2px;
      stroke-dasharray: 1000;
      stroke-dashoffset: 1000;
      animation: ${checkAnim} 15s linear forwards;
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.gray[200]};
    color: ${({ theme }) => theme.color.gray[600]};
  }

  ${({ isSelected, theme }) =>
    isSelected &&
    `
    padding-left: 36px;
    background-color: ${theme.color.brandLight};
    color: ${theme.color.brand};

    &:hover {
      background-color: ${theme.color.brandLight};
      color: ${theme.color.brand};
    }
  `}
`

export default StyledBubble
