import { gql } from '@apollo/client'

export const affiliateEarningFindBetweenDates = gql`
  query AffiliateEarningFindBetweenDates(
    $affiliate: String!
    $item: FilterFindManyAffiliateEarningItemInput
    $greaterThanDate: Date
    $lessThanDate: Date
  ) {
    affiliateEarningFindMany(
      filter: {
        affiliate: $affiliate
        item: $item
        OR: [
          { _operators: { userPaidDate: { gte: $greaterThanDate, lte: $lessThanDate } } }
          { _operators: { trialStartDate: { gte: $greaterThanDate, lte: $lessThanDate } } }
        ]
      }
    ) {
      _id
      user
      affiliate
      amount
      item {
        _id
        cost
        type
      }
      trialStartDate
      userPaidDate
    }
  }
`
