import { createRef, FC, useContext, useState } from 'react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { SocialProfilePopover } from '../../../../popovers'
import { getProfileListString, userStore } from '../../../../../utils'
import { Avatar } from '../../../../system'
import { SocialProfileType } from '../../../../../types'
import { StyledPublishWhereField } from '.'

type Props = {
  profiles: SocialProfileType[]
  onSelect: (profile: SocialProfileType) => void
}

const PublishWhereField: FC<Props> = ({ profiles, onSelect }) => {
  const selectBtnRef = createRef<HTMLButtonElement>()
  const { user } = useContext(userStore)
  const [showSocialProfilePopover, setShowSocialProfilePopover] = useState(false)

  const handleSelect = (_id: string) => {
    const profile = user?.socialProfiles.find((profile) => profile._id === _id)

    if (!profile) {
      return
    }

    onSelect(profile)
  }

  return (
    <>
      <StyledPublishWhereField>
        <div className="dialog-field-heading">
          <span className="dialog-field-title">Where</span>
          <button
            className="connections-select"
            ref={selectBtnRef}
            onClick={() => setShowSocialProfilePopover(!showSocialProfilePopover)}
          >
            {!profiles?.length && (
              <span className="connection-select-placeholder">
                Choose 1 or more social profiles
              </span>
            )}
            {!!profiles.length && (
              <div className="connection-select-profiles">
                {profiles.map((profile) => {
                  return (
                    <Avatar
                      name={profile.username}
                      image={profile.avatarUrl || ''}
                      inRow
                      key={profile._id}
                    />
                  )
                })}
                <span className="profile-list">{getProfileListString(profiles)}</span>
              </div>
            )}
            <ChevronDownIcon className="chevron-icon" />
          </button>
          <span className="dialog-field-subtitle">
            Select which social profiles you'd like to publish to.
          </span>
        </div>
      </StyledPublishWhereField>
      <SocialProfilePopover
        selectedProfiles={profiles.map((profile) => profile._id!)}
        buttonRef={selectBtnRef}
        width={452}
        isCollapsed={false}
        onSelect={(_id) => handleSelect(_id)}
        classToAvoid="connections-select"
        isOpen={showSocialProfilePopover}
        onClose={() => setShowSocialProfilePopover(false)}
      />
    </>
  )
}

export default PublishWhereField
