import { FC } from 'react'
import { StyledToggle } from '.'

type Props = {
  value?: boolean
  onChange?: (value: boolean) => void
}

const Toggle: FC<Props> = ({ value, onChange }) => {
  const handleChange = (v: boolean) => {
    if (onChange) {
      onChange(v)
    }
  }

  return (
    <StyledToggle>
      <input type="checkbox" checked={value} onChange={(e) => handleChange(e.target.checked)} />
      <div className="toggle-box">
        <div className="toggle-indicator" />
      </div>
    </StyledToggle>
  )
}

export default Toggle
