import { FC, ReactNode } from 'react'
import { BottomSheet, BottomSheetProps } from 'react-spring-bottom-sheet'

type Props = {
  children: ReactNode
  isBlocked?: boolean
} & BottomSheetProps

const Drawer: FC<Props> = ({ children, isBlocked, onDismiss, ...rest }) => {
  return (
    <>
      <BottomSheet
        sibling={
          <div
            className="bottom-sheet-sibling"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              onDismiss && onDismiss()
            }}
          />
        }
        style={
          {
            zIndex: 999999,
            position: 'relative',
            '--rsbs-overlay-rounded': '16px'
          } as any
        }
        {...rest}
        expandOnContentDrag={!isBlocked}
        onDismiss={isBlocked ? undefined : onDismiss}
      >
        {children}
      </BottomSheet>
    </>
  )
}

export default Drawer
