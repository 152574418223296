import { FC, useContext } from 'react'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { CardInput } from '../../../..'
import { userStore } from '../../../../../utils'
import { StyledPaymentScreen } from '.'

type Props = {
  plan: 'year' | 'month'
  hasPaymentMethod?: boolean
  setCardReady: (isReady: boolean) => void
}

const PaymentScreen: FC<Props> = ({ plan, hasPaymentMethod, setCardReady }) => {
  const { user } = useContext(userStore)

  return (
    <StyledPaymentScreen className="dialog-screen">
      <div className="dialog-breadcrumbs">
        <span>Select Plan</span>
        <ChevronRightIcon />
        <span className="active">Payment</span>
      </div>
      <div className="selected-plan">
        <span>{plan === 'year' ? 'Year' : 'Month'}ly Subscription</span>
        {(!user?.trial || plan === 'month') && <span>${plan === 'year' ? 599 : 59}</span>}
        {!!user?.trial && plan === 'year' && <span>$549 ($599/year)</span>}
      </div>
      <div className="card-input-container">
        <span>
          <b>Credit Card</b>
        </span>
        <CardInput onError={() => setCardReady(false)} onComplete={() => setCardReady(true)} />
      </div>
    </StyledPaymentScreen>
  )
}

export default PaymentScreen
