import { toast } from 'react-hot-toast'
import { compact } from 'lodash'
import { useApolloClient } from '@apollo/client'
import { AccessToken, FacebookLogin } from '@capacitor-community/facebook-login'
import { Capacitor } from '@capacitor/core'
import { getSocialProfiles } from '../../../graphql'
import { SocialProfileType } from '../../../types'
import { conditionalStage } from '..'

export const useLoginMeta = () => {
  const apollo = useApolloClient()
  const native = Capacitor.isNativePlatform()

  const getMetaToken = async (type: 'instagram' | 'facebook') => {
    const redirect = native
      ? 'https://app.socialcurator.com/connect?mobile=true'
      : `${window.location.origin}/connect`

    const scopes = [
      'public_profile',
      'pages_show_list',
      'pages_read_engagement',
      'pages_manage_posts',
      'instagram_basic',
      'instagram_content_publish',
      'business_management',
      'ads_management'
    ]

    // const url = compact([
    //   'https://www.facebook.com/',
    //   'v19.0',
    //   '/dialog/oauth?',
    //   `client_id=${process.env.REACT_APP_FACEBOOK_APP_ID}&`,
    //   `display=page&`,
    //   conditionalStage(type === 'instagram', `config_id=362527626673058&`),
    //   // conditionalStage(
    //   //   type === 'instagram',
    //   //   `extras=${JSON.stringify({ setup: { channel: 'IG_API_ONBOARDING' } })}&`
    //   // ),
    //   `response_type=token&`,
    //   `scope=${scopes.toString()}&`,
    //   `redirect_uri=${redirect}`
    // ])

    // console.log(url.join(''))

    // window.open(
    //   url.join(''),
    //   '',
    //   'resizable=yes,width=900,height=800,toolbar=no,titlebar=no,menubar=no,scrollbars=yes'
    // )

    // const token = await new Promise((resolve, reject) =>
    //   window.addEventListener('message', (event) => {
    //     if (event.origin !== window.origin) {
    //       return
    //     }

    //     if (typeof event.data !== 'object') {
    //       return
    //     }

    //     if (event.data?.type !== 'connected-profile') {
    //       return
    //     }

    //     console.log(event)

    //     resolve(event.data.access_token)
    //   })
    // )

    // return token as string

    const { accessToken } = await FacebookLogin.login({
      permissions: scopes,
      ...conditionalStage(type === 'instagram', {
        // config_id: '362527626673058'
        extras: { setup: { channel: 'IG_API_ONBOARDING' } }
      }),
      return_scopes: true,
      enable_profile_selector: true
    })

    return accessToken?.token
  }

  const initializeMeta = async () => {
    try {
      await FacebookLogin.initialize({
        appId: process.env.REACT_APP_FACEBOOK_APP_ID,
        version: 'v19.0'
      })
    } catch (err) {
      console.error(err)
    }
  }

  const checkMetaLoggedIn = async () => {
    try {
      const accessTokenResponse = await FacebookLogin.getCurrentAccessToken()

      if (accessTokenResponse.accessToken?.token) {
        return true
      }

      return false
    } catch (err) {
      return false
    }
  }

  const loginFacebook = async (type: 'instagram' | 'facebook') => {
    try {
      await initializeMeta()

      const loggedIn = await checkMetaLoggedIn()

      if (loggedIn) {
        await FacebookLogin.logout()
      }

      const accessToken = await getMetaToken(type)

      if (!accessToken) {
        throw { message: 'Sorry, something went wrong. Please try again.' }
      }

      return await getProfiles(accessToken, type)
    } catch (err) {
      console.error(err)

      const message = (err as any)?.message

      if (message) {
        toast.error(message)
      }

      return []
    }
  }

  const getProfiles = async (accessToken: string, type: 'instagram' | 'facebook') => {
    const { data } = await apollo.query({
      fetchPolicy: 'no-cache',
      query: getSocialProfiles,
      variables: {
        accessToken
      }
    })

    const profiles = data?.getSocialProfiles || []

    return profiles.filter((profile: SocialProfileType) => profile.type === type)
  }

  return {
    loginFacebook
  }
}
