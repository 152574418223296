import { ForwardedRef, forwardRef } from 'react'
import { PhotoIcon } from '@heroicons/react/24/outline'
import { PhotoSetType } from '../../../types'
import { PhotoSetListItem } from '../..'
import { StyledActionPlanPhotoSets } from '.'

type Props = {
  photoSets: PhotoSetType[]
}

const ActionPlanPhotoSets = forwardRef(
  ({ photoSets }: Props, ref: ForwardedRef<HTMLDivElement>) => {
    if (!photoSets?.length) {
      return null
    }

    return (
      <StyledActionPlanPhotoSets ref={ref}>
        <div className="action-plan-content-list-header">
          <PhotoIcon />
          <span>Recommended Photos</span>
        </div>
        <div className="action-plan-photos-list">
          {photoSets.map((photoSet) => {
            return <PhotoSetListItem photoSet={photoSet} key={photoSet._id} />
          })}
        </div>
      </StyledActionPlanPhotoSets>
    )
  }
)

export default ActionPlanPhotoSets
