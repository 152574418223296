import styled from 'styled-components'

type StyledProps = {
  isDragging?: boolean
  isOverlay?: boolean
}

const StyledCalendarPostListItem = styled.div<StyledProps>`
  overflow: hidden;

  .media-container {
    padding-top: 100%;
    position: relative;
    transition: opacity 0.2s;
    user-select: none;
    overflow: hidden;
    background-color: ${({ theme }) => theme.color.gray[100]};

    .empty-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 34px;
      stroke-width: 1.2px;
      color: ${({ theme }) => theme.color.gray[500]};
    }

    .media-preview {
      position: absolute;
      top: 15px;
      left: 15px;
      transition: 0.2s;
      width: calc(100% - 30px);
      height: calc(100% - 30px);
      pointer-events: none;

      img,
      video {
        border-radius: 6px;
        max-width: 100%;
        max-height: 100%;
        width: fit-content;
        margin: 0 auto;
        filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.2));
      }
    }

    .post-text-preview {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding: 10px 10px 0 10px;
      font-size: 11px;
      color: ${({ theme }) => theme.color.gray[500]};

      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 40px;
        background: linear-gradient(transparent, ${({ theme }) => theme.color.gray[100]});
      }
    }
  }

  .post-title {
    font-size: 13px;
    font-weight: 500;
    padding: 5px 5px 0 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-align: left;
    overflow-wrap: anywhere;
    -webkit-box-orient: vertical;
    white-space: pre-wrap;
    color: ${({ theme }) => theme.color.gray[700]};
  }

  &:hover {
    cursor: grab;
  }

  ${({ isOverlay }) =>
    isOverlay &&
    `
    border-radius: 6px;
    box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.2), 0px 0px 0px 1px rgba(0, 0, 0, 0.075),
    0px 2px 3px rgba(0, 0, 0, 0.1);
    cursor: grabbing !important;

    .post-title {
      display: none;
    }
  `}

  ${({ isDragging }) =>
    isDragging &&
    `
    opacity: 0.4;
  `}
`

export default StyledCalendarPostListItem
