import { FC } from 'react'
import { PhotoList } from '../../components'
import { StyledPhotosView } from '.'

const PhotosView: FC = () => {
  return (
    <StyledPhotosView id="photo-list-scroll">
      <PhotoList />
    </StyledPhotosView>
  )
}

export default PhotosView
