import { gql } from '@apollo/client'

export const getDraftsBetweenDates = gql`
  query GetDraftsBetweenDates($start: String!, $end: String!) {
    getDraftsBetweenDates(start: $start, end: $end) {
      posts {
        _id
        title
        captionText
        imageUrl
        userUpload
        socialProfiles
        imageId
        fullPath
        template
        challenge
        postStatus
        coverPhotoOffset
        coverPhotoUrl
        instagramPublishType
        instagramReelAudioName
        tikTokPrivacyStatus
        tikTokCommentDisabled
        tikTokDuetDisabled
        tikTokStitchDisabled
        media {
          _id
          url
          preview
          type
          uploaded
          design
          ref
          dimensions {
            x
            y
          }
        }
        imageDimensions {
          x
          y
        }
        category
        postDate
        tags
        isLiked
        isUsed
        index
        lastModified
        createdAt
      }
    }
  }
`
