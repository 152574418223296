import { FC, RefObject } from 'react'
import { LEVELS, useUserLevel } from '../../../utils'
import { Popover } from '../..'
import { StyledLevelsPopover } from '.'

type Props = {
  classToAvoid: string
  buttonRef: RefObject<HTMLDivElement | HTMLButtonElement>
  isOpen: boolean
  onClose: () => void
}

const LevelsPopover: FC<Props> = ({ ...rest }) => {
  const { level, postsNum } = useUserLevel()

  return (
    <Popover position="bottom-left" {...rest}>
      <StyledLevelsPopover>
        <div className="levels-header">
          <span className="level-title">
            {level.icon}
            {level.title}
          </span>
          <span className="level-description">
            {postsNum} post{postsNum !== 1 ? 's' : ''} in the last 30 days
          </span>
          <span className="levels-description">Level up to grow your business</span>
        </div>
        <div className="levels-list">
          <div className="levels-list-header">
            <span>LEVEL</span>
            <span>POSTS</span>
          </div>
          <div className="levels-list-grid">
            {LEVELS.map((l, index) => {
              const nextLevel = LEVELS[index + 1] || level
              const prevLevel = LEVELS[index - 1]

              return (
                <div className="level" data-active={l.stage === level.stage}>
                  <div className="level-details-container">
                    <div className="level-icon">{l.icon}</div>
                    <div className="level-details">
                      <span className="level-title">{l.title}</span>
                      {index === LEVELS.length - 1 && (
                        <span className="level-description">One of the top Curators</span>
                      )}
                      {l.stage === level.stage && level.stage !== LEVELS.length && (
                        <span className="level-description">
                          {level.target! - postsNum} post
                          {level.target! - postsNum !== 1 ? 's' : ''} to {nextLevel.title}
                        </span>
                      )}
                    </div>
                  </div>
                  <span className="level-posts">{prevLevel?.target || 0}</span>
                </div>
              )
            })}
          </div>
          <hr />
        </div>
      </StyledLevelsPopover>
    </Popover>
  )
}

export default LevelsPopover
