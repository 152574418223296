import styled from 'styled-components'

const StyledBitListItem = styled.button`
  padding: 12px;
  width: 100%;
  border-radius: 6px;
  text-align: left;
  box-sizing: border-box;
  user-select: none;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
    0px 2px 3px rgba(0, 0, 0, 0.05);
  background-color: ${({ theme }) => theme.color.background};

  .bit-list-item-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .bit-list-item-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      border-radius: 4px;
      height: 24px;
      background-color: ${({ theme }) => theme.color.gray[100]};
      color: ${({ theme }) => theme.color.gray[600]};
      margin-right: 10px;

      svg {
        height: 18px;
      }
    }

    .bit-list-item-category {
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0.04rem;
      color: ${({ theme }) => theme.color.gray[600]};
    }
  }

  .bit-list-item-content {
    font-size: 15px;
    line-height: 20px;
    color: ${({ theme }) => theme.color.gray[800]};
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.color.brandLight};
    // box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.2), 0px 0px 0px 1px rgba(0, 0, 0, 0.075),
    //   0px 2px 3px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 0px 1px ${({ theme }) => theme.color.brand};

    .bit-list-item-header {
      .bit-list-item-icon {
        background-color: ${({ theme }) => theme.color.brand};
        color: white;

        svg {
          fill: white;
          stroke-width: 0;
        }
      }

      .bit-list-item-category {
        color: ${({ theme }) => theme.color.brand};
      }
    }

    .bit-list-item-content {
      color: ${({ theme }) => theme.color.brand};
    }
  }
`

export default StyledBitListItem
