import styled from 'styled-components'

type StyledProps = {
  isInline?: boolean
}

const StyledPhotoList = styled.div<StyledProps>`
  margin-top: ${({ isInline }) => (isInline ? 0 : 20)}px;

  .masonry-grid {
    display: flex;
    margin-left: -${({ isInline }) => (isInline ? 4 : 10)}px;
    width: auto;

    .masonry-grid-column {
      padding-left: ${({ isInline }) => (isInline ? 4 : 10)}px;
      background-clip: padding-box;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.m}px) {
    // margin-top: 40px;
  }
`

export default StyledPhotoList
