import styled from 'styled-components'

const StyledCalendarHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 12px 6px 12px;
  user-select: none;

  .header-group {
    display: flex;
    align-items: center;

    .list-btn {
      height: 26px;
      width: 26px;
      border-radius: 6px;
      color: ${({ theme }) => theme.color.gray[600]};
      background-color: ${({ theme }) => theme.color.gray[100]};
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.2s;
      margin-right: 12px;

      svg {
        height: 18px;

        path {
          stroke-width: 2.1px;
        }
      }

      &:hover {
        color: ${({ theme }) => theme.color.gray[800]};
        background-color: ${({ theme }) => theme.color.gray[200]};
      }
    }

    .calendar-header-date {
      font-weight: 600;
      font-size: 17px;
      color: ${({ theme }) => theme.color.gray[800]};
      font-family: ${({ theme }) => theme.typeface.garnett};

      .calendar-year {
        font-weight: 500;
      }
    }

    .view-btn {
      height: 26px;
      padding: 0 8px;
      font-size: 15px;
      font-weight: 500;
      color: ${({ theme }) => theme.color.gray[600]};
      background-color: ${({ theme }) => theme.color.gray[100]};
      border-radius: 6px;
      margin: 0 8px 0 4px;
      text-transform: capitalize;
      transition: 0.2s;

      &:hover {
        color: ${({ theme }) => theme.color.gray[800]};
        background-color: ${({ theme }) => theme.color.gray[200]};
      }
    }

    .arrow-btn {
      height: 26px;
      width: 26px;
      border-radius: 6px;
      background-color: transparent;
      color: ${({ theme }) => theme.color.gray[400]};
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.2s;

      svg {
        height: 18px;
        stroke-width: 2.5px;
      }

      &:hover {
        color: ${({ theme }) => theme.color.gray[600]};
      }
    }

    .today-btn {
      height: 26px;
      padding: 0 10px;
      font-size: 15px;
      font-weight: 500;
      color: ${({ theme }) => theme.color.gray[600]};
      background-color: ${({ theme }) => theme.color.background};
      border-radius: 6px;
      box-shadow: 0 0 0 1px rgb(0, 0, 0, 0.1), 0 1px 4px rgb(0, 0, 0, 0.15);
      transition: 0.2s;

      &:hover {
        box-shadow: 0 0 0 1px rgb(0, 0, 0, 0.2), 0 2px 6px rgb(0, 0, 0, 0.15);
      }
    }
  }
`

export default StyledCalendarHeader
