const CURRENT_LOGGED_IN_USER = 'scUserDataObj'
export const setCurrentUserData = (data?: any) => {
  try {
    if (!data) {
      return localStorage.setItem(CURRENT_LOGGED_IN_USER, '')
    }
    const dataStr = typeof data === 'string' ? data : JSON.stringify(data)
    localStorage.setItem(CURRENT_LOGGED_IN_USER, dataStr)
  } catch (err) {
    console.error(err)
  }
}

export const getCurrentUserData = () => {
  try {
    const userObj = localStorage.getItem(CURRENT_LOGGED_IN_USER)
    if (userObj) {
      return JSON.parse(userObj)
    }
    return null
  } catch (err) {
    console.error(err)
    return null
  }
}
