import { createRef, FC, FormEvent, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { updatePage, useAppDispatch, useAppSelector } from '../../../redux'
import { useForm } from '../../../utils'
import { Button, Dialog, Input, Tabs } from '../..'
import { StyledNewLinkBlockDialog } from '.'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const NewLinkBlockDialog: FC<Props> = ({ isOpen, onClose }) => {
  const dialogRef = createRef<HTMLDivElement>()
  const dispatch = useAppDispatch()
  const { page } = useAppSelector(state => state.page)
  const { form, updateFormValue } = useForm({
    label: '',
    url: '',
    type: 'url'
  })

  const isDisabled = () => {
    const hasLabel = !!form.label?.trim()
    const hasUrl = !!form.url?.trim()

    if (form.type === 'divider') {
      return false
    }

    if (form.type === 'header' && hasLabel) {
      return false
    }

    if (form.type === 'url' && hasLabel && hasUrl) {
      return false
    }

    return true
  }

  const handleConfirm = (e: FormEvent) => {
    e.preventDefault()
    dispatch(
      updatePage({
        links: (page?.links || []).concat([
          {
            _id: uuidv4(),
            metadata: {
              label: form?.label,
              url: form?.url
            },
            type: form.type
          }
        ])
      })
    )
    handleClose()
  }

  const handleClose = () => {
    const dialog = dialogRef.current as any

    if (!dialog) {
      return onClose()
    }

    dialog.close()
  }

  useEffect(() => {
    updateFormValue({
      label: '',
      url: '',
      type: 'url'
    })
  }, [isOpen])

  return (
    <Dialog ref={dialogRef} isOpen={isOpen} onClose={onClose}>
      <StyledNewLinkBlockDialog>
        <Tabs
          tabs={[
            {
              label: 'URL',
              isActive: form.type === 'url',
              onClick: () => updateFormValue('type', 'url')
            },
            {
              label: 'Header',
              isActive: form.type === 'header',
              onClick: () => updateFormValue('type', 'header')
            },
            {
              label: 'Divider',
              isActive: form.type === 'divider',
              onClick: () => updateFormValue('type', 'divider')
            }
            // { label: 'Video', isActive: type === 'video', onClick: () => setType('video') },
            // { label: 'Contact', isActive: type === 'contact', onClick: () => setType('contact') }
          ]}
        />
        <form onSubmit={e => handleConfirm(e)}>
          {form.type !== 'divider' && (
            <Input
              value={form.label}
              placeholder="Block Label"
              maxLength={120}
              required
              onChange={e => updateFormValue('label', e.target.value)}
              size="md"
            />
          )}
          {form.type === 'url' && (
            <Input
              value={form.url}
              placeholder="Block URL"
              type="url"
              required
              onChange={e => updateFormValue('url', e.target.value)}
              size="md"
            />
          )}
          <div className="dialog-actions">
            <Button type="button" onClick={() => handleClose()}>
              Cancel
            </Button>
            <Button variant="emphasis" disabled={isDisabled()} isFullWidth>
              Add block
            </Button>
          </div>
        </form>
      </StyledNewLinkBlockDialog>
    </Dialog>
  )
}

export default NewLinkBlockDialog
