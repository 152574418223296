import styled from 'styled-components'

const StyledCaptionList = styled.div`
  margin-top: 20px;

  .masonry-grid {
    display: flex;
    margin-left: -10px;
    width: auto;

    .masonry-grid-column {
      padding-left: 10px;
      background-clip: padding-box;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.m}px) {
    // margin-top: 40px;
  }
`

export default StyledCaptionList
