import { SocialProfileType } from '../../../types'

type OverviewType = {
  [x in SocialProfileType['type']]: {
    howTo?: string
    supports: ('video' | 'text' | 'image' | 'carousel')[]
  }
}

export const overviews: OverviewType = {
  instagram: {
    supports: ['image', 'video', 'carousel'],
    howTo: `> You need both an Instagram business account and a Facebook page in order to enable auto-posting to your Instagram Business Account.

    ## Step One: Facebook Page Settings
    
    To ensure your Facebook is set up properly to be connected to your Social Curator account, please verify the following:
    
    - You have created or been given admin rights to the Facebook page you want to connect to Social Curator
    - Your Facebook Page has your Instagram business account connected
    
    [Check out this video tutorial for verifying the above!](https://www.loom.com/share/9a19205e13e74edb85c8e4a90d5ea3b6)
    
    ### ***I don’t have a Facebook Page - how do I create one?***
    
    No worries if you don’t have a Facebook Page yet, you can learn to create one **[>>HERE<<](https://www.facebook.com/help/mobile-touch/104002523024878)**
    
    ## Step Two: Instagram Business Account Settings
    
    To ensure your Instagram Business Account is set up properly to be connected to your Social Curator account, please verify the following: 

    - Your Instagram account is a Business Account
    - Your Facebook Page is Connected to your Instagram Account

    [Check out this video tutorial for verifying the above!](https://www.loom.com/share/c65dbf2796194a988be634ac600d1ed9) 

    
    ### I don’t have an Instagram Business Account - can I switch to one?
    
    Yes, you can easily switch to an [Instagram Business Account from a Personal or Creator account](https://help.socialcurator.com/en/articles/5801686-an-overview-of-instagram-account-types). You can learn how to do this [>>HERE<<](https://help.instagram.com/502981923235522)

    Once you’ve verified the above, you’ll be ready to connect your Instagram Business account and Facebook Page to your Social Curator Account! 

    _Keep reading to learn how._

    ## Step Three: Connecting your Instagram Business Account to Social Curator 

    - Login to Social Curator
    - Navigate to your “Account Settings” page by clicking your name at the bottom of the left sidebar
    - Click “Connect Social Account” found towards the bottom of the accounts settings page.
    - Click on the Instagram button.
    - Log in to your Facebook account💡 **That’s right, per Meta’s setup, you log in to Facebook to connect to your Instagram account.**
    - Follow the on-screen prompts and be sure to allow all permissions for the best results.
    - When prompted, ensure you toggle on the Instagram account(s) you wish to connect then click ‘Connect’
    
    `
  },
  facebook: {
    supports: ['text', 'image', 'video']
  },
  linkedin: {
    supports: ['text', 'image', 'video']
  },
  // 'linkedin-page': {
  //   supports: ['text', 'image', 'video']
  // },
  twitter: {
    supports: ['text', 'image', 'video', 'carousel']
  }
  // pinterest: {
  //   supports: ['text', 'image', 'video']
  // },
  // tiktok: {
  //   supports: ['video']
  // }
}
