import styled from 'styled-components'

const StyledAccountMenuItem = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  background-color: transparent;
  padding: 0 14px;
  color: ${({ theme }) => theme.color.gray[900]};

  .menu-item-label {
    display: flex;
    align-items: center;
    font-weight: 600;

    svg {
      height: 26px;
      stroke-width: 1.75px;
      margin-right: 10px;
      flex-shrink: 0;
      position: relative;
    }
  }

  .chevron-icon {
    height: 24px;
    stroke-width: 1.75px;
  }

  .toggle {
    height: 30px;
    width: 55px;
    border-radius: 50rem;
    background-color: transparent;
    position: relative;
    box-shadow: inset 0 0 0 1.5px ${({ theme }) => theme.color.gray[300]};

    div {
      transition: left 0.2s;
      position: absolute;
      left: 4px;
      top: 50%;
      transform: translateY(-50%);
      height: 22px;
      width: 22px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.color.gray[300]};
    }

    &[data-toggled='true'] {
      box-shadow: inset 0 0 0 20px ${({ theme }) => theme.color.brand};

      div {
        left: calc(55px - 22px - 4px);
        background-color: white;
      }
    }
  }
`

export default StyledAccountMenuItem
