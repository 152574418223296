import styled from 'styled-components'

const StyledCaptionsViewHeader = styled.div`
  .captions-view-header-top {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: minmax(0, 1fr) auto;

    .input {
      grid-column: 1/3;
    }
  }

  .bubbles {
    margin-top: 12px;

    [data-key='hide-used-separator'] {
      width: 20px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 22px;
        width: 1px;
        border-radius: 6px;
        background-color: ${({ theme }) => theme.color.gray[300]};
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.m}px) {
    .captions-view-header-top {
      grid-template-columns: minmax(0, 1fr) 185px auto;

      .input,
      .select,
      .btn {
        grid-column: unset;
      }
    }
  }
`

export default StyledCaptionsViewHeader
