import { ChangeEvent, FC, createRef, useEffect } from 'react'
import { v4 as uuid } from 'uuid'
import { useActiveObject, useEditor } from '@layerhub-io/react'
import { CloudArrowUpIcon, PhotoIcon, RectangleGroupIcon } from '@heroicons/react/24/outline'
import { ReactComponent as ElementsIcon } from '../../../assets/elements-icon.svg'
import { ReactComponent as ChatIcon } from '../../../assets/chat-outline-icon.svg'
import { ReactComponent as StickersIcon } from '../../../assets/stickers-icon.svg'
import { ReactComponent as TextSquareIcon } from '../../../assets/text-square-icon.svg'
import { setDesignerSubTab, setDesignerTab, useAppDispatch, useAppSelector } from '../../../redux'
import { DesignerNavItem } from '..'
import { StyledDesignerNav } from '.'
import { ChatBotIcon } from '../../chat'
import { toBase64 } from '../../../utils'

const DesignerNav: FC = () => {
  const inputRef = createRef<HTMLInputElement>()
  const editor = useEditor()
  const activeObject = useActiveObject()
  const dispatch = useAppDispatch()
  const { tab, subTab } = useAppSelector((state) => state.designer)

  const TABS = ['templates', 'text', 'elements', 'photos', 'uploads']

  const handleUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = [...(e.target.files as any)]

    files.map(async (file) => {
      if (file.type.includes('svg')) {
        const url = URL.createObjectURL(file)

        const object = {
          id: uuid(),
          src: url,
          type: 'StaticVector'
        }

        return editor.objects.add(object)
      }

      const base64 = (await toBase64(file)) as string

      const object = {
        id: uuid(),
        src: base64,
        preview: base64,
        type: 'StaticImage'
      }

      editor.objects.add(object)
    })
  }

  useEffect(() => {
    if (!!activeObject) {
      return
    }

    dispatch(setDesignerSubTab(undefined))
  }, [activeObject])

  return (
    <StyledDesignerNav>
      {/* <DesignerNavItem
        isActive={tab === 'templates' && !subTab}
        onClick={() => dispatch(setDesignerTab('templates'))}
      >
        <RectangleGroupIcon />
        Templates
      </DesignerNavItem>
      <DesignerNavItem
        isActive={tab === 'chat' && !subTab}
        onClick={() => dispatch(setDesignerTab('chat'))}
      >
        <ChatIcon />
        Dottie
      </DesignerNavItem> */}
      <DesignerNavItem
        isActive={tab === 'text' && !subTab}
        onClick={() => dispatch(setDesignerTab('text'))}
      >
        <TextSquareIcon />
        Text
      </DesignerNavItem>
      <DesignerNavItem
        isActive={tab === 'elements' && !subTab}
        onClick={() => dispatch(setDesignerTab('elements'))}
      >
        <ElementsIcon />
        Shapes
      </DesignerNavItem>
      <DesignerNavItem
        isActive={tab === 'stickers' && !subTab}
        onClick={() => dispatch(setDesignerTab('stickers'))}
      >
        <StickersIcon />
        Stickers
      </DesignerNavItem>
      <DesignerNavItem
        isActive={tab === 'photos' && !subTab}
        onClick={() => dispatch(setDesignerTab('photos'))}
      >
        <PhotoIcon />
        Gallery
      </DesignerNavItem>
      <DesignerNavItem
        isActive={tab === 'uploads' && !subTab}
        onClick={() => inputRef.current?.click()}
      >
        <input
          ref={inputRef}
          hidden
          type="file"
          accept="image/*"
          multiple
          onChange={(e) => handleUpload(e)}
        />
        <CloudArrowUpIcon />
        Upload
      </DesignerNavItem>
    </StyledDesignerNav>
  )
}

export default DesignerNav
