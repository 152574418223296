import styled from 'styled-components'

type StyledProps = {
  arrowOffset?: number
  gap?: number
  padding?: number
  isGray?: boolean
}

const StyledScrollRow = styled.div<StyledProps>`
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  position: relative;

  ${({ padding }) =>
    !!padding &&
    `
    .scroll-row-overflow {
      padding: 0 ${padding}px;
    }
  `}

  .react-horizontal-scrolling-menu--separator {
    width: ${({ gap }) => gap}px;
    flex-shrink: 0;
  }

  .react-horizontal-scrolling-menu--item {
    display: flex;
  }

  .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
  }

  .react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .arrow-background {
    position: absolute;
    z-index: 10;
    width: 100px;
    height: 100%;
    display: flex;
    pointer-events: none;

    .arrow-btn {
      height: 36px;
      width: 36px;
      border-radius: 50%;
      position: absolute;
      border: 0;
      background-color: ${({ theme }) => theme.color.foreground}70;
      color: #fff;
      pointer-events: all;
      top: 50%;
      transform: translateY(-50%);
      box-shadow: 0 0 0 1px rgb(255, 255, 255, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
        0px 2px 3px rgba(0, 0, 0, 0.05);

      svg path {
        stroke-width: 2.5px;
      }

      &:hover {
        background-color: ${({ theme }) => theme.color.foreground}80;
      }
    }

    &.arrow-left {
      left: 0;
      padding-left: ${({ arrowOffset }) => arrowOffset}px;
      background: linear-gradient(
        90deg,
        ${({ theme, isGray }) => (isGray ? theme.color.gray[100] : theme.color.background)},
        transparent
      );

      button {
        padding-right: 2px;
      }
    }

    &.arrow-right {
      right: 0;
      padding-right: ${({ arrowOffset }) => arrowOffset}px;
      background: linear-gradient(
        90deg,
        transparent,
        ${({ theme, isGray }) => (isGray ? theme.color.gray[100] : theme.color.background)}
      );
      flex-direction: row-reverse;

      button {
        padding-left: 2px;
      }
    }
  }
`

export default StyledScrollRow
