import { FC, useEffect } from 'react'
import { toast } from 'react-hot-toast'
import InfiniteScroll from 'react-infinite-scroll-component'
import Masonry from 'react-masonry-css'
import { uniqBy } from 'lodash'
import { useApolloClient } from '@apollo/client'
import { searchGalleryQuery } from '../../../../graphql'
import {
  changePhotosPage,
  changePhotosTotalDocs,
  setPhotos,
  useAppDispatch,
  useAppSelector
} from '../../../../redux'
import { PhotoListItem } from '..'
import { StyledPhotoList } from '.'

const PhotoList: FC = () => {
  const apollo = useApolloClient()
  const dispatch = useAppDispatch()
  const {
    photos,
    totalDocs,
    search,
    orientation,
    sort,
    categories,
    style,
    colors,
    liked,
    page,
    loading,
    photo
  } = useAppSelector((state) => state.photos)

  const loadPhotos = async () => {
    try {
      const { data } = await apollo.query({
        fetchPolicy: 'no-cache',
        query: searchGalleryQuery,
        variables: {
          search,
          orientation,
          sort,
          categories,
          style,
          colors,
          collection: null,
          liked,
          page,
          items: 15,
          photo
        }
      })

      const photosList = data?.searchGallery?.docs || []
      const total = data?.searchGallery?.totalDocs || 0

      dispatch(setPhotos(uniqBy([...photos, ...photosList], '_id')))
      dispatch(changePhotosTotalDocs(total))
    } catch (err) {
      console.log(err)
      toast.error((err as any).message)
    }
  }

  useEffect(() => {
    if (loading) {
      loadPhotos()
    }
  }, [loading])

  return (
    <StyledPhotoList>
      <InfiniteScroll
        scrollThreshold={0.7}
        dataLength={photos.length}
        next={() => dispatch(changePhotosPage(page + 1))}
        hasMore={photos.length < totalDocs}
        loader={null}
        scrollableTarget="photo-list-scroll"
      >
        <Masonry
          className="masonry-grid"
          columnClassName="masonry-grid-column"
          breakpointCols={{
            default: 4,
            800: 3,
            600: 2
          }}
        >
          {photos.map((photo) => {
            return <PhotoListItem photo={photo} key={photo._id} />
          })}
        </Masonry>
      </InfiniteScroll>
    </StyledPhotoList>
  )
}

export default PhotoList
