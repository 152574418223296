import { FC, useEffect, useState } from 'react'
import { useActiveObject, useEditor } from '@layerhub-io/react'
import { LockClosedIcon, LockOpenIcon } from '@heroicons/react/24/outline'
import { DesignerToolButton } from '.'

const DesignerLockTool: FC = () => {
  const editor = useEditor()
  const activeObject = useActiveObject() as any
  const [locked, setLocked] = useState(false)

  const handleToggleLock = () => {
    setLocked(!locked)

    if (locked) {
      editor.objects.unlock()
      return
    }

    editor.objects.lock()
  }

  useEffect(() => {
    if (activeObject) {
      setLocked(!!activeObject.locked)
    }
  }, [activeObject])

  return (
    <DesignerToolButton
      data-tooltip={locked ? 'Unlock' : 'Lock'}
      isIcon
      onClick={() => handleToggleLock()}
    >
      {locked ? <LockClosedIcon /> : <LockOpenIcon />}
    </DesignerToolButton>
  )
}

export default DesignerLockTool
