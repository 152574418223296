import { FC, useEffect } from 'react'
import toast from 'react-hot-toast'
import InfiniteScroll from 'react-infinite-scroll-component'
import Masonry from 'react-masonry-css'
import { debounce, times, uniqBy } from 'lodash'
import { useApolloClient } from '@apollo/client'
import { searchGalleryQuery } from '../../../graphql'
import {
  changePhotosPage,
  changePhotosTotalDocs,
  setPhotos,
  setPhotosQueryState,
  useAppDispatch,
  useAppSelector
} from '../../../redux'
import { PhotoType } from '../../../types'
import { conditionalStage, isMobile, useSearchParams, useSegment } from '../../../utils'
import { EmptyState, Skeleton } from '../..'
import { PhotoListItem } from '..'
import { StyledPhotoList } from '.'

type Props = {
  numCols?: number
  isInline?: boolean
  onExpand?: (photo: PhotoType) => void
}

const PhotoList: FC<Props> = ({ numCols = 3, isInline, onExpand }) => {
  const apollo = useApolloClient()
  const dispatch = useAppDispatch()
  const { track } = useSegment()
  const {
    photos,
    totalDocs,
    search,
    orientation,
    sort,
    categories,
    style,
    colors,
    liked,
    page,
    loading,
    photo
  } = useAppSelector((state) => state.photos)

  const { getSearchFromURL } = useSearchParams({
    search,
    orientation,
    sort,
    categories,
    style,
    colors,
    liked,
    photo
  })

  const handleSearchTrack = debounce((resultsCount: number) => {
    track('Searched Gallery', {
      search_results_count: resultsCount,
      search: search || '',
      sorted_by: sort,
      style_filter: style,
      color_filter: colors,
      category_fitler: categories,
      orientation_filter: orientation
    })
  }, 3000)

  const loadPhotos = async () => {
    try {
      const { data } = await apollo.query({
        fetchPolicy: 'no-cache',
        query: searchGalleryQuery,
        variables: {
          search,
          orientation,
          sort,
          categories,
          style,
          colors,
          collection: null,
          liked,
          page,
          items: 15,
          photo
        }
      })

      const photonList = data?.searchGallery?.docs || []
      const total = data?.searchGallery?.totalDocs || 0

      dispatch(setPhotos(uniqBy([...photos, ...photonList], '_id')))
      dispatch(changePhotosTotalDocs(total))
      handleSearchTrack(total)
    } catch (err) {
      console.log(err)
      toast.error((err as any).message)
    }
  }

  useEffect(() => {
    if (loading) {
      loadPhotos()
    }
  }, [loading])

  useEffect(() => {
    dispatch(setPhotosQueryState(getSearchFromURL()))
  }, [])

  return (
    <StyledPhotoList className="photo-list" isInline={isInline}>
      {!loading && !photos.length && (
        <EmptyState
          title="We came up empty"
          body="We couldn't find a photo that matches that query. How about a different search?"
        />
      )}
      {loading && page === 0 && (
        <Masonry
          className="masonry-grid"
          columnClassName="masonry-grid-column"
          breakpointCols={{
            default: numCols,
            900: 2,
            600: 1
          }}
        >
          {times(15, (num) => {
            return (
              <Skeleton
                randHeight={[280, 480]}
                styles="&:not(:last-child) {
                    margin-bottom: 10px;
                  }"
                key={num}
              />
            )
          })}
        </Masonry>
      )}
      <InfiniteScroll
        scrollThreshold={0.7}
        dataLength={photos.length}
        next={() => dispatch(changePhotosPage(page + 1))}
        hasMore={photos.length < totalDocs}
        loader={null}
        {...conditionalStage(isMobile(), { scrollableTarget: 'app-content' })}
      >
        <Masonry
          className="masonry-grid"
          columnClassName="masonry-grid-column"
          breakpointCols={{
            default: numCols,
            900: 2,
            600: 1
          }}
        >
          {photos.map((photo) => {
            return (
              <PhotoListItem
                photo={photo}
                isInline={isInline}
                onExpand={onExpand}
                key={photo._id}
              />
            )
          })}
        </Masonry>
      </InfiniteScroll>
    </StyledPhotoList>
  )
}

export default PhotoList
