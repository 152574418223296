import styled from 'styled-components'

const StyledCalendarView = styled.div`
  height: calc(
    100vh - (env(safe-area-inset-top) + 65px) -
      (max(calc(env(safe-area-inset-bottom) - 4px), 10px) + 53px)
  );
  overflow: hidden;
  display: flex;

  .calendar-container {
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
    height: 100%;
    width: 100%;
  }

  .calendar-header {
    padding: 0 12px;
    height: 53px;
    display: flex;
    align-items: center;
    user-select: none;
    justify-content: space-between;
    border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};

    .calendar-header-group {
      display: flex;
      align-items: center;

      .collapse-btn {
        margin-right: 10px;
      }

      .calendar-header-date {
        font-weight: 600;
        font-family: ${({ theme }) => theme.typeface.faktumXCon};
        color: ${({ theme }) => theme.color.gray[800]};
        font-size: 26px;
        position: relative;
        top: -1px;
        cursor: default;
      }

      .calendar-post-count {
        font-size: 15px;
        display: none;
        margin-left: 10px;
        background-color: ${({ theme }) => theme.color.gray[100]};
        padding: 4px 10px;
        border-radius: 50rem;
        cursor: default;
        color: ${({ theme }) => theme.color.gray[600]};
      }
    }

    .calendar-header-actions {
      display: flex;

      .btn {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }

  .toastui-calendar-week-view-day-names {
    overflow: hidden !important;
  }

  .toastui-calendar-grid-cell-date
    .toastui-calendar-weekday-grid-date.toastui-calendar-weekday-grid-date-decorator {
    color: white;
  }

  .toastui-calendar-week {
    border: 0 !important;
  }

  .toastui-calendar-day-names.toastui-calendar-week,
  .toastui-calendar-day-name-item.toastui-calendar-week {
    height: 44px !important;
    line-height: 42px !important;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    height: 100vh;

    .calendar-header {
      .calendar-header-group {
        .calendar-post-count {
          display: block;
        }
      }
    }
  }
`

export default StyledCalendarView
