import styled from 'styled-components'

const StyledConnectionExpiredArea = styled.div`
  margin: 20px 20px 0 20px;
  padding: 10px;
  border-radius: 10px;
  font-weight: 500;
  border: 1px solid ${({ theme }) => theme.color.red[700]};
  background-color: ${({ theme }) => theme.color.red[50]};
  color: ${({ theme }) => theme.color.red[700]};

  .expired-header {
    padding: 10px;
  }
`

export default StyledConnectionExpiredArea
