import { FC } from 'react'
import { PhotoIcon } from '@heroicons/react/24/outline'
import { setCreatePost, useAppDispatch, useAppSelector } from '../../../redux'
import { PostType } from '../../../types'
import { renderEditorImage } from '../../../utils'
import { StyledRecentPostItem } from '.'

type Props = {
  post: PostType
}

const RecentPostItem: FC<Props> = ({ post }) => {
  const dispatch = useAppDispatch()
  const { post: editorPost } = useAppSelector((state) => state.create)

  return (
    <StyledRecentPostItem
      className="recent-post-item"
      data-active={editorPost?._id === post._id}
      onClick={() => dispatch(setCreatePost(post))}
    >
      <div className="post-img-preview">
        {!!post.media?.length && (
          <img src={post.media[0]?.preview || renderEditorImage(post.media[0].url, 100)} alt="" />
        )}
        {!post.media?.length && <PhotoIcon />}
      </div>
      <div className="post-details">
        <span className="post-title">{post.title || 'Untitled Post'}</span>
        <span className="post-caption">
          {post.captionText || <span className="empty">Empty Caption</span>}
        </span>
      </div>
    </StyledRecentPostItem>
  )
}

export default RecentPostItem
