import { toast } from 'react-hot-toast'
import { DateTime } from 'luxon'
import { v4 as uuidv4 } from 'uuid'
import { useApolloClient } from '@apollo/client'
import {
  chatGetMessages,
  chatHandleDailyAction,
  chatHandlePhoto,
  chatHandleVideo,
  chatSendMessage
} from '../../graphql'
import {
  addChatMessage,
  addCreatePostBit,
  addCreatePostMedia,
  setChatConversation,
  setChatInput,
  setChatLoading,
  setChatMessages,
  setChatRef,
  setChatSuggestions,
  setChatTempTemplate,
  setChatTemplate,
  setShowChatHistory,
  updateCreatePost,
  upsertChatMessage,
  useAppDispatch,
  useAppSelector
} from '../../redux'
import { ChatMessageType, PhotoType } from '../../types'
import { getServerURL, useCreate, useSegment, waitForElementExists } from '..'

export const useChat = () => {
  const dispatch = useAppDispatch()
  const apollo = useApolloClient()
  const { addMediaToPost } = useCreate()
  const { template, conversation, persona, ref } = useAppSelector((state) => state.chat)
  const { post } = useAppSelector((state) => state.create)
  const { track } = useSegment()

  const clearChat = async () => {
    dispatch(setChatMessages([]))
    dispatch(setChatSuggestions([]))
    dispatch(setChatInput(''))
    dispatch(setChatTemplate(undefined))
    dispatch(setChatRef(undefined))
    dispatch(setChatConversation(uuidv4()))
    track('Cleared Chat')
  }

  const loadConversation = async (_id: string) => {
    dispatch(setChatMessages([]))
    dispatch(setChatSuggestions([]))
    dispatch(setChatInput(''))
    dispatch(setChatTemplate(undefined))
    dispatch(setChatRef(undefined))
    dispatch(setChatConversation(_id))
    dispatch(setShowChatHistory(false))

    const { data } = await apollo.query({
      fetchPolicy: 'no-cache',
      query: chatGetMessages,
      variables: {
        conversation: _id
      }
    })

    dispatch(setChatMessages(data?.chatGetMessages || []))
  }

  const scrollToMessage = (
    message: string,
    behavior: any = 'smooth',
    block: ScrollIntoViewOptions['block'] = 'center'
  ) => {
    const element = document.querySelector(`[data-message="${message}"]`)
    element?.scrollIntoView({
      behavior,
      block
    })

    element?.classList.add('highlight')

    setTimeout(() => {
      element?.classList.remove('highlight')
    }, 4000)
  }

  const highlightMessage = async (message: string) => {
    const query = `[data-message="${message}"]`
    await waitForElementExists(query, 10)
    const element = document.querySelector(query)
    element?.classList.add('highlight')

    setTimeout(() => {
      element?.classList.remove('highlight')
    }, 4000)
  }

  const getTemplateLabel = (value?: string) => {
    switch (value) {
      case 'blanks':
        return 'Fill in Blanks'
      case 'caption':
        return 'Generate a Caption'
      case 'photo':
        return 'Suggest a Photo'
      case 'daily-action':
        return 'Daily Action'
      case 'video':
        return 'Find a Video'
      case 'visual-ideas':
        return 'Find Visual Ideas'
      case 'hashtags':
        return 'Recommend Hashtags'
      case 'post-title':
        return 'Title Post'
      case 'video-script':
        return 'Video Script'
      case 'live-video-outline':
        return 'Live Video Outline'
      default:
        return 'General Conversation'
    }
  }

  const handlePhotoRequest = async (prompt: string, ref?: string) => {
    const { data } = await apollo.mutate({
      fetchPolicy: 'no-cache',
      mutation: chatHandlePhoto,
      variables: {
        prompt,
        conversation,
        ref
      }
    })

    const reply = data.chatHandlePhoto
    dispatch(addChatMessage(reply))
  }

  const handleVideoRequest = async (prompt: string) => {
    const { data } = await apollo.mutate({
      fetchPolicy: 'no-cache',
      mutation: chatHandleVideo,
      variables: {
        prompt,
        conversation
      }
    })

    const reply = data.chatHandleVideo
    dispatch(addChatMessage(reply))
  }

  const handleDailyActionRequest = async (prompt: string) => {
    const { data } = await apollo.mutate({
      fetchPolicy: 'no-cache',
      mutation: chatHandleDailyAction,
      variables: {
        conversation,
        prompt
      }
    })

    const reply = data.chatHandleDailyAction
    dispatch(addChatMessage(reply))
  }

  const addMessageToPost = (message: ChatMessageType) => {
    if (message.template === 'caption') {
      // const identifiedCaption = message.content.split('<caption>').pop()?.split('</caption>')[0]
      dispatch(addCreatePostBit(message.content))
      track('Added Caption to Post', {
        caption: message.content,
        is_chat: true,
        post_id: post?._id
      })
      return
    }

    if (message.template === 'post-title') {
      const formattedTitle = message.content.replaceAll('"', '')
      dispatch(updateCreatePost({ title: formattedTitle }))
      track('Added Title to Post', { title: formattedTitle, is_chat: true, post_id: post?._id })
      return
    }

    if (message.template === 'hashtags') {
      const hashtags = message.content.match(/\B\#\w\w+\b/g) || []

      dispatch(addCreatePostBit(hashtags.join(' ')))
      track('Added Hashtags to Post', {
        hashtags: hashtags.join(' '),
        is_chat: true,
        post_id: post?._id
      })
      return
    }

    if (message.template === 'photo') {
      const photo = message.metadata?.photo as PhotoType

      if (!photo) {
        return
      }

      addMediaToPost({
        url: photo.file.fileHttpLink || '',
        dimensions: {
          x: photo.file.imgSize?.width || 0,
          y: photo.file.imgSize?.height || 0
        }
      })

      track('Added Photo to Post', { ...photo, is_chat: true, post_id: post?._id })
      return
    }

    if (message.template === 'video') {
      const video = message.metadata?.video

      if (!video) {
        return
      }

      const formattedData = {
        _id: uuidv4(),
        url: video.file.fileHttpLink || '',
        preview: video.file?.preview,
        type: 'video',
        uploaded: true,
        dimensions: {
          x: video.file.imgSize.width || 0,
          y: video.file.imgSize.height || 0
        }
      }

      dispatch(addCreatePostMedia(formattedData as any))

      track('Added Video to Post', { ...video, is_chat: true, post_id: post?._id })
      return
    }

    const identifiedCaption = ''

    // message.content.split('<caption>').pop()?.split('</caption>')[0]

    dispatch(addCreatePostBit(identifiedCaption || message.content))
    track('Added Message to Post', {
      ...message,
      identified_caption: identifiedCaption,
      is_chat: true,
      post_id: post?._id
    })
  }

  const sendMessage = async (
    content: string,
    forceTemplate?: string,
    forceRef?: string,
    addToPost?: boolean
  ) => {
    try {
      dispatch(setChatLoading(true))
      dispatch(setChatSuggestions([]))

      const temp = forceTemplate || template
      const lowerString = content.toLowerCase().split(' ')
      const PHOTO_PROMPTS: string[] = [] // 'image', 'photo', 'picture', 'portrait'
      let forcePrefix: string | undefined = undefined
      let forceAlt: string | undefined = undefined
      const chatRef = ref || forceRef

      dispatch(setChatTempTemplate(temp))

      if (temp === 'post-title') {
        forceAlt = `Write a super short and creative title for the following caption. Simply answer with the title itself, don't add anything else. Caption: ${post?.captionText}`
      }

      const sentObject: ChatMessageType = {
        _id: uuidv4(),
        content: content,
        conversation,
        template: temp,
        ref: chatRef,
        status: 'success',
        createdAt: DateTime.now().toISO(),
        type: 'sent'
      }

      dispatch(addChatMessage(sentObject))

      if (lowerString.some((str) => PHOTO_PROMPTS.includes(str)) || temp === 'photo') {
        return await handlePhotoRequest(content, chatRef)
      }

      if (temp === 'video') {
        return await handleVideoRequest(content)
      }

      if (temp === 'daily-action') {
        return await handleDailyActionRequest(content)
      }

      // // START STREAM

      // const host = getServerURL()
      // const url = `${host}/chat`

      // const response = await fetch(url, {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json'
      //   },
      //   credentials: 'include',
      //   body: JSON.stringify({
      //     content,
      //     conversation,
      //     template: temp,
      //     forcePrefix,
      //     forceAlt,
      //     persona,
      //     ref: chatRef
      //   })
      // })

      // if (!response.ok) {
      //   throw new Error(response.statusText)
      // }

      // const data = response.body

      // if (!data) {
      //   return
      // }

      // const reader = data.getReader()
      // const decoder = new TextDecoder()

      // const receivedDummyId = uuidv4()

      // dispatch(
      //   upsertChatMessage({
      //     _id: receivedDummyId,
      //     message: {
      //       _id: receivedDummyId,
      //       content: '',
      //       prompt: '',
      //       status: 'success',
      //       type: 'received',
      //       conversation,
      //       createdAt: DateTime.now().toISO()
      //     }
      //   })
      // )

      // while (true) {
      //   const { value, done } = await reader.read()

      //   if (done) {
      //     break
      //   }

      //   const chunkValue = decoder.decode(value)
      //   const arr = chunkValue.split('{')
      //   const choice = `{${arr[arr.length - 1]}`

      //   try {
      //     const object = JSON.parse(choice) as ChatMessageType
      //     const sanitized = object.content
      //       .replace(/(<\/?(?:caption|hashtag|suggestion)[^>]*>)|<[^>]+>/gi, '$1')
      //       .replace('<>', '')
      //       .split('<s')[0]
      //     const suggestions = object.content.match(/<suggestion>(.*?)<\/suggestion>/g)

      //     dispatch(setChatSuggestions(suggestions || []))

      //     dispatch(
      //       upsertChatMessage({
      //         _id: receivedDummyId,
      //         message: {
      //           ...object,
      //           content: sanitized
      //         }
      //       })
      //     )

      //     scrollToMessage(receivedDummyId, 'instant', 'end')
      //     highlightMessage(object._id)
      //   } catch (err) {
      //     console.error(chunkValue)
      //     console.error(err)
      //     break
      //   }
      // }

      const { data } = await apollo.mutate({
        fetchPolicy: 'no-cache',
        mutation: chatSendMessage,
        variables: {
          content,
          conversation,
          template: temp,
          forcePrefix,
          forceAlt,
          persona,
          ref: chatRef,
          date: DateTime.now().toISO()
        }
      })

      const reply = {
        ...data.chatSendMessage
      } as ChatMessageType

      const suggestions = data.chatSendMessage.content.match(/<suggestion>(.*?)<\/suggestion>/g)

      dispatch(setChatSuggestions(suggestions || []))
      dispatch(addChatMessage(reply))
      highlightMessage(reply._id)

      if (addToPost) {
        addMessageToPost(reply)
      }
    } catch (err) {
      console.error(err)
      toast.error((err as any)?.message)
    } finally {
      dispatch(setChatLoading(false))
      dispatch(setChatTempTemplate(undefined))
      dispatch(setChatRef(undefined))
    }
  }

  return {
    clearChat,
    sendMessage,
    scrollToMessage,
    highlightMessage,
    getTemplateLabel,
    loadConversation,
    addMessageToPost
  }
}
