import styled from 'styled-components'

const StyledActionPlanVideo = styled.div`
  padding-top: 56.25%;
  position: relative;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.color.foreground};

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }

  &::after {
    content: '';
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    box-shadow: inset 0 0 0 1px rgb(0, 0, 0, 0.1);
  }
`

export default StyledActionPlanVideo
