import { gql } from '@apollo/client'

export const searchCaptions = gql`
  query SearchCaptions(
    $search: String
    $issue: String
    $categories: [String]!
    $liked: Boolean
    $sort: String!
    $hideUsed: Boolean
    $items: Int = 10
    $page: Int!
  ) {
    captionSetSearch(
      search: $search
      issue: $issue
      sort: $sort
      items: $items
      page: $page
      categories: $categories
      liked: $liked
      hideUsed: $hideUsed
    ) {
      docs {
        _id
        category
        caption
        captionPlainText
        title
        category
        issue
        issueTitle

        userCaption {
          _id
          liked
          used
          user
        }
      }
      totalDocs
    }
  }
`
