import styled from 'styled-components'

const StyledPaymentScreen = styled.div`
  .selected-plan {
    margin-top: 20px;
    border-radius: 4px;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    color: ${({ theme }) => theme.color.gray[800]};
    background-color: ${({ theme }) => theme.color.gray[200]};
  }

  .card-input-container {
    margin: 18px 0 6px 0;

    b {
      color: ${({ theme }) => theme.color.gray[800]};
      font-weight: 500;
      margin-bottom: 12px;
      display: block;
    }
  }
`

export default StyledPaymentScreen
