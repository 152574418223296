import { FC, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { parse } from 'query-string'
import {
  ChatBubbleOvalLeftEllipsisIcon,
  ClockIcon,
  ComputerDesktopIcon,
  VideoCameraIcon
} from '@heroicons/react/24/outline'
import { ReactComponent as SCLogo } from '../../assets/sc-logo-icon-stroke.svg'
import { setProductTest, useAppDispatch, useAppSelector } from '../../redux'
import { ROUTES } from '../../utils'
import { Button, PageLayout, PRODUCT_TESTS } from '../../components'
import { StyledProductTestView } from '.'

const ProductTestView: FC = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const history = useHistory()
  const { productTest } = useAppSelector((state) => state.productTesting)
  const [test, setTest] = useState<typeof productTest>()

  const handleStart = async () => {
    if (!test) {
      return
    }

    dispatch(setProductTest(test))
    history.push(ROUTES.home)
  }

  useEffect(() => {
    const parsed = parse(location.search)
    const tidString = parsed?.tid as string

    if (!tidString) {
      return history.push(ROUTES.home)
    }

    const testData = PRODUCT_TESTS[tidString]

    if (!testData) {
      return history.push(ROUTES.home)
    }

    setTest(testData)
  }, [location.search])

  return (
    <PageLayout maxWidth={450}>
      <StyledProductTestView>
        <div className="insiders-label">
          <SCLogo />
          INSIDERS
        </div>
        <h1>WELCOME TO {test?.title}</h1>
        <h2>You're invited to test the new Social Curator experience.</h2>
        <hr />
        <ul>
          <li>
            <VideoCameraIcon />
            For this test, you'll record your screen and follow directions to perform 5 different
            tasks.
          </li>
          <li>
            <ChatBubbleOvalLeftEllipsisIcon />
            Once you're finished, you'll be asked to answer some questions about the test.
          </li>
          <li>
            <ClockIcon />
            Although we recommend you spend 15-30 minutes completing the tasks, there is no time
            limit.
          </li>
          <li>
            <ComputerDesktopIcon />
            This test should be completed on a desktop device. Testing on a mobile device is not
            currently supported.
          </li>
        </ul>
        <Button size="lg" variant="emphasis" isFullWidth onClick={() => handleStart()}>
          Start Test
        </Button>
        <span className="disclaimer">
          Clicking <b>Start Test</b> will redirect you to start the screen recording.
        </span>
      </StyledProductTestView>
    </PageLayout>
  )
}

export default ProductTestView
