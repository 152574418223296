import styled from 'styled-components'

type StyledProps = {
  size: 'lg' | 'md' | 'sm'
  variant?:
    | 'neutral'
    | 'outline'
    | 'destructive'
    | 'destructive-solid'
    | 'emphasis'
    | 'emphasis-outline'
    | 'emphasis-tone'
  iconPos?: 'left' | 'right'
  isIcon?: boolean
  isFullWidth?: boolean
}

const StyledButton = styled.button<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-weight: 500;
  user-select: none;
  ${({ isFullWidth }) => isFullWidth && 'width: 100%;'}
  transition: background-color 0.2s, color 0.2s, border-color 0.2s;

  svg path, svg rect {
    stroke-width: 1.75px;
  }

  ${({ size, iconPos, isIcon }) =>
    size === 'lg' &&
    `
    height: 50px;
    ${isIcon ? 'width: 50px;' : 'padding: 0 18px;'}

    svg, img {
      height: 24px;
      
      ${iconPos &&
        `
        margin-right: ${!isIcon && (iconPos === 'left' ? '10px' : '-4px')};
        margin-left: ${!isIcon && (iconPos === 'right' ? '10px' : '-4px')};
      `}
    }

    img {
      width: 24px;
    }
  `}

  ${({ size, iconPos, isIcon }) =>
    size === 'md' &&
    `
    height: 42px;
    ${isIcon ? 'width: 42px;' : 'padding: 0 14px;'}

    svg, img {
      height: 22px;

      ${iconPos &&
        `
        margin-right: ${!isIcon && (iconPos === 'left' ? '6px' : '-4px')};
        margin-left: ${!isIcon && (iconPos === 'right' ? '6px' : '-4px')};
      `}
    }

    img {
      width: 22px;
    }
  `}

  ${({ size, iconPos, isIcon }) =>
    size === 'sm' &&
    `
    height: 38px;
    ${isIcon ? 'width: 38px;' : 'padding: 0 10px;'}

    svg, img {
      height: 20px;
      
      ${iconPos &&
        `
        margin-right: ${!isIcon && (iconPos === 'left' ? '6px' : '-2px')};
        margin-left: ${!isIcon && (iconPos === 'right' ? '6px' : '-2px')};
      `}
    }

    img {
      width: 20px;
    }
  `}

  ${({ variant, theme }) =>
    variant === 'neutral' &&
    `
    color: ${theme.color.gray[600]};
    background-color: ${theme.color.gray[100]};

    &:hover {
      color: ${theme.color.gray[700]};
      background-color: ${theme.color.gray[200]};
    }
  `}

  ${({ variant, theme }) =>
    variant === 'destructive' &&
    `
    color: ${theme.color.red[700]};
    background-color: ${theme.color.red[50]};
    border: 1px solid ${theme.color.red[200]};

    &:hover {
      color: ${theme.color.red[800]};
      background-color: ${theme.color.red[100]};
    }
  `}

  ${({ variant, theme }) =>
    variant === 'destructive-solid' &&
    `
    color: ${theme.color.background};
    background-color: ${theme.color.red[700]};
    border: 1px solid ${theme.color.red[700]};

    &:hover {
      color: ${theme.color.background};
      background-color: ${theme.color.red[800]};
      border: 1px solid ${theme.color.red[800]};
    }
  `}

  ${({ variant, theme }) =>
    variant === 'emphasis' &&
    `
    color: white;
    background-color: ${theme.color.brand};

    &:hover {
      color: white;
      background-color: ${theme.color.brandDark};
    }
  `}

  ${({ variant, theme }) =>
    variant === 'emphasis-tone' &&
    `
    color: ${theme.color.brand};
    background-color: ${theme.color.brandLight};

    &:hover {
      color: white;
      background-color: ${theme.color.brandDark};
    }
  `}

  ${({ variant, theme }) =>
    variant === 'emphasis-outline' &&
    `
    color: ${theme.color.brand};
    background-color: transparent;
    border: 1px solid ${theme.color.brand};

    &:hover {
      background-color: ${theme.color.brandLight};
    }
  `}

  ${({ variant, theme }) =>
    variant === 'outline' &&
    `
    color: ${theme.color.gray[600]};
    background-color: ${theme.color.background};
    border: 1px solid ${theme.color.gray[300]};

    &:hover {
      color: ${theme.color.gray[700]};
      background-color: ${theme.color.gray[100]};
    }
  `}

  &:focus-visible {
    box-shadow: 0 0 0 3px ${({ theme }) => theme.color.background}, 0 0 0 6px ${({ theme }) =>
  theme.color.brand};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.color.gray[100]};
    color: ${({ theme }) => theme.color.gray[400]};
    border-color: ${({ theme }) => theme.color.gray[100]};

    &:hover {
      cursor: not-allowed;
    }
  }
`

export default StyledButton
