import styled from 'styled-components'

const StyledUpdatePasswordDialog = styled.div`
  width: 100vw;
  padding: 10px 28px 0 28px;
  box-sizing: border-box;

  .dialog-header {
    display: flex;
    align-items: center;

    .dialog-title {
      font-weight: 500;
      font-size: 20px;
      color: ${({ theme }) => theme.color.gray[800]};
    }
  }

  form {
    margin-top: 20px;

    .input:not(:first-child) {
      margin-top: 14px;
    }
  }

  .dialog-actions {
    margin-top: 28px;
    display: flex;

    button {
      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    padding: 28px;
    max-width: 375px;
  }
`

export default StyledUpdatePasswordDialog
