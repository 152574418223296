import { FC, useCallback, useEffect, useState } from 'react'
import { useActiveObject, useEditor } from '@layerhub-io/react'
import { ReactComponent as ItalicIcon } from '../../../assets/italic-icon.svg'
import { getTextProperties, loadFonts } from '../../../utils'
import { DesignerToolButton } from '.'

const DesignerItalicTool: FC = () => {
  const editor = useEditor()
  const activeObject = useActiveObject() as any
  const [italic, setItalic] = useState(false)
  const [hasItalic, setHasItalic] = useState(true)

  const toggleItalic = useCallback(async () => {
    const { fontOptions, isBold } = getTextProperties(activeObject)
    let fontToChangeTo: any = {}

    if (italic && isBold) {
      const font = fontOptions.find((option) => {
        const postScriptNames = option.post_script_name.split('-')
        return postScriptNames[postScriptNames.length - 1].match(/^Bold$/)
      })

      fontToChangeTo = {
        name: font?.post_script_name,
        url: font?.url
      }
    }

    if (italic && !isBold) {
      const font = fontOptions.find((option) => {
        const postScriptNames = option.post_script_name.split('-')
        return postScriptNames[postScriptNames.length - 1].match(/^Regular$/)
      })

      fontToChangeTo = {
        name: font?.post_script_name,
        url: font?.url
      }
    }

    if (!italic && isBold) {
      const font = fontOptions.find((option) => {
        const postScriptNames = option.post_script_name.split('-')
        return postScriptNames[postScriptNames.length - 1].match(/^BoldItalic$/)
      })

      fontToChangeTo = {
        name: font?.post_script_name,
        url: font?.url
      }
    }

    if (!italic && !isBold) {
      const font = fontOptions.find((option) => {
        const postScriptNames = option.post_script_name.split('-')
        return postScriptNames[postScriptNames.length - 1].match(/^Italic$/)
      })

      fontToChangeTo = {
        name: font?.post_script_name,
        url: font?.url
      }
    }

    await loadFonts([fontToChangeTo])

    editor.objects.update({
      fontFamily: fontToChangeTo.name,
      fontURL: fontToChangeTo.url
    })

    setItalic(!italic)
  }, [editor, activeObject, italic])

  const handleItalic = () => {
    if (!activeObject || activeObject.type !== 'StaticText') {
      return
    }

    const { isItalic, hasItalic } = getTextProperties(activeObject)

    setItalic(isItalic)
    setHasItalic(hasItalic)
  }

  useEffect(() => {
    handleItalic()

    if (editor) {
      editor.on('history:changed', handleItalic)
    }
    return () => {
      if (editor) {
        editor.off('history:changed', handleItalic)
      }
    }
  }, [editor, activeObject])

  if (!hasItalic) {
    return null
  }

  return (
    <DesignerToolButton
      data-tooltip={'Italic'}
      isIcon
      isActive={italic}
      onClick={() => toggleItalic()}
    >
      <ItalicIcon />
    </DesignerToolButton>
  )
}

export default DesignerItalicTool
