import styled from 'styled-components'

const StyledBillingView = styled.div`
  .billing-view-header {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};

    h1 {
      font-size: 22px;
      font-weight: 500;
      font-family: ${({ theme }) => theme.typeface.garnett};
      color: ${({ theme }) => theme.color.gray[800]};
    }

    h2 {
      font-size: 16px;
      font-weight: 400;
      color: ${({ theme }) => theme.color.gray[500]};
    }
  }

  .billing-cards {
    display: grid;
    grid-gap: 20px;
    user-select: none;

    .billing-card {
      border-radius: 8px;
      display: grid;
      align-content: space-between;
      box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), 0 0 0 1px rgb(0, 0, 0, 0.05),
        0 3px 4px rgb(0, 0, 0, 0.06);

      .billing-card-content-group {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .billing-card-content {
        padding: 20px;

        .billing-card-details {
          .billing-card-title {
            display: flex;
            align-items: center;
            font-weight: 500;
            color: ${({ theme }) => theme.color.gray[800]};

            .billing-card-freq {
              font-size: 14px;
              font-weight: 500;
              padding: 4px 8px;
              border-radius: 50rem;
              margin-left: 8px;
              background-color: ${({ theme }) => theme.color.brandLight};
              color: ${({ theme }) => theme.color.brand};
            }
          }

          .billing-card-description {
            font-size: 15px;
            margin-top: 4px;
            display: block;
            color: ${({ theme }) => theme.color.gray[500]};
          }
        }

        .billing-card-price {
          font-size: 15px;
          display: block;
          color: ${({ theme }) => theme.color.gray[500]};

          mark {
            background-color: transparent;
            font-weight: 400;
            color: ${({ theme }) => theme.color.gray[700]};
            font-size: 26px;
            margin-right: 2px;
          }
        }
      }

      .billing-card-card-details-container {
        padding: 12px;
        border-radius: 8px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid ${({ theme }) => theme.color.gray[200]};

        .billing-card-card-icon {
          flex-shrink: 0;
          margin-right: 12px;
          width: 56px;
          display: flex;
          align-items: center;
          border-radius: 4px;

          svg {
            width: 100%;
            height: 100%;
          }
        }

        .billing-card-empty-state {
          height: 38px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          color: ${({ theme }) => theme.color.gray[500]};
          font-weight: 500;
          font-size: 15px;
        }

        .billing-card-card-details {
          span {
            display: block;
            font-size: 15px;
          }

          .card-number {
            color: ${({ theme }) => theme.color.gray[700]};
            font-weight: 500;
          }

          .card-expiry {
            color: ${({ theme }) => theme.color.gray[500]};
            margin-top: 2px;
          }
        }

        .btn {
          padding: 0 16px;
        }
      }

      .billing-card-footer {
        padding: 6px 10px;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid ${({ theme }) => theme.color.gray[200]};

        .btn {
          background-color: transparent;
          border: 0;
        }
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.m}px) {
    .billing-view-header {
      margin-bottom: 40px;
      padding-top: 20px;
    }

    .billing-cards {
      grid-template-columns: 1fr 1fr;
    }
  }
`

export default StyledBillingView
