import styled from 'styled-components'
import { SocialProfileType } from '../../../types'

type StyledProps = {
  type: SocialProfileType['type']
  size: number
  inRow?: boolean
}

const StyledConnectionAvatar = styled.div<StyledProps>`
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  font-size: ${({ size }) => size * 0.381}px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  border-radius: 50%;
  flex-shrink: 0;
  position: relative;
  ${({ inRow, size, theme }) =>
    inRow &&
    `
    box-shadow: 0 0 0 3px ${theme.color.background};

    &:not(:first-child) {
      margin-left: -${size * 0.2}px;
    }
  `}

  .avatar-img {
    height: ${({ size }) => size - 8}px;
    width: ${({ size }) => size - 8}px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: relative;
    pointer-events: none;
    font-weight: 500;
    background-color: ${({ theme }) => theme.color.brandLight};
    font-family: ${({ theme }) => theme.typeface.faktumXCon};
    color: ${({ theme }) => theme.color.brand};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.color.background};

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.foreground}20;
    }
  }

  .platform-indicator {
    position: absolute;
    bottom: -2px;
    right: -2px;
    height: ${({ size }) => size * 0.524}px;
    width: ${({ size }) => size * 0.524}px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${({ size }) => size * 0.1}px;

    svg {
      color: ${({ theme }) => theme.color.gray[600]} !important;
      height: ${({ size }) => size * 0.4}px !important;
      margin: 0 !important;
      stroke: ${({ theme }) => theme.color.background};
      stroke-width: 6px;
      paint-order: stroke;
      overflow: unset;
    }
  }

  .expired-indicator {
    position: absolute;
    bottom: -2px;
    right: -2px;
    height: ${({ size }) => size * 0.524}px;
    width: ${({ size }) => size * 0.524}px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.color.red[50]};
    color: ${({ theme }) => theme.color.red[800]};
    border-radius: 50%;

    svg {
      color: ${({ theme }) => theme.color.red[800]} !important;
      height: ${({ size }) => size * 0.524}px !important;
      margin: 0 !important;
    }
  }

  ${({ type }) =>
    type === 'instagram' &&
    `
    background: linear-gradient(
      90deg,
      #406ac9 0%,
      #863ac8 20%,
      #c635ad 40%,
      #f04c5b 60%,
      #ff9f4b 80%,
      #ffc851 100%
    );
  `}
  ${({ type }) =>
    type === 'facebook' &&
    `
    background: linear-gradient(90deg, #17aafd 0%, #0165e1 100%);
  `}
  ${({ type }) =>
    type === 'linkedin' &&
    `
    background: #007ebb;
  `}
  ${({ type }) =>
    type === 'twitter' &&
    `
    background: #1da1f2;
  `}
`

export default StyledConnectionAvatar
