import { FC } from 'react'
import { DateTime } from 'luxon'
import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { StyledHomeActivity } from '.'

type Props = {
  streak: number
  week: boolean[]
}

const HomeActivity: FC<Props> = ({ streak, week }) => {
  return (
    <StyledHomeActivity className="card">
      <div className="streak-label-container">
        <span className="streak-label">Post streak</span>
        <span
          className="streak-count"
          data-tooltip="Publish at least one post per day to continue your streak."
        >
          {streak || 0}-day streak
          <QuestionMarkCircleIcon />
        </span>
      </div>
      <div className="streak-status">
        {week.map((isCompleted, index) => {
          const today = DateTime.now().weekday - 1
          const isPast = today > index
          const isToday = today === index
          const day = ['M', 'T', 'W', 'T', 'F', 'S', 'S'][index]
          return (
            <div
              data-completed={isCompleted}
              data-past={isPast}
              data-today={isToday}
              className="streak-day"
              key={index}
            >
              <div className="streak-day-bubble">
                {isPast && !isCompleted && <XMarkIcon />}
                {isCompleted && <CheckIcon />}
              </div>
              <div className="streak-day-label">{day}</div>
            </div>
          )
        })}
      </div>
    </StyledHomeActivity>
  )
}

export default HomeActivity
