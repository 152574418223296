import { FC } from 'react'
import { DateTime } from 'luxon'
import { CalendarIcon } from '@heroicons/react/24/outline'
import { setShowPlanScheduleDialog, useAppDispatch, useAppSelector } from '../../../redux'
import { EditorBlock } from '..'
import { StyledEditorSchedule } from '.'

const EditorSchedule: FC = () => {
  const dispatch = useAppDispatch()
  const { post, posts } = useAppSelector((state) => state.plan)

  const getParsedDay = (date: DateTime) => {
    const parsedDate = date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    const today = DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    const diff = parsedDate.diff(today, 'days').days

    console.log(diff)

    if (diff < 1 && diff >= 0) {
      return date.toFormat("'Today at' h:mma")
    }

    if (diff < 2 && diff >= 1) {
      return date.toFormat("'Tomorrow at' h:mma")
    }

    if (diff < 0 && diff >= -1) {
      return date.toFormat("'Yesterday at' h:mma")
    }

    return date.toFormat("LLLL d 'at' h:mma")
  }

  return (
    <EditorBlock title="Scheduling options">
      <StyledEditorSchedule>
        <button
          className="date-picker-btn"
          onClick={() => dispatch(setShowPlanScheduleDialog(true))}
        >
          <CalendarIcon />
          {!posts[post]?.postDate && <span className="placeholder">Pick a date</span>}
          {!!posts[post]?.postDate && (
            <span>{getParsedDay(DateTime.fromISO(posts[post].postDate))}</span>
          )}
        </button>
        {/* <div className="date-suggestions">
          <button className="date-suggestion">
            <b>Today, 11/16</b>
            <span>4:00PM</span>
          </button>
          <button className="date-suggestion">
            <b>Tomorrow, 11/17</b>
            <span>8:00AM</span>
          </button>
          <button
            className="date-suggestion pick-btn"
            onClick={() => dispatch(setShowPlanScheduleDialog(true))}
          >
            <b>Pick a date</b>
          </button>
        </div> */}
      </StyledEditorSchedule>
    </EditorBlock>
  )
}

export default EditorSchedule
