import { FC, useState } from 'react'
import { PlusIcon } from '@heroicons/react/24/outline'
import { updatePlanPost, useAppDispatch, useAppSelector } from '../../../redux'
import { PickFilesDialog } from '../..'
import { EditorBlock, EditorMediaList } from '..'
import { StyledEditorMedia } from '.'

const EditorMedia: FC = () => {
  const dispatch = useAppDispatch()
  const [showPickDialog, setShowPickDialog] = useState(false)
  const { post, posts } = useAppSelector((state) => state.plan)

  return (
    <>
      <EditorBlock title="Media">
        <StyledEditorMedia>
          <EditorMediaList />
          <button className="add-btn" onClick={() => setShowPickDialog(true)}>
            <PlusIcon /> Add visual
          </button>
        </StyledEditorMedia>
      </EditorBlock>
      <PickFilesDialog
        onConfirm={(media) =>
          dispatch(
            updatePlanPost({
              _id: posts[post]._id || '',
              post: { ...posts[post], media: [...(posts[post].media || []), ...media] }
            })
          )
        }
        isOpen={showPickDialog}
        onClose={() => setShowPickDialog(false)}
      />
    </>
  )
}

export default EditorMedia
