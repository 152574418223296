import styled from 'styled-components'

const StyledCalendarWeek = styled.div`
  overflow-y: auto;
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  background-color: ${({ theme }) => theme.color.gray[50]};

  .calendar-time-list {
    width: 46px;
    border-right: 1px solid ${({ theme }) => theme.color.gray[200]};
  }
`

export default StyledCalendarWeek
