import styled from 'styled-components'

const StyledTabs = styled.div`
  position: relative;
  height: 54px;
  display: flex;
  align-items: center;
  box-shadow: inset 0 -1px ${({ theme }) => theme.color.gray[300]};

  .tab-list {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    height: 100%;
    padding: 0 20px;

    .tab-scroll {
      overflow-x: auto;
      overflow-y: hidden;
      display: flex;
      height: 100%;
      width: 100%;
    }

    .tab-list-extra {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      padding-left: 12px;
    }
  }
`

export default StyledTabs
