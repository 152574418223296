import { FC, useContext } from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
import { AtSymbolIcon } from '@heroicons/react/24/outline'
import { getPlatformIcon } from '../../../utils'
import { ConnectionSidebarProfile, connectionStore } from '..'
import { StyledConnectionSidebar } from '.'

const ConnectionSidebar: FC = () => {
  const { connectionLimit, activeProfiles, expiredProfiles, platformCounts } =
    useContext(connectionStore)

  return (
    <StyledConnectionSidebar>
      <div className="sidebar-top">
        {!!expiredProfiles.length && (
          <div className="sidebar-group expired">
            <span className="sidebar-group-label">
              <ExclamationCircleIcon />
              Expired connections
            </span>
            <ul className="sidebar-group-profile-list">
              {expiredProfiles.map((profile) => {
                return <ConnectionSidebarProfile profile={profile} key={profile._id} />
              })}
            </ul>
          </div>
        )}
        {!!activeProfiles.length && (
          <div className="sidebar-group">
            <span className="sidebar-group-label">
              <AtSymbolIcon />
              Connected profiles
            </span>
            <ul className="sidebar-group-profile-list">
              {activeProfiles.map((profile) => {
                return <ConnectionSidebarProfile profile={profile} key={profile._id} />
              })}
            </ul>
          </div>
        )}
        {/* <div className="sidebar-group">
          <span className="sidebar-group-label">
            <BuildingStorefrontIcon />
            Available platforms
          </span>
          <ConnectionPlatformList />
        </div> */}
      </div>
      <div className="sidebar-bottom">
        <div className="connection-limits">
          <div
            className="connection-limit"
            data-tooltip={`${platformCounts.instagram}/${connectionLimit} Instagram profiles`}
            data-tooltip-pos="top-start"
          >
            {getPlatformIcon('instagram')}
            {platformCounts.instagram}/{connectionLimit}
          </div>
          <div
            className="connection-limit"
            data-tooltip={`${platformCounts.facebook}/${connectionLimit} Facebook profiles`}
            data-tooltip-pos="top-center"
          >
            {getPlatformIcon('facebook')}
            {platformCounts.facebook}/{connectionLimit}
          </div>
          <div
            className="connection-limit"
            data-tooltip={`${platformCounts.linkedin}/${connectionLimit} LinkedIn profiles`}
            data-tooltip-pos="top-end"
          >
            {getPlatformIcon('linkedin')}
            {platformCounts.linkedin}/{connectionLimit}
          </div>
          <div
            className="connection-limit"
            data-tooltip={`${platformCounts.twitter}/${connectionLimit} Twitter profiles`}
            data-tooltip-pos="top-start"
          >
            {getPlatformIcon('twitter')}
            {platformCounts.twitter}/{connectionLimit}
          </div>
          {/* <div
            className="connection-limit"
            data-tooltip={`${platformCounts.pinterest}/${connectionLimit} Pinterest profiles`}
            data-tooltip-pos="top-center"
          >
            {getPlatformIcon('pinterest')}
            {platformCounts.pinterest}/{connectionLimit}
          </div>
          <div
            className="connection-limit"
            data-tooltip={`${platformCounts.tiktok}/${connectionLimit} TikTok profiles`}
            data-tooltip-pos="top-end"
          >
            {getPlatformIcon('tiktok')}
            {platformCounts.tiktok}/{connectionLimit}
          </div> */}
        </div>
      </div>
    </StyledConnectionSidebar>
  )
}

export default ConnectionSidebar
