import { DateTime } from 'luxon'

export const formateTimeToPost = (date: DateTime) => {
  const today = DateTime.now().startOf('day')
  const diff = Math.abs(date.startOf('day').diff(today, 'days').days)

  if (diff < 1) {
    return `${date.toFormat('h:mma')} Today`
  }

  if (diff < 2) {
    return `${date.toFormat('h:mma')} Tomorrow`
  }

  return date.toFormat('h:mma cccc')
}
