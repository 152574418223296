import { FC, createRef, useContext, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useReward } from 'react-rewards'
import { compact, uniq, xor } from 'lodash'
import { DateTime } from 'luxon'
import { useApolloClient } from '@apollo/client'
import { sendDraftNow } from '../../../graphql'
import { updateCreatePost, useAppDispatch, useAppSelector } from '../../../redux'
import { SocialProfileType } from '../../../types'
import { useForm, userStore } from '../../../utils'
import { Button, Dialog, Spinner } from '../..'
import { PublishHowField, PublishWhenField, PublishWhereField } from './fields'
import { StyledPublishDialog } from '.'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const PublishDialog: FC<Props> = ({ isOpen, onClose }) => {
  const apollo = useApolloClient()
  const dispatch = useAppDispatch()
  const dialogRef = createRef<HTMLDivElement>()
  const [loading, setLoading] = useState(false)
  const { post } = useAppSelector((state) => state.create)
  const { user } = useContext(userStore)
  const { form, updateFormValue, resetForm } = useForm({
    profiles: [],
    postDate: undefined,
    method: 'auto-publish'
  })

  const { reward } = useReward('confetti', 'confetti', {
    angle: -90,
    startVelocity: 18,
    spread: 220,
    elementCount: 150
  })

  const handleClose = () => {
    const dialog = dialogRef.current as any

    if (!dialog) {
      return onClose()
    }

    dialog.close()
  }

  const handlePostNow = async () => {
    try {
      const selectedProfilesStrings = form.profiles.map((profile: SocialProfileType) => profile._id)

      const { data } = await apollo.mutate({
        mutation: sendDraftNow,
        variables: {
          draft: post?._id,
          profiles: selectedProfilesStrings
        }
      })

      const failures = data?.sendDraftNow?.failures || []

      if (failures.length) {
        return failures.map((failure: string) => toast.error(failure, { duration: 6000 }))
      }

      dispatch(
        updateCreatePost({
          isUsed: true,
          socialProfiles: uniq([...selectedProfilesStrings, ...compact(post?.socialProfiles)]),
          postDate: DateTime.now().minus({ minute: 2 }).toISO()
        })
      )

      toast.success('Published successfully!')
      reward()
      handleClose()
    } catch (err) {
      toast.error((err as any).message)
    }
  }

  const handleSchedule = async () => {
    const selectedProfilesStrings = form.profiles.map((profile: SocialProfileType) => profile._id)

    dispatch(
      updateCreatePost({
        postDate: form.postDate ? form.postDate.toISO() : undefined,
        socialProfiles: selectedProfilesStrings || []
      })
    )

    handleClose()
  }

  const handleConfirm = async () => {
    try {
      setLoading(true)

      // if (form.postDate === 'now') {
      //   await handlePostNow()
      //   return
      // }

      await handlePostNow()
    } catch (err) {
      toast.error((err as any).message)
    } finally {
      setLoading(false)
    }
  }

  const getConfirmButtonLabel = () => {
    const publishNow = form?.postDate === 'now'
    const postHasDate = !!post?.postDate
    const addedDate = !!form?.postDate
    const addedProfiles = !!form?.profiles?.length

    if (publishNow) {
      return 'Publish now'
    }

    if (!addedDate && postHasDate) {
      return 'Remove date'
    }

    if (addedDate && !addedProfiles) {
      return 'Schedule email reminder'
    }

    if (addedDate && addedProfiles) {
      return 'Schedule auto-publish'
    }

    return 'Confirm'
  }

  useEffect(() => {
    updateFormValue({
      postDate: post?.postDate ? DateTime.fromISO(post?.postDate) : undefined,
      profiles: user?.socialProfiles?.filter((profile) =>
        post?.socialProfiles?.includes(profile._id || '')
      )
    })

    if (!isOpen) {
      resetForm()
    }
  }, [post?.socialProfiles, post?.postDate, isOpen])

  return (
    <Dialog ref={dialogRef} isOpen={isOpen} onClose={() => onClose()}>
      <StyledPublishDialog>
        <div className="dialog-content">
          {/* <PublishHowField profiles={form.profiles || []} /> */}
          <PublishWhereField
            profiles={form.profiles || []}
            onSelect={(profile) => updateFormValue('profiles', xor(form.profiles, [profile]))}
          />
          {/* <PublishWhenField
            postDate={form.postDate || undefined}
            onSelect={(postDate) => updateFormValue('postDate', postDate)}
          /> */}
        </div>
        <div className="dialog-actions">
          <Button variant="outline" disabled={loading} onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button
            variant="emphasis"
            // disabled={(!form.postDate && !post?.postDate) || loading}
            disabled={loading || !form?.profiles?.length}
            onClick={() => handleConfirm()}
          >
            {loading && <Spinner />}
            {!loading && 'Publish Now'}
            {/* {!loading && getConfirmButtonLabel()} */}
          </Button>
        </div>
      </StyledPublishDialog>
    </Dialog>
  )
}

export default PublishDialog
