import { FC } from 'react'
import { useAppSelector } from '../../../redux'
import {
  CreateBarChat,
  CreateBarContent,
  CreateBarHashtags,
  CreateBarSwitcher,
  CreateBarTasks,
  CreateBarUploads
} from '..'
import { StyledCreateBar } from '.'

const CreateBar: FC = () => {
  const { tab } = useAppSelector((state) => state.create)

  return (
    <StyledCreateBar>
      <CreateBarSwitcher />
      {tab === 'chat' && <CreateBarChat />}
      {tab === 'uploads' && <CreateBarUploads />}
      {tab === 'content' && <CreateBarContent />}
      {tab === 'hashtags' && <CreateBarHashtags />}
      {tab === 'tasks' && <CreateBarTasks />}
    </StyledCreateBar>
  )
}

export default CreateBar
