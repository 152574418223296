import { FC } from 'react'
import { DndContext, DragEndEvent } from '@dnd-kit/core'
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { SortableContext, arrayMove } from '@dnd-kit/sortable'
import { updatePlanPost, useAppDispatch, useAppSelector } from '../../../redux'
import { useSensors } from '../../../utils'
import { EditorMediaListItem } from '..'
import { StyledEditorMediaList } from '.'

const EditorMediaList: FC = () => {
  const sensors = useSensors()
  const dispatch = useAppDispatch()
  const { post, posts } = useAppSelector((state) => state.plan)

  const handleReorder = (sort: DragEndEvent) => {
    if (!posts[post]?.media) {
      return
    }

    const { active, over } = sort

    if (active.id === over?.id) {
      return
    }

    const oldIndex = active.data.current?.index || 0
    const newIndex = over?.data.current?.index || 0

    const sortedMedia = arrayMove(posts[post]?.media || [], oldIndex, newIndex)

    dispatch(
      updatePlanPost({
        _id: posts[post]?._id!,
        post: {
          media: sortedMedia
        }
      })
    )
  }

  if (!posts[post]?.media?.length) {
    return null
  }

  return (
    <DndContext
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      sensors={sensors}
      onDragEnd={(event) => handleReorder(event)}
    >
      <SortableContext items={posts[post]!.media!.map((m) => m._id)}>
        <StyledEditorMediaList>
          {posts[post]!.media!.map((file, index) => {
            return (
              <EditorMediaListItem
                item={{
                  ...file,
                  uploaded: true,
                  ref: file._id
                }}
                index={index}
                onDelete={() =>
                  dispatch(
                    updatePlanPost({
                      _id: posts[post]?._id!,
                      post: {
                        media: posts[post].media?.filter((m) => m._id !== file._id)
                      }
                    })
                  )
                }
                key={file._id}
              />
            )
          })}
        </StyledEditorMediaList>
      </SortableContext>
    </DndContext>
  )
}

export default EditorMediaList
