export const draftCategories = [
  'Uncategorized',
  'Behind the Scenes',
  'My Why',
  'Personal Insight',
  'Encouragement',
  'About Me',
  'Benefits',
  'Showcase Product/Service',
  'Holiday'
]

export const ORG_COUNTS = [
  {
    label: '1',
    value: '1'
  },
  {
    label: '2',
    value: '2'
  },
  {
    label: '3',
    value: '3'
  },
  {
    label: '4',
    value: '4'
  },
  {
    label: '5+',
    value: '5+'
  }
]

export const OCCUPATIONS = [
  {
    label: 'Solopreneur',
    value: 'solopreneur'
  },
  {
    label: 'Entrepreneur',
    value: 'entrepreneur'
  },
  {
    label: 'Social Media Marketing Manager',
    value: 'social media marketing manager'
  },
  {
    label: 'Freelancer',
    value: 'freelancer'
  },
  {
    label: 'Content Manager',
    value: 'content manager'
  },
  {
    label: 'Social Media Analyst',
    value: 'social media analyst'
  },
  {
    label: 'Influencer',
    value: 'influencer'
  },
  {
    label: 'Other',
    value: 'other'
  }
]

export const INDUSTRIES = [
  {
    text: 'Health and Wellness',
    value: 'Health and Wellness',
    emoji: {
      symbol: '💪🏻',
      label: 'flexed biceps'
    }
  },
  {
    text: 'Education or Course Creation',
    value: 'Education or Course Creation',
    emoji: {
      symbol: '🎓',
      label: 'graduation cap'
    }
  },
  {
    text: 'Fashion, Jewelry, or Accessories',
    value: 'Fashion, Jewelry, or Accessories',
    emoji: {
      symbol: '💅',
      label: 'nail polish'
    }
  },
  {
    text: 'Hair and Beauty',
    value: 'Hair and Beauty',
    emoji: {
      symbol: '💇‍♂️',
      label: 'haircut'
    }
  },
  {
    text: 'Restaurant or Food Service',
    value: 'Restaurant or Food Service',
    emoji: {
      symbol: '🍛',
      label: 'curry rice'
    }
  },
  {
    text: 'Wedding Photography or Videography',
    value: 'Wedding Photography or Videography',
    emoji: {
      symbol: '💐',
      label: 'bouquet'
    }
  },
  {
    text: 'Lifestyle Photography or Videography',
    value: 'Lifestyle Photography or Videography',
    emoji: {
      symbol: '📷',
      label: 'camera'
    }
  },
  {
    text: 'Life and Relationship Coaching',
    value: 'Life and Relationship Coaching',
    emoji: {
      symbol: '👩🏻‍🏫',
      label: 'teacher'
    }
  },
  {
    text: 'Business and Marketing Coaching',
    value: 'Business and Marketing Coaching',
    emoji: {
      symbol: '🏫',
      label: 'school'
    }
  },
  {
    text: 'Wedding and Event Planning',
    value: 'Wedding and Event Planning',
    emoji: {
      symbol: '💍',
      label: 'wedding ring'
    }
  },
  {
    text: 'Real Estate',
    value: 'Real Estate',
    emoji: {
      symbol: '🏡',
      label: 'house'
    }
  },
  {
    text: 'Travel Planning',
    value: 'Travel Planning',
    emoji: {
      symbol: '✈️',
      label: 'plane'
    }
  },
  {
    text: 'Finance or Bookkeeping',
    value: 'Finance or Bookkeeping',
    emoji: {
      symbol: '💰',
      label: 'money bag'
    }
  },
  {
    text: 'Virtual Assistant',
    value: 'Virtual Assistant',
    emoji: {
      symbol: '🤳🏼',
      label: 'selfie'
    }
  },
  {
    text: 'Graphic and Web Design',
    value: 'Graphic and Web Design',
    emoji: {
      symbol: '🖥',
      label: 'computer monitor'
    }
  },
  {
    text: 'Social Media Management and Copywriting',
    value: 'Social Media Management and Copywriting',
    emoji: {
      symbol: '📱',
      label: 'phone'
    }
  },
  {
    text: 'Interior Design',
    value: 'Interior Design',
    emoji: {
      symbol: '🛋',
      label: 'couch'
    }
  },
  {
    text: 'Musician or Artist',
    value: 'Musician or Artist',
    emoji: {
      symbol: '🎼',
      label: 'music notes'
    }
  },
  {
    text: 'Influencing and Blogging',
    value: 'Influencing and Blogging',
    emoji: {
      symbol: '💁‍♀️',
      label: 'person tipping hand'
    }
  },
  {
    text: 'Author or Speaker',
    value: 'Author or Speaker',
    emoji: {
      symbol: '🎤',
      label: 'microphone'
    }
  },
  {
    text: 'Medical or Dental Care',
    value: 'Medical or Dental Care',
    emoji: {
      symbol: '🏥',
      label: 'hospital'
    }
  },
  {
    text: 'Animal Care',
    value: 'Animal Care',
    emoji: {
      symbol: '🐋',
      label: 'whale'
    }
  },
  {
    text: 'Other',
    value: 'Other',
    emoji: null
  }
]

export const PHOTO_CATEGORIES = [
  {
    value: 'animals',
    label: 'Animals'
  },
  {
    value: 'architecture',
    label: 'Architecture'
  },
  {
    value: 'celebration',
    label: 'Celebration'
  },
  {
    value: 'desk_office',
    label: 'Desk + Office'
  },
  {
    value: 'fabric_textures',
    label: 'Fabric + Textures'
  },
  {
    value: 'fashion',
    label: 'Fashion'
  },
  {
    value: 'flatlay',
    label: 'Flatlay'
  },
  {
    value: 'flowers_plants',
    label: 'Flowers + Plants'
  },
  {
    value: 'food_drink',
    label: 'Food + Drink'
  },
  {
    value: 'health_wellness',
    label: 'Health + Wellness'
  },
  {
    value: 'home_interior',
    label: 'Home Interior'
  },
  {
    value: 'nature',
    label: 'Nature'
  },
  {
    value: 'negative_space',
    label: 'Negative Space'
  },
  {
    value: 'outdoor_recreation',
    label: 'Outdoor Recreation'
  },
  {
    value: 'people',
    label: 'People'
  },
  {
    value: 'personal_care',
    label: 'Personal Care'
  },
  {
    value: 'phones_screens',
    label: 'Phones + Screens'
  },
  {
    value: 'quotes_signs',
    label: 'Quotes + Signs'
  },
  {
    value: 'stationary',
    label: 'Stationery'
  },
  {
    value: 'travel',
    label: 'Travel'
  }
]

export const imageStyles = [
  {
    label: 'Light & Airy',
    value: 'light_airy'
  },
  {
    label: 'Dark & Moody',
    value: 'dark_moody'
  },
  {
    label: 'Vibrant',
    value: 'vibrant'
  }
]

export const imageOrientations = [
  {
    value: 'vertical',
    label: 'Vertical'
  },
  {
    value: 'horizontal',
    label: 'Horizontal'
  }
]

export const imageColors = [
  {
    value: '_ffffff',
    label: 'White'
  },
  {
    value: '_EBB7BE',
    label: 'Pink'
  },
  {
    value: '_C2152C',
    label: 'Red'
  },
  {
    value: '_F2AE7C',
    label: 'Orange'
  },
  {
    value: '_EBE291',
    label: 'Yellow'
  },
  {
    value: '_EDD5A6',
    label: 'Beige'
  },
  {
    value: '_864B15',
    label: 'Brown'
  },
  {
    value: '_83C996',
    label: 'Light Green'
  },
  {
    value: '_126F10',
    label: 'Green'
  },
  {
    value: '_C9E1F3',
    label: 'Light Blue'
  },
  {
    value: '_2F5CCF',
    label: 'Blue'
  },
  {
    value: '_B698DC',
    label: 'Purple'
  },
  {
    value: '_B1B1B1',
    label: 'Grey'
  },
  {
    value: '_000000',
    label: 'Black'
  },
  {
    value: 'multi',
    label: 'Multi'
  }
]
