import { FC } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../redux'
import { HashtagFolderEdit, HashtagFolderList } from '..'
import { StyledHashtagBank } from '.'

const HashtagBank: FC = () => {
  const dispatch = useAppDispatch()
  const { folder } = useAppSelector((state) => state.hashtags)

  if (folder) {
    return <HashtagFolderEdit />
  }

  return (
    <StyledHashtagBank>
      <HashtagFolderList />
      {/* <div className="hashtag-bank-folders scrollable">
        
      </div> */}
      {/* <div className="hashtag-bank-actions">
        <button onClick={() => dispatch(setHashtagFolder('new'))}>
          <PlusIcon />
          Create Hashtag Set
        </button>
      </div> */}
    </StyledHashtagBank>
  )
}

export default HashtagBank
