import { FC, useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import toast from 'react-hot-toast'
import { v4 as uuid } from 'uuid'
import { useApolloClient } from '@apollo/client'
import { Banner, Button, PageLayout, PillarList, Spinner } from '../../components'
import { contentPillarDeleteMany, contentPillarUpsertMany } from '../../graphql'
import { useAppDispatch } from '../../redux'
import { ContentPillarType } from '../../types'
import { userStore } from '../../utils'
import { StyledPillarsView } from '.'

const PillarsView: FC = () => {
  const apollo = useApolloClient()
  const [loading, setLoading] = useState(false)
  const [pillars, setPillars] = useState<ContentPillarType[]>([])
  const { user, refresh } = useContext(userStore)

  const handleRemove = async (_id: string) => {
    try {
      setLoading(true)

      setPillars(pillars.filter((p) => p._id !== _id))

      await apollo.mutate({
        fetchPolicy: 'no-cache',
        mutation: contentPillarDeleteMany,
        variables: {
          ids: [_id]
        }
      })

      await refresh()
    } catch (err) {
      console.error(err)
      toast.error((err as any)?.message || err)
    } finally {
      setLoading(false)
    }
  }

  const handleSave = async () => {
    try {
      setLoading(true)

      await apollo.mutate({
        fetchPolicy: 'no-cache',
        mutation: contentPillarUpsertMany,
        variables: {
          pillars
        }
      })

      await refresh()
    } catch (err) {
      console.error(err)
      toast.error((err as any)?.message || err)
    } finally {
      setLoading(false)
    }
  }

  const handleAdd = async (pillar: Omit<ContentPillarType, '_id'>) => {
    try {
      setLoading(true)
      const ID = uuid()
      setPillars([...pillars, { _id: ID, ...pillar }])

      const { data } = await apollo.mutate({
        fetchPolicy: 'no-cache',
        mutation: contentPillarUpsertMany,
        variables: {
          contentPillars: [pillar]
        }
      })

      const newId = data.contentPillarUpsertMany[0]?._id

      setPillars(
        pillars.map((p) => {
          if (p._id === ID) {
            return {
              ...p,
              _id: newId
            }
          }
          return p
        })
      )

      await refresh()
    } catch (err) {
      console.error(err)
      toast.error((err as any)?.message || err)
    } finally {
      setLoading(false)
    }
  }

  const canSave = () => {
    return JSON.stringify(user?.contentPillars || []) !== JSON.stringify(pillars)
  }

  useEffect(() => {
    setPillars(user?.contentPillars || [])
  }, [user?.contentPillars])

  return (
    <PageLayout maxWidth={800}>
      <Helmet title="Pillars | Social Curator" />
      <StyledPillarsView>
        <div className="header">
          <div className="header-details">
            <h1>Content Pillars</h1>
            <h2>Content Pillars help you create engaging social media content.</h2>
          </div>
          {canSave() && (
            <div className="header-actions">
              <Button disabled={loading} onClick={() => setPillars(user?.contentPillars || [])}>
                Revert
              </Button>
              <Button variant="emphasis" disabled={loading} onClick={() => handleSave()}>
                {loading ? <Spinner /> : 'Save changes'}
              </Button>
            </div>
          )}
        </div>
        <PillarList
          pillars={pillars}
          onChange={(_id, pillar) =>
            setPillars(
              pillars.map((p) => {
                if (p._id === pillar._id) {
                  return {
                    ...p,
                    ...pillar
                  }
                }

                return p
              })
            )
          }
          onRemove={(_id) => handleRemove(_id)}
          onAdd={(pillar) => handleAdd(pillar)}
        />
      </StyledPillarsView>
    </PageLayout>
  )
}

export default PillarsView
