import { FC, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { ReactComponent as ProfileCogIcon } from '../../../assets/profile-cog-icon.svg'
import { setShowChatHistory, useAppDispatch, useAppSelector } from '../../../redux'
import { ROUTES, useSegment } from '../../../utils'
import { Button } from '../..'
import { StyledChatHeader } from '.'

const ChatHeader: FC = () => {
  const dispatch = useAppDispatch()
  const { showHistory } = useAppSelector((state) => state.chat)
  const { splits } = useAppSelector((state) => state.splits)
  const { track } = useSegment()

  const handleHelp = () => {
    track('Clicked Chat Help Button')
  }

  const handleToggleChatHistory = () => {
    track('Toggled Chat History', { to_show: !showHistory })
    dispatch(setShowChatHistory(!showHistory))
  }

  return (
    <StyledChatHeader data-chat-handle>
      <div className="chat-header-group">
        <Button
          data-tooltip={showHistory ? 'Hide chat history' : 'Show chat history'}
          data-tooltip-pos="bottom-start"
          size="sm"
          isIcon
          onClick={() => handleToggleChatHistory()}
        >
          {showHistory ? <XMarkIcon /> : <Bars3Icon />}
        </Button>
        <div className="chat-bot-details">
          <span className="chat-bot-name">Dottie</span>
          <span className="chat-bot-status">
            <div />
            Online
          </span>
        </div>
      </div>
      <div className="chat-header-group">
        <Button
          className="help-me-btn"
          data-tooltip="Contact Support"
          size="sm"
          onClick={() => handleHelp()}
        >
          Help me
        </Button>
        {splits.personas === 'on' && (
          <Link to={ROUTES.settings.personas} onClick={() => track('Clicked Settings Button')}>
            <Button
              className="update-config-btn"
              data-tooltip="Update Profile"
              data-tooltip-pos="bottom-end"
              size="sm"
              isIcon
            >
              <ProfileCogIcon />
            </Button>
          </Link>
        )}
        {splits.personas !== 'on' && (
          <Link to={ROUTES.settings.account} onClick={() => track('Clicked Settings Button')}>
            <Button
              className="update-config-btn"
              data-tooltip="Update Profile"
              data-tooltip-pos="bottom-end"
              size="sm"
              isIcon
            >
              <ProfileCogIcon />
            </Button>
          </Link>
        )}
      </div>
    </StyledChatHeader>
  )
}

export default ChatHeader
