import { FC, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useParams } from 'react-router-dom'
import { uniqBy } from 'lodash'
import { useApolloClient } from '@apollo/client'
import { FolderPlusIcon } from '@heroicons/react/20/solid'
import { loadFilesFromCloud } from '../../../graphql'
import { setCloudFolders, useAppDispatch, useAppSelector } from '../../../redux'
import { getParentFromPath, useCloud } from '../../../utils'
import { InputDialog } from '../..'
import { UploadsFolderListHeader, UploadsFolderListItem } from '..'
import { StyledUploadsFolderList } from '.'

const UploadsFolderList: FC = () => {
  const apollo = useApolloClient()
  const dispatch = useAppDispatch()
  const { createFolder } = useCloud()
  const { path }: any = useParams()
  const { folders } = useAppSelector((state) => state.cloud)
  const [showNewFolderDialog, setShowNewFolderDialog] = useState(false)
  const [newFolderName, setNewFolderName] = useState('')
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)

  const loadData = async () => {
    try {
      setLoading(true)
      const { data } = await apollo.query({
        fetchPolicy: 'no-cache',
        query: loadFilesFromCloud,
        variables: {
          page,
          limit: 10,
          types: ['folder']
        }
      })

      const docs = data?.loadFilesFromCloud?.docs || []
      const total = data?.loadFilesFromCloud?.totalDocs || 0
      dispatch(setCloudFolders(uniqBy([...folders, ...docs], '_id')))
      setTotal(total)
    } catch (err) {
      console.log(err)
      toast.error((err as any).message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadData()
  }, [page])

  return (
    <>
      <StyledUploadsFolderList>
        <UploadsFolderListHeader />
        <div className="folder-scroll" id="uploads-folder-scroll">
          <InfiniteScroll
            scrollThreshold={0.7}
            dataLength={folders.length}
            next={() => setPage(page + 1)}
            hasMore={folders.length < total}
            loader={null}
            className="infinite-scroll"
            scrollableTarget="uploads-folder-scroll"
          >
            {folders.map((folder) => {
              return (
                <UploadsFolderListItem
                  folder={folder}
                  isActive={getParentFromPath(path) === folder.name}
                  key={folder._id}
                />
              )
            })}
          </InfiniteScroll>
        </div>
        <div className="add-folder-btn-container">
          <button onClick={() => setShowNewFolderDialog(true)}>
            <FolderPlusIcon />
            Add folder
          </button>
        </div>
      </StyledUploadsFolderList>
      <InputDialog
        isOpen={showNewFolderDialog}
        onChange={(value) => setNewFolderName(value.replaceAll('-', '').trimStart())}
        onClose={() => setShowNewFolderDialog(false)}
        onConfirm={() => createFolder(newFolderName)}
        title="Create a new folder"
        value={newFolderName}
        confirmButtonText="Create Folder"
      />
    </>
  )
}

export default UploadsFolderList
