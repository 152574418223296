import { FC } from 'react'
import { setChatTemplate, useAppDispatch, useAppSelector } from '../../../../redux'
import { useHaptics } from '../../../../utils'
import { StyledChatPromptBar } from '.'

type Props = {
  isExpanded?: boolean
  onCollapse: () => void
}

const PROMPTS = [
  {
    label: 'Generate a caption',
    type: 'caption'
  },
  {
    label: 'Recommend hashtags',
    type: 'hashtags'
  },
  {
    label: 'Suggest a gallery photo',
    type: 'photo'
  },
  {
    label: 'Find visual ideas',
    type: 'visual-ideas'
  },
  {
    label: 'Fill in caption blanks',
    type: 'blanks'
  },
  {
    label: 'Write a video script',
    type: 'video-script'
  }
]

const ChatPromptBar: FC<Props> = ({ isExpanded, onCollapse }) => {
  const dispatch = useAppDispatch()
  const { trigger } = useHaptics()
  const { template } = useAppSelector((state) => state.chat)

  const handleToggleTemplate = (value: any) => {
    if (value === template) {
      return dispatch(setChatTemplate(undefined))
    }
    dispatch(setChatTemplate(value))
    trigger('impact-light')
    onCollapse()
  }

  return (
    <StyledChatPromptBar isExpanded={isExpanded}>
      <div className="scroll-container">
        {PROMPTS.map((prompt) => {
          return (
            <button
              data-selected={template === prompt.type}
              onClick={() => handleToggleTemplate(prompt.type as any)}
              key={prompt.type}
            >
              {prompt.label}
            </button>
          )
        })}
      </div>
      <div className="btn-group" style={{ display: !!template ? 'flex' : 'none' }}>
        <button className="clear-btn" onClick={() => dispatch(setChatTemplate(undefined))}>
          Clear
        </button>
      </div>
    </StyledChatPromptBar>
  )
}

export default ChatPromptBar
