import styled, { keyframes } from 'styled-components'

const checkAnim = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`

const logoAnim = keyframes`
  0% {
    color: #2445f1;
  }
  20% {
    color: #00c2ff;
  }
  40% {
    color: #9d00ff;
  }
  60% {
    color: #ff416e;
  }
  80% {
    color: #ffd600;
  }
  100% {
    color: #2445f1;
  }
`

const logoSpinnerAnim = keyframes`
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(0.75);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
`

const StyledAuthLayout = styled.div`
  height: 100dvh;
  width: 100vw;
  display: grid;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.gray[100]};
  overflow: hidden;
  overflow-y: auto;

  .layout-container {
    display: grid;
    box-sizing: border-box;
    grid-template-rows: minmax(0, 1fr) 60px;
    width: 100vw;
    background-color: ${({ theme }) => theme.color.background};
  }

  .logo-spinner {
    display: flex;
    height: 26px;
    animation: ${logoSpinnerAnim} 1.5s infinite ease-in-out;
  }

  .bar {
    width: 100%;
    flex-shrink: 0;
    padding-top: 16px;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    outline: none;
    transition: 0.2s;
    background-color: ${({ theme }) => theme.color.background};
    color: ${({ theme }) => theme.color.gray[400]};
    border-top: 1px solid ${({ theme }) => theme.color.gray[300]};

    svg {
      height: 26px;
    }

    &:hover {
      color: ${({ theme }) => theme.color.gray[800]};
    }

    &:focus-visible {
      box-shadow: 0 0 0 4px ${({ theme }) => theme.color.blue[200]};
    }
  }

  .img-container {
    width: 100%;
    height: 100%;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
      background-color: ${({ theme }) => theme.color.gray[100]};
    }
  }

  main {
    width: 100%;
    box-sizing: border-box;
    overflow-y: auto;

    .auth-screen {
      display: none;

      &[data-active='true'] {
        display: block;
      }
    }

    .auth-header {
      user-select: none;
      margin: 30px 0 10px 0;

      .sc-logo {
        height: 26px;
        margin: 0 auto;
        display: block;
        margin-bottom: 10px;
        color: ${({ theme }) => theme.color.brand};

        &:hover {
          animation: 3s ${logoAnim} infinite;
        }
      }

      h1 {
        font-weight: 600;
        font-family: ${({ theme }) => theme.typeface.garnett};
        font-size: 32px;
        text-align: center;
        color: ${({ theme }) => theme.color.gray[900]};
      }

      h2 {
        font-size: 18px;
        text-align: center;
        font-weight: 400;
        margin-top: 4px;
        color: ${({ theme }) => theme.color.gray[600]};
      }

      h3 {
        font-size: 16px;
        text-align: center;
        font-weight: 400;
        margin: 10px auto 0 auto;
        max-width: 450px;
        color: ${({ theme }) => theme.color.gray[500]};
      }
    }

    .auth-header-row {
      height: 60px;
      padding: 0 20px;
      border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: sticky;
      top: 0;
      background-color: ${({ theme }) => theme.color.background};
      z-index: ${({ theme }) => theme.elevation.fixed};

      .trial-btn {
        font-weight: 600;
        flex-shrink: 0;
        height: 40px;
        padding: 0 8px 0 14px;
        background-color: transparent;
        border-radius: 50rem;
        transition: 0.2s;
        color: ${({ theme }) => theme.color.purple[600]};
        background-color: ${({ theme }) => theme.color.purple[100]};
        display: flex;
        align-items: center;

        svg {
          height: 16px;
          margin-left: 4px;
          stroke-width: 3px;
        }

        &:hover {
          color: ${({ theme }) => theme.color.purple[800]};
          background-color: ${({ theme }) => theme.color.purple[200]};
        }
      }
    }

    .auth-header-btn {
      display: flex;
      align-items: center;
      width: 100%;
      height: 60px;
      padding: 0;
      background-color: ${({ theme }) => theme.color.background};
      font-weight: 600;
      font-family: ${({ theme }) => theme.typeface.garnett};
      font-size: 18px;
      color: ${({ theme }) => theme.color.gray[900]};

      .auth-header-btn-label {
        position: relative;
        height: 24px;
        overflow: hidden;
        width: 200px;

        span {
          position: absolute;
          left: 0;
          display: block;
          transition: 0.2s;
        }

        .default-label {
          top: 0px;
        }

        .reveal-label {
          top: 100%;
        }
      }

      svg {
        height: 22px;
        margin-right: 10px;
        stroke-width: 2.5px;
      }

      &:focus-visible {
        box-shadow: 0 0 0 4px ${({ theme }) => theme.color.blue[200]};
      }

      &:hover {
        .auth-header-btn-label {
          .default-label {
            top: -100%;
          }

          .reveal-label {
            top: 0px;
          }
        }
      }
    }

    form {
      padding: 20px;

      .auth-inputs {
        display: grid;
        gap: 35px;

        .input-container {
          position: relative;

          .input-label {
            margin-bottom: 10px;
            font-weight: 700;
            color: ${({ theme }) => theme.color.gray[800]};
            font-size: 16px;
            user-select: none;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .show-password-btn {
              display: flex;
              align-items: center;
              font-weight: 700;
              padding: 0;
              margin: -2px 0;
              background-color: transparent;
              color: ${({ theme }) => theme.color.brand};

              svg {
                height: 20px;
                margin-left: 8px;
              }

              &:hover {
                color: ${({ theme }) => theme.color.brandDark};
              }

              &:focus-visible {
                box-shadow: 0 0 0 4px ${({ theme }) => theme.color.blue[200]};
              }
            }

            .input-label-subtext {
              display: flex;
              align-items: center;
              font-weight: 500;
              user-select: none;
              margin: -2px 0;
              color: ${({ theme }) => theme.color.gray[400]};

              a {
                margin-left: 4px;
                outline: none;
                color: ${({ theme }) => theme.color.gray[400]};

                &:hover {
                  color: ${({ theme }) => theme.color.gray[500]};
                }

                &:focus-visible {
                  box-shadow: 0 0 0 4px ${({ theme }) => theme.color.blue[200]};
                }
              }

              svg {
                height: 18px;
                margin-left: 4px;
              }
            }
          }

          .show-password-btn-small {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            position: absolute;
            right: 10px;
            bottom: 10px;
            color: ${({ theme }) => theme.color.gray[500]};
            background-color: ${({ theme }) => theme.color.gray[100]};

            svg {
              height: 22px;
            }

            &:hover {
              color: ${({ theme }) => theme.color.gray[600]};
              background-color: ${({ theme }) => theme.color.gray[200]};
            }
          }

          input[type='text'],
          input[type='password'],
          input[type='email'] {
            height: 60px;
            padding: 0 16px;
            font-size: 18px;
            border-radius: 6px;
            width: 100%;
            box-sizing: border-box;
            border: 1px solid ${({ theme }) => theme.color.gray[400]};
            color: ${({ theme }) => theme.color.gray[800]};

            &::placeholder {
              user-select: none;
              color: ${({ theme }) => theme.color.gray[400]};
            }

            &:hover {
              border: 1px solid ${({ theme }) => theme.color.gray[500]};
            }

            &:focus {
              border: 1px solid ${({ theme }) => theme.color.brand};
              box-shadow: 0 0 0 4px ${({ theme }) => theme.color.blue[200]};
            }

            &:disabled {
              background-color: ${({ theme }) => theme.color.gray[100]};
              border: 1px solid ${({ theme }) => theme.color.gray[100]};
              color: ${({ theme }) => theme.color.gray[400]};
            }
          }

          .billing-cycle-select {
            display: grid;
            gap: 10px;

            .billing-cycle-select-option {
              background-color: ${({ theme }) => theme.color.gray[100]};
              border-radius: 6px;
              padding: 12px;
              font-size: 18px;
              width: 100%;
              user-select: none;
              box-sizing: border-box;
              display: flex;
              align-items: center;
              position: relative;
              text-align: left;

              .billing-cycle-select-radio {
                height: 32px;
                width: 32px;
                border-radius: 50%;
                border: 2px solid ${({ theme }) => theme.color.gray[400]};
                margin-right: 12px;
                flex-shrink: 0;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              .billing-cycle-select-details {
                .billing-cycle-select-title {
                  font-weight: 500;
                  color: ${({ theme }) => theme.color.gray[700]};
                }

                .billing-cycle-select-price {
                  font-size: 15px;
                  margin-top: 2px;
                  color: ${({ theme }) => theme.color.gray[500]};

                  b {
                    font-weight: 600;
                    margin-right: 4px;
                    color: ${({ theme }) => theme.color.green[600]};
                  }

                  s {
                    color: ${({ theme }) => theme.color.gray[400]};
                  }
                }
              }

              .billing-cycle-select-tag {
                position: absolute;
                right: 12px;
                top: -12px;
                height: 24px;
                border-radius: 50rem;
                padding: 0 8px;
                display: flex;
                align-items: center;
                font-size: 13px;
                font-weight: 600;
                background-color: ${({ theme }) => theme.color.green[600]};
                color: ${({ theme }) => theme.color.background};
              }

              &:hover {
                .billing-cycle-select-radio {
                  border: 2px solid ${({ theme }) => theme.color.gray[500]};
                }
              }

              &[aria-selected='true'] {
                background-color: ${({ theme }) => theme.color.background};
                box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.1);

                .billing-cycle-select-radio {
                  border: 2px solid ${({ theme }) => theme.color.brand};

                  &::after {
                    content: '';
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    background-color: ${({ theme }) => theme.color.brand};
                    display: block;
                  }
                }
              }

              &:focus-visible {
                box-shadow: 0 0 0 4px ${({ theme }) => theme.color.blue[200]};
              }
            }
          }

          .order-summary {
            border-radius: 6px;
            user-select: none;
            background-color: ${({ theme }) => theme.color.gray[100]};

            .order-rows {
              padding: 12px;
              display: grid;
              gap: 6px;

              .order-row {
                display: flex;
                align-items: center;
                justify-content: space-between;
                min-height: 25px;
                font-size: 18px;
                font-weight: 500;
                color: ${({ theme }) => theme.color.gray[600]};

                .order-row-label {
                  display: flex;
                  align-items: center;

                  .coupon-remove-btn {
                    height: 25px;
                    width: 25px;
                    border-radius: 6px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 2px;
                    flex-shrink: 0;
                    background-color: transparent;
                    color: ${({ theme }) => theme.color.green[600]};

                    svg {
                      height: 20px;
                      stroke-width: 2.4px;
                    }

                    &:hover {
                      color: ${({ theme }) => theme.color.green[700]};
                    }

                    &:focus-visible {
                      box-shadow: 0 0 0 4px ${({ theme }) => theme.color.blue[200]};
                    }
                  }
                }

                .coupon {
                  color: ${({ theme }) => theme.color.green[600]};
                }

                .add-code-input-container {
                  display: flex;
                  align-items: center;
                  width: 100%;

                  .add-code-input {
                    height: 25px;
                    width: 100%;
                    font-size: 18px;
                    box-sizing: border-box;
                    font-weight: 500;
                    color: ${({ theme }) => theme.color.green[600]};
                    background-color: transparent;

                    &::placeholder {
                      user-select: none;
                      font-weight: 400;
                      color: ${({ theme }) => theme.color.gray[400]};
                    }
                  }

                  .add-code-apply-btn {
                    height: 25px;
                    border-radius: 6px;
                    padding: 0 6px;
                    margin-left: 6px;
                    font-size: 16px;
                    font-weight: 500;
                    flex-shrink: 0;
                    background-color: ${({ theme }) => theme.color.green[600]};
                    color: ${({ theme }) => theme.color.background};

                    &:hover {
                      background-color: ${({ theme }) => theme.color.green[700]};
                    }

                    &:focus-visible {
                      box-shadow: 0 0 0 4px ${({ theme }) => theme.color.blue[200]};
                    }
                  }
                }

                .add-code-btn {
                  display: flex;
                  align-items: center;
                  font-weight: 500;
                  background-color: transparent;
                  font-size: 18px;
                  color: ${({ theme }) => theme.color.gray[400]};

                  svg {
                    height: 20px;
                    margin-right: 6px;
                    stroke-width: 2.6px;
                  }

                  &:hover {
                    color: ${({ theme }) => theme.color.gray[500]};
                  }

                  &:focus-visible {
                    box-shadow: 0 0 0 4px ${({ theme }) => theme.color.blue[200]};
                  }
                }
              }
            }

            .order-total {
              padding: 12px;
              border-top: 1px solid ${({ theme }) => theme.color.gray[300]};
              display: flex;
              align-items: center;
              justify-content: space-between;
              height: 25px;
              font-size: 18px;
              font-weight: 500;
              color: ${({ theme }) => theme.color.gray[600]};
            }
          }
        }

        .checkbox-container {
          display: flex;
          align-items: center;
          user-select: none;

          .checkbox {
            height: 26px;
            width: 26px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            border-radius: 6px;
            margin-right: 10px;
            border: 1px solid ${({ theme }) => theme.color.gray[300]};

            svg {
              height: 20px;
              stroke-width: 2.5px;
              color: ${({ theme }) => theme.color.background};

              path {
                stroke-dasharray: 1000;
                stroke-dashoffset: 1000;
                animation: ${checkAnim} 15s linear forwards;
              }
            }

            &:focus-visible {
              box-shadow: 0 0 0 4px ${({ theme }) => theme.color.blue[200]};
            }

            &[aria-checked='true'] {
              background-color: ${({ theme }) => theme.color.brand};
              border-color: ${({ theme }) => theme.color.brand};
            }
          }

          .checkbox-label {
            font-size: 18px;
            color: ${({ theme }) => theme.color.gray[500]};

            a {
              outline: none;
              color: ${({ theme }) => theme.color.gray[500]};

              &:hover {
                color: ${({ theme }) => theme.color.gray[700]};
              }

              &:focus-visible {
                box-shadow: 0 0 0 4px ${({ theme }) => theme.color.blue[200]};
              }
            }
          }

          &:hover {
            cursor: pointer;
          }
        }
      }

      .submit-btn {
        margin-top: 35px;
        height: 70px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        font-size: 18px;
        font-weight: 600;
        transition: transform 0.2s;
        border: 1px solid ${({ theme }) => theme.color.brandDark};
        color: ${({ theme }) => theme.color.background};
        background-color: ${({ theme }) => theme.color.brand};

        &:not(:disabled):hover {
          background-color: ${({ theme }) => theme.color.brandDark};
        }

        &:not(:disabled):focus-visible {
          box-shadow: 0 0 0 4px ${({ theme }) => theme.color.blue[200]};
        }

        &:not(:disabled):active {
          transform: scale(0.98);
        }

        &:disabled {
          cursor: not-allowed;
        }

        &[data-grayed='true'] {
          border-color: ${({ theme }) => theme.color.gray[100]};
          background-color: ${({ theme }) => theme.color.gray[100]};
          color: ${({ theme }) => theme.color.gray[400]};
        }
      }
    }

    .form-subtext {
      text-align: center;
      user-select: none;
      margin: 0px 0 30px 0;
      color: ${({ theme }) => theme.color.gray[500]};

      a {
        font-weight: 500;
        outline: none;
        color: ${({ theme }) => theme.color.gray[500]};

        &:hover {
          color: ${({ theme }) => theme.color.gray[600]};
        }

        &:focus-visible {
          box-shadow: 0 0 0 4px ${({ theme }) => theme.color.blue[200]};
        }
      }
    }
  }

  .gradient-bar {
    height: 10px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      90deg,
      #2445f1 0%,
      #00c2ff 25%,
      #9d00ff 50%,
      #ff416e 75%,
      #ffd600 100%
    );
  }

  .auth-disclaimer {
    margin-top: 32px;
    user-select: none;
    border-radius: 6px;
    background-color: rgb(0, 0, 0, 0.05);
    box-shadow: inset 0 0 0 1px rgb(0, 0, 0, 0.08);
    color: rgb(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    padding: 12px;

    .icon {
      color: black;
      font-size: 20px;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 1px;
      position: relative;
    }
  }

  .desktop {
    display: none;
  }

  @media (min-width: 680px) {
    .layout-container {
      max-width: 540px;
      max-height: 95dvh;
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 0 0 0 1px rgb(0, 0, 0, 0.1), 0 4px 6px rgb(0, 0, 0, 0.1);
    }

    .mobile {
      display: none;
    }

    .desktop {
      display: unset;
    }
  }
`

export default StyledAuthLayout
