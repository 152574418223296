import { FC, ReactNode } from 'react'
import { StyledDesignerPanel } from '.'

type Props = {
  children: ReactNode
}

const DesignerPanel: FC<Props> = ({ children }) => {
  return <StyledDesignerPanel>{children}</StyledDesignerPanel>
}

export default DesignerPanel
