import { FC } from 'react'
import { Helmet } from 'react-helmet'
import { Banner, PageLayout, StoryList } from '../../components'
import { StyledStoriesView } from '.'

const StoriesView: FC = () => {
  return (
    <>
      {/* <Banner message="We don't currently support auto-posting to Instagram or Facebook stories." /> */}
      <PageLayout>
        <Helmet title="Stories | Social Curator" />
        <StyledStoriesView>
          <StoryList />
        </StyledStoriesView>
      </PageLayout>
    </>
  )
}

export default StoriesView
