import { FC, ReactNode } from 'react'
import { Tab } from '..'
import { StyledTabs } from '.'

type Props = {
  tabs: {
    label: string
    isActive?: boolean
    onClick: () => void
  }[]
  children?: ReactNode
}

const Tabs: FC<Props> = ({ tabs, children }) => {
  return (
    <StyledTabs className="tabs">
      <div className="scroll-container">
        {tabs.map((tab) => (
          <Tab tab={tab} key={tab.label} />
        ))}
      </div>
      {children && <div className="children-container">{children}</div>}
    </StyledTabs>
  )
}

export default Tabs
