import { DateTime } from 'luxon'

export const getRelativeString = (date?: string, showFullDateIfFar = false) => {
  if (!date) {
    return ''
  }

  const dateTime = DateTime.fromISO(date)
  const relativeString = dateTime.toRelative()

  if (relativeString?.includes('second')) {
    return 'just now'
  }

  if (showFullDateIfFar) {
    return dateTime.toFormat("LLLL d 'at' h:mma")
  }

  return relativeString
}
