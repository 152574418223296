import { FC, useState } from 'react'
import { PlusIcon } from '@heroicons/react/24/outline'
import { ContentPillarType } from '../../../types'
import { PillarDialog } from '../../dialogs'
import { PillarListItem } from '..'
import { StyledPillarList } from '.'

type Props = {
  pillars: ContentPillarType[]
  onChange: (_id: string, pillar: Partial<ContentPillarType>) => void
  onRemove: (_id: string) => void
  onAdd: (pillar: Omit<ContentPillarType, '_id'>) => void
}

const PillarList: FC<Props> = ({ pillars, onChange, onRemove, onAdd }) => {
  const [showAddDialog, setShowAddDialog] = useState(false)

  return (
    <>
      <StyledPillarList>
        {pillars.map((pillar) => {
          return (
            <PillarListItem
              pillar={pillar}
              onChange={(v) => onChange(pillar._id, v)}
              onRemove={() => onRemove(pillar._id)}
              key={pillar._id}
            />
          )
        })}
        <button className="add-btn" onClick={() => setShowAddDialog(true)}>
          <PlusIcon />
          Add Pillar
        </button>
      </StyledPillarList>
      <PillarDialog
        isOpen={showAddDialog}
        onClose={() => setShowAddDialog(false)}
        onConfirm={(pillar) => onAdd(pillar)}
      />
    </>
  )
}

export default PillarList
