import styled from 'styled-components'

type StyledProps = {
  isActive?: boolean
  isIcon?: boolean
}

const StyledDesignerToolButton = styled.button<StyledProps>`
  border-radius: 4px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.color.gray[900]};
  background-color: transparent;
  transition: 0.2s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  vertical-align: middle;
  height: 32px;
  min-width: unset;
  padding: 0 2px;
  font-size: 15px;
  font-weight: 500;

  span {
    padding: 0 10px;
  }

  svg {
    height: 22px;
    stroke-width: 1.75px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.gray[100]};
  }

  ${({ isIcon }) =>
    isIcon &&
    `
    width: 32px;
    padding: 0;

    &:not(:last-of-type) {
      margin-right: 6px;
    }
  `}

  ${({ isActive, theme }) =>
    isActive &&
    `
      color: ${theme.color.brand};
      background-color: ${theme.color.brandLight};

      &:hover {
        color: ${theme.color.brand};
        background-color: ${theme.color.brandLight};
      }
  `}
`

export default StyledDesignerToolButton
