import styled, { css, keyframes } from 'styled-components'
import { theme } from '../../../utils/theme'

const likeAnim = keyframes`
  0% {
    transform: scale(0.3);
    fill: ${theme.color.red[600]};
    color: ${theme.color.red[600]};
  }
  60% {
    transform: scale(1.2);
    fill: ${theme.color.red[600]};
    color: ${theme.color.red[600]};
  }
  80% {
    transform: scale(0.9);
    fill: ${theme.color.red[600]};
    color: ${theme.color.red[600]};
  }
  100% {
    transform: scale(1);
    fill: ${theme.color.red[600]};
    color: ${theme.color.red[600]};
  }
`

type StyledProps = {
  isUsed?: boolean
  isLiked?: boolean
}

const StyledCaptionListItem = styled.div<StyledProps>`
  padding: 18px;
  border-radius: 6px;
  box-sizing: border-box;
  position: relative;
  user-select: none;
  border: 1px solid ${({ theme }) => theme.color.gray[300]};
  background-color: ${({ theme }) => theme.color.background};

  .options-btn {
    position: absolute;
    z-index: 2;
    top: 18px;
    right: 18px;
    width: 28px;
    height: 28px;

    svg {
      height: 24px;
    }
  }

  span {
    display: block;
  }

  .caption-header {
    margin-bottom: 14px;
    max-width: calc(100% - 35px);

    .caption-title {
      font-weight: 500;
      color: ${({ theme }) => theme.color.gray[800]};
    }

    .caption-category {
      margin-top: 2px;
      font-size: 15px;
      color: ${({ theme }) => theme.color.gray[500]};
    }
  }

  .caption-text {
    font-size: 15px;
    color: ${({ theme }) => theme.color.gray[700]};
    line-height: 26px;
    line-break: loose;
    user-select: unset;
  }

  .caption-actions {
    margin-top: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .caption-action-group {
      display: flex;
      align-items: center;

      button {
        &:not(:last-child) {
          margin-right: 8px;
        }
      }
    }

    .fav-btn {
      ${({ isLiked, theme }) =>
        isLiked &&
        `
        background-color: ${theme.color.red[100]};

        &:hover {
          background-color: ${theme.color.red[200]};
        }
      `}

      svg {
        ${({ isLiked }) =>
          isLiked &&
          css`
            animation: ${likeAnim} forwards 0.2s ease-in-out;
          `}
      }
    }
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  ${({ isUsed, theme }) =>
    isUsed &&
    `
    border-color: ${theme.color.gray[200]}a4;
    background-color: ${theme.color.gray[100]}80;

    .caption-header .caption-title, .caption-text, .caption-actions {
      opacity: 0.7;
    }
  `}
`

export default StyledCaptionListItem
