import styled from 'styled-components'

const StyledHashtagBank = styled.div`
  height: 100%;
  // display: grid;
  overflow-y: auto;
  // grid-template-rows: minmax(0, 1fr) auto;

  .hashtag-bank-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};

    .hashtag-bank-header-details {
      display: flex;
      align-items: center;
      font-weight: 500;
      color: ${({ theme }) => theme.color.gray[800]};

      svg {
        height: 22px;
        margin-right: 6px;
        stroke-width: 2px;
      }
    }
  }

  .hashtag-bank-actions {
    padding: 20px;
  }
`

export default StyledHashtagBank
