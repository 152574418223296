import { FC } from 'react'
import { StyledEmptyState } from '.'

type Props = {
  title: string
  body: string
}

const EmptyState: FC<Props> = ({ title, body }) => {
  return (
    <StyledEmptyState className="empty-state">
      <span className="title">{title}</span>
      <span className="body">{body}</span>
    </StyledEmptyState>
  )
}

export default EmptyState
