import { useContext } from 'react'
import { toast } from 'react-hot-toast'
import { useApolloClient } from '@apollo/client'
import { Capacitor } from '@capacitor/core'
import { connectLinkedIn } from '../../../graphql'
import { userStore } from '../../UserContext'
import { useSegment } from '../useSegment'

export const useLoginPinterest = () => {
  const apollo = useApolloClient()
  const native = Capacitor.isNativePlatform()
  const { refresh } = useContext(userStore)
  const { track } = useSegment()

  const loginPinterest = () => {
    const clientId = process.env.REACT_APP_LINKEDIN_APP_ID
    const redirect = native ? 'https://app.socialcurator.com' : window.location.origin

    let url = 'https://www.pinterest.com/oauth/?'
    url += `client_id=${clientId}&`
    url += `redirect_uri=${redirect}&`
    url += `response_type=code&`
    url += `scope=pins:write`

    window.location.href = url
  }

  const connectPinterestProfile = async (code: string) => {
    // try {
    //   const callback = native ? 'https://app.socialcurator.com' : window.location.origin
    //   await apollo.mutate({
    //     mutation: connectLinkedIn,
    //     variables: { code, callback }
    //   })
    //   await refresh()
    //   track('Connected Social Profile')
    //   track('Connected LinkedIn Profile')
    //   toast.success(`Successfully connected your social profile`)
    // } catch (err) {
    //   console.error(err)
    // }
  }

  return {
    loginPinterest,
    connectPinterestProfile
  }
}
