import styled from 'styled-components'

const StyledDesignerElementsPanel = styled.div`
  padding: 20px;

  .element-list {
    display: grid;
    gap: 6px;
    grid-template-columns: repeat(auto-fill, minmax(75px, 1fr));
  }

  .shape-element {
    padding-top: 100%;
    background-color: #313233;
    border-radius: 4px;
    position: relative;
    transition: 0.2s;

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &:hover {
      background-color: #444547;
    }
  }
`

export default StyledDesignerElementsPanel
