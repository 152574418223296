import { xor } from 'lodash'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { PostType, SocialProfileType } from '../../types'
import { CREATE_TABS } from '../../utils'

type InitialState = {
  post?: Partial<PostType>
  tab: (typeof CREATE_TABS)[number]['_id']
  platform: SocialProfileType['type']
  contentTab: 'photos' | 'captions' | 'bits'
  uploadsTab: 'uploads' | 'designs'
  mode: 'edit' | 'preview'
  isSaved: boolean
  isSwipeable?: boolean
  showHashtags: boolean
}

const initialState: InitialState = {
  tab: 'chat',
  contentTab: 'photos',
  uploadsTab: 'uploads',
  mode: 'edit',
  platform: 'instagram',
  isSaved: true,
  isSwipeable: true,
  showHashtags: false
}

export const createReduxSlice = createSlice({
  name: 'create',
  initialState,
  reducers: {
    setCreatePost: (state, action: PayloadAction<Partial<PostType>>) => {
      state.post = {
        ...state.post,
        ...action.payload
      }
    },
    updateCreatePost: (state, action: PayloadAction<Partial<PostType>>) => {
      state.post = {
        ...state.post,
        ...action.payload,
        lastModified: new Date().toISOString()
      }
      state.isSaved = false
    },
    clearCreatePost: (state) => {
      state.post = undefined
      state.isSaved = true
    },
    setCreateTab: (state, action: PayloadAction<InitialState['tab']>) => {
      state.tab = action.payload
    },
    setCreateContentTab: (state, action: PayloadAction<InitialState['contentTab']>) => {
      state.contentTab = action.payload
    },
    setCreateUploadsTab: (state, action: PayloadAction<InitialState['uploadsTab']>) => {
      state.uploadsTab = action.payload
    },
    setCreateMode: (state, action: PayloadAction<InitialState['mode']>) => {
      state.mode = action.payload
    },
    toggleCreateSocialProfile: (state, action: PayloadAction<string>) => {
      state.post = {
        ...state.post,
        socialProfiles: xor(state.post?.socialProfiles || [], [action.payload])
      }
      state.isSaved = false
    },
    setCreateSaved: (state, action: PayloadAction<boolean>) => {
      state.isSaved = action.payload
    },
    addCreatePostMedia: (state, action: PayloadAction<Required<PostType>['media'][number]>) => {
      state.post = {
        ...state.post,
        media: [...(state.post?.media || []), action.payload]
      }
      state.isSaved = false
    },
    removeCreatePostMedia: (state, action: PayloadAction<string[]>) => {
      if (!state.post?.media) {
        return
      }

      state.post.media = (state.post?.media || [])?.filter(
        (m) => !action.payload.includes(m.ref || '')
      )
    },
    addCreatePostBit: (state, action: PayloadAction<string>) => {
      if (state.post?.captionText?.endsWith(' ') || !state.post?.captionText?.length) {
        state.post = {
          ...state.post,
          captionText: `${state.post?.captionText || ''}${action.payload}`
        }
      } else {
        state.post = {
          ...state.post,
          captionText: `${state.post?.captionText || ''} ${action.payload}`
        }
      }
      state.isSaved = false
    },
    setCreatePlatform: (state, action: PayloadAction<InitialState['platform']>) => {
      state.platform = action.payload
    },
    setCreateSwipeable: (state, action: PayloadAction<boolean>) => {
      state.isSwipeable = action.payload
    },
    setShowCreateHashtags: (state, action: PayloadAction<boolean>) => {
      state.showHashtags = action.payload
    }
  }
})

export const {
  setCreatePost,
  updateCreatePost,
  clearCreatePost,
  setCreateTab,
  setCreateContentTab,
  setCreateUploadsTab,
  setCreateMode,
  toggleCreateSocialProfile,
  setCreateSaved,
  addCreatePostMedia,
  removeCreatePostMedia,
  addCreatePostBit,
  setCreatePlatform,
  setCreateSwipeable,
  setShowCreateHashtags
} = createReduxSlice.actions
export default createReduxSlice.reducer
