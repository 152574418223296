import {
  ChangeEvent,
  ForwardedRef,
  InputHTMLAttributes,
  ReactNode,
  forwardRef,
  useEffect,
  useState
} from 'react'
import classNames from 'classnames'
import { StyledInput } from '.'

type Props = {
  size?: 'lg' | 'md' | 'sm'
  icon?: ReactNode
  preText?: string
  variant?: 'outline' | 'fill' | 'shadow'
  isDebounced?: boolean
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'size'>

const Input = forwardRef(
  (
    {
      size = 'md',
      icon,
      preText,
      variant = 'outline',
      value,
      maxLength,
      className,
      isDebounced,
      onClick,
      onChange,
      ...rest
    }: Props,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const [val, setVal] = useState(value)

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      setVal(e.target.value)

      if (!!onChange) {
        onChange(e)
      }
    }

    useEffect(() => {
      setVal(value)
    }, [value])

    return (
      <StyledInput
        scale={size}
        icon={!!icon}
        variant={variant}
        className={classNames('input', className)}
        onClick={onClick}
      >
        {icon}
        {preText && <span className="pre-text">{preText}</span>}
        <input
          value={isDebounced ? val : value}
          maxLength={maxLength}
          onChange={(e) => handleChange(e)}
          {...rest}
          ref={ref}
        />
        {/* {maxLength && (
          <span className="max-length-indicator">
            {(value as any)?.length || 0}/{maxLength}
          </span>
        )} */}
      </StyledInput>
    )
  }
)

export default Input
