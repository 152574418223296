import { FC, useEffect, useState } from 'react'
import { CloudArrowUpIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { toBase64 } from '../../../utils'
import { StyledImageInput } from '.'

type Props = {
  value?: string
  disabled?: boolean
  isSquare?: boolean
  isRounded?: boolean
  onChange: (file: File | null, preview: string | null) => void
}

const ImageInput: FC<Props> = ({ disabled, value, isSquare, isRounded, onChange }) => {
  const [preview, setPreview] = useState(value || '')

  const handleChange = async (file?: File | null) => {
    if (!file) {
      onChange(null, null)
      setPreview('')
      return
    }

    const prev = await toBase64(file)

    setPreview(prev)
    onChange(file, prev)
  }

  useEffect(() => {
    setPreview(value || '')
  }, [value])

  return (
    <StyledImageInput isSquare={isSquare} isRounded={isRounded}>
      {preview && (
        <>
          <img src={preview} alt="" />
          <button className="delete-btn" disabled={disabled} onClick={() => handleChange(null)}>
            <XMarkIcon />
          </button>
        </>
      )}
      {!preview && (
        <>
          <input
            type="file"
            accept="image/*"
            disabled={disabled}
            onChange={(e) => handleChange((e.target.files || [])[0])}
          />
          {!isSquare && (
            <button className="upload-btn" disabled={disabled}>
              <CloudArrowUpIcon />
              Upload Image
            </button>
          )}
          {isSquare && <CloudArrowUpIcon className="upload-icon" />}
        </>
      )}
    </StyledImageInput>
  )
}

export default ImageInput
