import styled from 'styled-components'

const StyledCalendarMonth = styled.div`
  display: grid;
  grid-template-rows: repeat(auto-fit, minmax(0, 1fr));

  .calendar-month-row {
    width: 100%;
    display: grid;
    position: relative;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    border-bottom: 1px solid ${({ theme }) => theme.color.gray[200]};

    .empty-slot {
      background-color: ${({ theme }) => theme.color.gray[100]};

      &:not(:last-child) {
        border-right: 1px solid ${({ theme }) => theme.color.gray[200]};
      }
    }
  }
`

export default StyledCalendarMonth
