import styled from 'styled-components'

const StyledNewLinkBlockDialog = styled.div`
  width: 100vw;
  box-sizing: border-box;

  form {
    margin-top: 20px;
    padding: 0 20px;

    .input {
      &:not(:last-child) {
        margin-bottom: 14px;
      }
    }
  }

  .dialog-actions {
    margin-top: 20px;
    display: flex;

    button {
      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    max-width: 375px;

    .dialog-actions {
      padding-bottom: 20px;
    }
  }
`

export default StyledNewLinkBlockDialog
