import styled from 'styled-components'

const StyledEditorConnections = styled.div`
  .connection-list {
    display: grid;
    gap: 14px;
    margin: 0 -16px;
  }

  .empty-state {
    margin: 20px 0 40px 0;

    span {
      display: block;
      text-align: center;
    }

    .empty-state-title {
      font-weight: 500;
      color: ${({ theme }) => theme.color.gray[800]};
    }

    .empty-state-description {
      font-size: 15px;
      line-height: 20px;
      max-width: 300px;
      margin: 10px auto 0 auto;
      color: ${({ theme }) => theme.color.gray[400]};
    }
  }

  .manage-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    height: 50px;
    border-radius: 14px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 14px;
    background-color: ${({ theme }) => theme.color.gray[100]};
    user-select: none;
    color: ${({ theme }) => theme.color.gray[500]};
    transition: 0.2s;
    margin-top: 20px;

    &:hover {
      background-color: ${({ theme }) => theme.color.gray[200]}c4;
    }
  }
`

export default StyledEditorConnections
