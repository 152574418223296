export const day2 = {
  _id: 'day2',
  title: 'DAY 2',
  tasks: [
    {
      label:
        'Create a post for yesterday at 3PM with the title “Calendar Product Test” using the Calendar',
      steps: [
        {
          label: 'Navigate to the Calendar using the sidebar'
        },
        {
          label: 'Find yesterday on the Calendar, and click the slot for 3PM'
        },
        {
          label:
            'Using the steps you tested in Day 1, create a simple post (you can simply write “testing” in the caption if you’d like!)'
        }
      ]
    },
    {
      label: 'In the Calendar, drag the post to a different date & time',
      steps: [
        {
          label: 'Navigate to the Calendar using the sidebar'
        },
        {
          label: 'Hover over the newly created post'
        },
        {
          label: 'Click & hold anywhere on the post and drag it'
        },
        {
          label: '“Drop” it into another slot on the Calendar'
        }
      ]
    },
    {
      label: 'Switch to the Calendar’s “Month” view',
      steps: [
        {
          label: 'Click the button that says “Week” in the Calendar’s header'
        }
      ]
    },
    {
      label: 'Find November 2022 on the Calendar',
      steps: [
        {
          label: 'Use the navigation arrows in the Calendar’s header to switch months'
        }
      ]
    },
    {
      label: 'Click the “Today” button to navigate back to today',
      steps: [
        {
          label: 'Click the button that says “Today” in the Calendar’s header'
        }
      ]
    }
  ],
  form: {
    url: 'https://notionforms.io/forms/day-2-feedback',
    keys: {
      FULL_NAME: 'f4f36ad3-b537-4dac-b8f5-a49ab234109c',
      EMAIL: 'e81dabd3-1f79-4bc3-86d6-6c3e34b32031',
      SCREEN_RECORDING: 'e85471ef-8897-4ef4-8151-47e2c2afbbb2',
      USER_ID: '215d29d0-58bc-45fb-96b7-4d478864b692'
    }
  }
}
