import goProHeaderImg from '../../../assets/go-pro-header.gif'
import { createRef, FC, useContext, useEffect, useState } from 'react'
import { BoltIcon } from '@heroicons/react/24/solid'
import { userStore, useSegment, useSubscription } from '../../../utils'
import { Button, Dialog, Spinner } from '../..'
import { StyledGoProDialog, PaymentScreen, SelectPlanScreen } from '.'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const GoProDialog: FC<Props> = ({ isOpen, onClose }) => {
  const {
    card,
    actions: { upgradePlan }
  } = useSubscription()
  const { track } = useSegment()
  const { user } = useContext(userStore)
  const [cardReady, setCardReady] = useState(false)
  const [plan, setPlan] = useState<'month' | 'year'>(
    user?.subscriptionInterval === 'Month' && !user.trial ? 'year' : 'month'
  )
  const [screen, setScreen] = useState<'plan' | 'payment'>('plan')
  const [loading, setLoading] = useState(false)
  const dialogRef = createRef<HTMLDivElement>()

  const handleNext = async () => {
    if (screen === 'plan' && !card) {
      return setScreen('payment')
    }

    await handleUpgrade()
  }

  const handleBack = () => {
    if (screen === 'plan') {
      return handleClose()
    }

    setScreen('plan')
  }

  const handleUpgrade = async () => {
    setLoading(true)
    await upgradePlan(plan === 'month' ? 'Month' : 'Year')
    setLoading(false)
    onClose()
  }

  const handleClose = () => {
    const dialog = dialogRef.current as any
    dialog?.close()
  }

  useEffect(() => {
    setCardReady(!!card)
  }, [card])

  useEffect(() => {
    if (isOpen) {
      track('Opened Go Pro Dialog')
    }
  }, [isOpen])

  return (
    <Dialog isOpen={isOpen} hasXIcon onClose={() => onClose()} ref={dialogRef}>
      <StyledGoProDialog>
        <div className="go-pro-header">
          <span className="go-pro-text">
            <BoltIcon />
            Go Pro
          </span>
          <img src={goProHeaderImg} alt="" />
        </div>
        <div className="dialog-screens">
          {screen === 'plan' && <SelectPlanScreen plan={plan} onSelect={(plan) => setPlan(plan)} />}
          {screen === 'payment' && (
            <PaymentScreen
              plan={plan}
              hasPaymentMethod={!!card}
              setCardReady={(isReady) => setCardReady(isReady)}
            />
          )}
        </div>
        <div className="dialog-actions">
          <Button size="sm" disabled={loading} onClick={() => handleBack()}>
            Back
          </Button>
          <Button
            size="sm"
            variant="emphasis"
            className="continue-btn"
            iconPos={screen === 'payment' || (screen === 'plan' && !!card) ? 'left' : undefined}
            disabled={(!cardReady && !card && screen === 'payment') || loading}
            onClick={() => handleNext()}
          >
            {screen === 'plan' && !card && 'Select'}
            {(screen === 'payment' || (!!card && screen === 'plan')) && !loading && (
              <>
                <BoltIcon /> Go Pro
              </>
            )}
            {loading && <Spinner />}
          </Button>
        </div>
      </StyledGoProDialog>
    </Dialog>
  )
}

export default GoProDialog
