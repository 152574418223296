import { FC, useCallback, useState } from 'react'
import { useEditor } from '@layerhub-io/react'
import { ReactComponent as UppercaseIcon } from '../../../assets/uppercase-icon.svg'
import { DesignerToolButton } from '.'

const DesignerCaseTool: FC = () => {
  const editor = useEditor()
  const [isUpper, setIsUpper] = useState(false)

  const toggleCase = useCallback(() => {
    setIsUpper(!isUpper)

    if (!isUpper) {
      return editor.objects.toUppercase()
    }

    editor.objects.toLowerCase()
  }, [editor, isUpper])

  return (
    <DesignerToolButton
      data-tooltip={isUpper ? 'Lowercase' : 'Uppercase'}
      isIcon
      onClick={() => toggleCase()}
    >
      <UppercaseIcon />
    </DesignerToolButton>
  )
}

export default DesignerCaseTool
