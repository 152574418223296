import { FC } from 'react'
import { ReactComponent as SpinnerLargeIcon } from './spinner-large.svg'
import { ReactComponent as SpinnerSmallIcon } from './spinner-small.svg'
import { StyledSpinner } from '.'

type Props = {
  isLarge?: boolean
}

const Spinner: FC<Props> = ({ isLarge }) => {
  return (
    <StyledSpinner className="spinner">
      {isLarge ? <SpinnerLargeIcon /> : <SpinnerSmallIcon />}
    </StyledSpinner>
  )
}

export default Spinner
