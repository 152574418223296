import { FC } from 'react'
import { toast } from 'react-hot-toast'
import Sheet from 'react-modal-sheet'
import { saveAs } from 'file-saver'
import { v4 as uuid } from 'uuid'
import { Media } from '@capacitor-community/media'
import { Dialog } from '@capacitor/dialog'
import { Directory, Filesystem } from '@capacitor/filesystem'
import { Share } from '@capacitor/share'
import {
  ArrowDownTrayIcon,
  ArrowUpCircleIcon,
  PlusIcon,
  TrashIcon
} from '@heroicons/react/24/outline'
import { CloudContentType, PhotoType } from '../../../../types'
import { useCloud, useCreate } from '../../../../utils'
import { CloudMediaPreview } from '../..'
import { StyledContentSheet } from '.'

type DefaultProps = {
  isOpen: boolean
  onClose: () => void
}

type PhotoProps = {
  content: PhotoType
  type: 'photo'
} & DefaultProps

type ContentProps = {
  content: CloudContentType
  type: 'cloud'
} & DefaultProps

type Props = PhotoProps | ContentProps

const ContentSheet: FC<Props> = ({ content, type, isOpen, onClose }) => {
  const { deleteFromCloud } = useCloud()
  const { addMediaToPost } = useCreate()

  const share = async () => {
    try {
      const url = type === 'cloud' ? content.file?.url : content.file.fileHttpLink

      if (type === 'cloud' && content.file?.type === 'video') {
        const data = await fetch(url!)
        const blob = await data.blob()

        const video: string = await new Promise((resolve) => {
          const reader = new FileReader()
          reader.readAsDataURL(blob)
          reader.onloadend = () => {
            const base64data: any = reader.result
            resolve(base64data)
          }
        })

        const { uri } = await Filesystem.writeFile({
          path: `${uuid()}.mp4`,
          data: video,
          directory: Directory.Cache
        })

        await Share.share({
          url: uri
        })

        return toast.success('Saved video to Photos')
      }

      const data = await fetch(url!)
      const blob = await data.blob()

      const image: string = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
          const base64data: any = reader.result
          resolve(base64data)
        }
      })

      const { uri } = await Filesystem.writeFile({
        path: `${uuid()}.png`,
        data: image.replace(/^data:image\/[a-z]+;base64,/, ''),
        directory: Directory.Cache
      })

      await Share.share({
        url: uri
      })
    } catch (err) {
      console.error(err)
    }
  }

  const download = async () => {
    try {
      const url = type === 'cloud' ? content.file?.url : content.file.fileHttpLink

      saveAs(url || '')

      // if (type === 'cloud' && content.file?.type === 'video') {
      //   const data = await fetch(url!)
      //   const blob = await data.blob()

      //   const video: string = await new Promise((resolve) => {
      //     const reader = new FileReader()
      //     reader.readAsDataURL(blob)
      //     reader.onloadend = () => {
      //       const base64data: any = reader.result
      //       resolve(base64data)
      //     }
      //   })

      //   await Media.saveVideo({
      //     path: video
      //   })

      //   return toast.success('Saved video to Photos')
      // }

      // await Media.savePhoto({
      //   path: url || ''
      // })

      toast.success('Saved photo to Photos')
    } catch (err) {}
  }

  const handleDelete = async () => {
    if (type === 'photo') {
      return
    }

    const { value: confirmed } = await Dialog.confirm({
      title: `Delete this ${content.file?.type || 'image'}?`,
      message: `Are you sure you want to delete "${content.name}" from your uploads?`
    })

    if (!confirmed) {
      return
    }

    onClose()
    await deleteFromCloud([content._id])
  }

  const handleAdd = async () => {
    const t = type === 'cloud' ? content.file?.type : 'image'

    addMediaToPost(content, t as any)
    onClose()
    toast.success('Added to post')
  }

  return (
    <Sheet detent="content-height" isOpen={isOpen} onClose={onClose}>
      <Sheet.Container style={{ overflow: 'hidden' }}>
        <Sheet.Content>
          <StyledContentSheet>
            <div className="content-preview">
              {type === 'cloud' && (
                <CloudMediaPreview
                  url={content.file?.url || ''}
                  type={content.file?.type || ''}
                  preview={content.file?.preview}
                  isInteractive
                />
              )}
              {type === 'photo' && (
                <CloudMediaPreview url={content.file.fileHttpLink || ''} type="image" />
              )}
            </div>
            <div className="content-actions">
              <div className="content-action-group">
                <button onClick={() => handleAdd()}>
                  Add to post <PlusIcon />
                </button>
                {/* {type === 'cloud' && (
                  <button>
                    Move <ArrowsRightLeftIcon />
                  </button>
                )} */}
                <button onClick={() => share()}>
                  Share <ArrowUpCircleIcon />
                </button>
                <button onClick={() => download()}>
                  Download <ArrowDownTrayIcon />
                </button>
              </div>
              {type === 'cloud' && (
                <div className="content-action-group">
                  <button className="destructive" onClick={() => handleDelete()}>
                    Delete <TrashIcon />
                  </button>
                </div>
              )}
            </div>
          </StyledContentSheet>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={() => onClose()} />
    </Sheet>
  )
}

export default ContentSheet
