import styled, { css, keyframes } from 'styled-components'

const checkAnim = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`

const shuffleAnim = keyframes`
  0% {
    transform: rotate(0deg);
  }
  70% {
    transform: rotate(380deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

type StyledProps = {
  isCompleted: boolean
}

const StyledDailyAction = styled.div<StyledProps>`
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: ${({ theme }) => theme.color.gray[100]};

  .daily-action-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 20px;

    .daily-action-header {
      .daily-action-title {
        font-weight: 500;
        font-size: 18px;
        letter-spacing: -0.01rem;
        display: flex;
        align-items: center;
        color: ${({ theme }) => theme.color.gray[900]};

        .daily-action-streak {
          display: flex;
          align-items: center;
          font-size: 14px;
          margin: -2px 0;
          padding: 2px 8px;
          font-weight: 500;
          position: relative;
          border-radius: 50rem;
          margin-left: 8px;
          border: 0;
          transition: 0.2s;
          color: ${({ theme }) => theme.color.gray[600]};
          background-color: ${({ theme }) => theme.color.gray[200]};

          &:hover {
            box-shadow: inset 0 0 0 1px rgb(0, 0, 0, 0.1);
            background-color: ${({ theme }) => theme.color.gray[300]};

            &::after,
            &::before {
              opacity: 1;
            }
          }
        }
      }

      .daily-action-header-details {
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
        align-items: end;
        color: ${({ theme }) => theme.color.gray[600]};

        .daily-action-desktop-text {
          display: none;
        }

        a {
          transition: 0.2s;
          text-decoration: underline;
          color: ${({ theme }) => theme.color.gray[600]};

          &:hover {
            color: ${({ theme }) => theme.color.gray[700]};
          }
        }

        .shuffle-btn {
          margin: -11px -10px;
          position: relative;
          top: -1px;

          svg {
            path {
              stroke-width: 2px;
            }
          }

          &.animate {
            svg {
              animation: ${shuffleAnim} 0.75s ease-in-out forwards;
            }
          }
        }
      }
    }

    .daily-action {
      display: flex;
      margin-top: 20px;
      align-items: center;
      border-radius: 8px;
      padding: 12px;
      transition: 0.2s;
      background-color: ${({ theme }) => theme.color.background};
      box-shadow: 0 0 0 1px rgb(0, 0, 0, 0.05), 0 3px 4px rgb(0, 0, 0, 0.06);

      .daily-action-checkbox {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 12px;
        position: relative;
        cursor: pointer;
        flex-shrink: 0;
        transition: 0.2s;
        background-color: ${({ theme }) => theme.color.brandLight};
        color: ${({ theme }) => theme.color.brand};

        svg {
          height: 24px;
          stroke-width: 1.7px;
        }

        &:hover {
          background-color: ${({ theme }) => theme.color.brand};
          color: ${({ theme }) => theme.color.background};
        }

        &:active:not(:disabled) {
          transform: scale(0.925);
        }

        ${({ isCompleted, theme }) =>
          isCompleted &&
          css`
            background-color: ${theme.color.brand};
            color: ${theme.color.background};

            svg {
              path {
                stroke-dasharray: 1000;
                stroke-dashoffset: 1000;
                animation: ${checkAnim} 15s linear forwards;
              }
            }

            &:hover {
              background-color: ${theme.color.brand};
              color: ${theme.color.background};
              cursor: not-allowed;
            }
          `}
      }

      .daily-action-content {
        color: ${({ theme }) => theme.color.gray[800]};

        a {
          text-decoration: none;
          color: ${({ theme }) => theme.color.gray[600]};
          transition: 0.2s;
          margin-top: 2px;
          display: block;
          width: fit-content;
          box-shadow: inset 0 -1px ${({ theme }) => theme.color.gray[300]};

          svg {
            height: 20px;
            vertical-align: middle;
            position: relative;
            top: -2px;
            stroke-width: 1.7px;
          }

          &:hover {
            color: ${({ theme }) => theme.color.brand};
            box-shadow: inset 0 -1px ${({ theme }) => theme.color.brand};
          }
        }
      }

      ${({ isCompleted, theme }) =>
        isCompleted &&
        `
        background-color: ${theme.color.brandLight};
        box-shadow: inset 0 0 0 1px ${theme.color.brand}20;

        .daily-action-content {
          font-weight: 500;
          color: ${theme.color.brand};
        }
      `}
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.m}px) {
    padding-top: 40px;

    .daily-action-container {
      .daily-action-header {
        .daily-action-header-details {
          .daily-action-desktop-text {
            display: inline;
          }

          .daily-action-mobile-text {
            display: none;
          }
        }
      }

      .daily-action {
        .daily-action-content {
          a {
            display: inline;
            width: fit-content;
            margin: 0;
          }
        }
      }
    }
  }
`

export default StyledDailyAction
