import { gql } from '@apollo/client'

export const loadFoldersFromCloud = gql`
  query LoadFoldersFromCloud {
    loadFoldersFromCloud {
      _id
      name
      file {
        url
        preview
        path
        metadata
        type
        ext
        size
        dimensions {
          x
          y
        }
      }
      ref
      parent
      type
      createdAt
    }
  }
`
