import { FC } from 'react'
import { times } from 'lodash'
import { PhotoSetType } from '../../../types'
import { PostTemplateListItem, ScrollRow, Skeleton } from '../..'
import { StyledPostTemplateRow } from '.'

type Props = {
  title: string
  description?: string
  templates: PhotoSetType[]
}

const PostTemplateRow: FC<Props> = ({ title, description, templates }) => {
  return (
    <StyledPostTemplateRow>
      <div className="row-heading">
        <span className="row-title">{title}</span>
        <span className="row-description">{description}</span>
      </div>
      <div className="row-list">
        {!!templates.length && (
          <ScrollRow arrowOffset={0} gap={12} isGray={true}>
            {templates.map((template) => {
              return <PostTemplateListItem template={template} key={template._id} />
            })}
          </ScrollRow>
        )}
        {!templates.length && (
          <ScrollRow arrowOffset={0} gap={12} isGray={true}>
            {times(10, (index) => {
              return (
                <Skeleton
                  styles="height: 258px; padding: 0; width: 200px; border-radius: 10px;"
                  key={index}
                />
              )
            })}
          </ScrollRow>
        )}
      </div>
    </StyledPostTemplateRow>
  )
}

export default PostTemplateRow
