import { FC } from 'react'
import { updateCreatePost, useAppDispatch, useAppSelector } from '../../../../redux'
import { CreateEditorSubConfig } from '../..'

export const TikTokInteractionAbilityConfig: FC = () => {
  const dispatch = useAppDispatch()
  const { post } = useAppSelector((state) => state.create)
  const { splits } = useAppSelector((state) => state.splits)

  if (splits.tikTok !== 'on') {
    return null
  }

  return (
    <CreateEditorSubConfig
      label="TikTok Interaction Ability"
      description="Choose how users can interact with your TikTok post."
      type="checkbox"
      options={[
        {
          label: 'Comment',
          name: 'allow_comment',
          checked: !post?.tikTokCommentDisabled,
          onSelect: (checked) => dispatch(updateCreatePost({ tikTokCommentDisabled: !checked }))
        },
        {
          label: 'Duet',
          name: 'allow_duet',
          checked: !post?.tikTokDuetDisabled,
          onSelect: (checked) => dispatch(updateCreatePost({ tikTokDuetDisabled: !checked }))
        },
        {
          label: 'Stitch',
          name: 'allow_stitch',
          checked: !post?.tikTokStitchDisabled,
          onSelect: (checked) => dispatch(updateCreatePost({ tikTokStitchDisabled: !checked }))
        }
      ]}
      // platforms={['tiktok']}
      platforms={[]}
    />
  )
}
