import { useContext } from 'react'
import { toast } from 'react-hot-toast'
import { useApolloClient } from '@apollo/client'
import { Dialog } from '@capacitor/dialog'
import { deleteUserAccountRequest } from '../../graphql'
import { userStore } from '..'

export const useAccount = () => {
  const apollo = useApolloClient()
  const { user, logout } = useContext(userStore)

  const deleteAccount = async () => {
    if (!user) {
      return
    }

    const { value: confirmed } = await Dialog.confirm({
      title: 'Delete account?',
      message: `By clicking "Delete", you agree to immediately disable your account and
      have all your account data manually deleted within 30 days. You will
      receive confirmation prior to a member of our team deleting your
      account.`,
      okButtonTitle: 'Delete'
    })

    if (!confirmed) {
      return
    }

    const toastId = toast.loading('Disabling account')
    await apollo.mutate({ mutation: deleteUserAccountRequest })
    toast.dismiss(toastId)
    toast.success('Your account has been disabled & marked for deletion')
    await logout()
  }

  const logOut = async () => {
    const { value: confirmed } = await Dialog.confirm({
      message: `Are you sure you'd like to log out of your account?`,
      okButtonTitle: 'Log Out'
    })

    if (!confirmed) {
      return
    }

    await logout()
  }

  return {
    deleteAccount,
    logOut
  }
}
