import { getFileExt } from '.'

export const isImage = (name: string) => {
  const ext = getFileExt(name)

  switch (ext.toLowerCase()) {
    case '.jpg':
    case '.jpeg':
    case '.gif':
    case '.bmp':
    case '.png':
    case '.heic':
    case '.avif':
    case '.webp':
      return true
  }
  return false
}
