import { SocialProfileType } from '../../../types'

export const getPlatformTitle = (platform: SocialProfileType['type']) => {
  switch (platform) {
    case 'instagram':
      return 'Instagram'
    case 'facebook':
      return 'Facebook'
    case 'linkedin':
      // case 'linkedin-page':
      return 'LinkedIn'
    case 'twitter':
      return 'Twitter'
    // case 'pinterest':
    //   return 'Pinterest'
    // case 'tiktok':
    //   return 'TikTok'
  }
}
