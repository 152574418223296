import { gql } from '@apollo/client'

export const affiliateLinkClickFindMany = gql`
  query AffiliateLinkClickFindMany(
    $greaterThanDate: Date
    $lessThanDate: Date
    $affiliate: String!
  ) {
    affiliateLinkClickFindMany(
      filter: {
        affiliate: $affiliate
        _operators: { createdAt: { gte: $greaterThanDate, lte: $lessThanDate } }
      }
    ) {
      _id
      affiliate
      createdAt
      updatedAt
    }
  }
`
