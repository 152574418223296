import { useContext } from 'react'
import toast from 'react-hot-toast'
import firebase from 'firebase/app'
import { useApolloClient } from '@apollo/client'
import { Capacitor } from '@capacitor/core'
import { Haptics, ImpactStyle } from '@capacitor/haptics'
import { duplicateDraft, upsertUserDraftMutation, userDraftRemoveByIdMutation } from '../../graphql'
import { addCalendarPost, removeCalendarPost, updateCalendarPost } from '../../mobile/redux'
import {
  addPost,
  clearCreatePost,
  removePost,
  setCreatePost,
  setPostsLiked,
  setPostsUsed,
  updateCreatePost,
  updatePost,
  useAppDispatch,
  useAppSelector
} from '../../redux'
import { PostType } from '../../types'
import { userStore } from '..'
import { useSegment } from '.'

export const usePost = () => {
  const apollo = useApolloClient()
  const dispatch = useAppDispatch()
  const storage = firebase.storage()
  const { user } = useContext(userStore)
  const { track } = useSegment()
  const { post: editorPost } = useAppSelector((state) => state.create)

  const deletePost = async (post: PostType) => {
    try {
      await apollo.mutate({
        mutation: userDraftRemoveByIdMutation,
        variables: { _id: post._id }
      })

      await Promise.all(
        (post.media || []).map(async (m) => {
          if (m.uploaded) {
            const fileRef = storage.refFromURL(m.url)
            await fileRef.delete()
          }
        })
      )

      track('Deleted Post', post)

      dispatch(removePost(post._id))
      dispatch(removeCalendarPost(post._id))

      if (post._id === editorPost?._id) {
        dispatch(clearCreatePost())
      }

      toast.success('Deleted post')
    } catch (err) {
      toast.error((err as any).message)
    }
  }

  const duplicatePost = async (_id: string, replaceInEditor?: boolean) => {
    try {
      const toastId = toast.loading('Duplicating post')

      const { data } = await apollo.mutate({
        mutation: duplicateDraft,
        variables: { _id: _id }
      })

      if (!data.duplicateDraft) {
        return
      }

      toast.dismiss(toastId)
      toast.success('Duplicated post')
      track('Duplicated Post', data.duplicateDraft)
      dispatch(addPost(data.duplicateDraft))
      dispatch(addCalendarPost(data.duplicateDraft))

      if (replaceInEditor) {
        dispatch(setCreatePost(data.duplicateDraft))
      }
    } catch (err) {
      toast.error((err as any).message)
    }
  }

  const togglePostUsed = async (post: PostType) => {
    try {
      const isUsed = !post.isUsed

      dispatch(setPostsUsed({ isUsed, _id: post._id }))

      if (isUsed && Capacitor.isNativePlatform()) {
        Haptics.notification()
      }

      if (post._id === editorPost?._id) {
        dispatch(updateCreatePost({ isUsed, _id: post._id }))
      }

      await apollo.mutate({
        mutation: upsertUserDraftMutation,
        variables: { record: { _id: post._id, user: user?.id, isUsed } }
      })

      track(`${isUsed ? 'Used' : 'Unused'} Post`, { ...post, used_type: 'manual' })

      toast.success(`Marked post as ${isUsed ? 'used' : 'unused'}`)
    } catch (err) {
      toast.error((err as any).message)
    }
  }

  const togglePostFavorited = async (post: PostType) => {
    try {
      const isFavorited = !post.isLiked

      dispatch(setPostsLiked({ _id: post._id, isLiked: isFavorited }))

      if (isFavorited && Capacitor.isNativePlatform()) {
        Haptics.notification()
      }

      await apollo.mutate({
        mutation: upsertUserDraftMutation,
        variables: { record: { _id: post._id, user: user?.id, isLiked: isFavorited } }
      })

      track(`${isFavorited ? 'Favorited' : 'Unfavorited'} Post`, post)

      toast.success(`${isFavorited ? 'Favorited' : 'Unfavorited'} post`)
    } catch (err) {
      toast.error((err as any).message)
    }
  }

  const updatePostData = async (post: Partial<PostType>) => {
    try {
      dispatch(updatePost(post as any))
      dispatch(updateCalendarPost(post as any))

      if (post._id === editorPost?._id) {
        dispatch(updateCreatePost(post))
      }

      await apollo.mutate({
        mutation: upsertUserDraftMutation,
        variables: { record: post }
      })
    } catch (err) {
      toast.error((err as any).message)
    }
  }

  return {
    deletePost,
    duplicatePost,
    togglePostUsed,
    togglePostFavorited,
    updatePostData
  }
}
