import styled from 'styled-components'

type StyledProps = {
  showOptions?: boolean
  isActive?: boolean
}

const StyledPostGridListItem = styled.div<StyledProps>`
  .post-preview {
    background-color: ${({ theme }) => theme.color.gray[200]};
    height: 150px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    user-select: none;
    ${({ isActive, theme }) =>
      isActive &&
      `
      box-shadow: 0 0 0 3px ${theme.color.gray[100]}, 0 0 0 5px ${theme.color.brand};
    `}

    .post-chips {
      z-index: 1;
      position: absolute;
      top: 10px;
      left: 10px;
      display: flex;

      .post-chip {
        height: 32px;
        transition: 0.2s;
        font-weight: 500;
        background-color: ${({ theme }) => theme.color.background};
        color: ${({ theme }) => theme.color.gray[600]};
        box-shadow: 0 0 0 1px rgb(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        padding: 0 8px;
        font-size: 13px;

        svg {
          height: 18px;
          margin-right: 6px;
        }

        &.icon {
          width: 32px;
          padding: 0;

          svg {
            height: 22px;
            margin: 0;
          }
        }

        &.error {
          background-color: ${({ theme }) => theme.color.red[600]};
          color: white;
          border-radius: 50%;
        }

        &:not(:last-child) {
          margin-right: 6px;
        }
      }
    }

    .post-media-length {
      z-index: 1;
      position: absolute;
      bottom: 10px;
      right: 10px;
      height: 32px;
      transition: 0.2s;
      font-weight: 500;
      background-color: rgb(0, 0, 0, 0.5);
      color: rgb(255, 255, 255, 0.9);
      box-shadow: 0 0 0 1px rgb(255, 255, 255, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      padding: 0 8px;
      font-size: 13px;
    }

    .empty-icon {
      height: 46px;
      stroke-width: 1px;
      color: ${({ theme }) => theme.color.gray[400]};
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .options-btn {
      z-index: 2;
      opacity: 0;
      position: absolute;
      right: 10px;
      top: 10px;
      width: 32px;
      height: 32px;
      transition: 0.2s;
      background-color: ${({ theme }) => theme.color.background};
      color: ${({ theme }) => theme.color.gray[600]};
      box-shadow: 0 0 0 1px rgb(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;

      svg {
        height: 24px;
      }

      &:hover {
        background-color: ${({ theme }) => theme.color.gray[100]};
      }
    }

    .media-preview {
      position: absolute;
      top: 15px;
      left: 15px;
      transition: 0.2s;
      width: calc(100% - 30px);
      height: calc(100% - 30px);
      pointer-events: none;

      img,
      video {
        border-radius: 6px;
        max-width: 100%;
        max-height: 100%;
        width: fit-content;
        margin: 0 auto;
        filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.2));
      }
    }

    .post-layout-preview {
      position: absolute;
      transition: 0.2s;
      top: 15px;
      pointer-events: none;
      left: 15px;
      width: calc(100% - 30px);
      bottom: 0;
      height: 100%;
      overflow: hidden;
      background-color: ${({ theme }) => theme.color.background};
      border-radius: 6px 6px 0 0;
      box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
        0px 2px 3px rgba(0, 0, 0, 0.05);

      img {
        width: 100%;
      }

      .post-caption-preview {
        padding: 16px;
        font-size: 13px;
        display: block;
        line-height: 16px;
        color: ${({ theme }) => theme.color.gray[500]};
      }
    }

    &::after {
      user-select: none;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.foreground}10;
      pointer-events: none;
      z-index: 1;
    }
  }

  .post-details {
    margin-top: 10px;
    padding: 0 10px;
    position: relative;
    z-index: 3;

    .post-profiles {
      margin-top: -28px;
      margin-bottom: 10px;
      display: flex;

      .avatar {
        box-shadow: 0 0 0 3px ${({ theme }) => theme.color.gray[100]};

        .platform-indicator svg {
          stroke: ${({ theme }) => theme.color.gray[100]};
        }

        &:not(:last-child) {
          margin-right: -10px;
        }
      }
    }

    .post-title {
      color: ${({ theme }) => theme.color.gray[800]};
      display: block;
      font-weight: 500;
      font-size: 15px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-align: left;
      -webkit-box-orient: vertical;
    }

    .post-status {
      display: flex;
      margin-top: 4px;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      color: ${({ theme }) => theme.color.gray[500]};
      user-select: none;

      svg {
        height: 16px;
        stroke-width: 1.75px;
        margin-right: 4px;
      }
    }
  }

  &:hover {
    cursor: pointer;

    .post-preview {
      .options-btn {
        opacity: 1;
      }

      .media-preview,
      .post-layout-preview {
        opacity: 0.3;
      }

      .post-chips {
        .post-chip:not(.error) {
          opacity: 0;
        }
      }
    }
  }

  ${({ showOptions }) =>
    showOptions &&
    `
      .post-preview {
        .options-btn {
          opacity: 1;
        }

        .media-preview,
        .post-layout-preview {
          opacity: 0.3;
        }

        .post-chips {
          .post-chip:not(.error) {
            opacity: 0;
          }
        }
      }
  `}
`

export default StyledPostGridListItem
