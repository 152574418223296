import { toast } from 'react-hot-toast'
import { PostType } from '../../../types'
import { copyToClipboard } from '..'
import { shareToFacebook } from '.'

export const useShare = () => {
  const share = async (post: Partial<PostType>, activity: 'facebook') => {
    try {
      await copyToClipboard(post.captionText || '')

      switch (activity) {
        case 'facebook':
          return await shareToFacebook(post)
      }
    } catch (err) {
      console.error(err)
      toast.error((err as any)?.message)
    }
  }

  return { share }
}
