import { FC, useState } from 'react'
import { Coordinates, Cropper, CropperRef } from 'react-advanced-cropper'
import { createPortal } from 'react-dom'
import { Button } from '..'
import { StyledCropTool } from '.'

type Props = {
  image: string
  defaultCoordinates?: Coordinates
  onCrop: (coords: Coordinates) => void
  onClose: () => void
}

const CropTool: FC<Props> = ({ image, defaultCoordinates, onCrop, onClose }) => {
  const [coords, setCoords] = useState<Coordinates>()

  const handleChange = (cropper: CropperRef) => {
    const coords = cropper.getCoordinates()

    if (!coords) {
      return
    }

    setCoords(coords)
  }

  const Element = (
    <StyledCropTool>
      <div className="cropper">
        <Cropper src={image} defaultCoordinates={defaultCoordinates} onChange={handleChange} />
        <div className="cropper-actions">
          <Button onClick={() => onClose()}>Cancel</Button>
          <Button variant="emphasis" disabled={!coords} onClick={() => onCrop(coords!)}>
            Crop
          </Button>
        </div>
      </div>
      <div className="backdrop" onClick={() => onClose()} />
    </StyledCropTool>
  )

  return createPortal(Element, document.getElementById('dialog-root') as HTMLElement)
}

export default CropTool
