import { FC, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline'
import {
  clearUploadFiles,
  setShowUploadManager,
  setTour,
  setUploadManagerCollapsed,
  useAppDispatch,
  useAppSelector
} from '../../redux'
import { Button } from '..'
import { StyledUploadManager, UploadRow } from '.'

const UploadManager: FC = () => {
  const dispatch = useAppDispatch()
  const { files, isCollapsed, isVisible } = useAppSelector((state) => state.upload)

  const getTitle = () => {
    const filesLeftToUpload = files.filter((file) => file.status === 'loading')
    const count = filesLeftToUpload?.length || 0

    if (filesLeftToUpload.length === 0) {
      return `${files.length} upload${files.length !== 1 ? 's' : ''} complete`
    }

    return `Uploading ${count} item${count !== 1 ? 's' : ''}`
  }

  const handleClose = () => {
    dispatch(clearUploadFiles())
    dispatch(setShowUploadManager(false))
  }

  useEffect(() => {
    if (!isVisible) {
      return
    }

    dispatch(setTour({ tour: 'upload-manager', onlyOnce: true }))
  }, [isVisible])

  if (!isVisible) {
    return null
  }

  const Element = (
    <StyledUploadManager data-tour-id="upload-manager" isCollapsed={isCollapsed}>
      <div className="upload-manager-header">
        <span className="upload-manager-title">{getTitle()}</span>
        <div className="upload-manager-actions">
          {!!files.filter((files) => files.status === 'loading').length && (
            <Button
              size="sm"
              isIcon
              onClick={() => dispatch(setUploadManagerCollapsed(!isCollapsed))}
            >
              <ChevronDownIcon style={{ transform: isCollapsed ? 'rotate(180deg)' : '' }} />
            </Button>
          )}
          {!files.filter((files) => ['loading', 'processing'].includes(files.status)).length && (
            <Button size="sm" isIcon onClick={() => handleClose()}>
              <XMarkIcon />
            </Button>
          )}
        </div>
      </div>
      <div className="upload-manager-uploads">
        {files.map((file) => {
          return <UploadRow file={file} key={file._id} />
        })}
      </div>
    </StyledUploadManager>
  )

  return createPortal(Element, document.getElementById('dialog-root') as HTMLElement)
}

export default UploadManager
