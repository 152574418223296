import { FC, FormEvent, useContext, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import TextareaAutosize from 'react-textarea-autosize'
import { Capacitor, CapacitorHttp } from '@capacitor/core'
import { getServerURL, useIntercom, userStore } from '../../../utils'
import { Button, Checkbox, Dialog, Spinner } from '../..'
import { StyledFeedbackDialog } from '.'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const FeedbackDialog: FC<Props> = ({ isOpen, onClose }) => {
  const [type, setType] = useState<'bug' | 'feature' | 'improvement'>('improvement')
  const [feedback, setFeedback] = useState('')
  const [loading, setLoading] = useState(false)
  const { user } = useContext(userStore)
  const { showIntercomMessenger } = useIntercom()

  const clearForm = () => {
    setLoading(false)
    setFeedback('')
    setType('improvement')
  }

  const submitFeedback = async (e: FormEvent) => {
    try {
      e.preventDefault()
      setLoading(true)
      const serverUrl = getServerURL()

      await CapacitorHttp.post({
        url: `${serverUrl}/feedback`,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {
          userId: user?.id,
          name: user?.displayName,
          email: user?.email,
          device: window?.navigator?.userAgent,
          platform: Capacitor.getPlatform(),
          type,
          feedback
        }
      })

      onClose()
      toast.success('We received your feedback!')
      clearForm()
    } catch (err) {
      toast.error((err as any)?.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setType('improvement')
    setFeedback('')
    setLoading(false)
  }, [isOpen])

  return (
    <Dialog hasXIcon isOpen={isOpen} onClose={onClose}>
      <StyledFeedbackDialog>
        <span className="dialog-title">Submit feedback</span>
        <div className="disclaimer">
          Your feedback helps us make Social Curator better. Need help now?{' '}
          <a onClick={() => showIntercomMessenger()}>Contact our customer support team</a>.
        </div>
        <form onSubmit={(e) => submitFeedback(e)}>
          <div className="form-fields">
            {/* <div className="form-field">
              <span className="field-label">I'd like to submit a</span>
              <div className="form-field-option" aria-selected={type === 'improvement'}>
                <input
                  type="radio"
                  value="improvement"
                  checked={type === 'improvement'}
                  onChange={e => setType(e.target.value as any)}
                />
                <Checkbox value={type === 'improvement'} onChange={() => {}} />
                <span className="form-field-option-title">General Feedback</span>
                <span className="form-field-option-description">
                  Use this for any general feedback you have.
                </span>
                <span className="form-field-option-examples">
                  Ex: I love the way ____ works, I wish ____ had a darker color, etc.
                </span>
              </div>
              <div className="form-field-option" aria-selected={type === 'bug'}>
                <input
                  type="radio"
                  value="bug"
                  checked={type === 'bug'}
                  onChange={e => setType(e.target.value as any)}
                />
                <Checkbox value={type === 'bug'} onChange={() => {}} />
                <span className="form-field-option-title">Bug Report</span>
                <span className="form-field-option-description">
                  Use this if you're experiencing an issue with a feature.
                </span>
                <span className="form-field-option-examples">
                  Ex: the ____ isn't loading, the site crashes when I ____, etc.
                </span>
              </div>
              <div className="form-field-option" aria-selected={type === 'feature'}>
                <input
                  type="radio"
                  value="feature"
                  checked={type === 'feature'}
                  onChange={e => setType(e.target.value as any)}
                />
                <Checkbox value={type === 'feature'} onChange={() => {}} />
                <span className="form-field-option-title">Feature Request</span>
                <span className="form-field-option-description">
                  Use this if you'd like to request a new feature be added.
                </span>
                <span className="form-field-option-examples">
                  Ex: I wish I could ____, I should be able to ____, etc.
                </span>
              </div>
            </div> */}
            <label className="form-field">
              {/* <span className="field-label">Tell us more</span> */}
              <TextareaAutosize
                value={feedback}
                placeholder="Let us know what you're thinking..."
                minRows={5}
                maxRows={10}
                onChange={(e) => setFeedback(e.target.value)}
              />
            </label>
          </div>
          <Button disabled={!feedback.trim() || loading} variant="emphasis" isFullWidth>
            {loading ? <Spinner /> : 'Submit feedback'}
          </Button>
        </form>
      </StyledFeedbackDialog>
    </Dialog>
  )
}

export default FeedbackDialog
