import { DateTime } from 'luxon'
import { isMobile } from './isMobile'

export const getStartOfWeek = (dateInput?: DateTime) => {
  const date = dateInput || DateTime.now()

  if (!isMobile()) {
    return date.startOf('week')
    // return date
    //   .plus({ days: 1 })
    //   .startOf('week')
    //   .minus({ days: 1 })
  }

  return date.startOf('day')
}
