import styled from 'styled-components'

type StyledProps = {
  layout: 'grid' | 'list'
}

const StyledPostList = styled.div<StyledProps>`
  margin-top: 20px;

  .emphasis-empty-state {
    margin-top: -20px;
    padding: 90px 30px;
  }

  .infinite-scroll {
    overflow: visible !important;
  }

  .post-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.m}px) {
    // margin-top: 40px;

    .post-grid {
      grid-gap: 16px;

      ${({ layout }) =>
        layout === 'grid' &&
        `
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 10px;
      `}
    }
  }
`

export default StyledPostList
