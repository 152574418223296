import { gql } from '@apollo/client'

export const onlyStorySetFindMany = gql`
  query OnlyStorySetFindMany($issue: String!) {
    storySetFindMany(filter: { issue: $issue }, sort: INDEX_ASC) {
      _id
      title
      canvaLink
      description
      thumbnail
      index
    }
  }
`
