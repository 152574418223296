import styled from 'styled-components'

type StyledProps = {
  keyboardHeight: number
}

const StyledShiftDrawer = styled.div<StyledProps>`
  width: 100vw;
  box-sizing: border-box;
  display: grid;
  transition: 0.2s;
  grid-template-rows: auto minmax(0, 1fr);
  height: calc(
    100dvh -
      ${({ keyboardHeight }) =>
        !!keyboardHeight ? `${keyboardHeight}px` : 'var(--bottom-spacing)'}
  );
  overflow: hidden;
  background-color: ${({ theme }) => theme.color.background};
  z-index: ${({ theme }) => theme.elevation.override};

  .header-container {
    padding: var(--top-spacing) 0 10px 0;
    border-bottom: 1px solid ${({ theme }) => theme.color.gray[200]};

    .header {
      padding: 0 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;

      .back-btn {
        height: 36px;
        width: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: -12px;
        color: ${({ theme }) => theme.color.gray[800]};
        background-color: transparent;

        svg {
          height: 28px;
          stroke-width: 2px;
        }
      }

      .drawer-title {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: ${({ theme }) => theme.color.gray[800]};
        font-weight: 800;
        font-size: 17px;
      }
    }
  }

  .content {
    height: 100%;
    overflow-y: auto;
  }
`

export default StyledShiftDrawer
