import styled from 'styled-components'

const StyledCreateEditorCaptionTextarea = styled.div`
  position: relative;
  display: flex;

  .textarea {
    padding: 14px;
    width: 100%;
    resize: none;
    background-color: transparent;
    box-sizing: border-box;
    color: transparent;
    caret-color: ${({ theme }) => theme.color.gray[800]};
    position: relative;
    line-height: 22px;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .dummy-text-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 1;
    height: 100%;
    overflow: hidden;
  }

  .dummy-text {
    position: absolute;
    top: 0;
    box-sizing: border-box;
    padding: 14px;
    width: 100%;
    color: ${({ theme }) => theme.color.gray[800]};
    white-space: pre-wrap;
    overflow-wrap: anywhere;
    line-height: 22px;

    a {
      pointer-events: all;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      &[data-disabled] {
        cursor: text;
        text-decoration: none;
        pointer-events: none;
      }
    }

    .hashtag {
      color: ${({ theme }) => theme.color.gray[500]};
    }

    .mention {
      color: ${({ theme }) => theme.color.brand};
    }
  }
`

export default StyledCreateEditorCaptionTextarea
