import { FC, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { times } from 'lodash'
import { useApolloClient } from '@apollo/client'
import { getPlatformStats } from '../../../../graphql'
import { setPostsNum, useAppDispatch, useAppSelector } from '../../../../redux'
import { HomeActivity, HomeLevel } from '..'
import { StyledHomeStats } from '.'

const HomeStats: FC = () => {
  const apollo = useApolloClient()
  const dispatch = useAppDispatch()
  const [streak, setStreak] = useState(0)
  const [week, setWeek] = useState<boolean[]>(times(7, () => false))
  const [loading, setLoading] = useState(true)
  const { posts } = useAppSelector((state) => state.posts)

  const getStats = async () => {
    try {
      setLoading(true)

      const { data } = await apollo.query({
        query: getPlatformStats,
        fetchPolicy: 'no-cache'
      })

      dispatch(setPostsNum(data?.getPlatformStats?.createdPosts || 0))
      setStreak(data?.getPlatformStats?.streak || 0)
      setWeek(data?.getPlatformStats?.week || [])
    } catch (err) {
      console.error(err)
      toast.error((err as any)?.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getStats()
  }, [posts.length])

  return (
    <StyledHomeStats>
      <HomeLevel />
      <HomeActivity streak={streak} week={week} />
    </StyledHomeStats>
  )
}

export default HomeStats
