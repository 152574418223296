import { FC } from 'react'
import { PageLinkType } from '../../../types'
import { StyledPageLink } from '.'

type Props = {
  link: PageLinkType
  onClick?: (block: string) => void | undefined
}

const PageLink: FC<Props> = ({ link, onClick }) => {
  const handleClick = () => {
    if (onClick) {
      onClick(link._id)
    }
  }

  return (
    <StyledPageLink className={`page-link ${link.type}`}>
      {link.type === 'url' && (
        <a
          href={link.metadata.url}
          target="_blank"
          rel="noreferrer"
          aria-label={link.metadata.label}
          onClick={() => handleClick()}
        >
          <span>{link.metadata.label}</span>
        </a>
      )}
      {link.type === 'header' && <span className="header-text">{link.metadata.label}</span>}
    </StyledPageLink>
  )
}

export default PageLink
