import { FC, createRef, useState } from 'react'
import { ClipboardIcon, HashtagIcon } from '@heroicons/react/24/outline'
import { TiktokIcon } from '../../../assets'
import { ReactComponent as FacebookIcon } from '../../../assets/facebook-icon.svg'
import { ReactComponent as InstagramIcon } from '../../../assets/instagram.svg'
import { ReactComponent as LinkedInIcon } from '../../../assets/linkedin-icon.svg'
import { ReactComponent as TwitterIcon } from '../../../assets/twitter-icon.svg'
import {
  setCreateContentTab,
  setCreatePlatform,
  setCreateTab,
  updateCreatePost,
  useAppDispatch,
  useAppSelector
} from '../../../redux'
import { copyToClipboard, useSegment } from '../../../utils'
import { OptionsPopover } from '../..'
import { CreateEditorCaptionEmoji, CreateEditorCaptionTextarea } from '..'
import { StyledCreateEditorCaption } from '.'

const CreateEditorCaption: FC = () => {
  const characterCountBtnRef = createRef<HTMLButtonElement>()
  const dispatch = useAppDispatch()
  const [showCharacterCountPopover, setShowCharacterCountPopover] = useState(false)
  const { track } = useSegment()
  const { post, platform } = useAppSelector((state) => state.create)

  const handleCopyPostCaption = () => {
    copyToClipboard(post?.captionText, 'Copied caption to clipboard')
    track('Copied Post to Clipboard')
  }

  const getPlatformCharacterCount = () => {
    switch (platform) {
      case 'instagram':
        return 2200
      case 'facebook':
        return 63206
      case 'linkedin':
        return 3000
      case 'twitter':
        return 280
      // case 'pinterest':
      //   return 500
      // case 'tiktok':
      //   return 150
    }
  }

  if (post?.instagramPublishType === 'story') {
    return null
  }

  return (
    <>
      <StyledCreateEditorCaption>
        <div className="post-editor-area-container">
          {!post?.captionText?.length && (
            <span className="placeholder">
              Start typing or{' '}
              <a
                onClick={() => {
                  dispatch(setCreateTab('content'))
                  dispatch(setCreateContentTab('captions'))
                }}
              >
                choose a caption
              </a>
            </span>
          )}
          <CreateEditorCaptionTextarea />
        </div>
        <div className="editor-caption-toolbar">
          <div className="editor-caption-toolbar-group">
            <CreateEditorCaptionEmoji />
            <button
              data-tooltip="Copy to clipboard"
              data-tooltip-pos="top-center"
              className="editor-caption-toolbar-btn icon-btn"
              disabled={!post?.captionText?.trim()?.length}
              onClick={() => handleCopyPostCaption()}
            >
              <ClipboardIcon />
            </button>
            <button
              data-tooltip={!showCharacterCountPopover ? 'Change character count platform' : ''}
              data-tooltip-pos="top-center"
              data-active={showCharacterCountPopover}
              className="editor-caption-character-count-btn"
              ref={characterCountBtnRef}
              onClick={() => setShowCharacterCountPopover(!showCharacterCountPopover)}
            >
              <span className="editor-caption-character-count">
                {post?.captionText?.length || 0}
              </span>
              <span className="editor-caption-character-count-limit">
                <span>/</span> {getPlatformCharacterCount()} characters
              </span>
            </button>
          </div>
          <div className="editor-caption-toolbar-group">
            <button
              data-tooltip={`${(post?.captionText?.split('#').length || 1) - 1} used hashtags`}
              data-tooltip-pos="top-center"
              className="editor-caption-toolbar-btn"
              onClick={() => dispatch(setCreateTab('hashtags'))}
            >
              <HashtagIcon /> {(post?.captionText?.split('#').length || 1) - 1}
            </button>
            <button
              className="editor-caption-toolbar-btn"
              onClick={() => dispatch(updateCreatePost({ captionText: '' }))}
            >
              Clear
            </button>
          </div>
        </div>
      </StyledCreateEditorCaption>
      <OptionsPopover
        options={[
          {
            icon: <InstagramIcon />,
            label: 'Instagram',
            stayOpen: true,
            isSelected: platform === 'instagram',
            action: () => dispatch(setCreatePlatform('instagram'))
          },
          {
            icon: <FacebookIcon />,
            label: 'Facebook',
            stayOpen: true,
            isSelected: platform === 'facebook',
            action: () => dispatch(setCreatePlatform('facebook'))
          },
          {
            icon: <LinkedInIcon />,
            label: 'LinkedIn',
            stayOpen: true,
            isSelected: platform === 'linkedin',
            action: () => dispatch(setCreatePlatform('linkedin'))
          },
          {
            icon: <TwitterIcon />,
            label: 'Twitter',
            stayOpen: true,
            isSelected: platform === 'twitter',
            action: () => dispatch(setCreatePlatform('twitter'))
          }
          // {
          //   icon: <TiktokIcon />,
          //   label: 'TikTok',
          //   stayOpen: true,
          //   isSelected: platform === 'tiktok',
          //   action: () => dispatch(setCreatePlatform('tiktok'))
          // }
        ]}
        position="top-left"
        classToAvoid="editor-caption-character-count-btn"
        buttonRef={characterCountBtnRef}
        isOpen={showCharacterCountPopover}
        onClose={() => setShowCharacterCountPopover(false)}
      />
    </>
  )
}

export default CreateEditorCaption
