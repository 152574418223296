import { FC } from 'react'
import { updateCreatePost, useAppDispatch, useAppSelector } from '../../../../redux'
import { CreateEditorSubConfig } from '../..'

export const InstagramReelAudioNameConfig: FC = () => {
  const dispatch = useAppDispatch()
  const { post } = useAppSelector((state) => state.create)

  const handleChange = (value: any) => {
    dispatch(
      updateCreatePost({
        instagramReelAudioName: value
      })
    )
  }

  if (post?.instagramPublishType !== 'reel') {
    return null
  }

  return (
    <CreateEditorSubConfig
      name="instagram_reel_audio_name"
      label="Reel Audio Name"
      description="Name the audio of your Reel."
      inputParams={{
        placeholder: 'Reel audio name...'
      }}
      type="input"
      platforms={['instagram']}
      onChange={(value) => handleChange(value)}
    />
  )
}
