import { FC } from 'react'
import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import { setDesignerSubTab, setDesignerTab, useAppDispatch, useAppSelector } from '../../../redux'
import {
  DesignerChatPanel,
  DesignerColorPanel,
  DesignerEditImagePanel,
  DesignerElementsPanel,
  DesignerGalleryPanel,
  DesignerNav,
  DesignerStickersPanel,
  DesignerTextPanel,
  DesignerUploadsPanel
} from '..'
import { ReactComponent as DesignerTab } from './designer-tab.svg'
import { StyledDesignerSidebar } from '.'

const DesignerSidebar: FC = () => {
  const dispatch = useAppDispatch()
  const { tab, subTab } = useAppSelector((state) => state.designer)

  return (
    <StyledDesignerSidebar isSubTab={!!subTab} isExpanded={!!tab || !!subTab}>
      <div className="sidebar">
        <DesignerNav />
        {tab === 'uploads' && !subTab && <DesignerUploadsPanel />}
        {tab === 'text' && !subTab && <DesignerTextPanel />}
        {tab === 'elements' && !subTab && <DesignerElementsPanel />}
        {tab === 'stickers' && !subTab && <DesignerStickersPanel />}
        {tab === 'photos' && !subTab && <DesignerGalleryPanel />}
        {tab === 'chat' && !subTab && <DesignerChatPanel />}
        {subTab === 'fill-color' && <DesignerColorPanel />}
        {subTab === 'edit-image' && <DesignerEditImagePanel />}
      </div>
      {!!tab && !subTab && (
        <button
          data-tooltip="Collapse"
          data-tooltip-pos="right-center"
          className="designer-tab-button"
          onClick={() => {
            dispatch(setDesignerTab(undefined))
            dispatch(setDesignerSubTab(undefined))
          }}
        >
          <ChevronLeftIcon className="chevron-icon" />
          <DesignerTab className="designer-tab" />
        </button>
      )}
    </StyledDesignerSidebar>
  )
}

export default DesignerSidebar
