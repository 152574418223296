import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { parse, stringify } from 'query-string'

type SearchTerms = {
  [x: string]: any
}

export const useSearchParams = (searchTerms: SearchTerms) => {
  const { search, pathname } = useLocation()
  const history = useHistory()
  const queriesFromURL = parse(search, { parseBooleans: true })

  const isJSON = (value: any) => {
    // `typeof null` somehow evaluates to 'object'
    if (value === null) {
      return false
    }

    return typeof value === 'object'
  }

  const getSearchFromURL = () => {
    // for every search term passed to this hook,
    // grab the named key from the URL and process it
    const searchObjectFromUrl = Object.keys(searchTerms).reduce((accumulator, key) => {
      const queryValueFromURL = queriesFromURL[key] as string

      if (typeof queryValueFromURL === 'undefined') {
        return accumulator
      }

      if (isJSON(searchTerms[key])) {
        return { ...accumulator, [key]: JSON.parse(queryValueFromURL) }
      }

      return { ...accumulator, [key]: queryValueFromURL }
    }, {})

    return searchObjectFromUrl
  }

  const insertSearchIntoURL = () => {
    // for every search term passed to this hook,
    // grab the named key from the state and add it to the URL
    const currentSearchFromState = Object.entries(searchTerms).reduce(
      (accumulator: any, currentItem: any) => {
        const [key, value] = currentItem

        if (isJSON(value)) {
          return { ...accumulator, [key]: JSON.stringify(value) }
        }

        return { ...accumulator, [key]: value }
      },
      {}
    )

    const newUrlQuery = stringify(currentSearchFromState, { skipEmptyString: true })
    history.replace(`${pathname}?${newUrlQuery}`)
  }

  // insert search query into URL every time the search changes
  useEffect(() => {
    insertSearchIntoURL()
  }, [stringify(searchTerms)])

  return { getSearchFromURL }
}
