import { FC, useContext, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useHistory, useLocation } from 'react-router-dom'
import firebase from 'firebase/app'
import { Spinner } from '../../../components'
import { ROUTES, userStore } from '../../../utils'
import { StyledAdminLoginAsUserView } from '.'

const AdminLoginAsUserView: FC = () => {
  const location = useLocation()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const { user, logout, refresh } = useContext(userStore)

  const loginAsUser = async (token: string) => {
    try {
      setLoading(true)

      await logout()
      await refresh()
      await firebase.auth().signInWithCustomToken(token)
      await refresh()

      history.push(ROUTES.home)
    } catch (err) {
      console.error(err)
      toast.error((err as any)?.message || err)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (loading) {
      return
    }

    if (user?.role !== 'admin') {
      history.push(ROUTES.home)
      return
    }

    const urlParams = new URLSearchParams(location.search)
    const { token } = Object.fromEntries(urlParams)

    if (!token) {
      history.push(ROUTES.home)
      return
    }

    loginAsUser(token)
  }, [user, loading, location.search, location.state])

  return (
    <StyledAdminLoginAsUserView>
      <Spinner />
    </StyledAdminLoginAsUserView>
  )
}

export default AdminLoginAsUserView
