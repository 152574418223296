import { sortBy, uniq, uniqBy } from 'lodash'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { HashtagFolderType, HashtagType } from '../../types'

type InitialState = {
  hashtags: HashtagType[]
  folders: HashtagFolderType[]
  folder?: string
}

const initialState: InitialState = {
  hashtags: [],
  folders: []
}

export const hashtagsSlice = createSlice({
  name: 'hashtags',
  initialState,
  reducers: {
    setHashtags: (state, action: PayloadAction<HashtagType[]>) => {
      state.hashtags = action.payload
    },
    clearHashtags: (state) => {
      state.hashtags = []
    },
    addHashtag: (state, action: PayloadAction<HashtagType>) => {
      state.hashtags = sortBy(uniqBy([action.payload, ...state.hashtags], 'hashtag'))
    },
    updateHashtag: (state, action: PayloadAction<Partial<HashtagType>>) => {
      state.hashtags = state.hashtags.map((hashtag) => {
        if (hashtag._id === action.payload._id) {
          return {
            ...hashtag,
            ...action.payload
          }
        }

        return hashtag
      })
    },
    removeHashtag: (state, action: PayloadAction<string>) => {
      state.hashtags = state.hashtags.filter((hashtag) => hashtag._id !== action.payload)
    },
    setHashtagFolders: (state, action: PayloadAction<HashtagFolderType[]>) => {
      state.folders = action.payload
    },
    setHashtagFolder: (state, action: PayloadAction<InitialState['folder']>) => {
      state.folder = action.payload
    },
    clearHashtagFolders: (state) => {
      state.folders = []
    },
    addHashtagFolder: (state, action: PayloadAction<HashtagFolderType>) => {
      state.folders = sortBy([action.payload, ...state.folders], 'label')
    },
    updateHashtagFolder: (state, action: PayloadAction<Partial<HashtagFolderType>>) => {
      state.folders = state.folders.map((folder) => {
        if (folder._id === action.payload._id) {
          return {
            ...folder,
            ...action.payload
          }
        }

        return folder
      })
    },
    deleteHashtagFolder: (state, action: PayloadAction<string>) => {
      state.folders = state.folders.filter((folder) => folder._id !== action.payload)
    }
  }
})

export const {
  setHashtags,
  clearHashtags,
  addHashtag,
  updateHashtag,
  removeHashtag,
  setHashtagFolders,
  setHashtagFolder,
  clearHashtagFolders,
  addHashtagFolder,
  updateHashtagFolder,
  deleteHashtagFolder
} = hashtagsSlice.actions
export default hashtagsSlice.reducer
