import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { PageType } from '../../types'

type InitialState = {
  page?: PageType
  savedPage?: PageType
  isSaved: boolean
  newAvatar?: {
    file: File | null
    preview: string | null
  }
}

const initialState: InitialState = {
  isSaved: true
}

export const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<InitialState['page']>) => {
      state.page = action.payload
      state.savedPage = action.payload
    },
    updatePage: (state, action: PayloadAction<PageType>) => {
      state.page = {
        ...state.page,
        ...action.payload
      }
      state.isSaved = false
    },
    clearPage: (state) => {
      state.page = undefined
    },
    savePage: (state) => {
      state.isSaved = true
      state.newAvatar = undefined
    },
    setNewAvatar: (state, action: PayloadAction<InitialState['newAvatar']>) => {
      state.newAvatar = action.payload
      state.isSaved = false
    },
    removeNewAvatar: (state) => {
      state.newAvatar = undefined
      state.isSaved = false
    },
    clearNewAvatar: (state) => {
      state.newAvatar = undefined
    }
  }
})

export const {
  setPage,
  updatePage,
  clearPage,
  savePage,
  setNewAvatar,
  removeNewAvatar,
  clearNewAvatar
} = pageSlice.actions
export default pageSlice.reducer
