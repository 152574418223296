import { gql } from '@apollo/client'

export const duplicateDraft = gql`
  mutation DuplicateDraft($_id: String!) {
    duplicateDraft(_id: $_id) {
      _id
      title
      captionText
      imageUrl
      userUpload
      socialProfiles
      imageId
      fullPath
      template
      challenge
      postStatus
      coverPhotoOffset
      coverPhotoUrl
      instagramPublishType
      instagramReelAudioName
      tikTokPrivacyStatus
      tikTokCommentDisabled
      tikTokDuetDisabled
      tikTokStitchDisabled
      media {
        _id
        url
        type
        uploaded
        design
        ref
        dimensions {
          x
          y
        }
      }
      imageDimensions {
        x
        y
      }
      category
      postDate
      tags
      isLiked
      isUsed
      index
      lastModified
      createdAt
    }
  }
`
