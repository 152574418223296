import { FC, createRef, useContext } from 'react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ROUTES, getPlatformIcon } from '../../../utils'
import { Dialog, connectionStore } from '../..'
import { StyledAuthenticateWithFacebookDialog } from '.'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const AuthenticateWithFacebookDialog: FC<Props> = ({ isOpen, onClose }) => {
  const dialogRef = createRef<HTMLDivElement>()
  const { connectPlatform } = useContext(connectionStore)

  const handleClose = () => {
    const dialog = dialogRef.current as any

    if (dialog) {
      return dialog.close()
    }

    onClose()
  }

  const handleSubmit = () => {
    connectPlatform('instagram')
    handleClose()
  }

  return (
    <Dialog isOpen={isOpen} hasXIcon onClose={() => onClose()} ref={dialogRef}>
      <StyledAuthenticateWithFacebookDialog>
        <div className="header">
          <span className="title">Continue with Facebook</span>
        </div>
        <div className="body">
          <span className="body-text">
            <span className="body-title">Wait...why do I have to login with Facebook?</span>
            Facebook requires Instagram Business or Creator accounts to be connected to a Facebook
            Page in order for auto-posting to work. This means you’ll first need to login to your{' '}
            <b>
              <i>Facebook account</i>
            </b>{' '}
            that manages your{' '}
            <b>
              <i>Facebook page</i>
            </b>{' '}
            before you connect your Instagram Account to Social Curator (
            <i>friendly reminder that Facebook Pages aren’t the same as Facebook Profiles</i>).
            <br />
            <br />
            <span className="body-title">What happens next?</span>
            Once you click the button below to login to Facebook, sign into the Facebook{' '}
            <b>profile</b> that has Admin permissions for the Facebook <b>page</b> connected to your
            Instagram account. Check out our{' '}
            <a href={ROUTES.external.connectingProfilesArticle} target="_blank">
              Instagram Connection Guide
            </a>{' '}
            for more.
          </span>
        </div>
        <div className="action-bar">
          <button className="cancel-btn" onClick={() => handleClose()}>
            Cancel
          </button>
          <button className="continue-btn" onClick={() => handleSubmit()}>
            {getPlatformIcon('facebook')}
            Login with Facebook
          </button>
        </div>
      </StyledAuthenticateWithFacebookDialog>
    </Dialog>
  )
}

export default AuthenticateWithFacebookDialog
