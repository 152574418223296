import { SocialProfileType } from '../../../types'

export const getPlatformLogo = (platform: SocialProfileType['type']) => {
  switch (platform) {
    case 'instagram':
      return 'instagram-logo.png'
    case 'facebook':
      return 'facebook-logo.png'
    case 'linkedin':
      // case 'linkedin-page':
      return 'linkedin-logo.png'
    case 'twitter':
      return 'twitter-logo.png'
    // case 'pinterest':
    //   return 'pinterest-logo.png'
    // case 'tiktok':
    //   return 'tiktok-logo.png'
  }
}
