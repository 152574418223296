import { FC, ReactNode, useContext, useEffect } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { DateTime } from 'luxon'
import { ROUTES, userStore } from '../../utils'
import { RenewTrialView } from '../../views'
import { Header, Sidebar, State, ToTopButton } from '..'
import { StyledAppLayout } from '.'

type Props = {
  children: ReactNode
}

const AppLayout: FC<Props> = ({ children }) => {
  const location = useLocation()
  const { user, loaded } = useContext(userStore)

  // const isOldCancelledUser = () => {
  //   if (!user) {
  //     return false
  //   }

  //   const signedUp = DateTime.fromSeconds(user.createdAt?.seconds || 0)
  //   const betaStart = DateTime.fromISO('2023-03-16')
  //   const diff = betaStart.diff(signedUp, 'days').days

  //   if (diff <= 0) {
  //     return false
  //   }

  //   if (user.stripeSubscriptionStatus === 'Active') {
  //     return false
  //   }

  //   if (!user.hasTrialed) {
  //     return false
  //   }

  //   return true
  // }

  useEffect(() => {
    if ((location?.state as any)?.scroll === 'none') {
      return
    }

    window.scrollTo({ top: 0 })
    document.getElementById('app-content')?.scrollTo({ top: 0 })
  }, [location.pathname])

  if (!user?.id) {
    return <>{children}</>
  }

  if (
    !!user &&
    !user?.aiOnboarding?.occupation &&
    !location.pathname.includes('/user') &&
    !location.pathname.includes('/community') &&
    !location.pathname.includes('/academy') &&
    !(location?.state as any)?.redirect
  ) {
    return (
      <Redirect
        to={{
          pathname: ROUTES.onboarding,
          state: Object.fromEntries(new URLSearchParams(location.search))?.redirect
        }}
      />
    )
  }

  // if (isOldCancelledUser()) {
  //   return <RenewTrialView />
  // }

  return (
    <StyledAppLayout>
      <Header />
      <Sidebar />
      <div id="app-content" className="app-content">
        {children}
      </div>
      <ToTopButton />
      <State />
    </StyledAppLayout>
  )
}

export default AppLayout
