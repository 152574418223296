import styled, { keyframes } from 'styled-components'

const checkAnim = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`

type StyledProps = {
  canSelect?: boolean
  isDisabled?: boolean
  isExpired?: boolean
  isCollapsed?: boolean
}

const StyledConnectionListItem = styled.div<StyledProps>`
  padding: ${({ isCollapsed }) => (isCollapsed ? 6 : 10)}px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  transition: 0.2s;
  justify-content: space-between;
  width: 100%;
  user-select: none;
  box-sizing: border-box;

  .connection-details-container {
    display: flex;
    align-items: center;

    .avatar-container {
      margin-right: 10px;
      display: flex;
      align-items: center;
      position: relative;

      .check-icon {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        z-index: 1;
        background-color: ${({ theme }) => theme.color.brand}b4;
        color: white;
        object-fit: cover;

        svg {
          height: 24px;
          stroke-width: 2px;

          path {
            stroke-dasharray: 1000;
            stroke-dashoffset: 1000;
            animation: ${checkAnim} 15s linear forwards;
          }
        }
      }
    }

    .connection-details {
      .connection-username {
        font-weight: 500;
        font-size: 15px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        color: ${({ theme }) => theme.color.gray[800]};
      }

      .connection-plaform {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-top: 2px;
        color: ${({ theme }) => theme.color.gray[500]};

        svg {
          height: 16px;
          margin-right: 6px;
        }
      }
    }
  }

  .connection-actions-container {
    display: flex;
    align-items: center;

    .btn {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  ${({ canSelect, theme, isCollapsed }) =>
    canSelect &&
    `
    &:hover {
      cursor: pointer;
      background-color: ${theme.color.gray[100]};
    }
  `}

  ${({ isDisabled, theme }) =>
    isDisabled &&
    `
    opacity: 0.8;
    filter: grayscale(1);

    .connection-details-container, .connection-actions-container {
      pointer-events: none;
    }

    &:hover {
      cursor: not-allowed;
      background-color: ${theme.color.background};
    }
  `}

  ${({ isDisabled, theme }) =>
    isDisabled &&
    `
    opacity: 0.8;
    filter: grayscale(1);

    .connection-details-container, .connection-actions-container {
      pointer-events: none;
    }

    &:hover {
      cursor: not-allowed;
      background-color: ${theme.color.background};
    }
  `}

  ${({ isExpired, theme }) =>
    isExpired &&
    `
    .connection-details-container {
      .connection-details {
        .connection-username, .connection-plaform {
          color: ${theme.color.red[700]};
        }
      }
    }
  `}
`

export default StyledConnectionListItem
