import { FC, RefObject, useEffect, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { searchUserDraftsQuery } from '../../../graphql'
import { useAppSelector } from '../../../redux'
import { PostType } from '../../../types'
import { EmptyState, Popover, Spinner } from '../..'
import { RecentPostItem, StyledRecentPostsPopover } from '.'

type Props = {
  classToAvoid: string
  buttonRef: RefObject<HTMLDivElement | HTMLButtonElement>
  isOpen: boolean
  onClose: () => void
}

const RecentPostsPopover: FC<Props> = ({ isOpen, onClose, ...rest }) => {
  const apollo = useApolloClient()
  const { post } = useAppSelector((state) => state.create)
  const [posts, setPosts] = useState<PostType[]>([])
  const [loading, setLoading] = useState(true)

  const loadDrafts = async () => {
    try {
      setLoading(true)

      const { data } = await apollo.query({
        query: searchUserDraftsQuery,
        fetchPolicy: 'no-cache',
        variables: {
          page: 0,
          search: '',
          categories: [],
          sort: 'modified',
          items: 30
        }
      })

      setPosts(data?.searchUserDrafts?.docs || [])
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setPosts([])

    if (isOpen) {
      loadDrafts()
    }
  }, [isOpen])

  useEffect(() => {
    onClose()
  }, [post?._id])

  return (
    <Popover position="bottom-left" isOpen={isOpen} onClose={() => onClose()} {...rest}>
      <StyledRecentPostsPopover>
        {loading && <Spinner />}
        {!loading && !posts.length && (
          <EmptyState title="No recent posts" body="Recently edited posts appear here" />
        )}
        {posts.map((post) => {
          return <RecentPostItem post={post} key={post._id} />
        })}
      </StyledRecentPostsPopover>
    </Popover>
  )
}

export default RecentPostsPopover
