import { gql } from '@apollo/client'

export const getWorkspaces = gql`
  query GetWorkspaces {
    getWorkspaces {
      _id
      name
      avatar
      users {
        id
        email
        displayName
      }
    }
  }
`
