import styled from 'styled-components'

type StyledProps = {
  showOptions?: boolean
}

const StyledHashtagFolderListItem = styled.div<StyledProps>`
  padding: 0 20px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  width: 100%;
  box-sizing: border-box;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

  .hashtag-folder-details-container {
    .hashtag-folder-header {
      display: flex;
      align-items: center;

      .hashtag-folder-title {
        font-weight: 500;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-align: left;
        -webkit-box-orient: vertical;
        color: ${({ theme }) => theme.color.gray[700]};
      }

      .hashtag-folder-count {
        font-size: 14px;
        margin-left: 8px;
        padding: 2px 6px;
        box-sizing: border-box;
        min-width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50rem;
        background-color: ${({ theme }) => theme.color.gray[100]};
        color: ${({ theme }) => theme.color.gray[500]};
      }
    }

    .hashtag-folder-preview {
      display: flex;
      margin-top: 2px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-align: left;
      -webkit-box-orient: vertical;
      font-size: 15px;
      color: ${({ theme }) => theme.color.gray[500]};

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .hashtag-folder-actions {
    flex-shrink: 0;
    margin-left: 6px;
    display: none;

    .btn {
      height: 41px;
    }

    .options-btn {
      width: 20px;
      margin-right: -6px;
      margin-left: 4px;
      background-color: transparent;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.gray[200]};
  }

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.color.brandLight};

    .hashtag-folder-actions {
      display: flex;
    }
  }

  ${({ showOptions, theme }) =>
    showOptions &&
    `
    background-color: ${theme.color.brandLight};

    .hashtag-folder-actions {
      display: flex;
    }
  `}
`

export default StyledHashtagFolderListItem
