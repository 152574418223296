import { FC } from 'react'
import { DragOverlay, useDndContext } from '@dnd-kit/core'
import { CalendarPost } from '..'

const CalendarDragOverlay: FC = () => {
  const { active } = useDndContext()

  return (
    <DragOverlay>
      {!!active && active.data.current?.post ? (
        <CalendarPost post={active.data.current?.post} />
      ) : null}
    </DragOverlay>
  )
}

export default CalendarDragOverlay
