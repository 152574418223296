import styled from 'styled-components'

const StyledAddToPostDialog = styled.div`
  width: 100vw;
  box-sizing: border-box;
  user-select: none;

  .photo-preview {
    height: 200px;
    padding: 20px;
    position: relative;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.color.gray[100]};
    border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};

    .media-preview {
      position: absolute;
      top: 15px;
      left: 15px;
      transition: 0.2s;
      width: calc(100% - 30px);
      height: calc(100% - 30px);

      img,
      video {
        border-radius: 6px;
        max-width: 100%;
        max-height: 100%;
        width: fit-content;
        margin: 0 auto;
        filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.2));
      }
    }
  }

  .post-list {
    max-height: 250px;
    overflow-y: auto;
    display: grid;

    .post-select-btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px;

      .post-details-container {
        display: flex;
        align-items: center;
        font-size: 15px;
        margin-right: 10px;
        color: ${({ theme }) => theme.color.gray[700]};

        .post-img {
          width: 50px;
          height: 50px;
          position: relative;
          border-radius: 4px;
          flex-shrink: 0;
          margin-right: 10px;
          background-color: ${({ theme }) => theme.color.gray[100]};

          .media-preview {
            position: absolute;
            top: 5px;
            left: 5px;
            transition: 0.2s;
            width: calc(100% - 10px);
            height: calc(100% - 10px);
            pointer-events: none;

            img,
            video {
              border-radius: 4px;
              max-width: 100%;
              max-height: 100%;
              width: fit-content;
              margin: 0 auto;
              filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.2));
            }
          }
        }

        .post-details {
          .post-title {
            text-align: left;
            font-weight: 500;
            letter-spacing: -0.01rem;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-size: 14px;
            color: ${({ theme }) => theme.color.gray[800]};
          }

          .post-caption {
            font-size: 13px;
            display: -webkit-box;
            word-break: break-word;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            margin-top: 1px;
            color: ${({ theme }) => theme.color.gray[600]};

            .empty {
              color: ${({ theme }) => theme.color.gray[400]};
            }
          }
        }
      }

      &:hover {
        cursor: pointer;
      }

      &:not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.color.gray[200]};
      }
    }
  }

  .actions {
    padding: 4px;
    border-top: 1px solid ${({ theme }) => theme.color.gray[300]};
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    max-width: 390px;
  }
`

export default StyledAddToPostDialog
