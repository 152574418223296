import styled from 'styled-components'

const StyledDesignerFontFamilyTool = styled.button`
  border-radius: 4px;
  box-sizing: border-box;
  width: 180px;
  background-color: ${({ theme }) => theme.color.background};
  transition: 0.2s;
  color: ${({ theme }) => theme.color.gray[900]};
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 6px 0 8px;
  justify-content: space-between;
  vertical-align: middle;
  overflow: hidden;
  height: 32px;
  margin: 0 8px 0 0;
  border: 1px solid ${({ theme }) => theme.color.gray[300]};

  span {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-align: left;
    font-size: 15px;
    -webkit-box-orient: vertical;
    font-weight: 500;
  }

  svg {
    height: 18px;
    stroke-width: 2.25px;
    flex-shrink: 0;
    margin-left: 8px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.gray[100]};
  }
`

export default StyledDesignerFontFamilyTool
