import styled from 'styled-components'

const StyledBusinessDrawer = styled.div`
  padding: 14px;

  .disclaimer {
    border-radius: 12px;
    margin-bottom: 14px;
    display: flex;
    padding: 14px;
    background-color: ${({ theme }) => theme.color.green[100]};
    color: ${({ theme }) => theme.color.green[800]};

    svg {
      height: 28px;
      flex-shrink: 0;
      margin-right: 10px;
    }

    .disclaimer-content {
      font-size: 15px;

      b {
        display: block;
        margin-bottom: 4px;
      }
    }
  }

  .inputs {
    display: grid;
    gap: 14px;
  }
`

export default StyledBusinessDrawer
