import { useMemo } from 'react'
import { serialize, serializePlainText } from './slate'

type Props = {
  html: string | undefined | null
  fallbackText?: string
  plainText?: boolean
  maxLength?: number
  noLinks?: boolean
  [rest: string]: any
}

export const RenderSlate = (args: Props) => {
  const fallbackPlaceholder = 'Loading...'
  const {
    html,
    noLinks,
    fallbackText = fallbackPlaceholder,
    maxLength,
    plainText = false,
    ...rest
  } = args

  const parseAndSerialize = (data: any) => {
    if (!data) {
      return ''
    }

    try {
      if (plainText) {
        return serializePlainText({ children: JSON.parse(data) })
      }
      return serialize({ children: JSON.parse(data) }, { noLinks })
    } catch (err) {
      return [{ type: 'paragraph', children: [{ text: fallbackText }] }]
    }
  }

  const memoizedHTML = useMemo(() => {
    let data = parseAndSerialize(html)
    if (typeof maxLength === 'number' && typeof data === 'string') {
      data = data.trim()
      return `${data.substring(0, maxLength)}${data.length > maxLength ? '…' : ''}`
    }
    return data
  }, [html])

  return <div dangerouslySetInnerHTML={{ __html: memoizedHTML }} {...rest} />
}
