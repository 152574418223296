import styled from 'styled-components'

const StyledBanner = styled.div`
  min-height: 50px;
  padding: 16px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  text-align: center;
  user-select: none;
  background-color: ${({ theme }) => theme.color.brandLight};
  color: ${({ theme }) => theme.color.brand};

  .banner-message {
    font-weight: 500;
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 20px;
  }
`

export default StyledBanner
