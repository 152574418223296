import { uniq } from 'lodash'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type InitialState = {
  tour?: string
  tours: string[]
}

const initialState: InitialState = {
  tours: []
}

export const tourSlice = createSlice({
  name: 'tour',
  initialState,
  reducers: {
    setTour: (state, action: PayloadAction<{ tour?: string; onlyOnce?: boolean }>) => {
      if (action.payload.onlyOnce && state.tours.includes(action.payload.tour!)) {
        return
      }

      state.tour = action.payload.tour

      if (!action.payload.tour) {
        return
      }

      state.tours = uniq([...state.tours, action.payload.tour])
    }
  }
})

export const { setTour } = tourSlice.actions
export default tourSlice.reducer
