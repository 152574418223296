import { useEffect, useState } from 'react'
import { Capacitor } from '@capacitor/core'
import { Keyboard, KeyboardInfo } from '@capacitor/keyboard'

export const useKeyboardHeight = () => {
  const [height, setHeight] = useState(0)

  const handleKeyboard = (info?: KeyboardInfo) => {
    setHeight(info?.keyboardHeight || 0)
  }

  const handleBrowserResize = (e: any) => {
    setHeight(Math.max(window.innerHeight - e.target.height, 0))
  }

  useEffect(() => {
    if (!Capacitor.isPluginAvailable('Keyboard')) {
      window.visualViewport?.addEventListener('resize', handleBrowserResize)
      return
    }

    handleKeyboard()

    Keyboard.addListener('keyboardDidShow', handleKeyboard)
    Keyboard.addListener('keyboardWillShow', handleKeyboard)
    Keyboard.addListener('keyboardWillHide', handleKeyboard)

    return () => {
      // Keyboard.removeAllListeners()

      if (!Capacitor.isPluginAvailable('Keyboard')) {
        window.visualViewport?.removeEventListener('resize', handleBrowserResize)
      }
    }
  }, [])

  return {
    height
  }
}
