import styled from 'styled-components'

type StyledProps = {
  showChat?: boolean
}

const StyledChatDialog = styled.div<StyledProps>`
  display: grid;
  height: 100vh;
  position: relative;

  .chat-container {
    height: 100vh;
    padding: 10px;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.color.gray[100]};
    overflow: hidden;
    display: grid;

    .chat {
      position: relative;
      border-radius: 12px;
      overflow: hidden;
      display: grid;
      grid-template-rows: auto minmax(0, 1fr) auto;
      background-color: ${({ theme }) => theme.color.background};
      box-shadow: 0px 0px 0px 1px ${({ theme }) => theme.color.foreground}15,
        0px 2px 3px ${({ theme }) => theme.color.foreground}10;
    }
  }

  ${({ showChat }) =>
    !showChat &&
    `
    width: 450px;
    position: absolute;
    transform: translateX(-450px);
  `}
`

export default StyledChatDialog
