import styled from 'styled-components'

const StyledPhotosViewHeader = styled.div`
  .photos-view-header-top {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: minmax(0, 1fr) auto;

    .input {
      grid-column: 1/3;
    }
  }

  .photos-view-additional-filters {
    display: flex;
    align-items: center;
    padding: 4px;
    margin: 8px -4px -4px -4px;
    overflow-x: auto;

    button {
      flex-shrink: 0;
      height: 34px;
      border-radius: 4px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      transition: 0.2s;
      background-color: ${({ theme }) => theme.color.background};
      color: ${({ theme }) => theme.color.gray[600]};
      box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.1),
        0px 2px 3px rgba(0, 0, 0, 0.1);

      .color-list {
        display: flex;
        align-items: center;

        .color {
          position: relative;
          border-radius: 50%;
          height: 16px;
          width: 16px;
          margin-right: 7px;
          transition: 0.2s;
          box-shadow: 0 0 0 2px ${({ theme }) => theme.color.background};

          &:not(:last-child) {
            margin-right: -4px;
          }

          img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
          }

          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.foreground}20;
          }
        }
      }

      svg {
        height: 20px;
        margin: 0 6px 0 -2px;
        color: ${({ theme }) => theme.color.gray[500]};
      }

      &.filter-orientation-btn {
        text-transform: capitalize;
      }

      &:not(:last-child) {
        margin-right: 10px;
      }

      &:hover {
        background-color: ${({ theme }) => theme.color.gray[100]};
        box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.2), 0px 0px 0px 1px rgba(0, 0, 0, 0.15),
          0px 2px 3px rgba(0, 0, 0, 0.15);

        .color-list {
          .color {
            box-shadow: 0 0 0 2px ${({ theme }) => theme.color.gray[100]};
          }
        }
      }

      &.clear-btn {
        box-shadow: none;
        background-color: ${({ theme }) => theme.color.gray[100]};
        color: ${({ theme }) => theme.color.gray[500]};

        &:hover {
          background-color: ${({ theme }) => theme.color.gray[200]};
          color: ${({ theme }) => theme.color.gray[600]};
        }
      }
    }
  }

  .bubbles {
    margin-top: 12px;

    [data-key='only-favorites-separator'] {
      width: 20px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 22px;
        width: 1px;
        border-radius: 6px;
        background-color: ${({ theme }) => theme.color.gray[300]};
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    .photos-view-header-top {
      grid-template-columns: minmax(0, 1fr) 160px auto;

      .input {
        grid-column: unset;
      }
    }

    .photos-view-additional-filters {
      overflow: visible;
    }
  }
`

export default StyledPhotosViewHeader
