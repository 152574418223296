import styled from 'styled-components'

const StyledPublishHowField = styled.div`
  .publish-method-disclaimer {
    border-radius: 6px;
    padding: 12px 14px;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    color: ${({ theme }) => theme.color.gray[500]};
    background-color: ${({ theme }) => theme.color.gray[100]};

    svg {
      height: 20px;
      flex-shrink: 0;
      margin-right: 12px;
    }
  }

  .publish-method-selection {
    margin-top: 10px;
    display: grid;
    row-gap: 10px;

    .publish-method-option {
      padding: 12px;
      border-radius: 6px;
      display: grid;
      align-content: start;
      text-align: left;
      color: ${({ theme }) => theme.color.gray[500]};
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.gray[300]};
      background-color: ${({ theme }) => theme.color.background};
      font-size: 12px;

      b {
        display: block;
        font-weight: 500;
        font-size: 15px;
        color: ${({ theme }) => theme.color.gray[700]};
      }

      .publish-method-option-content {
        display: none;

        svg {
          height: 46px;
          width: 46px;
          flex-shrink: 0;
          margin-right: 10px;
        }
      }

      span {
        display: none;
      }

      &[aria-selected='true'] {
        box-shadow: inset 0 0 0 2px ${({ theme }) => theme.color.gray[800]};
      }

      &:last-child {
        cursor: not-allowed;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    .publish-method-selection {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 10px;

      .publish-method-option {
        b {
          margin-bottom: 8px;
        }

        .publish-method-option-content,
        span {
          display: flex;
        }
      }
    }
  }
`

export default StyledPublishHowField
