import { useEffect } from 'react'
import { Capacitor } from '@capacitor/core'
import { StatusBar, Style } from '@capacitor/status-bar'
import { useAppSelector } from '../../redux'

export const useStatusBar = () => {
  const { theme, showChat } = useAppSelector((state) => state.state)

  const handleStatusBar = () => {
    if (!Capacitor.isPluginAvailable('StatusBar')) {
      return
    }

    if (showChat) {
      StatusBar.setStyle({ style: Style.Dark })
      return
    }

    StatusBar.setStyle({ style: theme === 'dark' ? Style.Dark : Style.Light })
  }

  useEffect(() => {
    handleStatusBar()
  }, [theme, showChat])
}
