import { FC, ReactNode } from 'react'
import classNames from 'classnames'
import { StyledEmptyState } from '.'

type Props = {
  image?: string
  title: string | ReactNode
  body: string
  button?: ReactNode
  disclaimer?: string | ReactNode
  className?: string
}

const EmptyState: FC<Props> = ({ image, title, body, button, disclaimer, className }) => {
  return (
    <StyledEmptyState className={classNames('empty-state', className)}>
      {image && <img src={image} alt={typeof title === 'string' ? title : ''} />}
      <span className="empty-state-title">{title}</span>
      <span className="empty-state-body">{body}</span>
      {button}
      {disclaimer && <span className="disclaimer">{disclaimer}</span>}
    </StyledEmptyState>
  )
}

export default EmptyState
