import { useContext } from 'react'
import { SplitFactory } from '@splitsoftware/splitio-browserjs'
import { setSplits, useAppDispatch } from '../../redux'
import { SPLITS } from '../../types'
import { conditionalStage, userStore } from '..'

export const useSplit = () => {
  const { user } = useContext(userStore)
  const dispatch = useAppDispatch()

  const configureSplit = async () => {
    const splitKey = process.env.REACT_APP_SPLIT_KEY

    if (!splitKey || !user) {
      return
    }

    const CONFIG = SplitFactory({
      core: {
        authorizationKey: splitKey,
        key: user?.id || 'anonymous'
      }
    })

    const client = CONFIG.client()
    await client.ready()

    const fullName = user.displayName || ''
    const firstAndLast = fullName.split(' ')

    client.setAttributes({
      uid: user.id,
      name: user.displayName,
      firstName: firstAndLast[0] || '',
      lastName: firstAndLast.slice(1).join(' ') || '',
      email: user.email,
      free: user.free || false,
      stripe_subscription_status: user.stripeSubscriptionStatus,
      subscription_interval: user.subscriptionInterval,
      stripe_customer_id: user.stripeCustomerId,
      role: user.role,
      beta: user.beta || false,
      use: user?.aiOnboarding?.use || 'Personal',
      occupation: user?.aiOnboarding?.occupation || '',
      company: user?.aiOnboarding?.company || '',
      ages: user?.aiOnboarding?.ages || [],
      genders: user?.aiOnboarding?.genders || [],
      occupations: user?.aiOnboarding?.occupations || [],
      voices: user?.aiOnboarding?.voices || [],
      connected_profiles_amount: user?.socialProfiles?.length || [],
      ...conditionalStage(user?.createdAt?.seconds !== undefined, {
        user_created_at: new Date((user.createdAt?.seconds || 0) * 1000)
      })
    })

    const treatments = client.getTreatments(SPLITS)
    dispatch(setSplits(treatments))

    // Listen for changes without hard app-restart
    client.on(client.Event.SDK_UPDATE, () => {
      const treatments = client.getTreatments(SPLITS)
      dispatch(setSplits(treatments))
    })
  }

  return {
    configureSplit
  }
}
