import { gql } from '@apollo/client'

export const getContentPillars = gql`
  query GetContentPillars {
    getContentPillars {
      _id
      icon
      title
      content
    }
  }
`
