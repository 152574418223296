import { FC, createRef, useEffect, useState } from 'react'
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  MeasuringConfiguration,
  MeasuringStrategy,
  closestCenter,
  useDndContext
} from '@dnd-kit/core'
import { SortableContext, arrayMove, useSortable } from '@dnd-kit/sortable'
import { PlusIcon } from '@heroicons/react/24/outline'
import { updateCreatePost, useAppDispatch, useAppSelector } from '../../../redux'
import { useSensors } from '../../../utils'
import { ScrollRow, UploadDialog } from '../..'
import { CreateEditorDropzone, CreateEditorMediaItem } from '..'
import { StyledCreateEditorMedia } from '.'

const CreateEditorMedia: FC = () => {
  const sensors = useSensors()
  const dispatch = useAppDispatch()
  const scrollRef = createRef<any>()
  const [showUploadDialog, setShowUploadDialog] = useState(false)
  const { post } = useAppSelector((state) => state.create)

  const measuring: MeasuringConfiguration = {
    droppable: {
      strategy: MeasuringStrategy.Always
    }
  }

  const handleScroll = () => {
    const scrollElement = scrollRef?.current?.scrollContainer?.current

    if (!scrollElement) {
      return
    }

    scrollElement?.scrollTo({
      left: scrollElement.scrollWidth,
      behavior: 'smooth'
    })
  }

  const handleReorder = (sort: DragEndEvent) => {
    if (!post?.media) {
      return
    }

    const { active, over } = sort

    if (active.id === over?.id) {
      return
    }

    const oldIndex = active.data.current?.index || 0
    const newIndex = over?.data.current?.index || 0

    const sortedMedia = arrayMove(post.media, oldIndex, newIndex)

    dispatch(
      updateCreatePost({
        media: sortedMedia
      })
    )
  }

  useEffect(() => {
    handleScroll()
  }, [post?.media?.length])

  if (!post?.media?.length) {
    return <CreateEditorDropzone />
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      measuring={measuring}
      onDragEnd={(event) => handleReorder(event)}
    >
      <SortableContext strategy={() => null} items={post.media.map((m) => m._id)}>
        <StyledCreateEditorMedia>
          <DragIndicatorFront />
          <ScrollRow gap={10} arrowOffset={0} apiRef={scrollRef}>
            {post.media.map((item, index) => {
              return <CreateEditorMediaItem media={item} index={index} key={item._id} />
            })}
          </ScrollRow>
          {post.media.length < 10 && (
            <button className="add-media-btn" onClick={() => setShowUploadDialog(true)}>
              <PlusIcon />
            </button>
          )}
        </StyledCreateEditorMedia>
      </SortableContext>
      <MediaOverlay />
      <UploadDialog
        isOpen={showUploadDialog}
        onClose={() => setShowUploadDialog(false)}
        addToPost={true}
      />
    </DndContext>
  )
}

const DragIndicatorFront = () => {
  const { isSorting, activeIndex, overIndex } = useSortable({ id: 'droppbale', disabled: true })

  if (!isSorting) {
    return null
  }

  if (overIndex !== 0 || activeIndex === 0) {
    return null
  }

  return <div className="drag-indicator" />
}

const MediaOverlay = () => {
  const { active } = useDndContext()

  return (
    <DragOverlay>
      {!!active && active.data.current?.media ? (
        <CreateEditorMediaItem media={active.data.current?.media} isOverlay />
      ) : null}
    </DragOverlay>
  )
}

export default CreateEditorMedia
