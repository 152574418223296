import { gql } from '@apollo/client'

export const storyCreateOne = gql`
  mutation StoryCreateOne(
    $fullPath: String!
    $imageUrl: String!
    $index: Float!
    $recordSet: String!
    $migrated: Boolean!
  ) {
    storyCreateOne(
      record: {
        fullPath: $fullPath
        imageUrl: $imageUrl
        index: $index
        storySet: $recordSet
        migrated: $migrated
      }
    ) {
      record {
        _id
        fullPath
        imageUrl
        index
        storySet
        migrated
      }
    }
  }
`
