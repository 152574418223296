import { createRef, FC, useEffect, useState } from 'react'
import { useActiveObject, useEditor } from '@layerhub-io/react'
import { IStaticText } from '@layerhub-io/types'
import { SpacingPopover } from '../..'
import { ReactComponent as SpacingIcon } from '../../../assets/spacing-icon.svg'
import { DesignerToolButton } from '.'

const DesignerSpacingTool: FC = () => {
  const btnRef = createRef<HTMLButtonElement>()
  const editor = useEditor()
  const activeObject = useActiveObject() as IStaticText
  const [charSpacing, setCharSpacing] = useState(0)
  const [lineHeight, setLineHeight] = useState(0)
  const [showOptions, setShowOptions] = useState(false)

  useEffect(() => {
    if (activeObject?.charSpacing) {
      setCharSpacing(activeObject.charSpacing / 10)
    }
    if (activeObject?.lineHeight) {
      setLineHeight(activeObject.lineHeight * 10)
    }
  }, [activeObject])

  return (
    <>
      <DesignerToolButton
        data-tooltip="Spacing"
        className="spacing-btn"
        isIcon
        isActive={showOptions}
        onClick={() => setShowOptions(!showOptions)}
        ref={btnRef}
      >
        <SpacingIcon />
      </DesignerToolButton>
      <SpacingPopover
        charSpacing={charSpacing}
        lineHeight={lineHeight}
        position="bottom-right"
        buttonRef={btnRef}
        classToAvoid="spacing-btn"
        isOpen={showOptions}
        onClose={() => setShowOptions(false)}
        onCharSpacingChange={(value) => {
          setCharSpacing(value)

          editor.objects.update({
            charSpacing: value * 10
          })
        }}
        onLineHeightChange={(value) => {
          setLineHeight(value)

          editor.objects.update({
            lineHeight: value / 10
          })
        }}
      />
    </>
  )
}

export default DesignerSpacingTool
