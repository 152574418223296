import { FC, HTMLAttributes } from 'react'
import { StyledAvatar } from '.'

type Props = {
  name: string
  image?: string
  size?: number
  isSquare?: boolean
  inRow?: boolean
} & HTMLAttributes<HTMLDivElement>

const Avatar: FC<Props> = ({ name, image, size = 34, isSquare, inRow, ...rest }) => {
  const getUserInitials = () => {
    const userSplit = name.trim().split(' ')

    if (userSplit.length == 1) {
      return userSplit[0][0]?.toUpperCase() || ''
    }

    return `${userSplit[0][0]?.toUpperCase() || ''}${userSplit[1][0]?.toUpperCase() || ''}`
  }

  if (!name) {
    return null
  }

  return (
    <StyledAvatar {...rest} className="avatar" size={size} isSquare={isSquare} inRow={inRow}>
      {image && <img src={image} alt={name} />}
      {!image && <span>{getUserInitials()}</span>}
    </StyledAvatar>
  )
}

export default Avatar
