import styled from 'styled-components'

const StyledAppLayout = styled.div`
  display: grid;
  overscroll-behavior-y: none;
  grid-template-rows: auto minmax(0, 1fr) auto;
  padding-top: calc(env(safe-area-inset-top) + 65px);
  // padding-bottom: calc(max(calc(env(safe-area-inset-bottom) - 4px), 10px) + 53px);

  .app-content {
    overflow-y: auto;
    overscroll-behavior-y: none;
    position: relative;
    height: calc(
      100vh - (env(safe-area-inset-top) + 65px) -
        (max(calc(env(safe-area-inset-bottom) - 4px), 10px) + 53px)
    );
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    display: grid;
    height: fit-content;
    grid-template-columns: auto minmax(0, 1fr);
    padding: 0;

    .app-content {
      overflow: unset;
      height: fit-content;
      min-height: 100vh;
    }
  }
`

export default StyledAppLayout
