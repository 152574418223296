import styled from 'styled-components'

const StyledCreateHeader = styled.div`
  padding: var(--top-spacing) 16px 10px 16px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.color.background};
  border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
  z-index: ${({ theme }) => theme.elevation.fixed};

  .header-group {
    display: flex;
    align-items: center;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50rem;
      height: 36px;
    }

    .close-btn {
      color: ${({ theme }) => theme.color.gray[900]};
      background-color: transparent;
      width: 36px;
      margin: 0 -8px;

      svg {
        height: 36px;
      }
    }

    .title-btn {
      padding: 0 10px;
      font-weight: 600;
      color: ${({ theme }) => theme.color.gray[900]};
      margin-left: 6px;
      font-size: 18px;
      background-color: transparent;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-align: left;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow-wrap: anywhere;
    }

    .options-btn {
      width: 36px;
      background-color: transparent;
      border: 2px solid ${({ theme }) => theme.color.gray[900]};
      color: ${({ theme }) => theme.color.gray[900]};
      transition: transform 0.2s, opacity 0.2s;

      svg {
        height: 32px;
      }

      &[data-active='true'] {
        transform: scale(0.85);
        opacity: 0.5;
      }
    }

    .share-btn {
      padding: 0 10px 0 12px;
      font-weight: 600;
      color: white;
      background-color: ${({ theme }) => theme.color.brand};
      margin-left: 6px;

      svg {
        height: 20px;
        stroke-width: 2px;
        margin-left: 4px;
      }
    }
  }
`

export default StyledCreateHeader
