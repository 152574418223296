import { BitType } from '../../types'

export const bits: BitType[] = [
  {
    _id: '0ny4m4dC2T4jV2EKT7tm',
    label: 'Your Favorite Cookie',
    text: "What's your go-to cookie?",
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'ADCBin0Fn0qLfJf95QuC',
    label: 'Where Would You Go If You Could Leave Today',
    text: 'What\'s that place called again where you\'re "all work and no play"?',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'GnUxdOVIP3WvSWWVswUk',
    label: 'Decorating Poll',
    text: 'What about you?',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: '1N49FikfPT0FPZYPy3Zi',
    label: 'Comparing Work to Working Out',
    text:
      'Sigh… if only I enjoyed workout mentioned previously as much as I enjoy being a job title!',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'HZdOdKSwWtdIS0G9l1XN',
    label: 'Sharing Encouragement for All',
    text: 'Comment your piece of encouragement below!',
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: '42ZR6JurTZBBjTgasWwH',
    label: 'A Time When Plans Unraveled',
    text: 'Anyone out there (besides me!',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: '3cWs6krOtw3kzl9imrz6',
    label: "Why You're Right for Your ",
    text: 'Truth bomb: You can probably find a job title to what you do for less than I charge.',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: '2Zqntd7nIdEs6hCItoYC',
    label: 'Who/What Grounds You',
    text: 'What (or who) do you look to in order to keep your feet planted firmly on the ground?',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: '3g3YZDEMZwbiT6Vuz966',
    label: 'Your Business Anniversary',
    text: "What's your favorite way to celebrate big milestones?",
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: '91LcnqdzGWtwNPewdzdI',
    label: 'What Made You the Businessperson You Are Today',
    text: "It's time for a flashback!",
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: '9AjNuHvl44Z2PwYfEX1y',
    label: 'Daydreaming of "What If"',
    text: 'Tell me a "what if" story of yours in the comments!',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: '35n4ZQoEw06lQePGFbOs',
    label: 'Describing Your Style',
    text: "What's your style?",
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: '1YmozWFzt5HTGsiivOKM',
    label: 'Something You Love As Much As Your Business',
    text: 'which is a LOT!',
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: '6AyJjmHH4bK4Tb4WF6sR',
    label: "The Emotion You'll Choose",
    text: "What's on the menu for today?",
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: '6CWaoYn97NUvF4Ygm1Ib',
    label: 'Where You Got Your Start',
    text: 'Where did you get your start?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'FHoKyHzeLE89LtaQTiaG',
    label: 'Perks of Working With Your Clients/Customers',
    text:
      'Of course, I love that my clients keep me in business and I love that they recommend me to their friends and families.',
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: '96gjCn049R6vIdByS8Lj',
    label: "Something You're Learning",
    text: 'Do you remember learning to ride a bike?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'FPg82rmMgfqAtYl1uDC5',
    label: 'How You Keep Track of Your Dreams',
    text: 'A digital dreamer?',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'R2CRRgrqQumVvDJNkWiA',
    label: "Something You're Insecure About",
    text: "Lately, I remind myself I'm human and so is making mistakes.",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'I19eAFrBoCSaXvVp1ufG',
    label: 'Something You Believe In',
    text: 'Do you agree?',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'GEqx0GuXIZMhmtWXdrFG',
    label: 'An Awkward Dating Story',
    text: "Thank goodness my date and I worked past it, and now we're married!",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'GV0mCvA5oMnHfMizrtgg',
    label: 'Unexpected Dreams',
    text: 'Yeah, me neither.',
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: '5INvc9K5ItWwHtdnXR0U',
    label: 'Client Testimonial',
    text: 'Click the link in my bio to get yours!',
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'ElIwaWgQ6AXPtjrlob7H',
    label: 'How You Should Talk to Yourself',
    text: '"You\'re being ridiculous.',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: '3nOpkl6AHhpTDrLL5GrO',
    label: 'Promotion Ending',
    text: 'Time is running out.',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'IoTlcZdCkjsuP2Hr4hWl',
    label: 'Weather Where You Live',
    text:
      'But before I do that, I just wanted to say that wherever you are in the world, thanks for letting me be a part of it!',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'FY2s4MYauwB85agcplTe',
    label: 'A Fun Announcement ',
    text:
      "Check back later this week to see how we're using a brand-new shipment of Southwest turquoise in a new line of our jewelry!",
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'EqFODLpSOmW729OqgeVr',
    label: "Who or What You're Thankful For",
    text: 'Comment below!',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'HxAmewFJd6j325cnxjof',
    label: 'Sleeping In vs. Waking Up Early',
    text: 'Love it or hate it, here it comes: I could sleep in until 2pm every day if I wanted to.',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'D2sHdzb3DTYyztkUuxuN',
    label: 'Freedom to Take Vacations',
    text: 'Vacation mode: activated!',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'CuqdTKWZVcKSuVQ2EWxs',
    label: 'The Ingredients In Your Business',
    text: 'Curious about the ingredients in my business?',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'FL8DAmhy1NeL85XpxYu8',
    label: 'Your Favorite Pizza',
    text: 'I bet I know one thing we can all agree on.',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'SC8OAv2KAPAncUGGTcjd',
    label: 'Starting Your Day',
    text: 'Every morning, I brew a pot of dark roast and then do a 30-minute workout.',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: '49B54QLIgrrOkUSRvgmq',
    label: 'How You Can Help During Busy Months',
    text: "Send me a DM if you'd like to talk more about how I can help you.",
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: '8KOpu0yGZO8aUr1lNo9b',
    label: 'Go-To Karaoke Song',
    text: 'Wanna know a secret?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'QX2x43p8CZ3mrLovfJL9',
    label: 'Who Believes In Your Business',
    text: 'No one ever said starting my own business would be easy.',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'ZNszSTzVX9Juvei1x9zM',
    label: "How You'd Spend Your Perfect Day",
    text: "I'd invite Gary Vee over for dinner.",
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'AsyyQWYn75voir5bgEEK',
    label: 'How You Breakfast/Brunch',
    text: 'Similar at all, or totally different?',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'g7mUyQ0l7iYBKuR4mLJT',
    label: 'Favorite Drink',
    text: 'Tell me what you drink and what it says about you!',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: '6Gl0fuwvGGCa7hondbZS',
    label: 'Live Announcement',
    text: 'Join me LIVE at date, time, and format of your Live to hear all about it!',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'KG8UkbWQ0lm6sZEVu9gl',
    label: 'Enjoy the Present',
    text: 'But first, spill all of the details about your weekend plans in the comments!',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'VqMnafcCUMG8KxDGCBnN',
    label: 'Your Uncaffeinated Pick-Me-Up',
    text: 'Afterward, my heart is so happy!',
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'AMRXT6iieP2gmoMxodKX',
    label: 'Favorite Meal of the Day',
    text: 'Everybody eats, right?',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'OEaE66NNNUwiiCXXIFaa',
    label: 'Fun Facts',
    text: "By all means, it's 4 o'clock somewhere!",
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'TJuzxukwYJlmDEEysT0s',
    label: 'Encouraging Advice',
    text: 'These are the words that keep me going!',
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: '0uURhv7nF1NpZNfsvN7S',
    label: 'Your Favorite Cookie',
    text: "What's your favorite kind of cookie?",
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'UPCvC17OG2bcnEhCXqbp',
    label: "One Thing You'd Never Get Tired Of",
    text: 'One thing for the rest of your life… Go!',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'UB1kTDqQqjQhqZ0Z7cTV',
    label: 'What Makes Your Business Unique?',
    text: "So to that end, I'm keeping my nose to the grindstone.",
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'E7pOuXLDr80LoflZJG4D',
    label: 'Your Drink of Choice',
    text: 'What about you?',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: '1j3ySYz7pCzIILbgHe7W',
    label: 'Promotion Reminder',
    text:
      'Over the months, I have developed a step-by-step curriculum to give my clients control of their renewed wellness.',
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'Jn5eEoleFcUiYa5BdeAw',
    label: 'Your Work Soundtrack',
    text:
      "Bonus points if you name specific artists and songs… I'm looking for new tunes to jam out to!",
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'CeSKtMPuDFQkvfTe5Nkt',
    label: 'Daily Pick-Me-Up',
    text: 'Tag someone below who needs to hear this pick-me-up today!',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'a7CdCezYm9EC4IoJ7TSi',
    label: 'Family Tree',
    text: "When I was a kid, my mom used to say I'd be a doctor!",
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'GiLn5XLVVKexRMLpUdu8',
    label: 'What Makes You Unique',
    text: 'Ready to get started?',
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'Ybe4oE4BAidB4yHbAtOk',
    label: "What You'd Get Away From",
    text: 'Spill!',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: '4zqjMDNzKmTpn6DJYNnn',
    label: 'Sharing A Secret',
    text: "I don't usually tell people this, but you won't spill it, right?",
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'AkFqCeeHoZuu8zSGOVpc',
    label: "How You'll Help Your Clients",
    text: 'How can I help you this year, friend?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'PLd4zdcHlWBhgEweT3qx',
    label: 'Introduction',
    text: 'Now enough about me, tell me one fun fact about YOU in the comments!',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'F749ImM92BUUUFI44dnP',
    label: 'Who Belongs Here',
    text: "No matter what, I'm so happy you're here!",
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'GWlZrsxhtWqCYdjIOacl',
    label: 'Your Cool Product/Service',
    text: 'How can I help you today?',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: '35wxl3lgoV9wBdso2hxt',
    label: 'Enjoying Your Freedom',
    text: 'As current season turns to upcoming season, I am so grateful to own my business.',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: '4HrK4eO8CL4K1xMXntB4',
    label: "All About The 90's",
    text: 'Bonus points if you slide a pic into my DMs!',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: '6gazHV0apA4zhY1PiKtL',
    label: 'Reaching Out to Your Target Market',
    text:
      "Are you a first-time home buyer who's ready to call in reinforcements to help you make the most reasonable decision for your growing family?",
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'AooMbH0MrJKn7CH1B8tI',
    label: 'Why You Own Your Business',
    text: 'Someone recently asked: Why do you own your own business?',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'E4ApRZYAY0OU6larQNrg',
    label: 'How You Celebrate',
    text: 'For business-related wins, I tend to how you celebrate.',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'DozPytISS3Gw4vZMlu9x',
    label: 'Seize the Opportunity',
    text: 'Did you know "Carpe diem" means "Seize the day" in Latin?',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'ah3lsrQo97BbZmRcMxTA',
    label: 'Likes vs. Dislikes',
    text: "You can relax knowing that I've got you covered!",
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'FXaQfvTUQ16iKoGLMm9W',
    label: 'Promotion Ending',
    text:
      'With just a few hours left in the sale, I/we hope to be a continued resource for all your hair styling needs, and will be glued to Instagram to answer all of your questions.',
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'O9GSZfsyNc8PhNi5wCTW',
    label: 'What You Love About Your Town',
    text: 'So, what do you love about your town?',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'GPXmyPXBW1UCSuhAMH6O',
    label: 'Seasons of Life',
    text:
      'Just as there are seasons throughout the year, there are seasons throughout our lives that change, too.',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'YUmP5acK82MsDpWnodAL',
    label: "How You're Different from Competitors",
    text: 'What else do you need to know?',
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'Pq7mPCbqgrWJHENSlYxZ',
    label: 'Welcome Gift For New Clients',
    text: "What's more welcoming than a welcome mat?",
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: '1ryVcr61CPtlOm0JnqYy',
    label: 'Items You Love to Use',
    text:
      "I wouldn't be able to survive life as a job title without them, and I LOVE using these tools to how you help your ideal client!",
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'QdjUgnNvSaQVszZSP36l',
    label: 'Where Your Creative Juices Flow',
    text: "Lately, that's been the noisy bar on the corner of 3rd and Main.",
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'B57TRtT5Lu2mNLjwvJlp',
    label: 'Your Upcoming Plans',
    text: "I'm share your plans.",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'TyeSADbPEyMRw7TVAbFw',
    label: 'Cinco de Mayo (May 5th)',
    text:
      'The members of the @yourhandle community are made up of all different types of people: from to new business owners to seasoned.',
    type: 'hook',
    category: 'holiday'
  },
  {
    _id: '8bYiOLPt18MpTuNDRQKx',
    label: 'Helpful Tips',
    text: 'Voila!',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'X8rkBu9FyCsFnha5buvU',
    label: 'You Are a Peach',
    text: "I don't know who needs to hear this, but YOU are a peach!",
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'CGHy4wm9vP8GyzVTpsPM',
    label: 'Surprising Job Necessity',
    text:
      'What might surprise you is how necessary list unexpected job necessity—app, group chat platform, organizational tool, special snack or drink, etc.',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'BAFe3sOLg4V5MKcEJgCh',
    label: 'If You Were An Animal',
    text: 'Who wouldn\'t want the title "Queen of the Jungle"?',
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'DfT2IcnKD6Rk42JYPMor',
    label: 'A Fresh Take In Your Business',
    text: 'Comment below with any questions!',
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'ITcfpxMhnLSUwC6VAhZZ',
    label: "3 People You're Cheersing To",
    text: "Who's one person you'd like to 'cheers' to today?",
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'EL0D2ARcnTwDdr6eBbpy',
    label: 'Business Casual Uniform',
    text: 'Real talk: I dress professionally for online meetings, but my feet need fluff.',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: '8Rq1YpitE5hvPJonpTFq',
    label: 'Your Road Trip Must-Haves',
    text:
      "Today, I'm packing up the model of car and headed to destination with who you're going with.",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'FNPLMsAbwyeQvmaENRse',
    label: 'Vacation Announcement',
    text:
      "I've certainly got the heart of a hustler, but beginning today, I'll be tearing myself away from my constant state of design and redesign to spend a fun-filled week with my cousins in Park City.",
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: '9V2bRu4DpXhhuBZxkjUd',
    label: 'Your Favorite Seasonal Beverage',
    text: 'What is your favorite seasonal beverage?',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'KogN6X1zo15DvmRiylBw',
    label: 'Value-Packed Tutorial',
    text: 'Let me know below!',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: '4IlSGXLiYFeSyzDUKBFO',
    label: 'Caption 1',
    text: 'Drop a comment below with your number one concern!',
    type: 'call-to-action',
    category: 'holiday'
  },
  {
    _id: 'Gnn2MGZNc2kTKY93Rfre',
    label: 'Oktoberfest (Late September to Early October)',
    text: 'your turn!',
    type: 'call-to-action',
    category: 'holiday'
  },
  {
    _id: 'DBfaOVr32wDbPmTBaEGE',
    label: 'An Embarrassing Moment In Business',
    text:
      "But here I am, still standing, and laughing my head off at one of my favorite early memories, which I'd like to share with you!",
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'EDHPcpZzAGoQMgDUxhpR',
    label: 'The Little Thing That Keeps You Going',
    text: 'The life of a job title is unpredictable.',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: '6hnkfONLKPyuEdoi8l3L',
    label: 'How You "Do" Self-Care',
    text:
      "Ahhhhh… It's day of the week night, which means no kids, no work, and no interruptions during date night with my husband.",
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'Cc1jksfYGG1C2GxhOETX',
    label: "How You've Bloomed",
    text: 'Today, it has bloomed into a booked calendar.',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'HF3mWHz33KNc8CVcg0Op',
    label: 'Sharing Kind Words',
    text:
      "I can tell you 'til I'm blue in the face why I believe my business is really something great.",
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: '3B0JtLKK6phvbYw4U7yG',
    label: 'Your Favorite Books',
    text: 'What does your favorite book say about you?',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: '9OhXLibW7D2lXbiW8d22',
    label: 'Your Love/Hate for Decorating',
    text: 'When I first discovered HGTV, I was welcomed into the world of interior decorating.',
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: '5Jb4dZhTgIgmXACg2bEx',
    label: 'A Loving Cyber Shout-Out',
    text:
      'Do you have that "special someone" in your life that pushes you to heights you never thought you could reach?',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: '5SX9kC9X8mscviARLk4p',
    label: 'Introduction of Your Favorites',
    text:
      "I love the sound of favorite sound, and there's no better sight to see than favorite sight.",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'H6o48ozD9Y55tVYpxKa0',
    label: 'Your Positivity Mantra',
    text: 'Share yours in the comments below!',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: '5UeOfjP2BfdZbkRh7K8H',
    label: '3 Things You Love About Your Job',
    text: 'What would your three things be?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'Ge8sDYYFMDNREejqDMb9',
    label: 'Highlighting Something Peachy',
    text: "I'm all ears!",
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'BzuPJ11I1nmNFuz1pvXO',
    label: 'Surprising Job Necessity',
    text: 'What do you do and what surprising necessity do you need to do it?',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'EiuNkZK4hkhGW3Tjik6a',
    label: 'Client/Customer Needs',
    text: 'What sets me apart?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'rcynKl9oF4c50Sr5XRdi',
    label: 'Caption 1',
    text: 'Save your seat via the link in my/our bio!',
    type: 'call-to-action',
    category: 'holiday'
  },
  {
    _id: 'TFMsjCxbr9LgSeognU5y',
    label: 'What You Do',
    text: 'Shoot me a message or comment below!',
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'Tiw5JV68w34Nc6YwIBJI',
    label: 'The Best Advice',
    text:
      "OK pals… answer A, B, or C, and then tell me some of the best advice you've got in your arsenal.",
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'ABIeOHB5pgiKKhkO8Vk6',
    label: 'How to Nourish Your Dreams',
    text: 'How do you nourish your dreams?',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'GgydERtzSonzmpUSY587',
    label: 'Advice to Your Past Self',
    text: 'Comment below!',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'VSTRsiC76rhY4Vgi6cZz',
    label: 'Turning Dreams Into Reality',
    text: 'Remember this: You once dreamed of being where you are right now.',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'YmpmC1ChvEtuWu1aPn1L',
    label: 'Your Messy BTS',
    text: "It's okay to be a little messy behind the scenes.",
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'H7uuj9HaIDkUJ7W0n95S',
    label: 'Keeping Track of Ideas',
    text:
      "To keep track of them, I have a document in the Notes app on my phone that is always within arm's reach.",
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: '61I4GQqXBfgOu3OU3vgP',
    label: 'Giving & Receiving Gifts',
    text: 'Your turn, friend!',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: '2qsaB9qFA6pQLC8zz5Fq',
    label: 'Your Most Productive Hour',
    text: 'Productivity… how do you find it?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: '1ODGdzciNMev9Oc2uLQS',
    label: "Who's Your Hero?",
    text: 'We could all use a little extra inspiration today!',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: '4ycurckNW7nTdeiGsprb',
    label: '4 Things You Love',
    text: 'Now, tell me something YOU love!',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: '6elM7aITQF7M6BgUGfto',
    label: 'Your Favorite Thing About What You Do',
    text:
      'I absolutely love what I do, and I could go on for days about the amazing friendships I forged with many of my brides.',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: '5oWies1CjsWdQYiwU8p6',
    label: "Where You're Dreaming of Traveling To",
    text: "(Please don't say the yellow brick road!",
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: '6Rou17uXI2uaJlHTRpgs',
    label: 'Seasonal Favorites',
    text: 'What do you love most about current or upcoming season?',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: '2KgLUUcnHO7Uk3XXW1hF',
    label: 'Getting Rid of Frustration',
    text: 'How do you get rid of your frustrations?',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: '1TtpetrDUNUxcLgILQoq',
    label: 'Cyber Shout Out',
    text: "I've never been the type to send thank you cards, but person's name deserves it.",
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: '1ahHkexcCh52r9qDYQTU',
    label: "What You're Thankful For on A Bad Day",
    text: "I'm so thankful that I've been able to turn my dreams into reality!",
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: '143bXg5tm0xgUgcfiErE',
    label: 'What Followers See from You',
    text: 'Hi there!',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: '5fr0suMm2O3rYrmfgvei',
    label: "Something You're Insecure About",
    text: 'Regardless of what it is, know this friend: you are enough!',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: '5h7FAvFZru9cSD5LUhB2',
    label: 'Sharing 3 Tips',
    text: 'Anything I could help answer for you?',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: '5YeYYndM3cvJjpizCJVD',
    label: "What It's Like to Work With You",
    text: 'First, we start with a free consultation.',
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: '6qeq70L0etYPTQPV3kp6',
    label: 'A Sweet Fact',
    text: 'I told you it was sweet!',
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: '0cBcleyL3YMm7ADoDUiv',
    label: 'A "Drinking" Game',
    text: 'Your turn!',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: '2T1Uy6164i6LLTEzossG',
    label: "A Challenge You're Grateful For",
    text: 'No rain = No flowers.',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: '188bM3dWk0u7VKDqPm1i',
    label: 'Who Inspires You',
    text: "Today, I'm raising a glass to all the fellow 9-to-5ers out there.",
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: '1Pkn7js1olrVJD0asF8i',
    label: 'Your Golden Hour',
    text:
      "I guess you could say that's my golden hour of productivity… as long as I have a little espresso, of course!",
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: '4FwhxIGZel9J4vhVJTK4',
    label: 'Fresh Air',
    text:
      "Drop some emojis in the comments that describe your favorite outdoor activity and let's see if I can guess what it is!",
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: '6CpvcPqD0bmnwrpozpnd',
    label: 'The Path You Chose',
    text: 'Did you take the road less traveled?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: '1lBaZcXg8hcRgFxvJvTG',
    label: 'Announcing A Sale/Discount',
    text: "Let's talk about money, honey!",
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: '293lpSoalLbPncOvcPkH',
    label: 'Going Live: Time Input',
    text: 'Calling all social media scrollers!',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: '4h32k1pBYBCRqb3epks0',
    label: 'Surprising Job Necessity ',
    text: 'Do you like surprises?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: '1ui9ILbvn2T6iYBjvj3o',
    label: 'A Relatable Insecurity',
    text:
      'Although I struggle with this, I am working every day to turn things around by reminding myself that making mistakes is part of being human.',
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: '3xbWory9ZZYTj6Z8ETQC',
    label: "What's Masked in Your Business",
    text: 'I guess that means I keep the difficult parts of my business covered up pretty well!',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: '0yiT3ZfvJIW6PEjpgEOo',
    label: 'The "Little Things"',
    text: 'True or false: Life is all about the "little things".',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: '46w9u1ZXMXmOn7jPmnPh',
    label: 'Your Biggest Goal',
    text: "What's one BIG goal you have?",
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: '5VX24PEE2vHnIZrmmiiq',
    label: 'Encouraging Advice',
    text: 'These are the words that keep me going!',
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: '3zm5FvkYwrvq7pynyKKM',
    label: "What I've Worked Hard For",
    text: 'Where are my fellow hustlers?',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: '10TrZrYfboglvtGbRxbL',
    label: 'Your Morning Routine',
    text:
      'It makes sense for me because I work best with clear structure, and making my own rules is a huge perk of what I do.',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: '6DOeX4a5T9guDr4J5R2Y',
    label: 'The Best Part of This Month',
    text:
      'Sure, I may not be curing cancer, but what my agency does for small business owners makes an impact.',
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: '3XRuRzHybZsYgVubl6U6',
    label: 'Your Sweet Tooth',
    text: ', or C.',
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: '26G7EtSY5LeNNrsBW4Ld',
    label: 'Color Outside The Lines',
    text: 'Do you tend to stick to the status quo or embrace being different from the rest?',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: '3k6q4i7QdkjrBMMrftVl',
    label: 'Helpful Tips',
    text: "I'm really excited to share this inside look, so check it out!",
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: '4C8CRneCr6oe7sOr9MYg',
    label: 'Time to Celebrate',
    text: 'Today marks my 10-year wedding anniversary!',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: '4HKmrw3w93nLFDcngWcV',
    label: 'Your Favorite Reads',
    text:
      "I have a confession to make… I love a good romance novel, and I would ONLY admit that here because you won't tell anyone, right?",
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: '4baISk9yhMke5sAPVV2X',
    label: 'How You Help Your Customers',
    text: "Then, you'll be your solution in no time!",
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: '2jmC9IKRvnvNEc9cJgPH',
    label: 'A Change of Scenery',
    text: "In the meantime, send me a DM, and I'll get back to you when I return!",
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: '6R9JYftfWHi41vqnMcOr',
    label: 'Plans for the Year',
    text: "I couldn't be more excited for what my business will become over the next year!",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: '1G9jwV2BijxqM1luitCF',
    label: 'What Success Looks Like to You',
    text: 'When will you know you have MADE IT?',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: '1VuKf0sJQAH3b8XHTjdf',
    label: 'Your Crowd Pleasing Product/Service',
    text: 'Check out my website!',
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: '1OkVay967jbUlwzmcGIk',
    label: 'Your Mission',
    text: "What's my mission for my business?",
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: '1OFFMCpTUxQKAvWIWjqF',
    label: 'Being Your Best',
    text: "I'm a job title who absolutely loves a favorite feature of what you do.",
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: '6YQ5o3ezhyYPf1fLX8uj',
    label: 'Reaching Your Dreams',
    text: "It wasn't easy, but I'm happy to give this dream everything I have.",
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: '14AxrsJhSkjxV9DJ6J0V',
    label: 'Sharing a Helpful Tip',
    text: 'How is your day of the week so far?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: '6hro3eRM4Jnh8BTViQXn',
    label: 'How You Motivate Yourself',
    text: 'For me, making my bed definitely starts my day off right.',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: '1urYYe8i4vDS4Rg0MgaG',
    label: 'Promotion Ending',
    text: 'Time is running out.',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: '6jNSKzNT76qEjhA28pdh',
    label: "How You've Honed Your Craft",
    text:
      "In order to develop this business I'm head over heels about, I took a lot of necessary steps to really hone my craft.",
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: '5Rm5peyFRRxGd21gJ4TL',
    label: 'A Drink That Helps You Unwind',
    text: 'Do you have a favorite drink to help you unwind?',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: '4qc5cmi0jgeJQPEpgLFT',
    label: "Today's To-Do List",
    text: 'Can I show you a peek of my business?',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: '1zZBXPFDnZH1ivC0qE4u',
    label: 'An Uncomfortable Confession',
    text: 'This is hard for me to admit, but here goes nothing!',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: '10WavAKC9SYgbdV40iiD',
    label: 'A Conversation Heart About Your Business',
    text: 'Want to learn more about what I do?',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: '6TZBZtduCR95IzfuV783',
    label: 'How You Fill Your Cup',
    text: 'Tell me, what are some ways you fill your cup throughout the day?',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: '2OxoY8lmBcMDTiyuRzge',
    label: 'Your Happy Place',
    text: "So, friend, where's yours?",
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: '1iMoFobH8F9X27OWEi7n',
    label: 'A Sweet Benefit',
    text: "You know what's sweeter than eating a warm cinnamon bun on a crisp, fall day?",
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: '1xTIZXhgmNI7pKZaUtvq',
    label: 'Finding Reasons To Celebrate',
    text:
      'Do you sometimes forget to make time to acknowledge an accomplishment before moving on to pursue the next?',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: '5PBVmY8S2zyuPxSRW9Z0',
    label: "How You're Always Ready to Serve",
    text:
      "At your business name, we're always open for suggestions to how we can make your client experience better.",
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: '5m1QhjLMC9OGKYMmM9fF',
    label: "What You're Drinking This Weekend",
    text: 'Better yet, what are we drinking?',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: '1GSoYUlspXD6gmxGVmKc',
    label: 'Sharing Your Struggles',
    text: 'Comment below if you agree!',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: '4fnAi1BvpZ0qkgXyoREU',
    label: 'How You Discovered Your Passion',
    text: 'Comment below if I can help YOU next!',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: '0eMEfibjk5uxrcRoUrlC',
    label: 'Upcoming Vacation',
    text: "I'd love to hear your recommendations!",
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: '6ks3c8sSXSrWfyQIlTKR',
    label: 'Weekend Plans',
    text: "I have plans to list your weekend plans and I'm excited to get outdoors!",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: '18Z3FyVXZiJGfjaSRIVV',
    label: 'Introducing the Team',
    text: 'Now tell us about YOU in the comments below!',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: '6soVO8oUCdsiFyXrlOCP',
    label: 'A Day for Relaxing',
    text: 'Does it get any better?',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: '6SlNI6tz5Puq859wRddE',
    label: 'What You Love About Social Media',
    text: 'There is SO much I love about social media.',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: '7MNQIcog12qdsmyer2Az',
    label: 'Introducing Something New',
    text: "It's been a labor of love and I can't wait to hear what you think!",
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: '32JHN9ZAiqeMPHNi1fyR',
    label: 'A Sneak Peek Into Your To-Do List',
    text: 'What are you up to today?',
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: '1mBZvQ4y91S2q02oAgUc',
    label: 'When You Were 13',
    text: "I'm name, a profession from city.",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: '2YqDebuKHZd6cnAV0XGb',
    label: 'Announcing Something New',
    text: "I can't wait to hear what you think!",
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: '1np7ueU32QhaiFbAz2gX',
    label: 'Caption 1',
    text: 'He/she recently told me/us insert testimonial here.',
    type: 'insight',
    category: 'holiday'
  },
  {
    _id: '0qdjbMKoHh1v1BEuBGK8',
    label: "The Path You're On",
    text:
      "What was the turning point in your life that sent you down a different path—or skyrocketed you into one you'd already chosen?",
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: '1TaMQWQFvhGnCKbArYK7',
    label: 'Lifting Your Spirits',
    text:
      'The vibrant colors and fresh smells really lift my spirits, especially during the cold winter months that seem to draaaag on.',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: '5yYrNK4PBoxxOeNoaer1',
    label: 'Features That Set You Apart',
    text: "A lot of people don't know this.",
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: '7pkrzxc9W3qIbw7kJbFI',
    label: "3 Words for How You're Feeling",
    text: 'Using only three words, describe how you feel at this very moment.',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: '6HeETkW1tY9zGfGkaCLi',
    label: 'Dream a Little Bigger',
    text: '" That\'s a good reminder the day before I meet with investors!',
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: '0ow7jHugC1WphMHB5YOi',
    label: 'Remembering Your High School Prom',
    text: 'What do you remember about your high school prom?',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: '54vj9VGMlg7cj5pLb4PW',
    label: 'Peace Amongst Chaos',
    text: 'How do you create room for peace during times of chaos?',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: '3l6VpDniu93YqpbAqR4D',
    label: 'Tackling the Week',
    text:
      "I'm so pumped to take on this week, sailing through spreadsheets and being the best darned job title I can be!",
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: '2A3jsQwRu1Tg5NM1M29X',
    label: 'A Random Act of Kindness',
    text: "Agree or disagree: It's cool to be kind!",
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: '6ivuBzeKdNzcduo2lKFL',
    label: 'How Your Business Is Growing',
    text: "Time flies, doesn't it?",
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: '12vqa93vZTcaZWkcVZdO',
    label: 'Saving Time for Your Clients/Customers',
    text: "Comment below if you'd like to learn more!",
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: '3BLKRAtIjfhCU5FPEd5K',
    label: 'Your Weekend Plans',
    text: "It's bound to be a great one!",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: '0dtTdwJ6NBODHfR6Trjw',
    label: 'A BIG Deal In Your Business',
    text:
      "This post is a pretty BIG deal… because I'm announcing everything in the shop is 10% off this weekend ONLY!",
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: '3EU72Ys7acWc00vv4te6',
    label: "What You're Looking Forward To",
    text: 'What are you looking forward to this month?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: '77HyqfC2i0VYNSFqkT0w',
    label: 'What You Love About Where You Live',
    text: 'I live in the heart of downtown, and I love working in the city every day.',
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: '2Xd9nWYy6oqdFI7iFVL6',
    label: 'Celebrating a Big Win',
    text:
      "As we close out current or upcoming season and head into a busy season, I'm reflecting on my wins over the past few months and celebrating a large contract with a new commercial client.",
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: '8BkmmpfBr0I8mC9I2dcm',
    label: "Question You'd Ask Your Older Self",
    text: 'If you were to sit across from YOU 5 years from now, what would you want to know?',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: '2XJs7oyXXcqoi601REUF',
    label: 'Doing What You Love',
    text: 'How did I get my start?',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: '1vfZvpiiIJqOJFk38jUi',
    label: 'Sharing A New Piece of Content',
    text: 'com to  read the full story!',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: '4MTbb6r0I7AvbfVzVuuD',
    label: 'How Your Product/Service Appeals to Your Niche',
    text: "I bet you're one of the busiest people you know!",
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: '4CnETnz67y5ikXDDwnZZ',
    label: 'Sale Ending',
    text: 'Hurry!',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: '6gtT9AT4hyyBanySTFOi',
    label: 'Time Flies',
    text: 'Time flies, am I right?',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: '84M7Zq7pG9o3XIQWEvb1',
    label: "Something You're Insecure About",
    text: 'Regardless of what it is, know this friend: you are enough!',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: '3oOjLjVykZ90XRuR7d27',
    label: 'How You Welcome the Upcoming Season',
    text:
      "When we're on the brink of upcoming season, I love to welcome the season by how you welcome the upcoming season.",
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: '6Sgv82VdtGmptQZbHioX',
    label: 'Explaining Your Product/Service to New Followers',
    text: 'Hi, friends… both old and new!',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: '0G62JkMBp73t8PiSFZUU',
    label: 'Sharing Secrets',
    text: "Now it's your turn to share your secrets in the comments below!",
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: '4GB7R3GCH2prOcYDOLOl',
    label: 'Unwinding After Long Days',
    text: 'How do you wash off the stress of a challenging day?',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: '4IzWcUbPrh7Ayu6wUXGh',
    label: 'Sharing A Client/Customer Review',
    text: 'This just made my day!',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: '4lxkBUY5CJACbP8PCtTs',
    label: "What Your Followers Do/Don't Know About You",
    text: 'Are you an OG follower of my account, or are you just tuning in?',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: '4ujNrmcbHdNzXnZ2feAk',
    label: 'A Secret Weapon In Your Business',
    text: 'Spill!',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: '3PgFf9sxEMb8IpM1EQIU',
    label: 'Your Dream Vacation',
    text: 'Ahhhhh, if only "summer" was synonymous with "vacation" like when we were kids, right?',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: '1lRYomwDx0I5bWsHUXtg',
    label: "You're Stronger Than You Think",
    text:
      'If the umpire was counting curveballs, how many would he say has been thrown your way this season?',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: '5EqB8nqcs8R8kAHVj0Mn',
    label: 'Weekend Plans + Business Update',
    text: 'What are you planning for the weekend?',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: '0Gly61uf87DCb7qlEHfW',
    label: 'Sharing a Testimonial About Something Exciting',
    text:
      "*blows noise maker* I'm doing this because reason behind big announcement, and it is my hope that you'll have the same experience as name client, who said THIS about topic of what you're announcing: Insert client.",
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: '1ETip7h8GQucv1LP5HR3',
    label: 'New Piece of Content',
    text: 'ANNOUNCEMENT!',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: '1hh9CxtyzupBRBa2mLW6',
    label: '48-Hour Reminder',
    text:
      'This is your chance to *finally* order clothes delivered to your doorstep that are cute AND functional, and friend, I want you to experience all the joy that comes with that!',
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: '1JvRomNQ515Rr4GnSgz9',
    label: 'Best Part About What You Do',
    text: "What's the single best part of what you do?",
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: '0FWE4qnzEEICSY1Lzzfc',
    label: 'How You Help Your Clients/Customers Relax',
    text: "With my writing services, I'll do the heavy lifting for you.",
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: '00CHPpfCQPdNHc25eNln',
    label: 'What Keeps You Afloat',
    text:
      "Hi, I'm name, a profession from city, and I want to share a little about me by way of shouting out a few of the folks who helped make me who I am.",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: '1O8xHhBU4T8VzNtmdVEV',
    label: 'Creating Boundaries to Thrive',
    text: "What do you need to say 'no' to, to thrive even more?",
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: '01RKW8rJWkMKsPEkDIN2',
    label: 'Encouraging Advice',
    text: 'These are the words that keep me going!',
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: '1WH23oXbcD3xxc6kq1KH',
    label: 'Raise a Glass to Your Win of the Week',
    text: 'Happy Friday, friends!',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: '0BPd3vSe79r4YoayGHjR',
    label: 'A Great Client Review',
    text: 'This client review that burst my heart into a million rainbows.',
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: '13mTOPRQRcifmJ8Q6vtA',
    label: "What's Next on Your Calendar",
    text: 'Honestly, it feels bigger than my team making the playoffs!',
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: '16AOd1wEVrJU5KG3zeXb',
    label: "What's Inspiring You Today",
    text: "This might sound silly… Today, I'm inspired by my children's laughter.",
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: '1bYMAez4QWAOJWqoIqRH',
    label: 'Inspiration Behind Your Product/Service',
    text:
      "It's my goal to serve you as best as I can, so please let me know if there's anything I can help you with!",
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: '2Zrgue2L3Crlv319yAYo',
    label: 'Seasons in Business',
    text:
      "The big push, followed by a slow season was one of the MANY reasons why I started this business, and I'm so grateful to be experiencing it!",
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: '2CQ8CRFI6Bs4bkmrQK6f',
    label: 'Christmas (December 25th)',
    text:
      "Looking back, I vividly remember how the excitement completely overcame me as I unwrapped each gift, and I'll never forget the look on my parents' faces while my brother and I played with our shiny new toys.",
    type: 'insight',
    category: 'holiday'
  },
  {
    _id: '2dZ2FulHNEh3IuGsZFFo',
    label: 'What Makes Your Business Unique',
    text: 'I have just the answer!',
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: '2ewBk1QGYGGaLg32ZZBe',
    label: 'Favorite Quote',
    text: 'My favorite quote from Little Women is "Conceit spoils the finest genius.',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: '37CseFLEh68p8eGZbUZE',
    label: 'Would You Rather Game',
    text: 'Let\'s play a game of "Would You Rather".',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: '1ou7yQvJ2QGtZqzPACt2',
    label: 'No Place Like Home',
    text: 'To a visitor, city may seem hectic.',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: '3TKbZXTWJu8D6JZBHDKw',
    label: 'What Helps You Grow',
    text: 'I love to tend to my garden.',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: '2AQGykkevGVrmX1B8o7w',
    label: 'A Bad Day Reminder',
    text:
      'But no matter the stress, the disappointment, or the humiliation, one reminder always makes me feel better: "" This too shall pass.',
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: '2Q3iiuDoMO9rDW9vcpBc',
    label: 'Surprising Job Necessity',
    text:
      'What might surprise you is how necessary list unexpected job necessity—app, group chat platform, organizational tool, special snack or drink, etc.',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: '3LEoXcol3tfB4buF0zA5',
    label: 'Vacation Recommendations',
    text:
      'If I were (theoretically) hoping to take a trip this summer, (typically) obsessed with tropical drinks and lazy days reading on the beach and (hopefully) able to keep it pretty budget-friendly, where should I go?',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: '3VuQ25Tvq1AjORao2Cj5',
    label: 'Permission to Rest',
    text: "Who's with me?",
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: '2ytPQduxvCXAGihAg2kP',
    label: 'Welcome to My Page',
    text: 'Hi there!',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: '2MFlcqHIYk5mAkm2BBoi',
    label: 'What Inspires You to Create',
    text: "What's inspiring you these days?",
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: '32IFAAqTrJ8dvOtPySzf',
    label: 'Most Frequently Used Emojis',
    text: 'drop your favorite emoji in the comments below!',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: '3F0H7rCQqP1eBOIw5sY3',
    label: "What You'd Take to a Deserted Island",
    text: 'What would you take to a deserted island?',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: '1txLHaUrMHJZV7PNQDFe',
    label: 'The Best Part of Your Product/Service',
    text: 'Head to my website!',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: '2nV3E0RQIyhqSA5qHcKS',
    label: 'Finding Inspiration',
    text: 'I am FIRED UP.',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: '3IKLNBzv3YijSdICj2hP',
    label: "What's On The Agenda Today",
    text: "It's all about how to wear stripes AND polka dots this summer!",
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: '2spvuY1WUnHjSDydRZP0',
    label: 'Encouraging Advice',
    text:
      'Whenever I\'m having a bad day in my business and consider throwing in the towel, I try to remember this advice my grandmother once told me: "" "When there\'s rain, sunshine is always right around the corner".',
    type: 'hook',
    category: 'holiday'
  },
  {
    _id: '3TS8F3WQTrskmOdpb9Bg',
    label: 'Sharing An Announcement',
    text: 'Last month, I branched out into doing more teaching assignments, and I had so much fun!',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: '5ILBnCvk4IrQlPQzq6S6',
    label: 'What Sparked Your Business Idea',
    text:
      "It's those two words that turned a spark into a fire that continues blazing to this day, 10 years later.",
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: '3p1wgZV4YVmgNdncwAm5',
    label: "New Year's Resolutions",
    text: "Do you have any New Year's Resolutions this year?",
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: '3oXf4lfayRUadKrwgJjn',
    label: 'Your Happy Place',
    text: 'Tell me about your happy place!',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: '5VbcGY31NXlGfNEhwIXb',
    label: 'Holiday Preparation',
    text: 'What holiday surprises would you like to see?',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: '48fzQh33d4S9RoAXmdiY',
    label: 'Your Biggest Goal',
    text: 'My biggest goal?',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: '5anchO4nM8SBJI3O2pJx',
    label: "How You'd Describe Yourself",
    text: ', , and 3 adjectives that people close to you would describe you as.',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: '4p9BZELgzYiVN8YvFnTf',
    label: "Big Project You're Working On",
    text: 'I can hardly WAIT to share with you the NEXT!',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: '3nSdpcfCXrG7nOpbIqLt',
    label: 'Welcome To Your Page',
    text: 'Hey there!',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: '3kKTZcSm45OnLC0yIGVT',
    label: 'Your Wild West Beginnings',
    text: 'That easily could have been my mantra when I started your business.',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: '06R1iHxATyaFX1uDOS84',
    label: "What's On The Agenda Today",
    text:
      'Head to the link in my bio to check it out and come back here to let me know what you think.',
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: '0BelntuoJAwoRf2qr0PC',
    label: 'Sharing a Smile',
    text: 'I once read, "A smile is a curve that sets everything straight.',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: '0NnrhycVaiXGonWP6xrh',
    label: 'Did You Know…?',
    text: 'Did you know I offer complimentary creative consults?',
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: '2xr9CaoCAHfOpfmUzSa3',
    label: 'Favorite Food and Drink Game',
    text:
      "I'll go first, because even the THOUGHT of life without detailed description of your favorite food has me gasping for air.",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: '4QhcGEGrIjuRw2e3EzLt',
    label: "What's On The Horizon This Month",
    text: "What's on the horizon this month?",
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: '4RzgDfkGVYJm0YcD9TAc',
    label: 'Permission to Pause',
    text: 'How will you pause today?',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: '6W8O1a8jK6Etf0W42Y0t',
    label: 'Answering An FAQ',
    text: "They say you've gotta give the people what they want, right?",
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: '6jeeUj8rlpqruPbUCZ3a',
    label: 'Client/Customer Testimonial',
    text: 'What questions do you have?',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: '6kXbljiM68qdG8wi64QZ',
    label: 'Pulling Back the Curtain',
    text: "It's time to pull back the curtain.",
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: '7AOqdS4dP50P2iZF1HI1',
    label: 'Staying Grounded',
    text: 'Am I alone?',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: '7Ces8weiqqjaKDKRVZyM',
    label: 'Going Live Announcement',
    text:
      "Mark your calendar and drop your questions below… I'll see you at time when I answer them Live!",
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: '7KUBey9pgAdTXdtsdGgi',
    label: 'Favorite Seasonal Food',
    text: "What season food can't YOU get enough of?",
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: '7N4NRyYIq1rYRnGPiHBT',
    label: 'The Toughest Part of Your Story',
    text: "What's the toughest challenge you've ever faced?",
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: '7Ywg8CtTWWaEjQhiaAwr',
    label: "What You're Dreaming About",
    text: 'So, what are you dreaming?',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: '7etWST92hTvzCBEsTx7o',
    label: 'Pursuing Dreams',
    text: 'Is there something that stops you from really reaching for your wildest dreams?',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: '7scLepruUHjtof2aIkXw',
    label: 'Your Favorite Relaxing Beverage',
    text: "What's your go-to beverage when you want to relax?",
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: '7toednZ5HKoFYtvL4gdr',
    label: 'How You Relax After Working',
    text: 'Can we all agree not to work today, and just stop to smell the roses?',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: '8EYewB1t6MvCRm9Ok1c4',
    label: 'Moment of Gratitude',
    text:
      "Not for the things I have, although that's a plus, but for the person that has been my ride-or-die through it all.",
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: '8HpsXGzvzSjJLEOkkpKj',
    label: "Today's Journal Entry",
    text: "What's on your mind today?",
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: '8KuPq3ayex4DRaQJD5y7',
    label: 'Fun Facts About You',
    text: 'This time, the theme is current season!',
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: '8M7AMm4tuUYHqynhoZwq',
    label: 'A How-To Guide',
    text: 'A how-to guide on taking the perfect selfie.',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: '8hgJmKgHRLNx8b1zBZHG',
    label: 'Money Motivated',
    text: 'Are you money motivated?',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: '8hnkkNdgRTCha03xGefa',
    label: 'Cyber Shout-Out',
    text: "Tag 'em in the comments below!",
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: '8klaUH7U89gAIcg8Oq3y',
    label: 'Your Origin Story',
    text:
      'Take a seat and let me tell you a story… The story of how I started my photography business, that is!',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: '8oeqPuMhY2iEY6lNQgun',
    label: 'A Thought-Provoking Question',
    text:
      "Let's start this day of the week off with a deep thought to get our minds ready to seize the day, shall we?",
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: '8qwtZsDk5vKjnTvnFLb9',
    label: "What's Inspiring You",
    text: "What's been inspiring you recently?",
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: '8sEJ2mEPXdO8TZZyiDD9',
    label: 'The Little Things',
    text: "Double tap if it's the little things that keep you going, too!",
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: '8tMpbgfKW31xQBmfIYEm',
    label: 'Your Favorite Drink While Working',
    text:
      "If you've been following me for a while, you'll know that I'm unashamed to admit that I'm powered by strong coffee.",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: '8vCKRgCrNWym69w8xjne',
    label: 'Sharing A Testimonial',
    text: "I'm here to connect with you if you have any questions I can answer.",
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: '93clmESsupxloiK6c2YI',
    label: 'Best Part of Business',
    text: 'What about you?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: '94ndNjyrsatnii9JHabT',
    label: 'Where You Work',
    text: 'I love it because the quiet hum of the desk lamp in the dark space inspires me.',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: '97UE8ZzOtZhdGc6DqITs',
    label: 'How You Fight Self-Doubt',
    text: 'Truth or Lie: I am completely confident at all times, in every aspect of my business!',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: '98pOTZfqIWNtE2GYaIcG',
    label: 'Stronger Together',
    text: 'Are you facing a challenge today?',
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: '9ALR6qaUCWT3g3CbLsJl',
    label: 'Expressing Gratitude for What You Do',
    text:
      'Something about slow Saturday mornings always brings me to a thankful, reflective headspace.',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: '9ASTtladCIFDIjTJN3lz',
    label: 'How to Find Balance',
    text: 'May I make a confession?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: '9O2aztcsVZsOwPvSD0AZ',
    label: 'Popping Out a New Announcement',
    text: 'Grab the popcorn and click the link in my bio!',
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: '9TgUAMJBQYTwr07JbKRS',
    label: 'Your Biggest Goal Right Now',
    text: 'What are you striving toward this month?',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: '9V5uPcbxi22gP6hHovQ5',
    label: "Something You're Looking Forward to Next Month",
    text: 'How are you stepping into the new month, and what are your plans?',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: '9brE9LQWftCafQzw3CQE',
    label: "Two Goals You're Working Toward",
    text: "I'd also love to another goal you have, which seems kinda doable.",
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: '9fv1IipzWP970HBQaPvD',
    label: 'Letting Go Of Something',
    text:
      "I'm sure it will be an adjustment, but I'm looking forward to living a healthier lifestyle.",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: '9iC7KMtoQAoB6ImyzsWj',
    label: 'Your Secret Dream Job',
    text: 'Is your current job what you wanted to do when you were a kid?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: '9jK1G2wQ4vPZicxm0Tog',
    label: 'Who Motivates You',
    text: 'Tag them in the comments to let them know how much they mean to you.',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: '9nyp1oQJYIma4oCjrXB1',
    label: 'Your Favorite Dessert',
    text:
      "Most days, I'll enjoy a piece of sugar-free dark chocolate and that will be enough to get through the afternoon.",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'A8hGuDMxZFVci8RIJ9XF',
    label: 'Being on the "Nice" List',
    text:
      'Christmas is right around the corner, but I have to know: Have you been naughty or nice this year?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'ABdXwl9oCez8iiddIHwS',
    label: 'Holiday Promotion',
    text: 'In the meantime, head over to my website to check it out!',
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'AE3Hy3JD8u69g8XFqUPu',
    label: 'Your Secret Weapon',
    text: "I've got a secret.",
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'AFzOIHdS7t2eyLjuzSsg',
    label: 'A Client/Customer Testimonial',
    text:
      'These words mean so much, and it would be an honor if I could help you how you help your client, too!',
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'AGMRGuLl50l3X9FmeLMS',
    label: 'Not an Ad',
    text: 'Check out my website for more info (link in bio)!',
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'AJn0wagC7CtBDy84uVsN',
    label: 'Going Live Showcasing Early Adopters',
    text:
      'Are you ready to join some amazing people who already signed up to join the Business Bootcamp?',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'APLtihcTi2m0FtDFoQKd',
    label: 'What Makes You Different',
    text: 'Similarly, every job title does event planning differently, too!',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'AVYymAyvLEh18tGLNtfD',
    label: 'How Your Childhood Shaped You',
    text: 'I grew up in place and was a tomboy, and that really shaped me.',
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'AWr5UrFvrwpSlZ14AtB6',
    label: 'Coffee Game',
    text: 'Wanna play a game?',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'AX2Xf9TGOS26CUmx9RUp',
    label: 'The Greatest Gift Your Work Gives You',
    text:
      'Capturing a stolen kiss fills me to the brim with overwhelming gratitude for the love that exists in the world.',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'AbLoYGCQD4Wb1X71DbsB',
    label: 'Exciting Announcement',
    text: 'I love hearing from you!',
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'AbWWFaMETml4SbWTBr09',
    label: 'Feature a Client Rave Review',
    text: 'What can I do to get you feeling this way, too?',
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'Ak3tCWxoZKSdmuYXTvy8',
    label: 'Keep Moving',
    text: 'Double tap if this encouraged you today.',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'AlWVLFnEbBNVxlpmC1O4',
    label: 'Most Popular Product/Service',
    text:
      "Drop the fire emoji in the comments below if you're interested in learning more about product!",
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'AonHCp0sHRHyRxmhtt5p',
    label: 'Caption 2',
    text:
      'I would love to know more about what you need and learn how I can specifically help you get into your dream home.',
    type: 'insight',
    category: 'holiday'
  },
  {
    _id: 'ApPfINlgcMMYPv7daC73',
    label: 'Getting Up & Motivated',
    text: 'What makes you get out of bed in the morning?',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'As3UZ14DBFooNcHwserv',
    label: 'Your Favorite Gift',
    text: 'What is the best gift you ever received?',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'AuwCEmoARW1DkMlFlfSD',
    label: 'Thanking Your Audience',
    text: 'You might think I do a lot of thanking.',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'Az0ZMXdP6c70S9mKb9D7',
    label: '3 Things Motivating You',
    text: "No matter the reason, share what's keeping you motivated in the comments below.",
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'AzQVBmGwDK7FfNcRDYEL',
    label: 'Gratitude Check',
    text: 'Who are you grateful for?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'AzlX4QGTRzP1Pcm8NXuC',
    label: 'How Your Friends Describe You',
    text: 'My most embarrassing moment as a kid was when I your most embarrassing moment as a kid.',
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'BEjZoVKUMhHqSNf2ZLIs',
    label: "Valentine's Day (February 14th)",
    text: "On this day all about love, I'm just brimming with heart eyes for you!",
    type: 'hook',
    category: 'holiday'
  },
  {
    _id: 'BI48zfv3Azb9Wq0UwlBY',
    label: "Life's Simple Pleasures",
    text: "What's one of life's simple pleasures that you love?",
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'BIHqgQWjprLU7NuyQunr',
    label: 'Work Perks',
    text: "Life is good, don't you agree?",
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'BWaKDuBPTB2tji8HSKVZ',
    label: 'Sharing A Trick of Your Trade',
    text: 'Sharing is caring, right?',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'BZIXoGPTRYw42aC6hBti',
    label: 'Your Dream Style',
    text: "Tell me how you're dreaming today!",
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'BclqMk1ETYHtlLGXgLGQ',
    label: 'Sharing a Client/Customer Testimonial',
    text: 'Click the link in my bio!',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'Be7TyhtbuZUTrNM9YzM1',
    label: "Who You're Grateful For",
    text: 'First of all, I want to thank person 1 for reason.',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'BlR0G46dZOGOWrmkIn9s',
    label: 'What You Do in Your Free Time',
    text:
      "I'd be honored to connect with you, so drop a comment letting me know what you like to do with your free time!",
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'BnUGESFOrJkWgS5wutPV',
    label: 'Releasing Something New',
    text:
      "It's jam-packed with , , and actionable templates you can use right away to grow your business.",
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'BtNGyrS3LyqoIr37pBmy',
    label: 'How You Embrace the New Season',
    text: 'How do you embrace the new season?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'BxKbxPHLdeEjiJ6gdPAY',
    label: 'Your Perfect Pizza',
    text: "Even when a slice of pizza is kinda bad, it's still pretty good, right?",
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'ByhnsWc0SiyWWplVyJ8a',
    label: 'Be Thankful, Be Happy',
    text: 'What are you thankful for today?',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'CBt1splkvR5FMTJzgHjA',
    label: 'Helpful Tips',
    text: 'What questions do you have for me?',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'CEFKWPSIFmK56WDuHvtE',
    label: 'Answering A Question',
    text:
      'Recently, I was talking with @clientname about my business, and they asked frequently asked question.',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'CEr9bwJPh8O9G3gpu2ra',
    label: 'Back to Basics',
    text: 'Friends (new and old), tell me about yourself in the comments!',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'CGmv2BuKLmiHI0uItobY',
    label: 'Your Weekend Plans',
    text: 'How are your weekend plans shaping up?',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'CIgjHKNedk6cZuibPzNI',
    label: 'Who Keeps You Accountable',
    text: "We all need that person in our life who'll push us and keep us accountable.",
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'CJk9nZpRt93iX6T49i4T',
    label: 'What Makes You Special',
    text: 'How can I make your event special?',
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'CKc9RXsRNh82LVBsQ0PI',
    label: 'Out of Office',
    text: "For now, tell me what you're up to today in the comments below!",
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'COIJWTsFAuOfYmfglwLf',
    label: 'Your BIG Plans',
    text:
      "While everyone around seems to be gearing up for the weekend, I'm over here already pumped for next week!",
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'CPSEze1pKjG2hYJ5eMwf',
    label: "A Big Goal You're Dreaming About",
    text: "I'm drawing up for my business this year, including a big goal.",
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'CSEhtIUluIE59lJTFcEM',
    label: "What You're Good At",
    text: "But what I'm really good at is helping you find where you belong.",
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'CSTgAa1EuTYQQduuDRjG',
    label: 'Seasonal Traditions and Routines',
    text: "Get this: we're number days into year!",
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'CgR9V14hMiCpwfz6Firc',
    label: 'The Best Day of the Week',
    text: 'Can you help me?',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'CiSDfQwPexHqw6sGVzUt',
    label: 'Person You Admire',
    text: 'Case in point, say a little about how your person encourages.',
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'ClTcaT4BBf98tJ02uAtS',
    label: 'Announcing an Upcoming Live',
    text: "Drop your questions below, and I'll see you on date and time of your Live!",
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'CmJMWp4eB4dwo0psqBlN',
    label: 'Thanking Your Followers',
    text: "I couldn't do this without you!",
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'CtN5tgFMUK8AMubY8TC0',
    label: 'My Ideal Workspace',
    text: "What's your ideal workspace?",
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'Cu6D1suUipSpGovOzKBL',
    label: 'A Great Idea You Have',
    text: 'I have a great idea!',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'Cxw4uTkYmCvxRlkf4vqI',
    label: 'Highlighting Pressure Point',
    text: "I know it can be overwhelming, so that's why I'd like to help.",
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'D2n9j7FjSDSCdiKWeRUn',
    label: 'A Simple Childhood Joy',
    text: "Whether it's old typewriters or Beanie Babies, put your answer below!",
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'D32vxv1zRiSQUVrFQESn',
    label: 'Weekend Plans',
    text: 'What are your weekend plans?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'D9nDXMRNYu1lI7b56mRe',
    label: "Something You're Proud Of",
    text:
      'What I never expected was to see someone turn their practice into a daily growth blog of their own, inspiring others to do the same.',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'DAiALLfmgcFcYkvdwrNA',
    label: 'How You Juggle Everything',
    text: 'And you, friend?',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'DDJy2SLAXLGw7pgi6XtU',
    label: 'Your Redemption Story',
    text:
      "Today, I am confident, and I'm proud of how far I've come, especially in my business, where I what you do for you clients.",
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'DDcPQEY0iLfMPvKdYRHp',
    label: "How You're Worth The Money",
    text: 'How hard do you work for your money?',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'DIg2VuVKbayIa7vMmmpO',
    label: 'Thanking Someone',
    text: 'Never?',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'DKfN5zNTKSPcDkgX9CPi',
    label: 'Huge News',
    text: "I'm waiting!",
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'DN5jRQHKBqC3pg4vsFv2',
    label: 'Your Morning Routine',
    text: 'Want a sneak peek into my morning routine?',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'DPvbp5VKcdR0e3sKOjVa',
    label: 'Expiration of Promotion',
    text: 'Drop an emoji below if you were one of the many people that got in on your promotion!',
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'DUgulYuAdT5YYTUwm39z',
    label: 'Best Friend Shout Out',
    text: 'Today is special.',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'DWlWRuf8gf1lmcDxEqf0',
    label: 'A Man to Celebrate',
    text:
      'I know not everyone is so lucky as to have a husband who teaches them what selflessness looks like, but I am so very thankful that I do!',
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'DZOLO4QcCwRZ5ssJVjBR',
    label: 'How You Find Quiet and Gratitude',
    text:
      ", I'm finding it more crucial than ever to get quiet and remind myself why I do what I do.",
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'Db0PSxZesZ1YM82rBK01',
    label: 'Your Typical Work Look',
    text: 'Do you have a similar look or something vastly different?',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'DcmXPtyX0Ke6fSCGHwxd',
    label: 'Best Thing That Happened This Week',
    text: 'Hey there!',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'DdVzSCBr6QVp04hV94By',
    label: 'When Dreams Become Reality',
    text: 'Sometimes, I just sit and think, "Is this really my life?',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'Dhs100GL88x6KpHNEHPF',
    label: 'Your Secret Weapon',
    text: "I've been styling my friends for events ever since our freshman year homecoming dance.",
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'DhuknXCgHaF1AsRyS0o1',
    label: 'Promotion Coming to An End',
    text: "What do you want to know before it's too late to snag this deal?",
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'DjqROfiE5ZasDDIyMg1c',
    label: 'DIY Tutorial',
    text: "Who doesn't love a do-it-yourself tutorial?",
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'DxpNqcspsTfX26G2RpwU',
    label: 'Introducing the Team',
    text: "It's our differences that make this company successful.",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'DymZU943T9mZtpqiSHsY',
    label: 'Resolutions Check-In',
    text: 'What do you want to do less often?',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'E2JDaJQd269bOONkk9BA',
    label: 'Reminder of Upcoming Live',
    text:
      'Join me LIVE on date, time, and format of your Live as I answer all of your questions, and added benefit to grab my free health-conscious shopping list, too!',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'E2Y2zmiVq19eXXw5DLqB',
    label: 'Songs You Love',
    text: "What's the song that best sums YOU up?",
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'E71PShF8PKtW6vTPJ4vp',
    label: 'Exciting Announcement',
    text: 'Who needs some excitement in their lives?',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'EAMfmqe6gOuloFOsIUEl',
    label: 'An Excuse to Celebrate',
    text: 'Looking for an excuse to call it a day early?',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'EEX4y0J1TSa9Qdr1Kg8E',
    label: 'How You Deal With Change',
    text:
      "Of course, there's plenty about our circumstances that can turn on a dime without our consent, but I want to know what makes YOU ready to bring about your own change.",
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'EEoouoOGvjP6Q6lqfdJ9',
    label: 'Who You Work For',
    text: 'Who do you work for?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'EJ0xMXcES6E0ysdhiF94',
    label: "What You'd Get Away From",
    text: 'If you could get away from doing one thing in your life, what would it be?',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'ENEP19D2EwZjrraKU8ke',
    label: 'Flipping the Script',
    text: 'I know you have a lot on your plate, so book a free consultation and get started today!',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'ERIKxj5ziMEgfgpVd4W8',
    label: 'Embarrassing Secret',
    text: 'Can I share an embarrassing secret?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'EZIXNK5EWSNcr48DZuyU',
    label: 'Chase Your Rainbow',
    text: 'Now, I current reality of how that dream has come true.',
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'Eogkl5asSeKWfH4SPDSv',
    label: 'Embracing Your Weirdness',
    text: 'Spill!',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'EsF3JZeWgqLZm3F8uX3y',
    label: 'How You Help Your Ideal Client',
    text:
      'I would love to know more about what you need and learn how I can specifically help you get into your dream home.',
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'EwoxhuMaXWC6hFRrSdRe',
    label: 'Added Bonus',
    text: 'Can I admit something?',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'Ez0cT2nO34zOv2Gib9eV',
    label: 'An Average Day in Your Life',
    text: 'I generally start my mornings with coffee and toast while reading The Times.',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'F855XxJbBOl4tCrsDBd9',
    label: 'Your Favorite Time of the Day',
    text: 'What are you up to today?',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'F87ZUT1trfZAx1dRPon8',
    label: 'Keep Pushing Forward',
    text: "True or false: There's no such thing as a stupid question.",
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'F8hFje1BYbaHRxm7H41M',
    label: 'Expectations vs. Reality',
    text: 'How are your expectations stacking up to reality lately?',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'FBc5GM1T4tOA9nL6OixW',
    label: 'Beyoncé-Inspired Announcement',
    text: "*Cues Beyoncé* \"I don't think you're ready for this podcast.",
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'FCChc9GmNLavLbRVvKMx',
    label: 'Overcoming Objections',
    text: "DM me your thoughts and I'll help you decide if this is right for you!",
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'FLF5K5JQIgjMXE84jM4I',
    label: 'Tackling Big Tasks',
    text: 'I tend to begin by your first step and avoid a major distraction at all costs!',
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'FR8213geB4yuud94DDNQ',
    label: 'Huge Announcement',
    text:
      "If you ever wanted to taste our heavenly cookies but you weren't a fan of shipping costs, then today is your lucky day!",
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'FVuycc74Gd6qJOzQQxyj',
    label: "Little Things You're Thankful For",
    text: "Quite often, when I mention something here that I'm thankful for, it's something major.",
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'FYed1g6O9B2E9bEHv0MH',
    label: 'Seeing Your Future',
    text:
      "I'm definitely not a fortune teller, but I've known what I wanted to do with my life from a very young age.",
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'FbluupccoUKw1iVC2E0q',
    label: "How You'll Celebrate the Weekend",
    text: 'What are you doing on this Friday evening, and does it involve tacos?',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'Fm2u7f3GP5u4t3dHoQT0',
    label: 'Your Feelings About Beer',
    text: "I'm an IPA guy through and through!",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'FvvnmTtGH9IReAVxH1uI',
    label: 'Vacation Recommendations',
    text: 'What do you recommend?',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'FwolBcIPP1KfFPqe1szL',
    label: 'Promotion Ending',
    text: "What do you want to know before it's too late to snag this deal?",
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'FyZ9Ufiq7Z2lULYneeNb',
    label: 'Why You Created Your Product/Service',
    text: 'Did you know this about me?',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'G1z0niwpfMZMM9qor5Ou',
    label: 'Childhood Memories',
    text: 'Ahhh, childhood memories.',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'G4t9G3wPCqGKseUIhnPK',
    label: 'A Look At Your Schedule',
    text: 'Do you find comfort in a familiar schedule or a rush from the brand new?',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'G6S6IEcgKeK8mO04CKdm',
    label: 'Favorite Technology',
    text: 'I know it may sound crazy, but I use it to personal use AND business use!',
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'GBKJHwhiTIGfrVfo4h3S',
    label: 'Keep Going on The Hard Days',
    text: 'Like this post if you vow to keep going, even on the hard days.',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'GEi5A4T43JJUmOrie9oU',
    label: 'Caption 1',
    text: 'Want to hear something embarrassing?',
    type: 'hook',
    category: 'holiday'
  },
  {
    _id: 'GFiaQpYpSqk8kXSDTia8',
    label: 'Client/Customer Testimonial',
    text: 'Who do you know who deserves a round of applause today?',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'GHJ1AsCRqlCfRdOJd3mk',
    label: '#RandomFact for the Day',
    text: 'Do tell!',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'GO3pniStW6KMxwi0TtwD',
    label: 'Your Love for Your Industry',
    text:
      "I mean, I spend more time what you do in your business than I do sleeping, so it's a good thing the feeling is mutual!",
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'GQGvdsUkeNgE7o5esN0Y',
    label: 'Sharing An Encouraging Message',
    text: 'Tag someone in the comments who could use some encouragement!',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'GQts8W35UhOvhhnTEDI2',
    label: 'What Your Coffee Says About You',
    text: 'How complicated is your coffee style?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'GS790jL5uimzqIxAHwHX',
    label: 'What You Love About Your Work',
    text:
      "I've been a musician for 3 months, and to this day, I love everything from long days spent in the practice room, to watching my piano students perform at their end-of-year recital.",
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'Gatqlw5JPUKFSTQ3fmfU',
    label: "A Mistake You'd Erase",
    text: 'Do you ever wish you could erase your mistakes and start over?',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'GdNCXsdBZBD9OCTbifFI',
    label: 'How Your Product/Service Can Help',
    text: 'HOLD UP!',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'GnjQie5uLqrRnjKRLBLH',
    label: 'What to Expect on Your Account',
    text:
      'Welcome to my corner of the internet, where the coffee is hot and my custom skincare regimens are cool!',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'GsEePno5NzZmrSrJ9kIH',
    label: 'Being Spontaneous',
    text:
      'Just yesterday, I was lounging on the back porch with a good book when I suddenly felt inspired to start writing my own novel.',
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'Gx4iz3Zhrl9vLRdUJjWp',
    label: 'Feature Your Favorite Product Or Service',
    text:
      'I want to tell you all about briefly describe a product or service you want to showcase.',
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'H3XUfMRQwJMKX5RIYG5I',
    label: 'Music You Love',
    text: 'What are you listening to these days that fits that bill?',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'H3lcc7VnAzGq9cGVln3J',
    label: 'Enjoying the Process',
    text: 'Sharing my journey with you makes me enjoy the process even more.',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'H5O3NiIbJEf6Sr7dsefM',
    label: 'Looking Back On The "Good Ol\' Days"',
    text:
      "Drop a praise hands emoji in the comments below if you're living your best life NOW so your FUTURE self will be proud!",
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'H5YYCBCGC11b4LcTv8nv',
    label: 'Attitude of Gratitude',
    text: "Yes, it's true… I absolutely LOVE what I do.",
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'H8JTJZ9a7eGJtoGOAUFs',
    label: 'Be One-of-a-Kind',
    text: "It's what makes this world beautiful.",
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'HGHYelkQhVdrzCHCxngJ',
    label: 'Promotion Ending',
    text: 'Time is running out.',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'HRh9b0LLmr15SzeLhpbZ',
    label: 'A Featured Product/Service',
    text: "Cozy up… I want to tell you about product, and I know you'll LOVE it!",
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'HToQyS9Kjag7HTpUDTF5',
    label: 'Unexpected Perks of Your Job',
    text: 'Have you found any unexpected perks in what you do?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'HTxceSS6E8NyGrfrMT3C',
    label: 'Your Dream Home',
    text: 'Tell me about your dream home!',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'HVml3ky7q4wHA2YHSWOw',
    label: 'Lifting Your Spirits',
    text: 'What do you do to lift your spirits?',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'HW6Q1LBQyfsTjZx5XLK2',
    label: 'What You Have A Heart For',
    text: 'What do you have a heart for?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'HXbdOxdlukvAHGso4S3R',
    label: 'Where Your Passion Takes You',
    text: "Through this work, I've been able to travel to NYFW.",
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'HYg2yr22n0nEwKp1icEt',
    label: 'Surprising Job Necessity',
    text: 'What do you do and what do you use to do it?',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'HZ3DFZwHCHVchysqFEUn',
    label: 'Introducing the Team',
    text: 'Hey, hi, hello!',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'HbxLbovsXrh1jXJseYq6',
    label: 'A Recent, Featured Change',
    text: "And around here, we've made a few changes, too!",
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'HfeFaXOPSeYoo54xJvBf',
    label: 'A Few of Your Favorite Things',
    text: "So, now it's your turn to chat… I'd love to know your name and where you're from!",
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'HgxAxb2MexlgwAf0gdC6',
    label: 'Sharing Success Stories',
    text: "I always love hearing success stories, don't you?",
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'Hl73j4BliosPI4YEhwLY',
    label: 'Your Team',
    text: 'Teamwork makes the dream work, right?',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'HmNEY4GxLN8pT5Rg0jrs',
    label: 'What Your Clients/Customers Like',
    text: 'Do you like piña coladas and getting caught in the rain?',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'Ho6UTgqzhdjUZDGE3VLf',
    label: 'When Inspiration Seems Far Off',
    text: 'When that happens, I go for a manicure to get my creativity flowing again.',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'HoOUJlhARcf37UEWiVjq',
    label: 'Client Shout Out',
    text: "It's customers like @clientname who keep me going.",
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'I0VazCKvFgoFDH5Yj5Ec',
    label: 'Wisdom From Others',
    text: 'And by a little bird, I mean @accountname.',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'I0gKC74VfpLTYbd8v0NR',
    label: 'Counting Down to Your Next Launch',
    text: 'Besides this big announcement, what are you counting down the days to?',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'IBeRfImuRpkHKodGRR04',
    label: 'How You Can Help',
    text:
      "While I'm no fairy godmother, I can grant you the wish of describe how your business will help someone.",
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'IBwdhV1nnu2fJ3VPeTET',
    label: 'Being Honest About Your Beginnings',
    text:
      'When I started out as a photographer, I was truthfully a complete disaster behind the lens.',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'ICMsxaF6xA404y5Uqp5X',
    label: 'The Tastiest Craving',
    text: 'What are you craving these days?',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'IEl3DCR7wisWjp6dQWjE',
    label: 'Caption 2',
    text: 'This is your last chance!',
    type: 'hook',
    category: 'holiday'
  },
  {
    _id: 'IFILinrDvK3WqDkeAD2z',
    label: 'How You Help Your Customers/Clients',
    text: 'What are you grateful for today?',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'IHyr7mYr8BYKJIEFku0W',
    label: 'Sending a Card Today',
    text:
      "Because today, I'm sending a card to my best friend, who lives across the country, and I think you should, too!",
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'IJ809VlmJ0GpEozk0T3c',
    label: 'Your 20-Year-Old Self',
    text:
      "I imagine I'd have saved myself hours a day, stressing over everything from outfits to term paper titles.",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'IJC68yxVis09pDH4JRRz',
    label: 'How Your Product/Service Is Good For Client/Customers',
    text: 'Ready to get started?',
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'ILWLjwAps6YqEKwNi42M',
    label: "Something You're Insecure About",
    text: 'Is there anything you are a bit insecure about?',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'IRklPGw8Pvl5FFhE15yL',
    label: 'Choose Your Feelings',
    text: 'Comment below!',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'IUoVT9Z2bHmVBcOW6cuW',
    label: ' 100% Certain in Your Product/Service',
    text: 'They say nothing is 100%.',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'IVNjTXhY3xzY8C1Yjaaf',
    label: 'What You Accomplished This Week',
    text: ", it's definitely a day worth celebrating!",
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'IXXB0HYPJfiyHrtCH6KB',
    label: "How You'd Spend An Extra 30 Minutes",
    text: 'How would you spend a bonus 30 minutes every day?',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'IXsfFJ5RQuptNk2NjKSV',
    label: 'How You Change Perspectives',
    text: "What do you do when things start looking different than you'd planned?",
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'IbhJ8mzlzmHqUKP0SZUH',
    label: 'Love/Hate for Plants',
    text: 'Do you have a green thumb?',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'Ilf8JZWrgDyNfvU7aQyE',
    label: 'Who You Hustle For',
    text: 'Who or what are you hustling for?',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'Im48uj4CxypBwHlOcRcQ',
    label: 'Your Favorite Childhood Treat',
    text: 'Tell me yours in the comments!',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'IpRZiLVohlOxnSpTs3A3',
    label: 'Sharing Tips/Advice/Etc.',
    text: 'Pretty sweet, huh?',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'IpTp3JqCDePmcQbFTWL2',
    label: 'School-Themed Introduction',
    text: "I'm name, a job title from city.",
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'IpgdIePaiO0G2tGqx38k',
    label: 'Getting Ready for Something',
    text: 'Hit me!',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'IqcRUebSydZ31ZuiQIgz',
    label: 'Your Biggest Fears',
    text:
      'I might still be afraid of the bogeyman, but I take on adult fear mentioned by how you face your adult fear.',
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'Ir5QgirF9wcP59I7NLMW',
    label: 'Unexpected Tools for Productivity',
    text: 'Is it surprising that I run this whole business on practically those things alone?',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'IwjvVbe3qk8ZKmkMx6jt',
    label: 'What Customers Can Count On',
    text: 'Do you have any questions?',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'IxdLvHhyW6kykDv520e7',
    label: "Something You're Not Finished With",
    text: "I guess I just have to practice patience right now… But don't worry!",
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'J2RSP8YSw1v5mc6pENfx',
    label: 'What Gives You Good Vibes',
    text: 'Set a timer for 60 seconds and GO!',
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'J3hHqVuQdYfzOctFE81i',
    label: 'Words From a Client/Customer',
    text: 'How can I help YOU?',
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'J56q8aaxZmxUJNqXYuh9',
    label: 'Truth or Dare',
    text:
      'When I used to play that game in 4th hour study hall to avoid doing my math homework, I always chose truth.',
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'JAwcgJ91ilex1T8BjMWH',
    label: 'Saying Thank You',
    text: 'I owe so much of my happiness to you… so thank you!',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'JHU6CzZhOo5hCyxaKkQn',
    label: 'How You Do What You Do',
    text: "I'd love to chat!",
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'JJXPdNxepOkSfz4TD9se',
    label: 'Productivity-Boosting Techniques',
    text: 'When it comes to scheduling out my day, the timer app on my phone is my BFF.',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'JKUS9Xf9qXnpdHATrJFX',
    label: 'A Day in the Life',
    text: 'What else would you like to know?',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'JPHl9sxUVShmWWdY9sbi',
    label: 'Sharing A Testimonial',
    text:
      "I'm convinced I am uniquely qualified to craft a nutrition plan to help you reach your weight loss goals.",
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'JRxPE8S7knDRmx1cf1Y3',
    label: 'Who Taught You Everything You Know',
    text: "Who's the person who helped skyrocket your success?",
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'JSzJq5cRYupj1fp5Wqln',
    label: 'Exciting Announcement',
    text: 'Cue the confetti because I have EXCITING news!',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'JTK4QXnQuxOH8YE11DSM',
    label: 'Your Perfect Day',
    text:
      "Whether I'm working or taking a day off, I love to start my mornings with first thing you do in the morning.",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'JUpqYC5mWZM6gx6DWgeO',
    label: 'Thanking Someone',
    text: 'Where do you stand on hat-wearing?',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'JX0cCq1tL2JeVzmbDjh0',
    label: 'Caption 3',
    text: 'You might think I do a lot of thanking, ( you should invite me to a dinner party.',
    type: 'hook',
    category: 'holiday'
  },
  {
    _id: 'JbfBG3hy0S1BmyFpkrde',
    label: "Your Business's Truest Purpose",
    text:
      "I'm always inspired and motivated by kids—they dress in what makes them happy before they've learned to care what others think.",
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'JdA9C5vUhWtLQwCEPWXC',
    label: 'Your Weekend Plans',
    text: '… OH MY!',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'JkZql13lh1PEUnnC4hO3',
    label: 'If You Slept In',
    text: 'What about you… did you sleep in this morning?',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'JmMXF7l9JIyks6EoIVhY',
    label: 'Best Part About What You Do',
    text: 'How about you, friend?',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'JswLek5DbbMrsOYwi4BA',
    label: 'Sharing Your Talents',
    text: 'I interrupt your regular social media content for a virtual talent show!',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'JsxuAcRj4SyEDDMemONZ',
    label: 'Promotion Ending',
    text:
      'With just a few hours left in the sale, I/we hope to be a continued resource for all your hair styling needs, and will be glued to Instagram to answer all of your questions.',
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'Jtb8QNdHNnWlVsimaeXk',
    label: 'Achieving Positive Results',
    text: "If you're like my, it's a no-brainer!",
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'Jv2tT3mDVfxpeA0csqHe',
    label: 'Kind Words from Clients/Customers',
    text: "I'd love to know how I could bring about a similar response from you.",
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'JvYLdwk0oIdHXbV9eUJi',
    label: 'Why You Love Your Routine',
    text: 'Are you a stickler for routine, or do you like to go with the flow?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'JwSVh5OvgDdgrhnpfZQF',
    label: 'Why You Do What You Do',
    text: 'Can I be honest with you?',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'Jxzn5ulzUtPH9rfN6Mu2',
    label: 'Decorating for The Holidays',
    text: 'When do you put up your holiday decorations?',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'JzoIPcKBq3kdedQiPw68',
    label: 'Client Review',
    text: 'What do you want most in a wellness routine?',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'K5F1h2KJBPDx1hpYDMvW',
    label: 'What Colors Your World',
    text: 'I thought that something you feared about starting your business.',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'K9ef7l7FVEfmDu5qtyvk',
    label: 'Deciding Your Saturday Plans',
    text: 'Help me out in the comments below!',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'K9u5krzzEAJ2k3gxSvle',
    label: 'Who You Grew Up With',
    text: 'Who did you grow up with?',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'KC1ArrCnrEFEYKvtsSHQ',
    label: 'Person You Want to Thank',
    text: 'So, I have to know… Are you a fan of sending cards?',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'KC1ScGkltXDS7oKj5SBf',
    label: 'Emoji Game',
    text:
      'Using ONLY emojis, comment below with what your ideal family portrait session would look like.',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'KE7NssiioZHGWXaDe6dX',
    label: 'Best Motherly Advice',
    text: 'It\'s been said that "mother always knows best.',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'KHUYgX4jJ3FDcUOtyJLz',
    label: 'Personal Opinion',
    text:
      "But no matter which group you fall into, I'm betting you'll absolutely love our handmade head-wraps!",
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'KSDeYkPYeBXiAvnW6CgT',
    label: 'Pushing Through Tough Days',
    text: 'How do you push through the tough days?',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'KSgzv0QO1IzcidSUi5wn',
    label: 'Sharing Your Process',
    text: 'Fire away!',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'KTGWFGospz2ORJzEpBah',
    label: 'The "Short Straw" of Your Business',
    text: 'When you were a kid, did you ever "draw straws" to do a chore you didn\'t enjoy?',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'KTGZ40WUcHvh8AmRnNq9',
    label: 'How Your Personality Influenced Your Career',
    text:
      'So it comes as no surprise that when I made the decision to start my own business, I chose a career heavily focused on customer service!',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'KTQd6oKCWexPrApaHjdV',
    label: 'Advice for Your Younger Self',
    text: 'What would you tell your younger self?',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'KdtZw74e2xfFqHIXnBfc',
    label: 'How You Relieve Stress for Your Clients',
    text:
      "Who out there is feeling like there's not enough time left in the day to handle everything you've got left to do?",
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'KeE2C2e7BkwR0GADN8ap',
    label: 'Your Split Personality',
    text: 'Does that sound a little Jekyll and Hyde to you?',
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'KekIiEGrCnb6YxkEQTgY',
    label: 'Your Picture-Perfect Weekend',
    text: "So what's your picture-perfect current season weekend?",
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'Ki8PbUZry5KqMtE3prpv',
    label: 'Showcasing Early Adopters',
    text: 'Are you ready to hear from these amazing people who registered for my online course?',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'KkP5o2435glOqlQTpyFr',
    label: 'Sharing a Struggle',
    text: "What's a struggle you're facing and how do you fight it?",
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'KmxdCrRzGt7JpTji2C0E',
    label: 'What Fun It Is To Do What You Do',
    text: 'Oh, what fun it is to be a your profession!',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'KpAwGoZJAys0UXZHOXq9',
    label: 'Sharing a Review/Testimonial',
    text: "You don't have to take my word for it.",
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'KpLxPVjlD0r2cy9JgyIk',
    label: 'Who Has Your Heart',
    text: 'Who has your heart?',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'KpeNbPQIwA8uGT4Wkhbj',
    label: 'Why Now Is The Time To Buy',
    text: "If that's not a reason to take the leap, I don't know what is.",
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'KpyLZsYOUsazn8SK0F9C',
    label: 'Thanking Someone',
    text: 'Who deserves a tip of your hat today?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'KvJDMvwDFz2sb5WYXwBF',
    label: 'Your Best Friend',
    text:
      'As a business owner, some days I feel like my best friend is my laptop because I spend so much time with it.',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'Kz9NClvcsiPJnkKVtQPo',
    label: 'What You Do',
    text: 'Visit your website for more information!',
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'KzIFZvpZLB7vPGE5VQav',
    label: 'Introduction Post',
    text: 'Hey there, friends!',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'L0hU9l62yH1mBCPjvQZe',
    label: 'Invitation To Purchase',
    text: 'Opportunity knocks…(or rings the doorbell, whatever)!',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'L0wtmlR7jMoF1vLqfrPU',
    label: "Doing Things You Don't Like to Do",
    text: "There's nothing quite like a clean plate and request for seconds.",
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'L1Mfc7dQHNDW3FWfupJh',
    label: 'Releasing Something New',
    text: "I can't wait to hear what you think!",
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'L3WzR2eNblSaXDtaFgTv',
    label: 'A Special Perk',
    text: 'How can I help you today, friend?',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'L7eS87MKR8O2QxB85QEO',
    label: 'The Nudge to Run Your Own Business',
    text: '" Do you have a similar story?',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'L7vk0RtcgbfQGBDucueo',
    label: 'Seasons In Your Business',
    text: 'Just like the seasons of the year, we have seasons, too.',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'LBjGPk7YkLeKavU9hoUr',
    label: 'A Knock-Your-Socks-Off Announcement',
    text: 'Please be sitting down… and be wearing sandals.',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'LDI8yDydRGAxAHPlVPi8',
    label: 'How You Grow With Clients',
    text: 'Think you can guess where I learn the most about running this business?',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'LKFD0TFOXZtnQUO6MZN0',
    label: 'Favorite Vacation',
    text:
      'This will be hard to top… My favorite vacation is still my honeymoon in Napa Valley in year.',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'LKRcs6GLypLykGnLpuEt',
    label: 'Cure For A Bad Day',
    text: "What's your go-to cure for a bad day?",
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'LNdbC3hO6NNFEmMBwCNo',
    label: 'Limited-Time Deal',
    text: 'Check out my family portrait packages and save 15% when you book online!',
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'LPiKJof25tH2GRvp57qs',
    label: 'Inviting People to Your Corner of The Internet',
    text:
      'Welcome to @yourhandle, where you can expect to catch a glimpse into my life as a business coach with the occasional start-up advice thrown in!',
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'LSPusZkCAtQff7656p3K',
    label: 'An Amazing Fact',
    text: 'Want to unwrap an amazing fact?',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'LT4shX9frdbzwUK1kTCr',
    label: 'How You Got Started',
    text: "Check out today's Story for a full account of how I got started!",
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'LguR1VQzyt3WQEuKleKU',
    label: 'The Perfect Day',
    text: "Now it's your turn to daydream!",
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'LimriBTI1lW5gkKUoKAO',
    label: 'Reasons to Stop And Smell The Roses',
    text: 'Hey, you!',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'LjBPh2FrvqiX8bbkCH6W',
    label: 'Your Work Week Flow',
    text:
      'As you can tell, my schedule describe your schedule is pretty predictable, just the way I like it, and this week is no different.',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'LlWDaFnrWHUlyk4zq6B6',
    label: 'Client/Customer Favorites ',
    text:
      'In fact, many of my agree that this is their favorite thing about them, too--next to the depth and hands-on approach.',
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'Ln7mIFHKXkQRW5mgfagi',
    label: 'Favorite Kind of Cookie',
    text: 'Okay, fine… but only because it was kind of cookie.',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'LnmGbb5mIJapOzAVAQ1L',
    label: "Who You'd Get Coffee/Tea With",
    text: "Now it's your turn… Who would YOU get coffee with?",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'LtEC0jSYFL1JrxeIAUJ3',
    label: 'Bad Day',
    text: 'It never fails to make me feel mood.',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'LtsIczjcAyT0zSPXf6DO',
    label: 'Limited Time Offer',
    text: 'Ask away!',
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'LuamqiLQpdnDDTYGgh7v',
    label: 'Your Preferred Pizza',
    text: 'Are you: A.',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'Lw9i8OSJB2VScqKth8J2',
    label: "What's On The Agenda Today",
    text: 'My newest podcast episode of course!',
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'Lx8EQ2uVQyPL7iuvdpSL',
    label: 'Your Plans for the Day',
    text: 'I hereby declare today as a day for relaxing.',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'LyxzVBIsdt2mo97xvKc1',
    label: 'Better Late Than Never',
    text: 'Friends, what better-late-than-never realizations have you made recently?',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'M2AM56GijuduQBL6bLDu',
    label: 'American Independence Day (July 4th)',
    text:
      "Even though it looks a little different than usual, I'm excited to try this new gourmet recipe.",
    type: 'insight',
    category: 'holiday'
  },
  {
    _id: 'M4NiXdeqEWluzfCBfSrR',
    label: "What You're Indulging In While You Work",
    text:
      "And since today I'm working alone, I can indulge and no one has any idea I'm emailing clients with a desk covered in cherry pits.",
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'M6glkxiTR0ZlAurqjGQJ',
    label: 'Big, Scary Goals',
    text: 'What big goals are you setting?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'M6j3t5pkaLsXNMDpo8yM',
    label: 'Uncommon Business Secret',
    text:
      'I have an embarrassing secret to share: I buy take-out food and put it in real dishes to pretend I make it for dinner parties.',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'M9NBSUFLyA82R9niaW7L',
    label: 'Favorite Quote of The Week',
    text:
      'Yvonne Pierre said, "Use what you\'ve been through as fuel, believe in yourself and be unstoppable!',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'MBXXBrIm6KYQbZ7Ypmbp',
    label: 'Dream Vacation',
    text:
      "I would spend my days what you'd do during the day and my nights what you'd do during the night.",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'MG83sGjqtSFvXGTqokB1',
    label: 'Sharing An Inside Look',
    text: "I'm really excited to share this inside look!",
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'MGUhADzt2AzhgsM1DFOq',
    label: 'The Feeling or Result Your Product/Service Helps to Achieve',
    text: "Send me a DM and let's chat!",
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'MMBs5UfHG7fqzMAtKaf8',
    label: 'Going Live: Facing Challenges',
    text: 'Ever wonder what it takes for me to smooth out my jagged edges?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'MOAVfTcH27eVkUofxHMI',
    label: 'How Today Looked',
    text: 'What did your day look like?',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'MPFCve8Qf667bkslVOgT',
    label: 'Sharing a Good Story',
    text: "Who doesn't love a good story, right?",
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'MQ7P5iw2GapqvqdBnJGD',
    label: 'Quote That Keeps You Going',
    text:
      "When I repeat those words to myself, along with the age-old method of hitting a punching bag, my insecurities and doubts fizzle away and I'm able to overcome even the biggest obstacles.",
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'MQO9tMsmemzAB6LAjn4v',
    label: 'Freedom To Work From Anywhere',
    text:
      "I started this business with the intention of broadening my horizons, but it's taken me so much further!",
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'MSGlBtn8zzul0VcSM8pB',
    label: 'Pump-Up Playlist',
    text: 'Does your life have a soundtrack?',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'MTirDvgQK0tYjHNGoPyf',
    label: 'Penny for Your Thoughts',
    text: "Here's mine about personal development: If I'm comfortable, I'm not growing.",
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'MXrrfez4zUfh7Zc0WKo9',
    label: '3 Reasons You Do What You Do',
    text: 'Now as a your profession, I how your view of money affects your business today.',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'MXsgKIIEFItBPTWJ7IJC',
    label: ' A Lesson Learned By Failure',
    text: "I'd love it if you left your insight in a comment below, so we can all learn together!",
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'MYDus7XPNdwXiEFZEieN',
    label: 'Offering Some Comfort (Food)',
    text: "So, friend, what's on your plate?",
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'MZeDKt0nlVADhaWkibwB',
    label: 'Who Your Product/Service Is For',
    text: "I really think you'll love it!",
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'Ma2Qnqb2RNBZhKHnvVpV',
    label: 'What to Do with Extra Time',
    text: 'One less hour of daylight to read all the lovely latest on my blog!',
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'MaLqcMsTZAnHco9kIkDo',
    label: 'What Is "Success" To You',
    text: 'What is it for you, friend?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'MikMAR7rNyZ5c9YbtpPp',
    label: 'Positive Words about Working With You',
    text:
      "Hang on to your morning coffee, because you're not going to BELIEVE what @clientname said about what it is like to work with me!",
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'MrIQvOGg8pw1JwkrIAEB',
    label: 'Your Favorite Product/Service',
    text: 'Did your parents have a favorite child?',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'N1jFWPscsEwN0jrCCM7K',
    label: 'How You Treat Yourself',
    text: "How do you remind yourself you're following your heart?",
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'N2ZsE6BUsiA6ZRIeplhh',
    label: 'A Red-Hot Announcement',
    text: "I have news that's guaranteed to heat things up!",
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'N3QnsWvyMRgcaIro8tWR',
    label: 'Thanksgiving (4th Thursday in November)',
    text: "What's your favorite Thanksgiving tradition?",
    type: 'call-to-action',
    category: 'holiday'
  },
  {
    _id: 'N9Sx6MrgebshSRQjXLyl',
    label: 'The Snack Behind Your Success',
    text:
      'Am I the only one who bribes myself throughtedious emails with the promise of a piece of dark chocolate?',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'N9c4i3Sws4BENKNslGF2',
    label: 'National Fun At Work Day (Last Friday of January)',
    text: 'When you love what you do, you never have to work a day in your life.',
    type: 'hook',
    category: 'holiday'
  },
  {
    _id: 'NFQVmkuuLLnH1ZJpiV2J',
    label: 'Things Get Bumpy Sometimes',
    text: 'Can I be honest with you?',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'NFt2ZpCv9LNSv6qIrEYB',
    label: 'Your Life Behind The Curtain',
    text: "I'd love to give you a sneak peek into my life behind the scenes.",
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'NGuG868BmX9O3dSoIGNA',
    label: 'Taking Big Risks',
    text: 'Are you a risk-taker?',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'NITNW2scawvaxv7ehLxO',
    label: 'What To Expect This Year',
    text:
      "I'm calling it right now: this year will be the year I go on a month-long cross country road trip with the income from my essential oils business.",
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'NIVUWSiAGeJcWP8SeJ74',
    label: 'Your Career Path',
    text: 'So where are you headed, friend?',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'NOqhDdr8UP0D3UE05FbR',
    label: 'Pranking Your Followers',
    text: "What's the best prank you've fallen for?",
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'NPHANTBN0VpRPfCpSQGT',
    label: 'Hitting the Refresh Button',
    text: "What do you do when you need to hit the 'refresh' button?",
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'NX4zsAhXNE4BZCJ7INx3',
    label: 'Nourishing Body & Mind',
    text: 'My mouth is watering just thinking about it!',
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'NZD85kRCIHx2ytk4rfqk',
    label: 'Where You Find Encouragement',
    text: 'Where do you turn when you most need encouragement?',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'NbTbzsrtielBxP032V0G',
    label: 'Prove Them Wrong',
    text: 'Double tap if this encouraged you!',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'NgjNUwYzw4hOrGswikPp',
    label: 'The Big Reason for Your Product/Service',
    text: 'What questions do you have for me?',
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'NhbInLsbL3NNuHLaZsfB',
    label: "A Struggle You're Facing",
    text: 'I need your help to get "out of the woods" and find the road back to civilization!',
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'NkGB7xgW8XlEShKzqvKF',
    label: 'Hypothetical Snow Day',
    text: 'Now you!',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'NnOG07NdMx04yb1HT4Q1',
    label: 'How You Handle Hard Days',
    text: 'Maybe we can all give each other ideas!',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'NsmsHdoPEQSL9jgB0Ja6',
    label: 'Best And Worst Parts of the Day',
    text: 'And you?',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'NsuQojiqBSgICjQueVzW',
    label: 'Friendly Introduction',
    text: "So, that's me in a nutshell.",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'Nva3ngWZr3caAE5ZQt2W',
    label: "Something You're Proud Of",
    text: "I'm here to help!",
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'O8NN23CZM18SYxRSv56e',
    label: 'How You Ground Yourself',
    text: 'How do you ground yourself on a stressful day?',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'OErxHPorJCDFDXJYi6IZ',
    label: 'Going Live: Keeping Clients Happy',
    text: "I'll tell you everything during date, time, and format of your Live!",
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'OEylhm7ium8FsQsuBLEM',
    label: 'Ingredients for Success',
    text: 'What questions do you have?',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'OFxliwehqErllkKukYL3',
    label: 'Popping Bubbly for a Big Announcement',
    text: 'Big news, right?',
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'OIw6u1Wv5ZVqOCu95lzD',
    label: 'Your Morning Routine',
    text: 'Do you have a morning routine?',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'ONVW4r4bI6PhHY3CcAgq',
    label: 'What Brings You Focus',
    text: 'What important reframe gets you back on track?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'ORSZKh5hPE6oy3AzoVkv',
    label: 'How You Take Breaks',
    text: 'How do you break things up when your days get crazy?',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'OWlYs83IjoattCMWv9ZG',
    label: 'Favorite Drink',
    text: "What's your favorite blend?",
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'OXBp1HRq7GbPFZrCyhRt',
    label: 'Featuring A Client/Customer Review',
    text: 'but do you know what happened when I worked with new client for the first time?',
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'OZtXYikhkDzL7fOA5RBq',
    label: 'What You Built And Why',
    text:
      'I preferredwhat you played with, because I was always excited to be what you loved about the game you played with flexing my creative muscles.',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'OcyWmzAp8zBP9pccjG8e',
    label: 'Favorite Sweet Treat',
    text: "I'll say that for me, I am all about gluten-free brownies.",
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'OeVZLDFTbOM0ZpTo86Cu',
    label: 'A Surprising Fact About Your Product/Service',
    text: 'What else surprises you about product?',
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'OfILz2Fugbg2qzB9frGt',
    label: 'Working With You',
    text: 'Can I ask YOU a question, friend?',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'OgHN9WcxXWNpVrQidF0a',
    label: 'Slowing Down In A Fast-Paced World',
    text:
      'Is it just me, or does our society seem to be moving at 100 miles per hour thanks to technology?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'OhlHR4Ph5b5ngl4M7dtF',
    label: "What's On The Agenda Today",
    text: "It's all about how to wear stripes AND polka dots this summer!",
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'OkDzaGO2Yd5tE1A2fls6',
    label: 'Personal Preferences',
    text:
      "I'm seeing so many gorgeous new faces around lately and feel like I should take a moment to properly introduce myself.",
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'Okogb4nUS3g0i6Vapv6o',
    label: 'Appreciating The Life You Live',
    text: 'Can you handle a reality check?',
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'OmxIkJF6deDirmk5K1iH',
    label: 'Announcing New Content',
    text: "This is why I'm excited to share my new blog post with you.",
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'OpFSmLTmOqAEfYTOqGLf',
    label: 'The Sweet and Sour of Your Job',
    text: 'What is something sweet and\n sour about what you do?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'OtPE1tUPPEHJUziVnqHq',
    label: "The Dreams You're Chasing",
    text: "Now, tell me your dreams in the comments and let's achieve them together!",
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'Ovrb3B5irJHojkTmRWsE',
    label: 'Taking Action on Your Product/Service',
    text: 'What are you waiting for?',
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'OyI5YB0k272OPBzUoTnO',
    label: "A Fun Trip You're Taking This Season",
    text: 'When I was a kid, season always meant sleep-away camp with my best friend.',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'OzXekGRboTAbLq4dDDsc',
    label: 'A REALLY Good Day',
    text:
      "There is nothing more gratifying in my line of work than seeing my clients reach their goals and I can't tell you how much that means to me.",
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'P0OZDJs04OOVOPFSiHKE',
    label: 'Encountering A Problem',
    text: 'Have you had to cross any bridges lately?',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'P0ndkyJiK1nobFQ5nRzq',
    label: 'Perks of Your Job',
    text: 'How do you make sure you get to have your cake and eat it too?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'P1dKszvjC3zGD4VBiOvI',
    label: '3-Step Tutorial',
    text: 'What questions do you have?',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'P2fnrhMryvcQFnAEIzwo',
    label: 'Your Favorite Form of Self-Care',
    text: 'So, what is your favorite form of self-care?',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'P4xo3eDzE8Q90fm6g84P',
    label: 'Introverted vs. Extroverted',
    text: "(No offense to you introverts/extroverts pick the other—you're the yin to my yang!",
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'P5T8Vukzh7JjlVdtIMfP',
    label: 'What Warms Your Heart',
    text:
      'But sunshine or snowstorm, there are a few things guaranteed to warm my heart: and  puppy snuggles will do it every time.',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'PAP4WiylLXwDKPF26bhv',
    label: "What's On The Agenda Today",
    text:
      'Head to the link in my bio to check it out and come back here to let me know what you think.',
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'PLBzDGhNtXZYj3G6F1cn',
    label: 'May Day (May 1st)',
    text: 'Do you celebrate May Day, and if so, how?',
    type: 'call-to-action',
    category: 'holiday'
  },
  {
    _id: 'PLfqtv9jFfRCgBob4eGd',
    label: 'Cheers to Caffeinated Productivity',
    text: 'If you feel me, drop a coffee mug emoji in the comments so I can toast to YOU!',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'PMU3DElJYBLC3esTvArn',
    label: 'Cure for a Tough Day',
    text: 'Tough day?',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'PNrHw8IG0FgaYRqfXqP8',
    label: 'Releasing New Content',
    text: "Are you ready for what's on the agenda today?",
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'PNt5naGRk3qKT5UmXNyy',
    label: 'Introduction',
    text: "I'm your name from city and I am passionate about what you're passionate about.",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'POeZOQdRlzuB5bppGuDT',
    label: 'Bringing Struggles To Light',
    text: 'Ever wonder what really goes on in the shadows?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'PQncMbz2ykHlpGHx08uO',
    label: 'Grateful for a Platform',
    text: 'What can I help you with today?',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'PRpmcRMlJve5HJIM9mtH',
    label: 'Big News, Big Benefit',
    text: 'Fire away!',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'PSn7C0EAWjAcBRlerurM',
    label: 'Your Trick to Prepare for A Busy Time',
    text: "I'm welcoming advice from business owners and NON-preneurs alike!",
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'PY12zzO8fdbln0SQV3GU',
    label: 'Going Live: Your Why',
    text:
      "I'll be forever grateful if you joined me LIVE in  length of time so I can share all about it with you!",
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'PY9UhRXlCspkKNn2lMWw',
    label: 'What Your Customers Love ',
    text: "It's things like this that make our customers feel all warm and fuzzy inside.",
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'PiJ0KniXfQR9QXnzr0mD',
    label: 'What Makes You Smile',
    text: 'What made you smile today, friend?',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'PmVRjU6GTZ2HlitWzHbI',
    label: 'Your Saturday Plans',
    text:
      "The whole day is ahead of us, and I've got big plans to grill the afternoon away in preparation for tonight's barbecue extravaganza.",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'PmwE0hmko4LOPMYgaCpO',
    label: 'Your Process',
    text: 'And finally, list step 3.',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'Pq4uZAsgDwFTjYouiOkF',
    label: 'Your Computer File Orgnization',
    text: 'Tell the truth!',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'Puo4f3WvGbsOyYk7xiZ0',
    label: 'Ask You Anything',
    text: 'How about this for a day of the week?',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'Q46tDhG4Z8Z26GhhqrR1',
    label: 'Thanking Someone ',
    text: 'Do you do it often?',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'Q4VMKrH02928z24L1mSy',
    label: 'Loving Your Pet',
    text:
      'Share the name of your first pet, the name of your current pet, or if you just owned a Tamagotchi pet… I want to get to know you better!',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'QB4gQeiQ3DUP8w2BYesf',
    label: 'Connecting With Others',
    text: 'What fills your cup (or bottle) to the brim?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'QGccbmPLzuv3kaaqHoVu',
    label: 'What Gets You Out of Bed',
    text: 'What gets you out of bed?',
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'QIGVf1VRke1u8JGbEx27',
    label: 'Focusing on the Present',
    text: "Here's a friendly reminder to just be HERE.",
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'QOIMwE8hDJj7JIh2uu4W',
    label: 'How You Spread Kindness',
    text: 'Do you agree or disagree with the statement, "It\'s cool to be kind"?',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'QQG4soMoVvRB91l87eKU',
    label: 'An Exciting Announcement',
    text:
      'Well, get THAT excited because this week marks the start of our everything-must-go spring clearance sale!',
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'QSJJPgT4WR4gr4swTepq',
    label: 'Sharing Your Passion',
    text:
      "Today's post is dedicated to welcoming any newbies to the community and sharing just a little about why I do what I do.",
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'QTKIuvGJLatBn8R1fvPA',
    label: 'Encouraging Advice',
    text: 'These are the words that keep me going!',
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'QVvAHc1ZYMsRBmYifQ63',
    label: 'How You Feel About Tacos',
    text: "True or false: The only bad taco is the one you didn't eat!",
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'QXHmiZ1J560UoFU2GbcS',
    label: 'Flash Fire Fun Facts',
    text: 'Fun fact #1, fun fact #2, and fun fact #3.',
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'QY82M0sfM85wKDpTC1Sh',
    label: 'Going Live: Topic Input',
    text: 'The plan: Go live on Instagram.',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'Qpue6BgMHoumY4EXtd6y',
    label: 'The Real Reason You Do What You Do',
    text: 'How about you, friend?',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'Qvtw0pPWHLF0KsxXQPvx',
    label: 'Berry Good News',
    text: "This isn't just good news, it's berry good news!",
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'R6tED058mQmcXaY4dZ2Q',
    label: 'Your Big Dreams',
    text: "Ever since I was little, I've been a dreamer.",
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'R9M1jFfhL0TINEbyrpTc',
    label: 'The Perfect Afternoon',
    text: 'Now tell me yours!',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'RDP9sFtXCDtoCOw8u88X',
    label: 'What You Do Differently',
    text: 'Want to hear something embarrassing?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'RIwYEPIoej2kIbLeaeat',
    label: 'Tis The Season',
    text: 'What do you look forward to this season?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'RJGqIVwJfd1ItDfiHdgE',
    label: 'A Meal You Love',
    text: "Turns out, more often than not, it's a total win.",
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'RPr1leAINgQcJunjqiVn',
    label: 'What Changed Your Perspective',
    text: '"What\'s helped change your perspective lately?',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'RQLA92BfdQYwVyrNOnUR',
    label: 'An Unforgettable Memory',
    text: "Tell me about a memory you'll never forget!",
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'RSR7rNOTnL9Jf1Z8VJ7R',
    label: 'Weekend (Lack of) Plans',
    text: 'How are your weekend plans shaping up?',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'RVvqLRWn96Sy25rs1Nh5',
    label: 'Encouraging Advice',
    text:
      'Whenever I\'m having a bad day in my business and consider throwing in the towel, I try to remember this advice my grandmother once told me: "" "When there\'s rain, sunshine is always right around the corner".',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'RX79pHiuXCcKeKeG6nz1',
    label: 'Taking Advantage of Creative Energy',
    text: 'Oh, friend, do I have a treat for YOU!',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'RjpaE6ghTI7QZP7E1H5X',
    label: 'Game: Pool Party Style',
    text:
      "When it comes to my business, however, I tend to wade in slowly, and luxuriate once I'm there.",
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'RmlVJJnuihkUU7fZxNiB',
    label: 'Thanking Your Followers',
    text: "I'm all ears!",
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'Rn9EoPWOwbzzmvmQ6MG6',
    label: 'A Sweet Add-On To Your Product/Service',
    text: 'Ready to get yours?',
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'Ro937M0dX5P2ThgpmfPO',
    label: 'Shaking Things Up',
    text: 'Ever feel like shaking things up?',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'RpZE0LzUobFHgqvkYINi',
    label: 'Client/Customer Review',
    text: 'Boy oh boy, I just LOVE hearing from happy clients!',
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'Rqs5SVFoOy9j1CKkxwrK',
    label: 'Who Helps You',
    text: 'Have you ever wondered how I manage to what you do on a daily basis?',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'RrPcHNPoUCHP12VdOrGC',
    label: 'Shopping Poll',
    text: 'Plus, all the cool kids hang out there, right?',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'RsIZjuJiQ9utmSo53cnn',
    label: 'How You Help Clients/Customers',
    text: 'Did you know this about me?',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'RtZ7x4Nm0XrJOfYSJ2zR',
    label: 'Sharing a Helpful Tip or Strategy',
    text: 'Here it is: Share your tip.',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'Rv71aOKK81kM5ShRhrcQ',
    label: 'Your Purpose',
    text: 'What do you love to do more than absolutely ANYTHING in the whole wide world?',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'Rz6btNbpTjLdXFYTSamy',
    label: 'Refreshing Benefits of Your Product/Service',
    text:
      'Ahhh--the sweet, refreshing feeling of relaxation, knowing your dream wedding is being expertly planned for you.',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'Rz8zIDHLiRm0C0qoXn8Q',
    label: 'Your Daily Fuel',
    text:
      "It's crazy over here as I gear up for next week's launch, so you won't catch me skipping my fuel these days!",
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'S0Y1PNbEKw2pOmZWdobg',
    label: 'What Inspired Your Business/Product',
    text: 'Recently, I was thinking about when I started Y business.',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'S0g7bChlZ2oAC34d0DtE',
    label: 'Send a Smile',
    text:
      '"A smile is the light in your window that tells others there is a sharing, caring person inside.',
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'S8nW2QsLXIxiRyPC20DR',
    label: 'Favorite Thing About Upcoming Season',
    text: "Luckily, it's just around the corner!",
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'S9WELU4yDGFnAWzVRsO4',
    label: 'Client/Customer Testimonial',
    text: 'It is oh-so-human for us to doubt ourselves from time to time.',
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'SC8i2ymB4zhL9JewJCU4',
    label: 'Looking for Suggestions',
    text: "I don't ask for this very often, but I need your help!",
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'SLth1GMf1W3a2eWyFaB7',
    label: 'Turning Trials Into Triumphs',
    text: "Tell me which trials you've turned into triumphs in the comments below!",
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'SNiRFMQ5TeEncgDJo8XG',
    label: 'The "Opportunitea" You Took',
    text: 'Want to hear something fun?',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'SP2YA9eWUoNlFBwcMaVW',
    label: 'What Gets You Out of Bed',
    text: "I'll be honest: some days, I snooze my alarm… twice.",
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'SPKCUW6Jbcw8LktDvDan',
    label: "What You're Looking Forward To",
    text: "Tell me what you're excited about in the comments!",
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'SSXJm8Jre8bDuiRMhvyo',
    label: 'Who Fires You Up',
    text: 'Tag someone in the comments that fires you up.',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'SWhrHvpRMy6LUPjUsUzn',
    label: 'Your Secret Productivity Hack',
    text: 'My secret: A double espresso, promptly at 4:37pm.',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'SZi5UuA38i0rHwjUxCmC',
    label: 'How You Serve A Niche',
    text: 'Sound anything like you?',
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'SiWT7dv4ZRVMbKhkxFwu',
    label: "What You Can't Live Without",
    text: "What can't you live without?",
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'SlK4IObizxyq5wj3KvYX',
    label: 'Working With You',
    text: 'Ready to hit the jackpot?',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'SliXnLEPFplsFwYMGNg1',
    label: 'A Sweet Fact',
    text: 'What questions do you have for me today?',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'SmWzwG44LC4QTH6n73Bt',
    label: 'Being Optimistic',
    text: 'At this very moment, does your cup runneth over or runneth empty?',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'SqgnYj5CobOQweUABMsw',
    label: "Don't Miss Out",
    text: "Don't miss out, friends!",
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'St3gvyDORSMKGFjJc7kn',
    label: 'Upcoming Tutorial Invitation',
    text:
      '1… Ready or not, here I come with an awesome webinar on date called "" 3 Ways to Ensure Your Family Photo Shoot Doesn\'t Feel Like Agony.',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'SusyIiVjTPBqmncQteZ2',
    label: 'Where You Work Best',
    text: 'Sometimes the change of scenery is all I need to get my creative juices flowing.',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'SwbipziNUwUVgm9K0i7A',
    label: 'Reasons to Invest',
    text: 'Are you feeling pressure point of your ideal client?',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'SzXoLaBjVb775aT2SgCb',
    label: 'How You Stand Out',
    text:
      "I always like to keep things fresh in my business… and on that note, I'd like to share with you 3 ways I stand out from the crowd as a web designer!",
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'T0BNYMceqM6nGB4yXdqb',
    label: "What You'd Get Away From",
    text: 'Spill!',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'T3QQagZB3BupmJAOAlgZ',
    label: 'The Moment That Changed The Game',
    text: 'Do you have a single moment that changed the game for you?',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'T9nhwPruVkxBxb2ZhC32',
    label: 'Work Perks',
    text: 'Leave a comment below or send me a DM!',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'TCKaVK2JeTf8I1lN2Jyi',
    label: 'Taking A Break',
    text: 'Consider this a gentle reminder to take it easy, friend.',
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'TCS3KO9E4iJW3hgr8EDS',
    label: '2 Truths & A Lie',
    text: 'Wanna play 2 Truths and a Lie?',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'TDqogwTdmFkfjobMODNz',
    label: 'Telling A Secret',
    text:
      'Here goes: I stole a Mars Bar from Safeway when I was six, ate it in my treehouse, then buried the wrapper in the yard.',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'TL3Jvzm7JFxrkRToe6r0',
    label: 'Sharing a Business Secret',
    text: 'Want to hear a secret?',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'TQqoN6D5w7YJSfspt911',
    label: 'Caption 3',
    text:
      "Today I'll be sharing THEIR reasons for registering and answering YOUR questions to ensure you don't miss out on this limited time opportunity!",
    type: 'insight',
    category: 'holiday'
  },
  {
    _id: 'TR4zhNPEZvDJ8eE5llXh',
    label: 'Conquering Adulting Tasks',
    text: 'We all deserve a trophy for our adulting efforts, right?',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'TUEOO0EV8NNES15cFPJU',
    label: "New Year's Resolutions",
    text: 'Now you!',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'TXjRus4wkWaTAtIq9VV1',
    label: 'How You Do Self-Care',
    text: "I'd love to know!",
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'TZ1dR0KPq3FNfG7AkAme',
    label: 'Thanking Your Followers',
    text: 'This page is a place where I , , and share my passion for health and wellness.',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'Taidzh8PbpgUcAI2fVHN',
    label: 'What Gives You Satisfaction',
    text: 'Your satisfaction gives me joy, so how can I help you today?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'Taw1BGy7cmLwYTswG2s3',
    label: 'An Old Saying You Believe In',
    text: 'Shed some light for me, friend: Do you believe in this age-old saying?',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'TiFIwOch3zjgNm4xhwVJ',
    label: "What You're Working Toward",
    text: 'Are you one to set goals or go with the flow?',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'ToiBjKadbpiH1cfszh5R',
    label: 'Lazy Day',
    text: 'What are you up to today?',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'TpekMPYoaSJrgXsVk4Y4',
    label: 'Favorite Outdoor Activity',
    text: 'How are you spending your season days?',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'Tz5dEhqgCrSKss1Wqfib',
    label: 'Favorite Music While Working',
    text: 'Cue mini dance party and productivity mode!',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'U14GmfsxgJibUY97rT8z',
    label: 'Fatherly Advice',
    text: 'Comment below some wisdom your dad or a fatherly figure has bestowed upon you!',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'U3iUxMqKaip1LVZJTaHv',
    label: 'Busting A Myth',
    text:
      "How many times have you heard that you'll never reach your fitness goals without hours dedicated to a serious workout regime?",
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'U56aHuJYpdMiPQD95HBl',
    label: 'Punctuality Poll',
    text: 'Time check!',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'U7DY9wrdSWTQryH9AO9s',
    label: 'How Your Personality Complements Your Job',
    text: 'Pop quiz!',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'U9S0XVKtIkt4btTfh1Lq',
    label: 'Starting Your Day Right',
    text: 'People "in the know" have a lot to say, don\'t they?',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'U9gOGjNi4APQbGD0lMex',
    label: "What Kind of Flower You'd Be",
    text: "Let's play a game.",
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'UCWLy7qQVsNZSiChkSBN',
    label: 'Family Genealogy',
    text: "Are you interested in your family's genealogy?",
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'UDC24xTPkHkfuOUkNjuG',
    label: 'Podcast Preference',
    text: 'How about you, friend?',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'UI0QYoaXb7wKkiqBFT3E',
    label: 'Something That Brings You Happiness',
    text:
      "Of course, things like the birth of my son come right to mind, but there are certain views I'm lucky enough to see nearly every day of my life.",
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'UKOirITVOnHejaJnZb0A',
    label: 'Releasing Something New',
    text:
      "It's jam-packed with , , and actionable advice you can use right away to lose 10 pounds in just 3 months.",
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'UMNk9cnPyInOQQRdG1Rx',
    label: '3 Fun Facts',
    text:
      "If you've been following me here for a while, I'm sure there's a ton about my life you already know well.",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'UMUYNTh2nwgeoHSZOaCs',
    label: 'How You Save Clients/Customers Time/Money',
    text: 'Fill out the questionnaire on my website, and get started today!',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'UNSgpELkC9I0xFwmH9TP',
    label: 'Where You Started',
    text: 'When I first began my industry business, I used to embarrassing fact.',
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'UNzJ3WDvfYI2DgNgg7Ir',
    label: 'What Sets You Apart',
    text:
      'It can be so hectic sometimes that even things like washing the dishes end up going by the wayside for longer than I ought to admit!',
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'URpcVCK7ogVG5t0TdnPY',
    label: 'Overcoming a Common Struggle',
    text: 'Drop a "100" emoji in the comments below if this helped you!',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'UWEf2ksIckO5xZHwyYI1',
    label: 'Weekend Work',
    text: "Instead, I'm putting on the finishing touches of my new course.",
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'UYj9GwhOZuu2CkNZVXjN',
    label: 'A Sweet Fact',
    text: 'What questions do you have for me today?',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'UbMWoeX7seTFfn8QUOsG',
    label: 'Best Part of Your Job',
    text:
      'Often times, people assume that the best part about being a your profession must be or working with high-profile clients.',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'UdhjYhXewciIFTjGcLIZ',
    label: 'Living Your Dream',
    text: "Ahhhhhh, I've always loved the freeing feeling of wind in my hair on the open road.",
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'Ui0Si0neWV8EEn9OIZmI',
    label: 'Describing Your Specialty',
    text: 'Hello!',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'UkyZu8hrYvMOqiL2JYrd',
    label: 'Sharing A Tutorial',
    text: "Not to sound creepy, but I've been watching you.",
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'Up78t5EutpfgHRPR5TVZ',
    label: '3 Things to Take on a Deserted Island',
    text: "Today is especially chilly, isn't it?",
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'UpUKNe1AA0J3dqLi8pOk',
    label: 'Pessimist or Optimist',
    text: "In fact, the best part about me is that I'm ME… not anyone else.",
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'UqNIs0nXm248qMdH023s',
    label: 'Something You Remind Yourself',
    text:
      "Whenever I forget that I'm doing the best I can, I remind myself to be gentle with myself.",
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'UsBX5Hms8333BYhNY1Kw',
    label: 'Big Reveal',
    text: 'Share a new product.',
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'Uu5eHTUvhjodzGgSq14N',
    label: 'Your Weekend Plans',
    text: "It'll be a weekend full of romance, nature, and copious laughter!",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'UvMtOsuFnOxBcNeqBnSr',
    label: 'How Tired Are You?',
    text:
      "I'd rate myself at a number between 1 & 10 because I only applied mascara to one eye this morning.",
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'UvsQuYQHXUnlEiadQ4gb',
    label: 'Good Idea Gone Bad',
    text: 'Have you ever had a great idea that turned out to be the worst idea EVER?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'UwFiG8HiTWIepSm3XgCc',
    label: 'Welcome To My Account',
    text: 'What brings you to my corner of the internet, friend?',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'UyPs9GSz2DFeeEaRSv5l',
    label: "Today's Agenda",
    text: "What's on the agenda for today?",
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'V5sMgHIizbKtsrYK6ftH',
    label: 'Things to Know About Your Business',
    text: 'Ask away via DM or the comments below!',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'VE4eR72dJTn0q9cxSK6p',
    label: 'How Your Mission Fuels Your Work',
    text: "I'll admit it: I'm guilty as charged.",
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'VFgoaGMjF5kSRVpPQ5dV',
    label: 'Surprising Benefit of Your Product/Service',
    text: 'Can you keep a secret?',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'VLst6RYiKITiAOb3YELl',
    label: 'The Feeling Your Followers Give You',
    text: "can you relate to the feeling that I've described?",
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'VOXTgczn9qrVXd9MhnXr',
    label: ' Your Entrepreneurial Path',
    text: 'When did you know you were on the right path?',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'VQ8b7q0YhAGOeeqhKjs6',
    label: 'Passing the Positivity',
    text: 'I just won a giveaway for the first time in my life!',
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'VSILnxTAM6Ed66TtbDxz',
    label: "How You're Welcoming Current Season",
    text: "I'm starting the new season how you're starting the season.",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'VUloamFj3qqnmONgAOsS',
    label: 'How You Relax',
    text: "It's the best way to unwind before I get back up and do what I love again the next day!",
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'VVsCIN1FLt4LIF8b4z0w',
    label: "What You're Working On",
    text: 'What are you working on today?',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'VeH9OSaMMI9JAaGoZ47x',
    label: 'Introduction Post',
    text: "Let me know who you are in the comments below… I'd love to say hello!",
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'VhBjiHIID9AHVEoBIqXq',
    label: 'Doing A Good Deed',
    text: 'Comment below if you want to be a recipient!',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'Vihl7u9HgD5jaPodC3R2',
    label: 'An Upcoming Event',
    text: 'Flood my comments!',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'VjAZoaQiIj8H3SAib6LL',
    label: 'Introducing Yourself to New and Old Followers',
    text: 'Now who are YOU?',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'VqDor9aaAD1QN5Qmo3ST',
    label: "How You'll Spend This Season",
    text: 'Happy current season, everybody!',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'VwGSSVUE34kjhsE2ERst',
    label: 'A Letter To Your Younger Self',
    text:
      "Dear 10-year-old your name,It's me, current age-year-old your name, writing to tell you some things I want you to know.",
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'W2LUNWzhEAR04damz3VW',
    label: 'A Negative You Turned Positive',
    text: 'When I was little, I was a know-it-all.',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'W6V49lF7rkNigIIP3jVg',
    label: "What You Can Take Off Your Client's/Customer's Plate",
    text: 'What questions do you have before turning a day like this into your personal paradise?',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'WDzf7AcMmjq4jDQCncCv',
    label: 'How Your Dreams Came True',
    text: "What's your mantra?",
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'WFwwJxT700ouDY9jhrfz',
    label: 'Weekend Plans in 3 Words',
    text: 'Tell me your weekend plans—in just three words!',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'WGjlBd4hQvcqIJP5Xudd',
    label: 'Impressive Benefit of Your Product/Service',
    text: '"Something this good should be illegal.',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'WH8hl6cNQ1udyv4qkW6z',
    label: 'How Your Values Benefit Your Clients/Customers',
    text:
      "You probably know I'm a coffee roaster, and maybe you've wondered whether a monthly subscription is right for you.",
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'WJdK7jKxeudSmN2IPg8G',
    label: 'Encouraging Advice',
    text: 'These are the words that keep me going!',
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'WOoqPE678vJ6JNHwiOIj',
    label: 'Releasing Something New',
    text: "I can't wait to hear what you think!",
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'WUhb6H6s5xpBaA2ntF6H',
    label: 'Your Working Tradition',
    text:
      'One of my faves is a thorough evaluation of how my branding strategies can improve to better serve my clients.',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'WVatuus3kkbUhplACnad',
    label: 'Being in the "Now"',
    text: "This is a note to myself as much as it's a note to you.",
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'WYdPFlWoJrc6F2ttWa7d',
    label: 'A Hot Deal',
    text: 'ATTENTION all lovers of beautifully-branded websites!',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'WblqDgYPpglvdPocHKTq',
    label: 'A Juicy Project/Update',
    text: 'Head to my blog now for all the details!',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'WgF4c4FKMxaVSVHzwMhy',
    label: 'Where You Want To Be Right Now',
    text: "They say the source of life's unhappiness is wanting to be somewhere you're not.",
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'WgPdrVyKVgvQyGrjuild',
    label: 'A Celebratory Announcement',
    text: "That's right!",
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'WgT0IW7aOz5BR2bS1rXN',
    label: 'Sharing a Testimonial',
    text: 'Who makes your job worthwhile?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'Wn2oyDDL6PjoDbTQzeGk',
    label: 'What Makes Your Business Unique',
    text: 'Guess what, I CAN!',
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'WoHpGDN3PwkgY2eOW8Ow',
    label: 'The Importance of What You Do',
    text: 'Welcome to Health 101.',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'Wp9Db7OXuucW3HsIVqbr',
    label: 'The Problem You Solve',
    text: 'Invest in yourself via the link in my bio!',
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'WrGEPguh5uyoh7m6eqcl',
    label: 'Friend/Team Member Shout Out',
    text: 'Who are you loving to do life with today and what makes you a great team?',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'WvxsDmCBHJtORtWTOnuY',
    label: 'What You Pride Yourself On',
    text: "It's no real secret that I what you do for a living.",
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'Wxo3u1q7tr4SFzQvQoBt',
    label: 'The State of Your Workspace',
    text: 'How is your workspace looking?',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'X0LtjxrbAGth1Nm3fWdO',
    label: 'First Memory of Your Favorite Season',
    text: 'Entertain me with your season memories!',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'X0iH7k71v9xAmB5ne86t',
    label: ' Providing A Helpful Resource',
    text: 'See how easy that was?',
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'X4Qd3lZE4tf41XGwTf2w',
    label: 'Encouragement for Your Ideal Client/Dream Customer',
    text: "Tag a fellow another way you'd refer to your ideal client that needs to hear this!",
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'X5UAkvhG5FHXOEUCOrZ9',
    label: 'How Your Product/Service Relaxes Your Customers',
    text:
      "With our team of professionals you'll be aaaaalmost as relaxed when it comes to planning your dream wedding.",
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'X6UkYq1hBuQ2rek0VLHO',
    label: 'How You Stay Ready',
    text: "Ever hear the phrase: if you stay ready, you don't have to get ready?",
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'XAI8g25ZHSlTXks6lkyA',
    label: 'My Recipe For Success',
    text:
      "Full disclosure: I haven't quite perfected it, but if you'd seen me when I started styling my friends in acid wash jeans and Hypercolor T-shirts, you'd agree that I've come a long way!",
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'XE95qS6Nexqs9Qe53ENK',
    label: 'Favorite Childhood Memory',
    text:
      "Since then, I've how that memory has impacted, and I'll never forget why that memory is unforgettable.",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'XF8LY1OeXF6CCwUJwrZv',
    label: 'What Makes You Special',
    text: 'I want to hear from ya!',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'XIEK1FbqO3fYbkkbUKm6',
    label: 'Your Perfect Holiday',
    text:
      'Mine goes like this: describe your perfect holiday I set no alarms and wake to the smell of roux wafting up from the kitchen whenever my wife starts the meal.',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'XMrNwgv4jN1kT49xc4Us',
    label: 'Better Serving Your Clients/Customers',
    text: "Calling all term or phrase you'd use to describe your ideal client!",
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'XOkgqE3mYxogOci4fxch',
    label: 'What Are You Working Toward',
    text:
      'Now I prefer real love to fairy tales and I know I hold the power to work until my wishes become my reality.',
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'XPEohHm0YVaPisXaKzYF',
    label: "How You're Resting Today",
    text: 'Are you ready for some incredible news?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'XPRYxZNbtKHxqpMqgVNy',
    label: 'Starting Without Feeling Ready',
    text: 'Would you feel ready if you had five extra hours each day?',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'XaL5MUsd4ZoGxYsALfZN',
    label: 'Compliments Galore',
    text: 'Guess what?',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'XkkjNLm5xZF9vrkef4fI',
    label: 'Currently Listening To...',
    text: "I'll tell you exactly what I'm listening to, and then you tell me!",
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'Xn9lMf1HtbTUuT8fYb17',
    label: 'Your Favorite Donut',
    text: 'I typically try to resist temptation, but a simple glazed cruller gets me every time!',
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'Xoq6eS6uhFAj9XLUMrvX',
    label: 'Making a Confession',
    text:
      'Confession: On days like this one, I find it hard to stay focused inside my office while the sun is shining outside!',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'Xp0Gktz4szW3RnzVCLY7',
    label: 'Pushing Worries to The Side',
    text: 'Leave a comment below or send me a DM so we can chat!',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'Xq35zBgBxHru2NbF9laW',
    label: 'One Day Sale',
    text: 'Click the link in our bio!',
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'XsMRh9A631wUkfaEi6h7',
    label: 'Something You Survived In The Past',
    text:
      "If I ever doubt how strong I am, I just need to remind myself that last month, I survived choking down my proud husband's bone-dry turkey.",
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'XsOY13198oAQX9XMtOnV',
    label: 'How You Give Gifts',
    text: 'How do you give gifts?',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'XtA3oJwmzXnmCtRAqcJL',
    label: 'Your Work-Life Balance',
    text:
      'If you were to see ONE snapshot that perfectly describes my work-life balance, it would be this: my husband making dinner while I hammer away on my laptop.',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'XtDunTulMmVHjUWNj9pU',
    label: 'Questions For Your Customer',
    text: 'Comment below so we can keep the conversation going!',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'XvaqT3nZYyErdH1IVnBW',
    label: 'One Area To Improve',
    text: 'This week, I vow to start eating healthier.',
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'XyVu6rCq2dypZLTPoiKl',
    label: 'Invitation to Connect',
    text: "Can't wait to hear from you!",
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'XzlMnI8wK2hBaAIA4Y21',
    label: 'A Sweet Fact',
    text: 'What questions do you have for me today?',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'Y6c7LfuYneKQOavfkZ7y',
    label: 'Your Own Masterpiece',
    text: 'What is your work of art?',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'Y8My5id9q1kyYvOJ3Gwq',
    label: 'Thoughts on The Current Season',
    text: 'When you come around, I welcome you with open arms!',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'YA4EDDuz8yQen81q1ZXg',
    label: 'Simple Joys',
    text: 'Story time!',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'YAjBfWKuphRcspkD3IU2',
    label: 'How You Can Help',
    text:
      "Let me know the best (rose) and worst (thorn) things about your hunt for a wedding photographer, and I'll let YOU know how I can help!",
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'YDuS8Wzf7kU88MZ9rzKo',
    label: 'Your Favorite Aspect of Your Job',
    text: 'Thank you for being such a huge part of the reason I get to be exactly who I am.',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'YEkAwOB9ArYy6VqWYWbz',
    label: 'Your Business MVP',
    text: 'Anybody else with me?',
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'YGBfmQZbgUophLb5XzZt',
    label: 'You and Your Cell Phone',
    text: 'This might sound crazy, but I am completely lost without you.',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'YGiXTvWS6ctrLTSTre9x',
    label: 'The Most Rewarding Part of Your Job',
    text:
      "There's nothing as rewarding as seeing a client meet her weight loss goal, and I can honestly say every time it happens, I'm bursting with pride.",
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'YMG3jc1jb7WSnu6O0DkT',
    label: 'How You Spend Sunday Morning',
    text: 'What are you up to today?',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'YMMspzsY1zdIOev3ZGnD',
    label: "What You've Been Working On",
    text: "Any guesses on what I've been busy working on lately?",
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'YQBAwey2q2odU9hUjfAT',
    label: 'Something to Celebrate',
    text: 'The hills are alive with the sound of celebrating!',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'Yh3HN6HIu94XLr1SGF5X',
    label: 'Weekend Plans',
    text:
      'Just like a cold glass of lemonade hits the spot on a hot summer day, your favorite activity is the perfect way to spend the weekend.',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'Yk9zaLD7wIiPxZ8eTDhF',
    label: 'Your Favorite Seasonal Recipe',
    text: 'Have you got a favorite season recipe?',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'YmRr6XHSur8Q3XobWtlP',
    label: 'Feature Bonuses or Perks',
    text: 'Extra!',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'YnyagcIQ2BPKGXYjrT4K',
    label: 'How You Serve Your Audience',
    text: 'Oh, hello there!',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'YoKcOc2LUrzVQ7ENsEU0',
    label: "When You Didn't Feel Ready",
    text: 'When I first started my business, I was feeling anxious.',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'YoO7iA4uwYhKyIfVIK2x',
    label: 'Cutting Out the Bad',
    text: '): Share an encouraging message or quote.',
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'Ys1OeOaewU2TVcXeUjvD',
    label: 'Having A Rough Time',
    text:
      'And in case you need a little extra boost today, consider this post your virtual fist bump or pat on the back.',
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'YwtKvuQvPxkogmUjyUXR',
    label: 'Caption 2',
    text: "I've been privately instructing yoga ever since completing 200 hours of training.",
    type: 'hook',
    category: 'holiday'
  },
  {
    _id: 'Ywzzh1DpgYTs8eqDo9D9',
    label: 'New Feature This Month',
    text: "Isn't current month just the coziest month?",
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'YyzMaky3EYRcYak0doio',
    label: 'Inspiration for A Tough Day',
    text: 'It happens!',
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'Z0Ww9Tj1PylsTxARzxEX',
    label: 'Note to Self',
    text: 'Note to self: Taking a 5-minute break every hour is ALWAYS a good idea.',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'Z59uUXbRJt2CxFTe74um',
    label: "Something Fun You're Doing",
    text: "It's day of the week!",
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'Z7CU1gfqRsJRRmtyjydf',
    label: 'Your Workday Routine',
    text: 'Comment below with your workday schedule!',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'Z82xmOHR7Y9yALRQaFhp',
    label: "What You're Working On",
    text:
      "Today, it's the thing that is getting me through the complete website redesign I'm working on!",
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'Z8k71FvSn1ILzSbNoPm3',
    label: "How You've Grown",
    text: "tell me how you've grown (personally or professionally) recently!",
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'ZABFWOPAVQMrU9qyyxDS',
    label: 'Sharing Some BIG News',
    text:
      "Come on in because I have some BIG news to share, and all of the important details are listed below so you won't miss a thing!",
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'ZAREVFPalY3rpiYJB1GN',
    label: 'Releasing Something New',
    text:
      "Have a seat, because I can't wait to share with you that I just finished working on my new ebook!",
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'ZEDe7DOlHQfcUCRfbCah',
    label: 'When You Work Best',
    text: "When's your perfect hour of the day (or night), and why do you love it?",
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'ZEPJ93VGvneVuKML2ceh',
    label: 'Excitement This Season',
    text: 'What are you excited for this season?',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'ZHB2zdrZDyt45UBlPMXQ',
    label: "What You're Dreaming Of",
    text: "As an entrepreneur, it's in my DNA to dream big.",
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'ZJWX46X223X8MZ2BxyrM',
    label: 'Announce Cart Close/Deadline',
    text: 'You might think I do a lot of thanking.',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'ZMxQcu4C0cdgWFRH4Kq0',
    label: 'Color Game',
    text: 'Did you know: Some people can HEAR colors?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'ZPOmelic9yS1tjAwSoR2',
    label: 'How You Recharge',
    text: 'How do you relax and recharge?',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'ZPwLnrKXaj7dwFXFYyMz',
    label: 'Where You Go for Unusual Inspiration ',
    text:
      "It's incredible to spend an afternoon at a modern museum and see how it affects my work.",
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'ZVswQ687cewHQrhc250r',
    label: 'How You Break The Rules',
    text: 'On a scale of 1-10, how much do you like to break the rules?',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'ZXLkK4W9y8EV0c1xd2to',
    label: 'Our Biggest Value',
    text:
      "We pride ourselves in providing the kind of one-on-one service that leave each and every one of our clients feeling as if they've hit the jackpot.",
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'ZXQRms1lk2Zec9vBbb2A',
    label: 'Taking a Break to Refocus',
    text: 'How do you refocus when you need a little break?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'ZZJMTcn5M2Hj0x0toSp1',
    label: 'Sharing About Your Business',
    text: 'Having a breakfast meeting with colleagues is my JAM.',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'Zb5cbsdaVFWiiAxvaNQI',
    label: 'Your Favorite Use of Social Media',
    text: "What's your absolute favorite use of social media?",
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'ZdcKeqY9d18vqSIxAIca',
    label: 'Friendly Introduction',
    text:
      "I'd love to know more about YOU, so let me know where you're from in the comments below so we can get acquainted!",
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'ZfJhYBVakAAoo7G5na7Q',
    label: 'How Loved Ones Describe You',
    text:
      "My wife would say I'm , , and silly, big-hearted, and fun, and I have to say I don't disagree.",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'ZiJIhjpFgUXY75CtzW5e',
    label: 'Lemonade Stand',
    text: 'Did you ever have a lemonade stand growing up?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'ZkJ0lGscn9xPwKm8IAEa',
    label: 'Books You Read',
    text: 'Tell me: What do you read?',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'ZnlcHmw6BmHiOGozlQ3R',
    label: 'Words of Encouragement',
    text: "Here's some food for thought for you: Share an encouraging quote.",
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'ZxPdsYPNyJgYabiONKZN',
    label: 'Your Favorite Aspect of the Holiday Season',
    text: "What's not to love about a Thursday focused on gratitude?",
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'ZxfDr68AhbUf3rhIU53h',
    label: "It's OK to Make Mistakes",
    text: "But I will also say this: I've learned many lessons along the way.",
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'a0ZXPIq75e3U7ML70llI',
    label: 'Getting A Closer Look',
    text: 'My website will give you a closer look at every gorgeous bauble I offer.',
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'a2wplNaz0oXyQ5wZmo32',
    label: 'Your Childhood Telephone',
    text: "Hello there, it's your name!",
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'a3w1PZrBggAaDpsBHsJp',
    label: 'Check Out Something New',
    text: 'Ready, set, GO check it out!',
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'a86AevD4XaWpe0DAc7yz',
    label: 'Unexpected Perks In Your Job',
    text: 'Can I make a confession?',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'a8L46n5PoFdm4o32gb2Q',
    label: 'Thanking Your Followers',
    text: 'More than you could possibly realize, you help me to stop taking myself so seriously.',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'a8MUyHnG9aIvLz0tigiQ',
    label: 'Celebrating With An Adult Beverage',
    text: 'Whoever says you need a holiday to celebrate something has it all wrong.',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'aJGaTkb0RT09h8grfFaZ',
    label: 'Looking Ahead And Being Present',
    text: 'What are you most looking forward to being fully present for?',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'aKsCZuQ2PjjIy7W5MRMt',
    label: 'Honesty Hour',
    text: 'Feels kinda good to get that out, actually.',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'aOVkL8NwmTrSzhMHx5e1',
    label: 'Doing What You Love',
    text: 'Think of the thing you love more than anything in the world.',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'aOYuY8nRjm45HmhFIjkr',
    label: 'Your Favorite Piece of Technology',
    text: "What's your favorite piece of technology?",
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'aOdp01ZVnlq6wENS44V2',
    label: 'The Best Part of Your Business',
    text:
      'But I have to say, while all of that IS amazing, my very favorite thing about my job is hearing directly from buyers of my designs how a dress or a top I created made them feel truly beautiful.',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'aY2bUb1zqNrdT0pa0ody',
    label: 'Welcome New Followers',
    text: 'Tell me a little bit about yourself below.',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'aYqEAaObveDmOslD3vjw',
    label: 'Dream Vacation',
    text: "Drop an emoji hinting at your dream vacay and let's see if I can guess the location!",
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'acY33JNdNfHnUlNNV0VG',
    label: 'The Feeling You Sell',
    text: "How's your March looking?",
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'agdzBazSiDhkmwLz2i2P',
    label: 'Shifting Your Point of View',
    text: 'Do you remember the last time you felt really stuck?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'aodezqbD3Ff2FU9sTavt',
    label: 'Exciting News',
    text: 'Yes, YOU!',
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'apJ4qE27PwT0HgwFfPT0',
    label: 'Client Testimonial',
    text: 'If you or someone you know needs what you do, you know where to find me.',
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'aujMiq26s7JNTol6rjkD',
    label: 'Weird Traditions',
    text: 'share your weird tradition or lack of.',
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'axPrEChswJkoKCyiFddx',
    label: 'Dreaming Big',
    text: 'The more you see, the more you know.',
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'axns3KzxuNjPcT45wZqw',
    label: 'Random Fact',
    text:
      "I've been designing interiors since I finished my degree and turned my college internship into my first real job, BUT I've had a heart for beautification since childhood, rearranging my bedroom at least once a week to reflect my latest whims.",
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'b1kg01DZPIVwbLdybcuC',
    label: 'Invitation To Learn More',
    text:
      "I've been in the your industry business for length of time, and have always prided myself on responding to client requests in order to continue to better meet their needs.",
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'b2gSoT11BtjxMHXCvwdp',
    label: 'Quote That Helps You',
    text: "Whenever I'm feeling lost, I remind myself: Motivational quote.",
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'b6Wc7Fl3Js2xLDdqpLos',
    label: 'Reminiscing On Humble Beginnings',
    text: 'Happy Monday!',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'b87qPxNy8OELJl8K8o4f',
    label: 'Step-By-Step Process',
    text: 'Guess what?',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'bCE2phhxt088teDvfzGG',
    label: '48-Hour Reminder',
    text: 'Click the link in my bio ASAP!',
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'bHH10zh9gAV9tRUz1zOl',
    label: "Why You're Passionate",
    text: 'As the season changes, one thing remains the same: my passion for what you do.',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'bJrp5spI9Vi78J1Y1V7b',
    label: 'What Feels Like Home',
    text: "Raise your hand if you've had a crazy month!",
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'bUZmszPs7PUkUy578OvK',
    label: 'Having Fun',
    text: "It's actually when I allow myself the time to relax that I do my best work.",
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'bVcMjZEdCubaKpXUzR7k',
    label: 'Announcing Something New',
    text: 'DM me with questions or join our mailing list at the link in bio!',
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'bVrmR8DD6Q8uOvpNserH',
    label: 'Purpose of Your Product/Service',
    text: "What's the big idea, you ask?",
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'bWB6UexKwuNrJBpkIyg4',
    label: 'Playlist Recommendations',
    text: 'What are you listening to today that I should add to my playlist?',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'bauxL0B3wTuGLkBQ1b9y',
    label: 'Snail Mail',
    text: 'How about you, do you still use snail mail?',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'bfNZENW4GaJ9vBHhml8x',
    label: 'You Being You',
    text: 'What are you thankful for today?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'bhX6Kl0uY7z1THStjdzN',
    label: 'Business Introduction',
    text: 'Hello, new friends (and old)!',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'bifatju0sPQJdEsJ9Pt7',
    label: "What's Going on in Your Business",
    text: 'Whoa, whoa, whoa!',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'bjhR1iVFIFpA4GZ7FSU0',
    label: 'Your Lighthouse Person',
    text: 'If you were stranded at sea, who would come to your rescue?',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'bkRxYsarepiXxOZluP8l',
    label: 'Behind Your Screen',
    text: 'What does life look like behind your screen?',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'blU7lNzSazGClRYTA2x2',
    label: 'Expect The Unexpected',
    text:
      'They say "expect the unexpected," but when I started my career as a job title length of time ago, I would have never guessed I\'d find such a supportive network of women doing the same thing.',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'bvjUHyuYH5ekfBxdjciH',
    label: 'Indulging In Your Work',
    text: 'Comment below if you can relate to BOTH indulgences!',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'c4hoqZg2c0DnRUYEf908',
    label: 'Something to Get Excited About',
    text: "All the info you'll need is on our website, so follow the link in bio!",
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'c5k30Bqak0kTjvU0DZ1s',
    label: 'Your Favorite Treat',
    text: 'Tell me what gets you through the trying days!',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'c5xlKtzY0VcZlimaNuTl',
    label: 'How You Met Your Business Partner/Team',
    text: "Who's had a profound impact on your life, and how did you meet?",
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'c82Qwd3BR5IagqTJvjMz',
    label: 'Thanking Someone',
    text: 'Where do you stand on hat-wearing?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'c8kzeJdux0E0DWnT2xN1',
    label: 'What Makes You Happy',
    text: "What's your fizz?",
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'c9ezeWzyeHPR2tBKaviK',
    label: 'Announcing Something New',
    text: "Once you've checked it out, let me know what you think in the comments below!",
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'cAzoVetcPyrHoO04M3p0',
    label: 'What Make You Feel Creative',
    text: 'Is it easy to fill it up with ideas, or does that take some serious work?',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'cHIzGomZoZlZJiPt8SAx',
    label: 'The Key to Your Morning Routine',
    text:
      "I'm taking a poll: What's the one thing about your morning routine you couldn't possibly live without?",
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'cKQiiA6MwxN1tMmDkRJP',
    label: 'What Makes Your Business Special',
    text: 'With that, the search may as well be over!',
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'cN9CRdGDnqMPid2XiZ7U',
    label: 'Telling Your Target Client About Your Specialty',
    text:
      "If you'd like , , and three benefits you offer to your clients, then you and I are a match made in heaven!",
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'cO4zpudC4alptwdyGQzH',
    label: 'This or That: Facts About You',
    text: 'Enough about me, friends: tell me a few fun facts about you in the comments below!',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'cQ6oThT2sNai9cqscrI9',
    label: "Things You've Learned About Yourself",
    text: "What's one thing you're thankful to have learned about yourself recently?",
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'cQSUr7BJmrHA4TmaShTK',
    label: 'Unglamorous vs. Glamorous Tasks In Your Business',
    text: 'without actually telling me you love what you do.',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'cR8Mqtq51vh7n7ILq6rh',
    label: 'What You Want More Of',
    text:
      "There is plenty in my life that I'm satisfied with, but there's always more I can strive for.",
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'cRtHRanrUCLQmCICYZ7w',
    label: 'What Fuels Your Fire',
    text:
      "If I could pick only three things I love about your profession, I'd pick , , and 3 things you love about what you do.",
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'cUNCwZNkQalTiWeKUcUm',
    label: 'What 3 Words Describe You',
    text: 'I bet you could get my best friends to talk about me… endlessly.',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'cVFIFjIxtwueA3ye3URb',
    label: 'Preferred Workplaces',
    text:
      'When I\'m not at the "office," AKA my dining room table, chances are, you\'ll find me where the coffee is hot and the music is smooth.',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'cZkjGKyT7iPgsiwveWvP',
    label: "Keepin' It Cool",
    text: 'Double tap if you can relate!',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'cbF5ig6tKFlZg8cThhCa',
    label: 'How You Start Your Week',
    text:
      "I love moving my body before I dive into my to-dos, and I've been doing it so long that it's become a weekly tradition!",
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'cbVtS8Blbz4BQEuembCP',
    label: 'Growing Pains',
    text: "We're at our best when we're growing, but that growth often comes with growing pains.",
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'cc9dJydCDFbc7Qkuwso3',
    label: 'Extra Dose of Motivation',
    text: 'And the internet seems chock-full of clever little quotes about it.',
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'ccYpOeSijZR6fClVeQcL',
    label: 'Step Up To The Mic And Introduce Yourself',
    text: "Hi, I'm name!",
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'cdOiQowsI1FD2BBgaUPZ',
    label: 'Cheering Each Other On',
    text: "For now, I'm dreaming big and resolving to make a vision board rather than set goals.",
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'cfdfAy2e0wWnyJ1QQ799',
    label: 'Going Live: Mark Your Calendar',
    text: 'Attention: your target market!',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'cjEoey75jXsKGo9bJMDJ',
    label: 'Secrets for Success',
    text: "Do you have any other secrets for success that you'd like to share?",
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'co58OOQddV6a4ykmR7VB',
    label: 'What You Sacrificed to Pursue Your Dreams',
    text: 'I pushed through my doubts, all in the name of pursuing what you do.',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'cqb1pZxAB8IXPGVjDHBX',
    label: 'Giving A Promotion',
    text: 'Can I give you something?',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'cs9VCyo4DqUCEtW40kt8',
    label: 'Favorite Thing About the Current Season',
    text: 'Does current season tend to make anyone other than me absurdly reflective?',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'dEqXSxrGzvsRbwCIDArA',
    label: 'Being Thankful for What You Have',
    text: 'Your turn!',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'dFMlD9K1tfK6L3IjiZyS',
    label: 'Answering FAQs',
    text:
      "I'm going LIVE on insert date and time on Instagram to answer everything you want to know!",
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'dGXWkDETIzbS2XBNC5Ih',
    label: 'Business-Related Poem',
    text: 'To celebrate the launch of my blog, I wrote a poem to make it a little more exciting!',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'dN4UeRqZAqJqHfW9oFwf',
    label: "Something You're Working Hard On",
    text:
      "You know how you basically roast all summer, but you're finally able to find relief in the cool change of seasons as autumn settles in?",
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'dN4dfPdIuhBGgugIaQKa',
    label: 'When You Hit A Wall',
    text: "I promise you, friend, I've been there!",
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'dQUvjfXr5t8QupaTRqRy',
    label: "Your Customer's Lucky Day",
    text: 'Guess what?',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'dVRZPZM3RRkv0275DBfC',
    label: 'New Beginnings',
    text: 'Can you relate to this?',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'dZk6OI5GNo9FCQQ7RiWI',
    label: 'A Simple Reminder',
    text: 'I hope this encourages you to the action you want them to take!',
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'daAdIE6fWwTXsXt0uccj',
    label: 'Slowing Down During a Busy Season',
    text:
      "When I'm feeling incredibly overwhelmed, I like to do the following to help me slooooooow down:1.",
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'dffJnm14ENptuhzlSxPq',
    label: 'Your Dream Vacation',
    text: "What's your dream vacation?",
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'dg3Cj60O3hguELZ2VZQY',
    label: 'Getting to Know Your Clients/Customers',
    text: "What's the food you never tire of eating; 2.",
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'dlUvDNSdCz9dOngOrEtv',
    label: 'What Got You Here',
    text: 'What are you willing to risk?',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'dpW7PNzDWrNtu9VKGyW3',
    label: "Something You're Insecure About",
    text: 'Regardless of what it is, know this friend: you are enough!',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'dsQQuIQDfxlAc9QNUyhG',
    label: 'Releasing Something New',
    text: "I can't wait to hear what you think!",
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'dzsZbambo5SI4Ven1wp5',
    label: "Where You're Working",
    text: 'Quick poll: If you could work from anywhere in the world, where would your office be?',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'eCJJVLa5cL9ztpmgiWVp',
    label: 'Laughter Is The Best Medicine',
    text:
      'Some people say laughter is the best medicine (probably not actual doctors, but SOME people), and every one of us has something small we could smile about.',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'eCWl8e3GX7IPsXobpZ4P',
    label: 'Working from Home',
    text: "Why do (or don't) you prefer working from home?",
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'eES5WRSe1HYnjTRR8o9y',
    label: ' Resolutions Check-In',
    text: 'What were your resolutions, and are you sticking to them?',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'eInGKnL2jRRbMZAD5yGs',
    label: 'Giving A Little Boost',
    text: "Comment below what you're doing today, then go and DO it!",
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'eLfsXQx1aTQgIs2QOQ7s',
    label: 'Deals Your Customers Love',
    text: "What's even better than the icing on your cupcake?",
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'eN1tWIUatbkhxl9OJ1rj',
    label: 'Your Little Secret',
    text: "What's your secret?",
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'eQbTYZ95eeYGRt7kf2yD',
    label: 'Releasing Something New',
    text: "I just finished working on my new ebook and I'm excited to share it with you.",
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'eSYM8vU3cfDAXljjyDQq',
    label: "Something You're Grateful For",
    text: "Something about Fridays makes me reflect on all that I'm thankful for.",
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'eTVxNlnIOZZZFGygG4bT',
    label: 'What Makes You Different',
    text: "Let's connect!",
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'eb64nSrfLsNuh9GDyitL',
    label: 'Answering A Frequently Asked Question',
    text: 'Hey, you….',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'edOYWtC8otxdaJS9x1k8',
    label: 'What You Offer',
    text: 'Are you window shopping for the hottest fashion trends for the lowest prices?',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'egIOdoqbqFSU2ZlGEaCg',
    label: "What You're About to Celebrate",
    text:
      "With that in mind, I'm planning to how you're celebrating, but for now, I'm wondering… If you were me, how would you celebrate?",
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'egUCXqjp3Y63oF7B8YX0',
    label: 'Announcing Something New',
    text: "I'd love to chat more about it, so please, ask me anything in the comments below!",
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'enlC2CS9yKIyC0NWXY54',
    label: 'Introduction',
    text: 'Konichiwa!',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'ephTfYxZb5CpW44VCv23',
    label: 'Deserted Island Game',
    text:
      "I would definitely consider , , and chicken, waffles, and syrup, but I don't know if I'd survive without beans, rice, and hot sauce to accompany them!",
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'et86UZGoj3gJnlhQwkA2',
    label: 'Something Different You Offer',
    text:
      "I'm hardly the only job title out there, but I do believe I have something different to offer than anyone else who claims to do what I do.",
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'etuBf4DzIrOT7xOeRLXS',
    label: 'Sharing A Kind Word',
    text: "Has someone told you today you're the bee's knees?",
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'euox4xCDhCLN2PwU02s2',
    label: 'What You Love About Yourself',
    text:
      "Self-love isn't always the easiest, but sometimes, it's important to look at yourself in the mirror and just be thankful for one thing you really love about yourself.",
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'evomianq7BvpQ2kVThWk',
    label: 'How You Reward Yourself',
    text:
      'Sometimes it feels like stacking pieces and pieces of wood on top of each other, until it becomes a huge pile of emotion.',
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'f1CCDrHnWlLm8SK3d1mb',
    label: 'Your Version of Self-Care',
    text: "Self-care looks a bit different for everyone, wouldn't you agree?",
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'f1Yq4LUOGAti9g0htPuX',
    label: 'How You Recenter Yourself',
    text: "Here's what they don't tell you about being an entrepreneur.",
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'f2c36pSfFW8kOsDWiBAt',
    label: 'How You Got Started On Your Path',
    text: 'Are we on the same path?',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'f9JbCmF2Y875H5XuE9sB',
    label: 'Peace In Tough Times',
    text: 'Anyone feeling the weight of the world today?',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'fDXTFMkMY7LNyd9ZLAU0',
    label: 'The Perfect Recommendation',
    text: "Let's talk about perfection.",
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'fErRi32QzxWhuUNsEP86',
    label: "Where You're Headed",
    text:
      "This month, I'll find myself going from home to location via crowded airport, but it'll all be worth it when I see my parents.",
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'fGceSS7ax74hXJG0rwWl',
    label: 'Mistakes Lead To Greatness',
    text: 'Ask me anything!',
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'fIzBqHNysZLFHXGXmTpr',
    label: 'A Love Letter to Your Phone',
    text: "Who's the love of your life?",
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'fJaBLdkqtot4UN5jmzys',
    label: 'Your Best Self',
    text: 'What does your life look like when you feel like you can truly soar?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'fTSxTMAZ2RwstiUIKOO4',
    label: 'Favorite Person Shout-Out',
    text: 'Where am I?',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'fTtLkYeACPTL26xcklGR',
    label: 'Introducing the Team',
    text: 'Now tell us about YOU in the comments below!',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'fVK4ovGB8QBvVTdzWMDT',
    label: 'Hundred Dollar Bill Game',
    text: 'Ok, now you!',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'fVslj8s7OJA2PK5ZlgGZ',
    label: 'Your Type of Clean',
    text: 'How about we play a game?',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'fWDsZ8d0gypGZSbj02ag',
    label: "What You'd Get Away From",
    text: 'Spill!',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'fWOunYKKA5YhHPcdPBGd',
    label: 'What Makes You Unique',
    text: "I'll be honest.",
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'fXOW97GTBlYjBiaDajL0',
    label: "What's New For Spring",
    text: "I 've Marie Kondo'd my whole  apartment.",
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'fa09xgfDz0FyN1i0pDw0',
    label: 'Who Supports You',
    text: "They always make me laugh, and I don't know where I'd be without them!",
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'fbS2rgtYFvLcHRP8kbnV',
    label: 'Vacation Announcement',
    text:
      "I'm swapping my and business necessities computer and planner for and vacation necessities flip-flops and sunscreen!",
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'fiLg3xp9rfTABY1CNPBl',
    label: 'A Sweet Fact',
    text: 'Want to hear a SWEET fact?',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'foysZxMw8BbQjrpnilLv',
    label: 'Tough Times Are Temporary',
    text: "I'd be lying if I said that it wasn't hard.",
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'fpmcS7sS6fKhG1z0FRt1',
    label: 'The Love That Keeps You Going',
    text: 'Tell me about the love that keeps your world turning.',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'frPwql4YR6vrqvCsuriK',
    label: 'Your Most Used Emoji',
    text: 'It just works for every situation!',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'fruHoRXIXLhrwhRlGnXi',
    label: 'What Your Brew Says About You',
    text: 'Tell me, friend, how do you take your favorite beverage?',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'fw5r5CLIqoqebtIsli9T',
    label: 'Cheers to Your Biggest Supporter',
    text: 'Who are you raising a glass to today?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'fw9lS3agyjpXbX2AJauw',
    label: 'Introducing the Team',
    text: 'Hey, hi, hello!',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'g9wdxladCw9DwKQCoNAV',
    label: 'Moment of Gratitude',
    text: 'I need to express thanks to @mentor for going out of their way to help my business.',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'gAhb0CLnpGZWuadKm2o4',
    label: "Something You're Insecure About",
    text:
      'I have struggled to show myself the same grace I show others when I make mistakes, but am working every day to turn things around.',
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'gCrtADGEscjzHt1C63xC',
    label: 'Using Your Phone',
    text: "Okay, I'll admit it: I'm a hardcore TikTok scroller.",
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'gGr8Fm4797frRZb3Q6zZ',
    label: 'Your Boundaries in Life and Business',
    text: 'What boundaries serve you in your life?',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'gH9omQq8INifJbRiNnea',
    label: 'A Challenge That Helped You Grow',
    text: 'Can I be brutally honest?',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'gMy8xXjKNUtuWAqIKKSK',
    label: 'An Epic Fail',
    text: 'But you know what?',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'gYLDyti5kQDyCCW7TNyw',
    label: 'Releasing Something New',
    text:
      "It's jam-packed with , , and actionable templates you can use right away to grow your business.",
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'ga3fy3v92R0MhSg1hdds',
    label: "This Month's Promotion",
    text: "You're invited!",
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'gajA7T4G24IDURj3gTV3',
    label: 'Time to Unplug',
    text: 'What helps you feel refreshed?',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'gjq5oawwsIjhqpPAvc3Y',
    label: 'Overcoming Fears',
    text: 'What scares you the most?',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'gkn5Tx2aC2Oqd9dMLkec',
    label: 'Answering FAQs',
    text:
      "But I don't want you to overthink using your company for your product or service, so I thought I'd share the answer to one of my most frequently asked questions to help you break free from your analysis paralysis!",
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'glRnf5DgedvjJQYe0FTe',
    label: 'Fun Facts',
    text: 'Who feels like having a laugh?',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'gla3au1avj4cENh3bFdv',
    label: 'How You Help',
    text: "Comment below, I'd be glad to help!",
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'gmVBUTX8Ow70tbmLQu8t',
    label: '24-Hour Reminder',
    text: "I'll see you on the other side, friend!",
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'goLWpvA6WX4CT7JuRe8m',
    label: 'Person That Made An Impact',
    text: 'What person has made the biggest impact in your life or business?',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'grBg4i9o6WvmvKZtqRQk',
    label: 'What Your Workspace Looks Like ',
    text: 'Home sweet home… Workspace sacred workspace… am I right?',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'gtF1d1W6Oc96tKfjTZCg',
    label: 'Business Responsibilities',
    text: 'In my business, I wear many hats.',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'gvEZL1Q5MgzLnsTcF2F4',
    label: "What You'd Get Away From",
    text:
      "But alas, I can't be relieved from that responsibility so it will have to stay… for now!",
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'gygNi3VjpfFOCq5OAnRt',
    label: "Something You're Looking Forward To",
    text: "You may not know this, but this year, I'm share an upcoming event.",
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'gyp4Ae3dwOxggySQPFfe',
    label: 'Surprising Job Necessity',
    text: 'Do you like surprises?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'h15DL8aLUfZv5KO6M7KH',
    label: 'Caption 2',
    text: "I'm really excited to share this inside look, so check it out!",
    type: 'call-to-action',
    category: 'holiday'
  },
  {
    _id: 'h2yXHPYOxKUkdqcO3udQ',
    label: 'Your Best Business Qualities',
    text: "I'm , , and super efficient with the bookkeeping.",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'h3SeUw94L5n4xr3on6J3',
    label: 'How You Reward Your Hard Work',
    text: 'How about you?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'h6qo3GItqT5iUED3isPe',
    label: 'What You Do for Fun',
    text: "I know, I know, it IS a little weird, but it's one of my favorite things to do!",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'h8EWIGGcJ4m4lClzuWcX',
    label: 'Your Favorite Part About What You Do',
    text: "What do you do, and what's your favorite part about it?",
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'h93BTBZLe9lj4zknjXTN',
    label: 'Behind the Scenes Request',
    text: "Don't we all love to know what goes on behind the scenes?",
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'h9cIlRXyKcsUVlj8akku',
    label: 'Why Someone Should Hire You',
    text:
      "I know you're busy with #allthethings, so I'd love to help you produce professional-sounding audio at a reasonable rate.",
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'hEIGRCX2kRItauPM2Fx1',
    label: "What You've Learned About Yourself",
    text: 'After a much-needed therapy session, I was able to do a bit of self-reflection.',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'hGW2zFyn4nlTjVGvQ9Yl',
    label: 'Behind Your Curtain',
    text: "What's going on behind your curtain (good or bad) today?",
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'hMN0hIvGkhD1PF69BLCM',
    label: 'Meeting New Friends',
    text: 'Nice to "virtually" meet you, new friends!',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'hNPSLh4A4Hd5OQkcQez4',
    label: 'A Picture of Your Why',
    text: 'Do you have a visual representation of your "why"?',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'hSmFVooHdthfmNpmeFe1',
    label: 'Sneak Peek of Your To-Do List',
    text: "Put 'em in the comments!",
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'hacQmeASJpowqW1yJk0q',
    label: 'Your "In Case of Emergency" Person',
    text: "For me, it's @person, my ride-or-die since 9th grade English.",
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'haiojm825ZX3qshslbi5',
    label: 'Feedback On Your Business',
    text:
      "I'm sure I learned this skill through years of waitressing through college, but whether accommodating a picky dinner guest or walking a frustrated client through a tech glitch on one of our website templates, I know that patience and understanding will always get the job done.",
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'hcFyfGWFgOID8WlHnXoC',
    label: 'Where You Came From',
    text:
      'Yes, plants have literal roots, but humans have roots that ground us on our journey through life.',
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'hfi1HB7dLN9RKF3fHa9p',
    label: 'Sharing Good News',
    text: 'I could be a morning person.',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'hiSIwHIRg9pBnuvN3wIH',
    label: 'Your Favorite Book',
    text: "These days, I don't often get through anything longer than a magazine article.",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'hlWq3ZePqeAb0QnNf7RM',
    label: 'Favorite Feature',
    text: 'Do you have a favorite feature?',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'hrTOYBtAtbi0jZ1HFl8Y',
    label: 'Three Words Clients/Customers Use to Describe You',
    text: 'What three words would you use to describe yourself?',
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'hyORjzb3wZanE1DhgYTw',
    label: 'Thank You for Being Here',
    text:
      'I guess you could say I\'m a pro at saying "thank you" – a way you show gratitude regularly.',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'i0gnH6OTD7LFEo8pNvF6',
    label: 'What Makes The Hard Work Worthwhile',
    text: "What's your ideal party?",
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'i2lC7vfJZPSxmrpKipOs',
    label: 'How You Can Help',
    text:
      'It might sound odd when I put it that way, but while my specialty is business logos and website overhaul, I really do it because I love shaping brands for young hustlers to take their businesses to the next level!',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'i3nsYhwX8vsxa2h3j7w5',
    label: 'Shout-Out to Your Business Helper(s)',
    text:
      "Shout-out the person you couldn't run without in the comments below, so it'll read like a \"who's who of total awesomeness\"!",
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'i7pcaPQFk9pddb6xhxRe',
    label: 'A Wacky Holiday Tradition',
    text: "What is the wackiest holiday tradition your holiday wouldn't be complete without?",
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'iEou8JmwF058AvfiYuHm',
    label: 'Up For A Challenge',
    text:
      "Have you ever felt like you're the only one in the world who needs something particularly particular?",
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'iFGSzRSIJxlnRh7r0VMR',
    label: 'Your Specialty',
    text: "Let me know if you'd like more information!",
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'iKa54GoH4cx01OJqu57l',
    label: 'Defining Success',
    text: "When I've got that, I'll have everything I need.",
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'iLvLU3wkTTnhSh0gqNZZ',
    label: "Where You Are vs. Where You'd Rather Be",
    text: 'Share away!',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'iQOWtqxjBUixHVWRhPQp',
    label: 'Celebrating Small Things',
    text: "It's time to celebrate the small things!",
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'icEubZ4csnQEewc0afXq',
    label: 'Epiphany (January 6th)',
    text: 'Are you doing anything special to honor this day?',
    type: 'insight',
    category: 'holiday'
  },
  {
    _id: 'ids8Q5E0bCuh4mXRJSR1',
    label: 'What Helps You Get Through The Day',
    text: "Here's something that helps me: what helps you get through a tough day.",
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'iernL69qGsZjARUdJDL0',
    label: 'Seasonal Saturday Morning Plans',
    text: 'How do you start a perfect season weekend?',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'imMcRSP0KI0RvRCbgWqG',
    label: 'Your Favorite Place to Hide Away',
    text: 'What about you, friend?',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'irY6dvc9SWbajpaPQ2ag',
    label: "What's Comforting About Your Product/Service",
    text:
      "Now you can feel comfortable knowing we'll have plenty of opportunities to make your website EXACTLY what you want!",
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'ivaHOjGxqCSrYkcxu10E',
    label: 'A Calculated Decision',
    text: 'Hands down, the hardest part of my job is hardest part about what you do.',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'j0igCJjQROkJRVmGd82j',
    label: 'Your Inspiration',
    text:
      "I love how simple things like that can inspire me to something you're working on in your business now.",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'j4Hu7GXxHTuZih1SS41f',
    label: 'Steps of Your Process',
    text: 'Ever wonder how I create seasonal nail art?',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'jAwiNyC7sjeCFS10PrAh',
    label: 'Highlight of the Week',
    text: 'Share your highlight in the comments!',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'jC8q77XNMDNQEtM6yeaU',
    label: 'Your Upcoming Plans',
    text:
      "Sure, I would have liked a few extra minutes in bed, but I'm  pumped for today because I've got big plans.",
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'jLG4bofvukoEyEhu7BMU',
    label: 'Favorite Treat',
    text: 'Bonus points if you leave a recipe!',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'jPr3yseAVYWRyCzIh3WD',
    label: 'Going Live: Facing Your Fears',
    text: 'Do you have any words of encouragement to help calm my nerves?',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'jPzo6WT8tUSw0OkIoDfe',
    label: 'Weekend Plans',
    text: "I can't wait for two days of all play and no work!",
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'jQI29zDqgIsVded0RtNg',
    label: 'Best Business Opportunity',
    text:
      'In my business, the best opportunity I was ever given was opportunity but accepting it was difficult because reason.',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'jZVhn3Q34rZckqy7XbVw',
    label: 'Bucket List Vacation Destination',
    text: "I'm SO ready for number of days days of , , and climbing the ancient ruins.",
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'ja2RyQhanjdGdTVc5Bze',
    label: 'You Are a Unicorn',
    text:
      "Embrace your unique gifts and don't be afraid to share them with the world, you magical being, you!",
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'jbxXdF5gjoepP9gM0dKH',
    label: 'Finally Making An Announcement',
    text: "I'm finally opening my second location, and it feels like I've just had a baby!",
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'jek0CMpyhJxN0eseCI0I',
    label: "What You Can't Get Enough Of",
    text: "Shoot me a DM… I'd love to chat!",
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'jfI99R4gZBjdyT3Pm5rF',
    label: 'Advertise Your Availability',
    text: 'Calling all store owners!',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'jhUbgvuoaOOi3ryAIv56',
    label: "What's On The Agenda Today",
    text: "It's all about how to wear stripes AND polka dots this summer!",
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'ji9eMRAUZoC44rXevoSK',
    label: 'Your Work Traditions',
    text: 'We live in a culture steeped in traditions.',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'jlSa7NR0Pk8Gdaj7iT7R',
    label: 'A Snapshot of Your Day',
    text: "My afternoon consists of what you're doing in the afternoon.",
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'jnEYOVmcH4CdPBEHIElA',
    label: 'Encouraging Advice',
    text: 'These are the words that keep me going!',
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'jnMSUynYGRHFpPVQ6Jtv',
    label: 'Random Fact About Your Younger Self',
    text: 'Would you guess this?',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'jqMCZqeZeLEzhl7xET8a',
    label: '3 Words to Describe You',
    text: 'What three words describe you best?',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'jtm6OLhsxteUXt6a3FjR',
    label: 'The Joy in What You Do',
    text: "But today, I'm feeling gratitude for something a little less blatant.",
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'jvNBIfQn8pcJwvh9hmYS',
    label: "Your Client's/Customer's Favorite Choice",
    text: 'Speaking of favorite choices, product has proven to be pretty popular among my.',
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'jyM4XHjyHQLHHxp4JG0X',
    label: 'Unusual Inspiration Pick-Me-Up',
    text: "I'm never surprised when it creeps into or a sitcom I'm watching.",
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'jzcZKeZFou5VqjCpXJkY',
    label: "It's Cool to Be Kind",
    text: 'Like this post if it gave you a little encouragement today.',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'k0ZSZmhBuzlF3e7Exeha',
    label: "Life's Experiences",
    text: 'Sometimes I wish life would just be simple, carefree, and wrapped up neatly with a bow.',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'k2EtM90nquoyW6kfXQDP',
    label: 'The Soundtrack to a Productive Day',
    text: "For me, on the other hand, it's hands down classic Zeppelin.",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'k4YiL2aUllmEyvIVX8yK',
    label: 'Speaking Another Language',
    text: 'So, there you have it!',
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'k4lAwKujV2pDs3t034v9',
    label: 'How You Are in the Kitchen',
    text: 'Want to know a secret?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'k7DmmsU591FsLcLUMN6u',
    label: 'How You Feel About Inspirational Quotes',
    text: 'Where do you stand on inspirational quotes like, "Keep Calm and Carry On"?',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'kDUfjHemT1aP7jFpmmow',
    label: 'What You Love Most About This Season',
    text: 'What do you love most about this season?',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'kGDveb5Wdly6FWVjmgzG',
    label: 'Your Favorite Refreshing Beverage',
    text: 'And you?',
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'kGggVpRM3xYXbMBcIM4E',
    label: 'What Inspires You',
    text:
      "Whenever I need a boost of creativity, my tried-and-true ways to feel inspired are , , and listening to my man Gary Vee's real and raw podcast.",
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'kO0vCJF65Yw7bUPKIOyf',
    label: 'What Your Clients Love',
    text:
      "You may think it is the carefully crafted Asana projects you came for, but most of our customers say it's the individualized attention they receive.",
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'kPxnH27gPZbecxG89jVK',
    label: 'Favorite "Fuel" Source',
    text: 'how do you fuel up?',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'kSlhOGK6qMfVisBjSrF6',
    label: 'Fun Facts',
    text: 'Oh hey there!',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'kTfgbeGP0gDjWiOX6Pqh',
    label: 'Special Seasonal Benefits',
    text: 'Could you use a little more time during this busy season?',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'kUUpFYuwRFUjmDYUJxPx',
    label: 'Messy Situation',
    text:
      "I'll eventually get this metaphorical mess cleaned up and perfect my smoothie-making skills, but right now I'm in the trenches!",
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'kV0kzg5EeN3UuiBxRsC0',
    label: 'Your Lemons-to-Lemonade Story',
    text:
      "It's pushing 90 degrees today, so I'm about to whip up an ice-cold pitcher for all-day sipping, but it's also a salute to the hustlers!",
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'kVwM89VXrGTq1HMQV01V',
    label: 'What You Just Created',
    text: "It's been said that a messy desk is the sign of a creative mind.",
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'kWQVLInWbSX6ZJ3pMEF2',
    label: 'Holiday-Inspired Intro',
    text: 'What are some of your all-time faves during the holidays?',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'kcswqafWklUKiuUGj1Mo',
    label: 'Claiming Your Dream ',
    text: 'What dreams are you ready to speak life into?',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'kdZZEVdRzR0wHuBPd0GB',
    label: 'Your Happy Place',
    text: "Where's yours?",
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'keNruFXNidegbLuby7j9',
    label: "How You're Spending the Day",
    text: "If you need me, I'll be what you'll be doing to prepare.",
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'kewYqrp0q7swSiLKc6xa',
    label: 'Busy Schedule',
    text: "Let's just say my planner is my BFF!",
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'kfnmZbn73x4KcA7xHBer',
    label: 'Making An Impact',
    text: 'Reach out if you need some help!',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'koCihCTrGwKyueWhaufO',
    label: 'A Special Treat',
    text: "I couldn't be more excited to get our latest frocks to you, so enjoy shipping on us!",
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'ksEjQXaS5two4ET0BzOT',
    label: "What You Can't Live Without",
    text: "What things can't YOU live without?",
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'ksJTOyoweTpwpWi3V4ZM',
    label: 'How You Got Where You Are Today',
    text: 'Pretty cool, huh?',
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'kybAPEDucvYUzyC2Wl0Q',
    label: 'Thanking Someone',
    text: 'Never?',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'kzwnTzmL0YFe0BhVnwV7',
    label: "What You'd Get Away From",
    text: 'Spill!',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'l1KkjA0nlOzGRyRM4xQM',
    label: "This Year's Accomplishments & Goals",
    text: 'Current year has absolutely flown by!',
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'l1MsWuLsOCJfme54lIpY',
    label: 'Finding Joy Every Day',
    text: 'What is something that you see everyday that brings you joy?',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'l5JYpvfmnt2GuO9p0g32',
    label: 'Customer Care',
    text: 'Want to know how you can be sure I mean business?',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'lE8HTxYv1CdAOPYKAD1o',
    label: 'Who You Have Heart Eyes For',
    text: 'Who did you THINK I was referring to?',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'lFx22oLPGNcg2Dc4guV4',
    label: 'Enjoying A Relaxing Sunday',
    text: "There's nothing, right?",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'lJ6uvf7gSweWUVHhY6mC',
    label: 'Sharing About a Collaboration',
    text: 'Are you ready for this?',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'lNAVo6bESeQgcg0n3vxC',
    label: 'Highlighting Pressure Point',
    text:
      'I would love to know more about what you need and how I can specifically help you meet your dietary health goals with hassle-free meal deliveries!',
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'lNYpX8OguU7BP0Mu7Fhn',
    label: 'Surprising Job Necessity',
    text:
      "I'm a job title so you can probably guess that from day to day I often use tools like , , and list 3 obvious job necessities to do what I love.",
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'lSiXE5qPBjN4cuDzYVwt',
    label: 'Cooking Up Something In Your Business',
    text:
      "I'm great in the kitchen, so I've been cooking up a storm this week… but it's not about food!",
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'lTUxLe5rL2U8M6RjPC6q',
    label: 'What You Do When Things Are Stale',
    text: 'On days when things are feeling a little stale, I change up my workspace.',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'lUBd2zitgDlywPna4cO1',
    label: 'Your Thoughts on Mondays',
    text: 'Share a few Monday rituals.',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'lWijB38PKJyUt6msUEqS',
    label: 'Did You Know?',
    text: "What'll you fonDUE to celebrate today?",
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'lXpFRfMNAPld72M7Bn3p',
    label: 'Who, What, When, Where, & Why',
    text: 'Fire away!',
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'ldz8Hjw9xwOJf8VDxEKN',
    label: 'Encouraging Your Clients/Customers to Try Something New',
    text: "It's easily something people don't even know they can do.",
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'lgiU8sef92L0LO81XLc3',
    label: 'Introduction Post, Game Show Style',
    text: "And for those who have been here a while, thanks for stickin' around!",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'lh38mpj1P2EEtnJzHozP',
    label: "How You'd Spend a Day Alone",
    text:
      'If I could have one day all to myself, I would start the morning with a relaxing yoga practice and then spend the rest of the day lying on the beach.',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'lj60GqzwJjvOKFJOmPa2',
    label: 'Your Favorite Part of Your Job',
    text: 'What do you love most about what you do, friend?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'lpbB9yXSxebnXrdcCfOJ',
    label: 'A Peek Behind the Curtain',
    text: 'But what about a look into how I prep my studio for business meetings?',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'lqbMdIVpJSGkraIPjGAu',
    label: "Today's To-Do List",
    text: 'What do you have to do today?',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'lrrPeMhAK6T5ZeB8CcCF',
    label: 'Surprising Job Necessity ',
    text: 'What do you do and what do you use to do it?',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'luT0upZjzVebCfPLP1ei',
    label: 'Seasonal Fun Facts',
    text: "I'm seeing some new faces around here lately (welcome!",
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'lvwSHH5kebyBZdJb4dlx',
    label: 'Who Inspires You',
    text:
      'They inspire me to take a timeout, appreciate what I do, and then refuel me to chase my dreams again.',
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'm2LDwXeXjG4BYUJNHc6j',
    label: 'Planning Out Your Day',
    text: "Let me know in the comments and I'll be forever grateful.",
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'm31RhtXUT4LSRMBs8uu6',
    label: 'Challenging Your Audience',
    text: "Who's up for a challenge?",
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'm6FHrDbxfwfuEGgk5SqC',
    label: '24-Hour Reminder',
    text: 'This is your last chance!',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'mDdLOwMhsgEUDx61vLXB',
    label: 'Big Announcement',
    text: 'Big, big news!',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'mEYUOqsSp5v8TN1tW9oA',
    label: 'Life Lessons',
    text: "On this beautiful day, I'm feeling grateful for how far I've come.",
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'mJTiBwQLNWpRb6DMxBcK',
    label: 'Your "Business Bouquet"',
    text: 'Can I tell you something you really need to know?',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'mOKeWaf6AjGsFtrbkzGi',
    label: 'What You Look Forward to Each Morning',
    text: 'What gets you out of bed every morning?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'mPUiJ76pFqjUBMsoErJ9',
    label: 'Client Testimonial',
    text: "There's so much joy in what I do, and for that I am so very thankful.",
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'mQ48dTzvMOwU9lhr3lPb',
    label: 'Your Sweet Escape from Mother Nature',
    text: 'What about you?',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'mR12LxvUmIbpYvzhEJLQ',
    label: 'Your Favorite High-Tech Indulgence',
    text: "What's your favorite high-tech indulgence?",
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'mU8DR3xvmS0f7r4oCDHb',
    label: 'Streamlining Your Work',
    text:
      'When everything, (, , ) beading, batting, bolts of fabric, seems to pile right up on top of itself, I find myself in desperate need of a streamline.',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'mU9nCpK79ITspSLh12u3',
    label: 'How You Find A Solution for Your Clients/Customers',
    text: "When well-laid plans never see the light of day, it's the worst, right?",
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'mYnNIKgi1hqbZXZ9b1uW',
    label: 'The Start of a Season',
    text: "What's the real start of season for you?",
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'mZMNToNtZqtNZz1ghHxC',
    label: 'Passion for Your Business',
    text: 'Did you ever have a coach that always brought out the best in you?',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'mb2wkdwWB4RzrNgJVrNb',
    label: ' What You Pride Yourself On',
    text: 'Sound tempting?',
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'mbdSXwqMybQekFjNxH4S',
    label: 'Simple Joys of Your Day',
    text: 'What simple joys have started your day off on the right foot?',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'mf8W8X1jhAjhiv0rrafa',
    label: 'Introducing the Team',
    text: 'Now tell us about YOU in the comments below!',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'mhJERShPRYKJ0HOG7pBc',
    label: 'Early Bird vs. Night Owl',
    text: "I've always been an early riser.",
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'miWI8uOvr8L9EFlkPOnl',
    label: 'When Everything Just "Clicked"',
    text: 'But luckily, THEN came solution and suddenly it all came together!',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'mlYmsLtikcQIDKu34DUQ',
    label: 'Something to Make Them Happy',
    text: "Trust me, it's bound to put a smile on your face.",
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'mmM37EKviRMHoRHvf0zT',
    label: 'Releasing Something New',
    text: 'My cup is so full!',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'mmcyQxfWeVg0eUTtqs0q',
    label: 'Canada Day (July 1st)',
    text: 'What country are you from and how do you celebrate it?',
    type: 'call-to-action',
    category: 'holiday'
  },
  {
    _id: 'mmkFrXTXxwLB7nPtjOBy',
    label: 'Favorite Holiday Song',
    text: "What's your favorite holiday song?",
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'mr5yFMnJ5qpDWizu8zxZ',
    label: 'Real Life Behind the Scenes',
    text:
      'While you may think my life is full of and Instagrammable moments, it typically includes and coffee-stained shirts instead.',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'mtstZoD4BYIdjmf9Pwl7',
    label: 'Your Day So Far',
    text:
      'Today started off like any other day of the week for me, but it quickly took a turn for the better when the person in front of me in the Starbucks drive-thru paid for my drink.',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'mur6Mn4isDrgXKycaiSz',
    label: "What You'd Take to a Deserted Island",
    text: "What are 3 things you'd take to a deserted island?",
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'mxF7ul4ODPDULJ5OvQMr',
    label: 'An Enticing Announcement',
    text: "Something's cooking in my kitchen… but it isn't edible!",
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'n2CRMwziOKW9j2L48BJv',
    label: 'What Inspires You',
    text: 'Feel like hiding from the week ahead?',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'n2m72UV2IsTE0p3KfdO5',
    label: "Resolutions That Don't Stick",
    text: "I can't be alone in this!",
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'n5O3DgxSGGTWX8ceo0B7',
    label: 'Your Favorite Dessert',
    text: "Comment below if you'd like my tried-and-true recipe.",
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'n8MOeWlQ54uNYK5jIClY',
    label: "Who You're Grateful For",
    text: "Tell me what (or who) you're grateful for in the comments below!",
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'nI7qmxR93PUS09IgQ92k',
    label: "Today's Agenda",
    text: 'Top of the Monday morning to you, friends!',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'nKnuCqb6cLnb8QNuaKWu',
    label: 'Time Freedom in Your Business',
    text:
      'For some, it means the ability to work smarter, not harder, while to others, it means doing what they WANT to do, not what they NEED to do.',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'nM7TfxfkHmbZgMC2MPVU',
    label: 'Keeping It Simple',
    text: "I've gotta admit, I'm a firm believer that there is beauty in simplicity.",
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'nQsxeB5OhG3Cg0F31n1h',
    label: 'Sharing Uplifting Words',
    text: 'Now, how about you save this post for later in case you need another reminder, OK?',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'nSrwXesspHEksGrZBAmb',
    label: "What's On The Agenda Today",
    text: "Are you ready for what's on the agenda today?",
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'nTShzWKHsnlO62KcQmxl',
    label: 'Your Non-Human Friends',
    text: 'Tell me yours below!',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'nX6jh5MhUKcrNOxSVvu3',
    label: 'Your Favorite Seasonal Refreshment',
    text: "What's your go-to season refreshment?",
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'nY8TSDgWGNZYQBsYP5gu',
    label: 'Favorite Flavor of Ice Cream',
    text: 'Tell me your absolute favorite flavor!',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'nYqgwEEKfnfNpzgz5wbs',
    label: "What's Refreshing",
    text: "With feature #1 and feature #2, you'll be benefit for your ideal client in no time!",
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'nZMtFsZBitWhvre1oF7m',
    label: 'Customer Review',
    text: 'Click the link in my bio to get results like theirs!',
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'nmvms6VAyB12dAMWJTAr',
    label: 'Iconic Duos',
    text: "Shout 'em out here.",
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'nnhYCrJMUgLACXUwk2aI',
    label: 'Your Process',
    text: 'Can I take a second to tell you about my process?',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'nntrUw5WbDTsQS3x02vl',
    label: 'Intro To Your Business',
    text:
      "I'm name or business name, a job title from place, and however you stumbled into my corner of the Internet, I'm glad you're here!",
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'ntdM43ueO2JwExqXZdKK',
    label: 'Clarifying What You Do',
    text: 'First, describe part 1, second describe part 2 and third describe part 3.',
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'o1Pd3BRjVoxUDg6hGXvJ',
    label: "Who's Pushing You Toward Your Dreams",
    text:
      "I'm happy to say that I've done it, but not without the essential encouragement of my mentor and business guru!",
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'o3i9gqaQrmdW8dyLMt7D',
    label: 'Your Business Mantra',
    text: 'Do you have a mantra?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'o5b3CKd9MqF9aELlNelj',
    label: 'Benefit of Owning Your Business',
    text: 'What do you love about what you do?',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'o5uxoHWXavJzpZLAGcbO',
    label: 'Brand New Sale',
    text: 'Drop them below!',
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'o61q7ZBxX7e2tKUHLHtH',
    label: "What You're Best At",
    text: "I'd love to show you my best work…  Click the link in bio!",
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'o6XUQkig5cA4BGzQYnHj',
    label: "What You'd Get Away From",
    text: 'If you could get away from doing one thing in your life, what would it be?',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'o8Pm2LDUj0dfEbqFqYBg',
    label: 'Introduction Post',
    text:
      "It's that time of the month again, where I introduce myself to all the new faces I've been seeing around here!",
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'o9pJzz5ciaRt4CiLKXMG',
    label: 'How Your Resolutions Are Faring',
    text: 'Can we have real talk about our resolutions?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'o9pWVgNarbkVhu5mKt0z',
    label: 'Shop Small Perk',
    text: 'Do you shop small?',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'oAJZxJ6wsJYfek88Dbxg',
    label: 'You Got This!',
    text:
      'You are the same glorious creature you always were, and you already have everything you need to push through and shine your very brightest!',
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'oD7lvoqOcJgoYIDM0cLE',
    label: 'One Day Sale',
    text: 'Guess what?',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'oLTl3yfsYkCZjsmOsW9C',
    label: 'Different Options of Your Offering',
    text: "Let me know if there's anything I can do to make your decision easier!",
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'oN1AeXWMcU8HWSlz42gP',
    label: 'Your Product/Service Recipe',
    text: "I can't be alone when I admit that I'm a terrible baker, right?",
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'oNIYvazmS68TNDGzlxhC',
    label: 'Childhood Lesson',
    text:
      "\" It's all fun and games until someone gets hurt I think about this advice often, especially when I'm frustrated with my kids.",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'oNvrWlMwNKOpfU7W7bQd',
    label: '3 Goals for the Month',
    text: 'Even a journey of a thousand miles begins with one step.',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'oOtb96jUvXmDKt9QBE2T',
    label: 'Why Someone Needs Your Product/Service',
    text: 'Do you have any questions?',
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'oV8vX6164bSswFSP2sqm',
    label: 'Giving a Gift',
    text: "Just call me Santa Claus, because I'm giving out gifts left and right!",
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'oVKv8Qx8oBM0bbevA5CU',
    label: 'The Beauty in this Season',
    text: 'How are you doing today, friend?',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'oViWtj5uYf8Vz5dhyMuv',
    label: 'Asking for Wisdom/Advice',
    text: 'Do you have any words of wisdom for me?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'oYRQKatEKPLoJvPZd5ON',
    label: 'A Learning Experience',
    text:
      "It's never super fun to admit to your mistakes, but I want to take you behind the scenes of our latest rebrand and, shall we say, air out a little of my dirty laundry.",
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'oYaU20Y9dO82ccbdIhsm',
    label: 'How Much You Love Your Clients/Customers',
    text: 'Is this weird?',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'oaFchCR6m39Tx808RHlT',
    label: 'What Helps Your Business',
    text: "without them, I don't know where I'd be.",
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'odjjtyKO5sxXPiJ2Ci1F',
    label: 'New Content Alert',
    text: 'NEW BLOG POST ALERT!',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'oieTbGPZG47h3wqAVAuv',
    label: 'Boxing Day (December 26th)',
    text: 'Who needs Black Friday when you have Boxing Day?',
    type: 'hook',
    category: 'holiday'
  },
  {
    _id: 'oiyHMuTtizZQ0ONsRZHc',
    label: "Something You're Insecure About",
    text: "If you're anything like me, something immediately came to mind!",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'ol53IouUzzQBJszZY18J',
    label: 'What Younger You Would Think of You Today',
    text: 'So, what would the younger version of you think of yourself now?',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'olSA5u5Qw2o5cSpzPwgY',
    label: "What's Inside Your Business",
    text:
      "Here's something I've NEVER shared before… If you were to crack open my product, you would find a 24-hour response time on all customer service inquiries, along with the peace of mind knowing the organization of your home is being taken care of.",
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'omQNB54vR2m45VWqVqnP',
    label: "Something You've Been Eyeing",
    text:
      "Is it just me, or does anyone else stalk their favorite websites in advance and make a list of what they'll buy during the next BOGO sale?",
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'otKVwM3w0af5Y0HBtCU7',
    label: 'Promotion Ending',
    text: "What do you want to know before it's too late to snag this deal?",
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'oum1Z3nJtf9B1MMAiiHO',
    label: 'The Catalyst That Changed Everything',
    text:
      'Do you ever step back and think about the one opportunity that seemed like the catalyst that changed everything?',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'ovPAEbMWEGdhCCCbAC4s',
    label: 'Helping Clients Win',
    text: 'What would it take for you to count your dream vacation an absolute win?',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'owQBPcGjXohfRDdPwO02',
    label: 'Be The Light',
    text: "What's one thing that has encouraged you recently?",
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'owjRJQ8yAK1SoiTDBlyP',
    label: 'Your Travel Tendencies',
    text:
      "I have always been an explorer at heart, and my goal is to travel to 30 countries by the time I'm 30.",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'oyE0gRcAP92ZwRZjDJyS',
    label: "Why It's Time to Start Now",
    text: 'Click the link in our bio right away!',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'ozg5bRY9cpqxOlCJjRg1',
    label: 'Top 3 Lessons Learned',
    text: "What's some advice you would give about the industry you're in, friend?",
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'p0fs3m7EBfdpoLFMqQCW',
    label: 'What Customers Should Expect Working With You',
    text: "Send me a DM if you'd like to know more!",
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'p1LoNP8EweEeG3WIsAKS',
    label: 'Introducing the Team',
    text: 'Now tell us about YOU in the comments below!',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'p3nJhiwkfHo8nAHNwwfZ',
    label: 'Your Like/Dislike for School',
    text: 'Did you like school growing up?',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'p6Yv5jZAc8i5FLBXYGgq',
    label: 'Thanking Your Clients/Customers',
    text: 'Are you ready for a love bomb?',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'p9vq0jC8OD2WQSl4xjG8',
    label: 'Feeling Lucky & Grateful',
    text: "What's most rewarding about the work you do?",
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'pGuSGWzWfRRt7TE8GOm6',
    label: ' Your Monday Plans',
    text: 'What are your big plans for today?',
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'pJSy2EwDDOe2vwg3wpkA',
    label: 'Living Your Dream',
    text: 'Do you know how improbable the success of this business was?',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'pMRegfCncqsMJz49RG63',
    label: "St. Andrew's Day (November 30th)",
    text:
      'Did you know that, according to legend, women anxious to be married should peel an orange at midnight (on the cusp of the 29th and the 30th), and they will find the first letter of their future husband&#39;s name?',
    type: 'insight',
    category: 'holiday'
  },
  {
    _id: 'pMkmHLvaR0Jp5DxwmCIQ',
    label: 'How You Escape',
    text:
      "The weather's a struggle, and as much as there is to love about all of it yes, even that snow, which is a drag to shovel, but a joy to make angels in, I HAVE to have some way to sneak a little bit of peace in the midst of it all.",
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'pR6DwTpNSjrP0Cw5Yg4u',
    label: "Something You're Thankful For in Business",
    text: 'Anyone else feeling especially thankful lately?',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'pT5s0LgWADlGuB2ebRUJ',
    label: '3 Ways You Avoid Burnout',
    text:
      'To avoid letting life zip by, here are 3 things I do to keep from burning out (and I hope they help you, too):First way you avoid burnout Making time for self care every SundaySecond way you avoid burnout Assuring that I finish work by 6pm every night to have dinner with my familyThird way you avoid burnout Taking frequent breaks during the daySo there you have it!',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'pTZVpDti2Heq8VXaRbbw',
    label: 'Favorite Memory',
    text: 'One of my favorite memories is when I was age at location.',
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'pVs4jH3cia5RXoM9D5Sh',
    label: 'Something Silly and Exciting',
    text: 'Can I tell you something ridiculous?',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'pWgc7atefoHg1PYDRhhA',
    label: "What's On Your Desk",
    text: 'Zodiac sign?',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'pYo9pwGhVn6NhtC4a7PM',
    label: 'Taking A Break',
    text: "I'm taking a break.",
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'pgteUowzTX1J03VBLXUm',
    label: 'Listen to This Announcement',
    text: 'Listen up, word or phrase to describe ideal client!',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'pjC9KE7t2iq8zrVr7I9x',
    label: 'New Or Improved Product Or Service',
    text: "You'll never guess what I've been up to!",
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'pmo8WeqrEiMtm7og5FCI',
    label: 'Ask Me Anything',
    text: 'Guess what?',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'po1ervHQc91qBVDa1XOr',
    label: 'Turning Lemons into Lemonade',
    text:
      'Most social media posts present polished final products… while the gritty behind the scenes remain unseen.',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'pp18QcxuinPjIxrCYdMe',
    label: "Best Thing You've Done This Season",
    text: 'If you had to pick just one favorite moment from this season, what would it be?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'pvmzdV0j7wqI1GyQcxB3',
    label: 'How You Got Started',
    text: "I'm a true believer that if you can dream it, you can do it!",
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'pxOD1owxpRnl3eaCzVe2',
    label: 'Working With You',
    text: "I'd be happy to give you all the juicy details, since that's exactly what I am!",
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'pzLuCjwUHT0pQlnXkyaZ',
    label: 'Personal Reward',
    text: "Doesn't this look tempting?",
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'q60PSfyxMFJKiCChiwBS',
    label: 'A Virtual "Cheers"',
    text: 'Double tap for a virtual "Cheers"!',
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'q8wIeJRdfbK5BmGtqpVo',
    label: 'Your Goals So Far',
    text:
      "I'm so excited for the next step, but I'm taking a moment today just to celebrate how far I've come and pop a bottle of bubbly!",
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'qBPsq3E8c0KzRYGRhUZS',
    label: 'Picture-Perfect Weekend',
    text: 'What are you doing this weekend?',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'qCimpPDWZxiwsDh4XJl9',
    label: 'Halloween (October 31st)',
    text: 'For me, it was hands down your favorite childhood costume!',
    type: 'insight',
    category: 'holiday'
  },
  {
    _id: 'qF6CgJiO1pC6tAoTwTgI',
    label: 'Fall In Love With Your Product/Service',
    text: 'Follow the link in my bio to your culinary paradise!',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'qPKtJwdijUmzUz9kHiGN',
    label: 'What You Can Count On',
    text: "The last 12 months have been full of the unexpected, don't you agree?",
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'qPXSMarVvNbL6kuG4Y69',
    label: 'How You Recharge Your Business',
    text: 'How do you breathe life back into what you do?',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'qQRYamMVKz44dJLXb9hw',
    label: 'Bottling Up A Memory',
    text:
      "I wish I could bottle up my wife's ecstatic laughter at the moment our son, Miles, called her mama for the very first time.",
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'qWMKqFtN0PM0Y95FCUYF',
    label: 'Doing It Anyway',
    text: "This just proves that we can ALL do hard things if we're doing it for LOVE!",
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'qXPo3RK64glGok1Nzg74',
    label: 'Your "Workout" for the Day',
    text: 'Talking fast counts as cardio, right?',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'qY9PbFlyA4wWlW6zfLiN',
    label: 'What Your Clients/Customers Love',
    text: 'Here are a few crowd favorites: , , and 3 benefits of your product or service.',
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'qYRbSkwLypHaclfRizCH',
    label: 'Try Something New',
    text: 'Are you ready to try something new?',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'qcYKHCMKs6qKPB1Bnq9s',
    label: 'Your Shopping Style',
    text: "I'm a bit of a procrastinator, so it's definitely A for me!",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'qesLSIkNevx6MkvgGtMF',
    label: 'Sharing an Exciting Announcement',
    text: '*Doot doo doo doooooo!',
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'qh7YKJbCDpclDmUVgsjn',
    label: 'Type of Fruit',
    text: 'What type of fruit would you be?',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'qn6AfeyfQhzcGeFVugSY',
    label: 'Black History Month (February)',
    text: 'Black History Month, that is!',
    type: 'insight',
    category: 'holiday'
  },
  {
    _id: 'qqlLXH69kwDbTCiJJdiW',
    label: "A Storm You've Weathered",
    text: '"Life isn\'t about waiting for the storm to pass.',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'qqnkzrwufme2CHfXcjXc',
    label: 'How You Help Clients Reach Their Dreams',
    text: 'What are you dreaming of today, and how can I help you get there?',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'qwk9ca7ZJALZLyAakg9R',
    label: 'A Sweet Fact',
    text: 'Want to hear a SWEET fact?',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'qx2i1aSYnUkXJ061eu8D',
    label: 'Your Favorite Season',
    text: "Here's to sunblock and lounging on pool floats!",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'r0vFuUZL2pYAGbsYaiXa',
    label: 'Where You Work Best',
    text: 'Where do you work best?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'r4Pm9l82Gl64W1RMGJJ9',
    label: 'What Inspires You',
    text: 'What inspires you to pursue what you love?',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'r72Q4uUwLRI8ydN1jddO',
    label: 'Favorite Indulgence',
    text: 'What can we do for you today?',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'r7WBaA2n1jn5e3XqMUKB',
    label: 'Results of Your Product/Service',
    text:
      'I have a question for you: "Orange" you glad your product exists to take administrative tasks off your plate?',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'r7nnsSITbBOemuhH14XJ',
    label: 'Your Biggest Cheerleader',
    text: 'Who does the same for you?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'r9er2EpKR6Sb2SbpGTlq',
    label: 'Industry Poll',
    text: 'What is one thing you wish you knew about what you do?',
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'rBYVVmj5vmBBXTiWFx7y',
    label: 'A Little Sweetness on a Bad Day',
    text: 'What sweet silver lining have you found on a bad day recently?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'rFukQMJ6VzLhMmANuTNn',
    label: 'Something (And Someone) You Love',
    text: 'Who are you grateful for today?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'rGUHJooQrEUmNBCdUq9G',
    label: 'Favorite Quote',
    text: "What's your favorite quote?",
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'rJGc5EeW7xhmnXwJqxGq',
    label: "How Life's Going For You",
    text: 'How are things for you, friend?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'rMmqAXEuWGtWUsjygBKU',
    label: 'Surprising Job Necessity',
    text:
      'What might surprise you is how necessary list unexpected job necessity—app, group chat platform, organizational tool, special snack or drink, etc.',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'rN9dZE2JF4NCRzeYUgGV',
    label: 'A Leap of Faith',
    text: "I'm so glad I did, because now, I'm happier than I've ever been!",
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'rNuxr1CPs0dsyY3CzTuM',
    label: 'Super Cool Benefits',
    text: 'I have something SO COOL to tell you!',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'rPOgA6Sg446jVBlUUQwQ',
    label: 'Good vs. Bad Situations',
    text: 'We all know the saying, "When life gives you lemons, make lemonade," right?',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'rV5G2XJ4uIoXy7K32iNt',
    label: 'An Upcoming Announcement',
    text: "com to be the first to know when it's open!",
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'rX1x8ErjySDsU2xA2uFV',
    label: 'Keep Dreaming',
    text:
      "If you give me a minute of your time, I'll give you some time of day encouragement you didn't know you needed!",
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'riD1WK2ItOxQH3euVsMb',
    label: 'How Ideas Become Reality',
    text: 'I usually start by sharing them with the team during our Monday meeting.',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'rjdl2nw9oOeg4XZnzfFN',
    label: 'Your Village',
    text: "Who's out there helping you make magic in your village?",
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'rmaTPp39ewSTczVRWGxF',
    label: 'A Big Announcement',
    text:
      'Call your mom and tell her THIS big news: All items in the shop are 50% off this weekend only!',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'roAgTL9PlrJinnoPKgWS',
    label: 'Love for Your Clients/Customers',
    text:
      "And in this moment, I want to share my IMMENSE love for the incredible clients I've worked with over the years who have shaped me into a better artist, a better businessperson, and most importantly, a better human being.",
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'rqsdSuIhFploU3RpVaMv',
    label: 'Your Favorite Quote',
    text: "What's your favorite quote?",
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'ryYn8UhsUsYLpj5ty0No',
    label: 'Your Home or Office Decor Style',
    text: 'Comment below with your style of choice!',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'ryzsIhV1OO59AHpeFgFo',
    label: "What/Who You're Hustling For",
    text: 'What (or who) are you out there hustling for?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 's18GhkrqbLmQl6VuDAkt',
    label: 'Accurate Description of Your Business',
    text: "What's a more accurate description of what YOU do?",
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 's1CbtZsqPRHM54MVuAtT',
    label: 'Who You Work For',
    text: 'I realized something today.',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 's3T7IjP3Gkg1yyqbxo4o',
    label: 'Product/Service Coming Soon',
    text: 'yourwebsite.',
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'sBugYCgw16NeWgF3a9j0',
    label: '(Re)Introducing Yourself',
    text:
      'Allow me to introduce myself (or reintroduce myself to those that have been here for a while).',
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'sCcRGeOzwGY6nMNkk11f',
    label: 'What Keeps Me Accountable',
    text: "But in my business, I'm 100% dedicated to my amazing clients.",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'sCxGydozA5Mmf8u27rFf',
    label: 'Answering FAQs',
    text: 'If you\'ve ever asked yourself: "?',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'sCyHzd4AELigk9vTDxTu',
    label: 'The Best Part of What You Do',
    text:
      "It's seeing the look on my clients' faces when they have a breakthrough during our relationship coaching session.",
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'sE2oc43I3YKbh53XZoKi',
    label: 'Memorial Day (Observed the last Monday in May)',
    text: 'Tell me friend, how are you spending the day?',
    type: 'call-to-action',
    category: 'holiday'
  },
  {
    _id: 'sEFPHF9IV5HH7Zyz6ABh',
    label: 'Inspiration is Everywhere',
    text: "What's inspiring you today?",
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'sFQVZhZCeXEicFTPlZ4a',
    label: 'Your Favorite Treat',
    text: 'Can I tell you a bite-sized fun fact?',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'sJEwepUVSMcgTiIPSAO5',
    label: "A Tradition You're Thankful For",
    text:
      "This time of year, I spend a lot of time considering traditions—where they came from, why they're important, and whether it's worth all the fuss to keep them.",
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'sL8rCzEnbpsw6JHemPFh',
    label: 'Guilty Pleasure',
    text: "He'll need some milk and then a straw and on and on and on.",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'sQ8LVgMKM7qrDYOD9kwD',
    label: 'Type of Reader You Are',
    text:
      "Confession: I'm someone who really does enjoy reading, but somehow can never make the time to finish anything.",
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'sQuUaRWlVgwlEETjstOc',
    label: 'A 3-Step Tutorial',
    text: 'I often wonder: Who first thought to start steaming mussels—and eating them?',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'sSVuEF5pCfLXnAJ8s5u2',
    label: 'Showing Gratitude',
    text: 'Got questions?',
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'sTh45ChwzCE9LTjgw8GG',
    label: 'Exciting Announcement',
    text: "It's time for a toast!",
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'sUfgBHifRjRgQqOqrgpK',
    label: 'How to Fill Your Cup',
    text: 'way #1,way #2, andway #3.',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'sWEPmOcofjyAJA4hDJKv',
    label: "An Item You Can't Live Without",
    text: 'As a kid, what was something that you brought with you everywhere you went?',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'sYcLSm4mVqUjkif8rjBU',
    label: 'Your End Goal',
    text: 'Find that surprising?',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'sZ7Uvwms1lOlDljDQh2e',
    label: 'Looking for Advice',
    text: 'Well, today I need a hand from you!',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'sZcaLKoCXGYeYcJV2cpn',
    label: 'Advice You Cling To',
    text: "What's the special advice you cling to when you need a lift, and who shared it?",
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'seIwKncfnY5S3vCDEL04',
    label: 'Favorite Ice Cream Flavor',
    text:
      "I scream, you scream… If I don't get some favorite ice cream flavor STAT, you'll probably hear my wails from there!",
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'srnS0UWSCsMBgFPA3bmb',
    label: 'Your Favorite Teacher',
    text: 'I am forever grateful for them.',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'ssRu5vW7X1JXoRdaT5Xm',
    label: 'Your Mood As A Color',
    text: 'Wanna play a game?',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'sxNqd30imMqWVRwBSBpT',
    label: 'How Your Business Stands Out',
    text: 'Anything we can help you get started with today?',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'syDc3leqQ44VljAKZgqI',
    label: 'The Savings You Offer',
    text: 'Anyone opposed to a little extra cash in their pocket?',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'syx7XliiNYpzG35qutZs',
    label: 'A Refreshing Benefit of Your Product/Service',
    text: "Let's dive in!",
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 't3KLkTIyFxcMikXgq4IN',
    label: 'Your 2020 Wish',
    text: 'What do you wish is in your ocean this year?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 't4jMYLTUv5jmFKL89ZDD',
    label: 'Easter (Observed on a Sunday between March 22nd and April 25th)',
    text: 'Happy Easter Sunday, everyone!',
    type: 'hook',
    category: 'holiday'
  },
  {
    _id: 't59oAiMHtkcCmQ7VpAQb',
    label: "What You're Excited About",
    text:
      "I'm so proud of this because why you're proud of accomplishment and I just had to share!",
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'tDnIsdwjo0jS2g6EhEVR',
    label: 'Your Greatest Satisfaction',
    text:
      'As a job title, I have no greater satisfaction than helping clients feel beautiful and confident in front of a camera.',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'tE1bUWQlGOy3Cuzahoas',
    label: 'Unexpected Job Necessity',
    text: "Who doesn't love surprises?",
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'tEVQWYsoqtW1p0Y01c8n',
    label: 'Holiday-Inspired Poll',
    text: "What's on your list, friend?",
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'tG5F2wedP1UVgph2WzCx',
    label: 'Announcing A Live "Ask Me Anything"',
    text:
      "I have a present for YOU… List date and time of upcoming Facebook or Instagram Live you'll be hosting is your chance to ask me ANYTHING!",
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'tIoX5AcsQX6CpZXJ6WXj',
    label: 'Your Strong Belief in Your Product/Service',
    text:
      'I will unabashedly admit this: I wholeheartedly believe in the miraculous healing power of plant and flower essences.',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'tQZzGXPQzKPBfX1PiebR',
    label: 'Beverage-Themed Introduction Post',
    text: 'Hey friends, new and old!',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'tVrdrd1OHveyxDenIfeC',
    label: 'Breakfast of Champions',
    text: 'Mine would have 2 over-easy eggs, 2 sausage links, and 2 strawberries, no competition.',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'tX0t1dAN2zPYKXnz9Daw',
    label: 'How Challenges Help Us Grow',
    text: "I don't claim to be an expert, but I know how to push my limits.",
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'tZ0AXZB1BbA6htDgKQFQ',
    label: 'A Glimpse of Your "Why"',
    text: 'Want a glimpse at why I do what I do?',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'ta4LPfbleaxNS9B19SfS',
    label: 'New Beginnings',
    text: "Let's do this together.",
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'tdhQqo04uqOtFImyeNTz',
    label: 'The Transformation You Provide',
    text:
      'By now, you probably know what I do… but today I want to shift gears to talk about the transformation our anti-aging skincare line can do for you!',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'tfXnuSN3lyiBFdCV72P7',
    label: 'An Embarrassing Confession',
    text: "Okay, I told you my embarrassing story… it's time to spill yours in the comments.",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'ti66Kwisb5HeRSyd669h',
    label: 'Getting Through Tough Times',
    text: 'How do you get through the really tough times?',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'tm6P8X5ML39cpjGdT053',
    label: 'Walking Down Memory Lane',
    text: "It's time for a walk down memory lane!",
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'tsRpP3kZe2ZqV0le7ccX',
    label: 'Celebrate Milestones',
    text: 'Hit me up in the comments or send me a DM and we can party together!',
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'tssV67GjqbwtnzIotnzX',
    label: 'Random Acts of Kindness Challenge',
    text: 'We should all do things like this more often.',
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'ttFnFAhEx4NoLLV7Mc4d',
    label: "A Big Dream You're Dreaming",
    text: "Share your big goal in the comments and we'll dream together!",
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'tvYDA8jG1GNtT1YCIGNe',
    label: 'Sweet Things About What You Do',
    text:
      'One of the sweetest parts about what I do is definitely benefit, but the cherry on top is unexpected benefit.',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'txkpACQlsoHN0oKoyKdV',
    label: 'A Day for Relaxing',
    text: 'Today is a day dedicated to relaxing.',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'tysRGDAc8vfsQpPONBlY',
    label: 'When Life Gives You Lemons',
    text: 'So, really, what do you fancy: A, B, or C?',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'u02fEgTxVjUCFzrMVraK',
    label: 'Ways to Unwind',
    text: "What's your go-to?",
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'u1egJ3Bn6BUYViV7KTuh',
    label: 'Your Routine',
    text: 'Comment below!',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'u8wUqbNjdjD4nplKXHMS',
    label: "What You're Grateful For",
    text: "What is one thing you're grateful for today?",
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'uBQm5GKYDOlbrYK5XQYu',
    label: "What's Inspiring You Lately",
    text: 'Thanks to them, my work is better and my soul is fulfilled.',
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'uFzH8tvfCBKu0Spr1nmh',
    label: 'Your Best Friend',
    text:
      "When I refer to my BEST friend, it's literally any one of 3 different people I have treasured since my freshman year in high school.",
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'uGaQfe7P36svQZfYCLYz',
    label: 'Thanking Your Followers',
    text: 'Milk and cookies.',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'uGjxCXF6DgDzb0TCkTZy',
    label: 'A Brief Introduction',
    text: "I'd love to get to know you better, so, do tell: What's your favorite hobby?",
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'uTEqIUYwXoljV0hYZQrs',
    label: 'Celebrity Meet & Greet',
    text: "Who would you pick to ask life's greatest questions?",
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'uXu5nBczkHSlxP5VP2Mg',
    label: "How You're Kicking Off The Weekend",
    text: 'How are you kicking off the weekend?',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'uaHWNit95Nf6XiT5AOD7',
    label: 'Share A Secret',
    text: 'Swift on repeat, or do you do it, too?',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'uaWDBCtTLdmpkRPM8mak',
    label: 'What You Love About Your Job',
    text: 'Now that you know about me, what do YOU do?',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'ubwpkvfWTPJ2NDVTJY0n',
    label: 'Welcome to My Page',
    text: 'Hello!',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'udLYjgjdb5uVCmxvZM7q',
    label: 'What If?',
    text: '" What if I created an all-natural skincare line for expectant moms?',
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'uh6MDK8pBybsVKxbByUt',
    label: 'What Rocks About Your Job',
    text: 'What rocks about your job?',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'ukBNpaaNBQDDDZfH1rj8',
    label: 'The Feeling You Sell',
    text: 'With all my heart, I believe I can give you the feeling you sell with product.',
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'urCAHa4Fv8euJUHt3SYU',
    label: 'Why You Value Kindness In Business',
    text: "I'll be honest.",
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'usJy7lk1ivc6W9KCk9OZ',
    label: 'Something New You Created',
    text: "It's that time again!",
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'uz9I0JLvU2ARzXGylbgk',
    label: 'Your Favorite Movie',
    text: "I know some of you will be mad at me for asking this, but what's your favorite movie?",
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'v0LcLqm2zEH6XT6YLCZe',
    label: "Right Where You're Meant To Be",
    text: 'Been there!',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'v1Phl1d7WJTeXcYWpe6V',
    label: 'Your 16-Year-Old Self',
    text:
      "From where I sit now, I'd love to go back and tell myself what you'd tell your 16-year-old self.",
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'v7OkW77iCJvHZx92h2rg',
    label: 'Where You Find Peace',
    text: "I can't be the only one, and I'm currently finding it in quiet morning walks.",
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'v87alihLwaOKPQORJuMA',
    label: 'Client/Customer Testimonial',
    text:
      "I can talk 'til I'm blue in the face about why I believe my business is really something great, but I'm a liiiiittle biased.",
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'v9vLNK5pGlYtucpTXCFd',
    label: "What You'll Celebrate and How",
    text: "What'll you celebrate, friend, and how?",
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'vCeETxXtONhcGoZUZ4Sd',
    label: 'Motivation Monday',
    text: "Keep going friends, there's only 4 more days until the weekend!",
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'vDE1EDDv7PM9LC88Fh6J',
    label: 'Diminishing Fear',
    text:
      "You'll never believe what I'm about to confess… I'm still a teensy bit afraid of the dark.",
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'vDG5UgPjGTL6wDTaJtF0',
    label: 'Thanking Someone',
    text: "Without them, I wouldn't be where I am today!",
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'vEgftDYuNrzD1tMh7iBd',
    label: "Something Sweet You're Offering",
    text: "Head on over to 123 Main Street to check it out… It's pretty SWEET!",
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'vEt0M3KqdNRV0VzumgbL',
    label: "What You're Drinking Today",
    text: 'I am going NOWHERE today… except maybe back to the kitchen for a refill!',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'vGL7aLAI8BVfM2RF6vLA',
    label: 'Exciting Announcement',
    text: 'Check it out at the link in my bio!',
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'vGxs28auOQLpxMLLqAcj',
    label: 'An Upcoming Collaboration/Event',
    text: "I'm beyond excited to announce a collab with XYZ business, set to launch date, time.",
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'vJAJB9MS3VOa3SUTgmR5',
    label: 'How You Start Your Day',
    text: "What's your go-to morning routine?",
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'vJpq0O9njpiO7nDIOBsE',
    label: "How It Started vs. How It's Going",
    text: "Today I'd love to share How It Started vs.",
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'vL9y8nqV4KjmUa7tMoZU',
    label: 'Short & Sweet Note',
    text: "It's mostly a note to say thanks.",
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'vM11tUqxjhI4SvGrbDGT',
    label: 'Making Life Easier for Your Clients/Customers',
    text: 'The holiday season can be hectic, right?',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'vPEtL8FkWfRvQSSWS7dZ',
    label: 'What Brings You Joy',
    text: 'So let me ask you, what collection do you have that brings you joy?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'vSK2wTIIdD0t0zk09Kq6',
    label: 'Your Day in 3 Words',
    text: 'What can I say?',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'vWqpOimnj2Wam29f5MIb',
    label: 'Helping Clients/Customers Relax',
    text: 'Is there anything better than a nice soak or steamy shower after a long day?',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'vZXrk3cEFJKiuvYSQy5P',
    label: 'Taking a Day Off',
    text: "Don't bother me… I'm very busy working today.",
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'vogQxTwZRpFXwe4nsKyF',
    label: "What's Coming Up",
    text: 'What are you looking forward to in the next few weeks?',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'vp7psHpUX402A2B7j2S0',
    label: 'Sharing Your Process',
    text: "Well it's your lucky day, because I'm going to break it down for you in three steps!",
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'vs8WshShJjfqWXn14zta',
    label: 'Reward for A Job Well Done',
    text: 'Tell me, how do you celebrate your accomplishments?',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'vsXHt65IW26SATWRW0ZZ',
    label: 'Morning Routine',
    text: 'Tell me: What is your morning routine?',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'vsfFZ5Eaq7nmy9mdhUwn',
    label: 'Sharing A Testimonial from A Happy Client/Customer',
    text: 'Totally unbiased opinion (wink wink): I work with the absolute BEST clients ever!',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'vyYNXUH5ZamSb7BfISu5',
    label: 'Your Current Obsession',
    text: "I just can't get enough of it!",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'w04FANyjHvL2Kuw7Mq3w',
    label: 'How You Welcome The Current Season',
    text:
      "I live in New York City, and by the time I flip the calendar to current month, it's already clear that autumn has officially arrived.",
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'w5ntmEkas2JwA2MFi4M4',
    label: 'Your Typical Day',
    text: "Truth is, I'm usually not doing ANYTHING even remotely cool.",
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'w6NKjRMa7idQ1HLM9Ced',
    label: '24-Hour Reminder',
    text: "Don't delay!",
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'w7O6qP5ONu68Kf8opkHl',
    label: 'Work Environment',
    text: "That's when my ideas really start flowing!",
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'w9pfh8JNoJyGvH4h5B0h',
    label: 'Elements of Your Product/ Service',
    text: "Drop them below, I'm more than happy to help!",
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'wGPZqTBrF5k5IAm1qUE0',
    label: 'Going Outside Your Safe Zone',
    text:
      '" Type "yes" in the comments below if you\'re committed to chasing your dreams, even if it means leaving the safety of the harbor!',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'wH2ocPJdfAxAAhtMhkAq',
    label: 'Client/Customer Love',
    text: "I don't know about you, but that definitely made my heart skip a beat!",
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'wJbD1FL5bBM2rIPiLRjK',
    label: 'Your Favorite Thing About This Month',
    text: 'Your turn!',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'wMlCHB2ttNZBmMbGy8oT',
    label: 'The Power of Social Media',
    text: "It's so incredible that social media allows me to share my life with you.",
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'wQ6QV8TmsvOQtbUphDD1',
    label: 'Cringey Faux Pas',
    text: "Share your cringiest faux pas below as a reminder of how far YOU'VE come!",
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'wV122sOCCCQ7hgl9ztNR',
    label: 'Thanking Your Clients/Customers',
    text: 'Who in your life deserves some thanking today?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'wVeZDI3erk5wvGksRDkv',
    label: 'An Early Childhood Lesson',
    text: 'What childhood lesson do you still practice?',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'wWH4dhiONPqCmqa13j84',
    label: 'Helping Clients/Customers Relax',
    text: 'Drop your questions in the comments below!',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'wWKc0Hb3v50kMSKvPiMY',
    label: 'How You Provide Clients with Comfort',
    text: "Reach out if you'd like our assistance!",
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'waJdh5tJAp55MDsuUImj',
    label: 'What You See Out Your Window',
    text: 'What do you see out of your window?',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'wb1vh3U1ves7hw7HfUHu',
    label: 'How You Work Best',
    text: "Are you more the 'life of the party' or 'table for one'?",
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'wbtcxkdtjAuaV1THwQoA',
    label: 'Invitation to Ask Questions',
    text:
      "If you have questions, leave them here and I'll share my juiciest secrets with you there!",
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'whY9EowSwl4vRlMmrOWo',
    label: 'Surprising Fact',
    text: "What I'm about to say might just surprise you!",
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'wi6Bf1zGVOK6SN94stI8',
    label: 'Keeping Your Goals On Track',
    text:
      'Do you have an inspirational phone background or are you more of the wishing and manifesting type?',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'wo0trINwdzsn6hWApyUS',
    label: 'Send A Card to A Friend',
    text: 'Who will you send a card to today (and make their day)?',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'wqJSBEhGOXh4licEZ3ds',
    label: 'How You Start Fresh',
    text: 'Ever feel like life should come with a delete button?',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'x6yYJ9pOYKu7CLPHkJ6E',
    label: 'What Motivates You',
    text: 'Where are my fellow gym rats?',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'x7NQYoC1xYClACD5efhX',
    label: 'Knowing Better, Doing Better',
    text: "Tell me something you've learned that managed to change everything!",
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'x7udxPriIODsT7xydydp',
    label: 'Who You Are',
    text: "I'd love to say hello, so give me the deets in the comments!",
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'xAlfC7x1VPuwXpMQXznL',
    label: 'Sharing A Testimonial',
    text: 'Enough about me.',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'xHsBRUqurCgtCo9vxjv5',
    label: 'Ahead of Trends',
    text: "I don't mean to brag, but I'm a bit of trendsetter!",
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'xIkpCs9HnF5S6GarrjFZ',
    label: 'An Eco-Friendly Change',
    text: 'Are you with me?',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'xMvEREdF8RjM8aIvXCWW',
    label: "What's On Your To-Do List",
    text: 'Who else out there is as annoyingly organized as I am?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'xOxs65HslwaiySkYUkAc',
    label: 'Where You Work From',
    text: 'Where are you working these days?',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'xPKAp0WNdzULhHu5TwyZ',
    label: " What You'd Tell Yourself 10 Years Ago",
    text: 'What would you tell yourself if you had the chance to go back 10 years?',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'xQJnuCKC32mW55ykiG5f',
    label: 'How Your Personality Helps Your Clients/Customers',
    text: "It's true… and you wanna know a secret?",
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'xQaN3S7crXEgDvWMw1OS',
    label: 'Dream Destinations',
    text: 'Do you have a list of places you are just dying to visit?',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'xTZYXu2dRdkjWHmLdoyy',
    label: "What You're Good At",
    text: "I'd love to answer any questions you may have… comment below!",
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'xU4jGI8E1D0Z2OgCz4km',
    label: "Something You're Insecure About",
    text: 'Regardless of what it is, know this friend: you are enough!',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'xUoVunoVl44rXysjhMtV',
    label: 'A Heart-Warming Review',
    text: 'How can I help you?',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'xWsGI7y0dNVpHUc9wDa1',
    label: 'Time for An Introduction',
    text: 'Hellllooooo, Instagram!',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'xZd1hj10xEV9QEYSqx6e',
    label: 'The Best New Start',
    text:
      'It was terrifying in ways, but at the end of the day, I knew I needed my brand to reflect the kinds of clients I wanted to attract.',
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'xcmswV1sO3xOX4eFoAcW',
    label: 'Encouraging Perspective',
    text: "Can someone PLEASE tell me I'm not alone in getting a little overwhelmed sometimes?",
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'xgMFdxlOhYupn2s7Lkit',
    label: 'Thanking Someone',
    text: 'Only for bad hair days and ball games?',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'xiguIZf2LqBe151O5aQn',
    label: 'Thanking Your Followers',
    text: 'What are you grateful for today?',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'xkgKLKkU74cbMnGwK58U',
    label: 'Pushing Through to The Weekend',
    text: 'What are you looking forward to this weekend?',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'xl2EImdjJdjddsJhyoZw',
    label: 'Love/Hate: Seasonal Edition',
    text: "However, you can't help but LOVE your product.",
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'xngCOUonYpgZtKP9N5AX',
    label: 'Helping Your Clients/Customers Enjoy Their Days',
    text: 'Ever have those days where all you want to do is have a cocktail by the pool?',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'xomtfpPPVP5xnLleBJsB',
    label: 'Self-Care Routine',
    text: 'How do you take care of YOU?',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'xpkECKqkMEqHk2gWbagL',
    label: 'A New Beginning',
    text: 'Rise and shine, dreamers!',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'xzufg8ETUG3ILhG8ypyp',
    label: 'Brainstorming New Ideas',
    text: 'Ready for a workout?',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'y0dnQIWkyPBf0PZR5eUk',
    label: 'How You Give Clients a Break',
    text: "We love to a way you help clients and can't wait to hear from you!",
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'y5ecLjpgnTioM9xiXJzJ',
    label: 'Deadline or Deal Expiring',
    text:
      'Can we take a break from the usually scheduled programming of what you typically post on your account for a quick note?',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'yDV7dJITp8ue6zlortVm',
    label: 'Announcing Something New',
    text: 'Good news!',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'yFv63nltuZGv1NOdhUIl',
    label: 'School Days Throwback',
    text: 'I worked really hard to maintain my B average!',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'yMrJrncmLeV7KkrIkxJT',
    label: "What's Been Inspiring You",
    text: "Now it's your turn… spill in the comments what's been inspiring you!",
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'yNFYe4Mscn3a8chm3cTR',
    label: 'Overcoming Objections',
    text: 'Drop a comment below with your number one concern!',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'yRhqD54jlfFAqO3Lt8QW',
    label: 'Introduction Post',
    text: 'One of the perks of pursuing what I love is meeting new (and awesome) people… like YOU.',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'yT4FIgyaUixnJeorlNzi',
    label: 'How You Outdo Yourself',
    text: 'How can I help you today?',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'yUi2EUJ1yHZi6J5J0ymA',
    label: 'What Clients/Customers Mean to You',
    text:
      'My clients what your clients, and I get a sense of how your clients with each connection I create.',
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'yV5MVn5TnL2oAlVFSd8Q',
    label: 'Main Ingredients For Success',
    text: 'How can I serve you?',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'yWdFwhgDAWGbAQSm0lEo',
    label: 'Your Vegetable Preference',
    text: 'Do you eat the recommended 2 cups of veggies per day, friend?',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'yaSKcmCDfoz6TbEA1kDx',
    label: 'People That Make You Love What You Do',
    text: 'Tag the person or people that make you love what you do below!',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'ycW9Yc7aFgxPxth81GO8',
    label: "Who's Your Boss",
    text: "Who's your boss?",
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'yciaXWi9zLZxa2S9IFzy',
    label: 'Why Your Clients/Customers Choose You',
    text:
      "I'm so thankful for them, and if you feel like my might be a good fit for you, hit me up in my DMs for more info!",
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'ygHRrwCuUWlCEs5kTqaS',
    label: 'A Sweet Fact',
    text: 'What questions do you have for me today?',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'yiNVotv0RFTdqpIfctSC',
    label: 'Who Your Business Is For',
    text:
      'For my part, I can tell you my all natural skincare products are uniquely suited to men and women who are looking for organic body products.',
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'yjWBY2FKXlSTwql1iTlI',
    label: 'Out of Office Notice',
    text: 'What are your plans?',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'yndREXHbzHEcDwvy6E87',
    label: "What You'd Get Away From ",
    text: 'Spill!',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'yoB6tlZxv3bHO4vz5mzB',
    label: 'Your Happy Hour(s)',
    text: "It's Happy Hour!",
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'ypAsTaI5zOPLnm50CEPX',
    label: 'What You Do and Why',
    text: 'Come on in, friend!',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'yrRfY0BvKHh0e5dnixSf',
    label: 'What Keeps You Creative',
    text:
      'But on the rare occasion that I am burning the midnight oil and need an extra boost of creativity, nothing gives me more spark than drinking some tea and burning a candle.',
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'yzVytyeKKxOoX3416KrI',
    label: 'A Song Lyric That Motivates You',
    text: "Sometimes it's a quote, a book, or a person that motivates us.",
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'z1zfBeIpfKhtKgMIcpnw',
    label: 'Making Confessions',
    text: 'I have a confession to make.',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'z2KdI8FACprrifiYcbBK',
    label: "When You're the Most Productive",
    text: 'Cast your vote in the comments!',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'z3t8ppmMMDl7le4cI9ot',
    label: 'Your Secret Superfood',
    text: 'What a week!',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'z4TLsgBVeduMFR4e8NaC',
    label: 'Productive Time of Day',
    text:
      'And to really get my day off to the right kind of start, I like to begin with a sweaty run.',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'z7Cl6PRJe2r2Vx9V6Wy4',
    label: 'Finishing Touches',
    text: "Please tell me I'm not the only one who's *extra*.",
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'zBXDkaIgrRBnWz7tKaqy',
    label: 'Facing Your Fear',
    text: "I've read that the fears we don't face become our limits.",
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'zENUHhsp2Y3KEn8EV9FC',
    label: 'Bag of Tricks',
    text: "What's indispensable in your bag of tricks?",
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'zHEOPBqY1eMNdLjfMq3K',
    label: "Exciting Things on This Week's Schedule",
    text: "How are your week's plans shaping up?",
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'zHRqZs4QSsRIwCv51KoT',
    label: "How You're Giving Back",
    text: "We all know that it's better to give than to receive!",
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'zKMalzi9gsnpI3NJBiSu',
    label: 'Best Places to Create',
    text: 'Let me know below if you need more ideas or encouragement to jumpstart your creativity!',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'zKXdNPTdqDHkeqP4EWTk',
    label: "How You're Above Average",
    text:
      'Aside from never going to any showing without Penny, my insanely well-behaved Golden Retriever, what really makes me unique is the individualized attention I give each of my clients, and that is something I am proud of!',
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'zKw6nY3g3Q6IhdGVDskI',
    label: 'A Non-Pushy Post',
    text:
      "I'm not particularly pushy or overbearing, unless I'm running late, so this post isn't me being pushy or overbearing at all.",
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'zNT5Esius53fLlL8Kmh0',
    label: "3 Things You're Grateful For",
    text: 'Feel free to elaborate, or keep it super simple.',
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'zQHgWJnK5PGbHSDUrXm1',
    label: 'What Your Most Used Emojis Say About You',
    text:
      'Head to your emoji screen and find the 3 most frequently used emojis, figure out what that says about you, and then share in the comments.',
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'zdacBDa5re5JXVKxZwyK',
    label: 'Crushing Your Fears',
    text:
      "These days, I'm still sometimes scared of networking events, but I manage to get through that by explain how you overcome your fear.",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'ziFjrBjMzPGo5hR52GKU',
    label: 'Popular Songs Then & Now',
    text: "What's your song these days?",
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'ziH7uTvp0c2Q15QpFGv3',
    label: 'Announce Your Live Webinar/Challenge/Podcast Episode/etc.',
    text: 'Will I see you there?',
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'zjBf4NedOHa0ntPapO9t',
    label: 'Something You Just Finished',
    text: "Oh man, that salad was soooooo good, I'll be sharing the recipe on my blog later!",
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'zki4yC6rgSAt9qTG2Sb1',
    label: "What You're Waiting For",
    text:
      "This season won't last forever, and when your dreams do come to fruition, it will be better than you could have imagined!",
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'zlOelgQnZJzbWFuPxlNs',
    label: 'Sharing Some Cheesy Tips',
    text: ') Share tips.',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'zmknof7x9JxfB5IEBP63',
    label: 'Announcing An Upcoming Event',
    text: "Comment below if you'll be attending!",
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'zp0PZKyFkQsmwbKF9Fii',
    label: 'Home Is Where The _____ Is',
    text: 'For me, that means home is where the thing that makes home feel like home is.',
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'zp5tlu5M53K09UswNxKC',
    label: 'Why Your Product/Service is Right for Your Clients/Customers',
    text: 'Send me a DM!',
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'zsMnWfrDFugq6jEqaqvq',
    label: 'Sharing Your Philosophy',
    text: 'What profound statement do you live by?',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'zynJLcCTWR10FRkIMx3E',
    label: 'Telling Your Story',
    text: "What's an element of your story most people wouldn't expect to hear?",
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'vnhhqpysrFQYyOG4UxDr',
    label: 'Tools of Your Trade',
    text: "Sometimes it's fun to see behind the scenes of a your profession, right?",
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'QoxRPK3CVpN7eIjTlOHB',
    label: "Your Ideal Client's/Customer's Pressure Point",
    text: "Are you struggling with your Ideal Client's?",
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 's9JSQOH3HtSjgLAOylg9',
    label: "Your Customer's/Client's Favorite Thing",
    text: "If you'd like to see for yourself,schedule an appointment at www.",
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'fytUmRZAOVXwyuH1HkzO',
    label: 'Who Needs Your Product/Service',
    text: 'Can I be blunt?',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'qJEXys5WGc9HqAeUVsWd',
    label: 'A Snapshot of Your Day',
    text:
      'As a graphic designer, I LIVE for days where I get to let my creative juices flow in the form of designing logos for other business owners.',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'nJvSiZkghiKMFvTXguO5',
    label: 'Favorite Work Drink/Snack',
    text:
      'Iced mocha lattes keep me and caffeinated and focused, so I can pursue my plans to launch my new course!',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'wiz6NjKML7Tfyt0ZSXm2',
    label: 'Filling A Gap',
    text: "I'd be honored to help you!",
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'mf7mALKU5ySxPcqAzhW2',
    label: 'Tips Related to Your Business',
    text:
      "If you thought those tips were helpful, here's one more: Hire me as your 1:1 fitness trainer!",
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'fQTRnR2bzTWi5FeKtJTo',
    label: 'A Friendly Nudge',
    text: 'Sometimes, we all need a little nudge.',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'tXSzjQijST4n0D9hc4OT',
    label: 'This or That Game',
    text: 'Your answerDogs or cats?',
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'yayNbruZ6bE18BpmqfYa',
    label: 'A Pain Point You Help Overcome',
    text: "Send me a DM if you're interested in learning more.",
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'jeEMKZ8k7thnEmI2DUL3',
    label: 'Now Is The Time To Invest',
    text: 'This is a PSA for all word or phrase to describe your ideal client!',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'qJVBP3mZoXPsto8Wntgw',
    label: 'How You Rewrote Your Story',
    text: 'How has your story changed over the years?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'HZICsPWqmrU19HMIJ7sh',
    label: "Advice That's Helped You",
    text: 'Anyone else a hot mess with directions?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'cHQru3mlN6cavqiywEWT',
    label: 'Prioritizing Process Over Perfection',
    text: 'How will you choose process over perfection today?',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'UHqLhwBWxscxYZtWTUSt',
    label: 'A Slice of Your Day',
    text: 'Can you relate?',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'JhcbAWZg17BiKdV23NcZ',
    label: 'What Makes You YOU',
    text: 'I narrate my own cooking show every time I make dinner.',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'xXRkyXNwK1VcEnKEo1fE',
    label: 'How You Serve It Up in Style',
    text:
      'Here at business name, I like to serve up my home furnishings in style: with 2-day shipping and optional gift wrapping, that is!',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: '4FqDeaFRf7Tio0p45UE3',
    label: 'How You Change with the Season',
    text: "What's the change of season mean to you?",
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: '52ASYK1li7ROEDnxNgVf',
    label: 'Plans for the Future',
    text: "That dream you're too afraid to say out loud?",
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: '7Ordsnrh3kZBCtdHWaxh',
    label: "3 Times You've Trusted Your Gut",
    text:
      'They are:The first time your instincts paid off The time I decided not to go downtown with my friends because I had an uneasy feeling… and it ended up saving my life.',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'Tgk5h3bg8vJYALPZ2R5I',
    label: 'Your Current Challenge',
    text: "You're the best!",
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'peaTPMVZsbSsll0ZyBVP',
    label: 'Thoughts On Mindset And Transformation',
    text: 'What kind of mindset transformation have you experienced recently?',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'RAVWi3B2z0qLl1WKEt0x',
    label: 'A Look into Your Life',
    text: 'tell me about yours!',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'M368znbiNo5mTOpcBI2d',
    label: 'What Brings You Pleasure',
    text: 'Have you got any guilty pleasures?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'nlqK90VOv75bRTb9pTIX',
    label: 'Be Your Authentic Self',
    text: 'This is your friendly reminder to be yourself today!',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: '8R9asYwdVi6vgP29FpD1',
    label: "Reminder: You're Awesome",
    text: 'Tag someone in the comments who could use this encouragement today.',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'mv8fJ6YLaDSn44V5ADjH',
    label: 'Love to Battle Overwhelm',
    text: 'Anyone feeling a wee bit overwhelmed lately?',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'Dhw583sewq4mzT8g3SQd',
    label: 'Conquering that Blank Page Feeling',
    text: "Let's fill the comments with ideas we can all save for rainy day inspiration!",
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'nuph0dCiMLS1I337CiX5',
    label: 'Music to Your Ears',
    text: "You know what's music to my ears?",
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'GwR4Kwq6bchCrjcwtxbO',
    label: 'When You Need A Break',
    text: 'Is there anyone out there who could use a day off?',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'EL7JJXlKxBt2agwtJ54l',
    label: "Something You've Gotten Better At",
    text: 'I wish I was more confident in what skills I brought to the table when I was younger.',
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: '9X35qqlrtisOe41ixi69',
    label: 'When You Have Your Head in the Clouds',
    text: 'Got your head in the clouds?',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'oLpeDoAwB2BuIodLsVRf',
    label: 'How You Give Clients Peace',
    text: 'How can I help you today?',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'jYvgrIg6gol8HfhiuT55',
    label: 'How Your Product/Service Drives Results',
    text: 'You REALLY need to hear this.',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'RkZZS4qCrQ6CgZmNuFwh',
    label: 'What Pairs Well with Your Product/Service',
    text: 'mywebsite.',
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'lWRi8WB3kc9cyxMPx7D3',
    label: "How You Turn A Client's 'Sour' into 'Sweet'",
    text: 'I know house-hunting can feel complicated, but I want to ensure you get the very best!',
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'g9lWEKAEFeJcm8DpVMkv',
    label: 'A Big Announcement',
    text: 'Can you keep a secret?',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'ZcUvfWjeBNT7LX58y43Z',
    label: 'Client/Customer Testimonial',
    text: 'How do you measure success?',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'EGUnHy5iFvf9hFOelCeq',
    label: 'Celebrate A Rave Review',
    text: 'What does it take for you to really celebrate something?',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: '98SaexZ1VJPbt4hmZ0th',
    label: 'You Got This, Because I Got You',
    text: 'Book a call today using the link in my bio!',
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: '3bgdDqQQCLcZ880zRimT',
    label: 'Sweet and Savory Days',
    text:
      "Basically, sweet days are ones to cherish and savory days are ones to hustle for, and today, I'm having a sweet day!",
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: '9jr1wNxXy5nmfs88NS4z',
    label: 'I Wear Many Hats',
    text: 'Where are my hat-lovers?',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'XAcPAAzUhgHhUOSthxvi',
    label: 'How You Get Everything Done',
    text: 'How do you ensure everything gets done in a day?',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: '1lxUljHzLVbDfSWOb3cs',
    label: 'Dreaming of Going To...',
    text: "Who's a dreamer out there?",
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'sDrVYcaaK2mr3CryWR40',
    label: 'Simple Inspiration',
    text:
      "This answer may surprise you: When asked what has the greatest influence on my creative process, people usually think I'm kidding when I say the ginger-citrus scented candle that's permanently lit on my desk during work hours.",
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'zfT0BN7IkPhwcGGcORUV',
    label: 'Slowing Life Down',
    text: "Let's be honest… sometimes life needs to slooooooww down.",
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: '36XIcgMXXDUdO8XgIL1x',
    label: 'What Fuels Me',
    text: "That's a better boost than Starbucks any day!",
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'zAACPcjXU1QxRx0NzNzH',
    label: 'Where Home Is to You',
    text: 'Where is home to you?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'O2u18d5lr7MfNuyn9yz5',
    label: 'If I Played Hooky Today',
    text: 'Wanna know a secret?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'Cnh8h2O8qxVPzghP40A5',
    label: 'Finding Beauty in Little Things',
    text:
      "What's all this hustle for if we don't allow ourselves time to appreciate and celebrate the little things?",
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'qY9dgSeVlIr0IRWZGSYc',
    label: 'Your Juiciest High School Secret',
    text: "We've all had our moments!",
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: '2jvUfBGPMSmY7lNNRpA2',
    label: 'A: Coffee, Q: ???',
    text: 'Can we play a game?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'AZaj35iaXcfawooqG2C6',
    label: "When Things Don't Go According to Plan",
    text: "Try as I might, sometimes things just don't go according to plan.",
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'yXuk1s9JdcysYT51VPRs',
    label: 'The Light at the End of the Tunnel',
    text: 'In a rut?',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'EQEzV0tNPwcpw0b4VZL5',
    label: 'A Trip Down Memory Lane',
    text:
      "Rekindling that feeling may take a little more focus, but I've discovered it's worth the extra effort.",
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'Srkd6bMMre47IJRk6CfZ',
    label: 'Rough Day Remedies',
    text: 'What would you add to this "rough day remedies" list?',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'jMmt5geCkLO0qDLWrBTZ',
    label: 'Your Favorite Season',
    text: "What's your favorite season?",
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'AHs8RlykkfLv6EeX95DR',
    label: 'An Embarrassing Story--Lesson Learned',
    text: "This is really embarrassing… good thing we're friends!",
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'go5uhtXPrEADGU0e61Rx',
    label: 'Three Words that Describe You',
    text: "It's been a minute since I shared a little tid-bit about myself.",
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: '7KGYk1PPcWs6pnZUEpsk',
    label: "Little Things You're Thankful For",
    text:
      "As it happens it's not very rainy where I live, but a heavy marine layer that creeps in from the Pacific keeps a thick haze over our neighborhood til at least 8 every day.",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: '714FsOBRSehX5yZ9bS8c',
    label: 'Getting Customers Comfortable',
    text:
      "Whether you're in your holey sweats or hiring your wedding photographer, comfort is everything.",
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: '7q6baQFHQCFnOMnYzY3t',
    label: 'What Your Ideal Client is Daydreaming Of',
    text: 'What do you often daydream about?',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'JWsrfHjHEfL0yXV4cXmL',
    label: 'Warm And Fuzzy Details',
    text: 'Could you indulge me for a moment?',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: '3WBDr1QL7QK4GiC3Gub4',
    label: 'How Your Product/Service Adds Up',
    text: 'Visit www.',
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: '5o8tYG0CdgLJbkrN9PiD',
    label: 'A Giveaway Announcement',
    text: "Who's ready for a GIVEAWAY?",
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'XzZKqiSPskqJewbExmVK',
    label: 'What You Love About Us',
    text: 'Can I ask you a question?',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: '7y321kLci9KWYbrzpTCb',
    label: 'Sharing Exciting Updates',
    text: "You are one of the first to know, and I couldn't be MORE excited about that!",
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'BpK9VEqvalSpjbm5BLRR',
    label: 'Refreshing Your Morning Routine',
    text: 'Wow, has this made all the difference… My morning routine craved a refresh.',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'afwvBkUK52t0QaHUqzVD',
    label: 'Getting Lost & Letting Go',
    text: 'Getting lost used to scare me.',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: '09IbwR9yHDNvx0ljclN0',
    label: 'What You Love About Your Job',
    text: 'What are you grateful for today?',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'pGV4t2vANtsdnie02KbY',
    label: 'Your Work Traditions',
    text: 'What about yours?',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'JbA03wGw3zrHDwm70qiD',
    label: 'A  Time Capsule of Your Business',
    text: 'What would you add to it?',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: '4Na8gZJEYZ3wrhgenApn',
    label: 'An Ode to Your Desk',
    text: 'An ode to my desk: Oh, desk, thank you for your constant support (literally).',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'm30XockfZIXqIGGVn13X',
    label: 'A Peek into Your Process',
    text:
      'I hope you enjoyed insight into my business, and if you have questions, let me know in the comments!',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'HMqlMBlyJmZHirrV4APf',
    label: 'How Your Friends Describe You',
    text: "I'd love to get to know you better.",
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'N7zVJaSZ4KKfzVHa7k2j',
    label: 'A Weird Childhood Trait',
    text: 'Wanna hear something weird?',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'YDgp7ERhOOIaiBOz17hK',
    label: 'How You Met Your Followers',
    text: 'You will one day become a client.',
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'SDDg7rEYu1SNXPvJZadp',
    label: 'What You Dreamed of Becoming vs. Now',
    text:
      'When I am living in my purpose, I take a second to raise a glass of champagne and thank myself for pursuing a career that makes me feel alive!',
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'BWCyP3doeqgWYy475Rk9',
    label: 'Who Inspires You',
    text:
      "They inspire me to keep pushing forward, and there's a piece of me that wants to show their belief in me to become a your profession, was well worth their time and support.",
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'rJ6Ch5xr3kLmAfY9Tpwb',
    label: 'A Secret for Your Clients/Customers',
    text: 'Dear client: can I share a secret with you?',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'hwjGcUqlgrPs8RSlKywu',
    label: 'Thanking Your Followers',
    text: 'Tag them in the comments!',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'cICLK7bEIN3HiQJtIYir',
    label: 'How You Can Help',
    text:
      "You see, my specialty is handwriting invitations, but what I'm really good at is helping you save time from writing everything yourself.",
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'YG9u4lJzpKhJKa6lsGWb',
    label: 'How You Love Spending Your Time',
    text:
      'Remember when you were a kid, and the school guidance counselor asked what you wanted to be when you grew up?',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'Omf0pWvEgNEshHWhgayJ',
    label: 'The Nicest Thing Someone Said About Your Business',
    text: 'Click the link in my bio!',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'TYLuHDFOWKYoXa2vSuq1',
    label: 'An Unexpected Use of Your Product/Service',
    text: 'Want to hear something crazy?',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'dQ9W04uTnMvrjNN6pP0Q',
    label: 'What You Do',
    text: 'This may seem silly, but do you know what I DO?',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'U4Ososqs1jWaiuiQn5in',
    label: 'Why They Need Your Product/Service',
    text: 'I only have 2 more spots available for 1:1 coaching… DM me to claim one!',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'VUjjhs7yKxtG20nU4ZZh',
    label: 'What Inspired A Client/Customer Testimonial',
    text: 'What are you waiting for… Get yours today at www.',
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: '16AZ8Sh1g4Ch6khXcUVc',
    label: 'Your Favorite Product/Service',
    text:
      "I'll be honest, choosing my favorite product is kind of like asking your mom to pick her favorite child.",
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: '9TxAoMRMI1iLkULLzwu2',
    label: 'The Little Details',
    text:
      "It's important to me because we want to personally thank each and every one of our customers for supporting our small business!",
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'lzs4ebNieGca7mFl14HU',
    label: 'Your Perfect Day',
    text:
      "Let's talk about perfection: There's something about Summer that just makes me want to spend an afternoon in a movie theater.",
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'WlAMRGQEyfE9JJTdLJyu',
    label: "What You're Listening To",
    text: 'What are you listening to these days that fits that bill?',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'cdY7U5pegEA1mhiOjvrc',
    label: 'Travel Recommendations',
    text: "I'm hatching a plan to get out of town for a while.",
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'MLGH9x0bgZkkt75i8o5g',
    label: 'Your Ultimate Refreshment',
    text: "What's your ultimate refreshment?",
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'qyxtSAXdkedmEqfUJoNh',
    label: 'Your Favorite Quote',
    text: 'Do you have a favorite quote?',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'u6UmDxOaWz3UORGZJHDS',
    label: "The Best Advice You've Received",
    text: "I'd love to hear your best advice in the comments below, too!",
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'kqYHJPuRrwuQi0pXrl2k',
    label: "What You'd Tell Your Ten-Years-Ago Self",
    text: 'What would you say?',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: '6RTlsOwnxUd7Cq4Cv74O',
    label: "3 Things You're Grateful For",
    text: "Take stock, right this second, of 3 things you're grateful for.",
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'SS5QOasZCsGey1YX1xZl',
    label: "New Year's Eve (December 31)",
    text: 'How about you?',
    type: 'call-to-action',
    category: 'holiday'
  },
  {
    _id: '1gENnvGo68YrJde3hok5',
    label: 'National Employee Day (First Friday in March)',
    text: "It's National Employee Day!",
    type: 'hook',
    category: 'holiday'
  },
  {
    _id: 'JsDmBr985RVZNub6kJDL',
    label: 'National Napping Day (The Day Following the Beginning of Daylight Savings Time)',
    text: 'So the day after we set our clocks ahead, what do you know?',
    type: 'hook',
    category: 'holiday'
  },
  {
    _id: 'W02Sgfmq3rgQXhoD6fMu',
    label: "St. Patrick's Day (March 17)",
    text: "It must be the luck o' the Irish that this year St.",
    type: 'hook',
    category: 'holiday'
  },
  {
    _id: 'DYQuHg0JmPNWFlMWgYhk',
    label: 'Easter (On a Sunday between March 22 and April 25)',
    text:
      "In other words, it's egg-hunting time and I'm giddy, hiding colorful, plastic eggs for my nieces and nephews to find!",
    type: 'insight',
    category: 'holiday'
  },
  {
    _id: 'sf3CpzgiFpH30ok7py70',
    label: 'National Ice Cream Day (Third Sunday in July)',
    text:
      "It's National Ice Cream Day and I, for one, plan to celebrate with two scoops of Chunky Monkey!",
    type: 'hook',
    category: 'holiday'
  },
  {
    _id: '0WOuhvCNyNial1cRJtsn',
    label: 'First Day of Fall (Between September 21 and 24, Northern Hemisphere)',
    text: "It's the first day of Fall!",
    type: 'hook',
    category: 'holiday'
  },
  {
    _id: 'Yf93yF4aVtpidhkEBd0d',
    label: 'National Coffee Day (September 29)',
    text:
      "In case you were unaware, friends, it's National Coffee Day, which is a little weird because I'm not sure how that's any different from a regular day.",
    type: 'hook',
    category: 'holiday'
  },
  {
    _id: '3wBHHCLxXceS6Ldy2mDp',
    label: 'Send A Card to A Friend Day (February 7)',
    text: "I'm IN!",
    type: 'insight',
    category: 'holiday'
  },
  {
    _id: '44DKOtlpeEdyqNzuZBVD',
    label: 'National Pizza Day (February 9)',
    text: "It's National Pizza Day!",
    type: 'hook',
    category: 'holiday'
  },
  {
    _id: 'dw2aIsejedtxv2TmU9eP',
    label: "Valentine's Day (February 14)",
    text: "May cupid's arrow strike you a thousand times!",
    type: 'call-to-action',
    category: 'holiday'
  },
  {
    _id: 'nyB74ymxfRKD6JqhF5TK',
    label: 'National Margarita Day (February 22)',
    text: 'Sí o no?',
    type: 'call-to-action',
    category: 'holiday'
  },
  {
    _id: 'gqWGXX47PBpjS1reY4JF',
    label: 'National Employee Day (First Friday in March)',
    text:
      "It's National Employee Day, so cheers to me, since I'm the boss and I guess this also makes me Employee of the Year, right?",
    type: 'hook',
    category: 'holiday'
  },
  {
    _id: 'ciDtkXEDDtYSoUsHm90g',
    label: "St Patrick's Day (March 17)",
    text:
      'Are you: A: B: or C: Feeling lucky as a leprechaun ready to celebrate the weekend with shenanigans, corned-beef, and Guinness?',
    type: 'insight',
    category: 'holiday'
  },
  {
    _id: 'kyoz9uAU2odxaM6I2tit',
    label:
      'Get to Know Your Customers Day (The Third Thursdays of January, April, July and October)',
    text: 'Did you know that today is "Get to Know Your Customers Day"?',
    type: 'hook',
    category: 'holiday'
  },
  {
    _id: 'f11zX12t39ZlDBnxZSti',
    label: 'Easter (On a Sunday between March 22 and April 25)',
    text: 'Better yet, what Easter candy are you eating?',
    type: 'call-to-action',
    category: 'holiday'
  },
  {
    _id: 'GMbPLCNQHwm371hR6QpX',
    label: "Mother's Day (Second Sunday of May - US)",
    text: "Happy Mother's Day!",
    type: 'hook',
    category: 'holiday'
  },
  {
    _id: 'vB5KeSPARqp2vQcw5Wy8',
    label: 'Memorial Day (Last Monday of May - US)',
    text:
      "I'm grateful I live in a country where I am free to and take my family to church on Sundays.",
    type: 'hook',
    category: 'holiday'
  },
  {
    _id: 'syS3uOITir3M6iutrI9a',
    label: "Father's Day (Third Sunday of June - US)",
    text: 'Who are you sending love to today?',
    type: 'call-to-action',
    category: 'holiday'
  },
  {
    _id: 'mxXzahttNm0U5wlaBSCV',
    label: 'First Day of Summer (Between June 20 and 22, Northern Hemisphere)',
    text: 'How do you feel about Summer?',
    type: 'hook',
    category: 'holiday'
  },
  {
    _id: 'HB4T6LQaLqrfAYNrWRPN',
    label: 'National Piña Colada Day (July 10)',
    text: 'Some might argue the taste of a tropical cocktail says it just as well!',
    type: 'insight',
    category: 'holiday'
  },
  {
    _id: 'HWgVYCHWRpzd8lzMMwAO',
    label:
      'Get to Know Your Customers Day (The Third Thursdays of January, April, July and October)',
    text: "(I'm a Slytherin-Taurus)!",
    type: 'call-to-action',
    category: 'holiday'
  },
  {
    _id: 'K41C3RT49an8sDCgo4aP',
    label: 'National Lazy Day (August 10)',
    text: 'How are you spending your lazy Saturday?',
    type: 'call-to-action',
    category: 'holiday'
  },
  {
    _id: 'Tj2k2eu0xuT2MEuWCNYv',
    label: 'Cyber Monday (Monday after Thanksgiving - US)',
    text: 'Today only!',
    type: 'call-to-action',
    category: 'holiday'
  },
  {
    _id: '8EplUtHQeY3JcqN1Ageh',
    label: "New Year's Day (January 1)",
    text: "How do you spend New Year's Day?",
    type: 'call-to-action',
    category: 'holiday'
  },
  {
    _id: 'IBZ3wfySRUZVJrvNR3V3',
    label: 'Lunar New Year (Between January 1 and February 20)',
    text: "I'm planning  dinner at our fave Sichuan spot and I can't wait.",
    type: 'insight',
    category: 'holiday'
  },
  {
    _id: '9LRVn3yEexeS25bLlPjG',
    label: 'Happy Lunar New Year!',
    text: "It's truth time.",
    type: 'hook',
    category: 'holiday'
  },
  {
    _id: 'Lc283aeiYwT4U6ljcV2r',
    label: 'National Random Acts of Kindness Day (February 17)',
    text: 'Who likes FREE stuff?',
    type: 'hook',
    category: 'holiday'
  },
  {
    _id: 'jvHIkcNci57hAqWUnCDc',
    label: 'National I Want You To Be Happy Day (March 3)',
    text: 'Head to the link in my profile to feel the love, friend.',
    type: 'call-to-action',
    category: 'holiday'
  },
  {
    _id: 'uny7SextwBL2hAFcgWbT',
    label: 'National Write Down Your Story Day (March 14)',
    text: "It's National Write Down Your Story Day, so there's no better time to share!",
    type: 'insight',
    category: 'holiday'
  },
  {
    _id: 'c9ov3u1Sss9aAKgKkfaq',
    label: 'National Reach As High As You Can Day (April 14)',
    text: 'Otherwise, I may not be where I am today!',
    type: 'insight',
    category: 'holiday'
  },
  {
    _id: 'WfPLHkVNs9emxA3dvQOy',
    label: 'National Honesty Day (April 30)',
    text: 'Spill the tea!',
    type: 'call-to-action',
    category: 'holiday'
  },
  {
    _id: 'qZfPq5Lbk6PyvIgnTNHu',
    label: 'May Day (May 1)',
    text: 'How are you welcoming spring weather?',
    type: 'call-to-action',
    category: 'holiday'
  },
  {
    _id: '9vKpiVUGbJ0TZrgioACH',
    label: "Mother's Day (Second Sunday of May - US)",
    text: 'Can we all agree not to work today?',
    type: 'hook',
    category: 'holiday'
  },
  {
    _id: 'tzcsANO6zesgQLiKJw30',
    label: 'National Doughnut Day (First Friday of June)',
    text: "C'mon, play along and cast your vote below!",
    type: 'call-to-action',
    category: 'holiday'
  },
  {
    _id: 'CW5uRuGqeKTXQwcfDLps',
    label: 'National Best Friends Day (June 8)',
    text: "How'd you meet your best friend?",
    type: 'call-to-action',
    category: 'holiday'
  },
  {
    _id: '1In2Dld9nR34MUVpdUpZ',
    label: 'National Relaxation Day (August 15)',
    text: "Today is no regular day of the week, friends--it's National Relaxation Day!",
    type: 'hook',
    category: 'holiday'
  },
  {
    _id: 'ZVSGPdIpopy8Jn6aY6IP',
    label: 'Labor Day (First Monday of September - US)',
    text: 'Whatcha doing for the long Labor Day weekend, friend?',
    type: 'hook',
    category: 'holiday'
  },
  {
    _id: 'vxARzjhZeuhpCoUAWDmU',
    label: 'End of Daylight Savings (First Sunday of November - US)',
    text:
      'Besides the extra hour of sleep, I like this time of year because it means the holiday season is approaching.',
    type: 'insight',
    category: 'holiday'
  },
  {
    _id: 'byz1UJeTzqvcbKKVuLnY',
    label: 'Thanksgiving Day (Fourth Thursday of November - US)',
    text: 'Tell me your favorite tradition!',
    type: 'call-to-action',
    category: 'holiday'
  },
  {
    _id: 'Fke8GWAaVYnqPwaPoap6',
    label: 'Christmas (December 25)',
    text: 'Ho, Ho, Ho, Meeeeeerrrry Christmas!',
    type: 'hook',
    category: 'holiday'
  },
  {
    _id: 'v88EfcSW7Gs6TimYE5SM',
    label: 'New Years Eve (December 31)',
    text:
      'Raise your hand if last year looked anything like the plans you made for it at the end of last year!',
    type: 'hook',
    category: 'holiday'
  },
  {
    _id: 'ud04cer7EkeGrKaqyaXl',
    label: 'Awkward Moments Day (March 18)',
    text: "I promise I'll laugh WITH you, not AT you!",
    type: 'call-to-action',
    category: 'holiday'
  },
  {
    _id: 'Z42FoqUw7ya7cM6yx7CM',
    label: 'Make Your Own Holiday Day (March 26)',
    text:
      'Since the calendar is giving me permission to make up my own holiday, I declare today as &quot;&quot; Eat Chocolate Cake For Breakfast Day.',
    type: 'insight',
    category: 'holiday'
  },
  {
    _id: 'WwCQnoTukKwmvXYKWvaX',
    label: 'Festival of Sleep Day (January 3)',
    text: 'Well get back in bed, friend, because today is the Festival of Sleep Day!',
    type: 'insight',
    category: 'holiday'
  },
  {
    _id: 'woyWtmsRmYJ96E1KMj6h',
    label:
      'Get to Know Your Customers Day (The Third Thursdays of January, April, July and October)',
    text: 'Do you know why today is EXTRA special?',
    type: 'hook',
    category: 'holiday'
  },
  {
    _id: '0XTVX5bkCKebGqPp9kVi',
    label: 'Send a Card to a Friend Day (February 7)',
    text: "So, tell me: how are you going to brighten someone's day today?",
    type: 'call-to-action',
    category: 'holiday'
  },
  {
    _id: '4Gg0t0RMsLJzq3tIeTPH',
    label: "Valentine's Day (February 14)",
    text: 'Tell me about your perfect day!',
    type: 'call-to-action',
    category: 'holiday'
  },
  {
    _id: 'UmO4f6BHJPM2rrJmK2TL',
    label: "International Women's Day (March 8)",
    text:
      "In honor of International Women's Day, I'd like to raise a glass to three women in my life who are always there to lift me up when I'm down: my sister, my business partner, and my grandmother.",
    type: 'hook',
    category: 'holiday'
  },
  {
    _id: 'KvwmKi9guBaVOviriDYT',
    label: 'National Napping Day (The Day Following the Beginning of Daylight Savings Time)',
    text: 'Are you ready for some incredible news?',
    type: 'hook',
    category: 'holiday'
  },
  {
    _id: 'WuWCg9p8YCimcyYRT1jP',
    label: "April Fools' Day (April 1)",
    text: "What's the best April Fools' prank you've fallen for?",
    type: 'call-to-action',
    category: 'holiday'
  },
  {
    _id: 'SRswZ1TYbLAxYJ2cZS1b',
    label: 'Easter (On a Sunday between March 22 and April 25)',
    text: 'Rise and shine, dreamers!',
    type: 'hook',
    category: 'holiday'
  },
  {
    _id: 'lZf3V7jupdsklkAoGJKc',
    label: "Mother's Day (Second Sunday of May - US)",
    text: "Happy Mother's Day to all of the mothers out there!",
    type: 'hook',
    category: 'holiday'
  },
  {
    _id: 'mVhYmjB2ruc7H4Ys5M9V',
    label: 'The Blank Canvas of Your Work',
    text: 'Shoot me a DM to book a consultation.',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'XBikdhA8twl1PdmShy8h',
    label: 'Spicing Things Up',
    text: 'Could you use a hand spicing things up a little?',
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'SS0MUxpVYpJT6EOAAnPC',
    label: 'A Surprising Routine',
    text: 'Share below!',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'tAxwgfQOM2qgSL9e1dYO',
    label: 'Your Personal Work Style',
    text: 'How do you infuse your personality into what you do?',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'vuZq2mTEviDwTZgbZfpr',
    label: 'Getting Through Busy Times',
    text:
      'I keep my health in order with plenty of vitamin C, I meditate daily to keep my mind right, and am sure to watch plenty of rom-coms to keep my spirits high all month long.',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'ptWuJo0P6wk5KTJ1JnKF',
    label: 'How it Started',
    text: "What's one unexpected lesson you're grateful to have learned in the last few years?",
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'cl3qOLSG62CDExvPFqc1',
    label: 'Gratitude for Little and Big Things',
    text:
      "Today, we're expressing gratitude for the little things in life, like steaming hot coffee, as well as the big things, like our amazing clients!",
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'ra388UKCscfLWDM5jkoy',
    label: 'My Magazine Cover Story',
    text: "What's the most important thing you'd share in your cover story?",
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: '5SD2IHTlPDyU3F23f9LN',
    label: 'Give Yourself a Compliment',
    text: 'So, tell me… What do you appreciate about yourself?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'Qrr0ybq77SL5nZEhZo0M',
    label: 'The Road Less Traveled',
    text:
      'Robert Frost said, "Two roads diverged in a wood and I - I took the one less traveled by, and that has made all the difference.',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'u2NfPpU7iKXkE1R0pShj',
    label: "It's a Good Day When…",
    text: "Let's play a game!",
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'VvwoKiguTUyTP23drl11',
    label: 'Navigating the Unexpected',
    text: 'It was the moment that changed everything.',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'w6X0cvUdCXFjc3A02UuK',
    label: 'Taking One Step at a Time',
    text: 'Everyone knows the age-old adage: "How do you eat an elephant?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'kXgXsgAwMSB0Dkv0W9AS',
    label: 'How I Got Here',
    text: 'First, describe the spark that got you interested.',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'N92fFXAqC02kPJCVv0hb',
    label: 'Delicious Compliments',
    text: 'Comment below with a delicious compliment that sweetened your day!',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'lmeKL4VOvidNtL2xDGbd',
    label: 'A Small Cause for Celebration',
    text:
      "Not to be dramatic, but I have a cause for celebration today: seemingly trivial reason to celebrate it's already 2pm and I've only had one cup of coffee.",
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'CewC5AiUQeQOT6TnUWpj',
    label: 'Words of Encouragement',
    text: 'Tag someone who could use this reminder, too!',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'KVpPLNM1lLiVyeGLkkdb',
    label: 'What I Learned From Regret',
    text: 'I regretted it instantly.',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'imklpJtAKTnYOsAtzW5N',
    label: 'Your Organization Level',
    text:
      "I'm dying to know… are you generally pretty organized, or would Marie Kondo have a field trip walking through your home?",
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: '8b6UATLMdDWzlPg4Sx2q',
    label: 'How I Grew Up',
    text:
      'My love for what you do has only grown ever since, and my amazing team keeps me rooted in why I do what I do.',
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'iJjJLMfT7NKKzEoNGgs5',
    label: 'Get to Know You Game',
    text: "Don't judge!",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'NS0Kms1oHaFSbLwESB0F',
    label: 'Where You Go to Think',
    text: 'Where do you go to refocus your mind?',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'ODH2R1bBJCyGg0FVUlvk',
    label: 'Good News to Keep You Warm',
    text: 'briefly describe a benefit you offer.',
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: '2Llk3jY2nhBOS8OC9d29',
    label: 'How Your Service/Product Makes Life Easier',
    text: 'Spill the tea in the comments below!',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'NlCOyHCPGwYIW1YhK65Q',
    label: 'How Your Product/Service is the Whole Package',
    text: "Ours is BOTH, and I'm ready for you to enjoy the whole package!",
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'fRmVgzE9ZvWwNPFfE77A',
    label: "We've Got Something Hot",
    text: "Wanna know what's even hotter than your morning cuppa?",
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'f1LphjQ5Kc0YwoHjDGk5',
    label: 'Getting the Boost You Need',
    text: "If you drop an emoji below, I'll even DM you a promo code!",
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'rGalHOtOrhOx6WnqZEj3',
    label: 'Something New in the Works',
    text:
      "Drop your guesses in the comments and I'll reveal the answer when you'll reveal the answer!",
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'MmScMzThaW9u7OabQLpX',
    label: 'Something to Party About',
    text: "Want a reason to party that you don't have to wait around all year for?",
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'Ev7TBZSwuuNxzDMaabOZ',
    label: 'The Right Time is Now',
    text: 'We get it.',
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: '8HodWpTIKrLRy4HTp9tY',
    label: 'A Project Coming Together',
    text: "It's always a relief when a project finally comes together, right?",
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'af5T2na8KWP0tVMgBE5I',
    label: 'Lessons The Seasons Teach',
    text: 'What do the changing (or not changing) seasons say to you?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: '4kVubOqkgWr4L8nCR17o',
    label: 'Escape Recommendation',
    text: 'Need an escape?',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'gFL7fbvBAMSR1i0ADx6J',
    label: 'Compartments of Your Business',
    text: 'Drop them below!',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'KYxFd06XL5VBnZ7K3aVv',
    label: "How You 'Soak It All In'",
    text: 'How do you like to soak it all in?',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'vrJuBWp9rS6yDF8atztZ',
    label: 'My Toolkit',
    text: 'Like this post if you agree!',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'qXurMfS8CFdnxCEGyJmp',
    label: 'The Gift of Today',
    text:
      'Whenever I get caught up in the day-to-day stress of baking pastries for the shop every morning at 6am, I always remind myself that today is a gift.',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: '2FtkWjeu2chyBQcoKzhL',
    label: 'How You Prioritize Your Time',
    text: 'How do you prioritize your time?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'g77JL90khBezrAzLXQmi',
    label: 'Dreaming of the Next Adventure',
    text: 'What adventure would you jump at the chance for?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'BlKj7S95BZUijoT1wSX3',
    label: 'Counting 3 Blessings',
    text: "What's one blessing you're counting today?",
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'NPFxN4TlsVXRgHVITMa0',
    label: 'Early Birds vs. Night Owls',
    text: 'Tell me: are you an early bird or a night owl?',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'F96DyveBCtjhRSJHllde',
    label: 'Your Future is Bright',
    text: 'Give them some love in the comments below!',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'aqnRfSW7iIFMKYw9tweK',
    label: 'When Opportunity Knocks',
    text: 'Wanna hear a cool story?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: '41zHo9Sq2GVDWhl98CtQ',
    label: 'Be the Light',
    text: "Real talk: You are the light in someone else's storm right now.",
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'avjnnFDUGGnU1VD86cLd',
    label: 'Words to Cheer Up Your Ideal Client',
    text: 'Feeling down?',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'A2gvUy2MBfY03vKtKWuP',
    label: 'Make A Scary Move',
    text: "Is anyone out there full of doubt even as you're also feeling ready for big growth?",
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'xfJPjsGJ6qohihzIZhAq',
    label: 'Where You Thrive',
    text: 'No judgement here… drop your number in the comments!',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'F8WRJGPkZwOAVQMGWNWw',
    label: 'A Beautiful Mundane Routine',
    text: 'Do you find beauty in the mundane?',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'LQTMYQSeNkWKos7sJq7y',
    label: "What I'm Listening To",
    text:
      "Today's music had to match my mood, which is why I'm currently spinning what you're listening to!",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'TXn46e42JJRPRY9zKl6B',
    label: 'Secret Skill or Talent',
    text: "Yep, it's true… Now I'm dying to know: what's your secret talent?",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'AGljVMTmhwMsPNsSMjiR',
    label: 'How You Prepare to Take a Break',
    text:
      "That's why I want to share a few ways I prepare for some time to myself…First, I first way you get in the mood to relax clear my schedule for a few days,Next, I second way you get in the mood to relax ensure I've delegated all of the tasks that need to be done while I'm away,Then, I third way you get in the mood to relax loosely fill my schedule with things I love.",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: '1TTtD2V5hJzNZiV5Xzdk',
    label: 'How You Love Creating',
    text: 'How can I help you today?',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'xhMUGFJrvJeD3EWftRTc',
    label: 'Keeping it Personal',
    text: 'Let me know below if you crave connection like I do, so I can send you some extra love!',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'pIYsypcZwH8kh5j4zxOC',
    label: 'Raising a Glass to Your Clients/Customers',
    text:
      "Today, I'd like to raise a glass to all of the incredible couples who've hired me as their wedding planner!",
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'j1fyaDbc0wDWIILOzFhi',
    label: 'An Unconventional Approach',
    text: "I'm the type to eat breakfast for dinner just because I feel like it.",
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'JTKISr0xnFVv2Ee2KrEH',
    label: 'Open for Business',
    text: 'This is me sparing you from witnessing my dancing "skills".',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'BPXyeidAwXzvYUzMkiL2',
    label: 'An Announcement That Should Be Framed',
    text: 'Call to action related to the announcement Click the link in my bio to learn more!',
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'B9XhTVKxDOLXVa9AUQ9l',
    label: "What's Cookin'",
    text: 'Will you indulge me, friend?',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'Od7h4XYWFyOXQFNLvxW3',
    label: 'The Secret is Out',
    text: 'The secret is out: my name of your product is stopping people in their tracks!',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'YJdy9wEtf2MWcSdHnvNI',
    label: 'Share A Secret',
    text: 'Want an inside look at something people never see?',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'Ixz5AMnZ7TE0XU16VT7J',
    label: "What You'd Tell Your Past Self",
    text: 'What would you tell your past self if given the opportunity?',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'irXteRuhagrXjCy2odQD',
    label: "How You're Killing It!",
    text: 'You, my friend, are KILLING it!',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'gjAcF0qmXjIgo88ipF9d',
    label: "Sneak Peek of What's Coming Soon",
    text: 'Pssst… I got a sneak peek to share with you.',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'jis4kzQ4Sv2fiLBrNwRE',
    label: 'How You Create Space to Pause',
    text: 'How do you create space for yourself to pause?',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'Cxo09TOsfzyHwrN6bTDn',
    label: 'Turning Dreams Into Reality',
    text:
      "There's plenty of planning involved, of course, but it all starts out as a crazy, colorful mishmash of brightly colored dreams.",
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: '64WUnDvXkbBPYdoWRwZu',
    label: "A Time Traveler's Advice",
    text:
      'If time travel were possible and you had the opportunity to sit down with yourself one year ago, what would you say?',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'GXkXLQJ0rw0Lckqwicrh',
    label: 'Menial Tasks You Enjoy',
    text: 'What small tasks do you enjoy checking off your list?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'Pug5TdjPAf8ZESvaaXxi',
    label: 'Silly (But Serious) Motivation',
    text: 'Can I share something I never have before?',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'sRuo2vnHSEXXMaOnVix8',
    label: 'The Magic Pill',
    text: 'There is no magic pill.',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'DJPUxmbfznB9Y1FpIqZp',
    label: 'How You Show Off Your Style',
    text: 'My editing style is a combination of and light and airy.',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'akeMi1R5Ps6kx2hp7MOE',
    label: 'How Great Days Start',
    text: 'How do you start your best days, friend?',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'Rd41FH3BInIwTDba39zD',
    label: 'Elevating Your Mood & Space',
    text: 'Share a tip below!',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: '1UFCcs0aLU9DzbOaxROr',
    label: 'How You Recharge',
    text: 'What do you do to get away from the hustle and bustle of everyday life?',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'enyJEFuL77GfHhOasd4D',
    label: 'Cell Phone Secrets',
    text: "Your secret's safe with me!",
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'KSDQEBGfLxbG8BhWa2nU',
    label: 'Dressing for the Occasion',
    text: 'You don\'t need to wait for the "perfect" occasion to dress up.',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'fcmRmtbmjTJINYmfM3CV',
    label: 'Adding Sunshine to Your Day',
    text: 'Cloudy day?',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'FqdOqiSpEQUD7huucNL5',
    label: 'How Love Has Changed You',
    text: 'Drop a *heart emoji* below if love has changed you.',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'J626dvhS2k3pNn7K6UHP',
    label: '3 Reasons to Speak Up',
    text: "The good news is, I've learned a thing or two that I hope you find encouraging.",
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'E9qOHAaC4GvdU5R01f80',
    label: 'A Picture of Life Right Now',
    text: "Here's a fun game!",
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'ocCKzc0RdiQHEg3QFcSg',
    label: 'Desert Island, Books Edition',
    text: "Who's up for a game?",
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'gX6kaWnnBMl8eJ5w9m7x',
    label: 'What Your Location Says About You',
    text: 'Does where you live say a lot about you?',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'FnryXpojDuwIM1YPqcxc',
    label: "3 Impactful Trips You've Taken",
    text: 'Are you a big traveler?',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'K0dOVB0dT1B06b5QndyC',
    label: 'Answering Client Questions',
    text: 'Can I be honest?',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'hbx4Jr5LkogRMZ3GyMQ7',
    label: 'Finding the Perfect Fit',
    text: 'mywebsite.',
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'Gc5rGpNvkkqIs57nLMLh',
    label: 'Seeing Growth from the Seeds You Planted',
    text: 'Head to my website!',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'Va5Gc9sZEUG47brkCL4O',
    label: "How We're Improving",
    text:
      "For that matter, I want to keep on hearing what you think, so please don't hesitate to reach out, and keep giving me the feedback that helps me give you more of what you want!",
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'midsh3SSZO56r1OH0x7b',
    label: 'Check Out My Website',
    text: "It's a pretty beautiful place, with and two things people can expect from your website.",
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'MAWNCXQKS864osiJIEQP',
    label: 'Celebrating Big News',
    text: 'What do you suggest?',
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'BrPFwZK7u5mzwd40jh04',
    label: 'Your Life Outside of Work',
    text: 'What can I say?',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'lvUmcZAj8HB2Oz7GGCbd',
    label: ' Ingredients for Your Product/Service',
    text: "that's right, I'm making a product or service you want to feature!",
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'sWXs6LIvky0smULJA05r',
    label: 'A Very Worthwhile "Yes"',
    text: 'And I am so glad I did!',
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'ynOoQyRnzvKwpUdhDS6W',
    label: 'More to Give',
    text: "That's why I'm creating a bonus with 5 additional crochet patterns.",
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'hHaVqy9SnshycAPj9uGq',
    label: 'Your Purpose',
    text:
      ', it makes me think that all the and two seemingly mundane things you do in your business emails I write and meetings I attend are part of my purpose to what you do for your ideal client.',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'tvQLjlbyf0wcNAv6WjvU',
    label: 'Who Pours into Your Business',
    text: 'Who is that special person for you?',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'Tb0Js5kZSqO1jiEScFu1',
    label: 'My Kind of Adventure',
    text:
      "Now this is what I call an adventure… Each time I meet a new coaching client, I get butterflies just thinking about what's possible for my client's future success.",
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'oOBBl155O8IbOTtX4Nxs',
    label: "What You're Creating",
    text: 'Drop a heart below if so!',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'hdYR1grBuIjD5ki4CQ4L',
    label: 'Choosing A Positive Intention',
    text: "I'll be sure to let you know when it comes to pass!",
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'shUsHQlprz5nBYfnXBcg',
    label: 'Making the Cut',
    text: "But that's sometimes easier said than done, am I right?",
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'lBQfZkLN8oqZDrUGZINO',
    label: 'If You Could Go Anywhere',
    text: 'Honestly?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'Alxsf0Jpei9HWZCQFz1n',
    label: "I'm Always Working for You",
    text: 'What can I help you with today?',
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'xLzXZcSEpkZXdQyGGZgt',
    label: 'My Favorite Pick Up Line',
    text:
      "How's this for a pick up line, \"You've struck such a chord with me, I may have to write a song about you!",
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'DbbydI9rJ0OWqRSP0d4Y',
    label: 'Something to High-Five About',
    text: 'Another thing your Ideal Client would do Grocery shopping?',
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: '8lnm2h99oJ6X6kh61O8J',
    label: 'An Embarrassing Mistake',
    text:
      "It's nothing a Band-Aid, a long hug, or a stiff drink can't fix, and mistakes always teach us something, too.",
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'KQ8wQsbvAa6au7Buf3wF',
    label: 'A Few Sweet Words',
    text: "I've got a treat for you…  but it's not what you may think.",
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'WkCthOyMYtAZFi1tUZL1',
    label: 'Trip Recommendations',
    text: 'Up for an adventure?',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'wAv5CG8LIMIysvdY1Vus',
    label: 'A Game About Books',
    text: "Let's play a game!",
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'XU11ldf38qOHiNGdSemG',
    label: "What I'm Particular About",
    text: "It's about time I admitted this.",
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'xX1CffGXMqIQqsyOa1ek',
    label: 'How You Get Unstuck',
    text: "Let's help each other out in the comments!",
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'L9j0UIRQ1K9xpju8NFZ7',
    label: "We've Got What's Good for You",
    text: "Who's with me?",
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'OS7aZLdAkmTeQvSIJvCm',
    label: 'The Lifesaver You Need',
    text: 'Your product or service will save you from what your Ideal Client needs "saving" from.',
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'DD16FTwl52B4aiEj2y5p',
    label: 'A Healthy Daily Practice',
    text: 'Fire away!',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'oO2sAHpvq5y5nPmZ1t0a',
    label: 'We Help You Level Up',
    text: "We'd love to help!",
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'OEVemIK7mnm9I8Ge6xbB',
    label: "What's on Your Gratitude List?",
    text: 'Thank you for supporting my small business.',
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'bItIFElMnIwDDaqCmnC6',
    label: 'The Problem You Solve',
    text:
      "Espresso may not be the answer to ALL of life's problems, but it's worth a shot… Kidding!",
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'pPEkeuPJ1tWSHmh30uoG',
    label: 'Rave Review',
    text: 'Did your mother ever tell you not to brag?',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'p9j9QUv8Mlm9oINt5Zp7',
    label: 'The Little Things',
    text:
      'If I had to choose a couple of the "little things" that make my web design services special, they would be and how they\'re intuitive and sleek.',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: '51BpPcf1wqzksBfTxAbK',
    label: 'Juicy Details on Something New',
    text:
      "There have been some hiccups— name an issue which arose comes to mind—but I can't wait to share it ALL with you!",
    type: 'insight',
    category: 'behind-the-scenes'
  },
  {
    _id: 'hWrPewpjBvXv7E1qZztr',
    label: 'Your Key to Success',
    text: 'Riddle me this: What has keys but no locks?',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'QVlm4ZunAfnIInDJBvcy',
    label: 'The Art of What You Do',
    text: 'I believe that every session is a work of art.',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: 'JOIW8oQWcYwBM5ALLwY9',
    label: "What You're Dreaming Of",
    text: 'What are you dreaming of, friend?',
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'FwsbHgest1MgVGVCSwjb',
    label: 'Team Shoutout',
    text: "Give 'em a shoutout below!",
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'qg7VaeOpZC0NEC7GumM6',
    label: 'How You Show Up',
    text: 'Drop an emoji below if you agree!',
    type: 'call-to-action',
    category: 'my-why'
  },
  {
    _id: 'EL7moW2wsHTZS30NFyWP',
    label: "How You Treat Yo'self",
    text: 'The payoff is immediate—a treat in itself!',
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'PUWjKR5jih8XohwBgz5W',
    label: 'The Risk Is Worth It',
    text:
      "When I'm in the weeds of daily tasks, it can often feel like I'm spinning in circles but it's in those moments that I remind myself how honored I am to be supporting business owners.",
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: 'FNQgPv8uP2a1c2V3kPtN',
    label: 'The Heart of the Home',
    text: 'They say the kitchen is the "heart of the home" and I have to agree.',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'LzNl1x6Wds8SrcxQ3tuT',
    label: 'What Brightens Your Day',
    text: "So tell me, what's guaranteed to brighten yours?",
    type: 'call-to-action',
    category: 'personal-insight'
  },
  {
    _id: 'Xqr2vO1o0Fx6OAljPy1S',
    label: 'Write It Down',
    text: "That goal or dream that's been on your heart?",
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: '5Kw23ugqVMUDsYR2Ehdz',
    label: 'Your Personal Preferences',
    text: 'I love it because the simple palette calms me!',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: 'T7rzw5vvV2BbatKD3Cx0',
    label: 'What You Do 1st When You Get Home',
    text: 'Can I get a little personal today?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'apeJTarAtvKmOtIGrxtx',
    label: 'Always in Season',
    text:
      'For me, , , and 3 things you appreciate that are always in season smiles are always ripe for the picking.',
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'wI5gTPUiKKQpb5CH3OYV',
    label: 'Pouring Out Compliments',
    text: 'It feels good to spread love!',
    type: 'call-to-action',
    category: 'encouragement'
  },
  {
    _id: 'NlGOvFnHXuiuzb1fBbSp',
    label: 'Getting a Fresh Start',
    text: 'Yesterday, I lost hours of work in a computer crash.',
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'wMozOygmguQiFBLypdfS',
    label: 'How You Stay Focused',
    text: 'Each check helps me gain momentum.',
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: 'q1F5VFZtR0r6jvI9c6yT',
    label: 'Making Room for Something New',
    text: "No, I'm not pregnant!",
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: 'v9EOavwkIspVYAMpWCvC',
    label: 'Your Favorite Seasonal Things',
    text: 'Is it finally feeling like season Spring where you are?',
    type: 'hook',
    category: 'about-me'
  },
  {
    _id: 'bkdaptKuB0CCcGSRgXhd',
    label: 'You Are Enough',
    text: 'Tag someone in the comments that would appreciate this reminder.',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'Y5zsNc8cl0YAzjgqr7JD',
    label: '3 Recent Opportunities',
    text: 'Drop a emoji below if so!',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'VK0jtHRvQR2FL3Ac4kJQ',
    label: 'We Love What We Do',
    text: 'Ask me anything!',
    type: 'call-to-action',
    category: 'benefits'
  },
  {
    _id: 'reuZyeiS0HycowjvUxot',
    label: 'What Fuels You',
    text:
      'But the TRUTH is the benefit of your service or product sourcing nothing but the best quality ingredients for my handmade soap is what truly keeps my tank(s) full.',
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'O5MlWA7t47dO5P4Az3gK',
    label: 'How Your Product/Service is a Form of Self-Care',
    text: 'Are you ready to put yourself first?',
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'taMi9pyexjIwp31IqSUx',
    label: 'Our Clients Are VIPs',
    text: "After all, you're the VIP!",
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'ndYKaUBDK6jqrBBgk7QR',
    label: 'Rumor Has It',
    text: 'The rumors are true: your friends will think your catered dinner is homemade.',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'eEDHBQkfLYYMTs5ilRGc',
    label: 'Broadcasting a Client Rave',
    text: 'Read all about it!',
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'VbwV4bSMdCqIj2iiOPbK',
    label: 'Visit My Website',
    text: 'com today!',
    type: 'call-to-action',
    category: 'showcase-product-or-service'
  },
  {
    _id: '1adab853-fb8d-4982-a1c4-99311f91a147',
    label: "Something You've Never Shared",
    text:
      "This is the real reason why I am a yoga instructor: yoga is the only thing that was able to ground me after my father's death, and I'm dedicated to helping others find the same peace, too.",
    type: 'insight',
    category: 'my-why'
  },
  {
    _id: '9c752002-30be-4aed-b905-a6dc8e6a1952',
    label: 'Gratitude After a Long Day',
    text: 'Can I make a confession?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: '0d34022e-193a-4b7c-bf3f-759bba396192',
    label: 'Unconventional Workspace',
    text: 'Is it weird that working from an office feels a little "old school" now?',
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: '0084bada-23dc-4423-9e1a-e57a1bd8448d',
    label: 'Preparing for a Project',
    text: "What's on your agenda today?",
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: '0b04f0a3-1f71-42e6-b706-90779036e87d',
    label: 'Starting the Morning Right',
    text:
      "Am I the only one who can't start my morning without hitting the snooze button at least twice?",
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: '9ae323bf-d58f-438c-9e5c-770319881fa5',
    label: 'Growing My Business',
    text:
      "If you'd asked me when you started your business last year that I'd be helping your Ideal Client the benefit your product or service provides achieve financial freedom, I'd be thrilled.",
    type: 'hook',
    category: 'behind-the-scenes'
  },
  {
    _id: '6968959f-2949-4398-913b-f0204a414224',
    label: 'The Sweetest Part of Your Job',
    text: 'Want to know the sweetest part of my job?',
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: '94aa5a1d-ece1-4b14-96f0-35dcc8149a5d',
    label: 'Why My Business Is Awesome',
    text:
      "I've gotta ask you: when you were a kid, what did you look forward to most about growing up?",
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: '9f5e982d-cb15-424c-9f31-078c8c4815b9',
    label: 'Wise Words',
    text: "A wise person once said, \"Don't look back, you're not going that way.",
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: '16b99f61-44de-4405-bc82-067c8fcf7037',
    label: 'Your Version of Vacation Mode',
    text:
      "Many entrepreneurs say it's difficult to detach from work when they're supposed to be relaxing, and that's definitely true for me.",
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: 'b6eff364-d9ce-4271-b775-17e6a5c03259',
    label: 'The Story of Your Life',
    text: 'If you were to write down the story of your life, what would your title be?',
    type: 'insight',
    category: 'personal-insight'
  },
  {
    _id: '71bd5e9c-acb3-4cc7-9ac9-53744230c97d',
    label: 'Simple Pleasures',
    text: "Life's simple pleasures really seem to be underrated sometimes.",
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'd29337a9-476c-4018-86d5-e17b93da2414',
    label: 'Something You Collect',
    text: 'Do you collect anything?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: '946ae8cd-fb7f-4e9c-8ec1-77328a9ddcfb',
    label: 'How My Business Makes Your Life Easier',
    text: 'Does remodeling your kitchen feel too complicated?',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: '4042f47a-b5cf-40c7-b29b-829f20fa71e2',
    label: 'Time to Take a Breath',
    text:
      "I know it can be tough to do when you're something that causes your Ideal Client stress busy lesson planning, but when you take a moment to just breathe, I promise it will be worth every second!",
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: '53df482e-fae6-4968-b9b8-c44ab9a4d633',
    label: "You're Amazing",
    text:
      "I'm sure you have a lot on your plate today, like something your Ideal Client would be doing answering copious amounts of emails, but I just wanted to remind you to add one more thing to your to-do list… to be AMAZING!",
    type: 'insight',
    category: 'encouragement'
  },
  {
    _id: '988f5287-f57a-4710-b087-3c77e8005025',
    label: 'No Rain, No Rainbows',
    text: 'Who else has been going THROUGH it lately?',
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: '86d844d9-3ddc-4dc6-b5d4-8fd38b324571',
    label: 'Have Your Cake and Eat It Too',
    text: "I've got some good news for you!",
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'a67443d1-69b8-4959-b7a5-84db219b1c9e',
    label: 'Your Favorite Accessory',
    text: 'How about you?',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: '78ce31fb-6301-465c-b8b9-317fd12e2963',
    label: 'To-Do List Game',
    text: 'A, B, or C?',
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: 'bc4ede8a-009d-44cb-ba4b-dffe978b7c19',
    label: 'Dinner Habits',
    text: "C'mon, spill the spaghetti in the comments below!",
    type: 'call-to-action',
    category: 'about-me'
  },
  {
    _id: '35554b6f-79e2-4964-8c04-46386c9c61b2',
    label: 'Your Hidden Talent and Un-Talent',
    text:
      'Seriously, all of my friends require me to organize their closets, kitchens, and sheds when I visit!',
    type: 'insight',
    category: 'about-me'
  },
  {
    _id: '2d00fbd7-a45d-4599-b8be-5f40fe52a756',
    label: 'What Would Feel Perfect?',
    text: 'Curious if my product is a perfect fit for you?',
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: '174215b5-cdcd-4258-8282-1cdaee3b1a92',
    label: 'A Gift for Your Customers/Clients',
    text: "Psssst… I'm pretty excited because I have a special gift for you.",
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: '9c22e4b8-6a85-470f-b7cf-0f0785bddb21',
    label: 'A Fruitful Project',
    text: 'Now, the first 6 episodes are ready to be enjoyed by YOU!',
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: 'af18dbe3-2520-4c3f-b29c-aba8bc6e7d61',
    label: 'How Clients Fuel Creativity',
    text: 'Want a sneak peek into my creative process?',
    type: 'hook',
    category: 'benefits'
  },
  {
    _id: 'a44ff384-7b62-4c8c-9366-b6719cfa1f91',
    label: 'Most Popular Product/Service',
    text:
      "* One of my favorite products in our new Spring line is name of the most popular product or service you're showcasing.",
    type: 'insight',
    category: 'showcase-product-or-service'
  },
  {
    _id: '024d018b-2692-4fac-9696-202b947b89b0',
    label: 'Cheers to the Unglamorous',
    text:
      "It's easy to toast to good health and fortune, but how about we cheers to the unglamorous parts of life for once?",
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: '00fdd77f-78d4-4612-83ba-b14a0f7846e2',
    label: "Announcing What's Coming Soon",
    text: "Who's ready for some exciting news?",
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: 'f6ed98e6-f360-4e24-9c06-0b0f9e7dabca',
    label: 'Time to Relax',
    text: 'Longing for some much-needed rest and relaxation?',
    type: 'hook',
    category: 'showcase-product-or-service'
  },
  {
    _id: '6d9fdcf4-1370-40b2-a362-3320ceb5d209',
    label: 'That Good Feeling',
    text:
      "Chase that good feeling and discover what's possible for your next vacation by visiting mywebsite.",
    type: 'insight',
    category: 'benefits'
  },
  {
    _id: '21ece2ba-4257-4b40-b50c-0a28fe7797ef',
    label: 'SC Affiliate Link: Your Caption-Writing Secret Weapon',
    text:
      "If you're feeling overwhelmed by writing captions that showcase your business and want to spend more time doing what you truly love, go to the link in my bio to learn more!",
    type: 'call-to-action',
    category: 'behind-the-scenes'
  },
  {
    _id: 'e5d48f40-9f74-482d-98e3-1766e1d170d7',
    label: 'SC Affiliate Link: Why Social Media Marketing is Necessary',
    text: "I didn't start my business to spend countless hours on social media.",
    type: 'hook',
    category: 'my-why'
  },
  {
    _id: '59003542-6c99-4695-8ef9-07ba3e66f3e7',
    label: 'SC Affiliate Link: How Long It Takes to Post',
    text: "What if I told you writing captions didn't have to steal valuable hours from your day?",
    type: 'hook',
    category: 'encouragement'
  },
  {
    _id: 'fb3ccda5-ce74-486e-98c9-a09a41b91a76',
    label: 'SC Affiliate Link: Your Favorite Business Tool',
    text: 'My favorite business tool may surprise you.',
    type: 'hook',
    category: 'personal-insight'
  },
  {
    _id: 'ddd0d132-cfe6-4b3e-80d1-c42c1633a202',
    label: 'SC Affiliate Link: A Marketing Agency In Your Pocket',
    text:
      'See what all the fuss is about by clicking on the link in my bio and save $15 when you start your subscription today!',
    type: 'call-to-action',
    category: 'about-me'
  }
]
