import { FC, FormEvent, useContext, useState } from 'react'
import { toast } from 'react-hot-toast'
import { Browser } from '@capacitor/browser'
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid'
import { SCMark } from '../../../assets'
import { handleEnterToNextInput, useKeyboardHeight, useLogin, userStore } from '../../../utils'
import { ForgotPasswordSheet, Spinner } from '../../components'
import { MOBILE_ROUTES } from '../../utils'
import { StyledSplashView } from '.'

const SplashView: FC = () => {
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showForgotPassword, setShowForgotPassword] = useState(false)
  const { form, params, updateFormValue, login } = useLogin()
  const { user } = useContext(userStore)
  const { height } = useKeyboardHeight()

  const handleSubmit = async (e?: FormEvent) => {
    try {
      e?.preventDefault()
      setLoading(true)

      await login()
    } catch (err) {
      console.error(err)
      toast.error((err as any)?.message)
      setLoading(false)
    }
  }

  if (!!user && params?.ref === 'academy') {
    return (
      <div
        style={{
          height: '100dvh',
          width: '100dvw',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Spinner />{' '}
        <span style={{ marginLeft: '10px', opacity: '0.6', fontWeight: 500 }}>Loading</span>
      </div>
    )
  }

  return (
    <>
      <StyledSplashView keyboardHeight={height}>
        <div className="splash-top">
          <div className="header-container">
            <SCMark />
            {params?.ref !== 'academy' && (
              <button
                onClick={() =>
                  Browser.open({
                    url: `${MOBILE_ROUTES.external.signup}?redirect=socialcurator://?auth=true&closeBrowser=true`,
                    presentationStyle: 'popover'
                  })
                }
              >
                Sign Up
              </button>
            )}
          </div>
          {params?.ref !== 'academy' && <h1>Goodbye social media overwhelm, hello Dottie.</h1>}
          {params?.ref === 'academy' && <h1>Social Curator Academy.</h1>}
        </div>
        <form autoComplete="on" onSubmit={(e) => handleSubmit(e)}>
          <div className="auth-inputs">
            <label htmlFor="email" className="input-container">
              <div className="input-label">Email address</div>
              <input
                id="email"
                value={form.email}
                placeholder="hello@socialcurator.com"
                required
                type="email"
                name="email"
                enterKeyHint="next"
                onKeyDown={(e) => handleEnterToNextInput(e)}
                onChange={(e) => updateFormValue('email', e.target.value)}
              />
            </label>
            <label htmlFor="password" className="input-container">
              <div className="input-label">
                <span>Password</span>
                <button type="button" onClick={() => setShowForgotPassword(true)}>
                  Forgot password?
                </button>
              </div>
              <input
                id="password"
                value={form.password}
                required
                type={showPassword ? 'text' : 'password'}
                name="password"
                enterKeyHint="go"
                onChange={(e) => updateFormValue('password', e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && handleSubmit()}
              />
              <button
                className="show-password-btn-small"
                type="button"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <EyeSlashIcon /> : <EyeIcon />}
              </button>
            </label>
          </div>
          <button
            type="submit"
            className="submit-btn"
            disabled={loading || !form?.email || !form?.password}
          >
            {!loading && 'Log In'}
            {loading && <SCMark className="logo-spinner" />}
          </button>
        </form>
      </StyledSplashView>
      <ForgotPasswordSheet
        isOpen={showForgotPassword}
        onClose={() => setShowForgotPassword(false)}
      />
    </>
  )
}

export default SplashView
