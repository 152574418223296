import { FC } from 'react'
import { ReactComponent as ChatIcon } from '../../../assets/chat-icon.svg'
import { StyledChatBotIcon } from '.'

type Props = {
  size?: number
}

const ChatBotIcon: FC<Props> = ({ size = 30 }) => {
  return (
    <StyledChatBotIcon size={size}>
      <ChatIcon />
    </StyledChatBotIcon>
  )
}

export default ChatBotIcon
