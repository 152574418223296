import { FC, useEffect, useState } from 'react'
import Sheet from 'react-modal-sheet'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { clearUploadFiles, useAppDispatch, useAppSelector } from '../../../../redux'
import { useHaptics } from '../../../../utils'
import { Spinner } from '../..'
import { StyledUploadSheet, UploadRow } from '.'

const UploadSheet: FC = () => {
  const dispatch = useAppDispatch()
  const [isComplete, setIsComplete] = useState(false)
  const { trigger } = useHaptics()
  const { files } = useAppSelector((state) => state.upload)

  const handleClose = () => {
    if (!isComplete) {
      return
    }

    dispatch(clearUploadFiles())
  }

  const getTitle = () => {
    const filesLeftToUpload = files.filter((file) => file.status === 'loading')
    const count = filesLeftToUpload?.length || 0

    if (filesLeftToUpload.length === 0) {
      return `${files.length} upload${files.length !== 1 ? 's' : ''} complete`
    }

    return `Uploading ${count} item${count !== 1 ? 's' : ''}`
  }

  useEffect(() => {
    const complete = !files.find((file) => file.status === 'loading')

    if (!complete || !files?.length) {
      setIsComplete(false)
      return
    }

    setIsComplete(true)
    trigger('notification')
  }, [files])

  return (
    <Sheet
      detent="content-height"
      disableDrag={!isComplete}
      isOpen={!!files.length}
      onClose={handleClose}
    >
      <Sheet.Container>
        <Sheet.Content>
          <StyledUploadSheet>
            <div className="header">
              {getTitle()}
              <button disabled={!isComplete} onClick={() => handleClose()}>
                <XMarkIcon />
              </button>
            </div>
            <div className="upload-list">
              {files.map((file) => {
                return <UploadRow file={file} key={file._id} />
              })}
            </div>
            <div className="button-bar">
              <button disabled={!isComplete} onClick={() => handleClose()}>
                {!isComplete ? <Spinner /> : 'Continue'}
              </button>
            </div>
          </StyledUploadSheet>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={() => handleClose()} />
    </Sheet>
  )
}

export default UploadSheet
