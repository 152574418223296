import styled from 'styled-components'

const StyledColorInput = styled.div`
  padding: 6px;
  width: 200px;

  .color-text-input {
    display: flex;
    border-radius: 6px;
    margin-top: 6px;
    position: relative;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.color.gray[100]};
    transition: 0.2s;

    input {
      height: 34px;
      box-sizing: border-box;
      padding-left: 30px;
      background: transparent;
      color: ${({ theme }) => theme.color.gray[700]};
    }

    svg {
      height: 20px;
      top: 50%;
      pointer-events: none;
      transform: translateY(-50%);
      position: absolute;
      left: 6px;
      color: ${({ theme }) => theme.color.gray[500]};
    }

    &:hover,
    &:focus-within {
      background-color: ${({ theme }) => theme.color.gray[200]};
    }
  }
`

export default StyledColorInput
