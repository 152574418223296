import { FC } from 'react'
import { DateTime } from 'luxon'
import { useDraggable } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import { CameraIcon } from '../../../../assets'
import { setCreatePost, setShowCreate, useAppDispatch } from '../../../../redux'
import { PostType } from '../../../../types'
import { CloudMediaPreview } from '../..'
import { StyledCalendarPost } from '.'

type Props = {
  post: PostType
  isOverlay?: boolean
}

const CalendarPost: FC<Props> = ({ post, isOverlay }) => {
  const dispatch = useAppDispatch()
  const { attributes, isDragging, listeners, setNodeRef, transform } = useDraggable({
    id: post._id,
    data: {
      post: post
    }
  })

  const style = {
    transform: CSS.Translate.toString(transform)
  }

  const openPost = () => {
    dispatch(setCreatePost(post))
    dispatch(setShowCreate(true))
  }

  return (
    <StyledCalendarPost
      ref={setNodeRef}
      // style={isOverlay ? style : {}}
      {...listeners}
      {...attributes}
      onClick={() => openPost()}
    >
      <div className="post-preview">
        {!post.media?.length && <CameraIcon className="camera-icon" />}
        {!!post.media?.length && (
          <CloudMediaPreview
            url={post.media[0].url}
            type={post.media[0].type}
            preview={post.media[0].preview}
          />
        )}
      </div>
      <div className="post-details">
        <span className="post-title">{post.title || 'Untitled Post'}</span>
        <span className="post-date">{DateTime.fromISO(post.postDate).toFormat('h:mma')}</span>
      </div>
    </StyledCalendarPost>
  )
}

export default CalendarPost
