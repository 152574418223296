import styled from 'styled-components'

const StyledCreateBarContentPhotos = styled.div`
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);

  .photo-list {
    padding: 0 20px 20px 20px;
    height: 100%;
    box-sizing: border-box;

    .masonry-grid {
      display: flex;
      margin-left: -4px;
      width: auto;

      .masonry-grid-column {
        padding-left: 4px;
        background-clip: padding-box;
      }
    }
  }

  .options-btn {
    position: relative;

    .filter-indicator {
      width: 16px;
      height: 16px;
      box-shadow: 0 0 0 3px ${({ theme }) => theme.color.background};
      border-radius: 50%;
      background-color: ${({ theme }) => theme.color.brand};
      color: ${({ theme }) => theme.color.background};
      position: absolute;
      top: -4px;
      right: -4px;

      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: 600;
    }
  }
`

export default StyledCreateBarContentPhotos
