import styled from 'styled-components'

const StyledPullDown = styled.div`
  position: fixed;
  background-color: ${({ theme }) => theme.color.background}b8;
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  width: 220px;
  display: grid;
  box-shadow: 0 6px 20px 0px #00000020;
  z-index: 999999999;
  border-radius: 12px;
  overflow: hidden;

  .row-btn {
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 13px 0 14px;
    font-weight: 500;
    background-color: transparent;
    color: ${({ theme }) => theme.color.foreground}d1;

    .row-btn-icon {
      width: 22px;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 22px;
        stroke-width: 1.75px;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.color.foreground}20;
    }

    &.destructive {
      color: ${({ theme }) => theme.color.red[500]};
    }
  }

  .row-div {
    margin: 0;
    height: 6px;
    border: 0;
    background-color: ${({ theme }) => theme.color.foreground}15;
  }
`

export default StyledPullDown
