import styled from 'styled-components'

const StyledChatConversationList = styled.div`
  height: 100%;
  overflow-y: auto;
  display: grid;
  grid-gap: 0px;
  padding: 7px 0 60px 0;
  align-content: start;
  box-sizing: border-box;
  margin-right: 3px;

  &::-webkit-scrollbar {
    height: 0;
    position: absolute;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.color.foreground}10;
    border-radius: 4px;

    &:hover {
      background: ${({ theme }) => theme.color.foreground}20;
    }
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border: solid 3px transparent;
    width: 8px;
  }

  .prompt-list {
    padding: 14px;
    box-sizing: border-box;
    display: grid;
    grid-gap: 10px;
    padding-left: calc(53px + 14px);

    button {
      max-width: 350px;
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0 10px;
      border-radius: 6px;
      transition: 0.2s;
      background-color: ${({ theme }) => theme.color.brandLight};
      color: ${({ theme }) => theme.color.brand};
      border: 1px solid ${({ theme }) => theme.color.brand}20;

      svg {
        height: 24px;
        margin-right: 10px;
      }

      &:hover {
        background-color: ${({ theme }) => theme.color.brand};
        color: ${({ theme }) => theme.color.background};
        border: 1px solid ${({ theme }) => theme.color.brand};
      }

      &[data-active='true'] {
        background-color: ${({ theme }) => theme.color.brand};
        color: ${({ theme }) => theme.color.background};
        border: 1px solid ${({ theme }) => theme.color.brand};
      }

      &:disabled {
        cursor: not-allowed;
        border: 1px solid ${({ theme }) => theme.color.gray[200]};
        background-color: ${({ theme }) => theme.color.gray[200]};
        color: ${({ theme }) => theme.color.gray[400]};
      }
    }

    details {
      summary {
        display: flex;
        align-items: center;
        user-select: none;
        transition: 0.2s;
        color: ${({ theme }) => theme.color.gray[500]};

        svg {
          height: 20px;
          margin-right: 6px;
        }

        &:hover {
          cursor: pointer;
          color: ${({ theme }) => theme.color.gray[800]};
        }
      }

      .all-templates-list {
        display: grid;
        margin-top: 10px;
        gap: 10px;

        button {
          background-color: ${({ theme }) => theme.color.gray[100]};
          color: ${({ theme }) => theme.color.gray[600]};
          border: 1px solid ${({ theme }) => theme.color.gray[200]};

          &:hover {
            background-color: ${({ theme }) => theme.color.gray[200]};
            color: ${({ theme }) => theme.color.gray[800]};
            border: 1px solid ${({ theme }) => theme.color.gray[300]};
          }

          &[data-active='true'] {
            background-color: ${({ theme }) => theme.color.brand};
            color: ${({ theme }) => theme.color.background};
            border: 1px solid ${({ theme }) => theme.color.brand};
          }

          &:disabled {
            cursor: not-allowed;
            border: 1px solid ${({ theme }) => theme.color.gray[200]};
            background-color: ${({ theme }) => theme.color.gray[200]};
            color: ${({ theme }) => theme.color.gray[400]};
          }
        }
      }
    }
  }
`

export default StyledChatConversationList
