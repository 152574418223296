import styled from 'styled-components'

const StyledCalendarWeek = styled.div`
  display: flex;
  height: 100%;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);

  .rows {
    display: grid;
    overflow: auto;
    overflow-x: hidden;
    overscroll-behavior: none;

    .row {
      width: 100vw;
    }
  }

  .viewer {
    width: 100%;
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
    height: 100%;
    overflow: hidden;
  }
`

export default StyledCalendarWeek
