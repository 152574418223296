import { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { sortBy } from 'lodash'
import { DateTime } from 'luxon'
import { useDroppable } from '@dnd-kit/core'
import { PlusIcon } from '@heroicons/react/24/outline'
import { setCreatePost, useAppDispatch, useAppSelector } from '../../../redux'
import { CalendarEventType } from '../../../types'
import { ROUTES, isMobile, isToday, parseDate, useCreate } from '../../../utils'
import { Button, CalendarPostItem } from '../..'
import { StyledCalendarMonthSlot } from '.'

type Props = {
  date: DateTime
}

const CalendarMonthSlot: FC<Props> = ({ date }) => {
  const ID = `calendar-slot-${date.day}`
  const dispatch = useAppDispatch()
  const history = useHistory()
  const [todayEvents, setTodayEvents] = useState<CalendarEventType[]>([])
  const { events } = useAppSelector((state) => state.calendar)
  const { newPost } = useCreate()
  const { active, isOver, setNodeRef } = useDroppable({
    id: ID,
    data: {
      date: date.set({ hour: 10 }).toISO()
    }
  })

  const newPostAtDate = () => {
    newPost()
    dispatch(
      setCreatePost({
        postDate: date.set({ hour: 10 }).toISO()
      })
    )

    history.push(ROUTES.create)
  }

  useEffect(() => {
    setTodayEvents(
      sortBy(
        events.filter((event) => {
          const diff = parseDate(event?.metadata?.day)?.diff(date, 'days')?.days
          return diff >= 0 && diff < 1
        }),
        'metadata.data.postDate',
        'desc'
      )
    )
  }, [events])

  return (
    <StyledCalendarMonthSlot
      isDragging={!!active}
      isToday={isToday(date)}
      isOver={isOver}
      ref={setNodeRef}
    >
      <div className="day-header">
        <div className="day-label">{date.day}</div>
        <Button
          // data-tooltip={`Create post for ${date.toFormat("LLL d 'at' t")}`}
          // data-tooltip-pos={date.weekday === 6 ? 'bottom-end' : 'bottom-center'}
          className="add-post-btn"
          isIcon
          onClick={() => newPostAtDate()}
        >
          <PlusIcon />
        </Button>
      </div>
      <div className="day-event-list">
        {todayEvents.map((event) => {
          return (
            <CalendarPostItem
              post={event.metadata.data as any}
              event={event}
              parent={ID}
              isCollapsed
              key={event._id}
            />
          )
        })}
      </div>
    </StyledCalendarMonthSlot>
  )
}

export default CalendarMonthSlot
