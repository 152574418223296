import { FC, useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import {
  ROUTES,
  useAppTheme,
  useKeyboard,
  usePushNotifications,
  useSegment,
  useStatusBar,
  userStore
} from '../../../utils'
import { ChatSheet, ConnectionSheet, CreateDrawer, UploadSheet } from '..'

const StateManager: FC = () => {
  useStatusBar()
  useKeyboard()
  usePushNotifications()
  useAppTheme()

  const location = useLocation()
  const { initialize } = useSegment()
  const { subscribeToNotifications } = usePushNotifications()
  const { user } = useContext(userStore)

  useEffect(() => {
    if (!user?.id) {
      return
    }

    subscribeToNotifications()
    initialize()
  }, [user])

  if (!user) {
    return null
  }

  if (location.pathname.includes(ROUTES.connect)) {
    return null
  }

  return (
    <>
      <ChatSheet />
      <CreateDrawer />
      <ConnectionSheet />
      <UploadSheet />
      <div className="confetti" id="confetti" />
    </>
  )
}

export default StateManager
