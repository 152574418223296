import styled from 'styled-components'

const StyledDesignGrid = styled.div`
  .grid-header {
    display: grid;
    margin-bottom: 20px;
    grid-template-columns: minmax(0, 1fr) auto;
    place-content: space-between;
    gap: 10px;

    .grid-header-filters {
      display: flex;
    }
  }

  .grid-list {
    display: grid;
    gap: 20px;
    align-items: start;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  }
`

export default StyledDesignGrid
