import { gql } from '@apollo/client'

export const storySetFindById = gql`
  query StorySetFindById($_id: String!) {
    storySetFindById(_id: $_id) {
      _id
      title
      canvaLink
      description
      index
      thumbnail
      issue
      records {
        _id
        fullPath
        imageUrl
        storySet
        index
        migrated
      }
    }
  }
`
