import styled from 'styled-components'

const StyledDesignerGalleryPanel = styled.div`
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  height: 100%;
  gap: 20px;

  .designer-panel-scroll {
    overflow-y: overlay;
    height: 100%;
    padding: 0 16px 20px 20px;
    margin-right: 6px;
    box-sizing: border-box;

    .masonry-grid {
      display: flex;
      margin-left: -6px;
      width: auto;

      .masonry-grid-column {
        padding-left: 6px;
        background-clip: padding-box;

        .gallery-photo {
          background: #313233 !important;

          &:not(:last-child) {
            margin-bottom: 6px;
          }
        }
      }
    }
  }
`

export default StyledDesignerGalleryPanel
