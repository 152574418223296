import { useContext } from 'react'
import { toast } from 'react-hot-toast'
import { useApolloClient } from '@apollo/client'
import { Browser } from '@capacitor/browser'
import { Capacitor } from '@capacitor/core'
import { connectLinkedIn } from '../../../graphql'
import { userStore } from '../..'
import { useSegment } from '..'

export const useLoginLinkedIn = () => {
  const apollo = useApolloClient()
  const native = Capacitor.isNativePlatform()
  const { refresh } = useContext(userStore)
  const { track } = useSegment()

  const loginLinkedIn = async () => {
    const clientId = process.env.REACT_APP_LINKEDIN_APP_ID
    const redirect = native
      ? 'https://app.socialcurator.com/connect?mobile=true'
      : `${window.location.origin}/connect`
    const encodedRedirect = encodeURIComponent(redirect)
    const url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${encodedRedirect}&state=linkedin&scope=r_liteprofile%20r_emailaddress%20w_member_social`

    if (native) {
      return await Browser.open({
        url,
        presentationStyle: 'popover'
      })
    }

    window.open(
      url,
      '',
      'resizable=yes,width=600,height=400,toolbar=no,titlebar=no,menubar=no,scrollbars=yes'
    )

    window.addEventListener('message', (event) => {
      if (event.origin !== window.origin) {
        return
      }

      if (typeof event.data !== 'object') {
        return
      }

      if (event.data?.type !== 'connected-profile') {
        return
      }

      refresh()
    })

    // window.location.href = url
  }

  const connectLinkedInProfile = async (code: string) => {
    try {
      const callback = native
        ? 'https://app.socialcurator.com/connect?mobile=true'
        : `${window.location.origin}/connect`

      await apollo.mutate({
        mutation: connectLinkedIn,
        variables: { code, callback }
      })

      await refresh()
      track('Connected Social Profile')
      track('Connected LinkedIn Profile')
      toast.success(`Successfully connected your social profile`)
    } catch (err) {
      console.error(err)
    }
  }

  return {
    loginLinkedIn,
    connectLinkedInProfile
  }
}
