import { FC, FormEvent, createRef, useContext, useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { Link, useHistory } from 'react-router-dom'
import ReactTextareaAutosize from 'react-textarea-autosize'
import rehypeRaw from 'rehype-raw'
import {
  ArchiveBoxIcon,
  PaperAirplaneIcon,
  PlusCircleIcon,
  SparklesIcon,
  XCircleIcon
} from '@heroicons/react/20/solid'
import {
  ArrowDownIcon,
  ArrowUturnRightIcon,
  Bars3BottomLeftIcon,
  DocumentTextIcon,
  HashtagIcon,
  LightBulbIcon,
  PhotoIcon,
  VideoCameraIcon
} from '@heroicons/react/24/outline'
import { ReactComponent as BroomIcon } from '../../../assets/broom-icon.svg'
import { ReactComponent as ChatIcon } from '../../../assets/chat-icon.svg'
import { ReactComponent as PersonasIcon } from '../../../assets/personas-icon.svg'
import {
  setChatInput,
  setChatPersona,
  setChatRef,
  setChatTemplate,
  setShowChatTemplates,
  useAppDispatch,
  useAppSelector
} from '../../../redux'
import { ROUTES, useChat, useSegment, userStore } from '../../../utils'
import { Button, ChatBotIcon, ChatSuggestions, OptionsPopover, Spinner } from '../..'
import { StyledChatInput } from '.'

const ChatInput: FC = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { sendMessage, clearChat, getTemplateLabel } = useChat()
  const { user } = useContext(userStore)
  const { loading, input, template, messages, ref, persona, showTemplates } = useAppSelector(
    (state) => state.chat
  )
  const { splits } = useAppSelector((state) => state.splits)
  const { track } = useSegment()
  const [showPersonas, setShowPersonas] = useState(false)
  const templatesBtnRef = createRef<HTMLButtonElement>()
  const personaBtnRef = createRef<HTMLButtonElement>()
  const inputRef = createRef<HTMLTextAreaElement>()

  const handleSubmit = async (e?: FormEvent) => {
    e?.preventDefault()

    if (!input.trim() || loading) {
      return
    }

    dispatch(setChatInput(''))
    dispatch(setChatTemplate(undefined))
    await sendMessage(input)
  }

  const handleShowTemplateOptions = () => {
    track('Clicked Chat Template Select')
    dispatch(setShowChatTemplates(!showTemplates))
  }

  const getMagicPrompt = () => {
    switch (template) {
      case 'caption':
        return 'What do you want to post about?'
      case 'hashtags':
        return 'What do you want hashtags about?'
      case 'blanks':
        return `Paste a caption template from <a href="${ROUTES.contentLibrary.main}">Captions</a>`
      case 'photo':
        return 'What do you want a photo of?'
      case 'video':
        return 'What do you want a video of?'
      case 'video-script':
        return 'What do you want the video to be about?'
      case 'visual-ideas':
        return 'What do you want visual ideas about?'
      default:
        return 'What do you want to post about?'
    }
  }

  useEffect(() => {
    if (inputRef?.current && ref) {
      inputRef.current.focus()
    }
  }, [inputRef, ref])

  return (
    <>
      <StyledChatInput htmlFor="chat-bot-input">
        {!messages.length && !!template && (
          <div className="chat-input-magic-indicator">
            <span className="chat-input-magic-indicator-title">Now type something...</span>
            <span className="chat-input-magic-indicator-body">
              <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                components={{
                  a({ href, children }) {
                    return <Link to={href || ''}>{children}</Link>
                  }
                }}
              >
                {getMagicPrompt()}
              </ReactMarkdown>
            </span>
            <ArrowDownIcon />
          </div>
        )}
        <ChatSuggestions />
        <form className="chat-input-container" onSubmit={(e) => handleSubmit(e)}>
          {ref && (
            <div className="chat-reply">
              <div className="chat-reply-label">
                <ArrowUturnRightIcon />
                Reply
              </div>
              <Button
                size="sm"
                type="button"
                isIcon
                onClick={() => dispatch(setChatRef(undefined))}
              >
                <XCircleIcon />
              </Button>
            </div>
          )}
          <div className="chat-input">
            <ReactTextareaAutosize
              id="chat-bot-input"
              value={input}
              placeholder="Ask Dottie a question or make a request..."
              minRows={0}
              maxRows={5}
              enterKeyHint="send"
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault()
                  handleSubmit()
                }
              }}
              onChange={(e) => dispatch(setChatInput(e.target.value))}
              ref={inputRef}
            />
            <div className="chat-input-actions">
              <div className="chat-input-actions-group">
                <button
                  data-tooltip={!showTemplates ? 'Choose template' : ''}
                  data-tooltip-pos="top-start"
                  data-has-template={!!template}
                  type="button"
                  ref={templatesBtnRef}
                  className="chat-template-select"
                  onClick={() => handleShowTemplateOptions()}
                >
                  <div className="border-gradient" />
                  <ArchiveBoxIcon />
                  <span className="template-name">{template && getTemplateLabel(template)}</span>
                </button>
                <button
                  data-tooltip="Suggest an action"
                  data-tooltip-pos="top-center"
                  type="button"
                  onClick={() => sendMessage('How can I show up on social today?', 'daily-action')}
                >
                  <SparklesIcon />
                </button>
                {splits.personas === 'on' && (
                  <button
                    data-tooltip={!showPersonas ? 'Switch persona' : ''}
                    data-tooltip-pos="top-center"
                    data-active={showPersonas}
                    type="button"
                    className="persona-btn"
                    onClick={() => setShowPersonas(!showPersonas)}
                    ref={personaBtnRef}
                  >
                    <PersonasIcon />
                  </button>
                )}
                <button
                  data-tooltip="New conversation"
                  data-tooltip-pos="top-center"
                  type="button"
                  onClick={() => clearChat()}
                >
                  <BroomIcon />
                </button>
                {/* <Button type="button" className="icon-btn" isIcon>
                  <ArrowDownTrayIcon />
                </Button> */}
              </div>
              <button
                disabled={!input.trim() || loading}
                id="chat-input-send-btn"
                className="send-btn"
                type="submit"
              >
                {loading && <Spinner />}
                {!loading && <PaperAirplaneIcon />}
              </button>
            </div>
          </div>
        </form>
      </StyledChatInput>
      <OptionsPopover
        options={[
          {
            icon: <DocumentTextIcon />,
            label: 'Generate a caption',
            isSelected: template === 'caption',
            action: () => dispatch(setChatTemplate('caption'))
          },
          {
            icon: <HashtagIcon />,
            label: 'Recommend hashtags',
            isSelected: template === 'hashtags',
            action: () => dispatch(setChatTemplate('hashtags'))
          },
          {
            icon: <PhotoIcon />,
            label: 'Suggest a gallery photo',
            isSelected: template === 'photo',
            action: () => dispatch(setChatTemplate('photo'))
          },
          // {
          //   icon: <VideoCameraIcon />,
          //   label: 'Suggest a video',
          //   isSelected: template === 'video',
          //   action: () => dispatch(setChatTemplate('video'))
          // },
          {
            icon: <LightBulbIcon />,
            label: 'Find visual ideas',
            isSelected: template === 'visual-ideas',
            action: () => dispatch(setChatTemplate('visual-ideas'))
          },
          {
            icon: <Bars3BottomLeftIcon />,
            label: 'Fill in caption blanks',
            isSelected: template === 'blanks',
            action: () => dispatch(setChatTemplate('blanks'))
          },
          {
            icon: <VideoCameraIcon />,
            label: 'Write a video script',
            isSelected: template === 'video-script',
            action: () => dispatch(setChatTemplate('video-script'))
          },
          {
            icon: <VideoCameraIcon />,
            label: 'Write a live video outline',
            isSelected: template === 'live-video-outline',
            action: () => dispatch(setChatTemplate('live-video-outline'))
          },
          {
            icon: <ChatIcon />,
            label: 'General conversation',
            isSelected: template === undefined,
            action: () => dispatch(setChatTemplate(undefined))
          }
        ]}
        position="top-left"
        width={320}
        divsAfter={['Write a live video outline']}
        labelsBefore={[{ label: 'Content Templates', before: 'Generate a caption' }]}
        classToAvoid="chat-template-select"
        isOpen={showTemplates}
        onClose={() => dispatch(setShowChatTemplates(false))}
        buttonRef={templatesBtnRef}
        sameParent={false}
      />
      <OptionsPopover
        options={[
          ...(user?.personas || [])?.map((p) => ({
            label: p.title || 'Untitled Persona',
            isSelected: persona === p._id,
            action: () => dispatch(setChatPersona(p._id))
          })),
          {
            label: user?.aiOnboarding?.title || 'Default Persona',
            isSelected: !persona,
            action: () => dispatch(setChatPersona(undefined))
          },
          {
            icon: <PlusCircleIcon />,
            label: 'Add persona',
            action: () => history.push(ROUTES.settings.personas)
          }
        ]}
        position="top-left"
        divsAfter={[user?.aiOnboarding?.title || 'Default Persona']}
        width={200}
        classToAvoid="persona-btn"
        isOpen={showPersonas}
        onClose={() => setShowPersonas(false)}
        buttonRef={personaBtnRef}
      />
    </>
  )
}

export default ChatInput
