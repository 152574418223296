import styled from 'styled-components'

const StyledPostListItem = styled.div`
  padding: 14px;

  .post-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    .post-details-container {
      display: flex;
      align-items: center;

      .post-profiles {
        display: flex;
        align-items: center;
        margin-right: 10px;
      }

      .post-details {
        span {
          display: block;
        }

        .post-title {
          font-weight: 600;
          color: ${({ theme }) => theme.color.gray[800]};
          display: -webkit-box;
          -webkit-line-clamp: 1;
          overflow: hidden;
          text-align: left;
          -webkit-box-orient: vertical;
        }

        .post-date {
          font-size: 14px;
          color: ${({ theme }) => theme.color.gray[600]};
        }
      }
    }

    .options-btn {
      height: 36px;
      width: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      border-radius: 8px;
      margin-right: -6px;
      background-color: transparent;
      color: ${({ theme }) => theme.color.gray[800]};

      svg {
        height: 32px;
      }
    }
  }

  .post-preview-container {
    border-radius: 12px;
    height: 200px;
    background-color: ${({ theme }) => theme.color.gray[100]};
    overflow: hidden;
    position: relative;

    .post-chips {
      z-index: 1;
      position: absolute;
      bottom: 10px;
      left: 10px;
      display: flex;

      .post-chip {
        height: 32px;
        min-width: 32px;
        box-sizing: border-box;
        transition: 0.2s;
        font-weight: 600;
        background-color: ${({ theme }) => theme.color.gray[800]};
        color: ${({ theme }) => theme.color.gray[100]};
        box-shadow: 0 0 0 1px rgb(255, 255, 255, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50rem;
        padding: 0 8px;
        font-size: 13px;

        svg {
          height: 18px;
          margin-right: 4px;
        }

        &.icon {
          width: 32px;
          padding: 0;

          svg {
            height: 22px;
            margin: 0;
          }
        }

        &.error {
          background-color: ${({ theme }) => theme.color.red[600]};
          color: white;
          border-radius: 50%;
        }

        &:not(:last-child) {
          margin-right: 6px;
        }
      }
    }

    .post-media-length {
      z-index: 1;
      position: absolute;
      bottom: 10px;
      right: 10px;
      height: 32px;
      transition: 0.2s;
      font-weight: 500;
      background-color: rgb(0, 0, 0, 0.5);
      color: rgb(255, 255, 255, 0.9);
      box-shadow: 0 0 0 1px rgb(255, 255, 255, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      padding: 0 8px;
      font-size: 13px;
    }

    .empty-icon {
      height: 46px;
      stroke-width: 1px;
      color: ${({ theme }) => theme.color.gray[400]};
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .media-preview {
      position: absolute;
      top: 15px;
      left: 15px;
      transition: 0.2s;
      width: calc(100% - 30px);
      height: calc(100% - 30px);
      pointer-events: none;

      img,
      video {
        border-radius: 6px;
        max-width: 100%;
        max-height: 100%;
        width: fit-content;
        margin: 0 auto;
        filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.2));
        position: relative;
      }
    }

    .post-layout-preview {
      position: absolute;
      transition: 0.2s;
      top: 15px;
      pointer-events: none;
      left: 15px;
      width: calc(100% - 30px);
      bottom: 0;
      height: 100%;
      overflow: hidden;
      background-color: ${({ theme }) => theme.color.background};
      border-radius: 6px 6px 0 0;
      box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
        0px 2px 3px rgba(0, 0, 0, 0.05);

      img {
        width: 100%;
      }

      .post-caption-preview {
        padding: 16px;
        font-size: 13px;
        display: block;
        line-height: 16px;
        color: ${({ theme }) => theme.color.gray[500]};
      }
    }

    &::after {
      user-select: none;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 12px;
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.foreground}10;
      pointer-events: none;
      z-index: 1;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.gray[200]};
  }
`

export default StyledPostListItem
