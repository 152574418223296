import { FC, RefObject, useEffect, useState } from 'react'
import classNames from 'classnames'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { useEditor, useFrame } from '@layerhub-io/react'
import { Button, Input, Popover } from '../..'
import { RESIZE_TEMPLATES } from './resizeTemplates'
import { StyledResizePopover } from '.'

type Props = {
  position?: 'top-left' | 'top-center' | 'bottom-right' | 'bottom-left'
  classToAvoid: string
  buttonRef: RefObject<HTMLDivElement | HTMLButtonElement>
  isOpen: boolean
  onClose: () => void
}

const ResizePopover: FC<Props> = ({
  position = 'bottom-right',
  classToAvoid,
  buttonRef,
  isOpen,
  onClose
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState<(typeof RESIZE_TEMPLATES)[number]>({
    label: 'Custom size',
    x: 1200,
    y: 1200
  })
  const frame = useFrame()
  const editor = useEditor()
  const [canContinue, setCanContinue] = useState(false)
  const [query, setQuery] = useState('')

  const handleResize = () => {
    editor.frame.resize({
      width: selectedTemplate.x,
      height: selectedTemplate.y
    })
    onClose()
  }

  const filterTemplatesByQuery = (templates: typeof RESIZE_TEMPLATES) => {
    if (!query.trim()) {
      return templates
    }

    return templates.filter((template) => {
      const label = template.label.toLowerCase()
      const labelSplit = label.split(' ')
      const oneWordMatches = labelSplit.find((text) => text.startsWith(query.toLowerCase()))
      const entireLabelMatches = labelSplit.length > 1 && label.includes(query.toLowerCase())

      return oneWordMatches || entireLabelMatches
    })
  }

  useEffect(() => {
    const sameWidth = frame?.width === selectedTemplate.x
    const sameHeight = frame?.height === selectedTemplate.y

    setCanContinue(!sameWidth || !sameHeight)
  }, [isOpen, frame, selectedTemplate])

  useEffect(() => {
    if (frame) {
      setQuery('')
      setSelectedTemplate({
        label: 'Custom Size',
        x: frame.width,
        y: frame.height
      })
    }
  }, [isOpen, frame])

  return (
    <Popover
      position={position}
      classToAvoid={classToAvoid}
      buttonRef={buttonRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <StyledResizePopover>
        <Input
          value={query}
          size="lg"
          icon={<MagnifyingGlassIcon />}
          placeholder="Search sizes"
          className="search-input"
          onChange={(e) => setQuery(e.target.value.trimStart())}
        />
        <div className="resize-option-list">
          {!query.trim() && (
            <div className="resize-custom">
              <span className="resize-option-label">Custom size</span>
              <div className="resize-custom-input-container">
                <div className="resize-custom-input">
                  <label>
                    <span className="resize-custom-input-label">Width</span>
                    <Input
                      value={selectedTemplate?.x || ''}
                      size="sm"
                      inputMode="numeric"
                      pattern="\d*"
                      step="any"
                      type="number"
                      onChange={(e) =>
                        setSelectedTemplate({
                          ...selectedTemplate,
                          label: 'Custom size',
                          x: parseInt(e.target.value)
                        })
                      }
                    />
                  </label>
                </div>
                <div className="resize-custom-input">
                  <label>
                    <span className="resize-custom-input-label">Height</span>
                    <Input
                      value={selectedTemplate?.y || ''}
                      size="sm"
                      inputMode="numeric"
                      pattern="\d*"
                      step="any"
                      type="number"
                      onChange={(e) =>
                        setSelectedTemplate({
                          ...selectedTemplate,
                          label: 'Custom size',
                          y: parseInt(e.target.value)
                        })
                      }
                    />
                  </label>
                </div>
                <div className="resize-custom-input-metric">PX</div>
              </div>
            </div>
          )}
          <div className="resize-template-list">
            <span className="resize-option-label">
              {!query.trim() ? 'Recommended sizes' : 'Search results'}
            </span>
            {filterTemplatesByQuery(RESIZE_TEMPLATES).map((template) => {
              return (
                <button
                  className={classNames('resize-template-list-item', {
                    active: selectedTemplate.label === template.label
                  })}
                  onClick={() => setSelectedTemplate(template)}
                >
                  <div className="resize-template-preview">
                    <div
                      className="resize-template-preview-card"
                      // style={{ aspectRatio: `${template.x}/${template.y}` }}
                      style={{
                        height: `${(template.y / template.x) * 100}%`,
                        maxWidth: `${(template.x / template.y) * 100}%`
                      }}
                    />
                  </div>
                  <span className="resize-template-label">{template.label}</span>
                  {template.label === 'Instagram Post' && (
                    <span className="resize-template-popular">POPULAR</span>
                  )}
                  <span className="resize-template-size">
                    {template.x} × {template.y} px
                  </span>
                </button>
              )
            })}
          </div>
        </div>
        <div className="resize-actions">
          <Button
            variant="emphasis"
            disabled={!canContinue}
            isFullWidth
            onClick={() => handleResize()}
          >
            Resize
          </Button>
        </div>
      </StyledResizePopover>
    </Popover>
  )
}

export default ResizePopover
