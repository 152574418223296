import styled, { keyframes } from 'styled-components'

const sparkleStar1Anim = keyframes`
  0% {
    tranform: translateY(10px);
    opacity: 0;
  }
  90% {
    transform: rotate(8deg) translateY(0);
    opacity: 1;
  }
  100% {
    transform: rotate(8deg) translateY(0);
    opacity: 0;
  }
`

const StyledMagicPostDialog = styled.div`
  padding: 30px;
  position: relative;
  width: 100vw;
  max-width: 400px;

  .sparkle {
    position: fixed;
    color: white;
    height: 46px;
    bottom: calc(100% + 8px);

    path:nth-of-type(1) {
      transform: translateY(10px);
      opacity: 0;
      animation: infinite 1.5s ease-in-out ${sparkleStar1Anim};
    }

    path:nth-of-type(2) {
      animation-delay: 0.2s;
      transform: translateY(10px);
      opacity: 0;
      animation: infinite 2s ease-in-out ${sparkleStar1Anim};
    }

    path:nth-of-type(3) {
      animation-delay: 0.4s;
      transform: translateY(10px);
      opacity: 0;
      animation: infinite 1.2s ease-in-out ${sparkleStar1Anim};
    }

    &:nth-of-type(1) {
      left: 4px;
      opacity: 0.5;
      transform: rotate(-19deg) skewY(357deg);
    }

    &:nth-of-type(2) {
      right: 4px;
      opacity: 0.5;
      transform: rotate(8deg) skewY(-357deg) rotateY(180deg);
    }
  }

  .magic-post-prompt {
    position: fixed;
    bottom: calc(100% + 20px);
    z-index: 999;
    text-shadow: 1px 1px 3px rgb(0, 0, 0, 0.4);
    color: white;
    text-align: center;
    left: 50%;
    font-size: 22px;
    width: 100%;
    transform: translateX(-50%);
    font-family: ${({ theme }) => theme.typeface.polySans};
  }

  form {
    display: grid;
    grid-gap: 25px;
  }

  .input-container {
    margin-bottom: -2px;

    .label {
      color: ${({ theme }) => theme.color.gray[700]};
      font-weight: 500;
      display: block;
      margin-bottom: 6px;
      font-size: 15px;
      cursor: default;
    }
  }

  .magic-post-recommendations {
    .magic-post-recommendations-title {
      color: ${({ theme }) => theme.color.brand};
      font-weight: 500;
      display: block;
      margin-bottom: 6px;
      font-size: 15px;
      cursor: default;
    }

    .bubble {
      color: ${({ theme }) => theme.color.brand};
      background-color: ${({ theme }) => theme.color.brandLight};

      &[aria-selected='true'] {
        color: #fff;
        background-color: ${({ theme }) => theme.color.brand};
      }
    }
  }

  .result-container {
    display: grid;
    grid-gap: 25px;

    .img-preview {
      height: 200px;
      padding: 20px;
      pointer-events: none;
      box-sizing: border-box;
      border-radius: 6px;
      background-color: ${({ theme }) => theme.color.gray[100]};
      box-shadow: 0 0 0 1px ${({ theme }) => theme.color.foreground}20;

      .photo-img {
        position: relative;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          object-fit: contain;
          filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
        }
      }
    }

    .caption {
      font-size: 14px;
      color: ${({ theme }) => theme.color.gray[600]};
      line-height: 24px;
      line-break: loose;
      user-select: unset;
      padding: 20px;
      border-radius: 6px;
      box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
        0px 2px 3px rgba(0, 0, 0, 0.05);
    }

    .actions {
      display: flex;

      .try-again-btn {
        margin-right: 10px;
        flex-shrink: 0;
      }
    }
  }
`

export default StyledMagicPostDialog
