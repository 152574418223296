import { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { PlayIcon } from '@heroicons/react/24/solid'
import { ActionPlanType } from '../../../../types'
import { MOBILE_ROUTES } from '../../../utils'
import { RenderSlate } from '../..'
import { StyledActionPlanListItem } from '.'

type Props = {
  actionPlan: ActionPlanType
}

const ActionPlanListItem: FC<Props> = ({ actionPlan }) => {
  const history = useHistory()
  return (
    <StyledActionPlanListItem
      onClick={() => history.push(MOBILE_ROUTES.actionPlans.actionPlanBySlug(actionPlan.slug))}
    >
      <div className="action-plan-img">
        {actionPlan?.headerImg?.fileHttpLink && <img src={actionPlan.headerImg.fileHttpLink} />}
        <div className="action-plan-play-indicator">
          <div>
            <PlayIcon />
          </div>
        </div>
      </div>
      <div className="action-plan-details">
        {actionPlan.type && (
          <span className="action-plan-number">
            {actionPlan.type === 'bonus' && 'BONUS'}
            {actionPlan.type !== 'bonus' &&
              !!actionPlan.number &&
              `ACTION PLAN #${actionPlan.number}`}
            {actionPlan.type !== 'bonus' && !actionPlan.number && `ACTION PLAN`}
          </span>
        )}
        <span className="action-plan-title">{actionPlan.title}</span>
        {actionPlan.about && (
          <span className="action-plan-preview">
            <RenderSlate html={actionPlan.about} plainText />
          </span>
        )}
      </div>
    </StyledActionPlanListItem>
  )
}

export default ActionPlanListItem
