import styled from 'styled-components'

const StyledOptionsDialog = styled.div`
  position: relative;
  width: 100vw;

  .menu-group {
    display: grid;
    grid-gap: 12px;
    padding: 12px;

    a,
    button {
      border-radius: 6px;
      display: flex;
      align-items: center;
      text-decoration: none;
      background-color: transparent;
      transition: background-color 0.2s, color 0.2s;
      color: ${({ theme }) => theme.color.gray[700]};
      padding: 0 6px;
      height: 32px;
      margin: -6px;
      font-size: 15px;
      position: relative;

      .new-flag {
        padding: 3px 6px;
        margin-left: 6px;
        font-size: 11px;
        font-weight: 600;
        letter-spacing: 0.05rem;
        border-radius: 50rem;
        color: ${({ theme }) => theme.color.background};
        background-color: ${({ theme }) => theme.color.brand};
      }

      svg,
      img {
        color: ${({ theme }) => theme.color.gray[600]};
        margin-right: 10px;
        height: 20px;
        stroke-width: 1.7px;
        transition: color 0.2s;
      }

      .selected-icon {
        position: absolute;
        margin: 0;
        right: 6px;
      }

      &:hover {
        color: ${({ theme }) => theme.color.gray[900]};
        background-color: ${({ theme }) => theme.color.gray[100]};

        svg {
          color: ${({ theme }) => theme.color.gray[900]};
        }
      }

      &.active {
        color: ${({ theme }) => theme.color.brand};
        background-color: ${({ theme }) => theme.color.brandLight};

        svg {
          color: ${({ theme }) => theme.color.brand};
        }
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
    }

    hr {
      background-color: ${({ theme }) => theme.color.gray[300]};
      width: calc(100% + 24px);
      height: 1px;
      border: 0;
      margin: 0 0 0 -12px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    max-width: 300px;
  }
`

export default StyledOptionsDialog
