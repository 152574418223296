import styled from 'styled-components'

const StyledUploadsStorage = styled.div`
  padding: 16px;
  user-select: none;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .label {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.01rem;
      color: ${({ theme }) => theme.color.gray[800]};
    }
  }

  .storage-indicator-container {
    display: grid;
    gap: 8px;
    margin-top: 16px;

    .storage-label {
      display: block;
      font-size: 14px;
      color: ${({ theme }) => theme.color.gray[400]};

      b {
        font-weight: 500;
        color: ${({ theme }) => theme.color.gray[500]};
      }
    }

    progress {
      width: 100%;
      overflow: hidden;
      border-radius: 50rem;
      height: 5px;

      &::-webkit-progress-bar {
        background-color: ${({ theme }) => theme.color.gray[200]};
        border-radius: 50rem;
      }

      &::-webkit-progress-value {
        background-color: ${({ theme }) => theme.color.brand};
        transition: 0.2s;
        border-radius: 50rem;
      }
    }
  }
`

export default StyledUploadsStorage
