export const day1 = {
  _id: 'day1',
  title: 'DAY 1',
  tasks: [
    {
      label: 'Create a new post',
      steps: [
        {
          label: 'Click the blue “New Post” button at the top of the left sidebar'
        }
      ]
    },
    {
      label: 'Attach an image to your new post',
      steps: [
        {
          label: 'Navigate to the Post Editor'
        },
        {
          label: 'Either:'
        },
        {
          label: 'Select a suggested image from the left bar',
          isSub: true
        },
        {
          label:
            'Upload your own image (drag & drop or click the “Upload Photos” button in the Media tab)',
          isSub: true
        },
        {
          label: 'Start a new design (click the “Start a design” button) in the Media tab',
          isSub: true
        }
      ]
    },
    {
      label: 'Write your new post’s caption',
      steps: [
        {
          label: 'Navigate to the Post Editor'
        },
        {
          label: 'Either:'
        },
        {
          label: 'Combine suggested caption bits (left hand sidebar)',
          isSub: true
        },
        {
          label: 'Use a template from the Caption Templates (click “choose a caption”)',
          isSub: true
        },
        {
          label: 'Write your own caption from scratch',
          isSub: true
        }
      ]
    },
    {
      label: 'Connect an Instagram, Facebook or LinkedIn account (or disconnect & reconnect)',
      steps: [
        {
          label: 'Click your “User Menu” at the bottom of the left sidebar (where your name is)'
        },
        {
          label: 'In the newly opened popover menu, click “Connections”'
        },
        {
          label: 'Select which type of account you’d like to connect (Instagram/Facebook/LinkedIn)'
        },
        {
          label: 'Follow the relevant setup tasks'
        }
      ]
    },
    {
      label: 'Schedule your new post to auto-publish to a social profile and date of your choosing',
      steps: [
        {
          label: 'Navigate to the Post Editor'
        },
        {
          label: 'Click the blue “Share Post” button near the bottom of your screen'
        },
        {
          label: 'Select “where” you’d like to post (which social profiles to post to)'
        },
        {
          label:
            'Select “when” you’d like Social Curator to automatically publish your post (you can set the date to the past if you don’t actually want it to publish)'
        },
        {
          label: 'Click the blue “Confirm” button to finish scheduling your post'
        }
      ]
    }
  ],
  form: {
    url: 'https://notionforms.io/forms/day-1-feedback',
    keys: {
      FULL_NAME: '6488f331-0b1a-4fc0-bf4e-8e1de59cbfbe',
      EMAIL: '77138653-99da-493a-8f41-f89fd3b23e4c',
      SCREEN_RECORDING: 'f0f96337-db27-477a-afb2-de550a96ba97',
      USER_ID: '27eda6ea-7f9b-4720-9ddb-fefd4af7c976'
    }
  }
}
