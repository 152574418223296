import { FC } from 'react'
import { useEditor } from '@layerhub-io/react'
import { TrashIcon } from '@heroicons/react/24/outline'
import { DesignerToolButton } from '.'

const DesignerDeleteTool: FC = () => {
  const editor = useEditor()

  return (
    <DesignerToolButton data-tooltip="Delete" isIcon onClick={() => editor.objects.remove()}>
      <TrashIcon />
    </DesignerToolButton>
  )
}

export default DesignerDeleteTool
