import { FC } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { RectangleStackIcon } from '@heroicons/react/24/solid'
import canvaLogo from '../../../assets/canva-icon.png'
import { setContentToView, useAppDispatch } from '../../../redux'
import { StorySetType } from '../../../types'
import { linkToSizedImage } from '../../../utils'
import { StyledStoryListItem } from '.'

type Props = {
  story: StorySetType
}

const StoryListItem: FC<Props> = ({ story }) => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useAppDispatch()

  return (
    <StyledStoryListItem
      onClick={() => {
        history.push(`${window.location.pathname}/story/${story._id}`, {
          scroll: 'none',
          backUrl: location.pathname
        })
        dispatch(setContentToView({ content: story, type: 'story' }))
      }}
    >
      <div className="story-list-item-preview">
        <div className="story-list-item-tags">
          {story.canvaLink && (
            <div className="story-list-item-tag canva">
              <img src={canvaLogo} alt="Canva Logo" />
            </div>
          )}
          <div className="story-list-item-tag count">
            <RectangleStackIcon style={{ transform: 'rotate(90deg)' }} />
            <span>{story.records?.length || 0}</span>
          </div>
        </div>
        <img
          src={linkToSizedImage(story.thumbnail, { height: 500 })}
          alt=""
          className="story-list-item-img"
        />
      </div>
      <div className="story-list-item-details">
        {!!story.index && (
          <span className="story-list-item-title">Story Set #{story.index + 1}</span>
        )}
        <span className="story-list-item-description">{story.title}</span>
      </div>
    </StyledStoryListItem>
  )
}

export default StoryListItem
