import styled from 'styled-components'

const StyledAuthenticateWithFacebookDialog = styled.div`
  max-width: 500px;
  width: 100vw;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr) auto;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 55px;
    padding: 0 16px;
    border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};

    .title {
      font-weight: 600;
      color: ${({ theme }) => theme.color.gray[800]};
    }
  }

  .body {
    padding: 16px;
    overflow-y: auto;
    color: ${({ theme }) => theme.color.gray[700]};
    font-size: 15px;
    line-height: 20px;

    .body-title {
      font-weight: 600;
      color: ${({ theme }) => theme.color.gray[800]};
      margin-bottom: 6px;
      display: block;
    }

    b {
      font-weight: 500;
    }

    a {
      color: ${({ theme }) => theme.color.brand};
    }
  }

  .action-bar {
    height: 80px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: end;
    background-color: ${({ theme }) => theme.color.gray[100]};

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      padding: 0 14px;
      border-radius: 6px;
      transition: 0.2s;

      svg {
        height: 20px;
        margin-right: 10px;
      }

      &.cancel-btn {
        background-color: transparent;
        color: ${({ theme }) => theme.color.gray[500]};

        &:hover {
          color: ${({ theme }) => theme.color.gray[800]};
        }
      }

      &.continue-btn {
        background-color: ${({ theme }) => theme.color.brand};
        font-weight: 500;
        color: white;

        &:hover {
          background-color: ${({ theme }) => theme.color.brandDark};
        }
      }
    }
  }
`

export default StyledAuthenticateWithFacebookDialog
