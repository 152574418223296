import styled from 'styled-components'

const StyledConnectionDisclaimer = styled.div`
  padding: 0 30px calc(var(--bottom-spacing) + 10px) 30px;
  position: relative;
  user-select: none;

  .back-btn {
    position: absolute;
    top: 0;
    left: 16px;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.color.gray[500]};
    background-color: ${({ theme }) => theme.color.gray[200]};

    svg {
      height: 24px;
      stroke-width: 2px;
    }
  }

  .connection-indicator {
    display: flex;
    align-items: center;
    justify-content: center;

    .platform-image {
      height: 52px;
      width: 52px;
      flex-shrink: 0;
      position: relative;
      pointer-events: none;

      img {
        width: 100%;
        height: 100%;
      }

      svg {
        height: 30px;
      }

      &.rounded {
        border-radius: 12px;
        overflow: hidden;
        background-color: ${({ theme }) => theme.color.brand};
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .connection-icon {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 24px;
        stroke-width: 1.75px;
        margin: 0 12px;
        color: ${({ theme }) => theme.color.gray[500]};
      }
    }
  }

  .connection-title {
    display: block;
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
    margin: 30px 0 16px 0;
    font-family: ${({ theme }) => theme.typeface.garnett};
    color: ${({ theme }) => theme.color.gray[800]};
  }

  .connection-description {
    font-size: 14px;
    text-align: center;
    max-width: 320px;
    margin: 0 auto 0 auto;
    line-height: 20px;
    display: block;
    color: ${({ theme }) => theme.color.gray[600]};
  }

  .connection-actions {
    margin-top: 40px;

    button {
      width: 100%;
      height: 60px;
      color: white;
      font-weight: 500;
      background-color: ${({ theme }) => theme.color.brand};
      border-radius: 12px;
    }
  }
`

export default StyledConnectionDisclaimer
