import styled from 'styled-components'

const StyledCalendarWeekHeading = styled.div`
  padding-bottom: 6px;

  .heading-label {
    text-align: center;
    font-size: 10px;
    font-weight: 700;
    color: ${({ theme }) => theme.color.gray[400]};
  }

  .heading-day {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    width: 38px;
    margin: 0 auto;
    font-weight: 500;
    text-align: center;
    font-size: 20px;
    color: ${({ theme }) => theme.color.gray[800]};
    font-family: ${({ theme }) => theme.typeface.garnett};
  }

  &[data-today='true'] {
    .heading-label {
      color: ${({ theme }) => theme.color.brand};
    }

    .heading-day {
      color: ${({ theme }) => theme.color.brand};
      background-color: ${({ theme }) => theme.color.brandLight};
    }
  }

  &[data-past='true'] {
    .heading-label {
      color: ${({ theme }) => theme.color.gray[400]};
    }

    .heading-day {
      color: ${({ theme }) => theme.color.gray[400]};
    }
  }
`

export default StyledCalendarWeekHeading
