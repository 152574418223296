import styled from 'styled-components'

const StyledAccountView = styled.div`
  .account-view-header {
    padding-bottom: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: end;
    justify-content: space-between;
    border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};

    h1 {
      font-size: 22px;
      font-weight: 500;
      font-family: ${({ theme }) => theme.typeface.garnett};
      color: ${({ theme }) => theme.color.gray[800]};
    }

    h2 {
      font-size: 16px;
      font-weight: 400;
      color: ${({ theme }) => theme.color.gray[500]};
    }
  }

  .account-content-layout {
    display: grid;
    grid-gap: 20px;
    align-items: flex-start;

    .account-form {
      display: grid;
      grid-gap: 24px;
      align-content: start;

      hr {
        border: 0;
        height: 1px;
        margin: 0;
        background-color: ${({ theme }) => theme.color.gray[300]};
        width: 100%;
      }

      label,
      .input-container {
        display: grid;
        grid-gap: 8px;
        cursor: default;

        .input-label {
          font-weight: 500;
          user-select: none;
          color: ${({ theme }) => theme.color.gray[600]};
        }

        .input-subtext {
          font-size: 14px;
          user-select: none;
          color: ${({ theme }) => theme.color.gray[500]};
        }
      }

      .app-version {
        font-size: 14px;
        user-select: none;
        color: ${({ theme }) => theme.color.gray[500]};
        display: block;
        text-align: center;
        margin-top: -18px;
        padding: 4px;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.m}px) {
    padding-bottom: 20px;

    .account-view-header {
      padding-top: 20px;
      margin-bottom: 40px;
    }

    .account-content-layout {
      grid-gap: 40px;
      justify-content: space-between;
      grid-template-columns: minmax(0, 1fr) 320px;
    }
  }
`

export default StyledAccountView
