import styled from 'styled-components'

const StyledCalendarWeekTime = styled.div`
  width: 60px;
  height: 100%;
  flex-shrink: 0;

  .header {
    height: 48px;
    border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
  }

  .column {
    height: 100%;
    border-right: 1px solid ${({ theme }) => theme.color.gray[300]};
  }
`

export default StyledCalendarWeekTime
