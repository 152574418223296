import { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { DateTime } from 'luxon'
import { useDroppable } from '@dnd-kit/core'
import { PlusIcon } from '@heroicons/react/24/outline'
import { setCreatePost, useAppDispatch, useAppSelector } from '../../../redux'
import { CalendarEventType } from '../../../types'
import { ROUTES, isMobile, parseDate, useCreate } from '../../../utils'
import { CalendarPostItem } from '../..'
import { StyledCalendarWeekRowSlot } from '.'

type Props = {
  now: DateTime
  date: DateTime
  hour: number
  thisWeek?: boolean
}

const CalendarWeekRowSlot: FC<Props> = ({ now, date, hour, thisWeek }) => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const ID = `calendar-slot-${date.day}-${hour}`
  const [todayEvents, setTodayEvents] = useState<CalendarEventType[]>([])
  const { events } = useAppSelector((state) => state.calendar)
  const { newPost } = useCreate()
  const { isOver, setNodeRef } = useDroppable({
    id: ID,
    data: {
      date: date.toISO()
    }
  })

  const newPostAtDate = (date: DateTime) => {
    newPost()
    dispatch(
      setCreatePost({
        postDate: date.toISO()
      })
    )

    history.push(ROUTES.create)
  }

  useEffect(() => {
    setTodayEvents(
      events.filter((event) => {
        const diff = parseDate(event?.metadata?.day)?.diff(date, 'hours')?.hours
        return diff >= 0 && diff < 1
      })
    )
  }, [events])

  return (
    <StyledCalendarWeekRowSlot isOver={isOver} ref={setNodeRef}>
      {now.weekday === date.weekday && now.hour === hour && thisWeek && (
        <div
          className="calendar-now-today-line"
          style={{ top: `calc(${(now.minute / 60) * 100}% - 1.5px)` }}
        />
      )}
      {todayEvents.map((event) => {
        return (
          <CalendarPostItem
            post={event.metadata.data as any}
            event={event}
            parent={ID}
            key={event._id}
          />
        )
      })}
      <button
        // data-tooltip={`Create post for ${date.toFormat("LLL d 'at' t")}`}
        className="add-post-btn"
        onClick={() => newPostAtDate(date)}
      >
        <PlusIcon />
      </button>
    </StyledCalendarWeekRowSlot>
  )
}

export default CalendarWeekRowSlot
