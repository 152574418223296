import { gql } from '@apollo/client'

export const pageFindMany = gql`
  query PageFindMany($filter: FilterFindManyPageInput) {
    pageFindMany(filter: $filter) {
      _id
      username
      user
      name
      description
      avatar
      links {
        _id
        type
        metadata {
          label
          url
        }
      }
      profiles {
        _id
        type
        url
      }
      styles {
        avatar {
          borderRadius
          borderWidth
          borderColor
        }
        title {
          fontSize
          color
        }
        description {
          fontSize
          color
        }
        page {
          backgroundColor
          fontFamily
        }
        link {
          fontSize
          color
          backgroundColor
          padding
          borderRadius
          borderWidth
          borderColor
        }
      }
      createdAt
    }
  }
`
