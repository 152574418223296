const L1_ICON = (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
      fill="currentcolor"
    />
  </svg>
)

const L2_ICON = (
  <svg viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 0L24 21H0L12 0Z" fill="currentcolor" />
  </svg>
)

const L3_ICON = (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0H24V24H0V0Z" fill="currentcolor" />
  </svg>
)

const L4_ICON = (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 12L12 0L24 12L12 24L0 12Z" fill="currentcolor" />
  </svg>
)

const L5_ICON = (
  <svg viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 0L24 8.71901L19.4164 22.8267H4.58359L0 8.71901L12 0Z" fill="currentcolor" />
  </svg>
)

const L6_ICON = (
  <svg viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 0L24 6.92647V20.7794L12 27.7059L0 20.7794V6.92647L12 0Z" fill="currentcolor" />
  </svg>
)

const L7_ICON = (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 0L15.2411 8.75891L24 12L15.2411 15.2411L12 24L8.75891 15.2411L0 12L8.75891 8.75891L12 0Z"
      fill="currentcolor"
    />
  </svg>
)

export const LEVELS = [
  { stage: 1, icon: L1_ICON, title: 'Beginner', target: 1 },
  { stage: 2, icon: L2_ICON, title: 'Learner', target: 5 },
  { stage: 3, icon: L4_ICON, title: 'Engager', target: 10 },
  { stage: 4, icon: L5_ICON, title: 'Action Taker', target: 20 },
  { stage: 5, icon: L7_ICON, title: 'Industry Leader', target: undefined }
]
