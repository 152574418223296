import { FC, useContext } from 'react'
import { DateTime } from 'luxon'
import { ROUTES, userStore } from '../../../utils'
import { StyledSidebarPlatformToggle } from '.'

const SidebarPlatformToggle: FC = () => {
  const { user } = useContext(userStore)

  const canAccess = () => {
    if (!user || !user?.createdAt) {
      return false
    }

    const signedUp = DateTime.fromSeconds(user.createdAt.seconds)
    const betaStart = DateTime.fromISO('2023-03-16')
    const diff = betaStart.diff(signedUp, 'days').days

    if (diff <= 0) {
      return false
    }

    return true
  }

  if (!canAccess()) {
    return null
  }

  // if (true) {
  //   return (
  //     <StyledSidebarPlatformToggle>
  //       <span className="title">Ready for an upgrade?</span>
  //       <span className="disclaimer">Switch to the new platform by clicking the toggle below.</span>
  //       <button>
  //         Upgraded Platform{' '}
  //         <div className="toggle checked">
  //           <div />
  //         </div>
  //       </button>
  //     </StyledSidebarPlatformToggle>
  //   )
  // }

  return (
    <StyledSidebarPlatformToggle aria-selected="true" href={ROUTES.external.oldPlatform}>
      <span className="title">Looking for the old view?</span>
      <span className="disclaimer">Switch back by clicking the toggle below.</span>
      <button>
        Upgraded Platform{' '}
        <div className="toggle checked">
          <div />
        </div>
      </button>
    </StyledSidebarPlatformToggle>
  )
}

export default SidebarPlatformToggle
