import { FC, SelectHTMLAttributes } from 'react'
import { xor } from 'lodash'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { getSelectedOptions } from '../../../../utils'
import { StyledSelect } from '.'

type Props = {
  value?: string[]
  options: { value: string; label: string }[]
  label?: string
  suggestions?: string[]
  onChange: (value: string[]) => void
} & Omit<SelectHTMLAttributes<HTMLSelectElement>, 'onChange' | 'value'>

const Select: FC<Props> = ({
  value = [],
  options,
  label,
  suggestions,
  required,
  onChange,
  ...rest
}) => {
  return (
    <StyledSelect>
      {label && (
        <div className="input-label">
          {label} {required && <div className="required-marker">*</div>}
        </div>
      )}
      <div className="input">
        <select
          value={value}
          required={required}
          onChange={(e) => onChange(getSelectedOptions(e.target))}
          {...rest}
        >
          {options.map((option) => (
            <option value={option.value}>{option.label}</option>
          ))}
        </select>
        <ChevronDownIcon className="list-icon" />
      </div>
      {!!suggestions?.length && (
        <div className="suggestions">
          {suggestions.map((suggestion) => {
            return (
              <button
                data-selected={!!value.includes(suggestion)}
                onClick={() => onChange(xor(value, [suggestion]))}
              >
                {suggestion}
              </button>
            )
          })}
        </div>
      )}
    </StyledSelect>
  )
}

export default Select
