import { ChangeEvent, FC, createRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useEditor } from '@layerhub-io/react'
import { Button, CloudDropzone, Spinner } from '../../..'
import { toBase64 } from '../../../../utils'
import { DesignerPanel } from '../..'
import { StyledDesignerUploadsPanel } from '.'

const DesignerUploadsPanel: FC = () => {
  const editor = useEditor()
  const inputRef = createRef<HTMLInputElement>()
  const [loading, setLoading] = useState(false)

  const handleUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    setLoading(true)
    const files = [...(e.target.files as any)]

    files.map(async (file) => {
      if (file.type.includes('svg')) {
        const url = URL.createObjectURL(file)

        const object = {
          id: uuidv4(),
          src: url,
          type: 'StaticVector'
        }

        return editor.objects.add(object)
      }

      const base64 = (await toBase64(file)) as string

      const object = {
        id: uuidv4(),
        src: base64,
        preview: base64,
        type: 'StaticImage'
      }

      editor.objects.add(object)
    })
    setLoading(false)
  }

  return (
    <DesignerPanel>
      <StyledDesignerUploadsPanel>
        <div className="designer-panel-header">
          <Button variant="emphasis" isFullWidth onClick={() => inputRef.current?.click()}>
            <input ref={inputRef} hidden type="file" multiple onChange={(e) => handleUpload(e)} />
            {loading ? <Spinner /> : 'Upload photos'}
          </Button>
        </div>
      </StyledDesignerUploadsPanel>
    </DesignerPanel>
  )
}

export default DesignerUploadsPanel
