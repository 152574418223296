import { day1 } from './day1'
import { day2 } from './day2'
import { day3 } from './day3'
import { day4 } from './day4'
import { day5 } from './day5'
import { day7 } from './day7'
import { day8 } from './day8'
import { day9 } from './day9'
import { day10 } from './day10'
import { day11 } from './day11'

export const PRODUCT_TESTS: any = {
  day1,
  day2,
  day3,
  day4,
  day5,
  day7,
  day8,
  day9,
  day10,
  day11
}
