export const RESIZE_TEMPLATES = [
  {
    label: 'Instagram Post',
    x: 1080,
    y: 1080
  },
  {
    label: 'Instagram Story',
    x: 1080,
    y: 1920
  },
  {
    label: 'Facebook Post',
    x: 1200,
    y: 1200
  },
  {
    label: 'LinkedIn Post',
    x: 1200,
    y: 1200
  },
  {
    label: 'LinkedIn Carousel',
    x: 1200,
    y: 1500
  },
  {
    label: 'Facebook Cover',
    x: 1702,
    y: 630
  },
  {
    label: 'Presentation (4:3)',
    x: 1024,
    y: 768
  },
  {
    label: 'Presentation (16:9)',
    x: 1920,
    y: 1080
  },
  {
    label: 'Twitter Post',
    x: 1200,
    y: 675
  },
  {
    label: 'YouTube Thumbnail',
    x: 1280,
    y: 720
  },
  {
    label: 'Instagram Profile Picture',
    x: 320,
    y: 320
  },
  {
    label: 'LinkedIn Profile Picture',
    x: 800,
    y: 800
  },
  {
    label: 'Facebook Profile Picture',
    x: 2048,
    y: 2048
  },
  {
    label: 'TikTok Profile Picture',
    x: 200,
    y: 200
  },
  {
    label: 'TikTok Video',
    x: 1080,
    y: 1920
  },
  {
    label: 'Facebook Ad',
    x: 1200,
    y: 628
  },
  {
    label: 'Facebook App Ad',
    x: 810,
    y: 450
  }
]
