import styled from 'styled-components'

const StyledUploadsSidebarHeader = styled.div`
  padding: 16px;
  user-select: none;

  .uploads-label {
    display: flex;
    align-items: center;
    font-size: 15px;
    color: ${({ theme }) => theme.color.gray[500]};
    transition: 0.2s;
    font-weight: 600;
    text-decoration: none;

    svg {
      height: 18px;
      margin-right: 5px;
    }

    &:hover {
      text-decoration: underline;
      color: ${({ theme }) => theme.color.gray[600]};
    }
  }

  .folder-label {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    margin-top: 8px;
    font-family: ${({ theme }) => theme.typeface.garnett};
    color: ${({ theme }) => theme.color.gray[800]};
  }

  .upload-btn {
    margin-top: 12px;
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    height: 44px;
    color: white;
    background-color: ${({ theme }) => theme.color.brand};
    transition: 0.2s;

    svg {
      height: 20px;
      margin-right: 5px;
      stroke-width: 2px;
    }

    &:hover {
      background-color: ${({ theme }) => theme.color.brandDark};
    }
  }
`

export default StyledUploadsSidebarHeader
