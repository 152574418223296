import { FC, createRef } from 'react'
import { ArrowUpTrayIcon } from '@heroicons/react/24/outline'
import { useAppSelector } from '../../../redux'
import { useUploadManager } from '../../../utils'
import { UploadsList } from '../../components'
import { StyledUploadsView } from '.'

const UploadsView: FC = () => {
  const inputRef = createRef<HTMLInputElement>()
  const { addToUploads } = useUploadManager()
  const { parent } = useAppSelector((state) => state.cloud)

  return (
    <StyledUploadsView data-in-folder={!!parent}>
      {!parent && (
        <div className="action-bar">
          <button className="upload-btn" onClick={() => inputRef?.current?.click()}>
            <ArrowUpTrayIcon />
            Upload media
            <input
              type="file"
              multiple
              accept="image/*, video/*"
              hidden
              ref={inputRef}
              onChange={(e) => addToUploads(e.target.files, false, undefined)}
            />
          </button>
          {/* <button className="new-folder-btn">New folder</button> */}
        </div>
      )}
      <div className="upload-list-scroll" id="upload-list-scroll">
        <UploadsList />
      </div>
    </StyledUploadsView>
  )
}

export default UploadsView
