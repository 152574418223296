import styled from 'styled-components'

type StyledProps = {
  isSaved?: boolean
}

const StyledLinkPageEditor = styled.div<StyledProps>`
  display: grid;
  height: calc(
    100vh - (env(safe-area-inset-top) + 65px) -
      (max(calc(env(safe-area-inset-bottom) - 4px), 10px) + 53px)
  );
  overflow: hidden;
  grid-template-rows: auto minmax(0, 1fr) ${({ isSaved }) => isSaved && 'auto'};

  .link-page-header-container {
    .link-page-header {
      padding: 20px 20px 5px 20px;

      h1 {
        font-weight: 500;
        font-size: 28px;
        display: flex;
        align-items: center;
        margin-top: -9px;
        color: ${({ theme }) => theme.color.gray[800]};
        font-family: ${({ theme }) => theme.typeface.polySans};

        .new-flag {
          padding: 6px 8px 6px 10px;
          margin-left: 10px;
          font-size: 13px;
          font-weight: 600;
          position: relative;
          top: 2px;
          user-select: none;
          letter-spacing: 0.1rem;
          border-radius: 50rem;
          color: #fff;
          background: linear-gradient(
            45deg,
            ${({ theme }) => theme.color.brand},
            ${({ theme }) => theme.color.purple[600]}
          );
        }
      }

      .link-page-details {
        font-size: 18px;
        font-weight: 400;
        letter-spacing: -0.01rem;
        color: ${({ theme }) => theme.color.gray[600]};
        margin-top: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .link-page-username {
          display: flex;
          align-items: center;
          position: relative;
          cursor: pointer;

          .copy-btn {
            color: ${({ theme }) => theme.color.gray[400]};
            background-color: transparent;
            position: absolute;
            left: calc(100% + 4px);
            width: 28px;
            height: 28px;

            &:hover {
              color: ${({ theme }) => theme.color.gray[500]};
            }
          }
        }

        .link-page-stats {
          font-size: 14px;
          font-weight: 500;
          display: none;
          align-items: center;

          div {
            padding: 6px 12px;
            margin: -6px 0;
            display: flex;
            align-items: center;
            background-color: ${({ theme }) => theme.color.gray[100]};
            color: ${({ theme }) => theme.color.gray[600]};
            border-radius: 50rem;

            &:not(:last-child) {
              margin-right: 8px;
            }
          }
        }

        .preview-btn {
          position: absolute;
          top: 20px;
          right: 20px;
        }
      }
    }

    .link-page-tabs {
      width: 100vw;

      .tabs {
        overflow-x: auto;

        .tab-list {
          padding: 0 20px;
        }
      }
    }
  }

  .link-page-content {
    position: relative;

    .link-page-content-scroll {
      overflow-y: auto;
      height: 100%;
    }
  }

  .link-page-actions {
    border-top: 1px solid ${({ theme }) => theme.color.gray[300]};
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    height: 100vh;
    border-right: 1px solid ${({ theme }) => theme.color.gray[300]};

    .link-page-header-container {
      .link-page-header {
        padding: 35px 35px 10px 35px;

        .link-page-details {
          .link-page-stats {
            display: flex;
          }
        }
      }

      .link-page-tabs {
        width: unset;

        .tabs {
          overflow: unset;

          .tab-list {
            padding: 0 35px;
          }
        }
      }
    }

    .link-page-actions {
      padding: 0 35px;
    }
  }
`

export default StyledLinkPageEditor
