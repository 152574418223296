import styled from 'styled-components'

const StyledDesignerEditImagePanel = styled.div`
  padding: 20px;
  display: grid;
  gap: 20px;

  .range-label-container {
    margin-bottom: 12px;
  }
`

export default StyledDesignerEditImagePanel
