import { gql } from '@apollo/client'

export const createWorkspace = gql`
  mutation CreateWorkspace($name: String!) {
    createWorkspace(name: $name) {
      _id
      name
      avatar
    }
  }
`
