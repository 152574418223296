import styled from 'styled-components'

const StyledPostGridList = styled.div`
  .post-list {
    display: grid;
    gap: 20px;
    align-items: start;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  }

  .empty-state {
    gap: 0;
  }

  .infinite-scroll {
    overflow: visible !important;
  }
`

export default StyledPostGridList
