import styled from 'styled-components'
import { PageType } from '../../../types'

type StyledProps = {
  styles?: PageType['styles']
}

const StyledPagePreview = styled.div<StyledProps>`
  background: ${({ styles }) => styles?.page?.backgroundColor || '#f5f5f5'};
  min-height: 100%;
  background-size: cover;
  background-position: center;
  position: relative;

  * {
    font-family: ${({ styles, theme }) => styles?.page?.fontFamily || theme.typeface.sans};
  }

  .page-content {
    max-width: 500px;
    margin: 0 auto;
    padding: 40px 20px 100px 20px;
    box-sizing: border-box;

    .page-header {
      .page-photo {
        transition: 0.2s;
        background-color: rgb(0, 0, 0, 0.05);
        width: 100px;
        height: 100px;
        border-radius: ${({ styles }) =>
          [null, undefined].includes(styles?.avatar?.borderRadius as any)
            ? 100
            : styles?.avatar?.borderRadius}px;
        margin: 0 auto;
        position: relative;
        box-shadow: 0 0 0 ${({ styles }) => styles?.avatar?.borderWidth || 0}px
          ${({ styles }) => styles?.avatar?.borderColor || '#000'};

        img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          border-radius: ${({ styles }) =>
            [null, undefined].includes(styles?.avatar?.borderRadius as any)
              ? 100
              : styles?.avatar?.borderRadius}px;
          object-fit: cover;
        }

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          border-radius: ${({ styles }) =>
            [null, undefined].includes(styles?.avatar?.borderRadius as any)
              ? 100
              : styles?.avatar?.borderRadius}px;
          box-shadow: inset 0 0 0 1px rgb(0, 0, 0, 0.05);
        }
      }

      h1 {
        margin-top: 20px;
        text-align: center;
        font-weight: 600;
        font-size: ${({ styles }) => styles?.title?.fontSize || 26}px;
        color: ${({ styles }) => styles?.description?.color || 'rgb(0, 0, 0, 0.8)'};
      }

      h2 {
        text-align: center;
        font-weight: 400;
        margin-top: 12px;
        // line-height: 26px;
        font-size: ${({ styles }) => styles?.description?.fontSize || 18}px;
        color: ${({ styles }) => styles?.description?.color || 'rgb(0, 0, 0, 0.7)'};
      }
    }

    .page-links {
      display: grid;
      grid-gap: 20px;
      margin-top: 40px;

      .page-link-dummy {
        border-radius: 6px;
        height: 65px;
        background-color: rgb(0, 0, 0, 0.05);
        border-radius: ${({ styles }) =>
          styles?.link?.borderRadius === null ? 14 : styles?.link?.borderRadius}px;
      }

      .page-link {
        font-size: ${({ styles }) => styles?.link?.fontSize || 18}px;
        display: flex;
        border-radius: 6px;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        transition: 0.2s;
        border-radius: ${({ styles }) =>
          [null, undefined].includes(styles?.link?.borderRadius as any)
            ? 14
            : styles?.link?.borderRadius}px;

        &.header {
          padding: 6px 0;
          font-weight: 600;
          color: ${({ styles }) => styles?.description?.color || '#000000'};
          // background-color: ${({ styles }) => styles?.link?.backgroundColor || '#000000'}30;
        }

        &.divider {
          height: 1px;
          background-color: ${({ styles }) => styles?.link?.backgroundColor || '#000000'}50;
        }

        &.url {
          background-color: ${({ styles }) =>
            styles?.link?.backgroundColor || 'rgb(255, 255, 255, 0.7)'};
          box-shadow: inset 0 0 0 1px rgb(0, 0, 0, 0.1), 0 1px 3px rgb(0, 0, 0, 0.04);

          a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            padding: 20px 0;
            font-weight: 400;
            transition: 0.2s;
            color: ${({ styles }) => styles?.link?.color || 'rgb(0, 0, 0, 0.85)'};
            text-decoration: none;
          }

          &:hover {
            cursor: pointer;
            filter: brightness(0.7);
          }
        }
      }
    }
  }

  .logo-footer {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    color: rgb(0, 0, 0, 0.4);
    height: 22px;
  }
`

export default StyledPagePreview
