import styled from 'styled-components'

const StyledCloudView = styled.div`
  padding: 20px 0px;

  .cloud-heading {
    margin-bottom: 12px;

    h3 {
      font-size: 18px;
      font-weight: 600;
      font-family: ${({ theme }) => theme.typeface.faktum};
      color: ${({ theme }) => theme.color.gray[800]};
    }
  }
`

export default StyledCloudView
