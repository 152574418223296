import styled from 'styled-components'

const StyledLinkPageProfileTab = styled.div`
  padding: 20px;
  display: grid;
  grid-gap: 30px;
  align-content: start;

  hr {
    margin: 0;
    border: 0;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.color.gray[300]};
  }

  label,
  .form-input-container {
    display: grid;
    grid-gap: 8px;
    cursor: default;

    .input-label {
      font-weight: 500;
      color: ${({ theme }) => theme.color.gray[600]};
    }

    .input-subtext {
      font-size: 14px;
      user-select: none;
      margin-top: -6px;
      color: ${({ theme }) => theme.color.gray[500]};
    }

    .form-input-choose {
      display: flex;

      &.font-choose {
        .form-input-choose-btn {
          font-size: 18px;
        }
      }

      .form-input-choose-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 60px;
        border-radius: 6px;
        background-color: transparent;
        transition: 0.2s;
        font-weight: 500;
        font-size: 15px;
        color: ${({ theme }) => theme.color.gray[600]};
        box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), inset 0 0 0 1px rgb(0, 0, 0, 0.1),
          0 1px 3px rgb(0, 0, 0, 0.04);

        &:not(:last-child) {
          margin-right: 8px;
        }

        &:hover {
          box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.2), inset 0 0 0 1px rgb(0, 0, 0, 0.15),
            0 1px 3px rgb(0, 0, 0, 0.08);
        }

        &[aria-selected='true'] {
          box-shadow: inset 0 0 0 2px ${({ theme }) => theme.color.brand};
          color: ${({ theme }) => theme.color.brand};
        }
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    padding: 35px;
  }
`

export default StyledLinkPageProfileTab
