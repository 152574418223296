export const day11 = {
  _id: 'day11',
  title: 'DAY 11',
  tasks: [
    {
      label: 'Start the unsubscribe process (don’t worry, this won’t unsubscribe you!)',
      steps: [
        // {
        //   label: 'Open the User Menu, by clicking on your profile at the bottom of the sidebar'
        // },
        // {
        //   label: 'Click the “Billing” link'
        // },
        // {
        //   label: 'Click “Unsubscribe”'
        // },
        // {
        //   label: 'Click “Stay on the Pro Plan”'
        // }
      ]
    },
    {
      label: 'Update your full name (then change it back)',
      steps: [
        // {
        //   label: 'Open the User Menu, by clicking on your profile at the bottom of the sidebar'
        // },
        // {
        //   label: 'Click the “Account” link'
        // },
        // {
        //   label: 'Click the “Edit Account” button'
        // },
        // {
        //   label: 'Change your full name'
        // },
        // {
        //   label: 'Click the “Save Changes” button'
        // },
        // {
        //   label: 'Repeat the process to change your name back'
        // }
      ]
    },
    {
      label: 'Download your most recent invoice',
      steps: [
        // {
        //   label: 'Open the User Menu, by clicking on your profile at the bottom of the sidebar'
        // },
        // {
        //   label: 'Click the “Billing” link'
        // },
        // {
        //   label: 'Click on the “Download” button on your most recent invoice'
        // }
      ]
    },
    {
      label: 'Open the Resource Center',
      steps: [
        // {
        //   label: 'Click “Help & Support” in the sidebar'
        // },
        // {
        //   label: 'Click the “Resource Center” button'
        // }
      ]
    },
    {
      label:
        'Send Customer Support the message “I finished my final task!” from within the platform',
      steps: [
        // {
        //   label: 'Click “Help & Support” in the sidebar'
        // },
        // {
        //   label: 'Click the “Chat with us” button'
        // },
        // {
        //   label: 'Click the “Send us a message” button'
        // },
        // {
        //   label: 'Type “I finished my final task!” and click the send button'
        // }
      ]
    }
  ],
  form: {
    url: 'https://notionforms.io/forms/day-11-feedback',
    keys: {
      FULL_NAME: 'c5916e0a-6691-4598-a32a-6ca0b03940d7',
      EMAIL: 'a323b4b3-4e5e-454b-82b1-c102bf8a2334',
      SCREEN_RECORDING: '8a548446-0d69-4c59-b127-d076bb32e843',
      USER_ID: 'd69ce846-046b-421b-97dc-793add1dc29e'
    }
  }
}
