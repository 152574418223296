import styled from 'styled-components'

const StyledPillarDialog = styled.div`
  width: 100vw;
  padding: 10px 28px 0 28px;
  box-sizing: border-box;

  .dialog-header {
    display: flex;
    align-items: center;

    .dialog-title {
      font-weight: 500;
      font-size: 20px;
      color: ${({ theme }) => theme.color.gray[800]};
    }
  }

  form {
    margin-top: 20px;

    .form-inputs {
      display: grid;
      gap: 20px;

      .icon-btn {
        height: 38px;
        border-radius: 4px;
        font-size: 26px;
        color: ${({ theme }) => theme.color.gray[600]};
        font-size: 15px;
        font-weight: 500;
        background-color: ${({ theme }) => theme.color.gray[200]};
        display: flex;
        align-items: center;
        justify-content: center;

        &[data-has-icon='true'] {
          font-size: 26px;
          width: 38px;
        }
      }
    }
  }

  .dialog-actions {
    margin-top: 28px;
    display: flex;

    button {
      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    padding: 28px;
    max-width: 375px;
  }
`

export default StyledPillarDialog
