import { FC, createRef, useContext, useEffect, useState } from 'react'
import { Link, NavLink, matchPath, useHistory, useLocation } from 'react-router-dom'
import { throttle } from 'lodash'
import { BoltIcon } from '@heroicons/react/20/solid'
import {
  ArchiveBoxIcon,
  CalendarIcon,
  ChatBubbleLeftRightIcon,
  ChevronDownIcon,
  ChevronUpDownIcon,
  CloudIcon,
  DocumentIcon,
  LifebuoyIcon,
  PlusIcon,
  QueueListIcon
} from '@heroicons/react/24/outline'
import { ChatIcon, ChatOutlineIcon, InstagramIcon, SparklesIcon } from '../../assets'
import { ReactComponent as AcademyIcon } from '../../assets/academy-logo-icon.svg'
import { ReactComponent as ContentIcon } from '../../assets/content-icon.svg'
import { ReactComponent as GridIcon } from '../../assets/home-icon.svg'
import { ReactComponent as SCLogo } from '../../assets/sc-logo-black.svg'
import {
  pushToDialogStack,
  setShowChat,
  setSidebarCollapsed,
  setSidebarMoreOpen,
  useAppDispatch,
  useAppSelector
} from '../../redux'
import { ROUTES, getWorkspaceById, isMobile, useCreate, userStore } from '../../utils'
import { Avatar, Button, SupportDialog, UserMenuPopover } from '..'
import {
  SidebarConnectionsButton,
  SidebarPlatformToggle,
  SidebarPostButton,
  StyledSidebar
} from '.'

const Sidebar: FC = () => {
  const location = useLocation()
  const history = useHistory()
  const userMenuBtn = createRef<HTMLDivElement>()
  const dispatch = useAppDispatch()
  const { user } = useContext(userStore)
  const { newPost } = useCreate()
  const { splits } = useAppSelector((state) => state.splits)
  const { workspace, sidebarMoreOpen, sidebarCollapsed, showChat } = useAppSelector(
    (state) => state.state
  )
  const [showSupportDialog, setShowSupportDialog] = useState(false)
  const [showUserMenu, setShowUserMenu] = useState(false)
  const [mobile, setMobile] = useState(isMobile())

  useEffect(() => {
    if (location.pathname === ROUTES.create) {
      dispatch(setSidebarCollapsed(true))
      return
    }

    if (location.pathname === ROUTES.posteditor) {
      dispatch(setSidebarCollapsed(true))
      return
    }

    if (location.pathname === ROUTES.calendar) {
      dispatch(setSidebarCollapsed(true))
      return
    }

    if (location.pathname.includes(ROUTES.community)) {
      dispatch(setSidebarCollapsed(true))
      return
    }

    if (location.pathname.includes(ROUTES.academy.main)) {
      dispatch(setSidebarCollapsed(true))
      return
    }

    if (location.pathname.includes(ROUTES.old.courses)) {
      dispatch(setSidebarCollapsed(true))
      return
    }

    if (location.pathname.includes(ROUTES.linkPage.page)) {
      dispatch(setSidebarCollapsed(true))
      return
    }

    if (location.pathname.includes(ROUTES.uploads.all)) {
      dispatch(setSidebarCollapsed(true))
      return
    }

    dispatch(setSidebarCollapsed(false))
  }, [location.pathname])

  useEffect(() => {
    const handleResize = throttle(() => {
      setMobile(isMobile())
    }, 100)

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <>
      <StyledSidebar isCollapsed={sidebarCollapsed}>
        <Link to={ROUTES.home} className="sidebar-header">
          <SCLogo />
        </Link>
        <div className="sidebar-content">
          <div className="sidebar-content-group">
            <Button
              variant="emphasis"
              iconPos="right"
              className="new-post-btn"
              id="create-btn"
              onClick={() => newPost(true)}
            >
              <span>New Post</span>
              <SparklesIcon className="sparkles-icon" />
              <PlusIcon className="plus-icon" />
            </Button>
            <nav>
              <NavLink
                id="home-btn"
                data-tooltip={sidebarCollapsed ? 'Home' : undefined}
                data-tooltip-pos="right-center"
                draggable={false}
                data-active={
                  !!matchPath(location.pathname, {
                    path: [ROUTES.home, ROUTES.postTemplates.all, ROUTES.myDesigns],
                    exact: true
                  })
                }
                to={ROUTES.home}
                exact
              >
                <GridIcon />
                Home
              </NavLink>
              <NavLink
                id="calendar-btn"
                data-tooltip={sidebarCollapsed ? 'Calendar' : undefined}
                data-tooltip-pos="right-center"
                draggable={false}
                to={ROUTES.calendar}
                exact
              >
                <CalendarIcon />
                Calendar
              </NavLink>
              <NavLink
                id="upload-btn"
                data-tooltip={sidebarCollapsed ? 'Uploads' : undefined}
                data-tooltip-pos="right-center"
                draggable={false}
                to={ROUTES.uploads.all}
              >
                <CloudIcon />
                Uploads
              </NavLink>
              {splits.metaReview === 'on' && (
                <NavLink
                  id="assets-btn"
                  data-tooltip={sidebarCollapsed ? 'Assets' : undefined}
                  data-tooltip-pos="right-center"
                  draggable={false}
                  to={ROUTES.assets.all}
                >
                  <InstagramIcon style={{ height: '22px' }} />
                  Instagram Accounts
                </NavLink>
              )}
              <NavLink
                id="content-btn"
                data-tooltip={sidebarCollapsed ? 'Content Library' : undefined}
                data-tooltip-pos="right-center"
                draggable={false}
                to={ROUTES.contentLibrary.main}
              >
                <ContentIcon />
                Content Library
              </NavLink>
              <NavLink
                id="community-btn"
                data-tooltip={sidebarCollapsed ? 'Community' : undefined}
                data-tooltip-pos="right-center"
                draggable={false}
                to={ROUTES.community}
              >
                <ChatBubbleLeftRightIcon />
                Community
              </NavLink>
              {!!sidebarMoreOpen && (
                <>
                  <NavLink
                    id="link-page-btn"
                    data-tooltip={sidebarCollapsed ? 'Link Page' : undefined}
                    data-tooltip-pos="right-center"
                    draggable={false}
                    to={ROUTES.linkPage.page}
                  >
                    <QueueListIcon />
                    Link Page
                  </NavLink>
                  <NavLink
                    id="action-plans-btn"
                    data-tooltip={sidebarCollapsed ? 'Action Plans' : undefined}
                    data-tooltip-pos="right-center"
                    draggable={false}
                    to={ROUTES.actionPlans}
                  >
                    <DocumentIcon />
                    Action Plans
                  </NavLink>
                  <NavLink
                    id="academy-btn"
                    data-tooltip={sidebarCollapsed ? 'Academy' : undefined}
                    data-tooltip-pos="right-center"
                    draggable={false}
                    to={ROUTES.academy.main}
                  >
                    <AcademyIcon />
                    Academy
                  </NavLink>
                  <a
                    id="help-support-btn"
                    data-tooltip={sidebarCollapsed ? 'Help & Support' : undefined}
                    data-tooltip-pos="right-center"
                    draggable={false}
                    onClick={() => setShowSupportDialog(true)}
                  >
                    <LifebuoyIcon />
                    Help & Support
                  </a>
                </>
              )}
              <button
                className="sidebar-more-btn"
                data-expanded={!!sidebarMoreOpen}
                onClick={() => dispatch(setSidebarMoreOpen(!sidebarMoreOpen))}
              >
                <div className="more-icon">
                  <ChevronDownIcon />
                </div>
                {!!sidebarMoreOpen ? 'Less' : 'More'}
              </button>
            </nav>
          </div>
          <div className="sidebar-content-group">
            {sidebarCollapsed && !!user?.trial && (
              <Button
                data-tooltip="Go Pro"
                data-tooltip-pos="right-center"
                variant="emphasis-tone"
                isIcon
                onClick={() => dispatch(pushToDialogStack('go-pro'))}
              >
                <BoltIcon />
              </Button>
            )}
            <nav>
              {!location.pathname.includes(ROUTES.create) && (
                <a
                  id="dottie-btn"
                  data-tooltip={sidebarCollapsed ? 'Chat With Dottie' : undefined}
                  data-tooltip-pos="right-center"
                  draggable={false}
                  onClick={() => dispatch(setShowChat(!showChat))}
                >
                  <ChatOutlineIcon />
                  {showChat ? 'Close' : 'Open'} Dottie
                </a>
              )}
            </nav>
            <SidebarPostButton />
            {!sidebarCollapsed && <SidebarPlatformToggle />}
            {!sidebarCollapsed && <SidebarConnectionsButton />}
          </div>
        </div>
        <div className="sidebar-footer" onClick={() => setShowUserMenu(!showUserMenu)}>
          <div className="sidebar-footer-latch" ref={userMenuBtn}>
            <div className="sidebar-user-details-container">
              <Avatar size={35} name={user?.displayName || ''} />
              <div className="sidebar-user-details">
                <span className="sidebar-user-name">{user?.displayName}</span>
                <span className="sidebar-user-detail">
                  {getWorkspaceById(workspace, user?.workspaces)?.name || 'Personal Workspace'}
                </span>
              </div>
            </div>
            <ChevronUpDownIcon />
          </div>
        </div>
      </StyledSidebar>
      <UserMenuPopover
        classToAvoid="sidebar-footer"
        isOpen={showUserMenu}
        onClose={() => setShowUserMenu(false)}
        position="top-left"
        buttonRef={userMenuBtn}
      />
      <SupportDialog isOpen={showSupportDialog} onClose={() => setShowSupportDialog(false)} />
    </>
  )
}

export default Sidebar
