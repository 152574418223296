import { gql } from '@apollo/client'

export const userHashtagFolderUpsertOne = gql`
  mutation UserHashtagFolderUpsertOne($_id: String, $label: String!) {
    userHashtagFolderUpsertOne(_id: $_id, label: $label) {
      _id
      label
    }
  }
`
