import styled from 'styled-components'

const StyledPostTemplateList = styled.div`
  display: grid;
  gap: 20px;

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    gap: 40px;
    padding: 20px 0;
  }
`

export default StyledPostTemplateList
