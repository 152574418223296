import { FC, useContext } from 'react'
import { useIntercom, userStore } from '../../../utils'
import { StyledInactiveView } from '.'

const InactiveView: FC = () => {
  const { showIntercomMessenger } = useIntercom()
  const { logout } = useContext(userStore)

  return (
    <StyledInactiveView>
      <div className="icon">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
          <path
            fill="currentColor"
            d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24ZM92 96a12 12 0 1 1-12 12a12 12 0 0 1 12-12Zm80 86.92a8 8 0 0 1-10.92-2.92c-7.47-12.91-19.21-20-33.08-20s-25.61 7.1-33.08 20a8 8 0 1 1-13.84-8c10.29-17.79 27.39-28 46.92-28s36.63 10.2 46.92 28a8 8 0 0 1-2.92 10.92ZM164 120a12 12 0 1 1 12-12a12 12 0 0 1-12 12Z"
          />
        </svg>
      </div>
      <h1>Your plan has expired</h1>
      <h2>Upgrade your plan on the web</h2>
      <button onClick={() => logout()}>Log out</button>
      <a onClick={() => showIntercomMessenger()}>Contact support</a>
    </StyledInactiveView>
  )
}

export default InactiveView
