import styled, { css, keyframes } from 'styled-components'
import { theme } from '../../../../utils/theme'

const likeAnim = keyframes`
  0% {
    transform: scale(0.3);
    fill: ${theme.color.red[600]};
    color: ${theme.color.red[600]};
  }
  60% {
    transform: scale(1.2);
    fill: ${theme.color.red[600]};
    color: ${theme.color.red[600]};
  }
  80% {
    transform: scale(0.9);
    fill: ${theme.color.red[600]};
    color: ${theme.color.red[600]};
  }
  100% {
    transform: scale(1);
    fill: ${theme.color.red[600]};
    color: ${theme.color.red[600]};
  }
`

type StyledProps = {
  isUsed?: boolean
  isLiked?: boolean
}

const StyledCaptionListItem = styled.div<StyledProps>`
  padding: 14px;
  box-sizing: border-box;
  position: relative;
  user-select: none;
  background-color: ${({ theme }) => theme.color.background};

  span {
    display: block;
  }

  .caption-header {
    margin-bottom: 14px;
    max-width: calc(100% - 35px);

    .caption-title {
      font-weight: 500;
      color: ${({ theme }) => theme.color.gray[800]};
    }

    .caption-category {
      margin-top: 2px;
      font-size: 15px;
      color: ${({ theme }) => theme.color.gray[500]};
    }
  }

  .caption-text {
    font-size: 15px;
    color: ${({ theme }) => theme.color.gray[700]};
    line-height: 26px;
    line-break: loose;
    user-select: unset;
  }

  .caption-actions {
    margin-top: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .caption-action-group {
      display: flex;
      align-items: center;

      .fill-btn {
        padding: 0 10px;
        background-color: ${({ theme }) => theme.color.background};
        color: ${({ theme }) => theme.color.gray[800]};
        border: 1px solid ${({ theme }) => theme.color.gray[300]};

        svg {
          height: 26px;
          margin-right: 6px;
        }
      }

      .use-btn {
        padding: 0 10px;

        svg {
          height: 26px;
          margin-right: 6px;
          stroke-width: 1.75px;
        }
      }

      button {
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        font-weight: 600;
        background-color: ${({ theme }) => theme.color.gray[100]};
        color: ${({ theme }) => theme.color.gray[600]};

        &:not(:last-child) {
          margin-right: 8px;
        }
      }
    }

    .fav-btn {
      ${({ isLiked, theme }) =>
        isLiked &&
        `
        background-color: ${theme.color.red[100]};
      `}

      svg {
        ${({ isLiked }) =>
          isLiked &&
          css`
            animation: ${likeAnim} forwards 0.2s ease-in-out;
          `}
      }
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.gray[200]};
  }

  ${({ isUsed, theme }) =>
    isUsed &&
    `
    border-color: ${theme.color.gray[200]}a4;
    background-color: ${theme.color.gray[100]}80;

    .caption-header .caption-title, .caption-text, .caption-actions {
      opacity: 0.7;
    }
  `}
`

export default StyledCaptionListItem
