import { FC, RefObject } from 'react'
import { useTextSelection } from 'use-text-selection'
import { PlusIcon } from '@heroicons/react/24/outline'
import { addCreatePostBit, useAppDispatch, useAppSelector } from '../../../redux'
import { useSegment } from '../../../utils'
import { StyledChatTooltip } from '.'

type Props = {
  messageRef: RefObject<HTMLDivElement>
}

const ChatTooltip: FC<Props> = ({ messageRef }) => {
  const dispatch = useAppDispatch()
  const { track } = useSegment()
  const { post } = useAppSelector((state) => state.create)
  const { clientRect, isCollapsed, textContent } = useTextSelection(
    messageRef?.current || undefined
  )

  const getStyles = () => {
    const messageElement = messageRef.current

    if (!messageElement || !clientRect) {
      return {}
    }

    const fixedLeft = clientRect.left + clientRect.width / 2
    const fixedTop = clientRect.top - 42

    const relativeLeft = fixedLeft - messageElement.getBoundingClientRect().left
    const relativeTop = fixedTop - messageElement.getBoundingClientRect().top

    return {
      left: `${relativeLeft}px`,
      top: `${relativeTop}px`
    }
  }

  const addToPost = () => {
    dispatch(addCreatePostBit(textContent || ''))
    track('Added to Post From Message Tooltip', { textContent, is_chat: true, post_id: post?._id })
  }

  if (isCollapsed || !clientRect || (textContent || '')?.trim().length < 3) {
    return null
  }

  return (
    <StyledChatTooltip style={getStyles()} onClick={() => addToPost()}>
      <PlusIcon />
      <span>Add to post</span>
      <div className="border-gradient" />
    </StyledChatTooltip>
  )
}

export default ChatTooltip
