import styled from 'styled-components'

type StyledProps = {
  size: number
}

const StyledChatBotIcon = styled.div<StyledProps>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.brandLight};
  color: ${({ theme }) => theme.color.brand};

  svg {
    height: ${({ size }) => size * 0.65}px;
  }
`

export default StyledChatBotIcon
