import styled from 'styled-components'

const StyledChatHeader = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.color.background};
  height: 53px;
  padding: 0 6px;
  user-select: none;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};

  button {
    background-color: transparent;
    border-radius: 6px;

    svg {
      height: 28px;
      width: 28px;

      path {
        stroke-width: 1.55px;
      }
    }

    &:hover {
      background-color: ${({ theme }) => theme.color.gray[100]};
    }

    &:not(:last-child) {
      margin-right: 6px;
    }

    &.help-me-btn {
      background-color: ${({ theme }) => theme.color.gray[100]};

      &:hover {
        background-color: ${({ theme }) => theme.color.gray[200]};
      }
    }
  }

  .chat-header-group {
    display: flex;
    align-items: center;
  }

  .chat-bot-details {
    .chat-bot-avatar {
      height: 32px;
      width: 32px;
      pointer-events: none;
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      position: relative;
      margin-right: 6px;
      flex-shrink: 0;
      background-color: ${({ theme }) => theme.color.brandLight};

      svg {
        height: 22px;
      }
    }

    .chat-bot-name {
      font-weight: 600;
      font-size: 14px;
      color: ${({ theme }) => theme.color.gray[800]};
      font-family: ${({ theme }) => theme.typeface.garnett};
    }

    .chat-bot-status {
      font-size: 13px;
      margin-top: -2px;
      cursor: default;
      user-select: none;
      color: ${({ theme }) => theme.color.green[600]};
      font-weight: 500;
      display: flex;
      align-items: center;

      div {
        height: 8px;
        width: 8px;
        margin-right: 4px;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.color.green[600]};
      }
    }
  }
`

export default StyledChatHeader
