import { FC, useCallback, useEffect, useState } from 'react'
import { useActiveObject, useEditor } from '@layerhub-io/react'
import { ReactComponent as BoldIcon } from '../../../assets/bold-icon.svg'
import { getTextProperties, loadFonts } from '../../../utils'
import { DesignerToolButton } from '.'

const DesignerBoldTool: FC = () => {
  const editor = useEditor()
  const activeObject = useActiveObject() as any
  const [bold, setBold] = useState(false)
  const [hasBold, setHasBold] = useState(true)

  const toggleBold = useCallback(async () => {
    const { fontOptions, isItalic } = getTextProperties(activeObject)
    let fontToChangeTo: any = {}

    if (bold && isItalic) {
      const font = fontOptions.find((option) => {
        const postScriptNames = option.post_script_name.split('-')
        return postScriptNames[postScriptNames.length - 1].match(/^Italic$/)
      })

      fontToChangeTo = {
        name: font?.post_script_name,
        url: font?.url
      }
    }

    if (bold && !isItalic) {
      const font = fontOptions.find((option) => {
        const postScriptNames = option.post_script_name.split('-')
        return postScriptNames[postScriptNames.length - 1].match(/^Regular$/)
      })

      fontToChangeTo = {
        name: font?.post_script_name,
        url: font?.url
      }
    }

    if (!bold && isItalic) {
      const font = fontOptions.find((option) => {
        const postScriptNames = option.post_script_name.split('-')
        return postScriptNames[postScriptNames.length - 1].match(/^BoldItalic$/)
      })

      fontToChangeTo = {
        name: font?.post_script_name,
        url: font?.url
      }
    }

    if (!bold && !isItalic) {
      const font = fontOptions.find((option) => {
        const postScriptNames = option.post_script_name.split('-')
        return postScriptNames[postScriptNames.length - 1].match(/^Bold$/)
      })

      fontToChangeTo = {
        name: font?.post_script_name,
        url: font?.url
      }
    }

    await loadFonts([fontToChangeTo])

    editor.objects.update({
      fontFamily: fontToChangeTo.name,
      fontURL: fontToChangeTo.url
    })

    setBold(!bold)
  }, [editor, activeObject, bold])

  const handleBold = () => {
    if (!activeObject || activeObject.type !== 'StaticText') {
      return
    }

    const { isBold, hasBold } = getTextProperties(activeObject)

    setBold(isBold)
    setHasBold(hasBold)
  }

  useEffect(() => {
    handleBold()

    if (editor) {
      editor.on('history:changed', handleBold)
    }
    return () => {
      if (editor) {
        editor.off('history:changed', handleBold)
      }
    }
  }, [editor, activeObject])

  if (!hasBold) {
    return null
  }

  return (
    <DesignerToolButton data-tooltip={'Bold'} isIcon isActive={bold} onClick={() => toggleBold()}>
      <BoldIcon />
    </DesignerToolButton>
  )
}

export default DesignerBoldTool
