import { gql } from '@apollo/client'

export const searchUserDraftsQuery = gql`
  query SearchUserDrafts(
    $search: String!
    $categories: [String!]!
    $liked: Boolean
    $hideUsed: Boolean
    $onlyScheduled: Boolean
    $profiles: [String]
    $status: String
    $sort: String!
    $page: Int
    $items: Int!
  ) {
    searchUserDrafts(
      search: $search
      categories: $categories
      liked: $liked
      hideUsed: $hideUsed
      onlyScheduled: $onlyScheduled
      profiles: $profiles
      status: $status
      sort: $sort
      page: $page
      items: $items
    ) {
      totalDocs
      page
      hasNextPage
      hasPrevPage
      nextPage
      prevPage
      tags
      docs {
        _id
        title
        captionText
        category
        imageUrl
        userUpload
        socialProfiles
        imageId
        fullPath
        template
        challenge
        user
        postStatus
        coverPhotoOffset
        coverPhotoUrl
        instagramPublishType
        instagramReelAudioName
        tikTokPrivacyStatus
        tikTokCommentDisabled
        tikTokDuetDisabled
        tikTokStitchDisabled
        media {
          _id
          url
          preview
          type
          uploaded
          design
          ref
          dimensions {
            x
            y
          }
        }
        imageDimensions {
          x
          y
        }
        postDate
        tags
        isLiked
        isUsed
        index
        lastModified
        createdAt
      }
    }
  }
`
