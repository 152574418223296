import { RefObject } from 'react'
import { PositionType } from '../../types'

export const getPosition = (
  pos: PositionType,
  targetRef?: RefObject<any> | HTMLElement,
  elementRef?: RefObject<any> | HTMLElement,
  offset: number = 8
) => {
  const target = (targetRef as any)?.current || targetRef
  const element = (targetRef as any)?.current || elementRef

  let top = 0
  let left = 0

  if (!target || !element) {
    return undefined
  }

  // ELEMENT STATS
  const elementRect = element.getBoundingClientRect()
  const elementHeight = elementRect.height
  const elementWidth = (element as HTMLDivElement).clientWidth

  // TARGET STATS
  const targetRect = target.getBoundingClientRect()
  const targetHeight = targetRect.height
  const targetWidth = targetRect.width
  const targetTop = targetRect.top
  const targetLeft = targetRect.left

  if (pos === 'top-start') {
    top = targetTop - elementHeight - offset
    left = targetLeft
  }

  if (pos === 'top-center') {
    top = targetTop - elementHeight - offset
    left = targetLeft + targetWidth / 2 - elementWidth / 2
  }

  if (pos === 'top-end') {
    top = targetTop - elementHeight - offset
    left = targetLeft + targetWidth - elementWidth
  }

  if (pos === 'right-center') {
    top = targetTop + targetHeight / 2 - elementHeight / 2
    left = targetLeft + targetWidth + offset
  }

  if (pos === 'bottom-start') {
    top = targetTop + targetHeight + offset
    left = targetLeft
  }

  if (pos === 'bottom-center') {
    top = targetTop + targetHeight + offset
    left = targetLeft + targetWidth / 2 - elementWidth / 2
  }

  if (pos === 'bottom-end') {
    top = targetTop + targetHeight + offset
    left = targetLeft + targetWidth - elementWidth
  }

  if (pos === 'left-center') {
    top = targetTop + targetHeight / 2 - elementHeight / 2
    left = targetLeft - elementWidth - offset
  }

  return {
    top,
    left
  }
}
