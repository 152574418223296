import { FC, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { saveAs } from 'file-saver'
import {
  ArrowDownTrayIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  HeartIcon,
  PlusIcon
} from '@heroicons/react/24/outline'
import canvaLogo from '../../../assets/canva-icon.png'
import { linkToSizedImage, useSegment } from '../../../utils'
import { Button, Spinner } from '..'
import { StyledPhotoGallery } from '.'

type PhotoGalleryPhotoType = {
  _id: string
  url: string
  canvaLink?: string
  onUse?: () => void
  onFavorite?: () => void
}

type Props = {
  photos: PhotoGalleryPhotoType[]
}

const PhotoGallery: FC<Props> = ({ photos }) => {
  const [currIndex, setCurrIndex] = useState(0)
  const [loading, setLoading] = useState(true)
  const { track } = useSegment()

  const nextPhoto = () => {
    if (currIndex === photos.length - 1) {
      return
    }

    setCurrIndex(currIndex + 1)
  }

  const prevPhoto = () => {
    if (currIndex === 0) {
      return
    }

    setCurrIndex(currIndex - 1)
  }

  const handleDownload = () => {
    saveAs(photos[currIndex].url || '', `${photos[currIndex]._id}.jpg`)
    toast.success('Downloaded photo')
    track('Downloaded Photo', { ...photos[currIndex] })
  }

  useEffect(() => {
    setLoading(true)
  }, [currIndex, JSON.stringify(photos)])

  return (
    <StyledPhotoGallery loading={loading}>
      <div className="photo-gallery-preview">
        {loading && <Spinner isLarge />}
        <img
          src={linkToSizedImage(photos[currIndex]?.url || '', { width: 500 })}
          alt=""
          onLoad={() => setLoading(false)}
        />
        {photos.length > 1 && (
          <div className="photo-gallery-preview-pagination">
            <Button isIcon onClick={() => prevPhoto()}>
              <ChevronLeftIcon />
            </Button>
            <span className="photo-gallery-preview-pagination-value">
              {currIndex + 1}/{photos.length}
            </span>
            <Button isIcon onClick={() => nextPhoto()}>
              <ChevronRightIcon />
            </Button>
          </div>
        )}
      </div>
      <div className="photo-gallery-action-bar">
        {!!photos[currIndex]?.onUse && (
          <Button
            iconPos="left"
            size="sm"
            variant="outline"
            onClick={() => photos[currIndex]!.onUse!()}
          >
            <PlusIcon /> USE
          </Button>
        )}
        {!photos[currIndex]?.onUse && <div />}
        <div className="photo-gallery-action-bar-group">
          {photos[currIndex]?.canvaLink && (
            <a
              href={photos[currIndex].canvaLink}
              target="_blank"
              rel="noopener noreferrer"
              className="canva-link"
            >
              <Button iconPos="left" size="sm">
                <img src={canvaLogo} alt="Canva Logo" /> Edit in Canva
              </Button>
            </a>
          )}
          {photos[currIndex]?.onFavorite && (
            <Button size="sm" isIcon>
              <HeartIcon />
            </Button>
          )}
          <Button size="sm" isIcon onClick={() => handleDownload()}>
            <ArrowDownTrayIcon />
          </Button>
        </div>
      </div>
    </StyledPhotoGallery>
  )
}

export default PhotoGallery
