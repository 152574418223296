import styled, { keyframes } from 'styled-components'
import { theme } from '../../../utils'

const hightlightAnim = keyframes`
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

type StyledProps = {
  type: 'sent' | 'received'
  isSub?: boolean
}

const StyledChatConversationListItem = styled.div<StyledProps>`
  padding: 7px 14px;
  max-width: 95%;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  ${({ type }) => type === 'sent' && 'justify-self: end;'};

  caption {
    display: unset;
    text-align: unset;
  }

  .conversation-item-used-template {
    display: flex;
    align-items: center;
    width: fit-content;
    user-select: none;
    color: ${({ theme }) => theme.color.brand};
    border-radius: 6px;
    padding: 4px;
    margin: -4px -4px 4px auto;
    transition: 0.2s;
    font-weight: 500;
    justify-content: end;
    font-size: 14px;

    svg {
      height: 17px;
      position: relative;
      stroke-width: 2.4px;
      top: -1px;
      margin-right: 4px;
    }

    &.reply-link {
      &:hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.color.brandLight};
      }
    }
  }

  .conversation-item-container {
    display: flex;
    align-items: center;
    ${({ type }) => type === 'sent' && 'justify-content: end;'};

    .chat-bot-avatar {
      height: 32;
      width: 32;
      pointer-events: none;
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      position: relative;
      margin-right: 12px;
      flex-shrink: 0;
      align-self: start;
      background-color: ${({ theme }) => theme.color.background};
    }

    .conversation-item-bubble {
      padding: 12px;
      font-size: 15px;
      position: relative;
      white-space: pre-wrap;
      box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
        0px 2px 4px rgba(0, 0, 0, 0.11);
      border-radius: ${({ type }) =>
        type === 'received' ? '4px 12px 12px 12px' : '12px 12px 4px 12px'};
      background-color: ${({ theme, type }) =>
        type === 'received' ? theme.color.background : theme.color.brand};
      color: ${({ theme, type }) => (type === 'received' ? theme.color.gray[700] : 'white')};

      a {
        color: ${({ theme }) => theme.color.gray[800]};

        &:hover {
          color: ${({ theme }) => theme.color.gray[600]};
        }
      }

      ul,
      ol {
        margin: 0;
        display: grid;
        grid-gap: 14px;
        padding-inline-start: 20px;

        li {
          white-space: normal;
        }
      }

      .image-result {
        margin-top: 10px;
        display: flex;
        position: relative;
        width: 240px;
        border-radius: 6px;
        overflow: hidden;
        background-color: ${({ theme }) => theme.color.gray[100]};

        img,
        video {
          width: 100%;
        }

        .use-btn {
          position: absolute;
          bottom: 12px;
          right: 12px;
          display: flex;
          align-items: center;
          font-weight: 500;
          font-size: 15px;
          padding: 8px;
          border-radius: 6px;
          transition: 0.2s;
          background-color: rgb(0, 0, 0, 0.8);
          color: rgb(255, 255, 255, 0.95);

          svg {
            height: 20px;
            margin-right: 4px;
            stroke-width: 2px;
          }

          &:hover {
            background-color: rgb(0, 0, 0, 0.9);
          }
        }

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          pointer-events: none;
          user-select: none;
          border-radius: 6px;
          box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.foreground}20;
        }
      }
    }
  }

  .conversation-item-actions {
    margin-top: 12px;
    margin-bottom: -6px;
    font-size: 11px;
    padding-left: 53px;
    display: flex;

    button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      border-radius: 6px;
      font-size: 13px;
      padding: 0 8px;
      height: 30px;
      background-color: ${({ theme }) => theme.color.gray[100]};
      color: ${({ theme }) => theme.color.gray[500]};
      margin-right: 6px;
      margin-bottom: 6px;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

      svg {
        height: 14px;
        flex-shrink: 0;
        margin-right: 4px;
        stroke-width: 2.25px;
      }

      &.icon-btn {
        width: 30px;
        padding: 0;

        svg {
          margin: 0;
        }
      }

      &.use-btn {
        background-color: transparent;
        box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.gray[200]};
        color: ${({ theme }) => theme.color.gray[600]};
      }

      &:hover,
      &[data-active='true'] {
        background-color: ${({ theme }) => theme.color.background};
        color: ${({ theme }) => theme.color.gray[800]};
        box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
          0px 2px 3px rgba(0, 0, 0, 0.05);
      }

      &.open-content-btn {
        svg {
          margin-right: 0;
          margin-left: 4px;
        }
      }

      &.dark {
        background-color: ${({ theme }) => theme.color.gray[800]};
        color: ${({ theme }) => theme.color.background};

        &:hover,
        &[data-active='true'] {
          background-color: ${({ theme }) => theme.color.gray[700]};
          color: ${({ theme }) => theme.color.background};
          box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
            0px 2px 3px rgba(0, 0, 0, 0.05);
        }
      }
    }
  }

  .conversation-item-extra {
    margin-top: 8px;
    font-size: 11px;
    padding-left: 53px;
    font-weight: 500;
    user-select: none;
    color: ${({ theme }) => theme.color.gray[500]};
    ${({ type }) => type === 'sent' && 'text-align: right;'};
  }

  &.highlight {
    .conversation-item-container {
      .conversation-item-bubble {
        &::after {
          opacity: 0;
          content: '';
          pointer-events: none;
          border: 2px solid transparent;
          animation: 3s ${hightlightAnim} forwards;
          background: linear-gradient(
              25deg,
              ${({ theme }) => theme.color.brand},
              ${({ theme }) => theme.color.blue[500]}
            )
            border-box;
          -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
          -webkit-mask-composite: xor;
          mask-composite: exclude;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: calc(100% - 4px);
          width: calc(100% - 4px);
          border-radius: 4px 12px 12px 12px;
        }
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    .conversation-item-container {
      .conversation-item-bubble {
        .image-result {
          width: 289px;
        }
      }
    }
  }

  ${({ isSub }) =>
    isSub &&
    `
    margin-top: -26px;

    .conversation-item-container {
      .chat-bot-avatar {
        opacity: 0;
      }
    }
  `}
`

export default StyledChatConversationListItem
