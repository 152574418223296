import styled from 'styled-components'

type StyledProps = {
  open?: boolean
}

const StyledPersonaEdit = styled.div<StyledProps>`
  border-radius: 10px;
  background-color: ${({ theme }) => theme.color.background};
  border: 1px solid ${({ theme }) => theme.color.gray[300]};

  .persona-heading {
    width: 100%;
    box-sizing: border-box;
    user-select: none;
    padding: 0 18px 0 12px;
    height: 60px;
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.color.background};
    justify-content: space-between;
    border-radius: 10px;
    transition: background-color 0.2s;

    .persona-heading-details {
      align-items: center;
      justify-content: space-between;

      input {
        height: 40px;
        padding: 0 8px;
        box-sizing: border-box;
        border-radius: 6px;
        background-color: transparent;
        font-weight: 500;
        border: 1px solid transparent;
        color: ${({ theme }) => theme.color.gray[800]};

        &:disabled {
          pointer-events: none;
        }

        &:hover,
        &:focus {
          border-color: ${({ theme }) => theme.color.gray[300]};
        }
      }

      .default-label {
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.1rem;
        margin-left: 10px;
        color: ${({ theme }) => theme.color.gray[600]};
        padding: 6px 10px;
        background-color: ${({ theme }) => theme.color.gray[900]}10;
        border-radius: 50rem;
      }
    }

    .persona-heading-actions {
      display: flex;
      align-items: center;

      .btn {
        margin-right: 10px;
      }
    }

    .chevron-icon {
      height: 24px;
      color: ${({ theme }) => theme.color.gray[500]};
      stroke-width: 1.75px;
      transform: rotate(${({ open }) => (open ? '180' : '0')}deg);
    }

    &:hover {
      cursor: pointer;
      background-color: ${({ theme }) => theme.color.gray[100]};
    }
  }

  .persona-inputs {
    padding: 18px;
    display: ${({ open }) => (open ? 'grid' : 'none')};
    gap: 24px;
  }

  &[open] {
    .persona-heading {
      border-radius: 10px 10px 0 0;
      border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
    }
  }
`

export default StyledPersonaEdit
