import { FC } from 'react'
import Sheet from 'react-modal-sheet'
import { Media } from '@capacitor-community/media'
import { AppLauncher } from '@capacitor/app-launcher'
import { Directory, Filesystem } from '@capacitor/filesystem'
import { ArrowDownTrayIcon, ArrowUpOnSquareIcon } from '@heroicons/react/24/outline'
import { FacebookIcon, InstagramIcon, LinkedinIcon, TiktokIcon } from '../../../../assets'
import { useAppSelector } from '../../../../redux'
import { PostType } from '../../../../types'
import { useCreate, useShare } from '../../../../utils'
import { Album, TikTokShare } from '../../../plugins'
import { StyledShareSheet } from '.'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const ShareSheet: FC<Props> = ({ isOpen, onClose }) => {
  const { post } = useAppSelector((state) => state.create)
  const { sharePost } = useCreate()
  const { share } = useShare()

  const savePostMedia = async () => {
    if (!post?.media?.length) {
      return
    }

    for (const item of post.media) {
      if (item.type === 'video') {
        await Media.saveVideo({
          path: item.url
        })
      } else {
        await Media.savePhoto({
          path: item.url
        })
      }
    }
  }

  const savePostMediaToPhotoLibrary = async (media: NonNullable<PostType['media']>) => {
    const fids: string[] = []

    for (const item of media) {
      const { localId } = await Album.save({
        type: 'image',
        path: item.url
      })

      fids.push(localId)
    }

    return fids

    // const fids: string[] = []

    // for (const item of media) {
    //   if (item.type === 'video') {
    //     const data = await fetch(item.url)
    //     const blob = await data.blob()

    //     const video: string = await new Promise((resolve) => {
    //       const reader = new FileReader()
    //       reader.readAsDataURL(blob)
    //       reader.onloadend = () => {
    //         const base64data: any = reader.result
    //         resolve(base64data)
    //       }
    //     })

    //     const { file } = await Album.save({
    //       type: 'video',
    //       path: video
    //     })

    //     fids.push(filePath)
    //   } else {
    //     const { filePath } = await Media.savePhoto({
    //       path: item.url
    //     })

    //     fids.push(filePath)
    //   }
    // }

    // return fids
  }

  const savePostMediaToFileSystem = async (media: NonNullable<PostType['media']>) => {
    const fids: string[] = []

    for (const item of media) {
      const data = await fetch(item.url)
      const blob = await data.blob()
      const ext = item.type === 'video' ? 'mp4' : 'png'

      const image: string = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
          const base64data: any = reader.result
          resolve(base64data)
        }
      })

      const { uri } = await Filesystem.writeFile({
        path: `${post?._id}-${item._id}.${ext}`,
        data: image.replace(/^data:image\/[a-z]+;base64,/, ''),
        directory: Directory.Cache
      })

      fids.push(uri)
    }

    return fids
  }

  const shareToFacebook = async () => {
    const encodedCaption = encodeURIComponent(post?.captionText || '')

    await savePostMedia()

    await AppLauncher.openUrl({
      url: `fb://composer?view=photo&text=${encodedCaption}`
    })
  }

  const shareToLinkedIn = async () => {
    if (!post?.media?.length) {
      return
    }

    const encodedCaption = encodeURIComponent(post?.captionText || '')
    const firstMedia = encodeURIComponent(post?.media[0].url)

    await AppLauncher.openUrl({
      url: `linkedin://share?text=${encodedCaption}&url=${firstMedia}`
    })
  }

  const shareToTikTok = async () => {
    if (!post?.media?.length) {
      return
    }

    const media = post.media
    const hasVideo = !!media.find((item) => item.type === 'video')

    if (hasVideo) {
      const videos = media.filter((item) => item.type === 'video')
      const fids = await savePostMediaToPhotoLibrary(videos)
      await TikTokShare.share({ type: 'video', fids })
      return
    }

    const photos = media.filter((item) => item.type === 'image')
    const fids = await savePostMediaToPhotoLibrary(photos)
    await TikTokShare.share({ type: 'image', fids })
  }

  if (!post) {
    return null
  }

  return (
    <Sheet detent="content-height" isOpen={isOpen} onClose={onClose}>
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>
          <StyledShareSheet>
            <button className="instagram-feed-btn share-btn" onClick={() => shareToTikTok()}>
              <div className="share-btn-icon">
                <TiktokIcon />
              </div>
              <div className="share-btn-label">TikTok</div>
            </button>
            <button className="instagram-feed-btn share-btn" onClick={() => shareToLinkedIn()}>
              <div className="share-btn-icon">
                <LinkedinIcon />
              </div>
              <div className="share-btn-label">LinkedIn</div>
            </button>
            <button className="instagram-feed-btn share-btn">
              <div className="share-btn-icon">
                <InstagramIcon />
              </div>
              <div className="share-btn-label">Feed</div>
            </button>
            <button className="instagram-feed-btn share-btn">
              <div className="share-btn-icon"></div>
              <div className="share-btn-label">Stories</div>
            </button>
            <button
              className="instagram-feed-btn share-btn"
              onClick={() => share(post, 'facebook')}
            >
              <div className="share-btn-icon">
                <FacebookIcon />
              </div>
              <div className="share-btn-label">Facebook</div>
            </button>
            <button className="instagram-feed-btn share-btn">
              <div className="share-btn-icon">
                <ArrowDownTrayIcon />
              </div>
              <div className="share-btn-label">Download</div>
            </button>
            <button
              className="instagram-feed-btn share-btn"
              onClick={() => {
                sharePost()
                onClose()
              }}
            >
              <div className="share-btn-icon">
                <ArrowUpOnSquareIcon />
              </div>
              <div className="share-btn-label">More</div>
            </button>
          </StyledShareSheet>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={() => onClose()} />
    </Sheet>
  )
}

export default ShareSheet
