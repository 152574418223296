import { FC, useContext, useEffect } from 'react'
import { Route, useHistory, useLocation } from 'react-router-dom'
import firebase from 'firebase/app'
import { DateTime } from 'luxon'
import {
  clearCoupon,
  pushToDialogStack,
  setCoupon,
  setShowConnectionsModal,
  setShowFeedbackModal,
  useAppDispatch,
  useAppSelector
} from '../../redux'
import {
  ROUTES,
  conditionalStage,
  getCoupon,
  useAppInfo,
  useChat,
  useCreate,
  useSplit,
  userStore
} from '../../utils'
import { Designer } from '../canvas'
import {
  ConnectionDialog,
  ContentViewer,
  CreatePopup,
  DialogManager,
  FeedbackDialog,
  UploadManager
} from '..'

const State: FC = () => {
  const updateLoggedInUser = firebase.functions().httpsCallable('updateLoggedInUser')
  const history = useHistory()
  const location = useLocation()
  const { coupon, showConnectionsModal, showFeedbackModal, showChat, contentToView, theme } =
    useAppSelector((state) => state.state)
  const { configureSplit } = useSplit()
  const { newPost } = useCreate()
  const { clearChat } = useChat()
  const { user, refresh } = useContext(userStore)
  const { loadAppInfo } = useAppInfo()
  // const {
  //   actions: { subscribeToNotifications }
  // } = usePushNotifications()
  const dispatch = useAppDispatch()

  const resetInformationalOnboarding = async (e: KeyboardEvent) => {
    if (e.key === 'o' && e.metaKey) {
      e.preventDefault()
      let newName = ''

      if (e.shiftKey) {
        newName = prompt("What's your name?", user?.displayName) || ''
      }

      await updateLoggedInUser({
        aiOnboarding: {},
        ...conditionalStage(!!newName?.trim(), {
          displayName: newName
        })
      })
      await refresh()
      newPost()
      clearChat()
      history.push(ROUTES.onboarding)
    }
  }

  useEffect(() => {
    if (user?.role !== 'admin') {
      return
    }

    window.addEventListener('keydown', resetInformationalOnboarding)

    return () => {
      window.removeEventListener('keydown', resetInformationalOnboarding)
    }
  }, [user])

  useEffect(() => {
    configureSplit()
    loadAppInfo()

    // if (Capacitor.isNativePlatform()) {
    //   subscribeToNotifications()
    // }

    // if (Capacitor.isPluginAvailable('Keyboard')) {
    //   Keyboard.setResizeMode({ mode: KeyboardResize.None })
    //   Keyboard.setAccessoryBarVisible({ isVisible: false })
    // }
  }, [])

  // useEffect(() => {
  //   if (!!coupon && coupon.expires <= DateTime.now().toSeconds()) {
  //     dispatch(clearCoupon())
  //   }
  // }, [coupon])

  // useEffect(() => {
  //   const urlParams = new URLSearchParams(location.search)
  //   const params = Object.fromEntries(urlParams)
  //   const { coupon: cParam, psd } = params

  //   urlParams.delete('coupon')
  //   urlParams.delete('psd')

  //   history.replace({
  //     search: urlParams.toString()
  //   })

  //   if (!cParam) {
  //     console.log('no cParam')
  //     return
  //   }

  //   dispatch(clearCoupon())

  //   const c = getCoupon(cParam, psd, true)

  //   if (!c) {
  //     console.log('no cc')
  //     return
  //   }

  //   dispatch(pushToDialogStack('go-pro'))
  //   dispatch(setCoupon(c))
  // }, [location.search, location.state])

  // useEffect(() => {
  //   if (!Capacitor.isPluginAvailable('StatusBar')) {
  //     return
  //   }

  //   if (Capacitor.getPlatform() === 'android') {
  //     const bgColor = theme === 'dark' ? themeDark.color.background : themeLight.color.background
  //     StatusBar.setBackgroundColor({ color: bgColor })
  //     NavigationBar.setColor({ color: bgColor })
  //   }

  //   if (theme === 'dark') {
  //     StatusBar.setStyle({ style: Style.Dark })
  //     return
  //   }

  //   StatusBar.setStyle({ style: Style.Light })
  // }, [theme])

  return (
    <>
      <ConnectionDialog
        isOpen={showConnectionsModal}
        onClose={() => dispatch(setShowConnectionsModal(false))}
      />
      <FeedbackDialog
        isOpen={showFeedbackModal}
        onClose={() => dispatch(setShowFeedbackModal(false))}
      />
      <UploadManager />
      <DialogManager />
      <Designer />
      {showChat && !location.pathname.includes(ROUTES.create) && <CreatePopup />}
      <Route path={[ROUTES.story, ROUTES.photoSets]}>
        <ContentViewer content={contentToView?.content} type={contentToView?.type} />
      </Route>
      <div className="confetti" id="confetti" />
    </>
  )
}

export default State
