import styled from 'styled-components'

const StyledProView = styled.div`
  .bullet-section {
    margin-top: 40px;

    h3 {
      font-size: 20px;
      font-weight: 600;
      font-family: ${({ theme }) => theme.typeface.faktumXCon};
      color: ${({ theme }) => theme.color.gray[800]};
    }

    ul {
      margin: 12px 0 0 0;
      display: grid;
      grid-gap: 14px;
      padding: 0;

      li {
        display: flex;
        color: ${({ theme }) => theme.color.gray[800]};

        .icon {
          height: 20px;
          width: 20px;
          margin-right: 10px;
          color: white;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            height: 16px;
            stroke-width: 2.5px;
          }
        }
      }
    }

    &.bad ul li .icon {
      background-color: ${({ theme }) => theme.color.red[600]};
    }

    &.good ul li .icon {
      background-color: ${({ theme }) => theme.color.green[600]};
    }
  }
`

export default StyledProView
