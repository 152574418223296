import styled from 'styled-components'
import { UploadType } from '../../../../../types'

type StyledProps = {
  status: UploadType['status']
}

const StyledUploadRow = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;

  .file-details {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.color.gray[600]};

    span {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      line-break: anywhere;
    }

    svg {
      color: ${({ theme }) => theme.color.gray[500]};
      height: 24px;
      margin-right: 12px;
      flex-shrink: 0;
    }
  }

  .file-download-progress {
    height: 24px;
    width: 24px;
    flex-shrink: 0;
    margin-left: 16px;
    position: relative;

    .icon {
      top: 50%;
      left: 50%;
      height: 24px;
      position: absolute;
      transform: translate(-50%, -50%);

      &.done {
        color: ${({ theme }) => theme.color.brand};
      }

      &.error {
        color: ${({ theme }) => theme.color.red[700]};
      }
    }

    .CircularProgressbar {
      .CircularProgressbar-trail {
        stroke: ${({ status, theme }) =>
          status === 'error' ? theme.color.red[700] : theme.color.gray[300]};
      }

      .CircularProgressbar-path {
        stroke: ${({ status, theme }) =>
          status === 'error' ? theme.color.red[700] : theme.color.brand};
      }
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.gray[200]};
  }
`

export default StyledUploadRow
