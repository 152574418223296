import { FC, createRef, useEffect, useState } from 'react'
import SwipeableDrawer from '@bit/mui-org.material-ui.swipeable-drawer'
import { setShowCreate, useAppDispatch, useAppSelector } from '../../../../redux'
import { useCreate, useHaptics, useKeyboardHeight } from '../../../../utils'
import {
  CreateActionBar,
  CreateAddMediaButton,
  CreateDateInput,
  CreateHashtags,
  CreateHeader,
  CreateMedia,
  CreateSocialProfileSelect,
  CreateTextarea
} from '..'
import { StyledCreateDrawer } from '.'

const CreateDrawer: FC = () => {
  const scrollRef = createRef<HTMLDivElement>()
  const dispatch = useAppDispatch()
  const [showTitleInput, setShowTitleInput] = useState(false)
  const { savePost } = useCreate()
  const { trigger } = useHaptics()
  const { height } = useKeyboardHeight()
  const { showCreate } = useAppSelector((state) => state.state)
  const { post, isSaved, showHashtags } = useAppSelector((state) => state.create)

  useEffect(() => {
    if (showCreate) {
      trigger('impact-light')
    }

    return () => {
      trigger('impact-light')
    }
  }, [showCreate])

  useEffect(() => {
    if (!scrollRef?.current || !height || showTitleInput) {
      return
    }

    scrollRef.current?.scrollTo({
      top: 100000000
    })
  }, [showTitleInput, showHashtags, scrollRef, height])

  useEffect(() => {
    if (!isSaved) {
      savePost()
    }

    return () => {
      savePost.cancel()
    }
  }, [{ ...post, _id: undefined }])

  return (
    <>
      <SwipeableDrawer
        variant="temporary"
        anchor="right"
        open={showCreate}
        disableDiscovery={true}
        disableSwipeToOpen={true}
        swipeAreaWidth={5}
        ModalProps={{ disableEnforceFocus: true }}
        SwipeAreaProps={{
          allowSwipeInChildren: true
        }}
        onOpen={() => dispatch(setShowCreate(true))}
        onClose={() => dispatch(setShowCreate(false))}
      >
        <StyledCreateDrawer keyboardHeight={showHashtags ? 336 : height}>
          <CreateHeader showTitleInput={showTitleInput} setShowTitleInput={setShowTitleInput} />
          <div
            className="create-scroll"
            style={{ opacity: showTitleInput ? 0.2 : 1 }}
            ref={scrollRef}
          >
            {!post?.media?.length && <CreateAddMediaButton />}
            {!!post?.media?.length && <CreateMedia />}
            <div className="post-options">
              <CreateSocialProfileSelect />
              <CreateDateInput />
            </div>
            <CreateTextarea />
          </div>
          {!showTitleInput && <CreateActionBar />}
          <CreateHashtags />
        </StyledCreateDrawer>
      </SwipeableDrawer>
    </>
  )
}

export default CreateDrawer
