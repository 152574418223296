import { gql } from '@apollo/client'

export const searchGalleryCollectionsQuery = gql`
  query SearchGalleryCollections(
    $search: String!
    $collection: String
    $page: Int!
    $items: Int!
    $containsImageId: String
  ) {
    searchGalleryCollections(
      search: $search
      collection: $collection
      page: $page
      items: $items
      containsImageId: $containsImageId
    ) {
      docs {
        _id
        countImages
        defaultPreview {
          fileHttpLink
          fullPath
        }
        containsGivenImage
        name
        index
        user
        createdAt
      }
      totalDocs
      totalPages
      nextPage
      hasNextPage
      hasPrevPage
    }
  }
`
