import { FC, ReactNode } from 'react'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { StyledAccountMenuItem } from '.'

type Props = {
  icon: ReactNode
  label: string
  isSelected?: boolean
  onClick?: () => void
}

const AccountMenuItem: FC<Props> = ({ icon, label, isSelected, onClick }) => {
  return (
    <StyledAccountMenuItem onClick={onClick}>
      <div className="menu-item-label">
        {icon}
        <span>{label}</span>
      </div>
      {isSelected === undefined && <ChevronRightIcon className="chevron-icon" />}
      {isSelected !== undefined && (
        <button data-toggled={isSelected} className="toggle">
          <div />
        </button>
      )}
    </StyledAccountMenuItem>
  )
}

export default AccountMenuItem
