import { FC } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ChevronDownIcon, ChevronLeftIcon } from '@heroicons/react/24/outline'
import { ROUTES } from '../../../utils'
import { StyledUploadsFolderListHeader } from '.'

const UploadsFolderListHeader: FC = () => {
  const history = useHistory()
  const { path }: any = useParams()

  return (
    <StyledUploadsFolderListHeader>
      <button
        className="label"
        data-in-folder={!!path}
        onClick={() => history.push(ROUTES.uploads.all)}
      >
        <ChevronLeftIcon />
        FOLDERS
      </button>
      {/* <button className="sort-btn">
        Last modified <ChevronDownIcon />
      </button> */}
    </StyledUploadsFolderListHeader>
  )
}

export default UploadsFolderListHeader
