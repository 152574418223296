import { useEffect } from 'react'
import { Capacitor } from '@capacitor/core'
import { UserInterfaceStyle } from '../../mobile/plugins'
import { useAppSelector } from '../../redux'

export const useAppTheme = () => {
  const { theme } = useAppSelector((state) => state.state)

  const changeTheme = async () => {
    if (!Capacitor.isPluginAvailable('UserInterfaceStyle')) {
      return
    }

    await UserInterfaceStyle.changeStyle({
      style: theme
    })
  }

  useEffect(() => {
    changeTheme()
  }, [theme])
}
