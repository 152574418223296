import styled from 'styled-components'

const StyledCommunityDialog = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;

  .community-dialog-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e4e4e4f5;
  }

  .community-dialog-content {
    z-index: 10;
    border-radius: 10px;
    position: absolute;
    margin: 2rem;
    top: 50%;
    left: 50%;
    outline: none;
    transform: translate(-50%, -50%);
    background-color: ${({ theme }) => theme.color.background};
    margin: 0;
    box-sizing: border-box;
    padding: 30px;
    border: 0;
    max-height: calc(80vh - 40px);
    max-width: 400px;
    width: 100vw;
    overflow-y: auto;
    box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 0.1), 0 0 0 1px #00000019,
      0 2px 4px 0px #00000036;

    .community-img {
      margin: 0 auto;
      pointer-events: none;

      img {
        width: 100%;
      }
    }

    h1 {
      display: block;
      font-weight: 500;
      font-size: 26px;
      margin-top: 20px;
      color: ${({ theme }) => theme.color.gray[800]};
      text-align: center;
      font-family: ${({ theme }) => theme.typeface.polySans};
    }

    h2 {
      display: block;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      letter-spacing: -0.01rem;
      color: ${({ theme }) => theme.color.gray[600]};
      text-align: center;
      margin-top: 8px;
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      margin: 30px auto 0 auto;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
`

export default StyledCommunityDialog
