import styled from 'styled-components'

const StyledDesigner = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  z-index: ${({ theme }) => theme.elevation.modal};

  .designer {
    z-index: ${({ theme }) => theme.elevation.modal};
    border-radius: 10px;
    position: fixed;
    margin: 2rem;
    width: 100vw;
    height: 100vh;
    max-height: calc(100vh - 50px);
    max-width: calc(100vw - 50px);
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
    top: 50%;
    left: 50%;
    outline: none;
    overflow: hidden;
    transform: translate(-50%, -50%);
    background-color: ${({ theme }) => theme.color.background};
    margin: 0;
    padding: 0;
    border: 0;
    box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 0.1), 0 0 0 1px #00000019,
      0 2px 4px 0px #00000036;

    .designer-content {
      display: grid;
      grid-template-columns: auto minmax(0, 1fr);
    }
  }

  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    transition: 0.1s;
    background-color: #000000cc;
  }
`

export default StyledDesigner
