import styled from 'styled-components'

const StyledActionPlanExtraItem = styled.a`
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.color.gray[300]};
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  transition: 0.2s color, 0.2s background-color, 0.2s box-shadow;

  .extra-preview {
    display: flex;
    align-items: center;

    .extra-img {
      border-radius: 4px;
      height: 46px;
      width: 46px;
      margin-right: 12px;
      flex-shrink: 0;
      position: relative;
      background-color: ${({ theme }) => theme.color.gray[100]};

      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        object-fit: cover;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.foreground}20;
      }
    }

    .extra-details {
      .extra-title {
        font-weight: 600;
        display: block;
        color: ${({ theme }) => theme.color.gray[900]};
      }

      .extra-type {
        display: flex;
        align-items: center;
        margin-top: 2px;
        color: ${({ theme }) => theme.color.gray[600]};

        svg {
          margin-right: 6px;
          height: 22px;
        }
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    display: grid;
    align-content: space-between;
    align-items: unset;
    justify-content: unset;

    .extra-preview {
      display: block;

      .extra-img {
        width: 100%;
        height: auto;
        padding-top: 100%;
        position: relative;
        background-color: ${({ theme }) => theme.color.gray[100]};
      }
    }

    .extra-details {
      margin: 12px 0;
    }

    .btn {
      width: 100%;
      justify-content: space-between;
    }
  }

  &:hover,
  &:focus-visible {
    cursor: pointer;
    box-shadow: 0 0 0 1px rgb(0, 0, 0, 0.15), 0 4px 6px rgb(0, 0, 0, 0.05);
  }
`

export default StyledActionPlanExtraItem
