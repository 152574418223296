import { FC, createRef, useEffect, useState } from 'react'
import { throttle, times } from 'lodash'
import { DateTime } from 'luxon'
import { useDndContext } from '@dnd-kit/core'
import { useAppSelector } from '../../../../redux'
import { CalendarWeekHour } from '..'
import { StyledCalendarWeekHourRow } from '.'

type Props = {
  hour: number
}

const CalendarWeekHourRow: FC<Props> = ({ hour }) => {
  const nowRef = createRef<HTMLDivElement>()
  const { start, weeks } = useAppSelector((state) => state.calendarMobile)
  const { active } = useDndContext()

  useEffect(() => {
    if (nowRef?.current) {
      nowRef.current.scrollIntoView({
        block: 'center'
      })
    }
  }, [])

  return (
    <StyledCalendarWeekHourRow>
      {/* {active?.id && (
        <div
          className="block"
          style={{
            minHeight: 120,
            background: 'transparent',
            width: `calc((100vw - 61px) * ${week})`
          }}
        />
      )} */}
      {times(weeks * 7, (x) => {
        const day = DateTime.fromISO(start).plus({ day: x }).set({ hour })

        return (
          <CalendarWeekHour
            day={day}
            hour={hour}
            key={`hour-${day.day}-${day.month}-${day.year}-${hour}`}
          />
        )
      })}
      {DateTime.now().hour === hour && (
        <div
          className="now-indicator"
          style={{ top: `calc(-12px + ${(DateTime.now().minute / 60) * 100}%)` }}
          ref={nowRef}
        />
      )}
    </StyledCalendarWeekHourRow>
  )
}

export default CalendarWeekHourRow
