import { FC, useContext } from 'react'
import { ClockIcon } from '@heroicons/react/20/solid'
import { CheckIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { BoltIcon } from '@heroicons/react/24/solid'
import { useAppSelector } from '../../../../../redux'
import { getCouponValue, getPlanPrice, userStore } from '../../../../../utils'
import { StyledSelectPlanScreen } from '.'

type Props = {
  plan: 'month' | 'year'
  onSelect: (plan: 'month' | 'year') => void
}

const SelectPlanScreen: FC<Props> = ({ plan, onSelect }) => {
  const { user } = useContext(userStore)
  const { coupon } = useAppSelector((state) => state.state)

  const getSavePrice = () => {
    if (user?.trial) {
      return 50
    }

    const yearPrice = getPlanPrice('year')
    const monthPrice = getPlanPrice('month')
    const yearDiscount = getCouponValue('year', coupon)
    const monthDiscount = getCouponValue('month', coupon)

    return Math.abs(yearPrice - yearDiscount - (monthPrice - monthDiscount) * 12)
  }

  const getPlanPriceText = (plan: 'month' | 'year') => {
    const price = getPlanPrice(plan)
    const discount = price - getCouponValue(plan, coupon)
    const adjustedPrice = Math.ceil(price / (plan === 'year' ? 12 : 1))
    const adjustedDiscount = Math.ceil(discount / (plan === 'year' ? 12 : 1))

    if (!!coupon?.discount[plan]) {
      return (
        <>
          <b>${adjustedDiscount}</b>
          <s>${adjustedPrice}</s>
        </>
      )
    }

    return <>${adjustedPrice}</>
  }

  const getTotal = () => {
    const subTotal = getPlanPrice(plan)
    const discount = getCouponValue(plan, coupon)

    return Math.max(subTotal - discount, 0)
  }

  return (
    <StyledSelectPlanScreen className="dialog-screen">
      <div className="dialog-breadcrumbs">
        <span className="active">Select Plan</span>
        <ChevronRightIcon />
        <span>Payment</span>
      </div>
      {!user?.trial && (
        <span className="dialog-body-text">
          Go Pro to maintain access to Social Curator and unlock the community, view all action
          plans and connect up to 6 social profiles.
        </span>
      )}
      {user?.trial && (
        <div className="trial-banner">
          <span className="special-deal-header">SPECIAL DEAL</span>
          <span>Upgrade to the annual plan before your trial ends and save $50.</span>
        </div>
      )}
      <div className="subscription-select-container">
        <div className="subscription-select">
          <div className="subscription-select-item">
            <input
              checked={plan === 'year'}
              type="radio"
              value="year"
              name="subscription"
              disabled={
                user?.subscriptionInterval === 'Year' && user?.stripeSubscriptionStatus === 'Active'
              }
              onChange={() => onSelect('year')}
            />
            <div className="subscription-details">
              <div className="subscription-checkbox">
                <CheckIcon />
              </div>
              <span className="subscription-title">
                Annual <mark>SAVE ${getSavePrice()}</mark>
              </span>
            </div>
            <span className="subscription-value">{getPlanPriceText('year')}</span>
          </div>
          <div className="subscription-select-item">
            <input
              checked={plan === 'month'}
              type="radio"
              value="month"
              name="subscription"
              disabled={
                user?.subscriptionInterval === 'Month' &&
                user?.stripeSubscriptionStatus === 'Active' &&
                !user.trial
              }
              onChange={() => onSelect('month')}
            />
            <div className="subscription-details">
              <div className="subscription-checkbox">
                <CheckIcon />
              </div>
              <span className="subscription-title">Monthly</span>
            </div>
            <span className="subscription-value">{getPlanPriceText('month')}</span>
          </div>
        </div>
      </div>
      <div className="subscription-total">
        <span className="subscription-total-label">Total</span>
        {(!user?.trial || plan === 'month') && (
          <span className="subscription-total-value">
            <b>${getTotal()}</b> <span>/ {plan === 'year' ? 'Year' : 'Month'}</span>
          </span>
        )}
        {!!user?.trial && plan === 'year' && (
          <span className="subscription-total-value special">
            <b>$549</b>
            <span>$599/year thereafter</span>
          </span>
        )}
        {/* {!!user?.trial && plan === 'month' && (
          <span className="subscription-total-value">
            <b>$39 now</b>
            <span>, then $59 / Month</span>
          </span>
        )} */}
      </div>
    </StyledSelectPlanScreen>
  )
}

export default SelectPlanScreen
