import styled from 'styled-components'

const StyledPickFilesDialog = styled.div`
  width: 100vw;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr) auto;

  .tabs {
    height: 64px;
  }

  .page-container {
  }

  .action-container {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid ${({ theme }) => theme.color.gray[300]};

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      height: 50px;
      padding: 0 16px;
      border-radius: 4px;
      background-color: ${({ theme }) => theme.color.gray[100]};
      color: ${({ theme }) => theme.color.gray[600]};

      &.solid-btn {
        width: 100px;
        box-sizing: border-box;
        background-color: ${({ theme }) => theme.color.gray[900]};
        color: ${({ theme }) => theme.color.background};
      }

      &:disabled {
        cursor: not-allowed;
        background-color: ${({ theme }) => theme.color.gray[100]};
        color: ${({ theme }) => theme.color.gray[300]};
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    max-width: 800px;
    max-height: 600px;
    box-sizing: border-box;
    height: 100vh;
  }
`

export default StyledPickFilesDialog
