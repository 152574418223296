import { FC } from 'react'
import { setDesignerSubTab, useAppDispatch } from '../../../redux'
import { DesignerToolButton } from '.'

const DesignerEditImageTool: FC = () => {
  const dispatch = useAppDispatch()

  return (
    <DesignerToolButton onClick={() => dispatch(setDesignerSubTab('edit-image'))}>
      <span>Edit Image</span>
    </DesignerToolButton>
  )
}

export default DesignerEditImageTool
