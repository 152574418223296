import styled from 'styled-components'

const StyledAcademyLanding = styled.div`
  .sizzle-container {
    padding-bottom: 30px;
    background-color: ${({ theme }) => theme.color.gray[100]};
  }
`

export default StyledAcademyLanding
