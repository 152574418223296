import { FC, useContext, useEffect, useState } from 'react'
import { compact } from 'lodash'
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline'
import {
  setPostsQuery,
  setPostsSort,
  setPostsStatus,
  togglePostsFilter,
  togglePostsProfile,
  useAppDispatch,
  useAppSelector
} from '../../../redux'
import { debounceFilter, formatUsername, useCreate, userStore } from '../../../utils'
import { Button, ConnectionAvatar, Dropdown, Input, PostGridList } from '../..'
import { StyledPostGrid } from '.'

const PostGrid: FC = () => {
  const dispatch = useAppDispatch()
  const [showSearch, setShowSearch] = useState(false)
  const { user } = useContext(userStore)
  const { newPost } = useCreate()
  const { query, tags, status, filters, sort, profiles, posts, onlyLiked, onlyUsed, loading } =
    useAppSelector((state) => state.posts)

  useEffect(() => {
    if (!query?.trim()) {
      return
    }

    dispatch(setPostsQuery(''))
  }, [showSearch])

  if (
    !loading &&
    !status &&
    !posts.length &&
    !query &&
    !profiles?.length &&
    !filters.length &&
    !onlyLiked &&
    !onlyUsed
  ) {
    return (
      <StyledPostGrid>
        <div className="empty-state">
          <span className="empty-state-title">You haven't created a post yet</span>
          <Button variant="emphasis" onClick={() => newPost(true)}>
            Create a Post
          </Button>
        </div>
      </StyledPostGrid>
    )
  }

  return (
    <StyledPostGrid>
      <div className="post-grid-header">
        <button className="search-btn" onClick={() => setShowSearch(!showSearch)}>
          {showSearch && <XMarkIcon />}
          {!showSearch && <MagnifyingGlassIcon />}
          Search
        </button>
        <div className="post-grid-header-filters">
          <Dropdown
            size="xs"
            placeholder="Labels"
            value={filters}
            options={compact(tags).map((tag) => {
              return {
                label: tag,
                value: tag,
                stayOpen: true
              }
            })}
            popoverOptions={{
              height: 200
            }}
            onSelect={(value) => dispatch(togglePostsFilter(value))}
          />
          <Dropdown
            size="xs"
            placeholder="Profiles"
            value={profiles}
            options={(user?.socialProfiles || []).map((profile) => ({
              icon: <ConnectionAvatar size={24} profile={profile} />,
              label: formatUsername(profile.username, profile.type),
              value: profile._id || '',
              stayOpen: true
            }))}
            popoverOptions={{
              height: 200
            }}
            onSelect={(value) => dispatch(togglePostsProfile(value))}
          />
          <Dropdown
            size="xs"
            placeholder="All Statuses"
            value={status || 'all'}
            options={[
              { label: 'All Statuses', value: 'all' },
              { label: 'Draft', value: 'draft' },
              { label: 'Reminder Notification', value: 'notification' },
              { label: 'Auto-Publish', value: 'auto-post' },
              { label: 'Published', value: 'published' }
            ]}
            popoverOptions={{
              width: 220
            }}
            onSelect={(value) => dispatch(setPostsStatus(value === 'all' ? undefined : value))}
          />
          <Dropdown
            size="xs"
            placeholder="Sort"
            value={sort}
            options={[
              {
                label: 'Date Created',
                value: 'created'
              },
              {
                label: 'Date Modified',
                value: 'modified'
              },
              {
                label: 'Scheduled Date',
                value: 'postDate'
              }
            ]}
            onSelect={(value) => dispatch(setPostsSort(value as any))}
          />
        </div>
      </div>
      {showSearch && (
        <div className="post-grid-search-field">
          <Input
            size="sm"
            defaultValue={query}
            icon={<MagnifyingGlassIcon />}
            placeholder="Search Posts"
            type="search"
            autoFocus
            onChange={(e) => debounceFilter(() => dispatch(setPostsQuery(e.target.value)))}
          />
        </div>
      )}
      <PostGridList />
    </StyledPostGrid>
  )
}

export default PostGrid
