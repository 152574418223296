import { FC, ReactNode, createContext } from 'react'
import { useConnections } from '../../../utils'

type InitialState = ReturnType<typeof useConnections>

const initialState: InitialState = {
  loading: false,
  platformCounts: {
    instagram: 0,
    facebook: 0,
    linkedin: 0,
    twitter: 0,
    pinterest: 0,
    tiktok: 0
  },
  hasConnections: true,
  connectedProfiles: [],
  activeProfiles: [],
  expiredProfiles: [],
  availableProfiles: [],
  profilesToConnect: [],
  platformToConnect: undefined,
  platformToOverview: undefined,
  connectionLimit: 1,
  setPlatformToConnect: () => {},
  connectPlatform: async () => {},
  toggleProfile: () => {},
  connectProfiles: async () => {},
  handleConnectAccountsFromState: async () => {},
  setPlatformToOverview: () => {},
  setAvailableProfiles: () => {},
  setProfilesToConnect: () => {}
}

type Props = {
  children: ReactNode
}

const connectionStore = createContext(initialState)
const { Provider } = connectionStore

const ConnectionContext: FC<Props> = ({ children }) => {
  const value = useConnections()

  return <Provider value={value}>{children}</Provider>
}

export { connectionStore, ConnectionContext, Provider }
