import { FC, useContext, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import firebase from 'firebase/app'
import { isEqual } from 'lodash'
import { styled } from 'styled-components'
import { Intercom } from '@capacitor-community/intercom'
import { Dialog } from '@capacitor/dialog'
import { Keyboard } from '@capacitor/keyboard'
import { OCCUPATIONS, useForm, userStore } from '../../../../utils'
import { Input, ShiftDrawer, Spinner } from '../..'
import { StyledProfileDrawer } from '.'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const ProfileDrawer: FC<Props> = ({ isOpen, onClose }) => {
  const updateLoggedInUser = firebase.functions().httpsCallable('updateLoggedInUser')
  const [canSave, setCanSave] = useState(false)
  const [loading, setLoading] = useState(false)
  const { user, refresh } = useContext(userStore)
  const { form, updateFormValue } = useForm({
    displayName: '',
    email: ''
  })

  const handleSubmit = async () => {
    try {
      setLoading(true)

      const profile = firebase.auth().currentUser
      const nameChanged = user?.displayName !== form.displayName
      const emailChanged = user?.email !== form.email

      if (emailChanged) {
        const { cancelled, value: password } = await Dialog.prompt({
          title: 'Confirm your password',
          message: 'You need to confirm your password to update your profile.',
          inputPlaceholder: 'Your password'
        })

        if (cancelled || !password?.trim()) {
          return
        }

        const credential = firebase.auth.EmailAuthProvider.credential(
          profile?.email as string,
          password.trim()
        )

        await profile?.reauthenticateWithCredential(credential)
        await profile?.updateEmail(form.email)
      }

      if (nameChanged) {
        await profile?.updateProfile({ displayName: form.displayName.trim() })
      }

      await updateLoggedInUser({
        email: form.email,
        displayName: form.displayName,
        aiOnboarding: {
          use: form.use,
          occupation: form.occupation,
          company: form.company,
          ages: form.ages,
          genders: form.genders,
          occupations: form.occupations,
          voices: form.voices,
          interests: form.interests
        }
      })

      await Intercom.updateUser({
        name: form.displayName,
        email: form.email,
        customAttributes: {
          name: form.displayName,
          email: form.email,
          aiOnboarding: {
            use: form.use,
            occupation: form.occupation,
            company: form.company,
            ages: form.ages,
            genders: form.genders,
            occupations: form.occupations,
            voices: form.voices,
            interests: form.interests
          }
        }
      })

      toast.success('Updated profile')
      await refresh()
    } catch (err) {
      toast.error((err as any)?.message || err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!user) {
      return
    }

    const madeChanges = !isEqual(form, {
      displayName: user.displayName,
      email: user.email,
      ...user.aiOnboarding
    })

    const hasDisplayName = !!form?.displayName?.trim()
    const hasEmail = !!form?.email?.trim()
    const hasOccupation = !!form?.occupation?.trim()

    const inputComplete = hasDisplayName && hasEmail && hasOccupation

    setCanSave(inputComplete && madeChanges)
  }, [user, form])

  useEffect(() => {
    if (!user) {
      return
    }

    updateFormValue({
      displayName: user.displayName,
      email: user.email,
      ...user.aiOnboarding
    })
  }, [user, isOpen])

  const SaveButton: FC = () => {
    return (
      <StyledSaveButton disabled={!canSave || loading} onClick={() => handleSubmit()}>
        {!loading ? 'Save' : <Spinner />}
      </StyledSaveButton>
    )
  }

  const StyledSaveButton = styled.button`
    height: 36px;
    color: ${({ theme }) => theme.color.brand};
    background-color: transparent;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;

    &:disabled {
      color: ${({ theme }) => theme.color.gray[400]};
    }
  `

  return (
    <ShiftDrawer
      title="Profile information"
      headerBtn={<SaveButton />}
      isOpen={isOpen}
      onClose={onClose}
    >
      <StyledProfileDrawer>
        <div className="inputs">
          <Input
            name="name"
            label="Full name"
            placeholder="Full name"
            type="text"
            enterKeyHint="done"
            required
            value={form.displayName}
            onChange={(value) => updateFormValue('displayName', value)}
            onKeyDown={(e) => e.key === 'Enter' && Keyboard.hide()}
          />
          <Input
            name="email"
            label="Email address"
            placeholder="Email address"
            type="email"
            enterKeyHint="done"
            required
            value={form.email}
            onChange={(value) => updateFormValue('email', value)}
            onKeyDown={(e) => e.key === 'Enter' && Keyboard.hide()}
          />
          <Input
            name="occupation"
            label="Occupation"
            placeholder="Occupation"
            type="text"
            enterKeyHint="done"
            required
            value={form?.occupation}
            dataList={OCCUPATIONS}
            onChange={(value) => updateFormValue('occupation', value)}
            onKeyDown={(e) => e.key === 'Enter' && Keyboard.hide()}
          />
        </div>
      </StyledProfileDrawer>
    </ShiftDrawer>
  )
}

export default ProfileDrawer
