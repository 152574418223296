import styled from 'styled-components'

type StyledProps = {
  isCollapsed: boolean
}

const StyledActionPlanContentHeader = styled.div<StyledProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 53px;
  z-index: 6;
  transition: 0.2s;
  background-color: ${({ theme }) => theme.color.background};
  border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};

  ${({ isCollapsed }) =>
    isCollapsed &&
    `
    top: -54px;
  `}

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    left: 220px;
  }
`

export default StyledActionPlanContentHeader
