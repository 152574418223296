export const day9 = {
  _id: 'day9',
  title: 'DAY 9',
  tasks: [
    {
      label: 'Find any caption template in the Caption Library',
      steps: []
    },
    {
      label: 'Click the “Fill” button on the caption template',
      steps: []
    },
    {
      label: 'Add the filled-in caption to your post',
      steps: []
    },
    {
      label: 'Use Dottie to suggest hashtags for the caption, using the “Suggest hashtags” button',
      steps: []
    },
    {
      label: 'Add the recommended hashtags to your post',
      steps: []
    }
  ],
  form: {
    url: 'https://notionforms.io/forms/day-9-feedback',
    keys: {
      FULL_NAME: 'defb4d2d-b807-4ff8-b0f6-f328aae115aa',
      EMAIL: 'e4af81b1-2d0d-4b4a-a8d0-bd70b3b6c82e',
      SCREEN_RECORDING: '2889f2f6-98c0-4915-836d-2ecc7eb01484',
      USER_ID: 'e948221b-0bcd-48c5-9726-44213f974f8c'
    }
  }
}
