import { FC, FormEvent, useState } from 'react'
import toast from 'react-hot-toast'
import Sheet from 'react-modal-sheet'
import firebase from 'firebase/app'
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import { SCMark } from '../../../../assets'
import { Input } from '../..'
import { StyledForgotPasswordSheet } from '.'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const ForgotPasswordSheet: FC<Props> = ({ isOpen, onClose }) => {
  const emailExists = firebase.functions().httpsCallable('emailExists')
  const loginMigration = firebase.functions().httpsCallable('confirmPasswordMigration')
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [isSent, setIsSent] = useState(false)

  const checkIfEmailExists = async (email: string) => {
    const signInMethods = await firebase.auth().fetchSignInMethodsForEmail(email)
    const emailResult = await emailExists({ email })
    const { emailFound } = emailResult.data

    if (signInMethods.length || emailFound) {
      return true
    }

    return false
  }

  const handleSubmit = async (e?: FormEvent) => {
    try {
      e?.preventDefault()
      setLoading(true)

      if (!email.trim()) {
        return
      }

      const exists = await checkIfEmailExists(email)

      if (!exists) {
        throw 'There is no user with that email address.'
      }

      await loginMigration({ email })
      await firebase.auth().sendPasswordResetEmail(email)

      setIsSent(true)
    } catch (err) {
      console.error(err)
      toast.error((err as any)?.message || err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Sheet detent="content-height" isOpen={isOpen} onClose={onClose}>
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>
          <StyledForgotPasswordSheet autoComplete="on" onSubmit={(e: FormEvent) => handleSubmit(e)}>
            <h1>Forgot Password</h1>
            <h2>
              Enter the email associated with your account and we'll send an email with instructions
              on how to reset your password.
            </h2>
            <Input
              id="email"
              value={email}
              disabled={isSent}
              placeholder="Email address"
              required
              type="email"
              name="email"
              enterKeyHint="next"
              onKeyDown={(e) => e.key === 'Enter' && handleSubmit()}
              onChange={(value) => setEmail(value)}
            />
            {isSent && (
              <div className="sent-indicator">
                <CheckCircleIcon />
                Sent Email
              </div>
            )}
            {!isSent && (
              <button disabled={loading}>
                {!loading && 'Send Instructions'}
                {loading && <SCMark className="logo-spinner" />}
              </button>
            )}
          </StyledForgotPasswordSheet>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={() => onClose()} />
    </Sheet>
  )
}

export default ForgotPasswordSheet
