import styled from 'styled-components'

type StyledProps = {
  isDragging?: boolean
  isToday?: boolean
  isOver?: boolean
}

const StyledCalendarMonthSlot = styled.div<StyledProps>`
  position: relative;
  transition: 0.2s;
  align-content: start;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  background-color: ${({ isOver, theme }) =>
    isOver ? theme.color.brandLight : theme.color.background};

  .day-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;

    .day-label {
      padding: 4px 10px;
      background-color: ${({ isToday, theme }) =>
        isToday ? theme.color.brand : theme.color.gray[100]};
      color: ${({ isToday, theme }) => (isToday ? '#fff' : theme.color.gray[600])};
      border-radius: 4px;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .add-post-btn {
    background-color: ${({ theme }) => theme.color.background};
    color: ${({ theme }) => theme.color.gray[500]};
    box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
      0px 2px 3px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    padding: 0;
    border-radius: 5px;
    transition: 0.2s;

    svg {
      height: 22px;
      stroke-width: 1.75px;
    }

    &:hover {
      cursor: pointer;
      box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.2), 0px 0px 0px 1px rgba(0, 0, 0, 0.075),
        0px 2px 3px rgba(0, 0, 0, 0.1);
    }
  }

  .day-event-list {
    display: grid;
    grid-gap: 4px;
    align-items: start;
    padding: 0 8px 8px 8px;
    align-content: start;
    overflow: ${({ isDragging }) => (isDragging ? 'hidden' : 'auto')};
  }

  &:hover {
    button {
      opacity: 1;
    }
  }

  &:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.color.gray[200]};
  }
`

export default StyledCalendarMonthSlot
