import { FC, useContext, useEffect } from 'react'
import { sample, shuffle, times } from 'lodash'
import { v4 as uuid } from 'uuid'
import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid'
import { PlusIcon } from '@heroicons/react/24/outline'
import { addPlanDay, setPlanDays, useAppDispatch, useAppSelector } from '../../../redux'
import { userStore } from '../../../utils'
import { PlanPostListItem } from '..'
import { StyledPlanPlanStep } from '.'

const PlanPlanStep: FC = () => {
  const dispatch = useAppDispatch()
  const { user } = useContext(userStore)
  const { days, pillars, loadingGenPosts } = useAppSelector((state) => state.plan)

  const FOCUSES = shuffle(['engagement', 'sales', 'education']) as any
  const PILLARS = shuffle(user?.contentPillars || pillars || [])

  const generateDay = (index: number) => {
    return {
      _id: uuid(),
      focus: FOCUSES[index % 3],
      pillar: PILLARS[index % PILLARS.length]
    }
  }

  const addDay = () => {
    const recent = days[days.length - 1]

    if (!recent) {
      return dispatch(addPlanDay(generateDay(0)))
    }

    const pillars = PILLARS.filter((p) => p._id !== recent.pillar._id)
    const focus = sample(FOCUSES)
    const pillar = sample(pillars)

    if (!pillar) {
      return dispatch(
        addPlanDay({
          _id: uuid(),
          focus,
          pillar: pillars[0]
        })
      )
    }

    return dispatch(
      addPlanDay({
        _id: uuid(),
        focus,
        pillar
      })
    )
  }

  useEffect(() => {
    if (!days.length) {
      dispatch(setPlanDays(times(3, (index) => generateDay(index))))
    }
  }, [])

  return (
    <StyledPlanPlanStep>
      <h1>
        Let’s plan{' '}
        {!!days.length && (
          <>
            <mark>{days.length} days</mark> of{' '}
          </>
        )}
        posts
      </h1>
      <p>Select a content pillar and a key focus for each post.</p>
      <div className="form-container">
        <div className="form-header">
          <div />
          <div className="form-heading">
            <span>PILLAR</span>
          </div>
          <div
            className="form-heading"
            data-tooltip="<span><b>Engagement</b> posts are posts that your ideal client can easily relate to (stories, quotes, real life lessons, etc.)</span>
            <br/>
<span><b>Education</b> posts educate your ideal client on something meaningful to them and related to your business (tips, hacks, etc)</span>
            <br/>
<span><b>Sales</b> posts specifically highlight your offer and how it benefits your ideal client.</span>"
          >
            <span>FOCUS</span>
            <QuestionMarkCircleIcon />
          </div>
        </div>
        <div className="form-content">
          {days.map((day, index) => {
            return <PlanPostListItem day={day} index={index} key={day._id} />
          })}
        </div>
        {days.length < 7 && (
          <button className="form-action" disabled={loadingGenPosts} onClick={() => addDay()}>
            <PlusIcon /> Add post
          </button>
        )}
      </div>
    </StyledPlanPlanStep>
  )
}

export default PlanPlanStep
