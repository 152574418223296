import styled from 'styled-components'

const StyledCreateEditorPostType = styled.div`
  .label {
    cursor: default;
    user-select: none;
    margin-bottom: 12px;

    span {
      display: block;
    }

    .label-title {
      color: ${({ theme }) => theme.color.gray[800]};
      font-weight: 500;
    }

    .label-description {
      color: ${({ theme }) => theme.color.gray[500]};
      font-size: 14px;
      margin-top: 4px;
    }
  }

  .options {
    display: flex;
    align-items: center;

    .option {
      display: flex;
      align-items: center;
      position: relative;
      overflow: hidden;

      input {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 1;

        &:checked + .option-box {
          box-shadow: inset 0 0 0 2px ${({ theme }) => theme.color.brand};

          &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 12px;
            width: 12px;
            border-radius: 50%;
            background-color: ${({ theme }) => theme.color.brand};
          }
        }
      }

      .option-box {
        height: 22px;
        width: 22px;
        border-radius: 50%;
        box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.gray[300]};
        flex-shrink: 0;
        margin-right: 8px;
        position: relative;
        transition: 0.2s;
      }

      .option-label {
        display: flex;
        align-items: center;
        font-size: 15px;
        color: ${({ theme }) => theme.color.gray[700]};

        svg {
          height: 18px;
          margin-left: 4px;
          color: ${({ theme }) => theme.color.gray[400]};
        }
      }

      &:not(:last-child) {
        margin-right: 18px;
      }

      &:hover,
      input:hover {
        cursor: pointer;

        .option-box {
          box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.gray[400]};
        }
      }
    }
  }
`

export default StyledCreateEditorPostType
