import styled from 'styled-components'

const StyledUploadsPage = styled.div`
  display: grid;
  overflow-y: auto;
  height: 100%;

  .loading-indicator {
    padding: 20px;
  }

  .upload-list-item {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    background-color: transparent;
    transition: 0.2s;

    .upload-list-item-preview {
      height: 44px;
      width: 44px;
      flex-shrink: 0;
      margin-right: 10px;
      padding: 4px;
      box-sizing: border-box;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${({ theme }) => theme.color.gray[100]};

      .media-preview {
        filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.2));
      }
    }

    .upload-list-item-details {
      font-size: 15px;
      color: ${({ theme }) => theme.color.gray[800]};
      display: -webkit-box;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-align: left;
      -webkit-box-orient: vertical;
      line-break: anywhere;
    }

    &:hover {
      background-color: ${({ theme }) => theme.color.gray[100]};
    }

    &[data-selected='true'] {
      .upload-list-item-preview {
        background-color: ${({ theme }) => theme.color.brand};
        color: ${({ theme }) => theme.color.background};

        svg {
          stroke-width: 2px;
          height: 24px;
        }
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.color.gray[200]};
    }
  }
`

export default StyledUploadsPage
