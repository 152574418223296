import { FC, useEffect, useState } from 'react'
import { ShepherdOptionsWithType } from 'react-shepherd'
import { isMobile } from '../../../utils'
import { useAppSelector } from '../../../redux'
import { Tour } from '..'
import { generateTour } from '.'

const NewPostTour: FC = () => {
  const { tour } = useAppSelector(state => state.tour)

  const TOUR_ID = 'create_post'
  const [steps, setSteps] = useState<ShepherdOptionsWithType[]>([])

  useEffect(() => {
    setSteps(generateTour(isMobile()))
  }, [])

  return <Tour _id={TOUR_ID} steps={steps} canStart={tour === TOUR_ID} />
}

export default NewPostTour
