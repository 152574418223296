import { ComponentProps, FC, useEffect, useState } from 'react'
import { xorBy } from 'lodash'
import { PostType } from '../../../types'
import { Dialog, Spinner, Tabs } from '../..'
import { ComputerPage, GalleryPage, StyledPickFilesDialog, UploadsPage } from '.'

type Props = {
  onConfirm: (media: NonNullable<PostType['media']>) => void
} & ComponentProps<typeof Dialog>

const PickFilesDialog: FC<Props> = ({ onConfirm, isOpen, onClose }) => {
  const [loading, setLoading] = useState(false)
  const [files, setFiles] = useState<NonNullable<PostType['media']>>([])
  const [page, setPage] = useState<'computer' | 'uploads' | 'gallery'>('computer')

  const handleConfirm = () => {
    onConfirm(files)
    onClose()
  }

  useEffect(() => {
    setFiles([])
    setLoading(false)
    setPage('computer')
  }, [isOpen])

  useEffect(() => {
    setFiles([])
  }, [page])

  return (
    <Dialog canClose={!loading} isOpen={isOpen} onClose={onClose}>
      <StyledPickFilesDialog>
        <div className="header-container">
          <Tabs
            tabs={[
              {
                label: 'My computer',
                isActive: page === 'computer',
                onClick: () => setPage('computer')
              },
              {
                label: 'Uploads',
                isActive: page === 'uploads',
                onClick: () => setPage('uploads')
              },
              {
                label: 'Gallery',
                isActive: page === 'gallery',
                onClick: () => setPage('gallery')
              }
            ]}
          />
        </div>
        <div className="page-container">
          {page === 'computer' && (
            <ComputerPage
              loading={loading}
              onConfirm={(media) => {
                onConfirm(media)
                onClose()
              }}
              setLoading={setLoading}
            />
          )}
          {page === 'uploads' && (
            <UploadsPage
              selected={files}
              onSelect={(file) => {
                setFiles(xorBy(files, [file], 'ref'))
              }}
            />
          )}
          {page === 'gallery' && (
            <GalleryPage
              selected={files}
              onSelect={(file) => {
                setFiles(xorBy(files, [file], 'url'))
              }}
            />
          )}
        </div>
        <div className="action-container">
          <button onClick={() => onClose()}>Cancel</button>
          <button disabled={!files.length} className="solid-btn" onClick={() => handleConfirm()}>
            {loading ? <Spinner /> : 'Add'}
          </button>
        </div>
      </StyledPickFilesDialog>
    </Dialog>
  )
}

export default PickFilesDialog
