import styled from 'styled-components'

type StyledProps = {
  maxWidth?: number
}

const StyledPageLayout = styled.div<StyledProps>`
  max-width: ${({ maxWidth }) => maxWidth}px;
  margin: 0 auto;
  padding: 20px;

  @media (min-width: ${({ theme }) => theme.breakpoint.m}px) {
    // padding: 40px 20px;
  }
`

export default StyledPageLayout
