import styled from 'styled-components'

const StyledNav = styled.nav`
  border-top: 1px solid ${({ theme }) => theme.color.gray[300]};
  background-color: ${({ theme }) => theme.color.background};
  padding-top: 10px;
  position: relative;
  padding-bottom: var(--bottom-spacing);
  z-index: ${({ theme }) => theme.elevation.fixed};

  .nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 28px;
  }

  .calendar-text {
    position: absolute;
    top: 12.6px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    width: 36px;
    font-weight: 600;
    color: ${({ theme }) => theme.color.background};
  }
`

export default StyledNav
