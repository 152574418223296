import styled, { keyframes } from 'styled-components'

const animIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(30px) scale(0.92);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
`

const StyledCreateBarPanel = styled.div`
  background-color: ${({ theme }) => theme.color.background};
  border-radius: 12px;
  height: 100%;
  width: 100%;
  box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1);
  animation: ${animIn} 0.2s ease-in-out;

  .create-bar-filter-header {
    padding: 20px 20px 20px 20px;
    display: flex;

    .input {
      width: 100%;
      border-radius: 6px;
    }

    .btn {
      margin-left: 8px;
      border-radius: 6px;
      flex-shrink: 0;
    }
  }
`

export default StyledCreateBarPanel
