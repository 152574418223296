import { createRef, FC, useContext, useEffect, useState } from 'react'
import {
  ArrowDownTrayIcon,
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
  CheckIcon,
  ChevronLeftIcon
} from '@heroicons/react/24/outline'
import { useEditor } from '@layerhub-io/react'
import { setDesignerTitle, setShowDesigner, useAppDispatch, useAppSelector } from '../../../redux'
import { useDesigner, userStore } from '../../../utils'
import { InputDialog, ResizePopover } from '../..'
import { StyledDesignerHeader } from '.'

const DesignerHeader: FC = () => {
  const editor = useEditor()
  const dispatch = useAppDispatch()
  const resizeBtnRef = createRef<HTMLButtonElement>()
  const { user } = useContext(userStore)
  const { title } = useAppSelector((state) => state.designer)
  const { exportJSON, saveDesign, loadDesignFromChat } = useDesigner()
  const [templateInput, setTemplateInput] = useState('')
  const [canUndo, setCanUndo] = useState(false)
  const [canRedo, setCanRedo] = useState(false)
  const [showResizePopover, setShowResizePopover] = useState(false)
  const [showTemplateInput, setShowTemplateInput] = useState(false)

  const handleStatus = () => {
    setCanUndo(!!editor?.history?.status?.undos?.length)
    setCanRedo(!!editor?.history?.status?.redos?.length)
  }

  useEffect(() => {
    handleStatus()

    if (editor) {
      editor.on('history:changed', handleStatus)
    }
    return () => {
      if (editor) {
        editor.off('history:changed', handleStatus)
      }
    }
  }, [editor])

  return (
    <>
      <StyledDesignerHeader>
        <div className="designer-header-group">
          <button className="designer-header-btn" onClick={() => dispatch(setShowDesigner(false))}>
            <ChevronLeftIcon /> Close
          </button>
          <button
            className="designer-header-btn resize-btn"
            ref={resizeBtnRef}
            onClick={() => setShowResizePopover(!showResizePopover)}
          >
            Resize
          </button>
          <hr />
          <button
            className="designer-header-btn icon-btn"
            disabled={!canUndo}
            onClick={() => editor?.history?.undo()}
          >
            <ArrowUturnLeftIcon />
          </button>
          <button
            className="designer-header-btn icon-btn"
            disabled={!canRedo}
            onClick={() => editor?.history?.redo()}
          >
            <ArrowUturnRightIcon />
          </button>
          <hr />
          <input
            placeholder="Untitled Design"
            className="design-title-input"
            value={title}
            onChange={(e) => dispatch(setDesignerTitle(e.target.value))}
          />
        </div>
        <div className="designer-header-group">
          {user?.role === 'admin' && (
            <button
              className="designer-header-btn sub-btn"
              onClick={() => setShowTemplateInput(true)}
            >
              Import template
            </button>
          )}
          {user?.role === 'admin' && (
            <button className="designer-header-btn sub-btn" onClick={() => exportJSON()}>
              <ArrowDownTrayIcon />
              Download
            </button>
          )}
          <button className="designer-header-btn main-btn" onClick={() => saveDesign()}>
            <CheckIcon />
            Save
          </button>
        </div>
      </StyledDesignerHeader>
      <ResizePopover
        position="bottom-left"
        buttonRef={resizeBtnRef}
        classToAvoid="resize-btn"
        isOpen={showResizePopover}
        onClose={() => setShowResizePopover(false)}
      />
      <InputDialog
        isOpen={showTemplateInput}
        title={`Import template`}
        value={templateInput}
        inputProps={{
          maxLength: undefined
        }}
        confirmButtonText="Import"
        onConfirm={() => loadDesignFromChat(templateInput)}
        onChange={(value) => setTemplateInput(value)}
        onClose={() => setShowTemplateInput(false)}
      />
    </>
  )
}

export default DesignerHeader
