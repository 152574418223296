import { FC, useEffect, useState } from 'react'
import { random } from 'lodash'
import { StyledSkeleton } from '.'

type Props = {
  randHeight?: number[]
  styles?: string
}

const Skeleton: FC<Props> = ({ randHeight, styles }) => {
  const [height, setHeight] = useState<number>()

  useEffect(() => {
    if (!!randHeight?.length) {
      setHeight(random(randHeight[0], randHeight[1]))
    }
  }, [randHeight])

  return (
    <StyledSkeleton
      className="skeleton"
      height={randHeight ? randHeight[0] : undefined}
      styles={styles}
    />
  )
}

export default Skeleton
