import { FC } from 'react'
import { BellIcon, BoltIcon, CheckCircleIcon } from '@heroicons/react/20/solid'
import { DocumentTextIcon } from '@heroicons/react/24/solid'
import { setCreateMode, useAppDispatch, useAppSelector } from '../../../redux'
import {
  CreateEditorCaption,
  CreateEditorLabels,
  CreateEditorMedia,
  CreateEditorPostType,
  CreateEditorProfilePicker,
  CreateEditorSubConfig,
  InstagramPublishTypeConfig,
  InstagramReelAudioNameConfig,
  Tabs,
  TikTokInteractionAbilityConfig,
  TikTokPrivacyStatusConfig
} from '../..'
import { CoverPhotoConfig } from '../configs/CoverPhotoConfig'
import { StyledCreateEditor } from '.'

const CreateEditor: FC = () => {
  const dispatch = useAppDispatch()
  const { post, mode } = useAppSelector((state) => state.create)

  const getStatus = () => {
    if (!!post?.isUsed) {
      return (
        <>
          <CheckCircleIcon /> Published
        </>
      )
    }

    if (!post?.postDate) {
      return (
        <>
          <DocumentTextIcon /> Post Draft
        </>
      )
    }

    if (!post?.socialProfiles?.length) {
      return (
        <>
          <BellIcon /> Reminder Notification
        </>
      )
    }

    return (
      <>
        <BoltIcon /> Auto-Publish
      </>
    )
  }

  return (
    <StyledCreateEditor>
      <div className="create-editor-header">
        <Tabs
          tabs={[
            {
              label: 'Edit Post',
              isActive: mode === 'edit',
              onClick: () => dispatch(setCreateMode('edit'))
            }
            // {
            //   label: 'Preview',
            //   isActive: mode === 'preview',
            //   onClick: () => dispatch(setCreateMode('preview'))
            // }
          ]}
        />
        <span className="post-status">{getStatus()}</span>
      </div>
      <div className="editor scrollable">
        <CreateEditorProfilePicker />
        <CreateEditorMedia />
        <CreateEditorCaption />
        <CoverPhotoConfig />
        <InstagramPublishTypeConfig />
        <InstagramReelAudioNameConfig />
        <TikTokPrivacyStatusConfig />
        <TikTokInteractionAbilityConfig />
        <CreateEditorLabels />
      </div>
    </StyledCreateEditor>
  )
}

export default CreateEditor
