import { gql } from '@apollo/client'

export const affiliateEarningFindMany = gql`
  query AffiliateEarningFindMany($affiliate: String!) {
    affiliateEarningFindMany(filter: { affiliate: $affiliate }) {
      _id
      user
      affiliate
      amount
      item {
        _id
        cost
        type
      }
      trialStartDate
      userPaidDate
    }
  }
`
