import styled from 'styled-components'

type StyledProps = {
  isCollapsed?: boolean
}

const StyledSidebar = styled.div<StyledProps>`
  height: 100vh;
  width: 220px;
  border-right: 1px solid ${({ theme }) => theme.color.gray[300]};
  position: sticky;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr) auto;
  top: 0;
  background-color: ${({ theme }) => theme.color.background};
  z-index: ${({ theme }) => theme.elevation.fixed};

  .sidebar-header {
    padding: 0 10px;
    display: flex;
    align-items: center;
    height: 53px;
    overflow: hidden;
    transition: 0.2s;
    color: ${({ theme }) => theme.color.gray[800]};
    border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};

    svg,
    img {
      position: relative;
    }

    svg {
      height: 24px;
    }

    &:hover {
      color: ${({ theme }) => theme.color.brand};
    }
  }

  .sidebar-content {
    padding: 10px;
    display: grid;
    align-content: space-between;
    overflow: auto;

    .new-post-btn {
      width: 100%;
      margin-bottom: 12px;
      border-radius: 6px;
      text-align: center;
      padding: 0 12px;
      position: relative;
      font-weight: 500;
      height: 40px;
      color: white;
      transition: unset;
      justify-content: space-between;
      background: linear-gradient(180deg, #5e6cff 0%, #2f40f8 100%);
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.15), 0px 0px 0px 1px rgba(0, 0, 0, 0.05) inset,
        0px -2px 4px 0px rgba(0, 0, 0, 0.1) inset;

      .sparkles-icon {
        height: 20px;
      }

      .plus-icon {
        display: none;
      }

      &:hover {
        background: ${({ theme }) => theme.color.gray[800]};
        color: ${({ theme }) => theme.color.background};
      }
    }

    .quickstart-btn {
      padding: 10px 4px 10px 12px;
      width: 100%;
      box-sizing: border-box;
      margin: -2px 0 10px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-decoration: none;
      font-weight: 500;
      border-radius: 4px;
      background-color: ${({ theme }) => theme.color.brandLight};
      color: ${({ theme }) => theme.color.brand};

      svg,
      img {
        height: 22px;
        width: 22px;
        margin-right: 8px;
      }

      &.active,
      &[data-active='true'] {
        color: ${({ theme }) => theme.color.brand};
      }
    }

    .sidebar-more-btn {
      display: flex;
      box-sizing: content-box;
      align-items: center;
      text-decoration: none;
      user-select: none;
      padding: 6px;
      margin: -6px;
      border-radius: 6px;
      font-weight: 500;
      font-size: 17px;
      position: relative;
      background-color: transparent;
      color: ${({ theme }) => theme.color.gray[500]};
      transition: 0.2s background-color, 0.2s color;

      .more-icon {
        height: 24px;
        width: 24px;
        background-color: ${({ theme }) => theme.color.gray[200]};
        color: ${({ theme }) => theme.color.gray[500]};
        transition: 0.2s background-color, 0.2s color;
        margin-right: 8px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          height: 16px;
          position: relative;
          top: 1px;

          path {
            stroke-width: 2.7px;
          }
        }
      }

      &::-webkit-details-marker,
      &::marker {
        display: none;
        content: '';
      }

      &:hover {
        cursor: pointer;
        color: ${({ theme }) => theme.color.gray[800]};

        .more-icon {
          background-color: ${({ theme }) => theme.color.gray[300]};
          color: ${({ theme }) => theme.color.gray[800]};
        }
      }

      &[data-expanded='true'] {
        svg {
          transform: rotate(180deg);
          position: relative;
          top: -1px;
        }
      }
    }

    nav {
      display: grid;
      gap: 10px;

      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        padding: 6px;
        margin: -6px;
        border-radius: 6px;
        font-weight: 500;
        font-size: 17px;
        position: relative;
        color: ${({ theme }) => theme.color.gray[500]};
        transition: 0.2s background-color, 0.2s color;

        svg,
        img {
          height: 24px;
          width: 24px;
          margin-right: 8px;

          path {
            stroke-width: 2px;
          }
        }

        .new-flag {
          padding: 3px 6px;
          margin-left: 6px;
          font-size: 11px;
          font-weight: 600;
          letter-spacing: 0.05rem;
          border-radius: 50rem;
          color: white;
          background-color: ${({ theme }) => theme.color.brand};
        }

        &:hover {
          color: ${({ theme }) => theme.color.gray[800]};
        }

        &.active,
        &[data-active='true'] {
          color: ${({ theme }) => theme.color.brand};
        }
      }
    }
  }

  .sidebar-footer {
    padding: 0 10px;
    user-select: none;
    box-sizing: border-box;
    height: 55px;
    overflow: hidden;
    transition: background-color 0.2s;
    border-top: 1px solid ${({ theme }) => theme.color.gray[300]};

    .sidebar-footer-latch {
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .sidebar-user-details-container {
      display: flex;
      align-items: center;

      .avatar {
        margin-right: 10px;
      }

      span {
        display: block;
      }

      .sidebar-user-name {
        font-weight: 500;
        font-size: 15px;
        color: ${({ theme }) => theme.color.gray[800]};
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .sidebar-user-detail {
        font-size: 14px;
        color: ${({ theme }) => theme.color.gray[600]};
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    svg,
    img {
      height: 26px;
      width: 26px;
      margin: 0 -8px 0 4px;
      color: ${({ theme }) => theme.color.gray[400]};
      stroke-width: 1.6px;
    }

    &:hover {
      cursor: pointer;
      background-color: ${({ theme }) => theme.color.gray[100]};
    }
  }

  ${({ isCollapsed, theme }) =>
    isCollapsed &&
    `
    width: 54px;

    .sidebar-header {
      svg, img {
        flex-shrink: 0;
        left: 5px;

        path:last-child {
          display: none;
        }
      }
    }

    .sidebar-content {
      padding: 6px 6px 6px 6px;
      overflow: unset;

      .sidebar-content-group {
        .new-post-btn {
          font-size: 0;
          padding: 0;
          justify-content: center;
          margin-bottom: 10px;

          .sparkles-icon {
            display: none;
          }

          .plus-icon {
            display: flex;
            height: 24px;
            
            path {
              stroke-width: 2px;
            }
          }

          svg, img {
            margin: 0;
          }
        }

        .post-btn, .quickstart-btn, #sidebar-upgrade-cta {
          display: none;
        }

        .sidebar-more-btn {
          height: 32px;
          width: 42px;
          justify-content: center;
          font-size: 0;
          position: relative;

          .more-icon {
            margin: 0;
          }
        }

        nav {
          a {
            height: 32px;
            width: 42px;
            justify-content: center;
            font-size: 0;
            position: relative;

            .new-flag {
              display: none;
            }

            svg, img {
              margin: 0;
            }
          }
        }
      }
    }
  
  `}

  @media (max-width: ${({ theme }) => theme.breakpoint.s}px) {
    width: 100%;
    border-right: 0;
    bottom: 0;
    top: unset;
    position: fixed;
    height: fit-content;
    padding-bottom: env(safe-area-inset-bottom);
    display: flex;
    justify-content: space-between;
    order: 2;
    background-color: ${({ theme }) => theme.color.background};
    border-top: 1px solid ${({ theme }) => theme.color.gray[300]};

    .sidebar-header,
    .sidebar-footer {
      display: none;
    }

    .sidebar-content {
      display: flex;
      height: 40px;
      width: 100%;
      padding: 10px;

      .new-post-btn,
      .quickstart-btn {
        display: flex !important;
      }

      .sidebar-content-group {
        overflow: hidden;
        display: flex;
        justify-content: space-around;
        width: 100%;

        .new-post-btn {
          order: 3;
          height: 100%;
          width: 85px;
          font-size: 0;
          justify-content: center;
          border-radius: 50rem;
          margin: 0 8px;
          border: 2px solid ${({ theme }) => theme.color.brand};
          color: ${({ theme }) => theme.color.brand};
          background-color: ${({ theme }) => theme.color.background};

          &:not(.show-xs) {
            display: none !important;
          }

          svg,
          img {
            margin: 0;
          }
        }

        nav {
          display: contents;

          a {
            font-size: 0;
            display: none;
            margin: 0;
            width: fit-content;

            &:hover {
              background-color: transparent;
            }

            &:before,
            &:after {
              display: none;
            }
          }

          #my-posts-btn,
          #caption-templates-btn,
          #photos-stories-btn,
          #action-plans-btn {
            display: flex;
            justify-content: center;

            svg,
            img {
              height: 36px;
              width: 36px;
              margin: 0;

              path {
                stroke-width: 1.3px;
              }
            }
          }

          #my-posts-btn {
            order: 1;
          }

          #caption-templates-btn {
            order: 2;
          }

          #photos-stories-btn {
            order: 4;
          }

          #action-plans-btn {
            order: 5;
          }
        }

        &:last-child {
          display: none;
        }
      }
    }
  }
`

export default StyledSidebar
