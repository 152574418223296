import escapeHtml from 'escape-html'
import { Text } from 'slate'

type SerializeOptions = {
  noLinks?: boolean
}
export const serialize = (node: any, options?: SerializeOptions) => {
  if (Text.isText(node)) {
    const separateLines = node.text.split('\r\n')
    const text = separateLines.map((line) => escapeHtml(line)).join('<br/>')
    let returnText = text

    if ((node as any).underline) {
      returnText = `<u>${returnText}</u>`
    }
    if ((node as any).bold) {
      returnText = `<strong>${returnText}</strong>`
    }
    if ((node as any).italic) {
      returnText = `<em>${returnText}</em>`
    }
    if ((node as any).code) {
      returnText = `<code>${returnText}</code>`
    }
    if ((node as any).strikethrough) {
      returnText = `<del>${returnText}</del>`
    }
    return returnText
  }
  if (typeof node === 'string') {
    return escapeHtml(node)
  }

  const children = node.children.map((n: any) => serialize(n, options)).join('')
  switch (node.type) {
    case 'quote':
      return `<blockquote><p>${children}</p></blockquote>`
    case 'paragraph':
      return `<p>${children}</p>`
    case 'bold':
      return `<strong>${children}</strong>`
    case 'italic':
      return `<em>${children}</em>`
    case 'underline':
      return `<u>${children}</u>`
    case 'numbered-list':
      return `<ol>${children}</ol>`
    case 'bulleted-list':
      return `<ul>${children}</ul>`
    case 'list-item':
      return `<li>${children}</li>`
    case 'link':
      if (children) {
        if (options?.noLinks) return children
        return `<a href="${escapeHtml(node.url)}" target="_blank">${children}</a>`
      }
      return ''
    case 'image':
      return `<img src="${node.url}" alt="Image" />`
    default:
      return children
  }
}

/**
 * Converts slate rich text to plain text and escapes HTML
 */
export const serializePlainText = (node: any, shouldEscapeHtml = true) => {
  if (Text.isText(node)) {
    return node.text.split('\r\n').map((line) => line)
  }
  if (typeof node === 'string') {
    return node
  }
  return node.children.map((n: any) => serializePlainText(n, shouldEscapeHtml)).join('')
}

/**
 * Converts slate rich text to plain text but does NOT escape HTML
 */
export const getPlainText = (node: any) => {
  if (Text.isText(node)) {
    return node.text.split('\r\n')
  }
  if (typeof node === 'string') {
    return node
  }
  return node.children.map((n: any) => getPlainText(n)).join('')
}

export const parseAndSerialize = (html: any) => {
  try {
    const parsed = JSON.parse(html)
    return serialize({ children: parsed })
  } catch (err) {
    console.warn('found incorrect html')
  }
}
