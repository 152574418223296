import { FC, useEffect, useState } from 'react'
import { PencilIcon } from '@heroicons/react/20/solid'
import { setPlanPosts, updatePlanPost, useAppDispatch, useAppSelector } from '../../../redux'
import {
  ActionDialog,
  EditorCaption,
  EditorConnections,
  EditorMedia,
  EditorSchedule,
  InputDialog
} from '../..'
import { StyledPlanReviewStep } from '.'

const PlanReviewStep: FC = () => {
  const dispatch = useAppDispatch()
  const [showInputDialog, setShowInputDialog] = useState(false)
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const { post, posts } = useAppSelector((state) => state.plan)

  useEffect(() => {
    window.scrollTo({
      top: 0
    })
  }, [post])

  return (
    <>
      <StyledPlanReviewStep>
        <div className="width-controller">
          <div className="post-details-container">
            <div className="post-index">POST #{post + 1}</div>
            <div className="post-title-container">
              <span>Review </span>
              <button className="post-title-btn" onClick={() => setShowInputDialog(true)}>
                “<span className="post-title">{posts[post]?.title}</span>” <PencilIcon />
              </button>
            </div>
            <p>You can edit this information anytime.</p>
          </div>
          {!!posts[post]?.media?.length && <EditorMedia />}
          <EditorCaption />
          <EditorSchedule />
          <EditorConnections />
          {posts.length > 1 && (
            <button className="delete-btn" onClick={() => setShowConfirmationDialog(true)}>
              Delete Post
            </button>
          )}
        </div>
      </StyledPlanReviewStep>
      <ActionDialog
        title="Remove this post?"
        body={`Are you sure you want to remove "${posts[post]?.title}"?`}
        type="error"
        confirmButtonText="Remove"
        isOpen={showConfirmationDialog}
        onClose={() => setShowConfirmationDialog(false)}
        onConfirm={() => dispatch(setPlanPosts(posts.filter((p, index) => index !== post)))}
      />
      <InputDialog
        title="Rename this post"
        value={posts[post]?.title || ''}
        inputProps={{
          required: true
        }}
        confirmButtonText="Done"
        isOpen={showInputDialog}
        onClose={() => setShowInputDialog(false)}
        onChange={(value) =>
          dispatch(updatePlanPost({ _id: posts[post]?._id!, post: { title: value } }))
        }
        onConfirm={() => setShowInputDialog(false)}
      />
    </>
  )
}

export default PlanReviewStep
