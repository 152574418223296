import { FC } from 'react'
import {
  ArrowUpRightIcon,
  DocumentIcon,
  PhotoIcon,
  VideoCameraIcon
} from '@heroicons/react/24/outline'
import { ExtraType } from '../../../types'
import { linkToSizedImage } from '../../../utils'
import { Button } from '../..'
import { StyledActionPlanExtraItem } from '.'

type Props = {
  extra: ExtraType
  index: number
}

const ActionPlanExtraItem: FC<Props> = ({ extra, index }) => {
  const getExtraType = () => {
    switch (extra.contentType) {
      case 'image':
        return 'Image'
      case 'video':
        return 'Video'
      default:
        return 'File'
    }
  }

  const getExtraTypeIcon = () => {
    switch (extra.contentType) {
      case 'image':
        return <PhotoIcon />
      case 'video':
        return <VideoCameraIcon />
      default:
        return <DocumentIcon />
    }
  }

  const getExtraUrl = () => {
    switch (extra.contentType) {
      case 'image':
        return extra.image?.fileHttpLink || ''
      case 'video':
        return extra.videoLink || ''
      default:
        return extra.file?.fileHttpLink || ''
    }
  }

  return (
    <StyledActionPlanExtraItem target="_blank" rel="noopener noreferrer" href={getExtraUrl()}>
      <div className="extra-preview">
        <div className="extra-img">
          <img
            src={linkToSizedImage(extra.thumbnail?.fileHttpLink || extra.image?.fileHttpLink, {
              width: 500
            })}
            alt={extra.title || ''}
          />
        </div>
        <div className="extra-details">
          <span className="extra-title">{extra.title || `Extra #${index + 1}`}</span>
          <span className="extra-type">
            {getExtraTypeIcon()}
            {getExtraType()}
          </span>
        </div>
      </div>
      <Button variant="outline" iconPos="right">
        Open <ArrowUpRightIcon />
      </Button>
    </StyledActionPlanExtraItem>
  )
}

export default ActionPlanExtraItem
