import { FC } from 'react'
import { setCreateTab, useAppDispatch, useAppSelector } from '../../../redux'
import { CREATE_TABS } from '../../../utils'
import { StyledCreateBarSwitcher } from '.'

const CreateBarSwitcher: FC = () => {
  const dispatch = useAppDispatch()
  const { tab } = useAppSelector((state) => state.create)

  return (
    <StyledCreateBarSwitcher>
      {CREATE_TABS.map((tabItem) => {
        return (
          <button
            className="tab"
            role="tab"
            aria-selected={tabItem._id === tab}
            key={tabItem._id}
            onClick={() => dispatch(setCreateTab(tabItem._id))}
          >
            {tabItem.icon}
            {tabItem.label}
          </button>
        )
      })}
    </StyledCreateBarSwitcher>
  )
}

export default CreateBarSwitcher
