import styled, { keyframes } from 'styled-components'
import { theme } from '../../../utils/theme'

const pulseAnim = keyframes`
  0% {
    background: ${theme.color.gray[100]};
  }
  50% {
    background: ${theme.color.gray[200]};
  }
  100% {
    background: ${theme.color.gray[100]};
  }
`

const StyledSidebarPostButton = styled.div`
  background-color: ${({ theme }) => theme.color.brand};
  border-radius: 7px;
  padding: 4px 4px 0 4px;
  position: relative;
  margin-top: 10px;
  transition: 0.2s background-color, 0.2s color;

  .clear-btn {
    position: absolute;
    top: -6px;
    right: -6px;
    z-index: 2;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    box-shadow: 0 0 0 3px ${({ theme }) => theme.color.background};
  }

  .post-content {
    border-radius: 4px;
    background-color: ${({ theme }) => theme.color.background};
    display: grid;
    grid-template-columns: 60px minmax(0, 1fr);
    padding: 6px;
    column-gap: 6px;

    .post-content-img {
      padding-top: 100%;
      position: relative;
      border-radius: 4px;
      background-color: ${({ theme }) => theme.color.gray[200]};

      .post-indicators {
        position: absolute;
        left: 4px;
        top: 4px;
        display: flex;
        z-index: 2;

        .post-indicator {
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0 0 0 1px rgb(0, 0, 0, 0.1);
          background-color: ${({ theme }) => theme.color.background};
          color: ${({ theme }) => theme.color.gray[700]};
          padding: 0 4px;
          min-width: 22px;
          box-sizing: border-box;
          height: 22px;
          border-radius: 50px;
          font-size: 12px;
          font-weight: 600;

          svg {
            height: 14px;
            margin-right: 3px;
            color: ${({ theme }) => theme.color.gray[600]};
          }

          &:not(:last-child) {
            margin-right: 6px;
          }
        }
      }

      .photo-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 22px;
        color: ${({ theme }) => theme.color.gray[500]};
      }

      .post-content-img-loading {
        border-radius: 4px;
        z-index: 1;
        animation: ${pulseAnim} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
      }

      .media-preview {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
        user-select: none;
      }

      &::after {
        user-select: none;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        box-shadow: inset 0 0 0 1px rgb(0, 0, 0, 0.1);
        z-index: 1;
      }
    }

    .post-content-caption {
      font-size: 14px;
      height: fit-content;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      color: ${({ theme }) => theme.color.gray[700]};

      .empty-caption {
        color: ${({ theme }) => theme.color.gray[400]};
      }
    }
  }

  .continue-btn {
    width: 100%;
    justify-content: space-between;
    padding: 0 4px;
    background-color: transparent;
  }

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.color.brandDark};
  }
`

export default StyledSidebarPostButton
