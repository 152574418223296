import { gql } from '@apollo/client'

export const uploadFilesToCloud = gql`
  mutation UploadFilesToCloud($files: [CloudContentInput!]!, $parent: String) {
    uploadFilesToCloud(files: $files, parent: $parent) {
      _id
      name
      file {
        url
        path
        preview
        metadata
        dimensions {
          x
          y
        }
        type
        ext
        size
      }
      ref
      parent
      type
      createdAt
      updatedAt
    }
  }
`
