import { FC, useEffect } from 'react'
import { CircularProgressbar } from 'react-circular-progressbar'
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  PaintBrushIcon,
  PhotoIcon
} from '@heroicons/react/20/solid'
import { UploadDesignType, UploadType } from '../../../types'
import { useUploadDesign, useUploadMedia } from '../../../utils'
import { StyledUploadRow } from '.'

type Props = {
  file: UploadType
}

const UploadRow: FC<Props> = ({ file }) => {
  const { progress: mediaProgress, uploadMedia } = useUploadMedia()
  const { progress: designProgress, uploadDesign } = useUploadDesign(file as UploadDesignType)

  const getValue = () => {
    if (file.status === 'finished') {
      return 100
    }

    if (file.status === 'processing') {
      return 25
    }

    return mediaProgress || designProgress
  }

  useEffect(() => {
    if (file.status === 'finished') {
      return
    }

    if (file.type === 'design') {
      uploadDesign()
      return
    }

    uploadMedia(file)
  }, [file._id])

  return (
    <StyledUploadRow status={file.status}>
      <div className="file-details">
        {file.type === 'file' && <PhotoIcon />}
        {file.type === 'design' && <PaintBrushIcon />}
        <span>{file.raw.name}</span>
      </div>
      <div className="file-download-progress">
        {file.status === 'finished' && <CheckCircleIcon className="icon done" />}
        {file.status === 'error' && <ExclamationCircleIcon className="icon error" />}
        <CircularProgressbar strokeWidth={13} value={getValue()} />
      </div>
    </StyledUploadRow>
  )
}

export default UploadRow
