import { FC, FormEvent, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { toast } from 'react-hot-toast'
import { Link, useHistory, useLocation } from 'react-router-dom'
import firebase from 'firebase/app'
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid'
import { ReactComponent as SCLogoIcon } from '../../assets/sc-logo-icon.svg'
import { AuthLayout } from '../../components'
import { ROUTES, handleEnterToNextInput } from '../../utils'
import { StyledResetPasswordView } from '.'

const ResetPasswordView: FC = () => {
  const history = useHistory()
  const location = useLocation()
  const [OOB, setOOB] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConf, setPasswordConf] = useState('')
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const handleSubmit = async (e?: FormEvent) => {
    try {
      e?.preventDefault()
      setLoading(true)

      if (!password.trim() || !passwordConf.trim()) {
        return
      }

      if (password !== passwordConf) {
        throw 'Passwords do not match'
      }

      await firebase.auth().confirmPasswordReset(OOB, password)

      toast.success('Password successfully reset')
      history.push(ROUTES.auth.login)
    } catch (err) {
      console.error(err)
      toast.error((err as any)?.message || err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    const params = Object.fromEntries(urlParams)
    const { oobCode } = params

    if (!oobCode?.trim()) {
      history.push(ROUTES.auth.login)
      return
    }

    setOOB(oobCode)
  }, [location.search, location.state])

  return (
    <AuthLayout>
      <Helmet title="Reset Password | Social Curator" />
      <StyledResetPasswordView>
        <div className="auth-screen" data-screen="reset-password" data-active={true}>
          <div className="auth-header">
            <h1>Create New Password</h1>
          </div>
          <form autoComplete="on" onSubmit={(e) => handleSubmit(e)}>
            <div className="auth-inputs">
              <label htmlFor="password" className="input-container">
                <div className="input-label">Password</div>
                <input
                  id="password"
                  value={password}
                  required
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  enterKeyHint="next"
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={(e) => handleEnterToNextInput(e)}
                />
                <button
                  className="show-password-btn-small"
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <EyeSlashIcon /> : <EyeIcon />}
                </button>
              </label>
              <label htmlFor="password" className="input-container">
                <div className="input-label">Confirm Password</div>
                <input
                  id="confirm-password"
                  value={passwordConf}
                  required
                  type="password"
                  name="password"
                  enterKeyHint="go"
                  onChange={(e) => setPasswordConf(e.target.value)}
                  onKeyDown={(e) => e.key === 'Enter' && handleSubmit()}
                />
              </label>
            </div>
            <button type="submit" className="submit-btn" disabled={loading}>
              {!loading && 'Reset Password'}
              {loading && <SCLogoIcon className="logo-spinner" />}
            </button>
          </form>
          <div className="form-subtext">
            <Link to={ROUTES.auth.login}>Go back to login</Link>
          </div>
        </div>
      </StyledResetPasswordView>
    </AuthLayout>
  )
}

export default ResetPasswordView
