import { ForwardedRef, forwardRef } from 'react'
import { GiftIcon } from '@heroicons/react/24/outline'
import { ExtraType } from '../../../types'
import { ActionPlanExtraItem } from '..'
import { StyledActionPlanExtras } from '.'

type Props = {
  extras: ExtraType[]
}

const ActionPlanExtras = forwardRef(({ extras }: Props, ref: ForwardedRef<HTMLDivElement>) => {
  if (!extras.length) {
    return null
  }

  return (
    <StyledActionPlanExtras ref={ref}>
      <div className="action-plan-content-list-header">
        <GiftIcon />
        <span>Linked Extras</span>
      </div>
      <div className="action-plan-extras-list">
        {extras.map((extra, index) => {
          return <ActionPlanExtraItem extra={extra} index={index} key={extra._id} />
        })}
      </div>
    </StyledActionPlanExtras>
  )
})

export default ActionPlanExtras
