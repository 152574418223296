import { FC, useContext } from 'react'
import { Grid, SearchBar, SearchContext, SuggestionBar } from '@giphy/react-components'
import { useDesigner } from '../../../../../utils'
import { StyledDesignerStickersSearch } from '.'

const DesignerStickersSearch: FC = () => {
  const { fetchGifs, searchKey } = useContext(SearchContext)
  const { addImage } = useDesigner()

  return (
    <StyledDesignerStickersSearch>
      <div className="search-bar-container">
        <div className="search-bar">
          <SearchBar autoFocus />
        </div>
        <SuggestionBar />
      </div>
      <div className="search-results">
        <Grid
          key={searchKey}
          columns={2}
          width={280}
          hideAttribution={true}
          noLink
          fetchGifs={fetchGifs}
          onGifClick={(gif) => addImage(gif.images['original_still'].url)}
        />
      </div>
    </StyledDesignerStickersSearch>
  )
}

export default DesignerStickersSearch
