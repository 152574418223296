import styled from 'styled-components'

const StyledActionPlanContent = styled.div`
  margin: 20px 0;

  .action-plan-content-list-header {
    font-weight: 600;
    color: ${({ theme }) => theme.color.gray[800]};
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    text-transform: capitalize;

    svg {
      height: 22px;
      margin-right: 8px;

      path {
        stroke-width: 2px;
      }
    }
  }
`

export default StyledActionPlanContent
