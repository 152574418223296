import styled, { keyframes } from 'styled-components'

const startAnim = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const StyledDateInputSheet = styled.div`
  padding-bottom: var(--bottom-spacing);
  overflow: hidden;

  .bottom-container {
    padding: 20px;
    border-top: 1px solid ${({ theme }) => theme.color.gray[300]};

    .time-input-container {
      display: flex;
      position: relative;
      height: 50px;
      border-radius: 12px;
      background-color: ${({ theme }) => theme.color.gray[200]};

      .time-input {
        box-sizing: border-box;
        height: 100%;
        width: 100%;
        padding: 0 10px;
        background-color: transparent;
        text-align: left;
        padding-left: 40px;
        font-weight: 500;
        color: ${({ theme }) => theme.color.gray[800]};

        &::-webkit-date-and-time-value {
          text-align: left;
        }
      }

      .placeholder {
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        left: 40px;
        font-weight: 500;
        color: ${({ theme }) => theme.color.gray[600]};
      }

      svg {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        height: 24px;
        color: ${({ theme }) => theme.color.gray[600]};
      }
    }

    .action-row {
      margin-top: 20px;
      display: flex;

      button {
        height: 50px;
        padding: 0 16px;
        box-sizing: border-box;
        border-radius: 12px;
        font-weight: 500;
        color: ${({ theme }) => theme.color.gray[600]};
        background-color: ${({ theme }) => theme.color.gray[200]};

        &.submit-btn {
          width: 100%;
          margin-left: 10px;
          color: white;
          background-color: ${({ theme }) => theme.color.brand};
        }

        &:disabled {
          pointer-events: none;
          background-color: ${({ theme }) => theme.color.gray[100]};
          color: ${({ theme }) => theme.color.gray[400]};
        }
      }
    }
  }

  .cal-container {
    height: 418px;
  }

  .Cal__Container__root {
    width: 100% !important;
    animation: 0.5s ${startAnim} ease-in-out forwards;

    * {
      font-family: ${({ theme }) => theme.typeface.sans};
    }

    .Cal__Header__root {
      min-height: 0 !important;
      padding: 0 20px 10px 20px !important;
      background-color: ${({ theme }) => theme.color.background} !important;

      .Cal__Header__dateWrapper {
        color: ${({ theme }) => theme.color.gray[800]} !important;

        span {
          font-family: ${({ theme }) => theme.typeface.garnett} !important;
        }

        &.Cal__Header__day .Cal__Header__date {
          font-weight: 500;
          font-size: 32px;
          color: ${({ theme }) => theme.color.gray[800]} !important;
        }

        &.Cal__Header__year .Cal__Header__date {
          font-weight: 500;
          color: ${({ theme }) => theme.color.gray[500]} !important;
        }
      }
    }

    .Cal__Container__wrapper {
      .Cal__Weekdays__root {
        background-color: ${({ theme }) => theme.color.gray[100]} !important;
        color: ${({ theme }) => theme.color.gray[500]} !important;
        box-shadow: inset 0 -1px rgba(0, 0, 0, 0.1) !important;
      }

      .Cal__Container__listWrapper {
        background-color: ${({ theme }) => theme.color.background} !important;

        .Cal__Today__root {
          background-color: ${({ theme }) => theme.color.brand} !important;
          color: white !important;

          svg {
            display: none;
          }
        }

        .Cal__MonthList__root {
          background-color: ${({ theme }) => theme.color.background} !important;

          .Cal__Month__rows {
            background: ${({ theme }) => theme.color.background} !important;
          }

          .Cal__Month__row {
            &:first-child li {
              box-shadow: 0 -1px 0 ${({ theme }) => theme.color.gray[200]} !important;
            }

            &:nth-child(2) {
              box-shadow: 0 -1px 0 ${({ theme }) => theme.color.gray[200]} !important;
            }

            &.Cal__Month__partial:first-child li:first-child {
              box-shadow: 0px -1px 0 ${({ theme }) => theme.color.gray[200]},
                inset 1px 0 0 ${({ theme }) => theme.color.gray[200]} !important;

              .Cal__Day__month {
                font-weight: 600;
                color: ${({ theme }) => theme.color.gray[600]} !important;
              }
            }

            .Cal__Day__root {
              &.Cal__Day__selected {
                .Cal__Day__selection {
                  background-color: ${({ theme }) => theme.color.brandLight} !important;
                  color: ${({ theme }) => theme.color.brand} !important;

                  .Cal__Day__month {
                    font-weight: 600;
                    color: ${({ theme }) => theme.color.brand} !important;
                  }
                }
              }

              &.Cal__Day__today {
                color: ${({ theme }) => theme.color.brand} !important;
              }
            }
          }
        }
      }
    }
  }
`

export default StyledDateInputSheet
