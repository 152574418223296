import { FC, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { disablePageScroll, enablePageScroll } from 'scroll-lock'
import { useApolloClient } from '@apollo/client'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { ChatBubbleLeftIcon, SparklesIcon, Square3Stack3DIcon } from '@heroicons/react/24/solid'
import { storySetFindById } from '../../graphql'
import { clearContentToView, useAppDispatch } from '../../redux'
import { ROUTES } from '../../utils'
import { Button, CaptionListItem, PhotoGallery, RenderSlate } from '..'
import { StyledContentViewer } from '.'

type Props = {
  content?: any
  type?: 'story' | 'extra' | 'photo-set'
}

const ContentViewer: FC<Props> = ({ content, type }) => {
  const history = useHistory()
  const location = useLocation()
  const apollo = useApolloClient()
  const dispatch = useAppDispatch()
  const { _id }: any = useParams()
  const [contentData, setContentData] = useState<any>()

  const loadContentData = async () => {
    try {
      if (location.pathname.includes('story/')) {
        const { data } = await apollo.query({
          query: storySetFindById,
          variables: { _id }
        })

        setContentData(data.storySetFindById)
      }

      if (location.pathname.includes('photo/')) {
        const { data } = await apollo.query({
          query: storySetFindById,
          variables: { _id }
        })

        setContentData(data.storySetFindById)
      }
    } catch (err) {
      console.log(err)
      toast.error((err as any).message)
    }
  }

  const getRecords = () => {
    return (
      contentData?.records?.map((record: any) => ({
        _id: record._id,
        url: record?.imageUrl || '',
        canvaLink: content?.canvaLink || ''
      })) || []
    )
  }

  const onBack = () => {
    dispatch(clearContentToView())
    setContentData(undefined)
    history.push((location.state as any)?.backUrl || ROUTES.home, { scroll: 'none' })
  }

  useEffect(() => {
    if (!!content) {
      setContentData(content)
      return
    }

    loadContentData()
  }, [_id, content])

  useEffect(() => {
    if (!!contentData) {
      disablePageScroll()
    }

    return () => {
      enablePageScroll()
    }
  }, [contentData])

  if (!contentData && !_id) {
    return null
  }

  return (
    <StyledContentViewer>
      <div className="content-viewer-gallery">
        <Button iconPos="left" className="back-btn" onClick={() => onBack()}>
          <ChevronLeftIcon /> Back
        </Button>
        <PhotoGallery photos={getRecords()} />
      </div>
      <div className="content-viewer-right-bar">
        <div className="content-viewer-header">
          <span className="content-viewer-title">{contentData?.title}</span>
          <Button iconPos="right" className="back-btn" onClick={() => onBack()}>
            Back
            <ChevronRightIcon />
          </Button>
        </div>
        <div className="content-viewer-gallery">
          <PhotoGallery photos={getRecords()} />
        </div>
        <div className="content-viewer-blocks">
          {contentData?.description && (
            <div className="content-viewer-block">
              <div className="content-viewer-block-label">
                <div className="content-viewer-block-icon">
                  <Square3Stack3DIcon />
                </div>
                <span>How to use</span>
              </div>
              <div className="content-viewer-block-content">
                <RenderSlate html={contentData.description} />
              </div>
            </div>
          )}
          {contentData?.caption && (
            <div className="content-viewer-block">
              <div className="content-viewer-block-label">
                <div className="content-viewer-block-icon">
                  <ChatBubbleLeftIcon />
                </div>
                <span>Caption</span>
              </div>
              <div className="content-viewer-block-content">
                <CaptionListItem
                  caption={{
                    _id: '',
                    caption: contentData.caption,
                    captionPlainText: contentData.captionPlainText,
                    category: contentData.category,
                    issue: '',
                    issueTitle: '',
                    title: 'Related Caption',
                    userCaption: {
                      _id: '',
                      liked: false,
                      used: false,
                      user: ''
                    }
                  }}
                  inActionPlan
                />
              </div>
            </div>
          )}
          {contentData?.photoIdeas && (
            <div className="content-viewer-block">
              <div className="content-viewer-block-label">
                <div className="content-viewer-block-icon">
                  <SparklesIcon />
                </div>
                <span>Visual Ideas</span>
              </div>
              <div className="content-viewer-block-content">
                <RenderSlate html={contentData.photoIdeas} />
              </div>
            </div>
          )}
        </div>
      </div>
    </StyledContentViewer>
  )
}

export default ContentViewer
