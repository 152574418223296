import { FC, createRef, useState } from 'react'
import AutosizeInput from 'react-input-autosize'
import { DateTime } from 'luxon'
import { CheckCircleIcon as SolidCheckCircleIcon } from '@heroicons/react/20/solid'
import {
  ArrowDownTrayIcon,
  CalendarIcon,
  CheckCircleIcon,
  DocumentArrowDownIcon,
  DocumentDuplicateIcon,
  EllipsisVerticalIcon,
  PlusIcon,
  TrashIcon
} from '@heroicons/react/24/outline'
import { ReactComponent as DoubleCheckIcon } from '../../../assets/doublecheck-icon.svg'
import { updateCreatePost, useAppDispatch, useAppSelector } from '../../../redux'
import {
  conditionalStage,
  getRelativeString,
  theme,
  useChat,
  useCreate,
  usePost
} from '../../../utils'
import {
  ActionDialog,
  Button,
  ChatBotIcon,
  Hint,
  OptionsPopover,
  PublishDialog,
  RecentPostsPopover,
  SchedulePopover,
  Spinner
} from '../..'
import { StyledCreateHeader } from '.'

const CreateHeader: FC = () => {
  const scheduleBtnRef = createRef<HTMLButtonElement>()
  const optionsBtnRef = createRef<HTMLButtonElement>()
  const postsBtnRef = createRef<HTMLButtonElement>()
  const newPostBtnRef = createRef<HTMLButtonElement>()
  const titleInputRef = createRef<HTMLDivElement>()
  const dispatch = useAppDispatch()
  const { post, isSaved } = useAppSelector((state) => state.create)
  const { loading, tempTemplate } = useAppSelector((state) => state.chat)
  const { togglePostUsed, duplicatePost } = usePost()
  const { downloadAsPDF, downloadAsZip, deletePost, newPost } = useCreate()
  const { sendMessage } = useChat()
  const [showPublishDialog, setShowPublishDialog] = useState(false)
  const [showPostsPopover, setShowPostsPopover] = useState(false)
  const [showSchedulePopover, setShowSchedulePopover] = useState(false)
  const [showDeletionConfirmation, setShowDeletionConfirmation] = useState(false)
  const [showOptions, setShowOptions] = useState(false)

  return (
    <>
      <StyledCreateHeader>
        <div className="create-editor-details-container">
          <div className="create-editor-details">
            <div className="create-editor-breadcrumbs" ref={titleInputRef}>
              <button
                data-tooltip={!showPostsPopover ? 'Show recent posts' : undefined}
                data-tooltip-pos="bottom-start"
                data-active={showPostsPopover}
                className="post-switcher"
                onClick={() => setShowPostsPopover(!showPostsPopover)}
                ref={postsBtnRef}
              >
                All Posts
              </button>
              <span className="divider">/</span>
              <AutosizeInput
                value={post?.title || ''}
                className="post-title-input"
                placeholder="Untitled Post"
                id="post-editor-area-title-input"
                onChange={(e) => dispatch(updateCreatePost({ title: e.target.value.trimStart() }))}
              />
            </div>
            {!post?.title?.trim() && (
              <button
                className="create-editor-detail"
                disabled={!post?.captionText?.trim()}
                data-tooltip="Generate post title"
                onClick={() => sendMessage('Title my post', 'post-title')}
              >
                {tempTemplate === 'post-title' && loading && <Spinner />}
                {(tempTemplate !== 'post-title' || !loading) && <ChatBotIcon size={18} />}
              </button>
            )}
            <div className="create-editor-detail" data-tooltip={isSaved ? 'Saved' : 'Saving'}>
              {isSaved ? <DoubleCheckIcon /> : <Spinner />}
            </div>
          </div>
          {/* <div className="create-editor-icon" />
          <div className="create-editor-details">
            <AutosizeInput
              value={post?.title || ''}
              className="post-title-input"
              placeholder="Untitled Post"
              id="post-editor-area-title-input"
              onChange={(e) => dispatch(updateCreatePost({ title: e.target.value.trimStart() }))}
            />
            <div className="create-editor-stats">
              <span className="create-editor-status">{getStatus()}</span>
              <span className="create-editor-stat">
                {isSaved ? <DoubleCheckIcon /> : <Spinner />}
                Saved
              </span>
            </div>
          </div> */}
        </div>
        <div className="create-actions">
          <Button
            iconPos="left"
            className="schedule-btn outline-btn"
            onClick={() => setShowSchedulePopover(!showSchedulePopover)}
            ref={scheduleBtnRef}
          >
            <CalendarIcon />
            {!post?.postDate && 'Schedule'}
            {!!post?.postDate && getRelativeString(post?.postDate, true)}
          </Button>
          <Button className="publish-btn" onClick={() => setShowPublishDialog(true)}>
            Publish
          </Button>
          <Button
            className="options-btn icon-btn"
            data-active={showOptions}
            data-tooltip={!showOptions ? 'Additional post options' : undefined}
            data-tooltip-pos="bottom-end"
            isIcon
            onClick={() => setShowOptions(!showOptions)}
            ref={optionsBtnRef}
          >
            <EllipsisVerticalIcon />
          </Button>
          <Button
            className="icon-btn"
            data-tooltip="New post"
            data-tooltip-pos="bottom-end"
            isIcon
            onClick={() => newPost()}
            ref={newPostBtnRef}
          >
            <PlusIcon />
          </Button>
        </div>
      </StyledCreateHeader>
      <SchedulePopover
        classToAvoid="schedule-btn"
        buttonRef={scheduleBtnRef}
        isOpen={showSchedulePopover}
        onClose={() => setShowSchedulePopover(false)}
      />
      <OptionsPopover
        options={[
          {
            icon: post?.isUsed ? (
              <SolidCheckCircleIcon fill={theme.color.green[600]} />
            ) : (
              <CheckCircleIcon />
            ),
            label: `Mark as ${!!post?.isUsed ? 'unpublished' : 'published'}`,
            action: () =>
              dispatch(
                updateCreatePost({
                  isUsed: !post?.isUsed,
                  postDate: !!post?.isUsed ? undefined : DateTime.now().startOf('minute').toISO()
                })
              )
          },
          ...conditionalStage(!!post?._id, [
            {
              icon: <DocumentDuplicateIcon />,
              label: 'Duplicate post',
              action: () => duplicatePost(post!._id!, true)
            }
          ]),
          {
            icon: <DocumentArrowDownIcon />,
            label: 'Download as PDF',
            action: () => downloadAsPDF()
          },
          {
            icon: <ArrowDownTrayIcon />,
            label: 'Download as ZIP',
            action: () => downloadAsZip()
          },
          {
            icon: <TrashIcon />,
            label: 'Delete Post',
            action: () => setShowDeletionConfirmation(true)
          }
        ]}
        divsAfter={['Download as ZIP']}
        position="bottom-right"
        classToAvoid="options-btn"
        buttonRef={optionsBtnRef}
        isOpen={showOptions}
        onClose={() => setShowOptions(false)}
      />
      <ActionDialog
        isOpen={showDeletionConfirmation}
        type="error"
        title="Delete this post?"
        body={`Are you sure you want to delete "${post?.title || 'Untitled Post'}"?`}
        confirmButtonText="Delete Post"
        onConfirm={() => deletePost()}
        onClose={() => setShowDeletionConfirmation(false)}
      />
      {!!post?.captionText?.trim() && !!post?.media?.length && !showSchedulePopover && (
        <Hint
          _id="editor-schedule-btn"
          title="Schedule your post"
          body={`Once you're done, click the "Schedule" button and choose to auto-publish your post or receive a reminder to manually post it at a time you prefer.`}
          action={{
            label: 'Schedule Post',
            onClick: () => setShowSchedulePopover(true)
          }}
          elementRef={scheduleBtnRef}
          position="bottom-end"
        />
      )}
      {!!post?.captionText?.trim() &&
        !!post?.media?.length &&
        !!post.postDate &&
        !showSchedulePopover && (
          <Hint
            _id="editor-new-post-btn"
            title="Start a new post"
            body={`When you're ready, click the + button to start a new post.`}
            action={{
              label: 'New Post',
              onClick: () => newPost()
            }}
            elementRef={newPostBtnRef}
            position="bottom-end"
          />
        )}
      {/* {!!post?.title?.trim() && (
        <Hint
          _id="create-title-post"
          title="Title your post"
          body={`Click "Untitled Post" to title your post. Post titles help you organize your posts.`}
          action={{
            label: 'Title Post',
            onClick: () => document.getElementById('post-editor-area-title-input')?.focus()
          }}
          elementRef={titleInputRef}
          position="bottom-start"
        />
      )} */}
      <RecentPostsPopover
        classToAvoid="post-switcher"
        isOpen={showPostsPopover}
        buttonRef={postsBtnRef}
        onClose={() => setShowPostsPopover(false)}
      />
      <PublishDialog isOpen={showPublishDialog} onClose={() => setShowPublishDialog(false)} />
    </>
  )
}

export default CreateHeader
