import { gql } from '@apollo/client'

export const affiliateLinkClickCount = gql`
  query AffiliateLinkClickCount($affiliate: String!, $greaterThanDate: Date, $lessThanDate: Date) {
    affiliateLinkClickCount(
      affiliate: $affiliate
      greaterThanDate: $greaterThanDate
      lessThanDate: $lessThanDate
    )
  }
`
