import { ShepherdOptionsWithType } from 'react-shepherd'
import { waitForElementExists } from '../../../utils'
import { removeEmptySteps } from '..'

export const generateTour = (isMobile: boolean): ShepherdOptionsWithType[] => {
  const steps: ShepherdOptionsWithType[] = [
    {
      title: 'You can publish carousel posts using the Post Editor',
      text: `Use or upload up to 5 images to a single post.`,
      attachTo: {
        element: '[data-tour-id="post-editor-media"]',
        on: 'auto'
      },
      buttons: [
        {
          text: 'Learn more',
          type: 'next'
        },
        {
          text: 'Skip tour',
          type: 'cancel'
        }
      ]
    },
    {
      text: 'You can scroll through your attached images using the arrow buttons.',
      attachTo: {
        element: '[data-tour-id="post-editor-media-pagination-btn-next"]',
        on: 'auto'
      },
      canClickTarget: false,
      buttons: [
        {
          text: 'Continue (1/3)',
          type: 'next'
        },
        {
          text: 'Leave tour',
          type: 'cancel'
        }
      ]
    },
    {
      text:
        'Each one of these dots represent one of the attached images. Click a dot to view that image.',
      attachTo: {
        element: '[data-tour-id="post-editor-media-ellipsis"]',
        on: 'auto'
      },
      canClickTarget: false,
      buttons: [
        {
          text: 'Continue (2/3)',
          type: 'next'
        },
        {
          text: 'Leave tour',
          type: 'cancel'
        }
      ]
    },
    {
      text: 'You can reorder your attached images by clicking this button.',
      attachTo: {
        element: '[data-tour-id="post-editor-reorder-btn"]',
        on: 'auto'
      },
      canClickTarget: false,
      buttons: [
        {
          text: 'Got it',
          type: 'next'
        }
      ]
    }
  ]
  return removeEmptySteps(steps as any)
}
