import styled from 'styled-components'

const StyledUploadSheet = styled.div`
  .header {
    padding: 16px;
    margin-bottom: 12px;
    color: ${({ theme }) => theme.color.gray[800]};
    font-weight: 800;
    font-size: 17px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${({ theme }) => theme.color.gray[200]};

    button {
      height: 36px;
      width: 36px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ theme }) => theme.color.gray[600]};
      background-color: ${({ theme }) => theme.color.gray[100]};

      svg {
        height: 24px;
        stroke-width: 2px;
      }

      &:disabled {
        color: ${({ theme }) => theme.color.gray[400]};
      }
    }
  }

  .button-bar {
    padding: 16px 16px calc(var(--bottom-spacing) + 16px) 16px;

    button {
      width: 100%;
      height: 52px;
      border-radius: 8px;
      font-weight: 500;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(
        ${({ theme }) => theme.color.brand},
        ${({ theme }) => theme.color.brandDark}
      );
      font-family: ${({ theme }) => theme.typeface.garnett};
      position: relative;
      z-index: 1;

      svg {
        fill: rgb(0, 0, 0, 0.3) !important;
      }

      &:disabled {
        background: ${({ theme }) => theme.color.gray[200]};
      }
    }
  }
`

export default StyledUploadSheet
