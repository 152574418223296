import { FC, useContext } from 'react'
import { SocialProfileListItem } from '../../../mobile/components'
import {
  setShowConnectionsModal,
  togglePlanPostConnection,
  useAppDispatch,
  useAppSelector
} from '../../../redux'
import { socialProfileByStatus, userStore } from '../../../utils'
import { EditorBlock } from '..'
import { StyledEditorConnections } from '.'

const EditorConnections: FC = () => {
  const dispatch = useAppDispatch()
  const { user } = useContext(userStore)
  const { post, posts } = useAppSelector((state) => state.plan)

  return (
    <EditorBlock title={user?.socialProfiles?.length ? 'Auto-Publish to...' : undefined}>
      <StyledEditorConnections>
        {!user?.socialProfiles?.length && (
          <div className="empty-state">
            <span className="empty-state-title">Connect a profile to start auto-publishing</span>
            <span className="empty-state-description">
              Without attaching a profile, you’ll receive a notification when it’s time to post
            </span>
          </div>
        )}
        {!!user?.socialProfiles?.length && (
          <div className="connection-list">
            {socialProfileByStatus(['active', 'expired'], user?.socialProfiles || []).map(
              (socialProfile) => {
                return (
                  <SocialProfileListItem
                    profile={socialProfile}
                    isSelected={posts[post]?.socialProfiles?.includes(socialProfile._id || '')}
                    onSelect={() =>
                      dispatch(
                        togglePlanPostConnection({
                          _id: posts[post]?._id!,
                          connection: socialProfile._id || ''
                        })
                      )
                    }
                    key={socialProfile._id}
                  />
                )
              }
            )}
          </div>
        )}
        <button className="manage-btn" onClick={() => dispatch(setShowConnectionsModal(true))}>
          Manage connections
        </button>
      </StyledEditorConnections>
    </EditorBlock>
  )
}

export default EditorConnections
