import styled from 'styled-components'

const StyledPhotosFilters = styled.div`
  padding: 0 14px 14px 14px;
  display: flex;
  align-items: center;

  .input-container {
    height: 42px;
    display: flex;
    align-items: center;
    position: relative;
    background-color: ${({ theme }) => theme.color.gray[100]};
    color: ${({ theme }) => theme.color.gray[800]};
    border-radius: 8px;
    width: 100%;

    input {
      width: 100%;
      background-color: transparent;
      padding: 0 10px 0 40px;
      height: 100%;
      box-sizing: border-box;
    }

    svg {
      height: 28px;
      position: absolute;
      left: 6px;
      color: ${({ theme }) => theme.color.gray[400]};
    }
  }

  .filters-btn {
    height: 42px;
    width: 42px;
    margin-left: 10px;
    flex-shrink: 0;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.color.gray[500]};
    background-color: ${({ theme }) => theme.color.background};
    border: 1px solid ${({ theme }) => theme.color.gray[200]};

    svg {
      height: 28px;
    }
  }
`

export default StyledPhotosFilters
