import styled from 'styled-components'

const StyledStreakWeek = styled.div`
  display: flex;
  align-items: center;
  margin: 26px auto 6px auto;
  width: fit-content;

  .streak-day {
    display: grid;
    grid-gap: 6px;
    justify-items: center;
    position: relative;
    padding: 8px;
    margin: -8px;
    border-radius: 10px;

    .streak-day-label {
      font-weight: 500;
      font-size: 14px;
      color: ${({ theme }) => theme.color.gray[600]};
    }

    .streak-day-check {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      display: flex;
      background-color: ${({ theme }) => theme.color.gray[200]};

      svg {
        display: none;
        height: 24px;
        stroke-width: 2px;
      }
    }

    &:not(:last-child) {
      margin-right: 6px;
    }

    &.today {
      box-shadow: inset 0 0 0 1.75px ${({ theme }) => theme.color.orange[400]};
    }

    &.completed {
      .streak-day-label {
        font-weight: 600;
        color: ${({ theme }) => theme.color.orange[500]};
      }

      .streak-day-check {
        color: ${({ theme }) => theme.color.background};
        background-color: ${({ theme }) => theme.color.orange[500]};

        svg {
          display: block;
        }
      }
    }
  }
`

export default StyledStreakWeek
