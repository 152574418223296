import { FC, createRef, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { PhotoType } from '../../../../types'
import { animateAddItemToPost, usePhoto } from '../../../../utils'
import { ContentSheet } from '../..'
import { StyledPhotoListItem } from '.'

type Props = {
  photo: PhotoType
}

const PhotoListItem: FC<Props> = ({ photo }) => {
  const photoRef = createRef<HTMLDivElement>()
  const location = useLocation()
  const [height, setHeight] = useState<number>()
  const [showOptions, setShowOptions] = useState(false)
  const { addPhotoToPost, downloadPhoto, togglePhotoFavorited } = usePhoto()

  const handleUse = () => {
    const added = addPhotoToPost(photo)

    if (!added) {
      return
    }

    animateAddItemToPost(photoRef)
  }

  const getCalculatedHeight = () => {
    const PADDING = 4
    const column = document.querySelector('.masonry-grid-column')
    const columnWidth = column?.getBoundingClientRect().width
    const photoHeight = photo.file.imgSize?.height
    const photoWidth = photo.file.imgSize?.width

    if (!photoHeight || !photoWidth || !columnWidth) {
      return undefined
    }

    const ratio = (columnWidth - PADDING) / photoWidth

    return photoHeight * ratio
  }

  useEffect(() => {
    setTimeout(() => {
      setHeight(getCalculatedHeight())
    }, 100)
  }, [location.pathname])

  return (
    <>
      <StyledPhotoListItem ref={photoRef} onClick={() => setShowOptions(true)}>
        <img height={height} src={photo.file.fileHttpLink || ''} alt="" />
      </StyledPhotoListItem>
      <ContentSheet
        content={photo}
        type="photo"
        isOpen={showOptions}
        onClose={() => setShowOptions(false)}
      />
    </>
  )
}

export default PhotoListItem
