import { gql } from '@apollo/client'

export const chatGetMessages = gql`
  query ChatGetMessages($conversation: String!) {
    chatGetMessages(conversation: $conversation) {
      _id
      content
      prompt
      type
      conversation
      status
      template
      alt
      metadata
      createdAt
      ref
    }
  }
`
