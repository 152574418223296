import { gql } from '@apollo/client'

export const storySetUpsertById = gql`
  mutation StorySetUpsertById(
    $_id: String!
    $description: String
    $index: Float
    $issue: String
    $thumbnail: String
    $title: String
    $canvaLink: String
  ) {
    storySetUpsertById(
      _id: $_id
      record: {
        description: $description
        index: $index
        issue: $issue
        thumbnail: $thumbnail
        title: $title
        canvaLink: $canvaLink
      }
    ) {
      _id
    }
  }
`
