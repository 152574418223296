import { FC, RefObject, useEffect, useState } from 'react'
import { throttle } from 'lodash'
import {
  ChatBubbleBottomCenterTextIcon,
  GiftIcon,
  PhotoIcon,
  RectangleStackIcon
} from '@heroicons/react/24/outline'
import { conditionalStage } from '../../../utils'
import { Tabs } from '../..'
import { StyledActionPlanContentHeader } from '.'

type Props = {
  hasStories: boolean
  hasPhotoSets: boolean
  hasCaptions: boolean
  hasExtras: boolean
  storiesRef: RefObject<HTMLDivElement>
  photosRef: RefObject<HTMLDivElement>
  captionsRef: RefObject<HTMLDivElement>
  extrasRef: RefObject<HTMLDivElement>
}

const ActionPlanContentHeader: FC<Props> = ({
  hasStories,
  hasPhotoSets,
  hasCaptions,
  hasExtras,
  storiesRef,
  photosRef,
  captionsRef,
  extrasRef
}) => {
  const SCROLL_OFFSET = 70
  const [isCollapsed, setIsCollapsed] = useState(true)
  const [activeTab, setActiveTab] = useState<'stories' | 'photos' | 'captions' | 'extras'>()

  const handleScroll = throttle(() => {
    const stories = storiesRef.current
    const photos = photosRef.current
    const captions = captionsRef.current
    const extras = extrasRef.current

    const storiesTop = stories?.offsetTop || 0
    const photosTop = photos?.offsetTop || 0
    const captionsTop = captions?.offsetTop || 0
    const extrasTop = extras?.offsetTop || 0
    const scrollPos = window.scrollY + SCROLL_OFFSET + 2

    if (hasExtras && extrasTop < scrollPos) {
      setIsCollapsed(false)
      setActiveTab('extras')
      return
    }

    if (hasCaptions && captionsTop < scrollPos) {
      setIsCollapsed(false)
      setActiveTab('captions')
      return
    }

    if (hasPhotoSets && photosTop < scrollPos) {
      setIsCollapsed(false)
      setActiveTab('photos')
      return
    }

    if (hasStories && storiesTop < scrollPos) {
      setIsCollapsed(false)
      setActiveTab('stories')
      return
    }

    setIsCollapsed(true)
  }, 10)

  const scrollTo = (tab: 'stories' | 'photos' | 'captions' | 'extras') => {
    switch (tab) {
      case 'stories':
        return window.scrollTo({
          top: (storiesRef.current?.offsetTop || 0) - SCROLL_OFFSET,
          behavior: 'smooth'
        })
      case 'photos':
        return window.scrollTo({
          top: (photosRef.current?.offsetTop || 0) - SCROLL_OFFSET,
          behavior: 'smooth'
        })
      case 'captions':
        return window.scrollTo({
          top: (captionsRef.current?.offsetTop || 0) - SCROLL_OFFSET,
          behavior: 'smooth'
        })
      case 'extras':
        return window.scrollTo({
          top: (extrasRef.current?.offsetTop || 0) - SCROLL_OFFSET,
          behavior: 'smooth'
        })
    }
  }

  useEffect(() => {
    handleScroll()
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [storiesRef, photosRef, captionsRef, extrasRef])

  return (
    <StyledActionPlanContentHeader isCollapsed={isCollapsed}>
      <Tabs
        tabs={[
          ...conditionalStage(hasStories, [
            {
              icon: <RectangleStackIcon style={{ transform: 'rotate(90deg)' }} />,
              label: 'Stories',
              isActive: activeTab === 'stories',
              onClick: () => scrollTo('stories')
            }
          ]),
          ...conditionalStage(hasPhotoSets, [
            {
              icon: <PhotoIcon />,
              label: 'Photos',
              isActive: activeTab === 'photos',
              onClick: () => scrollTo('photos')
            }
          ]),
          ...conditionalStage(hasCaptions, [
            {
              icon: <ChatBubbleBottomCenterTextIcon />,
              label: 'Captions',
              isActive: activeTab === 'captions',
              onClick: () => scrollTo('captions')
            }
          ]),
          ...conditionalStage(hasExtras, [
            {
              icon: <GiftIcon />,
              label: 'Extras',
              isActive: activeTab === 'extras',
              onClick: () => scrollTo('extras')
            }
          ])
        ]}
      />
    </StyledActionPlanContentHeader>
  )
}

export default ActionPlanContentHeader
