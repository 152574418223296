import styled, { keyframes } from 'styled-components'

const pulseAnimation = (lightGray: string, gray: string) => keyframes`
  0% {
    background-color: ${lightGray};
  }
  50% {
    background-color: ${gray};
  }
  100% {
    background-color: ${lightGray};
  }
`

const StyledPostList = styled.div`
  overflow-y: auto;
  height: 100%;

  .empty-state {
    padding: 60px 0;
  }

  .skeleton {
    margin: 14px;
    border-radius: 12px;
    height: 200px;
    background-color: ${({ theme }) => theme.color.gray[100]};
    animation: ${({ theme }) => pulseAnimation(theme.color.gray[100], theme.color.gray[200])} 2s
      cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
`

export default StyledPostList
