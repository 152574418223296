import { FC, useEffect } from 'react'
import { toast } from 'react-hot-toast'
import InfiniteScroll from 'react-infinite-scroll-component'
import { times } from 'lodash'
import { useApolloClient } from '@apollo/client'
import { DragOverlay, useDndContext } from '@dnd-kit/core'
import { searchUserDraftsQuery } from '../../../graphql'
import {
  changePostsPage,
  changePostsTotalDocs,
  clearPosts,
  setPosts,
  useAppDispatch,
  useAppSelector
} from '../../../redux'
import { isMobile } from '../../../utils'
import { CalendarPostListItem, Skeleton, Tabs } from '../..'
import { StyledCalendarPostList } from '.'

const CalendarPostList: FC = () => {
  const { showPostList, view } = useAppSelector((state) => state.calendar)
  const apollo = useApolloClient()
  const dispatch = useAppDispatch()
  const { active } = useDndContext()
  const {
    posts,
    loading,
    query,
    sort,
    page,
    onlyLiked,
    filters,
    totalDocs,
    profiles,
    layout,
    onlyUsed,
    onlyScheduled
  } = useAppSelector((state) => state.posts)

  const loadPosts = async () => {
    try {
      const { data } = await apollo.query({
        fetchPolicy: 'no-cache',
        query: searchUserDraftsQuery,
        variables: {
          page,
          search: query,
          categories: filters,
          liked: onlyLiked,
          hideUsed: onlyUsed,
          status: 'draft',
          profiles,
          onlyScheduled,
          sort,
          items: 50
        }
      })

      const postList = data?.searchUserDrafts?.docs || []
      dispatch(setPosts([...posts, ...postList]))
      dispatch(changePostsTotalDocs(data?.searchUserDrafts?.totalDocs || 0))
    } catch (err) {
      console.log(err)
      toast.error((err as any).message)
    }
  }

  useEffect(() => {
    if (loading) {
      loadPosts()
    }
  }, [loading])

  useEffect(() => {
    loadPosts()
    dispatch(clearPosts())

    return () => {
      dispatch(clearPosts())
    }
  }, [])

  if (isMobile()) {
    return null
  }

  return (
    <>
      <StyledCalendarPostList showPostList={showPostList}>
        <div className="post-list-container">
          <div className="post-list-header">
            <Tabs
              tabs={[
                {
                  label: 'Drafts',
                  isActive: true,
                  onClick: () => {}
                }
                // {
                //   label: 'Favorites',
                //   onClick: () => {}
                // },
                // {
                //   label: 'All Posts',
                //   onClick: () => {}
                // }
              ]}
            />
          </div>
          <div className="post-list" id="calendar-post-list-grid">
            {!posts.length && loading && (
              <div className="post-list-grid">
                {times(18, (index) => {
                  return <Skeleton key={index} />
                })}
              </div>
            )}
            <InfiniteScroll
              scrollThreshold={0.7}
              dataLength={posts.length}
              next={() => dispatch(changePostsPage(page + 1))}
              hasMore={posts.length < totalDocs}
              loader={null}
              className="infinite-scroll"
              scrollableTarget="calendar-post-list-grid"
            >
              <div className="post-list-grid">
                {posts
                  .filter((post) => !post.postDate)
                  .map((post) => {
                    return <CalendarPostListItem post={post} key={post._id} />
                  })}
              </div>
            </InfiniteScroll>
          </div>
        </div>
      </StyledCalendarPostList>
      {active && !!active?.data?.current?.isSidebarItem && (
        <DragOverlay>
          <CalendarPostListItem post={active?.data?.current?.post} isOverlay />
        </DragOverlay>
      )}
    </>
  )
}

export default CalendarPostList
