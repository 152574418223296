import styled from 'styled-components'

const StyledDesignerStickersSearch = styled.div`
  .search-bar-container {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #252627;

    .search-bar {
      padding: 20px 20px 10px 20px;
    }

    .giphy-suggestion-bar {
      padding: 0 20px;
      user-select: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .search-results {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;

    .giphy-gif {
      cursor: pointer;

      img {
        background: #313233 !important;
        transition: 0.2s;
      }

      &:hover {
        img {
          background: #444547 !important;
        }
      }
    }
  }
`

export default StyledDesignerStickersSearch
