import styled from 'styled-components'

const StyledNewHashtagFolderDialog = styled.div`
  padding: 10px 28px 0 28px;
  box-sizing: border-box;
  position: relative;
  width: 100vw;
  user-select: none;

  form {
    display: grid;
    grid-gap: 16px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    padding: 18px;
    max-width: 300px;
  }
`

export default StyledNewHashtagFolderDialog
