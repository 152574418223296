import { ForwardedRef, forwardRef } from 'react'
import { RectangleStackIcon } from '@heroicons/react/24/outline'
import { StorySetType } from '../../../types'
import { StoryListItem } from '../..'
import { StyledActionPlanStories } from '.'

type Props = {
  stories: StorySetType[]
}

const ActionPlanStories = forwardRef(({ stories }: Props, ref: ForwardedRef<HTMLDivElement>) => {
  if (!stories?.length) {
    return null
  }

  return (
    <StyledActionPlanStories ref={ref}>
      <div className="action-plan-content-list-header">
        <RectangleStackIcon style={{ transform: 'rotate(90deg)' }} />
        <span>Recommended Stories</span>
      </div>
      <div className="action-plan-stories-list">
        {stories.map((story) => {
          return <StoryListItem story={story} key={story._id} />
        })}
      </div>
    </StyledActionPlanStories>
  )
})

export default ActionPlanStories
