import styled from 'styled-components'
import { pulseAnimation } from '../../../utils'

const StyledCloudUploadItem_Skeleton = styled.div`
  height: 50px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  gap: 10px;

  .item-details-container {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 500px;
    flex-shrink: 0;
    margin-right: 20px;

    .item-preview {
      height: 50px;
      width: 75px;
      flex-shrink: 0;
      border-radius: 5px;
      margin-right: 12px;
      background-color: ${({ theme }) => theme.color.gray[100]};
      animation: ${({ theme }) => pulseAnimation(theme.color.gray[100], theme.color.gray[200])} 2s
        cubic-bezier(0.4, 0, 0.6, 1) infinite;
    }

    .item-details {
      .item-name {
        border-radius: 5px;
        display: block;
        background-color: ${({ theme }) => theme.color.gray[100]};
        animation: ${({ theme }) => pulseAnimation(theme.color.gray[100], theme.color.gray[200])} 2s
          cubic-bezier(0.4, 0, 0.6, 1) infinite;
        height: 17.5px;
        width: 100px;
      }

      .item-stats {
        display: flex;
        align-items: center;

        span {
          display: block;
          margin-top: 2px;
          height: 18.5px;
          width: 75px;
          border-radius: 5px;
          background-color: ${({ theme }) => theme.color.gray[100]};
          animation: ${({ theme }) => pulseAnimation(theme.color.gray[100], theme.color.gray[200])}
            2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
        }
      }
    }
  }
`

export default StyledCloudUploadItem_Skeleton
