import { FC } from 'react'
import {
  DesignerAlignmentTool,
  DesignerBoldTool,
  DesignerCaseTool,
  DesignerColorTool,
  DesignerDeleteTool,
  DesignerDuplicateTool,
  DesignerFontFamilyTool,
  DesignerFontSizeTool,
  DesignerItalicTool,
  DesignerPositionTool,
  DesignerSpacingTool,
  DesignerTransparencyTool,
  DesignerUnderlineTool
} from '..'
import { DesignerToolbarRow } from '.'

const DesignerTextToolbar: FC = () => {
  return (
    <DesignerToolbarRow>
      <DesignerColorTool />
      <DesignerFontFamilyTool />
      <DesignerFontSizeTool />
      <DesignerBoldTool />
      <DesignerItalicTool />
      <DesignerUnderlineTool />
      <DesignerCaseTool />
      <hr />
      <DesignerAlignmentTool />
      <DesignerSpacingTool />
      <hr />
      <DesignerPositionTool />
      <hr />
      <DesignerTransparencyTool />
      <DesignerDuplicateTool />
      <DesignerDeleteTool />
      <hr />
    </DesignerToolbarRow>
  )
}

export default DesignerTextToolbar
