import { FC, createRef } from 'react'
import Draggable from 'react-draggable'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { setShowChat, useAppDispatch, useAppSelector } from '../../../redux'
import { CreateBarChat } from '..'
import { StyledCreatePopup } from '.'

const CreatePopup: FC = () => {
  const popupRef = createRef<HTMLDivElement>()
  const dispatch = useAppDispatch()
  const { sidebarCollapsed } = useAppSelector((state) => state.state)

  return (
    <Draggable
      bounds={{
        left: sidebarCollapsed ? 54 : 220,
        top: 36,
        bottom: window.innerHeight - (popupRef.current?.clientHeight || 0),
        right: document.body.clientWidth - 420
      }}
      handle="[data-chat-handle]"
      allowAnyClick
      defaultPosition={{ x: (sidebarCollapsed ? 54 : 220) + 20, y: 36 + 20 }}
    >
      <StyledCreatePopup id="chat-popup" ref={popupRef}>
        <div className="handle" data-chat-handle>
          <span className="title">DOTTIE</span>
          <hr />
          <button className="close-btn" onClick={() => dispatch(setShowChat(false))}>
            <XMarkIcon />
          </button>
        </div>
        <CreateBarChat />
      </StyledCreatePopup>
    </Draggable>
  )
}

export default CreatePopup
