import { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import {
  ChevronLeftIcon,
  DocumentArrowDownIcon,
  DocumentIcon,
  FolderIcon
} from '@heroicons/react/24/outline'
import {
  ActionPlanContent,
  ActionPlanPdf,
  ActionPlanVideo,
  Button,
  PageLayout,
  ReadMore,
  RenderSlate,
  Tabs
} from '../../components'
import { getIssueBySlugQuery } from '../../graphql'
import { ActionPlanType } from '../../types'
import { conditionalStage, isMobile, ROUTES, useSegment } from '../../utils'
import { StyledActionPlanView } from '.'

const ActionPlanView: FC = () => {
  const { slug }: any = useParams()
  const { track } = useSegment()
  const [actionPlan, setActionPlan] = useState<ActionPlanType | null>()
  const [tab, setTab] = useState<'action-plan' | 'content'>('action-plan')

  const { data } = useQuery(getIssueBySlugQuery, {
    fetchPolicy: 'no-cache',
    variables: { slug }
  })

  const sendUserToLogin = () => {
    // history.push({
    //   pathname: ROUTES.errors.notLoggedIn,
    //   state: { backUrl: location.pathname }
    // })
  }

  useEffect(() => {
    if (!data) {
      return
    }

    // if (!user || user.stripeSubscriptionStatus !== 'Active') {
    //   sendUserToLogin()
    //   return
    // }

    // if (!data.issueFindOne || data.issueFindOne.status !== 'published') {
    //   history.push(ROUTES.errors.notFound)
    //   return
    // }

    setActionPlan(data.issueFindOne)
    setTab(!!data.issueFindOne.actionPlanPdf ? 'action-plan' : 'content')
  }, [slug, data])

  if (!actionPlan) {
    return null
  }

  return (
    <StyledActionPlanView>
      <Helmet title={`${actionPlan.title} | Social Curator`} />
      {actionPlan?.headerImg?.fileHttpLink && (
        <div className="action-plan-heading">
          <img src={actionPlan.headerImg.fileHttpLink} alt="" />
        </div>
      )}
      <PageLayout>
        <div className="action-plan-top">
          <div className="action-plan-header">
            <Link to={isMobile() ? ROUTES.mobile.learn : ROUTES.actionPlans}>
              <Button variant="outline" size="sm" iconPos="left">
                <ChevronLeftIcon /> Back
              </Button>
            </Link>
            {actionPlan?.actionPlanPdf?.fileHttpLink && (
              <a
                href={actionPlan.actionPlanPdf.fileHttpLink}
                target="_blank"
                onClick={() => track('Downloaded Action Plan PDF', { actionPlan })}
              >
                <Button variant="outline" size="sm" iconPos="left">
                  <DocumentArrowDownIcon /> Download Action Plan
                </Button>
              </a>
            )}
          </div>
          <ActionPlanVideo actionPlan={actionPlan} />
          <div className="action-plan-details-container">
            <div className="action-plan-details">
              <h1>{actionPlan.title}</h1>
              <ReadMore>
                <RenderSlate html={actionPlan.about} className="action-plan-description" />
              </ReadMore>
            </div>
          </div>
        </div>
      </PageLayout>
      <Tabs
        tabs={[
          ...conditionalStage(!!actionPlan.actionPlanPdf, [
            {
              icon: <DocumentIcon />,
              label: 'Action Plan PDF',
              isActive: tab === 'action-plan',
              onClick: () => setTab('action-plan')
            }
          ]),
          {
            icon: <FolderIcon />,
            label: 'Recommended Content',
            isActive: tab === 'content',
            onClick: () => setTab('content')
          }
        ]}
      />
      <PageLayout>
        {tab === 'action-plan' && <ActionPlanPdf actionPlan={actionPlan} />}
        {tab === 'content' && <ActionPlanContent actionPlan={actionPlan} />}
      </PageLayout>
    </StyledActionPlanView>
  )
}

export default ActionPlanView
