import { FC, useContext, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useReward } from 'react-rewards'
import { Redirect } from 'react-router-dom'
import firebase from 'firebase/app'
import { GiftIcon } from '@heroicons/react/24/solid'
import { Button, Spinner } from '../../components'
import { ROUTES, userStore } from '../../utils'
import { StyledClaimTrialView } from '.'

const ClaimTrialView: FC = () => {
  const startSubscription = firebase.functions().httpsCallable('startSubscription')
  const [loading, setLoading] = useState(false)
  const { user, refresh } = useContext(userStore)
  const { reward } = useReward('confetti', 'confetti', {
    angle: -90,
    startVelocity: 18,
    spread: 220,
    elementCount: 150
  })

  const claimTrial = async () => {
    try {
      setLoading(true)
      await startSubscription({
        subscriptionInterval: 'Trial',
        price: 0,
        couponID: undefined,
        isChallenge: false,
        isNew: false,
        affiliate: undefined,
        bypass: true
      })
      await refresh()
    } catch (err) {
      console.error(err)
      toast.error((err as any)?.message)
      setLoading(false)
    }
  }

  useEffect(() => {
    reward()
  }, [])

  if (user?.trial || user?.stripeSubscriptionStatus === 'Active') {
    return <Redirect to={ROUTES.home} />
  }

  return (
    <StyledClaimTrialView>
      <div className="dialog">
        <div className="pill">EXCLUSIVE OFFER</div>
        <h1>Claim your 7-day free trial</h1>
        <h2>
          Click the button below to start your 7-day free trial of Social Curator, no strings
          attached.
        </h2>
        <Button
          size="lg"
          variant="emphasis"
          disabled={loading}
          iconPos={loading ? undefined : 'left'}
          onClick={() => claimTrial()}
        >
          {!loading && (
            <>
              <GiftIcon />
              Claim Free Trial
            </>
          )}
          {loading && <Spinner />}
        </Button>
      </div>
      <div className="confetti" id="confetti" />
    </StyledClaimTrialView>
  )
}

export default ClaimTrialView
