import styled from 'styled-components'

const StyledGalleryPage = styled.div`
  padding: 10px;
  overflow-y: auto;
  height: 100%;
  box-sizing: border-box;

  .masonry-grid {
    display: flex;
    margin-left: -10px;
    width: auto;

    .masonry-grid-column {
      padding-left: 10px;
      background-clip: padding-box;
    }
  }
`

export default StyledGalleryPage
