import styled from 'styled-components'

const StyledCreatePopup = styled.div`
  height: 80vh;
  width: 420px;
  border-radius: 12px;
  position: fixed;
  z-index: ${({ theme }) => theme.elevation.override};

  .handle {
    position: absolute;
    top: calc(-26px - 10px);
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50rem;
    padding: 0 0 0 10px;
    color: white;
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 600;
    font-family: ${({ theme }) => theme.typeface.monospace};
    background-color: #171717;
    box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
      0px 2px 3px rgba(0, 0, 0, 0.05);

    hr {
      height: 26px;
      width: 1px;
      border: none;
      margin-left: 8px;
      background-color: rgb(255, 255, 255, 0.2);
    }

    .close-btn {
      width: 30px;
      height: 26px;
      padding-right: 2px;
      border-radius: 0px 50rem 50rem 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      color: rgb(255, 255, 255, 0.5);
      transition: 0.2s;

      svg {
        height: 20px;
        stroke-width: 2px;
      }

      &:hover {
        color: rgb(255, 255, 255, 1);
      }
    }
  }

  .create-bar-panel {
    box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
`

export default StyledCreatePopup
