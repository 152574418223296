import styled from 'styled-components'

const StyledPillarListItem = styled.div`
  max-width: 412px;
  user-select: none;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px 0px #0000000d;
  border-radius: 14px;
  position: relative;
  border: 1px solid ${({ theme }) => theme.color.gray[200]};
  background-color: ${({ theme }) => theme.color.background};

  .title-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .icon {
      font-size: 20px;
      margin-right: 10px;
      flex-shrink: 0;
    }

    .title {
      font-weight: 600;
      font-size: 15px;
      color: ${({ theme }) => theme.color.gray[800]};
    }
  }

  .content {
    line-height: 22px;
    color: ${({ theme }) => theme.color.gray[500]};
    font-size: 15px;
  }

  .actions {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    display: flex;
    opacity: 0;
    transition: opacity 0.2s;

    button {
      height: 34px;
      width: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: transparent;
      transition: 0.2s;
      color: ${({ theme }) => theme.color.gray[400]};

      svg {
        height: 16px;
      }

      &:hover {
        color: ${({ theme }) => theme.color.gray[600]};
      }

      &.delete-btn {
        background-color: ${({ theme }) => theme.color.gray[100]};
        color: ${({ theme }) => theme.color.gray[400]};

        svg {
          height: 20px;
          stroke-width: 2.25px;
        }

        &:hover {
          background-color: ${({ theme }) => theme.color.gray[200]};
          color: ${({ theme }) => theme.color.gray[600]};
        }
      }

      &:not(:last-child) {
        margin-right: 2px;
      }
    }
  }

  &:hover {
    .actions {
      opacity: 1;
    }
  }
`

export default StyledPillarListItem
