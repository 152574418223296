import styled from 'styled-components'

type StyledProps = {
  showOptions?: boolean
}

const StyledDesignGridItem = styled.div<StyledProps>`
  .design-preview {
    background-color: ${({ theme }) => theme.color.gray[200]};
    height: 150px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    user-select: none;

    .options-btn {
      z-index: 2;
      opacity: 0;
      position: absolute;
      right: 10px;
      top: 10px;
      width: 32px;
      height: 32px;
      transition: 0.2s;
      background-color: ${({ theme }) => theme.color.background};
      color: ${({ theme }) => theme.color.gray[600]};
      box-shadow: 0 0 0 1px rgb(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;

      svg {
        height: 24px;
      }

      &:hover {
        background-color: ${({ theme }) => theme.color.gray[100]};
      }
    }

    .media-preview {
      position: absolute;
      top: 15px;
      left: 15px;
      transition: 0.2s;
      width: calc(100% - 30px);
      height: calc(100% - 30px);
      pointer-events: none;

      img,
      video {
        border-radius: 6px;
        max-width: 100%;
        max-height: 100%;
        width: fit-content;
        margin: 0 auto;
        filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.2));
      }
    }

    &::after {
      user-select: none;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.foreground}10;
      pointer-events: none;
      z-index: 1;
    }
  }

  .design-details {
    margin-top: 10px;
    padding: 0 10px;
    position: relative;
    z-index: 3;

    .design-title {
      color: ${({ theme }) => theme.color.gray[800]};
      display: block;
      font-weight: 500;
      font-size: 15px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-align: left;
      -webkit-box-orient: vertical;
    }

    .design-status {
      display: flex;
      margin-top: 4px;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      color: ${({ theme }) => theme.color.gray[500]};
      user-select: none;

      svg {
        height: 16px;
        stroke-width: 1.75px;
        margin-right: 4px;
      }
    }
  }

  &:hover {
    cursor: pointer;

    .design-preview {
      .options-btn {
        opacity: 1;
      }

      .design-preview {
        opacity: 0.3;
      }
    }
  }

  ${({ showOptions }) =>
    showOptions &&
    `
      .design-preview {
        .options-btn {
          opacity: 1;
        }

        .media-preview {
          opacity: 0.3;
        }
      }
  `}
`

export default StyledDesignGridItem
