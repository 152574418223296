import { gql } from '@apollo/client'

export const captionSetGetTemplates = gql`
  query CaptionSetGetTemplates {
    captionSetGetTemplates {
      popular {
        _id
        title
        caption
        category
        photoIdeas
        captionPlainText
        thumbnail
        index
        canvaLink
        records {
          _id
          fullPath
          imageUrl
          index
          migrated
        }
      }
      behindTheScenes {
        _id
        title
        caption
        category
        photoIdeas
        captionPlainText
        thumbnail
        index
        canvaLink
        records {
          _id
          fullPath
          imageUrl
          index
          migrated
        }
      }
      myWhy {
        _id
        title
        caption
        category
        photoIdeas
        captionPlainText
        thumbnail
        index
        canvaLink
        records {
          _id
          fullPath
          imageUrl
          index
          migrated
        }
      }
      insight {
        _id
        title
        caption
        category
        photoIdeas
        captionPlainText
        thumbnail
        index
        canvaLink
        records {
          _id
          fullPath
          imageUrl
          index
          migrated
        }
      }
      inspiration {
        _id
        title
        caption
        category
        photoIdeas
        captionPlainText
        thumbnail
        index
        canvaLink
        records {
          _id
          fullPath
          imageUrl
          index
          migrated
        }
      }
      aboutMe {
        _id
        title
        caption
        category
        photoIdeas
        captionPlainText
        thumbnail
        index
        canvaLink
        records {
          _id
          fullPath
          imageUrl
          index
          migrated
        }
      }
      benefits {
        _id
        title
        caption
        category
        photoIdeas
        captionPlainText
        thumbnail
        index
        canvaLink
        records {
          _id
          fullPath
          imageUrl
          index
          migrated
        }
      }
      product {
        _id
        title
        caption
        category
        photoIdeas
        captionPlainText
        thumbnail
        index
        canvaLink
        records {
          _id
          fullPath
          imageUrl
          index
          migrated
        }
      }
      holiday {
        _id
        title
        caption
        category
        photoIdeas
        captionPlainText
        thumbnail
        index
        canvaLink
        records {
          _id
          fullPath
          imageUrl
          index
          migrated
        }
      }
    }
  }
`
