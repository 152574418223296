import { snakeCase } from 'lodash'

export const objectToSnake = (object?: any) => {
  if (!object) {
    return
  }

  const formatted = {} as any

  Object.keys(object).map(key => {
    formatted[snakeCase(key)] = object[key]
  })

  return formatted
}
