import { ForwardedRef, InputHTMLAttributes, forwardRef } from 'react'
import classNames from 'classnames'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { StyledSelect } from '.'

type Props = {
  value?: any
  placeholder?: string
  options: { value: string; label: string }[]
  size?: 'lg' | 'md' | 'sm'
  variant?: 'outline' | 'fill' | 'shadow'
} & Omit<InputHTMLAttributes<HTMLSelectElement>, 'size'>

const Select = forwardRef(
  (
    {
      value,
      placeholder,
      options,
      size = 'md',
      variant = 'outline',
      className,
      onClick,
      ...rest
    }: Props,
    ref: ForwardedRef<HTMLSelectElement>
  ) => {
    return (
      <StyledSelect
        hasValue={value !== undefined}
        scale={size}
        variant={variant}
        className={classNames('select', className)}
        onClick={onClick as any}
      >
        <select value={value} ref={ref} {...rest}>
          {value === undefined && (
            <option disabled selected>
              {placeholder || 'Select one'}
            </option>
          )}
          {options.map((option) => {
            return <option value={option.value}>{option.label}</option>
          })}
        </select>
        <ChevronDownIcon />
      </StyledSelect>
    )
  }
)

export default Select
