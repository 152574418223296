import { FC } from 'react'
import { setCreateContentTab, useAppDispatch, useAppSelector } from '../../../redux'
import { Tabs } from '../..'
import {
  CreateBarContentBits,
  CreateBarContentCaptions,
  CreateBarContentPhotos,
  CreateBarContentRecommended,
  CreateBarPanel
} from '..'
import { StyledCreateBarContent } from '.'

const CreateBarContent: FC = () => {
  const dispatch = useAppDispatch()
  const { contentTab } = useAppSelector((state) => state.create)

  return (
    <CreateBarPanel>
      <StyledCreateBarContent>
        <CreateBarContentRecommended />
        <Tabs
          tabs={[
            {
              label: 'Photos',
              isActive: contentTab === 'photos',
              onClick: () => dispatch(setCreateContentTab('photos'))
            },
            {
              label: 'Caption Templates',
              isActive: contentTab === 'captions',
              onClick: () => dispatch(setCreateContentTab('captions'))
            },
            {
              label: 'Caption Bits',
              isActive: contentTab === 'bits',
              onClick: () => dispatch(setCreateContentTab('bits'))
            }
          ]}
        />
        {contentTab === 'photos' && <CreateBarContentPhotos />}
        {contentTab === 'captions' && <CreateBarContentCaptions />}
        {contentTab === 'bits' && <CreateBarContentBits />}
      </StyledCreateBarContent>
    </CreateBarPanel>
  )
}

export default CreateBarContent
