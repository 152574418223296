import styled from 'styled-components'

const StyledPlanReviewStep = styled.div`
  padding: 35px;
  background-color: ${({ theme }) => theme.color.gray[100]};

  .width-controller {
    max-width: 580px;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
    display: grid;
    gap: 35px;
  }

  .post-index {
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.5px;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.color.gray[400]};
  }

  .post-title-container {
    font-size: 24px;
    font-weight: 700;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.color.gray[900]};

    button {
      font-size: 24px;
      font-weight: 700;
      display: flex;
      align-items: center;
      padding: 6px;
      border-radius: 8px;
      background-color: transparent;
      margin: -6px -6px -6px 2px;
      color: ${({ theme }) => theme.color.gray[900]};
      transition: 0.2s;

      .post-title {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-align: left;
        -webkit-box-orient: vertical;
      }

      svg {
        height: 20px;
        margin-left: 6px;
        flex-shrink: 0;
        color: ${({ theme }) => theme.color.gray[400]};
      }

      &:hover {
        background-color: ${({ theme }) => theme.color.gray[200]};
      }
    }
  }

  p {
    margin-top: 10px;
    font-size: 15px;
    color: ${({ theme }) => theme.color.gray[500]};
  }

  .delete-btn {
    height: 50px;
    border-radius: 50rem;
    font-weight: 500;
    color: ${({ theme }) => theme.color.red[800]};
    background-color: ${({ theme }) => theme.color.red[100]};
  }
`

export default StyledPlanReviewStep
