import { FC, useContext } from 'react'
import { Redirect, Route, RouteProps, matchPath, useLocation } from 'react-router-dom'
import { ROUTES, userStore } from '../../utils'
import { PlanEndedView } from '../../views'

type UserRole = 'admin' | 'basic' | 'course'

type Props = {
  userRole: UserRole[]
} & RouteProps

const PrivateRoute: FC<Props> = ({ userRole, ...rest }) => {
  const { user, loaded } = useContext(userStore)
  const location = useLocation()

  const allowedPages = [
    ...Object.values(ROUTES.settings),
    ...Object.values(ROUTES.academy),
    ROUTES.community,
    ROUTES.settings.billing,
    ROUTES.receipt,
    ROUTES.onboarding,
    ROUTES.claim,
    ROUTES.academy,
    ROUTES.old.affiliate,
    ROUTES.referrals
  ].filter((page) => typeof page === 'string')

  // Returns true if the user can access the page
  const canAccessWithoutSubscription = () => {
    return allowedPages.filter((page) => {
      return matchPath(location.pathname, {
        path: page.toString(),
        exact: rest.exact
      })
    }).length
  }

  const userCanAccess = () => {
    if (!user) {
      return false
    }

    if (user.course && !userRole.includes('course') && user.stripeSubscriptionStatus !== 'Active') {
      return false
    }

    return userRole.includes(user.role as UserRole)
  }

  const isCurrentRoute = () => {
    if (!rest.path) {
      return false
    }

    const pathArray = typeof rest.path === 'string' ? [rest.path] : rest.path

    return !!pathArray.filter((path) =>
      matchPath(location.pathname, {
        path,
        exact: rest.exact
      })
    ).length
  }

  if (!loaded) {
    return <div />
  }

  if (!user?.id) {
    return (
      <Redirect
        to={{
          pathname: ROUTES.auth.login,
          state: { redirect: location.pathname }
        }}
      />
    )
  }

  if (user.course && !userCanAccess()) {
    return <Redirect to={{ pathname: ROUTES.academy.main }} />
  }

  if (
    isCurrentRoute() &&
    user.stripeSubscriptionStatus !== 'Active' &&
    !canAccessWithoutSubscription()
  ) {
    return <PlanEndedView />
  }

  const allowAccessRoute = userCanAccess()
  const routeData = allowAccessRoute ? (
    <Route component={rest.component} {...rest} />
  ) : (
    <Redirect
      to={{
        pathname: ROUTES.fiveHundred,
        state: { from: rest.location }
      }}
    />
  )

  return routeData
}

export default PrivateRoute
