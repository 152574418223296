import styled from 'styled-components'

type StyledProps = {
  isCollapsed: boolean
}

const StyledUploadManager = styled.div<StyledProps>`
  position: fixed;
  max-width: 300px;
  width: 100vw;
  right: 20px;
  bottom: 0;
  z-index: 999999999999;
  overflow: hidden;
  border-radius: 6px 6px 0 0;
  background-color: ${({ theme }) => theme.color.background};
  box-shadow: 0 0 0 1px #00000019, 0 2px 4px 0px #00000036;
  border: 1px solid ${({ theme }) => theme.color.gray[300]};
  border-bottom: 0;

  .upload-manager-header {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: ${({ theme }) => theme.color.gray[100]};
    border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};

    .upload-manager-title {
      font-size: 15px;
      font-weight: 500;
      color: ${({ theme }) => theme.color.gray[700]};
    }

    .upload-manager-actions {
      display: flex;
      align-items: center;
      margin-right: -13px;

      button {
        svg path {
          stroke-width: 2.5px;
        }
      }
    }
  }

  .upload-manager-uploads {
    max-height: ${({ isCollapsed }) => (isCollapsed ? 0 : 300)}px;
    overflow-y: auto;
  }
`

export default StyledUploadManager
