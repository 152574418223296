import styled from 'styled-components'

const StyledConnectPlatformView = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: ${({ theme }) => theme.color.gray[100]};

  .connect-progress {
    position: fixed;
    user-select: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 6px;
    display: grid;
    gap: 20px;
    font-weight: 500;
    font-size: 15px;
    color: ${({ theme }) => theme.color.gray[600]};
    background-color: ${({ theme }) => theme.color.background};
    box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.2), 0px 0px 0px 1px rgba(0, 0, 0, 0.075),
      0px 2px 3px rgba(0, 0, 0, 0.1);

    svg {
      height: 40px;
      width: 40px;
    }
  }
`

export default StyledConnectPlatformView
