import { FC, useCallback, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { toast } from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
import { DndContext, DragEndEvent, pointerWithin } from '@dnd-kit/core'
import { ArrowUpTrayIcon } from '@heroicons/react/24/outline'
import { UploadsFileList, UploadsHeader, UploadsPreview, UploadsSidebar } from '../../components'
import { loadFilesFromCloud } from '../../graphql'
import {
  clearCloudState,
  removeCloudFile,
  setCloudFiles,
  setCloudStorage,
  setCloudTotalDocs,
  useAppDispatch,
  useAppSelector
} from '../../redux'
import { CloudContentType } from '../../types'
import { getParentFromPath, useCloud, useSensors, useUploadManager } from '../../utils'
import { StyledUploadsView } from '.'

const UploadsView: FC = () => {
  const apollo = useApolloClient()
  const sensors = useSensors()
  const dispatch = useAppDispatch()
  const { path }: any = useParams()
  const { files, page, sort, loading } = useAppSelector((state) => state.cloud)
  const { updateCloudFileData } = useCloud()
  const { addToUploads } = useUploadManager()

  const handleDrop = useCallback(
    (files: File[]) => {
      addToUploads(files, false, getParentFromPath(path))
    },
    [path]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    noClick: true,
    multiple: true
  })

  const loadData = async (changePath?: boolean) => {
    try {
      const { data } = await apollo.query({
        fetchPolicy: 'no-cache',
        query: loadFilesFromCloud,
        variables: {
          page,
          limit: 30,
          types: ['file'],
          parent: getParentFromPath(path)
        }
      })

      const docs = data?.loadFilesFromCloud?.docs || []
      const total = data?.loadFilesFromCloud?.totalDocs || 0
      const usage = data?.loadFilesFromCloud?.usage || 0

      dispatch(setCloudStorage(usage))
      dispatch(setCloudTotalDocs(total))

      if (changePath) {
        return dispatch(setCloudFiles(docs))
      }

      dispatch(setCloudFiles([...files, ...docs]))
    } catch (err) {
      console.log(err)
      toast.error((err as any).message)
    }
  }

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event

    const file = active.data.current?.file as CloudContentType
    const folder = over?.data.current?.folder as CloudContentType

    if (!file || !folder) {
      return
    }

    if (file.parent === over?.id) {
      return
    }

    await updateCloudFileData({
      ...file,
      parent: folder._id
    })

    dispatch(removeCloudFile(file._id))
  }

  useEffect(() => {
    if (loading) {
      loadData()
    }
  }, [loading])

  useEffect(() => {
    dispatch(clearCloudState())
    loadData(true)
  }, [path, sort])

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={pointerWithin}
      onDragEnd={(e) => handleDragEnd(e)}
    >
      <StyledUploadsView {...getRootProps()}>
        {isDragActive && (
          <div className="drag-overlay">
            <div className="overlay-icon">
              <ArrowUpTrayIcon />
            </div>
          </div>
        )}
        <input accept="image/*, video/*" {...getInputProps()} />
        <UploadsSidebar />
        <div className="uploads-content">
          <UploadsHeader />
          <UploadsFileList />
        </div>
      </StyledUploadsView>
      <UploadsPreview />
    </DndContext>
  )
}

export default UploadsView
