import styled from 'styled-components'

const StyledStreakDialog = styled.div`
  padding: 20px;
  position: relative;
  user-select: none;

  .share-bar-header {
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    align-items: center;
    justify-content: center;
    display: flex;
    color: ${({ theme }) => theme.color.gray[800]};
  }

  .share-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.color.orange[100]};

    .share-bar-label {
      font-weight: 500;
      font-size: 15px;
      color: ${({ theme }) => theme.color.orange[600]};
    }

    .share-bar-actions {
      display: flex;
      align-items: center;

      a {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.2s;
        color: ${({ theme }) => theme.color.orange[600]};
        background-color: ${({ theme }) => theme.color.background};

        svg {
          height: 20px;
        }

        &:not(:last-child) {
          margin-right: 8px;
        }

        &:hover {
          color: ${({ theme }) => theme.color.background};
          background-color: ${({ theme }) => theme.color.orange[600]};
        }
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    padding: 18px;
    width: 100vw;
    max-width: 350px;
  }
`

export default StyledStreakDialog
