import { gql } from '@apollo/client'

export const updateIssueMutation = gql`
  mutation UpdateIssueMutation(
    $_id: String!
    $title: String
    $about: String
    $aboutRaw: String
    $listDescription: String
    $listDescriptionRaw: String
    $headerImg: UpdateByIdLessonThumbnailInput
    $actionPlanAudio: UpdateByIdLessonThumbnailInput
    $actionPlanCover: UpdateByIdLessonThumbnailInput
    $actionPlanPdf: UpdateByIdLessonThumbnailInput
    $actionPlanVideo: String
    $status: String
    $slug: String
    $createdBy: String
    $hidden: Boolean
    $category: [String]
    $platform: [String]
    $facebookText: String
    $date: Date
    $tags: [JSON]
    $annualOnly: Boolean
  ) {
    issueUpdateById(
      _id: $_id
      record: {
        title: $title
        about: $about
        aboutRaw: $aboutRaw
        listDescription: $listDescription
        listDescriptionRaw: $listDescriptionRaw
        headerImg: $headerImg
        actionPlanAudio: $actionPlanAudio
        actionPlanCover: $actionPlanCover
        actionPlanPdf: $actionPlanPdf
        actionPlanVideo: $actionPlanVideo
        status: $status
        slug: $slug
        createdBy: $createdBy
        hidden: $hidden
        category: $category
        platform: $platform
        facebookText: $facebookText
        date: $date
        tags: $tags
        annualOnly: $annualOnly
      }
    ) {
      recordId
    }
  }
`
