import { FC, useContext, useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import { BoltIcon, CheckCircleIcon } from '@heroicons/react/24/solid'
import { pushToDialogStack, useAppDispatch } from '../../../redux'
import { useSubscription, userStore } from '../../../utils'
import { Button } from '../..'
import { StyledSidebarUpgradeCta } from '.'

const SidebarUpgradeCta: FC = () => {
  const dispatch = useAppDispatch()
  const { user } = useContext(userStore)
  const { plan } = useSubscription()
  const [trialEndDate, setTrialEndDate] = useState('')

  const getTrialEndDate = async () => {
    if (!plan) {
      return
    }

    const endDate = DateTime.fromSeconds(plan.endDate)

    setTrialEndDate(endDate.toRelative() || '')
  }

  useEffect(() => {
    if (!!user?.trial) {
      getTrialEndDate()
    }
  }, [user?.trial, plan?.endDate])

  if (!user?.trial) {
    return null
  }

  return (
    <StyledSidebarUpgradeCta id="sidebar-upgrade-cta">
      <ul className="upgrade-bullets">
        <li>
          <CheckCircleIcon />
          Unlimited social posts
        </li>
        <li>
          <CheckCircleIcon />
          All community spaces
        </li>
        <li>
          <CheckCircleIcon />
          Open all Action Plans
        </li>
        <li>
          <CheckCircleIcon />
          Up to 6 social profiles
        </li>
      </ul>
      <div className="upgrade-cta-action">
        <Button
          variant="emphasis-outline"
          isFullWidth
          onClick={() => dispatch(pushToDialogStack('go-pro'))}
        >
          Go Pro
          <BoltIcon />
        </Button>
        <div className="trial-disclaimer">
          {/* <ClockIcon /> */}
          Your trial ends {trialEndDate}
        </div>
      </div>
    </StyledSidebarUpgradeCta>
  )
}

export default SidebarUpgradeCta
