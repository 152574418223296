import styled from 'styled-components'

const StyledColorRow = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  column-gap: 10px;

  .color-list {
    position: relative;
  }

  .color-option {
    height: 35px;
    width: 35px;
    border-radius: 6px;
    flex-shrink: 0;
    position: relative;
    display: flex;
    background-size: 100%;
    background-position: center;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    background-size: cover !important;
    color: ${({ theme }) => theme.color.gray[600]};

    svg {
      height: 20px;
      stroke-width: 1.75px;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 6px;
      box-shadow: inset 0 0 0 1px rgb(0, 0, 0, 0.1);
    }

    &:not(:last-child) {
      margin-right: 8px;
    }

    &[aria-selected='true'] {
      box-shadow: inset 0 0 0 2px ${({ theme }) => theme.color.brand},
        inset 0 0 0 4px ${({ theme }) => theme.color.background};
    }
  }

  .react-horizontal-scrolling-menu--separator {
    width: 8px;
    flex-shrink: 0;
  }

  .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
  }

  .react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .arrow-background {
    position: absolute;
    z-index: 1;
    width: 100px;
    display: flex;
    pointer-events: none;

    .arrow-btn {
      height: 36px;
      width: 36px;
      border-radius: 50%;
      pointer-events: all;
    }

    &.arrow-left {
      left: 0;
      background: linear-gradient(90deg, ${({ theme }) => theme.color.background}, transparent);
    }

    &.arrow-right {
      right: 0;
      background: linear-gradient(90deg, transparent, ${({ theme }) => theme.color.background});
      flex-direction: row-reverse;
    }
  }
`

export default StyledColorRow
