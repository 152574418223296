import styled from 'styled-components'

const StyledCalendarWeekHeadingRow = styled.div`
  display: flex;

  .header {
    display: grid;
    width: 100vw;
    scroll-snap-align: start;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    background-color: ${({ theme }) => theme.color.background};
    border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
  }
`

export default StyledCalendarWeekHeadingRow
