import { FC, createRef, useState } from 'react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { setCloudSort, useAppDispatch, useAppSelector } from '../../../redux'
import { OptionsPopover } from '../../popovers'
import { StyledUploadsHeader } from '.'

const UploadsHeader: FC = () => {
  const optionsRef = createRef<HTMLButtonElement>()
  const dispatch = useAppDispatch()
  const [showOptions, setShowOptions] = useState(false)
  const { sort } = useAppSelector((state) => state.cloud)

  const getSortLabel = (value: string = sort) => {
    switch (value) {
      case 'a-z':
        return 'Alphabetically'
      case 'createdAt':
        return 'Recently created'
      default:
        return 'Last modified'
    }
  }

  return (
    <>
      <StyledUploadsHeader>
        <span className="label">FILES</span>
        <button className="sort-btn" onClick={() => setShowOptions(!showOptions)} ref={optionsRef}>
          {getSortLabel()} <ChevronDownIcon />
        </button>
      </StyledUploadsHeader>
      <OptionsPopover
        options={[
          {
            label: getSortLabel('createdAt'),
            isSelected: sort === 'createdAt',
            action: () => dispatch(setCloudSort('createdAt'))
          },
          {
            label: getSortLabel('a-z'),
            isSelected: sort === 'a-z',
            action: () => dispatch(setCloudSort('a-z'))
          },
          {
            label: getSortLabel('updatedAt'),
            isSelected: sort === 'updatedAt',
            action: () => dispatch(setCloudSort('updatedAt'))
          }
        ]}
        classToAvoid="sort-btn"
        buttonRef={optionsRef}
        isOpen={showOptions}
        onClose={() => setShowOptions(false)}
      />
    </>
  )
}

export default UploadsHeader
