import styled from 'styled-components'

const StyledConnectionPlatformOverview = styled.div`
  padding: 80px 40px;
  position: relative;
  user-select: none;

  .back-btn {
    position: absolute;
    top: 8px;
    left: 8px;
    background-color: transparent;

    svg {
      path {
        stroke-width: 2px;
      }
    }
  }

  .connection-indicator {
    display: flex;
    align-items: center;
    justify-content: center;

    .platform-image {
      height: 52px;
      width: 52px;
      flex-shrink: 0;
      position: relative;
      pointer-events: none;

      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      &.rounded {
        border-radius: 10px;
        overflow: hidden;
      }
    }

    .connection-icon {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 20px;
        stroke-width: 2px;
        margin: 0 12px;
        color: ${({ theme }) => theme.color.gray[500]};
      }
    }
  }

  .connection-title {
    display: block;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    margin: 20px 0 8px 0;
    font-family: ${({ theme }) => theme.typeface.faktumXCon};
    color: ${({ theme }) => theme.color.gray[800]};
  }

  .connection-description {
    font-size: 14px;
    text-align: center;
    margin: 0 auto 0 auto;
    line-height: 20px;
    display: block;
    color: ${({ theme }) => theme.color.gray[600]};
  }

  .connection-actions {
    margin-top: 40px;

    .btn {
      margin: 0 auto;
      min-width: 100px;
    }

    .disclaimer {
      font-size: 14px;
      text-align: center;
      margin: 8px auto 0 auto;
      line-height: 20px;
      display: block;
      color: ${({ theme }) => theme.color.gray[600]};

      a {
        font-weight: 500;
        color: ${({ theme }) => theme.color.gray[700]};
      }
    }
  }
`

export default StyledConnectionPlatformOverview
