import styled, { keyframes } from 'styled-components'

const checkAnim = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`

const StyledSelectPlanScreen = styled.div`
  .trial-banner {
    border-radius: 6px;
    padding: 12px;
    font-weight: 500;
    margin-top: 20px;
    line-height: 18px;
    color: ${({ theme }) => theme.color.brand};
    background-color: ${({ theme }) => theme.color.brandLight};

    .special-deal-header {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.01rem;
      margin-bottom: 8px;
      padding: 2px 6px;
      border-radius: 50rem;
      width: fit-content;
      color: ${({ theme }) => theme.color.background};
      background-color: ${({ theme }) => theme.color.brand};

      svg {
        height: 18px;
        margin-right: 4px;
      }
    }
  }

  .subscription-select-container {
    padding-bottom: 20px;
    margin-top: 20px;
    border-bottom: 1px solid ${({ theme }) => theme.color.gray[200]};

    .subscription-select-label {
      color: ${({ theme }) => theme.color.gray[800]};

      b {
        font-weight: 500;
      }
    }

    .subscription-select {
      display: grid;
      grid-gap: 8px;
      margin-top: 12px;

      .subscription-select-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        input {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          cursor: pointer;

          &:checked + {
            .subscription-details {
              .subscription-checkbox {
                background-color: ${({ theme }) => theme.color.brand};
                color: ${({ theme }) => theme.color.background};
                border: 1px solid ${({ theme }) => theme.color.brandDark};

                svg {
                  display: block;
                }
              }
            }
          }

          &:disabled {
            cursor: not-allowed;

            & + {
              .subscription-details {
                opacity: 0.6;
                filter: grayscale(1);

                & + {
                  .subscription-value {
                    opacity: 0.5;
                    filter: grayscale(1);
                  }
                }
              }
            }
          }
        }

        .subscription-details {
          display: flex;
          align-items: center;

          .subscription-checkbox {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            margin-right: 10px;
            background-color: ${({ theme }) => theme.color.background};
            border: 1px solid ${({ theme }) => theme.color.gray[300]};

            svg {
              height: 18px;
              stroke-width: 2.2px;
              display: none;

              path {
                stroke-dasharray: 1000;
                stroke-dashoffset: 1000;
                animation: ${checkAnim} 15s linear forwards;
              }
            }
          }

          .subscription-title {
            font-weight: 500;
            display: flex;
            align-items: center;
            color: ${({ theme }) => theme.color.gray[800]};

            mark {
              background-color: ${({ theme }) => theme.color.brandLight};
              font-weight: 400;
              display: flex;
              align-items: center;
              margin-left: 8px;
              font-weight: 600;
              font-size: 12px;
              padding: 4px 8px;
              border-radius: 50rem;
              color: ${({ theme }) => theme.color.brand};
            }
          }
        }

        .subscription-value {
          color: ${({ theme }) => theme.color.gray[700]};

          b {
            margin-right: 6px;
          }

          s {
            color: ${({ theme }) => theme.color.gray[400]};
          }
        }
      }
    }
  }

  .subscription-total {
    display: flex;
    align-items: center;
    margin-top: 18px;
    margin-bottom: 6px;
    justify-content: space-between;

    .subscription-total-label {
      color: ${({ theme }) => theme.color.gray[600]};
      font-weight: 500;
    }

    .subscription-total-value {
      color: ${({ theme }) => theme.color.gray[600]};

      b {
        font-size: 20px;
        font-weight: 500;
        color: ${({ theme }) => theme.color.gray[800]};
      }

      span {
        position: relative;
        bottom: 1px;
      }

      &.special {
        display: grid;
        justify-content: end;
        text-align: right;
        gap: 4px;

        span {
          font-style: italic;
        }
      }
    }
  }
`

export default StyledSelectPlanScreen
