import styled from 'styled-components'

const StyledActionPlanPdf = styled.div`
  border-radius: 4px;
  overflow: hidden;
  height: 100vh;
  position: relative;
  margin: 20px 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    border: 0;
    width: 100%;
  }
`

export default StyledActionPlanPdf
