import firebase from 'firebase/app'
import { createRef, FC, FormEvent, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeCardElement } from '@stripe/stripe-js'
import { useSubscription } from '../../../utils'
import { Button, CardInput, Dialog, Spinner } from '../..'
import { StyledPaymentMethodDialog } from '.'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const PaymentMethodDialog: FC<Props> = ({ isOpen, onClose }) => {
  const stripe = useStripe()
  const elements = useElements()
  const {
    actions: { loadCard }
  } = useSubscription()
  const [loading, setLoading] = useState(false)
  const [cardReady, setCardReady] = useState(false)
  const dialogRef = createRef<HTMLDivElement>()
  const attachPaymentMethod = firebase.functions().httpsCallable('attachPaymentMethod')

  const handleConfirm = async (e: FormEvent) => {
    e.preventDefault()
    try {
      setLoading(true)

      if (!stripe || !elements) {
        return
      }

      const cardElement = elements.getElement(CardElement)

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement as StripeCardElement
      })

      if (error) {
        setLoading(false)
        toast.error(error.message || 'There was an error')
        return
      }

      const method = await attachPaymentMethod({ paymentMethodId: paymentMethod?.id }).catch(
        err => {
          setLoading(false)
          toast.error(err.message)
        }
      )

      if (!method) {
        setLoading(false)
        return
      }

      await loadCard()

      toast.success('Updated payment method!')
      onClose()
    } catch (err) {
      console.log(err)
      toast.error((err as any).message)
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(false)
    setCardReady(false)
  }, [isOpen])

  return (
    <Dialog isOpen={isOpen} onClose={() => onClose()} ref={dialogRef}>
      <StyledPaymentMethodDialog>
        <div className="dialog-header">
          <span className="dialog-title">Update payment method</span>
        </div>
        <form onSubmit={e => handleConfirm(e)}>
          <CardInput onError={() => setCardReady(false)} onComplete={() => setCardReady(true)} />
          <div className="dialog-actions">
            <Button type="button" onClick={() => (dialogRef.current as any)?.close()}>
              Cancel
            </Button>
            <Button variant="emphasis" isFullWidth disabled={loading || !cardReady}>
              {loading ? <Spinner /> : 'Update payment method'}
            </Button>
          </div>
        </form>
      </StyledPaymentMethodDialog>
    </Dialog>
  )
}

export default PaymentMethodDialog
