import { compact } from 'lodash'
import { SplitsType } from '../../types'

export const getActiveSplits = (splits: { [x in SplitsType]?: 'on' | 'off' | 'control' }) => {
  return compact(
    Object.keys(splits).map((key) => {
      if ((splits as any)[key as any] === 'on') {
        return key
      }
    })
  )
}
