import { FC, MouseEvent, createRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { PlusIcon } from '@heroicons/react/24/outline'
import { setContentToView, useAppDispatch } from '../../../redux'
import { PhotoSetType } from '../../../types'
import { animateAddItemToPost, linkToSizedImage, useCreate } from '../../../utils'
import { Button } from '../..'
import { StyledPhotoSetListItem } from '.'

type Props = {
  photoSet: PhotoSetType
}

const PhotoSetListItem: FC<Props> = ({ photoSet }) => {
  const photoSetRef = createRef<HTMLImageElement>()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const location = useLocation()
  const { addMediaToPost } = useCreate()

  const addPhotoToPost = (e: MouseEvent) => {
    e.stopPropagation()
    animateAddItemToPost(photoSetRef)
    addMediaToPost({ url: photoSet.thumbnail || '' })
  }

  return (
    <StyledPhotoSetListItem
      onClick={() => {
        history.push(`${window.location.pathname}/photo-set/${photoSet._id}`, {
          scroll: 'none',
          backUrl: location.pathname
        })
        dispatch(setContentToView({ content: photoSet, type: 'photo-set' }))
      }}
    >
      <div className="photo-set-list-item-preview">
        <img
          ref={photoSetRef}
          src={linkToSizedImage(photoSet.thumbnail || '', { width: 200 })}
          alt=""
        />
        <Button iconPos="left" size="sm" className="use-btn" onClick={(e) => addPhotoToPost(e)}>
          <PlusIcon /> USE
        </Button>
      </div>
      <div className="photo-set-list-item-details">
        <span className="photo-set-list-item-title">{photoSet.title}</span>
        <span className="photo-set-list-item-category">{photoSet.category}</span>
      </div>
    </StyledPhotoSetListItem>
  )
}

export default PhotoSetListItem
