// Waits for an element to exist in the DOM, by trying n
// times in intervals of 1 second to target the element

export const waitForElementExists: (
  query: string,
  timeout?: number,
  step?: number
) => Promise<boolean> = async (query: string, timeout = 1000, step = 0) => {
  if (step === 60) {
    return false
  }

  const element = document.querySelector(query)

  if (!element) {
    await new Promise((resolve) => {
      setTimeout(resolve, timeout)
    })
    return waitForElementExists(query, timeout, step + 1)
  }

  return true
}
