import styled from 'styled-components'

const StyledHome2View = styled.div`
  min-height: 100vh;

  .width-controller {
    max-width: 785px;
    width: 100%;
    margin: 0 auto;
  }

  .tabs {
    position: sticky;
    top: 0;
    background-color: ${({ theme }) => theme.color.background};
    z-index: ${({ theme }) => theme.elevation.fixed};

    .tab-list {
      max-width: 785px;
    }
  }

  .new-post-btn {
    background-color: ${({ theme }) => theme.color.pink[100]};
    color: ${({ theme }) => theme.color.pink[900]};

    &:hover {
      background-color: ${({ theme }) => theme.color.pink[200]};
    }
  }
`

export default StyledHome2View
