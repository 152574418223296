import { FC, RefObject, useContext } from 'react'
import { PlusIcon } from '@heroicons/react/24/outline'
import { setShowConnectionsModal, useAppDispatch } from '../../../redux'
import { socialProfileByStatus, userStore } from '../../../utils'
import { Button, ConnectionListItem, EmptyState, Popover } from '../..'
import { StyledSocialProfilePopover } from '.'

type Props = {
  selectedProfiles: string[]
  classToAvoid: string
  width?: number | string
  buttonRef: RefObject<HTMLDivElement | HTMLButtonElement>
  isCollapsed?: boolean
  isOpen: boolean
  onSelect: (profile: string) => void
  onClose: () => void
}

const SocialProfilePopover: FC<Props> = ({
  selectedProfiles,
  width,
  isCollapsed = true,
  onSelect,
  onClose,
  isOpen,
  ...rest
}) => {
  const { user } = useContext(userStore)
  const dispatch = useAppDispatch()

  const handleOpenConnectionsModal = () => {
    dispatch(setShowConnectionsModal(true))
    onClose()
  }

  // if (isMobile()) {
  //   return (
  //     <SocialProfileDrawer
  //       selectedProfiles={selectedProfiles}
  //       isOpen={isOpen}
  //       onSelect={(profile) => onSelect(profile)}
  //       onClose={() => onClose()}
  //     />
  //   )
  // }

  return (
    <Popover position="bottom-right" isOpen={isOpen} onClose={() => onClose()} {...rest}>
      <StyledSocialProfilePopover width={width}>
        <div className="scroll">
          {!socialProfileByStatus(['active', 'expired'], user?.socialProfiles).length && (
            <EmptyState
              title="No profiles"
              body="Add a profile to get started"
              button={
                <Button
                  variant="emphasis-outline"
                  iconPos="right"
                  onClick={() => handleOpenConnectionsModal()}
                >
                  Add Profile
                  <PlusIcon />
                </Button>
              }
            />
          )}
          {socialProfileByStatus(['active', 'expired'], user?.socialProfiles || []).map(
            (socialProfile) => {
              return (
                <ConnectionListItem
                  profile={socialProfile}
                  isCollapsed={isCollapsed}
                  isSelected={selectedProfiles.includes(socialProfile._id || '')}
                  onSelect={() => onSelect(socialProfile._id || '')}
                  key={socialProfile._id}
                />
              )
            }
          )}
          {!!socialProfileByStatus(['active', 'expired'], user?.socialProfiles).length && (
            <Button
              variant="emphasis-outline"
              iconPos="left"
              onClick={() => handleOpenConnectionsModal()}
            >
              <PlusIcon />
              Connect Profile
            </Button>
          )}
        </div>
        <div className="bottom">
          <Button variant="emphasis" isFullWidth onClick={() => onClose()}>
            Done
          </Button>
        </div>
      </StyledSocialProfilePopover>
    </Popover>
  )
}

export default SocialProfilePopover
