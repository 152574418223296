import styled from 'styled-components'

const StyledPhotoViewerToolBar = styled.div`
  position: fixed;
  bottom: calc(20px + env(safe-area-inset-bottom));
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  background-color: rgb(0, 0, 0, 0.7);
  border-radius: 8px;
  z-index: 999;
  box-shadow: 0 0 0 1px rgb(255, 255, 255, 0.1);

  .button-group {
    padding: 6px;
    display: flex;
    align-items: center;
  }

  button {
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    color: rgb(255, 255, 255, 0.9);
    background-color: transparent;
    transition: 0.2s;

    svg {
      height: 25px;
      stroke-width: 1.8px;
    }

    &:hover {
      background-color: rgb(255, 255, 255, 0.1);
    }

    &:disabled {
      color: rgb(255, 255, 255, 0.6);
      cursor: not-allowed;
      background-color: transparent;
    }

    &.scale-btn {
      svg {
        height: 30px;
        stroke-width: 1.5px;
      }
    }

    &:not(:last-child) {
      margin-right: 2px;
    }
  }

  hr {
    margin: 0;
    height: calc(32px + 12px);
    position: relative;
    border: 0;
    width: 1px;
    background-color: rgb(255, 255, 255, 0.2);
  }
`

export default StyledPhotoViewerToolBar
