import { FC, useContext } from 'react'
import { PlusIcon } from '@heroicons/react/24/outline'
import { connectionStore } from '../../../../components'
import { getPlatformDescription, getPlatformIcon, getPlatformTitle } from '../../../../utils'
import { SocialProfileListItem } from '../..'
import { StyledConnectionOverview } from '.'

const PLATFORMS = ['instagram', 'facebook', 'linkedin']

const ConnectionOverview: FC = () => {
  const { connectedProfiles, setPlatformToConnect } = useContext(connectionStore)

  return (
    <StyledConnectionOverview>
      <div className="connection-list">
        {connectedProfiles.map((profile) => {
          return (
            <SocialProfileListItem
              profile={profile}
              isDisconnectable
              isReconnectable
              key={profile._id}
            />
          )
        })}
      </div>
      <div className="platform-list">
        {PLATFORMS.map((platform: any) => {
          return (
            <button onClick={() => setPlatformToConnect(platform)}>
              <div className="platform-details-container">
                {getPlatformIcon(platform)}
                <div className="platform-details">
                  <span className="platform-title">{getPlatformTitle(platform)}</span>
                  <span className="platform-description">{getPlatformDescription(platform)}</span>
                </div>
              </div>
              <PlusIcon className="plus-icon" />
            </button>
          )
        })}
      </div>
    </StyledConnectionOverview>
  )
}

export default ConnectionOverview
