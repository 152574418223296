import styled from 'styled-components'

const StyledCreateHeader = styled.div`
  height: 53px;
  // border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
  // background-color: ${({ theme }) => theme.color.background};
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .create-editor-details-container {
    display: flex;
    align-items: center;

    .create-editor-breadcrumbs {
      display: flex;
      align-items: center;
      user-select: none;
      margin-right: 2px;

      button {
        color: ${({ theme }) => theme.color.gray[400]};
        background-color: transparent;
        transition: 0.2s;
        text-decoration: none;
        display: flex;
        align-items: center;
        padding: 4px;
        margin: -4px;
        border-radius: 6px;

        &:hover,
        &[data-active='true'] {
          color: ${({ theme }) => theme.color.gray[600]};
          background-color: ${({ theme }) => theme.color.gray[200]};
        }
      }

      .divider {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 42px;
        margin: 0 6px;
        font-weight: 500;
        color: ${({ theme }) => theme.color.gray[300]};
      }

      input {
        transition: border-color 0.2s;
        margin: -2px;
        padding: 0 2px;
        border-radius: 4px;
        color: ${({ theme }) => theme.color.gray[700]};
        font-weight: 500;
        font-size: 16px;
        background-color: transparent;
        border: 1px solid transparent;

        &::placeholder {
          color: ${({ theme }) => theme.color.gray[700]};
        }

        &:hover {
          border-color: ${({ theme }) => theme.color.gray[300]};
        }

        &:focus {
          border-color: ${({ theme }) => theme.color.gray[400]};

          &::placeholder {
            color: transparent;
          }
        }
      }
    }

    .create-editor-details {
      display: flex;
      align-items: center;

      .create-editor-detail {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 28px;
        width: 28px;
        border-radius: 6px;
        font-size: 13px;
        transition: 0.2s;
        background-color: transparent;
        color: ${({ theme }) => theme.color.gray[500]};
        font-weight: 500;

        svg {
          height: 18px;
          width: 18px;
        }
      }

      button {
        &:hover {
          background-color: ${({ theme }) => theme.color.gray[200]};
        }

        &:disabled {
          filter: grayscale(1);
          opacity: 0.5;
          cursor: not-allowed;
          background-color: transparent;
        }
      }
    }
  }

  .create-actions {
    display: flex;

    .btn {
      border-radius: 50rem;

      svg path {
        stroke-width: 2px;
      }

      &.outline-btn {
        transition: 0.2s;
        background-color: transparent;
        padding: 0 16px 0 18px;
        box-shadow: inset 0 0 0 1.55px ${({ theme }) => theme.color.gray[800]};
        color: ${({ theme }) => theme.color.gray[800]};

        svg {
          margin-right: 8px;
        }

        &:hover {
          background-color: ${({ theme }) => theme.color.gray[100]};
        }
      }

      &.publish-btn {
        padding: 0 18px;
        margin-left: 6px;
        background-color: ${({ theme }) => theme.color.gray[800]};
        color: ${({ theme }) => theme.color.background};

        &:hover {
          background-color: ${({ theme }) => theme.color.gray[700]};
        }
      }

      &.icon-btn {
        width: 26px;
        background-color: transparent;
        color: ${({ theme }) => theme.color.gray[600]};

        &:hover,
        &[data-active='true'] {
          background-color: ${({ theme }) => theme.color.gray[200]};
          color: ${({ theme }) => theme.color.gray[800]};
        }
      }

      &.options-btn {
        margin-left: 4px;
      }
    }
  }
`

export default StyledCreateHeader
