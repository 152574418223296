import styled from 'styled-components'

type StyledProps = {
  showEmojiPicker?: boolean
}

const StyledCreateEditorCaptionEmoji = styled.button<StyledProps>`
  background-color: transparent;
  padding: 0;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1px;

  .emoji {
    font-size: 24px;
    opacity: 0.9;
    filter: grayscale(1);
    transition: transform 0.15s cubic-bezier(0.1, -0.6, 0.2, 0);
  }

  &:hover {
    .emoji {
      opacity: 1;
      filter: grayscale(0);
      transform: scale(1.15);
    }
  }

  ${({ showEmojiPicker }) =>
    showEmojiPicker &&
    `
      .emoji {
        filter: grayscale(0);
        transform: scale(1.15);
      }
  `}
`

export default StyledCreateEditorCaptionEmoji
