import { FC } from 'react'
import { useDropzone } from 'react-dropzone'
import { CloudIcon, PhotoIcon } from '@heroicons/react/20/solid'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Button } from '../../../..'
import { ReactComponent as CloudIconsIcon } from '../../../../../assets/cloud-icons.svg'
import { ReactComponent as MyDesignsIcon } from '../../../../../assets/my-designs-icon.svg'
import { setCreateContentTab, setCreateTab, useAppDispatch } from '../../../../../redux'
import { StyledUploadDialogDropzonePage } from '.'

type Props = {
  addToPost?: boolean
  isMulti?: boolean
  setPage: (page?: string) => void
  handleUpload: (files: File[]) => void
  onClose: () => void
}

const UploadDialogDropzonePage: FC<Props> = ({
  addToPost,
  isMulti,
  setPage,
  handleUpload,
  onClose
}) => {
  const dispatch = useAppDispatch()

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleUpload,
    multiple: isMulti
  })

  return (
    <StyledUploadDialogDropzonePage isDragActive={isDragActive}>
      <div className="dialog-header">
        <span className="dialog-title">Add an image or video</span>
        <Button size="sm" isIcon onClick={() => onClose()}>
          <XMarkIcon />
        </Button>
      </div>
      <div className="dialog-content">
        <div className="dropzone" {...getRootProps()}>
          <input {...getInputProps()} />
          <div className="dropzone-icon">
            <CloudIconsIcon />
          </div>
          <div className="dropzone-label">
            <span>Click here or drag & drop to upload</span>
          </div>
        </div>
        <div className="upload-option-list">
          {addToPost && (
            <button
              className="upload-option-list-item"
              onClick={() => {
                dispatch(setCreateContentTab('photos'))
                dispatch(setCreateTab('content'))
                onClose()
              }}
            >
              Photo Gallery
              <PhotoIcon />
            </button>
          )}
          <button className="upload-option-list-item" onClick={() => setPage('uploads')}>
            My Uploads
            <CloudIcon />
          </button>
          {/* <button className="upload-option-list-item" onClick={() => setPage('designs')}>
            My Designs
            <MyDesignsIcon />
          </button> */}
        </div>
      </div>
    </StyledUploadDialogDropzonePage>
  )
}

export default UploadDialogDropzonePage
