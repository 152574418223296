import styled from 'styled-components'

const StyledRange = styled.div`
  label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .range-label {
      font-size: 15px;
      user-select: none;
      color: ${({ theme }) => theme.color.gray[700]};
    }

    input {
      font-size: 15px;
      background-color: ${({ theme }) => theme.color.background};
      width: 38px;
      text-align: center;
      height: 22px;
      border-radius: 4px;
      color: ${({ theme }) => theme.color.gray[800]};
      border: 1px solid ${({ theme }) => theme.color.gray[300]};
      transition: border-color 0.2s;

      &:focus {
        border-color: ${({ theme }) => theme.color.brand};
      }
    }
  }

  .range {
    height: 16px;
    width: 100%;
    position: relative;

    .thumb {
      height: 16px;
      width: 16px;
      border-radius: 50%;
      box-shadow: 0 0 0 1px rgb(0, 0, 0, 0.15), 0 0 0 3px ${({ theme }) => theme.color.background};
      background-color: ${({ theme }) => theme.color.background};

      &:hover {
        cursor: grab;
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 0 1px rgb(0, 0, 0, 0.15), 0 0 4px rgb(0, 0, 0, 0.1),
          0 0 0 3px ${({ theme }) => theme.color.background};
      }

      &:active {
        cursor: grabbing;
      }
    }

    .track {
      top: calc(8px - 2px);
      height: 4px;
      box-sizing: border-box;
      border-radius: 50rem;
      background-color: ${({ theme }) => theme.color.gray[200]};

      &.track-0 {
        background-color: ${({ theme }) => theme.color.brand};
      }
    }
  }
`

export default StyledRange
