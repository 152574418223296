import { FC, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import TextareaAutosize from 'react-textarea-autosize'
import { difference } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { useApolloClient } from '@apollo/client'
import { XMarkIcon } from '@heroicons/react/24/outline'
import {
  userHashtagCreateMany,
  userHashtagFolderUpsertOne,
  userHashtagRemoveMany
} from '../../../../graphql'
import { setHashtagFolder, useAppDispatch, useAppSelector } from '../../../../redux'
import { HashtagType } from '../../../../types'
import { useForm } from '../../../../utils'
import { Input, Spinner } from '../..'
import { StyledHashtagFolderEdit } from '.'

const HashtagFolderEdit: FC = () => {
  const apollo = useApolloClient()
  const dispatch = useAppDispatch()
  const { form, updateFormValue } = useForm({
    _id: '',
    label: '',
    input: '',
    hashtags: [],
    originalHashtags: []
  })
  const { folder, hashtags, folders } = useAppSelector((state) => state.hashtags)
  const { showHashtags } = useAppSelector((state) => state.create)
  const [loading, setLoading] = useState(false)
  const isNew = folder === 'new'

  const handleSubmit = async () => {
    try {
      setLoading(true)

      const { data } = await apollo.mutate({
        fetchPolicy: 'no-cache',
        mutation: userHashtagFolderUpsertOne,
        variables: {
          label: form.label,
          _id: folder === 'new' ? undefined : folder
        }
      })

      const folderId = data.userHashtagFolderUpsertOne._id
      const newList = difference(form.hashtags, form.originalHashtags) as HashtagType[]
      const delList = difference(form.originalHashtags, form.hashtags) as HashtagType[]

      if (newList.length) {
        await apollo.mutate({
          fetchPolicy: 'no-cache',
          mutation: userHashtagCreateMany,
          variables: {
            hashtags: newList,
            folder: folderId
          }
        })
      }

      if (delList.length) {
        await apollo.mutate({
          fetchPolicy: 'no-cache',
          mutation: userHashtagRemoveMany,
          variables: {
            hashtags: delList.map((hashtag) => hashtag._id)
          }
        })
      }

      dispatch(setHashtagFolder(undefined))
    } catch (err) {
      console.error(err)
      toast.error((err as any)?.message)
    } finally {
      setLoading(false)
    }
  }

  const handleAdd = () => {
    const split = form.input
      .trim()
      .replaceAll(',', ' ')
      .replaceAll('\n', ' ')
      .replaceAll('  ', ' ')
      .split(' ')

    // const hashtags = split.filter((tag: string) => tag.startsWith('#'))
    const hashtags = split.map((tag: string) => {
      return {
        _id: uuidv4(),
        hashtag: tag.replaceAll('#', '')
      } as HashtagType
    })

    updateFormValue({
      input: '',
      hashtags: [...form.hashtags, ...hashtags]
    })
  }

  const removeHashtag = (_id: string) => {
    updateFormValue({
      input: '',
      hashtags: form.hashtags.filter((tag: HashtagType) => {
        return tag._id !== _id
      })
    })
  }

  useEffect(() => {
    const f = folders.find((f) => f._id === folder)

    if (!f) {
      return
    }

    const originalHashtags = hashtags.filter((hashtag) => hashtag.folder === folder)

    updateFormValue({
      label: f.label,
      hashtags: originalHashtags,
      originalHashtags
    })
  }, [folder])

  useEffect(() => {
    return () => {
      dispatch(setHashtagFolder(undefined))
    }
  }, [showHashtags])

  return (
    <StyledHashtagFolderEdit>
      <div className="hashtag-folder-edit-header">
        <span>{isNew ? 'Create' : 'Edit'} Hashtag Set</span>
      </div>
      <div className="form scrollable">
        <label>
          <span className="input-label">Hashtag Set Name</span>
          <Input
            value={form.label}
            placeholder="Full Name"
            onChange={(value) => updateFormValue('label', value)}
          />
        </label>
        <div className="input-container">
          <span className="input-label">Added Hashtags</span>
          <div className="hashtag-list">
            {form.hashtags.map((hashtag: HashtagType) => {
              return (
                <button type="button" onClick={() => removeHashtag(hashtag._id)}>
                  <span>#{hashtag.hashtag}</span> <XMarkIcon />
                </button>
              )
            })}
          </div>
        </div>
        <div className="input-container">
          <TextareaAutosize
            value={form.input}
            placeholder="Type hashtag(s)..."
            minRows={6}
            onChange={(e) => updateFormValue('input', e.target.value)}
          />
          <button disabled={!form.input?.length} type="button" onClick={() => handleAdd()}>
            Add hashtag
            {form.input.replaceAll(',', ' ').replaceAll('\n', ' ').replaceAll('  ', ' ').split(' ')
              .length > 1 && 's'}
          </button>
          <span className="input-subtext">
            Type 1 or more hashtags (separated by spaces or commas) and click "Add" to add them to
            the set.
          </span>
        </div>
      </div>
      <div className="hashtag-folder-edit-actions">
        <button className="cancel-btn" onClick={() => dispatch(setHashtagFolder(undefined))}>
          Cancel
        </button>
        <button
          disabled={!form.hashtags.length || !form.label || loading}
          className="save-btn"
          onClick={() => handleSubmit()}
        >
          {loading && <Spinner />}
          {!loading && <>{isNew ? 'Create' : 'Save'} Set</>}
        </button>
      </div>
    </StyledHashtagFolderEdit>
  )
}

export default HashtagFolderEdit
