import { FC, useEffect } from 'react'
import { toast } from 'react-hot-toast'
import InfiniteScroll from 'react-infinite-scroll-component'
import { times } from 'lodash'
import { useApolloClient } from '@apollo/client'
import { MagnifyingGlassIcon, PlusIcon } from '@heroicons/react/24/outline'
import { loadDesignsFromCloud } from '../../../graphql'
import {
  setDesigns,
  setDesignsPage,
  setDesignsSearch,
  setDesignsSort,
  setDesignsTotal,
  useAppDispatch,
  useAppSelector
} from '../../../redux'
import { conditionalStage, debounceFilter, isMobile, useDesigner } from '../../../utils'
import { Button, DesignGridItem, Dropdown, EmptyState, Input, Skeleton } from '../..'
import { StyledDesignGrid } from '.'

const DesignGrid: FC = () => {
  const apollo = useApolloClient()
  const dispatch = useAppDispatch()
  const { newDesign } = useDesigner()
  const { designs, loading, page, search, sort, total } = useAppSelector((state) => state.designs)

  const loadData = async () => {
    try {
      const { data } = await apollo.query({
        fetchPolicy: 'no-cache',
        query: loadDesignsFromCloud,
        variables: {
          search,
          page,
          sort,
          limit: 12
        }
      })

      const docs = data?.loadDesignsFromCloud?.docs || []
      const total = data?.loadDesignsFromCloud?.totalDocs || 0

      dispatch(setDesigns([...designs, ...docs]))
      dispatch(setDesignsTotal(total))
    } catch (err) {
      console.log(err)
      toast.error((err as any).message)
    }
  }

  const handleFilter = (type: 'search' | 'sort', value: string) => {
    if (type === 'search') {
      dispatch(setDesignsSearch(value))
    }

    if (type === 'sort') {
      dispatch(setDesignsSort(value as any))
    }
  }

  useEffect(() => {
    if (loading) {
      loadData()
    }
  }, [loading])

  return (
    <StyledDesignGrid>
      <div className="grid-header">
        <Input
          size="sm"
          variant="shadow"
          defaultValue={search}
          icon={<MagnifyingGlassIcon />}
          placeholder="Search Designs"
          type="search"
          onChange={(e) => debounceFilter(() => handleFilter('search', e.target.value))}
        />
        <div className="grid-header-filters">
          <Dropdown
            placeholder="Sort"
            value={sort}
            options={[
              {
                label: 'Date Created',
                value: 'createdAt'
              },
              {
                label: 'Date Modified',
                value: 'updatedAt'
              },
              {
                label: 'Design Name',
                value: 'a-z'
              }
            ]}
            onSelect={(value) => handleFilter('sort', value)}
          />
        </div>
      </div>
      {loading && page === 0 && (
        <div className="grid-list">
          {times(8).map((index) => {
            return <Skeleton styles="padding-top: 100%; border-radius: 10px;" key={index} />
          })}
        </div>
      )}
      {!loading && page === 0 && !designs.length && (
        <EmptyState
          title="You have no designs"
          body='Click "Create Design" to get started'
          button={
            <Button variant="emphasis" onClick={() => newDesign()}>
              Create Design
              <PlusIcon />
            </Button>
          }
        />
      )}
      <InfiniteScroll
        scrollThreshold={0.7}
        dataLength={designs.length}
        next={() => dispatch(setDesignsPage(page + 1))}
        hasMore={designs.length < total}
        loader={null}
        className="infinite-scroll"
        {...conditionalStage(isMobile(), { scrollableTarget: 'app-content' })}
      >
        <div className="grid-list">
          {designs.map((design) => {
            return <DesignGridItem design={design} key={design._id} />
          })}
        </div>
      </InfiniteScroll>
    </StyledDesignGrid>
  )
}

export default DesignGrid
