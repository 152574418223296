import styled, { keyframes } from 'styled-components'

const recordingAnim = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const StyledProductTestManager = styled.div`
  z-index: 9999999999999999999;
  position: fixed;
  pointer-events: all;
  border-radius: 12px;
  display: flex;
  right: 20px;
  bottom: 20px;
  background-color: ${({ theme }) => theme.color.background};
  box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.2), 0px 0px 0px 1px rgba(0, 0, 0, 0.075),
    0px 2px 3px rgba(0, 0, 0, 0.3);

  .product-test-drag-handle-container {
    padding: 10px;
    border-right: 1px solid ${({ theme }) => theme.color.gray[200]};

    .drag-handle {
      position: relative;
      width: 24px;
      height: 100%;
      padding: 0;
      border-radius: 6px;
      transition: 0.2s;
      background-color: ${({ theme }) => theme.color.gray[100]};
      color: ${({ theme }) => theme.color.gray[400]};

      svg {
        position: absolute;
        pointer-events: none;
        top: 9px;
        height: 24px;

        &:first-child {
          left: -4px;
        }

        &:last-child {
          right: -4px;
        }
      }

      &:hover {
        cursor: inherit;
        background-color: ${({ theme }) => theme.color.gray[200]};
        color: ${({ theme }) => theme.color.gray[600]};
      }
    }
  }

  .product-test-recording-indicator-container {
    padding: 10px 10px 10px 12px;
    display: grid;
    align-items: center;

    .product-test-recording-indicator {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 13px;
      color: ${({ theme }) => theme.color.gray[600]};

      .product-test-recording-indicator-light {
        height: 12px;
        width: 12px;
        flex-shrink: 0;
        margin-right: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${({ theme }) => theme.color.gray[200]};
        border-radius: 50%;

        svg {
          height: 8px;
        }

        &[data-recording='true'] {
          animation: ${recordingAnim} infinite ease-in-out 2s;
          background-color: ${({ theme }) => theme.color.red[500]};
        }
      }
    }

    .product-test-details {
      margin-top: 1px;
      display: flex;
      align-items: center;

      .product-test-id {
        display: block;
        padding: 2px 4px;
        border-radius: 4px;
        margin-right: 6px;
        color: ${({ theme }) => theme.color.gray[500]};
        font-size: 12px;
        background-color: ${({ theme }) => theme.color.gray[100]};
        font-weight: 600;
      }

      .product-test-recording-time {
        font-size: 15px;
        display: block;
        width: 60px;
        color: ${({ theme }) => theme.color.gray[700]};
      }
    }
  }

  .product-test-actions {
    display: flex;
    padding: 10px;
    border-left: 1px solid ${({ theme }) => theme.color.gray[200]};

    button {
      padding: 0 14px;
      height: 41px;
      font-weight: 500;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid ${({ theme }) => theme.color.gray[500]};
      color: ${({ theme }) => theme.color.gray[600]};
      background-color: ${({ theme }) => theme.color.background};
      transition: 0.2s;

      .play-icon {
        height: 20px;
        margin: 0 6px 0 -4px;
      }

      &:hover {
        border: 1px solid ${({ theme }) => theme.color.gray[600]};
        color: ${({ theme }) => theme.color.gray[700]};
        background-color: ${({ theme }) => theme.color.gray[100]};
      }

      &.tasks-btn {
        width: 150px;
        padding: 10px 0;
      }

      &.done-button {
        color: #fff;
        border-color: ${({ theme }) => theme.color.green[700]};
        background-color: ${({ theme }) => theme.color.green[700]};

        &:hover {
          border-color: ${({ theme }) => theme.color.green[800]};
          background-color: ${({ theme }) => theme.color.green[800]};
        }
      }

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
`

export default StyledProductTestManager
