import { gql } from '@apollo/client'

export const chatRetryMessage = gql`
  mutation ChatRetryMessage($_id: String!, $persona: String, $date: String) {
    chatRetryMessage(_id: $_id, persona: $persona, date: $date) {
      _id
      content
      prompt
      type
      conversation
      status
      template
      alt
      metadata
      createdAt
      ref
    }
  }
`
