import { FC, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Browser } from '@capacitor/browser'
import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import { getIssueBySlugQuery } from '../../../graphql'
import { ActionPlanType } from '../../../types'
import { theme, useSegment } from '../../../utils'
import { ReadMore, RenderSlate } from '../../components'
import { MOBILE_ROUTES } from '../../utils'
import { StyledActionPlanView } from '.'

const ActionPlanView: FC = () => {
  const history = useHistory()
  const { slug }: any = useParams()
  const { track } = useSegment()
  const [actionPlan, setActionPlan] = useState<ActionPlanType>()

  const { data } = useQuery(getIssueBySlugQuery, {
    fetchPolicy: 'no-cache',
    variables: { slug }
  })

  const openActionPlan = async () => {
    try {
      if (!actionPlan?.actionPlanPdf?.fileHttpLink) {
        return
      }

      track('Downloaded Action Plan PDF', { actionPlan })

      await Browser.open({
        url: actionPlan.actionPlanPdf.fileHttpLink,
        presentationStyle: 'popover'
      })
    } catch (err) {}
  }

  useEffect(() => {
    if (!data) {
      return
    }

    setActionPlan(data.issueFindOne)
  }, [slug, data])

  if (!actionPlan) {
    return null
  }

  return (
    <StyledActionPlanView>
      <div className="video-player">
        <button className="back-btn" onClick={() => history.push(MOBILE_ROUTES.actionPlans.main)}>
          <ChevronLeftIcon />
          back
        </button>
        <iframe
          src={`${actionPlan.actionPlanVideo}?color=${theme.color.brand.replace(
            '#',
            ''
          )}&pip=true&texttrack=false&h=96067f6a77&badge=0&autopause=0&player_id=0&app_id=58479`}
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        />
      </div>
      <div className="details">
        <h1>{actionPlan.title}</h1>
        <ReadMore>
          <RenderSlate html={actionPlan.about} className="description" />
        </ReadMore>
        {actionPlan?.actionPlanPdf?.fileHttpLink && (
          <button className="action-plan-btn" onClick={() => openActionPlan()}>
            Open Action Plan
          </button>
        )}
      </div>
    </StyledActionPlanView>
  )
}

export default ActionPlanView
