import styled from 'styled-components'

type StyledProps = {
  isExpanded?: boolean
}

const StyledChatPromptBar = styled.div<StyledProps>`
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  max-height: ${({ isExpanded }) => (isExpanded ? 38 : 0)}px;
  transition: 0.2s;
  overflow: hidden;

  .scroll-container {
    padding: 0 14px 6px 14px;
    display: flex;
    overflow-x: auto;

    button {
      flex-shrink: 0;
      height: 32px;
      border-radius: 8px;
      padding: 0 10px;
      font-size: 15px;
      font-weight: 600;
      background-color: ${({ theme }) => theme.color.brandLight};
      color: ${({ theme }) => theme.color.brand};

      &:not(:last-child) {
        margin-right: 6px;
      }

      &[data-selected='true'] {
        background-color: ${({ theme }) => theme.color.brand};
        color: ${({ theme }) => theme.color.brandLight};
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .btn-group {
    padding: 0 6px 6px 6px;

    .clear-btn {
      flex-shrink: 0;
      height: 32px;
      border-radius: 8px;
      padding: 0 10px;
      font-size: 15px;
      font-weight: 600;
      background-color: ${({ theme }) => theme.color.gray[200]};
      color: ${({ theme }) => theme.color.gray[600]};
    }
  }
`

export default StyledChatPromptBar
