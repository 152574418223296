import { createRef, FC, useState } from 'react'
import { useEditor } from '@layerhub-io/react'
import { ReactComponent as BringToFrontIcon } from '../../../assets/bring-to-front-icon.svg'
import { ReactComponent as SendToBackIcon } from '../../../assets/send-to-back-icon.svg'
import { ReactComponent as AlignTopIcon } from '../../../assets/align-top-icon.svg'
import { ReactComponent as AlignLeftIcon } from '../../../assets/align-left-icon.svg'
import { ReactComponent as AlignMiddleIcon } from '../../../assets/align-middle-icon.svg'
import { ReactComponent as AlignCenterIcon } from '../../../assets/align-center-icon.svg'
import { ReactComponent as AlignBottomIcon } from '../../../assets/align-bottom-icon.svg'
import { ReactComponent as AlignRightIcon } from '../../../assets/align-right-icon.svg'
import { OptionsPopover } from '../..'
import { DesignerToolButton } from '.'

const DesignerPositionTool: FC = () => {
  const btnRef = createRef<HTMLButtonElement>()
  const editor = useEditor()
  const [showOptions, setShowOptions] = useState(false)

  return (
    <>
      <DesignerToolButton
        className="position-btn"
        isActive={showOptions}
        onClick={() => setShowOptions(!showOptions)}
        ref={btnRef}
      >
        <span>Position</span>
      </DesignerToolButton>
      <OptionsPopover
        options={[
          {
            icon: <BringToFrontIcon />,
            label: 'Bring to front',
            stayOpen: true,
            action: () => editor.objects.bringToFront()
          },
          {
            icon: <BringToFrontIcon />,
            label: 'Bring forward',
            stayOpen: true,
            action: () => editor.objects.bringForward()
          },
          {
            icon: <SendToBackIcon />,
            label: 'Send to back',
            stayOpen: true,
            action: () => editor.objects.sendToBack()
          },
          {
            icon: <BringToFrontIcon />,
            label: 'Send backwards',
            stayOpen: true,
            action: () => editor.objects.sendBackwards()
          },
          {
            icon: <AlignTopIcon />,
            label: 'Align top',
            stayOpen: true,
            action: () => editor.objects.alignTop()
          },
          {
            icon: <AlignLeftIcon />,
            label: 'Align left',
            stayOpen: true,
            action: () => editor.objects.alignLeft()
          },
          {
            icon: <AlignCenterIcon />,
            label: 'Align center',
            stayOpen: true,
            action: () => editor.objects.alignCenter()
          },
          {
            icon: <AlignMiddleIcon />,
            label: 'Align middle',
            stayOpen: true,
            action: () => editor.objects.alignMiddle()
          },
          {
            icon: <AlignBottomIcon />,
            label: 'Align bottom',
            stayOpen: true,
            action: () => editor.objects.alignBottom()
          },
          {
            icon: <AlignRightIcon />,
            label: 'Align right',
            stayOpen: true,
            action: () => editor.objects.alignRight()
          }
        ]}
        divsAfter={['Send backwards']}
        position="bottom-left"
        buttonRef={btnRef}
        classToAvoid="position-btn"
        isOpen={showOptions}
        onClose={() => setShowOptions(false)}
      />
    </>
  )
}

export default DesignerPositionTool
