import styled from 'styled-components'

const StyledHomeSplash = styled.div`
  padding: 0;
  position: relative;

  #gradient-canvas {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.2;
    background-color: ${({ theme }) => theme.color.gray[100]};
    --gradient-color-1: ${({ theme }) => theme.color.background};
    --gradient-color-2: #7dd3fc;
    --gradient-color-3: ${({ theme }) => theme.color.purple[300]};
    --gradient-color-4: ${({ theme }) => theme.color.background};
  }

  .home-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;

    h1 {
      font-size: 36px;
      font-weight: 600;
      font-family: ${({ theme }) => theme.typeface.faktumXCon};
      color: ${({ theme }) => theme.color.gray[800]};
    }

    .home-date {
      text-align: right;
      font-size: 13px;
      color: ${({ theme }) => theme.color.gray[500]};
      font-family: ${({ theme }) => theme.typeface.monospace};

      span {
        display: block;

        &:not(:first-child) {
          margin-top: 2px;
        }
      }
    }
  }

  .home-input-container {
    display: flex;
    margin-top: 20px;

    .home-input-btn {
      height: 50px;
      border-radius: 50rem;
      display: flex;
      align-items: center;
      font-weight: 500;
      padding: 0 26px;
      flex-shrink: 0;
      transition: 0.2s;
      color: ${({ theme }) => theme.color.background};
      background-color: ${({ theme }) => theme.color.gray[900]};

      &:hover {
        background-color: ${({ theme }) => theme.color.gray[800]};
      }

      &.design-btn {
        padding: 0 20px;
        flex-shrink: 0;
        border-radius: 0;

        svg {
          height: 20px;
          margin-right: 6px;
        }
        
        // position: relative;
        // color: white;
        // background: linear-gradient(
        //     25deg,
        //     ${({ theme }) => theme.color.brand},
        //     ${({ theme }) => theme.color.blue[500]}
        //   )
        //   border-box;

        // &::after {
        //   content: '';
        //   transition: 0.2s;
        //   pointer-events: none;
        //   border: 1px solid transparent;
        //   background: linear-gradient(
        //       25deg,
        //       ${({ theme }) => theme.color.brandDark},
        //       ${({ theme }) => theme.color.blue[400]}
        //     )
        //     border-box;
        //   -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
        //   -webkit-mask-composite: xor;
        //   mask-composite: exclude;
        //   position: absolute;
        //   right: -1px;
        //   top: -1px;
        //   height: calc(100%);
        //   width: calc(100%);
        //   border-radius: 50rem;
        // }

        // &::before {
        //   content: '';
        //   transition: 0.2s;
        //   pointer-events: none;
        //   position: absolute;
        //   right: -1px;
        //   top: -1px;
        //   height: calc(100%);
        //   width: calc(100%);
        //   border-radius: 50rem;
        // }

        // .label {
        //   position: relative;
        //   display: flex;
        //   align-items: center;
        //   justify-content: center;
        // }

        // &:hover {
        //   &::before {
        //     background-color: ${({ theme }) => theme.color.foreground}30;
        //   }
        // }
      }
    }

    .home-input-btn-more-container {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      border-radius: 50rem;
      overflow: hidden;
      margin-left: 10px;
      background-color: ${({ theme }) => theme.color.gray[900]};
      border: 1px solid ${({ theme }) => theme.color.gray[600]};

      .home-input-btn-more {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${({ theme }) => theme.color.gray[900]};
        color: ${({ theme }) => theme.color.gray[400]};
        border-left: 1px solid ${({ theme }) => theme.color.gray[600]};
        height: 50px;
        width: 45px;
        transition: 0.2s;
        
        svg {
          position: relative;
          left: -1px;
          height: 24px;
        }

        &:hover, &[data-active="true"] {
          background-color: ${({ theme }) => theme.color.gray[800]};
        }
      }
    }

    .home-input {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 50rem;
      padding: 0 6px 0 18px;
      height: 50px;
      width: 100%;
      transition: 0.2s;
      color: ${({ theme }) => theme.color.gray[500]};
      box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.1),
        0px 3px 5px rgba(0, 0, 0, 0.05);
      background-color: ${({ theme }) => theme.color.background};

      .home-input-label {
        display: flex;
        align-items: center;
        font-weight: 500;

        svg {
          height: 24px;
          margin-right: 8px;
          stroke-width: 2px;
        }
      }

      .ask-btn {
        height: 36px;
        display: flex;
        align-items: center;
        font-weight: 500;
        padding: 0 10px;
        border-radius: 50rem;
        border: 1px solid transparent;
        position: relative;
        color: ${({ theme }) => theme.color.brand};
        background: linear-gradient(
            25deg,
            ${({ theme }) => theme.color.brand}10,
            ${({ theme }) => theme.color.blue[500]}20
          )
          border-box;

        &::after {
          content: '';
          transition: 0.2s;
          pointer-events: none;
          border: 1px solid transparent;
          background: linear-gradient(
              25deg,
              ${({ theme }) => theme.color.brand}40,
              ${({ theme }) => theme.color.blue[500]}40
            )
            border-box;
          -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
          -webkit-mask-composite: xor;
          mask-composite: exclude;
          position: absolute;
          right: -1px;
          top: -1px;
          height: calc(100%);
          width: calc(100%);
          border-radius: 50rem;
        }

        svg {
          height: 20px;
          margin-right: 8px;
        }

        &:hover {
          background: linear-gradient(
              25deg,
              ${({ theme }) => theme.color.brand}20,
              ${({ theme }) => theme.color.blue[500]}30
            )
            border-box;

          &::after {
            background: linear-gradient(
                25deg,
                ${({ theme }) => theme.color.brand}50,
                ${({ theme }) => theme.color.blue[500]}50
              )
              border-box;
          }
        }
      }

      &:hover {
        color: ${({ theme }) => theme.color.gray[600]};
        box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.2), 0px 0px 0px 1px rgba(0, 0, 0, 0.075),
          0px 2px 3px rgba(0, 0, 0, 0.1);
      }
    }
  }
`

export default StyledHomeSplash
