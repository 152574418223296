import styled from 'styled-components'

const StyledSelect = styled.div`
  display: grid;

  .input-label {
    font-weight: 700;
    font-size: 15px;
    margin-bottom: 6px;
    color: ${({ theme }) => theme.color.gray[800]};
    display: flex;
    align-items: center;

    .required-marker {
      height: 12px;
      width: 12px;
      border-radius: 50%;
      margin-left: 4px;
      padding-top: 5px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ theme }) => theme.color.red[800]};
      background-color: ${({ theme }) => theme.color.red[100]};
    }
  }

  .input {
    height: 50px;
    width: 100%;
    background-color: ${({ theme }) => theme.color.gray[100]};
    border-radius: 8px;
    overflow: hidden;
    position: relative;

    .list-icon {
      position: absolute;
      height: 24px;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      stroke-width: 1.75px;
      color: ${({ theme }) => theme.color.gray[500]};
      pointer-events: none;
    }

    select {
      padding: 0 16px;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      background-color: transparent;
      font-weight: 500;
      color: ${({ theme }) => theme.color.gray[700]};

      &::-webkit-calendar-picker-indicator,
      &::-webkit-list-button {
        opacity: 0 !important;
        width: 50px;
      }
    }
  }

  .suggestions {
    margin-top: 8px;
    display: flex;
    width: 100%;
    overflow: hidden;
    overflow-x: auto;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      white-space: nowrap;
      height: 32px;
      padding: 0 10px;
      border-radius: 6px;
      font-weight: 500;
      font-size: 14px;
      transition: 0.2s;
      color: ${({ theme }) => theme.color.gray[700]};
      background-color: ${({ theme }) => theme.color.gray[100]};

      &:not(:last-child) {
        margin-right: 8px;
      }

      &[data-selected='true'] {
        background-color: ${({ theme }) => theme.color.brand} !important;
        color: white !important;
        box-shadow: inset 0 0 0 1px rgb(0, 0, 0, 0.1);
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
`

export default StyledSelect
