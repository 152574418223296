import styled from 'styled-components'

const StyledAcademyCourseLessonView = styled.div`
  background-color: ${({ theme }) => theme.color.gray[100]};

  .tab-list {
    max-width: 835px;
    padding: 0 20px;
  }

  .width-controller {
    max-width: 835px;
    margin: 0 auto;
    padding: 0 20px;
  }

  .lesson-splash {
    padding: 20px 20px 6px 20px;
    max-width: 835px;
    margin: 0 auto;

    .lesson-splash-layout {
      display: grid;
      grid-gap: 20px;

      .lesson-video-container {
        .lesson-video-player-container {
          padding-top: 56.25%;
          background-color: ${({ theme }) => theme.color.foreground}10;
          position: relative;

          .no-video-status {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: ${({ theme }) => theme.color.foreground}95;
          }

          .muse-player {
            position: absolute;
            top: 0;
            width: 100%;
          }
        }
      }

      .course-lesson-list {
        box-sizing: border-box;
        position: relative;

        .lesson-map-container {
          max-height: 300px;
        }
      }
    }

    .course-lesson-details-container {
      margin-top: 20px;

      .course-lesson-details {
        h1 {
          font-weight: 700;
          font-size: 22px;
          color: ${({ theme }) => theme.color.gray[800]};
          max-width: 700px;
        }

        .lesson-finished-stats {
          display: flex;
          align-items: center;
          margin-top: 8px;
          color: ${({ theme }) => theme.color.gray[600]};

          svg {
            height: 20px;
            margin-right: 2px;
            color: ${({ theme }) => theme.color.brand};
          }

          b {
            color: ${({ theme }) => theme.color.brand};
            font-weight: 600;
            margin-right: 6px;
          }
        }
      }

      button {
        margin-top: 20px;
        flex-shrink: 0;
      }
    }
  }

  .tab-content {
    padding: 20px 0;
    background-color: ${({ theme }) => theme.color.background};

    p {
      max-width: 600px;
      line-height: 26px;
      color: ${({ theme }) => theme.color.gray[700]};

      a {
        font-weight: 500;
        color: ${({ theme }) => theme.color.brand};
      }

      &:not(:first-child) {
        margin-top: 20px;
      }
    }

    .resource-list {
      max-width: 600px;
      display: grid;
      grid-gap: 12px;

      .resource-list-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 6px;
        padding: 10px;
        text-decoration: none;
        transition: 0.2s;
        box-shadow: 0 0 0 1px ${({ theme }) => theme.color.foreground}20;

        .resource-list-item-details {
          display: flex;
          align-items: center;

          .resource-list-item-icon {
            height: 34px;
            width: 34px;
            background-color: ${({ theme }) => theme.color.brandLight};
            border-radius: 4px;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              height: 20px;
              color: ${({ theme }) => theme.color.brand};
            }
          }

          .resource-list-item-title {
            font-weight: 500;
            color: ${({ theme }) => theme.color.brand};
          }
        }

        svg {
          height: 22px;
          color: ${({ theme }) => theme.color.gray[600]};
        }

        &:hover {
          box-shadow: 0 0 0 1px ${({ theme }) => theme.color.foreground}30,
            0 2px 4px ${({ theme }) => theme.color.foreground}10;

          svg {
            color: ${({ theme }) => theme.color.gray[800]};
          }
        }
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    .tab-list,
    .width-controller {
      padding: 0 40px;
    }

    .lesson-splash {
      padding: 40px 40px 20px 40px;

      .lesson-splash-layout {
        grid-gap: 40px;
        grid-template-columns: minmax(0, 1fr) 300px;

        .course-lesson-list {
          position: relative;

          .lesson-map-container {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            max-height: unset;
          }
        }
      }

      .course-lesson-details-container {
        margin-top: 40px;

        .course-lesson-details {
          h1 {
            font-size: 26px;
          }
        }
      }
    }

    .tab-content {
      padding: 40px 0;
    }
  }
`

export default StyledAcademyCourseLessonView
