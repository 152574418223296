import { FC } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { times } from 'lodash'
import { DragOverlay, useDndContext } from '@dnd-kit/core'
import { snapCenterToCursor } from '@dnd-kit/modifiers'
import { setCloudPage, useAppDispatch, useAppSelector } from '../../../redux'
import { EmptyState, Skeleton } from '../../system'
import { UploadsFileListItem } from '..'
import { StyledUploadsFileList } from '.'

const UploadsFileList: FC = () => {
  const dispatch = useAppDispatch()
  const { active } = useDndContext()
  const { files, totalDocs, page, loading } = useAppSelector((state) => state.cloud)

  return (
    <>
      <StyledUploadsFileList id="uploads-file-list">
        {!loading && !files.length && (
          <EmptyState title="No uploaded files" body="Upload a photo or video to see it here" />
        )}
        <InfiniteScroll
          scrollThreshold={0.7}
          dataLength={files.length}
          next={() => dispatch(setCloudPage(page + 1))}
          hasMore={files.length < totalDocs}
          loader={null}
          scrollableTarget="uploads-file-list"
        >
          <div className="file-grid">
            {loading &&
              !files.length &&
              times(10, (num) => {
                return <Skeleton randHeight={[206, 206]} key={num} />
              })}
            {files.map((file) => {
              return <UploadsFileListItem file={file} key={file._id} />
            })}
          </div>
        </InfiniteScroll>
      </StyledUploadsFileList>
      <DragOverlay modifiers={[snapCenterToCursor]} dropAnimation={null}>
        {!!active?.data?.current?.file && (
          <UploadsFileListItem file={active.data.current.file} isOverlay />
        )}
      </DragOverlay>
    </>
  )
}

export default UploadsFileList
