import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type InitialState = {
  tab: 'uploads' | 'photos' | 'stories' | 'captions'
}

const initialState: InitialState = {
  tab: 'uploads'
}

export const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    setContentTab: (state, action: PayloadAction<InitialState['tab']>) => {
      state.tab = action.payload
    }
  }
})

export const { setContentTab } = contentSlice.actions
export default contentSlice.reducer
