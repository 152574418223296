import styled from 'styled-components'

type StyledProps = {
  isDisabled?: boolean
}

const StyledConnectionPlatformListItem = styled.button<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  white-space: nowrap;
  text-align: left;
  transition: 0.2s;
  border-radius: 6px;
  padding: 10px;
  box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
    0px 2px 3px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;

  .platform-details-container {
    display: flex;
    align-items: center;
    margin-right: 10px;

    .platform-image {
      height: 28px;
      width: 28px;
      flex-shrink: 0;
      margin-right: 10px;
      position: relative;

      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .platform-details {
      span {
        display: block;
      }

      .platform-name {
        font-size: 14px;
        font-weight: 500;
        color: ${({ theme }) => theme.color.gray[800]};
      }

      .platform-description {
        color: ${({ theme }) => theme.color.gray[600]};
        font-size: 13px;
        marign-top: 2px;
      }
    }
  }

  svg {
    height: 20px;
    stroke-width: 2px;
    color: ${({ theme }) => theme.color.gray[500]};
  }

  .platform-coming-soon {
    font-size: 12px;
    font-weight: 500;
    padding: 4px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.color.brandLight};
    color: ${({ theme }) => theme.color.brand};
  }

  &:hover {
    box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.2), 0px 0px 0px 1px rgba(0, 0, 0, 0.075),
      0px 2px 3px rgba(0, 0, 0, 0.1);
  }

  ${({ isDisabled, theme }) =>
    isDisabled &&
    `
    .platform-details-container, svg {
      opacity: 0.6;
    }

    &:hover {
      cursor: not-allowed;
      box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
    0px 2px 3px rgba(0, 0, 0, 0.05);
    }
  `}
`

export default StyledConnectionPlatformListItem
