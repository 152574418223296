import { FC, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import InfiniteScroll from 'react-infinite-scroll-component'
import { times, uniqBy } from 'lodash'
import { useApolloClient } from '@apollo/client'
import { DndContext, DragEndEvent, DragOverlay, pointerWithin, useDndContext } from '@dnd-kit/core'
import { snapCenterToCursor } from '@dnd-kit/modifiers'
import { loadFilesFromCloud } from '../../../graphql'
import {
  removeCloudFile,
  setCloudFiles,
  setCloudFolders,
  setCloudParent,
  useAppDispatch,
  useAppSelector
} from '../../../redux'
import { CloudContentType } from '../../../types'
import { useCloud, useCreate, useSensors } from '../../../utils'
import { CloudFolderListItem, CloudUploadItem, CloudUploadItem_Skeleton, EmptyState } from '../..'
import { StyledCreateBarUploadsList } from '.'

const CreateBarUploadsList: FC = () => {
  const apollo = useApolloClient()
  const sensors = useSensors()
  const dispatch = useAppDispatch()
  const { parent, folders, files } = useAppSelector((state) => state.cloud)
  const { addMediaToPost } = useCreate()
  const { updateCloudFileData } = useCloud()
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(true)

  const loadFolders = async () => {
    try {
      const { data } = await apollo.query({
        fetchPolicy: 'no-cache',
        query: loadFilesFromCloud,
        variables: {
          page: 0,
          parent,
          limit: 100,
          types: ['folder']
        }
      })

      const docs = data?.loadFilesFromCloud?.docs || []
      dispatch(setCloudFolders(docs))
    } catch (err) {
      console.log(err)
      toast.error((err as any).message)
    } finally {
      setLoading(false)
    }
  }

  const loadFiles = async () => {
    try {
      setLoading(true)

      const { data } = await apollo.query({
        fetchPolicy: 'no-cache',
        query: loadFilesFromCloud,
        variables: {
          page,
          parent,
          types: ['file', 'design'],
          limit: 20
        }
      })

      const docs = data?.loadFilesFromCloud?.docs || []
      dispatch(setCloudFiles(uniqBy([...files, ...docs], '_id')))
      setTotal(data?.loadFilesFromCloud?.totalDocs || 0)
    } catch (err) {
      console.log(err)
      toast.error((err as any).message)
    } finally {
      setLoading(false)
    }
  }

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event
    const content = active.data.current?.content as CloudContentType
    const folder = over?.data.current?.folder as CloudContentType

    if (!content || !folder) {
      return
    }

    if (content.parent === over?.id) {
      return
    }

    await updateCloudFileData({
      ...content,
      parent: folder._id
    })

    dispatch(removeCloudFile(content._id))
  }

  const handleChangeParent = (parent: string) => {
    dispatch(setCloudFiles([]))
    dispatch(setCloudFolders([]))
    dispatch(setCloudParent(parent))
  }

  useEffect(() => {
    loadFiles()
    loadFolders()
  }, [page, parent])

  useEffect(() => {
    dispatch(setCloudFiles([]))
    dispatch(setCloudFolders([]))
    dispatch(setCloudParent(''))

    return () => {
      dispatch(setCloudFiles([]))
      dispatch(setCloudFolders([]))
      dispatch(setCloudParent(''))
    }
  }, [])

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={pointerWithin}
      onDragEnd={(event) => handleDragEnd(event)}
    >
      <StyledCreateBarUploadsList className="scrollable" id="uploads-file-list">
        {parent && (
          <div className="parent-header">
            <button onClick={() => handleChangeParent('')}>All uploads</button>
            <span>/</span>
            <button>{parent}</button>
          </div>
        )}
        <InfiniteScroll
          scrollThreshold={0.7}
          dataLength={files.length}
          next={() => setPage(page + 1)}
          hasMore={files.length < total}
          scrollableTarget="uploads-file-list"
          loader={null}
        >
          <div className="uploads-file-list">
            {!parent &&
              folders.map((folder) => {
                return (
                  <CloudFolderListItem
                    folder={folder}
                    onClick={() => handleChangeParent(folder.name)}
                    key={folder._id}
                  />
                )
              })}
            {loading &&
              page === 0 &&
              times(10).map((index) => {
                return <CloudUploadItem_Skeleton key={index} />
              })}
            {files.map((file) => {
              return (
                <CloudUploadItem
                  content={file}
                  onClick={() => addMediaToPost(file, (file.file?.type as any) || 'image', true)}
                  key={file._id}
                />
              )
            })}
            {!files.length && !loading && (
              <EmptyState
                title="Nothing to see here"
                body="Upload a photo or video to see it here."
              />
            )}
          </div>
        </InfiniteScroll>
        <FileOverlay />
      </StyledCreateBarUploadsList>
    </DndContext>
  )
}

const FileOverlay = () => {
  const { active } = useDndContext()

  return (
    <DragOverlay modifiers={[snapCenterToCursor]}>
      <CloudUploadItem content={active?.data?.current?.content} isOverlay />
    </DragOverlay>
  )
}

export default CreateBarUploadsList
