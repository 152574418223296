import { useContext } from 'react'
import { toast } from 'react-hot-toast'
import { useApolloClient } from '@apollo/client'
import { connectionStore } from '../../../components'
import { socialProfileRemoveByIdMutation } from '../../../graphql'
import { SocialProfileType } from '../../../types'
import { userStore } from '../../UserContext'
import { useSegment } from '../useSegment'

export const useConnection = () => {
  const apollo = useApolloClient()
  const { track } = useSegment()
  const { connectPlatform } = useContext(connectionStore)
  const { refresh } = useContext(userStore)

  const disconnectProfile = async (profile: SocialProfileType) => {
    try {
      if (!profile) {
        return
      }

      await apollo.mutate({
        mutation: socialProfileRemoveByIdMutation,
        variables: { _id: profile._id }
      })

      toast.success(`${profile.username} was successfully disconnected!`)
      track('Disconnected Social Profile', profile)
      refresh()
    } catch (err) {
      toast.error('There was an error disconnecting that profile.')
    }
  }

  const reconnectProfile = async (profile: SocialProfileType) => {
    try {
      await connectPlatform(profile.type)
    } catch (err) {
      toast.error('There was an error disconnecting that profile.')
    }
  }

  return {
    disconnectProfile,
    reconnectProfile
  }
}
