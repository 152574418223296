import { FC, TouchEvent, createRef, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Dialog } from '@capacitor/dialog'
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import { setContentTab, setShowCreate, useAppDispatch, useAppSelector } from '../../../../redux'
import { useCreate, useHaptics, useUploadManager } from '../../../../utils'
import { MOBILE_ROUTES } from '../../../utils'
import { PullDown } from '../..'
import { CreateMediaItem } from '..'
import { StyledCreateMedia } from '.'

const CreateMedia: FC = () => {
  const inputRef = createRef<HTMLInputElement>()
  const addBtnRef = createRef<HTMLButtonElement>()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const [showAddOptions, setShowAddOptions] = useState(false)
  const [index, setIndex] = useState(0)
  const { addToUploads } = useUploadManager()
  const { removeMediaFromPost } = useCreate()
  const { trigger } = useHaptics()
  const { post } = useAppSelector((state) => state.create)
  const { files } = useAppSelector((state) => state.upload)

  const handleGallery = () => {
    history.push(MOBILE_ROUTES.content)
    dispatch(setContentTab('photos'))
    dispatch(setShowCreate(false))
  }

  const handleUploads = () => {
    history.push(MOBILE_ROUTES.content)
    dispatch(setContentTab('uploads'))
    dispatch(setShowCreate(false))
  }

  const handleTouchStart = (e: TouchEvent) => {
    const { nativeEvent } = e as any

    if (!nativeEvent) {
      return
    }

    nativeEvent.defaultMuiPrevented = true
    nativeEvent.muiHandled = true
  }

  const handleRemoveMedia = async () => {
    const item = (post?.media || [])[index]

    if (!item) {
      return
    }

    const { value: confirmed } = await Dialog.confirm({
      title: `Remove ${item.type}`,
      message: `Are you sure you want to remove this ${item.type}?`
    })

    if (!confirmed) {
      return
    }

    await removeMediaFromPost(item._id)
  }

  useEffect(() => {
    return () => {
      trigger('impact-light')
    }
  }, [index])

  if (!post?.media?.length) {
    return null
  }

  return (
    <>
      <StyledCreateMedia onTouchStart={handleTouchStart}>
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          onRealIndexChange={(swiper) => setIndex(swiper.realIndex)}
        >
          {post.media.map((item) => {
            return (
              <SwiperSlide>
                <CreateMediaItem media={item} key={item._id} />
              </SwiperSlide>
            )
          })}
        </Swiper>
        {post.media.length > 1 && (
          <div className="index-indicator">
            {index + 1} / {post.media.length}
          </div>
        )}
        {post.media.length < 10 && (
          <button
            className="add-btn action-btn"
            data-active={showAddOptions}
            ref={addBtnRef}
            onClick={() => setShowAddOptions(!showAddOptions)}
          >
            <PlusIcon />
          </button>
        )}
        <button className="delete-btn action-btn" onClick={() => handleRemoveMedia()}>
          <TrashIcon />
        </button>
      </StyledCreateMedia>
      {!files.length && (
        <PullDown
          rows={[
            {
              label: 'From Gallery',
              action: () => handleGallery(),
              type: 'button'
            },
            {
              label: 'From Uploads',
              action: () => handleUploads(),
              type: 'button'
            },
            {
              type: 'divider'
            },
            {
              label: (
                <>
                  <input
                    hidden
                    type="file"
                    accept="image/*, video/*"
                    multiple
                    ref={inputRef}
                    onChange={(e) => addToUploads(e.target.files, true, undefined)}
                  />
                  From Camera Roll
                </>
              ),
              stayOpen: true,
              action: () => inputRef.current?.click(),
              type: 'button'
            }
            // {
            //   label: 'Take Picture',
            //   action: () => takePhoto(),
            //   type: 'button'
            // }
          ]}
          position="top-start"
          targetRef={addBtnRef}
          isOpen={showAddOptions}
          onClose={() => setShowAddOptions(false)}
        />
      )}
    </>
  )
}

export default CreateMedia
