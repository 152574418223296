import styled from 'styled-components'

const StyledCreateView = styled.div`
  height: 100vh;
  background-color: ${({ theme }) => theme.color.gray[900]};
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);

  .create-layout {
    height: 100%;
    display: flex;
    padding: 10px;
    box-sizing: border-box;
    justify-content: center;
    overflow: hidden;
  }

  .create-editor-layout {
    max-width: 900px;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
  }

  .scrollable {
    overflow-y: overlay;

    &::-webkit-scrollbar {
      height: 0;
      position: absolute;
      width: 18px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10000px;
      border: 4px solid ${({ theme }) => theme.color.background};
      background: ${({ theme }) => theme.color.foreground}10;

      &:hover {
        background: ${({ theme }) => theme.color.foreground}20;
      }
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      width: 8px;
    }
  }
`

export default StyledCreateView
