import { FC } from 'react'
import { Toaster } from 'react-hot-toast'
import { Provider } from 'react-redux'
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { ApolloProvider } from '@apollo/client'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import {
  AppLayout,
  ConnectionContext,
  CouponState,
  PrivateRoute,
  ProductTestManager,
  ReferralTrack,
  TooltipContext,
  TourContext
} from './components'
import { persistor, store } from './redux'
import {
  Global,
  ROUTES,
  ThemeProvider,
  UserProvider,
  apolloClient,
  isMobile,
  useKeyboard
} from './utils'
import {
  AcademyView,
  AccountView,
  ActionPlanView,
  ActionPlansView,
  AdminLoginAsUserView,
  AssetsView,
  BillingView,
  CalendarView,
  ClaimTrialView,
  CloudView,
  CommunityView,
  ConnectPlatformView,
  ContentLibraryView,
  CreateView,
  DiagnosticsView,
  ForgotPasswordView,
  Home2View,
  HomeView,
  LinkPageView,
  LoginView,
  MobileBlockView,
  NewPageView,
  OnboardingView,
  PersonasView,
  PillarsView,
  PlanView,
  ProView,
  ProductTestView,
  ReferralsView,
  RegisterView,
  ResetPasswordView,
  UploadsView,
  UserPageView,
  WorkspaceInviteView
} from './views'
import 'react-spring-bottom-sheet/dist/style.css'
import 'react-photo-view/dist/react-photo-view.css'
import 'react-circular-progressbar/dist/styles.css'
import 'react-advanced-cropper/dist/style.css'
import 'swiper/swiper.min.css'

const App: FC = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider>
          <Global />
          <ApolloProvider client={apolloClient}>
            <Elements stripe={loadStripe(process.env.REACT_APP_STRIPE_ELEMENTS_KEY as string)}>
              <Router>
                <UserProvider>
                  <ConnectionContext>
                    <TourContext>
                      <Switch>
                        {/* ADMIN */}
                        <Route
                          path={ROUTES.admin.loginAsUser}
                          component={AdminLoginAsUserView}
                          exact
                        />

                        <Route path={ROUTES.connect} component={ConnectPlatformView} exact />
                        <Route path={ROUTES.page.userPage} component={UserPageView} exact />
                        <Route path={ROUTES.affiliate} component={ReferralTrack} exact />
                        <Route path={ROUTES.productTest} component={ProductTestView} exact />
                        {/* {!localStorage.getItem('insider-id') && (
                        <Route path="*" component={InsidersView} />
                      )} */}
                        <Route
                          path={[ROUTES.auth.register, ROUTES.old.register]}
                          component={RegisterView}
                        />
                        <Route
                          path={[ROUTES.auth.login, ROUTES.auth.returning, ROUTES.old.login]}
                          component={LoginView}
                        />
                        <Route path={ROUTES.auth.forgotPassword} component={ForgotPasswordView} />
                        <Route path={ROUTES.auth.resetPassword} component={ResetPasswordView} />
                        <Route
                          path={ROUTES.workspaces.invite}
                          component={WorkspaceInviteView}
                          exact
                        />
                        <Route path={ROUTES.diagnostics} component={DiagnosticsView} exact />
                        <PrivateRoute
                          userRole={['admin', 'basic', 'course']}
                          path={ROUTES.onboarding}
                          component={OnboardingView}
                          exact
                        />
                        <PrivateRoute
                          userRole={['admin', 'basic', 'course']}
                          path={ROUTES.claim}
                          component={ClaimTrialView}
                          exact
                        />
                        <PrivateRoute
                          userRole={['admin', 'basic', 'course']}
                          path={ROUTES.plan}
                          component={PlanView}
                          exact
                        />
                        {isMobile() && (
                          <PrivateRoute
                            userRole={['admin', 'basic', 'course']}
                            path={[ROUTES.community]}
                            component={CommunityView}
                          />
                        )}
                        {isMobile() && (
                          <PrivateRoute
                            userRole={['admin', 'basic', 'course']}
                            path="*"
                            component={MobileBlockView}
                          />
                        )}
                        <Route
                          path={[
                            ROUTES.old.courses,
                            ROUTES.old.coursePreview,
                            ROUTES.old.lesson,
                            ROUTES.academy.main,
                            ROUTES.academy.preview,
                            ROUTES.academy.lesson
                          ]}
                          component={AcademyView}
                          exact
                        />
                        <AppLayout>
                          <Switch>
                            <PrivateRoute
                              userRole={['admin', 'basic']}
                              path={[
                                ROUTES.home,
                                ROUTES.myPosts,
                                ROUTES.old.drafts,
                                ROUTES.scheduled,
                                ROUTES.myDesigns,
                                ROUTES.postTemplates.all
                              ]}
                              component={Home2View}
                              exact
                            />
                            <PrivateRoute
                              userRole={['admin', 'basic']}
                              path={[ROUTES.create, ROUTES.posteditor]}
                              component={CreateView}
                              exact
                            />
                            <PrivateRoute
                              userRole={['admin', 'basic']}
                              path={ROUTES.calendar}
                              component={CalendarView}
                            />
                            <PrivateRoute
                              userRole={['admin', 'basic']}
                              path={[ROUTES.uploads.folder, ROUTES.uploads.all, ROUTES.cloud.all]}
                              component={UploadsView}
                            />
                            <PrivateRoute
                              userRole={['admin', 'basic']}
                              path={ROUTES.assets.all}
                              component={AssetsView}
                            />
                            {/* <PrivateRoute
                              userRole={['admin', 'basic']}
                              path={[ROUTES.cloud.path, ROUTES.cloud.all]}
                              component={CloudView}
                            /> */}
                            <PrivateRoute
                              userRole={['admin', 'basic']}
                              path={[
                                ROUTES.contentLibrary.main,
                                ROUTES.old.captions,
                                ROUTES.old.stories,
                                ROUTES.old.gallery
                              ]}
                              component={ContentLibraryView}
                            />
                            <PrivateRoute
                              userRole={['admin', 'basic']}
                              path={[ROUTES.actionPlans, ROUTES.old.issues]}
                              component={ActionPlansView}
                              exact
                            />
                            <Route
                              path={[ROUTES.actionPlan, ROUTES.old.issue]}
                              component={ActionPlanView}
                            />
                            <PrivateRoute
                              userRole={['admin', 'basic']}
                              path={ROUTES.community}
                              component={CommunityView}
                            />
                            <PrivateRoute
                              userRole={['admin', 'basic']}
                              path={ROUTES.linkPage.page}
                              component={LinkPageView}
                              exact
                            />
                            <PrivateRoute
                              userRole={['admin', 'basic']}
                              path={ROUTES.linkPage.newPage}
                              component={NewPageView}
                              exact
                            />
                            <PrivateRoute
                              userRole={['admin', 'basic', 'course']}
                              path={ROUTES.settings.account}
                              component={AccountView}
                              exact
                            />
                            <PrivateRoute
                              userRole={['admin', 'basic', 'course']}
                              path={ROUTES.settings.personas}
                              component={PersonasView}
                              exact
                            />
                            <PrivateRoute
                              userRole={['admin', 'basic', 'course']}
                              path={ROUTES.settings.pillars}
                              component={PillarsView}
                              exact
                            />
                            <PrivateRoute
                              userRole={['admin', 'basic', 'course']}
                              path={ROUTES.settings.billing}
                              component={BillingView}
                              exact
                            />
                            {/* <PrivateRoute
                              userRole={['admin', 'basic']}
                              path={ROUTES.workspaces.workspace}
                              component={WorkspaceView}
                              exact
                            />
                            <PrivateRoute
                              userRole={['admin', 'basic']}
                              path={ROUTES.workspaces.new}
                              component={NewWorkspaceView}
                              exact
                            /> */}
                            <PrivateRoute
                              userRole={['admin', 'basic']}
                              path={[ROUTES.referrals, ROUTES.old.affiliate]}
                              component={ReferralsView}
                              exact
                            />
                            <PrivateRoute
                              userRole={['admin', 'basic', 'course']}
                              path={ROUTES.pro}
                              component={ProView}
                              exact
                            />
                            <Route path="*">
                              <Redirect to={ROUTES.home} />
                            </Route>
                          </Switch>
                        </AppLayout>
                      </Switch>
                      <Toaster
                        position="bottom-center"
                        containerStyle={{ zIndex: 9999999999999 }}
                      />
                      <CouponState />
                      {/* {!isMobile() && <ProductTestManager />} */}
                      <TooltipContext />
                    </TourContext>
                  </ConnectionContext>
                </UserProvider>
              </Router>
            </Elements>
          </ApolloProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
