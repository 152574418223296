export const day3 = {
  _id: 'day3',
  title: 'DAY 3',
  tasks: [
    {
      label: 'Create your Link Page',
      steps: [
        {
          label: 'Navigate to your Link Page using the sidebar'
        },
        {
          label: 'Create a username and verify your name & bio'
        },
        {
          label: 'Click the “Create Link Page” button'
        }
      ]
    },
    {
      label: 'Add at least one Link block to your Link Page',
      steps: [
        {
          label: 'In the Layout tab of your Link Page, click the “Add Block” button'
        },
        {
          label: 'Label your link, and fill in the link’s URL'
        },
        {
          label: 'When finished, click the “Add block” button'
        }
      ]
    },
    {
      label: 'Change the background color and font style of your Link Page',
      steps: [
        {
          label: 'Changing the style of your Link Page can be done from the Style tab'
        }
      ]
    },
    {
      label: 'Copy the URL of your Link Page to your clipboard',
      steps: [
        {
          label: 'Click the copy button beside your username in the top-left corner of the page'
        }
      ]
    },
    {
      label: 'Add a Header block to your Link Page',
      steps: [
        {
          label: 'In the Layout tab of your Link Page, click the “Add Block” button'
        },
        {
          label: 'Label your header'
        },
        {
          label: 'When finished, click the “Add block” button'
        }
      ]
    }
  ],
  form: {
    url: 'https://notionforms.io/forms/day-3-feedback',
    keys: {
      FULL_NAME: '0ec8136e-e5fc-4a8d-85d4-1b867e63f83f',
      EMAIL: '0d2cbbbf-b6b8-4007-b5ee-e050c002eff4',
      SCREEN_RECORDING: '2bb73c7e-a09b-4be1-b15f-59d16ccb60b6',
      USER_ID: 'c1541eeb-38c0-49a2-9cac-89e47f6ed2a0'
    }
  }
}
