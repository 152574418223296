import styled from 'styled-components'

const StyledPublishWhereField = styled.div`
  .connections-select {
    height: 42px;
    display: flex;
    align-items: center;
    margin-top: 10px;
    position: relative;
    border-radius: 6px;
    transition: box-shadow 0.2s;
    background-color: transparent;
    width: 100%;
    padding: 0 38px 0 10px;
    box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.15),
      0px 2px 3px rgba(0, 0, 0, 0.05);

    .connection-select-placeholder {
      color: ${({ theme }) => theme.color.gray[400]};
    }

    .connection-select-profiles {
      display: flex;
      align-items: center;
      margin-left: -4px;

      .profile-list {
        margin-left: 8px;
        color: ${({ theme }) => theme.color.gray[700]};
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-align: left;

        mark {
          color: ${({ theme }) => theme.color.gray[700]};
          background-color: transparent;
        }
      }
    }

    .chevron-icon {
      position: absolute;
      pointer-events: none;
      color: ${({ theme }) => theme.color.gray[400]};
      right: 8px;
      height: 24px;
      stroke-width: 1.75px;
    }

    &:hover {
      cursor: pointer;
      box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.2), 0px 0px 0px 1px rgba(0, 0, 0, 0.15),
        0px 2px 3px rgba(0, 0, 0, 0.15);
    }
  }
`

export default StyledPublishWhereField
