import styled from 'styled-components'

const StyledUpsell = styled.div`
  .upsell-container {
    max-width: 700px;
    box-sizing: border-box;
    padding: 20px;

    .icon-row {
      display: flex;
      align-items: center;
      margin: 0 auto;
      width: fit-content;

      div {
        height: 42px;
        width: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        position: relative;
        background-color: ${({ theme }) => theme.color.gray[200]};
        color: ${({ theme }) => theme.color.gray[500]};
        box-shadow: 0 0 0 3px ${({ theme }) => theme.color.background};

        svg {
          height: 22px;
        }

        &:nth-of-type(1) {
          z-index: 10;
        }

        &:nth-of-type(2) {
          z-index: 9;
        }

        &:nth-of-type(3) {
          z-index: 8;
        }

        &:nth-of-type(4) {
          z-index: 7;
        }

        &:nth-of-type(5) {
          z-index: 6;
        }

        &:not(:last-child) {
          margin-right: -4px;
        }
      }
    }

    h1 {
      font-size: 22px;
      font-weight: 500;
      text-align: center;
      margin-top: 20px;
      color: ${({ theme }) => theme.color.gray[800]};
    }

    h2 {
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      margin-top: 6px;
      color: ${({ theme }) => theme.color.gray[500]};
    }

    .go-pro-faq {
      border: 1px solid ${({ theme }) => theme.color.gray[300]};
      border-radius: 10px;
      margin: 20px auto 0 auto;
      max-width: 500px;

      details {
        summary {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 16px 20px;
          font-weight: 500;
          user-select: none;
          transition: background-color 0.2s;
          color: ${({ theme }) => theme.color.gray[800]};

          svg {
            height: 22px;
            color: ${({ theme }) => theme.color.gray[400]};
            stroke-width: 2px;
            transition: 0.2s;
          }

          &:hover {
            cursor: pointer;
            background-color: ${({ theme }) => theme.color.gray[100]};
          }

          &::-webkit-details-marker,
          &::marker {
            display: none;
            content: '';
          }
        }

        .summary-content {
          padding: 0 20px 16px 20px;
          font-size: 15px;
          color: ${({ theme }) => theme.color.gray[600]};

          a {
            text-decoration: underline;
            color: ${({ theme }) => theme.color.brand};
          }
        }

        &[open] {
          summary {
            svg {
              transform: rotate(180deg);
            }
          }
        }

        &:first-child summary {
          border-radius: 9px 9px 0 0;
        }

        &:last-child:not([open]) summary {
          border-radius: 0 0 9px 9px;
        }

        &:not(:last-child) {
          border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
        }
      }
    }

    .go-pro-btn {
      margin: 20px auto 0 auto;
      height: 46px;
      display: flex;
      align-items: center;
      font-weight: 600;
      padding: 0 20px;
      border-radius: 50rem;
      background: linear-gradient(
            ${({ theme }) => theme.color.background},
            ${({ theme }) => theme.color.background}
          )
          padding-box,
        linear-gradient(-25deg, #1fa4fd, #e89af3, ${({ theme }) => theme.color.brand}) border-box;
      border-radius: 50em;
      border: 2px solid transparent;
      color: ${({ theme }) => theme.color.brand};

      svg {
        height: 20px;
        margin-right: 6px;
      }

      &:hover {
        background: ${({ theme }) => theme.color.foreground};
        color: ${({ theme }) => theme.color.background};
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    .upsell-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      max-height: 100vh;
      padding: 50px;

      .go-pro-faq {
        max-width: 400px;
        margin: 40px auto 0 auto;
      }

      .go-pro-btn {
        margin: 40px auto 0 auto;
      }
    }
  }
`

export default StyledUpsell
