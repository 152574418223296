import { FC, ReactNode } from 'react'
import { StyledOnboardingStep } from '.'

type Props = {
  children: ReactNode
}

const OnboardingStep: FC<Props> = ({ children }) => {
  return <StyledOnboardingStep>{children}</StyledOnboardingStep>
}

export default OnboardingStep
