import { FC } from 'react'
import { useAppSelector } from '../../../redux'
import { ChatConversationList, ChatHeader, ChatHistoryList, ChatInput } from '../..'
import { CreateBarPanel } from '..'
import { StyledCreateBarChat } from '.'

const CreateBarChat: FC = () => {
  const { showHistory } = useAppSelector((state) => state.chat)

  return (
    <CreateBarPanel>
      <StyledCreateBarChat>
        <ChatHeader />
        <ChatConversationList />
        <ChatInput />
        {showHistory && <ChatHistoryList />}
      </StyledCreateBarChat>
    </CreateBarPanel>
  )
}

export default CreateBarChat
