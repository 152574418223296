import { FC, ReactNode } from 'react'
import {
  CarouselTour,
  ConnectSocialProfileTour,
  DailyActionStreaksTour,
  DailyActionTour,
  LinkPageTour,
  NewPostTour,
  PostEditorTour,
  PostEditorTypeHashtagTour,
  UploadManagerTour
} from '..'

type Props = {
  children: ReactNode
}

const TourContext: FC<Props> = ({ children }) => {
  return (
    <>
      <CarouselTour />
      <ConnectSocialProfileTour />
      <NewPostTour />
      <DailyActionStreaksTour />
      <DailyActionTour />
      <LinkPageTour />
      <PostEditorTour />
      <PostEditorTypeHashtagTour />
      <UploadManagerTour />
      {children}
    </>
  )
}

export default TourContext
