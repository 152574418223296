import { FC } from 'react'
import { updateCreatePost, useAppDispatch, useAppSelector } from '../../../../redux'
import { conditionalStage } from '../../../../utils'
import { CreateEditorSubConfig } from '../..'

export const InstagramPublishTypeConfig: FC = () => {
  const dispatch = useAppDispatch()
  const { post } = useAppSelector((state) => state.create)

  const handleChange = (value: any) => {
    dispatch(
      updateCreatePost({
        instagramPublishType: value
      })
    )
  }

  return (
    <CreateEditorSubConfig
      name="instagram_post_type"
      label="Instagram Post Type"
      description="Choose how your post is published on Instagram."
      type="radio"
      options={[
        {
          label: 'Feed',
          value: 'feed',
          checked: !post?.instagramPublishType || post.instagramPublishType === 'feed'
        },
        ...conditionalStage((post?.media?.length || 0) < 2, [
          {
            label: 'Reel',
            value: 'reel',
            info: 'Reels appear in the Reels tab of the Instagram app',
            checked: post?.instagramPublishType === 'reel'
          },
          {
            label: 'Story',
            value: 'story',
            checked: post?.instagramPublishType === 'story'
          }
        ])
      ]}
      platforms={['instagram']}
      onSelect={(value) => handleChange(value)}
    />
  )
}
