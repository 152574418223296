import styled from 'styled-components'

const StyledHomeTop = styled.div`
  padding: 30px;
  overflow: hidden;
  position: relative;

  .width-controller {
    display: grid;
    gap: 26px;
    position: relative;
    z-index: 1;
  }

  #gradient-canvas {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 200%;
    opacity: 1;
  }

  &[data-time='morning'] {
    #gradient-canvas {
      background-color: #ff80e0;
      --gradient-color-1: #ff80e0;
      --gradient-color-2: #ff540a;
      --gradient-color-3: #ff2d9d;
      --gradient-color-4: #ff804e;
    }
  }

  &[data-time='afternoon'] {
    #gradient-canvas {
      background-color: #494fe1;
      --gradient-color-1: #494fe1;
      --gradient-color-2: #408dff;
      --gradient-color-3: #2d32ff;
      --gradient-color-4: #4066f5;
    }
  }

  &[data-time='evening'] {
    #gradient-canvas {
      background-color: #210b60;
      --gradient-color-1: #210b60;
      --gradient-color-2: #3a085d;
      --gradient-color-3: #10085c;
      --gradient-color-4: #0c226e;
    }
  }
`

export default StyledHomeTop
