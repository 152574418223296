import styled from 'styled-components'

type StyledProps = {
  isOver?: boolean
}

const StyledCalendarWeekHour = styled.div<StyledProps>`
  min-height: 120px;
  overflow: hidden;
  padding: 2px;
  width: calc((100vw) / 6.999);
  box-sizing: border-box;
  transition: background-color 0.2s;
  border-right: 1px solid ${({ theme }) => theme.color.gray[300]};

  .hour-header {
    font-size: 10px;
    font-weight: 600;
    text-align: right;
    padding-right: 2px;
    padding-top: 2px;
    color: ${({ theme }) => theme.color.gray[300]};
  }

  ${({ isOver, theme }) =>
    isOver &&
    `
    background-color: ${theme.color.brandLight};


    .hour-header {
      color: ${theme.color.brand};
    }
  `}
`

export default StyledCalendarWeekHour
