import { FC, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { DateTime } from 'luxon'
import {
  clearCoupon,
  pushToDialogStack,
  setCoupon,
  useAppDispatch,
  useAppSelector
} from '../../redux'
import { getCoupon } from '../../utils'
import { StyledCouponState } from '.'

const CouponState: FC = () => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { coupon } = useAppSelector((state) => state.state)

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    const params = Object.fromEntries(urlParams)
    const { coupon: cParam, psd } = params

    urlParams.delete('coupon')
    urlParams.delete('psd')

    history.replace({
      search: urlParams.toString()
    })

    // if (!cParam) {
    //   return
    // }

    dispatch(clearCoupon())

    const c = getCoupon(cParam, psd, true)

    if (!c) {
      return
    }

    // dispatch(pushToDialogStack('go-pro'))
    dispatch(setCoupon(c))
  }, [location.search, location.state])

  useEffect(() => {
    if (!!coupon && coupon.expires <= DateTime.now().toSeconds()) {
      dispatch(clearCoupon())
    }
  }, [coupon])

  return null
}

export default CouponState
