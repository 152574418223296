import styled from 'styled-components'

const StyledReferralActivity = styled.div`
  p {
    b {
      font-weight: 600;
    }

    &.small {
      font-size: 13px;
      line-height: initial;
      margin-top: 4px;
      color: ${({ theme }) => theme.color.gray[500]};
    }
  }

  .empty-state {
    margin-top: 30px;
  }

  .active-list {
    margin-top: 30px;
    display: grid;
    row-gap: 16px;
    position: relative;

    .active-row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .user-details-container {
        display: flex;
        align-items: center;

        .avatar {
          flex-shrink: 0;
        }

        .user-details {
          margin-left: 12px;

          span {
            display: block;
          }

          .user-name {
            font-size: 15px;
            font-weight: 500;
          }

          .description {
            font-size: 13px;
            color: ${({ theme }) => theme.color.gray[600]};
          }
        }
      }

      .timestamp {
        font-size: 13px;
        font-weight: 500;
        color: ${({ theme }) => theme.color.gray[600]};
        border: 1px solid ${({ theme }) => theme.color.gray[300]};
        padding: 6px 10px;
        border-radius: 50rem;
      }
    }

    .more-indicator-container {
      position: absolute;
      height: 200px;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
      background: linear-gradient(
        ${({ theme }) => theme.color.background}00,
        ${({ theme }) => theme.color.background}
      );

      .more-indicator {
        border-radius: 50rem;
        display: flex;
        align-items: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        padding: 0 16px 0 12px;
        height: 40px;
        font-weight: 500;
        font-size: 15px;
        color: ${({ theme }) => theme.color.gray[800]};
        background-color: ${({ theme }) => theme.color.background};
        box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
          0px 2px 3px rgba(0, 0, 0, 0.05);

        svg {
          color: ${({ theme }) => theme.color.gray[600]};
          height: 22px;
          stroke-width: 1.8px;
          margin-right: 6px;
        }
      }
    }
  }
`

export default StyledReferralActivity
