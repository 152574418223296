import { gql } from '@apollo/client'

export const getAdAccounts = gql`
  query GetAdAccounts {
    getAdAccounts {
      id
      username
      profile_pic
      page_id
    }
  }
`
