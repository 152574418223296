import { FC, useContext } from 'react'
import { useAppSelector } from '../../../redux'
import { SOCIAL_PROFILE_PLATFORMS } from '../../../types'
import { conditionalStage } from '../../../utils'
import { ConnectionPlatformListItem, connectionStore } from '..'
import { StyledConnectionPlatformList } from '.'

const ConnectionPlatformList: FC = () => {
  const { platformCounts } = useContext(connectionStore)
  const { splits } = useAppSelector((state) => state.splits)

  const comingSoon = ['linkedin-page', 'pinterest']
  const excluded = [
    ...conditionalStage(platformCounts.twitter === 0 && splits.twitter !== 'on', ['twitter']),
    ...conditionalStage(splits.tikTok !== 'on', ['tiktok'])
  ]

  return (
    <StyledConnectionPlatformList>
      {SOCIAL_PROFILE_PLATFORMS.filter((p) => !excluded.includes(p)).map((platform) => {
        return (
          <ConnectionPlatformListItem
            platform={platform as any}
            isDisabled={comingSoon.includes(platform)}
            key={platform}
          />
        )
      })}
    </StyledConnectionPlatformList>
  )
}

export default ConnectionPlatformList
