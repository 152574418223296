import { BellIcon, BoltIcon, CheckCircleIcon } from '@heroicons/react/20/solid'
import { DocumentTextIcon } from '@heroicons/react/24/solid'
import { PostType } from '../../types'

export const getPostStatus = (post?: PostType | Partial<PostType>) => {
  if (!!post?.isUsed) {
    return (
      <>
        <CheckCircleIcon /> Published
      </>
    )
  }

  if (!post?.postDate || !post) {
    return (
      <>
        <DocumentTextIcon /> Draft
      </>
    )
  }

  if (!post.socialProfiles?.length) {
    return (
      <>
        <BellIcon /> Reminder
      </>
    )
  }

  return (
    <>
      <BoltIcon /> Auto-Publish
    </>
  )
}
