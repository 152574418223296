import { FC, RefObject } from 'react'
import { Popover, Range } from '../..'
import { StyledSpacingPopover } from '.'

type Props = {
  charSpacing: number
  lineHeight: number
  position?: 'top-left' | 'top-center' | 'bottom-right' | 'bottom-left'
  classToAvoid: string
  buttonRef: RefObject<HTMLDivElement | HTMLButtonElement>
  isOpen: boolean
  onCharSpacingChange: (value: number) => void
  onLineHeightChange: (value: number) => void
  onClose: () => void
}

const SpacingPopover: FC<Props> = ({
  charSpacing,
  lineHeight,
  position = 'bottom-right',
  classToAvoid,
  buttonRef,
  isOpen,
  onCharSpacingChange,
  onLineHeightChange,
  onClose
}) => {
  return (
    <Popover
      position={position}
      classToAvoid={classToAvoid}
      buttonRef={buttonRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <StyledSpacingPopover>
        <Range
          label="Letter Spacing"
          value={charSpacing}
          min={-20}
          onChange={(value) => onCharSpacingChange(Math.round(value))}
        />
        <Range
          label="Line Spacing"
          value={lineHeight}
          onChange={(value) => onLineHeightChange(Math.round(value))}
        />
      </StyledSpacingPopover>
    </Popover>
  )
}

export default SpacingPopover
