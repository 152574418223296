import { FC } from 'react'
import { ShepherdOptionsWithType, ShepherdTour, TourMethods } from 'react-shepherd'
import { TourEvents } from '..'
import { StyledTour } from '.'

type Props = {
  _id: string
  steps?: ShepherdOptionsWithType[]
  canStart?: boolean
  showConfetti?: boolean
  onTourComplete?: () => void
}

const Tour: FC<Props> = ({ _id, steps, canStart, showConfetti, onTourComplete }) => {
  if (!steps?.length) {
    return <></>
  }

  return (
    <>
      <StyledTour />
      <ShepherdTour
        steps={steps}
        tourOptions={{
          tourName: _id,
          defaultStepOptions: {
            arrow: false,
            cancelIcon: { enabled: true },
            modalOverlayOpeningRadius: 6,
            popperOptions: { modifiers: [{ name: 'offset', options: { offset: [0, 10] } }] }
          },
          useModalOverlay: true,
          exitOnEsc: true
        }}
      >
        <TourMethods
          children={(tour) => (
            <TourEvents
              tour={tour}
              tourId={_id}
              canStart={canStart}
              showConfetti={showConfetti}
              onTourComplete={onTourComplete}
            />
          )}
        />
      </ShepherdTour>
    </>
  )
}

export default Tour
