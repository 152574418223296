import { FC, useState } from 'react'
import AutosizeInput from 'react-input-autosize'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { PersonaType } from '../../../types'
import { ages } from '../../../views/OnboardingView/data/ages'
import { occupations } from '../../../views/OnboardingView/data/occupations'
import { voices } from '../../../views/OnboardingView/data/voices'
import { ActionDialog, Button } from '../..'
import { PersonaInput } from '../PersonaInput'
import { StyledPersonaEdit } from '.'

type Props = {
  persona: PersonaType
  isDefault?: boolean
  onUpdate: (personaValue: string | object, value?: any) => void
  onDelete?: () => void
}

const PersonaEdit: FC<Props> = ({ persona, isDefault, onUpdate, onDelete }) => {
  const [showDeletionConfirmation, setShowDeletionConfirmation] = useState(false)
  const [open, setOpen] = useState(isDefault)

  return (
    <>
      <StyledPersonaEdit open={open}>
        <div className="persona-heading" onClick={() => setOpen(!open)}>
          <div className="persona-heading-details">
            <AutosizeInput
              placeholder={isDefault ? 'Default Persona' : 'Untitled Persona'}
              type="text"
              value={persona.title}
              minLength={1}
              onChange={(e) => onUpdate('title', e.target.value?.trimStart())}
              onClick={(e) => e.stopPropagation()}
            />
            {isDefault && <span className="default-label">DEFAULT</span>}
          </div>
          <div className="persona-heading-actions">
            {onDelete && (
              <Button
                variant="destructive"
                onClick={(e) => {
                  e.stopPropagation()
                  setShowDeletionConfirmation(true)
                }}
              >
                Delete
              </Button>
            )}
            <ChevronDownIcon className="chevron-icon" />
          </div>
        </div>
        <div className="persona-inputs">
          <PersonaInput
            label="Occupation"
            description="What do you call yourself/work as?"
            options={occupations.map((o) => ({
              label: o,
              value: o
            }))}
            value={{
              label: persona.occupation,
              value: persona.occupation
            }}
            required
            isSearchable={true}
            isCreatable={true}
            onChange={(value) => onUpdate('occupation', value.label)}
          />
          <PersonaInput
            label="Business/brand name"
            description="What is the name of your business/brand?"
            value={persona.company}
            placeholder="Ex: Sally Smith Photography"
            isInput={true}
            onChange={(value) => {
              onUpdate('company', value)
            }}
          />
          <PersonaInput
            label="Voice(s)"
            description="How do you want your copy to sound?"
            value={persona?.voices?.map((voice: any) => ({
              label: voice,
              value: voice
            }))}
            required
            options={voices}
            forceMenu
            isMulti
            placeholder="Start typing or select one or more voices"
            onChange={(value) =>
              onUpdate(
                'voices',
                value.map((v: any) => v.label)
              )
            }
          />
          <PersonaInput
            label="Target audience's age(s)"
            options={ages.map((o) => ({
              label: o,
              value: o
            }))}
            value={persona?.ages?.map((age: string) => ({
              label: age,
              value: age
            }))}
            forceMenu
            isMulti
            placeholder="Start typing or select one or more ages"
            onChange={(value) =>
              onUpdate(
                'ages',
                value.map((v: any) => v.label)
              )
            }
          />
          <PersonaInput
            label="Target audience's gender(s)"
            value={persona?.genders?.map((g: string) => ({
              label: g,
              value: g.toLowerCase()
            }))}
            options={[
              { label: 'Men', value: 'men' },
              { label: 'Women', value: 'women' }
            ]}
            forceMenu
            isMulti
            placeholder="Start typing or select one or more genders"
            onChange={(value) =>
              onUpdate(
                'genders',
                value.map((v: any) => v.label)
              )
            }
          />
          <PersonaInput
            label="Target audience's occupation(s)"
            options={occupations.map((o) => ({
              label: `${o}s`,
              value: `${o}s`
            }))}
            value={persona?.occupations?.map((o: string) => ({
              label: o,
              value: o
            }))}
            isMulti
            placeholder="Start typing..."
            onChange={(value) =>
              onUpdate(
                'occupations',
                value.map((v: any) => v.label)
              )
            }
          />
        </div>
      </StyledPersonaEdit>
      {onDelete && (
        <ActionDialog
          type="error"
          title="Delete this persona?"
          body={`Are you sure you want to delete "${persona.title}"?`}
          confirmButtonText="Delete"
          isOpen={showDeletionConfirmation}
          onClose={() => setShowDeletionConfirmation(false)}
          onConfirm={() => onDelete()}
        />
      )}
    </>
  )
}

export default PersonaEdit
