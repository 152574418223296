import { FC, HTMLProps, ReactNode, createRef, useState } from 'react'
import classNames from 'classnames'
import { v4 as uuid } from 'uuid'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { OptionsPopover } from '../..'
import { StyledDropdown } from '.'

type Props = {
  size?: 'xs' | 's' | 'm'
  value?: string | string[]
  options: {
    icon?: ReactNode
    stayOpen?: boolean
    label: string
    value: string
  }[]
  popoverOptions?: (typeof OptionsPopover)['defaultProps']
  onSelect: (option: string) => void
} & Omit<HTMLProps<HTMLSelectElement>, 'onSelect' | 'size'>

const Dropdown: FC<Props> = ({
  size = 'm',
  value,
  placeholder,
  options,
  popoverOptions,
  className,
  onSelect
}) => {
  const btnRef = createRef<HTMLButtonElement>()
  const DID = `dropdown-${uuid()}`
  const [showOptions, setShowOptions] = useState(false)

  const getLabel = () => {
    if (!value || typeof value !== 'string') {
      return placeholder
    }

    const curr = options.find((option) => option.value === value)

    if (!curr) {
      return placeholder
    }

    return (
      <>
        {curr.icon}
        {curr.label}
      </>
    )
  }

  return (
    <>
      <StyledDropdown
        size={size}
        aria-expanded={showOptions}
        ref={btnRef}
        className={classNames('dropdown-btn', className, DID)}
        onClick={() => setShowOptions(!showOptions)}
      >
        <span className="dropdown-label">
          {typeof value !== 'string' && !!value?.length && (
            <div className="selected-count">{value.length}</div>
          )}
          {getLabel()}
        </span>
        <ChevronDownIcon className="chevron-icon" />
      </StyledDropdown>
      <OptionsPopover
        {...popoverOptions}
        options={options.map((option) => {
          return {
            ...option,
            isSelected:
              typeof value === 'string' ? option.value === value : value?.includes(option.value),
            action: () => onSelect(option.value)
          }
        })}
        position="bottom-right"
        buttonRef={btnRef}
        classToAvoid={DID}
        isOpen={showOptions}
        onClose={() => setShowOptions(false)}
      />
    </>
  )
}

export default Dropdown
