import { FC, ReactNode } from 'react'
import Sheet from 'react-modal-sheet'
import { StyledOptionsSheet } from '.'

type OptionType = {
  icon: ReactNode
  label: string
  variant?: 'default' | 'destructive'
  stayOpen?: boolean
  action: () => void
}

type Props = {
  options: OptionType[]
  isOpen: boolean
  onClose: () => void
}

const OptionsSheet: FC<Props> = ({ options, isOpen, onClose }) => {
  const handleClick = (option: OptionType) => {
    if (!option.stayOpen) {
      onClose()
    }

    option.action()
  }

  return (
    <Sheet detent="content-height" isOpen={isOpen} onClose={onClose}>
      <Sheet.Container style={{ overflow: 'hidden' }}>
        <Sheet.Header />
        <Sheet.Content>
          <StyledOptionsSheet>
            <div className="option-group">
              {options.map((option) => {
                return (
                  <button
                    className={option.variant}
                    onClick={() => handleClick(option)}
                    key={option.label}
                  >
                    {option.label} {option.icon}
                  </button>
                )
              })}
            </div>
          </StyledOptionsSheet>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={() => onClose()} />
    </Sheet>
  )
}

export default OptionsSheet
