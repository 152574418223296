export const SOCIAL_PROFILE_PLATFORMS = [
  'instagram',
  'facebook',
  'linkedin',
  // 'tiktok',
  // 'linkedin-page',
  'twitter'
  // 'pinterest'
]

export const SocialProfilePlaformType = [
  'instagram',
  'facebook',
  'linkedin',
  // 'linkedin-page',
  'twitter'
  // 'pinterest',
  // 'tiktok'
] as const

export type SocialProfileType = {
  _id: string | null
  type: (typeof SocialProfilePlaformType)[number]
  username: string
  avatarUrl: string | null
  profileId: string
  pageId: string | null
  accessToken: any | null
  pageAccessToken: any | null
  status: string
  isBusiness?: boolean
}
