import styled from 'styled-components'

const StyledCheckbox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 24px;
  width: 24px;

  input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    &:hover {
      cursor: pointer;
    }

    &:checked + .check-box {
      border: 1px solid ${({ theme }) => theme.color.gray[800]};
      background-color: ${({ theme }) => theme.color.gray[800]};
    }
  }

  .check-box {
    height: 24px;
    width: 24px;
    flex-shrink: 0;
    margin-right: 10px;
    border-radius: 6px;
    position: relative;
    border: 1px solid ${({ theme }) => theme.color.gray[300]};
    background-color: ${({ theme }) => theme.color.background};
    color: ${({ theme }) => theme.color.background};
    transition: 0.2s;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 20px;
      stroke-width: 2px;
    }
  }

  .check-box-label {
    color: ${({ theme }) => theme.color.gray[800]};
  }

  &:hover {
    .check-box {
      color: ${({ theme }) => theme.color.gray[300]};
    }

    input:checked + .check-box {
      border: 1px solid ${({ theme }) => theme.color.gray[900]};
      background-color: ${({ theme }) => theme.color.gray[900]};
      color: ${({ theme }) => theme.color.background};
    }
  }
`

export default StyledCheckbox
