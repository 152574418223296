import { FC, useEffect } from 'react'
import { fabric } from 'fabric'
import { IAllFilters } from 'fabric/fabric-impl'
import { findIndex } from 'lodash'
import { useActiveObject, useEditor } from '@layerhub-io/react'
import { Checkbox, Range } from '../../..'
import { useForm } from '../../../../utils'
import { DesignerPanel } from '../..'
import { StyledDesignerEditImagePanel } from '.'

const DesignerEditImagePanel: FC = () => {
  const activeObject = useActiveObject() as any
  const editor = useEditor()

  const EFFECTS = ['brightness', 'contrast', 'saturation', 'vibrance', 'noise', 'pixelate', 'blur']

  const defaultObject = {
    brightness: 0,
    contrast: 0,
    saturation: 0,
    blur: 0,
    tint: 0,
    grayscale: 0,
    invert: 0,
    vibrance: 0,
    pixelate: 0,
    noise: 0
  }

  const { updateFormValue, resetForm, form } = useForm(defaultObject)

  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const applyEffect = (value: number, type: string) => {
    const filters = activeObject.filters
    const key = capitalizeFirstLetter(type) as any as keyof IAllFilters
    const index = findIndex(filters, (val: any) => val[type] !== undefined)
    const filterIndex = index >= 0 ? index : filters.length
    const parsedValue = value / 100

    filters[filterIndex] = new fabric.Image.filters[key]({
      [type]: parsedValue
    })

    activeObject.applyFilters()
    editor.canvas.requestRenderAll()
    handleEffects()
  }

  const handleEffects = () => {
    const filters = activeObject?.filters || []
    const filterList: Object[] = []

    EFFECTS.map((filter: string) => {
      const index = findIndex(filters, (val: any) => val[filter])

      if (index < 0) {
        return
      }

      filterList.push({
        [filter]: parseInt((filters[index][filter] * 100).toString())
      })
    })

    const isGrayscale = !!filters.find((filter: any) => Object.keys(filter)[0] === 'grayscale')
    const isInverted = !!filters.find((filter: any) => Object.keys(filter)[0] === 'invert')

    updateFormValue({
      brightness: 0,
      contrast: 0,
      saturation: 0,
      blur: 0,
      tint: 0,
      vibrance: 0,
      pixelate: 0,
      noise: 0,
      ...Object.assign({}, ...filterList),
      grayscale: isGrayscale,
      invert: isInverted
    })
  }

  const toggleEffect = (type: string) => {
    const filters = activeObject.filters
    const key = capitalizeFirstLetter(type) as any as keyof IAllFilters
    const index = findIndex(filters, (val: any) => val[type] !== undefined)

    if (index === -1) {
      filters[filters.length] = new fabric.Image.filters[key]({
        [type]: 1
      })
    } else {
      filters.splice(index, 1)
    }

    activeObject.applyFilters()
    editor.canvas.requestRenderAll()
    handleEffects()
  }

  useEffect(() => {
    handleEffects()

    if (editor) {
      editor.on('history:changed', handleEffects)
    }
    return () => {
      if (editor) {
        editor.off('history:changed', handleEffects)
      }
    }
  }, [editor, activeObject])

  return (
    <DesignerPanel>
      <StyledDesignerEditImagePanel>
        <Range
          label={capitalizeFirstLetter('brightness')}
          value={form['brightness']}
          min={-100}
          onChange={(value) => applyEffect(value, 'brightness')}
        />
        <Range
          label={capitalizeFirstLetter('contrast')}
          value={form['contrast']}
          min={-100}
          onChange={(value) => applyEffect(value, 'contrast')}
        />
        <Range
          label={capitalizeFirstLetter('saturation')}
          value={form['saturation']}
          min={-100}
          onChange={(value) => applyEffect(value, 'saturation')}
        />
        <Range
          label={capitalizeFirstLetter('vibrance')}
          value={form['vibrance']}
          min={-100}
          onChange={(value) => applyEffect(value, 'vibrance')}
        />
        <Range
          label={capitalizeFirstLetter('noise')}
          value={form['noise']}
          min={0}
          max={10000}
          onChange={(value) => applyEffect(value, 'noise')}
        />
        <Range
          label={capitalizeFirstLetter('pixelate')}
          value={form['pixelate']}
          min={0}
          max={100}
          onChange={(value) => applyEffect(value, 'pixelate')}
        />
        <Range
          label={capitalizeFirstLetter('blur')}
          value={form['blur']}
          min={0}
          max={100}
          onChange={(value) => applyEffect(value, 'blur')}
        />
        <Checkbox
          value={!!form['grayscale']}
          label="Grayscale"
          onChange={() => toggleEffect('grayscale')}
        />
        <Checkbox
          value={!!form['invert']}
          label="Inverted"
          onChange={() => toggleEffect('invert')}
        />
      </StyledDesignerEditImagePanel>
    </DesignerPanel>
  )
}

export default DesignerEditImagePanel
