import { FC } from 'react'
import {
  DesignerCropTool,
  DesignerDeleteTool,
  DesignerDuplicateTool,
  DesignerEditImageTool,
  DesignerFlipTool,
  DesignerLockTool,
  DesignerPositionTool,
  DesignerTransparencyTool
} from '..'
import { DesignerToolbarRow } from '.'

const DesignerImageToolbar: FC = () => {
  return (
    <DesignerToolbarRow>
      <DesignerEditImageTool />
      <DesignerCropTool />
      <DesignerFlipTool />
      <hr />
      <DesignerPositionTool />
      <hr />
      <DesignerTransparencyTool />
      <DesignerDuplicateTool />
      <DesignerDeleteTool />
      <hr />
    </DesignerToolbarRow>
  )
}

export default DesignerImageToolbar
