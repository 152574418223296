import styled from 'styled-components'

const StyledUploadsFileList = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.color.gray[100]};
  overflow-y: overlay;

  .empty-state {
    background-color: transparent;
  }

  .file-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    padding: 16px;
    gap: 16px;
    padding-bottom: 250px;
  }

  &::-webkit-scrollbar {
    height: 0;
    position: absolute;
    width: 14px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10000px;
    border: 4px solid ${({ theme }) => theme.color.gray[100]};
    background: ${({ theme }) => theme.color.foreground}30;

    &:hover {
      background: ${({ theme }) => theme.color.foreground}40;
    }
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    width: 8px;
  }
`

export default StyledUploadsFileList
