import { FC } from 'react'
import { ConnectionDialogContent, Dialog } from '../..'
import { StyledConnectionConnectDialog } from '.'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const ConnectionConnectDialog: FC<Props> = ({ isOpen, onClose }) => {
  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <StyledConnectionConnectDialog>
        <ConnectionDialogContent />
      </StyledConnectionConnectDialog>
    </Dialog>
  )
}

export default ConnectionConnectDialog
