import { FC, useEffect } from 'react'
import { Canvas, useActiveObject, useEditor } from '@layerhub-io/react'
import { useAppSelector } from '../../../redux'
import { DesignerToolbar } from '..'
import { StyledDesignerCanvas } from '.'
import { useDesigner } from '../../../utils'

const DesignerCanvas: FC = () => {
  const editor = useEditor()
  const activeObject = useActiveObject() as any
  const { template, photo } = useAppSelector((state) => state.designer)
  const { loadDesign, loadPhoto } = useDesigner()

  const handleKeyDown = (e: KeyboardEvent) => {
    if (!activeObject) {
      return
    }

    const amount = e.shiftKey ? 10 : 1

    if (e.key === 'ArrowUp') {
      editor.objects.move('top', -amount)
    }

    if (e.key === 'ArrowDown') {
      editor.objects.move('top', amount)
    }

    if (e.key === 'ArrowLeft') {
      editor.objects.move('left', -amount)
    }

    if (e.key === 'ArrowRight') {
      editor.objects.move('left', amount)
    }

    editor.canvas.requestRenderAll()
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [activeObject])

  useEffect(() => {
    if (!template || !editor) {
      return
    }

    loadDesign(template)
  }, [editor, template])

  useEffect(() => {
    if (!editor || !photo) {
      return
    }

    loadPhoto()
  }, [editor, photo])

  return (
    <StyledDesignerCanvas>
      <DesignerToolbar />
      <Canvas
        config={{
          background: 'transparent',
          controlsPosition: {
            rotation: 'BOTTOM'
          },
          clipToFrame: true,
          shadow: {
            blur: 10,
            color: '#00000020',
            offsetX: 0,
            offsetY: 6
          }
        }}
      />
    </StyledDesignerCanvas>
  )
}

export default DesignerCanvas
