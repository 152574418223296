import styled from 'styled-components'

const StyledUploadsFolderList = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
  display: grid;
  grid-template-rows: auto minmax(0, 1fr) auto;

  .folder-scroll {
    overflow-y: auto;
    box-sizing: border-box;
    padding: 10px 0;
  }

  .add-folder-btn-container {
    padding: 0 16px 16px 16px;

    button {
      width: 100%;
      padding: 0 10px;
      box-sizing: border-box;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      height: 44px;
      color: ${({ theme }) => theme.color.gray[500]};
      background-color: ${({ theme }) => theme.color.gray[100]};
      transition: 0.2s;

      svg {
        height: 24px;
        margin-right: 8px;
        stroke-width: 2px;
      }

      &:hover {
        background-color: ${({ theme }) => theme.color.gray[200]};
      }
    }
  }
`

export default StyledUploadsFolderList
