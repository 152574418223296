import styled from 'styled-components'
import { Link } from 'react-router-dom'

type StyledProps = {
  isDisabled: boolean
}

const StyledActionPlanListItem = styled(Link)<StyledProps>`
  display: grid;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  user-select: none;
  align-items: start;

  .action-plan-trial-cta {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: grid;
    justify-items: center;
    align-content: center;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    grid-gap: 20px;
    color: ${({ theme }) => theme.color.brand};
    background-color: ${({ theme }) => theme.color.brandLight}c4;

    svg {
      height: 30px;
    }
  }

  .action-plan-img {
    padding-top: 56.25%;
    flex-shrink: 0;
    position: relative;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.color.gray[100]};

    .action-plan-play-indicator {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 5px;
      background-color: rgb(0, 0, 0, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;

      div {
        height: 60px;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.2s;
        background-color: ${({ theme }) => theme.color.brand};
        color: ${({ theme }) => theme.color.background};
        box-shadow: 0 2px 6px rgb(0, 0, 0, 0.2);
        border-radius: 50%;

        svg {
          height: 28px;
        }
      }
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 5px;
      object-fit: cover;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 5px;
      box-shadow: inset 0 0 0 1px rgb(0, 0, 0, 0.1);
      z-index: 1;
    }
  }

  .action-plan-details {
    margin: 16px 0 10px;

    .action-plan-number {
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 0.05rem;
      text-align: center;
      padding: 6px 10px;
      display: flex;
      align-items: center;
      width: fit-content;
      color: ${({ theme }) => theme.color.gray[500]};
      border-radius: 6px;
      background-color: ${({ theme }) => theme.color.gray[100]};
    }

    .action-plan-title {
      font-size: 18px;
      line-height: 28px;
      display: block;
      margin-top: 10px;
      font-weight: 600;
      color: ${({ theme }) => theme.color.gray[700]};
    }

    .action-plan-preview {
      display: block;
      margin-top: 2px;
      color: ${({ theme }) => theme.color.gray[500]};
      font-size: 15px;
      line-height: 22px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-align: left;
      -webkit-box-orient: vertical;
    }
  }

  button {
    display: flex;
    width: fit-content;
    align-items: center;
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;
    margin-top: 10px;
    background-color: transparent;
    color: ${({ theme }) => theme.color.gray[500]};

    svg {
      margin-left: 8px;
      height: 16px;

      path {
        stroke-width: 2.5px;
      }
    }
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &:hover {
    .action-plan-play-indicator {
      div {
        transform: scale(1.05);
        background-color: ${({ theme }) => theme.color.brandDark};
      }
    }

    .action-plan-details {
      text-decoration: underline;
      text-decoration-color: ${({ theme }) => theme.color.gray[300]};
    }
  }

  ${({ isDisabled, theme }) =>
    isDisabled &&
    `
    pointer-events: none;

    .action-plan-img {
      filter: grayscale(1);
    }

    .action-plan-img .action-plan-play-indicator {
      display: none;
    }
    
    &:hover {
      cursor: not-allowed;
    }
  `}

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    grid-template-columns: 250px minmax(0, 1fr);

    .action-plan-details {
      margin: 0 0 0 20px;
    }
  }
`

export default StyledActionPlanListItem
