import { FC } from 'react'
import { Coordinates } from 'react-advanced-cropper'
import { useActiveObject, useEditor } from '@layerhub-io/react'
import { IStaticImage } from '@layerhub-io/types'
import { setDesignerImageToCrop, useAppDispatch, useAppSelector } from '../../../redux'
import { CropTool } from '../..'
import { DesignerToolButton } from '.'

const DesignerCropTool: FC = () => {
  const activeObject = useActiveObject() as Required<IStaticImage>
  const editor = useEditor()
  const dispatch = useAppDispatch()
  const { imageToCrop } = useAppSelector((state) => state.designer)

  const openCropTool = () => {
    const url = activeObject.preview

    dispatch(
      setDesignerImageToCrop({
        url,
        coords: {
          height: activeObject.height,
          width: activeObject.width,
          left: activeObject.cropX,
          top: activeObject.cropX
        }
      })
    )
  }

  const handleCrop = (coords: Coordinates) => {
    editor.objects.update({
      cropY: coords.top,
      cropX: coords.left,
      width: coords.width,
      height: coords.height
    })
    dispatch(setDesignerImageToCrop(undefined))
  }

  return (
    <>
      <DesignerToolButton isActive={!!imageToCrop} onClick={() => openCropTool()}>
        <span>Crop</span>
      </DesignerToolButton>
      {imageToCrop && (
        <CropTool
          image={imageToCrop?.url}
          defaultCoordinates={imageToCrop?.coords}
          onCrop={(coords) => handleCrop(coords)}
          onClose={() => dispatch(setDesignerImageToCrop(undefined))}
        />
      )}
    </>
  )
}

export default DesignerCropTool
