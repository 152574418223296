import styled from 'styled-components'

const StyledCalendarWeekRow = styled.div`
  width: 100%;
  display: grid;
  position: relative;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));

  .calendar-now {
    position: absolute;
    top: calc(100% - 12px);
    right: calc(100% + 1px);
    user-select: none;
    z-index: ${({ theme }) => theme.elevation.base};
    pointer-events: none;

    .calendar-now-label {
      height: 24px;
      display: flex;
      align-items: center;
      padding-right: 9px;
      font-size: 12px;
      font-family: ${({ theme }) => theme.typeface.monospace};
      color: ${({ theme }) => theme.color.brand};
      background: linear-gradient(transparent, ${({ theme }) => theme.color.gray[50]}, transparent);
      font-weight: 500;
    }
  }

  .calendar-now-today-line {
    position: absolute;
    height: 3px;
    width: 100%;
    left: 0;
    right: 0;
    pointer-events: none;
    border-radius: 50rem;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.color.gray[50]};
    z-index: ${({ theme }) => theme.elevation.fixed + 1};
    background-color: ${({ theme }) => theme.color.brand};

    &::before {
      content: '';
      position: absolute;
      height: 3px;
      width: 30px;
      border-radius: 50rem;
      background-color: ${({ theme }) => theme.color.brand};
      left: 0;
      top: 50%;
      z-index: 1;
      transform: translateY(-50%);
    }

    &::after {
      content: '';
      position: absolute;
      height: 16px;
      width: 3px;
      border-radius: 50rem;
      background-color: ${({ theme }) => theme.color.brand};
      left: 0;
      top: 50%;
      box-shadow: 0 0 0 2px ${({ theme }) => theme.color.gray[50]};
      transform: translateY(-50%);
    }
  }

  .calendar-now-line {
    position: absolute;
    height: 1px;
    width: 100%;
    left: 0;
    pointer-events: none;
    right: 0;
    z-index: ${({ theme }) => theme.elevation.fixed};
    background-color: ${({ theme }) => theme.color.brand};
  }

  .calendar-time-slot {
    position: absolute;
    top: calc(100% - 12px);
    right: 100%;
    user-select: none;

    .calendar-time-slot-label {
      height: 24px;
      display: flex;
      align-items: center;
      padding-right: 10px;
      font-size: 12px;
      font-family: ${({ theme }) => theme.typeface.monospace};
      color: ${({ theme }) => theme.color.gray[500]};
    }
  }

  &:not(:last-child) {
    border-bottom: 1px dashed ${({ theme }) => theme.color.gray[200]};
  }
`

export default StyledCalendarWeekRow
