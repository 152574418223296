import { FC } from 'react'
import ReactTextareaAutosize from 'react-textarea-autosize'
import { updatePlanPost, useAppDispatch, useAppSelector } from '../../../redux'
import { EditorBlock } from '..'
import { StyledEditorCaption } from '.'

const EditorCaption: FC = () => {
  const dispatch = useAppDispatch()
  const { post, posts } = useAppSelector((state) => state.plan)

  return (
    <EditorBlock title="Caption">
      <StyledEditorCaption>
        <ReactTextareaAutosize
          value={posts[post]?.captionText}
          className="textarea"
          minRows={8}
          maxRows={30}
          onChange={(e) =>
            dispatch(
              updatePlanPost({ _id: posts[post]?._id!, post: { captionText: e.target.value } })
            )
          }
        />
      </StyledEditorCaption>
    </EditorBlock>
  )
}

export default EditorCaption
