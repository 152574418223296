import styled from 'styled-components'

const StyledConnectionOverview = styled.div`
  overflow: hidden;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);

  .heading-container {
    display: grid;
    gap: 16px;
    padding: 20px 20px 4px 20px;

    .heading {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .back-btn {
        height: 36px;
        padding: 0 8px 0 12px;
        display: flex;
        align-items: center;
        border-radius: 6px;
        font-size: 15px;
        font-weight: 500;
        color: ${({ theme }) => theme.color.gray[600]};
        transition: 0.2s;
        background-color: ${({ theme }) => theme.color.gray[100]};

        svg {
          height: 20px;
          margin-left: 6px;
          stroke-width: 2.2px;
          color: ${({ theme }) => theme.color.gray[500]};
        }

        &:hover {
          background-color: ${({ theme }) => theme.color.gray[200]};
        }
      }
    }

    .platform-details {
      display: flex;
      align-items: center;
      font-size: 15px;
      font-weight: 500;
      color: ${({ theme }) => theme.color.gray[800]};

      img {
        height: 26px;
        width: 26px;
        flex-shrink: 0;
        margin-right: 10px;
        position: relative;
        object-fit: contain;
      }
    }

    .connect-btn {
      height: 36px;
      padding: 0 12px;
      border-radius: 6px;
      font-size: 15px;
      font-weight: 500;
      color: white;
      transition: 0.2s;
      background-color: ${({ theme }) => theme.color.brand};

      &:hover {
        background-color: ${({ theme }) => theme.color.brandDark};
      }
    }
  }

  .tabs {
    height: 50px;

    .tab {
      button {
        font-size: 15px;
      }
    }
  }

  .tab-content {
    overflow-y: auto;
    padding: 20px;

    p,
    li {
      font-size: 15px;
      color: ${({ theme }) => theme.color.gray[700]};
      line-height: 20px;
    }

    blockquote {
      margin: 0;
      border-radius: 0 4px 4px 0;
      padding: 12px 12px 12px 16px;
      background-color: ${({ theme }) => theme.color.brandLight};
      border-left: 2px solid ${({ theme }) => theme.color.brand};

      p,
      a {
        color: ${({ theme }) => theme.color.brand};
      }
    }

    ul {
      display: grid;
      gap: 10px;
    }

    h1,
    h2 {
      font-size: 18px;
      font-weight: 700;
      color: ${({ theme }) => theme.color.gray[800]};
    }

    h3 {
      font-size: 16px;
      font-weight: 700;
      color: ${({ theme }) => theme.color.gray[800]};
    }

    a {
      color: ${({ theme }) => theme.color.brand};
    }

    p,
    ul,
    blockquote {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    h1,
    h2,
    h3 {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    .values {
      padding: 0;

      li {
        display: flex;
        align-items: center;
        text-transform: capitalize;

        svg {
          height: 20px;
          margin-right: 10px;
          color: ${({ theme }) => theme.color.gray[500]};
          stroke-width: 2px;
        }
      }
    }

    &::-webkit-scrollbar {
      height: 0;
      position: absolute;
      width: 16px;
    }

    &::-webkit-scrollbar-thumb {
      border: 4px solid ${({ theme }) => theme.color.background};
      background: ${({ theme }) => theme.color.foreground}10;
      border-radius: 50rem;

      &:hover {
        background: ${({ theme }) => theme.color.foreground}20;
      }
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      border: solid 3px transparent;
      width: 8px;
    }
  }
`

export default StyledConnectionOverview
