import styled from 'styled-components'

const StyledFeedbackDialog = styled.div`
  position: relative;
  width: 100vw;
  user-select: none;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;

  .dialog-title {
    font-weight: 500;
    font-size: 20px;
    color: ${({ theme }) => theme.color.gray[800]};
    margin-bottom: 20px;
    display: block;
  }

  .disclaimer {
    margin-bottom: 4px;
    padding: 12px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.color.gray[100]};
    color: ${({ theme }) => theme.color.gray[600]};

    a {
      color: ${({ theme }) => theme.color.brand};
    }
  }

  form {
    display: grid;
    row-gap: 20px;

    .form-fields {
      display: grid;
      row-gap: 20px;

      .form-field {
        .field-label {
          font-weight: 600;
          font-size: 14px;
          display: block;
          color: ${({ theme }) => theme.color.gray[700]};
        }

        .form-field-option {
          position: relative;
          margin-top: 10px;
          border-radius: 4px;
          padding: 14px;
          overflow: hidden;
          transition: 0.2s;
          border: 1px solid ${({ theme }) => theme.color.gray[200]};

          input {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            opacity: 0;
          }

          .checkbox-container {
            pointer-events: none;
            position: absolute;
            top: 12px;
            right: 12px;

            input:checked + .check-box {
              background-color: ${({ theme }) => theme.color.brand};
              border-color: ${({ theme }) => theme.color.brand};
            }

            .check-box {
              border-radius: 2px;
            }
          }

          span {
            display: block;
          }

          .form-field-option-title {
            font-weight: 500;
            display: block;
            color: ${({ theme }) => theme.color.gray[800]};
          }

          .form-field-option-description {
            font-size: 14px;
            margin-top: 4px;
            display: none;
            color: ${({ theme }) => theme.color.gray[600]};
          }

          .form-field-option-examples {
            font-size: 13px;
            margin-top: 6px;
            display: none;
            color: ${({ theme }) => theme.color.gray[500]};
            font-style: italic;
          }

          &:hover {
            background-color: ${({ theme }) => theme.color.gray[100]};
            border-color: ${({ theme }) => theme.color.gray[300]};
          }

          &[aria-selected='true'] {
            box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.brand};
            border-color: ${({ theme }) => theme.color.brand};

            .form-field-option-title {
              color: ${({ theme }) => theme.color.brand};
            }

            .form-field-option-description,
            .form-field-option-examples {
              display: none;
            }
          }
        }

        textarea {
          resize: none;
          margin-top: 10px;
          border: 1px solid ${({ theme }) => theme.color.gray[300]};
          color: ${({ theme }) => theme.color.gray[900]};
          border-radius: 4px;
          width: 100%;
          padding: 10px;
          box-sizing: border-box;
          background-color: transparent;

          &::placeholder {
            color: ${({ theme }) => theme.color.gray[400]};
          }
        }
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    padding: 28px;
    max-width: 480px;

    form {
      .form-fields {
        .form-field {
          .form-field-option {
            .form-field-option-description,
            .form-field-option-examples {
              display: block;
            }
          }
        }
      }
    }
  }
`

export default StyledFeedbackDialog
