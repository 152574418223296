import styled from 'styled-components'

const StyledContentViewer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.color.background};
  z-index: ${({ theme }) => theme.elevation.modal};

  .content-viewer-gallery {
    display: none;

    .back-btn {
      position: absolute;
      top: 16px;
      left: 16px;
      z-index: 1;
      background-color: ${({ theme }) => theme.color.background};
      box-shadow: 0 0 0 1px rgb(0, 0, 0, 0.1);

      &:hover {
        background-color: ${({ theme }) => theme.color.gray[100]};
      }
    }
  }

  .content-viewer-right-bar {
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    border-left: 1px solid ${({ theme }) => theme.color.gray[300]};

    .content-viewer-header {
      padding: 20px 16px;
      background-color: ${({ theme }) => theme.color.background};
      z-index: 2;
      border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
      position: sticky;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .content-viewer-title {
        font-weight: 600;
        font-size: 22px;
        line-height: 28px;
        margin-top: -2px;
        display: block;
        color: ${({ theme }) => theme.color.gray[900]};
      }

      .back-btn {
        margin: -8px 0 -8px 16px;
      }
    }

    .content-viewer-gallery {
      display: block;
      height: 400px;
      border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
    }

    .content-viewer-block {
      padding: 16px;

      &:not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
      }

      .content-viewer-block-label {
        display: flex;
        font-weight: 500;
        color: ${({ theme }) => theme.color.brand};
        margin-bottom: 18px;
        align-items: center;
        font-size: 18px;

        .content-viewer-block-icon {
          height: 28px;
          width: 28px;
          display: flex;
          margin-right: 10px;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background-color: ${({ theme }) => theme.color.brand};
          color: ${({ theme }) => theme.color.background};

          svg {
            height: 16px;
          }
        }
      }

      .content-viewer-block-content {
        p,
        li {
          color: ${({ theme }) => theme.color.gray[800]};
          line-height: 24px;

          &:not(:first-child) {
            margin-top: 10px;
          }
        }
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    display: grid;
    grid-template-columns: minmax(0, 1fr) 400px;
    left: 221px;
    width: calc(100% - 221px);

    .content-viewer-gallery {
      display: block;
    }

    .content-viewer-right-bar {
      .content-viewer-header {
        padding: 25px;

        .back-btn {
          display: none;
        }
      }

      .content-viewer-gallery {
        display: none;
      }

      .content-viewer-block {
        padding: 25px;
      }
    }
  }
`

export default StyledContentViewer
