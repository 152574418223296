import styled from 'styled-components'

const StyledContentLibraryView = styled.div`
  .tabs {
    position: sticky;
    top: 0;
    background-color: ${({ theme }) => theme.color.background};
    z-index: ${({ theme }) => theme.elevation.fixed};
  }
`

export default StyledContentLibraryView
