import { FC, RefObject, createRef, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { setHintCompleted, useAppDispatch, useAppSelector } from '../../../redux'
import { getPosition } from '../../../utils'
import { StyledHint } from '.'

type Props = {
  _id: string
  position:
    | 'top-center'
    | 'top-start'
    | 'top-end'
    | 'left-center'
    | 'right-center'
    | 'bottom-center'
    | 'bottom-start'
    | 'bottom-end'
  elementRef: RefObject<HTMLElement>
  title: string
  body: string
  action?: {
    label: string
    onClick: () => void
  }
}

const Hint: FC<Props> = ({ _id, position, elementRef, title, body, action }) => {
  const hintRef = createRef<HTMLDivElement>()
  const dispatch = useAppDispatch()
  const [positioning, setPositioning] = useState<{ top: number; left: number }>()
  const { hints, sidebarCollapsed } = useAppSelector((state) => state.state)

  const handleAction = () => {
    if (!action?.onClick) {
      return
    }

    dispatch(setHintCompleted(_id))
    action.onClick()
  }

  const positionHint = () => {
    const element = elementRef?.current
    const hint = hintRef?.current

    if (!element || !hint) {
      return setPositioning(undefined)
    }

    setPositioning(getPosition(position, element, hint))
  }

  useEffect(() => {
    positionHint()
  }, [sidebarCollapsed])

  if (hints.includes(_id)) {
    return null
  }

  const Element = (
    <StyledHint position={position} positioning={positioning} ref={hintRef}>
      <div className="hint-header">
        {title}
        {/* <LightbulbIcon /> */}
      </div>
      <div className="hint-content">{body}</div>
      <div className="hint-actions">
        <button onClick={() => dispatch(setHintCompleted(_id))}>Got it</button>
        {action && <button onClick={() => handleAction()}>{action.label}</button>}
      </div>
    </StyledHint>
  )

  return createPortal(Element, document.getElementById('popover-root') as HTMLElement)
}

export default Hint
