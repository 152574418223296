import styled from 'styled-components'

const StyledNewWorkspaceForm = styled.form`
  display: grid;
  grid-gap: 24px;
  align-content: start;

  .callout {
    padding: 28px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.color.gray[100]};

    .callout-title {
      display: block;
      font-size: 18px;
      font-weight: 500;
      color: ${({ theme }) => theme.color.gray[800]};
    }

    .callout-body {
      display: block;
      margin-top: 10px;
      line-height: 24px;
      color: ${({ theme }) => theme.color.gray[600]};
    }
  }

  hr {
    border: 0;
    height: 1px;
    margin: 0;
    background-color: ${({ theme }) => theme.color.gray[300]};
    width: 100%;
  }

  label,
  .form-label {
    display: grid;
    grid-gap: 8px;
    cursor: default;

    .input-label {
      font-weight: 500;
      user-select: none;
      color: ${({ theme }) => theme.color.gray[600]};
    }

    .input-subtext {
      font-size: 14px;
      user-select: none;
      color: ${({ theme }) => theme.color.gray[500]};
    }
  }
`

export default StyledNewWorkspaceForm
