import styled from 'styled-components'

const StyledSidebarUpgradeCta = styled.div`
  display: none;
  background-color: ${({ theme }) => theme.color.brandLight};
  padding: 8px;
  border-radius: 6px;
  margin-top: 10px;
  user-select: none;

  .upgrade-bullets {
    list-style-type: none;
    display: grid;
    padding: 0;
    grid-gap: 6px;

    li {
      display: flex;
      align-items: center;
      font-size: 14px;
      letter-spacing: -0.01rem;
      font-weight: 500;
      color: ${({ theme }) => theme.color.brand};

      svg {
        height: 20px;
        margin-right: 5px;
      }
    }
  }

  .upgrade-cta-action {
    margin-top: 12px;

    .btn {
      justify-content: space-between;
      padding: 0 8px 0 10px;

      svg {
        margin: 0;
      }
    }

    .trial-disclaimer {
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ theme }) => theme.color.brand};
      font-size: 14px;
      letter-spacing: -0.01rem;
      font-weight: 500;
      margin-top: 6px;

      svg {
        height: 18px;
        margin-right: 4px;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    display: grid;
  }
`

export default StyledSidebarUpgradeCta
