import { ShepherdOptionsWithType } from 'react-shepherd'
import { waitForElementExists } from '../../../utils'
import { removeEmptySteps } from '..'

export const generateTour = (isMobile: boolean): ShepherdOptionsWithType[] => {
  const steps: ShepherdOptionsWithType[] = [
    {
      title: "You're on fire!",
      text: `Congrats on completing today's daily action, and starting your streak.`,
      buttons: [
        {
          text: "What's a streak?",
          type: 'next'
        },
        {
          text: 'Skip tour',
          type: 'cancel'
        }
      ]
    },
    {
      text:
        'Every consecutive day you complete a daily action adds to your streak. You can view a detailed overview of your current streak by clicking this button.',
      attachTo: {
        element: '[data-tour-id="daily-action-streak"]',
        on: 'auto'
      },
      canClickTarget: false,
      buttons: [
        {
          text: 'Awesome',
          type: 'next'
        },
        {
          text: 'Leave tour',
          type: 'cancel'
        }
      ]
    }
  ]
  return removeEmptySteps(steps as any)
}
