import styled, { keyframes } from 'styled-components'
import { hextorgb } from '../../../utils'

const popInAnim = keyframes`
  from {
    opacity: 0;
    transform: translateY(6px) scale(0.95);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
`

const popOutAnim = keyframes`
  from {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
  to {
    opacity: 0;
    transform: translateY(6px) scale(0.95);
  }
`

type StyledProps = {
  showCloseAnim?: boolean
}

const StyledEmojiPicker = styled.div<StyledProps>`
  position: fixed;
  z-index: ${({ theme }) => theme.elevation.override};
  animation: forwards 0.05s ease-in-out
    ${({ showCloseAnim }) => (showCloseAnim ? popOutAnim : popInAnim)};
  box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.1),
    0px 2px 12px rgba(0, 0, 0, 0.1);
  padding: 1px;
  background-color: ${({ theme }) => theme.color.background};
  border-radius: 10px;

  em-emoji-picker {
    width: 352px;
    --border-radius: 11px;
    --color-border-over: rgba(0, 0, 0, 0.1);
    --color-border: rgba(0, 0, 0, 0.05);
    --color-border: ${({ theme }) => theme.color.gray[100]};
    --font-family: ${({ theme }) => theme.typeface.sans};
    --rgb-accent: ${({ theme }) => hextorgb(theme.color.brand)};
    --rgb-background: ${({ theme }) => hextorgb(theme.color.background)};
    --rgb-color: ${({ theme }) => hextorgb(theme.color.gray[600])};
    --rgb-input: ${({ theme }) => hextorgb(theme.color.gray[200])};
    --shadow: none;

    height: 50vh;
    min-height: 400px;
    max-height: 800px;
  }
`

export default StyledEmojiPicker
