export const VOICES = [
  {
    label: 'Friendly',
    value: 'friendly'
  },
  {
    label: 'Playful',
    value: 'playful'
  },
  {
    label: 'Warm',
    value: 'warm'
  },
  {
    label: 'Authoritative',
    value: 'authoritative'
  },
  {
    label: 'Inspiring',
    value: 'inspiring'
  },
  {
    label: 'Professional',
    value: 'professional'
  },
  {
    label: 'Personal',
    value: 'personal'
  },
  {
    label: 'Honest',
    value: 'honest'
  },
  {
    label: 'Humble',
    value: 'humble'
  },
  {
    label: 'Direct',
    value: 'direct'
  },
  {
    label: 'Clinical',
    value: 'clinical'
  },
  {
    label: 'Scientific',
    value: 'scientific'
  },
  {
    label: 'Savvy',
    value: 'savvy'
  },
  {
    label: 'Fun',
    value: 'fun'
  },
  {
    label: 'Whimsical',
    value: 'whimsical'
  },
  {
    label: 'Serious',
    value: 'serious'
  },
  {
    label: 'Educational',
    value: 'educational'
  },
  {
    label: 'Entertaining',
    value: 'entertaining'
  },
  {
    label: 'Informing',
    value: 'informing'
  }
]
