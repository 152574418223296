import { FC, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import keywordExtractor from 'keyword-extractor'
import { debounce, isEqual, times } from 'lodash'
import { useApolloClient } from '@apollo/client'
import { SparklesIcon } from '@heroicons/react/20/solid'
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { searchGalleryQuery } from '../../../graphql'
import { useAppSelector } from '../../../redux'
import { PhotoType } from '../../../types'
import { linkToSizedImage, useCreate } from '../../../utils'
import { Button, ScrollRow, Skeleton } from '../..'
import { StyledCreateBarContentRecommended } from '.'

const CreateBarContentRecommended: FC = () => {
  const apollo = useApolloClient()
  const { addMediaToPost } = useCreate()
  const { post } = useAppSelector((state) => state.create)
  const [photos, setPhotos] = useState<PhotoType[]>([])
  const [prevKeywords, setPrevKeywords] = useState<any[]>([])
  const [isExpanded, setIsExpanded] = useState(true)
  const [loading, setLoading] = useState(true)

  const keywordsChanged = () => {
    const keywords = keywordExtractor.extract(post?.captionText || '', {
      language: 'english',
      remove_digits: true,
      return_changed_case: true,
      remove_duplicates: false
    })

    setPrevKeywords(keywords)

    if (isEqual(prevKeywords, keywords)) {
      return false
    }

    return true
  }

  const loadPhotos = debounce(async (checkKeywords?: boolean) => {
    try {
      setLoading(true)

      if (checkKeywords && !keywordsChanged()) {
        return
      }

      const { data } = await apollo.query({
        fetchPolicy: 'no-cache',
        query: searchGalleryQuery,
        variables: {
          search: post?.captionText || '',
          orientation: null,
          sort: 'random',
          categories: [],
          style: [],
          colors: [],
          collection: null,
          liked: false,
          page: 0,
          items: 15
        }
      })

      setPhotos(data?.searchGallery?.docs || [])
    } catch (err) {
      console.log(err)
      toast.error((err as any).message)
    } finally {
      setLoading(false)
    }
  }, 1000)

  useEffect(() => {
    loadPhotos()
  }, [])

  useEffect(() => {
    if (!post?.captionText?.trim()) {
      return
    }

    const keywords = keywordExtractor.extract(post?.captionText, {
      language: 'english',
      remove_digits: true,
      return_changed_case: true,
      remove_duplicates: false
    })

    if (!keywords.length || isEqual(prevKeywords, keywords)) {
      return
    }

    loadPhotos(true)

    return () => {
      loadPhotos.cancel()
    }
  }, [post?.captionText])

  if (!photos.length && !loading) {
    return null
  }

  return (
    <StyledCreateBarContentRecommended isExpanded={isExpanded}>
      <div className="recommended-sizer">
        <div className="recommended-header">
          <span className="title">
            <SparklesIcon /> Recommended
          </span>
          <span className="sub-title">Need some inspo? We've got you.</span>
        </div>
        {loading && (
          <ScrollRow arrowOffset={22} padding={20} gap={4}>
            {times(10, (num) => {
              return <Skeleton randHeight={[140, 140]} styles="width: 200px;" key={num} />
            })}
          </ScrollRow>
        )}
        {!loading && (
          <ScrollRow arrowOffset={22} padding={20} gap={4}>
            {photos.map((photo) => {
              return (
                <button
                  className="photo-item"
                  style={{
                    width:
                      (140 / (photo.file.imgSize?.height || 0)) * (photo.file.imgSize?.width || 0)
                  }}
                  key={photo._id}
                  onClick={() =>
                    addMediaToPost({
                      url: photo.file.fileHttpLink || '',
                      dimensions: {
                        x: photo.file.imgSize?.width || 0,
                        y: photo.file.imgSize?.height || 0
                      }
                    })
                  }
                >
                  <img src={linkToSizedImage(photo.file.fileHttpLink, { width: 400 })} alt="" />
                </button>
              )
            })}
          </ScrollRow>
        )}
      </div>
      <Button className="toggle-btn" isIcon onClick={() => setIsExpanded(!isExpanded)}>
        {isExpanded ? <XMarkIcon /> : <ChevronDownIcon />}
      </Button>
    </StyledCreateBarContentRecommended>
  )
}

export default CreateBarContentRecommended
