import { ComponentProps, FC, RefObject, useEffect, useState } from 'react'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { createPortal } from 'react-dom'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { PositionType } from '../../../types'
import { getPosition } from '../../../utils'
import { StyledEmojiPicker } from '.'

type Props = {
  classToAvoid: string
  buttonRef: RefObject<HTMLDivElement | HTMLButtonElement>
  position?: PositionType
  isOpen?: boolean
  onSelect: (emoji: string) => void
  onClose: () => void
}

const EmojiPicker: FC<Props> = ({
  classToAvoid,
  buttonRef,
  position = 'top-start',
  isOpen,
  onSelect,
  onClose
}) => {
  const [showCloseAnim, setShowCloseAnim] = useState(false)
  const CLOSE_ANIM_LENGTH = 50
  const POPOVER_OFFSET = 12

  const handleClose = (e?: Event) => {
    const target = e?.target as HTMLElement

    if (!!target?.closest(`.${classToAvoid}`)) {
      return
    }

    setShowCloseAnim(true)
    setTimeout(() => onClose(), CLOSE_ANIM_LENGTH)
  }

  const handleSelect = (emoji: string) => {
    handleClose()
    onSelect(emoji)
  }

  const handleScroll = () => {
    handleClose()
  }

  const popoverRef = useDetectClickOutside({
    onTriggered: (e) => handleClose(e)
  }) as RefObject<HTMLDivElement>

  useEffect(() => {
    setShowCloseAnim(false)
  }, [isOpen])

  useEffect(() => {
    const button = buttonRef.current
    const popover = popoverRef.current

    if (!button || !popover) {
      return
    }

    const pos = getPosition(position, button, popover, POPOVER_OFFSET)

    if (!pos) {
      return
    }

    popover.style.top = `${pos.top}px`
    popover.style.left = `${pos.left}px`
  }, [buttonRef, popoverRef])

  useEffect(() => {
    if (!isOpen) {
      return
    }

    document.addEventListener('scroll', handleScroll)

    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [isOpen])

  if (!isOpen) {
    return <></>
  }

  const Element = (
    <StyledEmojiPicker className="emoji-picker" showCloseAnim={showCloseAnim} ref={popoverRef}>
      <Picker
        data={data}
        autoFocus
        theme="light"
        onEmojiSelect={(emojiData: any) => handleSelect(emojiData.native)}
      />
    </StyledEmojiPicker>
  )

  return createPortal(Element, document.getElementById('popover-root') as HTMLElement)
}

export default EmojiPicker
