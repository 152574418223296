import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

type StyledProps = {
  isCompleted?: boolean
  isCurrentLesson?: boolean
}

const StyledAcademyCourseLessonMapItem = styled(NavLink)<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px;
  margin: 0 -6px;
  border-radius: 6px;
  transition: 0.2s;
  text-decoration: none;
  position: relative;

  .lesson-details {
    display: flex;
    align-items: center;
    position: relative;
    color: ${({ theme }) => theme.color.gray[700]};

    .lesson-status-indicator {
      height: 20px;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      flex-shrink: 0;
      margin-right: 10px;
      box-shadow: inset 0 0 0 2px ${({ theme }) => theme.color.gray[400]};
      position: relative;
      z-index: 2;

      svg {
        height: 14px;
        color: white;
        stroke-width: 2.5px;
      }

      ${({ isCompleted, theme }) =>
        isCompleted &&
        `
      box-shadow: inset 0 0 0 20px ${theme.color.gray[900]};
      `}
    }

    .lesson-title {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .lesson-time {
    flex-shrink: 0;
    margin-left: 10px;
    color: ${({ theme }) => theme.color.gray[500]};
  }

  &:not(:first-child) {
    .lesson-details {
      .lesson-status-indicator {
        &::after {
          content: '';
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          width: 2px;
          background-color: ${({ theme }) => theme.color.gray[400]};
          bottom: 100%;
          height: 12px;
          z-index: 0;

          ${({ isCompleted, theme }) =>
            isCompleted &&
            `
            background-color: ${theme.color.gray[900]};
          `}
        }
      }
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.gray[200]};
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.foreground}20;
  }

  ${({ isCurrentLesson, isCompleted, theme }) =>
    isCurrentLesson &&
    `
    box-shadow: inset 0 0 0 1px ${theme.color.foreground}20;

    .lesson-details {
      .lesson-status-indicator {
        &::before {
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          width: 6px;
          height: 6px;
          background-color: ${isCompleted ? 'white' : theme.color.gray[400]};
        }
      }

      .lesson-title {
        color: ${theme.color.gray[800]};
        font-weight: 500;
      }
    }
  `}
`

export default StyledAcademyCourseLessonMapItem
