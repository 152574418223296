import { FC, createRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Bars3Icon } from '@heroicons/react/24/outline'
import { ReactComponent as YouIcon } from '../../assets/mobile-menu-you-icon.svg'
import { ReactComponent as SCLogo } from '../../assets/sc-logo-black.svg'
import { setShowMobileSidebar } from '../../redux'
import { ROUTES } from '../../utils'
import { Button, UserMenuPopover } from '..'
import { StyledHeader } from '.'

const Header: FC = () => {
  const dispatch = useDispatch()
  const userMenuBtn = createRef<HTMLButtonElement>()
  const [showUserMenu, setShowUserMenu] = useState(false)

  return (
    <>
      <StyledHeader>
        <div className="header-group">
          <Button size="sm" isIcon onClick={() => dispatch(setShowMobileSidebar(true))}>
            <Bars3Icon />
          </Button>
          <Link to={ROUTES.home} className="sc-logo">
            <SCLogo />
          </Link>
        </div>
        <Button
          className="user-icon-btn"
          ref={userMenuBtn}
          isIcon
          onClick={() => setShowUserMenu(!showUserMenu)}
        >
          <YouIcon />
        </Button>
      </StyledHeader>
      <UserMenuPopover
        classToAvoid="user-icon-btn"
        isOpen={showUserMenu}
        onClose={() => setShowUserMenu(false)}
        position="bottom-right"
        buttonRef={userMenuBtn}
      />
    </>
  )
}

export default Header
