import { FC, useEffect } from 'react'
import Sheet from 'react-modal-sheet'
import { useHaptics } from '../../../../utils'
import { SocialProfileList } from '..'
import { StyledSocialProfileSheet } from '.'

type Props = {
  selectedProfiles: string[]
  onSelect: (_id: string) => void
  isOpen: boolean
  onClose: () => void
}

const SocialProfileSheet: FC<Props> = ({ isOpen, onClose, ...rest }) => {
  const { trigger } = useHaptics()

  useEffect(() => {
    if (isOpen) {
      trigger('impact-light')
    }

    return () => {
      trigger('impact-light')
    }
  }, [isOpen])

  return (
    <Sheet detent="content-height" isOpen={isOpen} onClose={onClose}>
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content disableDrag>
          <StyledSocialProfileSheet>
            <SocialProfileList {...rest} />
          </StyledSocialProfileSheet>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={() => onClose()} />
    </Sheet>
  )
}

export default SocialProfileSheet
