import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { DailyActionType } from '../../types'

type InitialState = {
  dailyAction: DailyActionType
}

const initialState: InitialState = {
  dailyAction: {
    _id: '',
    streak: 0
  }
}

export const dailyActionSlice = createSlice({
  name: 'dailyAction',
  initialState,
  reducers: {
    setDailyAction: (state, action: PayloadAction<DailyActionType>) => {
      state.dailyAction = action.payload
    },
    setDailyActionCompleted: (state) => {
      state.dailyAction.isCompleted = true
      state.dailyAction.streak += 1
    },
    setDailyActionAction: (state, action: PayloadAction<string>) => {
      if (!state.dailyAction.action) {
        return
      }

      state.dailyAction.action.action = action.payload
    }
  }
})

export const { setDailyAction, setDailyActionCompleted, setDailyActionAction } =
  dailyActionSlice.actions
export default dailyActionSlice.reducer
