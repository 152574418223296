import { FC, useContext } from 'react'
import { PlusIcon } from '@heroicons/react/24/outline'
import { SocialProfileType } from '../../../types'
import { getPlatformDescription, getPlatformLogo, getPlatformTitle } from '../../../utils'
import { connectionStore } from '..'
import { StyledConnectionPlatformListItem } from '.'

type Props = {
  platform: SocialProfileType['type']
  isDisabled?: boolean
}

const ConnectionPlatformListItem: FC<Props> = ({ platform, isDisabled }) => {
  const { connectionLimit, platformCounts, setPlatformToConnect } = useContext(connectionStore)
  const count = (platformCounts as any)[platform]

  const getTooltip = () => {
    // if (platform === 'linkedin-page') {
    //   return `LinkedIn pages are coming soon`
    // }

    if (isDisabled) {
      return `${getPlatformTitle(platform)} is coming soon`
    }

    if (count >= connectionLimit) {
      return `At ${getPlatformTitle(platform)} profile limit`
    }

    return undefined
  }

  const handleClick = () => {
    if (isDisabled) {
      return
    }

    if (count >= connectionLimit) {
      return
    }

    setPlatformToConnect(platform)
  }

  return (
    <StyledConnectionPlatformListItem
      data-tooltip={getTooltip()}
      data-tooltip-pos="top-center"
      isDisabled={count >= connectionLimit || isDisabled}
      onClick={() => handleClick()}
    >
      <div className="platform-details-container">
        <div className="platform-image">
          <img
            src={`/images/logos/${getPlatformLogo(platform)}`}
            alt={`${getPlatformTitle(platform)} Logo`}
          />
        </div>
        <div className="platform-details">
          <span className="platform-name">{getPlatformTitle(platform)}</span>
          <span className="platform-description">{getPlatformDescription(platform)}</span>
        </div>
      </div>
      {isDisabled ? <div className="platform-coming-soon">Coming Soon</div> : <PlusIcon />}
    </StyledConnectionPlatformListItem>
  )
}

export default ConnectionPlatformListItem
