import styled from 'styled-components'

const StyledEditorBlock = styled.div`
  padding: 20px;
  background-color: ${({ theme }) => theme.color.background};
  border-radius: 14px;
  box-shadow: 0px 4px 10px 0px #0000000d, 0px 2px 4px 0px #0000000d;

  .header {
    margin-bottom: 20px;

    .title-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        font-weight: 500;
        color: ${({ theme }) => theme.color.gray[800]};
      }

      .extra {
        flex-shrink: 0;
      }
    }
  }

  .content {
  }
`

export default StyledEditorBlock
