import { FC, useContext, useState } from 'react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { toggleCreateSocialProfile, useAppDispatch, useAppSelector } from '../../../../redux'
import { hydrateSocialProfiles, userStore } from '../../../../utils'
import { SocialProfileAvatar, SocialProfileSheet } from '../..'
import { StyledCreateSocialProfileSelect } from '.'

const CreateSocialProfileSelect: FC = () => {
  const dispatch = useAppDispatch()
  const [showSocialProfileSelect, setShowSocialProfileSelect] = useState(false)
  const { user } = useContext(userStore)
  const { post } = useAppSelector((state) => state.create)

  return (
    <>
      <StyledCreateSocialProfileSelect>
        <span className="input-label">Publish post to:</span>
        <button
          className="social-profile-select-btn"
          onClick={() => setShowSocialProfileSelect(true)}
        >
          {!post?.socialProfiles?.length && (
            <span className="placeholder">Select one or more social profiles</span>
          )}
          {!!post?.socialProfiles?.length && (
            <div className="social-profile-selection">
              {hydrateSocialProfiles(post.socialProfiles, user).map((profile) => {
                return <SocialProfileAvatar profile={profile} size={36} inRow key={profile._id} />
              })}
            </div>
          )}
          <ChevronDownIcon />
        </button>
      </StyledCreateSocialProfileSelect>
      <SocialProfileSheet
        selectedProfiles={post?.socialProfiles || []}
        onSelect={(profile) => dispatch(toggleCreateSocialProfile(profile))}
        isOpen={showSocialProfileSelect}
        onClose={() => setShowSocialProfileSelect(false)}
      />
    </>
  )
}

export default CreateSocialProfileSelect
