import { createGlobalStyle } from 'styled-components'
import '../../fonts/index.css'

type StyledProps = {
  keyboardHeight?: number
}

export const Global = createGlobalStyle<StyledProps>`
  :root {
    --top-spacing: max(env(safe-area-inset-top), 20px);
    --bottom-spacing: max(env(safe-area-inset-bottom), 14px);
  }

  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    overscroll-behavior-y: none;
  }

  *:not(input) {
    user-select: none !important;
    -webkit-user-select: none !important;
  }

  body {
    background-color: ${({ theme }) => theme.color.background};
    color: ${({ theme }) => theme.color.gray[900]};
    font-size: 16px;
    margin: 0;
    font-family: ${({ theme }) => theme.typeface.sans};
    overscroll-behavior-y: none;
  }

  h1, h2, h3, h4, h5, h6, p, ul, li {
    margin: 0;
  }

  label {
    cursor: default;
  }

  input, button, select, textarea {
    -webkit-appearance: none;
    outline: none;
    font-family: ${({ theme }) => theme.typeface.sans};
    margin: 0;
    padding: 0;
    font-size: 16px;
    border: 0;
  }

  button, a, span, p, h1, h2, h3, h4, h5, h6, label {
    user-select: none;
  }

  input::placeholder {
    font-size: 16px;
    color: ${({ theme }) => theme.color.gray[400]};
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  button:hover, a:hover {
    cursor: pointer;
  }

  .react-modal-sheet-container, .MuiPaper-root {
    background-color: ${({ theme }) => theme.color.background} !important;
  }

  .confetti {
    position: fixed;
    top: -250px;
    left: 50%;
    pointer-events: none;
    transform: translateX(-50%);
    z-index: 9999999;
  }
`
