import styled from 'styled-components'

const StyledUploadsSidebar = styled.div`
  height: 100vh;
  width: 312px;
  background-color: ${({ theme }) => theme.color.background};
  border-right: 1px solid ${({ theme }) => theme.color.gray[300]};
  display: grid;
  grid-template-rows: auto minmax(0, 1fr) auto;
`

export default StyledUploadsSidebar
