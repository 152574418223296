import { FC, ReactNode, createRef, useEffect, useState } from 'react'
import { Button } from '..'
import { StyledReadMore } from '.'

type Props = {
  children: ReactNode
}

const ReadMore: FC<Props> = ({ children }) => {
  const readMoreRef = createRef<HTMLDivElement>()
  const CUTOFF_HEIGHT = 200
  const [expandedHeight, setExpandedHeight] = useState(0)
  const [isExpanded, setIsExpanded] = useState(false)
  const [canShowMore, setCanShowMore] = useState(false)

  useEffect(() => {
    if (!readMoreRef.current) {
      return
    }

    if (readMoreRef.current.scrollHeight > CUTOFF_HEIGHT) {
      setCanShowMore(true)
      setExpandedHeight(readMoreRef.current.scrollHeight)
    }
  }, [readMoreRef])

  return (
    <StyledReadMore
      expandedHeight={expandedHeight}
      canShowMore={canShowMore}
      isExpanded={isExpanded}
    >
      <div className="read-more-content-container">
        <div className="read-more-content" ref={readMoreRef}>
          {children}
        </div>
      </div>
      {canShowMore && (
        <Button
          size="sm"
          className="expand-btn"
          isFullWidth
          onClick={() => setIsExpanded(!isExpanded)}
        >
          Read {isExpanded ? 'less' : 'more'}
        </Button>
      )}
    </StyledReadMore>
  )
}

export default ReadMore
