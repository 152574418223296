import styled from 'styled-components'

type StyledProps = {
  isActive: boolean
}

const StyledPostListItem = styled.div<StyledProps>`
  border-radius: 6px;
  position: relative;
  user-select: none;
  transition: 0.2s color, 0.2s background-color, 0.2s box-shadow;

  ${({ isActive, theme }) =>
    isActive &&
    `
    box-shadow: 0 0 0 3px ${theme.color.background}, 0 0 0 6px ${theme.color.brand} !important;
  `}

  .options-btn {
    z-index: 2;
    position: absolute;
    right: 14px;
    top: 14px;
    width: 28px;
    height: 28px;
    background-color: ${({ theme }) => theme.color.background};
    box-shadow: 0 0 0 1px rgb(0, 0, 0, 0.1);

    svg {
      height: 24px;
    }

    &:hover {
      background-color: ${({ theme }) => theme.color.gray[100]};
    }
  }

  .post-preview {
    background-color: ${({ theme }) => theme.color.gray[100]};
    padding-top: 100%;
    border-radius: 6px 6px 0 0;
    position: relative;
    transition: filter 0.2s;

    .post-indicators {
      position: absolute;
      left: 14px;
      top: 14px;
      display: flex;
      z-index: 2;
    }

    .post-error-indicator {
      display: flex;
      align-items: center;
      box-shadow: inset 0 0 0 1px rgb(0, 0, 0, 0.1);
      background-color: ${({ theme }) => theme.color.red[800]};
      color: ${({ theme }) => theme.color.background};
      padding: 0 10px 0 4px;
      height: 28px;
      border-radius: 50rem;
      font-size: 14px;
      font-weight: 500;

      svg {
        height: 22px;
        margin-right: 4px;
      }
    }

    .post-indicator {
      display: flex;
      align-items: center;
      box-shadow: 0 0 0 1px rgb(0, 0, 0, 0.1);
      background-color: ${({ theme }) => theme.color.background};
      color: ${({ theme }) => theme.color.gray[700]};
      padding: 0 8px;
      height: 28px;
      border-radius: 50rem;
      font-size: 14px;
      font-weight: 500;

      svg {
        height: 16px;
        margin-right: 4px;
        stroke-width: 2.5px;
      }

      &:not(:last-child) {
        margin-right: 6px;
      }
    }

    .empty-img-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 66px;
      color: ${({ theme }) => theme.color.gray[400]};
      stroke-width: 1.2px;
    }

    .post-preview-caption {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      padding: 18px 20px 0 20px;
      box-sizing: border-box;
      font-size: 18px;
      line-height: 32px;
      user-select: none;
      color: ${({ theme }) => theme.color.gray[600]};

      &::after {
        content: '';
        position: absolute;
        left: 1px;
        bottom: 0;
        width: calc(100% - 2px);
        height: 100px;
        background: linear-gradient(0deg, ${({ theme }) => theme.color.gray[100]}, rgb(0, 0, 0, 0));
        z-index: 1;
      }
    }

    .social-profiles {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 40px 14px 14px 14px;
      display: flex;
      z-index: 2;

      .social-profile {
        border-radius: 50%;
        display: flex;
        align-items: center;
        position: relative;

        &:not(:first-child) {
          margin-left: -10px;
        }

        .avatar {
          &::after {
            box-shadow: inset 0 0 0 1px rgb(0 0 0 / 20%);
          }
        }
      }
    }

    .post-preview-img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 6px 6px 0 0;
      user-select: none;
    }

    &::after {
      user-select: none;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 6px 6px 0 0;
      box-shadow: inset 0 -1px 0 0 rgb(0, 0, 0, 0.1);
      pointer-events: none;
      z-index: 1;
    }
  }

  .post-details {
    padding: 14px;
    border-radius: 0 0 6px 6px;
    box-shadow: 0 -1px 0 0 rgb(0, 0, 0, 0.1);
    transition: 0.2s;
    background-color: ${({ theme }) => theme.color.background};

    .post-title {
      text-align: left;
      font-weight: 600;
      color: ${({ theme }) => theme.color.gray[700]};
      display: flex;
      letter-spacing: -0.01rem;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .post-date {
      margin-top: 6px;
      border-radius: 4px;
      transition: 0.2s;
      background-color: transparent;

      .post-unscheduled {
        padding: 6px 8px;
        display: flex;
        width: fit-content;
        font-size: 15px;
        font-weight: 500;
        border-radius: 4px;
        background-color: ${({ theme }) => theme.color.gray[100]};
        color: ${({ theme }) => theme.color.gray[500]};
      }

      &:hover {
        box-shadow: 0 0 0 3px ${({ theme }) => theme.color.background},
          0 0 0 5px ${({ theme }) => theme.color.gray[300]};
      }
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    pointer-events: none;
    box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), inset 0 0 0 1px rgb(0, 0, 0, 0.1);
  }

  &:hover,
  &:focus-visible {
    cursor: pointer;
    box-shadow: 0 0 0 1px rgb(0, 0, 0, 0.15), 0 4px 6px rgb(0, 0, 0, 0.05);

    .post-preview {
      filter: brightness(0.9);
    }
  }
`

export default StyledPostListItem
