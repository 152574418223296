import { gql } from '@apollo/client'

export const affiliateEarningUpdateOne = gql`
  mutation AffiliateEarningUpdateOne(
    $user: String!
    $amount: Float!
    $item: UpdateOneAffiliateEarningItemInput
    $userPaidDate: Date
  ) {
    affiliateEarningUpdateOne(
      filter: { user: $user }
      record: { amount: $amount, item: $item, userPaidDate: $userPaidDate }
    ) {
      recordId
    }
  }
`
