import styled from 'styled-components'

type StyledProps = {
  handleBefore?: boolean
  isDragging?: boolean
  isOverlay?: boolean
}

const StyledCreateEditorMediaItem = styled.div<StyledProps>`
  height: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: ${({ theme }) => theme.color.gray[100]};

  .drop-indicator {
    position: absolute;
    width: 4px;
    height: 100%;
    top: 0;
    bottom: 0;
    border-radius: 50rem;
    background-color: ${({ theme }) => theme.color.brand};

    ${({ handleBefore }) => (handleBefore ? 'left: -7px' : 'right: -7px')};
  }

  .media-preview {
    height: 230px;

    img {
      position: relative;
    }
  }

  .delete-btn {
    opacity: 0;
    position: absolute;
    z-index: 5;
    top: 8px;
    right: 8px;
    height: 34px;
    width: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
    background-color: rgb(0, 0, 0, 0.6);
    box-shadow: 0 0 0 1px rgb(255, 255, 255, 0.1);
    color: white;
    border-radius: 50%;

    svg {
      stroke-width: 2px;
      height: 24px;
    }

    &:hover {
      background-color: rgb(0, 0, 0, 0.8);
    }
  }

  .media-actions {
    position: absolute;
    z-index: 1;
    bottom: 8px;
    left: 8px;
    display: flex;

    .action-btn {
      height: 34px;
      padding: 0 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.2s;
      background-color: rgb(0, 0, 0, 0.6);
      box-shadow: 0 0 0 1px rgb(255, 255, 255, 0.1);
      color: white;
      border-radius: 50rem;

      svg {
        height: 20px;
      }

      &:hover {
        background-color: rgb(0, 0, 0, 0.8);
      }

      &.icon {
        width: 34px;
        padding: 0;
      }

      &:not(:last-child) {
        margin-right: 6px;
      }
    }
  }

  &::after {
    user-select: none;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none;
    border-radius: 0;
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.foreground}10;
  }

  &:hover {
    cursor: grab;

    .delete-btn {
      opacity: 1;
    }
  }

  ${({ isDragging }) =>
    isDragging &&
    `
    opacity: 0.3;

    button, .delete-btn {
      opacity: 0;
    }

    &:hover {
      button, .delete-btn {
        opacity: 0;
      }
    }
  `}

  ${({ isOverlay }) =>
    isOverlay &&
    `
    cursor: grabbing;
    z-index: 99999999;
    box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.2), 0px 0px 0px 1px rgba(0, 0, 0, 0.075),
      0px 2px 3px rgba(0, 0, 0, 0.1);
    
    button, .delete-btn {
      opacity: 0;
    }

    &:hover {
      cursor: grabbing;
  
      button, .delete-btn {
        opacity: 0;
      }
    }
  `}
`

export default StyledCreateEditorMediaItem
