import styled from 'styled-components'

type StyledProps = {
  isDragging: boolean
}

const StyledLinkPageLayoutBlock = styled.details<StyledProps>`
  position: relative;

  * {
    user-select: none;
  }

  summary {
    height: 50px;
    border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background-color 0.2s, border-radius 0.2s, box-shadow 0.2s, padding 0.2s;
    padding: 0 20px;

    .block-details {
      display: flex;
      align-items: center;

      .block-type {
        font-size: 13px;
        font-weight: 600;
        border-radius: 50rem;
        color: ${({ theme }) => theme.color.gray[600]};
        background-color: ${({ theme }) => theme.color.foreground}10;
        padding: 4px 8px;
        margin-right: 12px;
      }

      .block-title {
        font-weight: 500;
        color: ${({ theme }) => theme.color.gray[800]};
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    .block-actions {
      display: flex;
      align-items: center;
      margin-left: 10px;
      margin-right: -14px;
      flex-shrink: 0;
      transition: opacity 0.2s;

      button {
        padding: 0 6px;
        background-color: transparent;
        border-color: transparent;
      }
    }

    &:hover {
      cursor: grab;
      background-color: ${({ theme }) => theme.color.gray[100]};
    }

    &::-webkit-details-marker,
    &::marker {
      display: none;
      content: '';
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    summary {
      padding: 0 35px;
    }
  }

  ${({ isDragging, theme }) =>
    isDragging &&
    `
    
    summary {
      padding: 0 12px;
      box-sizing: border-box;
      width: calc(100% - 40px);
      border: 0 !important;
      background-color: ${theme.color.background} !important;
      box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
        0px 2px 3px rgba(0, 0, 0, 0.15);
      border-radius: 6px;
      cursor: grabbing !important;

      .block-actions {
        opacity: 0;
      }
    }
  `}
`

export default StyledLinkPageLayoutBlock
