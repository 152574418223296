import styled, { keyframes } from 'styled-components'

type StyledProps = {
  hasError?: boolean
  showTimeDropdown?: boolean
  isAbsolute?: boolean
}

const popInAnim = keyframes`
  from {
    opacity: 0;
    transform: translateY(6px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const StyledDateTimeInput = styled.div<StyledProps>`
  position: fixed;
  top: 100px;
  left: 100px;
  background-color: ${({ theme }) => theme.color.background};
  animation: ${popInAnim} 0.15s ease-in-out;
  z-index: 99999999999999;
  box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.1),
    0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 250px;
  user-select: none;

  * {
    box-sizing: border-box;
  }

  svg {
    stroke-width: 2px;
  }

  .time-dropdown {
    position: fixed;
    top: 100px;
    left: 100px;
    z-index: 99999999999999;
    width: 200px;
    transition: 0.2s;
    visibility: ${({ showTimeDropdown }) => (showTimeDropdown ? 'visible' : 'hidden')};
    opacity: ${({ showTimeDropdown }) => (showTimeDropdown ? 1 : 0)};
    background-color: ${({ theme }) => theme.color.background};
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: grid;
      grid-gap: 2px;
      padding: 4px;
      height: 200px;
      overflow-y: auto;

      li {
        padding: 0 12px;
        height: 34px;
        display: flex;
        align-items: center;
        border-radius: 6px;
        font-weight: 500;
        font-size: 15px;
        position: relative;
        overflow: hidden;
        transition: 0.2s;
        color: ${({ theme }) => theme.color.gray[600]};

        input {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;

          &:hover {
            cursor: pointer;
          }
        }

        &.active {
          background-color: ${({ theme }) => theme.color.brandLight};
          color: ${({ theme }) => theme.color.brand};

          &:hover {
            background-color: ${({ theme }) => theme.color.brandLight};
          }
        }

        &:hover {
          cursor: pointer;
          background-color: ${({ theme }) => theme.color.gray[100]};
        }
      }
    }
  }

  button {
    &:hover {
      cursor: pointer;
    }
  }

  .close-btn {
    position: absolute;
    top: 15px;
    right: 12px;
    height: 24px;
    width: 24px;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 0;
    background-color: ${({ theme }) => theme.color.background};
    color: ${({ theme }) => theme.color.gray[400]};
    transition: 0.2s;

    svg {
      height: 20px;
    }

    &:hover {
      background-color: ${({ theme }) => theme.color.gray[100]};
      color: ${({ theme }) => theme.color.gray[500]};
    }
  }

  .react-calendar {
    padding: 8px 12px;

    .react-calendar__navigation {
      position: relative;
      height: 38px;
      display: flex;
      justify-content: end;
      align-items: center;
      margin: 0 6px 6px 6px;
      padding-right: 25px;

      button {
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        padding: 0;
        background-color: ${({ theme }) => theme.color.background};
        color: ${({ theme }) => theme.color.gray[400]};
        transition: 0.2s;

        svg {
          height: 20px;
        }

        &:hover {
          background-color: ${({ theme }) => theme.color.gray[100]};
          color: ${({ theme }) => theme.color.gray[500]};
        }
      }

      .react-calendar__navigation__arrow {
        width: 24px;
        height: 24px;

        &:last-child {
          margin-right: -7px;
        }
      }

      .react-calendar__navigation__label {
        position: absolute;
        height: 38px;
        left: 0;
        padding: 0 8px;
        margin: 0 -8px;
        pointer-events: none;
        color: ${({ theme }) => theme.color.gray[700]};
        font-weight: 500;

        &:hover {
          background-color: ${({ theme }) => theme.color.gray[100]};
          color: ${({ theme }) => theme.color.gray[600]};
        }
      }
    }

    .react-calendar__viewContainer {
      .react-calendar__month-view {
        .react-calendar__month-view__weekdays {
          .react-calendar__month-view__weekdays__weekday {
            text-align: center;

            abbr {
              text-decoration: none;
              color: ${({ theme }) => theme.color.gray[400]};
              font-weight: 500;
              font-size: 14px;
            }
          }
        }

        .react-calendar__month-view__days {
          display: grid !important;
          grid-gap: 2px;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
          justify-items: center;
          margin-top: 4px;

          .react-calendar__tile {
            height: 30px;
            width: 30px;
            font-weight: 500;
            font-size: 15px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0;
            background-color: transparent;
            transition: background-color 0.2s;
            color: ${({ theme }) => theme.color.gray[600]};

            &:hover {
              background-color: ${({ theme }) => theme.color.gray[100]};
            }

            &.react-calendar__month-view__days__day--neighboringMonth {
              color: ${({ theme }) => theme.color.gray[400]};

              &:hover {
                background-color: ${({ theme }) => theme.color.background};
              }
            }

            &.react-calendar__tile--now {
              box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.gray[200]};
            }

            &.react-calendar__tile--active {
              color: ${({ theme }) => theme.color.brand};
              background-color: ${({ theme }) => theme.color.brandLight};
              box-shadow: none;

              &:hover {
                background-color: ${({ theme }) => theme.color.brandLight};
              }
            }
          }
        }
      }
    }
  }

  .time-input-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 18px 12px 18px;

    .time-input {
      height: 34px;
      position: relative;
      border-radius: 6px;
      overflow: hidden;
      display: flex;
      align-items: center;

      svg {
        position: absolute;
        height: 20px;
        pointer-events: none;
        left: 6px;
        color: ${({ hasError, theme }) =>
          hasError ? theme.color.red[600] : theme.color.gray[400]};
      }

      input {
        height: 34px;
        border-radius: 6px;
        padding: 0 0 0 32px;
        outline: none;
        transition: 0.2s;
        font-weight: 500;
        width: 100%;
        color: ${({ hasError, theme }) =>
          hasError ? theme.color.red[600] : theme.color.gray[600]};
        font-size: 15px;
        border: 1px solid
          ${({ hasError, theme }) => (hasError ? theme.color.red[600] : theme.color.gray[300])};
        background-color: ${({ hasError, theme }) =>
          hasError ? theme.color.red[50] : theme.color.background};

        &:hover,
        &:focus {
          background-color: ${({ hasError, theme }) =>
            hasError ? theme.color.red[50] : theme.color.gray[100]};
        }
      }
    }

    .time-meridiem-select {
      height: 34px;
      border-radius: 6px;
      display: flex;
      padding: 4px;
      background-color: ${({ theme }) => theme.color.gray[100]};
      margin-left: 8px;

      .time-meridiem-select-item {
        position: relative;

        input {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          border-radius: 4px;

          &:checked {
            pointer-events: none;

            & + .time-meridiem-select-item-box {
              background-color: ${({ theme }) => theme.color.background};
              color: ${({ theme }) => theme.color.gray[600]};
              box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
            }
          }

          &:not(:checked):hover + .time-meridiem-select-item-box {
            background-color: ${({ theme }) => theme.color.gray[200]};
          }

          &:hover {
            cursor: pointer;
          }
        }

        .time-meridiem-select-item-box {
          border-radius: 4px;
          height: 100%;
          width: 100%;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: transparent;
          padding: 4px 10px;
          font-size: 14px;
          font-weight: 600;
          transition: 0.2s;
          color: ${({ theme }) => theme.color.gray[500]};
        }

        &:last-child {
          margin-left: 4px;
        }
      }
    }
  }

  .date-time-input-divider {
    height: 1px;
    border: 0;
    width: 100%;
    margin: 0;
    background-color: ${({ theme }) => theme.color.gray[300]};
  }

  .date-time-input-actions {
    display: flex;
    padding: 0 18px 18px 18px;

    button {
      padding: 0 10px;
      font-weight: 500;
      user-select: none;
      height: 41px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      padding: 0 14px;
      text-align: center;
      transition: 0.2s;
      border-radius: 50rem;
      background-color: ${({ theme }) => theme.color.gray[100]};
      color: ${({ theme }) => theme.color.gray[500]};

      span {
        position: relative;
        top: 1px;
      }

      svg {
        height: 18px;
        margin-right: 8px;
      }

      &:hover {
        background-color: ${({ theme }) => theme.color.gray[200]};
      }

      &:not(:last-child) {
        margin-right: 8px;
      }

      &:disabled {
        background-color: ${({ theme }) => theme.color.gray[100]};
        color: ${({ theme }) => theme.color.gray[400]};

        &:hover {
          cursor: not-allowed;
          background-color: ${({ theme }) => theme.color.gray[100]};
        }
      }
    }

    .done-btn {
      width: 100%;
      background-color: ${({ theme }) => theme.color.brand};
      color: white;

      &:hover {
        background-color: ${({ theme }) => theme.color.brandDark};
      }
    }
  }

  ${({ isAbsolute }) =>
    isAbsolute &&
    `
    position: absolute;
    top: 0;
    left: calc(-250px - 7px);

    .time-dropdown {
      position: absolute;
    }
  `}
`

export default StyledDateTimeInput
