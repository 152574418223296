import { FC, useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { toast } from 'react-hot-toast'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import firebase from 'firebase/app'
import { uniq } from 'lodash'
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline'
import { ReactComponent as SparkleIcon } from '../../assets/sparkle-icon.svg'
import {
  ChatBotIcon,
  ConnectionDialog,
  Input,
  ScrollRow,
  Spinner,
  connectionStore
} from '../../components'
import { useAppSelector } from '../../redux'
import {
  ROUTES,
  getPlatformDescription,
  getPlatformLogo,
  getPlatformTitle,
  useForm,
  useMesh,
  useSegment,
  userStore
} from '../../utils'
import { OnboardingSelect } from './OnboardingSelect'
import { ages } from './data/ages'
import { occupations } from './data/occupations'
import { voices } from './data/voices'
import { StyledOnboardingView } from '.'

const OnboardingView: FC = () => {
  useMesh()
  const updateLoggedInUser = firebase.functions().httpsCallable('updateLoggedInUser')
  const w = window as any
  const location = useLocation()
  const history = useHistory()
  const { splits } = useAppSelector((state) => state.splits)
  const { user, refresh } = useContext(userStore)
  const { availableProfiles, connectedProfiles, connectPlatform } = useContext(connectionStore)
  const { track } = useSegment()
  const [step, setStep] = useState(0)
  const [loading, setLoading] = useState(false)
  const [params, setParams] = useState<{ [k: string]: string }>()
  const { form, updateFormValue } = useForm({
    use: 'Personal',
    occupation: '',
    company: '',
    ages: [],
    genders: [],
    occupations: [],
    voices: [],
    interests: []
  })

  const nextStep = () => {
    track('Completed Onboarding Step', {
      step
    })

    setStep(step + 1)
  }

  const handleSave = async () => {
    try {
      setLoading(true)

      await updateLoggedInUser({
        email: form.email,
        displayName: form.displayName,
        aiOnboarding: {
          ...form
        }
      })

      w.Intercom('update', {
        name: form.displayName,
        email: form.email,
        aiOnboarding: {
          ...form
        }
      })

      track('Completed Onboarding', {
        ...form
      })

      await refresh()

      if (params?.redirect) {
        window.location.href = params.redirect
        return
      }

      if (splits.pillars === 'on') {
        return history.push(ROUTES.plan, { redirect: 'none' })
      }

      history.push(ROUTES.home, { redirect: 'none' })
    } catch (err) {
      console.log(err)
      toast.error((err as any)?.message)
      setLoading(false)
    }
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    const params = Object.fromEntries(urlParams)
    setParams({ ...params, ...(location.state as any) })
  }, [location.search, location.state])

  if (!!user?.aiOnboarding?.occupation) {
    return <Redirect to={ROUTES.create} />
  }

  return (
    <>
      <Helmet title="Meet Dottie | Social Curator" />
      <StyledOnboardingView step={step}>
        <div className="onboarding-question">
          {step === 1 && (
            <div className="question">
              <div className="question-content">
                {/* <OnboardingSelect
                options={uses}
                defaultValue={
                  form.use && {
                    label: uses.find((u) => u.value === form.use)?.label,
                    value: form.use
                  }
                }
                isCreatable={false}
                isSearchable={false}
                placeholder="Select a use case"
                onChange={(value) => updateFormValue('use', value.value)}
              /> */}
                <div className="question-input">
                  <span className="question-input-label">
                    What do you call yourself (what is your job title)?{' '}
                    <span data-tooltip="Required" className="required">
                      *
                    </span>
                  </span>
                  <OnboardingSelect
                    options={occupations.map((o) => ({
                      label: o,
                      value: o
                    }))}
                    defaultValue={
                      form?.occupation && {
                        label: form?.occupation,
                        value: form?.occupation
                      }
                    }
                    value={
                      form?.occupation && {
                        label: form?.occupation,
                        value: form?.occupation
                      }
                    }
                    isCreatable={true}
                    isSearchable={true}
                    placeholder="Start typing..."
                    onChange={(value) => updateFormValue('occupation', value.label)}
                  />
                  <ScrollRow className="question-input-suggestions" arrowOffset={0} gap={8}>
                    <button
                      className="question-input-suggestion-btn"
                      onClick={() => updateFormValue('occupation', 'Photographer')}
                    >
                      Photographer
                    </button>
                    <button
                      className="question-input-suggestion-btn"
                      onClick={() => updateFormValue('occupation', 'Boutique Owner')}
                    >
                      Boutique Owner
                    </button>
                    <button
                      className="question-input-suggestion-btn"
                      onClick={() => updateFormValue('occupation', 'Marketing Coach')}
                    >
                      Marketing Coach
                    </button>
                    <button
                      className="question-input-suggestion-btn"
                      onClick={() => updateFormValue('occupation', 'Real Estate Agent')}
                    >
                      Real Estate Agent
                    </button>
                    <button
                      className="question-input-suggestion-btn"
                      onClick={() => updateFormValue('occupation', 'Hair Stylist')}
                    >
                      Hair Stylist
                    </button>
                  </ScrollRow>
                </div>
                <div className="question-input">
                  <span className="question-input-label">
                    What is the name of your business/brand?
                  </span>
                  <Input
                    value={form.company}
                    placeholder="Ex: Sally Smith Photography"
                    onChange={(e) => {
                      updateFormValue({
                        company: e.target.value,
                        use: !!e.target.value.trim() ? 'Business' : 'Personal'
                      })
                    }}
                  />
                </div>
              </div>
              <div className="button-container">
                <button disabled={!form?.occupation} onClick={() => nextStep()}>
                  Continue
                </button>
              </div>
            </div>
          )}
          {step === 2 && (
            <div className="question">
              <div className="question-content">
                <div className="question-input">
                  <span className="question-input-label">What are their ages?</span>
                  <OnboardingSelect
                    options={ages.map((o) => ({
                      label: o,
                      value: o
                    }))}
                    defaultValue={
                      form?.ages &&
                      form.ages.map((age: any) => ({
                        label: age,
                        value: age
                      }))
                    }
                    isMulti
                    forceMenu
                    placeholder="Select one or more ages"
                    onChange={(value) =>
                      updateFormValue(
                        'ages',
                        value.map((v: any) => v.label)
                      )
                    }
                  />
                </div>
                <div className="question-input">
                  <span className="question-input-label">What is their gender(s)?</span>
                  <OnboardingSelect
                    options={[
                      { label: 'Men', value: 'men' },
                      { label: 'Women', value: 'women' }
                    ]}
                    defaultValue={
                      form?.genders &&
                      form.genders.map((gender: any) => ({
                        label: gender,
                        value: gender
                      }))
                    }
                    isMulti
                    forceMenu
                    placeholder="Select one or more genders"
                    onChange={(value) =>
                      updateFormValue(
                        'genders',
                        value.map((v: any) => v.label)
                      )
                    }
                  />
                </div>
                <div className="question-input">
                  <span className="question-input-label">How do they refer to themselves?</span>
                  <OnboardingSelect
                    options={occupations.map((o) => ({
                      label: `${o}s`,
                      value: `${o}s`
                    }))}
                    value={
                      form?.occupations &&
                      form.occupations.map((occupation: any) => ({
                        label: occupation,
                        value: occupation
                      }))
                    }
                    isMulti
                    placeholder="Start typing..."
                    onChange={(value) =>
                      updateFormValue(
                        'occupations',
                        value.map((v: any) => v.label)
                      )
                    }
                  />
                  <ScrollRow className="question-input-suggestions" arrowOffset={0} gap={8}>
                    <button
                      className="question-input-suggestion-btn"
                      onClick={() =>
                        updateFormValue(
                          'occupations',
                          uniq([...form.occupations, 'High-achievers'])
                        )
                      }
                    >
                      High-achievers
                    </button>
                    <button
                      className="question-input-suggestion-btn"
                      onClick={() =>
                        updateFormValue('occupations', uniq([...form.occupations, 'Moms']))
                      }
                    >
                      Moms
                    </button>
                    <button
                      className="question-input-suggestion-btn"
                      onClick={() =>
                        updateFormValue('occupations', uniq([...form.occupations, 'Photographers']))
                      }
                    >
                      Photographers
                    </button>
                    <button
                      className="question-input-suggestion-btn"
                      onClick={() =>
                        updateFormValue('occupations', uniq([...form.occupations, 'Brides']))
                      }
                    >
                      Brides
                    </button>
                    <button
                      className="question-input-suggestion-btn"
                      onClick={() =>
                        updateFormValue(
                          'occupations',
                          uniq([...form.occupations, 'Burnt-Out Babes'])
                        )
                      }
                    >
                      Burnt-Out Babes
                    </button>
                  </ScrollRow>
                </div>
              </div>
              <div className="button-container">
                <button className="skip" onClick={() => nextStep()}>
                  Skip
                </button>
                <button
                  disabled={
                    !form?.ages?.length && !form?.genders?.length && !form?.occupations?.length
                  }
                  onClick={() => nextStep()}
                >
                  Continue
                </button>
              </div>
            </div>
          )}
          {step === 3 && (
            <div className="question">
              <div className="question-content">
                <div className="question-input">
                  <span className="question-input-label">
                    What is your voice?{' '}
                    <span data-tooltip="Required" className="required">
                      *
                    </span>
                  </span>
                  <OnboardingSelect
                    options={voices}
                    defaultValue={
                      form?.voices &&
                      form.voices.map((voice: any) => ({
                        label: voice,
                        value: voice
                      }))
                    }
                    forceMenu
                    isMulti
                    placeholder="Select one or more voices"
                    onChange={(value) =>
                      updateFormValue(
                        'voices',
                        value.map((v: any) => v.label)
                      )
                    }
                  />
                </div>
              </div>
              <div className="button-container">
                <button disabled={!form?.voices?.length} onClick={() => nextStep()}>
                  Continue
                </button>
              </div>
            </div>
          )}
          {/* {step === 4 && (
            <div className="question">
              <div className="question-content">
                <div className="question-input">
                  <span className="question-input-label">Select a platform to connect</span>
                  <div className="platform-select">
                    {['instagram', 'facebook', 'linkedin', 'twitter'].map((platform: any) => {
                      const connected = !!connectedProfiles.find(
                        (profile) => profile.type === platform
                      )

                      return (
                        <button
                          className="platform-select-btn"
                          disabled={connected}
                          onClick={() => connectPlatform(platform)}
                        >
                          <div className="platform-details-container">
                            <div className="platform-image">
                              <img
                                src={`/images/logos/${getPlatformLogo(platform)}`}
                                alt={`${getPlatformTitle(platform)} Logo`}
                              />
                            </div>
                            <div className="platform-details">
                              <span className="platform-name">{getPlatformTitle(platform)}</span>
                              <span className="platform-description">
                                {getPlatformDescription(platform)}
                              </span>
                            </div>
                          </div>
                          <div className="connect-label">{connected ? 'Connected' : 'Connect'}</div>
                        </button>
                      )
                    })}
                  </div>
                </div>
              </div>
              <div className="button-container">
                {connectedProfiles.length < 4 && (
                  <button className="skip" onClick={() => nextStep()}>
                    Skip {!connectedProfiles.length && 'add later'}
                  </button>
                )}
                {!!connectedProfiles.length && <button onClick={() => nextStep()}>Continue</button>}
              </div>
            </div>
          )} */}
        </div>
        <div className="onboarding-fluid">
          {step === 0 && (
            <div className="intro-text">
              <div className="dottie-icon-intro">
                <ChatBotIcon size={55} />
              </div>
              <h1>Hi {user?.displayName.split(' ')[0]}, I'm Dottie!</h1>
              <h2>
                As your social media marketing manager, I’m ready to create scroll-stopping posts
                that sound just like you!
              </h2>
              <div className="button-container">
                <button onClick={() => nextStep()}>
                  Let's get started <ArrowRightIcon />
                </button>
              </div>
            </div>
          )}
          {step === 1 && (
            <div className="intro-text">
              <div className="dottie-icon-intro">
                <ChatBotIcon size={55} />
              </div>
              <h1>What do you do?</h1>
              <h2>
                This information helps me create
                <br />
                highly customized social content.
              </h2>
            </div>
          )}
          {step === 2 && (
            <div className="intro-text">
              <div className="dottie-icon-intro">
                <ChatBotIcon size={55} />
              </div>
              <h1>Who’s your ideal audience?</h1>
              <h2>
                This information helps me write social content that resonate with your audience.
              </h2>
            </div>
          )}
          {step === 3 && (
            <div className="intro-text">
              <div className="dottie-icon-intro">
                <ChatBotIcon size={55} />
              </div>
              <h1>What's your voice?</h1>
              <h2>Let me know how you want your copy to sound so I get it just right!</h2>
            </div>
          )}
          {/* {step === 4 && (
            <div className="intro-text">
              <div className="dottie-icon-intro">
                <ChatBotIcon size={55} />
              </div>
              <h1>Connect your account(s)</h1>
              <h2>
                I can schedule and auto-publish posts directly to your social profiles for you.
              </h2>
            </div>
          )} */}
          {step === 4 && (
            <div className="intro-text">
              <div className="dottie-icon-intro">
                <ChatBotIcon size={55} />
              </div>
              <h1>Awesome, let's do this.</h1>
              <h2>Thanks for answering my questions.</h2>
              <div className="button-container">
                <button className="enter-btn" disabled={loading} onClick={() => handleSave()}>
                  {loading ? <Spinner /> : 'Enter Social Curator'}
                </button>
                <SparkleIcon className="sparkle" />
                <SparkleIcon className="sparkle" />
              </div>
            </div>
          )}
          <canvas id="gradient-canvas" />
        </div>
        {step !== 0 && (
          <button className="back-btn" onClick={() => setStep(step - 1)}>
            <ArrowLeftIcon />
          </button>
        )}
        <ConnectionDialog isOpen={!!availableProfiles.length} onClose={() => {}} />
      </StyledOnboardingView>
    </>
  )
}

export default OnboardingView
