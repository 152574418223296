import styled from 'styled-components'

const StyledCreateBarContentBits = styled.div`
  height: 100%;
  display: grid;
  padding: 20px;
  box-sizing: border-box;
`

export default StyledCreateBarContentBits
