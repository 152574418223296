import { useEffect, useState } from 'react'
import { throttle } from 'lodash'
import { Capacitor } from '@capacitor/core'
import { theme } from '..'

const isMobile = () => {
  return window.innerWidth <= theme.breakpoint.s || Capacitor.isNativePlatform()
}

export const useIsDesktop = () => {
  const [isDesktop, setIsDesktop] = useState<boolean>(!isMobile())

  const handleIsDesktop = throttle(() => {
    setIsDesktop(!isMobile())
  }, 100)

  useEffect(() => {
    handleIsDesktop()
    window.addEventListener('resize', handleIsDesktop)

    return () => {
      window.removeEventListener('resize', handleIsDesktop)
    }
  }, [])

  return { isDesktop }
}
