import styled from 'styled-components'

const StyledCreateEditor = styled.div`
  background-color: ${({ theme }) => theme.color.background};
  border-radius: 12px;
  box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 900px;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);

  .create-editor-header {
    position: relative;

    .tabs {
      height: 56px;

      .tab-list {
        padding: 0 30px;
      }
    }

    .post-status {
      position: absolute;
      user-select: none;
      pointer-events: none;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      font-size: 15px;
      border-radius: 6px;
      padding: 0 10px 0 8px;
      height: 36px;
      color: ${({ theme }) => theme.color.gray[500]};
      background-color: ${({ theme }) => theme.color.gray[100]};
      font-weight: 500;

      svg {
        height: 20px;
        margin-right: 4px;
      }
    }
  }

  .editor {
    padding: 30px;
    display: grid;
    gap: 30px;
    align-content: start;
  }
`

export default StyledCreateEditor
