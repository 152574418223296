import styled from 'styled-components'

const StyledActionPlanPhotoSets = styled.div`
  .action-plan-photos-list {
    display: grid;
    grid-gap: 20px 14px;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }

  &:not(:first-child) {
    margin-top: 20px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    &:not(:first-child) {
      margin-top: 40px;
    }
  }
`

export default StyledActionPlanPhotoSets
