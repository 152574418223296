import { FC, InputHTMLAttributes } from 'react'
import { v4 as uuid } from 'uuid'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { ScrollRow } from '../../../../components'
import { conditionalStage } from '../../../../utils'
import { StyledInput } from '.'

type Props = {
  label?: string
  dataList?: string[]
  suggestions?: string[]
  onChange?: (value: string) => void
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>

const Input: FC<Props> = ({ label, dataList, suggestions, required, onChange, ...rest }) => {
  const listID = uuid()

  return (
    <StyledInput>
      {label && (
        <div className="input-label">
          {label} {required && <div className="required-marker">*</div>}
        </div>
      )}
      <div className="input">
        <input
          required={required}
          {...rest}
          {...conditionalStage(!!dataList?.length, { list: listID })}
          {...conditionalStage(!!onChange, { onChange: (e: any) => onChange!(e.target.value) })}
        />
        {!!dataList?.length && <ChevronDownIcon className="list-icon" />}
      </div>
      {!!dataList?.length && (
        <datalist id={listID}>
          {dataList.map((item) => (
            <option value={item} key={item} />
          ))}
        </datalist>
      )}
      {!!suggestions?.length && !!onChange && (
        <div className="suggestions">
          {suggestions.map((suggestion) => {
            return <button onClick={() => onChange(suggestion)}>{suggestion}</button>
          })}
        </div>
      )}
    </StyledInput>
  )
}

export default Input
