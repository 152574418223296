import styled from 'styled-components'

const StyledAcademyCourseModulePreview = styled.details`
  border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
  background-color: ${({ theme }) => theme.color.background};

  summary {
    user-select: none;
    max-width: 835px;
    margin: 0 auto;
    padding: 20px;
    font-size: 16px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
    color: ${({ theme }) => theme.color.gray[800]};
    font-weight: 500;

    mark {
      margin-right: 10px;
      padding: 6px 12px;
      border-radius: 50rem;
      color: ${({ theme }) => theme.color.gray[600]};
      background-color: ${({ theme }) => theme.color.gray[100]};
    }

    svg {
      color: ${({ theme }) => theme.color.gray[500]};
      height: 20px;
      stroke-width: 2px;
    }

    &::-webkit-details-marker,
    &::marker {
      display: none;
      content: '';
    }
  }

  .course-lesson-list-container {
    max-width: 835px;
    margin: 0 auto;
    padding: 0 20px 20px 20px;

    .course-lesson-list {
      margin-top: 2px;
      position: relative;

      .react-horizontal-scrolling-menu--separator {
        width: 10px;
        flex-shrink: 0;
      }

      .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
        display: none;
      }

      .react-horizontal-scrolling-menu--scroll-container {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }

      .arrow-background {
        position: absolute;
        z-index: 1;
        width: 100px;
        display: flex;
        top: 0;
        bottom: 0;
        pointer-events: none;

        .arrow-btn {
          height: 36px;
          width: 36px;
          position: absolute;
          border-radius: 50%;
          pointer-events: all;
          top: 50%;
          transform: translateY(-50%);
          background-color: rgb(0, 0, 0, 0.55);
          color: ${({ theme }) => theme.color.background};
          border: 0;
        }

        &.arrow-left {
          left: 0px;
          background: linear-gradient(90deg, ${({ theme }) => theme.color.background}, transparent);

          .arrow-btn {
            left: -18px;
          }
        }

        &.arrow-right {
          right: 0;
          background: linear-gradient(90deg, transparent, ${({ theme }) => theme.color.background});
          flex-direction: row-reverse;

          .arrow-btn {
            right: -18px;
          }
        }
      }
    }
  }

  .lesson-learn-container {
    position: relative;
    padding: 0 20px;
    background-color: ${({ theme }) => theme.color.gray[100]};

    .width-controller {
      display: flex;
      padding: 14px 0;
      align-items: center;
      position: relative;

      .lesson-learn-container-caret {
        position: absolute;
        left: 0;
        height: 20px;
        width: 20px;
        transform: rotate(45deg);
        bottom: calc(100% - 10px);
        background-color: ${({ theme }) => theme.color.gray[100]};
      }

      .lesson-learn-pills-label {
        margin-right: 20px;
        color: ${({ theme }) => theme.color.gray[600]};
        font-weight: 500;
        font-size: 14px;
      }

      .lesson-learn-pills {
        display: flex;
        align-items: center;

        .lesson-learn-pill {
          display: flex;
          align-items: center;
          border-radius: 50rem;
          padding: 0 12px;
          height: 28px;
          border: 1px solid ${({ theme }) => theme.color.gray[400]};
          color: ${({ theme }) => theme.color.gray[600]};

          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }
    }
  }
`

export default StyledAcademyCourseModulePreview
