import styled from 'styled-components'

const StyledCreateAddMediaButton = styled.div`
  padding: 16px;

  button {
    border-radius: 12px;
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 10px;
    font-weight: 600;
    color: ${({ theme }) => theme.color.gray[600]};
    background-color: ${({ theme }) => theme.color.gray[200]};

    svg {
      height: 32px;
      margin-right: 8px;
    }
  }
`

export default StyledCreateAddMediaButton
