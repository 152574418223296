import { FC } from 'react'
import { updateCreatePost, useAppDispatch, useAppSelector } from '../../../../redux'
import { StyledCreateTitleInput } from '.'

type Props = {
  onClose: () => void
}

const CreateTitleInput: FC<Props> = ({ onClose }) => {
  const dispatch = useAppDispatch()
  const { post } = useAppSelector((state) => state.create)

  return (
    <StyledCreateTitleInput>
      <input
        type="text"
        placeholder="Untitled Post"
        enterKeyHint="done"
        value={post?.title !== 'Untitled Post' ? post?.title : undefined}
        autoFocus
        onKeyDown={(e) => e.key === 'Enter' && onClose()}
        onFocus={(e) => e.target.select()}
        onBlur={() => onClose()}
        onChange={(e) => dispatch(updateCreatePost({ title: e.target.value }))}
      />
    </StyledCreateTitleInput>
  )
}

export default CreateTitleInput
