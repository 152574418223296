import styled from 'styled-components'

const StyledCreateEditorLabels = styled.div`
  .label {
    cursor: default;
    user-select: none;
    margin-bottom: 12px;

    span {
      display: block;
    }

    .label-title {
      color: ${({ theme }) => theme.color.gray[800]};
      font-weight: 500;
    }

    .label-description {
      color: ${({ theme }) => theme.color.gray[500]};
      font-size: 14px;
      margin-top: 4px;
    }
  }

  .label-list {
    margin: 0 -6px -6px 0;

    .label-input-container {
      height: 38px;
      border-radius: 6px;
      border: 1px solid ${({ theme }) => theme.color.gray[300]};
      position: relative;
      display: inline-flex;
      align-items: center;
      overflow: hidden;
      width: fit-content;
      margin: 0 6px 6px 0;
      transition: 0.2s;

      .label-input {
        display: flex;

        input {
          padding: 10px;

          &::placeholder {
            transition: 0.2s;
            color: ${({ theme }) => theme.color.gray[400]};
          }
        }
      }

      .label-input-actions {
        height: 28px;
        display: flex;
        align-items: center;
        padding: 0 6px;
        display: none;
        border-left: 1px solid ${({ theme }) => theme.color.gray[300]};

        button {
          height: 28px;
          width: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background-color: ${({ theme }) => theme.color.gray[200]};
          color: ${({ theme }) => theme.color.gray[500]};

          svg {
            height: 18px;
            stroke-width: 2.4px;
          }

          &.confirm-btn {
            color: white;
            background-color: ${({ theme }) => theme.color.brand};
            margin-left: 6px;
          }
        }
      }

      &[data-has-value='false']:not(:focus-within) {
        .label-input {
          input {
            &::placeholder {
              color: ${({ theme }) => theme.color.gray[700]};
            }

            &:hover {
              cursor: pointer;
            }
          }
        }

        &:hover {
          border-color: ${({ theme }) => theme.color.gray[400]};
        }
      }

      &[data-has-value='true'] {
        .label-input-actions {
          display: flex;
        }
      }
    }

    .label-item {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      user-select: none;
      height: 40px;
      padding: 0 10px;
      border-radius: 6px;
      transition: 0.2s;
      position: relative;
      background-color: ${({ theme }) => theme.color.gray[100]};
      color: ${({ theme }) => theme.color.gray[600]};
      margin: 0 6px 6px 0;

      svg {
        color: ${({ theme }) => theme.color.red[900]};
        opacity: 0;
        transition: 0.2s;
        height: 24px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &:hover {
        background-color: ${({ theme }) => theme.color.red[100]};
        color: transparent;

        svg {
          opacity: 1;
        }
      }
    }
  }
`

export default StyledCreateEditorLabels
