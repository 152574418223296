import { useEffect } from 'react'
import { Capacitor } from '@capacitor/core'
import { Keyboard, KeyboardResize, KeyboardStyle } from '@capacitor/keyboard'
import { useAppSelector } from '../../redux'

export const useKeyboard = () => {
  const { theme, showChat } = useAppSelector((state) => state.state)

  const handleKeyboard = () => {
    if (!Capacitor.isPluginAvailable('Keyboard')) {
      return
    }

    Keyboard.setResizeMode({ mode: KeyboardResize.None })
    Keyboard.setStyle({ style: theme === 'dark' ? KeyboardStyle.Dark : KeyboardStyle.Light })
  }

  useEffect(() => {
    handleKeyboard()
  }, [theme, showChat])
}
