import { FC, useContext, useEffect, useState } from 'react'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { parse } from 'query-string'
import { Spinner } from '../../components'
import { ROUTES, useConnections, userStore } from '../../utils'
import { StyledConnectPlatformView } from '.'

const ConnectPlatformView: FC = () => {
  const location = useLocation()
  const history = useHistory()
  const { user } = useContext(userStore)
  const { handleConnectAccountsFromState } = useConnections()
  const [loading, setLoading] = useState(false)

  const connectProfile = async () => {
    setLoading(true)
    const parsed = {
      ...parse(location.search),
      ...parse(location.hash)
    }

    if (!!parsed.mobile) {
      window.location.href = `socialcurator://${location.search}`
      return
    }

    if (!user?.id) {
      history.push(ROUTES.auth.register)
      return
    }

    await handleConnectAccountsFromState(parsed)

    window.opener.postMessage({
      type: 'connected-profile',
      access_token: parsed.access_token
    })

    window.close()
  }

  useEffect(() => {
    if (loading) {
      return
    }

    connectProfile()
  }, [loading, user, location.search])

  return (
    <StyledConnectPlatformView>
      <div className="connect-progress">
        <Spinner isLarge />
        <span>Connecting profile</span>
      </div>
    </StyledConnectPlatformView>
  )
}

export default ConnectPlatformView
