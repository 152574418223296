import { gql } from '@apollo/client'

export const registerUser = gql`
  mutation register(
    $fullName: String!
    $email: String!
    $password: String!
    $plan: String!
    $coupon: String
    $affiliate: String
    $paymentMethod: String
  ) {
    register(
      fullName: $fullName
      email: $email
      password: $password
      plan: $plan
      coupon: $coupon
      affiliate: $affiliate
      paymentMethod: $paymentMethod
    )
  }
`
