import { FC, createRef } from 'react'
import { Link, useParams } from 'react-router-dom'
import { CloudIcon } from '@heroicons/react/20/solid'
import { ROUTES, getParentFromPath, useUploadManager } from '../../../utils'
import { StyledUploadsSidebarHeader } from '.'

const UploadsSidebarHeader: FC = () => {
  const inputRef = createRef<HTMLInputElement>()
  const { addToUploads } = useUploadManager()
  const { path }: any = useParams()

  return (
    <StyledUploadsSidebarHeader>
      <Link to={ROUTES.uploads.all} className="uploads-label">
        <CloudIcon /> My Uploads
      </Link>
      <h1 className="folder-label">{getParentFromPath(path) || 'All files'}</h1>
      <button className="upload-btn" onClick={() => inputRef.current?.click()}>
        <input
          type="file"
          accept="image/*, video/*"
          multiple
          hidden
          onChange={(e) => addToUploads(e.target.files, false, getParentFromPath(path))}
          ref={inputRef}
        />
        Upload files
      </button>
    </StyledUploadsSidebarHeader>
  )
}

export default UploadsSidebarHeader
