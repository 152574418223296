import { FC, useEffect } from 'react'
import { toast } from 'react-hot-toast'
import InfiniteScroll from 'react-infinite-scroll-component'
import { times } from 'lodash'
import { useApolloClient } from '@apollo/client'
import { searchUserDraftsQuery } from '../../../graphql'
import {
  changePostsPage,
  changePostsTotalDocs,
  setPosts,
  setPostsTags,
  useAppDispatch,
  useAppSelector
} from '../../../redux'
import { conditionalStage, isMobile } from '../../../utils'
import { EmptyState, Skeleton } from '../..'
import { PostGridListItem } from '..'
import { StyledPostGridList } from '.'

const PostGridList: FC = () => {
  const apollo = useApolloClient()
  const dispatch = useAppDispatch()
  const {
    posts,
    loading,
    query,
    sort,
    page,
    onlyLiked,
    filters,
    totalDocs,
    profiles,
    status,
    onlyUsed,
    onlyScheduled
  } = useAppSelector((state) => state.posts)

  const loadPosts = async () => {
    try {
      const { data } = await apollo.query({
        fetchPolicy: 'no-cache',
        query: searchUserDraftsQuery,
        variables: {
          page,
          status,
          search: query,
          categories: filters,
          liked: onlyLiked,
          hideUsed: onlyUsed,
          profiles,
          onlyScheduled,
          sort,
          items: 15
        }
      })

      const postList = data?.searchUserDrafts?.docs || []
      dispatch(setPosts([...posts, ...postList]))
      dispatch(setPostsTags(data.searchUserDrafts.tags || []))
      dispatch(changePostsTotalDocs(data?.searchUserDrafts?.totalDocs || 0))
    } catch (err) {
      console.log(err)
      toast.error((err as any).message)
    }
  }

  useEffect(() => {
    if (loading) {
      loadPosts()
    }
  }, [loading])

  return (
    <StyledPostGridList>
      {loading && page === 0 && (
        <div className="post-list">
          {times(8).map((index) => {
            return <Skeleton styles="padding-top: 100%; border-radius: 10px;" key={index} />
          })}
        </div>
      )}
      {!loading && !posts.length && (
        <EmptyState
          title="No posts found"
          body="We couldn't find any posts matching your search."
        />
      )}
      <InfiniteScroll
        scrollThreshold={0.7}
        dataLength={posts.length}
        next={() => dispatch(changePostsPage(page + 1))}
        hasMore={posts.length < totalDocs}
        loader={null}
        className="infinite-scroll"
        {...conditionalStage(isMobile(), { scrollableTarget: 'app-content' })}
      >
        <div className="post-list">
          {posts.map((post) => {
            return <PostGridListItem post={post} key={post._id} />
          })}
        </div>
      </InfiniteScroll>
    </StyledPostGridList>
  )
}

export default PostGridList
