import { FC, useContext, useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import { ArrowUpRightIcon } from '@heroicons/react/24/outline'
import { BoltIcon } from '@heroicons/react/24/solid'
import { pushToDialogStack, useAppDispatch } from '../../../redux'
import { isPro, useSegment, useSubscription, userStore } from '../../../utils'
import { ActionDialog, Button, CancelPlanDialog, EmptyState } from '../..'
import { StyledCurrentPlanCard } from '.'

const CurrentPlanCard: FC = () => {
  const dispatch = useAppDispatch()
  const {
    plan,
    loading,
    actions: { resubscribePlan }
  } = useSubscription()
  const { track } = useSegment()
  const { user } = useContext(userStore)
  const [showCancelDialog, setShowCancelDialog] = useState(false)
  const [showResubscribeDialog, setShowResubscribeDialog] = useState(false)
  const [userIsPro, setUserIsPro] = useState(isPro(user))

  const getFreq = () => {
    switch (plan?.interval) {
      case 'year':
        return 'Yearly'
      case 'month':
        return 'Monthly'
      case 'week':
        return 'Free Trial'
      case 'forever':
        return 'Forever'
    }
  }

  useEffect(() => {
    setUserIsPro(isPro(user))
  }, [user?.stripeSubscriptionStatus, user?.trial, user?.subscriptionInterval])

  if (loading) {
    return <span>Loading...</span>
  }

  return (
    <>
      {!plan && (
        <EmptyState
          title="Your plan has ended"
          body="Go Pro and get back to using Social Curator."
          button={
            <Button variant="emphasis" onClick={() => dispatch(pushToDialogStack('go-pro'))}>
              Go Pro <BoltIcon style={{ margin: 0 }} />
            </Button>
          }
        />
      )}
      {!!plan && (
        <StyledCurrentPlanCard className="billing-card">
          <div className="billing-card-content">
            <div className="billing-card-content-group">
              <div className="billing-card-details">
                <span className="billing-card-title">
                  Pro Plan <span className="billing-card-freq">{getFreq()}</span>
                </span>
                <span className="billing-card-description">
                  {plan?.renews && userIsPro && (
                    <>
                      Renews on{' '}
                      {DateTime.fromSeconds(plan?.endDate).toLocaleString({
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric'
                      })}
                    </>
                  )}
                  {(!userIsPro || !!plan.cancelDate) && (
                    <>
                      Ends on{' '}
                      {DateTime.fromSeconds(plan?.cancelDate || plan?.endDate).toLocaleString({
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric'
                      })}
                    </>
                  )}
                </span>
              </div>
              {userIsPro && (
                <span className="billing-card-price">
                  <mark>${plan.amount}</mark> per {plan.interval}
                </span>
              )}
            </div>
          </div>
          <div className="billing-card-footer">
            {userIsPro && !plan.cancelDate && (
              <Button
                size="sm"
                variant="destructive"
                onClick={() => {
                  setShowCancelDialog(true)
                  track('Clicked Cancel Button')
                }}
              >
                Unsubscribe
              </Button>
            )}
            {(!userIsPro || !!plan.cancelDate) && <div />}
            {(plan?.interval === 'month' || user?.trial) && (
              <Button
                size="sm"
                variant="emphasis-outline"
                iconPos="right"
                onClick={() => dispatch(pushToDialogStack('go-pro'))}
              >
                Upgrade plan <ArrowUpRightIcon />
              </Button>
            )}
            {!!plan.cancelDate && !user?.trial && (
              <Button
                size="sm"
                variant="emphasis-outline"
                iconPos="right"
                onClick={() => setShowResubscribeDialog(true)}
              >
                Resubscribe <ArrowUpRightIcon />
              </Button>
            )}
          </div>
        </StyledCurrentPlanCard>
      )}
      <CancelPlanDialog isOpen={showCancelDialog} onClose={() => setShowCancelDialog(false)} />
      <ActionDialog
        title="Reactivate Pro Plan"
        body="Are you sure you want to reactivate your Social Curator subscription?"
        isOpen={showResubscribeDialog}
        onClose={() => setShowResubscribeDialog(false)}
        onConfirm={() => resubscribePlan()}
      />
    </>
  )
}

export default CurrentPlanCard
