import styled from 'styled-components'

const StyledLinkPageAnalyticsTab = styled.div`
  display: grid;
  padding: 20px;
  row-gap: 14px;

  .page-link-analytic {
    display: flex;
    align-items: center;
    user-select: none;
    justify-content: space-between;

    b {
      font-weight: 500;
      color: ${({ theme }) => theme.color.gray[800]};
    }

    span {
      display: block;
      padding: 6px 12px;
      font-size: 14px;
      font-weight: 500;
      border-radius: 50rem;
      color: ${({ theme }) => theme.color.gray[600]};
      background-color: ${({ theme }) => theme.color.gray[100]};
    }

    &:not(:last-child) {
      padding-bottom: 14px;
      border-bottom: 1px solid ${({ theme }) => theme.color.gray[200]};
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    padding: 35px;
  }
`

export default StyledLinkPageAnalyticsTab
