import styled from 'styled-components'

type StyledProps = {
  showBackArrow?: boolean
}

const StyledAcademyHeader = styled.div<StyledProps>`
  background-color: ${({ theme }) => theme.color.background};
  border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
  z-index: ${({ theme }) => theme.elevation.fixed};
  position: sticky;
  top: 0;

  .header {
    max-width: 835px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 53px;
    padding: 0 20px;

    .logo-link {
      display: flex;
      align-items: center;
      color: ${({ theme }) => theme.color.gray[900]};

      .back-icon {
        transition: 0.2s;
        flex-shrink: 0;
        opacity: 0.6;
        max-width: ${({ showBackArrow }) => (showBackArrow ? 32 : 0)}px;
        stroke-width: 1.6px;
        height: 26px;
        margin: 0 6px 0 -9px;
      }

      svg {
        height: 26px;
        width: fit-content;
      }
    }

    .header-links {
      display: flex;

      a {
        text-decoration: none;

        button {
          height: 40px;
          padding: 0 18px;
          font-weight: 500;
          color: ${({ theme }) => theme.color.gray[900]};
          border: 1px solid ${({ theme }) => theme.color.gray[900]};
          background-color: ${({ theme }) => theme.color.background};
          transition: 0.2s;
          border-radius: 4px;

          &:hover {
            background-color: ${({ theme }) => theme.color.gray[200]};
          }

          &.header-register-btn {
            background-color: ${({ theme }) => theme.color.brand};
            border: 1px solid ${({ theme }) => theme.color.brand};
            color: white;
            font-weight: 500;

            &:hover {
              background-color: ${({ theme }) => theme.color.brandDark};
            }
          }
        }

        &:not(:last-child) {
          margin-right: 8px;
        }
      }
    }
  }
`

export default StyledAcademyHeader
