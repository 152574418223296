import { FC, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { PhotoSlider } from 'react-photo-view'
import { useHistory, useParams } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
import { galleryImageFindOne } from '../../../graphql'
import { useAppSelector } from '../../../redux'
import { PhotoType } from '../../../types'
import { ROUTES, isMobile } from '../../../utils'
import { Spinner } from '../..'
import { PhotoViewerToolBar } from '.'

type Props = {
  isOpen: boolean
  photo?: string
  onChange: (photo?: PhotoType) => void
}

const PhotoViewer: FC<Props> = ({ isOpen, photo, onChange }) => {
  const history = useHistory()
  const apollo = useApolloClient()
  const { _id }: any = useParams()
  const { photos } = useAppSelector((state) => state.photos)
  const [index, setIndex] = useState<number>()
  const [loadedPhoto, setLoadedPhoto] = useState<PhotoType>()

  const tryLoadPhoto = async () => {
    try {
      const { data } = await apollo.query({
        fetchPolicy: 'no-cache',
        query: galleryImageFindOne,
        variables: {
          _id
        }
      })

      if (!data) {
        return handleClose()
      }

      setIndex(0)
      setLoadedPhoto(data.galleryImageFindOne)

      if (isMobile()) {
        return
      }

      history.push(ROUTES.photoById(_id), { scroll: 'none' })
    } catch (err) {
      toast.error((err as any).message)
    }
  }

  const handleChange = (index: number) => {
    const photo = photos[index]
    setIndex(index)

    if (isMobile()) {
      return
    }

    history.push(ROUTES.photoById(photo._id), { scroll: 'none' })
  }

  const handleClose = () => {
    setIndex(undefined)
    onChange(undefined)
    setLoadedPhoto(undefined)

    if (isMobile()) {
      return
    }

    history.push(ROUTES.gallery, { scroll: 'none' })
  }

  useEffect(() => {
    const photoId = photo || _id

    if (!photoId) {
      setIndex(0)

      if (isMobile()) {
        return
      }

      history.push(ROUTES.gallery, { scroll: 'none' })
      return
    }

    const index = photos.findIndex((p) => p._id === photoId)

    if (index < 0) {
      tryLoadPhoto()
      return
    }

    setIndex(index)

    if (isMobile()) {
      return
    }

    history.push(ROUTES.photoById(photoId), { scroll: 'none' })
  }, [photo])

  if (!photos.length && !_id) {
    return <></>
  }

  return (
    <PhotoSlider
      className="photo-view"
      images={
        loadedPhoto
          ? [{ src: loadedPhoto.file.fileHttpLink || '', key: loadedPhoto._id }]
          : photos.map((photo) => ({ src: photo.file.fileHttpLink || '', key: photo._id }))
      }
      visible={isOpen || !!loadedPhoto}
      onClose={() => handleClose()}
      index={index}
      onIndexChange={(index) => handleChange(index)}
      loadingElement={<Spinner isLarge />}
      loop={false}
      overlayRender={({ scale, onScale }) => {
        return (
          <PhotoViewerToolBar
            photo={loadedPhoto || photos[index || 0]}
            scale={scale}
            onScale={(number) => onScale(number)}
            onClose={() => handleClose()}
          />
        )
      }}
      maskOpacity={0.8}
    />
  )
}

export default PhotoViewer
