import { FC } from 'react'
import { useAppSelector } from '../../../redux'
import { useChat } from '../../../utils'
import { ScrollRow } from '../../system'
import { StyledChatSuggestions } from '.'

const ChatSuggestions: FC = () => {
  const { loading, ref, suggestions } = useAppSelector((state) => state.chat)
  const { sendMessage } = useChat()

  const getSuggestion = (suggestion: string) => {
    const santized = suggestion
      .replace(/(<([^>]+)>)/gi, '')
      .replace(/[.,\/#!$%\^&\*;:{}=\\_`~()]/g, '')
      .replace('Ask me to', '')
      .replace('Ask me for', '')
      .replace('Ask me', '')
      .replace('your', 'my')
      .replace('yours', 'mine')
      .replace('you', 'me')
      .trim()

    return santized.charAt(0).toUpperCase() + santized.slice(1)
  }

  if (!!ref || !suggestions.length) {
    return null
  }

  return (
    <StyledChatSuggestions>
      <ScrollRow gap={4} arrowOffset={0}>
        {suggestions.map((suggestion) => {
          return (
            <button
              className="suggestion"
              key={getSuggestion(suggestion)}
              onClick={() => sendMessage(getSuggestion(suggestion))}
            >
              {getSuggestion(suggestion)}
            </button>
          )
        })}
      </ScrollRow>
    </StyledChatSuggestions>
  )
}

export default ChatSuggestions
