import styled from 'styled-components'

const StyledCreateEditorProfilePicker = styled.div`
  .label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: default;
    user-select: none;
    margin-bottom: 12px;
    color: ${({ theme }) => theme.color.gray[800]};
    font-weight: 500;

    button {
      padding: 4px;
      margin: -4px;
      border-radius: 6px;
      background-color: transparent;
      color: ${({ theme }) => theme.color.gray[500]};
      transition: 0.2s;

      &:hover {
        color: ${({ theme }) => theme.color.gray[800]};
        background-color: ${({ theme }) => theme.color.gray[100]};
      }
    }
  }
`

export default StyledCreateEditorProfilePicker
