import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/firestore'
import 'firebase/functions'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
}

firebase.initializeApp(firebaseConfig)

// if (process.env.NODE_ENV === 'development') {
//   const functions = firebase.functions()
//   functions.useFunctionsEmulator(`http://${window.location.hostname}:5001`)
// }

// const { projectId } = firebase.app().options as any
// export const isProduction = projectId === 'thofjuly-aeb65'

// export const functionEndpoint = (cloudFunctionName: string) => {
//   if (process.env.NODE_ENV === 'development') {
//     return `http://localhost:5001/curator75/us-central1/${cloudFunctionName}`
//   }

//   return `https://us-central1-${projectId}.cloudfunctions.net/${cloudFunctionName}`
// }
