import { ShepherdOptionsWithType } from 'react-shepherd'
import { waitForElementExists } from '../../../utils'
import { removeEmptySteps } from '..'

export const generateTour = (isMobile: boolean): ShepherdOptionsWithType[] => {
  const steps: ShepherdOptionsWithType[] = [
    {
      text: `Add blocks for each link you’d like to include on your link page.`,
      attachTo: {
        element: '[data-tour-id="link-page-links-tab"]',
        on: 'auto'
      },
      canClickTarget: false,
      buttons: [
        {
          text: 'Awesome!',
          type: 'next'
        },
        {
          text: 'Skip tour',
          type: 'cancel'
        }
      ],
      beforeShowPromise: async () => {
        return waitForElementExists('[data-tour-id="link-page-links-tab"]')
      }
    },
    {
      text: 'Customize the style of your Link Page.',
      attachTo: {
        element: '[data-tour-id="link-page-style-tab"]',
        on: 'auto'
      },
      canClickTarget: false,
      buttons: [
        {
          text: 'Great!',
          type: 'next'
        },
        {
          text: 'Leave tour',
          type: 'cancel'
        }
      ]
    },
    {
      text: 'Customize Your Link Page with your name, profile photo, and a short bio.',
      attachTo: {
        element: '[data-tour-id="link-page-details-tab"]',
        on: 'auto'
      },
      canClickTarget: false,
      buttons: [
        {
          text: 'Ok, got it!',
          type: 'next'
        },
        {
          text: 'Leave tour',
          type: 'cancel'
        }
      ]
    },
    {
      text: 'Track clicks to your links here.',
      attachTo: {
        element: '[data-tour-id="link-page-analytics-tab"]',
        on: 'auto'
      },
      canClickTarget: false,
      buttons: [
        {
          text: 'This is helpful!',
          type: 'next'
        },
        {
          text: 'Leave tour',
          type: 'cancel'
        }
      ]
    },
    {
      text: 'Copy and paste this link on your bio page.',
      attachTo: {
        element: '[data-tour-id="link-page-username"]',
        on: 'auto'
      },
      canClickTarget: false,
      buttons: [
        {
          text: 'Got it!',
          type: 'next'
        }
      ]
    }
  ]
  return removeEmptySteps(steps as any)
}
