import { FC } from 'react'
import { BoltIcon } from '@heroicons/react/24/solid'
import communityImg from '../../../assets/community-img.png'
import { pushToDialogStack, useAppDispatch } from '../../../redux'
import { Button } from '../..'
import { StyledCommunityDialog } from '.'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const CommunityDialog: FC<Props> = ({ isOpen, onClose }) => {
  const dispatch = useAppDispatch()

  if (!isOpen) {
    return null
  }

  return (
    <>
      <StyledCommunityDialog>
        <div className="community-dialog-content">
          <div className="community-img">
            <img src={communityImg} alt="" />
          </div>
          <h1>You don't have access</h1>
          <h2>
            Upgrade to annual to unlock this space
            <br />
            and the rest of the community.
          </h2>
          <div className="actions">
            <Button
              iconPos="left"
              variant="emphasis"
              onClick={() => dispatch(pushToDialogStack('go-pro'))}
            >
              <BoltIcon /> Upgrade
            </Button>
            <Button variant="outline" onClick={() => onClose()}>
              Go back
            </Button>
          </div>
        </div>
        <div className="community-dialog-backdrop" />
      </StyledCommunityDialog>
    </>
  )
}

export default CommunityDialog
