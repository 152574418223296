export const formatTimestamp = (input: number) => {
  const minutes = Math.floor(input / 60)
    .toString()
    .padStart(2, '0')
  const seconds = Math.floor(input % 60)
    .toString()
    .padStart(2, '0')

  return `${minutes}:${seconds}`
}
