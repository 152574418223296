import styled from 'styled-components'

const StyledAssetsView = styled.div`
  margin: 40px 0;

  h1 {
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 24px;
    color: ${({ theme }) => theme.color.gray[900]};
  }

  p {
    margin-bottom: 40px;
    line-height: 24px;
    color: ${({ theme }) => theme.color.gray[500]};
  }

  .asset-list {
    display: grid;
    gap: 30px;

    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-decoration: none;
      color: ${({ theme }) => theme.color.gray[800]};

      .asset-info {
        display: flex;
        align-items: center;

        .avatar {
          margin-right: 12px;
        }

        span {
          font-weight: 500;
        }
      }

      button {
        height: 40px;
        padding: 0 12px;
        border-radius: 4px;
        font-weight: 500;
        background-color: ${({ theme }) => theme.color.gray[100]};
        color: ${({ theme }) => theme.color.gray[500]};
        transition: 0.2s;
        display: flex;
        align-items: center;

        svg {
          height: 16px;
          margin-left: 10px;
          stroke-width: 2.75px;
          opacity: 0.6;
        }
      }

      &:hover {
        button {
          background-color: ${({ theme }) => theme.color.blue[100]};
          color: ${({ theme }) => theme.color.blue[800]};
        }
      }
    }
  }
`

export default StyledAssetsView
