import { FC, useState } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline'
import { ActionDialog, Button, EditLinkBlockDialog } from '../../../../..'
import { updatePage, useAppDispatch, useAppSelector } from '../../../../../../redux'
import { PageType } from '../../../../../../types'
import { theme } from '../../../../../../utils'
import { StyledLinkPageLayoutBlock } from '.'

type Props = {
  link: Required<PageType>['links'][number]
}

const LinkPageLayoutBlock: FC<Props> = ({ link }) => {
  const dispatch = useAppDispatch()
  const { page } = useAppSelector((state) => state.page)
  const [showDeletionConfirmation, setShowDeletionConfirmation] = useState(false)
  const [showEditDialog, setShowEditDialog] = useState(false)
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: link._id || ''
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    zIndex: isDragging ? theme.elevation.override : 'inherit',
    transition
  }

  const handleDelete = async () => {
    dispatch(
      updatePage({
        links: page?.links?.filter((l) => l._id !== link._id) || []
      })
    )
  }

  return (
    <>
      <StyledLinkPageLayoutBlock
        open={false}
        ref={setNodeRef}
        style={style}
        isDragging={isDragging}
        {...attributes}
        {...listeners}
      >
        <summary>
          <div className="block-details">
            <div className="block-type">{link.type.toUpperCase()}</div>
            <span className="block-title">{link.metadata.label}</span>
          </div>
          <div className="block-actions">
            {link.type !== 'divider' && (
              <Button className="edit-btn" iconPos="left" onClick={() => setShowEditDialog(true)}>
                <PencilIcon />
                Edit
              </Button>
            )}
            <Button className="delete-btn" isIcon onClick={() => setShowDeletionConfirmation(true)}>
              <TrashIcon />
            </Button>
          </div>
        </summary>
      </StyledLinkPageLayoutBlock>
      <EditLinkBlockDialog
        block={link}
        isOpen={showEditDialog}
        onClose={() => setShowEditDialog(false)}
      />
      <ActionDialog
        isOpen={showDeletionConfirmation}
        type="error"
        title="Delete this block?"
        body={`Are you sure you want to delete "${link?.metadata?.label || `this ${link.type}`}"?`}
        confirmButtonText="Delete Post"
        onConfirm={() => handleDelete()}
        onClose={() => setShowDeletionConfirmation(false)}
      />
    </>
  )
}

export default LinkPageLayoutBlock
