import styled from 'styled-components'

const StyledReferralsView = styled.div`
  overflow: hidden;
  height: 100vh;
  box-sizing: border-box;
  position: relative;
  user-select: none;
  pointer-events: none;

  .lock-screen {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: center;
    padding: 80px;
    position: absolute;
    max-height: 100vh;
    bottom: 0;
    height: calc(100vh - 60px);
    left: 0;
    right: 0;
    background: linear-gradient(
      ${({ theme }) => theme.color.background}85,
      ${({ theme }) => theme.color.background} 99%
    );
    z-index: 999;
    box-sizing: border-box;

    .icon {
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.color.red[800]};
      color: white;
      margin: 0 auto 20px auto;

      svg {
        height: 36px;
      }
    }

    .title {
      display: block;
      font-size: 30px;
      font-weight: 600;
      font-family: ${({ theme }) => theme.typeface.faktumXCon};
      color: ${({ theme }) => theme.color.gray[800]};
    }

    .body {
      display: block;
      font-size: 16px;
      font-weight: 400;
      max-width: 480px;
      line-height: 24px;
      margin: 6px auto 0 auto;
      color: ${({ theme }) => theme.color.gray[600]};
    }
  }

  .page-layout {
    padding: 60px 0;
  }

  .heading {
    h1 {
      font-size: 40px;
      font-weight: 800;
      color: ${({ theme }) => theme.color.gray[800]};
    }

    p {
      margin-top: 10px;
      font-size: 18px;
      line-height: 28px;
      color: ${({ theme }) => theme.color.gray[600]};

      a {
        font-weight: 500;
        color: ${({ theme }) => theme.color.brand};
      }
    }
  }

  .section {
    margin-top: 40px;

    h2 {
      font-size: 22px;
      font-weight: 700;
      color: ${({ theme }) => theme.color.gray[800]};
    }

    p {
      margin-top: 6px;
      font-size: 18px;
      line-height: 28px;
      color: ${({ theme }) => theme.color.gray[600]};
    }

    .offers {
      margin-top: 30px;
      display: grid;
      row-gap: 26px;

      .offer {
        span {
          display: block;
          font-size: 15px;
          color: ${({ theme }) => theme.color.gray[600]};

          a {
            color: ${({ theme }) => theme.color.gray[600]};
            text-decoration: underline;

            &:hover {
              color: ${({ theme }) => theme.color.gray[900]};
            }
          }
        }

        b {
          font-size: 16px;
          font-weight: 600;
          display: block;
          margin-bottom: 4px;
          color: ${({ theme }) => theme.color.gray[800]};
        }

        .offer-input {
          display: flex;
          margin-top: 10px;

          .input {
            width: 100%;
          }

          .btn {
            padding: 0 20px;
            margin-left: 10px;
          }
        }
      }
    }

    &:not(:last-child) {
      padding-bottom: 40px;
      border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
    }
  }
`

export default StyledReferralsView
