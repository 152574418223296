import { useContext, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useHistory, useLocation } from 'react-router-dom'
import { SavePassword } from 'capacitor-ios-autofill-save-password'
import firebase from 'firebase/app'
import { useApolloClient } from '@apollo/client'
import { Capacitor } from '@capacitor/core'
import { Keyboard } from '@capacitor/keyboard'
import { loginAsUser } from '../../../graphql'
import { ROUTES, userStore } from '../..'
import { useForm, useSegment } from '..'

export const useLogin = () => {
  const location = useLocation()
  const history = useHistory()
  const apollo = useApolloClient()
  const { track } = useSegment()
  const { loaded, user, refresh } = useContext(userStore)
  const [params, setParams] = useState<{ [k: string]: string }>()

  const { form, resetForm, updateFormValue } = useForm({
    email: '',
    password: ''
  })

  const login = async () => {
    if (Capacitor.isPluginAvailable('Keyboard')) {
      Keyboard.hide()
    }

    if (!form.email || !form.password) {
      throw 'You must enter a password or email address'
    }

    await firebase.auth().signInWithEmailAndPassword(form.email, form.password)

    if (Capacitor.getPlatform() === 'ios') {
      await SavePassword.promptDialog({
        username: form.email,
        password: form.password
      })
    }

    track('Logged In')

    await refresh()
  }

  const academyAuth = async (redirect?: string) => {
    const { data } = await apollo.query({
      fetchPolicy: 'no-cache',
      query: loginAsUser
    })

    const token = data.loginAsUser

    const url =
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:3001'
        : 'https://academy.socialcurator.com'

    window.location.href = `${url}/connect?token=${token}&redirect=${redirect}`
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    const params = Object.fromEntries(urlParams)
    const { email } = params

    updateFormValue({
      email: email || ''
    })

    setParams({
      ...params,
      ...(location.state as any)
    })
  }, [location.search, location.state])

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    const params = {
      ...Object.fromEntries(urlParams),
      ...(location.state as any)
    }

    if (!loaded || !user) {
      return
    }

    if (params?.ref === 'academy') {
      academyAuth(params?.redirect)
      return
    }

    if (params?.redirect) {
      window.location.href = params?.redirect
      return
    }

    history.push(ROUTES.home)
  }, [location.search, location.state, user, loaded])

  return {
    form,
    params,
    updateFormValue,
    login
  }
}
