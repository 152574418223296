import styled from 'styled-components'

const StyledDesignerColorPanel = styled.div`
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  display: grid;
  grid-gap: 20px;

  .color-row {
    .color-label {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 15px;
      color: ${({ theme }) => theme.color.gray[800]};

      svg {
        height: 22px;
        margin-right: 8px;
        stroke-width: 2px;
      }
    }

    .color-list {
      display: grid;
      margin-top: 12px;
      gap: 10px;
      grid-template-columns: repeat(auto-fill, minmax(35px, 1fr));

      button {
        padding-top: 100%;
        border-radius: 5px;
        box-shadow: inset 0 0 0 1px rgb(0, 0, 0, 0.1);

        &[aria-selected='true'] {
          box-shadow: inset 0 0 0 3px ${({ theme }) => theme.color.brand},
            inset 0 0 0 5px ${({ theme }) => theme.color.background};
        }
      }
    }
  }
`

export default StyledDesignerColorPanel
