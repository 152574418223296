import { FC } from 'react'
import { StyledPageSizzle } from '.'

type Props = {
  bubble: string
  title: string
  body: string
  maxWidth?: number
}

const PageSizzle: FC<Props> = ({ bubble, title, body, maxWidth = 1000 }) => {
  return (
    <StyledPageSizzle maxWidth={maxWidth}>
      <div className="sizzle">
        <div className="sizzle-bubble">{bubble}</div>
        <h1>{title}</h1>
        <h2>{body}</h2>
      </div>
    </StyledPageSizzle>
  )
}

export default PageSizzle
