import styled from 'styled-components'

type StyledProps = {
  position:
    | 'top-center'
    | 'top-start'
    | 'top-end'
    | 'left-center'
    | 'right-center'
    | 'bottom-center'
    | 'bottom-start'
    | 'bottom-end'
  positioning?: { top: number; left: number }
}

const StyledHint = styled.div<StyledProps>`
  position: fixed;
  z-index: 99999999999999999999999;
  top: ${({ positioning }) => positioning?.top || 0}px;
  left: ${({ positioning }) => positioning?.left || 0}px;
  user-select: none;
  opacity: ${({ positioning }) => (!!positioning ? 1 : 0)};
  width: 260px;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.color.gray[800]};
  box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
    0px 2px 3px rgba(0, 0, 0, 0.05);

  .hint-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 15px;
    color: ${({ theme }) => theme.color.background};

    svg {
      height: 20px;
      color: ${({ theme }) => theme.color.gray[400]};
    }
  }

  .hint-content {
    margin: 4px 0 20px 0;
    color: ${({ theme }) => theme.color.gray[300]}c4;
    font-size: 14px;
    line-height: 20px;
  }

  .hint-actions {
    display: flex;

    button {
      height: 40px;
      padding: 0 16px;
      border-radius: 6px;
      font-size: 15px;
      font-weight: 500;
      transition: 0.2s;
      background-color: ${({ theme }) => theme.color.gray[700]};
      color: ${({ theme }) => theme.color.background};

      &:first-child {
        width: 100%;
      }

      &:hover {
        background-color: ${({ theme }) => theme.color.gray[600]};
      }

      &:last-child {
        margin-left: 10px;
        flex-shrink: 0;
        font-weight: 600;
        background-color: ${({ theme }) => theme.color.brand};

        &:hover {
          background-color: ${({ theme }) => theme.color.brandDark};
        }
      }
    }
  }
`

// &::after {
//   content: '';
//   display: block;
//   position: absolute;
//   background-color: ${({ theme }) => theme.color.gray[800]};
//   width: 12px;
//   height: 12px;
//   z-index: 9;
//   transform: rotate(45deg);

//   ${({ position }) =>
//     position === 'top-start' &&
//     `
//     left: 24px;
//     bottom: -6px;
//     border-radius: 0 0 2px 0;
//   `}

//   ${({ position }) =>
//     position === 'top-center' &&
//     `
//     left: 50%;
//     bottom: -6px;
//     border-radius: 0 0 2px 0;
//     transform: translateX(-50%), rotate(45deg);
//   `}

//   ${({ position }) =>
//     position === 'top-end' &&
//     `
//     right: 24px;
//     bottom: -6px;
//     border-radius: 0 0 2px 0;
//   `}

//   ${({ position }) =>
//     position === 'bottom-start' &&
//     `
//     left: 24px;
//     top: -6px;
//     border-radius: 2px 0 0 0;
//   `}

//   ${({ position }) =>
//     position === 'bottom-center' &&
//     `
//     left: 50%;
//     top: -6px;
//     border-radius: 2px 0 0 0;
//     transform: translateX(-50%), rotate(45deg);
//   `}

//   ${({ position }) =>
//     position === 'bottom-end' &&
//     `
//     right: 24px;
//     top: -6px;
//     border-radius: 2px 0 0 0;
//   `}
// }

export default StyledHint
