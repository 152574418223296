import { FC, useCallback, useEffect, useState } from 'react'
import { useActiveObject, useEditor } from '@layerhub-io/react'
import { ReactComponent as UnderlineIcon } from '../../../assets/underline-icon.svg'
import { DesignerToolButton } from '.'

const DesignerUnderlineTool: FC = () => {
  const editor = useEditor()
  const activeObject = useActiveObject() as any
  const [underline, setUnderline] = useState(false)

  const toggleUnderline = useCallback(() => {
    editor.objects.update({
      underline: !underline
    })
    setUnderline(!underline)
  }, [editor, underline])

  useEffect(() => {
    setUnderline(activeObject?.underline)
  }, [activeObject])

  return (
    <DesignerToolButton
      data-tooltip={underline ? 'Remove underline' : 'Underline'}
      isIcon
      isActive={underline}
      onClick={() => toggleUnderline()}
    >
      <UnderlineIcon />
    </DesignerToolButton>
  )
}

export default DesignerUnderlineTool
