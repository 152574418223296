import styled from 'styled-components'

const StyledUploadsFolderListHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px 16px 16px;
  user-select: none;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};

  .label {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.01rem;
    color: ${({ theme }) => theme.color.gray[800]};
    display: flex;
    align-items: center;
    position: relative;
    transition: 0.2s;
    background-color: transparent;

    svg {
      height: 14px;
      stroke-width: 3.5px;
      position: absolute;
      left: -2px;
      transform-origin: left;
      transform: scale(0);
      transition: 0.2s;
    }

    &[data-in-folder='true'] {
      padding-left: 16px;

      svg {
        transform: scale(1);
      }
    }
  }

  .sort-btn {
    color: ${({ theme }) => theme.color.gray[500]};
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    background-color: transparent;

    svg {
      height: 14px;
      position: relative;
      top: 1px;
      stroke-width: 2.75px;
      margin-left: 6px;
    }
  }
`

export default StyledUploadsFolderListHeader
