import styled from 'styled-components'

const StyledContentView = styled.div`
  overflow: hidden;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  height: 100%;

  .tabs {
    margin-top: -14px;
  }

  &[data-has-header='true'] {
    grid-template-rows: auto auto minmax(0, 1fr);
  }
`

export default StyledContentView
