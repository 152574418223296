import { FC, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { throttle } from 'lodash'
import { getPosition } from '../../utils'
import { StyledTooltipContext } from '.'

const TooltipContext: FC = () => {
  const [content, setContent] = useState('')
  const [positioning, setPositioning] = useState<{ top: number; left: number }>()
  const OFFSET = 8

  const handlePosition = async (
    target: HTMLElement,
    value: string,
    position:
      | 'top-center'
      | 'top-start'
      | 'top-end'
      | 'left-center'
      | 'right-center'
      | 'bottom-center'
      | 'bottom-start'
      | 'bottom-end'
  ) => {
    const tooltip = document.getElementById('tooltip-base')

    if (!tooltip) {
      setPositioning(undefined)
      return
    }

    setPositioning(getPosition(position, target, tooltip))

    // // TOOLTIP STATS
    // const tooltipRect = tooltip.getBoundingClientRect()
    // const tooltipHeight = tooltipRect.height
    // const tooltipWidth = tooltipRect.width

    // // TARGET STATS
    // const targetRect = target.getBoundingClientRect()
    // const targetHeight = targetRect.height
    // const targetWidth = targetRect.width
    // const targetTop = targetRect.top
    // const targetLeft = targetRect.left

    // if (position === 'top-center') {
    //   const top = targetTop - tooltipHeight - OFFSET
    //   const left = targetLeft + targetWidth / 2 - tooltipWidth / 2
    //   const pos = { top, left }

    //   setPositioning(pos)
    //   return
    // }

    // if (position === 'top-start') {
    //   const top = targetTop - tooltipHeight - OFFSET
    //   const left = targetLeft
    //   const pos = { top, left }

    //   setPositioning(pos)
    //   return
    // }

    // if (position === 'top-end') {
    //   const top = targetTop - tooltipHeight - OFFSET
    //   const left = targetLeft + targetWidth - tooltipWidth
    //   const pos = { top, left }

    //   setPositioning(pos)
    //   return
    // }

    // if (position === 'bottom-center') {
    //   const top = targetTop + targetHeight + OFFSET
    //   const left = targetLeft + targetWidth / 2 - tooltipWidth / 2
    //   const pos = { top, left }

    //   setPositioning(pos)
    //   return
    // }

    // if (position === 'bottom-start') {
    //   const top = targetTop + targetHeight + OFFSET
    //   const left = targetLeft
    //   const pos = { top, left }

    //   setPositioning(pos)
    //   return
    // }

    // if (position === 'bottom-end') {
    //   const top = targetTop + targetHeight + OFFSET
    //   const left = targetLeft + targetWidth - tooltipWidth
    //   const pos = { top, left }

    //   setPositioning(pos)
    //   return
    // }

    // if (position === 'right-center') {
    //   const top = targetTop + targetHeight / 2 - tooltipHeight / 2
    //   const left = targetLeft + targetWidth + OFFSET
    //   const pos = { top, left }

    //   setPositioning(pos)
    //   return
    // }

    // if (position === 'left-center') {
    //   const top = targetTop + targetHeight / 2 - tooltipHeight / 2
    //   const left = targetLeft - tooltipWidth - OFFSET
    //   const pos = { top, left }

    //   setPositioning(pos)
    //   return
    // }
  }

  const handleMouseMove = throttle((e: MouseEvent) => {
    const target = e.target as HTMLElement
    const closest = target?.closest('[data-tooltip]') as HTMLElement

    if (!target || !closest) {
      setContent('')
      return
    }

    const value = closest.getAttribute('data-tooltip') || ''
    const position = closest.getAttribute('data-tooltip-pos') || 'bottom-center'

    if (!value.trim()) {
      setContent('')
      return
    }

    setContent(value)
    handlePosition(closest, value, position as any)
  }, 10)

  const clearTooltip = () => {
    setContent('')
    setPositioning(undefined)
  }

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('click', clearTooltip)

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('click', clearTooltip)
    }
  }, [])

  if (!content) {
    return null
  }

  const Element = (
    <StyledTooltipContext
      id="tooltip-base"
      positioning={positioning}
      dangerouslySetInnerHTML={{ __html: content }}
    ></StyledTooltipContext>
  )

  return createPortal(Element, document.getElementById('tooltip-root') as HTMLElement)
}

export default TooltipContext
