import { FC } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { times } from 'lodash'
import { SortableContext } from '@dnd-kit/sortable'
import { setCloudPage, useAppDispatch, useAppSelector } from '../../../redux'
import { conditionalStage, isMobile } from '../../../utils'
import { CloudUploadItem_Skeleton, EmptyState } from '../..'
import { CloudUploadItem } from '..'
import { StyledCloudUploads } from '.'

const CloudUploads: FC = () => {
  const dispatch = useAppDispatch()
  const { files, totalDocs, page, loading } = useAppSelector((state) => state.cloud)

  return (
    <StyledCloudUploads>
      <SortableContext items={files.map((f) => f._id)}>
        <InfiniteScroll
          scrollThreshold={0.7}
          dataLength={files.length}
          next={() => dispatch(setCloudPage(page + 1))}
          hasMore={files.length < totalDocs}
          loader={null}
          {...conditionalStage(isMobile(), { scrollableTarget: 'app-content' })}
        >
          <div className="cloud-upload-list">
            {!files.length && !loading && (
              <EmptyState title="Make this your own" body="Upload a file to see it here." />
            )}
            {loading &&
              times(10).map((index) => {
                return (
                  <>
                    <CloudUploadItem_Skeleton key={index} />
                    {index !== 9 && <hr key={`${index}-divider`} />}
                  </>
                )
              })}
            {files.map((file, index) => {
              return (
                <>
                  <CloudUploadItem content={file} key={file._id} />
                  {index !== files.length - 1 && <hr key={`${file._id}-divider`} />}
                </>
              )
            })}
          </div>
        </InfiniteScroll>
      </SortableContext>
    </StyledCloudUploads>
  )
}

export default CloudUploads
