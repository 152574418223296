import { RefObject } from 'react'
import anime from 'animejs/lib/anime.es.js'
import { theme } from '..'
import { waitForElementExists } from '.'

export const animateAddItemToPost = async (ref: RefObject<HTMLElement>) => {
  const item = ref.current

  const isDesktop = window.innerWidth >= theme.breakpoint.s
  const postButtonID = 'sidebar-post-btn'

  await waitForElementExists(`#${postButtonID}`, 50)
  const button = document.getElementById(postButtonID)

  if (!item || !button) {
    return
  }

  const buttonWidth = button.getBoundingClientRect().width
  const buttonHeight = button.getBoundingClientRect().height
  const buttonTop = button.getBoundingClientRect().top
  const buttonLeft = button.getBoundingClientRect().left

  const itemWidth = item.getBoundingClientRect().width
  const itemHeight = item.getBoundingClientRect().height
  const itemTop = item.getBoundingClientRect().top
  const itemLeft = item.getBoundingClientRect().left

  const clone = item.cloneNode(true) as HTMLDivElement

  clone.style.position = 'fixed'
  clone.style.pointerEvents = 'none'
  clone.style.zIndex = '999999'
  clone.style.width = `${itemWidth}px`
  clone.style.height = `${itemHeight}px`
  clone.style.left = `${itemLeft}px`
  clone.style.top = `${itemTop}px`
  clone.style.transformBox = 'fill-box'
  clone.style.transformOrigin = 'left top'

  document.getElementById('root')?.append(clone)

  const scalePercentage = 60 / itemWidth

  const duration = isDesktop ? (buttonLeft + buttonWidth / 2 - 30) * 6 : 500
  const leftAmount = buttonLeft + buttonWidth / 2 - 30
  const topAmount = buttonTop

  anime({
    targets: clone,
    left: [itemLeft, leftAmount],
    top: [itemTop, Math.max(itemTop - 100, 20), topAmount],
    scale: scalePercentage,
    easing: 'easeInOutSine',
    duration: duration
  })

  setTimeout(() => {
    clone.remove()
  }, duration)
}
