import { useContext, useState } from 'react'
import { toast } from 'react-hot-toast'
import firebase from 'firebase/app'
import heic2any from 'heic2any'
import { v4 as uuidv4 } from 'uuid'
import { useApolloClient } from '@apollo/client'
import { FFmpeg } from '@ffmpeg/ffmpeg'
import { fetchFile, toBlobURL } from '@ffmpeg/util'
import { uploadFilesToCloud } from '../../../graphql'
import {
  addCloudFiles,
  addCreatePostMedia,
  updateFileStatus,
  useAppDispatch,
  useAppSelector
} from '../../../redux'
import { CloudContentType, PostType, UploadFileType } from '../../../types'
import { userStore } from '../../UserContext'
import { conditionalStage, useSegment } from '..'
import { getDimensions, getFileExt, isImage, isVideo } from '.'

const IMG_SIZE_LIMIT = 1350

export const useUploadMedia = () => {
  const apollo = useApolloClient()
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)
  const { user } = useContext(userStore)
  const { storage } = useAppSelector((state) => state.cloud)
  const { post } = useAppSelector((state) => state.create)
  const { track } = useSegment()
  const STORAGE_LIMIT = storage.limit * (user?.subscriptionInterval === 'Year' ? 2 : 1)

  const processVideo = async (file: UploadFileType) => {
    const ext = getFileExt(file.raw.name).toLowerCase()

    if (ext.includes('mp4')) {
      return file.raw
    }

    dispatch(updateFileStatus({ _id: file._id, status: 'processing' }))

    const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd'

    const ffmpeg = new FFmpeg()

    await ffmpeg.load({
      coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
      wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm')
    })

    await ffmpeg.writeFile('input.mov', await fetchFile(file.raw))
    await ffmpeg.exec(['-i', 'input.mov', '-preset', 'ultrafast', 'output.mp4'])

    const fileData = await ffmpeg.readFile('output.mp4')
    const data = new Uint8Array(fileData as ArrayBuffer)

    dispatch(updateFileStatus({ _id: file._id, status: 'loading' }))

    return new Blob([data.buffer], { type: 'video/mp4' })
  }

  const processImage = async (file: UploadFileType) => {
    const ext = getFileExt(file.raw.name).toLowerCase()

    if (ext.includes('heic')) {
      return (await heic2any({ blob: file.raw, toType: 'image/png' })) as Blob
    }

    return (await new Promise(async (resolve, reject) => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      const img = document.createElement('img')

      if (!ctx) {
        return reject()
      }

      img.src = window.URL.createObjectURL(file.raw)

      img.onload = (e) => {
        const image = e.target as HTMLImageElement

        const width = image.width
        const height = image.height

        const frameWidth = IMG_SIZE_LIMIT
        const frameHeight = IMG_SIZE_LIMIT

        let scale = 1

        if (frameWidth < width || frameHeight < height) {
          scale = Math.min(frameWidth / width, frameHeight / height)
        }

        const scaledWidth = img.width * scale
        const scaledHeight = img.height * scale

        canvas.width = scaledWidth
        canvas.height = scaledHeight

        ctx.drawImage(image, 0, 0, scaledWidth, scaledHeight)

        canvas.toBlob((blob) => {
          if (!blob) {
            return reject()
          }
          resolve(blob)
        }, 'image/png')
      }
    })) as Blob | undefined
  }

  const uploadMedia = async (file: UploadFileType) => {
    try {
      setLoading(true)

      const type = isVideo(file.raw.name) ? 'video' : 'image'
      const ext = type === 'video' ? '.mp4' : '.png'
      const storageRef = firebase.storage().ref()
      const fileRef = storageRef.child(`user-images/${uuidv4()}${ext}`)

      if (storage.current + file.raw.size > STORAGE_LIMIT) {
        dispatch(updateFileStatus({ _id: file._id, status: 'error' }))
        throw new Error(`You've reached your upload limit.`)
      }

      if (!isImage(file.raw.name) && !isVideo(file.raw.name)) {
        dispatch(updateFileStatus({ _id: file._id, status: 'error' }))
        toast.error(`${getFileExt(file.raw.name)} is not a supported file type.`)
        return
      }

      const blob = type === 'image' ? await processImage(file) : await processVideo(file)

      const media: CloudContentType = await new Promise((resolve, reject) => {
        const uploadTask = fileRef.put(blob || file.raw)

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            setProgress(progress)
          },
          (error) => {
            console.error(error)
            toast.error(error.message)
            dispatch(updateFileStatus({ _id: file._id, status: 'error' }))
            reject()
          },
          async () => {
            const downloadUrl = await uploadTask.snapshot.ref.getDownloadURL()
            const dimensions = await getDimensions(blob || file.raw, type)

            const mediaItem = {
              name: file.raw.name.substring(0, file.raw.name.lastIndexOf('.')),
              type: 'file',
              file: {
                url: downloadUrl,
                preview: dimensions?.preview,
                path: uploadTask.snapshot.ref.fullPath,
                type,
                ext: file.raw.type,
                size: file.raw.size,
                ...conditionalStage(!!dimensions, {
                  dimensions: { x: dimensions?.x, y: dimensions?.y }
                })
              }
            }

            track('Uploaded File to Cloud', { ...mediaItem })

            const { data } = await apollo.mutate({
              fetchPolicy: 'no-cache',
              mutation: uploadFilesToCloud,
              variables: {
                files: [mediaItem],
                parent: file.parent
              }
            })

            dispatch(updateFileStatus({ _id: file._id, status: 'finished' }))
            dispatch(addCloudFiles(data.uploadFilesToCloud))

            if (file.addToPost) {
              const content = data.uploadFilesToCloud[0] as CloudContentType
              const formattedData = {
                _id: uuidv4(),
                url: content.file?.url || '',
                type,
                uploaded: true,
                ref: content._id,
                dimensions: {
                  x: content.file?.dimensions.x || 0,
                  y: content.file?.dimensions.y || 0
                }
              } as NonNullable<PostType['media']>[number]

              dispatch(addCreatePostMedia(formattedData))
              track('Uploaded Media to Post', { post_id: post?._id, ...formattedData })
            }

            resolve(data.uploadFilesToCloud[0])
          }
        )
      })

      return media
    } catch (err) {
      console.error(err)
      dispatch(updateFileStatus({ _id: file._id, status: 'error' }))
      toast.error(`An unknown error occured`)
    } finally {
      setLoading(false)
    }
  }

  return {
    progress,
    loading,
    uploadMedia
  }
}
