import styled, { keyframes } from 'styled-components'
import { theme } from '../../../utils/theme'

const pulseAnim = keyframes`
  0% {
    box-shadow: 0 0 0 0 ${theme.color.brand};
  }
  100% {
    box-shadow: 0 0 0 10px ${theme.color.brand}00;
  }
`

type StyledProps = {
  position: {
    top?: number
    right?: number
    bottom?: number
    left?: number
  }
}

const StyledContextBubble = styled.button<StyledProps>`
  position: absolute;
  height: 26px;
  width: 26px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.brand};
  color: ${({ theme }) => theme.color.background};
  transition: background-color 0.2s;
  animation: infinite ease-in-out 2s ${pulseAnim};
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ position }) => position.top && `top: ${position.top}px;`}
  ${({ position }) => position.right && `right: ${position.top}px;`}
  ${({ position }) => position.bottom && `bottom: ${position.top}px;`}
  ${({ position }) => position.left && `left: ${position.top}px;`}

  svg {
    all: unset;
    height: 22px;
    fill: #fff;
  }

  &:hover {
    cursor: help;
    background-color: ${({ theme }) => theme.color.brandDark};
  }
`

export default StyledContextBubble
