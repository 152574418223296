import styled from 'styled-components'

const StyledGoProBanner = styled.div`
  user-select: none;
  border-radius: 6px;
  background: linear-gradient(0deg, #0013a4, #1c2cd9);

  .go-pro-content {
    display: flex;
    padding: 20px;
    align-items: center;
    justify-content: space-between;

    .go-pro-details {
      span {
        display: block;
      }

      .go-pro-title {
        font-weight: 500;
        font-size: 22px;
        color: ${({ theme }) => theme.color.background};
      }

      .go-pro-description {
        margin-top: 4px;
        color: ${({ theme }) => theme.color.background}a4;
      }
    }
  }
`

export default StyledGoProBanner
