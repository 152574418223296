import { decompress } from 'compress-json'
import { saveAs } from 'file-saver'
import { v4 as uuidv4 } from 'uuid'
import { useEditor, useFrame } from '@layerhub-io/react'
import { IStaticImage } from '@layerhub-io/types'
import {
  setDesignerImageToCrop,
  setDesignerMediaId,
  setDesignerPhoto,
  setDesignerTemplate,
  setDesignerTitle,
  setShowDesigner,
  useAppDispatch,
  useAppSelector
} from '../../redux'
import { PhotoType } from '../../types'
import { loadFonts, loadTemplateFonts } from '../../utils'
import { fontList } from '..'
import { useUploadManager } from '.'

type DesignerElementType = {
  id: string
  preview: string
  data: {
    angle?: number
    stroke?: string
    strokeWidth?: number
    opacity?: number
    flipX?: boolean
    flipY?: boolean
    skewX?: number
    skewY?: number
    src?: string
    cropX?: number
    cropY?: number
    left: number
    top: number
    width: number
    height: number
    originX: 'left' | 'top' | 'bottom' | 'right'
    originY: 'left' | 'top' | 'bottom' | 'right'
    scaleX: number
    scaleY: number
    type: string
    path?: any[]
    fill?: string
    metadata: Record<string, string | number | boolean>
  }
}

type TextOptions = {
  text: string
  width?: number
  fontSize?: number
  font: string
  fontStyle?: string
  textAlign?: string
  fill?: string
}

export const useDesigner = () => {
  const editor = useEditor()
  const frame = useFrame()
  const dispatch = useAppDispatch()
  const { uploadDesign } = useUploadManager()
  const { mediaId, photo, addToPost, title } = useAppSelector((state) => state.designer)

  const newDesign = async () => {
    dispatch(setDesignerTitle(''))
    dispatch(setDesignerTemplate(undefined))
    dispatch(setDesignerPhoto(undefined))
    dispatch(setDesignerMediaId(undefined))
    dispatch(setDesignerImageToCrop(undefined))
    dispatch(setShowDesigner(true))
  }

  const saveDesign = async () => {
    const currentScene = editor.scene.exportToJSON()
    const image = (await editor.renderer.render(currentScene)) as string

    await uploadDesign(
      image,
      { ...currentScene, title: title || 'Untitled Design' },
      title || 'Untitled Design',
      mediaId,
      addToPost
    )
    dispatch(setShowDesigner(false))
  }

  const exportJSON = async () => {
    const currentScene = editor.scene.exportToJSON()
    const image = (await editor.renderer.render(currentScene)) as string

    console.log(currentScene)
    saveAs(image, `${uuidv4()}.jpeg`)

    // const zip = new jsZip()
    // zip.file('template.json', JSON.stringify(currentScene))
    // zip.file('preview.jpeg', image, { binary: true })

    // const zipFile = await zip.generateAsync({ type: 'blob' })
    // saveAs(zipFile, `${uuidv4()}.zip`)
  }

  const loadDesignFromChat = async (template: string) => {
    const templateJSON = JSON.parse(template)

    await loadTemplateFonts(templateJSON)
    editor.scene.importFromJSON(templateJSON)

    dispatch(setDesignerTemplate(undefined))
    dispatch(setDesignerTitle(templateJSON?.title))
  }

  const loadDesign = async (template: string) => {
    const templateJSON = decompress(JSON.parse(template))

    await loadTemplateFonts(templateJSON)
    editor.scene.importFromJSON(templateJSON)

    dispatch(setDesignerTemplate(undefined))
    dispatch(setDesignerTitle(templateJSON?.title))
  }

  const loadPhoto = async () => {
    if (!photo) {
      return
    }

    const SIZE_LIMIT = 1200

    await new Promise(async (resolve, reject) => {
      const img = new Image()
      img.src = photo

      img.onload = async () => {
        const imgWidth = img?.width || 1
        const imgHeight = img?.height || 1

        const frameWidth = SIZE_LIMIT
        const frameHeight = SIZE_LIMIT

        let scale = 1

        if (frameWidth < imgWidth || frameHeight < imgHeight) {
          scale = Math.min(frameWidth / imgWidth, frameHeight / imgHeight)
        }

        const scaledWidth = img.width * scale
        const scaledHeight = img.height * scale

        console.log(scaledWidth, scaledHeight)

        editor.frame.resize({ width: scaledWidth, height: scaledHeight })

        const object = {
          id: uuidv4(),
          src: photo,
          preview: photo,
          type: 'StaticImage',
          scaleX: scale,
          scaleY: scale
        }

        editor.objects.add(object)

        resolve(true)
      }

      img.onerror = reject
    })

    dispatch(setDesignerPhoto(undefined))
  }

  const addText = async (text: TextOptions) => {
    const fontURL = fontList.find((font) => font.post_script_name === text.font)?.url

    if (!fontURL) {
      return
    }

    await loadFonts([
      {
        name: text.font,
        url: fontURL
      }
    ])

    const options = {
      ...text,
      id: uuidv4(),
      type: 'StaticText',
      width: text.width,
      text: text.text,
      fontSize: text.fontSize || 28,
      fontFamily: text.font,
      textAlign: text.textAlign || 'center',
      fontStyle: text.fontSize || 'normal',
      fontURL,
      fill: text.fill || '#000000',
      metadata: {}
    }

    editor.objects.add(options)
  }

  const addImage = (url: string, sizeData?: PhotoType['file']['imgSize']) => {
    const imgWidth = sizeData?.width || 1
    const imgHeight = sizeData?.height || 1

    const frameWidth = frame?.width || 1200
    const frameHeight = frame?.height || 1200

    let scale = 1

    if (frameWidth < imgWidth || frameHeight < imgHeight) {
      scale = Math.min(frameWidth / imgWidth, frameHeight / imgHeight)
    }

    const options = {
      id: uuidv4(),
      type: 'StaticImage',
      src: url,
      preview: url,
      scaleX: scale,
      scaleY: scale
    } as IStaticImage

    editor.objects.add(options)
  }

  const addObject = (data: DesignerElementType['data'] | any) => {
    const options = {
      id: uuidv4(),
      ...data
    }

    editor.objects.add(options)
  }

  return {
    saveDesign,
    exportJSON,
    addText,
    addImage,
    addObject,
    loadPhoto,
    loadDesign,
    newDesign,
    loadDesignFromChat
  }
}
