import { FC } from 'react'
import { updateCreatePost, useAppDispatch, useAppSelector } from '../../../../redux'
import { CreateEditorCoverPhotoPicker, CreateEditorSubConfig } from '../..'

export const CoverPhotoConfig: FC = () => {
  const dispatch = useAppDispatch()
  const { post } = useAppSelector((state) => state.create)

  const handleChange = (value: any) => {
    dispatch(
      updateCreatePost({
        instagramPublishType: value
      })
    )
  }

  if (!post?.media?.filter((m) => m.type === 'video').length || post?.media?.length !== 1) {
    return null
  }

  return (
    <CreateEditorSubConfig
      label="Video Cover Photo"
      description="Pick a timestamp to use as your video's cover, or upload your own."
      type="custom"
      platforms={['instagram']}
    >
      <CreateEditorCoverPhotoPicker />
    </CreateEditorSubConfig>
  )
}
