import styled from 'styled-components'

const StyledCommunityView = styled.div`
  height: 100%;

  iframe {
    height: 100%;
    width: 100%;
    border: 0;
  }
`

export default StyledCommunityView
