import styled, { keyframes } from 'styled-components'

export const pulseAnimation = (lightGray: string, gray: string) => keyframes`
  0% {
    background-color: ${lightGray};
  }
  50% {
    background-color: ${gray};
  }
  100% {
    background-color: ${lightGray};
  }
`

const StyledHashtagFolderList = styled.div`
  overflow-y: auto;
  padding: 1px 14px 14px 14px;
  box-sizing: border-box;
  height: 100%;

  .skeleton {
    height: 60px;
    width: 100%;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.color.gray[200]};
    animation: ${({ theme }) => pulseAnimation(theme.color.gray[200], theme.color.gray[300])} 2s
      cubic-bezier(0.4, 0, 0.6, 1) infinite;

    &:not(:last-child) {
      margin-bottom: 14px;
    }
  }

  .empty-state {
    border-radius: 12px 12px 34px 34px;
    height: 100%;
  }
`

export default StyledHashtagFolderList
