import styled from 'styled-components'

const StyledHomeStats = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  gap: 10px;

  .stat {
    padding: 12px 16px;
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    user-select: none;
    backdrop-filter: blur(10px) saturate(1.5);

    .label {
      font-weight: 600;
      font-size: 15px;
      display: flex;
      align-items: center;
      width: fit-content;
      cursor: help;
      color: rgba(255, 255, 255, 1);

      svg {
        height: 18px;
        margin-left: 3px;
        color: rgba(255, 255, 255, 0.4);
      }
    }

    &.level {
      .level-indicator {
        height: 36px;
        width: 36px;
        color: rgba(255, 255, 255, 1);
        flex-shrink: 0;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;

        svg {
          height: 16px;
        }
      }

      .level-status-container {
        width: 100%;

        .level-status {
          font-size: 13px;
          font-weight: 500;
          margin-top: 2px;
          display: flex;
          align-items: center;

          .level-post-count {
            flex-shrink: 0;
            color: rgba(255, 255, 255, 1);
          }

          progress {
            width: 100%;
            overflow: hidden;
            border-radius: 50rem;
            height: 4px;
            margin: 0 10px;

            &::-webkit-progress-bar {
              background: rgba(255, 255, 255, 0.25);
              border-radius: 50rem;
            }

            &::-webkit-progress-value {
              background: rgba(255, 255, 255, 1);
              transition: 0.2s;
              border-radius: 50rem;
            }
          }

          .level-post-limit {
            flex-shrink: 0;
            color: rgba(255, 255, 255, 0.7);

            svg {
              height: 20px;
            }
          }
        }
      }
    }

    &.streak {
      flex-shrink: 0;
      justify-content: space-between;

      .streak-label-container {
        span {
          display: block;
        }

        .streak-count {
          display: flex;
          align-items: center;
          font-size: 13px;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.7);

          svg {
            height: 18px;
            margin-left: 3px;
            color: rgba(255, 255, 255, 0.4);
          }
        }
      }

      .streak-status {
        display: flex;
        margin-left: 40px;

        .streak-day {
          text-align: center;

          .streak-day-bubble {
            width: 25px;
            height: 25px;
            margin-bottom: 4px;
            flex-shrink: 0;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.4);

            svg {
              height: 18px;
              stroke-width: 2.3px;
            }
          }

          .streak-day-label {
            font-size: 13px;
            color: rgba(255, 255, 255, 0.5);
          }

          &:not(:last-child) {
            margin-right: 6px;
          }

          &[data-past='true'][data-completed='false'] {
            .streak-day-bubble {
              color: white;
              box-shadow: inset 0 0 0 25px rgba(255, 255, 255, 0.5);
            }
          }

          &[data-today='true'] {
            .streak-day-bubble {
              box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.8);
            }

            .streak-day-label {
              font-weight: 500;
              color: rgba(255, 255, 255, 0.9);
            }
          }

          &[data-completed='true'] {
            .streak-day-bubble {
              color: rgba(255, 255, 255, 1);
              box-shadow: inset 0 0 0 4px rgba(255, 255, 255, 1);

              svg {
                width: 25px;
                height: 25px;
              }
            }
          }
        }
      }
    }
  }
`

export default StyledHomeStats
