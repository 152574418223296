import styled from 'styled-components'

const StyledConnectionPlatformList = styled.ul`
  padding: 20px;
  align-content: start;
  list-style-type: none;
  display: grid;
  gap: 12px;
  user-select: none;
`

export default StyledConnectionPlatformList
