import { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { toast } from 'react-hot-toast'
import { uniqBy } from 'lodash'
import { DateTime } from 'luxon'
import { useApolloClient } from '@apollo/client'
import { DndContext, DragEndEvent, pointerWithin } from '@dnd-kit/core'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { ReactComponent as CollapseIcon } from '../../assets/collapse-icon.svg'
import { Button, Calendar, CalendarHeader, CalendarPostList } from '../../components'
import { getMonthsDrafts, upsertUserDraftMutation } from '../../graphql'
import {
  setCalendarEvents,
  setShowCalendarPostList,
  updateCalendarEvent,
  updateCreatePost,
  updatePost,
  useAppDispatch,
  useAppSelector
} from '../../redux'
import { CalendarEventType, PostType } from '../../types'
import { isMobile, parseDate, useCalendar, useSensors } from '../../utils'
import { StyledCalendarView } from '.'

const CalendarView: FC = () => {
  const apollo = useApolloClient()
  const dispatch = useAppDispatch()
  const sensors = useSensors()
  const { post: editorPost } = useAppSelector((state) => state.create)
  const { posts } = useAppSelector((state) => state.posts)
  const { events, start, view, monthString, yearString, showPostList } = useAppSelector(
    (state) => state.calendar
  )
  const [isFirstLoad, setIsFirstLoad] = useState(true)

  const loadMonth = async () => {
    const startDate = DateTime.fromISO(start)

    const { data } = await apollo.query({
      query: getMonthsDrafts,
      fetchPolicy: 'no-cache',
      variables: {
        date: startDate.toISO()
      }
    })

    const postsWithDate = posts.filter((post) => !!post.postDate)
    const formattedPosts = postsWithDate.map((post) => ({
      _id: post._id,
      type: 'post',
      metadata: {
        day: DateTime.fromISO(post.postDate),
        data: post
      }
    })) as CalendarEventType[]

    if (!data?.getMonthsDrafts?.posts) {
      return dispatch(setCalendarEvents(uniqBy([...formattedPosts, ...events], '_id')))
    }

    const formattedResult = data.getMonthsDrafts.posts.map((post: PostType) => ({
      _id: post._id,
      type: 'post',
      metadata: {
        day: DateTime.fromISO(post.postDate),
        data: post
      }
    }))

    if (isFirstLoad) {
      dispatch(setCalendarEvents(uniqBy([...formattedPosts, ...formattedResult], '_id')))
      setIsFirstLoad(false)
      return
    }

    dispatch(setCalendarEvents(uniqBy([...formattedPosts, ...formattedResult, ...events], '_id')))
  }

  const handleDragEnd = async (event: DragEndEvent) => {
    try {
      // setLoading(true)

      const { active, over } = event
      const post = active.data.current?.post as PostType
      const parent = active.data.current?.parent as string
      const date = over?.data.current?.date as string

      if (!date || !post) {
        return
      }

      if (parent === over?.id) {
        return
      }

      let postDate = ''

      if (!!post.postDate && view === 'month') {
        const oldDate = DateTime.fromISO(post.postDate)
        const newDate = DateTime.fromISO(date).set({ hour: oldDate.hour, minute: oldDate.minute })
        postDate = newDate.toISO()
      }

      if (!post.postDate && view === 'month') {
        const newDate = DateTime.fromISO(date).set({ hour: 9, minute: 0 })
        postDate = newDate.toISO()
      }

      if (view === 'week') {
        postDate = date
      }

      const newPost = {
        ...post,
        postDate: postDate
      }

      dispatch(updatePost(newPost))
      dispatch(
        updateCalendarEvent({
          _id: newPost._id,
          type: 'post',
          metadata: {
            day: DateTime.fromISO(newPost.postDate),
            data: newPost
          }
        })
      )

      if (editorPost?._id === post._id) {
        dispatch(
          updateCreatePost({
            postDate: newPost.postDate
          })
        )
      }

      await apollo.mutate({
        mutation: upsertUserDraftMutation,
        variables: { record: newPost }
      })
    } catch (err) {
      console.error(err)
      toast.error((err as any).message)
    } finally {
      // setLoading(false)
    }
  }

  useEffect(() => {
    loadMonth()
  }, [start, posts])

  return (
    <>
      <Helmet title="Calendar | Social Curator" />
      <DndContext
        collisionDetection={pointerWithin}
        sensors={sensors}
        onDragEnd={(event) => handleDragEnd(event)}
      >
        <StyledCalendarView>
          <CalendarPostList />
          <div className="calendar-container">
            <CalendarHeader />
            {/* <div className="calendar-header">
              <div className="calendar-header-group">
                {!isMobile() && (
                  <Button
                    size="sm"
                    isIcon
                    data-tooltip={showPostList ? 'Hide draft list' : 'Show draft list'}
                    data-tooltip-pos="bottom-start"
                    className="collapse-btn"
                    onClick={() => dispatch(setShowCalendarPostList(!showPostList))}
                  >
                    <CollapseIcon style={{ transform: showPostList ? `rotate(180deg)` : '' }} />
                  </Button>
                )}
                <div className="calendar-header-date">
                  {monthString} {yearString}
                </div>
                <span
                  className="calendar-post-count"
                  data-tooltip={`${
                    events.filter((event) => {
                      const day = parseDate(event.metadata.day)
                      return day.hasSame(DateTime.fromISO(start), 'month')
                    }).length
                  } posts this month`}
                >
                  {
                    events.filter((event) => {
                      const day = parseDate(event.metadata.day)
                      return day.hasSame(DateTime.fromISO(start), 'month')
                    }).length
                  }{' '}
                  Post
                  {events.filter((event) => {
                    const day = parseDate(event.metadata.day)
                    return day.hasSame(DateTime.fromISO(start), 'month')
                  }).length !== 1 && 's'}
                </span>
              </div>
              <div className="calendar-header-actions">
                <Button
                  data-tooltip="Change calendar view"
                  size="sm"
                  variant="outline"
                  onClick={() => changeView(view === 'month' ? 'week' : 'month')}
                >
                  {view === 'month' ? 'Month' : 'Week'}
                </Button>
                <Button
                  data-tooltip="Last week"
                  size="sm"
                  variant="outline"
                  isIcon
                  onClick={() => handleShift('prev')}
                >
                  <ChevronLeftIcon />
                </Button>
                <Button data-tooltip="Go to today" size="sm" onClick={() => handleShift('today')}>
                  Today
                </Button>
                <Button
                  data-tooltip="Next week"
                  data-tooltip-pos="bottom-end"
                  size="sm"
                  variant="outline"
                  isIcon
                  onClick={() => handleShift('next')}
                >
                  <ChevronRightIcon />
                </Button>
              </div>
            </div> */}
            <Calendar />
          </div>
        </StyledCalendarView>
      </DndContext>
    </>
  )
}

export default CalendarView
