import { FC, createRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CameraIcon } from '../../../../assets'
import { setContentTab, setShowCreate, useAppDispatch, useAppSelector } from '../../../../redux'
import { useUploadManager } from '../../../../utils'
import { MOBILE_ROUTES } from '../../../utils'
import { PullDown } from '../..'
import { StyledCreateAddMediaButton } from '.'

const CreateAddMediaButton: FC = () => {
  const inputRef = createRef<HTMLInputElement>()
  const addBtnRef = createRef<HTMLButtonElement>()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const [showAddOptions, setShowAddOptions] = useState(false)
  const { addToUploads } = useUploadManager()
  const { files } = useAppSelector((state) => state.upload)

  const handleGallery = () => {
    history.push(MOBILE_ROUTES.content)
    dispatch(setContentTab('photos'))
    dispatch(setShowCreate(false))
  }

  const handleUploads = () => {
    history.push(MOBILE_ROUTES.content)
    dispatch(setContentTab('uploads'))
    dispatch(setShowCreate(false))
  }

  return (
    <>
      <StyledCreateAddMediaButton>
        <button onClick={() => setShowAddOptions(!showAddOptions)} ref={addBtnRef}>
          <CameraIcon />
          Add photo or video
        </button>
      </StyledCreateAddMediaButton>
      {!files.length && (
        <PullDown
          rows={[
            {
              label: 'From Gallery',
              action: () => handleGallery(),
              type: 'button'
            },
            {
              label: 'From Uploads',
              action: () => handleUploads(),
              type: 'button'
            },
            {
              type: 'divider'
            },
            {
              label: (
                <>
                  <input
                    hidden
                    type="file"
                    accept="image/*, video/*"
                    multiple
                    ref={inputRef}
                    onChange={(e) => addToUploads(e.target.files, true, undefined)}
                  />
                  From Camera Roll
                </>
              ),
              stayOpen: true,
              action: () => inputRef.current?.click(),
              type: 'button'
            }
          ]}
          position="bottom-start"
          targetRef={addBtnRef}
          isOpen={showAddOptions}
          onClose={() => setShowAddOptions(false)}
        />
      )}
    </>
  )
}

export default CreateAddMediaButton
