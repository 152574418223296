import styled from 'styled-components'

const StyledCalendarPost = styled.div`
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;

  .post-preview {
    width: 100%;
    padding-top: 100%;
    background-color: ${({ theme }) => theme.color.gray[100]};
    position: relative;

    .media-preview {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 20px;
      color: ${({ theme }) => theme.color.gray[400]};
    }

    &::after {
      user-select: none;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.foreground}10;
      pointer-events: none;
      z-index: 1;
    }
  }

  span {
    display: block;
  }

  .post-title {
    font-size: 9px;
    font-weight: 600;
    color: ${({ theme }) => theme.color.gray[900]};
    line-height: 10px;
    margin-top: 4px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-align: left;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow-wrap: anywhere;
  }

  .post-date {
    font-size: 8px;
    font-weight: 600;
    color: ${({ theme }) => theme.color.gray[500]};
    line-height: 12px;
    margin-top: 1px;
  }

  &:not(:first-child) {
    margin-top: 4px;
  }
`

export default StyledCalendarPost
