export const SPLITS = [
  'stepList',
  'workspaces',
  'collections',
  'linkedin',
  'twitter',
  'calendar',
  'tasks',
  'darkMode',
  'designer',
  'carousels',
  'academy',
  'contentLibrary',
  'personas',
  'tikTok',
  'pillars',
  'metaReview'
]
export const SPLIT_TYPE = [
  'stepList',
  'workspaces',
  'collections',
  'linkedin',
  'twitter',
  'calendar',
  'tasks',
  'darkMode',
  'designer',
  'carousels',
  'academy',
  'contentLibrary',
  'personas',
  'tikTok',
  'pillars',
  'metaReview'
] as const

export type SplitsType = (typeof SPLIT_TYPE)[number]
