import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { CardType, PlanType } from '../../types'

type InitialState = {
  card?: CardType
  plan?: PlanType
}

const initialState: InitialState = {}

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setCard: (state, action: PayloadAction<CardType>) => {
      state.card = action.payload
    },
    setPlan: (state, action: PayloadAction<PlanType>) => {
      state.plan = action.payload
    }
  }
})

export const { setCard, setPlan } = subscriptionSlice.actions
export default subscriptionSlice.reducer
