import styled, { keyframes } from 'styled-components'

const fadeUp = keyframes`
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`

type StyledProps = {
  fullScreen?: boolean
}

const StyledChatOnboarding = styled.div<StyledProps>`
  position: ${({ fullScreen }) => (fullScreen ? 'fixed' : 'absolute')};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 49;
  transition: 0.2s;
  user-select: none;
  background-color: ${({ theme }) => theme.color.background}c4;

  #gradient-canvas {
    opacity: 0.9;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    --gradient-color-1: #ffffff;
    --gradient-color-2: #7dd3fc;
    --gradient-color-3: ${({ theme }) => theme.color.purple[300]};
    --gradient-color-4: #ffffff;
  }

  .close-btn {
    position: absolute;
    top: 20px;
    left: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    z-index: 1;
    background-color: rgb(0, 0, 0, 0.65);
    color: rgb(255, 255, 255, 0.95);

    svg {
      height: 24px;
      stroke-width: 2px;
    }
  }

  .intro-text,
  .question {
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 500px;
    margin: 0 auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20px;
    box-sizing: border-box;
    display: grid;
    align-content: center;
    z-index: 1;
    text-align: center;

    .dottie-icon-intro {
      width: 80px;
      height: 80px;
      margin: 0 auto 20px auto;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgb(255, 255, 255, 0.6);
      box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
        0px 2px 3px rgba(0, 0, 0, 0.05);

      div {
        background-color: transparent;
      }

      svg {
        height: 55px;
      }
    }

    h1 {
      animation: ${fadeUp} 0.5s ease-in-out forwards;
      font-size: 42px;
      font-weight: 600;
      color: rgb(0, 0, 0, 0.9);
      font-family: ${({ theme }) => theme.typeface.faktumXCon};
    }

    h2 {
      animation: ${fadeUp} 0.5s ease-in-out forwards;
      transform: translateY(20px);
      opacity: 0;
      animation-delay: 0.2s;
      font-size: 20px;
      max-width: 400px;
      margin: 12px auto 0 auto;
      font-weight: 400;
      letter-spacing: -0.01rem;
      color: rgb(0, 0, 0, 0.75);
      font-family: ${({ theme }) => theme.typeface.faktum};
    }

    .whats-next {
      margin-top: 20px;
      border-radius: 6px;
      padding: 20px;
      border: 2px solid ${({ theme }) => theme.color.brand};
      background-color: ${({ theme }) => theme.color.brand}20;

      .label {
        background-color: ${({ theme }) => theme.color.brand};
        color: white;
        display: flex;
        padding: 8px 14px;
        border-radius: 50rem;
        font-size: 15px;
        font-weight: 500;
        text-align: center;
        width: fit-content;
        margin: 0 auto;
      }
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 20px 0 0 0;
      display: grid;
      gap: 8px;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      justify-items: center;
      color: ${({ theme }) => theme.color.gray[800]};

      li {
        display: flex;
        align-items: center;

        svg {
          height: 18px;
          margin-right: 10px;
        }
      }
    }

    p {
      letter-spacing: -0.01rem;
      margin-top: 20px;
      font-size: 20px;
      color: rgb(0, 0, 0, 0.75);
      font-family: ${({ theme }) => theme.typeface.faktum};
    }

    .message-content {
      animation: ${fadeUp} 0.5s ease-in-out forwards;
      transform: translateY(20px);
      opacity: 0;
      animation-delay: 0.3s;
      margin-top: 20px;
      padding: 18px;
      line-height: 22px;
      white-space: pre-wrap;
      color: ${({ theme }) => theme.color.gray[700]};
      background-color: ${({ theme }) => theme.color.background};
      border-radius: 6px;
      box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
        0px 2px 3px rgba(0, 0, 0, 0.05);

      ul,
      ol {
        margin: 0;
        display: grid;
        grid-gap: 20px;
        padding-inline-start: 20px;

        li {
          white-space: normal;
        }
      }
    }

    .question-content {
      margin: 40px auto 0 auto;
      animation: ${fadeUp} 0.5s ease-in-out forwards;
      transform: translateY(20px);
      opacity: 0;

      .question-option-btn {
        display: flex;
        font-size: 15px;
        font-weight: 500;
        margin: 0 auto;
        transition: 0.2s;
        background-color: ${({ theme }) => theme.color.background};
        color: ${({ theme }) => theme.color.gray[700]};
        box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
          0px 2px 3px rgba(0, 0, 0, 0.05);
        padding: 10px;
        box-sizing: border-box;
        text-align: left;
        border-radius: 6px;

        img {
          margin-right: 10px !important;
          position: relative;
          top: 1px;
        }

        &:hover {
          background-color: ${({ theme }) => theme.color.gray[100]};
          color: ${({ theme }) => theme.color.gray[800]};
        }

        &.surprise-btn {
          background-color: rgb(0, 0, 0, 0.95);
          color: rgb(255, 255, 255, 0.95);
          box-shadow: 0;
          width: fit-content;
        }
      }

      .question-content-group {
        margin-top: 30px;
        display: flex;
        justify-self: center;
        gap: 10px;
      }

      &.grid {
        gap: 10px;
        display: grid;
      }
    }

    .dottie-cards {
      margin: 0 auto 30px auto;
      animation: ${fadeUp} 0.5s ease-in-out forwards;
      transform: translateY(20px);
      opacity: 0;

      .swiper {
        width: 280px;
      }

      .swiper-slide {
        display: grid;
        grid-template-rows: minmax(0, 1fr) auto;
        border-radius: 18px;
        text-align: left;
        box-sizing: border-box;
        background-color: ${({ theme }) => theme.color.background};
        box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
          0px 2px 3px rgba(0, 0, 0, 0.05);

        .slide-img {
          padding-top: 100%;
          user-select: none;
          pointer-events: none;
          position: relative;
          background-color: ${({ theme }) => theme.color.gray[100]};

          img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
          }

          &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            background: rgb(0, 0, 0, 0.05);
          }
        }

        .slide-label {
          padding: 20px;
          font-size: 16px;
          font-weight: 500;
          color: ${({ theme }) => theme.color.gray[800]};
        }
      }
    }

    .button-container {
      animation: ${fadeUp} 0.5s ease-in-out forwards;
      transform: translateY(20px);
      opacity: 0;
      animation-delay: 0.4s;
      position: relative;
      margin: 40px auto 0 auto;
      display: flex;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 100px;
        border-radius: 6px;
        height: 50px;
        padding: 0 24px;
        background-color: rgb(0, 0, 0, 0.95);
        color: rgb(255, 255, 255, 0.95);
        font-weight: 500;
        font-size: 18px;
        width: 100%;
        transition: background-color 0.2s;
        font-family: ${({ theme }) => theme.typeface.faktum};

        &.skip-btn {
          background-color: rgb(0, 0, 0, 0.1);
          color: rgb(0, 0, 0, 0.95);
          width: fit-content;
          margin-right: 10px;

          &:hover {
            background-color: rgb(0, 0, 0, 0.25);
            color: rgb(0, 0, 0, 0.95);
          }
        }

        &:not(.enter-btn) {
          svg {
            height: 24px;
            stroke-width: 2px;
            margin-left: 10px;
          }
        }

        &.enter-btn {
          svg {
            fill: white;
          }
        }

        &:hover {
          background-color: rgb(0, 0, 0, 0.75);
        }

        &:disabled {
          background-color: rgb(0, 0, 0, 0.25);
          color: rgb(255, 255, 255, 0.85);
          cursor: not-allowed;
        }
      }
    }
  }
`

export default StyledChatOnboarding
