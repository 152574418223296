import { AppLauncher } from '@capacitor/app-launcher'
import { FacebookShare } from '../../../mobile/plugins'
import { PostType } from '../../../types'
import { saveToAlbum } from '.'

export const shareToFacebook = async (post: Partial<PostType>) => {
  const encodedCaption = encodeURIComponent(post?.captionText || '')

  // await saveToAlbum(post.media)

  await FacebookShare.share({ text: post.captionText || 'test' })

  // await AppLauncher.openUrl({
  //   url: `fb://composer?view=photo&text=${encodedCaption}`
  // })
}
