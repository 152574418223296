import { gql } from '@apollo/client'

export const userHashtagCreateOne = gql`
  mutation UserHashtagCreateOne($_id: String, $hashtag: String, $folder: String) {
    userHashtagCreateOne(_id: $_id, hashtag: $hashtag, folder: $folder) {
      _id
      hashtag
      folder
    }
  }
`
