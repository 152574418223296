import styled from 'styled-components'

const StyledCloudFolderList = styled.div`
  display: grid;
  gap: 12px;
  margin-bottom: 12px;

  .cloud-folder-list-container {
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
    column-gap: 12px;

    .new-folder-btn {
      height: 135px;
      width: 199px;
      flex-shrink: 0;
      padding: 18px;
      box-sizing: border-box;
      border-radius: 5px;
      font-weight: 500;
      font-size: 15px;
      transition: 0.2s;
      background-color: ${({ theme }) => theme.color.gray[100]};
      color: ${({ theme }) => theme.color.gray[600]};

      .new-folder-btn-icon {
        height: 40px;
        width: 40px;
        display: flex;
        margin: 0 auto 14px auto;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transition: 0.2s;
        background-color: ${({ theme }) => theme.color.gray[200]};
        color: ${({ theme }) => theme.color.gray[600]};

        svg {
          height: 24px;
        }
      }

      &:hover {
        background-color: ${({ theme }) => theme.color.gray[200]};
        color: ${({ theme }) => theme.color.gray[700]};
        box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.gray[300]};

        .new-folder-btn-icon {
          background-color: ${({ theme }) => theme.color.gray[300]};
          color: ${({ theme }) => theme.color.gray[700]};
        }
      }
    }
  }
`

export default StyledCloudFolderList
