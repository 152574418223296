import styled from 'styled-components'

const StyledInputDrawer = styled.div`
  padding: 0 20px;
  margin-bottom: -10px;

  .drawer-header {
    display: flex;
    align-items: center;

    .drawer-title {
      font-weight: 500;
      font-size: 20px;
      color: ${({ theme }) => theme.color.gray[800]};
    }
  }

  form {
    margin-top: 20px;
  }

  .drawer-actions {
    margin-top: 28px;
    display: flex;

    button {
      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
`

export default StyledInputDrawer
