import { gql } from '@apollo/client'

export const getDraftCounts = gql`
  query GetDraftCounts {
    getDraftCounts {
      totalPosts
      totalScheduledPosts
      totalDesigns
    }
  }
`
