import styled from 'styled-components'

const StyledPlanPostListItem = styled.div`
  display: grid;
  grid-template-columns: 45px minmax(0, 1fr) 185px;
  gap: 8px;
  position: relative;
  user-select: none;

  .post-label-container {
    height: 45px;
    border-radius: 8px;
    display: grid;
    justify-content: center;
    align-content: center;
    text-align: center;
    gap: 2px;
    background-color: ${({ theme }) => theme.color.gray[100]};

    .post-label {
      font-size: 10px;
      font-weight: 700;
      color: ${({ theme }) => theme.color.gray[500]};
    }

    .post-index {
      font-weight: 600;
      color: ${({ theme }) => theme.color.gray[700]};
    }
  }

  .dropdown {
    height: 45px;
    border-radius: 8px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    box-sizing: border-box;
    transition: 0.2s;
    width: 100%;

    .dropdown-label {
      display: flex;
      align-items: center;
    }

    .chevron-icon {
      height: 16px;
      stroke-width: 2.75px;
      flex-shrink: 0;
    }

    &.pillar-dropdown {
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.gray[200]};

      .pillar-icon {
        font-size: 24px;
        margin-right: 10px;
        flex-shrink: 0;
      }

      .pillar-name {
        color: ${({ theme }) => theme.color.gray[800]};
        display: -webkit-box;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-align: left;
        -webkit-box-orient: vertical;
      }

      .chevron-icon {
        color: ${({ theme }) => theme.color.gray[400]};
      }

      &:hover,
      &[data-expanded='true'] {
        box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.gray[400]};
      }
    }

    &.focus-dropdown {
      position: relative;
      padding-left: 0;
      background-color: ${({ theme }) => theme.color.brandLight};

      .dropdown-label {
        width: 100%;
        padding-left: 10px;
        color: ${({ theme }) => theme.color.brand};

        .focus-icon {
          height: 20px;
          width: 20px;
          flex-shrink: 0;
          margin-right: 10px;
          color: ${({ theme }) => theme.color.brand};
        }
      }

      .chevron-icon {
        color: ${({ theme }) => theme.color.brand};
      }

      &:hover,
      &[data-expanded='true'] {
        box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.brand};
      }
    }
  }

  .delete-btn {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    position: absolute;
    left: calc(100% + 10px);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
    opacity: 0;
    background-color: ${({ theme }) => theme.color.red[50]};
    color: ${({ theme }) => theme.color.red[900]}90;

    svg {
      height: 20px;
      stroke-width: 2.5px;
    }

    &:hover {
      color: ${({ theme }) => theme.color.red[900]};
      background-color: ${({ theme }) => theme.color.red[100]};
    }
  }

  &:hover {
    .delete-btn {
      opacity: 1;
    }
  }
`

export default StyledPlanPostListItem
