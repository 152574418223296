export const colorList = [
  {
    id: '_000000',
    color: '#171717',
    label: 'Black'
  },
  {
    id: '_126F10',
    color: '#22c55e',
    label: 'Green'
  },
  {
    id: '_83C996',
    color: '#86efac',
    label: 'Light Green'
  },
  {
    id: '_2F5CCF',
    color: '#0ea5e9',
    label: 'Blue'
  },
  {
    id: '_C9E1F3',
    color: '#7dd3fc',
    label: 'Light Blue'
  },
  {
    id: '_864B15',
    color: '#78350f',
    label: 'Brown'
  },
  {
    id: '_B1B1B1',
    color: '#e5e5e5',
    label: 'Gray'
  },
  {
    id: '_B698DC',
    color: '#8b5cf6',
    label: 'Purple'
  },
  {
    id: '_C2152C',
    color: '#ef4444',
    label: 'Red'
  },
  {
    id: '_EBB7BE',
    color: '#fca5a5',
    label: 'Light Red'
  },
  {
    id: '_EBE291',
    color: '#facc15',
    label: 'Yellow'
  },
  {
    id: '_EDD5A6',
    color: '#EDD5A6',
    label: 'Tan'
  },
  {
    id: '_F2AE7C',
    color: '#f97316',
    label: 'Orange'
  },
  {
    id: '_ffffff',
    color: '#fff',
    label: 'White'
  }
]

export const styleList = [
  {
    label: 'Light & Airy',
    value: 'light_airy'
  },
  {
    label: 'Dark & Moody',
    value: 'dark_moody'
  },
  {
    label: 'Vibrant',
    value: 'vibrant'
  }
]

export const orientationList = [
  {
    value: 'vertical',
    label: 'Vertical'
  },
  {
    value: 'horizontal',
    label: 'Horizontal'
  }
]
