import styled from 'styled-components'

type StyledProps = {
  showPostList?: boolean
}

const StyledCalendarPostList = styled.div<StyledProps>`
  overflow: hidden;
  display: flex;
  width: 0px;
  transition: 0.3s ease-in-out;
  border-right: 0;
  flex-shrink: 0;
  height: 100%;

  ${({ showPostList, theme }) =>
    showPostList &&
    `
    width: 300px;
    border-right: 1px solid ${theme.color.gray[300]};
  `}

  .post-list-container {
    width: 300px;
    flex-shrink: 0;
    box-sizing: border-box;
    height: 100%;
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);

    .tabs .tab-list .tab button {
      font-size: 15px;
    }

    .post-list {
      width: 100%;
      box-sizing: border-box;
      overflow-y: overlay;
      padding-right: 0px;

      .post-list-grid {
        display: grid;
        gap: 12px 5px;
        padding: 5px 0 5px 5px;
        box-sizing: border-box;
        grid-template-columns: 1fr 1fr;
      }

      &::-webkit-scrollbar {
        height: 0;
        position: absolute;
        width: 18px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10000px;
        border: 4px solid ${({ theme }) => theme.color.background};
        background: ${({ theme }) => theme.color.foreground}10;

        &:hover {
          background: ${({ theme }) => theme.color.foreground}20;
        }
      }

      &::-webkit-scrollbar-track {
        background: transparent;
        width: 8px;
      }
    }
  }
`

export default StyledCalendarPostList
