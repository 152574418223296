import styled from 'styled-components'

const StyledSupportDialog = styled.div`
  padding: 20px;
  box-sizing: border-box;

  .support-heading {
    display: grid;
    justify-items: center;

    svg {
      height: 50px;
      color: ${({ theme }) => theme.color.gray[500]};
    }

    span {
      display: block;
    }

    .support-title {
      margin-top: 14px;
      font-weight: 500;
      font-size: 24px;
      letter-spacing: -0.01rem;
      color: ${({ theme }) => theme.color.gray[800]};
    }

    .support-subtitle {
      margin-top: 6px;
      color: ${({ theme }) => theme.color.gray[500]};
    }
  }

  .support-actions {
    display: grid;
    row-gap: 12px;
    margin-top: 34px;

    a {
      text-decoration: none;
    }

    .btn {
      justify-content: space-between;
    }

    .intercom-btn {
      svg {
        path {
          stroke-width: 1.5px;
        }
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    max-width: 300px;
    width: 100vw;
    padding: 30px 24px 24px 24px;
  }
`

export default StyledSupportDialog
