import styled from 'styled-components'

type StyledProps = {
  loading: boolean
}

const StyledMusePlayer = styled.div<StyledProps>`
  padding-top: 56.25%;
  position: relative;
  border-radius: 3px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.color.gray[200]};

  .fake-splash {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;

    svg {
      height: 20%;
      min-height: 50px;
      color: ${({ theme }) => theme.color.background};
      stroke: #00000033;
      stroke-width: 0.04rem;
      paint-order: stroke;
      transition: 0.2s ease-in-out;
    }

    &:hover {
      svg {
        transform: scale(1.05);
      }
    }
  }

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  iframe,
  .player {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    pointer-events: ${({ loading }) => (loading ? 'none' : 'unset')};
    opacity: ${({ loading }) => (loading ? '0' : '1')};
    visibility: ${({ loading }) => (loading ? 'hidden' : 'visible')};
  }
`

export default StyledMusePlayer
