import { UserType } from '../../types'

export const hydrateSocialProfiles = (
  strings?: (string | null)[] | null,
  user?: UserType | null
) => {
  if (!strings?.length || !user) {
    return []
  }

  return user.socialProfiles.filter((profile) => strings.includes(profile._id || ''))
}
