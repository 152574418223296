import { ReactComponent as FacebookIcon } from '../../../assets/facebook-icon.svg'
import { ReactComponent as InstagramIcon } from '../../../assets/instagram.svg'
import { ReactComponent as LinkedInIcon } from '../../../assets/linkedin-icon.svg'
import { ReactComponent as PinterestIcon } from '../../../assets/pinterest-icon.svg'
import { ReactComponent as TikTokIcon } from '../../../assets/tiktok-icon.svg'
import { ReactComponent as TwitterIcon } from '../../../assets/twitter-icon.svg'
import { SocialProfileType } from '../../../types'

export const getPlatformIcon = (platform: SocialProfileType['type']) => {
  switch (platform) {
    case 'instagram':
      return <InstagramIcon />
    case 'facebook':
      return <FacebookIcon />
    case 'linkedin':
      // case 'linkedin-page':
      return <LinkedInIcon />
    case 'twitter':
      return <TwitterIcon />
    // case 'pinterest':
    //   return <PinterestIcon />
    // case 'tiktok':
    //   return <TikTokIcon />
  }
}
