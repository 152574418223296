import { FC, useState } from 'react'
import { TrashIcon } from '@heroicons/react/20/solid'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { SocialProfileType } from '../../../types'
import { formatUsername, useConnection } from '../../../utils'
import { ActionDialog, Spinner } from '../..'
import { ConnectionAvatar } from '..'
import { StyledConnectionSidebarProfile } from '.'

type Props = {
  profile: SocialProfileType
}

const ConnectionSidebarProfile: FC<Props> = ({ profile }) => {
  const { disconnectProfile, reconnectProfile } = useConnection()
  const [loading, setLoading] = useState(false)
  const [showDeletionConfirmation, setShowDeletionConfirmation] = useState(false)

  const handleDisconnect = async () => {
    setLoading(true)
    await disconnectProfile(profile)
  }

  const handleReconnect = async () => {
    setLoading(true)
    await reconnectProfile(profile)
    setLoading(false)
  }

  return (
    <>
      <StyledConnectionSidebarProfile isExpired={profile.status === 'expired'}>
        <div className="profile-details-container">
          <ConnectionAvatar profile={profile} size={28} />
          <div className="profile-details">
            <span className="profile-username">
              {formatUsername(profile.username, profile.type)}
            </span>
          </div>
        </div>
        <div className="profile-actions">
          {profile.status === 'expired' && !loading && (
            <button data-tooltip="Reconnect" className="icon" onClick={() => handleReconnect()}>
              <ArrowPathIcon />
            </button>
          )}
          <button
            data-tooltip="Disconnect"
            data-tooltip-pos="bottom-end"
            disabled={loading}
            className="icon"
            onClick={() => setShowDeletionConfirmation(true)}
          >
            {loading ? <Spinner /> : <TrashIcon />}
          </button>
        </div>
      </StyledConnectionSidebarProfile>
      <ActionDialog
        title={`Disconnect Profile`}
        body={`Are you sure you want to disconnect ${formatUsername(
          profile.username,
          profile.type
        )}?`}
        onConfirm={() => handleDisconnect()}
        type="error"
        isOpen={showDeletionConfirmation}
        onClose={() => setShowDeletionConfirmation(false)}
      />
    </>
  )
}

export default ConnectionSidebarProfile
