import { CouponType } from '../../../types'

export const getCouponValue = (plan: keyof CouponType['discount'], coupon?: CouponType) => {
  if (!coupon?.discount) {
    return 0
  }

  const discount = coupon.discount[plan]?.amount

  return discount || 0
}
