import { FC, useEffect } from 'react'
import toast from 'react-hot-toast'
import InfiniteScroll from 'react-infinite-scroll-component'
import { times } from 'lodash'
import { useApolloClient } from '@apollo/client'
import { searchIssuesQuery } from '../../../graphql'
import {
  changeActionPlansHasNext,
  changeActionPlansPage,
  setActionPlans,
  useAppDispatch,
  useAppSelector
} from '../../../redux'
import { conditionalStage, isMobile } from '../../../utils'
import { EmptyState, Skeleton } from '../..'
import { ActionPlanListItem } from '..'
import { StyledActionPlanList } from '.'

const ActionPlanList: FC = () => {
  const apollo = useApolloClient()
  const dispatch = useAppDispatch()
  const { loading, actionPlans, hasNext, page, search, categories, platform } = useAppSelector(
    (state) => state.actionPlans
  )

  const loadActionPlans = async () => {
    try {
      const { data } = await apollo.query({
        fetchPolicy: 'no-cache',
        query: searchIssuesQuery,
        variables: {
          page,
          search,
          platform,
          categories
        }
      })

      const actionPlanList = data?.searchIssue?.docs || []
      dispatch(setActionPlans([...actionPlans, ...actionPlanList]))
      dispatch(changeActionPlansHasNext(!!data?.searchIssue?.hasNextPage))
    } catch (err) {
      console.log(err)
      toast.error((err as any).message)
    }
  }

  useEffect(() => {
    if (loading) {
      loadActionPlans()
    }
  }, [loading])

  return (
    <StyledActionPlanList>
      {!loading && !actionPlans.length && (
        <EmptyState
          title="We came up empty"
          body="We couldn't find an action plan that matches that query. How about a different search?"
        />
      )}
      {loading && page === 0 && (
        <div className="action-plans-list">
          {times(15, (num) => {
            return <Skeleton randHeight={[328, 328]} key={num} />
          })}
        </div>
      )}
      <InfiniteScroll
        scrollThreshold={0.7}
        dataLength={actionPlans.length}
        next={() => dispatch(changeActionPlansPage(page + 1))}
        hasMore={!!hasNext}
        loader={null}
        {...conditionalStage(isMobile(), { scrollableTarget: 'app-content' })}
      >
        {actionPlans.map((actionPlan, index) => {
          return <ActionPlanListItem actionPlan={actionPlan} index={index} key={actionPlan._id} />
        })}
      </InfiniteScroll>
    </StyledActionPlanList>
  )
}

export default ActionPlanList
