import { FC, useContext, useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { useApolloClient } from '@apollo/client'
import { SparklesIcon } from '../../../assets'
import { generateContentPillars } from '../../../graphql'
import {
  setPlanLoadingGenPillars,
  setPlanPillars,
  updatePlanPillar,
  useAppDispatch,
  useAppSelector
} from '../../../redux'
import { userStore } from '../../../utils'
import { PillarList } from '..'
import { StyledPlanPillarStep } from '.'

const PlanPillarStep: FC = () => {
  const apollo = useApolloClient()
  const dispatch = useAppDispatch()
  const { user, refresh } = useContext(userStore)
  const { pillars, loadingGenPillars } = useAppSelector((state) => state.plan)

  const generate = async () => {
    if (!!pillars.length) {
      return
    }

    dispatch(setPlanLoadingGenPillars(true))

    const { data } = await apollo.query({
      fetchPolicy: 'no-cache',
      query: generateContentPillars
    })

    dispatch(setPlanPillars(data.generateContentPillars))
    dispatch(setPlanLoadingGenPillars(false))
  }

  useEffect(() => {
    generate()
  }, [])

  return (
    <StyledPlanPillarStep>
      <div className="sparkles-icon">
        <SparklesIcon />
      </div>
      <h1>{(user?.displayName || 'Friend').split(' ')[0]}, review your custom Content Pillars</h1>
      <p>
        Your content pillars will help you create social media content that engages your ideal
        client.
      </p>
      <div className="pillar-container">
        {(!loadingGenPillars || !!pillars.length) && (
          <PillarList
            pillars={pillars}
            onChange={(_id, pillar) => dispatch(updatePlanPillar({ _id, pillar }))}
            onRemove={(_id) => dispatch(setPlanPillars(pillars.filter((p) => p._id !== _id)))}
            onAdd={(pillar) => dispatch(setPlanPillars([...pillars, { _id: uuid(), ...pillar }]))}
          />
        )}
      </div>
    </StyledPlanPillarStep>
  )
}

export default PlanPillarStep
