import styled from 'styled-components'

const StyledClaimTrialView = styled.div`
  height: 100vh;
  width: 100vw;
  padding: 20px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.color.gray[100]};

  .dialog {
    padding: 60px;
    box-sizing: border-box;
    border-radius: 12px;
    position: fixed;
    max-width: 500px;
    width: 100vw;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.2), 0px 0px 0px 1px rgba(0, 0, 0, 0.075),
      0px 2px 3px rgba(0, 0, 0, 0.1);
    background-color: ${({ theme }) => theme.color.background};
  }

  .pill {
    border-radius: 50rem;
    padding: 0 12px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    width: fit-content;
    font-weight: 600;
    letter-spacing: 0.05rem;
    margin: 0 auto 20px auto;
    color: ${({ theme }) => theme.color.brand};
    background-color: ${({ theme }) => theme.color.brandLight};
  }

  h1 {
    font-size: 30px;
    font-weight: 600;
    font-family: ${({ theme }) => theme.typeface.faktumXCon};
    color: ${({ theme }) => theme.color.gray[800]};
  }

  h2 {
    font-size: 16px;
    font-weight: 400;
    max-width: 480px;
    line-height: 22px;
    margin-top: 8px;
    font-family: ${({ theme }) => theme.typeface.faktum};
    color: ${({ theme }) => theme.color.gray[600]};
  }

  .btn {
    margin: 40px auto 0 auto;
  }
`

export default StyledClaimTrialView
