import { FC } from 'react'
import { updateCreatePost, useAppDispatch, useAppSelector } from '../../../../redux'
import { CreateEditorSubConfig } from '../..'

export const TikTokPrivacyStatusConfig: FC = () => {
  const dispatch = useAppDispatch()
  const { post } = useAppSelector((state) => state.create)
  const { splits } = useAppSelector((state) => state.splits)

  const handleChange = (value: any) => {
    dispatch(
      updateCreatePost({
        tikTokPrivacyStatus: value
      })
    )
  }

  if (splits.tikTok !== 'on') {
    return null
  }

  return (
    <CreateEditorSubConfig
      name="tik_tok_privacy_status"
      label="TikTok Privacy Status"
      description="Choose how your post is published on TikTok."
      type="radio"
      options={[
        {
          label: 'Public',
          value: 'PUBLIC_TO_EVERYONE',
          checked: !post?.tikTokPrivacyStatus || post.tikTokPrivacyStatus === 'PUBLIC_TO_EVERYONE'
        },
        {
          label: 'Friends',
          value: 'MUTUAL_FOLLOW_FRIENDS',
          checked: post?.tikTokPrivacyStatus === 'MUTUAL_FOLLOW_FRIENDS'
        },
        {
          label: 'Private',
          value: 'SELF_ONLY',
          checked: post?.tikTokPrivacyStatus === 'SELF_ONLY'
        }
      ]}
      // platforms={['tiktok']}
      platforms={[]}
      onSelect={(value) => handleChange(value)}
    />
  )
}
