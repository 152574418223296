import { FC, useContext } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { LockClosedIcon } from '@heroicons/react/20/solid'
import { copyToClipboard, ROUTES, userStore } from '../../utils'
import {
  Banner,
  Button,
  Input,
  PageLayout,
  ReferralActivity,
  ReferralStats
} from '../../components'
import { StyledReferralsView } from '.'

const ReferralsView: FC = () => {
  const { user } = useContext(userStore)

  return (
    <StyledReferralsView>
      <Helmet title="Referrals | Social Curator" />
      <Banner message="The Social Curator Referral Program is coming soon." />
      <div className="lock-screen">
        <div className="icon">
          <LockClosedIcon />
        </div>
        <span className="title">Check back later</span>
        <span className="body">Referrals are coming soon.</span>
      </div>
      <PageLayout maxWidth={650}>
        <div className="heading">
          <h1>Referral Program</h1>
          <p>
            Every Social Curator user can take part in our affiliate program. For every paying
            customer you refer with one of your unique URLs, you'll receive compensation.
          </p>
          <p>
            Want some pre-made graphics to use?{' '}
            <Link to={ROUTES.actionPlanBySlug('affiliates')} target="_blank">
              Download them here
            </Link>
            .
          </p>
        </div>
        <div className="section">
          <h2>Your unique referral URLs</h2>
          <p>Start earning by sharing your link to one of the offers below.</p>
          <div className="offers">
            <div className="offer">
              <span>
                <b>Social Curator Subscription</b>
              </span>
              <span>
                When someone you refer signs up for a paid plan, you both receive benefits.{' '}
                <a>Learn more</a>
              </span>
              <div className="offer-input">
                <Input value={`${window.location.origin}/a/${user?.id}`} disabled={true} />
                <Button
                  variant="emphasis-tone"
                  onClick={() => copyToClipboard(`${window.location.origin}/a/${user?.id}`)}
                >
                  Copy
                </Button>
              </div>
            </div>
            <div className="offer">
              <span>
                <b>S.O.S. Selling on Social Course</b>
              </span>
              <span>
                Earn {user?.isWhale ? '50' : '40'}% every time someone uses your URL to purchase the
                course.
              </span>
              <div className="offer-input">
                <Input
                  value={`${window.location.origin}/course/signup?affiliate=${user?.id}`}
                  disabled={true}
                />
                <Button
                  variant="emphasis-tone"
                  onClick={() =>
                    copyToClipboard(`${window.location.origin}/course/signup?affiliate=${user?.id}`)
                  }
                >
                  Copy
                </Button>
              </div>
            </div>
          </div>
        </div>
        <ReferralActivity />
        <div className="section">
          <h2>Your referral stats</h2>
          <p>Automatic payouts are processed on the first of every month.</p>
          <ReferralStats />
        </div>
      </PageLayout>
    </StyledReferralsView>
  )
}

export default ReferralsView
