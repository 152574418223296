import styled from 'styled-components'

type StyledProps = {
  $handleBefore?: boolean
  $isDragging?: boolean
  $isOverlay?: boolean
}

const StyledEditorMediaListItem = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  border-radius: 10px;
  transition: background-color 0.2s;
  user-select: none;

  .group {
    display: flex;
    align-items: center;

    .drag-icon {
      display: flex;
      position: relative;
      left: -4px;
      margin-right: 6px;
      color: ${({ theme }) => theme.color.gray[500]};

      svg {
        height: 20px;
      }
    }

    .media-preview {
      height: 60px;
      width: 60px;
      overflow: hidden;
      border-radius: 6px;
      pointer-events: none;
      background-color: ${({ theme }) => theme.color.gray[200]}c4;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }

    .media-details {
      margin-left: 14px;
      font-size: 14px;
      color: ${({ theme }) => theme.color.gray[600]};

      .type {
        margin-top: 4px;
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 13px;
        color: ${({ theme }) => theme.color.gray[500]};

        svg {
          height: 16px;
          margin-right: 5px;
        }
      }

      .size {
        display: block;
        font-weight: 500;
      }
    }

    .action-btn {
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      cursor: pointer;
      opacity: 0;
      background-color: transparent;
      color: ${({ theme }) => theme.color.gray[600]};
      border: 1px solid ${({ theme }) => theme.color.gray[200]};

      svg {
        height: 20px;
      }

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  &:hover {
    .drag-icon {
      cursor: grab;
    }

    .group .action-btn {
      opacity: 1;
    }
  }

  ${({ $isDragging, theme }) =>
    $isDragging &&
    `
    cursor: grabbing;
    z-index: 10;
    background-color: ${theme.color.background};
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1),
    0 2px 6px rgba(0, 0, 0, 0.05), 0px 2px 12px rgba(0, 0, 0, 0.075);

    .group {
      .action-btn {
        display: none;
      }
    }

    &:hover {
      cursor: grabbing;
      background-color: ${theme.color.background};
    }
  `}
`

export default StyledEditorMediaListItem
