import styled from 'styled-components'

const StyledTab = styled.button`
  height: 50px;
  position: relative;
  font-weight: 600;
  flex-shrink: 0;
  background-color: transparent;
  color: ${({ theme }) => theme.color.gray[500]};

  &:not(:last-child) {
    margin-right: 17px;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    pointer-events: none;
  }

  &[aria-selected='true'] {
    color: ${({ theme }) => theme.color.gray[900]};

    &::after {
      background-color: ${({ theme }) => theme.color.gray[900]};
    }
  }
`

export default StyledTab
