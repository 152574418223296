import { FC, useContext, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { Prompt, useHistory } from 'react-router-dom'
import firebase from 'firebase/app'
import { omit } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { useApolloClient } from '@apollo/client'
import { DocumentDuplicateIcon } from '@heroicons/react/20/solid'
import { EyeIcon } from '@heroicons/react/24/outline'
import {
  ChartPieIcon,
  QueueListIcon,
  SparklesIcon,
  UserCircleIcon
} from '@heroicons/react/24/solid'
import { getUserPages, pageUpsertOne } from '../../../graphql'
import { savePage, setPage, useAppDispatch, useAppSelector } from '../../../redux'
import { ROUTES, copyToClipboard, isMobile, userStore } from '../../../utils'
import { Button, LinkPagePreviewDialog, Spinner, Tabs } from '../..'
import {
  LinkPageAnalyticsTab,
  LinkPageLayoutTab,
  LinkPageProfileTab,
  LinkPageStyleTab
} from './tab'
import { StyledLinkPageEditor } from '.'

const LinkPageEditor: FC = () => {
  const apollo = useApolloClient()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const storageRef = firebase.storage().ref()
  const { page, isSaved, newAvatar, savedPage } = useAppSelector((state) => state.page)
  const { user } = useContext(userStore)
  const [loading, setLoading] = useState(false)
  const [showPreview, setShowPreview] = useState(false)
  const [tab, setTab] = useState<'links' | 'style' | 'details' | 'analytics'>('links')

  const checkPageExists = async () => {
    if (!user) {
      return
    }

    const { data } = await apollo.query({
      fetchPolicy: 'no-cache',
      query: getUserPages
    })

    if (!data.getUserPages) {
      return history.push(ROUTES.linkPage.newPage)
    }

    dispatch(
      setPage({
        ...data.getUserPages,
        clicks: data.getUserPages.clicks || 0,
        views: data.getUserPages.views || 0
      })
    )
  }

  const discardChanges = () => {
    dispatch(savePage())
    dispatch(setPage(savedPage))
  }

  const handleSaveAvatar = async () => {
    let avatar = page?.avatar

    if (newAvatar?.file !== undefined && !!page?.avatar) {
      const fileRef = firebase.storage().refFromURL(page?.avatar)
      await fileRef.delete()
      avatar = null
    }

    if (!!newAvatar?.file) {
      const fileRef = storageRef.child(
        `user-images/pages/${page?.username || 'username'}-${uuidv4()}`
      )

      const uploadTask = await fileRef.put(newAvatar.file)
      avatar = await uploadTask.ref.getDownloadURL()
    }

    return avatar
  }

  const handleSavePage = async () => {
    try {
      setLoading(true)
      const avatar = await handleSaveAvatar()

      await apollo.mutate({
        fetchPolicy: 'no-cache',
        mutation: pageUpsertOne,
        variables: {
          record: {
            ...omit(page, ['views', 'clickBreakdown', 'clicks']),
            avatar: avatar || null
          },
          isNew: savedPage?.username !== page?.username
        }
      })

      dispatch(savePage())
      dispatch(setPage({ ...page, avatar: avatar || null }))
    } catch (err) {
      console.error(err)
      toast.error((err as any).message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (page) {
      return
    }

    checkPageExists()
  }, [user, page])

  useEffect(() => {
    return () => {
      discardChanges()
    }
  }, [])

  if (!page?._id) {
    return <></>
  }

  return (
    <>
      <StyledLinkPageEditor isSaved={isSaved}>
        <div className="link-page-header-container">
          <div className="link-page-header">
            <h1>
              Link Page <div className="new-flag">NEW</div>
            </h1>
            <div className="link-page-details">
              <label className="link-page-username" data-tour-id="link-page-username">
                @{page?.username}
                <Button
                  className="copy-btn"
                  isIcon
                  onClick={() => copyToClipboard(`${window.origin}/@${page?.username}`)}
                >
                  <DocumentDuplicateIcon />
                </Button>
              </label>
              <span className="link-page-stats">
                <div>
                  {page?.clicks || 0} click{page?.clicks !== 1 && 's'}
                </div>
                <div>
                  {page?.views || 0} impression{page?.views !== 1 && 's'}
                </div>
              </span>
              {isMobile() && (
                <Button
                  size="sm"
                  className="preview-btn"
                  iconPos="left"
                  onClick={() => setShowPreview(true)}
                >
                  <EyeIcon />
                  Preview
                </Button>
              )}
            </div>
          </div>
          <div className="link-page-tabs">
            <Tabs
              tabs={[
                {
                  icon: <QueueListIcon />,
                  label: 'Layout',
                  tourId: 'link-page-links-tab',
                  isActive: tab === 'links',
                  onClick: () => setTab('links')
                },
                {
                  icon: <SparklesIcon />,
                  label: 'Style',
                  tourId: 'link-page-style-tab',
                  isActive: tab === 'style',
                  onClick: () => setTab('style')
                },
                {
                  icon: <UserCircleIcon />,
                  label: 'Profile',
                  tourId: 'link-page-details-tab',
                  isActive: tab === 'details',
                  onClick: () => setTab('details')
                },
                {
                  icon: <ChartPieIcon />,
                  label: 'Analytics',
                  tourId: 'link-page-analytics-tab',
                  isActive: tab === 'analytics',
                  onClick: () => setTab('analytics')
                }
              ]}
            />
          </div>
        </div>
        <div className="link-page-content">
          <div className="link-page-content-scroll">
            {tab === 'links' && <LinkPageLayoutTab />}
            {tab === 'style' && <LinkPageStyleTab />}
            {tab === 'details' && <LinkPageProfileTab />}
            {tab === 'analytics' && <LinkPageAnalyticsTab />}
          </div>
        </div>
        {!isSaved && (
          <div className="link-page-actions">
            <Button disabled={loading} onClick={() => discardChanges()}>
              Discard changes
            </Button>
            <Button variant="emphasis" disabled={loading} onClick={() => handleSavePage()}>
              {loading ? <Spinner /> : 'Save changes'}
            </Button>
          </div>
        )}
      </StyledLinkPageEditor>
      <Prompt
        when={isSaved !== true}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <LinkPagePreviewDialog isOpen={showPreview} onClose={() => setShowPreview(false)} />
    </>
  )
}

export default LinkPageEditor
