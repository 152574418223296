import { FC, useContext } from 'react'
import { ArrowLeftIcon, ArrowsRightLeftIcon } from '@heroicons/react/24/outline'
import { SCMark } from '../../../../assets'
import { connectionStore } from '../../../../components'
import { getPlatformDescription, getPlatformLogo, getPlatformTitle } from '../../../../utils'
import { Spinner } from '../..'
import { StyledConnectionDisclaimer } from '.'

const ConnectionDisclaimer: FC = () => {
  const { platformToConnect, loading, setPlatformToConnect, connectPlatform } =
    useContext(connectionStore)

  if (!platformToConnect) {
    return null
  }

  return (
    <StyledConnectionDisclaimer>
      <button className="back-btn" onClick={() => setPlatformToConnect(undefined)}>
        <ArrowLeftIcon />
      </button>
      <div className="connection-indicator">
        <div className="platform-image">
          <img
            src={`/images/logos/${getPlatformLogo(platformToConnect)}`}
            alt={`${getPlatformTitle(platformToConnect)} Logo`}
          />
        </div>
        <div className="connection-icon">
          <ArrowsRightLeftIcon />
        </div>
        <div className="platform-image rounded">
          <SCMark />
        </div>
      </div>
      <span className="connection-title">
        Connect {getPlatformTitle(platformToConnect)} to Social Curator
      </span>
      <span className="connection-description">
        Connecting your {getPlatformTitle(platformToConnect)}{' '}
        {getPlatformDescription(platformToConnect)} lets you schedule & publish posts to{' '}
        {getPlatformTitle(platformToConnect)}, directly from Social Curator.
      </span>
      <div className="connection-actions">
        <button disabled={loading} onClick={() => connectPlatform(platformToConnect)}>
          {loading && <Spinner />}
          {!loading && (
            <>
              Connect {getPlatformTitle(platformToConnect)}{' '}
              {getPlatformDescription(platformToConnect)}
            </>
          )}
        </button>
      </div>
    </StyledConnectionDisclaimer>
  )
}

export default ConnectionDisclaimer
