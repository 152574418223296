import { FC, createRef, useState } from 'react'
import { Capacitor } from '@capacitor/core'
import { CheckCircleIcon as SolidCheckCircleIcon } from '@heroicons/react/20/solid'
import {
  ArrowUpCircleIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  EllipsisHorizontalIcon,
  PlusCircleIcon,
  TrashIcon,
  XMarkIcon
} from '@heroicons/react/24/outline'
import { PencilIcon } from '@heroicons/react/24/solid'
import { setShowCreate, useAppDispatch, useAppSelector } from '../../../../redux'
import { conditionalStage, theme, useCreate, usePost } from '../../../../utils'
import { CreatePublishSheet, CreateTitleInput, PullDown, ShareSheet } from '../..'
import { StyledCreateHeader } from '.'

type Props = {
  showTitleInput: boolean
  setShowTitleInput: (bool: boolean) => void
}

const CreateHeader: FC<Props> = ({ showTitleInput, setShowTitleInput }) => {
  const optionsRef = createRef<HTMLButtonElement>()
  const dispatch = useAppDispatch()
  const [showShareSheet, setShowShareSheet] = useState(false)
  const [showOptions, setShowOptions] = useState(false)
  const [showPublishSheet, setShowPublishSheet] = useState(false)
  const { post } = useAppSelector((state) => state.create)
  const { newPost, sharePost } = useCreate()
  const { togglePostUsed } = usePost()

  return (
    <>
      <StyledCreateHeader>
        <div className="header-group">
          <button
            className="close-btn"
            onClick={() => {
              newPost()
              dispatch(setShowCreate(false))
            }}
          >
            <XMarkIcon />
          </button>
          <div className="title-btn" onClick={() => setShowTitleInput(true)}>
            {post?.title || 'Untitled Post'}
          </div>
        </div>
        <div className="header-group">
          <button
            className="options-btn"
            data-active={showOptions}
            ref={optionsRef}
            onClick={() => setShowOptions(!showOptions)}
          >
            <EllipsisHorizontalIcon />
          </button>
          <button className="share-btn" onClick={() => setShowPublishSheet(!showPublishSheet)}>
            Publish <ChevronDownIcon />
          </button>
        </div>
        {showTitleInput && <CreateTitleInput onClose={() => setShowTitleInput(false)} />}
      </StyledCreateHeader>
      <CreatePublishSheet isOpen={showPublishSheet} onClose={() => setShowPublishSheet(false)} />
      <PullDown
        rows={[
          {
            label: 'Edit title',
            icon: <PencilIcon style={{ height: 18 }} />,
            type: 'button',
            action: () => setShowTitleInput(true)
          },
          // {
          //   label: 'Duplicate',
          //   icon: <Square2StackIcon />,
          //   type: 'button'
          // },
          // {
          //   label: 'Favorite',
          //   icon: <HeartIcon />,
          //   type: 'button'
          // },
          // {
          //   type: 'divider'
          // },
          {
            icon: post?.isUsed ? (
              <SolidCheckCircleIcon fill={theme.color.green[600]} />
            ) : (
              <CheckCircleIcon />
            ),
            label: `Mark as ${post?.isUsed ? 'unpublished' : 'published'}`,
            type: 'button',
            action: () => togglePostUsed(post as any)
          },
          ...conditionalStage(!!Capacitor.isNativePlatform(), [
            {
              label: 'Share',
              icon: <ArrowUpCircleIcon />,
              type: 'button',
              action: () => sharePost()
            }
          ]),
          {
            type: 'divider'
          },
          {
            label: 'New post',
            icon: <PlusCircleIcon />,
            type: 'button',
            action: () => newPost()
          },
          {
            type: 'divider'
          },
          {
            label: 'Delete',
            icon: <TrashIcon />,
            variant: 'destructive',
            type: 'button'
          }
        ]}
        position="bottom-end"
        targetRef={optionsRef}
        isOpen={showOptions}
        onClose={() => setShowOptions(false)}
      />
      <ShareSheet isOpen={showShareSheet} onClose={() => setShowShareSheet(false)} />
    </>
  )
}

export default CreateHeader
