import styled from 'styled-components'

const StyledSidebarConnectionsButton = styled.button`
  height: 40px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.color.gray[300]};
  background-color: ${({ theme }) => theme.color.background};
  color: ${({ theme }) => theme.color.gray[500]};
  transition: 0.2s;
  display: flex;
  font-weight: 500;
  font-size: 17px;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  padding: 0 4px 0 8px;
  box-sizing: border-box;
  width: 100%;

  .plus-icon {
    height: 22px;
    stroke-width: 2px;
  }

  .profile-list {
    display: flex;

    .plus-bubble {
      height: 28px;
      width: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 0 0 3px ${({ theme }) => theme.color.background};
      background-color: ${({ theme }) => theme.color.gray[200]};
      color: ${({ theme }) => theme.color.gray[600]};
      border-radius: 50%;
      position: relative;
      z-index: 1;
      font-size: 13px;
      margin-left: -${28 * 0.2}px;
      font-weight: 600;
    }

    .avatar,
    .plus-bubble {
      transition: 0.2s;
    }
  }

  &:hover {
    color: ${({ theme }) => theme.color.gray[800]};
    background-color: ${({ theme }) => theme.color.gray[100]};
    border-color: ${({ theme }) => theme.color.gray[300]};

    .profile-list {
      .avatar,
      .plus-bubble {
        box-shadow: 0 0 0 3px ${({ theme }) => theme.color.gray[100]};
      }
    }
  }

  &[data-has-connections='false'] {
    border: 1px solid ${({ theme }) => theme.color.brandLight};
    background-color: ${({ theme }) => theme.color.brandLight};
    color: ${({ theme }) => theme.color.brand};

    &:hover {
      color: white;
      background-color: ${({ theme }) => theme.color.brand};
      border-color: ${({ theme }) => theme.color.brand};
    }
  }
`

export default StyledSidebarConnectionsButton
