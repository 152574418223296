import styled from 'styled-components'

const StyledLinkPageStyleTab = styled.div`
  .style-group {
    padding: 0 0 20px 0;

    .style-group-heading {
      padding: 20px 20px 15px 20px;

      span {
        display: block;
      }

      .style-header {
        font-weight: 600;
        font-size: 16px;
        color: ${({ theme }) => theme.color.gray[800]};
      }

      .style-subtext {
        margin-top: 4px;
        color: ${({ theme }) => theme.color.gray[600]};
        font-size: 14px;
      }
    }

    .scroll-row-overflow,
    .style-group-content {
      padding: 0 20px;

      .style-group-color-input-container {
        display: block;
        width: fit-content;

        .style-group-color-input-label {
          font-weight: 500;
          color: ${({ theme }) => theme.color.gray[600]};
          font-size: 14px;
        }

        .style-group-color-input {
          display: flex;
          margin-top: 10px;

          .style-group-color-input-color {
            height: 42px;
            width: 42px;
            margin-right: 10px;
            position: relative;
            border-radius: 4px;
            cursor: pointer;
            background-size: cover !important;
            background-position: center !important;
            background-color: ${({ theme }) => theme.color.gray[100]};

            input {
              opacity: 0;
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              width: 100%;
              height: 100%;
              border-radius: 4px;
            }

            &::after {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              width: 100%;
              height: 100%;
              border-radius: 4px;
              box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.foreground}20;
            }
          }
        }

        .form-input-choose {
          display: grid;
          grid-gap: 8px;
          /* margin-top: 10px; */

          .form-input-choose-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 60px;
            border-radius: 6px;
            background-color: transparent;
            transition: 0.2s;
            font-weight: 500;
            font-size: 15px;
            color: ${({ theme }) => theme.color.gray[600]};
            box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), inset 0 0 0 1px rgb(0, 0, 0, 0.1),
              0 1px 3px rgb(0, 0, 0, 0.04);

            &:hover {
              box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.2),
                inset 0 0 0 1px rgb(0, 0, 0, 0.15), 0 1px 3px rgb(0, 0, 0, 0.08);
            }

            &[aria-selected='true'] {
              box-shadow: inset 0 0 0 2px ${({ theme }) => theme.color.brand};
              color: ${({ theme }) => theme.color.brand};
            }

            &.font-choose-btn {
              font-weight: 400;
            }
          }
        }

        &:not(:first-child) {
          margin-top: 15px;
        }

        &.range-container {
          .range {
            width: 268.5px;
            margin-top: 14px;
          }
        }

        &.choose-container {
          width: 100%;
        }
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
    }
  }

  /* FONTS */
  .font-select-btn {
    font-weight: 400;
    width: 268.5px;
    justify-content: space-between;

    span {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    svg {
      margin-left: 10px;
      color: ${({ theme }) => theme.color.gray[400]};
    }
  }

  /* CHOOSE BUTTON GRAPHICS */

  .photo-option-preview,
  .link-option-preview {
    height: 30px;
    width: 30px;
    border: 2px solid ${({ theme }) => theme.color.gray[500]};

    &.low-radius {
      border-radius: 4px;
    }

    &.med-radius {
      border-radius: 8px;
    }

    &.high-radius {
      border-radius: 12px;
    }

    &.circle-radius {
      border-radius: 100px;
    }

    &.link-option-preview {
      width: 75px;
    }
  }

  [aria-selected='true'] {
    .photo-option-preview,
    .link-option-preview {
      border-color: ${({ theme }) => theme.color.brand};
    }
  }

  /* IMAGES */
  .suggested-image-block {
    width: 128px;
    height: 200px;
    border-radius: 6px;
    position: relative;
    cursor: pointer;
    background-color: ${({ theme }) => theme.color.gray[100]};

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 6px;
      object-fit: cover;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 6px;
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.foreground}20;
    }
  }

  /* THEMES */
  .theme-block-container {
    cursor: pointer;

    .theme-block {
      width: 128px;
      height: 200px;
      border-radius: 6px;
      position: relative;
      background-color: ${({ theme }) => theme.color.gray[100]};

      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        border-radius: 6px;
        object-fit: cover;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        border-radius: 6px;
        box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.foreground}20;
      }
    }

    .theme-block-label {
      display: block;
      margin-top: 8px;
      text-align: center;
      font-size: 13px;
      color: ${({ theme }) => theme.color.gray[800]};
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    .style-group {
      padding: 0 0 35px 0;

      .style-group-heading {
        padding: 35px 35px 25px 35px;
      }

      .scroll-row-overflow,
      .style-group-content {
        padding: 0 35px;

        .style-group-color-input-container {
          .form-input-choose {
            display: flex;
            grid-gap: unset;

            .form-input-choose-btn {
              &:not(:last-child) {
                margin-right: 8px;
              }
            }
          }

          &:not(:first-child) {
            margin-top: 25px;
          }
        }
      }
    }
  }
`

export default StyledLinkPageStyleTab
