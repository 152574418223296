import { gql } from '@apollo/client'

export const chatGetConversations = gql`
  query ChatGetConversations {
    chatGetConversations {
      _id
      topic
      lastMessage {
        content
        template
        createdAt
      }
    }
  }
`
