import { gql } from '@apollo/client'

export const courseFindMany = gql`
  query CourseFindMany(
    $_id: String
    $slug: String
    $hideInactive: Boolean
    $page: Int
    $items: Int = 10
    $userId: String
  ) {
    courseFindMany(
      _id: $_id
      slug: $slug
      hideInactive: $hideInactive
      page: $page
      items: $items
      userId: $userId
    ) {
      totalDocs
      totalPages
      nextPage
      hasNextPage
      docs {
        _id
        title
        coverImage {
          fileHttpLink
          fullPath
          imgSize {
            width
            height
          }
        }
        description
        previewDescription
        offeringsDescription
        includesDescription {
          label
          icon
        }
        availableForSale
        hideToInactive
        hideToTrial
        teaser {
          fid
          svid
          duration
        }
        releaseDate
        status
        slug
        modules {
          _id
          label
          title
          course
          moduleNumber
          lessons {
            _id
            label
            title
            thumbnail {
              fileHttpLink
              fullPath
              imgSize {
                width
                height
              }
            }
            workbook {
              fileHttpLink
              fullPath
            }
            description
            video {
              fid
              svid
              duration
            }
            offeringsDescription
            additionalFiles {
              label
              file {
                fileHttpLink
                fullPath
              }
            }
            status
            userLesson {
              _id
              isCompleted
              isSkipped
            }
            module
            slug
            course
          }
        }
        isOwned
        price
        accentColor
        pills
      }
    }
  }
`
