import { FC, useContext, useEffect, useState } from 'react'
import { uniqBy } from 'lodash'
import { connectionStore } from '../../../../components'
import { SocialProfileListItem, Spinner } from '../..'
import { ConnectionDisclaimer } from '..'
import { StyledConnectionConnect } from '.'

const ConnectionConnect: FC = () => {
  const [atLimit, setAtLimit] = useState(false)
  const {
    loading,
    platformToConnect,
    activeProfiles,
    expiredProfiles,
    connectionLimit,
    profilesToConnect,
    availableProfiles,
    toggleProfile,
    connectProfiles
  } = useContext(connectionStore)

  // useEffect(() => {
  //   const parsed = parse(location.search)
  //   handleConnectAccountsFromState(parsed)
  // }, [location.search])

  useEffect(() => {
    const profiles = uniqBy(
      [
        ...activeProfiles.filter((profile) => profile.type === platformToConnect),
        ...profilesToConnect
      ],
      'profileId'
    )
    setAtLimit(profiles.length >= connectionLimit)
  }, [activeProfiles, profilesToConnect])

  if (!availableProfiles.length) {
    return <ConnectionDisclaimer />
  }

  return (
    <StyledConnectionConnect>
      <div className="connection-select-header">
        <span>Which profiles do you want to connect?</span>
      </div>
      <div className="connection-select-list">
        {availableProfiles.map((profile) => {
          return (
            <>
              <SocialProfileListItem
                profile={profile}
                isSelected={
                  !![...activeProfiles, ...profilesToConnect].find(
                    (p) => p.profileId === profile.profileId
                  )
                }
                isDisabled={
                  !![...activeProfiles, ...expiredProfiles].find(
                    (p) => p.profileId === profile.profileId
                  ) ||
                  (atLimit && !profilesToConnect.find((p) => p.profileId === profile.profileId))
                }
                onSelect={() => toggleProfile(profile)}
                key={profile._id}
              />
            </>
          )
        })}
      </div>
      <div className="connection-action-list">
        <button disabled={!profilesToConnect.length || loading} onClick={() => connectProfiles()}>
          {loading && <Spinner />}
          {!loading && <>Connect profile{profilesToConnect.length > 1 && 's'}</>}
        </button>
      </div>
    </StyledConnectionConnect>
  )
}

export default ConnectionConnect
