import { Capacitor } from '@capacitor/core'
import { Haptics, ImpactStyle } from '@capacitor/haptics'

type HapticType =
  | 'impact-heavy'
  | 'impact-medium'
  | 'impact-light'
  | 'vibrate'
  | 'selection-start'
  | 'selection-changed'
  | 'selection-end'
  | 'notification'

export const useHaptics = () => {
  const trigger = async (type: HapticType) => {
    try {
      if (!Capacitor.isPluginAvailable('Haptics')) {
        return
      }

      switch (type) {
        case 'impact-light':
          return await Haptics.impact({ style: ImpactStyle.Light })
        case 'impact-medium':
          return await Haptics.impact({ style: ImpactStyle.Medium })
        case 'impact-heavy':
          return await Haptics.impact({ style: ImpactStyle.Heavy })
        case 'vibrate':
          return await Haptics.vibrate()
        case 'selection-start':
          return await Haptics.selectionStart()
        case 'selection-changed':
          return await Haptics.selectionChanged()
        case 'selection-end':
          return await Haptics.selectionEnd()
        case 'notification':
          return await Haptics.notification()
      }
    } catch (err) {}
  }

  return {
    trigger
  }
}
