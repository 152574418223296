import { FC } from 'react'
import { useAppDispatch, useAppSelector } from '../../../redux'
import { HomeStats, PostList, Tabs } from '../../components'
import { setHomeTab } from '../../redux'
import { StyledHomeView } from '.'

const HomeView: FC = () => {
  const dispatch = useAppDispatch()
  const { tab } = useAppSelector((state) => state.homeMobile)

  return (
    <StyledHomeView>
      <HomeStats />
      <Tabs
        tabs={[
          {
            label: 'My Posts',
            isActive: tab === 'posts',
            onClick: () => dispatch(setHomeTab('posts'))
          }
          // {
          //   label: 'My Designs',
          //   isActive: tab === 'designs',
          //   onClick: () => dispatch(setHomeTab('designs'))
          // },
          // {
          //   label: 'Post Templates',
          //   isActive: tab === 'templates',
          //   onClick: () => dispatch(setHomeTab('templates'))
          // }
        ]}
      >
        {/* <button className="filter-btn">
          <AdjustmentsHorizontalIcon />
        </button> */}
      </Tabs>
      <PostList />
    </StyledHomeView>
  )
}

export default HomeView
