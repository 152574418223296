import { FC } from 'react'
import { ArrowTopRightOnSquareIcon, LifebuoyIcon } from '@heroicons/react/24/outline'
import { ReactComponent as IntercomIcon } from '../../../assets/intercom-icon.svg'
import { ROUTES, useIntercom } from '../../../utils'
import { Button, Dialog } from '../..'
import { StyledSupportDialog } from '.'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const SupportDialog: FC<Props> = ({ isOpen, onClose }) => {
  const { showIntercomMessenger } = useIntercom()

  const handleShowMessenger = () => {
    showIntercomMessenger()
    onClose()
  }

  return (
    <Dialog isOpen={isOpen} hasXIcon onClose={() => onClose()}>
      <StyledSupportDialog>
        <div className="support-heading">
          <LifebuoyIcon />
          <span className="support-title">Need some help?</span>
          <span className="support-subtitle">Let’s see what we can do.</span>
        </div>
        <div className="support-actions">
          <a href={ROUTES.community}>
            <Button variant="outline" iconPos="right" isFullWidth>
              Ask the community <ArrowTopRightOnSquareIcon />
            </Button>
          </a>
          <a href={ROUTES.external.helpCenterUrl} target="_blank" rel="noopener noreferer">
            <Button variant="outline" iconPos="right" isFullWidth>
              Resource center <ArrowTopRightOnSquareIcon />
            </Button>
          </a>
          <Button
            variant="outline"
            iconPos="right"
            className="intercom-btn"
            id="intercom_btn"
            isFullWidth
            onClick={() => handleShowMessenger()}
          >
            Chat with us <IntercomIcon />
          </Button>
        </div>
      </StyledSupportDialog>
    </Dialog>
  )
}

export default SupportDialog
