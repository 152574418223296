import { FC } from 'react'
import { useEditor } from '@layerhub-io/react'
import { ReactComponent as DuplicateIcon } from '../../../assets/duplicate-icon.svg'
import { DesignerToolButton } from '.'

const DesignerDuplicateTool: FC = () => {
  const editor = useEditor()

  return (
    <DesignerToolButton data-tooltip="Duplicate" isIcon onClick={() => editor.objects.clone()}>
      <DuplicateIcon />
    </DesignerToolButton>
  )
}

export default DesignerDuplicateTool
