import styled from 'styled-components'

const StyledSpacingPopover = styled.div`
  padding: 20px 24px 24px 24px;
  width: 200px;
  display: grid;
  grid-gap: 20px;
`

export default StyledSpacingPopover
