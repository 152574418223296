import { gql } from '@apollo/client'

export const userLessonUpsertOne = gql`
  mutation UserLessonUpsertOne(
    $lesson: String
    $course: String
    $isCompleted: Boolean
    $isSkipped: Boolean
  ) {
    userLessonUpsertOne(
      record: {
        lesson: $lesson
        course: $course
        isCompleted: $isCompleted
        isSkipped: $isSkipped
      }
    )
  }
`
