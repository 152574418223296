import { FC } from 'react'
import { times } from 'lodash'
import { DateTime } from 'luxon'
import { useDndContext } from '@dnd-kit/core'
import { useAppSelector } from '../../../../redux'
import { CalendarWeekHeading } from '..'
import { StyledCalendarWeekHeadingRow } from '.'

const CalendarWeekHeadingRow: FC = () => {
  const { start, weeks, week } = useAppSelector((state) => state.calendarMobile)
  const { active } = useDndContext()

  return (
    <StyledCalendarWeekHeadingRow>
      {active?.id && (
        <div
          className="block"
          style={{
            minHeight: 1,
            background: 'gainsboro',
            width: `calc((100vw) * ${week})`
          }}
        />
      )}
      <div className="header">
        {times(7, (num) => {
          return <CalendarWeekHeading day={DateTime.fromISO(start).plus({ days: num })} key={num} />
        })}
      </div>
      {/* {times(weeks, (x) => {
        if (active?.id && x !== week) {
          return null
        }

        return (
          <div className="header" id={`week-${x}`} key={`week-${x}`}>
            {times(7, (num) => {
              return (
                <CalendarWeekHeading
                  day={DateTime.fromISO(start).plus({ weeks: x }).plus({ days: num })}
                  key={num}
                />
              )
            })}
          </div>
        )
      })} */}
    </StyledCalendarWeekHeadingRow>
  )
}

export default CalendarWeekHeadingRow
