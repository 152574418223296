import { xor } from 'lodash'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type InitialState = {
  productTest: {
    _id: string
    title: string
    tasks: {
      label: string
      steps?: {
        label: string
        isSub?: boolean
      }[]
    }[]
    form: {
      url: string
      keys: {
        FULL_NAME: string
        EMAIL: string
        SCREEN_RECORDING: string
        USER_ID: string
      }
    }
    completed: string[]
  }
  recordingUrl?: string
  showTasks: boolean
  step: 'tasks' | 'feedback' | 'done'
}

const initialState: InitialState = {
  productTest: {
    _id: '',
    title: '',
    form: {
      url: '',
      keys: {
        FULL_NAME: '',
        EMAIL: '',
        SCREEN_RECORDING: '',
        USER_ID: ''
      }
    },
    tasks: [],
    completed: []
  },
  showTasks: false,
  step: 'tasks'
}

export const productTestingSlice = createSlice({
  name: 'productTesting',
  initialState,
  reducers: {
    setProductTest: (
      state,
      action: PayloadAction<Omit<InitialState['productTest'], 'completed'>>
    ) => {
      state.productTest = {
        ...state.productTest,
        ...action.payload
      }
    },
    setShowProductTestTasks: (state, action: PayloadAction<InitialState['showTasks']>) => {
      state.showTasks = action.payload
    },
    toggleProductTestTaskComplete: (state, action: PayloadAction<string>) => {
      state.productTest.completed = xor(state.productTest.completed, [action.payload])
    },
    setProductTestStep: (state, action: PayloadAction<InitialState['step']>) => {
      state.step = action.payload
    },
    setProductTestRecordingUrl: (state, action: PayloadAction<string>) => {
      state.recordingUrl = action.payload
    }
  }
})

export const {
  setProductTest,
  setShowProductTestTasks,
  toggleProductTestTaskComplete,
  setProductTestStep,
  setProductTestRecordingUrl
} = productTestingSlice.actions
export default productTestingSlice.reducer
