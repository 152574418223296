import { FC, ReactNode } from 'react'
import { StyledTabs, Tab } from '.'

type Props = {
  tabs: {
    label: string
    icon?: ReactNode
    path?: string
    amount?: number
    tourId?: string
    isDisabled?: boolean
    isActive?: boolean
    isExact?: boolean
    onClick?: () => void
  }[]
  children?: ReactNode
}

const Tabs: FC<Props> = ({ tabs, children }) => {
  return (
    <StyledTabs className="tabs">
      <div className="tab-list">
        <div className="tab-scroll">
          {tabs.map((tab, key) => {
            return (
              <Tab
                label={tab.label}
                icon={tab.icon}
                path={tab.path}
                amount={tab.amount}
                tourId={tab.tourId}
                isDisabled={tab?.isDisabled}
                isActive={tab?.isActive}
                isExact={tab?.isExact}
                onClick={tab.onClick}
                key={key}
              />
            )
          })}
        </div>
        {children && <div className="tab-list-extra">{children}</div>}
      </div>
    </StyledTabs>
  )
}

export default Tabs
