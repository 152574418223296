import { gql } from '@apollo/client'

export const loadFilesFromCloud = gql`
  query LoadFilesFromCloud(
    $search: String
    $sort: String
    $types: [String]
    $parent: String
    $limit: Int
    $page: Int
  ) {
    loadFilesFromCloud(
      search: $search
      sort: $sort
      types: $types
      parent: $parent
      limit: $limit
      page: $page
    ) {
      docs {
        _id
        name
        file {
          url
          preview
          path
          metadata
          type
          ext
          size
          dimensions {
            x
            y
          }
        }
        fileCount
        ref
        parent
        type
        createdAt
        updatedAt
      }
      totalDocs
      usage
    }
  }
`
