import { FC, useEffect, useState } from 'react'
import InfiniteCalendar from 'react-infinite-calendar'
import Sheet from 'react-modal-sheet'
import { DateTime } from 'luxon'
import { ClockIcon } from '../../../../assets'
import { useAppSelector } from '../../../../redux'
import { useHaptics } from '../../../../utils'
import { StyledDateInputSheet } from '.'

type Props = {
  value?: string
  isOpen: boolean
  onClose: () => void
  onConfirm: (date?: string) => void
}

const DateInputSheet: FC<Props> = ({ value, isOpen, onClose, onConfirm }) => {
  const [inputDay, setInputDay] = useState<DateTime>()
  const [inputTime, setInputTime] = useState<DateTime>()
  const [showCal, setShowCal] = useState(false)
  const { post } = useAppSelector((state) => state.create)
  const { trigger } = useHaptics()

  const handleConfirm = () => {
    if (!inputDay || !inputTime) {
      return
    }

    const clean = DateTime.now().startOf('year')
    const year = inputDay.year
    const month = inputDay.month
    const day = inputDay.day

    const hour = inputTime.hour
    const minute = inputTime.minute

    const combined = clean.set({
      year,
      month,
      day,
      hour,
      minute
    })

    onConfirm(combined.toISO() || undefined)
    onClose()
  }

  const handleTimeChange = (value: string) => {
    setInputTime(DateTime.fromFormat(value, 'HH:mm'))
  }

  const handleDayChange = (value: Date) => {
    setInputDay(DateTime.fromJSDate(value))
  }

  const getButtonLabel = () => {
    if (!inputDay && !!post?.postDate) {
      return 'Unschedule Post'
    }

    if (post?.postDate) {
      return 'Reschedule Post'
    }

    return <>Schedule {!!post?.socialProfiles?.length ? 'Auto-Post' : 'Reminder'}</>
  }

  useEffect(() => {
    if (!isOpen) {
      setShowCal(false)
      return
    }

    setTimeout(() => {
      setShowCal(true)
    }, 150)
  }, [isOpen])

  useEffect(() => {
    if (isOpen) {
      trigger('impact-light')
    }

    return () => {
      trigger('impact-light')
    }
  }, [isOpen])

  useEffect(() => {
    return () => {
      trigger('impact-light')
    }
  }, [inputDay])

  useEffect(() => {
    if (!value) {
      return
    }

    const date = DateTime.fromISO(value)
    setInputDay(date)
    setInputTime(date)
  }, [value])

  return (
    <Sheet detent="content-height" isOpen={isOpen} onClose={onClose}>
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content disableDrag>
          <StyledDateInputSheet>
            <div className="cal-container">
              {showCal && (
                <InfiniteCalendar
                  selected={inputDay?.toJSDate()}
                  onSelect={(date: Date) => handleDayChange(date)}
                  height={300}
                />
              )}
            </div>
            <div className="bottom-container">
              <div className="time-input-container">
                <ClockIcon />
                {!inputTime && <span className="placeholder">Pick a time</span>}
                <input
                  type="time"
                  className="time-input"
                  value={inputTime?.toFormat('HH:mm:ss')}
                  onChange={(e) => handleTimeChange(e.target.value)}
                />
              </div>
              <div className="action-row">
                <button onClick={() => onClose()}>Cancel</button>
                <button
                  className="submit-btn"
                  disabled={!inputDay || !inputTime}
                  onClick={() => handleConfirm()}
                >
                  {getButtonLabel()}
                </button>
              </div>
            </div>
          </StyledDateInputSheet>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={() => onClose()} />
    </Sheet>
  )
}

export default DateInputSheet
