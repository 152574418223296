import styled from 'styled-components'

const StyledCreateEditorErrors = styled.div`
  background-color: ${({ theme }) => theme.color.red[100]};
  color: ${({ theme }) => theme.color.red[800]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  width: 100%;
  padding: 0 8px;
  border-radius: 12px;
  box-sizing: border-box;
  margin-bottom: 10px;
  user-select: none;

  .post-editor-errors-group {
    display: flex;
    align-items: center;
    font-weight: 600;

    .post-editor-errors-icon {
      width: 30px;
      margin-right: 10px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.color.background};
      box-shadow: 0 0 0 1px rgb(0, 0, 0, 0.05), 0 1px 3px rgb(0, 0, 0, 0.1);
      color: ${({ theme }) => theme.color.red[800]};

      svg {
        height: 24px;
      }
    }

    span {
      position: relative;
      top: 1px;
    }
  }

  .error-btn {
    background-color: ${({ theme }) => theme.color.red[800]};
    color: ${({ theme }) => theme.color.background};

    &:hover {
      background-color: ${({ theme }) => theme.color.red[900]};
    }
  }
`

export default StyledCreateEditorErrors
