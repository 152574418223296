import styled from 'styled-components'

type StyledProps = {
  isDisabled?: boolean
}

const StyledTab = styled.div<StyledProps>`
  display: flex;
  user-select: none;
  flex-shrink: 0;
  background-color: transparent;

  a,
  button {
    display: flex;
    align-items: center;
    text-decoration: none;
    position: relative;
    font-weight: 500;
    padding: 0;
    background-color: transparent;
    transition: 0.2s color, 0.2s background-color;
    color: ${({ theme }) => theme.color.gray[500]};

    .tab-flag {
      font-size: 14px;
      margin-left: 8px;
      height: 20px;
      border-radius: 50rem;
      padding: 0 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 23px;
      box-sizing: border-box;
      color: ${({ theme }) => theme.color.gray[500]};
      background-color: ${({ theme }) => theme.color.gray[200]};
      border: 1px solid ${({ theme }) => theme.color.gray[200]};
    }

    svg {
      height: 24px;
      margin-right: 6px;

      path {
        stroke-width: 1.75px;
      }
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0px;
      height: 2px;
      left: 0;
      right: 0;
      width: 100%;
      transition: 0.2s background-color;
    }

    &:hover {
      color: ${({ theme }) => theme.color.gray[600]};

      &::after {
        background-color: ${({ theme }) => theme.color.gray[300]};
      }
    }

    &.active {
      color: ${({ theme }) => theme.color.gray[800]};

      .tab-flag {
        color: ${({ theme }) => theme.color.gray[600]};
        background-color: ${({ theme }) => theme.color.background};
        border: 1px solid ${({ theme }) => theme.color.gray[300]};
      }

      &::after {
        background-color: ${({ theme }) => theme.color.gray[800]};
      }
    }
  }

  &:not(:last-child) {
    margin-right: 20px;
  }

  &:hover {
    cursor: pointer;
  }

  ${({ isDisabled }) =>
    isDisabled &&
    `
  button, a {
    pointer-events: none;
    opacity: 0.6;
  }

  &:hover {
    cursor: not-allowed;
  }
  `}
`

export default StyledTab
