import styled from 'styled-components'

const StyledRecentPostsPopover = styled.div`
  width: 300px;
  padding: 12px;
  display: grid;
  grid-gap: 12px;
  box-sizing: border-box;
  max-height: 300px;
  overflow-y: auto;
`

export default StyledRecentPostsPopover
