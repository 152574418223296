import { FC, useContext } from 'react'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useAppSelector } from '../../../redux'
import {
  getPublishString,
  hydrateSocialProfiles,
  useCreate,
  usePostTodos,
  userStore
} from '../../../utils'
import { Button } from '../..'
import { CreateBarPanel } from '..'
import { StyledCreateBarTasks } from '.'

const CreateBarTasks: FC = () => {
  const { post } = useAppSelector((state) => state.create)
  const { user } = useContext(userStore)
  const { completedTodos } = usePostTodos(post)
  const { newPost } = useCreate()

  const TODOS = [
    {
      id: 'create_post',
      label: 'Start a new post'
    },
    {
      id: 'add_media',
      label: 'Add a photo or video'
    },
    {
      id: 'add_caption',
      label: 'Write a caption'
    },
    {
      id: 'link_profiles',
      label: 'Attach a social profile'
    },
    {
      id: 'add_title',
      label: 'Title your post'
    },
    {
      id: 'add_date',
      label: 'Schedule at a date and time'
    }
  ]

  return (
    <CreateBarPanel>
      <StyledCreateBarTasks>
        {completedTodos.length === TODOS.length && (
          <div className="todo-completed-container">
            <div className="todo-completed">
              <div className="icon">
                <CheckIcon />
              </div>
              <span className="title">You're all finished</span>
              <span className="body">
                {getPublishString(
                  hydrateSocialProfiles(post?.socialProfiles, user),
                  post?.postDate
                )}
              </span>
              <Button variant="emphasis" isFullWidth onClick={() => newPost()}>
                Start a new post
              </Button>
            </div>
          </div>
        )}
        {completedTodos.length !== TODOS.length && (
          <div className="todo-list">
            {TODOS.map((todo) => {
              const isCompleted = completedTodos.includes(todo.id as any)
              return (
                <div className="todo" key={todo.id} aria-selected={isCompleted}>
                  <div className="check-icon">{isCompleted ? <CheckIcon /> : <XMarkIcon />}</div>
                  <span className="label">{todo.label}</span>
                </div>
              )
            })}
          </div>
        )}
      </StyledCreateBarTasks>
    </CreateBarPanel>
  )
}

export default CreateBarTasks
