import { useHistory } from 'react-router-dom'
import { ParsedQuery, stringify } from 'query-string'
import { Browser } from '@capacitor/browser'
import { MOBILE_ROUTES } from '../../mobile/utils'
import { setContentTab, setShowCreate, useAppDispatch } from '../../redux'
import { ROUTES } from '../routes'

export const useHandleRoute = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()

  const handleRoute = (url: string, params: ParsedQuery<string>) => {
    if (url.includes('community.')) {
      return Browser.open({
        url,
        presentationStyle: 'fullscreen'
      })
    }
    if (params.oobCode) {
      return history.push({
        pathname: ROUTES.auth.resetPassword,
        search: `?${stringify(params)}`
      })
    }
    if (url.includes('calendar')) {
      return history.push(MOBILE_ROUTES.calendar)
    }
    if (url.includes('/uploads')) {
      dispatch(setContentTab('uploads'))
      return history.push(MOBILE_ROUTES.content)
    }
    if (url.includes('/content/photos')) {
      dispatch(setContentTab('photos'))
      return history.push(MOBILE_ROUTES.content)
    }
    if (url.includes('/user')) {
      return history.push(MOBILE_ROUTES.account.main)
    }
    if (url.includes('/create')) {
      dispatch(setShowCreate(true))
      return history.push(MOBILE_ROUTES.home)
    }
  }

  return {
    handleRoute
  }
}
