import { FC, useState } from 'react'
import { DateTime } from 'luxon'
import {
  CalendarIcon,
  ChatIcon,
  ContentIcon,
  EllipsisCircleIcon,
  HomeIcon
} from '../../../../assets'
import { setShowChat, useAppDispatch, useAppSelector } from '../../../../redux'
import { MOBILE_ROUTES } from '../../../utils'
import { Fab, MenuSheet } from '../..'
import { NavButton } from '..'
import { StyledNav } from '.'

const Nav: FC = () => {
  const dispatch = useAppDispatch()
  const [showMenu, setShowMenu] = useState(false)
  const { showChat } = useAppSelector((state) => state.state)

  return (
    <StyledNav>
      <div className="nav">
        <NavButton to={MOBILE_ROUTES.home} exact>
          <HomeIcon />
        </NavButton>
        <NavButton to={MOBILE_ROUTES.calendar}>
          <span className="calendar-text">{DateTime.now().day}</span>
          <CalendarIcon />
        </NavButton>
        <NavButton active={showChat} onClick={() => dispatch(setShowChat(true))}>
          <ChatIcon />
        </NavButton>
        <NavButton to={MOBILE_ROUTES.content} exact>
          <ContentIcon />
        </NavButton>
        <NavButton active={false} onClick={() => setShowMenu(true)}>
          <EllipsisCircleIcon />
        </NavButton>
      </div>
      <Fab />
      <MenuSheet isOpen={showMenu} onClose={() => setShowMenu(false)} />
    </StyledNav>
  )
}

export default Nav
