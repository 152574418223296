import { FC, useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { parse } from 'query-string'
import { ConnectionPlatformOverview, ConnectionSelect, connectionStore } from '..'

const ConnectionDialogContent: FC = () => {
  const location = useLocation()
  const { platformToConnect, availableProfiles, handleConnectAccountsFromState } =
    useContext(connectionStore)

  // useEffect(() => {
  //   const parsed = parse(location.search)
  //   handleConnectAccountsFromState(parsed)
  // }, [location.search])

  if (availableProfiles.length) {
    return <ConnectionSelect />
  }

  if (platformToConnect) {
    return <ConnectionPlatformOverview />
  }

  return null
}

export default ConnectionDialogContent
