import styled from 'styled-components'

const StyledStepList = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.gray[300]};

  .step-list-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px 20px 10px 20px;

    .step-list-header {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 12px;
      color: ${({ theme }) => theme.color.gray[900]};

      .step-list-icon {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        color: ${({ theme }) => theme.color.brand};
        background-color: ${({ theme }) => theme.color.brandLight};

        svg {
          height: 16px;
        }
      }
    }

    .step-progress {
      .step-progress-header {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        justify-content: space-between;

        .step-progress-label {
          font-weight: 500;
          text-transform: capitalize;
          color: ${({ theme }) => theme.color.gray[800]};
        }

        .btn {
          width: 20px;
          height: 20px;
          margin: -2px;
          background-color: transparent;
          color: ${({ theme }) => theme.color.gray[400]};
          // display: none;

          &:hover {
            color: ${({ theme }) => theme.color.gray[500]};
          }
        }
      }

      .step-progress-bar {
        display: flex;
        align-items: center;
        // margin: 6px 0;

        .step-progress-count {
          font-size: 15px;
          margin-left: 10px;
          color: ${({ theme }) => theme.color.gray[600]};
        }

        progress {
          width: 100%;
          border-radius: 50rem;
          overflow: hidden;
          height: 8px;
        }

        progress::-webkit-progress-bar {
          background-color: ${({ theme }) => theme.color.gray[200]};
          border-radius: 50rem;
        }

        progress::-webkit-progress-value {
          background-color: ${({ theme }) => theme.color.brand};
          transition: 0.2s;
        }
      }

      .step-progress-undertext {
        font-size: 15px;
        margin-top: 10px;
        display: block;
        color: ${({ theme }) => theme.color.gray[600]};

        b {
          font-weight: 500;
          text-transform: capitalize;
          color: ${({ theme }) => theme.color.gray[700]};
        }
      }
    }

    .step-cards {
      margin-top: 20px;
      position: relative;

      .step-cards-skeleton {
        display: flex;
        overflow-x: auto;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }

  .react-horizontal-scrolling-menu--separator {
    width: 8px;
    flex-shrink: 0;
  }

  .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
  }

  .react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .arrow-btn {
    height: 36px;
    width: 36px;
    position: absolute;
    background-color: rgb(0, 0, 0, 0.55);
    color: #fff;
    border: 0;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 4;

    svg {
      path {
        stroke-width: 2.25px;
      }
    }

    &.arrow-left {
      left: -18px;
    }

    &.arrow-right {
      right: -18px;
    }

    &:hover {
      background-color: rgb(0, 0, 0, 0.8);
    }
  }
`

export default StyledStepList
