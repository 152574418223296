import { uniq } from 'lodash'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type Dialogs = 'go-pro' | 'publish' | 'support' | 'media-by-url'

type InitialState = {
  stack: Dialogs[]
  curr?: Dialogs
}

const initialState: InitialState = {
  stack: [],
  curr: undefined
}

export const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    pushToDialogStack: (state, action: PayloadAction<Dialogs>) => {
      state.stack = uniq([...state.stack, action.payload])
      state.curr = action.payload
    },
    removeFromDialogStack: (state, action: PayloadAction<Dialogs>) => {
      const newStack = state.stack.filter((dialog) => dialog !== action.payload)
      state.stack = newStack
      state.curr = newStack[0]
    }
  }
})

export const { pushToDialogStack, removeFromDialogStack } = dialogSlice.actions
export default dialogSlice.reducer
