import { FC, createRef, useContext, useEffect, useState } from 'react'
import { compact, uniqBy } from 'lodash'
import { DateTime } from 'luxon'
import { useDraggable } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import {
  BellIcon,
  CheckIcon,
  ExclamationCircleIcon,
  PaperClipIcon
} from '@heroicons/react/20/solid'
import { PhotoIcon } from '@heroicons/react/24/outline'
import { ReactComponent as EditorIcon } from '../../../assets/editor-icon.svg'
import { useAppSelector } from '../../../redux'
import { CalendarEventType, PostType, SocialProfileType } from '../../../types'
import {
  getPlatformIcon,
  getPostStatus,
  getSocialProfileIcon,
  socialProfileById,
  useCreate,
  usePost,
  usePostErrors,
  userStore
} from '../../../utils'
import { Avatar, Button, CloudMediaPreview, ConnectionAvatar, DateTimeInput } from '../..'
import { StyledCalendarPostItem } from '.'

type Props = {
  post: PostType
  event?: CalendarEventType
  parent?: string
  isCollapsed?: boolean
}

const CalendarPostItem: FC<Props> = ({ post, event, parent, isCollapsed }) => {
  const { user } = useContext(userStore)
  const { errors } = usePostErrors(post)
  const { post: editorPost } = useAppSelector((state) => state.create)
  const { setEditorPost } = useCreate()
  const { updatePostData } = usePost()
  const { attributes, isDragging, listeners, setNodeRef, transform } = useDraggable({
    id: post._id,
    data: {
      post: post,
      parent: parent,
      event: event
    }
  })
  const [showDateInput, setShowDateInput] = useState(false)
  const [profiles, setProfiles] = useState<SocialProfileType[]>([])
  const dateBtnRef = createRef<HTMLDivElement>()

  const handleChangeDate = async (date?: Date) => {
    const newDate = date ? date?.toISOString() : null
    const newPostObject = {
      ...post,
      postDate: newDate
    }

    await updatePostData(newPostObject)
  }

  const hydrateProfiles = () => {
    const hydratedProfiles = post?.socialProfiles?.map((profile) => {
      return socialProfileById(profile || '', user?.socialProfiles || [])
    })
    setProfiles(compact(hydratedProfiles || []))
  }

  const style = {
    transform: CSS.Translate.toString(transform)
  }

  useEffect(() => {
    hydrateProfiles()
  }, [user, post.socialProfiles])

  const Preview = (
    <div className="post-preview" onClick={() => setEditorPost(post)}>
      <div className="post-chips">
        {!!errors.length && (
          <div className="post-chip icon error">
            <ExclamationCircleIcon />
          </div>
        )}
        <div className="post-chip status">{getPostStatus(post)}</div>
      </div>
      {(post.media || [])?.length > 1 && (
        <div className="post-media-length">1 of {post.media?.length}</div>
      )}
      {!!post.media?.length && (
        <CloudMediaPreview url={post.media[0].url} type={post.media[0].type} />
      )}
      {!!post.captionText?.trim() && !post.media?.length && (
        <div className="post-layout-preview">
          <span className="post-caption-preview">{post.captionText}</span>
        </div>
      )}
      {!post.captionText?.trim() && !post.media?.length && <PaperClipIcon className="empty-icon" />}
    </div>
  )

  const Collapsed = (
    <>
      <div className="collapsed-post">
        {Preview}
        <div className="post-details">
          <span className="post-title">{post.title || 'Untitled Post'}</span>
          <div
            className="post-time"
            role="button"
            ref={dateBtnRef}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setShowDateInput(!showDateInput)
            }}
          >
            {DateTime.fromISO(post.postDate).toFormat('t')?.replace(' ', '')}
          </div>
        </div>
      </div>
    </>
  )
  const Expanded = (
    <>
      {Preview}
      <div className="post-details-container">
        <span className="post-title">{post.title || 'Untitled Post'}</span>
        <div className="post-time-container">
          <div
            className="post-time"
            role="button"
            ref={dateBtnRef}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setShowDateInput(!showDateInput)
            }}
          >
            {DateTime.fromISO(post.postDate).toFormat('t')?.replace(' ', '')}
          </div>
        </div>
      </div>
      {!!profiles.length && (
        <div className="post-platform-details">
          <div className="post-profiles">
            {profiles.map((profile) => {
              return <ConnectionAvatar size={35} profile={profile} inRow />
            })}
          </div>
          {!!uniqBy(profiles, 'type').length && (
            <div className="post-platforms">
              {uniqBy(profiles, 'type').map((profile) => {
                return getPlatformIcon(profile.type)
              })}
            </div>
          )}
        </div>
      )}
    </>
  )

  return (
    <>
      <StyledCalendarPostItem
        transform={transform}
        isCollapsed={isCollapsed}
        isDragging={isDragging}
        isActive={editorPost?._id === post._id}
        ref={setNodeRef}
        style={style}
        {...listeners}
        {...attributes}
        onClick={() => setEditorPost(post)}
      >
        {isCollapsed && Collapsed}
        {!isCollapsed && Expanded}
      </StyledCalendarPostItem>
      {showDateInput && (
        <DateTimeInput
          value={
            typeof post.postDate === 'string'
              ? DateTime.fromISO(post.postDate).toJSDate()
              : post.postDate
          }
          buttonRef={dateBtnRef}
          onChange={(date) => handleChangeDate(date)}
          onClear={() => handleChangeDate(undefined)}
          onClose={() => setShowDateInput(false)}
        />
      )}
    </>
  )
}

export default CalendarPostItem
