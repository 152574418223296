import { FC, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
import { PagePreview } from '../../components'
import { PageType } from '../../types'
import { ROUTES } from '../../utils'
import { getPageByUsername, trackPageMetric } from '../../graphql'
import { StyledUserPageView } from '.'

const UserPageView: FC = () => {
  const apollo = useApolloClient()
  const history = useHistory()
  const { username }: any = useParams()
  const [page, setPage] = useState<PageType>()

  const checkPageExists = async () => {
    const { data } = await apollo.query({
      fetchPolicy: 'no-cache',
      query: getPageByUsername,
      variables: { username }
    })

    if (!data.getPageByUsername) {
      return history.push(ROUTES.auth.register)
    }

    setPage(data.getPageByUsername)
  }

  const trackPageView = async () => {
    if (!page) {
      return
    }

    await apollo.mutate({
      fetchPolicy: 'no-cache',
      mutation: trackPageMetric,
      variables: { page: page._id, type: 'view' }
    })
  }

  const trackLinkClick = async (block: string) => {
    if (!page) {
      return
    }

    await apollo.mutate({
      fetchPolicy: 'no-cache',
      mutation: trackPageMetric,
      variables: { page: page._id, type: 'click', block }
    })
  }

  useEffect(() => {
    checkPageExists()
  }, [username])

  useEffect(() => {
    trackPageView()
  }, [page])

  if (!page) {
    return null
  }

  return (
    <StyledUserPageView>
      <PagePreview page={page} onLinkClick={(link) => trackLinkClick(link)} />
    </StyledUserPageView>
  )
}

export default UserPageView
