import { setCalendarStart, setCalendarView, useAppDispatch, useAppSelector } from '../../redux'
import { getStartOfWeek, isMobile, parseDate } from '.'

export const useCalendar = () => {
  const dispatch = useAppDispatch()
  const { start, view } = useAppSelector((state) => state.calendar)

  const handleShift = (shift: 'prev' | 'next' | 'today') => {
    const timeToAdd = isMobile() ? { day: 2 } : { week: 1 }
    const startDate = parseDate(start)

    if (view === 'month' && shift === 'next') {
      return dispatch(setCalendarStart(startDate.plus({ month: 1 })))
    }

    if (view === 'month' && shift === 'prev') {
      return dispatch(setCalendarStart(startDate.minus({ month: 1 })))
    }

    if (shift === 'today') {
      return dispatch(setCalendarStart(getStartOfWeek()))
    }

    if (view === 'week' && shift === 'next') {
      return dispatch(setCalendarStart(startDate.plus(timeToAdd)))
    }

    if (view === 'week' && shift === 'prev') {
      return dispatch(setCalendarStart(startDate.minus(timeToAdd)))
    }
  }

  const changeView = (view: 'week' | 'month') => {
    dispatch(setCalendarView(view))
  }

  return {
    actions: {
      handleShift,
      changeView
    }
  }
}
