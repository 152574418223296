import { FC } from 'react'
import { Link } from 'react-router-dom'
import { ChatBubbleOvalLeftIcon, PlusIcon } from '@heroicons/react/24/outline'
import avatar1 from '../../../assets/avatar-1.png'
import avatar2 from '../../../assets/avatar-2.png'
import avatar3 from '../../../assets/avatar-3.png'
import avatar4 from '../../../assets/avatar-4.png'
import { ROUTES } from '../../../utils'
import { Avatar, Button } from '../..'
import { StyledCommunityCta } from '.'

const CommunityCta: FC = () => {
  return (
    <StyledCommunityCta>
      <div className="community-cta-avatars">
        <Avatar name="LF" image={avatar1} size={44} inRow />
        <Avatar name="OR" image={avatar2} size={44} inRow />
        <Avatar name="AM" image={avatar3} size={44} inRow />
        <Avatar name="JK" image={avatar4} size={44} inRow />
        <div className="community-cta-plus-avatar">
          <PlusIcon />
        </div>
      </div>
      <div className="community-cta-details">
        <span className="community-cta-title">
          Keep the conversation
          <br />
          alive in our community.
        </span>
        <span className="community-cta-body">
          Participate in vibrant conversations, share your wins and stay up to date on Social
          Curator's announcements.
        </span>
      </div>
      <Link to={ROUTES.community}>
        <Button variant="emphasis" iconPos="right" isFullWidth>
          Visit the Community <ChatBubbleOvalLeftIcon />
        </Button>
      </Link>
    </StyledCommunityCta>
  )
}

export default CommunityCta
