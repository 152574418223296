import { FC, createRef, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useLongPress } from 'use-long-press'
import { Capacitor } from '@capacitor/core'
import { Haptics, ImpactStyle } from '@capacitor/haptics'
import {
  CheckCircleIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  DocumentTextIcon,
  EllipsisHorizontalIcon,
  HeartIcon,
  PlusIcon
} from '@heroicons/react/24/outline'
import {
  CheckCircleIcon as SolidCheckCircleIcon,
  HeartIcon as SolidHeartIcon
} from '@heroicons/react/24/solid'
import { ReactComponent as ChatIcon } from '../../../assets/chat-icon.svg'
import { setCreateTab, updateCreatePost, useAppDispatch, useAppSelector } from '../../../redux'
import { CaptionType } from '../../../types'
import {
  ROUTES,
  animateAddItemToPost,
  conditionalStage,
  copyToClipboard,
  theme,
  useCaption,
  useChat,
  useSegment,
  userStore
} from '../../../utils'
import { Button, OptionsPopover, ReadMore, RenderSlate } from '../..'
import { StyledCaptionListItem } from '.'

type Props = {
  caption: CaptionType
  inActionPlan?: boolean
  isInline?: boolean
}

const CaptionListItem: FC<Props> = ({ caption, inActionPlan, isInline }) => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const captionRef = createRef<HTMLDivElement>()
  const optionsBtnRef = createRef<HTMLButtonElement>()
  const [showOptions, setShowOptions] = useState(false)
  const { user } = useContext(userStore)
  const { sendMessage } = useChat()
  const { toggleCaptionUsed, toggleCaptionFavorited, copyCaptionToClipboard } = useCaption()
  const { post } = useAppSelector((state) => state.create)
  const { track } = useSegment()

  const addCaptionToPost = async () => {
    animateAddItemToPost(captionRef)
    toggleCaptionUsed(caption)
    dispatch(updateCreatePost({ captionText: caption.captionPlainText || '' }))
    track('Added Caption to Post', { ...caption, post_id: post?._id })
  }

  const longPressBind = useLongPress(
    () => {
      if (!Capacitor.isNativePlatform()) {
        return
      }
      Haptics.impact({ style: ImpactStyle.Medium })
      setShowOptions(true)
    },
    {
      cancelOnMovement: true
    }
  )

  const openInChat = () => {
    dispatch(setCreateTab('chat'))
    toggleCaptionUsed(caption)
    sendMessage(caption.captionPlainText || '', 'blanks')
    history.push(ROUTES.create)
  }

  return (
    <>
      <StyledCaptionListItem
        className="caption-list-item"
        isUsed={!!caption.userCaption?.used}
        isLiked={!!caption.userCaption?.liked}
        ref={captionRef}
        data-tour-id="caption"
        {...longPressBind()}
      >
        {!inActionPlan && (
          <Button
            size="sm"
            className={`options-btn options-btn-${caption._id}`}
            isIcon
            ref={optionsBtnRef}
            onClick={() => setShowOptions(!showOptions)}
          >
            <EllipsisHorizontalIcon />
          </Button>
        )}
        <div className="caption-header">
          <span className="caption-title">{caption.title}</span>
          {(caption.category || !!caption.userCaption?.used) && (
            <span className="caption-category">
              {!caption.userCaption?.used ? caption.category : 'Used Caption'}
            </span>
          )}
        </div>
        {isInline && (
          <ReadMore>
            <span className="caption-text">
              <RenderSlate html={caption.caption} />
            </span>
          </ReadMore>
        )}
        {!isInline && (
          <span className="caption-text">
            <RenderSlate html={caption.caption} />
          </span>
        )}
        <div className="caption-actions">
          <div className="caption-action-group">
            <Button
              iconPos="left"
              size="sm"
              variant="outline"
              data-tour-id="caption-fill-btn"
              onClick={() => openInChat()}
            >
              <ChatIcon /> FILL
            </Button>
            <Button
              iconPos="left"
              size="sm"
              data-tour-id="caption-use-btn"
              onClick={() => addCaptionToPost()}
            >
              <PlusIcon /> USE
            </Button>
          </div>
          {!inActionPlan && (
            <Button
              className="fav-btn"
              size="sm"
              isIcon
              onClick={() => toggleCaptionFavorited(caption)}
            >
              {caption.userCaption?.liked ? (
                <SolidHeartIcon fill={theme.color.red[600]} />
              ) : (
                <HeartIcon />
              )}
            </Button>
          )}
        </div>
      </StyledCaptionListItem>
      <OptionsPopover
        options={[
          {
            icon: <PlusIcon />,
            label: 'Add to post',
            action: () => addCaptionToPost()
          },
          {
            icon: caption.userCaption?.liked ? (
              <SolidHeartIcon fill={theme.color.red[600]} />
            ) : (
              <HeartIcon />
            ),
            label: caption.userCaption?.liked ? 'Unfavorite' : 'Favorite',
            action: () => toggleCaptionFavorited(caption)
          },
          {
            icon: caption.userCaption?.used ? (
              <SolidCheckCircleIcon fill={theme.color.green[600]} />
            ) : (
              <CheckCircleIcon />
            ),
            label: `Mark as ${caption.userCaption?.used ? 'unused' : 'used'}`,
            action: () => toggleCaptionUsed(caption)
          },
          {
            icon: <DocumentDuplicateIcon />,
            label: 'Copy to clipboard',
            action: () => copyCaptionToClipboard(caption)
          },
          ...conditionalStage(user?.role === 'admin', [
            {
              icon: <DocumentDuplicateIcon />,
              label: 'Copy URL to caption',
              action: () => copyToClipboard(ROUTES.admin.captionById(caption._id))
            },
            {
              icon: <Cog6ToothIcon />,
              label: 'Open in Admin Panel',
              action: () =>
                (window.location.href = ROUTES.admin.editCaptionById(caption.issue, caption._id))
            },
            {
              icon: <ChatIcon />,
              label: 'Fill with Dottie',
              action: () => openInChat()
            }
          ])
        ]}
        divsAfter={['Copy to clipboard']}
        classToAvoid={`options-btn-${caption._id}`}
        buttonRef={optionsBtnRef}
        isOpen={showOptions}
        onClose={() => setShowOptions(false)}
      />
    </>
  )
}

export default CaptionListItem
