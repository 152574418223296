import { toast } from 'react-hot-toast'
import { useApolloClient } from '@apollo/client'
import { userHashtagCreateOne, userHashtagRemoveById } from '../../graphql'
import {
  userHashtagFolderRemoveById,
  userHashtagFolderUpsertOne
} from '../../graphql/UserHashtagFolder'
import {
  addCreatePostBit,
  addHashtag,
  addHashtagFolder,
  deleteHashtagFolder,
  removeHashtag,
  updateHashtag,
  updateHashtagFolder,
  useAppDispatch,
  useAppSelector
} from '../../redux'
import { HashtagFolderType, HashtagType } from '../../types'
import { isMobile, useSegment } from '.'

export const useHashtag = () => {
  const apollo = useApolloClient()
  const dispatch = useAppDispatch()
  const { post } = useAppSelector((state) => state.create)
  const { track } = useSegment()

  const createHashtag = async (hashtag: string, folder?: string) => {
    try {
      const { data } = await apollo.query({
        fetchPolicy: 'no-cache',
        query: userHashtagCreateOne,
        variables: { hashtag: hashtag.replace('#', '').trim(), folder }
      })

      dispatch(addHashtag(data.userHashtagCreateOne))
      track('Created Hashtag', data.userHashtagCreateOne)
      toast.success('Created hashtag')
    } catch (err) {
      console.log(err)
      toast.error((err as any).message)
    }
  }

  const deleteHashtag = async (hashtag: HashtagType) => {
    try {
      dispatch(removeHashtag(hashtag._id))

      await apollo.query({
        fetchPolicy: 'no-cache',
        query: userHashtagRemoveById,
        variables: { _id: hashtag._id }
      })

      track('Deleted Hashtag', hashtag)
      toast.success('Deleted hashtag')
    } catch (err) {
      console.log(err)
      toast.error((err as any).message)
    }
  }

  const updateHashtagData = async (hashtag: Partial<HashtagType>) => {
    try {
      dispatch(updateHashtag(hashtag))

      await apollo.query({
        fetchPolicy: 'no-cache',
        query: userHashtagCreateOne,
        variables: { ...hashtag }
      })
    } catch (err) {
      console.log(err)
      toast.error((err as any).message)
    }
  }

  const createHashtagFolder = async (label: string) => {
    try {
      const { data } = await apollo.query({
        fetchPolicy: 'no-cache',
        query: userHashtagFolderUpsertOne,
        variables: { label: label.trim() }
      })

      dispatch(
        addHashtagFolder({
          _id: data.userHashtagFolderUpsertOne._id,
          label: label.trim()
        })
      )

      track('Created Hashtag Folder', {
        _id: data.userHashtagFolderUpsertOne._id,
        label: label.trim()
      })
      toast.success('Created hashtag Folder')
    } catch (err) {
      console.log(err)
      toast.error((err as any).message)
    }
  }

  const removeHashtagFolder = async (_id: string) => {
    try {
      dispatch(deleteHashtagFolder(_id))

      await apollo.query({
        fetchPolicy: 'no-cache',
        query: userHashtagFolderRemoveById,
        variables: { _id }
      })

      track('Deleted Hashtag Folder', { _id })
      toast.success('Deleted hashtag Folder')
    } catch (err) {
      console.log(err)
      toast.error((err as any).message)
    }
  }

  const updateHashtagFolderData = async (folder: Partial<HashtagFolderType>) => {
    try {
      dispatch(updateHashtagFolder(folder))

      await apollo.query({
        fetchPolicy: 'no-cache',
        query: userHashtagFolderUpsertOne,
        variables: { ...folder }
      })
    } catch (err) {
      console.log(err)
      toast.error((err as any).message)
    }
  }

  const addHashtagToPost = async (hashtag: HashtagType) => {
    dispatch(addCreatePostBit(`#${hashtag.hashtag}`))

    track('Added Hashtag to Post', { ...hashtag, post_id: post?._id })

    if (isMobile()) {
      toast.success('Added hashtag to post')
    }
  }

  return {
    createHashtag,
    deleteHashtag,
    updateHashtagData,
    createHashtagFolder,
    removeHashtagFolder,
    updateHashtagFolderData,
    addHashtagToPost
  }
}
