import { FC, createRef, useContext, useEffect } from 'react'
import { DateTime } from 'luxon'
import { ArchiveBoxIcon, CheckCircleIcon } from '@heroicons/react/20/solid'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import {
  DocumentOutlineIcon,
  HashtagSquareOutlineIcon,
  VideoScriptOutlineIcon
} from '../../../../assets'
import { ChatConversationListItem } from '../../../../components'
import {
  setChatTemplate,
  setShowChatPrompts,
  useAppDispatch,
  useAppSelector
} from '../../../../redux'
import { userStore } from '../../../../utils'
import { ChatMessage } from '..'
import { StyledChatMessages } from '.'

const ChatMessages: FC = () => {
  const scrollRef = createRef<HTMLDivElement>()
  const dispatch = useAppDispatch()
  const { user } = useContext(userStore)
  const { messages, template } = useAppSelector((state) => state.chat)

  useEffect(() => {
    scrollRef.current?.scrollTo({
      top: 1000000
    })
  }, [scrollRef.current, messages.length])

  return (
    <StyledChatMessages ref={scrollRef}>
      <ChatMessage
        message={{
          _id: 'test',
          content: `Hey ${user?.displayName.split(' ')[0]}!`,
          conversation: 'test',
          createdAt: DateTime.now().toISO()!,
          type: 'received',
          prompt: '',
          status: 'error'
        }}
        key="Start message"
      />
      <ChatMessage
        message={{
          _id: 'test-2',
          content: `What do you want to do?`,
          conversation: 'test',
          createdAt: DateTime.now().toISO()!,
          type: 'received',
          prompt: '',
          status: 'error'
        }}
        isSub
        key="Start message x2"
      />
      {messages.map((message) => (
        <ChatMessage message={message as any} key={message._id} />
      ))}
      {!messages.length && (
        <div className="prompt-list">
          <button
            data-active={template === 'caption'}
            data-chat-onboarding="0"
            onClick={() => dispatch(setChatTemplate('caption'))}
          >
            {template !== 'caption' ? <DocumentOutlineIcon /> : <CheckCircleIcon />}
            Generate a caption
            <ChevronRightIcon className="chevron-icon" />
          </button>
          <button
            data-active={template === 'hashtags'}
            onClick={() => dispatch(setChatTemplate('hashtags'))}
          >
            {template !== 'hashtags' ? <HashtagSquareOutlineIcon /> : <CheckCircleIcon />}
            Recommend hashtags
            <ChevronRightIcon className="chevron-icon" />
          </button>
          <button
            data-active={template === 'video-script'}
            onClick={() => dispatch(setChatTemplate('video-script'))}
          >
            {template !== 'video-script' ? <VideoScriptOutlineIcon /> : <CheckCircleIcon />}
            Write a video script
            <ChevronRightIcon className="chevron-icon" />
          </button>
          <button className="all-prompts-btn" onClick={() => dispatch(setShowChatPrompts(true))}>
            <ArchiveBoxIcon />
            See all templates
          </button>
        </div>
      )}
    </StyledChatMessages>
  )
}

export default ChatMessages
