import { FC, RefObject, useEffect, useRef, useState } from 'react'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { createPortal } from 'react-dom'
import { DateTime } from 'luxon'
import { v4 as uuid } from 'uuid'
import { BoltIcon, CalendarIcon, SparklesIcon } from '@heroicons/react/20/solid'
import { updateCreatePost, useAppDispatch, useAppSelector } from '../../../redux'
import { formateTimeToPost, getPosition, getRelativeString, getTimesToPost } from '../../../utils'
import { DateTimeInput } from '../..'
import { StyledSchedulePopover } from '.'

type Props = {
  classToAvoid: string
  buttonRef: RefObject<HTMLButtonElement>
  isOpen: boolean
  onClose: () => void
}

const SchedulePopover: FC<Props> = ({ classToAvoid, buttonRef, isOpen, onClose }) => {
  const popoverId = useRef(uuid())
  const dispatch = useAppDispatch()
  const CLOSE_ANIM_LENGTH = 50
  const [suggestions, setSuggestions] = useState<DateTime[]>([])
  const [position, setPosition] = useState<{ top: number; left: number }>()
  const [enableClickDetection, setEnableClickDetection] = useState(false)
  const [showDateInput, setShowDateInput] = useState(false)
  const [showCloseAnim, setShowCloseAnim] = useState(false)
  const [inputDate, setInputDate] = useState<Date>()
  const { post } = useAppSelector((state) => state.create)

  const popoverRef = useDetectClickOutside({
    onTriggered: (e) => handleClose(e),
    disableClick: !enableClickDetection
  }) as RefObject<HTMLDivElement>

  const handleClose = (e?: Event) => {
    const target = e?.target as HTMLElement

    if (!!target?.closest(`.${classToAvoid}`)) {
      return
    }

    if (!!target?.closest(`[data-popover="${popoverId}"]`)) {
      return
    }

    setShowCloseAnim(true)
    setTimeout(() => onClose(), CLOSE_ANIM_LENGTH)
  }

  const handleSave = () => {
    const postDate = inputDate ? inputDate.toISOString() : null
    dispatch(updateCreatePost({ postDate }))
    handleClose()
  }

  const getButtonLabel = () => {
    if (!inputDate && !!post?.postDate) {
      return 'Unschedule Post'
    }

    if (post?.postDate) {
      return 'Reschedule Post'
    }

    return <>Schedule {!!post?.socialProfiles?.length ? 'Auto-Post' : 'Reminder'}</>
  }

  useEffect(() => {
    setShowDateInput(false)

    if (!post?.postDate) {
      return setInputDate(undefined)
    }

    if (typeof post.postDate === 'string') {
      return setInputDate(DateTime.fromISO(post.postDate).toJSDate())
    }

    setInputDate(post.postDate?.toJSDate())
  }, [post?.postDate, isOpen])

  useEffect(() => {
    const popover = popoverRef?.current
    const button = buttonRef?.current

    if (!popover || !button) {
      return
    }

    setPosition(getPosition('bottom-end', button, popover))
  }, [popoverRef, buttonRef])

  useEffect(() => {
    setShowCloseAnim(false)
  }, [isOpen])

  useEffect(() => {
    if (showDateInput) {
      setEnableClickDetection(false)
      return
    }

    setTimeout(() => {
      setEnableClickDetection(true)
    }, 100)
  }, [showDateInput])

  useEffect(() => {
    setSuggestions(getTimesToPost())
  }, [])

  if (!isOpen) {
    return null
  }

  const Element = (
    <StyledSchedulePopover
      data-popover={popoverId.current}
      style={{ ...position }}
      showCloseAnim={showCloseAnim}
      ref={popoverRef}
    >
      <div className="input-group">
        <span className="input-group-label">Schedule post ({DateTime.now().toFormat('ZZZZ')})</span>
        <button
          className="date-input-btn"
          data-empty={true}
          onClick={() => setShowDateInput(!showDateInput)}
        >
          {!inputDate && (
            <>
              <CalendarIcon /> Pick a date & time
            </>
          )}
          {!!inputDate && getRelativeString(inputDate.toISOString(), true)}
        </button>
      </div>
      <div className="input-group">
        <span className="input-group-label">
          <SparklesIcon /> Recommended times
        </span>
        {suggestions.map((time, index) => {
          return (
            <button
              className="date-suggestion-btn"
              key={time.toISO()}
              onClick={() => setInputDate(time.toJSDate())}
            >
              {index === 0 && <BoltIcon />}
              {formateTimeToPost(time)}
            </button>
          )
        })}
      </div>
      <button
        disabled={!inputDate && !post?.postDate}
        className="confirm-btn"
        onClick={() => handleSave()}
      >
        {getButtonLabel()}
      </button>
      {showDateInput && (
        <DateTimeInput
          value={inputDate}
          onChange={(date) => setInputDate(date)}
          onClose={() => setShowDateInput(false)}
          onClear={() => setInputDate(undefined)}
          className={classToAvoid}
        />
      )}
    </StyledSchedulePopover>
  )

  return createPortal(Element, document.getElementById('popover-root') as HTMLElement)
}

export default SchedulePopover
