import styled from 'styled-components'

type StyledProps = {
  isSquare?: boolean
  isRounded?: boolean
}

const StyledImageInput = styled.div<StyledProps>`
  padding: 12px;
  border-radius: ${({ isRounded }) => (isRounded ? '50%' : '4px')};
  height: 100px;
  overflow: hidden;
  ${({ isSquare }) => isSquare && 'width: 100px;'}
  background-color: ${({ theme }) => theme.color.gray[100]};
  box-shadow: inset 0 0 0 1px rgb(0, 0, 0, 0.05);
  transition: 0.2s;
  position: relative;

  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    object-fit: cover;
  }

  input {
    position: absolute;
    font-size: 0;
    cursor: pointer;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 140%;
    height: 140%;

    &:disabled:hover {
      cursor: not-allowed;
    }
  }

  .upload-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 48px;
    pointer-events: none;
    color: ${({ theme }) => theme.color.gray[500]};
    stroke-width: 1.1px;
  }

  .upload-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    pointer-events: none;
    height: 38px;
    font-weight: 500;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.color.gray[600]};
    padding: 0 14px;
    transform: translate(-50%, -50%);
    border-radius: 50rem;
    box-shadow: 0 0 0 1px rgb(0, 0, 0, 0.09);
    background-color: ${({ theme }) => theme.color.background};

    svg {
      height: 24px;
      stroke-width: 1.9px;
      margin-right: 6px;
    }

    &:disabled:hover {
      cursor: not-allowed;
    }
  }

  .delete-btn {
    position: absolute;
    right: 6px;
    bottom: 6px;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 0 1px rgb(0, 0, 0, 0.1), inset 0 0 0 1px rgb(255, 255, 255, 0.1);
    background-color: ${({ theme }) => theme.color.gray[800]};
    color: ${({ theme }) => theme.color.gray[100]};
    border-radius: 50%;
    transition: 0.2s;

    svg {
      height: 20px;
      stroke-width: 2px;
    }

    &:hover {
      background-color: ${({ theme }) => theme.color.gray[700]};
    }

    &:disabled:hover {
      cursor: not-allowed;
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.gray[200]};
    box-shadow: inset 0 0 0 1px rgb(0, 0, 0, 0.09);
  }
`

export default StyledImageInput
