import styled from 'styled-components'

const StyledPostGrid = styled.div`
  .post-grid-header {
    display: flex;
    margin-bottom: 20px;
    place-content: space-between;
    gap: 10px;
    position: relative;

    .search-btn {
      height: 24px;
      color: ${({ theme }) => theme.color.gray[500]};
      background-color: transparent;
      font-size: 15px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      font-weight: 500;
      transition: 0.2s;

      svg {
        height: 20px;
        stroke-width: 2px;
        margin-right: 6px;
      }

      &:hover {
        color: ${({ theme }) => theme.color.gray[800]};
      }
    }

    .post-grid-header-filters {
      display: flex;
    }

    .input:focus-within {
      position: absolute;
      transition: 0.2s;
      width: 100%;
      right: 0;
      left: 0;

      & + .post-grid-header-filters {
        display: none;
      }
    }
  }

  .post-grid-search-field {
    margin: -10px 0 20px 0;

    .input {
      position: relative;
      overflow: unset;
      border-radius: 8px;

      input {
        background-color: ${({ theme }) => theme.color.background};
        z-index: 1;
        border-radius: 8px;
      }

      svg {
        z-index: 2;
      }

      &::before {
        content: '';
        width: 16px;
        height: 16px;
        background-color: ${({ theme }) => theme.color.gray[300]};
        transform: rotate(45deg);
        left: 23px;
        top: -8px;
        position: absolute;
        border-top-left-radius: 5px;
        transition: background-color 0.2s;
      }

      &::after {
        content: '';
        width: 14px;
        height: 14px;
        background-color: ${({ theme }) => theme.color.background};
        transform: rotate(45deg);
        left: 24px;
        top: -7px;
        position: absolute;
        border-top-left-radius: 4px;
      }

      &:hover,
      &:focus-within {
        &::before {
          background-color: ${({ theme }) => theme.color.gray[400]};
        }
      }
    }
  }

  .empty-state {
    margin: 0 auto;
    display: grid;
    border-radius: 20px;
    padding: 40px;
    background-color: ${({ theme }) => theme.color.brandLight};
    justify-items: center;
    gap: 20px;

    img {
      width: 200px;
    }

    .empty-state-title {
      font-size: 30px;
      font-weight: 600;
      font-family: ${({ theme }) => theme.typeface.faktumXCon};
      color: ${({ theme }) => theme.color.gray[800]};
    }
  }
`

export default StyledPostGrid
