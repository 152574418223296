import { FC, useContext } from 'react'
import { matchPath, useHistory, useLocation } from 'react-router-dom'
import { Browser } from '@capacitor/browser'
import { Dialog } from '@capacitor/dialog'
import {
  BellOutlineIcon,
  BriefcaseOutlineIcon,
  CardOutlineIcon,
  ConnectionsOutlineIcon,
  EnvelopeOutlineIcon,
  JournalOutlineIcon,
  LockOutlineIcon,
  LogOutOutlineIcon,
  MoonOutlineIcon,
  QuestionMarkCircleOutlineIcon,
  StarCircleOutlineIcon,
  UserCircleOutlineIcon
} from '../../../assets'
import { setShowConnections, setTheme, useAppDispatch, useAppSelector } from '../../../redux'
import { ROUTES, useAccount, useIntercom } from '../../../utils'
import { AccountMenuItem, BusinessDrawer, ProfileDrawer } from '../../components'
import { MOBILE_ROUTES } from '../../utils'
import { StyledAccountView } from '.'

const AccountView: FC = () => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { theme } = useAppSelector((state) => state.state)
  const { showIntercomMessenger } = useIntercom()
  const { deleteAccount, logOut } = useAccount()

  return (
    <>
      <StyledAccountView>
        <div className="menu-group">
          <span className="menu-group-label">Account</span>
          <div className="menu-group-items">
            <AccountMenuItem
              icon={<UserCircleOutlineIcon />}
              label="Profile"
              onClick={() => history.push(MOBILE_ROUTES.account.profile)}
            />
            <AccountMenuItem
              icon={<BriefcaseOutlineIcon />}
              label="Business/brand"
              onClick={() => history.push(MOBILE_ROUTES.account.business)}
            />
            <AccountMenuItem
              icon={<CardOutlineIcon />}
              label="Billing"
              onClick={() =>
                Dialog.alert({
                  title: 'Unavailable in mobile app',
                  message:
                    'Visit Social Curator from your desktop to modify your billing information.'
                })
              }
            />
            <AccountMenuItem
              icon={<ConnectionsOutlineIcon />}
              label="Connections"
              onClick={() => dispatch(setShowConnections(true))}
            />
            {/* <AccountMenuItem icon={<EnvelopeOutlineIcon />} label="Submit feedback" /> */}
          </div>
        </div>
        <div className="menu-group">
          <span className="menu-group-label">Preferences</span>
          <div className="menu-group-items">
            {/* <AccountMenuItem
              icon={<BellOutlineIcon />}
              label="Allow notifications"
              isSelected={false}
            /> */}
            <AccountMenuItem
              icon={<MoonOutlineIcon />}
              label="Dark mode"
              isSelected={theme === 'dark'}
              onClick={() => dispatch(setTheme(theme === 'dark' ? 'light' : 'dark'))}
            />
          </div>
        </div>
        <div className="menu-group">
          <span className="menu-group-label">Legal</span>
          <div className="menu-group-items">
            <AccountMenuItem
              icon={<LockOutlineIcon />}
              label="Privacy policy"
              onClick={() => Browser.open({ url: MOBILE_ROUTES.external.privacyPolicy })}
            />
            <AccountMenuItem
              icon={<JournalOutlineIcon />}
              label="Terms of use"
              onClick={() => Browser.open({ url: MOBILE_ROUTES.external.termsOfService })}
            />
          </div>
        </div>
        <div className="menu-group">
          <div className="menu-group-items">
            <AccountMenuItem
              icon={<QuestionMarkCircleOutlineIcon />}
              label="Get support"
              onClick={() => showIntercomMessenger()}
            />
            <AccountMenuItem
              icon={<StarCircleOutlineIcon />}
              label="Resource center"
              onClick={() => {
                Browser.open({
                  url: MOBILE_ROUTES.external.resourceCenter,
                  presentationStyle: 'popover'
                })
              }}
            />
            <AccountMenuItem
              icon={<LogOutOutlineIcon style={{ left: '-2px' }} />}
              label="Log out"
              onClick={() => logOut()}
            />
          </div>
        </div>
        <div className="action-group">
          <button className="delete-account-btn" onClick={() => deleteAccount()}>
            Delete Social Curator account
          </button>
        </div>
      </StyledAccountView>
      <ProfileDrawer
        isOpen={!!matchPath(location.pathname, { path: MOBILE_ROUTES.account.profile })}
        onClose={() => history.push(MOBILE_ROUTES.account.main)}
      />
      <BusinessDrawer
        isOpen={!!matchPath(location.pathname, { path: MOBILE_ROUTES.account.business })}
        onClose={() => history.push(MOBILE_ROUTES.account.main)}
      />
    </>
  )
}

export default AccountView
