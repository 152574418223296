import { FC } from 'react'
import { useDetectClickOutside } from 'react-detect-click-outside'
import Sheet from 'react-modal-sheet'
import { setShowCreateHashtags, useAppDispatch, useAppSelector } from '../../../../redux'
import { HashtagBank } from '../..'
import { StyledCreateHashtags } from '.'

const CreateHashtags: FC = () => {
  const dispatch = useAppDispatch()
  const { showHashtags } = useAppSelector((state) => state.create)

  const handleClose = () => {
    document.getElementById('create-textarea')?.focus()
    dispatch(setShowCreateHashtags(false))
  }

  const handleClickOutside = (e?: Event) => {
    const clicked = e?.target as HTMLElement
    const actionBar = document.getElementById('create-action-bar')
    const textarea = document.getElementById('create-textarea')

    if (actionBar?.contains(clicked)) {
      return
    }

    if (clicked.tagName === 'TEXTAREA') {
      return
    }

    dispatch(setShowCreateHashtags(false))
  }

  const elementRef = useDetectClickOutside({ onTriggered: handleClickOutside })

  return (
    <Sheet
      disableDrag={true}
      detent="content-height"
      isOpen={showHashtags}
      onClose={() => handleClose()}
    >
      <Sheet.Container style={{ boxShadow: 'none', borderRadius: 0 }}>
        <Sheet.Content disableDrag={true}>
          <StyledCreateHashtags ref={elementRef}>
            <button className="close-btn" onClick={() => handleClose()}>
              <div />
            </button>
            <HashtagBank />
          </StyledCreateHashtags>
        </Sheet.Content>
      </Sheet.Container>
    </Sheet>
  )
}

export default CreateHashtags
