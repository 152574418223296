import styled from 'styled-components'

const StyledStoryList = styled.div`
  margin-top: 20px;

  .infinite-scroll {
    overflow: visible !important;
  }

  .story-list-grid {
    display: grid;
    grid-gap: 20px 14px;
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.m}px) {
    // margin-top: 40px;

    .story-list-grid {
      grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    }
  }
`

export default StyledStoryList
