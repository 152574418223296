import { FC } from 'react'
import { Helmet } from 'react-helmet'
import { CaptionList, CaptionsViewHeader, PageLayout } from '../../components'
import { StyledCaptionsView } from '.'

const CaptionsView: FC = () => {
  return (
    <PageLayout>
      <Helmet title="Caption Templates | Social Curator" />
      <StyledCaptionsView>
        <CaptionsViewHeader />
        <CaptionList />
      </StyledCaptionsView>
    </PageLayout>
  )
}

export default CaptionsView
