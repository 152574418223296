import styled, { keyframes } from 'styled-components'

const checkAnim = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`

const StyledCreateEditorSubConfig = styled.div`
  .label {
    cursor: default;
    user-select: none;
    margin-bottom: 12px;

    span {
      display: block;
    }

    .label-title {
      color: ${({ theme }) => theme.color.gray[800]};
      font-weight: 500;
    }

    .label-description {
      color: ${({ theme }) => theme.color.gray[500]};
      font-size: 14px;
      margin-top: 4px;
    }
  }

  .input {
    border-radius: 6px;
    height: 36px;
    width: 100%;
    border: 1px solid ${({ theme }) => theme.color.gray[300]};
    display: flex;
    overflow: hidden;
    transition: 0.2s;

    input {
      width: 100%;
      padding: 0 10px;
      font-size: 15px;
      box-sizing: border-box;
      background-color: transparent;
      color: ${({ theme }) => theme.color.gray[800]};

      &::placeholder {
        color: ${({ theme }) => theme.color.gray[400]};
      }
    }

    &:focus-within {
      border-color: ${({ theme }) => theme.color.gray[400]};
    }
  }

  .options {
    display: flex;
    align-items: center;

    .option {
      display: flex;
      align-items: center;
      position: relative;
      overflow: hidden;

      input {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 1;
      }

      .option-box {
        height: 22px;
        width: 22px;
        flex-shrink: 0;
        margin-right: 8px;
        position: relative;
      }

      .option-label {
        display: flex;
        align-items: center;
        font-size: 15px;
        color: ${({ theme }) => theme.color.gray[700]};

        svg {
          height: 18px;
          margin-left: 4px;
          color: ${({ theme }) => theme.color.gray[400]};
        }
      }

      &:not(:last-child) {
        margin-right: 18px;
      }

      &[data-type='radio'] {
        .option-box {
          border-radius: 50%;
          transition: 0.2s;
          box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.gray[300]};
        }

        &:hover,
        input:hover {
          cursor: pointer;

          .option-box {
            box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.gray[400]};
          }
        }

        input {
          &:checked + .option-box {
            box-shadow: inset 0 0 0 2px ${({ theme }) => theme.color.brand};

            &::after {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              height: 12px;
              width: 12px;
              border-radius: 50%;
              background-color: ${({ theme }) => theme.color.brand};
            }
          }
        }
      }

      &[data-type='checkbox'] {
        .option-box {
          border-radius: 4px;
          box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.gray[300]};
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            height: 16px;
            stroke-width: 2.5px;

            path {
              stroke-dasharray: 1000;
              stroke-dashoffset: 1000;
              animation: ${checkAnim} 15s linear forwards;
            }
          }
        }

        &:hover,
        input:hover {
          cursor: pointer;

          .option-box {
            box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.gray[400]};
          }
        }

        input {
          &:checked + .option-box {
            box-shadow: inset 0 0 0 50px ${({ theme }) => theme.color.brand};
          }
        }
      }
    }
  }
`

export default StyledCreateEditorSubConfig
