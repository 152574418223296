import { FC, createRef, useEffect, useState } from 'react'
import { sample } from 'lodash'
import { useCreate, useSegment } from '../../../utils'
import { EmojiPicker } from '../..'
import { StyledCreateEditorCaptionEmoji } from '.'

const CreateEditorCaptionEmoji: FC = () => {
  const EMOJIS = ['😃', '😂', '😊', '🥺', '🥳', '🤔']
  const emojiPickerBtnRef = createRef<HTMLButtonElement>()
  const { track } = useSegment()
  const { insertEmojiAtCursor } = useCreate()
  const [emoji, setEmoji] = useState(EMOJIS[0])
  const [mouseIn, setMouseIn] = useState(false)
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)

  const handleShowEmoji = () => {
    if (!showEmojiPicker) {
      track('Opened Emoji Picker')
    }

    setShowEmojiPicker(!showEmojiPicker)
  }

  useEffect(() => {
    if (!mouseIn || showEmojiPicker) {
      return
    }

    const emojiList = EMOJIS.filter((e) => e !== emoji)
    setEmoji(sample(emojiList)!)
  }, [showEmojiPicker, mouseIn])

  return (
    <>
      <StyledCreateEditorCaptionEmoji
        className="emoji-picker-btn"
        showEmojiPicker={showEmojiPicker}
        onMouseEnter={() => setMouseIn(true)}
        onMouseLeave={() => setMouseIn(false)}
        onClick={() => handleShowEmoji()}
        ref={emojiPickerBtnRef}
      >
        <span className="emoji">{emoji}</span>
      </StyledCreateEditorCaptionEmoji>
      <EmojiPicker
        buttonRef={emojiPickerBtnRef}
        classToAvoid="emoji-picker-btn"
        isOpen={showEmojiPicker}
        onSelect={(emoji) => insertEmojiAtCursor(emoji)}
        onClose={() => setShowEmojiPicker(false)}
      />
    </>
  )
}

export default CreateEditorCaptionEmoji
