import styled from 'styled-components'

type StyledProps = {
  variant?: 'outline' | 'fill' | 'shadow'
}

const StyledTextarea = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s;

  textarea {
    display: flex;
    width: 100%;
    padding: 10px 12px 12px 12px;
    box-sizing: border-box;
    line-height: 24px;
    min-height: 120px;
    max-height: 240px;
    resize: vertical;
    background-color: transparent;
    color: ${({ theme }) => theme.color.gray[600]};
    transition: color 0.2s;

    &::placeholder {
      pointer-events: none;
      user-select: none;
      color: ${({ theme }) => theme.color.gray[400]};
    }
  }

  .max-length-indicator {
    position: absolute;
    right: 10px;
    bottom: 10px;
    pointer-events: none;
    color: ${({ theme }) => theme.color.gray[500]};
    font-size: 14px;
  }

  svg {
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: ${({ theme }) => theme.color.gray[400]};

    path {
      stroke-width: 1.75px;
    }
  }
  
  ${({ variant, theme }) =>
    variant === 'outline' &&
    `
    background-color: ${theme.color.background};
    border: 1px solid ${theme.color.gray[300]};

    &:hover, &:focus-within {
      border: 1px solid ${theme.color.gray[400]};
    }
  `}
  
  ${({ variant, theme }) =>
    variant === 'fill' &&
    `
    background-color: ${theme.color.gray[100]};

    &:hover, &:focus-within {
      background-color: ${theme.color.gray[200]};
    }
  `}
  
  ${({ variant, theme }) =>
    variant === 'shadow' &&
    `
    background-color: ${theme.color.background};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    border: 1px solid ${theme.color.gray[300]};
    border-radius: 6px;

    input {
      color: ${theme.color.gray[800]};
    }

    &:hover, &:focus-within {
      border: 1px solid ${theme.color.gray[400]};
    }
  `}
`

export default StyledTextarea
