import { FC, ReactNode, RefObject, useEffect, useState } from 'react'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { createPortal } from 'react-dom'
import { PositionType } from '../../../../types'
import { getPosition, useHaptics } from '../../../../utils'
import { StyledPullDown } from '.'

type ButtonType = {
  label: string | ReactNode
  icon?: ReactNode
  variant?: 'default' | 'destructive'
  type: 'button'
  stayOpen?: boolean
  action?: () => void
}

type DividerType = {
  type: 'divider'
}

type RowType = ButtonType | DividerType

type Props = {
  rows: RowType[]
  position?: PositionType
  targetRef: RefObject<HTMLElement>
  isOpen: boolean
  onClose: () => void
}

const PullDown: FC<Props> = ({ rows, position = 'bottom-start', targetRef, isOpen, onClose }) => {
  const [xy, setXY] = useState<{ top: number; left: number }>()
  const { trigger } = useHaptics()

  const handleClickOutside = (e?: Event) => {
    const clicked = e?.target as HTMLElement
    const target = targetRef?.current

    if (!clicked || !target) {
      return
    }

    if (target.contains(clicked)) {
      return
    }

    e?.stopImmediatePropagation()
    e?.preventDefault()
    e?.stopPropagation()

    onClose()
  }

  const elementRef = useDetectClickOutside({ onTriggered: handleClickOutside })

  const positionHint = () => {
    const element = elementRef?.current
    const target = targetRef?.current

    if (!element || !target) {
      return setXY(undefined)
    }

    setXY(getPosition(position, target, element))
  }

  useEffect(() => {
    positionHint()
  }, [targetRef])

  const Element = (
    <StyledPullDown style={xy} ref={elementRef}>
      {rows.map((row, index) => {
        if (row.type === 'divider') {
          return <hr className="row-div" key={`div-${index}`} />
        }

        return (
          <button
            className={`row-btn ${row.variant || 'default'}`}
            key={`div-${index}`}
            onClick={() => {
              if (!row.stayOpen) {
                onClose()
              }
              row.action && row.action()
            }}
          >
            <span>{row.label}</span>
            <div className="row-btn-icon">{row.icon}</div>
          </button>
        )
      })}
    </StyledPullDown>
  )

  if (!isOpen) {
    return null
  }

  return createPortal(Element, document.getElementById('pulldown-root') as HTMLElement)
}

export default PullDown
