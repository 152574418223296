import { FC, ReactNode } from 'react'
import { StyledEditorBlock } from '.'

type Props = {
  title?: string
  extra?: string | ReactNode
  children: ReactNode
}

const EditorBlock: FC<Props> = ({ title, extra, children }) => {
  return (
    <StyledEditorBlock>
      {title && (
        <div className="header">
          <div className="title-container">
            <span className="title">{title}</span>
            {extra && <div className="extra">{extra}</div>}
          </div>
        </div>
      )}
      <div className="content">{children}</div>
    </StyledEditorBlock>
  )
}

export default EditorBlock
