import styled from 'styled-components'

type StyledProps = {
  width?: number | string
}

const StyledSocialProfilePopover = styled.div<StyledProps>`
  .scroll {
    padding: 6px;
    display: grid;
    grid-gap: 1px;
    width: ${({ width }) => (typeof width === 'string' ? width : `${width}px`)}
      ${({ width }) => !width && '225px'};
    box-sizing: border-box;
    max-height: 250px;
    overflow-y: auto;

    .empty-state {
      padding: 12px;

      button {
        margin-top: 12px;
      }
    }
  }

  .bottom {
    padding: 0 6px 6px 6px;
  }
`

export default StyledSocialProfilePopover
