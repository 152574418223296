import styled from 'styled-components'

const StyledActionPlanList = styled.div`
  .masonry-grid {
    display: flex;
    margin-left: -20px;
    width: auto;

    .masonry-grid-column {
      padding-left: 20px;
      background-clip: padding-box;
    }
  }

  .action-plans-list {
    display: grid;
    grid-gap: 20px;
    align-items: start;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
`

export default StyledActionPlanList
