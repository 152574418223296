import styled from 'styled-components'

const StyledAcademyCourseLessonPreview = styled.div`
  width: 250px;

  .lesson-preview-item-img {
    padding-top: 56.25%;
    background-color: ${({ theme }) => theme.color.gray[100]};
    position: relative;
    border-radius: 6px;

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 6px;
      object-fit: cover;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 6px;
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.foreground}20;
    }
  }

  .lesson-preview-item-details {
    margin-top: 10px;

    .lesson-preview-item-label {
      font-weight: 500;
      display: block;
    }

    .lesson-preview-item-title {
      display: block;
      margin-top: 4px;
      color: ${({ theme }) => theme.color.gray[500]};
    }
  }
`

export default StyledAcademyCourseLessonPreview
