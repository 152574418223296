import { FC, useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { toast } from 'react-hot-toast'
import firebase from 'firebase/app'
import { isEqual } from 'lodash'
import { v4 as uuid } from 'uuid'
import { useApolloClient } from '@apollo/client'
import { PlusIcon } from '@heroicons/react/24/outline'
import { ReactComponent as PersonasIcon } from '../../assets/personas-icon.svg'
import { Banner, Button, PageLayout, PersonaEdit, Spinner } from '../../components'
import { personaDeleteOne, personaUpsertMany } from '../../graphql'
import { PersonaType } from '../../types'
import { useForm, userStore } from '../../utils'
import { StyledPersonasView } from '.'

const PersonasView: FC = () => {
  const w = window as any
  const apollo = useApolloClient()
  const updateLoggedInUser = firebase.functions().httpsCallable('updateLoggedInUser')
  const [loading, setLoading] = useState(false)
  const { user, refresh } = useContext(userStore)
  const { form, updateFormValue } = useForm({
    default: {},
    personas: []
  })

  const newPersona = () => {
    updateFormValue({
      personas: [
        ...form.personas,
        {
          _id: uuid(),
          title: `Untitled Persona ${form.personas.length + 1}`
        }
      ]
    })
  }

  const updatePersona = (_id: string, data: Object) => {
    const personas = form.personas
    const newPersonas = personas.map((persona: PersonaType) => {
      if (persona._id === _id) {
        return {
          ...persona,
          ...data
        }
      }
      return persona
    })

    updateFormValue('personas', newPersonas)
  }

  const deletePersona = async (_id: string) => {
    try {
      setLoading(true)
      const personas = form.personas
      const newPersonas = personas.filter((persona: PersonaType) => persona._id !== _id)

      updateFormValue('personas', newPersonas)

      await apollo.mutate({
        fetchPolicy: 'no-cache',
        mutation: personaDeleteOne,
        variables: {
          _id
        }
      })

      await refresh()
    } catch (err) {
      console.error(err)
      toast.error((err as any)?.message || err)
    } finally {
      setLoading(false)
    }
  }

  const canSave = () => {
    const p = form.personas
    const d = form.default
    const hasOccupations =
      !!d?.occupation && !p.filter((persona: PersonaType) => !persona.occupation).length
    const hasVoices =
      !!d?.voices?.length && !p.filter((persona: PersonaType) => !persona.voices?.length).length

    const pEqual = isEqual(user?.personas || [], form.personas)
    const dEqual = isEqual(
      {
        _id: 'default',
        title: 'Default Persona',
        ...user?.aiOnboarding
      },
      form.default
    )

    return hasVoices && hasOccupations && (!pEqual || !dEqual)
  }

  const handleSave = async () => {
    try {
      setLoading(true)
      const personas = form.personas

      await apollo.mutate({
        fetchPolicy: 'no-cache',
        mutation: personaUpsertMany,
        variables: {
          personas
        }
      })

      await updateLoggedInUser({
        aiOnboarding: {
          title: form?.default?.title,
          occupation: form?.default?.occupation,
          company: form?.default?.company,
          ages: form?.default?.ages,
          genders: form?.default?.genders,
          occupations: form?.default?.occupations,
          voices: form?.default?.voices
        }
      })

      w.Intercom('update', {
        aiOnboarding: {
          occupation: form?.default?.occupation,
          company: form?.default?.company,
          ages: form?.default?.ages,
          genders: form?.default?.genders,
          occupations: form?.default?.occupations,
          voices: form?.default?.voices
        }
      })

      await refresh()
    } catch (err) {
      console.error(err)
      toast.error((err as any)?.message || err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!user) {
      return
    }

    updateFormValue({
      default: {
        _id: 'default',
        title: 'Default Persona',
        ...user?.aiOnboarding
      },
      personas: user.personas || []
    })
  }, [user])

  if (!user) {
    return null
  }

  return (
    <PageLayout maxWidth={800}>
      <Helmet title="Personas | Social Curator" />
      <StyledPersonasView>
        <Banner message="Want to use Dottie for different brands or businesses? Create a new persona" />
        <div className="header">
          <div className="header-details">
            <h1>Dottie Personas</h1>
            <h2>
              Switch personas by clicking the <PersonasIcon /> button when talking to Dottie
            </h2>
          </div>
          <Button variant="emphasis" disabled={!canSave() || loading} onClick={() => handleSave()}>
            {loading ? <Spinner /> : 'Save changes'}
          </Button>
        </div>
        <div className="page-content">
          <PersonaEdit
            persona={form.default}
            isDefault={true}
            onUpdate={(personaValue, value) =>
              updateFormValue({
                default: {
                  ...form.default,
                  ...(typeof personaValue === 'object'
                    ? personaValue
                    : {
                        [personaValue]: value
                      })
                }
              })
            }
          />
          {form.personas?.map((persona: PersonaType) => {
            return (
              <PersonaEdit
                persona={persona}
                onUpdate={(personaValue, value) =>
                  updatePersona(persona._id, {
                    ...(typeof personaValue === 'object'
                      ? personaValue
                      : {
                          [personaValue]: value
                        })
                  })
                }
                onDelete={() => deletePersona(persona._id)}
              />
            )
          })}
          <Button
            size="lg"
            iconPos="left"
            variant="emphasis-outline"
            isFullWidth
            onClick={() => newPersona()}
            disabled={form.personas.length >= 4 || loading}
          >
            <PlusIcon /> Add Persona
          </Button>
        </div>
      </StyledPersonasView>
    </PageLayout>
  )
}

export default PersonasView
