import styled from 'styled-components'

const StyledHomeView = styled.div`
  display: grid;
  min-height: 100vh;
  grid-template-rows: auto minmax(0, 1fr);

  .tabs {
    position: sticky;
    top: 0;
    background-color: ${({ theme }) => theme.color.background};
    z-index: ${({ theme }) => theme.elevation.fixed};

    .tab-list {
      max-width: 785px;
    }
  }

  .home-container {
    background-color: ${({ theme }) => theme.color.gray[100]};
  }
`

export default StyledHomeView
