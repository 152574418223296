import { FC, ReactNode } from 'react'
import { ThemeProvider as Provider } from 'styled-components'
import { useAppSelector } from '../redux'
import { theme, themeDark } from '.'

type Props = {
  children: ReactNode
}

const ThemeProvider: FC<Props> = ({ children }) => {
  const { theme: currTheme } = useAppSelector(state => state.state)

  return <Provider theme={currTheme === 'dark' ? themeDark : theme}>{children}</Provider>
}

export default ThemeProvider
