import styled from 'styled-components'

const StyledCreateBar = styled.div`
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  gap: 10px;
  width: 440px;
  flex-shrink: 0;
  height: 100%;
  margin-right: 10px;
`

export default StyledCreateBar
