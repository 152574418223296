import styled from 'styled-components'

const StyledWorkspaceInviteView = styled.div`
  background-color: ${({ theme }) => theme.color.gray[100]};
  height: 100vh;
  display: grid;
  align-content: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;

  .workspace-invite-content {
    padding: 40px;
    border-radius: 10px;
    max-width: 400px;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.color.background};
    box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 0.1), 0 0 0 1px #00000019,
      0 2px 4px 0px #00000016;

    h1 {
      font-size: 24px;
      display: block;
      font-weight: 500;
      color: ${({ theme }) => theme.color.gray[900]};
      text-align: center;
      font-family: ${({ theme }) => theme.typeface.polySans};
    }

    button {
      margin: 30px auto 0 auto;
    }
  }
`

export default StyledWorkspaceInviteView
