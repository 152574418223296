import styled from 'styled-components'

type StyledProps = {
  isCompleted?: boolean
}

const StyledQuickstartItem = styled.button<StyledProps>`
  box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
    0px 2px 3px rgba(0, 0, 0, 0.05);
  background-color: ${({ theme }) => theme.color.background};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;

  .quick-start-item-details {
    display: flex;
    align-items: center;

    .quick-start-item-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      margin-right: 12px;
      position: relative;
      flex-shrink: 0;
      background-color: ${({ theme }) => theme.color.brandLight};
      color: ${({ theme }) => theme.color.brand};
      ${({ isCompleted }) => isCompleted && 'opacity: 0.7;'}

      svg {
        height: 24px;
        stroke-width: 2px;
      }

      .quick-start-completed-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.color.brand};
        box-shadow: 0 0 0 3px ${({ theme }) => theme.color.background};
        color: #fff;
        position: absolute;
        right: 0px;
        bottom: 0px;

        svg {
          height: 16px;
          stroke-width: 3px;
        }
      }
    }

    .quick-start-item-title {
      font-weight: 600;
      text-align: left;
      color: ${({ theme }) => theme.color.gray[800]};
      ${({ isCompleted }) => isCompleted && 'opacity: 0.6;'}
    }
  }

  .arrow-icon {
    height: 24px;
    stroke-width: 2px;
    color: ${({ theme }) => theme.color.gray[400]};
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 0 1px ${({ theme }) => theme.color.brand},
      inset 0 0 0 1px rgb(255, 255, 255, 0.2), 0px 0px 0px 1px rgba(0, 0, 0, 0.075),
      0px 2px 6px rgba(0, 0, 0, 0.15);

    .arrow-icon,
    .quick-start-item-icon,
    .quick-start-item-details .quick-start-item-title {
      color: ${({ theme }) => theme.color.brand};
      opacity: 1;
    }
  }
`

export default StyledQuickstartItem
