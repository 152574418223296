import styled from 'styled-components'

const StyledEmptyState = styled.div`
  padding: 30px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.color.brandLight};
  width: 100%;
  box-sizing: border-box;
  user-select: none;
  align-content: center;
  display: grid;

  img {
    pointer-events: none;
    width: 100%;
    max-width: 205px;
    margin: -20px auto 0 auto;
  }

  .empty-state-title {
    display: block;
    font-weight: 600;
    font-size: 20px;
    color: ${({ theme }) => theme.color.gray[800]};
    text-align: center;
    font-family: ${({ theme }) => theme.typeface.faktumXCon};
  }

  .empty-state-body {
    display: block;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.01rem;
    color: ${({ theme }) => theme.color.gray[600]};
    text-align: center;
    margin-top: 8px;
  }

  button {
    margin: 30px auto 0 auto;
    width: 100%;
    max-width: 200px;
    justify-content: space-between;
  }

  .disclaimer {
    display: block;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.01rem;
    color: ${({ theme }) => theme.color.gray[600]};
    text-align: center;
    margin-top: 8px;

    a {
      color: inherit;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`

export default StyledEmptyState
