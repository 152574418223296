import { FC, MouseEvent, useContext, useState } from 'react'
import { useLongPress } from 'use-long-press'
import { Dialog } from '@capacitor/dialog'
import {
  CheckCircleIcon as SolidCheckCircleIcon,
  Square2StackIcon
} from '@heroicons/react/20/solid'
import {
  ArrowsPointingOutIcon,
  CheckCircleIcon,
  DocumentDuplicateIcon,
  EllipsisHorizontalIcon,
  PaperClipIcon,
  TrashIcon
} from '@heroicons/react/24/outline'
import { setCreatePost, setShowCreate, useAppDispatch } from '../../../../redux'
import { PostType } from '../../../../types'
import {
  getPostStatus,
  getRelativeString,
  hydrateSocialProfiles,
  theme,
  useHaptics,
  usePost,
  userStore
} from '../../../../utils'
import { CloudMediaPreview, OptionsSheet, SocialProfileAvatar } from '../..'
import { StyledPostListItem } from '.'

type Props = {
  post: PostType
}

const PostListItem: FC<Props> = ({ post }) => {
  const dispatch = useAppDispatch()
  const [showOptions, setShowOptions] = useState(false)
  const { user } = useContext(userStore)
  const { trigger } = useHaptics()
  const { deletePost, duplicatePost, togglePostUsed } = usePost()

  const openPost = () => {
    dispatch(setCreatePost(post))
    dispatch(setShowCreate(true))
  }

  const handleOptions = (e: MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setShowOptions(true)
  }

  const handleDeletePost = async () => {
    const { value: confirmed } = await Dialog.confirm({
      title: 'Delete this post?',
      message: `Are you sure you want to delete "${post.title || 'Untitled Post'}"?`,
      okButtonTitle: 'Delete Post'
    })

    if (!confirmed) {
      return
    }

    await deletePost(post)
  }

  const longPressBind = useLongPress(
    () => {
      trigger('impact-medium')
      setShowOptions(true)
    },
    {
      cancelOnMovement: true
    }
  )

  return (
    <>
      <StyledPostListItem onClick={() => openPost()} {...longPressBind()}>
        <div className="post-preview-container">
          <div className="post-chips">
            <div className="post-chip">{getPostStatus(post)}</div>
            {(post.media?.length || 0) > 1 && (
              <div className="post-chip">
                <Square2StackIcon />
                {post.media?.length}
              </div>
            )}
          </div>

          {!!post.media?.length && (
            <CloudMediaPreview
              url={post.media[0].url}
              type={post.media[0].type}
              preview={post.media[0].preview}
            />
          )}
          {!!post.captionText?.trim() && !post.media?.length && (
            <div className="post-layout-preview">
              <span className="post-caption-preview">{post.captionText}</span>
            </div>
          )}
          {!post.captionText?.trim() && !post.media?.length && (
            <PaperClipIcon className="empty-icon" />
          )}
        </div>
        <div className="post-header">
          <div className="post-details-container">
            {!!hydrateSocialProfiles(post.socialProfiles, user)?.length && (
              <div className="post-profiles">
                {hydrateSocialProfiles(post.socialProfiles, user)?.map((profile) => {
                  return <SocialProfileAvatar profile={profile} size={36} inRow key={profile._id} />
                })}
              </div>
            )}
            <div className="post-details">
              <span className="post-title">{post.title || 'Untitled Post'}</span>
              <span className="post-date">
                {post.postDate ? getRelativeString(post.postDate, true) : 'Unscheduled'}
              </span>
            </div>
          </div>
          <button className="options-btn" onClick={(e) => handleOptions(e)}>
            <EllipsisHorizontalIcon />
          </button>
        </div>
      </StyledPostListItem>
      <OptionsSheet
        options={[
          {
            icon: <ArrowsPointingOutIcon />,
            label: 'Open',
            action: () => openPost()
          },
          {
            icon: post.isUsed ? (
              <SolidCheckCircleIcon fill={theme.color.green[600]} />
            ) : (
              <CheckCircleIcon />
            ),
            label: `Mark as ${post.isUsed ? 'unpublished' : 'published'}`,
            stayOpen: true,
            action: () => togglePostUsed(post)
          },
          {
            icon: <DocumentDuplicateIcon />,
            label: 'Duplicate',
            action: () => duplicatePost(post._id)
          },
          {
            icon: <TrashIcon />,
            label: 'Delete',
            variant: 'destructive',
            action: () => handleDeletePost()
          }
        ]}
        isOpen={showOptions}
        onClose={() => setShowOptions(false)}
      />
    </>
  )
}

export default PostListItem
