import styled, { keyframes } from 'styled-components'

const popInAnim = keyframes`
  from {
    opacity: 0;
    transform: translateY(6px) scale(0.95);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
`

const popOutAnim = keyframes`
  from {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
  to {
    opacity: 0;
    transform: translateY(6px) scale(0.95);
  }
`

type StyledProps = {
  showCloseAnim?: boolean
}

const StyledSchedulePopover = styled.div<StyledProps>`
  width: 322px;
  padding: 20px;
  box-sizing: border-box;
  display: grid;
  gap: 20px;
  user-select: none;
  position: fixed;
  background-color: ${({ theme }) => theme.color.background};
  border-radius: 12px;
  z-index: 999999;
  box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.1),
    0px 2px 12px rgba(0, 0, 0, 0.1);

  .input-group {
    .input-group-label {
      cursor: default;
      font-weight: 600;
      color: ${({ theme }) => theme.color.gray[800]};
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      svg {
        height: 18px;
        margin-right: 6px;
      }
    }

    .date-input-btn {
      height: 38px;
      border-radius: 8px;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      padding: 0 10px;
      background-color: ${({ theme }) => theme.color.gray[100]};
      color: ${({ theme }) => theme.color.gray[700]};
      transition: 0.2s;

      svg {
        height: 22px;
        margin-right: 8px;
        color: ${({ theme }) => theme.color.gray[500]};
      }

      &[data-empty='true'] {
        color: ${({ theme }) => theme.color.gray[500]};
      }

      &:hover {
        background-color: ${({ theme }) => theme.color.gray[200]};
      }
    }

    .date-suggestion-btn {
      height: 38px;
      border-radius: 8px;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px;
      font-weight: 500;
      color: ${({ theme }) => theme.color.orange[900]};
      background-color: ${({ theme }) => theme.color.orange[100]};
      transition: 0.2s;

      svg {
        height: 20px;
        margin-right: 6px;
      }

      &:not(:last-child) {
        margin-bottom: 6px;
      }

      &:hover {
        background-color: ${({ theme }) => theme.color.orange[200]};
      }

      &:first-of-type {
        color: ${({ theme }) => theme.color.orange[950]};
        background-color: ${({ theme }) => theme.color.orange[300]};
        transition: 0.2s;

        &:hover {
          background-color: ${({ theme }) => theme.color.orange[400]};
        }
      }
    }
  }

  .confirm-btn {
    height: 45px;
    border-radius: 50rem;
    color: ${({ theme }) => theme.color.background};
    background-color: ${({ theme }) => theme.color.gray[900]};
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    transition: 0.2s;

    &:hover {
      background-color: ${({ theme }) => theme.color.gray[800]};
    }

    &:disabled {
      color: ${({ theme }) => theme.color.gray[400]};
      cursor: not-allowed;
      background-color: ${({ theme }) => theme.color.gray[100]};
    }
  }

  animation: forwards 0.05s ease-in-out
    ${({ showCloseAnim }) => (showCloseAnim ? popOutAnim : popInAnim)};
`

export default StyledSchedulePopover
