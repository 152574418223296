import styled from 'styled-components'

const StyledActionPlanList = styled.div`
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 14px 14px 80px 14px;
`

export default StyledActionPlanList
