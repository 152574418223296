import styled from 'styled-components'

type StyledProps = {
  keyboardHeight: number
}

const StyledCreateDrawer = styled.div<StyledProps>`
  width: 100vw;
  height: calc(
    100dvh -
      ${({ keyboardHeight }) =>
        !!keyboardHeight ? `${keyboardHeight}px` : 'var(--bottom-spacing)'} - 65px
  );
  overflow: hidden;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  background-color: ${({ theme }) => theme.color.background};
  z-index: ${({ theme }) => theme.elevation.override};

  .create-scroll {
    overflow-y: auto;
    height: 100%;
    display: grid;
    width: 100%;
    grid-template-rows: auto auto minmax(0, 1fr);
    transition: opacity 0.2s;
  }

  .post-options {
    padding: 0 16px 16px 16px;
    display: grid;
    gap: 16px;
    border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};

    .input-label {
      color: ${({ theme }) => theme.color.gray[800]};
      font-weight: 700;
      display: block;
      font-size: 15px;
    }
  }
`

export default StyledCreateDrawer
