import { createRef, FC, useEffect, useState } from 'react'
import { xorBy } from 'lodash'
import { PlusIcon } from '@heroicons/react/24/outline'
import { PostType } from '../../../types'
import { useCreate, useUploadManager } from '../../../utils'
import { updateCreatePost, useAppDispatch, useAppSelector } from '../../../redux'
import { Button, Dialog } from '../..'
import {
  UploadDialogDesignsPage,
  UploadDialogDropzonePage,
  UploadDialogPhotosPage,
  UploadDialogUploadsPage
} from './pages'
import { StyledUploadDialog } from '.'

type Props = {
  addToPost?: boolean
  newPost?: boolean
  isMulti?: boolean
  isOpen: boolean
  onClose: () => void
}

const UploadDialog: FC<Props> = ({ addToPost, newPost, isMulti = true, isOpen, onClose }) => {
  const dialogRef = createRef<HTMLDivElement>()
  const dispatch = useAppDispatch()
  const { addToUploads } = useUploadManager()
  const { post } = useAppSelector((state) => state.create)
  const { newPost: clearPost } = useCreate()
  const [mediaToAdd, setMediaToAdd] = useState<PostType['media']>([])
  const [page, setPage] = useState<'photos' | 'uploads' | 'designs'>()

  const handleClose = () => {
    const dialog = dialogRef.current as any
    dialog?.close()

    if (!dialog) {
      onClose()
    }
  }

  const toggleMedia = (media: NonNullable<PostType['media']>[number]) => {
    setMediaToAdd(xorBy(mediaToAdd, [media], 'ref'))
  }

  const handleAdd = () => {
    if (newPost) {
      clearPost(true)
      dispatch(updateCreatePost({ media: mediaToAdd || [] }))
      return
    }
    dispatch(updateCreatePost({ media: [...(post?.media || []), ...(mediaToAdd || [])] }))
  }

  const handleUpload = async (files: File[]) => {
    if (newPost) {
      clearPost(true)
    }
    await addToUploads(files, addToPost)
  }

  useEffect(() => {
    setMediaToAdd([])
  }, [page])

  useEffect(() => {
    setPage(undefined)
    setMediaToAdd([])
  }, [isOpen])

  return (
    <Dialog ref={dialogRef} isOpen={isOpen} onClose={onClose}>
      <StyledUploadDialog className="upload-dialog-scroll">
        {!page && (
          <UploadDialogDropzonePage
            addToPost={addToPost}
            isMulti={isMulti}
            setPage={(page) => setPage(page as any)}
            handleUpload={(files) => handleUpload(files)}
            onClose={() => handleClose()}
          />
        )}
        {page === 'uploads' && (
          <UploadDialogUploadsPage
            mediaToAdd={mediaToAdd}
            toggleMedia={(media) => toggleMedia(media)}
            setPage={(page) => setPage(page as any)}
            onClose={() => handleClose()}
          />
        )}
        {page === 'designs' && (
          <UploadDialogDesignsPage
            mediaToAdd={mediaToAdd}
            toggleMedia={(media) => toggleMedia(media)}
            setPage={(page) => setPage(page as any)}
            onClose={() => handleClose()}
          />
        )}
        {page === 'photos' && (
          <UploadDialogPhotosPage
            mediaToAdd={mediaToAdd}
            toggleMedia={(media) => toggleMedia(media)}
            setPage={(page) => setPage(page as any)}
            onClose={() => handleClose()}
          />
        )}
        {!!page && !!mediaToAdd?.length && (
          <div className="dialog-actions">
            <Button
              size="lg"
              iconPos="left"
              isFullWidth
              variant="emphasis"
              onClick={() => handleAdd()}
            >
              <PlusIcon />
              {addToPost ? 'Add to post' : 'Create post'}
            </Button>
          </div>
        )}
      </StyledUploadDialog>
    </Dialog>
  )
}

export default UploadDialog
