import Calendar from 'react-calendar'
import { FC, useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { Button, Drawer } from '../..'
import { StyledDateTimeDrawer } from '.'

type Props = {
  value?: Date
  isOpen: boolean
  onClear: () => void
  onChange: (date: Date) => void
  onClose: () => void
}

const DateTimeDrawer: FC<Props> = ({ value, isOpen, onClear, onChange, onClose }) => {
  const [activeDate, setActiveDate] = useState<Date>()
  const [timeInputValue, setTimeInputValue] = useState('')

  const getFormattedTime = (value?: Date | string) => {
    if (!value) {
      return ''
    }

    const jsDate = typeof value === 'string' ? new Date(value) : value
    const formattedDate = DateTime.fromJSDate(jsDate)
    const hour = formattedDate.get('hour')
    const minutes = formattedDate.get('minute')

    return `${hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
  }

  const getFormattedDate = () => {
    if (!activeDate) {
      return
    }

    const formattedDate = DateTime.fromJSDate(activeDate).toJSDate()
    const timeSplit = timeInputValue.split(':')
    const hour = parseInt(timeSplit[0])
    const minute = timeSplit[1]

    formattedDate.setMilliseconds(0)
    formattedDate.setSeconds(0)
    formattedDate.setHours(hour)
    formattedDate.setMinutes(parseInt(minute))

    return formattedDate
  }

  const handleTimeChange = (value: string) => {
    const valueList = value.split(':')
    const hour = parseInt(valueList[0])
    const minute = parseInt(valueList[1])

    setTimeInputValue(`${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`)
  }

  const configurePostDate = (value?: Date | string) => {
    if (!value) {
      return setActiveDate(undefined)
    }

    if (typeof value === 'string') {
      return setActiveDate(DateTime.fromISO(value).toJSDate())
    }

    setActiveDate(value)
  }

  const handleDone = () => {
    const formattedDate = getFormattedDate()

    if (!formattedDate) {
      return
    }

    onChange(formattedDate)
    onClose()
  }

  const handleClear = () => {
    onClose()
    onClear()
  }

  useEffect(() => {
    if (!isOpen) {
      setTimeInputValue('')
      setActiveDate(undefined)
    }
  }, [isOpen])

  useEffect(() => {
    setTimeInputValue(getFormattedTime(value))
    configurePostDate(value)
  }, [value, isOpen])

  return (
    <Drawer expandOnContentDrag open={isOpen} onDismiss={onClose}>
      <StyledDateTimeDrawer>
        <div className="date-time-drawer-calendar">
          <Calendar
            next2Label={null}
            prev2Label={null}
            nextLabel={<ChevronRightIcon />}
            prevLabel={<ChevronLeftIcon />}
            formatShortWeekday={(locale, date) => DateTime.fromJSDate(date).toFormat('ccccc')}
            maxDetail="month"
            minDetail="month"
            value={activeDate}
            onChange={(e: Date) => setActiveDate(e)}
          />
        </div>
        <label className="date-time-drawer-time-container">
          {/* <span className="time-label">Time</span> */}
          {!timeInputValue && <span className="time-placeholder">Pick a time</span>}
          <div className="date-time-drawer-time">
            <input
              value={timeInputValue}
              type="time"
              onChange={e => handleTimeChange(e.target.value)}
            />
          </div>
        </label>
        <div className="date-time-drawer-actions">
          <Button size="lg" onClick={() => handleClear()}>
            Clear
          </Button>
          <Button
            size="lg"
            variant="emphasis"
            disabled={!timeInputValue.trim().length}
            className="done-btn"
            onClick={() => handleDone()}
          >
            Done
          </Button>
        </div>
      </StyledDateTimeDrawer>
    </Drawer>
  )
}

export default DateTimeDrawer
