import { persistReducer, persistStore } from 'redux-persist'
import { createFilter } from 'redux-persist-transform-filter'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import {
  calendarReducer as calendarMobileReducer,
  homeReducer as homeMobileReducer
} from '../mobile/redux'
import {
  actionPlansReducer,
  calendarReducer,
  captionsReducer,
  chatReducer,
  cloudReducer,
  contentReducer,
  createReducer,
  dailyActionReducer,
  designerReducer,
  designsReducer,
  dialogReducer,
  hashtagsReducer,
  pageReducer,
  photosReducer,
  planReducer,
  postsReducer,
  productTestingReducer,
  splitsReducer,
  stateReducer,
  storiesReducer,
  subscriptionReducer,
  tourReducer,
  uploadReducer
} from './slices'

const reducers = combineReducers({
  captions: captionsReducer,
  photos: photosReducer,
  actionPlans: actionPlansReducer,
  stories: storiesReducer,
  posts: postsReducer,
  tour: tourReducer,
  subscription: subscriptionReducer,
  state: stateReducer,
  dailyAction: dailyActionReducer,
  hashtags: hashtagsReducer,
  page: pageReducer,
  plan: planReducer,
  splits: splitsReducer,
  designer: designerReducer,
  calendar: calendarReducer,
  cloud: cloudReducer,
  upload: uploadReducer,
  productTesting: productTestingReducer,
  chat: chatReducer,
  dialog: dialogReducer,
  designs: designsReducer,
  create: createReducer,
  content: contentReducer,
  // MOBILE
  calendarMobile: calendarMobileReducer,
  homeMobile: homeMobileReducer
})

const tourSubsetFilter = createFilter('tour', ['tours'])
const calendarSubsetFilter = createFilter('calendar', ['events', 'view', 'showPostList'])

const persistConfig = {
  key: 'root',
  whitelist: ['create', 'calendar', 'state', 'tour'],
  storage,
  transforms: [tourSubsetFilter, calendarSubsetFilter],
  stateReconciler: autoMergeLevel2 as any
}

const persistedReducer = persistReducer(persistConfig, reducers) as typeof reducers

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
