import styled from 'styled-components'

const StyledCalendarWeekRow = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.gray[200]};
  height: 75px;
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

  .day {
    border-right: 1px solid ${({ theme }) => theme.color.gray[200]};
    height: 75px;
  }

  &:last-child {
    border: 0;
  }
`

export default StyledCalendarWeekRow
