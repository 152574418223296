import { FC } from 'react'
import { ActionPlanType } from '../../../types'
import { theme } from '../../../utils/theme'
import { StyledActionPlanVideo } from '.'

type Props = {
  actionPlan: ActionPlanType
}

const ActionPlanVideo: FC<Props> = ({ actionPlan }) => {
  if (!actionPlan.actionPlanVideo) {
    return null
  }

  return (
    <StyledActionPlanVideo>
      <iframe
        src={`${actionPlan.actionPlanVideo}?color=${theme.color.brand.replace(
          '#',
          ''
        )}&pip=true&texttrack=false&h=96067f6a77&badge=0&autopause=0&player_id=0&app_id=58479`}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      />
      <script src="https://player.vimeo.com/api/player.js"></script>
    </StyledActionPlanVideo>
  )
}

export default ActionPlanVideo
