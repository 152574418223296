import { FC } from 'react'
import { Helmet } from 'react-helmet'
import { Route, useHistory } from 'react-router-dom'
import { SparklesIcon } from '../../assets'
import {
  Button,
  DesignGrid,
  HomeTop,
  PageLayout,
  PostGrid,
  PostTemplateList,
  Tabs
} from '../../components'
import { useAppSelector } from '../../redux'
import { ROUTES, theme, useChat, useSegment } from '../../utils'
import { StyledHome2View } from '.'

const Home2View: FC = () => {
  const history = useHistory()
  const { clearChat, sendMessage } = useChat()
  const { track } = useSegment()
  const { splits } = useAppSelector((state) => state.splits)
  const { theme: t, postsNum } = useAppSelector((state) => state.state)

  const askChat = async () => {
    await clearChat()
    sendMessage('How can I show up on social today?', 'daily-action')
    history.push(ROUTES.create)
  }

  return (
    <StyledHome2View style={{ backgroundColor: t === 'dark' ? '#0c0c0c' : theme.color.gray[100] }}>
      <Helmet title="Home | Social Curator" />
      <HomeTop />
      <Tabs
        tabs={[
          {
            label: 'My Posts',
            amount: postsNum,
            path: ROUTES.home,
            isExact: true
          },
          // {
          //   label: 'My Designs',
          //   path: ROUTES.myDesigns
          // },
          {
            label: 'Post Templates',
            path: ROUTES.postTemplates.all
          }
        ]}
      >
        {splits.pillars === 'on' && (
          <Button
            className="new-post-btn"
            iconPos="left"
            size="sm"
            onClick={() => {
              track('Clicked Plan Button')
              history.push(ROUTES.plan)
            }}
          >
            <SparklesIcon /> Plan posts
          </Button>
        )}
      </Tabs>
      <PageLayout maxWidth={785}>
        <Route path={ROUTES.home} component={PostGrid} exact />
        <Route path={ROUTES.myDesigns} component={DesignGrid} exact />
        <Route path={ROUTES.postTemplates.all} component={PostTemplateList} />
      </PageLayout>
    </StyledHome2View>
  )
}

export default Home2View
