import { FC, MouseEvent as ReactMouseEvent, createRef, useEffect } from 'react'
import ReactTextareaAutosize from 'react-textarea-autosize'
import { ArchiveBoxIcon, XCircleIcon } from '@heroicons/react/20/solid'
import { ArrowSmallUpIcon, ArrowUturnRightIcon } from '@heroicons/react/24/outline'
import {
  setChatInput,
  setChatRef,
  setChatTemplate,
  setShowChatPrompts,
  useAppDispatch,
  useAppSelector
} from '../../../../redux'
import { useChat, useHaptics } from '../../../../utils'
import { Spinner } from '../..'
import { ChatPromptBar } from '..'
import { StyledChatInput } from '.'

const ChatInput: FC = () => {
  const inputRef = createRef<HTMLTextAreaElement>()
  const dispatch = useAppDispatch()
  const { trigger } = useHaptics()
  const { sendMessage } = useChat()
  const { input, showPrompts, template, loading, ref } = useAppSelector((state) => state.chat)

  const handleSubmit = async (e: ReactMouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    document.getElementById('chat-input')?.focus()

    if (!input.trim() || loading) {
      return
    }

    dispatch(setChatInput(''))
    dispatch(setChatTemplate(undefined))
    await sendMessage(input)
  }

  const handleClick = (e: MouseEvent) => {
    const target = e?.target as HTMLElement
    const sheet = document.querySelector('[data-chat-sheet-content]')

    if (sheet?.contains(target)) {
      e.preventDefault()
      e.stopPropagation()
      document.getElementById('chat-input')?.focus()
      return
    }

    trigger('impact-light')
  }

  useEffect(() => {
    window.addEventListener('click', handleClick)

    return () => {
      window.removeEventListener('click', handleClick)
    }
  }, [template])

  useEffect(() => {
    if (inputRef?.current && ref) {
      inputRef.current.focus()
    }
  }, [inputRef, ref])

  return (
    <StyledChatInput data-chat-input hasTemplate={!!template}>
      {ref && (
        <div className="chat-reply-container">
          <div className="chat-reply">
            <div className="chat-reply-label">
              <ArrowUturnRightIcon />
              Reply
            </div>
            <button onClick={() => dispatch(setChatRef(undefined))}>
              <XCircleIcon />
            </button>
          </div>
        </div>
      )}
      <div className="input-top-row">
        <div className="input-container">
          <ReactTextareaAutosize
            id="chat-input"
            maxRows={6}
            autoFocus
            value={input}
            className="input"
            placeholder="Message"
            ref={inputRef}
            onChange={(e) => dispatch(setChatInput(e.target.value))}
            // onFocus={() => trigger('selection-changed')}
          />
          <button
            className="template-btn"
            tabIndex={0}
            data-active={showPrompts}
            onClick={() => dispatch(setShowChatPrompts(!showPrompts))}
          >
            <ArchiveBoxIcon />
          </button>
        </div>
        <button
          className="send-btn"
          disabled={loading || !input?.trim()}
          onClick={(e) => handleSubmit(e)}
        >
          {loading ? <Spinner /> : <ArrowSmallUpIcon />}
        </button>
      </div>
      <ChatPromptBar
        isExpanded={showPrompts}
        onCollapse={() => dispatch(setShowChatPrompts(false))}
      />
    </StyledChatInput>
  )
}

export default ChatInput
