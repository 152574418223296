import { FC } from 'react'
import { UploadsFolderList, UploadsSidebarHeader, UploadsStorage } from '..'
import { StyledUploadsSidebar } from '.'

const UploadsSidebar: FC = () => {
  return (
    <StyledUploadsSidebar>
      <UploadsSidebarHeader />
      <UploadsFolderList />
      <UploadsStorage />
    </StyledUploadsSidebar>
  )
}

export default UploadsSidebar
