import styled from 'styled-components'

type StyledProps = {
  isExpired?: boolean
}

const StyledConnectionSidebarProfile = styled.li<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  color: ${({ theme }) => theme.color.gray[800]};

  .profile-details-container {
    display: flex;
    align-items: center;

    .profile-username {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-align: left;
      overflow-wrap: anywhere;
      -webkit-box-orient: vertical;
      line-break: anywhere;
    }

    .avatar {
      margin-right: 8px;
    }
  }

  .profile-actions {
    display: flex;
    align-items: center;
    margin-right: -7px;
    margin-left: 10px;

    button {
      height: 26px;
      padding: 0 10px;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      transition: 0.2s;
      background-color: transparent;
      color: ${({ theme }) => theme.color.gray[500]};

      svg {
        height: 18px;
        stroke-width: 2px;
      }

      &:hover {
        background-color: ${({ theme }) => theme.color.gray[200]};
        color: ${({ theme }) => theme.color.gray[800]};
      }

      &.icon {
        padding: 0;
        width: 26px;
      }
    }
  }

  &:hover {
    cursor: default;
  }

  ${({ isExpired, theme }) =>
    isExpired &&
    `
    color: ${theme.color.red[800]};

    .avatar {
      .avatar-img {
        box-shadow: 0 0 0 2px ${theme.color.red[100]};
      }
    }

    .profile-actions {
      button {
        color: ${theme.color.red[800]};
  
        svg {
          height: 18px;
        }
  
        &:hover {
          background-color: ${theme.color.red[200]};
          color: ${theme.color.red[900]};
        }
      }
    }
  `}
`

export default StyledConnectionSidebarProfile
