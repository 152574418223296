import styled from 'styled-components'

type StyledProps = {
  $isDragActive?: boolean
}

const StyledComputerPage = styled.div<StyledProps>`
  height: 100%;
  transition: 0.2s;
  position: relative;
  display: grid;
  align-items: center;
  user-select: none;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.gray[100]};

  .label {
    display: grid;
  }

  ${({ $isDragActive, theme }) =>
    $isDragActive &&
    `
    background-color: ${theme.color.gray[200]};
  `}

  svg {
    height: 40px;
    margin: 0 auto 20px auto;
    color: ${({ theme }) => theme.color.gray[500]};
  }

  span {
    display: block;
    text-align: center;

    &.label-title {
      font-weight: 500;
      color: ${({ theme }) => theme.color.gray[700]};
    }

    &.label-description {
      font-size: 15px;
      margin-top: 10px;
      color: ${({ theme }) => theme.color.gray[500]};
    }
  }

  &:hover {
    cursor: hover;
    background-color: ${({ theme }) => theme.color.gray[200]};
  }
`

export default StyledComputerPage
