import { useContext, useEffect, useState } from 'react'
import { uniq } from 'lodash'
import { DateTime } from 'luxon'
import { PostErrorType, PostType } from '../../types'
import { userStore } from '..'
import { formatUsername, hydrateSocialProfiles, socialProfileByStatus } from '.'

export const usePostErrors = (post?: Partial<PostType>) => {
  const { user } = useContext(userStore)
  const [errors, setErrors] = useState<PostErrorType[]>([])

  const postInFuture = () => {
    if (!post?.postDate) {
      return false
    }

    const postDate = DateTime.fromISO(post.postDate)

    if (postDate.diffNow('seconds').seconds < 0) {
      return false
    }

    return true
  }

  const checkExpiredProfiles = (errorList: PostErrorType[]) => {
    if (!postInFuture()) {
      return
    }

    const expiredProfiles = socialProfileByStatus(['expired'], user?.socialProfiles || [])
    const matches = expiredProfiles.filter((profile) =>
      post?.socialProfiles?.includes(profile._id || '')
    )

    matches.map((profile) => {
      errorList.push({
        reason: `${formatUsername(profile.username, profile.type)} needs to be reconnected`,
        status: 'urgent',
        type: 'expired-profile',
        metadata: {
          profile: profile._id
        }
      })
    })
  }

  const checkPlatformRequirements = (errorList: PostErrorType[]) => {
    // if (!postInFuture()) {
    //   return
    // }

    const profiles = hydrateSocialProfiles(post?.socialProfiles, user)
    const platforms = uniq(profiles.map((profile) => profile.type))

    const isScheduled = postInFuture()

    const media = post?.media || []
    const hasMedia = !!post?.imageUrl || !!post?.media?.length
    const hasCaption = !!post?.captionText?.length
    const hasVideo = !!media.find((m) => m.type === 'video')
    const hasPhoto = !!media.find((m) => m.type === 'image')
    const mixedMedia = hasVideo && hasPhoto
    const hashtagCount = post?.captionText?.match(/#/g)?.length || 0

    if (isScheduled && !hasMedia && !hasCaption) {
      return errorList.push({
        reason: `You can\'t publish an empty post.`,
        status: 'urgent',
        type: 'platform'
      })
    }

    if (platforms.includes('instagram') && !hasMedia) {
      errorList.push({
        reason: 'You need an image or video to post to Instagram.',
        status: 'urgent',
        type: 'platform'
      })
    }

    if (platforms.includes('instagram') && hashtagCount > 30) {
      errorList.push({
        reason: "You are above Instagram's 30 hashtag limit.",
        status: 'urgent',
        type: 'platform'
      })
    }

    // if (platforms.includes('tiktok') && !hasMedia) {
    //   errorList.push({
    //     reason: 'You need a video to post to TikTok.',
    //     status: 'urgent',
    //     type: 'platform'
    //   })
    // }

    // if (platforms.includes('tiktok') && mixedMedia) {
    //   errorList.push({
    //     reason: "You can't post video and photo together to TikTok.",
    //     status: 'urgent',
    //     type: 'platform'
    //   })
    // }

    // if (platforms.includes('tiktok') && media.length > 1 && hasVideo) {
    //   errorList.push({
    //     reason: "You can't post carousels to TikTok.",
    //     status: 'urgent',
    //     type: 'platform'
    //   })
    // }

    if (platforms.includes('facebook') && mixedMedia) {
      errorList.push({
        reason: "You can't post video and photo together to Facebook.",
        status: 'urgent',
        type: 'platform'
      })
    }

    if (platforms.includes('linkedin') && mixedMedia) {
      errorList.push({
        reason: "You can't post video and photo together to LinkedIn.",
        status: 'urgent',
        type: 'platform'
      })
    }

    if (platforms.includes('linkedin') && media.length > 1 && hasVideo) {
      errorList.push({
        reason: "You can't post video carousels to LinkedIn.",
        status: 'urgent',
        type: 'platform'
      })
    }
  }

  const checkPostErrors = () => {
    const errorList: PostErrorType[] = []

    checkExpiredProfiles(errorList)
    checkPlatformRequirements(errorList)

    setErrors(errorList)
  }

  useEffect(() => {
    checkPostErrors()
  }, [
    post?._id,
    post?.socialProfiles?.length,
    post?.captionText?.length,
    post?.imageUrl,
    post?.media?.length,
    post?.postDate,
    user?.socialProfiles
  ])

  return { errors }
}
