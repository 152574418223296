import { FC, ReactNode } from 'react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { Drawer } from '../..'
import { StyledOptionDrawer } from '.'

type Props = {
  options: {
    icon?: ReactNode
    label: string
    render?: ReactNode
    isSelected?: boolean
    stayOpen?: boolean
    action: () => void
  }[]
  divsAfter?: string[]
  isOpen: boolean
  onClose: () => void
}

const OptionDrawer: FC<Props> = ({ options, divsAfter, isOpen, onClose }) => {
  const handleAction = (action: () => void, stayOpen?: boolean) => {
    action()

    if (!stayOpen) {
      onClose()
    }
  }

  return (
    <Drawer expandOnContentDrag maxHeight={500} open={isOpen} onDismiss={onClose}>
      <StyledOptionDrawer>
        <div className="option-list">
          {options.map((option, index) => {
            return (
              <>
                <button
                  aria-label={option.label}
                  aria-selected={option.isSelected}
                  onClick={() => handleAction(option.action, option.stayOpen)}
                  key={index}
                >
                  {option.icon}
                  {option.render || option.label}
                  {option.isSelected && <CheckIcon className="selected-icon" />}
                </button>
                {typeof option.label === 'string' && divsAfter?.includes(option.label) && (
                  <hr key={`${index}-divider`} />
                )}
              </>
            )
          })}
        </div>
      </StyledOptionDrawer>
    </Drawer>
  )
}

export default OptionDrawer
