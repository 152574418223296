import styled from 'styled-components'

const StyledProductTestDrawer = styled.div`
  height: 100vh;
  width: 380px;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  border-left: 1px solid ${({ theme }) => theme.color.gray[300]};
  background-color: ${({ theme }) => theme.color.background};

  .header {
    padding: 16px 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};

    .test-details {
      .test-id {
        display: block;
        padding: 2px 4px;
        border-radius: 4px;
        color: ${({ theme }) => theme.color.gray[500]};
        font-size: 12px;
        background-color: ${({ theme }) => theme.color.gray[100]};
        font-weight: 600;
        width: fit-content;
      }

      .title {
        margin-top: 6px;
        font-weight: 600;
        font-size: 18px;
        display: block;
        color: ${({ theme }) => theme.color.gray[900]};
      }
    }

    .close-btn {
      padding: 10px 14px;
      font-weight: 500;
      border-radius: 4px;
      display: flex;
      align-items: center;
      border: 1px solid ${({ theme }) => theme.color.gray[500]};
      color: ${({ theme }) => theme.color.gray[600]};
      background-color: ${({ theme }) => theme.color.background};
      transition: 0.2s;

      &:hover {
        border: 1px solid ${({ theme }) => theme.color.gray[600]};
        color: ${({ theme }) => theme.color.gray[700]};
        background-color: ${({ theme }) => theme.color.gray[100]};
      }
    }
  }

  .scroll-container {
    overflow-y: auto;
    position: relative;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }

  .task-list {
    display: grid;
    padding: 0 20px 120px 20px;
    box-sizing: border-box;
    align-content: start;

    .task {
      display: flex;
      padding: 20px 0;

      .check-icon {
        height: 32px;
        width: 32px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid ${({ theme }) => theme.color.gray[300]};
        background-color: ${({ theme }) => theme.color.background};
        color: ${({ theme }) => theme.color.gray[400]};
        border-radius: 6px;
        transition: 0.2s;
        margin-right: 16px;

        svg {
          height: 24px;
        }

        &:hover {
          cursor: pointer;
          border: 1px solid ${({ theme }) => theme.color.gray[400]};
          background-color: ${({ theme }) => theme.color.gray[100]};
          color: ${({ theme }) => theme.color.gray[500]};
        }
      }

      .task-details {
        .label {
          font-size: 15px;
          font-weight: 600;
          color: ${({ theme }) => theme.color.gray[700]};
        }

        details {
          margin-top: 2px;

          summary {
            font-size: 15px;
            transition: 0.2s;
            user-select: none;
            color: ${({ theme }) => theme.color.gray[500]};

            &:hover {
              cursor: pointer;
              color: ${({ theme }) => theme.color.gray[600]};
            }
          }

          .task-steps {
            font-size: 15px;
            color: ${({ theme }) => theme.color.gray[500]};
            padding: 14px;
            border-radius: 6px;
            margin-top: 6px;
            background-color: ${({ theme }) => theme.color.gray[100]};

            ol {
              margin: 0;
              padding-inline-start: 18px;

              li {
                &:not(:first-child) {
                  margin-top: 10px;
                }

                &[data-sub='true'] {
                  list-style-type: none;

                  &::before {
                    content: counter(list, lower-alpha) ') ';
                    counter-increment: list;
                  }
                }
              }
            }
          }
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.color.gray[200]};
      }

      &[aria-selected='true'] {
        .check-icon {
          border: 1px solid ${({ theme }) => theme.color.green[600]};
          background-color: ${({ theme }) => theme.color.green[600]};
          color: #fff;
        }

        .label {
          text-decoration: line-through;
          color: ${({ theme }) => theme.color.green[600]};
        }
      }
    }
  }
`

export default StyledProductTestDrawer
