import { FC, createRef, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { times } from 'lodash'
import { DateTime } from 'luxon'
import { useApolloClient } from '@apollo/client'
import { CheckCircleIcon, QuestionMarkCircleIcon } from '@heroicons/react/20/solid'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { getPlatformStats } from '../../../graphql'
import { setPostsNum, useAppDispatch, useAppSelector } from '../../../redux'
import { LEVELS, useUserLevel } from '../../../utils'
import { LevelsPopover } from '../..'
import { StyledHomeStats } from '.'

const HomeStats: FC = () => {
  const apollo = useApolloClient()
  const levelsBtnRef = createRef<HTMLDivElement>()
  const dispatch = useAppDispatch()
  const [streak, setStreak] = useState()
  const [week, setWeek] = useState<boolean[]>(times(7, () => false))
  const [loading, setLoading] = useState(true)
  const [showLevelsPopover, setShowLevelsPopover] = useState(false)
  const { posts } = useAppSelector((state) => state.posts)
  const { level, postsNum } = useUserLevel()

  const getStats = async () => {
    try {
      setLoading(true)

      const { data } = await apollo.query({
        query: getPlatformStats,
        fetchPolicy: 'no-cache'
      })

      dispatch(setPostsNum(data?.getPlatformStats?.createdPosts || 0))
      setStreak(data?.getPlatformStats?.streak || 0)
      setWeek(data?.getPlatformStats?.week || [])
    } catch (err) {
      console.error(err)
      toast.error((err as any)?.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getStats()
  }, [posts.length])

  return (
    <>
      <StyledHomeStats>
        <div className="stat level">
          <div className="level-indicator">{LEVELS[level.stage - 1].icon}</div>
          <div className="level-status-container">
            <div
              className="label level-label"
              ref={levelsBtnRef}
              onClick={() => setShowLevelsPopover(!showLevelsPopover)}
            >
              {level.title} <QuestionMarkCircleIcon />
            </div>
            <div className="level-status">
              <span className="level-post-count">
                {postsNum} post{postsNum !== 1 && 's'}
              </span>
              <progress max={level.target || 0} value={postsNum}>
                {postsNum}
              </progress>
              <span className="level-post-limit">{level.target || <CheckIcon />}</span>
            </div>
          </div>
        </div>
        <div className="stat streak">
          <div className="streak-label-container">
            <span className="label">Post streak</span>
            <span
              className="streak-count"
              data-tooltip="Publish at least one post per day to continue your streak."
            >
              {streak || 0}-day streak
              <QuestionMarkCircleIcon />
            </span>
          </div>
          <div className="streak-status">
            {week.map((isCompleted, index) => {
              const today = DateTime.now().weekday - 1
              const isPast = today > index
              const isToday = today === index
              const day = ['M', 'T', 'W', 'T', 'F', 'S', 'S'][index]
              return (
                <div
                  data-completed={isCompleted}
                  data-past={isPast}
                  data-today={isToday}
                  className="streak-day"
                  key={index}
                >
                  <div className="streak-day-bubble">
                    {isPast && !isCompleted && <XMarkIcon />}
                    {isCompleted && <CheckCircleIcon />}
                  </div>
                  <div className="streak-day-label">{day}</div>
                </div>
              )
            })}
          </div>
        </div>
      </StyledHomeStats>
      {showLevelsPopover && (
        <LevelsPopover
          buttonRef={levelsBtnRef}
          classToAvoid="level-label"
          isOpen={showLevelsPopover}
          onClose={() => setShowLevelsPopover(false)}
        />
      )}
    </>
  )
}

export default HomeStats
