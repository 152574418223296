import styled from 'styled-components'

type StyledProps = {
  keyboardHeight?: number
}

const StyledChatSheet = styled.div<StyledProps>`
  overflow: hidden;
  transition: 0.15s;
  height: calc(100% - ${({ keyboardHeight }) => keyboardHeight}px);
  display: grid;
  grid-template-rows: minmax(0, 1fr) auto;
  box-sizing: border-box;
  ${({ keyboardHeight }) => !keyboardHeight && 'padding-bottom: 30px;'}
`

export default StyledChatSheet
