import styled from 'styled-components'

const StyledAccountView = styled.div`
  overflow-y: auto;
  height: 100%;

  .menu-group {
    padding: 8px 0;

    .menu-group-label {
      font-weight: 600;
      font-size: 14px;
      color: ${({ theme }) => theme.color.gray[400]};
      display: block;
      padding: 0 14px;
      margin-bottom: 4px;
    }

    .menu-group-items {
      display: grid;
      gap: 4px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
    }
  }

  .action-group {
    padding: 14px;

    .delete-account-btn {
      width: 100%;
      height: 60px;
      font-weight: 600;
      background-color: ${({ theme }) => theme.color.red[100]};
      color: ${({ theme }) => theme.color.red[800]};
      border-radius: 8px;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
`

export default StyledAccountView
