import { FC, HTMLAttributes } from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
import { SocialProfileType } from '../../../types'
import { getPlatformIcon } from '../../../utils'
import { StyledConnectionAvatar } from '.'

type Props = {
  profile: SocialProfileType
  size?: number
  inRow?: boolean
} & HTMLAttributes<HTMLDivElement>

const ConnectionAvatar: FC<Props> = ({ profile, size = 42, inRow, ...rest }) => {
  const getUserInitials = () => {
    const userSplit = profile.username.trim().split(' ')

    if (userSplit.length == 1) {
      return userSplit[0][0]?.toUpperCase() || ''
    }

    return `${userSplit[0][0]?.toUpperCase() || ''}${userSplit[1][0]?.toUpperCase() || ''}`
  }

  return (
    <StyledConnectionAvatar
      type={profile.type}
      size={size}
      inRow={inRow}
      className="avatar"
      {...rest}
    >
      <div className="avatar-img">
        {profile.avatarUrl && <img src={profile.avatarUrl} alt={profile.username} />}
        {!profile.avatarUrl && getUserInitials()}
      </div>
      {profile.status !== 'expired' && (
        <div className="platform-indicator">{getPlatformIcon(profile.type)}</div>
      )}
      {profile.status === 'expired' && (
        <div className="expired-indicator">
          <ExclamationCircleIcon />
        </div>
      )}
    </StyledConnectionAvatar>
  )
}

export default ConnectionAvatar
