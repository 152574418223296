import { Coordinates } from 'react-advanced-cropper'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type InitialState = {
  isOpen?: boolean
  addToPost?: boolean
  template?: string
  title?: string
  mediaId?: string
  photo?: string
  tab?: 'templates' | 'elements' | 'uploads' | 'text' | 'canvas' | 'photos' | 'stickers' | 'chat'
  subTab?: 'text-font' | 'text-color' | 'fill-color' | 'edit-image'
  imageToCrop?: {
    url: string
    coords?: Coordinates
  }
}

const initialState: InitialState = {
  tab: 'elements',
  addToPost: false
}

export const designerSlice = createSlice({
  name: 'designer',
  initialState,
  reducers: {
    setDesignerMediaId: (state, action: PayloadAction<InitialState['mediaId']>) => {
      state.mediaId = action.payload
    },
    setShowDesigner: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload

      if (!action.payload) {
        state.mediaId = ''
        state.addToPost = false
        state.title = ''
      }
    },
    setDesignerTemplate: (state, action: PayloadAction<InitialState['template']>) => {
      state.template = action.payload
    },
    setDesignerPhoto: (state, action: PayloadAction<InitialState['photo']>) => {
      state.photo = action.payload
    },
    setDesignerTab: (state, action: PayloadAction<InitialState['tab']>) => {
      state.tab = action.payload
      state.subTab = undefined
    },
    setDesignerSubTab: (state, action: PayloadAction<InitialState['subTab']>) => {
      state.subTab = action.payload
    },
    setDesignerImageToCrop: (state, action: PayloadAction<InitialState['imageToCrop']>) => {
      state.imageToCrop = action.payload
    },
    setDesignerAddToPost: (state, action: PayloadAction<boolean>) => {
      state.addToPost = action.payload
    },
    setDesignerTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload
    }
  }
})

export const {
  setDesignerMediaId,
  setShowDesigner,
  setDesignerTemplate,
  setDesignerPhoto,
  setDesignerTab,
  setDesignerSubTab,
  setDesignerImageToCrop,
  setDesignerAddToPost,
  setDesignerTitle
} = designerSlice.actions
export default designerSlice.reducer
