import { FC, InputHTMLAttributes, ReactNode, useContext } from 'react'
import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid'
import { CheckIcon } from '@heroicons/react/24/outline'
import { useAppSelector } from '../../../redux'
import { SocialProfileType } from '../../../types'
import { conditionalStage, hydrateSocialProfiles, userStore } from '../../../utils'
import { StyledCreateEditorSubConfig } from '.'

type GeneralProps = {
  label: string
  description: string
  platforms: SocialProfileType['type'][]
}

type CustomProps = GeneralProps & {
  type: 'custom'
  children: ReactNode
}

type InputProps = GeneralProps & {
  type: 'input'
  name: string
  inputParams?: InputHTMLAttributes<HTMLInputElement>
  onChange: (value: string) => void
}

type RadioProps = GeneralProps & {
  type: 'radio'
  name: string
  options: {
    label: string
    info?: string
    checked?: boolean
    value: string
  }[]
  onSelect: (value: string) => void
}

type CheckboxProps = GeneralProps & {
  type: 'checkbox'
  options: {
    label: string
    name: string
    info?: string
    checked?: boolean
    onSelect: (checked: boolean) => void
  }[]
}

type Props = CustomProps | InputProps | RadioProps | CheckboxProps

const CreateEditorSubConfig: FC<Props> = ({ label, description, platforms, type, ...props }) => {
  const { user } = useContext(userStore)
  const { post } = useAppSelector((state) => state.create)

  const canAccess = () => {
    const profiles = hydrateSocialProfiles(post?.socialProfiles, user)
    return !!profiles.filter((p) => platforms.includes(p.type)).length
  }

  if (!canAccess()) {
    return null
  }

  return (
    <StyledCreateEditorSubConfig>
      <div className="label">
        <span className="label-title">{label}</span>
        <span className="label-description">{description}</span>
      </div>
      {type === 'custom' && (props as CustomProps).children}
      {type === 'input' && (
        <div className="input">
          <input
            type="text"
            {...(props as InputProps).inputParams}
            onChange={(e) => (props as InputProps).onChange(e.target.value)}
          />
        </div>
      )}
      {['radio', 'checkbox'].includes(type) && (
        <div className="options">
          {type === 'radio' &&
            (props as RadioProps).options.map((option) => {
              return (
                <div
                  className="option"
                  data-type={type}
                  key={option.value}
                  {...conditionalStage(!!option.info, {
                    'data-tooltip': option.info,
                    'data-tooltip-pos': 'top-center'
                  })}
                >
                  <input
                    type="radio"
                    name={(props as any).name}
                    value={option.value}
                    checked={option.checked}
                    onChange={() => (props as any).onSelect(option.value)}
                  />
                  <div className="option-box" />
                  <span className="option-label">
                    {option.label} {option.info && <QuestionMarkCircleIcon />}
                  </span>
                </div>
              )
            })}
          {type === 'checkbox' &&
            (props as CheckboxProps).options.map((option) => {
              return (
                <div
                  className="option"
                  data-type={type}
                  key={option.name}
                  {...conditionalStage(!!option.info, {
                    'data-tooltip': option.info,
                    'data-tooltip-pos': 'top-center'
                  })}
                >
                  <input
                    type="checkbox"
                    name={option.name}
                    checked={option.checked}
                    onChange={(e) => option.onSelect(e.target.checked)}
                  />
                  <div className="option-box">{option.checked && <CheckIcon />}</div>
                  <span className="option-label">
                    {option.label} {option.info && <QuestionMarkCircleIcon />}
                  </span>
                </div>
              )
            })}
        </div>
      )}
    </StyledCreateEditorSubConfig>
  )
}

export default CreateEditorSubConfig
