export const getPlanPrice = (plan: 'year' | 'month' | 'trial') => {
  switch (plan) {
    case 'year':
      return 599
    case 'month':
      return 59
    default:
      return 0
  }
}
