import { FC, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import firebase from 'firebase/app'
import { DateTime } from 'luxon'
import { CloudArrowDownIcon } from '@heroicons/react/24/outline'
import { DocumentIcon } from '@heroicons/react/24/solid'
import { InvoiceType } from '../../../types'
import { conditionalStage } from '../../../utils'
import { Button, Spinner } from '../..'
import { StyledInvoiceHistoryTable } from '.'

const InvoiceHistoryTable: FC = () => {
  const listInvoices = firebase.functions().httpsCallable('listInvoices')
  const [loading, setLoading] = useState(true)
  const [hasMore, setHasMore] = useState(false)
  const [invoices, setInvoices] = useState<InvoiceType[]>([])

  const loadInvoices = async (loadMore?: boolean) => {
    try {
      setLoading(true)

      const { data } = await listInvoices({
        limit: 5,
        ...conditionalStage(!!loadMore, {
          after: loadMore ? invoices[invoices.length - 1]!.id : undefined
        })
      })

      setInvoices([...invoices, ...data.data])
      setHasMore(!!data?.has_more)
    } catch (err) {
      toast.error((err as any)?.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setInvoices([])
    loadInvoices()
  }, [])

  return (
    <StyledInvoiceHistoryTable>
      <div className="table-container">
        <div className="table-header">
          <div className="table-column-title">Invoice</div>
          <div className="table-column-title">Billing date</div>
          <div className="table-column-title">Amount</div>
          <div className="table-column-title" />
        </div>
        <div className="table-content">
          {!invoices.length && loading && <Spinner isLarge />}
          {invoices?.map((invoice) => {
            return (
              <div className="table-row" key={invoice.id}>
                <div className="table-column-data invoice-id">
                  <div className="invoice-icon">
                    <DocumentIcon />
                  </div>
                  <span>{invoice.number}</span>
                </div>
                <div className="table-column-data">
                  {DateTime.fromSeconds(invoice.status_transitions.paid_at).toFormat(
                    "LLLL d',' yyyy"
                  )}
                </div>
                <div className="table-column-data">
                  {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                    invoice.amount_paid / 100
                  )}
                </div>
                <div className="table-column-data">
                  <a href={invoice.hosted_invoice_url} target="_blank" rel="noopener noreferrer">
                    <CloudArrowDownIcon /> Download
                  </a>
                </div>
              </div>
            )
          })}
        </div>
        {hasMore && !!invoices.length && (
          <Button isFullWidth onClick={() => loadInvoices(true)}>
            {loading ? <Spinner /> : 'Load More'}
          </Button>
        )}
      </div>
    </StyledInvoiceHistoryTable>
  )
}

export default InvoiceHistoryTable
