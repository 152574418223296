import styled from 'styled-components'

const StyledShareSheet = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  padding: 0 20px calc(var(--bottom-spacing) + 10px) 20px;

  .share-btn {
    background-color: transparent;

    .share-btn-icon {
      padding-top: 100%;
      border-radius: 50%;
      position: relative;
      background-color: ${({ theme }) => theme.color.gray[200]};
      color: ${({ theme }) => theme.color.gray[600]};

      svg {
        height: 38px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        stroke-width: 1.75px;
      }
    }

    .share-btn-label {
      font-size: 12px;
      margin-top: 5px;
      color: ${({ theme }) => theme.color.gray[600]};
      font-weight: 600;
    }
  }
`

export default StyledShareSheet
