import { FC, useContext } from 'react'
import { useAppSelector } from '../../../redux'
import { formatFileSize, userStore } from '../../../utils'
import { StyledUploadsStorage } from '.'

const UploadsStorage: FC = () => {
  const { user } = useContext(userStore)
  const { storage } = useAppSelector((state) => state.cloud)
  const STORAGE_LIMIT = storage.limit * (user?.subscriptionInterval === 'Year' ? 2 : 1)

  return (
    <StyledUploadsStorage>
      <div className="header">
        <div className="label">STORAGE</div>
      </div>
      <div className="storage-indicator-container">
        <progress max="100" value={(storage.current / STORAGE_LIMIT) * 100}>
          {(storage.current / STORAGE_LIMIT) * 100}%
        </progress>
        <span className="storage-label">
          <b>{storage.current ? formatFileSize(storage.current) : '0GB'}</b> out{' '}
          {formatFileSize(STORAGE_LIMIT)} used
        </span>
      </div>
    </StyledUploadsStorage>
  )
}

export default UploadsStorage
