import styled from 'styled-components'

const StyledTrialCta = styled.div`
  border-radius: 8px;
  padding: 20px;
  background-color: ${({ theme }) => theme.color.brandLight};
  margin-bottom: 20px;

  .trial-cta-header {
    display: flex;
    align-items: center;

    h3 {
      font-weight: 500;
      font-size: 20px;
      color: ${({ theme }) => theme.color.gray[800]};

      b {
        font-weight: 700;
      }
    }

    svg {
      margin-right: 6px;
      color: ${({ theme }) => theme.color.brand};
      height: 24px;
      flex-shrink: 0;
    }
  }

  span {
    margin-top: 6px;
    display: block;
    color: ${({ theme }) => theme.color.gray[700]};
  }

  .btn {
    margin-top: 20px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.m}px) {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn {
      margin-top: 0;
    }
  }
`

export default StyledTrialCta
