export const day10 = {
  _id: 'day10',
  title: 'DAY 10',
  tasks: [
    {
      label:
        'Introduce yourself in the “Say Hello” channel (or leave a comment on the most recent intro post instead!)',
      steps: [
        // {
        //   label: 'Navigate to the Community using the sidebar'
        // },
        // {
        //   label: 'Click on the “Say Hello” channel'
        // },
        // {
        //   label: 'Click the “Start a post” button'
        // },
        // {
        //   label: 'When you’re finished writing, click the “Publish post” button'
        // }
      ]
    },
    {
      label: 'Like someone else’s post in “Business Conversations”',
      steps: [
        // {
        //   label: 'Click the like (👍) button on a post'
        // }
      ]
    },
    {
      label: 'Watch a class replay',
      steps: [
        // {
        //   label: 'Click on the “Class Replays” channel'
        // },
        // {
        //   label: 'Click on a post'
        // },
        // {
        //   label: 'Click play on the video (no need to actually watch the video)'
        // }
      ]
    },
    {
      label: 'RSVP to a live event',
      steps: [
        // {
        //   label: 'Click on the “Live Events” channel'
        // },
        // {
        //   label: 'Click on the “RSVP” button on an event'
        // }
      ]
    },
    {
      label: 'Send a DM to Jamel Hammoud with the text “I just finished Day #10”',
      steps: [
        // {
        //   label:
        //     'Search “Jamel Hammoud” in the community (Click “members” on the sidebar and then search by “name”'
        // },
        // {
        //   label: 'On the user profile, click the “Message” button'
        // },
        // {
        //   label: 'Type “I just finished Day #10” and click the “Send” button'
        // }
      ]
    }
  ],
  form: {
    url: 'https://notionforms.io/forms/day-10-feedback',
    keys: {
      FULL_NAME: 'daa6686e-8d0d-4d74-bd0b-50645091af7f',
      EMAIL: '423948eb-ab10-42b6-a2e7-b9f600b16607',
      SCREEN_RECORDING: 'bfff45b1-1915-4bfc-ad40-b122acb7d77f',
      USER_ID: '48242179-86ee-43ed-afb0-994190c1d750'
    }
  }
}
