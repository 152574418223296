import { useState } from 'react'

type FormType = {
  [x: string]: any
}

export const useForm = (formValues: FormType) => {
  const [form, setForm] = useState<FormType>(formValues)

  const updateFormValue = (formValue: string | object, value?: any) => {
    if (typeof formValue === 'object') {
      return setForm({
        ...form,
        ...formValue
      })
    }

    setForm({
      ...form,
      [formValue]: value
    })
  }

  const resetForm = () => {
    const clearedForm = Object.keys(form).map((key) => (form[key] = undefined))
    setForm(clearedForm)
  }

  return {
    form,
    updateFormValue,
    resetForm
  }
}
