import { FC } from 'react'
import classNames from 'classnames'
import { CheckIcon } from '@heroicons/react/24/outline'
import { StyledCheckbox } from '.'

type Props = {
  label?: string
  value?: boolean
  onChange: (value: boolean) => void
}

const Checkbox: FC<Props> = ({ label, value, onChange }) => {
  return (
    <StyledCheckbox className={classNames('checkbox-container', { checked: value })}>
      <input type="checkbox" checked={value} onChange={(e) => onChange(e.target.checked)} />
      <div className="check-box">
        <CheckIcon />
      </div>
      {label && <span className="check-box-label">{label}</span>}
    </StyledCheckbox>
  )
}

export default Checkbox
