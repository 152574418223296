import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { UploadType } from '../../types'

type InitialState = {
  files: UploadType[]
  isVisible: boolean
  isCollapsed: boolean
}

const initialState: InitialState = {
  files: [],
  isVisible: false,
  isCollapsed: false
}

export const uploadSlice = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    addUploadFiles: (state, action: PayloadAction<InitialState['files']>) => {
      state.files = [...action.payload, ...state.files]
    },
    clearUploadFiles: (state) => {
      state.files = []
    },
    updateFileStatus: (
      state,
      action: PayloadAction<{ _id: string; status: InitialState['files'][number]['status'] }>
    ) => {
      state.files = state.files.map((file) => {
        if (file._id === action.payload._id) {
          return {
            ...file,
            status: action.payload.status
          }
        }
        return file
      })
    },
    setShowUploadManager: (state, action: PayloadAction<boolean>) => {
      state.isVisible = action.payload

      if (action.payload) {
        state.isCollapsed = false
      }
    },
    setUploadManagerCollapsed: (state, action: PayloadAction<boolean>) => {
      state.isCollapsed = action.payload
    }
  }
})

export const {
  addUploadFiles,
  clearUploadFiles,
  updateFileStatus,
  setShowUploadManager,
  setUploadManagerCollapsed
} = uploadSlice.actions
export default uploadSlice.reducer
