import styled from 'styled-components'

const StyledProfileDrawer = styled.div`
  padding: 14px;

  .inputs {
    display: grid;
    gap: 14px;
  }
`

export default StyledProfileDrawer
