import { useContext, useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useReward } from 'react-rewards'
import firebase from 'firebase/app'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeCardElement } from '@stripe/stripe-js'
import { clearCoupon, setCard, setPlan, useAppDispatch, useAppSelector } from '../../redux'
import { userStore } from '..'

export const useSubscription = () => {
  const { user, refresh } = useContext(userStore)
  const { card, plan } = useAppSelector((state) => state.subscription)
  const { coupon } = useAppSelector((state) => state.state)
  const [loading, setLoading] = useState(true)
  const isInitialMount = useRef(true)
  const dispatch = useAppDispatch()
  const stripe = useStripe()
  const elements = useElements()

  const { reward } = useReward('confetti', 'confetti', {
    angle: -90,
    startVelocity: 18,
    spread: 220,
    elementCount: 150
  })

  const startSubscription = firebase.functions().httpsCallable('startSubscription')
  const updateSubscription = firebase.functions().httpsCallable('updateSubscription')
  const attachPaymentMethod = firebase.functions().httpsCallable('attachPaymentMethod')
  const getSubscription = firebase.functions().httpsCallable('getSubscription')
  const cancelSubscription = firebase.functions().httpsCallable('cancelSubscription')
  const reactivateSubscription = firebase.functions().httpsCallable('reactivateSubscription')
  const getPaymentInfo = firebase.functions().httpsCallable('getPaymentInfo')

  const loadCard = async () => {
    if (!user?.id) {
      return
    }

    const { data } = await getPaymentInfo()

    if (!data?.card) {
      return
    }

    if (!data.card.exp_month) {
      return
    }

    const card = data.card
    const expiryMonth = card?.exp_month?.toString()

    dispatch(
      setCard({
        brand: `${card.brand[0]?.toUpperCase()}${card.brand.slice(1)}`,
        last4: card.last4,
        expMonth: expiryMonth.padStart(2, '0'),
        expYear: card.exp_year
      })
    )
  }

  const loadPlan = async () => {
    if (!user?.id) {
      return
    }

    const { data } = await getSubscription()

    if (!data) {
      return
    }

    const discount = data?.discount?.coupon?.amount_off || 0

    dispatch(
      setPlan({
        _id: data.id,
        startDate: data.current_period_start,
        endDate: data.current_period_end,
        cancelDate: data.cancel_at || undefined,
        interval: data?.plan?.interval,
        amount: (data.plan.amount - discount) / 100,
        renews: !data.cancel_at_period_end,
        status: data.status
      })
    )
  }

  const attachCard = async () => {
    if (!user?.id) {
      return
    }

    if (!stripe || !elements) {
      return
    }

    const cardElement = elements.getElement(CardElement)

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement as StripeCardElement
    })

    if (error) {
      throw error.message
    }

    await attachPaymentMethod({ paymentMethodId: paymentMethod?.id })
  }

  const resubscribePlan = async () => {
    if (!user?.id) {
      return
    }

    const toastId = toast.loading('Reactivating plan...')
    await reactivateSubscription()
    dispatch(clearCoupon())
    await refresh()
    await loadSubscriptionDetails()
    reward()
    toast.dismiss(toastId)
    toast.success('Plan reactivated!')
    // window.location.reload()
  }

  const cancelPlan = async () => {
    if (!user?.id) {
      return
    }

    await cancelSubscription({})
    await refresh()
    await loadSubscriptionDetails()
    // window.location.reload()

    // if (subscriptionInfo && subscriptionInfo.end) {
    //   window._refiner('showForm', surveys.cancellationSurvey)
    // }
  }

  const upgradePlan = async (interval: 'Month' | 'Year') => {
    try {
      if (!user?.id) {
        return
      }

      if (!card) {
        await attachCard()
      }

      const { data } = await getSubscription()

      if (!data) {
        await startSubscription({
          subscriptionInterval: interval
        })
        dispatch(clearCoupon())
        await refresh()
        reward()
        return
      }

      await updateSubscription({
        interval,
        subscriptionId: data.id,
        couponID:
          coupon?.discount[interval.toLowerCase() as keyof typeof coupon.discount]?.id ||
          (!!user?.trial && interval === 'Year' ? 'trial-to-annual-50' : undefined)
      })
      await loadSubscriptionDetails()
      dispatch(clearCoupon())
      await refresh()
      reward()
    } catch (err) {
      console.error(err)
      toast.error((err as any).message)
    }
  }

  const loadSubscriptionDetails = async () => {
    try {
      setLoading(true)
      await Promise.all([loadCard(), loadPlan()])
    } catch (err) {
      console.error(err)
      toast.error((err as any).message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!!plan && !!card) {
      setLoading(false)
      return
    }

    if (!user?.id) {
      return
    }

    loadSubscriptionDetails()
  }, [user])

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
      return
    }

    if (!user?.id) {
      return
    }

    loadSubscriptionDetails()
  }, [user?.id, user?.stripeSubscriptionStatus, user?.subscriptionInterval, user?.trial])

  return {
    loading,
    card,
    plan,
    actions: {
      resubscribePlan,
      cancelPlan,
      loadCard,
      upgradePlan,
      attachCard,
      loadSubscriptionDetails
    }
  }
}
