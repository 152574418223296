import styled from 'styled-components'

type StyledProps = {
  scale?: 'lg' | 'md' | 'sm'
  icon?: boolean
  variant?: 'outline' | 'fill' | 'shadow'
}

const StyledInput = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s;

  .pre-text {
    padding: 0 0 0 12px;
    cursor: text;
    flex-shrink: 0;
    color: ${({ theme }) => theme.color.gray[500]};
    user-select: none;
  }

  input {
    display: flex;
    width: 100%;
    background-color: transparent;
    color: ${({ theme }) => theme.color.gray[600]};
    transition: color 0.2s;

    &::placeholder {
      pointer-events: none;
      user-select: none;
      color: ${({ theme }) => theme.color.gray[400]};
    }
  }

  .max-length-indicator {
    position: absolute;
    right: 10px;
    pointer-events: none;
    color: ${({ theme }) => theme.color.gray[500]};
    font-size: 14px;
  }

  svg {
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: ${({ theme }) => theme.color.gray[400]};

    path {
      stroke-width: 1.75px;
    }
  }

  ${({ scale, icon }) =>
    scale === 'lg' &&
    `
    input {
      height: 50px;
      padding: 0 0 0 ${icon ? '40px' : '14px'};
    }

    svg {
      height: 22px;
    }
  `}

  ${({ scale, icon }) =>
    scale === 'md' &&
    `
    input {
      height: 40px;
      padding: 0 0 0 ${icon ? '40px' : '12px'};
    }

    svg {
      height: 22px;
    }
  `}

  ${({ scale, icon }) =>
    scale === 'sm' &&
    `
    input {
      height: 38px;
      padding: 0 0 0 ${icon ? '38px' : '10px'};
    }

    svg {
      height: 20px;
    }
  `}
  
  ${({ variant, theme }) =>
    variant === 'outline' &&
    `
    background-color: ${theme.color.background};
    border: 1px solid ${theme.color.gray[300]};

    &:hover, &:focus-within {
      border: 1px solid ${theme.color.gray[400]};
    }
  `}
  
  ${({ variant, theme }) =>
    variant === 'fill' &&
    `
    background-color: ${theme.color.gray[100]};

    &:hover, &:focus-within {
      background-color: ${theme.color.gray[200]};
    }
  `}
  
  ${({ variant, theme }) =>
    variant === 'shadow' &&
    `
    background-color: ${theme.color.background};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    border: 1px solid ${theme.color.gray[300]};
    border-radius: 6px;

    input {
      color: ${theme.color.gray[800]};
    }

    &:hover, &:focus-within {
      border: 1px solid ${theme.color.gray[400]};
    }
  `}

  input:disabled {
    color: ${({ theme }) => theme.color.gray[400]};
    -webkit-text-fill-color: ${({ theme }) => theme.color.gray[400]};
    opacity: 1;

    &:hover {
      cursor: not-allowed;
    }
  }
`

export default StyledInput
