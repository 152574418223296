import { createRef, FC, FormEvent, useEffect, useState } from 'react'
import { HashtagIcon } from '@heroicons/react/24/outline'
import { useHashtag } from '../../../utils'
import { Button, Dialog, Input } from '../..'
import { StyledNewHashtagDialog } from '.'

type Props = {
  folder?: string
  isOpen: boolean
  onClose: () => void
}

const NewHashtagDialog: FC<Props> = ({ folder, isOpen, onClose }) => {
  const [hashtag, setHashtag] = useState('')
  const { createHashtag } = useHashtag()
  const dialog = createRef<HTMLDivElement>() as any

  const handleAddHashtag = async (e: FormEvent) => {
    e.preventDefault()

    if (!hashtag.trim().length) {
      return
    }

    await createHashtag(hashtag, folder)
    onClose()
  }

  const handleHashtag = (value: string) => {
    const formattedValue = value.toLowerCase().replaceAll(' ', '-')
    setHashtag(formattedValue)
  }

  useEffect(() => {
    setHashtag('')
  }, [isOpen])

  return (
    <Dialog ref={dialog} isOpen={isOpen} onClose={onClose}>
      <StyledNewHashtagDialog>
        <form onSubmit={(e) => handleAddHashtag(e)}>
          <Input
            icon={<HashtagIcon />}
            value={hashtag}
            placeholder="your-hashtag"
            autoFocus
            onChange={(e) => handleHashtag(e.target.value)}
          />
          <Button variant="emphasis" disabled={!hashtag.trim().length} isFullWidth>
            Add hashtag
          </Button>
        </form>
      </StyledNewHashtagDialog>
    </Dialog>
  )
}

export default NewHashtagDialog
