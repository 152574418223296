import { gql } from '@apollo/client'

export const getIssueAssetsQuery = gql`
  query GetIssueAssetsQuery($issue: String!) {
    storySetFindMany(filter: { issue: $issue }, sort: INDEX_ASC) {
      _id
      title
      canvaLink
      description
      index
      thumbnail
      records {
        _id
        fullPath
        imageUrl
        index
      }
    }

    captionSetSearch(issue: $issue, sort: "index", items: 50, page: 0, categories: []) {
      docs {
        _id
        category
        caption
        captionPlainText
        title
        category
        issue
        issueTitle

        userCaption {
          _id
          liked
          used
          user
        }
      }
      totalDocs
    }

    captionSetFindMany(filter: { issue: $issue, showInIssue: true }, sort: INDEX_ASC) {
      _id
      category
      title
      thumbnail
      caption
      captionPlainText
      photoIdeas
      records {
        _id
        fullPath
        imageUrl
        index
        captionSet
        migrated
        updatedAt
        createdAt
      }
    }

    extraSetFindMany(filter: { issue: $issue }, sort: INDEX_ASC) {
      _id
      fileType
      contentType
      image {
        fileHttpLink
      }
      file {
        fileHttpLink
      }
      videoLink
      title
      thumbnail {
        fileHttpLink
      }
    }
  }
`
