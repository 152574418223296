import { FC, createRef, useCallback, useState } from 'react'
import { ReactComponent as NewFolderIcon } from '../../../assets/new-folder-icon.svg'
import {
  setCloudFiles,
  setCloudFolders,
  setCloudParent,
  setCreateUploadsTab,
  useAppDispatch,
  useAppSelector
} from '../../../redux'
import { useCloud, useUploadManager } from '../../../utils'
import {
  Button,
  CreateBarDesignsList,
  CreateBarPanel,
  CreateBarUploadsList,
  InputDialog,
  Tabs
} from '../..'
import { StyledCreateBarUploads } from '.'

const CreateBarUploads: FC = () => {
  const dispatch = useAppDispatch()
  const inputRef = createRef<HTMLInputElement>()
  const [showNewFolderDialog, setShowNewFolderDialog] = useState(false)
  const [newFolderName, setNewFolderName] = useState('')
  const { createFolder } = useCloud()
  const { addToUploads } = useUploadManager()
  const { uploadsTab } = useAppSelector((state) => state.create)
  const { parent } = useAppSelector((state) => state.cloud)

  const handleFile = useCallback(
    (files: File[]) => {
      addToUploads(files, false, parent)
    },
    [parent]
  )

  return (
    <>
      <CreateBarPanel>
        <StyledCreateBarUploads>
          <div className="uploads-bar-header">
            <div className="uploads-bar-header-top">
              <Button className="upload-btn" isFullWidth onClick={() => inputRef?.current?.click()}>
                Upload photos or videos
                <input
                  type="file"
                  multiple
                  accept="image/*, video/*"
                  hidden
                  ref={inputRef}
                  onChange={(e) => handleFile(Array.from(e.target.files || []))}
                />
              </Button>
            </div>
            <div className="tab-container">
              <Tabs
                tabs={[
                  {
                    label: 'Photos & Videos',
                    isActive: uploadsTab === 'uploads',
                    onClick: () => {
                      dispatch(setCreateUploadsTab('uploads'))

                      if (!!parent) {
                        dispatch(setCloudFolders([]))
                        dispatch(setCloudFiles([]))
                        dispatch(setCloudParent(''))
                      }
                    }
                  }
                  // {
                  //   label: 'Designs',
                  //   isActive: uploadsTab === 'designs',
                  //   onClick: () => dispatch(setCreateUploadsTab('designs'))
                  // }
                ]}
              />
              {!parent && (
                <button
                  data-tooltip="Create new folder"
                  data-tooltip-pos="bottom-end"
                  className="new-folder-btn"
                  onClick={() => setShowNewFolderDialog(true)}
                >
                  <NewFolderIcon />
                </button>
              )}
            </div>
          </div>
          {uploadsTab === 'uploads' && <CreateBarUploadsList />}
          {uploadsTab === 'designs' && <CreateBarDesignsList />}
        </StyledCreateBarUploads>
      </CreateBarPanel>
      <InputDialog
        isOpen={showNewFolderDialog}
        onChange={(value) => setNewFolderName(value)}
        onClose={() => setShowNewFolderDialog(false)}
        onConfirm={() => createFolder(newFolderName)}
        title="Create a new folder"
        value={newFolderName}
        confirmButtonText="Create folder"
      />
    </>
  )
}

export default CreateBarUploads
