import { FC, useContext } from 'react'
import { AtSymbolIcon, LinkIcon } from '@heroicons/react/24/outline'
import { Button, ImageInput, Input, Textarea } from '../../../..'
import { setNewAvatar, updatePage, useAppDispatch, useAppSelector } from '../../../../../redux'
import { formatUsername, userStore } from '../../../../../utils'
import { StyledLinkPageProfileTab } from '.'

const LinkPageProfileTab: FC = () => {
  const dispatch = useAppDispatch()
  const { user } = useContext(userStore)
  const { page } = useAppSelector((state) => state.page)

  return (
    <StyledLinkPageProfileTab>
      <div className="form-input-container">
        <span className="input-label">Page Photo</span>
        <ImageInput
          value={page?.avatar || ''}
          isSquare
          onChange={(file, preview) => dispatch(setNewAvatar({ file, preview }))}
        />
      </div>
      <label>
        <span className="input-label">Page Username</span>
        <Input
          value={page?.username}
          icon={<AtSymbolIcon />}
          maxLength={30}
          onChange={(e) => dispatch(updatePage({ username: e.target.value }))}
        />
      </label>
      <label>
        <span className="input-label">Page Name</span>
        <Input
          value={page?.name}
          maxLength={30}
          onChange={(e) => dispatch(updatePage({ name: e.target.value }))}
        />
      </label>
      <label>
        <span className="input-label">Page Bio</span>
        <Textarea
          value={page?.description}
          maxLength={150}
          onChange={(e) => dispatch(updatePage({ description: e.target.value }))}
        />
      </label>
      {/* <hr />
      <div className="short-link-form">
        <label className="short-link-input-container">
          <span className="input-label">Add a social icon</span>
          <span className="input-subtext">
            Shortlinks appear on the bottom of your Link Page as icons.
          </span>
          <div className="short-link-input">
            <Button></Button>
            <Input icon={<LinkIcon />} placeholder="Type your shortlink here" />
          </div>
        </label>
        <div className="short-link-input-suggestions">
          {user?.socialProfiles.filter((profile) => page?.profiles.).map(profile => {
            return (
              <div className="short-link-input-suggestion">
                {formatUsername(profile.username, profile.type)}
              </div>
            )
          })}
        </div>
      </div> */}
    </StyledLinkPageProfileTab>
  )
}

export default LinkPageProfileTab
