import { FC, useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { matchPath, useLocation } from 'react-router-dom'
import { Capacitor } from '@capacitor/core'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import {
  BoltIcon,
  ChatBubbleLeftRightIcon,
  CloudIcon,
  DocumentIcon,
  PencilIcon,
  QueueListIcon
} from '@heroicons/react/24/solid'
import { pushToDialogStack, useAppDispatch } from '../../redux'
import { ROUTES, useIntercom, userStore } from '../../utils'
import { StyledUpsell } from '.'

type DataType = {
  title: string
  feature: string
  description: string
  faq?: string
  plural?: boolean
}

const Upsell: FC = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const { user } = useContext(userStore)
  const { showIntercomMessenger } = useIntercom()
  const [data, setData] = useState<DataType>()
  const routeList = [
    {
      path: ROUTES.home,
      title: 'Your Pro Plan has expired',
      description: 'We miss you. Go Pro to get back into it.',
      exact: true,
      asIs: true
    },
    {
      path: ROUTES.create,
      title: 'Dottie',
      description: 'creating & publishing your posts',
      faq: 'Create social media posts in less than 5 minutes. Fill in the blanks of one of our caption templates. Upload your own photo or choose a lifestyle photo from the Gallery to pair with your caption. Simply customize, copy, paste, and post…then watch the engagement pour in!'
    },
    {
      path: ROUTES.calendar,
      title: 'Calendar',
      description: 'scheduling & planning your posts',
      faq: 'The Calendar is your one-stop-shop for planning out your social media posts.'
    },
    {
      path: ROUTES.cloud.all,
      title: 'Uploads',
      description: 'uploading your own images',
      faq: 'Your Uploads is where you upload, store and organize media you want to use in your posts.'
    },
    {
      path: ROUTES.contentLibrary.main,
      title: 'Content Library',
      description: 'using our content templates',
      faq: "The Caption Library has 3000+ customizable caption templates to use as inspiration and foster engagement with your audience. In the Caption Library, you can even make collections of your favorites, edit the templates for easy posting, and mark your completed captions as 'used'!"
    },
    {
      path: ROUTES.gallery,
      title: 'Photos & Stories',
      description: 'browsing through the gallery',
      plural: true,
      faq: "We know what it's like to spend HOURS trying to find the perfect light to take a photo... all for one social media post. With Social Curator, you have access to an ever-growing library of thousands of stunning lifestyle photos."
    },
    {
      path: ROUTES.actionPlans,
      title: 'Action Plans',
      description: 'learning the latest marketing strategies',
      plural: true,
      faq: "Action Plans teach you how to show up confidently on social media, knowing you're using the most effective and proven marketing strategies."
    },
    {
      path: ROUTES.community,
      title: 'Community',
      description: 'engaging with like-minded business owners',
      faq: 'Become a part of an active community of thousands of other Curators.'
    },
    {
      path: ROUTES.linkPage.page,
      title: 'Link Page',
      description: 'sharing your links on social',
      faq: 'Your Link Page helps you share & track your links on social.'
    }
  ]

  const formatDescription = (description: string) => {
    if (user?.stripeSubscriptionStatus === 'Active') {
      return `Go Pro to start ${description}.`
    }

    return `Go Pro to continue ${description}.`
  }

  const formatTitle = (title: string, pural?: boolean) => {
    if (user?.stripeSubscriptionStatus === 'Active' && pural) {
      return `${title} are not included in your trial`
    }

    if (user?.stripeSubscriptionStatus === 'Active') {
      return `The ${title} is not included in your trial`
    }

    if (pural) {
      return `You've lost access to ${title}`
    }

    return `You've lost access to the ${title}`
  }

  const loadDetails = () => {
    const activeRoute = routeList.find((route) => {
      return matchPath(location.pathname || '/', {
        path: route.path,
        exact: route.exact
      })
    })

    if (!activeRoute) {
      return setData({
        title: formatTitle('Feature'),
        feature: 'Feature',
        description: formatDescription('making the most of Social Curator')
      })
    }

    setData({
      title: activeRoute?.asIs
        ? activeRoute.title
        : formatTitle(activeRoute.title, activeRoute.plural),
      feature: activeRoute.title,
      description: activeRoute?.asIs
        ? activeRoute.description
        : formatDescription(activeRoute.description),
      faq: activeRoute.faq,
      plural: activeRoute.plural
    })
  }

  useEffect(() => {
    loadDetails()
  }, [location.pathname])

  return (
    <StyledUpsell>
      <Helmet title="Go Pro | Social Curator" />
      <div className="upsell-container">
        <div className="icon-row">
          <div>
            <PencilIcon />
          </div>
          <div>
            <CloudIcon />
          </div>
          <div>
            <ChatBubbleLeftRightIcon />
          </div>
          <div>
            <QueueListIcon />
          </div>
          <div>
            <DocumentIcon />
          </div>
        </div>
        <h1>{data?.title}</h1>
        <h2>{data?.description}</h2>
        <div className="go-pro-faq">
          {!!data?.faq && (
            <details>
              <summary>
                What {data?.plural ? 'are' : 'is the'} {data?.feature}? <ChevronDownIcon />
              </summary>
              <div className="summary-content">{data.faq}</div>
            </details>
          )}
          <details>
            <summary>
              What is "Pro"? <ChevronDownIcon />
            </summary>
            <div className="summary-content">
              Pro gives you access to the entire Social Curator Platform, where you can create and
              autopost unlimited social posts, access 8000+ captions, photos, and stories, engage
              with an active Community of thousands of business owners, and learn the latest
              marketing strategies through classes and step-by-step educational Action Plans.
            </div>
          </details>
          <details>
            <summary>
              How do I Go Pro? <ChevronDownIcon />
            </summary>
            <div className="summary-content">
              That's easy! Click the "Go Pro" button below. Have questions?{' '}
              <a onClick={() => showIntercomMessenger()}>Reach out to our support team</a> and we'll
              get you taken care of!
            </div>
          </details>
        </div>
        {!Capacitor.isNativePlatform() && (
          <button className="go-pro-btn" onClick={() => dispatch(pushToDialogStack('go-pro'))}>
            <BoltIcon />
            {user?.stripeSubscriptionStatus === 'Active' ? "It's Time" : 'Come back'}, Go Pro
          </button>
        )}
      </div>
    </StyledUpsell>
  )
}

export default Upsell
