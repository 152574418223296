import styled from 'styled-components'

const StyledActionPlanExtras = styled.div`
  .action-plan-extras-list {
    display: grid;
    grid-gap: 14px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

  &:not(:first-child) {
    margin-top: 20px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    &:not(:first-child) {
      margin-top: 40px;
    }
  }
`

export default StyledActionPlanExtras
