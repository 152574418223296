import { ShepherdOptionsWithType } from 'react-shepherd'
import { removeEmptySteps } from '..'

export const generateTour = (isMobile: boolean): ShepherdOptionsWithType[] => {
  const steps: ShepherdOptionsWithType[] = [
    {
      title: "Hashtags? We've got you covered.",
      text: 'You can use the Hashtag Bank to store, organize and use your hashtags.',
      attachTo: {
        element: '[data-tour-id="post-editor-hashtags-tab"]',
        on: 'left-start'
      },
      canClickTarget: false,
      buttons: [
        {
          text: 'Awesome!',
          type: 'next'
        }
      ]
    }
  ]
  return removeEmptySteps(steps as any)
}
