import { FC, MouseEvent } from 'react'
import { useHistory } from 'react-router-dom'
import { ChevronRightIcon, PhotoIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useAppSelector } from '../../../redux'
import { ROUTES, useCreate, useSegment } from '../../../utils'
import { Button, CloudMediaPreview } from '../..'
import { SidebarUpgradeCta } from '..'
import { StyledSidebarPostButton } from '.'

const SidebarPostButton: FC = () => {
  const history = useHistory()
  const { post } = useAppSelector((state) => state.create)
  const { track } = useSegment()
  const { newPost } = useCreate()

  const handleClearPost = (e: MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    newPost()
    track('Cleared Editor Post')
  }

  if (
    !post?.captionText &&
    !post?.media?.length &&
    !post?.title &&
    !post?._id &&
    !post?.postDate &&
    !post?.tags?.length
  ) {
    return <SidebarUpgradeCta />
  }

  return (
    <StyledSidebarPostButton
      className="post-btn"
      id="sidebar-post-btn"
      onClick={() => history.push(ROUTES.create)}
    >
      <Button
        data-tooltip="Close Editor"
        data-tooltip-pos="top-center"
        size="sm"
        className="clear-btn"
        variant="emphasis"
        isIcon
        onClick={(e) => handleClearPost(e)}
      >
        <XMarkIcon />
      </Button>
      <div className="post-content">
        <div className="post-content-img">
          <div className="post-indicators">
            {!!post.media && post.media?.length > 1 && (
              <div className="post-indicator">
                <span>{post.media.length}</span>
              </div>
            )}
          </div>
          {!!post?.media?.length ? (
            <CloudMediaPreview type={post.media[0].type || 'image'} url={post.media[0].url} />
          ) : (
            <PhotoIcon className="photo-icon" />
          )}
        </div>
        <div className="post-content-caption">
          {post.captionText || <span className="empty-caption">Empty caption</span>}
        </div>
      </div>
      <Button variant="emphasis" className="continue-btn" iconPos="right">
        Continue Post <ChevronRightIcon />
      </Button>
    </StyledSidebarPostButton>
  )
}

export default SidebarPostButton
