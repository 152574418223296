import { useContext, useEffect, useRef } from 'react'
import { useApolloClient } from '@apollo/client'
import { FCM } from '@capacitor-community/fcm'
import { Capacitor } from '@capacitor/core'
import { PushNotifications } from '@capacitor/push-notifications'
import { Badge } from '@robingenz/capacitor-badge'
import { userDraftFindByIdQuery } from '../../graphql'
import {
  clearCreatePost,
  setCreatePost,
  setShowCreate,
  useAppDispatch,
  useAppSelector
} from '../../redux'
import { PostType } from '../../types'
import { userStore } from '..'

export const usePushNotifications = () => {
  const dispatch = useAppDispatch()
  const postList = useRef<PostType[]>([])
  const apollo = useApolloClient()
  const available = Capacitor.isPluginAvailable('PushNotifications')
  const { posts } = useAppSelector((state) => state.posts)
  const { user } = useContext(userStore)

  const requestNotificationPermissions = async () => {
    if (!available) return

    await PushNotifications.requestPermissions()
  }

  const subscribeToNotifications = async () => {
    try {
      if (!available) return

      await PushNotifications.requestPermissions()
      await PushNotifications.register()

      await FCM.subscribeTo({ topic: user!.id })
      await FCM.setAutoInit({ enabled: true })

      // Handles clicking notifications
      PushNotifications.addListener('pushNotificationActionPerformed', async (push) => {
        const notificationType = push.notification?.data?.type

        switch (notificationType) {
          case 'time-to-post':
          case 'auto-posted':
            const postId = push.notification?.data?.post
            const { data } = await apollo.query({
              query: userDraftFindByIdQuery,
              fetchPolicy: 'no-cache',
              variables: { _id: postId }
            })
            const post = data.userDraftFindById

            if (!!post) {
              dispatch(clearCreatePost())
              dispatch(
                setCreatePost({
                  ...post,
                  isUsed: !!(notificationType === 'auto-posted')
                })
              )
              dispatch(setShowCreate(true))

              return
            }

            return
        }
      })
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (!available) return

    Badge.clear()

    return () => {
      if (!available) return

      PushNotifications.removeAllListeners()
    }
  }, [])

  useEffect(() => {
    postList.current = posts
  }, [posts])

  return {
    subscribeToNotifications,
    requestNotificationPermissions
  }
}
