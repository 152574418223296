import { CheckCircleIcon, CloudIcon } from '@heroicons/react/20/solid'
import { HashtagIcon } from '@heroicons/react/24/outline'
import { ChatIcon } from '../../assets'
import { ReactComponent as ContentIcon } from '../../assets/content-solid-icon.svg'

export const CREATE_TABS = [
  {
    _id: 'chat',
    label: 'Dottie',
    icon: <ChatIcon />
  },
  {
    _id: 'uploads',
    label: 'Uploads',
    icon: <CloudIcon />
  },
  {
    _id: 'content',
    label: 'Content',
    icon: <ContentIcon />
  },
  {
    _id: 'hashtags',
    label: 'Hashtags',
    icon: <HashtagIcon />
  },
  {
    _id: 'tasks',
    label: 'Tasks',
    icon: <CheckCircleIcon />
  }
] as const
