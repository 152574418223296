export const slugify = (text: string = 'untitled') => {
  const formattedSlug = text
    .trim()
    .replace(/\s+/g, ' ')
    .replace(/[^a-z0-9\s]+/gi, '')
    .trim()
    .replace(/\s/g, '-')
    .toLowerCase()

  return formattedSlug
}
