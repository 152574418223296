import { FC, createRef, useContext, useState } from 'react'
import classNames from 'classnames'
import {
  BellIcon,
  BoltIcon,
  CalendarIcon,
  ExclamationCircleIcon,
  CheckCircleIcon as SolidCheckCircleIcon,
  HeartIcon as SolidHeartIcon
} from '@heroicons/react/20/solid'
import {
  CheckCircleIcon,
  DocumentDuplicateIcon,
  EllipsisHorizontalIcon,
  HeartIcon,
  PaperClipIcon,
  TrashIcon
} from '@heroicons/react/24/outline'
import { DocumentTextIcon } from '@heroicons/react/24/solid'
import { ReactComponent as EditorIcon } from '../../../assets/editor-icon.svg'
import { useAppSelector } from '../../../redux'
import { PostType } from '../../../types'
import {
  getRelativeString,
  hydrateSocialProfiles,
  socialProfileByStatus,
  theme,
  useCreate,
  usePost,
  usePostErrors,
  userStore
} from '../../../utils'
import { CloudMediaPreview, ConnectionAvatar, OptionsPopover } from '../..'
import { StyledPostGridListItem } from '.'

type Props = {
  post: PostType
}

const PostGridListItem: FC<Props> = ({ post }) => {
  const { user } = useContext(userStore)
  const { errors } = usePostErrors(post)
  const { post: activePost } = useAppSelector((state) => state.create)
  const { setEditorPost } = useCreate()
  const { deletePost, duplicatePost, togglePostUsed, togglePostFavorited, updatePostData } =
    usePost()
  const [showOptions, setShowOptions] = useState(false)
  const optionsBtnRef = createRef<HTMLButtonElement>()

  const getStatus = () => {
    if (!!post.isUsed) {
      return (
        <>
          <SolidCheckCircleIcon /> Published
        </>
      )
    }

    if (!post.postDate) {
      return (
        <>
          <DocumentTextIcon /> Post Draft
        </>
      )
    }

    if (!post.socialProfiles?.length) {
      return (
        <>
          <BellIcon /> Reminder Notification
        </>
      )
    }

    return (
      <>
        <BoltIcon /> Auto-Publish
      </>
    )
  }

  return (
    <>
      <StyledPostGridListItem
        showOptions={showOptions}
        isActive={activePost?._id === post._id}
        onClick={() => setEditorPost(post)}
      >
        <div className="post-preview">
          <div className="post-chips">
            {!!errors.length && (
              <div className="post-chip icon error">
                <ExclamationCircleIcon />
              </div>
            )}
            {!!post.postDate && (
              <div className="post-chip">
                <CalendarIcon />
                {getRelativeString(post.postDate, true)}
              </div>
            )}
          </div>
          {(post.media || [])?.length > 1 && (
            <div className="post-media-length">1 of {post.media?.length}</div>
          )}
          {!!post.media?.length && (
            <CloudMediaPreview url={post.media[0].url} type={post.media[0].type} />
          )}
          {!!post.captionText?.trim() && !post.media?.length && (
            <div className="post-layout-preview">
              <span className="post-caption-preview">{post.captionText}</span>
            </div>
          )}
          {!post.captionText?.trim() && !post.media?.length && (
            <PaperClipIcon className="empty-icon" />
          )}
          <button
            className={classNames('options-btn', `post-${post._id}`)}
            ref={optionsBtnRef}
            onClick={(e) => {
              e.stopPropagation()
              setShowOptions(!showOptions)
            }}
          >
            <EllipsisHorizontalIcon />
          </button>
        </div>
        <div className="post-details">
          {!!socialProfileByStatus(
            ['active', 'expired'],
            hydrateSocialProfiles(post.socialProfiles, user)
          ).length && (
            <div className="post-profiles">
              {hydrateSocialProfiles(post.socialProfiles, user).map((profile) => {
                return <ConnectionAvatar size={36} profile={profile} />
              })}
            </div>
          )}
          <span className="post-title">{post.title || 'Untitled Post'}</span>
          <span className="post-status">{getStatus()}</span>
        </div>
      </StyledPostGridListItem>
      <OptionsPopover
        options={[
          {
            icon: <EditorIcon />,
            label: 'Open in Create',
            action: () => setEditorPost(post)
          },
          {
            icon: post.isLiked ? <SolidHeartIcon fill={theme.color.red[600]} /> : <HeartIcon />,
            label: post.isLiked ? 'Unfavorite' : 'Favorite',
            action: () => togglePostFavorited(post)
          },
          {
            icon: post.isUsed ? (
              <SolidCheckCircleIcon fill={theme.color.green[600]} />
            ) : (
              <CheckCircleIcon />
            ),
            label: `Mark as ${post.isUsed ? 'unpublished' : 'published'}`,
            action: () => togglePostUsed(post)
          },
          {
            icon: <DocumentDuplicateIcon />,
            label: 'Duplicate post',
            action: () => duplicatePost(post._id)
          },
          {
            icon: <TrashIcon />,
            label: 'Delete post',
            action: () => deletePost(post)
          }
        ]}
        classToAvoid={`post-${post._id}`}
        buttonRef={optionsBtnRef}
        isOpen={showOptions}
        onClose={() => setShowOptions(false)}
      />
    </>
  )
}

export default PostGridListItem
