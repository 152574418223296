import { toast } from 'react-hot-toast'
import { useHistory, useLocation } from 'react-router-dom'
import { saveAs } from 'file-saver'
import { jsPDF } from 'jspdf'
import jsZip from 'jszip'
import { debounce, uniq } from 'lodash'
import { v4 as uuid } from 'uuid'
import { useApolloClient } from '@apollo/client'
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'
import { Directory, Filesystem } from '@capacitor/filesystem'
import { Share } from '@capacitor/share'
import { upsertUserDraftMutation, userDraftFindByIdQuery } from '../../graphql'
import {
  addPost,
  clearCreatePost,
  setCreatePost,
  setCreateSaved,
  updateCreatePost,
  updatePost,
  useAppDispatch,
  useAppSelector
} from '../../redux'
import { CloudContentType, PhotoType, PostType } from '../../types'
import { ROUTES } from '..'
import { copyToClipboard, slugify, usePost, useSegment } from '.'

const CAROUSEL_LIMIT = 10

export const useCreate = () => {
  const history = useHistory()
  const location = useLocation()
  const apollo = useApolloClient()
  const dispatch = useAppDispatch()
  const { post } = useAppSelector((state) => state.create)
  const { track } = useSegment()
  const { deletePost: handleDeletePost } = usePost()

  const sharePost = async () => {
    try {
      if (!post) {
        return
      }

      await copyToClipboard(post.captionText)

      if (!!post.captionText?.length && !post?.media?.length) {
        return await Share.share({
          // title: post?.title,
          text: post?.captionText
        })
      }

      if (!post?.media?.length) {
        return
      }

      if (post.media.length === 1) {
        const item = post.media[0]

        const data = await fetch(item.url)
        const blob = await data.blob()
        const ext = item.type === 'video' ? 'mp4' : 'png'

        const image: string = await new Promise((resolve) => {
          const reader = new FileReader()
          reader.readAsDataURL(blob)
          reader.onloadend = () => {
            const base64data: any = reader.result
            resolve(base64data)
          }
        })

        const { uri } = await Filesystem.writeFile({
          path: `${post?._id}-${item._id}.${ext}`,
          data: image.replace(/^data:image\/[a-z]+;base64,/, ''),
          directory: Directory.Cache
        })

        return await Share.share({
          // title: post?.title,
          text: post?.captionText,
          url: uri
        })
      }

      const fids: string[] = []

      for (const item of post.media) {
        const data = await fetch(item.url)
        const blob = await data.blob()
        const ext = item.type === 'video' ? 'mp4' : 'png'

        const image: string = await new Promise((resolve) => {
          const reader = new FileReader()
          reader.readAsDataURL(blob)
          reader.onloadend = () => {
            const base64data: any = reader.result
            resolve(base64data)
          }
        })

        const { uri } = await Filesystem.writeFile({
          path: `${post?._id}-${item._id}.${ext}`,
          data: image.replace(/^data:image\/[a-z]+;base64,/, ''),
          directory: Directory.Cache
        })

        fids.push(uri)
      }

      await Share.share({
        title: post?.title,
        text: post?.captionText,
        files: fids
      })
    } catch (err) {}
  }

  const takePhoto = async () => {
    try {
      await Camera.getPhoto({
        presentationStyle: 'popover',
        resultType: CameraResultType.Base64,
        allowEditing: true,
        source: CameraSource.Camera
      })
    } catch (err) {
      console.error(err)
    }
  }

  const pickMedia = async () => {
    try {
      const { photos } = await Camera.pickImages({
        presentationStyle: 'popover',
        limit: 10
      })

      alert(JSON.stringify(photos))
    } catch (err) {
      console.error(err)
    }
  }

  const setEditorPost = (post: PostType) => {
    dispatch(setCreatePost(post))

    history.push(ROUTES.create)
    track('Opened Post in Post Editor', post)
  }

  const newPost = (redirect?: boolean) => {
    dispatch(clearCreatePost())

    if (redirect) {
      history.push(ROUTES.create)
    }
  }

  const savePost = debounce(async () => {
    const isNew = !post?._id

    const { data: postQuery } = await apollo.mutate({
      mutation: upsertUserDraftMutation,
      variables: { record: post }
    })

    const postData = {
      ...post,
      _id: postQuery.upsertUserDraft._id
    } as any

    dispatch(setCreatePost({ _id: postData._id }))
    dispatch(isNew ? addPost(postData) : updatePost(postData))
    dispatch(setCreateSaved(true))

    track(`${isNew ? 'Created' : 'Edited'} post`, postData)
  }, 1000)

  const loadPostFromUrl = async () => {
    const urlParams = new URLSearchParams(location.search)
    const params = Object.fromEntries(urlParams)
    const { post: postId } = params

    if (!postId) {
      return
    }

    const { data } = await apollo.query({
      query: userDraftFindByIdQuery,
      variables: {
        _id: postId
      }
    })

    const post = data?.userDraftFindById

    if (!post) {
      return
    }

    dispatch(clearCreatePost())
    dispatch(setCreatePost(post))

    history.replace({
      search: ''
    })
  }

  const addMediaToPost = (
    media: PhotoType | CloudContentType | { url: string; dimensions?: { y?: number; x?: number } },
    type: NonNullable<PostType['media']>[number]['type'] = 'image',
    uploaded: boolean = false
  ) => {
    const postMedia = post?.media || []
    const m = media as any
    const url = m?.file?.fileHttpLink || m?.file?.url || m?.url || ''
    const x = m?.file?.imgSize?.width || m?.file?.dimensions?.x || m?.dimensions?.x || 0
    const y = m?.file?.imgSize?.height || m?.file?.dimensions?.y || m?.dimensions?.y || 0
    const preview = m?.file?.preview
    const ref = uploaded ? m?._id : undefined
    const design = m?.file?.metadata?.design

    if (postMedia.length >= CAROUSEL_LIMIT) {
      toast.error("You can't add more than 10 photos or videos to a carousel.")
      return
    }

    const mediaObject = {
      _id: uuid(),
      url,
      dimensions: {
        x,
        y
      },
      preview,
      design,
      ref,
      uploaded,
      type
    } as NonNullable<PostType['media']>[number]

    return dispatch(
      updateCreatePost({
        media: [...postMedia, mediaObject]
      })
    )
  }

  const removeMediaFromPost = async (_id: string) => {
    const mediaToDelete = post?.media?.find((m) => m._id === _id)

    if (!mediaToDelete) {
      return
    }

    dispatch(updateCreatePost({ media: post?.media?.filter((m) => m._id !== _id) || [] }))
    track('Removed Media from Post', { post_id: post?._id, type: mediaToDelete.type })
  }

  // Inserts an Emoji at the user's cursor
  const insertEmojiAtCursor = (emoji: string) => {
    const input = document.getElementById('create-caption-area') as HTMLTextAreaElement

    const caretPos = input.selectionStart
    const updatedPos = caretPos + emoji.length
    const captionText = post?.captionText || ''
    const captionFirstHalf = captionText.substring(0, caretPos)
    const captionSecondHalf = captionText.substring(caretPos)
    const updatedCaption = captionFirstHalf + emoji + captionSecondHalf

    dispatch(updateCreatePost({ captionText: updatedCaption }))

    setTimeout(() => {
      input.focus()
      input.setSelectionRange(updatedPos, updatedPos)
    }, 10)

    track('Added Emoji to Post', { ...post, emoji })
  }

  // Downloads the post as a PDF
  const downloadAsPDF = () => {
    const doc = new jsPDF({ format: 'letter' })
    let captionOffset = 20

    if (post?.title) {
      doc.setFont('Helvetica', 'bold')
      doc.setFontSize(24)
      doc.setLineHeightFactor(1.4)

      const title = doc.splitTextToSize(post?.title, 175)
      doc.text(title, 20, 20)
      console.log(title.length)
      captionOffset += title.length * 15.5
    }

    doc.setFont('Helvetica', 'normal')
    doc.setFontSize(14)
    doc.setLineHeightFactor(2)

    const caption = doc.splitTextToSize(post?.captionText || '', 175)
    doc.text(caption, 20, captionOffset)

    doc.save(`${slugify(post?.title || '')}.pdf`)
    track('Downloaded Post as PDF', post)
  }

  // Downloads the post as a ZIP
  const downloadAsZip = async () => {
    const zip = new jsZip()

    if (post?.captionText?.trim()) {
      zip.file('caption.txt', post.captionText)
    }

    for (const media of post?.media || []) {
      if (media.type === 'image' || media.type === 'design') {
        const data = await fetch(media.url, {
          method: 'GET'
        })

        const buffer = await data.arrayBuffer()
        zip.file('image.jpeg', buffer, { base64: true })
      }
      if (media.type === 'video') {
        const data = await fetch(media.url, {
          method: 'GET'
        })

        const buffer = await data.arrayBuffer()
        zip.file('image.mp4', buffer, { base64: true })
      }
    }

    const zipFile = await zip.generateAsync({ type: 'blob' })
    saveAs(zipFile, `${slugify(post?.title || 'untitled-post')}.zip`)
    track('Downloaded Post as ZIP', post)
  }

  const deletePost = async () => {
    if (!post?._id) {
      return
    }

    await handleDeletePost(post as PostType)
  }

  const addPostTag = (tag: string) => {
    const newTags = uniq([...(post?.tags || []), tag])

    dispatch(
      updateCreatePost({
        tags: newTags
      })
    )

    track('Added Tag to Post', { ...post, tags: newTags, added: tag })
  }

  const removePostTag = (tag: string) => {
    const newTags = (post?.tags || []).filter((t) => t !== tag)

    dispatch(
      updateCreatePost({
        tags: newTags
      })
    )

    track('Removed Tag to Post', { ...post, tags: newTags, removed: tag })
  }

  return {
    setEditorPost,
    newPost,
    savePost,
    loadPostFromUrl,
    addMediaToPost,
    removeMediaFromPost,
    downloadAsPDF,
    downloadAsZip,
    deletePost,
    insertEmojiAtCursor,
    addPostTag,
    removePostTag,
    sharePost,
    pickMedia,
    takePhoto
  }
}
