import { xor } from 'lodash'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ContentPillarType, PostType } from '../../types'

type InitialState = {
  step?: 'pillars' | 'plan' | 'review'
  days: {
    _id: string
    pillar: ContentPillarType
    focus: 'engagement' | 'education' | 'sales'
  }[]
  pillars: ContentPillarType[]
  post: number
  posts: (Partial<PostType> & { approved?: boolean })[]
  showScheduleDialog?: boolean
  loadingGenPosts?: boolean
  loadingGenPillars?: boolean
}

const initialState: InitialState = {
  pillars: [],
  post: 0,
  posts: [],
  days: []
}

export const planSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    setPlanStep: (state, action: PayloadAction<InitialState['step']>) => {
      state.step = action.payload
    },
    setPlanPillars: (state, action: PayloadAction<InitialState['pillars']>) => {
      state.pillars = action.payload
    },
    updatePlanPillar: (
      state,
      action: PayloadAction<{ _id: string; pillar: Partial<InitialState['pillars'][number]> }>
    ) => {
      state.pillars = state.pillars.map((p) => {
        if (p._id === action.payload._id) {
          return {
            ...p,
            ...action.payload.pillar
          }
        }

        return p
      })
    },
    setPlanDays: (state, action: PayloadAction<InitialState['days']>) => {
      state.days = action.payload
    },
    addPlanDay: (state, action: PayloadAction<InitialState['days'][number]>) => {
      state.days = [...state.days, action.payload]
    },
    updatePlanDay: (
      state,
      action: PayloadAction<{ _id: string; day: Partial<InitialState['days'][number]> }>
    ) => {
      state.days = state.days.map((d) => {
        if (d._id === action.payload._id) {
          return {
            ...d,
            ...action.payload.day
          }
        }

        return d
      })
    },
    removePlanDay: (state, action: PayloadAction<string>) => {
      state.days = state.days.filter((d) => d._id !== action.payload)
    },
    setPlanPost: (state, action: PayloadAction<InitialState['post']>) => {
      state.post = action.payload
    },
    setPlanPosts: (state, action: PayloadAction<InitialState['posts']>) => {
      state.posts = action.payload
    },
    updatePlanPost: (
      state,
      action: PayloadAction<{ _id: string; post: InitialState['posts'][number] }>
    ) => {
      state.posts = state.posts.map((post) => {
        if (post._id === action.payload._id) {
          return {
            ...post,
            ...action.payload.post
          }
        }

        return post
      })
    },
    togglePlanPostConnection: (
      state,
      action: PayloadAction<{ _id: string; connection: string }>
    ) => {
      state.posts = state.posts.map((post) => {
        if (post._id === action.payload._id) {
          return {
            ...post,
            socialProfiles: xor(post?.socialProfiles || [], [action.payload.connection])
          }
        }

        return post
      })
    },
    setShowPlanScheduleDialog: (state, action: PayloadAction<boolean>) => {
      state.showScheduleDialog = action.payload
    },
    setPlanLoadingGenPosts: (state, action: PayloadAction<boolean>) => {
      state.loadingGenPosts = action.payload
    },
    setPlanLoadingGenPillars: (state, action: PayloadAction<boolean>) => {
      state.loadingGenPillars = action.payload
    },
    clearPlanState: (state) => {
      state.loadingGenPosts = false
      state.loadingGenPillars = false
      state.days = []
      state.pillars = []
      state.post = 0
      state.posts = []
      state.showScheduleDialog = false
      state.step = undefined
    }
  }
})

export const {
  setPlanStep,
  setPlanPillars,
  updatePlanPillar,
  setPlanDays,
  addPlanDay,
  updatePlanDay,
  removePlanDay,
  setPlanPost,
  setPlanPosts,
  updatePlanPost,
  togglePlanPostConnection,
  setShowPlanScheduleDialog,
  setPlanLoadingGenPosts,
  setPlanLoadingGenPillars,
  clearPlanState
} = planSlice.actions
export default planSlice.reducer
