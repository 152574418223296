import { FC } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { setContentTab, setShowCreate, useAppDispatch } from '../../../redux'
import { ROUTES } from '../../../utils'
import { MOBILE_ROUTES } from '../../utils'

const RouteCatcher: FC = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()

  console.log(window.location.href)

  if (window.location.href.includes('?ref=academy')) {
    return <Redirect to={{ pathname: MOBILE_ROUTES.splash, state: { ref: 'academy' } }} />
  }

  if (location.pathname.includes('/calendar')) {
    return <Redirect to={MOBILE_ROUTES.calendar} />
  }

  if (location.pathname.includes('/content/photos')) {
    dispatch(setContentTab('photos'))
    return <Redirect to={MOBILE_ROUTES.content} />
  }

  if (location.pathname.includes('/uploads')) {
    dispatch(setContentTab('uploads'))
    return <Redirect to={MOBILE_ROUTES.content} />
  }

  if (location.pathname.includes('/user')) {
    return <Redirect to={MOBILE_ROUTES.account.main} />
  }

  if (location.pathname.includes('/academy')) {
    return <Redirect to={location.pathname} />
  }

  if (location.pathname.includes('/create')) {
    dispatch(setShowCreate(true))
    return <Redirect to={MOBILE_ROUTES.home} />
  }

  if (location.pathname.includes('/login')) {
    return (
      <Redirect to={{ pathname: MOBILE_ROUTES.splash, state: { redirect: location.search } }} />
    )
  }

  return <Redirect to={MOBILE_ROUTES.home} />
}

export default RouteCatcher
