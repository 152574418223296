import styled from 'styled-components'

const StyledRecentPostItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;
  user-select: none;
  padding: 6px;
  margin: -6px;
  transition: 0.2s;

  .post-img-preview {
    width: 40px;
    height: 40px;
    position: relative;
    border-radius: 4px;
    flex-shrink: 0;
    margin-right: 12px;
    background-color: ${({ theme }) => theme.color.gray[200]};

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 24px;
      color: ${({ theme }) => theme.color.gray[500]};
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
      user-select: none;
    }

    &::after {
      user-select: none;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      box-shadow: inset 0 0 0 1px rgb(0, 0, 0, 0.1);
      z-index: 1;
    }
  }

  .post-details {
    .post-title {
      text-align: left;
      font-weight: 500;
      letter-spacing: -0.01rem;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-size: 14px;
      color: ${({ theme }) => theme.color.gray[800]};
    }

    .post-caption {
      font-size: 13px;
      display: -webkit-box;
      word-break: break-word;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-top: 1px;
      color: ${({ theme }) => theme.color.gray[600]};

      .empty {
        color: ${({ theme }) => theme.color.gray[400]};
      }
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.gray[100]};
  }

  &[data-active='true'] {
    background-color: ${({ theme }) => theme.color.brandLight};

    &:hover {
      cursor: not-allowed;
    }
  }
`

export default StyledRecentPostItem
