import { FC, useState } from 'react'
import { Capacitor } from '@capacitor/core'
import { Haptics, ImpactStyle } from '@capacitor/haptics'
import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import { SortableContext, arrayMove, rectSortingStrategy } from '@dnd-kit/sortable'
import { PlusIcon } from '@heroicons/react/24/outline'
import { updatePage, useAppDispatch, useAppSelector } from '../../../../../redux'
import { NewLinkBlockDialog } from '../../../../dialogs'
import { LinkPageLayoutBlock, StyledLinkPageLayoutTab } from '.'

const LinkPageLayoutTab: FC = () => {
  const dispatch = useAppDispatch()
  const [showNewDialog, setShowNewDialog] = useState(false)
  const { page } = useAppSelector((state) => state.page)

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 1
      }
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 2
      }
    })
  )

  const handleDrag = async (sort: DragEndEvent) => {
    const { active, over } = sort

    // If not dragged over another block, quit
    if (!over) {
      return
    }

    // If position has not moved, quit
    if (active.id === over.id) {
      return
    }

    const oldIndex = page?.links?.findIndex((link) => link._id === active.id) || 0
    const newIndex = page?.links?.findIndex((link) => link._id === over.id) || 0

    const sortedList = arrayMove(page?.links || [], oldIndex, newIndex)
    dispatch(
      updatePage({
        links: sortedList
      })
    )
  }

  const handleHaptics = () => {
    if (Capacitor.isNativePlatform()) {
      Haptics.impact({ style: ImpactStyle.Light })
    }
  }

  return (
    <>
      <DndContext
        sensors={sensors}
        autoScroll={{ threshold: { x: 0, y: 0.1 } }}
        collisionDetection={closestCenter}
        onDragOver={() => handleHaptics()}
        onDragEnd={(event) => handleDrag(event)}
      >
        <StyledLinkPageLayoutTab>
          <div className="block-list">
            <SortableContext
              items={page?.links?.map((link) => link._id) || []}
              strategy={rectSortingStrategy}
            >
              {page?.links?.map((link) => {
                return <LinkPageLayoutBlock link={link} key={link._id} />
              })}
              <button className="add-block-btn" onClick={() => setShowNewDialog(true)}>
                <PlusIcon />
                Add Block
              </button>
            </SortableContext>
          </div>
        </StyledLinkPageLayoutTab>
      </DndContext>
      <NewLinkBlockDialog isOpen={showNewDialog} onClose={() => setShowNewDialog(false)} />
    </>
  )
}

export default LinkPageLayoutTab
