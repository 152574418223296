import styled from 'styled-components'

const StyledProductTestView = styled.div`
  padding: 40px 0;

  .insiders-label {
    display: flex;
    align-items: center;
    padding: 0 14px;
    height: 32px;
    font-size: 15px;
    border-radius: 50rem;
    color: ${({ theme }) => theme.color.brand};
    background-color: ${({ theme }) => theme.color.brandLight};
    font-weight: 600;
    letter-spacing: 1px;
    width: fit-content;
    margin-bottom: 20px;

    svg {
      height: 20px;
      width: 20px;
      margin-right: 8px;

      path {
        stroke-width: 3px;
      }
    }
  }

  h1 {
    font-family: ${({ theme }) => theme.typeface.faktumXCon};
    color: ${({ theme }) => theme.color.gray[900]};
    font-size: 28px;
    font-weight: 700;
  }

  h2 {
    margin-top: 4px;
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme }) => theme.color.gray[600]};
  }

  hr {
    margin: 30px 0;
    height: 1px;
    width: 100%;
    border: 0;
    background-color: ${({ theme }) => theme.color.gray[300]};
  }

  ul {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding-left: 0;
    margin-top: -3px;
    margin-bottom: 30px;
    list-style-type: none;
    color: ${({ theme }) => theme.color.gray[600]};

    li {
      display: flex;

      svg {
        height: 24px;
        margin-right: 18px;
        flex-shrink: 0;
        stroke-width: 1.75px;
        position: relative;
        top: 2px;
        color: ${({ theme }) => theme.color.gray[500]};
      }

      &:not(:first-child) {
        margin-top: 18px;
      }
    }
  }

  .disclaimer {
    font-size: 14px;
    user-select: none;
    margin-top: 12px;
    display: block;
    text-align: center;
    color: ${({ theme }) => theme.color.gray[500]};

    b {
      font-weight: 500;
    }
  }
`

export default StyledProductTestView
