import styled from 'styled-components'

const StyledCaptionBitDialog = styled.div`
  padding: 18px;
  position: relative;
  width: 100%;
  width: 400px;
  user-select: none;

  .dialog-header {
    color: ${({ theme }) => theme.color.gray[700]};
    display: flex;
    align-items: center;
    font-weight: 600;
    margin-bottom: 18px;

    svg {
      height: 22px;
      stroke-width: 2.2px;
      margin-right: 6px;
    }
  }

  .dialog-text {
    color: ${({ theme }) => theme.color.gray[800]};
    line-height: 22px;

    b {
      font-weight: 600;
    }
  }
`

export default StyledCaptionBitDialog
