import { gql } from '@apollo/client'

export const captionSetFindMany = gql`
  query CaptionSetFindMany($category: String, $issue: String) {
    captionSetFindMany(filter: { category: $category, issue: $issue }, limit: 10, sort: INDEX_ASC) {
      _id
      title
      caption
      category
      photoIdeas
      captionPlainText
      thumbnail
      index
      canvaLink
      records {
        _id
        fullPath
        imageUrl
        index
        migrated
      }
    }
  }
`
