import { useContext, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useApolloClient } from '@apollo/client'
import { deleteUserAccountRequest } from '../../graphql'
import { userStore } from '..'

//  ------------------------------------------------------------
//  |  This script handles the account deletion flow           |
//  ------------------------------------------------------------

export const useAccountDeletion = () => {
  const apollo = useApolloClient()
  const { user, logout } = useContext(userStore)
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)

  const deleteAccount = async () => {
    try {
      if (!user) {
        return
      }

      const toastId = toast.loading('Disabling account')
      await apollo.mutate({ mutation: deleteUserAccountRequest })

      toast.dismiss(toastId)

      await logout()

      setShowConfirmationDialog(false)
      toast.success('Your account has been disabled & marked for deletion')
    } catch (err) {
      alert(err)
    }
  }

  return {
    showConfirmationDialog,
    actions: {
      deleteAccount,
      setShowConfirmationDialog
    }
  }
}
