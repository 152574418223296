import { FC, useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { Link, useHistory } from 'react-router-dom'
import { omit } from 'lodash'
import { DateTime } from 'luxon'
import { useApolloClient } from '@apollo/client'
import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import {
  DateTimeDialog,
  PlanPillarStep,
  PlanPlanStep,
  PlanReviewStep,
  Spinner,
  State
} from '../../components'
import {
  contentPillarUpsertMany,
  generateContentPillars,
  generateDrafts,
  upsertUserDraftMutation
} from '../../graphql'
import {
  addPosts,
  clearPlanState,
  setPlanLoadingGenPillars,
  setPlanLoadingGenPosts,
  setPlanPillars,
  setPlanPost,
  setPlanPosts,
  setPlanStep,
  setShowPlanScheduleDialog,
  updatePlanPost,
  useAppDispatch,
  useAppSelector
} from '../../redux'
import { ROUTES, useSegment, userStore } from '../../utils'
import { StyledPlanView } from '.'

const PlanView: FC = () => {
  const apollo = useApolloClient()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const [isOverflowed, setIsOverflowed] = useState(false)
  const { user, refresh } = useContext(userStore)
  const { track } = useSegment()
  const {
    step,
    pillars,
    post,
    posts,
    days,
    showScheduleDialog,
    loadingGenPosts,
    loadingGenPillars
  } = useAppSelector((state) => state.plan)

  const checkIsOverflowed = () => {
    const view = document.getElementById('plan-content')
    const scrollHeight = view?.scrollHeight || 0
    const offsetHeight = view?.offsetHeight || 0

    setIsOverflowed(offsetHeight !== scrollHeight)
  }

  const savePillars = async () => {
    dispatch(setPlanLoadingGenPillars(true))
    await apollo.mutate({
      fetchPolicy: 'no-cache',
      mutation: contentPillarUpsertMany,
      variables: {
        contentPillars: pillars
      }
    })
    await refresh()
    dispatch(setPlanLoadingGenPillars(false))
  }

  const generatePosts = async () => {
    dispatch(setPlanLoadingGenPosts(true))

    const { data } = await apollo.mutate({
      fetchPolicy: 'no-cache',
      mutation: generateDrafts,
      variables: {
        posts: days,
        timezone: DateTime.now().toFormat('z')!,
        today: DateTime.now().toISO()!
      }
    })

    track('Generated Posts Templates', {
      days: days,
      day_count: days.length
    })

    dispatch(setPlanPosts(data.generateDrafts))
    dispatch(setPlanLoadingGenPosts(false))
  }

  const finish = async () => {
    dispatch(setPlanLoadingGenPosts(true))

    await Promise.all(
      posts.map(async (post) => {
        await apollo.mutate({
          fetchPolicy: 'no-cache',
          mutation: upsertUserDraftMutation,
          variables: {
            record: omit(post, 'approved')!
          }
        })
      })
    )

    track('Generated Posts', {
      posts: posts,
      post_count: posts.length
    })

    dispatch(addPosts(posts.map((p) => omit(p, 'approved')) as any))

    history.push(ROUTES.home)
    dispatch(clearPlanState())
    toast.success(`Saved ${posts.length} post${posts.length !== 1 ? 's' : ''}`, { duration: 4000 })
  }

  const handleStep = async () => {
    if (step === 'pillars') {
      await savePillars()
      return dispatch(setPlanStep('plan'))
    }

    if (step === 'plan') {
      await generatePosts()
      return dispatch(setPlanStep('review'))
    }

    if (posts.filter((p) => !p.approved).length) {
      dispatch(updatePlanPost({ _id: posts[post]._id!, post: { approved: true } }))
    }

    if (posts.length - 1 !== post) {
      return dispatch(setPlanPost(post + 1))
    }

    await finish()
  }

  useEffect(() => {
    if (!step && !user?.contentPillars?.length) {
      dispatch(setPlanStep('pillars'))
    }

    if (!step && !!user?.contentPillars?.length) {
      dispatch(setPlanStep('plan'))
    }
  }, [step, user])

  useEffect(() => {
    const target = document.getElementById('plan-content')

    if (!target) {
      return
    }

    const observer = new ResizeObserver(checkIsOverflowed)

    observer.observe(target, { box: 'border-box' })

    window.addEventListener('resize', checkIsOverflowed)

    return () => {
      window.removeEventListener('resize', checkIsOverflowed)
      observer.disconnect()
    }
  }, [])

  useEffect(() => {
    return () => {
      // dispatch(clearPlanState())
    }
  }, [])

  return (
    <>
      <StyledPlanView $step={step} $isOverflowed={isOverflowed}>
        <button
          className="plan-exit-btn"
          onClick={() => (!!history.length ? history.goBack() : history.push(ROUTES.home))}
        >
          <ChevronLeftIcon />
          Exit
        </button>
        <div className="plan-content" id="plan-content">
          {step === 'pillars' && <PlanPillarStep />}
          {step === 'plan' && <PlanPlanStep />}
          {step === 'review' && <PlanReviewStep />}
        </div>
        <div className="plan-action-row">
          <button
            className="action-btn"
            disabled={(step === 'plan' && !days.length) || loadingGenPosts || loadingGenPillars}
            onClick={() => handleStep()}
          >
            {(loadingGenPosts || loadingGenPillars) && <Spinner />}
            {step === 'pillars' && !loadingGenPillars && 'Approve Pillars'}
            {step === 'plan' && !loadingGenPosts && 'Create Post Drafts'}
            {step === 'review' && !loadingGenPosts && 'Approve & continue'}
          </button>
          <span className="action-subtext">
            {step === 'pillars' && 'You can change or edit these at any time.'}
            {step === 'plan' && (
              <>
                Want to change your Pillars? <Link to={ROUTES.settings.pillars}>Click Here</Link>.
              </>
            )}
            {step === 'review' &&
              posts.map((post) => {
                return <div className="review-step" data-approved={post.approved} />
              })}
          </span>
        </div>
      </StyledPlanView>
      <State />
      <DateTimeDialog
        value={posts[post]?.postDate || undefined}
        isOpen={!!showScheduleDialog}
        onChange={(value) =>
          dispatch(updatePlanPost({ _id: posts[post]?._id || '', post: { postDate: value } }))
        }
        onClose={() => dispatch(setShowPlanScheduleDialog(false))}
      />
    </>
  )
}

export default PlanView
