import { FC, useContext, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import firebase from 'firebase/app'
import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import {
  AGES,
  OCCUPATIONS,
  VOICES,
  useForm,
  useIntercom,
  useKeyboardHeight,
  useMesh,
  useSegment,
  userStore
} from '../../../utils'
import { OnboardingSelect } from '../../../views/OnboardingView/OnboardingSelect'
import { Input, OnboardingStep, Select, Spinner } from '../../components'
import { MOBILE_ROUTES } from '../../utils'
import { StyledOnboardingView } from '.'

const OnboardingView: FC = () => {
  useMesh()
  const location = useLocation()
  const history = useHistory()
  const updateLoggedInUser = firebase.functions().httpsCallable('updateLoggedInUser')
  const [step, setStep] = useState(0)
  const [loading, setLoading] = useState(false)
  const [params, setParams] = useState<{ [k: string]: string }>()
  const { track } = useSegment()
  const { updateIntercom } = useIntercom()
  const { height } = useKeyboardHeight()
  const { user, refresh } = useContext(userStore)
  const { form, updateFormValue } = useForm({
    use: 'Personal',
    occupation: '',
    company: '',
    ages: [],
    genders: [],
    occupations: [],
    voices: [],
    interests: []
  })

  const canContinue = () => {
    const hasOccupation = !!form?.occupation?.trim()
    const hasVoice = !!form?.voices?.length

    if (step === 0 && !hasOccupation) {
      return false
    }

    if (step === 2 && !hasVoice) {
      return false
    }

    return true
  }

  const handleSave = async () => {
    try {
      setLoading(true)

      await updateLoggedInUser({
        email: form.email,
        displayName: form.displayName,
        aiOnboarding: {
          ...form
        }
      })

      updateIntercom({
        name: form.displayName,
        email: form.email,
        aiOnboarding: {
          ...form
        }
      })

      track('Completed Onboarding', {
        ...form
      })

      await refresh()

      if (params?.redirect) {
        window.location.href = params.redirect
        return
      }

      history.push(MOBILE_ROUTES.home)
    } catch (err) {
      console.log(err)
      toast.error((err as any)?.message)
      setLoading(false)
    }
  }

  const nextStep = () => {
    if (step === 2) {
      return handleSave()
    }

    track('Completed Onboarding Step', {
      step
    })

    setStep(step + 1)
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    const params = Object.fromEntries(urlParams)
    setParams({ ...params, ...(location.state as any) })
  }, [location.search, location.state])

  if (!!user?.aiOnboarding?.occupation) {
    return <Redirect to={MOBILE_ROUTES.home} />
  }

  return (
    <StyledOnboardingView step={step} keyboardHeight={height}>
      <div className="onboarding-header">
        <button className="back-btn" onClick={() => setStep(step - 1)}>
          <ChevronLeftIcon />
        </button>
        <div className="onboarding-header-heading">
          <span className="title">
            {step === 0 && 'What do you do?'}
            {step === 1 && 'Who’s your ideal audience?'}
            {step === 2 && 'What’s your voice?'}
          </span>
          <span className="helps-info">
            {step === 0 && 'This helps me create tailored content.'}
            {step === 1 && 'This helps me resonate with your audience.'}
            {step === 2 && 'This helps me write in your tone!'}
          </span>
        </div>
      </div>
      <div className="onboarding-steps">
        {step === 0 && (
          <OnboardingStep>
            <Input
              label="What is your job title/occupation?"
              value={form?.occupation}
              autoCapitalize="words"
              placeholder="Start typing..."
              required
              suggestions={[
                'Photographer',
                'Boutique Owner',
                'Marketing Coach',
                'Real Estate Agent',
                'Hair Stylist'
              ]}
              dataList={OCCUPATIONS}
              onChange={(value) => updateFormValue('occupation', value)}
            />
            <Input
              label="What is the name of your business/brand?"
              value={form.company}
              placeholder="Ex: Sally Smith Photography"
              onChange={(value) => {
                updateFormValue({
                  company: value,
                  use: !!value.trim() ? 'Business' : 'Personal'
                })
              }}
            />
          </OnboardingStep>
        )}
        {step === 1 && (
          <OnboardingStep>
            <Select
              options={AGES.map((a) => ({ value: a, label: a }))}
              name="ages"
              multiple
              label="Target audience's age(s)"
              placeholder="Target audience's age(s)"
              value={form.ages}
              suggestions={AGES}
              onChange={(value) => {
                updateFormValue('ages', value)
              }}
            />
            <Select
              options={[
                { label: 'Men', value: 'Men' },
                { label: 'Women', value: 'Women' }
              ]}
              name="genders"
              multiple
              label="Target audience's gender(s)"
              placeholder="Target audience's gender(s)"
              value={form.genders}
              suggestions={['Men', 'Women']}
              onChange={(value) => {
                updateFormValue('genders', value)
              }}
            />
            {/* <Select
              options={OCCUPATIONS.map((o) => ({ value: o, label: o }))}
              name="occupations"
              multiple
              label="Target audience's occupation(s)"
              placeholder="Target audience's occupation(s)"
              value={form.occupations}
              onChange={(value) => updateFormValue('occupations', value)}
            /> */}

            <div className="mobile-custom-select">
              <div className="input-label">How do they refer to themselves?</div>
              <OnboardingSelect
                options={OCCUPATIONS.map((o) => ({
                  label: `${o}s`,
                  value: `${o}s`
                }))}
                value={
                  form?.occupations &&
                  form.occupations.map((occupation: any) => ({
                    label: occupation,
                    value: occupation
                  }))
                }
                isMulti
                placeholder="Start typing..."
                onChange={(value) =>
                  updateFormValue(
                    'occupations',
                    value.map((v: any) => v.label)
                  )
                }
              />
            </div>
          </OnboardingStep>
        )}
        {step === 2 && (
          <OnboardingStep>
            <div className="mobile-custom-select">
              <OnboardingSelect
                options={VOICES.map((voice) => ({ label: voice.label, value: voice.label }))}
                value={
                  form?.voices &&
                  form.voices.map((voice: any) => ({
                    label: voice,
                    value: voice
                  }))
                }
                forceMenu
                isMulti
                placeholder="Select one or more voices"
                onChange={(value) =>
                  updateFormValue(
                    'voices',
                    value.map((v: any) => v.label)
                  )
                }
              />
            </div>
            {/* <Select
              options={VOICES.map((voice) => ({ label: voice.label, value: voice.label }))}
              name="voices"
              multiple
              label="Voice(s)"
              placeholder="Voice(s)"
              value={form.voices}
              suggestions={VOICES.map((v) => v.label)}
              onChange={(value) => {
                updateFormValue('voices', value)
              }}
            /> */}
          </OnboardingStep>
        )}
      </div>
      <div className="action-bar">
        {step === 1 && (
          <button className="skip-btn" onClick={() => nextStep()}>
            Skip
          </button>
        )}
        <button
          className="continue-btn"
          disabled={!canContinue() || loading}
          onClick={() => nextStep()}
        >
          {step === 2 && !loading && 'Finish'}
          {step !== 2 && !loading && 'Continue'}
          {loading && <Spinner />}
        </button>
      </div>
      <canvas id="gradient-canvas" />
    </StyledOnboardingView>
  )
}

export default OnboardingView
