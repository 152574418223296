import styled from 'styled-components'

const StyledCreateHashtags = styled.div`
  height: 336px;
  background-color: ${({ theme }) => theme.color.gray[100]};
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  overflow: hidden;

  .close-btn {
    width: 100%;
    height: 20px;
    background-color: ${({ theme }) => theme.color.gray[100]};
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      width: 50px;
      height: 4px;
      border-radius: 50rem;
      background-color: ${({ theme }) => theme.color.gray[300]};
    }
  }
`

export default StyledCreateHashtags
