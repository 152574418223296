import styled from 'styled-components'

type StyledProps = {
  hasValue?: boolean
  scale?: 'lg' | 'md' | 'sm'
  variant?: 'outline' | 'fill' | 'shadow'
}

const StyledSelect = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  border-radius: 4px;
  position: relative;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s;

  select {
    user-select: none;
    display: flex;
    cursor: pointer;
    width: 100%;
    background-color: transparent;
    color: ${({ theme, hasValue }) => (hasValue ? theme.color.gray[600] : theme.color.gray[400])};
    transition: color 0.2s;

    &::placeholder {
      pointer-events: none;
      user-select: none;
      color: ${({ theme }) => theme.color.gray[400]};
    }
  }

  svg {
    pointer-events: none;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: ${({ theme }) => theme.color.gray[400]};
  }

  ${({ scale }) =>
    scale === 'lg' &&
    `
    select {
      height: 50px;
      padding: 0 14px;
    }

    svg {
      height: 22px;
    }
  `}

  ${({ scale }) =>
    scale === 'md' &&
    `
    select {
      height: 40px;
      padding: 0 12px;
    }

    svg {
      height: 22px;
    }
  `}

  ${({ scale }) =>
    scale === 'sm' &&
    `
    select {
      height: 38px;
      padding: 0 10px;
    }

    svg {
      height: 20px;
    }
  `}

  ${({ variant, theme }) =>
    variant === 'outline' &&
    `
    background-color: ${theme.color.background};
    border: 1px solid ${theme.color.gray[300]};

    &:hover, &:focus-within {
      border: 1px solid ${theme.color.gray[400]};
    }
  `}

  ${({ variant, theme }) =>
    variant === 'fill' &&
    `
    background-color: ${theme.color.gray[100]};

    &:hover, &:focus-within {
      background-color: ${theme.color.gray[200]};
    }
  `}

  ${({ variant, theme }) =>
    variant === 'shadow' &&
    `
    background-color: ${theme.color.background};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    border: 1px solid ${theme.color.gray[300]};
    border-radius: 6px;

    &:hover, &:focus-within {
      border: 1px solid ${theme.color.gray[400]};
    }
  `}

  select:disabled {
    opacity: 1;

    &:hover {
      cursor: not-allowed;
    }
  }
`

export default StyledSelect
