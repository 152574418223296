import styled from 'styled-components'

const StyledMobileBlockView = styled.div`
  text-align: center;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.color.background};

  .icon {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    box-shadow: inset 0 -4px 6px 0px #00000030, 0 4px 6px 0px #00000030;
    background-color: ${({ theme }) => theme.color.brand};
    color: white;
    margin: 0 auto 20px auto;

    svg {
      height: 44px;
    }
  }

  .title {
    display: block;
    font-size: 20px;
    font-weight: 600;
    font-family: ${({ theme }) => theme.typeface.garnett};
    color: ${({ theme }) => theme.color.gray[800]};
  }

  .body {
    display: block;
    font-size: 14px;
    font-weight: 400;
    max-width: 480px;
    line-height: 24px;
    margin: 6px auto 0 auto;
    color: ${({ theme }) => theme.color.gray[600]};
  }

  .apple-btn {
    display: block;
    max-width: 220px;
    width: 100%;
    margin: 30px auto 0 auto;

    img {
      width: 100%;
    }
  }
`

export default StyledMobileBlockView
