import styled, { keyframes } from 'styled-components'

const linksAnimIn = keyframes`
  0% {
    transform: scale(0);
    -webkit-filter: blur(40px);
  }
  50% {
    transform: scale(1);
    -webkit-filter: blur(0);
  }
`

const linksAnimOut = keyframes`
  0% {
    transform: scale(1);
    -webkit-filter: blur(0);
  }
  100% {
    transform: scale(0);
    -webkit-filter: blur(40px);
  }
`

const backdropAnimIn = (background: string) => keyframes`
  0% {
    background-color: ${background}00;
    backdrop-filter: saturate(1) blur(0);
    -webkit-backdrop-filter: saturate(1) blur(0);
  }
  100% {
    background-color: ${background}a8;
    backdrop-filter: saturate(180%) blur(20px);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
  }
`

const backdropAnimOut = (background: string) => keyframes`
  0% {
    background-color: ${background}a8;
    backdrop-filter: saturate(180%) blur(20px);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
  }
  100% {
    background-color: ${background}00;
    backdrop-filter: saturate(1) blur(0);
    -webkit-backdrop-filter: saturate(1) blur(0);
  }
`

const StyledMenuSheet = styled.div`
  padding: 0px 20px calc(var(--bottom-spacing) + 20px) 20px;
  display: grid;
  gap: 12px;

  a {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
    border-radius: 10px;
    padding: 10px;
    color: ${({ theme }) => theme.color.gray[800]};
    background-color: ${({ theme }) => theme.color.gray[100]};
    text-decoration: none;

    .icon {
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;

      img {
        height: 100%;
      }
    }

    &.active {
      color: ${({ theme }) => theme.color.brand};
      background-color: ${({ theme }) => theme.color.brandLight};
    }
  }
`

// const StyledMenuSheet = styled.div`
//   position: fixed;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   width: 100%;
//   height: 100%;
//   box-sizing: border-box;
//   z-index: 999999999;
//   animation: forwards 0.2s ${({ theme }) => backdropAnimIn(theme.color.background)};

//   .click-catcher {
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     width: 100%;
//     height: 100%;
//     background-color: transparent;
//   }

//   .menu-links {
//     transform-origin: right bottom;
//     display: grid;
//     gap: 20px;
//     align-content: end;
//     justify-items: end;
//     padding: 20px 20px calc(var(--bottom-spacing) + 10px) 20px;
//     position: absolute;
//     z-index: 999999999;
//     bottom: 0;
//     right: 0;
//     animation: forwards 1s ${linksAnimIn};
//   }

//   a {
//     display: flex;
//     align-items: center;
//     font-size: 22px;
//     color: ${({ theme }) => theme.color.gray[900]};
//     text-decoration: none;
//     font-weight: 500;

//     .icon {
//       width: 54px;
//       height: 54px;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       margin-left: 16px;

//       img {
//         height: 54px;
//         width: 54px;
//         flex-shrink: 0;
//       }
//     }

//     &:last-child {
//       .icon img {
//         height: 48px;
//         width: 48px;
//       }
//     }
//   }

//   &.leaving {
//     animation: forwards 0.2s ${({ theme }) => backdropAnimOut(theme.color.background)};

//     .menu-links {
//       animation: ${linksAnimOut} 0.5s forwards;
//     }
//   }
// `

export default StyledMenuSheet
