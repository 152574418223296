import styled from 'styled-components'

const StyledEmptyState = styled.div`
  padding: 20px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.color.brandLight};
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.brand}10;
  border-radius: 12px 12px;
  display: grid;
  align-content: center;
  justify-content: center;
  text-align: center;

  span {
    display: block;
  }

  .title {
    font-size: 20px;
    font-family: ${({ theme }) => theme.typeface.garnett};
    font-weight: 600;
    color: ${({ theme }) => theme.color.brand};
    position: relative;
    z-index: 1;
  }

  .body {
    font-size: 14px;
    font-weight: 500;
    max-width: 200px;
    margin: 4px auto 0 auto;
    color: ${({ theme }) => theme.color.brand};
    position: relative;
    z-index: 1;
  }
`

export default StyledEmptyState
