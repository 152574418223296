import styled from 'styled-components'

const StyledCreateBarChat = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
  display: grid;
  border-radius: 12px;
  grid-template-rows: auto minmax(0, 1fr) auto;
`

export default StyledCreateBarChat
