import styled from 'styled-components'

const StyledCloudUploads = styled.div`
  .cloud-heading {
    justify-content: space-between;

    h3 {
      display: flex;
      align-items: center;

      .upload-count {
        font-size: 14px;
        margin-left: 8px;
        height: 20px;
        border-radius: 50rem;
        padding: 0 6px;
        display: flex;
        align-items: center;
        min-width: 23px;
        box-sizing: border-box;
        color: ${({ theme }) => theme.color.gray[500]};
        background-color: ${({ theme }) => theme.color.gray[200]};
        border: 1px solid ${({ theme }) => theme.color.gray[200]};
        font-family: ${({ theme }) => theme.typeface.sans};
      }

      .upload-spinner {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 8px;
      }
    }

    .actions {
      display: flex;
      align-items: center;
    }
  }

  .infinite-scroll-component {
    overflow: visible !important;
  }

  .cloud-upload-list {
    display: grid;
    gap: 12px;

    hr {
      height: 1px;
      margin: 0;
      width: 100%;
      background-color: ${({ theme }) => theme.color.gray[200]};
      border: 0;
    }
  }
`

export default StyledCloudUploads
