import { FC } from 'react'
import {
  DesignerColorTool,
  DesignerDeleteTool,
  DesignerDuplicateTool,
  DesignerPositionTool,
  DesignerTransparencyTool
} from '..'
import { DesignerToolbarRow } from '.'

const DesignerVectorToolbar: FC = () => {
  return (
    <DesignerToolbarRow>
      <DesignerColorTool />
      <hr />
      <DesignerPositionTool />
      <hr />
      <DesignerTransparencyTool />
      <DesignerDuplicateTool />
      <DesignerDeleteTool />
      <hr />
    </DesignerToolbarRow>
  )
}

export default DesignerVectorToolbar
