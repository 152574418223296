import { useEffect } from 'react'
import { v4 as uuid } from 'uuid'

export const useMesh = (dependency?: string) => {
  useEffect(() => {
    const script = document.createElement('script')

    script.src = `/js/meshAnim.js?${uuid()}`
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [dependency])
}
