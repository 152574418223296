import { FC } from 'react'
import { useMesh } from '../../../utils'
import { HomeGreeting, HomeStats } from '..'
import { StyledHomeTop } from '.'

const HomeTop: FC = () => {
  const getGreeting = () => {
    const now = new Date().getHours()

    if (now < 12) {
      return 'morning'
    }

    if (now < 18) {
      return 'afternoon'
    }

    return 'evening'
  }

  useMesh(getGreeting())

  return (
    <StyledHomeTop data-time={getGreeting()}>
      <div className="width-controller">
        <HomeGreeting time={getGreeting()} />
        <HomeStats />
      </div>
      <canvas id="gradient-canvas" key={getGreeting()} />
    </StyledHomeTop>
  )
}

export default HomeTop
