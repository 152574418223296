import styled from 'styled-components'

const StyledPostTemplateListItem = styled.button`
  background-color: transparent;
  text-align: left;

  .img-preview {
    width: 200px;
    height: 150px;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.color.gray[200]};
    position: relative;
    user-select: none;

    .post-template-icon {
      height: 50px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: ${({ theme }) => theme.color.gray[400]};
    }

    .use-indicator {
      opacity: 0;
      transition: 0.2s;
      display: flex;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 46px;
      z-index: 1;
      white-space: nowrap;
      padding: 0 10px;
      font-weight: 500;
      border-radius: 6px;
      font-size: 15px;
      color: ${({ theme }) => theme.color.gray[800]};
      background-color: ${({ theme }) => theme.color.background};
      box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.2), 0px 0px 0px 1px rgba(0, 0, 0, 0.075),
        0px 2px 3px rgba(0, 0, 0, 0.1);

      img {
        margin-right: 10px;
      }
    }

    .media-preview {
      position: absolute;
      top: 15px;
      left: 15px;
      transition: 0.2s;
      width: calc(100% - 30px);
      height: calc(100% - 30px);
      pointer-events: none;

      img,
      video {
        border-radius: 6px;
        max-width: 100%;
        max-height: 100%;
        width: fit-content;
        margin: 0 auto;
        filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.2));
      }
    }

    &::after {
      user-select: none;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.foreground}10;
      pointer-events: none;
      z-index: 1;
    }
  }

  .template-details {
    margin-top: 12px;

    .template-title {
      font-weight: 500;
      font-size: 15px;
      color: ${({ theme }) => theme.color.gray[800]};
      display: -webkit-box;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-align: left;
      -webkit-box-orient: vertical;
    }

    .caption-preview {
      margin-top: 6px;
      font-size: 12px;
      line-height: 18px;
      color: ${({ theme }) => theme.color.gray[500]};
      display: -webkit-box;
      -webkit-line-clamp: 4;
      overflow: hidden;
      text-align: left;
      -webkit-box-orient: vertical;
    }
  }

  &:hover {
    .img-preview {
      .use-indicator {
        opacity: 1;
      }
    }
  }
`

export default StyledPostTemplateListItem
