import { FC, ReactNode } from 'react'
import { ReactComponent as SCLogoIcon } from '../../../assets/sc-logo-black.svg'
import { ROUTES } from '../../../utils'
import { StyledAuthLayout } from '.'

type Props = {
  children: ReactNode
}

const AuthLayout: FC<Props> = ({ children }) => {
  return (
    <StyledAuthLayout>
      <div className="layout-container">
        <main className="page-content">{children}</main>
        <a href={ROUTES.external.marketing} target="_blank" rel="noreferrer" className="bar">
          <SCLogoIcon />
        </a>
      </div>
      {/* <div className="gradient-bar" /> */}
    </StyledAuthLayout>
  )
}

export default AuthLayout
