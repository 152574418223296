import { FC } from 'react'
import { Upsell } from '../../components'
import { StyledPlanEndedView } from '.'

const PlanEndedView: FC = () => {
  return (
    <StyledPlanEndedView>
      <Upsell />
    </StyledPlanEndedView>
  )
}

export default PlanEndedView
