import { useContext } from 'react'
import { toast } from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { saveAs } from 'file-saver'
import firebase from 'firebase/app'
import { v4 as uuidv4 } from 'uuid'
import { useApolloClient } from '@apollo/client'
import { deleteFileFromCloud, updateCloudContent, uploadFilesToCloud } from '../../graphql'
import {
  addCloudFiles,
  addCloudFolder,
  removeCloudFile,
  removeCloudFiles,
  removeCreatePostMedia,
  removeMediaFromPosts,
  updateCloudFile,
  useAppDispatch,
  useAppSelector
} from '../../redux'
import { CloudContentType, PhotoType } from '../../types'
import { userStore } from '../UserContext'
import { useSegment } from '.'

export const useCloud = () => {
  const storageRef = firebase.storage().ref()
  const apollo = useApolloClient()
  const dispatch = useAppDispatch()
  const { path }: any = useParams()
  const { user } = useContext(userStore)
  const { track } = useSegment()
  const { storage } = useAppSelector((state) => state.cloud)
  const STORAGE_LIMIT = storage.limit * (user?.subscriptionInterval === 'Year' ? 2 : 1)

  const createFolder = async (name: string) => {
    try {
      const folder: Omit<CloudContentType, '_id' | 'createdAt'> = {
        name,
        type: 'folder'
      }

      const { data } = await apollo.mutate({
        fetchPolicy: 'no-cache',
        mutation: uploadFilesToCloud,
        variables: {
          files: [folder],
          parent: path?.split('/')[path?.split('/').length - 1]
        }
      })

      dispatch(addCloudFolder(data?.uploadFilesToCloud[0]))
      toast.success('Created folder')
      track('Created Folder', { name })
      return data?.uploadFilesToCloud[0]
    } catch (err) {
      toast.error((err as any).message)
    }
  }

  const addGalleryPhotoToCloud = async (photo: PhotoType, folder?: string) => {
    const toastId = toast.loading('Adding photo')
    const tags = photo.tags || []
    const name = tags[0]?.label || 'Gallery Image'

    const mediaItem = {
      name,
      type: 'file',
      file: {
        url: photo.file.fileHttpLink,
        path: photo.file.fullPath,
        dimensions: { x: photo.file.imgSize?.width || 0, y: photo.file.imgSize?.height || 0 }
      },
      parent: folder,
      ref: true
    }

    const { data } = await apollo.mutate({
      fetchPolicy: 'no-cache',
      mutation: uploadFilesToCloud,
      variables: {
        files: [mediaItem],
        parent: path?.split('/')[path?.split('/').length - 1]
      }
    })

    dispatch(addCloudFiles(data?.uploadFilesToCloud || []))
    track('Added Gallery Photo to Cloud', { ...mediaItem })

    toast.dismiss(toastId)
    toast.success('Saved photo to Uploads')

    return (data?.uploadFilesToCloud || []) as CloudContentType[]
  }

  const uploadToCloud = async (fileInput: FileList | null | File[]) => {
    if (!fileInput) {
      return []
    }

    const files = Array.isArray(fileInput) ? fileInput : Array.from(fileInput)
    const filesToAdd: Omit<CloudContentType, '_id' | 'createdAt'>[] = []

    const toastId = toast.loading('Uploading media')

    if (!files?.length) {
      return []
    }

    for await (const file of files) {
      await new Promise(async (resolve, reject) => {
        const fileSize = file.size
        if (storage.current + fileSize > STORAGE_LIMIT) {
          return resolve(undefined)
        }

        if (fileSize > 5000000) {
          toast.error(`${file.name} exceeds the 5MB upload limit.`)
          return resolve(undefined)
        }

        if (!file.type.includes('image')) {
          toast.error(`${file.name} is not an image.`)
          return resolve(undefined)
        }

        const fileRef = storageRef.child(`user-images/${uuidv4()}`)

        // Upload image to bucket
        const uploadTask = await fileRef.put(file)
        const imageUrl = await uploadTask.ref.getDownloadURL()

        // Load image dimensions & add to post
        const img = new Image()
        img.src = imageUrl
        img.onload = () => {
          const mediaItem = {
            name: file.name,
            type: 'file',
            file: {
              url: imageUrl,
              path: uploadTask.ref.fullPath,
              ext: file.type,
              size: file.size,
              dimensions: { x: img.width, y: img.height }
            }
          }
          filesToAdd.push(mediaItem)
          track('Uploaded File to Cloud', { ...mediaItem })
          resolve(mediaItem)
        }
        img.onerror = reject
      })
    }

    toast.dismiss(toastId)

    if (!filesToAdd.length) {
      return []
    }

    const { data } = await apollo.mutate({
      fetchPolicy: 'no-cache',
      mutation: uploadFilesToCloud,
      variables: {
        files: filesToAdd,
        parent: path?.split('/')[path?.split('/').length - 1]
      }
    })

    dispatch(addCloudFiles(data?.uploadFilesToCloud || []))
    toast.success('Uploaded media')

    return (data?.uploadFilesToCloud || []) as CloudContentType[]
  }

  const deleteFromCloud = async (list: string[]) => {
    try {
      const toastId = toast.loading('Deleting...')

      await apollo.mutate({
        fetchPolicy: 'no-cache',
        mutation: deleteFileFromCloud,
        variables: {
          list: list
        }
      })

      dispatch(removeMediaFromPosts(list))
      dispatch(removeCreatePostMedia(list))

      list.map((_id) => {
        track('Deleted Content from Cloud', { _id })
      })

      toast.dismiss(toastId)
      toast.success('Deleted')
      dispatch(removeCloudFiles(list))
    } catch (err) {
      toast.error((err as any).message)
    }
  }

  const downloadCloudFile = async (content: CloudContentType) => {
    try {
      saveAs(content.file?.url || '', `${content.name}.${content.file?.ext || 'jpg'}`)
      toast.success('Downloaded file')
      track('Downloaded Cloud File', content)
    } catch (err) {
      toast.error((err as any).message)
    }
  }

  const updateCloudFileData = async (content: CloudContentType) => {
    try {
      dispatch(updateCloudFile(content))

      await apollo.mutate({
        fetchPolicy: 'no-cache',
        mutation: updateCloudContent,
        variables: {
          _id: content._id,
          content: {
            ...content,
            _id: undefined
          }
        }
      })

      track('Updated Cloud File', content)
    } catch (err) {
      toast.error((err as any).message)
    }
  }

  return {
    createFolder,
    uploadToCloud,
    deleteFromCloud,
    downloadCloudFile,
    addGalleryPhotoToCloud,
    updateCloudFileData
  }
}
