import { FC } from 'react'
import { ActionPlanType } from '../../../types'
import { StyledActionPlanPdf } from '.'

type Props = {
  actionPlan: ActionPlanType
}

const ActionPlanPdf: FC<Props> = ({ actionPlan }) => {
  return (
    <StyledActionPlanPdf>
      <iframe
        src={actionPlan.actionPlanPdf?.fileHttpLink || ''}
        allow="fullscreen"
        allowFullScreen
      />
    </StyledActionPlanPdf>
  )
}

export default ActionPlanPdf
