import styled from 'styled-components'

const StyledCreateSocialProfileSelect = styled.div`
  .social-profile-select-btn {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    border-radius: 8px;
    box-shadow: inset 0 0 0 1.25px ${({ theme }) => theme.color.gray[300]};
    width: 100%;
    box-sizing: border-box;
    padding: 0 10px 0 14px;
    background-color: transparent;

    .placeholder {
      font-weight: 500;
      color: ${({ theme }) => theme.color.gray[400]};
    }

    svg {
      height: 24px;
      stroke-width: 2px;
      color: ${({ theme }) => theme.color.gray[400]};
    }

    .social-profile-selection {
      display: flex;
      margin-left: -4px;
    }
  }
`

export default StyledCreateSocialProfileSelect
