import { FC } from 'react'
import { times } from 'lodash'
import { DateTime } from 'luxon'
import { useAppSelector } from '../../../redux'
import { isMobile, parseDate } from '../../../utils'
import { CalendarWeekRowSlot } from '..'
import { StyledCalendarWeekRow } from '.'

type Props = {
  hour: number
  now: DateTime
  thisWeek?: boolean
}

const CalendarWeekRow: FC<Props> = ({ hour, now, thisWeek }) => {
  const { start } = useAppSelector((state) => state.calendar)

  const formatTimeLabel = (num: number) => {
    if (num > 11) {
      return `${num === 12 ? 12 : num - 12}PM`
    }

    return `${num === 0 ? 12 : num}AM`
  }

  return (
    <StyledCalendarWeekRow>
      {hour !== 23 && (
        <div className="calendar-time-slot">
          <span className="calendar-time-slot-label">{formatTimeLabel(hour + 1)}</span>
        </div>
      )}
      {now.hour === hour && thisWeek && (
        <>
          <div
            className="calendar-now"
            style={{ top: `calc(-12px + ${(now.minute / 60) * 100}%)` }}
          >
            <span className="calendar-now-label">NOW</span>
          </div>
          <div
            className="calendar-now-line"
            data-calendar-now-line
            style={{ top: `calc(${(now.minute / 60) * 100}%)` }}
          />
        </>
      )}
      {times(isMobile() ? 2 : 7, (day) => {
        const date = parseDate(start).plus({ days: day }).set({ hour })
        return (
          <CalendarWeekRowSlot
            now={now}
            date={date}
            hour={hour}
            thisWeek={thisWeek}
            key={`${date.day}-${hour}`}
          />
        )
      })}
    </StyledCalendarWeekRow>
  )
}

export default CalendarWeekRow
