import { FC, MouseEvent, useEffect, useState } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { XCircleIcon } from '@heroicons/react/20/solid'
import { PlusIcon } from '@heroicons/react/24/outline'
import { CameraIcon } from '../../../assets'
import { setShowCreate, useAppDispatch, useAppSelector } from '../../../redux'
import { useCreate } from '../../../utils'
import { CloudMediaPreview } from '..'
import { StyledFab } from '.'

const allowedPages = [
  {
    path: '/',
    exact: true
  },
  {
    path: '/calendar'
  },
  {
    path: '/content'
  }
]

const Fab: FC = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const { newPost } = useCreate()
  const { post } = useAppSelector((state) => state.create)
  const [showFab, setShowFab] = useState(false)
  const [fabAnim, setFabAnim] = useState(false)

  const handleClear = (e: MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    newPost()
  }

  const isExpanded = () => {
    return post?._id || !!post?.captionText?.trim() || !!post?.media?.length
  }

  useEffect(() => {
    const shouldShow = !!allowedPages.filter((page) => {
      return matchPath(location.pathname, {
        ...page
      })
    }).length

    if (shouldShow) {
      setFabAnim(false)
      setShowFab(true)
      return
    }

    setFabAnim(true)
    setTimeout(() => {
      setShowFab(false)
    }, 150)
  }, [location.pathname])

  if (!showFab) {
    return null
  }

  return (
    <StyledFab
      id="fab"
      className={classNames('fab', {
        leaving: fabAnim,
        expanded: isExpanded()
      })}
      onClick={() => dispatch(setShowCreate(true))}
    >
      {!isExpanded() && <PlusIcon />}
      {!!isExpanded() && (
        <div className="fab-post">
          <div className="post-details-container">
            <div className="post-preview">
              {!post?.media?.length && <CameraIcon />}
              {!!post?.media?.length && (
                <CloudMediaPreview
                  url={post.media[0].url}
                  type={post.media[0].type}
                  preview={post.media[0].preview}
                />
              )}
            </div>
            <div className="post-details">
              <span className="post-title">{post?.title || 'Untitled Post'}</span>
              <span className="post-description" data-empty={!post?.captionText?.trim()}>
                {post?.captionText || 'Empty caption'}
              </span>
            </div>
          </div>
          <button className="close-btn" onClick={(e) => handleClear(e)}>
            <XCircleIcon />
          </button>
        </div>
      )}
    </StyledFab>
  )
}

export default Fab
