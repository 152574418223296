import { FC, useEffect, useState } from 'react'
import { throttle } from 'lodash'
import { ChevronUpIcon } from '@heroicons/react/24/outline'
import { StyledToTopButton } from '.'

const ToTopButton: FC = () => {
  const [show, setShow] = useState(false)

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    const handleScroll = throttle(() => {
      const scrollOffset = window.pageYOffset
      const pageHeight = window.innerHeight

      setShow(scrollOffset > pageHeight)
    }, 333)

    window.addEventListener('scroll', handleScroll)
    window.addEventListener('resize', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleScroll)
    }
  }, [])

  if (!show) {
    return null
  }

  return (
    <StyledToTopButton onClick={() => scrollToTop()}>
      <ChevronUpIcon />
    </StyledToTopButton>
  )
}

export default ToTopButton
