import styled, { keyframes } from 'styled-components'

const checkAnim = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`

const StyledCreateBarTasks = styled.div`
  .todo-completed-container {
    padding: 20px;

    .todo-completed {
      padding: 20px;
      background-color: ${({ theme }) => theme.color.brandLight};
      border-radius: 6px;

      .icon {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        color: #fff;
        display: flex;
        margin: 0 auto;
        align-items: center;
        justify-content: center;
        background-color: ${({ theme }) => theme.color.brand};

        svg {
          height: 42px;

          path {
            stroke-dasharray: 1000;
            stroke-dashoffset: 1000;
            animation: ${checkAnim} 15s linear forwards;
          }
        }
      }

      span {
        text-align: center;
      }

      .title {
        display: block;
        font-size: 22px;
        font-weight: 600;
        margin-top: 20px;
        color: ${({ theme }) => theme.color.gray[800]};
        font-family: ${({ theme }) => theme.typeface.faktumXCon};
      }

      .body {
        font-size: 13px;
        display: block;
        line-height: 18px;
        margin-top: 10px;
        color: ${({ theme }) => theme.color.gray[600]};
      }

      button {
        margin-top: 20px;
      }
    }
  }

  .todo-list {
    display: grid;

    .todo {
      display: flex;
      align-items: center;
      padding: 20px;

      .check-icon {
        height: 36px;
        width: 36px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${({ theme }) => theme.color.gray[100]};
        color: ${({ theme }) => theme.color.gray[400]};
        border-radius: 50%;
        margin-right: 10px;

        svg {
          height: 24px;
        }
      }

      .label {
        font-size: 15px;
        color: ${({ theme }) => theme.color.gray[700]};
      }

      &:not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.color.gray[200]};
      }

      &[aria-selected='true'] {
        .check-icon {
          background-color: ${({ theme }) => theme.color.green[600]};
          color: ${({ theme }) => theme.color.background};
        }

        .label {
          text-decoration: line-through;
          color: ${({ theme }) => theme.color.green[600]};
        }
      }
    }
  }
`

export default StyledCreateBarTasks
