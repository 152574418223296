import styled from 'styled-components'

const StyledCalendarWeekHourRow = styled.div`
  display: flex;
  position: relative;

  .now-indicator {
    height: 1px;
    background-color: ${({ theme }) => theme.color.brand};
    left: 0;
    right: 0;
    width: 100%;
    position: absolute;
    z-index: 999;
  }

  &:not(:last-child) {
    border-bottom: 1px dashed ${({ theme }) => theme.color.gray[300]};
  }
`

export default StyledCalendarWeekHourRow
