import { FC, useContext, useEffect, useState } from 'react'
import { uniqBy } from 'lodash'
import { Button, Spinner } from '../..'
import { ConnectionListItem, connectionStore } from '..'
import { StyledConnectionSelect } from '.'

const ConnectionSelect: FC = () => {
  const {
    availableProfiles,
    activeProfiles,
    expiredProfiles,
    profilesToConnect,
    platformToConnect,
    connectionLimit,
    loading,
    toggleProfile,
    connectProfiles
  } = useContext(connectionStore)
  const [atLimit, setAtLimit] = useState(false)

  useEffect(() => {
    const profiles = uniqBy(
      [
        ...activeProfiles.filter((profile) => profile.type === platformToConnect),
        ...profilesToConnect
      ],
      'profileId'
    )
    setAtLimit(profiles.length >= connectionLimit)
  }, [activeProfiles, profilesToConnect])

  return (
    <StyledConnectionSelect>
      <div className="connection-select-header">
        <span>Which profiles do you want to connect?</span>
      </div>
      <div className="connection-select-list">
        {availableProfiles.map((profile) => {
          return (
            <>
              <ConnectionListItem
                profile={profile}
                isSelected={
                  !![...activeProfiles, ...profilesToConnect].find(
                    (p) => p.profileId === profile.profileId
                  )
                }
                isDisabled={
                  !![...activeProfiles, ...expiredProfiles].find(
                    (p) => p.profileId === profile.profileId
                  ) ||
                  (atLimit && !profilesToConnect.find((p) => p.profileId === profile.profileId))
                }
                onSelect={() => toggleProfile(profile)}
                key={profile._id}
              />
            </>
          )
        })}
      </div>
      <div className="connection-action-list">
        <Button
          size="lg"
          variant="emphasis"
          disabled={!profilesToConnect.length || loading}
          isFullWidth
          onClick={() => connectProfiles()}
        >
          {loading && <Spinner />}
          {!loading && <>Connect profile{profilesToConnect.length > 1 && 's'}</>}
        </Button>
      </div>
    </StyledConnectionSelect>
  )
}

export default ConnectionSelect
