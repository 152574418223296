import { orderBy } from 'lodash'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { CloudContentType } from '../../types'

type InitialState = {
  search: string
  sort: 'createdAt' | 'updatedAt' | 'a-z'
  parent: string
  files: CloudContentType[]
  folders: CloudContentType[]
  storage: {
    limit: number
    current: number
  }
  totalDocs: number
  page: number
  loading: boolean
  showBackTooltip?: boolean
  preview?: CloudContentType
}

const initialState: InitialState = {
  search: '',
  sort: 'createdAt',
  parent: '',
  files: [],
  folders: [],
  storage: {
    limit: 536870912,
    current: 0
  },
  totalDocs: 0,
  page: 0,
  loading: true,
  showBackTooltip: true
}

export const cloudSlice = createSlice({
  name: 'cloud',
  initialState,
  reducers: {
    setCloudSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload
    },
    setCloudSort: (state, action: PayloadAction<InitialState['sort']>) => {
      state.sort = action.payload
    },
    setCloudParent: (state, action: PayloadAction<string>) => {
      state.parent = action.payload
    },
    setCloudStorage: (state, action: PayloadAction<number>) => {
      state.storage.current = action.payload
    },
    setCloudFiles: (state, action: PayloadAction<CloudContentType[]>) => {
      state.files = action.payload
      state.loading = false
    },
    setCloudPreview: (state, action: PayloadAction<InitialState['preview']>) => {
      state.preview = action.payload
    },
    addCloudFile: (state, action: PayloadAction<CloudContentType>) => {
      state.storage.current = state.storage.current + (action.payload?.file?.size || 0)
      state.files = orderBy([action.payload, ...state.files], ['createdAt'], ['desc'])
      state.totalDocs = state.totalDocs + 1
    },
    addCloudFiles: (state, action: PayloadAction<CloudContentType[]>) => {
      state.files = orderBy([...action.payload, ...state.files], ['createdAt'], ['desc'])
      state.totalDocs = state.totalDocs + action.payload.length

      state.storage.current = action.payload.reduce((amount, file) => {
        return amount + (file.file?.size || 0)
      }, state.storage.current)
    },
    updateCloudFile: (state, action: PayloadAction<CloudContentType>) => {
      state.files = state.files.map((file) => {
        if (file._id === action.payload._id) {
          return {
            ...file,
            ...action.payload
          }
        }

        return file
      })
    },
    removeCloudFile: (state, action: PayloadAction<string>) => {
      const fileToRemove = state.files.find((file) => file._id)
      state.storage.current = state.storage.current - (fileToRemove?.file?.size || 0)
      state.files = state.files.filter((file) => file._id !== action.payload)
      state.totalDocs = state.totalDocs - 1
    },
    removeCloudFiles: (state, action: PayloadAction<string[]>) => {
      let sizeRemoved = 0

      for (const _id in action.payload) {
        const fileToRemove = state.files.find((file) => file._id)
        sizeRemoved += fileToRemove?.file?.size || 0
      }

      state.storage.current = state.storage.current - sizeRemoved
      state.files = state.files.filter((file) => !action.payload.includes(file._id))
      state.totalDocs = state.totalDocs - action.payload.length
    },
    setCloudFolders: (state, action: PayloadAction<CloudContentType[]>) => {
      state.folders = action.payload
    },
    addCloudFolder: (state, action: PayloadAction<CloudContentType>) => {
      state.folders = [action.payload, ...state.folders]
    },
    updateCloudFolder: (state, action: PayloadAction<CloudContentType>) => {
      state.folders = state.folders.map((folder) => {
        if (folder._id === action.payload._id) {
          return {
            ...folder,
            ...action.payload
          }
        }

        return folder
      })
    },
    removeCloudFolder: (state, action: PayloadAction<string>) => {
      state.folders = state.folders.filter((folder) => folder._id !== action.payload)
    },
    setCloudTotalDocs: (state, action: PayloadAction<number>) => {
      state.totalDocs = action.payload
    },
    setCloudPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload
      state.loading = true
    },
    clearCloudState: (state) => {
      state.files = []
      state.page = 0
      state.totalDocs = 0
      state.loading = true
    },
    setHideCloudBackTooltip: (state) => {
      state.showBackTooltip = false
    }
  }
})

export const {
  setCloudSearch,
  setCloudSort,
  setCloudParent,
  setCloudStorage,
  setCloudFiles,
  setCloudPreview,
  addCloudFile,
  addCloudFiles,
  updateCloudFile,
  removeCloudFile,
  removeCloudFiles,
  setCloudFolders,
  addCloudFolder,
  updateCloudFolder,
  removeCloudFolder,
  setCloudTotalDocs,
  setCloudPage,
  clearCloudState,
  setHideCloudBackTooltip
} = cloudSlice.actions
export default cloudSlice.reducer
