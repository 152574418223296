import html2pdf from 'html2pdf.js'
import { FC, useContext, useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { Button, PageLayout } from '../../components'
import { getActiveSplits, userStore, useSplit } from '../../utils'
import { useAppSelector } from '../../redux'
import { StyledDiagnosticsView } from '.'

const DiagnosticsView: FC = () => {
  const { user } = useContext(userStore)
  const { splits } = useAppSelector(state => state.splits)
  const [loading, setLoading] = useState(false)

  const { configureSplit } = useSplit()

  const handleDownlad = async () => {
    setLoading(true)
    const table = document.querySelector('#root')
    await html2pdf()
      .from(table)
      .save(`sc-diagnostics-${DateTime.now().toSeconds()}.pdf`)
    setLoading(false)
  }

  useEffect(() => {
    if (!user?.id) {
      return
    }

    configureSplit()
  }, [user?.id])

  return (
    <PageLayout maxWidth={450}>
      <StyledDiagnosticsView>
        <h1>Diagnostics</h1>
        <table id="table">
          <tr>
            <td>
              <code>WINDOW_WIDTH</code>
            </td>
            <td>
              <code>{window.innerWidth}px</code>
            </td>
          </tr>
          <tr>
            <td>
              <code>WINDOW_HEIGHT</code>
            </td>
            <td>
              <code>{window.innerHeight}px</code>
            </td>
          </tr>
          <tr>
            <td>
              <code>USER_AGENT</code>
            </td>
            <td>
              <code>{window?.navigator?.userAgent || 'N/A'}</code>
            </td>
          </tr>
          <tr>
            <td>
              <code>UID</code>
            </td>
            <td>
              <code>{user?.id || 'N/A'}</code>
            </td>
          </tr>
          <tr>
            <td>
              <code>ACTIVE_SPLITS</code>
            </td>
            <td>
              <code>{JSON.stringify(getActiveSplits(splits))}</code>
            </td>
          </tr>
        </table>
        {!loading && (
          <Button
            variant="emphasis-outline"
            iconPos="left"
            isFullWidth
            onClick={() => handleDownlad()}
          >
            <ArrowDownTrayIcon /> Download Diagnostics
          </Button>
        )}
      </StyledDiagnosticsView>
    </PageLayout>
  )
}

export default DiagnosticsView
