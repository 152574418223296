import { FC, ReactNode, useContext } from 'react'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { Button } from '..'
import { Bubble, StyledBubbles } from '.'

type Props = {
  children?: JSX.Element | JSX.Element[]
  value: string[]
  options: { label: string; value: string }[]
  onSelect: (value: { label: string; value: string }) => void
}

const Bubbles: FC<Props> = ({ children, value, options, onSelect }) => {
  const ArrowLeft = () => {
    const { isFirstItemVisible, initComplete, scrollPrev } = useContext(VisibilityContext)

    if (!initComplete || isFirstItemVisible) {
      return <></>
    }

    return (
      <div className="arrow-background arrow-left">
        <Button
          size="sm"
          className="arrow-btn"
          variant="outline"
          isIcon
          onClick={() => scrollPrev()}
        >
          <ChevronLeftIcon />
        </Button>
      </div>
    )
  }

  const ArrowRight = () => {
    const { isLastItemVisible, scrollNext } = useContext(VisibilityContext)

    if (isLastItemVisible) {
      return <></>
    }

    return (
      <div className="arrow-background arrow-right">
        <Button
          size="sm"
          className="arrow-btn"
          variant="outline"
          isIcon
          onClick={() => scrollNext()}
        >
          <ChevronRightIcon />
        </Button>
      </div>
    )
  }

  let bubbleOptions = options.map((option) => {
    return (
      <Bubble
        label={option.label}
        isSelected={value.includes(option.label) || value.includes(option.value)}
        onClick={() => onSelect(option)}
        key={option.value}
      />
    )
  })

  if (!!children && Array.isArray(children)) {
    bubbleOptions = children.concat(bubbleOptions)
  }

  if (!!children && !Array.isArray(children)) {
    bubbleOptions.unshift(children)
  }

  return (
    <StyledBubbles className="bubbles">
      <ScrollMenu LeftArrow={<ArrowLeft />} RightArrow={<ArrowRight />}>
        {bubbleOptions}
      </ScrollMenu>
    </StyledBubbles>
  )
}

export default Bubbles
