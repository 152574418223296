import { FC, useState } from 'react'
import { Capacitor } from '@capacitor/core'
import { CreditCardIcon } from '@heroicons/react/24/solid'
import { ReactComponent as AmexIcon } from '../../../assets/amex.svg'
import { ReactComponent as DiscoverIcon } from '../../../assets/discover.svg'
import { ReactComponent as JcbIcon } from '../../../assets/jcb.svg'
import { ReactComponent as MastercardIcon } from '../../../assets/mastercard.svg'
import { ReactComponent as VisaIcon } from '../../../assets/visa.svg'
import { useSubscription } from '../../../utils'
import { Button, PaymentMethodDialog, Skeleton } from '../..'
import { StyledPaymentMethodCard } from '.'

const PaymentMethodCard: FC = () => {
  const { card, loading } = useSubscription()
  const [showPaymentDialog, setShowPaymentDialog] = useState(false)

  const getCardIcon = () => {
    switch (card?.brand) {
      case 'Visa':
        return <VisaIcon />
      case 'Mastercard':
        return <MastercardIcon />
      case 'Amex':
        return <AmexIcon />
      case 'Jcb':
        return <JcbIcon />
      case 'Discover':
        return <DiscoverIcon />
      default:
        return <CreditCardIcon />
    }
  }

  if (Capacitor.isNativePlatform()) {
    return <></>
  }

  return (
    <>
      <StyledPaymentMethodCard className="billing-card">
        <div className="billing-card-content">
          <div className="billing-card-details">
            <span className="billing-card-title">Payment method</span>
            <span className="billing-card-description">Change how you pay for your plan</span>
          </div>
          <div className="billing-card-card-details-container">
            {loading && <Skeleton randHeight={[38, 38]} />}
            {!loading && !card && (
              <div className="billing-card-empty-state">
                <span>No Payment Method on File</span>
              </div>
            )}
            {!loading && !!card && (
              <>
                <div className="billing-card-content-group">
                  <div className="billing-card-card-icon">{getCardIcon()}</div>
                  <div className="billing-card-card-details">
                    <span className="card-number">
                      {card?.brand} ends in {card?.last4}
                    </span>
                    <span className="card-expiry">
                      Expires {card?.expMonth}/{card?.expYear}
                    </span>
                  </div>
                </div>
                <Button size="sm" onClick={() => setShowPaymentDialog(true)}>
                  Edit
                </Button>
              </>
            )}
          </div>
        </div>
      </StyledPaymentMethodCard>
      <PaymentMethodDialog isOpen={showPaymentDialog} onClose={() => setShowPaymentDialog(false)} />
    </>
  )
}

export default PaymentMethodCard
