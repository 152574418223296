import { useContext } from 'react'
import { toast } from 'react-hot-toast'
import { useApolloClient } from '@apollo/client'
import { Capacitor } from '@capacitor/core'
import { Preferences } from '@capacitor/preferences'
import { connectTwitter, generateTwitterConnectURL } from '../../../graphql'
import { userStore } from '../../UserContext'
import { useSegment } from '../useSegment'

export const useLoginTwitter = () => {
  const apollo = useApolloClient()
  const native = Capacitor.isNativePlatform()
  const { refresh } = useContext(userStore)
  const { track } = useSegment()

  const loginTwitter = async () => {
    const callback = native ? 'socialcurator://' : `${window.location.origin}/connect`

    const { data } = await apollo.query({
      fetchPolicy: 'no-cache',
      query: generateTwitterConnectURL,
      variables: {
        callback
      }
    })

    if (!data.generateTwitterConnectURL) {
      return toast.error('Whoops, please try again')
    }

    const { oauth_token_secret, url } = data.generateTwitterConnectURL

    await Preferences.set({
      key: 'twitter-secret',
      value: oauth_token_secret
    })

    window.open(
      url,
      '',
      'resizable=yes,width=600,height=400,toolbar=no,titlebar=no,menubar=no,scrollbars=yes'
    )

    window.addEventListener('message', (event) => {
      if (event.origin !== window.origin) {
        return
      }

      if (typeof event.data !== 'object') {
        return
      }

      if (event.data?.type !== 'connected-profile') {
        return
      }

      refresh()
    })

    // window.location.href = url
  }

  const connectTwitterProfile = async (code: string, verifier: string, secret: string) => {
    try {
      await apollo.mutate({
        mutation: connectTwitter,
        variables: { code, verifier, secret }
      })

      await refresh()
      track('Connected Social Profile')
      track('Connected Twitter Profile')
      toast.success(`Successfully connected your social profile`)
    } catch (err) {
      console.error(err)
    }
  }

  return {
    loginTwitter,
    connectTwitterProfile
  }
}
