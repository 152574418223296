import { gql } from '@apollo/client'

export const affiliateEarningCreateOne = gql`
  mutation AffiliateEarningCreateOne(
    $user: String!
    $affiliate: String!
    $amount: Float!
    $item: AffiliateEarningItemInput
    $trialStartDate: Date
    $userPaidDate: Date
  ) {
    affiliateEarningCreateOne(
      record: {
        user: $user
        affiliate: $affiliate
        amount: $amount
        item: $item
        trialStartDate: $trialStartDate
        userPaidDate: $userPaidDate
      }
    ) {
      recordId
    }
  }
`
