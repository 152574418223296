import { FC, createRef, useState } from 'react'
import emoji from 'react-easy-emoji'
import ReactMarkdown from 'react-markdown'
import { DateTime } from 'luxon'
import rehypeRaw from 'rehype-raw'
import { Keyboard } from '@capacitor/keyboard'
import { SparklesIcon } from '@heroicons/react/20/solid'
import {
  ArrowPathIcon,
  ArrowTopRightOnSquareIcon,
  ArrowUturnRightIcon,
  DocumentTextIcon,
  HashtagIcon,
  LightBulbIcon,
  PhotoIcon,
  VideoCameraIcon
} from '@heroicons/react/24/outline'
import { ChatIcon } from '../../../../assets'
import { setShowChat, setShowCreate, useAppDispatch, useAppSelector } from '../../../../redux'
import { ChatMessageType } from '../../../../types'
import { conditionalStage, useChat, useChatMessage } from '../../../../utils'
import { PullDown } from '../..'
import { StyledChatMessage } from '.'

type Props = {
  message: ChatMessageType
  isSub?: boolean
}

const ChatMessage: FC<Props> = ({ message, isSub }) => {
  const remixBtnRef = createRef<HTMLButtonElement>()
  const adjustBtnRef = createRef<HTMLButtonElement>()
  const dispatch = useAppDispatch()
  const [showAdjustOptions, setShowAdjustOptions] = useState(false)
  const [showRemixOptions, setShowRemixOptions] = useState(false)
  const { getTemplateLabel, scrollToMessage } = useChat()
  const { addToPost, tryAgain, remix } = useChatMessage()
  const { loading } = useAppSelector((state) => state.chat)

  const handleUse = () => {
    try {
      dispatch(setShowCreate(true))
      dispatch(setShowChat(false))
      addToPost(message)
      Keyboard.hide()
    } catch (err) {}
  }

  return (
    <>
      <StyledChatMessage type={message.type} data-message={message._id} isSub={isSub}>
        {message.type === 'sent' && message.template && !message.ref && (
          <div className="conversation-item-used-template">
            <SparklesIcon />
            {getTemplateLabel(message.template)}
          </div>
        )}
        {message.type === 'sent' && !!message.ref && (
          <a
            className="conversation-item-used-template reply-link"
            onClick={() => scrollToMessage(message.ref || '')}
          >
            <ArrowUturnRightIcon />
            Reply
          </a>
        )}
        <div className="conversation-item-container">
          {message.type === 'received' && (
            <div className="chat-bot-avatar">
              <ChatIcon />
            </div>
          )}
          <div className="conversation-item-bubble">
            <span>
              <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                components={{
                  img({ src, alt, title, height, width }) {
                    return (
                      <div className="image-result" style={{ aspectRatio: `${width}/${height}` }}>
                        <img src={src} alt={alt} />
                      </div>
                    )
                  },
                  a({ href, children }) {
                    return (
                      <a href={href} target="_blank">
                        {children}
                      </a>
                    )
                  }
                }}
              >
                {
                  message.content
                    ?.replace(/(<\/?(?:caption|hashtag|suggestion)[^>]*>)/gi, '')
                    .replace('<>', '')
                    .split('<s')[0]
                }
              </ReactMarkdown>
            </span>
          </div>
        </div>
        {message.type === 'received' && message.status === 'success' && (
          <div className="conversation-item-actions">
            {!['daily-action'].includes(message.template || '') && (
              <button disabled={loading} onClick={() => handleUse()}>
                Add to post
              </button>
            )}
            {message.template && (
              <>
                {!['daily-action', 'post-title', 'photo', 'video'].includes(message.template) && (
                  <button
                    className="adjust-btn"
                    disabled={loading}
                    data-active={showAdjustOptions}
                    onClick={() => setShowAdjustOptions(!showAdjustOptions)}
                    ref={adjustBtnRef}
                  >
                    {/* <AdjustmentsHorizontalIcon /> */}
                    Adjust
                  </button>
                )}
                {message.template === 'daily-action' && !!message.metadata?.dailyAction?.isUrl && (
                  <button
                    className="open-content-btn dark"
                    disabled={loading}
                    onClick={() => window.open(message.metadata?.dailyAction?.url || '', '_blank')}
                  >
                    Learn More
                    <ArrowTopRightOnSquareIcon />
                  </button>
                )}
                {!['daily-action', 'post-title'].includes(message.template) && (
                  <button
                    className="remix-btn"
                    disabled={loading}
                    data-active={showRemixOptions}
                    onClick={() => setShowRemixOptions(!showRemixOptions)}
                    ref={remixBtnRef}
                  >
                    {/* <SparklesIcon /> */}
                    Remix
                  </button>
                )}
                {['daily-action'].includes(message.template) && (
                  <button className="reply-btn" disabled={loading} onClick={() => remix(message)}>
                    <ArrowUturnRightIcon />
                    Reply
                  </button>
                )}
                <button
                  data-tooltip="Try again"
                  data-tooltip-pos="top-center"
                  disabled={loading}
                  className="icon-btn"
                  onClick={() => tryAgain(message)}
                >
                  <ArrowPathIcon />
                </button>
              </>
            )}
          </div>
        )}
        <div className="conversation-item-extra">
          <span>{DateTime.fromISO(message.createdAt).toFormat('LLL. d, h:mma')}</span>
        </div>
      </StyledChatMessage>
      <PullDown
        rows={[
          {
            icon: <DocumentTextIcon />,
            label: 'Make shorter',
            type: 'button',
            action: () => remix(message, '', 'shorter')
          },
          {
            icon: <DocumentTextIcon />,
            label: 'Make longer',
            type: 'button',
            action: () => remix(message, '', 'longer')
          },
          {
            type: 'divider'
          },
          {
            icon: <i>{emoji('💼')}</i>,
            label: 'Professional',
            type: 'button',
            action: () => remix(message, '', 'professional')
          },
          {
            icon: <i>{emoji('🙂')}</i>,
            label: 'Neutral',
            type: 'button',
            action: () => remix(message, '', 'neutral')
          },
          {
            icon: <i>{emoji('😃')}</i>,
            label: 'Casual',
            type: 'button',
            action: () => remix(message, '', 'casual')
          },
          {
            icon: <i>{emoji('😂')}</i>,
            label: 'Funny',
            type: 'button',
            action: () => remix(message, '', 'funny')
          }
        ]}
        // labelsBefore={[
        //   {
        //     before: 'Make shorter',
        //     label: 'Text length'
        //   },
        //   {
        //     before: 'Professional',
        //     label: 'Writing style'
        //   }
        // ]}
        position="top-center"
        targetRef={adjustBtnRef}
        isOpen={showAdjustOptions}
        onClose={() => setShowAdjustOptions(false)}
      />
      <PullDown
        rows={[
          ...conditionalStage(['caption', 'blanks'].includes(message.template || ''), [
            {
              icon: <PhotoIcon />,
              label: 'Find related photo',
              type: 'button',
              action: () => remix(message, 'photo')
            },
            {
              icon: <HashtagIcon />,
              label: 'Suggest hashtags',
              type: 'button',
              action: () => remix(message, 'hashtags')
            },
            {
              icon: <VideoCameraIcon />,
              label: 'Turn into video script',
              type: 'button',
              action: () => remix(message, 'video-script')
            },
            {
              icon: <LightBulbIcon />,
              label: 'Find visual ideas',
              type: 'button',
              action: () => remix(message, 'visual-ideas')
            }
          ]),
          ...conditionalStage(['photo'].includes(message.template || ''), [
            {
              icon: <DocumentTextIcon />,
              label: 'Generate caption',
              type: 'button',
              action: () => remix(message, 'caption')
            },
            {
              icon: <HashtagIcon />,
              label: 'Suggest hashtags',
              type: 'button',
              action: () => remix(message, 'hashtags')
            }
          ]),
          {
            icon: <ChatIcon />,
            label: 'Custom reply',
            type: 'button',
            action: () => remix(message)
          }
        ]}
        position="top-center"
        targetRef={remixBtnRef}
        isOpen={showRemixOptions}
        onClose={() => setShowRemixOptions(false)}
      />
    </>
  )
}

export default ChatMessage
