import { FC, useState } from 'react'
import { startCase } from 'lodash'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { PaintBrushIcon, PhotoIcon, VideoCameraIcon } from '@heroicons/react/20/solid'
import { TrashIcon } from '@heroicons/react/24/solid'
import { PostType } from '../../../types'
import { ActionDialog } from '../../dialogs'
import { StyledEditorMediaListItem } from '.'

type Props = {
  item: NonNullable<PostType['media']>[number]
  index: number
  onDelete: () => void
}

const EditorMediaListItem: FC<Props> = ({ item, index, onDelete }) => {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const {
    overIndex,
    activeIndex,
    attributes,
    listeners,
    isDragging,
    isOver,
    setNodeRef,
    transform,
    transition
  } = useSortable({
    animateLayoutChanges: () => false,
    id: item._id,
    data: {
      item,
      index
    }
  })

  const style = {
    transform: CSS.Translate.toString(transform),
    transition
  }

  return (
    <>
      <StyledEditorMediaListItem
        style={style}
        ref={setNodeRef}
        $isDragging={isDragging}
        {...attributes}
        {...listeners}
      >
        <div className="group">
          <div className="drag-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M8.5 10a2 2 0 1 0 2 2a2 2 0 0 0-2-2Zm0 7a2 2 0 1 0 2 2a2 2 0 0 0-2-2Zm7-10a2 2 0 1 0-2-2a2 2 0 0 0 2 2Zm-7-4a2 2 0 1 0 2 2a2 2 0 0 0-2-2Zm7 14a2 2 0 1 0 2 2a2 2 0 0 0-2-2Zm0-7a2 2 0 1 0 2 2a2 2 0 0 0-2-2Z"
              />
            </svg>
          </div>
          <div className="media-preview">
            <img src={item.preview || item.url} alt="" />
          </div>
          {item?.dimensions?.x && item?.dimensions?.y && (
            <div className="media-details">
              <span className="size">
                {item.dimensions.x} x {item.dimensions.y}
              </span>
              <span className="type">
                {item.type === 'image' && <PhotoIcon />}
                {item.type === 'video' && <VideoCameraIcon />}
                {item.type === 'design' && <PaintBrushIcon />}
                {startCase(item.type)}
              </span>
            </div>
          )}
        </div>
        <div className="group">
          <button className="action-btn" onClick={() => setShowConfirmationDialog(true)}>
            <TrashIcon />
          </button>
        </div>
      </StyledEditorMediaListItem>
      <ActionDialog
        title="Remove media?"
        body={`Are you sure you want to remove this?`}
        type="error"
        confirmButtonText="Remove"
        isOpen={showConfirmationDialog}
        onClose={() => setShowConfirmationDialog(false)}
        onConfirm={() => onDelete()}
      />
    </>
  )
}

export default EditorMediaListItem
