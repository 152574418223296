import { FC } from 'react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { StyledBubble } from '.'

type Props = {
  label: string
  isSelected?: boolean
  onClick: () => void
}

const Bubble: FC<Props> = ({ label, isSelected, onClick }) => {
  return (
    <StyledBubble
      className="bubble"
      aria-selected={isSelected}
      isSelected={isSelected}
      type="button"
      onClick={() => onClick()}
    >
      {isSelected && <CheckIcon />}
      {label}
    </StyledBubble>
  )
}

export default Bubble
