import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

type StyledProps = {
  isOver?: boolean
}

const StyledCloudFolderListItem = styled(NavLink)<StyledProps>`
  padding: 6px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-radius: 5px;
  transition: 0.2s;
  position: relative;
  text-decoration: none;
  background-color: ${({ theme }) => theme.color.gray[100]};

  .cloud-folder-details-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .cloud-folder-icon {
      margin: -2px 20px 0 0px;
      position: relative;
      top: 3px;
      display: flex;
      width: fit-content;
      pointer-events: none;
      user-select: none;

      .cloud-folder-photo-count {
        height: 22px;
        min-width: 22px;
        padding: 0 6px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50rem;
        font-size: 12px;
        font-weight: 500;
        background-color: #fff;
        color: ${({ theme }) => theme.color.brand};
        position: absolute;
        box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.2), 0px 0px 0px 1px rgba(0, 0, 0, 0.075),
          0px 2px 3px rgba(0, 0, 0, 0.1);
        bottom: 6px;
        right: 0;
      }

      img {
        height: 50px;
        width: 57px;
      }
    }
  }

  .folder-options-btn {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    flex-shrink: 0;
    margin-left: 10px;
  }

  .cloud-folder-upload-icon {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.color.brand};

    svg {
      height: 42px;
    }
  }

  .cloud-folder-details {
    .cloud-folder-title {
      font-weight: 500;
      font-size: 15px;
      color: ${({ theme }) => theme.color.gray[700]};
      display: -webkit-box;
      word-break: break-word;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-align: left;
    }

    .cloud-folder-detail {
      display: block;
      font-size: 14px;
      margin-top: 2px;
      color: ${({ theme }) => theme.color.gray[500]};
    }
  }

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.color.gray[200]};
  }

  &.active {
    box-shadow: inset 0 0 0 2px ${({ theme }) => theme.color.brand};

    .cloud-folder-details {
      .cloud-folder-title,
      .cloud-folder-detail {
        color: ${({ theme }) => theme.color.brand};
        display: -webkit-box;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-align: left;
        -webkit-box-orient: vertical;
      }
    }
  }

  ${({ isOver, theme }) =>
    isOver &&
    `
    box-shadow: inset 0 0 0 2px ${theme.color.brand};
    background-color: ${theme.color.brandLight};

    .cloud-folder-details, .cloud-folder-icon { {
      opacity: 0;
    }
  `}
`

export default StyledCloudFolderListItem
