import { FC, useEffect } from 'react'
import { ReactComponent as SCLogo } from '../../../assets/sc-logo-black.svg'
import { PageType } from '../../../types'
import { ROUTES, fontList } from '../../../utils'
import { PageLink } from '..'
import { StyledPagePreview } from '.'

type Props = {
  page: PageType
  newAvatar?: {
    file: File | null
    preview: string | null
  }
  onLinkClick?: (block: string) => void
}

const PagePreview: FC<Props> = ({ page, newAvatar, onLinkClick }) => {
  const getFontWeight = (fontName: string) => {
    if (fontName.includes('Thin')) {
      return 100
    }
    if (fontName.includes('ExtraLight')) {
      return 200
    }
    if (fontName.includes('Light')) {
      return 300
    }
    if (fontName.includes('Medium')) {
      return 500
    }
    if (fontName.includes('Bold')) {
      return 600
    }
    if (fontName.includes('SemiBold')) {
      return 700
    }
    if (fontName.includes('ExtraBold')) {
      return 800
    }
    if (fontName.includes('Heavy') || fontName.includes('Black')) {
      return 900
    }
    return 400
  }

  const getFontStyle = (fontName: string) => {
    if (fontName.includes('Italic')) {
      return 'italic'
    }
    return 'normal'
  }

  const loadFontToDoc = async (fonts: typeof fontList) => {
    await Promise.all(
      fonts.map(async (font) => {
        const loadedFont = await new FontFace(font.family, `url(${font.url})`, {
          weight: getFontWeight(font.post_script_name).toString(),
          style: getFontStyle(font.post_script_name)
        }).load()
        document.fonts.add(loadedFont)
      })
    )
  }

  const loadFont = async () => {
    const fonts = fontList.filter((font) => font.family === page.styles?.page?.fontFamily)

    if (!fonts?.length) {
      return
    }

    await loadFontToDoc(fonts)
  }

  useEffect(() => {
    loadFont()
  }, [page.styles?.page?.fontFamily])

  return (
    <StyledPagePreview styles={page.styles}>
      <div className="page-content">
        <div className="page-header">
          <div className="page-photo">
            {newAvatar === undefined && !!page.avatar && <img src={page.avatar} alt={page.name} />}
            {!!newAvatar?.preview && <img src={newAvatar?.preview || ''} alt={page.name} />}
          </div>
          <h1>{page.name}</h1>
          {page.description && <h2>{page.description}</h2>}
        </div>
        <div className="page-links">
          {!page.links?.length && (
            <>
              <div className="page-link-dummy" />
              <div className="page-link-dummy" />
              <div className="page-link-dummy" />
              <div className="page-link-dummy" />
              <div className="page-link-dummy" />
              <div className="page-link-dummy" />
            </>
          )}
          {page.links?.map((link, index) => {
            return <PageLink link={link} key={link._id} onClick={onLinkClick} />
          })}
        </div>
      </div>
      <a href={ROUTES.external.marketing} target="_blank">
        <SCLogo className="logo-footer" />
      </a>
    </StyledPagePreview>
  )
}

export default PagePreview
