import { FC, useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import { CalendarMarkIcon } from '../../../../assets'
import { useAppDispatch, useAppSelector } from '../../../../redux'
import { setCalendarWeek } from '../../../redux'
import { StyledCalendarMonthButton } from '.'

const CalendarMonthButton: FC = () => {
  const dispatch = useAppDispatch()
  const [month, setMonth] = useState(DateTime.now().toFormat('LLLL'))
  const { week, start } = useAppSelector((state) => state.calendarMobile)

  useEffect(() => {
    setMonth(DateTime.fromISO(start).toFormat('LLLL'))
  }, [start])

  return (
    <StyledCalendarMonthButton>
      <button className="month-btn">
        {month}
        {/* <ChevronDownIcon /> */}
      </button>
      <button
        className="today-btn"
        data-active={week === 13}
        onClick={() => dispatch(setCalendarWeek(13))}
      >
        <CalendarMarkIcon />
      </button>
    </StyledCalendarMonthButton>
  )
}

export default CalendarMonthButton
