import { FC, RefObject, useContext, useEffect } from 'react'
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom'
import { Capacitor } from '@capacitor/core'
import {
  ArrowRightCircleIcon,
  AtSymbolIcon,
  BoltIcon,
  BookOpenIcon,
  BriefcaseIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  Cog6ToothIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
  MegaphoneIcon,
  MoonIcon,
  PlusIcon,
  SparklesIcon,
  SunIcon,
  UserCircleIcon
} from '@heroicons/react/24/outline'
import { ReactComponent as PersonasOutlineIcon } from '../../../assets/personas-outline-icon.svg'
import {
  pushToDialogStack,
  setShowConnectionsModal,
  setShowFeedbackModal,
  setTheme,
  useAppDispatch,
  useAppSelector
} from '../../../redux'
import {
  ROUTES,
  conditionalStage,
  isMobile,
  useSegment,
  useWorkspace,
  userStore
} from '../../../utils'
import { Avatar, OptionDrawer, Popover } from '../..'
import { StyledUserMenuPopover } from '.'

type Props = {
  position: 'top-left' | 'bottom-right'
  classToAvoid: string
  buttonRef: RefObject<HTMLDivElement | HTMLButtonElement>
  isOpen: boolean
  onClose: () => void
}

const UserMenuPopover: FC<Props> = ({ isOpen, onClose, ...rest }) => {
  const { track } = useSegment()
  const { user, logout } = useContext(userStore)
  const { splits } = useAppSelector((state) => state.splits)
  const { showConnectionsModal, workspace, theme } = useAppSelector((state) => state.state)
  const { changeWorkspace } = useWorkspace()
  const location = useLocation()
  const history = useHistory()
  const dispatch = useAppDispatch()

  useEffect(() => {
    onClose()
  }, [location.pathname, showConnectionsModal])

  if (isMobile()) {
    return (
      <OptionDrawer
        options={[
          {
            icon: <UserCircleIcon />,
            label: 'Account',
            action: () => history.push(ROUTES.settings.account)
          },
          {
            icon: <AtSymbolIcon />,
            label: 'Connections',
            action: () => dispatch(setShowConnectionsModal(true))
          },
          {
            icon: <CreditCardIcon />,
            label: 'Billing',
            action: () => history.push(ROUTES.settings.billing)
          },
          ...conditionalStage(!!user?.trial && !Capacitor.isNativePlatform(), [
            {
              icon: <BoltIcon />,
              label: 'Go Pro',
              action: () => dispatch(pushToDialogStack('go-pro'))
            }
          ]),
          {
            icon: theme === 'dark' ? <SunIcon /> : <MoonIcon />,
            label: `Switch to ${theme === 'dark' ? 'Light' : 'Dark'} Mode`,
            action: () => {
              dispatch(setTheme(theme === 'dark' ? 'light' : 'dark'))
              track('Changed App Theme', { theme: theme === 'dark' ? 'light' : 'dark' })
            }
          },
          {
            icon: <MegaphoneIcon />,
            label: 'Submit Feedback',
            action: () => {
              dispatch(setShowFeedbackModal(true))
            }
          },
          {
            icon: <ArrowRightCircleIcon />,
            label: 'Log out',
            action: () => logout()
          }
        ]}
        isOpen={isOpen}
        onClose={onClose}
      />
    )
  }

  return (
    <Popover isOpen={isOpen} onClose={() => onClose()} {...rest}>
      <StyledUserMenuPopover>
        <div className="user-menu-group">
          <NavLink to={ROUTES.settings.account}>
            <UserCircleIcon />
            Account
          </NavLink>
          {splits.workspaces === 'on' && (
            <NavLink to={ROUTES.workspaces.workspace} exact>
              <BriefcaseIcon />
              Workspace
              <div className="new-flag">NEW</div>
            </NavLink>
          )}
          <a onClick={() => dispatch(setShowConnectionsModal(true))}>
            <AtSymbolIcon />
            Connections
            {/* <div className="new-flag">NEW</div> */}
          </a>
          {splits.pillars === 'on' && (
            <NavLink to={ROUTES.settings.pillars}>
              <BookOpenIcon />
              Pillars
              <div className="new-flag">NEW</div>
            </NavLink>
          )}
          {splits.personas === 'on' && (
            <NavLink to={ROUTES.settings.personas}>
              <PersonasOutlineIcon />
              Personas
              <div className="new-flag">NEW</div>
            </NavLink>
          )}
          <NavLink to={ROUTES.settings.billing}>
            <CreditCardIcon />
            Billing
          </NavLink>
          <NavLink to={ROUTES.referrals}>
            <CurrencyDollarIcon />
            Referrals
          </NavLink>
          {/* <NavLink to={ROUTES.affiliates}>
              <BanknotesIcon />
              Become an affiliate
            </NavLink> */}
          {user?.role === 'admin' && (
            <a href={ROUTES.external.adminPanel} target="_blank" rel="noopener noreferrer">
              <Cog6ToothIcon />
              Admin Panel
            </a>
          )}
          {!!user?.trial && !Capacitor.isNativePlatform() && (
            <a
              onClick={() => {
                dispatch(pushToDialogStack('go-pro'))
                onClose()
              }}
            >
              <BoltIcon />
              Go Pro
            </a>
          )}
        </div>
        {splits.workspaces === 'on' && (
          <div className="user-menu-group">
            <a
              className="workspace-item"
              aria-selected={!workspace}
              onClick={() => changeWorkspace()}
            >
              <Avatar name="Personal Workspace" size={26} isSquare />
              <span>Personal Workspace</span>
            </a>
            {user?.workspaces?.map((w) => {
              return (
                <a
                  className="workspace-item"
                  aria-selected={workspace === w._id}
                  onClick={() => changeWorkspace(w._id)}
                >
                  <Avatar name={w.name} image={w.avatar} size={26} isSquare />
                  <span>{w.name}</span>
                </a>
              )
            })}
            <Link to={ROUTES.workspaces.new}>
              <PlusIcon />
              New Workspace
            </Link>
          </div>
        )}
        <div className="user-menu-group">
          <a
            onClick={() => {
              dispatch(setShowFeedbackModal(true))
              onClose()
            }}
          >
            <ChatBubbleOvalLeftEllipsisIcon />
            Submit Feedback
          </a>
          <a
            onClick={() => {
              dispatch(setTheme(theme === 'dark' ? 'light' : 'dark'))
              track('Changed App Theme', { theme: theme === 'dark' ? 'light' : 'dark' })
            }}
          >
            {theme === 'dark' ? <SunIcon /> : <MoonIcon />}
            Switch to {theme === 'dark' ? 'Light' : 'Dark'} Mode
          </a>
          <a onClick={() => logout()}>
            <ArrowRightCircleIcon />
            Log out
          </a>
        </div>
      </StyledUserMenuPopover>
    </Popover>
  )
}

export default UserMenuPopover
