import styled from 'styled-components'

const StyledCreateMediaItem = styled.div`
  height: 100%;
  width: 100%;

  img {
    position: relative !important;
  }
`

export default StyledCreateMediaItem
