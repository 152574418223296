import styled from 'styled-components'

const StyledOnboardingStep = styled.div`
  z-index: 1;
  position: relative;
  padding: 20px;
  display: grid;
  gap: 20px;

  .input {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.color.brand}20,
      0 3px 10px ${({ theme }) => theme.color.brand}20;
    background-color: ${({ theme }) => theme.color.background} !important;

    input {
      color: ${({ theme }) => theme.color.gray[800]} !important;

      &::placeholder {
        color: ${({ theme }) => theme.color.gray[400]} !important;
      }
    }
  }

  .suggestions {
    button {
      color: ${({ theme }) => theme.color.foreground}c2 !important;
      background-color: ${({ theme }) => theme.color.foreground}10 !important;
    }
  }
`

export default StyledOnboardingStep
