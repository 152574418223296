import styled from 'styled-components'

const StyledComingSoonSheet = styled.div`
  padding: 10px 40px calc(var(--bottom-spacing) + 20px) 40px;
  text-align: center;
  position: relative;

  .icon {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 20px auto;
    border-radius: 50%;
    color: ${({ theme }) => theme.color.brand};
    background-color: ${({ theme }) => theme.color.brandLight};

    svg {
      height: 48px;
      position: relative;
      top: -3px;
    }
  }

  h1 {
    font-size: 30px;
    font-family: ${({ theme }) => theme.typeface.garnett};
    font-weight: 600;
    color: ${({ theme }) => theme.color.gray[800]};
    line-height: 30px;
    position: relative;
    z-index: 1;
  }

  h2 {
    font-size: 16px;
    font-weight: 500;
    max-width: 300px;
    margin: 10px auto 0 auto;
    color: ${({ theme }) => theme.color.gray[600]};
    position: relative;
    z-index: 1;
  }

  button {
    margin-top: 30px;
    width: 100%;
    height: 52px;
    border-radius: 8px;
    font-weight: 500;
    color: white;
    background: linear-gradient(
      ${({ theme }) => theme.color.brand},
      ${({ theme }) => theme.color.brandDark}
    );
    font-family: ${({ theme }) => theme.typeface.garnett};
    position: relative;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 50px;
    background: linear-gradient(
      ${({ theme }) => theme.color.background},
      ${({ theme }) => theme.color.brandLight}
    );
  }
`

export default StyledComingSoonSheet
