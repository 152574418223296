import { FC, useContext, useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { Square2StackIcon } from '@heroicons/react/20/solid'
import { Bars3BottomLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { PhotoIcon, VideoCameraIcon } from '@heroicons/react/24/solid'
import { conditionalStage, getPlatformDescription, getPlatformLogo, getPlatformTitle } from '../../../utils'
import { Tabs } from '../..'
import { connectionStore } from '..'
import { StyledConnectionOverview, overviews } from '.'

const ConnectionOverview: FC = () => {
  const { platformToOverview, setPlatformToOverview, setPlatformToConnect } = useContext(connectionStore)
  const [tab, setTab] = useState<'how-to' | 'description'>('description')
  const platform = platformToOverview || 'instagram'

  const getValueIcon = (value: 'video' | 'text' | 'image' | 'carousel') => {
    switch (value) {
      case 'carousel':
        return <Square2StackIcon />
      case 'image':
        return <PhotoIcon />
      case 'video':
        return <VideoCameraIcon />
      case 'text':
        return <Bars3BottomLeftIcon />
    }
  }

  useEffect(() => {
    setTab('description')
  }, [platform])

  return (
    <StyledConnectionOverview>
      <div className="overview-top">
        <div className="heading-container">
          <div className="heading">
            <div className="platform-details">
              <img
                src={`/images/logos/${getPlatformLogo(platform)}`}
                alt={`${getPlatformTitle(platform)} Logo`}
              />
              <span>{getPlatformTitle(platform)}</span>
            </div>
            <button className="back-btn" onClick={() => setPlatformToOverview(undefined)}>
              All Platforms <ChevronRightIcon />
            </button>
          </div>
          <button className="connect-btn" onClick={() => setPlatformToConnect(platform)}>
            Connect {getPlatformTitle(platform)} {getPlatformDescription(platform)}
          </button>
        </div>
        <Tabs
          tabs={[
            {
              label: 'Description',
              isActive: tab === 'description',
              onClick: () => setTab('description')
            },
            ...conditionalStage(!!overviews[platform].howTo, [
              {
                label: 'How to connect',
                isActive: tab === 'how-to',
                onClick: () => setTab('how-to')
              }
            ])
          ]}
        />
      </div>
      <div className="tab-content">
        {tab === 'how-to' && (
          <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            components={{
              a({ href, children }) {
                return (
                  <a href={href} target="_blank">
                    {children}
                  </a>
                )
              }
            }}
          >
            {(overviews[platform].howTo || '').replace(/^ {4}/gm, '')}
          </ReactMarkdown>
        )}
        {tab === 'description' && (
          <>
            <p>
              Connecting your {getPlatformTitle(platform)} {getPlatformDescription(platform)} to Social
              Curator lets you schedule & publish your posts posts to {getPlatformTitle(platform)}.
            </p>
            <p>
              <b>Supported post types</b>
            </p>
            <ul className="values">
              {overviews[platform].supports.map((type) => {
                return (
                  <li key={type}>
                    {getValueIcon(type)}
                    {type}
                  </li>
                )
              })}
            </ul>
          </>
        )}
      </div>
    </StyledConnectionOverview>
  )
}

export default ConnectionOverview
