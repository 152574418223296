import { gql } from '@apollo/client'

export const generateDrafts = gql`
  query GenerateDrafts(
    $posts: [generateDraftsPostTypeInput]!
    $timezone: String!
    $today: String!
  ) {
    generateDrafts(posts: $posts, timezone: $timezone, today: $today) {
      _id
      title
      captionText
      postDate
      pillar
      focus
      media {
        _id
        url
        preview
        type
        uploaded
        design
        ref
        dimensions {
          x
          y
        }
      }
    }
  }
`
