import { FC, useContext } from 'react'
import Selectable, { StylesConfig } from 'react-select'
import {
  setShowConnectionsModal,
  updateCreatePost,
  useAppDispatch,
  useAppSelector
} from '../../../redux'
import { SocialProfileType } from '../../../types'
import {
  theme as defaultTheme,
  formatUsername,
  hydrateSocialProfiles,
  socialProfileByStatus,
  themeDark,
  userStore
} from '../../../utils'
import { ConnectionAvatar } from '../..'
import { StyledCreateEditorProfilePicker } from '.'

const CreateEditorProfilePicker: FC = () => {
  const dispatch = useAppDispatch()
  const { user } = useContext(userStore)
  const { theme: currTheme } = useAppSelector((state) => state.state)
  const { post } = useAppSelector((state) => state.create)
  const theme = currTheme === 'dark' ? themeDark : defaultTheme

  const styles: StylesConfig<any, any, any> = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      border: `1px solid ${state.isFocused ? theme.color.gray[500] : theme.color.gray[300]}`,
      boxShadow: '0',
      height: 'unset',
      boxSizing: 'border-box',
      minHeight: 62,
      borderRadius: '8px',
      backgroundColor: theme.color.background,
      fontSize: '16px',
      color: theme.color.gray[800],
      padding: '0',
      ':hover': {
        border: `1px solid ${state.isFocused ? theme.color.gray[500] : theme.color.gray[400]}`
      }
    }),
    input: (baseStyles, state) => ({
      ...baseStyles,
      padding: '0 6px',
      color: theme.color.gray[800]
    }),
    singleValue: (baseStyles, state) => ({
      ...baseStyles,
      textAlign: 'left',
      color: theme.color.gray[800]
    }),
    dropdownIndicator: (baseStyles, state) => ({
      ...baseStyles,
      color: theme.color.gray[400],
      ':hover': {
        cursor: 'pointer',
        backgroundColor: 'transparent',
        color: theme.color.gray[400]
      }
    }),
    menu: (baseStyles, state) => ({
      ...baseStyles,
      zIndex: 99
    }),
    clearIndicator: (baseStyles, state) => ({
      ...baseStyles,
      color: theme.color.gray[400],
      ':hover': {
        cursor: 'pointer',
        backgroundColor: 'transparent',
        color: theme.color.gray[400]
      }
    }),
    placeholder: (baseStyles, state) => ({
      ...baseStyles,
      textAlign: 'left',
      padding: '0 6px',
      color: theme.color.gray[400]
    }),
    indicatorSeparator: (baseStyles, state) => ({
      ...baseStyles,
      display: 'none'
    }),
    multiValue: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: theme.color.gray[100],
      borderRadius: '50rem',
      padding: '2px 6px 2px 0',
      '.avatar .platform-indicator svg': {
        stroke: theme.color.gray[100]
      }
    }),
    multiValueLabel: (baseStyles, state) => ({
      ...baseStyles,
      color: theme.color.gray[700],
      fontSize: '16px',
      display: 'flex',
      userSelect: 'none',
      marginRight: '4px',
      alignItems: 'center',
      '.avatar': {
        marginRight: '6px'
      }
    }),
    multiValueRemove: (baseStyles, state) => ({
      ...baseStyles,
      color: 'rgb(0, 0, 0, 0.5)',
      borderRadius: '50rem',
      ':hover': {
        cursor: 'pointer',
        backgroundColor: 'transparent',
        color: 'rgb(0, 0, 0, 0.9)'
      }
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      display: 'flex',
      alignItems: 'center',
      color: theme.color.gray[700],
      '.avatar': {
        marginRight: '10px'
      },
      backgroundColor: state.isFocused ? theme.color.gray[100] : theme.color.background,
      ':hover': {
        backgroundColor: theme.color.gray[100],
        cursor: 'pointer',
        '.avatar .platform-indicator svg': {
          stroke: theme.color.gray[100]
        }
      }
    })
  }

  return (
    <StyledCreateEditorProfilePicker>
      <div className="label">
        <span>Publish this post to:</span>
        <button onClick={() => dispatch(setShowConnectionsModal(true))}>Connect new profile</button>
      </div>
      <Selectable
        options={socialProfileByStatus(['active', 'expired'], user?.socialProfiles).map(
          (profile) => ({
            ...profile,
            label: profile.username,
            value: profile._id
          })
        )}
        value={hydrateSocialProfiles(post?.socialProfiles, user).map((profile) => ({
          ...profile,
          label: profile.username,
          value: profile._id
        }))}
        placeholder="Select social profile(s)..."
        styles={styles}
        isSearchable
        isMulti
        noOptionsMessage={({ inputValue }) =>
          `Couldn't find a connected social profile matching "${inputValue}".`
        }
        blurInputOnSelect={false}
        formatOptionLabel={(profile: SocialProfileType) => (
          <>
            <ConnectionAvatar profile={profile} size={34} />
            <span>{formatUsername(profile.username, profile.type)}</span>
          </>
        )}
        onChange={(value: any) =>
          dispatch(
            updateCreatePost({
              socialProfiles: (value || []).map((profile: SocialProfileType) => profile._id)
            })
          )
        }
      />
    </StyledCreateEditorProfilePicker>
  )
}

export default CreateEditorProfilePicker
