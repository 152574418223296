import styled from 'styled-components'

const StyledCreateEditorCaption = styled.div`
  border: 1px solid ${({ theme }) => theme.color.gray[300]};
  position: relative;
  border-radius: 10px;

  .placeholder {
    position: absolute;
    top: 13px;
    left: 14px;
    font-size: 16px;
    pointer-events: none;
    user-select: none;
    z-index: 5;
    color: ${({ theme }) => theme.color.gray[400]};

    a {
      pointer-events: all;
      line-height: 24px;
      text-decoration: underline;
      color: ${({ theme }) => theme.color.gray[400]};
      transition: color 0.2s;

      &:hover {
        color: ${({ theme }) => theme.color.gray[500]};
      }
    }
  }

  .editor-caption-toolbar {
    height: 50px;
    border-top: 1px solid ${({ theme }) => theme.color.gray[300]};
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .editor-caption-toolbar-group {
      display: flex;
      align-items: center;
    }

    .editor-caption-toolbar-btn {
      height: 32px;
      padding: 0 10px;
      border-radius: 6px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.2s;
      color: ${({ theme }) => theme.color.gray[600]};
      background-color: ${({ theme }) => theme.color.gray[100]};

      svg {
        height: 18px;
        stroke-width: 2px;
        margin-right: 4px;
        margin-left: -2px;
      }

      &:hover {
        background-color: ${({ theme }) => theme.color.gray[200]};
      }

      &.icon-btn {
        width: 32px;
        padding: 0;
        background-color: transparent;
        color: ${({ theme }) => theme.color.gray[500]};
        margin-left: 2px;

        svg {
          height: 24px;
          stroke-width: 1.5px;
          margin: 0;
        }

        &:hover {
          background-color: ${({ theme }) => theme.color.gray[100]};
          color: ${({ theme }) => theme.color.gray[600]};
        }
      }

      &:not(:last-child) {
        margin-right: 8px;
      }
    }

    .editor-caption-character-count-btn {
      height: 32px;
      padding: 0 6px;
      border-radius: 6px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: -8px;
      transition: 0.2s;
      font-size: 14px;
      background-color: transparent;

      .editor-caption-character-count {
        display: flex;
        align-items: center;
        margin-right: 4px;
        color: ${({ theme }) => theme.color.gray[500]};
      }

      .editor-caption-character-count-limit {
        color: ${({ theme }) => theme.color.gray[400]};
      }

      &:hover,
      &[data-active='true'] {
        background-color: ${({ theme }) => theme.color.gray[100]};
      }
    }
  }
`

export default StyledCreateEditorCaption
