import styled from 'styled-components'

const StyledHomeGreeting = styled.div`
  margin: 0 auto;
  width: fit-content;

  h1 {
    font-size: 25px;
    font-weight: 400;
    font-family: ${({ theme }) => theme.typeface.garnett};
    color: white;
  }
`

export default StyledHomeGreeting
