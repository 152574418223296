import { FC } from 'react'
import { StyledBanner } from '.'

type Props = {
  message: string
}

const Banner: FC<Props> = ({ message }) => {
  return (
    <StyledBanner className="banner">
      <div className="banner-message">
        <span>{message}</span>
      </div>
    </StyledBanner>
  )
}

export default Banner
