import styled from 'styled-components'

const StyledStartUpView = styled.div`
  box-sizing: border-box;
  position: relative;
  background-color: ${({ theme }) => theme.color.gray[100]};

  .page-content {
    z-index: 1;
    position: relative;

    .page-header {
      padding: 40px;

      h1 {
        text-align: center;
        font-size: 28px;
        font-weight: 800;
        color: ${({ theme }) => theme.color.gray[800]};
      }

      h2 {
        text-align: center;
        font-size: 18px;
        font-weight: 400;
        max-width: 400px;
        line-height: 26px;
        margin: 10px auto 0 auto;
        color: ${({ theme }) => theme.color.gray[600]};
      }
    }

    .decision-tree {
      padding: 40px;
      border-radius: 30px 30px 0 0;
      text-align: center;
      display: grid;
      column-gap: 40px;
      background-color: ${({ theme }) => theme.color.background};
      box-shadow: inset 0 1px rgb(255, 255, 255, 0.2), 0 -1px rgba(0, 0, 0, 0.075),
        0px -3px 3px rgba(0, 0, 0, 0.05);
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

      .decision-branch {
        .icon {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          margin: 0 auto 20px auto;
          background-color: ${({ theme }) => theme.color.gray[100]};
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 40px;
          text-align: center;
        }

        h3 {
          font-weight: 600;
          font-size: 22px;
          color: ${({ theme }) => theme.color.gray[800]};

          mark {
            padding: 5px 8px;
            color: white;
            border-radius: 6px;
            background-color: ${({ theme }) => theme.color.brand};
          }
        }

        ul {
          padding: 0;
          margin: 20px 0 0 0;
          list-style-type: none;

          li {
            a {
              text-decoration: none;
              padding: 12px;
              border-radius: 6px;
              background-color: ${({ theme }) => theme.color.gray[100]};
              text-align: left;
              align-items: center;
              display: flex;
              justify-content: space-between;
              font-size: 16px;
              font-weight: 400;
              color: ${({ theme }) => theme.color.gray[600]};
              transition: 0.2s;

              svg {
                height: 24px;
                flex-shrink: 0;
                margin-left: 16px;
              }

              &:hover {
                box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.gray[300]} !important;
                background-color: ${({ theme }) => theme.color.background} !important;
                color: ${({ theme }) => theme.color.gray[800]} !important;
              }
            }

            &:not(:last-child) {
              margin-bottom: 10px;
            }
          }
        }

        .fake-new-post-btn {
          margin: 20px auto 0 auto;
          width: 200px;
          box-sizing: border-box;
          height: 42px;
          padding: 0 14px;
          color: #fff;
          background-color: ${({ theme }) => theme.color.brand};
          font-weight: 500;
          border-radius: 4px;
          font-size: 16px;
          display: flex;
          align-items: center;
          transition: 0.2s;
          justify-content: space-between;
          text-decoration: none;
          box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);

          svg {
            height: 22px;
            stroke-width: 2px;
            margin-right: -4px;
            margin-left: 6px;
          }

          &:hover {
            background-color: ${({ theme }) => theme.color.brandDark};
            box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
          }
        }

        &:first-child {
          .list {
            margin: 20px 0 0 0;
            background-color: ${({ theme }) => theme.color.brandLight}60;
            padding: 30px;
            border-radius: 6px;
            border: 1px dashed ${({ theme }) => theme.color.brand}30;

            span {
              text-align: center;
              font-size: 16px;
              font-weight: 400;
              color: ${({ theme }) => theme.color.gray[600]};
            }
          }
        }

        &:nth-of-type(2) {
          h3 {
            mark {
              color: white;
              background-color: ${({ theme }) => theme.color.purple[700]};
            }
          }

          ul {
            li {
              a {
                background-color: ${({ theme }) => theme.color.purple[700]}20;
                color: ${({ theme }) => theme.color.purple[700]};
              }
            }
          }
        }

        &:nth-of-type(3) {
          h3 {
            mark {
              color: white;
              background-color: ${({ theme }) => theme.color.green[700]};
            }
          }

          ul {
            li {
              a {
                background-color: ${({ theme }) => theme.color.green[700]}20;
                color: ${({ theme }) => theme.color.green[700]};
              }
            }
          }
        }
      }
    }
  }
`

export default StyledStartUpView
