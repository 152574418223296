import styled from 'styled-components'

const StyledAcademyCourseLessonMap = styled.div`
  height: 100%;
  position: relative;

  .scroll-btn {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 18px;
    height: 26px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.color.background};
    color: ${({ theme }) => theme.color.gray[600]};
    box-shadow: 0 0 0 1px ${({ theme }) => theme.color.foreground}20;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;

    svg {
      height: 24px;
      stroke-width: 1.75px;
    }

    &.top {
      top: 0;
    }

    &.bottom {
      bottom: 0;
    }

    &:hover {
      color: ${({ theme }) => theme.color.gray[700]};
      box-shadow: 0 0 0 1px ${({ theme }) => theme.color.foreground}30,
        0 2px 3px ${({ theme }) => theme.color.foreground}20;
    }
  }

  .scroll-indicator {
    position: absolute;
    width: calc(100% + 20px);
    left: -10px;
    z-index: 10;
    pointer-events: none;
    transition: 0.2s;
    height: 100px;

    &.top {
      top: 0;
      background: linear-gradient(${({ theme }) => theme.color.gray[100]}, transparent);
    }

    &.bottom {
      bottom: 0;
      background: linear-gradient(transparent, ${({ theme }) => theme.color.gray[100]});
    }

    &[aria-hidden='true'] {
      opacity: 0;
    }
  }

  .lesson-map-scroll {
    overflow: hidden;
    height: 100%;
    padding: 0 10px;
    margin: 0 -10px;

    .lesson-map {
      padding: 0 6px;
      margin: 0 -6px;
      overflow-y: auto;
      display: grid;
      grid-gap: 16px;
      align-content: start;
      height: 100%;
      box-sizing: border-box;

      .module {
        .module-header {
          font-size: 14px;
          padding-bottom: 8px;
          margin-bottom: 4px;
          border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};

          .module-title {
            font-weight: 500;
            color: ${({ theme }) => theme.color.gray[800]};
          }

          .module-length {
            display: flex;
            align-items: center;
            margin-top: 2px;
            color: ${({ theme }) => theme.color.gray[600]};
          }
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
`

export default StyledAcademyCourseLessonMap
