import styled from 'styled-components'

const StyledNewPageView = styled.div`
  display: grid;
  height: 100vh;
  grid-template-columns: 650px minmax(0, 1fr);
  background-color: ${({ theme }) => theme.color.gray[100]};

  .details-column {
    padding: 50px;
    overflow-y: auto;
    border-right: 1px solid ${({ theme }) => theme.color.gray[300]};
    background-color: ${({ theme }) => theme.color.background};

    h1 {
      font-weight: 600;
      font-size: 30px;
      display: flex;
      align-items: center;
      margin-top: -9px;
      color: ${({ theme }) => theme.color.gray[800]};
      font-family: ${({ theme }) => theme.typeface.faktumXCon};

      .new-flag {
        padding: 6px 10px 6px 12px;
        margin-right: 10px;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.1rem;
        border-radius: 50rem;
        color: ${({ theme }) => theme.color.background};
        background: linear-gradient(
          45deg,
          ${({ theme }) => theme.color.brand},
          ${({ theme }) => theme.color.purple[400]}
        );
      }
    }

    h2 {
      font-size: 18px;
      font-weight: 400;
      max-width: 500px;
      line-height: 24px;
      margin-top: 8px;
      font-family: ${({ theme }) => theme.typeface.faktum};
      color: ${({ theme }) => theme.color.gray[600]};
      padding-bottom: 30px;
      border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
    }

    form {
      padding-top: 50px;
      display: grid;
      grid-gap: 24px;
      align-content: start;

      hr {
        border: 0;
        height: 1px;
        margin: 0;
        background-color: ${({ theme }) => theme.color.gray[300]};
        width: 100%;
      }

      label,
      .form-input-container {
        display: grid;
        grid-gap: 8px;
        cursor: default;

        .input-label {
          font-weight: 500;
          color: ${({ theme }) => theme.color.gray[600]};
        }
      }

      .create-btn {
        width: fit-content;
        margin-top: calc(50px - 24px);
      }
    }
  }
`

export default StyledNewPageView
