import { createRef, FC, useCallback, useEffect, useState } from 'react'
import { ArrowUturnRightIcon, ArrowUturnUpIcon } from '@heroicons/react/24/outline'
import { useActiveObject, useEditor } from '@layerhub-io/react'
import { OptionsPopover } from '../..'
import { DesignerToolButton } from '.'

const DesignerFlipTool: FC = () => {
  const btnRef = createRef<HTMLButtonElement>()
  const editor = useEditor()
  const activeObject = useActiveObject() as any
  const [showOptions, setShowOptions] = useState(false)
  const [flip, setFlip] = useState({ flipX: false, flipY: false })

  const flipHorizontally = useCallback(() => {
    editor.objects.update({ flipX: !flip.flipX })
    setFlip({ ...flip, flipX: !flip.flipX })
  }, [editor, flip])

  const flipVertically = useCallback(() => {
    editor.objects.update({ flipY: !flip.flipY })
    setFlip({ ...flip, flipY: !flip.flipY })
  }, [editor, flip])

  useEffect(() => {
    if (activeObject) {
      setFlip({
        flipX: activeObject.flipX,
        flipY: activeObject.flipY
      })
    }
  }, [activeObject])

  return (
    <>
      <DesignerToolButton
        className="flip-btn"
        isActive={showOptions}
        onClick={() => setShowOptions(!showOptions)}
        ref={btnRef}
      >
        <span>Flip</span>
      </DesignerToolButton>
      <OptionsPopover
        options={[
          {
            icon: <ArrowUturnRightIcon />,
            label: 'Flip horizontally',
            stayOpen: true,
            action: () => flipHorizontally()
          },
          {
            icon: <ArrowUturnUpIcon />,
            label: 'Flip vertically',
            stayOpen: true,
            action: () => flipVertically()
          }
        ]}
        position="bottom-left"
        buttonRef={btnRef}
        classToAvoid="flip-btn"
        isOpen={showOptions}
        onClose={() => setShowOptions(false)}
      />
    </>
  )
}

export default DesignerFlipTool
