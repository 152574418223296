import { ForwardedRef, InputHTMLAttributes, ReactNode, forwardRef } from 'react'
import classNames from 'classnames'
import { StyledTextarea } from '.'

type Props = {
  variant?: 'outline' | 'fill' | 'shadow'
} & Omit<InputHTMLAttributes<HTMLTextAreaElement>, 'size'>

const Textarea = forwardRef(
  (
    { variant = 'outline', value, maxLength, className, ...rest }: Props,
    ref: ForwardedRef<HTMLTextAreaElement>
  ) => {
    return (
      <StyledTextarea variant={variant} className={classNames('input', className)}>
        <textarea value={value} maxLength={maxLength} {...rest} ref={ref}></textarea>
        {maxLength && (
          <span className="max-length-indicator">
            {(value as any).length}/{maxLength}
          </span>
        )}
      </StyledTextarea>
    )
  }
)

export default Textarea
