import styled from 'styled-components'

type StyledProps = {
  isDragActive?: boolean
}

const StyledUploadDialogDropzonePage = styled.div<StyledProps>`
  .dropzone {
    height: 150px;
    width: 100%;
    overflow: hidden;
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.color.gray[100]};
    border: 1px dashed ${({ theme }) => theme.color.gray[200]};
    transition: 0.2s;
    display: grid;
    align-items: center;
    align-content: center;
    user-select: none;

    .dropzone-icon {
      display: flex;
      height: fit-content;
      margin: 0 auto 16px auto;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: 0.2s;

      svg {
        height: 75px;
      }
    }

    .dropzone-label {
      text-align: center;
      transition: 0.2s;
      color: ${({ theme }) => theme.color.gray[500]};

      span {
        display: block;
        font-size: 14px;

        &:not(:first-child) {
          margin-top: 4px;
        }
      }

      a {
        text-decoration: underline;

        svg {
          height: 14px;
          margin-right: 4px;
        }
      }
    }

    &:hover {
      border: 1px dashed ${({ theme }) => theme.color.gray[400]};
      cursor: pointer;

      .dropzone-icon {
        color: ${({ theme }) => theme.color.gray[600]};
      }

      .dropzone-label {
        color: ${({ theme }) => theme.color.gray[600]};
      }
    }

    ${({ isDragActive, theme }) =>
      isDragActive &&
      `
  background-color: ${theme.color.brandLight};
  border: 1px dashed ${theme.color.brand};

  .dropzone-icon {
    color: ${theme.color.brand};
  }

  .dropzone-label {
    color: ${theme.color.brand};
  }
`}
  }

  .upload-option-list {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.color.gray[100]};
    display: grid;
    overflow: hidden;

    button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 46px;
      padding: 0 16px;
      transition: 0.2s;
      background-color: transparent;
      font-weight: 500;
      color: ${({ theme }) => theme.color.gray[600]};

      svg {
        height: 24px;
      }

      &:not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
      }

      &:hover {
        background-color: ${({ theme }) => theme.color.gray[200]};
      }
    }
  }
`

export default StyledUploadDialogDropzonePage
