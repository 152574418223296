import { linkToSizedImage } from '.'

export const renderEditorImage = (url: string, width: number) => {
  if (url.includes('user-images')) {
    return linkToSizedImage(url, { width }, 'user-images')
  }

  if (url.includes('images')) {
    return linkToSizedImage(url, { width })
  }

  return url
}
