import styled from 'styled-components'

const StyledEditorCaption = styled.div`
  .textarea {
    resize: none;
    width: 100%;
    border-radius: 10px;
    box-sizing: border-box;
    font-size: 16px;
    padding: 20px;
    line-height: 28px;
    color: ${({ theme }) => theme.color.gray[800]};
    background-color: ${({ theme }) => theme.color.background};
    border: 1px solid ${({ theme }) => theme.color.gray[200]};
    transition: border-color 0.2s;

    &:hover,
    &:focus {
      border-color: ${({ theme }) => theme.color.gray[300]};
    }
  }
`

export default StyledEditorCaption
