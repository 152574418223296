import { FC, useEffect, useState } from 'react'
import { sampleSize } from 'lodash'
import { useAppSelector } from '../../../redux'
import { BitType } from '../../../types'
import { countPunctuation } from '../../../utils'
import { BitListItem, bits as bitData } from '../..'
import { StyledCreateBarContentBits } from '.'

const CreateBarContentBits: FC = () => {
  const [bits, setBits] = useState<BitType[]>([])
  const { post } = useAppSelector((state) => state.create)

  const getBits = () => {
    const captionLength = post?.captionText?.trim().length
    const captionSentenceCount = countPunctuation(post?.captionText || '')

    if (!captionLength) {
      const hooks = bitData.filter((bit) => bit.type === 'hook')
      return setBits(sampleSize(hooks, 15))
    }

    if (captionSentenceCount < 4) {
      const notHooks = bitData.filter((bit) => bit.type !== 'hook')
      return setBits(sampleSize(notHooks, 15))
    }

    const callToActions = bitData.filter((bit) => bit.type === 'call-to-action')
    setBits(sampleSize(callToActions, 15))
  }

  useEffect(() => {
    getBits()
  }, [countPunctuation(post?.captionText || '')])

  return (
    <StyledCreateBarContentBits className="scrollable">
      {bits.map((bit) => {
        return <BitListItem bit={bit} key={bit._id} />
      })}
    </StyledCreateBarContentBits>
  )
}

export default CreateBarContentBits
