import { ButtonHTMLAttributes, ForwardedRef, forwardRef } from 'react'
import classNames from 'classnames'
import { StyledButton } from '.'

type Props = {
  size?: 'lg' | 'md' | 'sm'
  variant?:
    | 'neutral'
    | 'outline'
    | 'destructive'
    | 'destructive-solid'
    | 'emphasis'
    | 'emphasis-outline'
    | 'emphasis-tone'
  iconPos?: 'left' | 'right'
  isIcon?: boolean
  isFullWidth?: boolean
} & ButtonHTMLAttributes<HTMLButtonElement>

const Button = forwardRef(
  (
    {
      size = 'md',
      variant = 'neutral',
      iconPos,
      isIcon,
      isFullWidth,
      children,
      className,
      ...rest
    }: Props,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    return (
      <StyledButton
        className={classNames('btn', className)}
        size={size}
        variant={variant}
        iconPos={iconPos}
        isIcon={isIcon}
        isFullWidth={isFullWidth}
        ref={ref}
        {...rest}
      >
        {children}
      </StyledButton>
    )
  }
)

export default Button
