import { SocialProfileType } from '../../types'
import { socialProfileByStatus } from '.'

export const socialProfileByType = (
  type: ('instagram' | 'facebook' | 'linkedin' | 'twitter')[],
  profiles?: SocialProfileType[]
) => {
  if (!profiles?.length) {
    return []
  }

  return socialProfileByStatus(['active', 'expired'], profiles).filter((profile) =>
    type.includes(profile.type as any)
  )
}
