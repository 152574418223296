import { FC, useContext } from 'react'
import { userStore } from '../../../utils'
import { StyledHomeGreeting } from '.'

type Props = {
  time: 'morning' | 'afternoon' | 'evening'
}

const HomeGreeting: FC<Props> = ({ time }) => {
  const { user } = useContext(userStore)

  return (
    <StyledHomeGreeting>
      <h1>
        Good {time}, {(user?.displayName || 'friend').split(' ')[0]}
      </h1>
    </StyledHomeGreeting>
  )
}

export default HomeGreeting
