import { v4 as uuidv4 } from 'uuid'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ChatMessageType } from '../../types'

type InitialState = {
  conversations?: string[]
  input: string
  template?:
    | 'caption'
    | 'photo'
    | 'hashtags'
    | 'blanks'
    | 'video'
    | 'video-script'
    | 'visual-ideas'
    | 'post-title'
    | 'live-video-outline'
  tempTemplate?: string
  ref?: string
  messages: ChatMessageType[]
  suggestions: string[]
  loading: boolean
  showPrompts?: boolean
  showHistory?: boolean
  showTemplates: boolean
  conversation: string
  persona?: string
}

const initialState: InitialState = {
  input: '',
  messages: [],
  suggestions: [],
  loading: false,
  showHistory: false,
  showPrompts: false,
  showTemplates: false,
  conversation: uuidv4()
}

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setChatMessages: (state, action: PayloadAction<InitialState['messages']>) => {
      state.messages = action.payload
    },
    addChatMessage: (state, action: PayloadAction<InitialState['messages'][number]>) => {
      state.messages = [...state.messages, action.payload]
    },
    addChatMessages: (state, action: PayloadAction<InitialState['messages']>) => {
      state.messages = [...state.messages, ...action.payload]
    },
    removeChatMessage: (state, action: PayloadAction<string>) => {
      state.messages = state.messages.filter((message) => message._id !== action.payload)
    },
    upsertChatMessage: (
      state,
      action: PayloadAction<{ _id: string; message: Partial<InitialState['messages'][number]> }>
    ) => {
      const exists = state.messages.find((message) => message._id === action.payload._id)

      if (!exists) {
        state.messages = [...state.messages, action.payload.message as any]
        return
      }

      state.messages = state.messages.map((message) => {
        if (message._id === action.payload._id) {
          return {
            ...message,
            ...action.payload.message
          } as any
        }
        return message
      })
    },
    updateChatMessage: (
      state,
      action: PayloadAction<{ _id: string; message: Partial<InitialState['messages'][number]> }>
    ) => {
      state.messages = state.messages.map((message) => {
        if (message._id === action.payload._id) {
          return {
            ...message,
            ...action.payload.message
          } as any
        }
        return message
      })
    },
    setChatSuggestions: (state, action: PayloadAction<string[]>) => {
      state.suggestions = action.payload
    },
    setChatLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setChatInput: (state, action: PayloadAction<string>) => {
      state.input = action.payload
    },
    setChatTemplate: (state, action: PayloadAction<InitialState['template']>) => {
      state.template = action.payload
    },
    setChatRef: (state, action: PayloadAction<InitialState['ref']>) => {
      state.ref = action.payload
    },
    setChatTempTemplate: (state, action: PayloadAction<InitialState['tempTemplate']>) => {
      state.tempTemplate = action.payload
    },
    setShowChatHistory: (state, action: PayloadAction<boolean>) => {
      state.showHistory = action.payload
    },
    setShowChatTemplates: (state, action: PayloadAction<boolean>) => {
      state.showTemplates = action.payload
    },
    setChatConversation: (state, action: PayloadAction<string>) => {
      state.conversation = action.payload
    },
    setShowChatPrompts: (state, action: PayloadAction<boolean>) => {
      state.showPrompts = action.payload
    },
    setChatPersona: (state, action: PayloadAction<InitialState['persona']>) => {
      state.persona = action.payload
    }
  }
})

export const {
  setChatMessages,
  addChatMessage,
  addChatMessages,
  removeChatMessage,
  upsertChatMessage,
  updateChatMessage,
  setChatSuggestions,
  setChatLoading,
  setChatInput,
  setChatTemplate,
  setChatRef,
  setChatTempTemplate,
  setShowChatHistory,
  setShowChatTemplates,
  setChatConversation,
  setShowChatPrompts,
  setChatPersona
} = chatSlice.actions
export default chatSlice.reducer
