import { gql } from '@apollo/client'

export const searchIssuesQuery = gql`
  query SearchIssues(
    $search: String
    $categories: [String] = []
    $platform: String
    $page: Int!
    $items: Int = 20
  ) {
    searchIssue(
      search: $search
      categories: $categories
      platform: $platform
      page: $page
      items: $items
    ) {
      totalDocs
      totalPages
      nextPage
      hasNextPage
      docs {
        _id
        title
        number
        type
        date
        about
        listDescription
        slug
        status
        annualOnly
        headerImg {
          fileHttpLink
        }
      }
    }
  }
`
