import { FC } from 'react'
import { Helmet } from 'react-helmet'
import { Route } from 'react-router-dom'
import {
  ChatBubbleLeftEllipsisIcon,
  PhotoIcon,
  RectangleStackIcon
} from '@heroicons/react/24/outline'
import { Tabs } from '../../components'
import { ROUTES } from '../../utils'
import { CaptionsView, PhotosView, StoriesView } from '..'
import { StyledContentLibraryView } from '.'

const ContentLibraryView: FC = () => {
  return (
    <StyledContentLibraryView>
      <Helmet title="Content Library | Social Curator" />
      <Tabs
        tabs={[
          {
            label: 'Caption Templates',
            icon: <ChatBubbleLeftEllipsisIcon />,
            path: ROUTES.contentLibrary.main
          },
          {
            label: 'Photo Gallery',
            icon: <PhotoIcon />,
            path: ROUTES.contentLibrary.photos,
            isExact: false
          },
          {
            label: 'Story Templates',
            icon: <RectangleStackIcon style={{ transform: 'rotate(90deg)' }} />,
            path: ROUTES.contentLibrary.stories,
            isExact: false
          }
        ]}
      />
      <Route
        path={[ROUTES.contentLibrary.main, ROUTES.old.captions]}
        component={CaptionsView}
        exact
      />
      <Route
        path={[ROUTES.contentLibrary.photos, ROUTES.photo, ROUTES.old.gallery]}
        component={PhotosView}
        exact
      />
      <Route path={[ROUTES.contentLibrary.stories, ROUTES.old.stories]} component={StoriesView} />
    </StyledContentLibraryView>
  )
}

export default ContentLibraryView
