import styled from 'styled-components'

const StyledWorkspaceDetails = styled.div`
  display: grid;
  grid-gap: 20px;
  align-items: flex-start;

  .workspace-details-header {
    padding: 12px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.color.gray[100]};

    .workspace-icon {
      margin-right: 14px;
      height: 45px;
      width: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      color: ${({ theme }) => theme.color.gray[600]};
      background-color: ${({ theme }) => theme.color.background};

      svg {
        height: 26px;
      }
    }

    .workspace-details {
      .workspace-title {
        display: block;
        font-size: 16px;
        font-weight: 600;
        color: ${({ theme }) => theme.color.gray[800]};
      }

      .workspace-members {
        display: block;
        margin-top: 2px;
        font-size: 15px;
        color: ${({ theme }) => theme.color.gray[500]};
      }
    }
  }

  .workspace-form {
    display: grid;
    grid-gap: 24px;
    align-content: start;

    label,
    .form-label {
      display: grid;
      grid-gap: 8px;
      cursor: default;

      .input-label {
        font-weight: 500;
        user-select: none;
        color: ${({ theme }) => theme.color.gray[600]};
      }

      .input-subtext {
        font-size: 14px;
        user-select: none;
        color: ${({ theme }) => theme.color.gray[500]};
      }
    }
  }

  .workspace-members {
    padding: 18px;
    border-radius: 6px;
    margin-bottom: 20px;
    border: 1px solid ${({ theme }) => theme.color.gray[300]};

    .workspace-detail-label {
      font-weight: 500;
      user-select: none;
      color: ${({ theme }) => theme.color.gray[600]};
    }

    .workspace-members-list {
      margin: 20px 0;
      display: grid;
      grid-gap: 10px;

      .workspace-member {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .workspace-member-details {
          display: flex;
          align-items: center;
          color: ${({ theme }) => theme.color.gray[800]};
          font-weight: 500;

          .avatar {
            margin-right: 10px;
          }
        }
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.m}px) {
    grid-gap: 40px;
    justify-content: space-between;
    grid-template-columns: minmax(0, 1fr) 320px;
  }
`

export default StyledWorkspaceDetails
