import styled from 'styled-components'

const StyledNewEventDialog = styled.div`
  max-width: 375px;
  width: 100vw;
  padding: 28px;
  box-sizing: border-box;

  .dialog-header {
    display: flex;
    align-items: center;

    .dialog-title {
      font-weight: 500;
      font-size: 20px;
      color: ${({ theme }) => theme.color.gray[800]};
    }
  }

  form {
    margin-top: 20px;

    label,
    .form-label {
      display: grid;
      grid-gap: 8px;
      cursor: default;

      .input-label {
        font-weight: 500;
        user-select: none;
        color: ${({ theme }) => theme.color.gray[600]};
      }

      .input-subtext {
        font-size: 14px;
        user-select: none;
        color: ${({ theme }) => theme.color.gray[500]};
      }

      &:not(:last-child) {
        margin-top: 18px;
      }
    }
  }

  .dialog-actions {
    margin-top: 28px;
    display: flex;

    button {
      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
`

export default StyledNewEventDialog
