import styled from 'styled-components'

type StyledProps = {
  positioning?: { top: number; left: number }
}

const StyledTooltipContext = styled.div<StyledProps>`
  position: fixed;
  z-index: 99999999999999999999999;
  top: ${({ positioning }) => positioning?.top || 0}px;
  left: ${({ positioning }) => positioning?.left || 0}px;
  pointer-events: none;
  padding: 10px;
  user-select: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  max-width: 275px;
  width: max-content;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 4px;
  color: #fff;
  white-space: pre-wrap;
  transition: opacity 0.2s;
  box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
    0px 2px 3px rgba(0, 0, 0, 0.05);
  background-color: #171717;
  opacity: ${({ positioning }) => (!!positioning ? 1 : 0)};

  b {
    color: #fff;
    font-weight: 600;
  }

  span {
    color: #a3a3a3;
  }
`

export default StyledTooltipContext
