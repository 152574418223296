import { FC, useState } from 'react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { PencilIcon } from '@heroicons/react/24/solid'
import { ContentPillarType } from '../../../types'
import { ActionDialog, PillarDialog } from '../../dialogs'
import { StyledPillarListItem } from '.'

type Props = {
  pillar: ContentPillarType
  onChange: (pillar: Partial<ContentPillarType>) => void
  onRemove: () => void
}

const PillarListItem: FC<Props> = ({ pillar, onChange, onRemove }) => {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [showEditDialog, setShowEditDialog] = useState(false)

  return (
    <>
      <StyledPillarListItem>
        <div className="actions">
          <button className="change-btn" onClick={() => setShowEditDialog(true)}>
            <PencilIcon />
          </button>
          <button className="delete-btn" onClick={() => setShowConfirmationDialog(true)}>
            <XMarkIcon />
          </button>
        </div>
        <div className="title-container">
          <div className="icon">{pillar.icon}</div>
          <span className="title">{pillar.title}</span>
        </div>
        <span className="content">{pillar.content}</span>
      </StyledPillarListItem>
      <ActionDialog
        title="Delete this content pillar?"
        body={`Are you sure you want to delete "${pillar.title}"?`}
        type="error"
        confirmButtonText="Delete"
        isOpen={showConfirmationDialog}
        onClose={() => setShowConfirmationDialog(false)}
        onConfirm={() => onRemove()}
      />
      <PillarDialog
        pillar={pillar}
        isOpen={showEditDialog}
        onClose={() => setShowEditDialog(false)}
        onConfirm={(pillar) => onChange(pillar)}
      />
    </>
  )
}

export default PillarListItem
