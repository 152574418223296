import styled from 'styled-components'

const StyledCloudHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .page-path {
    display: flex;
    align-items: center;
    margin-right: 20px;
    position: relative;

    h1,
    h2 {
      font-size: 30px;
      font-family: ${({ theme }) => theme.typeface.faktumXCon};
    }

    h1 {
      font-weight: 600;
      color: ${({ theme }) => theme.color.gray[800]};
    }

    h2 {
      font-weight: 400;
      color: ${({ theme }) => theme.color.gray[500]};
      max-width: 400px;
      display: -webkit-box;
      word-break: break-word;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-align: left;
    }

    a {
      text-decoration: none;
      color: ${({ theme }) => theme.color.gray[800]};

      &:hover {
        text-decoration: underline;
      }
    }

    svg {
      height: 12px;
      stroke-width: 4px;
      margin: 0 5px;
      color: ${({ theme }) => theme.color.gray[500]};
    }

    .back-cta {
      position: absolute;
      pointer-events: none;
      padding: 10px;
      border-radius: 6px;
      font-size: 14px;
      max-width: 275px;
      width: max-content;
      box-sizing: border-box;
      padding: 10px;
      border-radius: 4px;
      color: #afafaf;
      font-weight: 400;
      transition: opacity 0.2s;
      box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
        0px 2px 3px rgba(0, 0, 0, 0.05);
      background-color: #171717;
      top: calc(100% + 4px);
      left: 0;

      b {
        font-weight: 600;
        color: #fff;
      }

      &::after {
        content: '';
        position: absolute;
        height: 10px;
        width: 10px;
        transform: rotate(45deg);
        background-color: #171717;
        left: 12px;
        top: -4px;
        border-top-left-radius: 2px;
      }
    }
  }

  .storage-indicator-container {
    display: grid;
    row-gap: 6px;

    .storage-label {
      text-align: right;
      display: block;
      font-size: 14px;
      color: ${({ theme }) => theme.color.gray[400]};

      b {
        font-weight: 500;
        color: ${({ theme }) => theme.color.gray[500]};
      }
    }

    progress {
      width: 200px;
      overflow: hidden;
      border-radius: 50rem;
      height: 4px;

      &::-webkit-progress-bar {
        background-color: ${({ theme }) => theme.color.gray[200]};
        border-radius: 50rem;
      }

      &::-webkit-progress-value {
        background-color: ${({ theme }) => theme.color.gray[500]};
        transition: 0.2s;
        border-radius: 50rem;
      }
    }
  }
`

export default StyledCloudHeader
