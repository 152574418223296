import { FC } from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
import { ArrowUpRightIcon } from '@heroicons/react/24/outline'
import { Button } from '../..'
import { StyledMobilePlanDisclaimer } from '.'

const MobilePlanDisclaimer: FC = () => {
  return (
    <StyledMobilePlanDisclaimer>
      <div className="disclaimer-header">
        <div className="disclaimer-icon">
          <ExclamationCircleIcon />
        </div>
        <span>How do I update my plan?</span>
      </div>
      <span>
        Due to restrictive billing policies, you are unable to modify your subscription from the
        Social Curator app. Please log into your account on the web to make any changes.
      </span>
      <a href="https://app.socialcurator.com/user/billing" target="_blank">
        <Button size="lg" isFullWidth>
          Take me there
          <ArrowUpRightIcon />
        </Button>
      </a>
    </StyledMobilePlanDisclaimer>
  )
}

export default MobilePlanDisclaimer
