import { uniqBy, xor } from 'lodash'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { PostType } from '../../types'
import { draftCategories } from '../../types/CategoriesType'

type InitialState = {
  posts: PostType[]
  query: string
  filters: (typeof draftCategories)[number][]
  status?: string
  tags: string[]
  profiles: string[]
  sort: 'custom' | 'created' | 'modified' | 'postDate'
  layout: 'grid' | 'list'
  onlyUsed: boolean
  onlyScheduled: boolean
  onlyLiked: boolean
  loading: boolean
  page: number
  totalDocs: number
}

const initialState: InitialState = {
  posts: [],
  query: '',
  filters: [],
  tags: [],
  profiles: [],
  sort: 'created',
  layout: 'grid',
  onlyUsed: false,
  onlyScheduled: false,
  onlyLiked: false,
  loading: true,
  page: 0,
  totalDocs: 0
}

export const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    setPosts: (state, action: PayloadAction<PostType[]>) => {
      state.posts = uniqBy(action.payload, '_id')
      state.loading = false
    },
    addPost: (state, action: PayloadAction<PostType>) => {
      state.posts = uniqBy([action.payload].concat(state.posts), '_id')
      state.totalDocs = state.totalDocs + 1
    },
    addPosts: (state, action: PayloadAction<PostType[]>) => {
      state.posts = uniqBy(action.payload.concat(state.posts), '_id')
      state.totalDocs = state.totalDocs + action.payload.length
    },
    updatePost: (state, action: PayloadAction<PostType>) => {
      state.posts = state.posts.map((post) => {
        if (post._id === action.payload._id) {
          return action.payload
        }

        return post
      })
    },
    removePost: (state, action: PayloadAction<string>) => {
      state.posts = state.posts.filter((post) => post._id !== action.payload)
      state.totalDocs = state.totalDocs - 1
    },
    setPostsLiked: (state, action: PayloadAction<{ isLiked: boolean; _id: string }>) => {
      state.posts = state.posts.map((post) => {
        if (post._id === action.payload._id) {
          return {
            ...post,
            isLiked: action.payload.isLiked
          }
        }

        return post
      })
    },
    setPostsStatus: (state, action: PayloadAction<InitialState['status']>) => {
      state.status = action.payload
      state.posts = []
      state.loading = true
      state.page = 0
    },
    setPostsUsed: (state, action: PayloadAction<{ isUsed: boolean; _id: string }>) => {
      state.posts = state.posts.map((post) => {
        if (post._id === action.payload._id) {
          return {
            ...post,
            isUsed: action.payload.isUsed
          }
        }

        return post
      })
    },
    clearPosts: (state) => {
      state.posts = []
      state.loading = true
      state.page = 0
    },
    setPostsQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload
      state.posts = []
      state.loading = true
      state.page = 0
    },
    setPostsUsedFilter: (state, action: PayloadAction<boolean>) => {
      state.onlyUsed = action.payload
      state.posts = []
      state.loading = true
      state.page = 0
    },
    setPostsLikedFilter: (state, action: PayloadAction<boolean>) => {
      state.onlyLiked = action.payload
      state.posts = []
      state.loading = true
      state.page = 0
    },
    setPostsScheduledFilter: (state, action: PayloadAction<boolean>) => {
      state.onlyScheduled = action.payload
      state.posts = []
      state.loading = true
      state.page = 0
    },
    togglePostsFilter: (state, action: PayloadAction<(typeof draftCategories)[number]>) => {
      state.filters = xor(state.filters, [action.payload])
      state.posts = []
      state.loading = true
      state.page = 0
    },
    togglePostsProfile: (state, action: PayloadAction<string>) => {
      state.profiles = xor(state.profiles, [action.payload])
      state.posts = []
      state.loading = true
      state.page = 0
    },
    setPostsSort: (
      state,
      action: PayloadAction<'custom' | 'created' | 'modified' | 'postDate'>
    ) => {
      state.sort = action.payload
      state.posts = []
      state.loading = true
      state.page = 0
    },
    changePostsPage: (state, action: PayloadAction<number>) => {
      state.loading = true
      state.page = action.payload
    },
    changePostsTotalDocs: (state, action: PayloadAction<number>) => {
      state.totalDocs = action.payload
    },
    setPostsLayout: (state, action: PayloadAction<'grid' | 'list'>) => {
      state.layout = action.payload
    },
    setPostsTags: (state, action: PayloadAction<string[]>) => {
      state.tags = action.payload
    },
    removeMediaFromPosts: (state, action: PayloadAction<string[]>) => {
      state.posts = state.posts.map((post) => {
        return {
          ...post,
          media: post.media?.filter((m) => !action.payload.includes(m.ref || ''))
        }
      })
    }
  }
})

export const {
  setPosts,
  addPost,
  addPosts,
  updatePost,
  removePost,
  setPostsLiked,
  setPostsStatus,
  setPostsUsed,
  clearPosts,
  setPostsQuery,
  changePostsPage,
  setPostsScheduledFilter,
  setPostsUsedFilter,
  setPostsLikedFilter,
  togglePostsFilter,
  togglePostsProfile,
  setPostsSort,
  changePostsTotalDocs,
  setPostsLayout,
  setPostsTags,
  removeMediaFromPosts
} = postsSlice.actions
export default postsSlice.reducer
