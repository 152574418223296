import { FC, createRef, useState } from 'react'
import { PhotoIcon, SwatchIcon } from '@heroicons/react/20/solid'
import {
  AdjustmentsHorizontalIcon,
  MagnifyingGlassIcon,
  XMarkIcon
} from '@heroicons/react/24/outline'
import {
  clearPhotosColors,
  clearPhotosStyle,
  setPhotosColor,
  setPhotosFavoritesFilter,
  setPhotosOrientation,
  setPhotosQuery,
  setPhotosSort,
  setPhotosStyle,
  togglePhotosCategoryFilter,
  useAppDispatch,
  useAppSelector
} from '../../../redux'
import { PHOTO_CATEGORIES } from '../../../types'
import { debounceFilter } from '../../../utils'
import { Bubble, Bubbles, Button, Input, OptionsPopover, Select } from '../..'
import { StyledPhotosViewHeader, colorList, orientationList, styleList } from '.'

const PhotosViewHeader: FC = () => {
  const dispatch = useAppDispatch()
  const [showAdditionalFilters, setShowAdditionalFilters] = useState(false)
  const [showColorPopover, setShowColorPopover] = useState(false)
  const [showStylePopover, setShowStylePopover] = useState(false)
  const [showOrientationPopover, setShowOrientationPopover] = useState(false)
  const { search, style, categories, colors, liked, sort, orientation } = useAppSelector(
    (state) => state.photos
  )
  const colorBtnRef = createRef<HTMLButtonElement>()
  const styleBtnRef = createRef<HTMLButtonElement>()
  const orientationBtnRef = createRef<HTMLButtonElement>()

  const clearAllFilters = () => {
    dispatch(clearPhotosStyle())
    dispatch(clearPhotosColors())
    dispatch(setPhotosOrientation(undefined))
  }

  return (
    <>
      <StyledPhotosViewHeader>
        <div className="photos-view-header-top">
          <Input
            value={search}
            icon={<MagnifyingGlassIcon />}
            placeholder="Search Photos"
            isDebounced
            onChange={(e) => debounceFilter(() => dispatch(setPhotosQuery(e.target.value)))}
          />
          <Select
            value={sort || ''}
            options={[
              { label: 'For You', value: 'for-you' },
              { label: 'Popular', value: 'popular' },
              { label: 'Recently Added', value: 'added' }
            ]}
            onChange={(e) => dispatch(setPhotosSort(e.target.value))}
          />
          <Button
            variant="outline"
            isIcon
            onClick={() => setShowAdditionalFilters(!showAdditionalFilters)}
          >
            {showAdditionalFilters ? <XMarkIcon /> : <AdjustmentsHorizontalIcon />}
          </Button>
        </div>
        {showAdditionalFilters && (
          <div className="photos-view-additional-filters">
            <button
              className="additional-filter filter-color-btn"
              ref={colorBtnRef}
              onClick={() => setShowColorPopover(!showColorPopover)}
            >
              <div className="color-list">
                {!colors.length && (
                  <div className="color">
                    <img src="https://i.pinimg.com/originals/d6/de/74/d6de74e3f355ff5b3e3f24ae56941d2e.jpg" />
                  </div>
                )}
                {colors.map((color) => {
                  return (
                    <div
                      className="color"
                      style={{ backgroundColor: colorList.find((c) => c.id === color)?.color }}
                      key={color}
                    />
                  )
                })}
              </div>
              {!colors.length ? 'All' : colors.length} color{colors.length !== 1 && 's'}
            </button>
            <button
              className="additional-filter filter-style-btn"
              ref={styleBtnRef}
              onClick={() => setShowStylePopover(!showStylePopover)}
            >
              <SwatchIcon />
              {!style.length ? 'All' : style.length} style{style.length !== 1 && 's'}
            </button>
            <button
              className="additional-filter filter-orientation-btn"
              ref={orientationBtnRef}
              onClick={() => setShowOrientationPopover(!showOrientationPopover)}
            >
              <PhotoIcon />
              {!orientation && 'All orientations'}
              {!!orientation && orientation}
            </button>
            {(!!orientation || !!style.length || !!colors.length) && (
              <button className="clear-btn" onClick={() => clearAllFilters()}>
                Clear all filters
              </button>
            )}
          </div>
        )}
        <Bubbles
          value={categories}
          options={PHOTO_CATEGORIES}
          onSelect={(value) => dispatch(togglePhotosCategoryFilter(value.value))}
        >
          <Bubble
            label="Only Favorites"
            isSelected={liked}
            onClick={() => dispatch(setPhotosFavoritesFilter(!liked))}
            key="only-favorites"
          />
        </Bubbles>
      </StyledPhotosViewHeader>
      <OptionsPopover
        options={colorList.map((color) => ({
          icon: <div className="color-option" style={{ backgroundColor: color.color }} />,
          label: color.label,
          isSelected: colors.includes(color.id),
          stayOpen: true,
          action: () => dispatch(setPhotosColor(color.id))
        }))}
        height={250}
        position="bottom-left"
        buttonRef={colorBtnRef}
        classToAvoid="filter-color-btn"
        isOpen={showColorPopover}
        onClose={() => setShowColorPopover(false)}
      />
      <OptionsPopover
        options={styleList.map((s) => ({
          label: s.label,
          isSelected: style.includes(s.value),
          stayOpen: true,
          action: () => dispatch(setPhotosStyle(s.value))
        }))}
        height={250}
        position="bottom-left"
        buttonRef={styleBtnRef}
        classToAvoid="filter-style-btn"
        isOpen={showStylePopover}
        onClose={() => setShowStylePopover(false)}
      />
      <OptionsPopover
        options={orientationList.map((o) => ({
          label: o.label,
          isSelected: orientation === o.value,
          stayOpen: true,
          action: () =>
            dispatch(setPhotosOrientation(orientation === o.value ? undefined : o.value))
        }))}
        height={250}
        position="bottom-left"
        buttonRef={orientationBtnRef}
        classToAvoid="filter-orientation-btn"
        isOpen={showOrientationPopover}
        onClose={() => setShowOrientationPopover(false)}
      />
    </>
  )
}

export default PhotosViewHeader
