import { createRef, FC, useEffect, useState } from 'react'
import { groupBy } from 'lodash'
import { useActiveObject, useEditor } from '@layerhub-io/react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { fontList, getTextProperties, loadFonts } from '../../../utils'
import { OptionsPopover } from '../..'
import { StyledDesignerFontFamilyTool } from '.'

const DesignerFontFamilyTool: FC = () => {
  const btnRef = createRef<HTMLButtonElement>()
  const editor = useEditor()
  const activeObject = useActiveObject() as any
  const [commonFonts, setCommonFonts] = useState<typeof fontList>([])
  const [fontFamily, setFontFamily] = useState('')
  const [showOptions, setShowOptions] = useState(false)

  const changeFontFamily = async (font: typeof commonFonts[number]) => {
    const fontObject = {
      name: font.post_script_name,
      url: font.url
    }

    await loadFonts([fontObject])

    editor.objects.update({
      fontFamily: fontObject.name,
      fontURL: fontObject.url
    })
  }

  const handleFontFamily = () => {
    if (!activeObject || activeObject.type !== 'StaticText') {
      return
    }

    const { fontFamily } = getTextProperties(activeObject)

    setFontFamily(fontFamily)
  }

  useEffect(() => {
    const grouped = groupBy(fontList, 'family')
    const standardFonts = Object.keys(grouped).map((key) => {
      const familyFonts = grouped[key]
      const standardFont = familyFonts.find((familyFont) =>
        familyFont.post_script_name.includes('-Regular')
      )
      if (standardFont) {
        return standardFont
      }
      return familyFonts[familyFonts.length - 1]
    })
    setCommonFonts(standardFonts)
  }, [])

  useEffect(() => {
    handleFontFamily()

    if (editor) {
      editor.on('history:changed', handleFontFamily)
    }
    return () => {
      if (editor) {
        editor.off('history:changed', handleFontFamily)
      }
    }
  }, [editor, activeObject])

  return (
    <>
      <StyledDesignerFontFamilyTool
        className="font-select-btn"
        ref={btnRef}
        onClick={() => setShowOptions(true)}
      >
        <span>{fontFamily}</span>
        <ChevronDownIcon />
      </StyledDesignerFontFamilyTool>
      <OptionsPopover
        options={commonFonts
          .sort((a, b) => a.family.localeCompare(b.family))
          .map((font) => ({
            label: font.family,
            render: <img src={font.preview} style={{ height: 30 }} />,
            isSelected: fontFamily === font.url,
            action: () => changeFontFamily(font)
          }))}
        position="bottom-left"
        classToAvoid="font-select-btn"
        width={400}
        buttonRef={btnRef}
        isSearchable
        isOpen={showOptions}
        onClose={() => setShowOptions(false)}
      />
    </>
  )
}

export default DesignerFontFamilyTool
