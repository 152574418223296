import { FC, MouseEvent, createRef, useEffect, useState } from 'react'
import { useDraggable } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import {
  ArrowDownTrayIcon,
  EllipsisHorizontalIcon,
  EyeIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon
} from '@heroicons/react/24/outline'
import { PhotoIcon, VideoCameraIcon } from '@heroicons/react/24/solid'
import { setCloudPreview, useAppDispatch } from '../../../redux'
import { CloudContentType } from '../../../types'
import { getFileExt, getFileName, getRelativeString, useCloud } from '../../../utils'
import {
  ActionDialog,
  AddToPostDialog,
  CloudMediaPreview,
  InputDialog,
  OptionsPopover
} from '../..'
import { StyledUploadsFileListItem } from '.'

type Props = {
  file: CloudContentType
  isOverlay?: boolean
}

const UploadsFileListItem: FC<Props> = ({ file, isOverlay }) => {
  const optionsBtnRef = createRef<HTMLButtonElement>()
  const dispatch = useAppDispatch()
  const [showDeletionConfirmation, setShowDeletionConfirmation] = useState(false)
  const [showAddToPostDialog, setShowAddToPostDialog] = useState(false)
  const [showOptions, setShowOptions] = useState(false)
  const [showNameInput, setShowNameInput] = useState(false)
  const [newName, setNewName] = useState('')
  const [loading, setLoading] = useState(false)
  const { deleteFromCloud, downloadCloudFile, updateCloudFileData } = useCloud()
  const { attributes, listeners, isDragging, setNodeRef, transform } = useDraggable({
    id: file._id,
    data: {
      file
    }
  })

  const style = {
    transform: CSS.Translate.toString(transform)
  }

  const handleDelete = async () => {
    setLoading(true)
    await deleteFromCloud([file._id])
    setLoading(false)
  }

  const handleDownload = async () => {
    setLoading(true)
    await downloadCloudFile(file)
    setLoading(false)
  }

  const handleOptions = (e: MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setShowOptions(!showOptions)
  }

  useEffect(() => {
    setNewName(file.name)
  }, [showNameInput])

  return (
    <>
      <StyledUploadsFileListItem
        style={isOverlay ? style : undefined}
        isDragging={isDragging}
        isOverlay={isOverlay}
        ref={setNodeRef}
        {...attributes}
        {...listeners}
        onClick={() => dispatch(setCloudPreview(file))}
        onContextMenu={(e: MouseEvent) => handleOptions(e)}
      >
        <div className="file-preview-container">
          <CloudMediaPreview
            url={file.file?.url || ''}
            preview={file.file?.preview}
            mediaWidth={200}
            type={file.file?.type || 'image'}
          />
        </div>
        <div className="file-footer">
          <div className="file-details-container">
            <div className="file-icon">
              {file.file?.type === 'video' ? <VideoCameraIcon /> : <PhotoIcon />}
            </div>
            <div className="file-details">
              <div className="file-name">
                {getFileName(file.name)}
                <span className="file-ext">{getFileExt(file.name)}</span>
              </div>
              <span className="file-modified">
                Last modified {getRelativeString(file?.updatedAt)}
              </span>
            </div>
          </div>
          <button
            className="options-btn"
            data-active={showOptions}
            ref={optionsBtnRef}
            onClick={(e) => handleOptions(e)}
          >
            <EllipsisHorizontalIcon />
          </button>
        </div>
      </StyledUploadsFileListItem>
      <OptionsPopover
        options={[
          {
            icon: <PlusIcon />,
            label: 'Add to post',
            action: () => setShowAddToPostDialog(true)
          },
          {
            icon: <EyeIcon />,
            label: `Preview ${file.type}`,
            action: () => console.log('PREVIEW')
          },
          {
            icon: <PencilIcon />,
            label: `Rename ${file.type}`,
            action: () => setShowNameInput(true)
          },
          {
            icon: <ArrowDownTrayIcon />,
            label: `Download ${file.type}`,
            action: () => handleDownload()
          },
          {
            icon: <TrashIcon />,
            label: `Delete ${file.type}`,
            action: () => setShowDeletionConfirmation(true)
          }
        ]}
        classToAvoid="options-btn"
        buttonRef={optionsBtnRef}
        isOpen={showOptions}
        onClose={() => setShowOptions(false)}
      />
      <AddToPostDialog
        photo={file}
        isOpen={showAddToPostDialog}
        onClose={() => setShowAddToPostDialog(false)}
      />
      <ActionDialog
        title={`Delete this file?`}
        body={`Are you sure you want to delete "${
          file.name || 'Untitled File'
        }"? This file will be removed from all posts. You will not be able to recover it.`}
        type="error"
        confirmButtonText="Delete"
        isOpen={showDeletionConfirmation}
        onClose={() => setShowDeletionConfirmation(false)}
        onConfirm={() => handleDelete()}
      />
      <InputDialog
        isOpen={showNameInput}
        title={`Rename ${file.type}`}
        value={newName}
        confirmButtonText="Rename"
        onConfirm={() => updateCloudFileData({ ...file, name: newName })}
        onChange={(value) => setNewName(value)}
        onClose={() => setShowNameInput(false)}
      />
    </>
  )
}

export default UploadsFileListItem
