import { FC, RefObject } from 'react'
import { Popover, Range } from '../..'
import { StyledOpacityPopover } from '.'

type Props = {
  value: number
  position?: 'top-left' | 'top-center' | 'bottom-right' | 'bottom-left'
  classToAvoid: string
  buttonRef: RefObject<HTMLDivElement | HTMLButtonElement>
  isOpen: boolean
  onChange: (value: number) => void
  onClose: () => void
}

const OpacityPopover: FC<Props> = ({
  position = 'bottom-right',
  classToAvoid,
  buttonRef,
  value,
  isOpen,
  onChange,
  onClose
}) => {
  return (
    <Popover
      position={position}
      classToAvoid={classToAvoid}
      buttonRef={buttonRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <StyledOpacityPopover>
        <Range label="Transparency" value={value} onChange={(value) => onChange(value)} />
      </StyledOpacityPopover>
    </Popover>
  )
}

export default OpacityPopover
