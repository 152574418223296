import { createGlobalStyle } from 'styled-components'

const StyledTour = createGlobalStyle`
  .shepherd-element{
    background-color: ${({ theme }) => theme.color.background};
    border-radius: 10px;
    box-shadow: 0px 0px 0px 1px rgba(17, 24, 39, 0.1), 0px 2px 4px rgba(17, 24, 39, 0.1);
    max-width: 360px;
    opacity: 0;
    outline: none;
    transition: opacity 0.3s, visibility 0.3s;
    visibility: hidden;
    width: 100%;
    z-index: 9999;

    .shepherd-content {
      .shepherd-header {
        .shepherd-cancel-icon {
          position: absolute;
          top: 8px;
          right: 8px;
          padding: 0;
          width: 26px;
          height: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 28px;
          border-radius: 6px;
          border: 0;
          transition: 0.2s;
          color: ${({ theme }) => theme.color.gray[900]}60;
          background-color: transparent;

          &:hover {
            cursor: pointer;
            background-color: ${({ theme }) => theme.color.background}50;
            color: ${({ theme }) => theme.color.gray[900]}cc;
          }
        }
      }

      .shepherd-title {
        padding: 25px;
        margin-bottom: -40px;
        font-size: 20px;
        font-weight: 600;
        font-family: ${({ theme }) => theme.typeface.faktumXCon};
        color: ${({ theme }) => theme.color.gray[800]};
        width: calc(100% - 36px);
        box-sizing: border-box;
      }

      .shepherd-text {
        padding: 25px;
        box-sizing: border-box;
        width: calc(100% - 36px);
        font-size: 1rem;
        line-height: 24px;
        color: ${({ theme }) => theme.color.gray[700]};

        img {
          height: 125px;
          object-fit: cover;
          width: calc(100% + 36px + 25px + 25px);
          border-radius: 6px 6px 0 0;
          margin: -25px -25px 18px -25px;
        }

        .centered-text {
          text-align: center;
          display: block;
          margin-right: -36px;
        }

        b {
          font-weight: 600;
        }
      }
      
      .shepherd-footer {
        padding: 25px;
        display: flex;
        align-items: center;

        .step-progress {
          margin-right: auto;
          font-size: 14px;
          font-weight: 600;
          color: ${({ theme }) => theme.color.gray[500]};
          user-select: none;
        }

        button {
          height: 42px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.2s;
          border-radius: 4px;
          padding: 0 16px;
          min-width: 70px;
          font-weight: 500;
          user-select: none;
          border: 1px solid ${({ theme }) => theme.color.gray[300]};
          background-color: ${({ theme }) => theme.color.background};
          color: ${({ theme }) => theme.color.gray[600]};

          &:not(:last-child) {
            margin-right: 10px;
          }
    
          &:hover {
            cursor: pointer;
            background-color: ${({ theme }) => theme.color.gray[100]};
          }

          &:first-child {
            border: 1px solid ${({ theme }) => theme.color.brand};
            background-color: ${({ theme }) => theme.color.brand};
            color: ${({ theme }) => theme.color.background};

            &:hover {
              background-color: ${({ theme }) => theme.color.brandDark};
              border: 1px solid ${({ theme }) => theme.color.brandDark};
            }
          }
        }
      }
    }

    &.shepherd-enabled {
      opacity:1;
      visibility:visible
    }

    &[data-popper-reference-hidden]:not(.shepherd-centered){
      opacity:0;
      pointer-events:none;
      visibility:hidden
    }
  }

  .shepherd-modal-overlay-container{
    height: 0;
    left: 0;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    position: fixed;
    top: 0;
    transition: all .3s ease-out, height 0ms .3s, opacity .3s 0ms;
    width: 100vw;
    z-index: 9999;

    &.shepherd-modal-is-visible {
      height: 100vh;
      opacity: 0.5;
      transition: all .3s ease-out, height 0s 0s, opacity .3s 0s;

      path {
        pointer-events: all;
      }
    }
  }

  .shepherd-target-click-disabled {
    pointer-events: none !important;
    cursor: default;
  }
`

export default StyledTour
