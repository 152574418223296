import styled from 'styled-components'

type StyledProps = {
  keyboardHeight: number
}

const StyledCreateActionBar = styled.div<StyledProps>`
  height: 65px;
  left: 16px;
  right: 16px;
  position: fixed;
  box-sizing: border-box;
  bottom: var(--bottom-spacing);
  border-radius: 12px;
  padding: 0 14px;
  display: flex;
  align-items: center;
  z-index: ${({ theme }) => theme.elevation.fixed};
  justify-content: space-between;
  box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
    0px 2px 3px rgba(0, 0, 0, 0.05);

  button {
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .chat-btn {
    color: ${({ theme }) => theme.color.brand};
    font-weight: 600;
    padding: 0 10px 0 7px;
    background-color: ${({ theme }) => theme.color.brandLight};
    border-radius: 30px 30px 30px 4px;

    svg {
      height: 26px;
      margin-right: 4px;
    }
  }

  .action-row {
    display: flex;
    align-items: center;

    .post-status {
      user-select: none;
      pointer-events: none;
      display: flex;
      align-items: center;
      font-size: 15px;
      border-radius: 6px;
      padding: 0 10px 0 8px;
      height: 36px;
      color: ${({ theme }) => theme.color.gray[500]};
      background-color: ${({ theme }) => theme.color.gray[100]};
      font-weight: 500;

      svg {
        height: 20px;
        margin-right: 4px;
      }
    }

    .action-row-icon-btn {
      width: 38px;
      border-radius: 12px;
      background-color: transparent;
      color: ${({ theme }) => theme.color.gray[900]};

      svg {
        height: 36px;
      }

      &:not(:first-child) {
        margin-left: 8px;
      }

      &:not(:last-child) {
        margin-right: 8px;
      }
    }

    .collapse-btn {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      color: ${({ theme }) => theme.color.gray[500]};
      background-color: ${({ theme }) => theme.color.gray[200]};

      svg {
        height: 24px;
        position: relative;
        top: 1px;
        stroke-width: 2px;
      }
    }
  }

  &[data-keyboard-shown='true'] {
    bottom: ${({ keyboardHeight }) => keyboardHeight}px;
    left: 0;
    right: 0;
    border-radius: 0;
    box-shadow: 0px 0px 0px 1px ${({ theme }) => theme.color.foreground}10,
      0px 2px 3px ${({ theme }) => theme.color.foreground}15;
  }
`

export default StyledCreateActionBar
