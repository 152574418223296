import styled from 'styled-components'

type StyledProps = {
  view: 'mobile' | 'desktop'
}

const StyledLinkPagePreview = styled.div<StyledProps>`
  background-color: ${({ theme }) => theme.color.gray[100]};
  display: grid;
  position: fixed;
  display: none;
  overflow: hidden;
  grid-template-rows: auto minmax(0, 1fr);

  .link-page-preview-header {
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 14px;
    background-color: ${({ theme }) => theme.color.background};
    border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
    z-index: 1;
    position; relative;

    .browser-actions {
      display: flex;
      align-items: center;

      div {
        height: 12px;
        width: 12px;
        border-radius: 50%;
        box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.foreground}10;

        &:nth-of-type(1) {
          background-color: #ec6a5e;
        }

        &:nth-of-type(2) {
          background-color: #f5bd4f;
        }

        &:nth-of-type(3) {
          background-color: #61c454;
        }

        &:not(:last-child) {
          margin-right: 8px;
        }
      }
    }

    .browser-search-bar {
      border-radius: 6px;
      height: 30px;
      padding: 0 10px 0 8px;
      display: flex;
      align-items: center;
      font-size: 14px;
      user-select: none;
      background-color: ${({ theme }) => theme.color.gray[100]};
      color: ${({ theme }) => theme.color.gray[600]};

      svg {
        height: 18px;
        stroke-width: 1.9px;
        margin-right: 8px;
        color: ${({ theme }) => theme.color.gray[500]};
      }
    }

    .link-page-view-switch {
      display: flex;
      height: 30px;
      border-radius: 6px;
      overflow: hidden;
      border: 1px solid ${({ theme }) => theme.color.gray[300]};

      button {
        height: 30px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        color: ${({ theme }) => theme.color.gray[500]};

        svg {
          height: 22px;
          stroke-width: 1.75px;
        }

        &:first-child {
          border-right: 1px solid ${({ theme }) => theme.color.gray[300]};
        }

        &[aria-selected="true"] {
          color: ${({ theme }) => theme.color.brand};
          background-color: ${({ theme }) => theme.color.brandLight};
        }
      }
    }
  }

  .page-preview-container {
    overflow-y: auto;
    ${({ view }) =>
      view === 'mobile' &&
      `
      max-width: 450px;
      width: 100%;
      margin: 0 auto;
    `}
    box-shadow: 0 0 0 10px ${({ theme }) => theme.color.foreground}10;

    .page-preview-sizer {
      height: 100%;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    height: 100vh;
    display: grid;
    position: unset;
  }
`

export default StyledLinkPagePreview
