import { FC, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import InfiniteScroll from 'react-infinite-scroll-component'
import Masonry from 'react-masonry-css'
import { times, uniqBy } from 'lodash'
import { useApolloClient } from '@apollo/client'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { EmptyState, Input, PhotoListItem, Skeleton } from '../../..'
import { searchGalleryQuery } from '../../../../graphql'
import { PhotoType } from '../../../../types'
import { debounceFilter, useDesigner } from '../../../../utils'
import { DesignerPanel } from '../..'
import { StyledDesignerGalleryPanel } from '.'

const DesignerGalleryPanel: FC = () => {
  const apollo = useApolloClient()
  const { addImage } = useDesigner()
  const [query, setQuery] = useState('')
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [photos, setPhotos] = useState<PhotoType[]>([])
  const [loading, setLoading] = useState(true)

  const loadPhotos = async () => {
    try {
      setLoading(true)

      const { data } = await apollo.query({
        fetchPolicy: 'no-cache',
        query: searchGalleryQuery,
        variables: {
          search: query,
          orientation: null,
          sort: 'custom',
          categories: [],
          style: [],
          colors: [],
          collection: null,
          page,
          items: 20
        }
      })

      const result = data?.searchGallery?.docs || []
      const total = data?.searchGallery?.totalDocs || 0

      setPhotos(uniqBy([...photos, ...result], '_id'))
      setTotal(total)
    } catch (err) {
      console.log(err)
      toast.error((err as any).message)
    } finally {
      setLoading(false)
    }
  }

  const handleSearch = (search: string) => {
    setQuery(search)
    setPage(0)
    setPhotos([])
  }

  useEffect(() => {
    loadPhotos()
  }, [query, page])

  return (
    <DesignerPanel>
      <StyledDesignerGalleryPanel>
        <div className="designer-panel-header">
          <Input
            value={query}
            icon={<MagnifyingGlassIcon />}
            type="search"
            placeholder="Search gallery"
            isDebounced
            onChange={(e) => debounceFilter(() => handleSearch(e.target.value))}
          />
        </div>
        <div className="designer-panel-scroll" id="designer-gallery-list">
          {!loading && !photos.length && (
            <EmptyState
              title="We came up empty"
              body="We couldn't find a photo that matches that query. How about a different search?"
            />
          )}
          {loading && (
            <Masonry
              className="masonry-grid"
              columnClassName="masonry-grid-column"
              breakpointCols={{
                default: 2,
                900: 1
              }}
            >
              {times(15, (num) => {
                return (
                  <Skeleton
                    randHeight={[160, 220]}
                    styles="&:not(:last-child) {
                  margin-bottom: 6px;
                } background: #313233 !important;"
                    key={num}
                  />
                )
              })}
            </Masonry>
          )}
          <InfiniteScroll
            scrollThreshold={0.7}
            dataLength={photos.length}
            next={() => setPage(page + 1)}
            hasMore={photos.length < total}
            scrollableTarget="designer-gallery-list"
            loader={null}
          >
            <Masonry
              className="masonry-grid"
              columnClassName="masonry-grid-column"
              breakpointCols={{
                default: 2,
                900: 1
              }}
            >
              {photos.map((photo) => {
                return (
                  <PhotoListItem
                    onExpand={(photo) =>
                      addImage(photo.file.fileHttpLink || '', photo.file.imgSize)
                    }
                    photo={photo}
                    isInline={true}
                    key={photo._id}
                  />
                )
              })}
            </Masonry>
          </InfiniteScroll>
        </div>
      </StyledDesignerGalleryPanel>
    </DesignerPanel>
  )
}

export default DesignerGalleryPanel
