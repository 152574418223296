import { FC, useEffect } from 'react'
import toast from 'react-hot-toast'
import InfiniteScroll from 'react-infinite-scroll-component'
import { times } from 'lodash'
import { useApolloClient } from '@apollo/client'
import { searchStorySets } from '../../../graphql'
import {
  changeStoriesPage,
  changeStoriesTotalDocs,
  setStories,
  useAppDispatch,
  useAppSelector
} from '../../../redux'
import { conditionalStage, isMobile } from '../../../utils'
import { Skeleton } from '../..'
import { StoryListItem } from '..'
import { StyledStoryList } from '.'

const StoryList: FC = () => {
  const apollo = useApolloClient()
  const dispatch = useAppDispatch()
  const { loading, stories, page, totalDocs } = useAppSelector((state) => state.stories)

  const loadStories = async () => {
    try {
      const { data } = await apollo.query({
        fetchPolicy: 'no-cache',
        query: searchStorySets,
        variables: { page, items: 15 }
      })

      const storyList = data?.searchStorySets?.docs || []
      dispatch(setStories([...stories, ...storyList]))
      dispatch(changeStoriesTotalDocs(data?.searchStorySets?.totalDocs || 0))
    } catch (err) {
      console.log(err)
      toast.error((err as any).message)
    }
  }

  useEffect(() => {
    if (loading) {
      loadStories()
    }
  }, [loading])

  return (
    <StyledStoryList>
      {loading && page === 0 && (
        <div className="story-list-grid">
          {times(15, (num) => {
            return <Skeleton styles="padding-top: 178%;" key={num} />
          })}
        </div>
      )}
      <InfiniteScroll
        scrollThreshold={0.7}
        dataLength={stories.length}
        next={() => dispatch(changeStoriesPage(page + 1))}
        hasMore={stories.length < totalDocs}
        loader={null}
        className="infinite-scroll"
        {...conditionalStage(isMobile(), { scrollableTarget: 'app-content' })}
      >
        <div className="story-list-grid">
          {stories.map((story) => {
            return <StoryListItem story={{ ...story, index: null }} key={story._id} />
          })}
        </div>
      </InfiniteScroll>
    </StyledStoryList>
  )
}

export default StoryList
