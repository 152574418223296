import { FC, useContext } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router-dom'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { BoltIcon } from '@heroicons/react/24/solid'
import goProHeaderImg from '../../assets/go-pro-header.gif'
import { Banner, Button, PageLayout, PageSizzle } from '../../components'
import { pushToDialogStack, useAppDispatch } from '../../redux'
import { ROUTES, userStore } from '../../utils'
import { StyledProView } from '.'

const ProView: FC = () => {
  const dispatch = useAppDispatch()
  const { user } = useContext(userStore)

  if (!user?.trial && user?.stripeSubscriptionStatus === 'Active') {
    return <Redirect to={ROUTES.settings.billing} />
  }

  return (
    <StyledProView>
      <Helmet title="Go Pro | Social Curator" />
      {/* {user?.trial && <Banner message="You have 13 days left until your trial ends." />} */}
      <PageSizzle
        maxWidth={650}
        bubble="GO PRO"
        title="Become a Pro user"
        body="Upgrade to continue using Dottie & keep your posts, uploaded media, Link Page and more."
      />
      <PageLayout maxWidth={650}>
        <Button
          iconPos="left"
          variant="emphasis"
          size="lg"
          onClick={() => dispatch(pushToDialogStack('go-pro'))}
        >
          <BoltIcon /> Upgrade to Pro
        </Button>
        <div className="bullet-section good">
          <h3>What you gain by upgrading</h3>
          <ul>
            <li>
              <div className="icon">
                <CheckIcon />
              </div>
              <span>Access to the entire platform</span>
            </li>
            <li>
              <div className="icon">
                <CheckIcon />
              </div>
              <span>A community of entrepenuers</span>
            </li>
            <li>
              <div className="icon">
                <CheckIcon />
              </div>
              <span>Connecting up to 6 social profiles</span>
            </li>
          </ul>
        </div>
        {!!user?.trial && (
          <div className="bullet-section bad">
            <h3>What you lose when your trial ends</h3>
            <ul>
              <li>
                <div className="icon">
                  <XMarkIcon />
                </div>
                <span>Access to Dottie</span>
              </li>
              <li>
                <div className="icon">
                  <XMarkIcon />
                </div>
                <span>All posts you created</span>
              </li>
              <li>
                <div className="icon">
                  <XMarkIcon />
                </div>
                <span>All uploaded photos and videos</span>
              </li>
              <li>
                <div className="icon">
                  <XMarkIcon />
                </div>
                <span>A gallery of 6k+ lifestyle photos</span>
              </li>
              <li>
                <div className="icon">
                  <CheckIcon />
                </div>
                <span>Your custom bio Link Page</span>
              </li>
              <li>
                <div className="icon">
                  <XMarkIcon />
                </div>
                <span>Auto-posting to connected profiles</span>
              </li>
              <li>
                <div className="icon">
                  <XMarkIcon />
                </div>
                <span>Completing Daily Actions</span>
              </li>
            </ul>
          </div>
        )}
      </PageLayout>
    </StyledProView>
  )
}

export default ProView
