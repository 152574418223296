import { toast } from 'react-hot-toast'

export const copyToClipboard = async (text?: string | null, copyString?: string) => {
  try {
    if (!text) {
      return
    }

    await navigator.clipboard.writeText(text || '')
    toast.success(copyString || 'Copied to clipboard')
  } catch {
    toast.error('Failed to copy to clipboard')
  }
}
