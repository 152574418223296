import styled from 'styled-components'

const StyledUploadsHeader = styled.div`
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 53px;
  color: ${({ theme }) => theme.color.gray[800]};
  border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
  user-select: none;

  .label {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.01rem;
    color: ${({ theme }) => theme.color.gray[800]};
  }

  .sort-btn {
    color: ${({ theme }) => theme.color.gray[500]};
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    background-color: transparent;

    svg {
      height: 14px;
      position: relative;
      top: 1px;
      stroke-width: 2.75px;
      margin-left: 6px;
    }
  }
`

export default StyledUploadsHeader
