import { FC, useEffect } from 'react'
import { toast } from 'react-hot-toast'
import InfiniteScroll from 'react-infinite-scroll-component'
import { times } from 'lodash'
import { useApolloClient } from '@apollo/client'
import { searchUserDraftsQuery } from '../../../../graphql'
import {
  changePostsPage,
  changePostsTotalDocs,
  setPosts,
  setPostsTags,
  useAppDispatch,
  useAppSelector
} from '../../../../redux'
import { EmptyState } from '../..'
import { PostListItem } from '..'
import { StyledPostList } from '.'

const PostList: FC = () => {
  const apollo = useApolloClient()
  const dispatch = useAppDispatch()
  const {
    posts,
    loading,
    query,
    sort,
    page,
    onlyLiked,
    filters,
    totalDocs,
    profiles,
    status,
    onlyUsed,
    onlyScheduled
  } = useAppSelector((state) => state.posts)

  const loadPosts = async () => {
    try {
      const { data } = await apollo.query({
        fetchPolicy: 'no-cache',
        query: searchUserDraftsQuery,
        variables: {
          page,
          status,
          search: query,
          categories: filters,
          liked: onlyLiked,
          hideUsed: onlyUsed,
          profiles,
          onlyScheduled,
          sort,
          items: 15
        }
      })

      const postList = data?.searchUserDrafts?.docs || []
      dispatch(setPosts([...posts, ...postList]))
      dispatch(setPostsTags(data.searchUserDrafts.tags || []))
      dispatch(changePostsTotalDocs(data?.searchUserDrafts?.totalDocs || 0))
    } catch (err) {
      console.log(err)
      toast.error((err as any)?.message || err)
    }
  }

  useEffect(() => {
    if (loading) {
      loadPosts()
    }
  }, [loading])

  return (
    <StyledPostList id="post-list-scroll">
      {!posts.length && !loading && (
        <div style={{ padding: 14 }}>
          <EmptyState title="What will you create?" body="Created posts appear here" />
        </div>
      )}
      {loading &&
        page === 0 &&
        times(6, (num) => {
          return <div className="skeleton" key={num} />
        })}
      <InfiniteScroll
        scrollThreshold={0.7}
        dataLength={posts.length}
        next={() => dispatch(changePostsPage(page + 1))}
        hasMore={posts.length < totalDocs}
        loader={null}
        scrollableTarget="post-list-scroll"
      >
        {posts.map((post) => {
          return <PostListItem post={post} key={post._id} />
        })}
      </InfiniteScroll>
    </StyledPostList>
  )
}

export default PostList
