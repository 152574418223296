import { FC, useEffect, useState } from 'react'
import { useActiveObject, useEditor } from '@layerhub-io/react'
import { setDesignerSubTab, useAppDispatch } from '../../../redux'
import { StyledDesignerColorTool } from '.'

const DesignerColorTool: FC = () => {
  const activeObject = useActiveObject() as any
  const editor = useEditor()
  const dispatch = useAppDispatch()
  const [color, setColor] = useState('#000')

  const handleColor = () => {
    if (!activeObject) {
      return
    }

    if (activeObject?.colorMap) {
      setColor(Object.values(activeObject.colorMap)[0] as any)
      return
    }

    setColor(activeObject?.fill)
  }

  useEffect(() => {
    handleColor()

    if (editor) {
      editor.on('history:changed', handleColor)
    }
    return () => {
      if (editor) {
        editor.off('history:changed', handleColor)
      }
    }
  }, [editor, activeObject])

  return (
    <StyledDesignerColorTool
      data-tooltip="Change Color"
      style={{ background: color }}
      onClick={() => dispatch(setDesignerSubTab('fill-color'))}
    />
  )
}

export default DesignerColorTool
