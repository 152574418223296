import styled from 'styled-components'

type StyledProps = {
  isExpanded?: boolean
}

const StyledCreateBarContentRecommended = styled.div<StyledProps>`
  user-select: none;
  position: relative;
  overflow: hidden;
  max-height: 62px;
  transition: 0.2s;

  .recommended-sizer {
    padding: 0 0 10px 0;

    .recommended-header {
      padding: 20px 20px 16px 20px;

      .title {
        display: flex;
        align-items: center;
        color: ${({ theme }) => theme.color.gray[800]};
        font-weight: 600;

        svg {
          height: 18px;
          margin-right: 4px;
        }
      }

      .sub-title {
        margin-top: 4px;
        display: block;
        color: ${({ theme }) => theme.color.gray[500]};
        font-size: 15px;
      }
    }

    .photo-item {
      height: 140px;
      background-color: ${({ theme }) => theme.color.gray[100]};

      img {
        height: 100%;
      }
    }
  }

  .toggle-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    border-radius: 50%;
  }

  ${({ isExpanded }) =>
    isExpanded &&
    `
      max-height: 226px;
  `}
`

export default StyledCreateBarContentRecommended
