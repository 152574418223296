const ActionPlans = {
  actionPlans: '/action-plans',
  actionPlan: '/action-plans/:slug',
  actionPlanBySlug: (slug: string) => `/action-plans/${slug}`
}

const Photos = {
  photo: '/content/photos/photo/:_id',
  photoById: (_id: string) => `/content/photos/photo/${_id}`
}

const PhotoSets = {
  photoSets: '*/photo-set/:_id',
  photoSetById: (_id: string) => `/photo-set/${_id}`
}

const Stories = {
  stories: '/gallery/stories',
  story: '*/story/:_id',
  storiesById: (_id: string) => `/action-plans/${_id}`
}

const Page = {
  page: '/page',
  newPage: '/page/new',
  preview: '/page/preview',
  userPage: '/@:username',
  userPageByUsername: (username: string) => `/@${username}`
}

const LinkPage = {
  page: '/link-page',
  newPage: '/link-page/new',
  preview: '/link-page/preview',
  userPage: '/@:username',
  userPageByUsername: (username: string) => `/@${username}`
}

const Settings = {
  account: '/user/profile',
  billing: '/user/billing',
  personas: '/user/personas',
  pillars: '/user/pillars'
}

const Admin = {
  loginAsUser: '/admin/user/login',
  editCaptionById: (issueId: string | null, captionId: string) => {
    if (!issueId) {
      return `https://hello.socialcurator.com/admin/captions/${captionId}`
    }
    return `https://hello.socialcurator.com/admin/action-plans/edit-action-plan/${issueId}/caption/${captionId}`
  },
  captionById: (_id: string) => `${window.location.origin}/captions?caption=${_id}`,
  editPhotoById: (_id: string) => `https://hello.socialcurator.com/admin/gallery/${_id}`,
  photoById: (_id: string) => `${window.location.origin}/gallery?photo=${_id}`
}

const Workspaces = {
  new: '/workspace/new',
  workspace: '/workspace',
  workspaceById: (_id: string) => `/workspace/${_id}`,
  invite: '/w/invite'
}

const External = {
  termsOfServiceUrl: 'https://socialcurator.com/terms-of-service/',
  privacyPolicyUrl: 'https://www.socialcurator.com/privacy-policy',
  cancellationPolicyUrl: 'https://socialcurator.com/cancel/',
  helpCenterUrl: 'https://help.socialcurator.com/en/',
  // adminPanel: 'https://app.forestadmin.com/Social%20Curator',
  adminPanel: 'https://hello.socialcurator.com/admin',
  oldPlatform: 'https://hello.socialcurator.com',
  marketing: 'https://new.socialcurator.com',
  connectingProfilesArticle:
    'https://help.socialcurator.com/en/articles/7974721-connecting-your-social-media-accounts-to-your-social-curator-profile'
}

const ResourceCenter = {
  6333355:
    'https://help.socialcurator.com/en/articles/6333355-connecting-your-social-media-accounts-and-enabling-auto-posting-using-the-social-curator-platform'
}

const Mobile = {
  content: '/content',
  learn: '/learn'
}

const Tasks = {
  list: '/tasks'
}

const Academy = {
  main: '/academy',
  preview: '/academy/:slug/preview',
  previewBySlug: (slug: string) => `/academy/${slug}/preview`,
  lesson: '/academy/:slug/:lesson',
  lessonBySlug: (slug: string, lesson: string) => `/academy/${slug}/${lesson}`
}

const Cloud = {
  all: '/cloud/all',
  path: '/cloud/all/:path+',
  pathByPath: (path: string) => `/cloud/all/${path.split(' ').join('-')}`
}

const Uploads = {
  all: '/uploads',
  folder: '/uploads/all/:path+',
  folderByPath: (path: string) => `/uploads/all/${encodeURIComponent(path).split('%20').join('-')}`
}

const Assets = {
  all: '/assets'
}

const Old = {
  captions: '/captions',
  stories: '/stories',
  gallery: '/gallery',
  courses: '/courses',
  coursePreview: '/courses/:slug/preview',
  coursePreviewBySlug: (slug: string) => `/courses/${slug}/preview`,
  lesson: '/courses/:slug/:lesson',
  lessonBySlug: (slug: string, lesson: string) => `/courses/${slug}/${lesson}`,
  affiliate: '/affiliate',
  login: '/user/login',
  register: '/user/signup',
  drafts: '/drafts',
  issues: '/issues',
  issue: '/issues/:slug'
}

const Auth = {
  login: '/login',
  register: '/signup',
  returning: '/returning',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password'
}

const ContentLibrary = {
  main: '/content',
  photos: '/content/photos',
  stories: '/content/stories'
}

const PostTemplates = {
  all: '/post-templates'
}

const App = {
  auth: Auth,
  home: '/',
  myPosts: '/posts',
  scheduled: '/scheduled',
  calendar: '/calendar',
  posteditor: '/editor',
  plan: '/plan',
  captions: '/captions',
  gallery: '/content/photos',
  myDesigns: '/my-designs',
  quickstart: '/quickstart',
  productTest: '/test',
  connect: '/connect',
  ...Photos,
  ...Stories,
  ...PhotoSets,
  ...ActionPlans,
  assets: Assets,
  postTemplates: PostTemplates,
  contentLibrary: ContentLibrary,
  tasks: Tasks,
  workspaces: Workspaces,
  community: '/community',
  chat: '/chat',
  create: '/create',
  referrals: '/referrals',
  referral: '/a/:affiliateId',
  old: Old,
  affiliate: '/a/:affiliateId',
  affiliates: '/affiliates',
  page: Page,
  linkPage: LinkPage,
  checkout: '/checkout',
  pro: '/pro',
  onboarding: '/onboarding',
  receipt: '/checkout/receipt',
  notLoggedIn: '/not-logged-in',
  fiveHundred: '/500',
  diagnostics: '/diagnostics',
  linkedIn: '/oauth/linkedin',
  designer: '/designer',
  claim: '/claim',
  academy: Academy,
  settings: Settings,
  external: External,
  cloud: Cloud,
  uploads: Uploads,
  mobile: Mobile,
  admin: Admin,
  resourceCenter: ResourceCenter
}

export const ROUTES = {
  ...App
}
