import styled from 'styled-components'

const StyledCalendarMonthButton = styled.div`
  display: flex;
  align-items: center;

  button {
    display: flex;
    height: 32px;
    border-radius: 50rem;
    align-items: center;
    margin-left: 10px;
    font-size: 20px;
    font-weight: 600;
    color: ${({ theme }) => theme.color.gray[800]};
    background-color: ${({ theme }) => theme.color.gray[200]};
    font-family: ${({ theme }) => theme.typeface.garnett};
  }

  .month-btn {
    padding: 0 10px;

    svg {
      height: 18px;
      stroke-width: 2.75px;
      margin-left: 6px;
    }
  }

  .today-btn {
    background-color: transparent;
    margin-left: 7px;

    &[data-active='true'] {
      color: ${({ theme }) => theme.color.gray[400]};
    }

    svg {
      height: 32px;
    }
  }
`

export default StyledCalendarMonthButton
