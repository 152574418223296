import { ComponentProps, FC, FormEvent, createRef, useState } from 'react'
import { ContentPillarType } from '../../../types'
import { Button, Dialog, EmojiPicker, Input, Textarea } from '../..'
import { StyledPillarDialog } from '.'

type Props = {
  pillar?: Partial<ContentPillarType>
  onConfirm: (pillar: Omit<ContentPillarType, '_id'>) => void
} & ComponentProps<typeof Dialog>

const PillarDialog: FC<Props> = ({ pillar: value, isOpen, onConfirm, onClose, ...rest }) => {
  const emojiBtnRef = createRef<HTMLButtonElement>()
  const [pillar, setPillar] = useState<Partial<ContentPillarType> | undefined>(value)
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)

  const handleConfirm = (e: FormEvent) => {
    e.preventDefault()

    if (!pillar?.icon || !pillar?.content || !pillar?.title) {
      return
    }

    onClose()
    onConfirm(pillar as any)
  }

  return (
    <>
      <Dialog isOpen={isOpen} onClose={onClose} {...rest}>
        <StyledPillarDialog>
          <div className="dialog-header">
            <span className="dialog-title">{!pillar?._id ? 'Create' : 'Edit'} Content Pillar</span>
          </div>
          <form onSubmit={(e) => handleConfirm(e)}>
            <div className="form-inputs">
              <button
                data-has-icon={!!pillar?.icon}
                className="icon-btn"
                type="button"
                onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                ref={emojiBtnRef}
              >
                {pillar?.icon}
                {!pillar?.icon && 'Pick an icon'}
              </button>
              <Input
                value={pillar?.title}
                required
                onChange={(e) => setPillar({ ...pillar, title: e.target.value })}
                size="md"
              />
              <Textarea
                value={pillar?.content}
                required
                onChange={(e) => setPillar({ ...pillar, content: e.target.value })}
              />
            </div>
            <div className="dialog-actions">
              <Button type="button" onClick={() => onClose()}>
                Cancel
              </Button>
              <Button
                variant="emphasis"
                isFullWidth
                disabled={
                  !pillar?.content?.trim()?.length ||
                  !pillar?.title?.trim()?.length ||
                  !pillar?.icon
                }
              >
                Done
              </Button>
            </div>
          </form>
        </StyledPillarDialog>
      </Dialog>
      <EmojiPicker
        buttonRef={emojiBtnRef}
        classToAvoid="icon-btn"
        position="bottom-start"
        isOpen={showEmojiPicker}
        onSelect={(emoji) => setPillar({ ...pillar, icon: emoji })}
        onClose={() => setShowEmojiPicker(false)}
      />
    </>
  )
}

export default PillarDialog
