import styled from 'styled-components'

const StyledConnectionDialogHeader = styled.div`
  padding: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};

  .connections-header-top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .connections-header-title-container {
      display: flex;
      align-items: center;

      .connections-header-title {
        font-size: 22px;
        font-weight: 600;
        font-family: ${({ theme }) => theme.typeface.faktumXCon};
        color: ${({ theme }) => theme.color.gray[800]};
      }
    }

    .connections-header-help-btn {
      display: flex;
      align-items: center;
      text-decoration: none;
      font-size: 15px;
      color: ${({ theme }) => theme.color.gray[500]};

      svg {
        height: 20px;
        margin-left: 4px;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .connections-header-limit-indicator {
    margin-top: 10px;
    display: flex;

    .connections-header-limit-indicator-pill {
      width: 100%;
      max-width: 52px;
      height: 6px;
      border-radius: 50rem;
      overflow: hidden;
      background-color: ${({ theme }) => theme.color.gray[200]};

      .connections-header-limit-indicator-pill-progress {
        height: 6px;
        border-radius: 50rem;
        background-color: ${({ theme }) => theme.color.gray[600]};
      }

      &.instagram .connections-header-limit-indicator-pill-progress {
        background: linear-gradient(
          90deg,
          #406ac9 0%,
          #863ac8 20%,
          #c635ad 40%,
          #f04c5b 60%,
          #ff9f4b 80%,
          #ffc851 100%
        );
      }

      &.facebook .connections-header-limit-indicator-pill-progress {
        background: linear-gradient(90deg, #17aafd 0%, #0165e1 100%);
      }

      &.linkedin .connections-header-limit-indicator-pill-progress {
        background: #007ebb;
      }

      &.twitter .connections-header-limit-indicator-pill-progress {
        background: #1da1f2;
      }

      &.pinterest .connections-header-limit-indicator-pill-progress {
        background: #ca2027;
      }

      &.tiktok .connections-header-limit-indicator-pill-progress {
        background: linear-gradient(90deg, #69c8cf 0%, #ee1d52 100%);
      }

      &:not(:last-child) {
        margin-right: 6px;
      }
    }
  }
`

export default StyledConnectionDialogHeader
