import styled from 'styled-components'

const StyledUserMenuPopover = styled.div`
  width: 220px;
  box-sizing: border-box;

  .user-menu-group {
    display: grid;
    grid-gap: 12px;
    padding: 12px;

    a {
      border-radius: 6px;
      display: flex;
      align-items: center;
      text-decoration: none;
      transition: background-color 0.2s, color 0.2s;
      color: ${({ theme }) => theme.color.gray[700]};
      padding: 0 6px;
      height: 32px;
      margin: -6px;
      font-size: 15px;

      .avatar {
        margin-right: 7px;
        margin-left: -3px;
      }

      span {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .new-flag {
        padding: 3px 6px;
        margin-left: 6px;
        font-size: 11px;
        font-weight: 600;
        letter-spacing: 0.05rem;
        border-radius: 50rem;
        color: white;
        background-color: ${({ theme }) => theme.color.brand};
      }

      svg {
        color: ${({ theme }) => theme.color.gray[600]};
        margin-right: 10px;
        height: 20px;
        stroke-width: 1.7px;
        transition: color 0.2s;
      }

      &.workspace-item {
        opacity: 0.7;
        transition: 0.2s;

        &[aria-selected='true'] {
          opacity: 1;
          color: ${({ theme }) => theme.color.gray[900]};
          box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.gray[200]};

          svg {
            color: ${({ theme }) => theme.color.gray[900]};
          }
        }
      }

      &:hover {
        opacity: 1;
        color: ${({ theme }) => theme.color.gray[900]};
        background-color: ${({ theme }) => theme.color.gray[100]};

        svg {
          color: ${({ theme }) => theme.color.gray[900]};
        }
      }

      &.active {
        color: ${({ theme }) => theme.color.brand};
        background-color: ${({ theme }) => theme.color.brandLight};

        svg {
          color: ${({ theme }) => theme.color.brand};
        }
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
    }
  }
`

export default StyledUserMenuPopover
