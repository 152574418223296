import { FC, createRef, useEffect, useState } from 'react'
import { debounce, groupBy } from 'lodash'
import { useApolloClient } from '@apollo/client'
import { ChevronDownIcon, HashtagIcon } from '@heroicons/react/24/outline'
import { Button, Input, OptionsPopover, Range, ScrollRow } from '../../../..'
import { searchGalleryQuery } from '../../../../../graphql'
import { updatePage, useAppDispatch, useAppSelector } from '../../../../../redux'
import { PhotoType } from '../../../../../types'
import { fontList, linkToSizedImage } from '../../../../../utils'
import { StyledLinkPageStyleTab } from '.'

const LinkPageStyleTab: FC = () => {
  const dispatch = useAppDispatch()
  const apollo = useApolloClient()
  const fontsBtnRef = createRef<HTMLButtonElement>()
  const { page } = useAppSelector((state) => state.page)
  const [suggestedImages, setSuggestedImages] = useState<PhotoType[]>([])
  const [commonFonts, setCommonFonts] = useState<typeof fontList>([])
  const [showFontDropdown, setShowFontDropdown] = useState(false)

  const changeValueDebounced = debounce((value: object, prop: string) => {
    changeValue(value, prop)
  }, 100)

  const changeValue = (value: object, prop: string) => {
    if (!page) {
      return
    }

    if (prop === 'page') {
      dispatch(
        updatePage({
          styles: {
            ...page?.styles,
            page: {
              ...page?.styles?.page,
              ...value
            }
          }
        })
      )
    }

    if (prop === 'avatar') {
      dispatch(
        updatePage({
          styles: {
            ...page?.styles,
            avatar: {
              ...page?.styles?.avatar,
              ...value
            }
          }
        })
      )
    }

    if (prop === 'title') {
      dispatch(
        updatePage({
          styles: {
            ...page?.styles,
            title: {
              ...page?.styles?.title,
              ...value
            }
          }
        })
      )
    }

    if (prop === 'description') {
      dispatch(
        updatePage({
          styles: {
            ...page?.styles,
            description: {
              ...page?.styles?.description,
              ...value
            }
          }
        })
      )
    }

    if (prop === 'link') {
      dispatch(
        updatePage({
          styles: {
            ...page?.styles,
            link: {
              ...page?.styles?.link,
              ...value
            }
          }
        })
      )
    }
  }

  const themes = [
    {
      _id: 'custom',
      label: 'Custom'
    },
    {
      _id: 'pebble-blue',
      label: 'Pebble Blue',
      preview:
        'https://mfe-appearance.production.linktr.ee/images/selector-pebble-blue.48047a34097e85836255.png'
    },
    {
      _id: 'pebble-yellow',
      label: 'Pebble Yellow',
      preview:
        'https://mfe-appearance.production.linktr.ee/images/selector-pebble-yellow.adffcf319fe3cb16a9b7.png'
    },
    {
      _id: 'pebble-pink',
      label: 'Pebble Pink',
      preview:
        'https://mfe-appearance.production.linktr.ee/images/selector-pebble-pink.71c34887a9c4ca41828c.png'
    },
    {
      _id: 'breeze-pink',
      label: 'Breeze Pink',
      preview:
        'https://mfe-appearance.production.linktr.ee/images/selector-breeze-pink.85b6f909ee2f87738c10.webp'
    },
    {
      _id: 'rainbow',
      label: 'Rainbow',
      preview:
        'https://mfe-appearance.production.linktr.ee/images/selector-rainbow.85e9302f9aac535367aa.webp'
    },
    {
      _id: 'confetti',
      label: 'Confetti',
      preview:
        'https://mfe-appearance.production.linktr.ee/images/selector-confetti.3da60ad2f58e6d1f40da.webp'
    }
  ]

  const loadSuggestedImages = async () => {
    const { data } = await apollo.query({
      fetchPolicy: 'no-cache',
      query: searchGalleryQuery,
      variables: {
        search: '',
        orientation: 'vertical',
        sort: 'random',
        categories: [],
        style: [],
        colors: [],
        collection: null,
        liked: false,
        page: 0,
        items: 20
      }
    })

    setSuggestedImages(data?.searchGallery?.docs || [])
  }

  const loadFontList = () => {
    const grouped = groupBy(fontList, 'family')
    const standardFonts = Object.keys(grouped).map((key) => {
      const familyFonts = grouped[key]
      const standardFont = familyFonts.find((familyFont) =>
        familyFont.post_script_name.includes('-Regular')
      )
      if (standardFont) {
        return standardFont
      }
      return familyFonts[familyFonts.length - 1]
    })
    setCommonFonts(standardFonts)
  }

  useEffect(() => {
    loadSuggestedImages()
    loadFontList()
  }, [])

  return (
    <>
      <StyledLinkPageStyleTab>
        {/* <div className="style-group">
          <div className="style-group-heading">
            <span className="style-header">Pre-made Themes</span>
            <span className="style-subtext">
              Choose from one of our done-for-you Link Page themes.
            </span>
          </div>
          <ScrollRow>
            {themes.map(theme => {
              return (
                <div className="theme-block-container" key={theme._id}>
                  <div className="theme-block">
                    {theme.preview && <img src={theme.preview} alt={theme.label} />}
                  </div>
                  <span className="theme-block-label">{theme.label}</span>
                </div>
              )
            })}
          </ScrollRow>
        </div> */}
        <div className="style-group">
          <div className="style-group-heading">
            <span className="style-header">Background</span>
            <span className="style-subtext">
              Style your Link Page with a custom background color or image.
            </span>
          </div>
          <ScrollRow>
            {suggestedImages.map((image) => {
              return (
                <div
                  className="suggested-image-block"
                  onClick={() =>
                    changeValue({ backgroundColor: `url(${image.file.fileHttpLink})` }, 'page')
                  }
                  key={image._id}
                >
                  <img
                    src={linkToSizedImage(image.file.fileHttpLink || '', { width: 200 })}
                    alt=""
                  />
                </div>
              )
            })}
          </ScrollRow>
          <div className="style-group-content">
            <div />
            <label htmlFor="background-color" className="style-group-color-input-container">
              <span className="style-group-color-input-label">
                Background{' '}
                {page?.styles?.page?.backgroundColor?.includes('url(') ? 'Image' : 'Color'}
              </span>
              <div className="style-group-color-input">
                <div
                  className="style-group-color-input-color"
                  style={{ background: page?.styles?.page?.backgroundColor }}
                >
                  <input
                    value={page?.styles?.page?.backgroundColor}
                    type="color"
                    id="background-color"
                    onChange={(e) =>
                      changeValueDebounced({ backgroundColor: e.target.value }, 'page')
                    }
                  />
                </div>
                {page?.styles?.page?.backgroundColor?.includes('url(') && (
                  <Button onClick={(e) => changeValue({ backgroundColor: '#fff' }, 'page')}>
                    Remove Image
                  </Button>
                )}
                {!page?.styles?.page?.backgroundColor?.includes('url(') && (
                  <Input
                    icon={<HashtagIcon />}
                    placeholder="Color"
                    variant="fill"
                    value={page?.styles?.page?.backgroundColor?.replace('#', '')}
                    onChange={(e) =>
                      changeValue(
                        { backgroundColor: `#${e.target.value.replace('#', '')}` },
                        'page'
                      )
                    }
                    className="style-group-color-input-hex"
                  />
                )}
              </div>
            </label>
          </div>
        </div>
        <div className="style-group">
          <div className="style-group-heading">
            <span className="style-header">Buttons</span>
            <span className="style-subtext">
              Give your buttons a unique flair by personalizing their style and color.
            </span>
          </div>
          <div className="style-group-content">
            <label className="style-group-color-input-container choose-container">
              <div className="form-input-choose">
                <button
                  className="form-input-choose-btn"
                  aria-selected={page?.styles?.link?.borderRadius === 0}
                  onClick={() => changeValue({ borderRadius: 0 }, 'link')}
                >
                  <div className="link-option-preview no-radius" />
                </button>
                <button
                  className="form-input-choose-btn"
                  aria-selected={page?.styles?.link?.borderRadius === 6}
                  onClick={() => changeValue({ borderRadius: 6 }, 'link')}
                >
                  <div className="link-option-preview low-radius" />
                </button>
                <button
                  className="form-input-choose-btn"
                  aria-selected={page?.styles?.link?.borderRadius === null}
                  onClick={() => changeValue({ borderRadius: null }, 'link')}
                >
                  <div className="link-option-preview med-radius" />
                </button>
                <button
                  className="form-input-choose-btn"
                  aria-selected={page?.styles?.link?.borderRadius === 25}
                  onClick={() => changeValue({ borderRadius: 25 }, 'link')}
                >
                  <div className="link-option-preview high-radius" />
                </button>
                <button
                  className="form-input-choose-btn"
                  aria-selected={page?.styles?.link?.borderRadius === 100}
                  onClick={() => changeValue({ borderRadius: 100 }, 'link')}
                >
                  <div className="link-option-preview circle-radius" />
                </button>
              </div>
            </label>
            <label htmlFor="button-color" className="style-group-color-input-container">
              <span className="style-group-color-input-label">Button Color</span>
              <div className="style-group-color-input">
                <div
                  className="style-group-color-input-color"
                  style={{ background: page?.styles?.link?.backgroundColor }}
                >
                  <input
                    value={page?.styles?.link?.backgroundColor}
                    type="color"
                    id="button-color"
                    onChange={(e) =>
                      changeValueDebounced({ backgroundColor: e.target.value }, 'link')
                    }
                  />
                </div>
                <Input
                  icon={<HashtagIcon />}
                  placeholder="Color"
                  variant="fill"
                  value={page?.styles?.link?.backgroundColor?.replace('#', '')}
                  onChange={(e) =>
                    changeValue({ backgroundColor: `#${e.target.value.replace('#', '')}` }, 'link')
                  }
                  className="style-group-color-input-hex"
                />
              </div>
            </label>
            <label htmlFor="button-text-color" className="style-group-color-input-container">
              <span className="style-group-color-input-label">Button Text Color</span>
              <div className="style-group-color-input">
                <div
                  className="style-group-color-input-color"
                  style={{ background: page?.styles?.link?.color }}
                >
                  <input
                    value={page?.styles?.link?.color}
                    type="color"
                    id="button-text-color"
                    onChange={(e) => changeValueDebounced({ color: e.target.value }, 'link')}
                  />
                </div>
                <Input
                  icon={<HashtagIcon />}
                  placeholder="Color"
                  variant="fill"
                  value={page?.styles?.link?.color?.replace('#', '')}
                  onChange={(e) =>
                    changeValue({ color: `#${e.target.value.replace('#', '')}` }, 'link')
                  }
                  className="style-group-color-input-hex"
                />
              </div>
            </label>
          </div>
        </div>
        <div className="style-group">
          <div className="style-group-heading">
            <span className="style-header">Font</span>
            <span className="style-subtext">
              Choose a font and pick a color to represent you on your Link Page.
            </span>
          </div>
          <div className="style-group-content">
            <label className="style-group-color-input-container">
              <span className="style-group-color-input-label">Font Family</span>
              <div className="style-group-color-input">
                <Button
                  variant="outline"
                  iconPos="right"
                  className="font-select-btn"
                  ref={fontsBtnRef}
                  onClick={() => setShowFontDropdown(!showFontDropdown)}
                >
                  <span>{page?.styles?.page?.fontFamily || 'Pick Font'}</span>
                  <ChevronDownIcon />
                </Button>
              </div>
            </label>
            <label htmlFor="font-color" className="style-group-color-input-container">
              <span className="style-group-color-input-label">Font Color</span>
              <div className="style-group-color-input">
                <div
                  className="style-group-color-input-color"
                  style={{ background: page?.styles?.description?.color }}
                >
                  <input
                    value={page?.styles?.description?.color}
                    type="color"
                    id="font-color"
                    onChange={(e) => changeValueDebounced({ color: e.target.value }, 'description')}
                  />
                </div>
                <Input
                  icon={<HashtagIcon />}
                  placeholder="Color"
                  variant="fill"
                  value={page?.styles?.description?.color?.replace('#', '')}
                  onChange={(e) =>
                    changeValue({ color: `#${e.target.value.replace('#', '')}` }, 'description')
                  }
                  className="style-group-color-input-hex"
                />
              </div>
            </label>
          </div>
        </div>
        <div className="style-group">
          <div className="style-group-heading">
            <span className="style-header">Photo</span>
            <span className="style-subtext">
              Customize how others see your photo on your Link Page.
            </span>
          </div>
          <div className="style-group-content">
            <label className="style-group-color-input-container choose-container">
              <div className="form-input-choose">
                <button
                  className="form-input-choose-btn"
                  aria-selected={page?.styles?.avatar?.borderRadius === 0}
                  onClick={() => changeValue({ borderRadius: 0 }, 'avatar')}
                >
                  <div className="photo-option-preview no-radius" />
                </button>
                <button
                  className="form-input-choose-btn"
                  aria-selected={page?.styles?.avatar?.borderRadius === 6}
                  onClick={() => changeValue({ borderRadius: 6 }, 'avatar')}
                >
                  <div className="photo-option-preview low-radius" />
                </button>
                <button
                  className="form-input-choose-btn"
                  aria-selected={page?.styles?.avatar?.borderRadius === 14}
                  onClick={() => changeValue({ borderRadius: 14 }, 'avatar')}
                >
                  <div className="photo-option-preview med-radius" />
                </button>
                <button
                  className="form-input-choose-btn"
                  aria-selected={page?.styles?.avatar?.borderRadius === 25}
                  onClick={() => changeValue({ borderRadius: 25 }, 'avatar')}
                >
                  <div className="photo-option-preview high-radius" />
                </button>
                <button
                  className="form-input-choose-btn"
                  aria-selected={page?.styles?.avatar?.borderRadius === null}
                  onClick={() => changeValue({ borderRadius: null }, 'avatar')}
                >
                  <div className="photo-option-preview circle-radius" />
                </button>
              </div>
            </label>
            <label className="style-group-color-input-container range-container">
              <span className="style-group-color-input-label">Photo Border Width</span>
              <Range
                value={page?.styles?.avatar?.borderWidth || 0}
                min={0}
                max={10}
                onChange={(value) => changeValue({ borderWidth: value }, 'avatar')}
              />
            </label>
            <label htmlFor="photo-border-color" className="style-group-color-input-container">
              <span className="style-group-color-input-label">Photo Border Color</span>
              <div className="style-group-color-input">
                <div
                  className="style-group-color-input-color"
                  style={{ background: page?.styles?.avatar?.borderColor }}
                >
                  <input
                    value={page?.styles?.avatar?.borderColor}
                    type="color"
                    id="photo-border-color"
                    onChange={(e) =>
                      changeValueDebounced({ borderColor: e.target.value }, 'avatar')
                    }
                  />
                </div>
                <Input
                  icon={<HashtagIcon />}
                  placeholder="Color"
                  variant="fill"
                  value={page?.styles?.avatar?.borderColor?.replace('#', '')}
                  onChange={(e) =>
                    changeValue({ borderColor: `#${e.target.value.replace('#', '')}` }, 'avatar')
                  }
                  className="style-group-color-input-hex"
                />
              </div>
            </label>
          </div>
        </div>
      </StyledLinkPageStyleTab>
      <OptionsPopover
        isOpen={showFontDropdown}
        buttonRef={fontsBtnRef}
        classToAvoid={'font-select-btn'}
        height={400}
        position="top-left"
        width={300}
        options={commonFonts
          .sort((a, b) => a.family.localeCompare(b.family))
          .map((font) => ({
            label: font.family,
            render: <img src={font.preview} style={{ height: 30 }} />,
            isSelected: page?.styles?.page?.fontFamily === font.url,
            action: () => changeValue({ fontFamily: font.family }, 'page')
          }))}
        isSearchable
        onClose={() => setShowFontDropdown(false)}
      />
    </>
  )
}

export default LinkPageStyleTab
