import styled from 'styled-components'

type StyledProps = {
  snap: number
}

const StyledChatHistorySheet = styled.div<StyledProps>`
  padding: 0 20px var(--bottom-spacing) 20px;
  box-sizing: border-box;
`

export default StyledChatHistorySheet
