import { FC, createRef, useContext, useState } from 'react'
import { startCase } from 'lodash'
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { FocusIcon } from '../../../assets'
import { removePlanDay, updatePlanDay, useAppDispatch } from '../../../redux'
import { ContentPillarType } from '../../../types'
import { userStore } from '../../../utils'
import { OptionsPopover } from '../../popovers'
import { StyledPlanPostListItem } from '.'

type Props = {
  day: {
    _id: string
    pillar: ContentPillarType
    focus: 'engagement' | 'education' | 'sales'
  }
  index: number
}

const PlanPostListItem: FC<Props> = ({ day, index }) => {
  const dispatch = useAppDispatch()
  const pillarBtnRef = createRef<HTMLButtonElement>()
  const focusBtnRef = createRef<HTMLButtonElement>()
  const [showPillarOptions, setShowPillarOptions] = useState(false)
  const [showFocusOptions, setShowFocusOptions] = useState(false)
  const { user } = useContext(userStore)

  return (
    <>
      <StyledPlanPostListItem>
        <div className="post-label-container">
          <span className="post-label">POST</span>
          <span className="post-index">{index + 1}</span>
        </div>
        <button
          className="dropdown pillar-dropdown"
          data-expanded={showPillarOptions}
          onClick={() => setShowPillarOptions(!showPillarOptions)}
          ref={pillarBtnRef}
        >
          <div className="dropdown-label">
            <div className="pillar-icon">{day?.pillar?.icon}</div>
            <span className="pillar-name">{day?.pillar?.title}</span>
          </div>
          <ChevronDownIcon className="chevron-icon" />
        </button>
        <button
          className="dropdown focus-dropdown"
          data-expanded={showFocusOptions}
          onClick={() => setShowFocusOptions(!showFocusOptions)}
          ref={focusBtnRef}
        >
          <div className="dropdown-label">
            <FocusIcon className="focus-icon" />
            <span>{startCase(day.focus)}</span>
          </div>
          <ChevronDownIcon className="chevron-icon" />
        </button>
        <button className="delete-btn" onClick={() => dispatch(removePlanDay(day._id))}>
          <XMarkIcon />
        </button>
      </StyledPlanPostListItem>
      <OptionsPopover
        options={(user?.contentPillars || [])?.map((pillar) => ({
          icon: <span style={{ marginRight: 10 }}>{pillar.icon}</span>,
          label: pillar.title,
          isSelected: pillar._id === day.pillar._id,
          action: () => dispatch(updatePlanDay({ _id: day._id, day: { pillar } }))
        }))}
        width={300}
        position="bottom-left"
        buttonRef={pillarBtnRef}
        classToAvoid="pillar-dropdown"
        isOpen={showPillarOptions}
        onClose={() => setShowPillarOptions(false)}
      />
      <OptionsPopover
        options={['engagement', 'education', 'sales'].map((focus: any) => ({
          icon: <FocusIcon />,
          label: startCase(focus),
          isSelected: focus === day.focus,
          action: () => dispatch(updatePlanDay({ _id: day._id, day: { focus } }))
        }))}
        position="bottom-left"
        buttonRef={focusBtnRef}
        classToAvoid="focus-dropdown"
        isOpen={showFocusOptions}
        onClose={() => setShowFocusOptions(false)}
      />
    </>
  )
}

export default PlanPostListItem
