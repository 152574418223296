import { FC, FormEvent, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { toast } from 'react-hot-toast'
import { Link } from 'react-router-dom'
import { EyeIcon, EyeSlashIcon, LockClosedIcon } from '@heroicons/react/20/solid'
import {
  ArrowLeftIcon,
  CheckIcon,
  ChevronRightIcon,
  PlusIcon,
  XMarkIcon
} from '@heroicons/react/24/outline'
import { ReactComponent as SCLogoIcon } from '../../assets/sc-logo-icon.svg'
import { AuthLayout, CardInput } from '../../components'
import { clearCoupon, setCoupon, useAppDispatch, useAppSelector } from '../../redux'
import {
  ROUTES,
  conditionalStage,
  getCoupon,
  getCouponValue,
  getPlanPrice,
  handleEnterToNextInput,
  useRegister
} from '../../utils'
import { StyledRegisterView } from '.'

const RegisterView: FC = () => {
  const dispatch = useAppDispatch()
  const [couponInput, setCouponInput] = useState('')
  const [showCouponInput, setShowCouponInput] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState<'info' | 'payment'>('info')
  const { coupon } = useAppSelector((state) => state.state)
  const { form, params, updateFormValue, getTotal, register } = useRegister()

  const handleSubmit = async (e?: FormEvent) => {
    try {
      e?.preventDefault()
      setLoading(true)

      await register()
    } catch (err) {
      console.error(err)
      toast.error((err as any)?.message)
      setLoading(false)
    }
  }

  const handleStep = (e?: FormEvent) => {
    e?.preventDefault()

    if (step === 'info' && form.plan === 'trial') {
      return handleSubmit()
    }

    setStep(step === 'info' ? 'payment' : 'info')
  }

  const getSavePrice = () => {
    const yearPrice = getPlanPrice('year')
    const monthPrice = getPlanPrice('month')
    const yearDiscount = getCouponValue('year', coupon)
    const monthDiscount = getCouponValue('month', coupon)

    return Math.abs(yearPrice - yearDiscount - (monthPrice - monthDiscount) * 12)
  }

  const getPlanPriceText = (plan: 'month' | 'year') => {
    const price = getPlanPrice(plan)
    const discount = price - getCouponValue(plan, coupon)
    const adjustedPrice = Math.ceil(price / (plan === 'year' ? 12 : 1))
    const adjustedDiscount = Math.ceil(discount / (plan === 'year' ? 12 : 1))

    if (!!coupon?.discount[plan]) {
      return (
        <>
          <b>${adjustedDiscount}</b>
          <s>${adjustedPrice}</s> / month
        </>
      )
    }

    return <>${adjustedPrice} / month</>
  }

  const addCouponCode = () => {
    const coupon = getCoupon(couponInput)

    if (!!couponInput.trim() && !coupon) {
      return
    }

    dispatch(setCoupon(coupon))
    setCouponInput('')
    setShowCouponInput(false)
  }

  const removeCouponCode = () => {
    dispatch(clearCoupon())
    setCouponInput('')
    setShowCouponInput(false)
    toast.success('Removed coupon code')
  }

  useEffect(() => {
    if (form.plan === 'trial' && step === 'payment') {
      handleSubmit()
    }
  }, [form.plan])

  return (
    <AuthLayout>
      <Helmet title="Join | Social Curator" />
      <StyledRegisterView>
        <div className="auth-screen" data-screen="info" data-active={step === 'info'}>
          <div className="auth-header">
            <h1>Join Social Curator</h1>
            <h2>You’re 2 minutes away from your first post</h2>
          </div>
          <form autoComplete="on" onSubmit={(e) => handleStep(e)}>
            <div className="auth-inputs">
              <label htmlFor="fullName" className="input-container">
                <div className="input-label">Full name</div>
                <input
                  id="fullName"
                  value={form.fullName}
                  autoFocus={true}
                  placeholder="Jasmine Star"
                  autoCapitalize="words"
                  minLength={1}
                  required
                  type="text"
                  name="fullname"
                  enterKeyHint="next"
                  onKeyDown={(e) => handleEnterToNextInput(e)}
                  onChange={(e) => updateFormValue('fullName', e.target.value)}
                />
              </label>
              <label htmlFor="email" className="input-container">
                <div className="input-label">Email address</div>
                <input
                  id="email"
                  value={form.email}
                  placeholder="hello@socialcurator.com"
                  required
                  type="email"
                  name="email"
                  enterKeyHint="next"
                  onKeyDown={(e) => handleEnterToNextInput(e)}
                  onChange={(e) => updateFormValue('email', e.target.value)}
                />
              </label>
              <label htmlFor="password" className="input-container">
                <div className="input-label">
                  Create a password
                  <button
                    className="show-password-btn"
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? 'Hide' : 'Show'} password
                    {showPassword ? <EyeSlashIcon /> : <EyeIcon />}
                  </button>
                </div>
                <input
                  id="password"
                  value={form.password}
                  required
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  enterKeyHint="go"
                  onChange={(e) => updateFormValue('password', e.target.value)}
                  onKeyDown={(e) => e.key === 'Enter' && handleStep()}
                />
              </label>
            </div>
            <button type="submit" className="submit-btn" disabled={loading}>
              {!loading && form.plan === 'trial' && 'Start 14 day free trial'}
              {!loading && form.plan !== 'trial' && 'Continue'}
              {loading && <SCLogoIcon className="logo-spinner" />}
            </button>
          </form>
          <div className="form-subtext">
            Already have an account?{' '}
            <Link to={{ pathname: ROUTES.auth.login, state: { ...params } }}>Log in</Link>
          </div>
        </div>
        <div className="auth-screen" data-screen="payment" data-active={step === 'payment'}>
          <div className="auth-header-row">
            <button className="auth-header-btn" disabled={loading} onClick={() => handleStep()}>
              <ArrowLeftIcon />
              <div className="auth-header-btn-label">
                <span className="default-label">Pick a plan</span>
                <span className="reveal-label" aria-hidden={true}>
                  Go back
                </span>
              </div>
            </button>
            {/* {!coupon && (
              <button
                className="trial-btn"
                disabled={loading}
                onClick={() => updateFormValue('plan', 'trial')}
              >
                <span className="desktop">Continue with 7-day free trial</span>
                <span className="mobile">Free trial</span> <ChevronRightIcon />
              </button>
            )} */}
          </div>

          <form onSubmit={(e) => handleSubmit(e)}>
            <div
              className="auth-inputs"
              {...conditionalStage(loading, { style: { display: 'none' } })}
            >
              <div className="input-container">
                <div className="input-label">Billing cycle</div>
                <div className="billing-cycle-select">
                  <button
                    className="billing-cycle-select-option"
                    aria-selected={form.plan === 'year'}
                    type="button"
                    onClick={() => updateFormValue('plan', 'year')}
                  >
                    <div className="billing-cycle-select-radio" />
                    <div className="billing-cycle-select-details">
                      <div className="billing-cycle-select-title">Billed Annually</div>
                      <div className="billing-cycle-select-price">{getPlanPriceText('year')}</div>
                    </div>
                    <div className="billing-cycle-select-tag">SAVE ${getSavePrice()}</div>
                  </button>
                  <button
                    className="billing-cycle-select-option"
                    aria-selected={form.plan === 'month'}
                    type="button"
                    onClick={() => updateFormValue('plan', 'month')}
                  >
                    <div className="billing-cycle-select-radio" />
                    <div className="billing-cycle-select-details">
                      <div className="billing-cycle-select-title">Billed Monthly</div>
                      <div className="billing-cycle-select-price">{getPlanPriceText('month')}</div>
                    </div>
                  </button>
                </div>
              </div>
              <div className="input-container">
                <div className="input-label">
                  Payment information
                  <div className="input-label-subtext">
                    Secured by
                    <a href="https://stripe.com/privacy" target="_blank" rel="noreferrer">
                      Stripe
                    </a>
                    <LockClosedIcon />
                  </div>
                </div>
                <CardInput
                  onError={() => updateFormValue('cardReady', false)}
                  onComplete={() => updateFormValue('cardReady', true)}
                />
              </div>
              <div className="input-container">
                <div className="input-label">Order summary</div>
                <div className="order-summary">
                  <div className="order-rows">
                    <div className="order-row">
                      <span className="order-row-label">
                        Pro Plan ({form.plan === 'year' ? 'Annual' : 'Monthly'})
                      </span>
                      <span className="order-row-price">${getPlanPrice(form.plan)}</span>
                    </div>
                    <div className="order-row">
                      {showCouponInput && (
                        <div className="add-code-input-container">
                          <input
                            className="add-code-input"
                            autoFocus
                            placeholder="Type coupon code"
                            value={couponInput}
                            onChange={(e) => setCouponInput(e.target.value?.trim()?.toUpperCase())}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault()
                                e.stopPropagation()
                                addCouponCode()
                              }
                            }}
                          />
                          <button
                            className="add-code-apply-btn"
                            type="button"
                            onClick={() => addCouponCode()}
                          >
                            Apply Coupon
                          </button>
                        </div>
                      )}
                      {!showCouponInput && !coupon && (
                        <button
                          className="add-code-btn"
                          type="button"
                          onClick={() => setShowCouponInput(true)}
                        >
                          <PlusIcon /> Add coupon code
                        </button>
                      )}
                      {!showCouponInput && !!coupon && (
                        <>
                          <span className="order-row-label coupon">
                            {coupon.code}
                            <button
                              className="coupon-remove-btn"
                              type="button"
                              onClick={() => removeCouponCode()}
                            >
                              <XMarkIcon />
                            </button>
                          </span>
                          <span className="order-row-price coupon">
                            -${getCouponValue(form.plan, coupon)}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="order-total">
                    <span className="order-total-label">Due today</span>
                    <span className="order-total-price">${getTotal()}</span>
                  </div>
                </div>
              </div>
              <label className="checkbox-container">
                <button
                  className="checkbox"
                  type="button"
                  aria-checked={form.agreedToTerms}
                  onClick={() => updateFormValue('agreedToTerms', !form.agreedToTerms)}
                >
                  {form.agreedToTerms && <CheckIcon />}
                </button>
                <span className="checkbox-label">
                  I agree to the{' '}
                  <a href={ROUTES.external.termsOfServiceUrl} target="_blank" rel="noreferrer">
                    Terms of Service
                  </a>{' '}
                  and{' '}
                  <a href={ROUTES.external.privacyPolicyUrl} target="_blank" rel="noreferrer">
                    Privacy Policy
                  </a>
                  .
                </span>
              </label>
            </div>
            <button
              type="submit"
              className="submit-btn"
              style={{ marginTop: loading ? 0 : 35 }}
              data-grayed={!form.cardReady || !form.agreedToTerms}
              disabled={loading || !form.cardReady || !form.agreedToTerms}
            >
              {!loading && 'Complete Purchase'}
              {loading && <SCLogoIcon className="logo-spinner" />}
            </button>
          </form>
        </div>
      </StyledRegisterView>
    </AuthLayout>
  )
}

export default RegisterView
