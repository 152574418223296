import styled from 'styled-components'

const StyledWorkspaceInviteDialog = styled.div`
  padding: 40px;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  width: 500px;
  user-select: none;

  .workspace-invite-details {
    .workspace-invite-icon {
      height: 65px;
      width: 65px;
      margin: 0 auto 20px auto;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      color: ${({ theme }) => theme.color.brand};
      background-color: ${({ theme }) => theme.color.brandLight};

      svg {
        height: 30px;
      }
    }

    .workspace-invite-heading {
      display: block;
      font-weight: 500;
      max-width: 340px;
      margin: 0 auto;
      font-size: 24px;
      color: ${({ theme }) => theme.color.gray[800]};
      text-align: center;
      font-family: ${({ theme }) => theme.typeface.polySans};
    }

    .workspace-invite-subtext {
      display: block;
      max-width: 300px;
      line-height: 24px;
      text-align: center;
      margin: 16px auto 0 auto;
      color: ${({ theme }) => theme.color.gray[700]};
    }
  }

  .workspace-invite-input {
    display: flex;
    margin-top: 40px;

    .input {
      margin-right: 14px;
      width: 100%;
    }

    button {
      flex-shrink: 0;
    }
  }
`

export default StyledWorkspaceInviteDialog
