import { FC } from 'react'
import { Helmet } from 'react-helmet'
import { Capacitor } from '@capacitor/core'
import {
  CurrentPlanCard,
  InvoiceHistoryTable,
  MobilePlanDisclaimer,
  PageLayout,
  PaymentMethodCard,
  TrialCta
} from '../../components'
import { StyledBillingView } from '.'

const BillingView: FC = () => {
  return (
    <PageLayout maxWidth={800}>
      <Helmet title="Billing | Social Curator" />
      <StyledBillingView>
        <div className="billing-view-header">
          <h1>Billing</h1>
          <h2>Manage your plan & payment details</h2>
        </div>
        <TrialCta />
        <div
          className="billing-cards"
          style={{ pointerEvents: Capacitor.isNativePlatform() ? 'none' : 'all' }}
        >
          <CurrentPlanCard />
          <PaymentMethodCard />
        </div>
        {Capacitor.isNativePlatform() && <MobilePlanDisclaimer />}
        {!Capacitor.isNativePlatform() && <InvoiceHistoryTable />}
      </StyledBillingView>
    </PageLayout>
  )
}

export default BillingView
