import { FC, useContext, useEffect, useState } from 'react'
import emoji from 'react-easy-emoji'
import { sample, times } from 'lodash'
import { DateTime } from 'luxon'
import { EffectCards } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { v4 as uuid } from 'uuid'
import { useApolloClient } from '@apollo/client'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { chatGetSuggestions, chatSendMessage } from '../../../graphql'
import {
  addChatMessages,
  setShowEducationalOnboarding,
  useAppDispatch,
  useAppSelector
} from '../../../redux'
import { useChat, useMesh, userStore } from '../../../utils'
import { ChatBotIcon, InputDialog, Skeleton, Spinner } from '../..'
import { StyledChatOnboarding } from '.'

const ChatOnboarding: FC = () => {
  useMesh()
  const apollo = useApolloClient()
  const dispatch = useAppDispatch()
  const { user } = useContext(userStore)
  const { highlightMessage } = useChat()
  const { conversation, messages } = useAppSelector((state) => state.chat)
  const { showEducationalOnboarding } = useAppSelector((state) => state.state)
  const [suggestions, setSuggestions] = useState<string[]>([])
  const [prompt, setPrompt] = useState('')
  const [promptInput, setPromptInput] = useState('')
  const [reply, setReply] = useState('')
  const [showPromptDialog, setShowPromptDialog] = useState(false)
  const [step, setStep] = useState(0)

  const getSuggestions = async () => {
    if (!!suggestions.length) {
      return
    }

    const { data } = await apollo.query({
      fetchPolicy: 'no-cache',
      query: chatGetSuggestions
    })

    setSuggestions(data?.chatGetSuggestions || [])
  }

  const sendMessage = async (type: 'reply' | 'edit') => {
    if (!!reply?.trim()) {
      return
    }

    const { data } = await apollo.mutate({
      fetchPolicy: 'no-cache',
      mutation: chatSendMessage,
      variables: {
        content: prompt,
        conversation,
        template: 'caption',
        date: DateTime.now().toISO()
      }
    })

    const message = data?.chatSendMessage

    if (type === 'reply') {
      setReply(message?.content)
      dispatch(
        addChatMessages([
          {
            _id: uuid(),
            content: prompt,
            conversation,
            template: 'caption',
            status: 'success',
            createdAt: DateTime.now().toISO(),
            type: 'sent'
          },
          message
        ])
      )
      return
    }
  }

  useEffect(() => {
    if (step === 1) {
      getSuggestions()
    }
    if (step === 2) {
      sendMessage('reply')
    }
  }, [step])

  useEffect(() => {
    if (!!prompt.trim()) {
      setStep(2)
    }
  }, [prompt])

  // useEffect(() => {
  //   if (!showEducationalOnboarding) {
  //     return
  //   }

  //   const hasInput = !!input.trim()
  //   const hasTemplate = !!template
  //   const hasMessages = !!messages.length

  //   if (!hasInput && !hasTemplate && !hasMessages) {
  //     setStep(0)
  //     return
  //   }

  //   if ((hasInput || hasTemplate) && !hasMessages) {
  //     setStep(1)
  //     return
  //   }

  //   if (hasMessages && messages.length < 3) {
  //     setStep(2)
  //     return
  //   }

  //   if (hasMessages && messages.length > 2) {
  //     setStep(3)
  //     return
  //   }
  // }, [showEducationalOnboarding, input, template, messages])

  if (showEducationalOnboarding === false) {
    return null
  }

  return (
    <>
      <StyledChatOnboarding fullScreen={true}>
        {step === 0 && (
          <div className="intro-text">
            {/* <div className="dottie-icon-intro">
          <ChatBotIcon size={55} />
        </div> */}
            <div className="dottie-cards">
              <Swiper
                effect={'cards'}
                grabCursor={true}
                modules={[EffectCards]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div className="slide-img">
                    <img src="/images/tailored-posts.png" alt="" />
                  </div>
                  <span className="slide-label">
                    Social media content tailored for{' '}
                    {user?.aiOnboarding?.occupation?.toLowerCase() || 'companie'}s
                  </span>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="slide-img">
                    <img src="/images/request-changes.png" alt="" />
                  </div>
                  <span className="slide-label">
                    Collaborate by requesting changes and making edits
                  </span>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="slide-img">
                    <img src="/images/repurpose-content.png" alt="" />
                  </div>
                  <span className="slide-label">Repurpose social media content and save time</span>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="slide-img">
                    <img src="/images/schedule-and-publish.png" alt="" />
                  </div>
                  <span className="slide-label">Schedule and publish posts directly to social</span>
                </SwiperSlide>
              </Swiper>
            </div>
            <h1>Ready when you are</h1>
            <h2>
              See how I can help {user?.aiOnboarding?.occupation || 'companie'}s create social media
              posts and more.
            </h2>
            <div className="button-container">
              <button
                className="skip-btn"
                onClick={() => dispatch(setShowEducationalOnboarding(false))}
              >
                Skip
              </button>
              <button onClick={() => setStep(1)}>Create a post</button>
            </div>
          </div>
        )}
        {step === 1 && (
          <div className="intro-text">
            <div className="dottie-icon-intro">
              <ChatBotIcon size={55} />
            </div>
            <h1>What's your post about?</h1>
            <div className="question-content grid">
              {suggestions.map((suggestion) => {
                return (
                  <button
                    className="question-option-btn"
                    key={suggestion}
                    onClick={() => setPrompt(suggestion)}
                  >
                    {emoji(suggestion)}
                  </button>
                )
              })}
              {!suggestions.length &&
                times(3, (index) => {
                  return <Skeleton randHeight={[37.5, 37.5]} key={index} />
                })}
              <div className="question-content-group">
                <button className="question-option-btn" onClick={() => setShowPromptDialog(true)}>
                  {emoji('✏️ Custom prompt')}
                </button>
                <button
                  className="question-option-btn surprise-btn"
                  onClick={() => setPrompt(sample(suggestions) || '')}
                >
                  {emoji('✨ Surprise me')}
                </button>
              </div>
            </div>
          </div>
        )}
        {step === 2 && (
          <div className="intro-text">
            <div className="dottie-icon-intro">
              <ChatBotIcon size={55} />
            </div>
            <h1>
              {!reply && 'Working on it...'}
              {reply && "Here's your caption:"}
            </h1>
            <div className="message-content">
              {reply && <span>{reply}</span>}
              {!reply && <Spinner />}
            </div>
            {reply && (
              <div className="whats-next">
                <span className="label">What's Next?</span>
                <ul>
                  <li>Ask Dottie to make changes</li>
                  <li>Remix into a video script, photo, etc.</li>
                  <li>Schedule to social media</li>
                </ul>
              </div>
            )}
            <div className="button-container">
              <button
                disabled={!reply}
                onClick={() => {
                  dispatch(setShowEducationalOnboarding(false))
                  highlightMessage(messages[messages.length - 1]._id)
                }}
              >
                Let's get started
              </button>
            </div>
          </div>
        )}

        <canvas id="gradient-canvas" />
        {step !== 0 && (
          <button
            className="close-btn"
            onClick={() => dispatch(setShowEducationalOnboarding(false))}
          >
            <XMarkIcon />
          </button>
        )}
      </StyledChatOnboarding>
      <InputDialog
        isOpen={showPromptDialog}
        onChange={(value) => setPromptInput(value)}
        onClose={() => setShowPromptDialog(false)}
        onConfirm={() => setPrompt(promptInput)}
        title="Use a custom prompt"
        value={promptInput}
        confirmButtonText="Generate caption"
      />
    </>
  )
}

// {step === 3 && (
//   <div className="intro-text">
//     <div className="dottie-icon-intro">
//       <ChatBotIcon size={55} />
//     </div>
//     <h1>Suggest An Edit</h1>
//     <h2>Ask me to make a change. Ex: make it shorter, add a part about..., add hashtags.</h2>
//     {/* <div className="button-container">
//     <button onClick={() => document.getElementById('chat-input-send-btn')?.focus()}>
//       Type your reply & click Send
//     </button>
//   </div> */}
//   </div>
// )}
// {step === 4 && (
//   <div className="intro-text">
//     <div className="dottie-icon-intro">
//       <ChatBotIcon size={55} />
//     </div>
//     <h1>It's That Simple</h1>
//     <h2>
//       The more you use me, the better
//       <br />
//       I'll get at helping you.
//     </h2>
//     <div className="message-content">
//       {messages[3]?._id && <span>{messages[3].content}</span>}
//       {!messages[3]?._id && <Spinner />}
//     </div>
//     <div className="button-container">
//       <button onClick={() => dispatch(setShowEducationalOnboarding(false))}>
//         Create My Post
//       </button>
//     </div>
//   </div>
// )}

export default ChatOnboarding
