import { createRef, FC, useEffect, useState } from 'react'
import { useActiveObject, useEditor } from '@layerhub-io/react'
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline'
import { getTextProperties } from '../../../utils'
import { OptionsPopover } from '../..'
import { StyledDesignerFontSizeTool } from '.'

const DesignerFontSizeTool: FC = () => {
  const btnRef = createRef<HTMLInputElement>()
  const editor = useEditor()
  const activeObject = useActiveObject() as any
  const [fontSize, setFontSize] = useState(0)
  const [showOptions, setShowOptions] = useState(false)

  const FONT_SIZES = [
    6,
    8,
    10,
    12,
    14,
    16,
    18,
    21,
    24,
    28,
    32,
    36,
    42,
    48,
    56,
    64,
    72,
    80,
    88,
    96,
    104,
    120,
    144
  ]

  const changeFontSize = (size: number) => {
    setFontSize(Math.max(0, size))
    editor.objects.update({ fontSize: Math.max(0, size) })
  }

  const handleFontSize = () => {
    if (!activeObject || activeObject.type !== 'StaticText') {
      return
    }

    const { fontSize } = getTextProperties(activeObject)

    setFontSize(fontSize)
  }

  useEffect(() => {
    handleFontSize()

    if (editor) {
      editor.on('history:changed', handleFontSize)
    }
    return () => {
      if (editor) {
        editor.off('history:changed', handleFontSize)
      }
    }
  }, [editor, activeObject])

  return (
    <>
      <StyledDesignerFontSizeTool>
        <button onClick={() => changeFontSize(fontSize - 1)}>
          <MinusIcon />
        </button>
        <input
          value={parseInt(fontSize.toFixed(0))}
          type="number"
          className="font-size-input"
          ref={btnRef}
          onFocus={() => setShowOptions(true)}
          onChange={(e) => changeFontSize(parseInt(e.target.value))}
        />
        <button onClick={() => changeFontSize(fontSize + 1)}>
          <PlusIcon />
        </button>
      </StyledDesignerFontSizeTool>
      <OptionsPopover
        options={FONT_SIZES.map((size) => ({
          label: size.toString(),
          isSelected: size === fontSize,
          stayOpen: true,
          action: () => changeFontSize(size)
        }))}
        position="bottom-left"
        classToAvoid="font-size-input"
        width={100}
        buttonRef={btnRef}
        isOpen={showOptions}
        onClose={() => setShowOptions(false)}
      />
    </>
  )
}

export default DesignerFontSizeTool
