import { FC } from 'react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { ChatIcon, HashtagSquareOutlineIcon } from '../../../../assets'
import {
  setShowChat,
  setShowCreateHashtags,
  useAppDispatch,
  useAppSelector
} from '../../../../redux'
import { getPostStatus, useKeyboardHeight } from '../../../../utils'
import { StyledCreateActionBar } from '.'

const CreateActionBar: FC = () => {
  const dispatch = useAppDispatch()
  const { height } = useKeyboardHeight()
  const { post, showHashtags } = useAppSelector((state) => state.create)

  const showChat = () => {
    dispatch(setShowChat(true))
  }

  return (
    <StyledCreateActionBar
      id="create-action-bar"
      keyboardHeight={showHashtags ? 336 : height}
      data-keyboard-shown={!!height || showHashtags}
    >
      <button
        className="chat-btn"
        onClick={() => {
          showChat()
        }}
      >
        <ChatIcon />
        Ask Dottie
      </button>
      <div className="action-row">
        {/* <button className="action-row-icon-btn">
          <AlbumOutlineIcon />
        </button>
        <button className="action-row-icon-btn">
          <DocumentTextOutlineIcon />
        </button> */}

        {!(showHashtags ? 336 : height) && <div className="post-status">{getPostStatus(post)}</div>}

        {!!(showHashtags ? 336 : height) && (
          <>
            <button
              className="action-row-icon-btn"
              onClick={() => dispatch(setShowCreateHashtags(!showHashtags))}
            >
              <HashtagSquareOutlineIcon />
            </button>
            <button className="collapse-btn" onClick={() => dispatch(setShowCreateHashtags(false))}>
              <ChevronDownIcon />
            </button>
          </>
        )}
      </div>
    </StyledCreateActionBar>
  )
}

export default CreateActionBar
