import styled from 'styled-components'
import roundedEdgeTop from '../../../assets/rounded-edge-top.svg'
import roundedEdgeBottom from '../../../assets/rounded-edge-bottom.svg'

const StyledDesignerNavItem = styled.button`
  display: grid;
  row-gap: 6px;
  height: 80px;
  text-align: center;
  align-content: center;
  justify-items: center;
  font-weight: 600;
  font-size: 12px;
  padding: 0 6px;
  box-sizing: border-box;
  background-color: transparent;
  transition: color 0.2s;
  color: rgb(255, 255, 255, 0.4);
  position: relative;

  svg {
    height: 28px;
  }

  &:hover {
    color: rgb(255, 255, 255, 0.8);
  }

  &[aria-selected='true'] {
    background-color: #252627;
    color: rgb(255, 255, 255, 0.8);

    &::before {
      content: '';
      position: absolute;
      top: -10px;
      z-index: 3;
      pointer-events: none;
      right: 0;
      width: 10px;
      height: 10px;
      background-color: #252627;
      mask-image: url(${roundedEdgeTop});
    }

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      z-index: 3;
      pointer-events: none;
      right: 0;
      width: 10px;
      height: 10px;
      background-color: #252627;
      mask-image: url(${roundedEdgeBottom});
    }
  }
`

export default StyledDesignerNavItem
