import { useContext } from 'react'
import { toast } from 'react-hot-toast'
import { saveAs } from 'file-saver'
import { useApolloClient } from '@apollo/client'
import { Capacitor } from '@capacitor/core'
import { Haptics } from '@capacitor/haptics'
import {
  galleryImagesLikeAndIncrement,
  galleryImagesLikeRemoveMany,
  searchGalleryQuery
} from '../../graphql'
import { setPhotosLiked, useAppDispatch, useAppSelector } from '../../redux'
import { PhotoType } from '../../types'
import { userStore } from '..'
import { useCloud, useCreate, useHaptics, useSegment } from '.'

export const usePhoto = () => {
  const apollo = useApolloClient()
  const { track } = useSegment()
  const { addGalleryPhotoToCloud } = useCloud()
  const { addMediaToPost } = useCreate()
  const { trigger } = useHaptics()
  const { user } = useContext(userStore)
  const { post } = useAppSelector((state) => state.create)
  const dispatch = useAppDispatch()

  const downloadPhoto = (photo: PhotoType) => {
    saveAs(photo.file.fileHttpLink || '', `${photo._id}.jpg`)
    toast.success('Downloaded photo')
    track('Downloaded Photo', { ...photo })
  }

  const addPhotoToPost = (photo: PhotoType) => {
    const added = addMediaToPost(photo)

    if (!added) {
      return false
    }

    trigger('impact-light')
    track('Added Photo to Post', { ...photo, post_id: post?._id })

    return true
  }

  const togglePhotoFavorited = async (photo: PhotoType) => {
    try {
      const isFavorited = !!photo.liked?._id

      dispatch(setPhotosLiked({ _id: photo._id, isLiked: isFavorited }))

      if (isFavorited && Capacitor.isNativePlatform()) {
        Haptics.notification()
      }

      if (isFavorited) {
        await apollo.mutate({
          mutation: galleryImagesLikeRemoveMany,
          variables: { image: photo._id, user: user?.id }
        })

        track('Favorited Photo', photo)
        return toast.success('Favorited photo')
      }

      const { data: search } = await apollo.query({
        query: searchGalleryQuery,
        fetchPolicy: 'no-cache',
        variables: {
          search: '',
          sort: 'latest',
          categories: [],
          style: [],
          colors: [],
          items: 1,
          collection: null,
          liked: true,
          page: 0
        }
      })

      await apollo.mutate({
        mutation: galleryImagesLikeAndIncrement,
        variables: {
          imageId: photo._id,
          record: {
            image: photo._id,
            user: user?.id,
            index: search.searchGallery?.totalDocs || 0
          }
        }
      })

      track('Favorited Photo', photo)
      toast.success('Favorited photo')
    } catch (err) {
      toast.error((err as any).message)
    }
  }

  const addPhotoToCollection = async (photo: PhotoType) => {
    await addGalleryPhotoToCloud(photo)
  }

  return {
    downloadPhoto,
    addPhotoToPost,
    togglePhotoFavorited,
    addPhotoToCollection
  }
}
