export const day7 = {
  _id: 'day7',
  title: 'DAY 7',
  tasks: [
    {
      label:
        'Find the Action Plan titled “Your LinkedIn Toolkit: Everything You Need to Grow Your Business On LinkedIn”',
      steps: [
        // {
        //   label: 'Navigate to the Actions Plans using the sidebar'
        // },
        // {
        //   label: 'Search for the Action Plan using the search bar'
        // },
        // {
        //   label: 'Click on the Action Plan in the search results'
        // }
      ]
    },
    {
      label: 'Download the Action Plan PDF',
      steps: [
        // {
        //   label:
        //     'Click the “Download Action Plan” button in the top-right corner of the Action Plan'
        // }
      ]
    },
    {
      label: 'Favorite a Recommended Caption',
      steps: [
        // {
        //   label: 'In the Recommended Content tab, find a caption and click the favorite (♡) button'
        // }
      ]
    },
    {
      label: 'Download a Recommended Photo',
      steps: [
        // {
        //   label: 'In the Recommended Content tab, click on a photo'
        // },
        // {
        //   label: 'In the overlay, click on the download (↓) button'
        // }
      ]
    },
    {
      label: 'Open a Linked Extra',
      steps: [
        // {
        //   label: 'In the Recommended Content tab, click on an extra'
        // }
      ]
    }
  ],
  form: {
    url: 'https://notionforms.io/forms/day-7-feedback',
    keys: {
      FULL_NAME: '1334c3c4-49a2-4cac-97fb-03b19272b65a',
      EMAIL: 'd769466e-4ead-4c1e-b771-b3b3add8fae9',
      SCREEN_RECORDING: 'be6ae969-b318-4596-b223-c32c2cc6bf68',
      USER_ID: 'bc2c6d7d-d3bc-4a2d-a0ab-4476478e4866'
    }
  }
}
