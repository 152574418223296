import { FC, useEffect, useState } from 'react'
import { useReward } from 'react-rewards'
import { Tour } from 'react-shepherd'
import { disablePageScroll, enablePageScroll } from 'scroll-lock'
import { setTour, useAppDispatch, useAppSelector } from '../../../redux'
import { useSegment } from '../../../utils'
import { TourVideoBubble } from '..'

type Props = {
  tour: Tour | null
  tourId: string
  canStart?: boolean
  onlyOnce?: boolean
  showConfetti?: boolean
  onTourComplete?: () => void
}

const TourEvents: FC<Props> = ({
  tour,
  tourId,
  canStart,
  onlyOnce,
  showConfetti = true,
  onTourComplete
}) => {
  const dispatch = useAppDispatch()
  const { tours } = useAppSelector((state) => state.tour)
  const { track } = useSegment()
  const [video, setVideo] = useState('')
  const { reward } = useReward('confetti', 'confetti', {
    angle: -90,
    startVelocity: 18,
    spread: 220,
    elementCount: 150
  })

  const handleTourVideo = (video?: string) => {
    if (!video) {
      return setVideo('')
    }

    setVideo(video)
  }

  const endTour = () => {
    dispatch(setTour({ tour: undefined }))
    setVideo('')
    enablePageScroll()
  }

  const triggerTourStart = async () => {
    if (onlyOnce && tours.includes(tourId)) {
      dispatch(setTour({ tour: undefined }))
      return
    }

    tour?.start()
    disablePageScroll()
    const tourOptions = tour?.steps[0].options as any
    handleTourVideo(tourOptions.video)
    track('Started Onboarding Flow', { flow_name: tourId })
  }

  const handleTourEvents = (tour: Tour | null) => {
    if (!tour) {
      return
    }

    tour.on('start', async (e: any) => {
      disablePageScroll()
    })

    tour.on('cancel', () => {
      const currStep = tour.getCurrentStep()

      track('Ended Onboarding Flow', {
        flow_name: tourId,
        step: currStep ? tour.steps.indexOf(currStep) : undefined
      })

      endTour()
    })

    tour.on('show', (e: any) => {
      handleTourVideo(e?.step?.options?.video)
    })

    tour.on('complete', () => {
      track('Completed Onboarding Flow', { flow_name: tourId })

      if (showConfetti) {
        reward()
      }
      endTour()

      if (onTourComplete) {
        onTourComplete()
      }
    })
  }

  useEffect(() => {
    if (canStart) {
      triggerTourStart()
    }
  }, [canStart])

  useEffect(() => {
    handleTourEvents(tour)
  }, [tour])

  return <>{video && <TourVideoBubble url={video} />}</>
}

export default TourEvents
