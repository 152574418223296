import styled from 'styled-components'

const StyledChatHistoryList = styled.div`
  background-color: ${({ theme }) => theme.color.background};
  position: absolute;
  width: 100%;
  overflow-y: auto !important;
  box-sizing: border-box;
  height: calc(100% - 54px);
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  align-content: start;

  .spinner {
    padding: 14px;

    svg {
      height: 40px;
      width: 40px;
    }
  }
`

export default StyledChatHistoryList
