import styled from 'styled-components'

const StyledCreateBarDesignsList = styled.div`
  .designs-file-list {
    padding: 20px;
    display: grid;
    gap: 10px;
  }
`

export default StyledCreateBarDesignsList
