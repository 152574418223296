import { FC, createRef, ReactNode } from 'react'
import { Dialog } from '../..'
import { StyledActionDialog } from '.'
import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon
} from '@heroicons/react/20/solid'

type Props = {
  type?: 'default' | 'warning' | 'error'
  title: string
  body?: string | ReactNode
  cancelButtonText?: string
  confirmButtonText?: string
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}

const ActionDialog: FC<Props> = ({
  type = 'default',
  title,
  body,
  cancelButtonText = 'Cancel',
  confirmButtonText = 'Confirm',
  onConfirm,
  ...rest
}) => {
  const dialogRef = createRef<HTMLDivElement>()

  const getIcon = () => {
    switch (type) {
      case 'error':
        return <ExclamationCircleIcon />
      case 'warning':
        return <ExclamationTriangleIcon />
      default:
        return <InformationCircleIcon />
    }
  }

  const handleConfirm = () => {
    const dialog = dialogRef.current as any
    dialog?.close()
    onConfirm()
  }

  return (
    <Dialog ref={dialogRef} {...rest}>
      <StyledActionDialog type={type}>
        <div className="dialog-header">
          <div className="dialog-message-icon">{getIcon()}</div>
          <span className="dialog-title">{title}</span>
        </div>
        <div className="dialog-message-container">
          {body && <span className="dialog-body">{body}</span>}
        </div>
        <div className="dialog-actions">
          <button className="cancel-btn" onClick={() => (dialogRef.current as any)?.close()}>
            <span>{cancelButtonText}</span>
          </button>
          <button className="confirm-btn" onClick={() => handleConfirm()}>
            <span>{confirmButtonText}</span>
          </button>
        </div>
      </StyledActionDialog>
    </Dialog>
  )
}

export default ActionDialog
