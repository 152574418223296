import { FC, useEffect, useState } from 'react'
import { times } from 'lodash'
import { DateTime } from 'luxon'
import { useAppSelector } from '../../../redux'
import { isMobile, isToday, parseDate } from '../../../utils'
import { StyledCalendarDays } from '.'

const CalendarDays: FC = () => {
  const { start, view } = useAppSelector((state) => state.calendar)
  const [days, setDays] = useState<DateTime[]>([])

  const getDays = () => {
    const dayList: DateTime[] = []

    if (view === 'month') {
      return setDays(
        times(7, (num) => {
          return DateTime.now().set({ weekday: num })
        })
      )
    }

    times(isMobile() ? 2 : 7, (num) => {
      dayList.push(parseDate(start).plus({ days: num }))
    })

    setDays(dayList)
  }

  useEffect(() => {
    getDays()
  }, [start, view])

  return (
    <StyledCalendarDays view={view}>
      {view === 'week' && (
        <div className="calendar-timezone">
          <span>{DateTime.now().toFormat('ZZZZ')}</span>
        </div>
      )}
      <div className="calendar-days">
        {days.map((day) => {
          return (
            <div className="calendar-day" data-today={isToday(day)}>
              <span className="calendar-day-label">{day.toFormat('ccc')}</span>
              {view === 'week' && <div className="calendar-day-number">{day.day}</div>}
            </div>
          )
        })}
      </div>
    </StyledCalendarDays>
  )
}

export default CalendarDays
