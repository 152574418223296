import styled from 'styled-components'

const StyledPlanPlanStep = styled.div`
  padding: 50px 30px 30px 30px;
  max-width: 580px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  user-select: none;

  h1 {
    font-size: 24px;
    font-weight: 700;
    color: ${({ theme }) => theme.color.gray[900]};

    mark {
      background-color: transparent;
      color: ${({ theme }) => theme.color.brand};
    }
  }

  p {
    font-size: 15px;
    margin: 14px 0;
    color: ${({ theme }) => theme.color.gray[500]};
  }

  .form-container {
    margin-top: 36px;

    .form-header {
      display: grid;
      grid-template-columns: 45px minmax(0, 1fr) 185px;
      gap: 8px;

      .form-heading {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 0.5px;
        color: ${({ theme }) => theme.color.gray[400]};

        svg {
          height: 16px;
          margin-left: 4px;
        }
      }
    }

    .form-content {
      margin-top: 10px;
      display: grid;
      gap: 12px;
    }

    .form-action {
      margin-top: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50rem;
      height: 45px;
      width: 100%;
      font-weight: 500;
      color: ${({ theme }) => theme.color.gray[500]};
      background-color: ${({ theme }) => theme.color.gray[100]};
      transition: 0.2s;

      svg {
        height: 18px;
        stroke-width: 2.5px;
        margin-right: 10px;
      }

      &:hover {
        color: ${({ theme }) => theme.color.gray[700]};
        background-color: ${({ theme }) => theme.color.gray[200]};
      }
    }
  }
`

export default StyledPlanPlanStep
