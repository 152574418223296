import styled from 'styled-components'

const StyledUploadsPreview = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.85);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    user-select: none;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 16px;

    .header-group {
      display: flex;
      align-items: center;

      .back-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        transition: 0.2s;
        color: rgb(255, 255, 255, 1);
        background-color: transparent;
        margin-right: 16px;

        svg {
          height: 22px;
          stroke-width: 2.2px;
        }

        &:hover {
          background-color: rgb(255, 255, 255, 0.1);
        }
      }

      .file-details {
        display: flex;
        align-items: center;
        color: rgb(255, 255, 255, 1);

        svg {
          height: 22px;
          margin-right: 8px;
        }
      }
    }
  }

  .preview {
    display: flex;
    align-items: center;
    justify-content: center;

    .media-preview {
      filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.2));

      .media-player {
        height: unset;
        position: unset;
        display: unset;
      }

      img,
      video {
        position: unset;
        top: unset;
        left: unset;
        right: unset;
        bottom: unset;
        width: unset;
        height: unset;
        max-width: calc(100vw - 160px);
        max-height: calc(100vh - 160px);
      }
    }
  }
`

export default StyledUploadsPreview
