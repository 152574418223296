import InfiniteScroll from 'react-infinite-scroll-component'
import { FC, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { v4 as uuid } from 'uuid'
import { useApolloClient } from '@apollo/client'
import { ArrowLeftIcon, CheckIcon } from '@heroicons/react/24/outline'
import { ReactComponent as MyDesignsIcon } from '../../../../../assets/my-designs-icon.svg'
import { CloudContentType, PostType } from '../../../../../types'
import { loadFilesFromCloud } from '../../../../../graphql'
import { Button, CloudMediaPreview, Spinner } from '../../../..'
import { StyledUploadDialogDesignsPage } from '.'

type Props = {
  mediaToAdd: PostType['media']
  toggleMedia: (media: NonNullable<PostType['media']>[number]) => void
  setPage: (page?: string) => void
  onClose: () => void
}

const UploadDialogDesignsPage: FC<Props> = ({ mediaToAdd, toggleMedia, setPage: goBack }) => {
  const apollo = useApolloClient()
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [files, setFiles] = useState<CloudContentType[]>([])

  const loadData = async () => {
    try {
      setLoading(true)
      const { data } = await apollo.query({
        fetchPolicy: 'no-cache',
        query: loadFilesFromCloud,
        variables: {
          page,
          parent: undefined,
          limit: 10,
          types: ['design']
        }
      })

      const docs = data?.loadFilesFromCloud?.docs || []
      const total = data?.loadFilesFromCloud?.totalDocs || 0

      setFiles([...files, ...docs])
      setTotal(total)
    } catch (err) {
      console.log(err)
      toast.error((err as any).message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadData()
  }, [page])

  return (
    <StyledUploadDialogDesignsPage className="dialog-page">
      <div className="dialog-header">
        <div className="dialog-header-details">
          <div className="dialog-header-icon">
            <MyDesignsIcon />
          </div>
          <span className="dialog-title">Add from designs</span>
        </div>
        <Button size="sm" isIcon onClick={() => goBack(undefined)}>
          <ArrowLeftIcon />
        </Button>
      </div>
      <div className="upload-list" id="upload-dialog-upload-list">
        {loading && page === 0 && (
          <div className="loading-indicator">
            <Spinner />
          </div>
        )}
        <InfiniteScroll
          scrollThreshold={0.7}
          dataLength={files.length}
          next={() => setPage(page + 1)}
          hasMore={files.length < total}
          loader={null}
          scrollableTarget="upload-dialog-upload-list"
          className="infinite-scroll"
        >
          {files.map((file) => {
            const isSelected = !!mediaToAdd?.find((media) => media.ref === file._id)

            return (
              <button
                className="upload-list-item"
                data-selected={isSelected}
                onClick={() =>
                  toggleMedia({
                    _id: uuid(),
                    url: file.file?.url || '',
                    preview: file.file?.preview,
                    design: file.file?.metadata?.design,
                    type: (file.file?.type as any) || 'image',
                    uploaded: true,
                    ref: file._id,
                    dimensions: {
                      x: file.file?.dimensions.x || 0,
                      y: file.file?.dimensions.y || 0
                    }
                  })
                }
              >
                <div className="upload-list-item-preview">
                  {isSelected && <CheckIcon />}
                  {!isSelected && (
                    <CloudMediaPreview url={file.file?.url || ''} type={file.file?.type || ''} />
                  )}
                </div>
                <div className="upload-list-item-details">
                  <span>{file.name}</span>
                </div>
              </button>
            )
          })}
        </InfiniteScroll>
      </div>
    </StyledUploadDialogDesignsPage>
  )
}

export default UploadDialogDesignsPage
