import styled from 'styled-components'

const StyledDesignerCanvas = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  overflow: hidden;
  grid-template-rows: auto minmax(0, 1fr);
  background-color: ${({ theme }) => theme.color.gray[100]};

  #layerhub_io_canvas_container {
    height: 100%;
  }
`

export default StyledDesignerCanvas
