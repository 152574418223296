import { useContext, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useHistory } from 'react-router-dom'
import { compact, xor } from 'lodash'
import { useApolloClient } from '@apollo/client'
import { Preferences } from '@capacitor/preferences'
import { connectSocialProfilesMutation } from '../../../graphql'
import { setShowConnectionsModal, useAppDispatch } from '../../../redux'
import { SocialProfileType } from '../../../types'
import {
  socialProfileByStatus,
  socialProfileByType,
  useLoginLinkedIn,
  useLoginMeta,
  useLoginPinterest,
  useLoginTikTok,
  useLoginTwitter,
  useSegment,
  userStore
} from '../..'

export const useConnections = () => {
  const apollo = useApolloClient()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { user, refresh } = useContext(userStore)
  const { loginFacebook } = useLoginMeta()
  const { connectTwitterProfile, loginTwitter } = useLoginTwitter()
  const { connectLinkedInProfile, loginLinkedIn } = useLoginLinkedIn()
  const { connectTikTokProfile, loginTikTok } = useLoginTikTok()
  const { connectPinterestProfile, loginPinterest } = useLoginPinterest()
  const { track } = useSegment()
  const [loading, setLoading] = useState(false)
  const [hasConnections, setHasConnections] = useState(true)
  const [connectedProfiles, setConnectedProfiles] = useState<SocialProfileType[]>([])
  const [activeProfiles, setActiveProfiles] = useState<SocialProfileType[]>([])
  const [expiredProfiles, setExpiredProfiles] = useState<SocialProfileType[]>([])
  const [availableProfiles, setAvailableProfiles] = useState<SocialProfileType[]>([])
  const [profilesToConnect, setProfilesToConnect] = useState<SocialProfileType[]>([])
  const [platformToConnect, setPlatformToConnect] = useState<SocialProfileType['type']>()
  const [platformToOverview, setPlatformToOverview] = useState<SocialProfileType['type']>()
  const [platformCounts, setPlatformCounts] = useState({
    instagram: 0,
    facebook: 0,
    linkedin: 0,
    twitter: 0,
    pinterest: 0,
    tiktok: 0
  })
  const connectionLimit = user?.trial ? 1 : 3

  const connectPlatform = async (platform: SocialProfileType['type']) => {
    try {
      setLoading(true)
      const profileList: SocialProfileType[] = []

      if (platform === 'facebook' || platform === 'instagram') {
        const fbProfiles = await loginFacebook(platform)

        console.log('FB' + fbProfiles)

        profileList.push(...fbProfiles)
      }

      if (platform === 'linkedin') {
        return loginLinkedIn()
      }

      if (platform === 'twitter') {
        return await loginTwitter()
      }

      // if (platform === 'tiktok') {
      //   return await loginTikTok()
      // }

      // if (platform === 'pinterest') {
      //   return loginPinterest()
      // }

      setAvailableProfiles(profileList)
      setProfilesToConnect(expiredProfiles.filter((profile) => profile.type === platform))
      // dispatch(setShowConnectionsModal(true))
    } catch (err) {
      console.error(err)
      const message = (err as any)?.message

      if (message?.includes("Cannot read properties of null (reading 'accessToken')")) {
        return
      }

      toast.error((err as any).message || err)
    } finally {
      setLoading(false)
    }
  }

  const toggleProfile = (profile: SocialProfileType) => {
    setProfilesToConnect(xor(profilesToConnect, [profile]))
  }

  const connectProfiles = async () => {
    try {
      setLoading(true)

      const formattedProfiles = compact(
        profilesToConnect.map((profile) => {
          if (profile.isBusiness !== false && !!profile?.accessToken?.token) {
            return {
              ...profile,
              _id: undefined,
              isBusiness: undefined
            }
          }
        })
      )

      await apollo.mutate({
        mutation: connectSocialProfilesMutation,
        variables: { profiles: formattedProfiles }
      })
      refresh()
      toast.success(
        `Successfully connected your social profile${profilesToConnect.length === 1 ? '' : 's'}`
      )
      formattedProfiles.map((profile) => {
        if (profile.type === 'instagram') {
          track('Connected Instagram Profile', profile)
        }
        if (profile.type === 'facebook') {
          track('Connected Facebook Profile', profile)
        }
        track('Connected Social Profile', profile)
      })
      setAvailableProfiles([])
      setProfilesToConnect([])
      setPlatformToConnect(undefined)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const handleConnectAccountsFromState = async (parsed: any) => {
    // if (!!parsed?.code && parsed?.state === 'sc-instagram') {
    //   await connectInstagramProfile(parsed.code as string)

    //   return history.replace({
    //     search: ''
    //   })
    // }

    if (!!parsed?.code && parsed?.state === 'linkedin') {
      await connectLinkedInProfile(parsed.code as string)

      return history.replace({
        search: ''
      })
    }

    if (!!parsed?.code && parsed?.state === 'sc-tiktok') {
      await connectTikTokProfile(parsed.code as string)

      return history.replace({
        search: ''
      })
    }

    if (!!parsed?.oauth_token && !!parsed?.oauth_verifier) {
      const twitterSecret = await Preferences.get({
        key: 'twitter-secret'
      })

      if (!twitterSecret.value) {
        return
      }

      await connectTwitterProfile(
        parsed.oauth_token as string,
        parsed?.oauth_verifier as string,
        twitterSecret.value
      )

      await Preferences.remove({
        key: 'twitter-secret'
      })

      setAvailableProfiles([])
      setProfilesToConnect([])
      setPlatformToConnect(undefined)

      history.replace({
        search: ''
      })
    }
  }

  useEffect(() => {
    setProfilesToConnect([])
  }, [platformToConnect])

  useEffect(() => {
    setAvailableProfiles([])
    setProfilesToConnect([])
    setPlatformToConnect(undefined)

    const instagramCount = socialProfileByType(['instagram'], user?.socialProfiles)
    const facebookCount = socialProfileByType(['facebook'], user?.socialProfiles)
    const linkedinCount = socialProfileByType(['linkedin'], user?.socialProfiles)
    const twitterCount = socialProfileByType(['twitter'], user?.socialProfiles)
    const pinterestCount = []
    const tiktokCount = []

    setPlatformCounts({
      instagram: instagramCount.length,
      facebook: facebookCount.length,
      linkedin: linkedinCount.length,
      twitter: twitterCount.length,
      pinterest: pinterestCount.length,
      tiktok: tiktokCount.length
    })

    setConnectedProfiles(socialProfileByStatus(['active', 'expired'], user?.socialProfiles))
    setActiveProfiles(socialProfileByStatus(['active'], user?.socialProfiles))
    setExpiredProfiles(socialProfileByStatus(['expired'], user?.socialProfiles))
    setHasConnections(!!socialProfileByStatus(['active', 'expired'], user?.socialProfiles)?.length)
  }, [user?.socialProfiles])

  return {
    loading,
    platformCounts,
    hasConnections,
    connectedProfiles,
    activeProfiles,
    expiredProfiles,
    availableProfiles,
    profilesToConnect,
    platformToConnect,
    connectionLimit,
    platformToOverview,
    toggleProfile,
    setPlatformToConnect,
    connectPlatform,
    connectProfiles,
    handleConnectAccountsFromState,
    setPlatformToOverview,
    setAvailableProfiles,
    setProfilesToConnect
  }
}
