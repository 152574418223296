import { FC, MouseEvent, createRef, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDroppable } from '@dnd-kit/core'
import { EllipsisHorizontalIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline'
import { FolderIcon, FolderOpenIcon } from '@heroicons/react/24/solid'
import { removeCloudFolder, updateCloudFolder, useAppDispatch } from '../../../redux'
import { CloudContentType } from '../../../types'
import { ROUTES, useCloud } from '../../../utils'
import { ActionDialog, InputDialog, OptionsPopover } from '../..'
import { StyledUploadsFolderListItem } from '.'

type Props = {
  folder: CloudContentType
  isActive?: boolean
}

const UploadsFolderListItem: FC<Props> = ({ folder, isActive }) => {
  const optionsBtnRef = createRef<HTMLButtonElement>()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const [newName, setNewName] = useState('')
  const [showOptions, setShowOptions] = useState(false)
  const [showNameInput, setShowNameInput] = useState(false)
  const [showDeletionConfirmation, setShowDeletionConfirmation] = useState(false)
  const { deleteFromCloud, updateCloudFileData } = useCloud()
  const { isOver, setNodeRef } = useDroppable({
    id: folder._id,
    data: {
      folder
    }
  })

  const handleShowOptions = (e: MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
    setShowOptions(!showOptions)
  }

  const handleRename = () => {
    updateCloudFileData({ ...folder, name: newName })
    dispatch(updateCloudFolder({ ...folder, name: newName }))
  }

  const handleDelete = () => {
    deleteFromCloud([folder._id])
    dispatch(removeCloudFolder(folder._id))
  }

  useEffect(() => {
    setNewName(folder.name)
  }, [showNameInput])

  return (
    <>
      <StyledUploadsFolderListItem
        isOver={isOver}
        isActive={isActive}
        onClick={() => history.push(ROUTES.uploads.folderByPath(folder.name))}
        ref={setNodeRef}
      >
        <div className="folder-details-container">
          <div className="folder-icon">{isActive ? <FolderOpenIcon /> : <FolderIcon />}</div>
          <span className="folder-name">{folder.name}</span>
        </div>
        <button
          className="options-btn"
          data-active={showOptions}
          ref={optionsBtnRef}
          onClick={(e) => handleShowOptions(e)}
        >
          <EllipsisHorizontalIcon />
        </button>
      </StyledUploadsFolderListItem>
      <OptionsPopover
        options={[
          {
            icon: <PencilIcon />,
            label: `Rename folder`,
            action: () => setShowNameInput(true)
          },
          {
            icon: <TrashIcon />,
            label: `Delete folder`,
            action: () => setShowDeletionConfirmation(true)
          }
        ]}
        classToAvoid={`options-btn`}
        buttonRef={optionsBtnRef}
        isOpen={showOptions}
        onClose={() => setShowOptions(false)}
      />
      <InputDialog
        isOpen={showNameInput}
        title={`Rename folder`}
        value={newName}
        confirmButtonText="Rename"
        onConfirm={() => handleRename()}
        onChange={(value) => setNewName(value.replaceAll('-', '').trimStart())}
        onClose={() => setShowNameInput(false)}
      />
      <ActionDialog
        title={`Delete folder`}
        body={`Are you sure you want to delete folder?`}
        type="error"
        confirmButtonText="Delete"
        isOpen={showDeletionConfirmation}
        onClose={() => setShowDeletionConfirmation(false)}
        onConfirm={() => handleDelete()}
      />
    </>
  )
}

export default UploadsFolderListItem
