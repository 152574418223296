import styled from 'styled-components'

const StyledPublishWhenField = styled.div`
  .time-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    white-space: nowrap;
    height: 40px;
    padding: 0 12px;
    border-radius: 6px;
    transition: 0.2s;
    color: ${({ theme }) => theme.color.gray[700]};
    background-color: ${({ theme }) => theme.color.gray[100]};

    svg {
      height: 22px;
      margin-right: 8px;
    }

    &:hover {
      color: ${({ theme }) => theme.color.gray[800]};
      background-color: ${({ theme }) => theme.color.gray[200]};
    }

    &.primary {
      color: ${({ theme }) => theme.color.gray[700]};
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.gray[300]};
      background-color: ${({ theme }) => theme.color.background};

      &:hover {
        color: ${({ theme }) => theme.color.gray[800]};
        box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.gray[400]};
        background-color: ${({ theme }) => theme.color.gray[100]};
      }
    }

    &[aria-selected='true'] {
      color: ${({ theme }) => theme.color.brand};
      background-color: ${({ theme }) => theme.color.brandLight};
      box-shadow: inset 0 0 0 2px ${({ theme }) => theme.color.brand};

      &:hover {
        color: ${({ theme }) => theme.color.brand};
        background-color: ${({ theme }) => theme.color.brandLight};
        box-shadow: inset 0 0 0 2px ${({ theme }) => theme.color.brand};
      }
    }
  }

  .when-chips {
    display: grid;
    grid-template-columns: auto auto minmax(0, 1fr);
    column-gap: 8px;
    margin-top: 10px;
  }

  .when-chip-suggestions {
    position: relative;
    margin-top: 10px;
  }

  .clear-btn {
    margin-right: 17px;
    text-decoration: underline;
    transition: 0.2s;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      right: -10px;
      height: 3px;
      width: 3px;
      border-radius: 50%;
      top: 50%;
      transform: translateY(-50%);
      background-color: ${({ theme }) => theme.color.gray[500]};
    }

    &:hover {
      color: ${({ theme }) => theme.color.gray[800]};
    }
  }
`

export default StyledPublishWhenField
