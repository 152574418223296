import { FC } from 'react'
import { Keyboard } from '@capacitor/keyboard'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import {
  clearActionPlansCategory,
  setActionPlansCategory,
  setActionPlansQuery,
  useAppDispatch,
  useAppSelector
} from '../../../redux'
import { ActionPlanList, Tabs } from '../../components'
import { StyledActionPlansView } from '.'

const ActionPlansView: FC = () => {
  const dispatch = useAppDispatch()
  const { search, categories } = useAppSelector((state) => state.actionPlans)

  return (
    <StyledActionPlansView>
      <div className="heading">
        <div className="input-container">
          <MagnifyingGlassIcon />
          <input
            type="search"
            placeholder="Search action plans"
            enterKeyHint="search"
            value={search}
            onChange={(e) => dispatch(setActionPlansQuery(e.target.value))}
            onKeyDown={(e) => e.key === 'Enter' && Keyboard.hide()}
          />
        </div>
      </div>
      <Tabs
        tabs={[
          {
            label: 'All Action Plans',
            isActive: !categories.length,
            onClick: () => dispatch(clearActionPlansCategory())
          },
          {
            label: 'Sales',
            isActive: categories.includes('sales'),
            onClick: () => dispatch(setActionPlansCategory('sales'))
          },
          {
            label: 'Planning',
            isActive: categories.includes('planning'),
            onClick: () => dispatch(setActionPlansCategory('planning'))
          },
          {
            label: 'Engagement',
            isActive: categories.includes('engagement'),
            onClick: () => dispatch(setActionPlansCategory('engagement'))
          },
          {
            label: 'Captions',
            isActive: categories.includes('captions'),
            onClick: () => dispatch(setActionPlansCategory('captions'))
          }
        ]}
      />
      <ActionPlanList />
    </StyledActionPlansView>
  )
}

export default ActionPlansView
