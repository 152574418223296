import { xor } from 'lodash'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ActionPlanType } from '../../types'

type InitialState = {
  actionPlans: ActionPlanType[]
  search: string
  categories: string[]
  platform: string
  type?: string
  page: number
  hasNext?: boolean
  loading: boolean
}

const initialState: InitialState = {
  actionPlans: [],
  categories: [],
  loading: true,
  search: '',
  page: 0,
  platform: ''
}

export const actionPlansSlice = createSlice({
  name: 'actionPlans',
  initialState,
  reducers: {
    setActionPlans: (state, action: PayloadAction<ActionPlanType[]>) => {
      state.actionPlans = action.payload
      state.loading = false
    },
    addActionPlan: (state, action: PayloadAction<ActionPlanType>) => {
      state.actionPlans = state.actionPlans.concat([action.payload])
    },
    updateActionPlan: (state, action: PayloadAction<ActionPlanType>) => {
      state.actionPlans = state.actionPlans.map((actionPlan) => {
        if (actionPlan._id === action.payload._id) {
          return action.payload
        }

        return actionPlan
      })
    },
    removeActionPlan: (state, action: PayloadAction<string>) => {
      state.actionPlans = state.actionPlans.filter(
        (actionPlan) => actionPlan._id !== action.payload
      )
    },
    setActionPlansLiked: (state, action: PayloadAction<{ isLiked: boolean; _id: string }>) => {
      state.actionPlans = state.actionPlans.map((actionPlan) => {
        if (actionPlan._id === action.payload._id) {
          return {
            ...actionPlan,
            isLiked: action.payload.isLiked
          }
        }

        return actionPlan
      })
    },
    setActionPlansUsed: (state, action: PayloadAction<{ isUsed: boolean; _id: string }>) => {
      state.actionPlans = state.actionPlans.map((actionPlan) => {
        if (actionPlan._id === action.payload._id) {
          return {
            ...actionPlan,
            isUsed: action.payload.isUsed
          }
        }

        return actionPlan
      })
    },
    clearActionPlans: (state) => {
      state.actionPlans = []
      state.loading = true
      state.page = 0
    },
    setActionPlansQuery: (state, action: PayloadAction<string>) => {
      state.search = action.payload
      state.actionPlans = []
      state.loading = true
      state.page = 0
    },
    toggleActionPlansFilter: (state, action: PayloadAction<string>) => {
      state.categories = xor(state.categories, [action.payload])
      state.actionPlans = []
      state.loading = true
      state.page = 0
    },
    setActionPlansType: (state, action: PayloadAction<string>) => {
      state.type = action.payload
      state.actionPlans = []
      state.loading = true
      state.page = 0
    },
    changeActionPlansPage: (state, action: PayloadAction<number>) => {
      state.loading = true
      state.page = action.payload
    },
    changeActionPlansHasNext: (state, action: PayloadAction<boolean>) => {
      state.hasNext = action.payload
    },
    setActionPlansPlatform: (state, action: PayloadAction<string>) => {
      state.platform = action.payload
      state.loading = true
      state.actionPlans = []
      state.page = 0
    },
    setActionPlansCategory: (state, action: PayloadAction<string>) => {
      state.categories = [action.payload]
      state.loading = true
      state.actionPlans = []
      state.page = 0
    },
    clearActionPlansCategory: (state) => {
      state.categories = []
      state.loading = true
      state.actionPlans = []
      state.page = 0
    }
  }
})

export const {
  setActionPlans,
  addActionPlan,
  updateActionPlan,
  removeActionPlan,
  setActionPlansLiked,
  setActionPlansUsed,
  clearActionPlans,
  setActionPlansQuery,
  changeActionPlansPage,
  toggleActionPlansFilter,
  setActionPlansType,
  changeActionPlansHasNext,
  setActionPlansPlatform,
  setActionPlansCategory,
  clearActionPlansCategory
} = actionPlansSlice.actions
export default actionPlansSlice.reducer
