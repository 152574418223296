import { FC, useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { LinkPageEditor, LinkPagePreview, Spinner } from '../../components'
import { setTour, useAppDispatch, useAppSelector } from '../../redux'
import { userStore } from '../../utils'
import { StyledLinkPageView } from '.'

const LinkPageView: FC = () => {
  const dispatch = useAppDispatch()
  const { page } = useAppSelector((state) => state.page)
  const { user } = useContext(userStore)

  useEffect(() => {
    dispatch(setTour({ tour: 'link_page_overview', onlyOnce: true }))
  }, [])

  return (
    <StyledLinkPageView>
      <Helmet title={`${page?.name || user?.displayName}'s Link Page | Social Curator`} />
      {!page && (
        <div className="loading-screen">
          <Spinner />
          Loading Link Page
        </div>
      )}
      <LinkPageEditor />
      <LinkPagePreview />
    </StyledLinkPageView>
  )
}

export default LinkPageView
