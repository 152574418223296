import styled from 'styled-components'

const StyledCreateBarUploadsList = styled.div`
  .parent-header {
    height: 40px;
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.color.gray[100]};
    padding: 0 20px;
    user-select: none;

    button {
      color: ${({ theme }) => theme.color.gray[500]};
      background-color: transparent;
      transition: 0.2s;
      font-size: 
      text-decoration: none;
      display: flex;
      align-items: center;
      border-radius: 6px;

      &:not(:last-child) {
        &:hover {
          text-decoration: underline;
          color: ${({ theme }) => theme.color.gray[800]};
        }
      }

      &:last-child {
        color: ${({ theme }) => theme.color.gray[800]};
        pointer-events: none;
      }
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 42px;
      margin: 0 6px;
      font-weight: 500;
      color: ${({ theme }) => theme.color.gray[300]};
    }
  }

  .uploads-file-list {
    padding: 20px;
    display: grid;
    gap: 10px;
  }
`

export default StyledCreateBarUploadsList
