import { KeyboardEvent } from 'react'

export const handleEnterToNextInput = (e: KeyboardEvent) => {
  if (e?.key !== 'Enter') {
    return
  }

  const target = e?.currentTarget
  const label = target?.closest('label')
  const next = label?.nextElementSibling as HTMLDivElement

  if (!next) {
    return
  }

  next.focus()
}
