import { gql } from '@apollo/client'

export const getIssuesForAdminDropdown = gql`
  query GetIssuesForAdminDropdown {
    issueFindMany(sort: DATE_DESC, limit: 300) {
      _id
      title
    }
  }
`
