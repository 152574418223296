import styled, { keyframes } from 'styled-components'

const popIn = keyframes`
  0% { 
    transform: scale(0.3);
  }
  70% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`

const StyledToTopButton = styled.button`
  position: fixed;
  height: 60px;
  width: 60px;
  right: 12px;
  bottom: 12px;
  border-radius: 50%;
  display: none;
  align-items: center;
  z-index: ${({ theme }) => theme.elevation.fixed};
  justify-content: center;
  background-color: ${({ theme }) => theme.color.gray[900]};
  color: ${({ theme }) => theme.color.background};
  transition: 0.2s;
  box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.3), 0 2px 4px rgb(0, 0, 0, 0.3);
  animation: ${popIn} 0.22s ease-in-out;

  svg {
    height: 28px;
    stroke-width: 2.25px;
  }

  &:hover {
    transform: scale(0.98);
    background-color: ${({ theme }) => theme.color.gray[800]};
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    display: flex;
  }
`

export default StyledToTopButton
