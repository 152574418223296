import { FC, useEffect } from 'react'
import { useAppSelector } from '../../../redux'
import { parseDate, waitForElementExists } from '../../../utils'
import { CalendarDays, CalendarMonth, CalendarWeek } from '..'
import { StyledCalendar } from '.'

const Calendar: FC = () => {
  const { start, view } = useAppSelector((state) => state.calendar)

  const scrollToNow = async () => {
    await waitForElementExists('[data-calendar-now-line]', 100, 3)

    const weekElement = document.querySelector('[data-calendar-week]') as HTMLDivElement
    const nowLineElement = document.querySelector('[data-calendar-now-line]') as HTMLDivElement

    if (!weekElement || !nowLineElement) {
      return
    }

    const nowY = nowLineElement?.getBoundingClientRect().y
    const weekHeight = weekElement.getBoundingClientRect().height
    const weekTop = weekElement.offsetTop
    const weekOffset = nowY < weekHeight ? -weekHeight / 2 : weekTop - weekHeight / 2

    nowLineElement.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    })

    // weekElement.scrollTo({
    //   top: nowY - weekOffset,
    //   behavior: 'smooth'
    // })
  }

  useEffect(() => {
    const diff = parseDate(start).diffNow('weeks').weeks
    const thisWeek = diff > -1 && diff < 0

    if (!thisWeek) {
      console.log(thisWeek)
      return
    }

    scrollToNow()
  }, [start])

  return (
    <StyledCalendar>
      <CalendarDays />
      {view === 'week' && <CalendarWeek />}
      {view === 'month' && <CalendarMonth />}
    </StyledCalendar>
  )
}

export default Calendar
