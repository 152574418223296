import { FC } from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
import { PostErrorType } from '../../../types'
import { Button, Dialog } from '../..'
import { StyledPostErrorsDialog } from '.'

type Props = {
  errors: PostErrorType[]
  isOpen: boolean
  onClose: () => void
}

const PostErrorsDialog: FC<Props> = ({ errors, isOpen, onClose }) => {
  return (
    <Dialog hasXIcon isOpen={isOpen} onClose={() => onClose()}>
      <StyledPostErrorsDialog>
        <div className="dialog-header">
          <div className="dialog-header-icon">
            <ExclamationCircleIcon />
          </div>
          <span className="dialog-header-title">This post has {errors.length} errors</span>
        </div>
        <div className="error-list">
          {errors.map((error, index) => {
            return (
              <div className="error-list-item" key={index}>
                <span>{error.reason}</span>
                {/* <Button variant="destructive" size="sm">
                  Resolve
                </Button> */}
              </div>
            )
          })}
        </div>
      </StyledPostErrorsDialog>
    </Dialog>
  )
}

export default PostErrorsDialog
