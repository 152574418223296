import styled from 'styled-components'

const StyledInvoiceHistoryTable = styled.div`
  margin-top: 20px;
  border-radius: 8px;
  overflow-x: auto;
  box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), 0 0 0 1px rgb(0, 0, 0, 0.05),
    0 3px 4px rgb(0, 0, 0, 0.06);

  .spinner {
    height: 200px;

    svg {
      height: 40px;
      width: 40px;
    }
  }

  .table-container {
    width: fit-content;
  }

  .table-header {
    padding: 0 12px;
    height: 42px;
    user-select: none;
    display: grid;
    align-items: center;
    grid-template-columns: minmax(200px, 1fr) 250px 185px 120px;
    background-color: ${({ theme }) => theme.color.gray[100]};
    border-bottom: 1px solid ${({ theme }) => theme.color.gray[200]};

    .table-column-title {
      color: ${({ theme }) => theme.color.gray[600]};
      font-weight: 500;
      font-size: 15px;
    }
  }

  .table-content {
    .table-row {
      padding: 12px;
      border-bottom: 1px solid ${({ theme }) => theme.color.gray[200]};
      color: ${({ theme }) => theme.color.gray[600]};
      font-size: 15px;
      align-items: center;
      display: grid;
      grid-template-columns: minmax(200px, 1fr) 250px 185px 120px;

      .table-column-data {
        &.invoice-id {
          color: ${({ theme }) => theme.color.gray[800]};
          display: flex;
          font-weight: 500;
          align-items: center;

          .invoice-icon {
            width: 32px;
            margin-right: 12px;
            height: 32px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: ${({ theme }) => theme.color.brandLight};
            color: ${({ theme }) => theme.color.brand};

            svg {
              height: 18px;
            }
          }
        }

        a {
          display: flex;
          align-items: center;
          font-weight: 500;
          text-decoration: none;
          color: ${({ theme }) => theme.color.brand};

          svg {
            height: 24px;
            margin-right: 8px;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.m}px) {
    margin-top: 40px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.l}px) {
    .table-container {
      width: 100%;
    }
  }
`

export default StyledInvoiceHistoryTable
