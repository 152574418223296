import { FC, useCallback, useEffect, useState } from 'react'
import { useActiveObject, useEditor } from '@layerhub-io/react'
import { IStaticText } from '@layerhub-io/types'
import { ReactComponent as AlignmentCenterIcon } from '../../../assets/alignment-center-icon.svg'
import { ReactComponent as AlignmentLeftIcon } from '../../../assets/alignment-left-icon.svg'
import { ReactComponent as AlignmentRightIcon } from '../../../assets/alignment-right-icon.svg'
import { ReactComponent as AlignmentJustifyIcon } from '../../../assets/alignment-justify-icon.svg'
import { DesignerToolButton } from '.'

const DesignerAlignmentTool: FC = () => {
  const editor = useEditor()
  const activeObject = useActiveObject() as IStaticText
  const TEXT_ALIGNS = ['left', 'center', 'right', 'justify']
  const [alignment, setAlignment] = useState('center')

  const changeAlign = useCallback(() => {
    const currIndex = TEXT_ALIGNS.indexOf(alignment)

    if (currIndex === TEXT_ALIGNS.length - 1) {
      setAlignment(TEXT_ALIGNS[0])
      editor.objects.update({ textAlign: TEXT_ALIGNS[0] })
      return
    }

    setAlignment(TEXT_ALIGNS[currIndex + 1])
    editor.objects.update({ textAlign: TEXT_ALIGNS[currIndex + 1] })
  }, [editor, alignment])

  useEffect(() => {
    if (!activeObject?.textAlign) {
      return
    }

    setAlignment(activeObject.textAlign)
  }, [activeObject])

  return (
    <DesignerToolButton data-tooltip="Alignment" isIcon onClick={() => changeAlign()}>
      {alignment === 'left' && <AlignmentLeftIcon />}
      {alignment === 'center' && <AlignmentCenterIcon />}
      {alignment === 'right' && <AlignmentRightIcon />}
      {alignment === 'justify' && <AlignmentJustifyIcon />}
    </DesignerToolButton>
  )
}

export default DesignerAlignmentTool
