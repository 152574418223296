import { xor } from 'lodash'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { PhotoType } from '../../types'

type InitialState = {
  photos: PhotoType[]
  search: string
  liked: boolean
  sort: string | null
  colors: string[]
  orientation?: string | null
  categories: string[]
  style: string[]
  page: number
  loading: boolean
  totalDocs: number
  photo?: string
}

const initialState: InitialState = {
  photos: [],
  search: '',
  liked: false,
  sort: 'for-you',
  colors: [],
  orientation: null,
  categories: [],
  style: [],
  page: 0,
  loading: true,
  totalDocs: 0
}

export const photosSlice = createSlice({
  name: 'photos',
  initialState,
  reducers: {
    setPhotos: (state, action: PayloadAction<PhotoType[]>) => {
      state.photos = action.payload
      state.loading = false
    },
    addImage: (state, action: PayloadAction<PhotoType>) => {
      state.photos = state.photos.concat([action.payload])
    },
    updateImage: (state, action: PayloadAction<PhotoType>) => {
      state.photos = state.photos.map((image) => {
        if (image._id === action.payload._id) {
          return action.payload
        }

        return image
      })
    },
    removeImage: (state, action: PayloadAction<string>) => {
      state.photos = state.photos.filter((image) => image._id !== action.payload)
    },
    setPhotosLiked: (state, action: PayloadAction<{ isLiked: boolean; _id: string }>) => {
      state.photos = state.photos.map((image) => {
        if (image._id === action.payload._id) {
          return {
            ...image,
            liked: !action.payload.isLiked
              ? {
                  _id: 'liked',
                  dateLike: '',
                  image: '',
                  index: 0,
                  user: ''
                }
              : {
                  _id: '',
                  dateLike: '',
                  image: '',
                  index: 0,
                  user: ''
                }
          }
        }

        return image
      })
    },
    setPhotosUsed: (state, action: PayloadAction<{ isUsed: boolean; _id: string }>) => {
      state.photos = state.photos.map((image) => {
        if (image._id === action.payload._id) {
          return {
            ...image,
            isUsed: action.payload.isUsed
          }
        }

        return image
      })
    },
    clearPhotos: (state) => {
      state.photos = []
      state.loading = true
      state.page = 0
    },
    setPhotosQueryState: (state, action: PayloadAction<Partial<InitialState>>) => {
      return (state = {
        ...state,
        ...action.payload,
        photos: [],
        loading: true
      })
    },
    setPhotosQuery: (state, action: PayloadAction<string>) => {
      state.search = action.payload
      state.photo = ''
      state.photos = []
      state.loading = true
      state.page = 0
    },
    setPhotosFavoritesFilter: (state, action: PayloadAction<boolean>) => {
      state.liked = action.payload
      state.photo = ''
      state.photos = []
      state.loading = true
      state.page = 0
    },
    togglePhotosCategoryFilter: (state, action: PayloadAction<string>) => {
      state.categories = xor(state.categories, [action.payload])
      state.photo = ''
      state.photos = []
      state.loading = true
      state.page = 0
    },
    setPhotosSort: (state, action: PayloadAction<string>) => {
      state.sort = action.payload
      state.photo = ''
      state.photos = []
      state.loading = true
      state.page = 0
    },
    setPhotosOrientation: (state, action: PayloadAction<string | undefined>) => {
      state.orientation = action.payload
      state.photo = ''
      state.photos = []
      state.loading = true
      state.page = 0
    },
    setPhotosColor: (state, action: PayloadAction<string>) => {
      state.colors = xor(state.colors, [action.payload])
      state.photo = ''
      state.photos = []
      state.loading = true
      state.page = 0
    },
    clearPhotosColors: (state) => {
      state.colors = []
      state.photos = []
      state.loading = true
      state.page = 0
    },
    setPhotosStyle: (state, action: PayloadAction<string>) => {
      state.style = xor(state.style, [action.payload])
      state.photo = ''
      state.photos = []
      state.loading = true
      state.page = 0
    },
    clearPhotosStyle: (state) => {
      state.style = []
      state.photo = ''
      state.photos = []
      state.loading = true
      state.page = 0
    },
    changePhotosPage: (state, action: PayloadAction<number>) => {
      state.loading = true
      state.page = action.payload
    },
    changePhotosTotalDocs: (state, action: PayloadAction<number>) => {
      state.totalDocs = action.payload
    }
  }
})

export const {
  setPhotos,
  addImage,
  updateImage,
  removeImage,
  setPhotosLiked,
  setPhotosUsed,
  clearPhotos,
  setPhotosQueryState,
  setPhotosQuery,
  changePhotosPage,
  setPhotosFavoritesFilter,
  togglePhotosCategoryFilter,
  setPhotosSort,
  changePhotosTotalDocs,
  setPhotosColor,
  setPhotosOrientation,
  setPhotosStyle,
  clearPhotosColors,
  clearPhotosStyle
} = photosSlice.actions
export default photosSlice.reducer
