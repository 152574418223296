import styled from 'styled-components'

const StyledPublishDialog = styled.div`
  box-sizing: border-box;
  max-width: 520px;
  user-select: none;
  display: grid;
  grid-gap: 36px;
  padding: 20px;

  .dialog-header {
    .dialog-title {
      font-size: 24px;
      font-weight: 500;
      color: ${({ theme }) => theme.color.gray[800]};
      display: block;
    }

    .dialog-subtitle {
      margin-top: 8px;
      color: ${({ theme }) => theme.color.gray[600]};
      display: block;
    }
  }

  .dialog-content {
    display: grid;
    grid-gap: 26px;

    .dialog-field-heading {
      span {
        display: block;
      }

      .dialog-field-title {
        font-size: 16px;
        font-weight: 500;
        color: ${({ theme }) => theme.color.gray[700]};
      }
    }

    .dialog-field-subtitle {
      margin-top: 10px;
      font-size: 14px;
      color: ${({ theme }) => theme.color.gray[500]};
      display: block;
    }
  }

  .dialog-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    width: 100vw;
    padding: 34px;
  }
`

export default StyledPublishDialog
