import styled from 'styled-components'

const StyledCreateTextarea = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;

  .input {
    min-height: 100%;
    border-radius: 0;
    padding: 16px;
    width: 100%;
    background-color: transparent;
    resize: none;
    box-sizing: border-box;
    color: ${({ theme }) => theme.color.gray[800]};
  }
`

export default StyledCreateTextarea
