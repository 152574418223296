import styled from 'styled-components'

const StyledStoryListItem = styled.div`
  position: relative;
  height: fit-content;

  .story-list-item-preview {
    padding-top: 178%;
    border-radius: 4px;
    position: relative;

    .story-list-item-img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
      user-select: none;
    }

    &::after {
      user-select: none;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      box-shadow: inset 0 0 0 1px rgb(0, 0, 0, 0.1);
      z-index: 1;
    }

    .story-list-item-tags {
      position: absolute;
      top: 6px;
      right: 6px;
      display: flex;
      align-items: center;
      z-index: 1;

      .story-list-item-tag {
        display: flex;
        align-items: center;
        box-shadow: 0 0 0 1px rgb(0, 0, 0, 0.1);
        background-color: ${({ theme }) => theme.color.background};
        color: ${({ theme }) => theme.color.gray[700]};
        padding: 0 8px;
        height: 24px;
        border-radius: 50rem;
        font-size: 14px;
        font-weight: 500;

        &:not(:last-child) {
          margin-right: 6px;
        }

        &.canva {
          padding: 0 2px;

          img {
            height: 20px;
            width: 20px;
          }
        }

        &.count {
          svg {
            height: 16px;
            margin-right: 4px;
          }
        }
      }
    }
  }

  .story-list-item-details {
    padding-top: 10px;

    span {
      display: block;
    }

    .story-list-item-title {
      color: ${({ theme }) => theme.color.gray[800]};
      font-weight: 600;
      font-size: 15px;
    }

    .story-list-item-description {
      font-size: 15px;
      margin-top: 2px;
      color: ${({ theme }) => theme.color.gray[600]};
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: -8px;
    left: -8px;
    width: calc(100% + 16px);
    height: calc(100% + 16px);
    object-fit: cover;
    background-color: rgb(0, 0, 0, 0.08);
    border-radius: 10px;
    user-select: none;
    transform: scale(0.5);
    opacity: 0;
    transition: 0.15s;
    z-index: -1;
  }

  &:hover {
    cursor: pointer;

    &::after {
      transform: scale(1);
      opacity: 1;
    }
  }
`

export default StyledStoryListItem
