import styled from 'styled-components'

const StyledPillarList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  width: 100%;
  margin: 0 auto;

  .add-btn {
    min-height: 122.5px;
    user-select: none;
    max-width: 412px;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
    font-size: 15px;
    font-weight: 500;
    transition: 0.2s;
    background-color: ${({ theme }) => theme.color.gray[100]};
    color: ${({ theme }) => theme.color.gray[500]};

    svg {
      height: 20px;
      stroke-width: 2px;
      margin-right: 6px;
    }

    &:hover {
      background-color: ${({ theme }) => theme.color.gray[200]};
    }
  }
`

export default StyledPillarList
