import { FC, useContext, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import firebase from 'firebase/app'
import { isEqual } from 'lodash'
import { styled } from 'styled-components'
import { Intercom } from '@capacitor-community/intercom'
import { Keyboard } from '@capacitor/keyboard'
import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid'
import { AGES, OCCUPATIONS, VOICES, useForm, userStore } from '../../../../utils'
import { Input, Select, ShiftDrawer, Spinner } from '../..'
import { StyledBusinessDrawer } from '.'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const BusinessDrawer: FC<Props> = ({ isOpen, onClose }) => {
  const updateLoggedInUser = firebase.functions().httpsCallable('updateLoggedInUser')
  const [canSave, setCanSave] = useState(false)
  const [loading, setLoading] = useState(false)
  const { user, refresh } = useContext(userStore)
  const { form, updateFormValue } = useForm({})

  const handleSubmit = async () => {
    try {
      setLoading(true)

      await updateLoggedInUser({
        aiOnboarding: {
          use: form.use,
          occupation: form.occupation,
          company: form.company,
          ages: form.ages,
          genders: form.genders,
          occupations: form.occupations,
          voices: form.voices,
          interests: form.interests
        }
      })

      await Intercom.updateUser({
        customAttributes: {
          aiOnboarding: {
            use: form.use,
            occupation: form.occupation,
            company: form.company,
            ages: form.ages,
            genders: form.genders,
            occupations: form.occupations,
            voices: form.voices,
            interests: form.interests
          }
        }
      })

      toast.success('Updated business information')
      await refresh()
    } catch (err) {
      toast.error((err as any)?.message || err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!user) {
      return
    }

    const madeChanges = !isEqual(form, {
      ...user.aiOnboarding
    })

    setCanSave(madeChanges)
  }, [user, form])

  useEffect(() => {
    if (!user) {
      return
    }

    updateFormValue({
      ...user.aiOnboarding
    })
  }, [user, isOpen])

  const SaveButton: FC = () => {
    return (
      <StyledSaveButton disabled={!canSave || loading} onClick={() => handleSubmit()}>
        {!loading ? 'Save' : <Spinner />}
      </StyledSaveButton>
    )
  }

  const StyledSaveButton = styled.button`
    height: 36px;
    color: ${({ theme }) => theme.color.brand};
    background-color: transparent;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;

    &:disabled {
      color: ${({ theme }) => theme.color.gray[400]};
    }
  `

  return (
    <ShiftDrawer
      title="My business/brand"
      headerBtn={<SaveButton />}
      isOpen={isOpen}
      onClose={onClose}
    >
      <StyledBusinessDrawer>
        <div className="disclaimer">
          <QuestionMarkCircleIcon />
          <div className="disclaimer-content">
            <b>Why do you need this?</b>
            <span>
              Dottie uses this information to create truly personalized & unique posts that resonate
              with your target audience.
            </span>
          </div>
        </div>
        <div className="inputs">
          <Input
            name="company"
            label="Business/brand name"
            placeholder="Business/brand name"
            type="text"
            enterKeyHint="done"
            value={form.company}
            onChange={(value) => updateFormValue('company', value)}
            onKeyDown={(e) => e.key === 'Enter' && Keyboard.hide()}
          />
          <Select
            options={VOICES.map((voice) => ({ label: voice.label, value: voice.label }))}
            name="voices"
            multiple
            label="Voice(s)"
            placeholder="Voice(s)"
            value={form.voices}
            onChange={(value) => updateFormValue('voices', value)}
          />
          <Select
            options={AGES.map((a) => ({ value: a, label: a }))}
            name="ages"
            multiple
            label="Target audience's age(s)"
            placeholder="Target audience's age(s)"
            value={form.ages}
            onChange={(value) => {
              updateFormValue('ages', value)
            }}
          />
          <Select
            options={[
              { label: 'Men', value: 'Men' },
              { label: 'Women', value: 'Women' }
            ]}
            name="genders"
            multiple
            label="Target audience's gender(s)"
            placeholder="Target audience's gender(s)"
            value={form.genders}
            onChange={(value) => {
              updateFormValue('genders', value)
            }}
          />
          <Select
            options={OCCUPATIONS.map((o) => ({ value: o, label: o }))}
            name="occupations"
            multiple
            label="Target audience's occupation(s)"
            placeholder="Target audience's occupation(s)"
            value={form.occupations}
            onChange={(value) => updateFormValue('occupations', value)}
          />
        </div>
      </StyledBusinessDrawer>
    </ShiftDrawer>
  )
}

export default BusinessDrawer
