import { Capacitor } from '@capacitor/core'
import { theme } from '../theme'

export const isMobile = () => {
  const isCapacitorApp = Capacitor.isNativePlatform()
  const isMobileBrowser = (navigator as any)?.userAgentData?.mobile
  const smallScreen = window.matchMedia(`(max-width: ${theme.breakpoint.s}px)`).matches

  return isCapacitorApp || isMobileBrowser || smallScreen
}
