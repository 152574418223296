import { FC, useContext } from 'react'
import { socialProfileByStatus, userStore } from '../../../../utils'
import { SocialProfileListItem } from '..'
import { StyledSocialProfileList } from '.'

type Props = {
  selectedProfiles: string[]
  onSelect: (_id: string) => void
}

const SocialProfileList: FC<Props> = ({ selectedProfiles, onSelect }) => {
  const { user } = useContext(userStore)

  return (
    <StyledSocialProfileList>
      {socialProfileByStatus(['active', 'expired'], user?.socialProfiles || []).map(
        (socialProfile) => {
          return (
            <SocialProfileListItem
              profile={socialProfile}
              isSelected={selectedProfiles.includes(socialProfile._id || '')}
              onSelect={() => onSelect(socialProfile._id || '')}
              key={socialProfile._id}
            />
          )
        }
      )}
    </StyledSocialProfileList>
  )
}

export default SocialProfileList
