import { FC, createRef, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Capacitor } from '@capacitor/core'
import { CommunityDialog, Spinner } from '../../components'
import { ROUTES, conditionalStage, useCommunity, userStore } from '../../utils'
import { StyledCommunityView } from '.'

const CommunityView: FC = () => {
  const history = useHistory()
  const monthlyBlockedPages = ['/c/class-replays/']
  const { user } = useContext(userStore)
  const {
    actions: { identifyCommunityUser }
  } = useCommunity()
  const [showCommunityDialog, setShowCommunityDialog] = useState(false)
  const iframeRef = createRef<HTMLIFrameElement>()

  const handleDialogClose = () => {
    history.push(ROUTES.community)
    if (iframeRef?.current) {
      // const cleanedPath = history.location.pathname.split('/community/')[1]
      iframeRef.current.src = `https://hello.community.socialcurator.com/?iframe=true`
    }

    setShowCommunityDialog(false)
  }

  const handleIframeMessage = (e: MessageEvent) => {
    const { event, title, url, path } = e.data

    if (event !== 'locationchange') {
      return
    }

    if (url && url.includes('hello.community.socialcurator')) {
      return window.location.replace(url)
    }

    if (url) {
      const formattedUrl = url.replace('hello.', 'app.')
      return window.location.replace(formattedUrl)
    }

    window.document.title = title

    if (!!path && user?.subscriptionInterval === 'Month' && monthlyBlockedPages.includes(path)) {
      return setShowCommunityDialog(true)
    }

    if (path) {
      history.push(`/community${path}`)
    }
  }

  useEffect(() => {
    identifyCommunityUser()

    window.addEventListener('message', handleIframeMessage, false)

    return () => {
      window.removeEventListener('message', handleIframeMessage, false)
    }
  }, [])

  useEffect(() => {
    const iframe = iframeRef.current

    if (!iframe) {
      return
    }

    const cleanedPath = history.location.pathname.split('/community/')[1]
    iframe.src = `https://hello.community.socialcurator.com/${cleanedPath || '?iframe=true'}`
  }, [iframeRef.current])

  useEffect(() => {
    document.querySelector(`meta[name="apple-itunes-app"]`)?.remove()
  }, [])

  if (Capacitor.isNativePlatform()) {
    return null
  }

  return (
    <StyledCommunityView>
      <div className="loading-screen">
        <Spinner />
        Loading community
      </div>
      <iframe
        title="Community"
        ref={iframeRef}
        allow="camera;microphone;display-capture;fullscreen"
        src="https://hello.community.socialcurator.com/c/home?automatic_login=true"
        {...conditionalStage(showCommunityDialog, { style: { pointerEvents: 'none' } })}
      />
      <CommunityDialog isOpen={showCommunityDialog} onClose={() => handleDialogClose()} />
    </StyledCommunityView>
  )
}

export default CommunityView
