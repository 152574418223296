import { toast } from 'react-hot-toast'
import { DateTime } from 'luxon'
import { useApolloClient } from '@apollo/client'
import { chatRetryMessage } from '../../graphql'
import {
  setChatLoading,
  setChatRef,
  setChatTempTemplate,
  updateChatMessage,
  useAppDispatch,
  useAppSelector
} from '../../redux'
import { ChatMessageType } from '../../types'
import { useChat, useSegment } from '.'

export const useChatMessage = () => {
  const apollo = useApolloClient()
  const dispatch = useAppDispatch()
  const { sendMessage, highlightMessage, addMessageToPost } = useChat()
  const { track } = useSegment()
  const { post } = useAppSelector((state) => state.create)
  const { persona } = useAppSelector((state) => state.chat)

  const tryAgain = async (message: ChatMessageType) => {
    try {
      dispatch(setChatLoading(true))

      const { data } = await apollo.mutate({
        fetchPolicy: 'no-cache',
        mutation: chatRetryMessage,
        variables: {
          _id: message._id,
          persona,
          date: DateTime.now().toISO()
        }
      })

      const reply = data.chatRetryMessage
      dispatch(updateChatMessage({ _id: reply._id, message: reply }))

      track('Retried Message', { ...message, is_chat: true, post_id: post?._id })
      highlightMessage(reply._id)
    } catch (err) {
      console.error(err)
      toast.error((err as any)?.message)
    } finally {
      dispatch(setChatLoading(false))
      dispatch(setChatTempTemplate(undefined))
      dispatch(setChatRef(undefined))
    }
  }

  const remix = async (message: ChatMessageType, template?: string, category?: string) => {
    if (!category && !template) {
      dispatch(setChatRef(message._id))
      return
    }

    let content = ''

    if (template === 'photo') {
      content = 'Find a related photo'
    }

    if (template === 'video') {
      content = 'Find a related video'
    }

    if (template === 'hashtags') {
      content = 'Suggest hashtags'
    }

    if (template === 'video-script') {
      content = 'Turn into a video script'
    }

    if (template === 'visual-ideas') {
      content = 'Find visual ideas'
    }

    if (template === 'caption') {
      content = 'Generate a caption'
    }

    if (category === 'shorter') {
      content = 'Make it shorter'
    }

    if (category === 'longer') {
      content = 'Make it longer'
    }

    if (category === 'professional') {
      content = 'Rewrite it in a professional tone'
    }

    if (category === 'neutral') {
      content = 'Rewrite it in a neutral tone'
    }

    if (category === 'casual') {
      content = 'Rewrite it in a casual tone'
    }

    if (category === 'funny') {
      content = 'Make it funnier'
    }

    await sendMessage(content, template, message._id)
  }

  return {
    addToPost: addMessageToPost,
    tryAgain,
    remix
  }
}
