import { FC } from 'react'
import Sheet from 'react-modal-sheet'
import { ConeIcon } from '../../../../assets'
import { StyledComingSoonSheet } from '.'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const ComingSoonSheet: FC<Props> = ({ isOpen, onClose }) => {
  return (
    <Sheet detent="content-height" isOpen={isOpen} onClose={onClose}>
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>
          <StyledComingSoonSheet>
            <div className="icon">
              <ConeIcon />
            </div>
            <h1>
              This feature is
              <br />
              coming soon
            </h1>
            <h2>We're working hard to get you everything you need.</h2>
            <button onClick={() => onClose()}>Sounds good</button>
          </StyledComingSoonSheet>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={() => onClose()} />
    </Sheet>
  )
}

export default ComingSoonSheet
