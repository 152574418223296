import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { SplitsType } from '../../types'

type initialState = {
  splits: {
    [x in SplitsType]?: 'on' | 'off' | 'control'
  }
}

const initialState: initialState = {
  splits: {}
}

export const splitsSlice = createSlice({
  name: 'splits',
  initialState,
  reducers: {
    setSplits: (state, action: PayloadAction<{ [x: string]: string }>) => {
      state.splits = action.payload
    }
  }
})

export const { setSplits } = splitsSlice.actions
export default splitsSlice.reducer
