import { FC, useContext, useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import { Capacitor } from '@capacitor/core'
import { FaceFrownIcon } from '@heroicons/react/20/solid'
import { BoltIcon } from '@heroicons/react/24/solid'
import { pushToDialogStack, useAppDispatch } from '../../../redux'
import { useSubscription, userStore } from '../../../utils'
import { Button } from '../..'
import { StyledTrialCta } from '.'

const TrialCta: FC = () => {
  const dispatch = useAppDispatch()
  const { plan } = useSubscription()
  const { user } = useContext(userStore)
  const [trialEndDate, setTrialEndDate] = useState('')

  const getTrialEndDate = async () => {
    if (!plan) {
      return
    }

    const endDate = DateTime.fromSeconds(plan.endDate)
    const relativeDate = endDate.toRelative()?.replace('in', '').trimStart()

    setTrialEndDate(relativeDate || '')
  }

  useEffect(() => {
    if (!!user?.trial) {
      getTrialEndDate()
    }
  }, [user?.trial, plan?.endDate])

  if (!user?.trial || !trialEndDate) {
    return null
  }

  if (Capacitor.isNativePlatform()) {
    return null
  }

  return (
    <>
      <StyledTrialCta className="trial-cta">
        <div className="trial-cta-details">
          <div className="trial-cta-header">
            <FaceFrownIcon />
            <h3>
              <b>{trialEndDate} left</b> until it's time for goodbye
            </h3>
          </div>
          <span>Want to keep Dottie, your posts, uploaded media and more? Go Pro.</span>
        </div>
        <Button
          variant="emphasis"
          iconPos="left"
          onClick={() => dispatch(pushToDialogStack('go-pro'))}
        >
          <BoltIcon />
          Go Pro
        </Button>
      </StyledTrialCta>
    </>
  )
}

export default TrialCta
