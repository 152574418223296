import { FC } from 'react'
import { times } from 'lodash'
import { DateTime } from 'luxon'
import { DragOverlay, useDndContext } from '@dnd-kit/core'
import { useAppSelector } from '../../../redux'
import { CalendarMonthSlot, CalendarPostItem } from '..'
import { StyledCalendarMonth } from '.'

const CalendarMonth: FC = () => {
  const { active } = useDndContext()
  const { start } = useAppSelector((state) => state.calendar)

  const getStartDay = (initial: number) => {
    if (initial === 6) {
      return 0
    }

    return initial + 1
  }

  const getRowLength = () => {
    const startDate = DateTime.fromISO(start)
    const month = startDate.startOf('month')
    const daysInMonth = month.daysInMonth
    const startDay = month.weekday - 1 // 0 - 6
    const offset = daysInMonth + getStartDay(startDay)

    return Math.ceil(offset / 7)
  }

  return (
    <>
      <StyledCalendarMonth>
        {times(getRowLength(), (week) => {
          return (
            <div className="calendar-month-row">
              {times(7, (day: number) => {
                const startDate = DateTime.fromISO(start)
                const month = startDate.startOf('month')
                const startDay = month.weekday - 1 // 0 - 6
                const offset = day - getStartDay(startDay)

                const date = startDate
                  .startOf('month')
                  .startOf('day')
                  .plus({ days: 7 * week + offset })

                const monthDiff = date.diff(month, 'months').months

                if (monthDiff < 1 && monthDiff >= 0) {
                  return <CalendarMonthSlot date={date} key={`${week}-${day}`} />
                }

                return <div className="empty-slot" />
              })}
            </div>
          )
        })}
      </StyledCalendarMonth>
      {active && !active?.data?.current?.isSidebarItem && (
        <DragOverlay>
          <CalendarPostItem post={active?.data?.current?.post} isCollapsed />
        </DragOverlay>
      )}
    </>
  )
}

export default CalendarMonth
