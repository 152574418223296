import { FC, FormEvent, useState } from 'react'
import { Helmet } from 'react-helmet'
import { toast } from 'react-hot-toast'
import { Link } from 'react-router-dom'
import firebase from 'firebase/app'
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import { ReactComponent as SCLogoIcon } from '../../assets/sc-logo-icon.svg'
import { AuthLayout } from '../../components'
import { ROUTES } from '../../utils'
import { StyledForgotPasswordView } from '.'

const ForgotPasswordView: FC = () => {
  const emailExists = firebase.functions().httpsCallable('emailExists')
  const loginMigration = firebase.functions().httpsCallable('confirmPasswordMigration')
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [isSent, setIsSent] = useState(false)

  const checkIfEmailExists = async (email: string) => {
    const signInMethods = await firebase.auth().fetchSignInMethodsForEmail(email)
    const emailResult = await emailExists({ email })
    const { emailFound } = emailResult.data

    if (signInMethods.length || emailFound) {
      return true
    }

    return false
  }

  const handleSubmit = async (e?: FormEvent) => {
    try {
      e?.preventDefault()
      setLoading(true)

      if (!email.trim()) {
        return
      }

      const exists = await checkIfEmailExists(email)

      if (!exists) {
        throw 'There is no user with that email address.'
      }

      await loginMigration({ email })
      await firebase.auth().sendPasswordResetEmail(email)

      setIsSent(true)
    } catch (err) {
      console.error(err)
      toast.error((err as any)?.message || err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <AuthLayout>
      <Helmet title="Forgot Password | Social Curator" />
      <StyledForgotPasswordView>
        <div className="auth-screen" data-screen="forgot-password" data-active={true}>
          <div className="auth-header">
            <h1>Forgot Password</h1>
            <h3>
              Enter the email associated with your account and we'll send an email with instructions
              on how to reset your password.
            </h3>
          </div>
          <form autoComplete="on" onSubmit={(e) => handleSubmit(e)}>
            <div className="auth-inputs">
              <label htmlFor="email" className="input-container">
                <div className="input-label">Email address</div>
                <input
                  id="email"
                  value={email}
                  disabled={isSent}
                  placeholder="hello@socialcurator.com"
                  required
                  type="email"
                  name="email"
                  enterKeyHint="next"
                  onKeyDown={(e) => e.key === 'Enter' && handleSubmit()}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </label>
            </div>
            {isSent && (
              <div className="sent-indicator">
                <CheckCircleIcon />
                Sent Email
              </div>
            )}
            {!isSent && (
              <button type="submit" className="submit-btn" disabled={loading}>
                {!loading && 'Send Instructions'}
                {loading && <SCLogoIcon className="logo-spinner" />}
              </button>
            )}
          </form>
          <div className="form-subtext">
            <Link to={ROUTES.auth.login}>Go back to login</Link>
          </div>
        </div>
      </StyledForgotPasswordView>
    </AuthLayout>
  )
}

export default ForgotPasswordView
