import styled from 'styled-components'

const StyledAcademyView = styled.div`
  height: 100%;

  iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }

  .sidebar {
    display: none;
  }

  @media (min-width: 700px) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
  }
`

export default StyledAcademyView
