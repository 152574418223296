import { FC, useContext } from 'react'
import { PlusIcon } from '@heroicons/react/24/outline'
import { setShowConnectionsModal, useAppDispatch } from '../../../redux'
import { ConnectionAvatar, connectionStore } from '../..'
import { StyledSidebarConnectionsButton } from '.'

const SidebarConnectionsButton: FC = () => {
  const dispatch = useAppDispatch()
  const { connectedProfiles, hasConnections } = useContext(connectionStore)

  return (
    <StyledSidebarConnectionsButton
      data-has-connections={hasConnections}
      onClick={() => dispatch(setShowConnectionsModal(true))}
    >
      {!hasConnections && (
        <>
          Connect Profile
          <PlusIcon className="plus-icon" />
        </>
      )}
      {hasConnections && (
        <>
          Connections
          <div className="profile-list">
            {connectedProfiles.slice(0, 2).map((profile) => (
              <ConnectionAvatar profile={profile} size={28} inRow />
            ))}
            {connectedProfiles.length > 2 && (
              <div className="plus-bubble">+{connectedProfiles.length - 3}</div>
            )}
          </div>
        </>
      )}
    </StyledSidebarConnectionsButton>
  )
}

export default SidebarConnectionsButton
