import { FC, useState } from 'react'
import { Dialog } from '@capacitor/dialog'
import { TrashIcon } from '@heroicons/react/24/outline'
import { SocialProfileType } from '../../../../types'
import {
  formatUsername,
  getPlatformDescription,
  getPlatformIcon,
  getPlatformTitle,
  useConnection,
  useHaptics
} from '../../../../utils'
import { SocialProfileAvatar } from '..'
import { StyledSocialProfileListItem } from '.'

type Props = {
  profile: SocialProfileType
  isSelected?: boolean
  isDisabled?: boolean
  isDisconnectable?: boolean
  isReconnectable?: boolean
  onSelect?: () => void
}

const SocialProfileListItem: FC<Props> = ({
  profile,
  isSelected,
  isDisabled,
  isDisconnectable,
  isReconnectable,
  onSelect
}) => {
  const [loading, setLoading] = useState(false)
  const { trigger } = useHaptics()
  const { disconnectProfile, reconnectProfile } = useConnection()

  const handleDisconnect = async () => {
    const { value: confirmed } = await Dialog.confirm({
      title: 'Disconnect profile',
      message: `Are you sure you want to disconnect ${formatUsername(
        profile.username,
        profile.type
      )}`
    })

    if (!confirmed) {
      return
    }

    setLoading(true)
    await disconnectProfile(profile)
  }

  const handleReconnect = async () => {
    setLoading(true)
    await reconnectProfile(profile)
    setLoading(false)
  }

  const handleClick = () => {
    if (onSelect && !isDisabled) {
      onSelect()
      trigger('impact-light')
    }
  }

  return (
    <StyledSocialProfileListItem isDisabled={isDisabled} onClick={() => handleClick()}>
      <div className="social-profile-details-container">
        <SocialProfileAvatar profile={profile} />
        <div className="social-profile-details">
          <span className="social-profile-username">
            {formatUsername(profile.username, profile.type)}
          </span>
          <div className="social-profile-plaform">
            {getPlatformIcon(profile.type)}
            {getPlatformTitle(profile.type)} {getPlatformDescription(profile.type)}
          </div>
        </div>
      </div>
      <div className="social-profile-actions">
        {onSelect && (
          <button data-toggled={isSelected} className="social-profile-toggle">
            <div />
          </button>
        )}
        {isDisconnectable && (
          <button disabled={loading} className="disconnect-btn" onClick={() => handleDisconnect()}>
            <TrashIcon />
          </button>
        )}
      </div>
    </StyledSocialProfileListItem>
  )
}

export default SocialProfileListItem
