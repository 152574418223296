import { FC, createRef, useContext, useEffect } from 'react'
import { DateTime } from 'luxon'
import { CheckCircleIcon, EyeIcon } from '@heroicons/react/20/solid'
import {
  Bars3BottomLeftIcon,
  DocumentTextIcon,
  HashtagIcon,
  LightBulbIcon,
  PhotoIcon,
  VideoCameraIcon
} from '@heroicons/react/24/outline'
import {
  setChatTemplate,
  setShowChatTemplates,
  useAppDispatch,
  useAppSelector
} from '../../../redux'
import { useSegment, userStore } from '../../../utils'
import { ChatConversationListItem } from '..'
import { StyledChatConversationList } from '.'

const ChatConversationList: FC = () => {
  const scrollRef = createRef<HTMLDivElement>()
  const dispatch = useAppDispatch()
  const { user } = useContext(userStore)
  const { track } = useSegment()
  const { messages, template, showTemplates } = useAppSelector((state) => state.chat)

  const handlePromptTemplate = (temp: typeof template) => {
    document.getElementById('chat-bot-input')?.focus()
    dispatch(setChatTemplate(temp))

    track('Clicked Chat Template', {
      template
    })
  }

  useEffect(() => {
    scrollRef.current?.scrollTo({
      top: 1000000
    })
  }, [scrollRef.current, messages.length])

  return (
    <StyledChatConversationList ref={scrollRef}>
      <ChatConversationListItem
        message={{
          _id: 'test',
          content: `Hey ${user?.displayName.split(' ')[0]}. How can I help you today?`,
          conversation: 'test',
          createdAt: DateTime.now().toISO(),
          type: 'received',
          prompt: '',
          status: 'error'
        }}
        key="Start message"
      />
      <ChatConversationListItem
        message={{
          _id: 'test-2',
          content: `Start typing or select a template below.`,
          conversation: 'test',
          createdAt: DateTime.now().toISO(),
          type: 'received',
          prompt: '',
          status: 'error'
        }}
        isSub
        key="Start message x2"
      />
      {!messages.length && (
        <div className="prompt-list">
          <button
            data-active={template === 'caption'}
            data-chat-onboarding="0"
            onClick={() => handlePromptTemplate('caption')}
          >
            {template !== 'caption' ? <DocumentTextIcon /> : <CheckCircleIcon />}
            Generate a caption
          </button>
          <button
            data-active={template === 'hashtags'}
            // disabled={showEducationalOnboarding}
            onClick={() => handlePromptTemplate('hashtags')}
          >
            {template !== 'hashtags' ? <HashtagIcon /> : <CheckCircleIcon />}
            Recommend hashtags
          </button>
          <button
            data-active={template === 'video-script'}
            // disabled={showEducationalOnboarding}
            onClick={() => handlePromptTemplate('video-script')}
          >
            {template !== 'video-script' ? <VideoCameraIcon /> : <CheckCircleIcon />}
            Write a video script
          </button>
          <details
            className="chat-template-select"
            onClick={() => dispatch(setShowChatTemplates(!showTemplates))}
          >
            <summary className="chat-template-select">
              <EyeIcon />
              See all templates
            </summary>
            {/* <div className="all-templates-list">
              <button
                data-active={template === 'photo'}
                // disabled={showEducationalOnboarding}
                onClick={() => handlePromptTemplate('photo')}
              >
                {template !== 'photo' ? <PhotoIcon /> : <CheckCircleIcon />}
                Suggest a gallery photo
              </button>
              <button
                data-active={template === 'visual-ideas'}
                // disabled={showEducationalOnboarding}
                onClick={() => handlePromptTemplate('visual-ideas')}
              >
                {template !== 'visual-ideas' ? <LightBulbIcon /> : <CheckCircleIcon />}
                Find visual ideas
              </button>
              <button
                data-active={template === 'blanks'}
                // disabled={showEducationalOnboarding}
                onClick={() => handlePromptTemplate('blanks')}
              >
                {template !== 'blanks' ? <Bars3BottomLeftIcon /> : <CheckCircleIcon />}
                Fill in caption blanks
              </button>
              <button
                data-active={template === 'live-video-outline'}
                // disabled={showEducationalOnboarding}
                onClick={() => handlePromptTemplate('live-video-outline')}
              >
                {template !== 'live-video-outline' ? <PhotoIcon /> : <CheckCircleIcon />}
                Write a live video outline
              </button>
            </div> */}
          </details>
        </div>
      )}
      {messages.map((message) => {
        return <ChatConversationListItem message={message} key={message._id} />
      })}
    </StyledChatConversationList>
  )
}

export default ChatConversationList
