import styled from 'styled-components'

const StyledActionPlanView = styled.div`
  height: 100%;
  overflow-y: auto;
  position: relative;

  .video-player {
    padding-top: 56.25%;
    background: black;
    position: relative;

    .back-btn {
      position: absolute;
      top: 8px;
      left: 8px;
      height: 32px;
      border-radius: 4px;
      font-weight: 500;
      padding: 0 12px 0 6px;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #000000e6;
      color: white;

      svg {
        height: 20px;
        stroke-width: 2px;
        position: relative;
        margin-right: 2px;
      }
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      border: 0;
      padding-top: 100;
    }

    &::after {
      content: '';
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      box-shadow: inset 0 1px rgb(0, 0, 0, 0.1);
    }
  }

  .details {
    padding: 14px;

    h1 {
      font-family: ${({ theme }) => theme.typeface.garnett};
      font-size: 20px;
      line-height: 28px;
    }

    .description {
      line-height: 20px;
      margin-top: 6px;
      font-size: 15px;
      white-space: break-spaces;
      color: ${({ theme }) => theme.color.gray[600]};

      a {
        color: ${({ theme }) => theme.color.brand};
      }

      p:not(:first-child) {
        margin-top: 10px;
      }
    }

    .action-plan-btn {
      margin-top: 14px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      width: 100%;
      font-weight: 600;
      color: ${({ theme }) => theme.color.gray[800]};
      background-color: ${({ theme }) => theme.color.gray[200]};
    }
  }
`

export default StyledActionPlanView
