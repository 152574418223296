import styled from 'styled-components'

const StyledConnectionDialogContent = styled.div`
  .connection-list {
    padding: 10px 5px;
    margin: 0 5px;
    display: grid;
    gap: 2px;
    max-height: 400px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      height: 0;
      position: absolute;
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.color.foreground}10;
      border-radius: 4px;

      &:hover {
        background: ${({ theme }) => theme.color.foreground}20;
      }
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      border: solid 3px transparent;
      width: 8px;
    }

    &.skeleton {
      padding: 20px;
      gap: 10px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    max-width: 1150px;
    max-height: 715px;
    height: 100vh;
    display: grid;
    grid-template-columns: 240px minmax(0, 1fr);
  }
`

export default StyledConnectionDialogContent
