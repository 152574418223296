import styled from 'styled-components'

type StyledProps = {
  isCompleted?: boolean
}

const StyledStepListCard = styled.div<StyledProps>`
  width: 295px;
  border-radius: 8px;
  padding: 12px;
  border: 1px solid ${({ theme }) => theme.color.gray[300]};

  .step-card-header {
    display: flex;
    align-items: center;
    margin-bottom: 6px;

    .step-card-icon {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      color: ${({ theme }) => theme.color.brand};
      background-color: ${({ theme }) => theme.color.brandLight};

      svg {
        height: 16px;
      }
    }

    .step-card-title {
      font-size: 15px;
      font-weight: 500;
      color: ${({ theme }) => theme.color.gray[800]};
    }
  }

  .step-card-description {
    font-size: 15px;
    color: ${({ theme }) => theme.color.gray[600]};
  }

  .step-card-actions {
    display: flex;
    align-items: center;
    margin-top: 12px;

    .step-check {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${({ theme }) => theme.color.brand};
      color: ${({ theme }) => theme.color.background};

      svg {
        height: 20px;
        stroke-width: 2px;
      }
    }

    a {
      text-decoration: none;
    }

    button {
      padding: 0 12px;
      border-radius: 50rem;
      height: 32px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      background-color: transparent;
      transition: 0.2s;
      color: ${({ theme }) => theme.color.gray[600]};
      border: 1px solid ${({ theme }) => theme.color.gray[400]};

      &:hover {
        background-color: ${({ theme }) => theme.color.gray[100]};
      }
    }
  }

  ${({ isCompleted, theme }) =>
    isCompleted &&
    `
    opacity: 0.6;
    background-color: ${theme.color.gray[100]};
  `}
`

export default StyledStepListCard
