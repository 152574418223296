import styled from 'styled-components'

const StyledDesignerHeader = styled.div`
  height: 60px;
  background-image: linear-gradient(90deg, #07c0c3, #2b3fff);
  box-shadow: inset 0 -1px rgb(255, 255, 255, 0.2);
  position: relative;
  z-index: 99;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;

  .designer-header-group {
    display: flex;
    align-items: center;

    .designer-header-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      border-radius: 4px;
      background-color: transparent;
      transition: 0.2s;
      color: rgb(255, 255, 255, 0.95);
      font-size: 15px;
      font-weight: 500;
      padding: 0 10px;

      svg {
        height: 20px;
        stroke-width: 2.2px;
      }

      &:not(.icon-btn) {
        svg {
          margin-left: -4px;
          margin-right: 6px;
        }
      }

      &.icon-btn {
        padding: 0;
        width: 40px;
      }

      &:not(:disabled):hover {
        background-color: rgb(255, 255, 255, 0.1);
      }

      &.sub-btn {
        background-color: rgb(255, 255, 255, 0.1);
        margin-right: 10px;
        padding-left: 13px;
      }

      &.main-btn {
        padding: 0 16px;
        background-color: rgb(255, 255, 255, 0.9);
        color: #18191b;

        &:hover {
          background-color: rgb(255, 255, 255, 1);
          color: #18191b;
        }
      }

      &:disabled {
        color: rgb(255, 255, 255, 0.45);

        &:hover {
          cursor: not-allowed;
        }
      }
    }

    hr {
      height: 30px;
      width: 1px;
      margin: 0 14px;
      background-color: rgb(255, 255, 255, 0.3);
      border: 0;
    }
  }

  .design-title-input {
    height: 40px;
    border-radius: 3px;
    padding: 0 10px;
    width: 200px;
    transition: 0.2s;
    background-color: transparent;
    color: rgb(255, 255, 255, 0.9);

    &::placeholder {
      color: rgb(255, 255, 255, 0.5);
    }

    &:hover,
    &:focus {
      box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.3);
    }
  }
`

export default StyledDesignerHeader
