import styled from 'styled-components'

const StyledActionPlansView = styled.div`
  overflow: hidden;
  display: grid;
  grid-template-rows: auto auto minmax(0, 1fr);
  height: 100%;

  .heading {
    padding: 0 14px 0 14px;

    .input-container {
      height: 42px;
      display: flex;
      align-items: center;
      position: relative;
      background-color: ${({ theme }) => theme.color.gray[100]};
      color: ${({ theme }) => theme.color.gray[800]};
      border-radius: 8px;
      width: 100%;

      input {
        width: 100%;
        background-color: transparent;
        padding: 0 10px 0 40px;
        height: 100%;
        box-sizing: border-box;
      }

      svg {
        height: 28px;
        position: absolute;
        left: 6px;
        color: ${({ theme }) => theme.color.gray[400]};
      }
    }
  }
`

export default StyledActionPlansView
