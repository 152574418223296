import styled from 'styled-components'

const StyledSidebarPlatformToggle = styled.a`
  text-decoration: none;
  padding: 10px;
  border-radius: 6px;
  user-select: none;
  margin-top: 10px;
  background-color: ${({ theme }) => theme.color.gray[100]};

  .title {
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.color.gray[800]};
  }

  .disclaimer {
    font-size: 12px;
    color: ${({ theme }) => theme.color.gray[700]};
    display: block;
    margin-top: 2px;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    margin-top: 10px;
    background-color: transparent;
    color: ${({ theme }) => theme.color.gray[600]};

    .toggle {
      height: 22px;
      width: 44px;
      background-color: ${({ theme }) => theme.color.gray[300]};
      border-radius: 50rem;
      position: relative;
      flex-shrink: 0;
      margin-left: 8px;

      div {
        position: absolute;
        height: 14px;
        width: 14px;
        border-radius: 50%;
        top: 4px;
        left: 4px;
        transition: 0.2s;
        background-color: ${({ theme }) => theme.color.background};
      }
    }
  }

  &[aria-selected='true'] {
    background-color: ${({ theme }) => theme.color.gray[100]};

    button {
      color: ${({ theme }) => theme.color.brand};

      .toggle {
        background-color: ${({ theme }) => theme.color.brand};

        div {
          background-color: white;
          left: 26px;
        }
      }
    }
  }
`

export default StyledSidebarPlatformToggle
