import { FC, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { v4 as uuidv4 } from 'uuid'
import { useApolloClient } from '@apollo/client'
import { searchUserDraftsQuery } from '../../../graphql'
import { useAppSelector } from '../../../redux'
import { CloudContentType, PhotoType, PostType } from '../../../types'
import { linkToSizedImage, usePost } from '../../../utils'
import { Button, Checkbox, CloudMediaPreview, Dialog, Spinner } from '../..'
import { StyledAddToPostDialog } from '.'

type Props = {
  photo: CloudContentType | PhotoType
  isOpen: boolean
  onClose: () => void
}

const AddToPostDialog: FC<Props> = ({ photo, isOpen, onClose }) => {
  const apollo = useApolloClient()
  const { updatePostData } = usePost()
  const { post: editorPost } = useAppSelector((state) => state.create)
  const [posts, setPosts] = useState<PostType[]>([])
  const [post, setPost] = useState<PostType>()
  const [loading, setLoading] = useState(false)

  const loadPosts = async () => {
    try {
      const { data } = await apollo.query({
        query: searchUserDraftsQuery,
        fetchPolicy: 'no-cache',
        variables: {
          page: 0,
          search: '',
          categories: [],
          sort: 'modified',
          items: 30
        }
      })

      setPosts(data?.searchUserDrafts?.docs || [])
    } catch (err) {
      console.log(err)
      toast.error((err as any).message)
    }
  }

  const handleSubmit = async () => {
    try {
      setLoading(true)

      if (!post) {
        return
      }

      const newMedia = post.media || []
      const photoCast = photo as PhotoType
      const cloudCast = photo as CloudContentType
      let newPhoto: any = {}

      if (newMedia.length >= 10) {
        toast.error("You can't add more than 10 photos or videos to a carousel.")
        return
      }

      if (photoCast?.file?.fileHttpLink) {
        newPhoto = {
          _id: uuidv4(),
          url: photoCast.file.fileHttpLink,
          dimensions: {
            x: photoCast.file?.imgSize?.width || 0,
            y: photoCast.file?.imgSize?.height || 0
          },
          uploaded: false,
          type: 'image'
        }
      }

      if (cloudCast?.type === 'design') {
        newPhoto = {
          _id: uuidv4(),
          url: cloudCast.file?.url,
          design: JSON.stringify(cloudCast.file?.metadata?.design),
          type: 'design',
          uploaded: true,
          ref: cloudCast._id,
          dimensions: cloudCast.file?.dimensions
        }
      }

      if (cloudCast?.type === 'file') {
        newPhoto = {
          _id: uuidv4(),
          url: cloudCast.file?.url,
          dimensions: cloudCast.file?.dimensions,
          uploaded: false,
          ref: cloudCast._id,
          type: cloudCast.file?.type
        }
      }

      await updatePostData({
        _id: post._id,
        media: [...newMedia, newPhoto]
      })

      toast.success('Added media to post')
    } catch (err) {
      console.log(err)
      toast.error((err as any).message)
    } finally {
      setLoading(false)
      onClose()
    }
  }

  useEffect(() => {
    setPosts([])
    setPost(editorPost as PostType)
    setLoading(false)

    if (isOpen) {
      loadPosts()
    }
  }, [editorPost, isOpen])

  return (
    <Dialog hasXIcon isOpen={isOpen} onClose={() => onClose()}>
      <StyledAddToPostDialog>
        <div className="photo-preview">
          <CloudMediaPreview
            isInteractive
            type={(photo?.file as any)?.type || 'image'}
            url={(photo?.file as any)?.fileHttpLink || (photo?.file as any)?.url}
          />
        </div>
        <div className="post-list">
          {editorPost?._id && (
            <label className="post-select-btn">
              <div className="post-details">
                <div className="post-img"></div>
                <span>Add to Current Post</span>
              </div>
              <Checkbox
                value={post?._id === editorPost._id}
                onChange={() => setPost(editorPost as PostType)}
              />
            </label>
          )}
          {posts
            .filter((p) => p._id !== editorPost?._id)
            .map((p) => {
              return (
                <label className="post-select-btn" key={p._id}>
                  <div className="post-details-container">
                    <div className="post-img">
                      {!!p.media?.length && (
                        <CloudMediaPreview type={p.media[0].type || 'image'} url={p.media[0].url} />
                      )}
                    </div>
                    <div className="post-details">
                      <span className="post-title">{p.title || 'Untitled Post'}</span>
                      <span className="post-caption">
                        {p.captionText || <span className="empty">Empty Caption</span>}
                      </span>
                    </div>
                  </div>
                  <Checkbox value={post?._id === p._id} onChange={() => setPost(p)} />
                </label>
              )
            })}
        </div>
        <div className="actions">
          <Button disabled={!post} variant="emphasis" isFullWidth onClick={() => handleSubmit()}>
            {loading ? <Spinner /> : 'Add to Post'}
          </Button>
        </div>
      </StyledAddToPostDialog>
    </Dialog>
  )
}

export default AddToPostDialog
