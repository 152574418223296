import { gql } from '@apollo/client'

export const createIssueMutation = gql`
  mutation CreateIssueMutation(
    $_id: String!
    $title: String!
    $about: String
    $aboutRaw: String
    $listDescription: String
    $listDescriptionRaw: String
    $headerImg: LessonThumbnailInput
    $actionPlanAudio: LessonThumbnailInput
    $actionPlanCover: LessonThumbnailInput
    $actionPlanPdf: LessonThumbnailInput
    $actionPlanVideo: String
    $status: String!
    $slug: String!
    $createdBy: String
    $hidden: Boolean
    $category: [String]
    $platform: [String]
    $facebookText: String
    $date: Date!
    $tags: [JSON]
  ) {
    issueCreateOne(
      record: {
        _id: $_id
        title: $title
        about: $about
        aboutRaw: $aboutRaw
        listDescription: $listDescription
        listDescriptionRaw: $listDescriptionRaw
        headerImg: $headerImg
        actionPlanAudio: $actionPlanAudio
        actionPlanCover: $actionPlanCover
        actionPlanPdf: $actionPlanPdf
        actionPlanVideo: $actionPlanVideo
        status: $status
        slug: $slug
        createdBy: $createdBy
        hidden: $hidden
        category: $category
        platform: $platform
        facebookText: $facebookText
        date: $date
        tags: $tags
      }
    ) {
      recordId
    }
  }
`
