import { gql } from '@apollo/client'

export const galleryImageFindOne = gql`
  query GalleryImageFindOne($_id: String!) {
    galleryImageFindOne(filter: { _id: $_id }) {
      _id
      category
      colors
      downloads
      favorites
      file {
        fileHttpLink
        fullPath
        imgSize {
          width
          height
        }
      }
      migrated
      orientation
      recommended
      season
      style
      tags
      updateDate
      uploadDate
      liked {
        _id
        user
        image
        dateLike
      }
      collectionImage {
        _id
        index
        collectionId
        dateAdd
      }
    }
  }
`
