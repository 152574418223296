import { ShepherdOptionsWithType } from 'react-shepherd'
import { removeEmptySteps } from '..'
import { waitForElementExists } from '../../../utils'

export const generateTour = (isMobile: boolean): ShepherdOptionsWithType[] => {
  const steps: ShepherdOptionsWithType[] = [
    {
      title: 'Connect an account',
      text: `You can click on the platform you want to connect.`,
      attachTo: {
        element: '[data-tour-id="connections-dialog"]',
        on: 'right-start'
      },
      canClickTarget: false,
      buttons: [
        {
          text: 'Awesome!',
          type: 'next'
        }
      ],
      beforeShowPromise: async () => {
        return waitForElementExists('[data-tour-id="connections-dialog"]')
      }
    }
  ]
  return removeEmptySteps(steps as any)
}
