import { gql } from '@apollo/client'

export const generateTwitterConnectURL = gql`
  query GenerateTwitterConnectURL($callback: String!) {
    generateTwitterConnectURL(callback: $callback) {
      oauth_token
      oauth_token_secret
      url
    }
  }
`
