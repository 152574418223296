import styled from 'styled-components'

type StyledProps = {
  $step?: 'plan' | 'pillars' | 'review'
  $isOverflowed?: boolean
}

const StyledPlanView = styled.div<StyledProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: minmax(0, 1fr) auto;

  .plan-exit-btn {
    top: 30px;
    left: 30px;
    position: fixed;
    display: flex;
    font-weight: 500;
    background-color: transparent;
    transition: 0.2s;
    user-select: none;
    color: ${({ theme }) => theme.color.gray[400]};

    svg {
      height: 18px;
      stroke-width: 2.75px;
      margin-right: 6px;
    }

    &:hover {
      color: ${({ theme }) => theme.color.gray[600]};
    }
  }

  .plan-content {
    display: grid;
    align-items: center;
    overflow-y: auto;
  }

  .plan-action-row {
    padding: 20px 30px 30px 30px;
    width: 100%;
    box-sizing: border-box;
    user-select: none;

    .action-btn {
      max-width: 412px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin: 0 auto;
      width: 100%;
      border-radius: 50rem;
      height: 50px;
      font-weight: 500;
      transition: 0.2s;
      background-color: ${({ theme }) => theme.color.gray[900]};
      color: ${({ theme }) => theme.color.background};

      &:hover {
        background-color: ${({ theme }) => theme.color.gray[700]};
      }

      ${({ $step, theme }) =>
        $step === 'review' &&
        `
        background-color: ${theme.color.green[700]};
        color: #fff;

        &:hover {
          background-color: ${theme.color.green[900]};
        }
      `}

      &:disabled {
        cursor: not-allowed;
        background-color: ${({ theme }) => theme.color.gray[100]};
        color: ${({ theme }) => theme.color.gray[300]};
      }
    }

    .action-subtext {
      text-align: center;
      justify-content: center;
      margin: 12px auto 0 auto;
      max-width: 412px;
      display: flex;
      font-size: 14px;
      color: ${({ theme }) => theme.color.gray[400]};

      a {
        text-decoration: none;
        margin-left: 6px;
        color: ${({ theme }) => theme.color.gray[500]};

        &:hover {
          text-decoration: underline;
        }
      }

      .review-step {
        height: 4px;
        border-radius: 50rem;
        width: 100%;
        transition: 0.2s;
        background-color: ${({ theme }) => theme.color.gray[200]};

        &[data-approved='true'] {
          background-color: ${({ theme }) => theme.color.green[700]};
        }

        &:not(:last-child) {
          margin-right: 6px;
        }
      }
    }

    ${({ $isOverflowed, theme }) =>
      $isOverflowed &&
      `
      box-shadow: 0 0 0 1px ${theme.color.gray[200]};
    `}
  }
`

export default StyledPlanView
