import { gql } from '@apollo/client'

export const cloudContentFindMany = gql`
  query CloudContentFindMany(
    $filter: FilterFindManyCloudContentInput!
    $sort: SortFindManyCloudContentInput
  ) {
    cloudContentFindMany(filter: $filter, sort: $sort) {
      _id
      name
      file {
        url
        preview
        path
        metadata
        type
        ext
        size
        dimensions {
          x
          y
        }
      }
      ref
      parent
      type
      createdAt
    }
  }
`
