import { FC, FormEvent, createRef, useEffect, useState } from 'react'
import AutosizeInput from 'react-input-autosize'
import { compact } from 'lodash'
import { XCircleIcon } from '@heroicons/react/20/solid'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useAppSelector } from '../../../redux'
import { useCreate } from '../../../utils'
import { OptionsPopover } from '../..'
import { StyledCreateEditorLabels } from '.'

const CreateEditorLabels: FC = () => {
  const inputBtnRef = createRef<HTMLFormElement>()
  const [input, setInput] = useState('')
  const [showPostTagsPopover, setShowPostTagsPopover] = useState(false)
  const { addPostTag, removePostTag } = useCreate()
  const { post } = useAppSelector((state) => state.create)
  const { tags: usedTags } = useAppSelector((state) => state.posts)

  const handleSubmit = (e?: FormEvent) => {
    e?.preventDefault()

    if (!input.trim()) {
      return
    }

    addPostTag(input)
    setInput('')
    setShowPostTagsPopover(false)
  }

  useEffect(() => {
    setInput('')
  }, [post?._id])

  return (
    <>
      <StyledCreateEditorLabels>
        <div className="label">
          <span className="label-title">Labels</span>
          <span className="label-description">Organize and tag your posts using Labels.</span>
        </div>
        <div className="label-list">
          <form
            className="label-input-container"
            data-has-value={!!input.trim().length}
            onSubmit={(e) => handleSubmit(e)}
            ref={inputBtnRef}
          >
            <AutosizeInput
              value={input}
              maxLength={20}
              className="label-input"
              placeholder={showPostTagsPopover ? 'Type your label...' : 'Add Label'}
              onFocus={() => setShowPostTagsPopover(true)}
              onChange={(e) => setInput(e.currentTarget.value)}
            />
            <div className="label-input-actions">
              <button
                type="button"
                onClick={() => {
                  setShowPostTagsPopover(false)
                  setInput('')
                }}
              >
                <XMarkIcon />
              </button>
              <button className="confirm-btn">
                <CheckIcon />
              </button>
            </div>
          </form>
          {post?.tags?.map((tag) => {
            return (
              <button className="label-item" key={tag} onClick={() => removePostTag(tag)}>
                {tag}
                <XCircleIcon />
              </button>
            )
          })}
        </div>
      </StyledCreateEditorLabels>
      {!!usedTags.length && (
        <OptionsPopover
          options={compact(usedTags)
            .sort()
            .map((tag) => ({
              label: tag,
              isSelected: post?.tags?.includes(tag),
              action: () => addPostTag(tag)
            }))}
          position="top-left"
          classToAvoid="label-input-container"
          width={200}
          height={300}
          buttonRef={inputBtnRef as any}
          isOpen={showPostTagsPopover}
          onClose={() => setShowPostTagsPopover(false)}
        />
      )}
    </>
  )
}

export default CreateEditorLabels
