const App = {
  splash: '/splash',
  home: '/',
  calendar: '/calendar',
  content: '/content',
  community: '/community',
  onboarding: '/onboarding'
}

const ActionPlans = {
  main: '/action-plans',
  actionPlan: '/action-plans/:slug',
  actionPlanBySlug: (slug: string) => `/action-plans/${slug}`
}

const Account = {
  main: '/account',
  profile: '/account/profile',
  business: '/account/business'
}

const External = {
  termsOfService: 'https://socialcurator.com/terms-of-service/',
  privacyPolicy: 'https://www.socialcurator.com/privacy-policy',
  cancellationPolicy: 'https://socialcurator.com/cancel/',
  helpCenter: 'https://help.socialcurator.com/en/',
  marketing: 'https://new.socialcurator.com',
  signup: 'https://app.socialcurator.com/signup',
  community: 'https://app.socialcurator.com/community',
  resourceCenter: 'https://help.socialcurator.com/en/'
}

export const MOBILE_ROUTES = {
  ...App,
  account: Account,
  external: External,
  actionPlans: ActionPlans
}
