import { FC, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import InfiniteScroll from 'react-infinite-scroll-component'
import Masonry from 'react-masonry-css'
import { v4 as uuid } from 'uuid'
import { useApolloClient } from '@apollo/client'
import { CheckIcon } from '@heroicons/react/24/outline'
import { loadFilesFromCloud, searchGalleryQuery } from '../../../../../graphql'
import { CloudContentType, PhotoType, PostType } from '../../../../../types'
import { CloudMediaPreview } from '../../../../cloud'
import { PhotoListItem } from '../../../../photos'
import { Spinner } from '../../../../system'
import { StyledGalleryPage } from '.'

type Props = {
  selected: NonNullable<PostType['media']>
  onSelect: (file: NonNullable<PostType['media']>[number]) => void
}

const GalleryPage: FC<Props> = ({ selected, onSelect }) => {
  const apollo = useApolloClient()
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [files, setFiles] = useState<PhotoType[]>([])

  const loadData = async () => {
    try {
      setLoading(true)

      const { data } = await apollo.query({
        fetchPolicy: 'no-cache',
        query: searchGalleryQuery,
        variables: {
          search: '',
          liked: false,
          sort: 'for-you',
          colors: [],
          orientation: null,
          collection: null,
          categories: [],
          style: [],
          page,
          items: 15
        }
      })

      const docs = data?.searchGallery?.docs || []
      const total = data?.searchGallery?.totalDocs || 0

      setFiles([...files, ...docs])
      setTotal(total)
    } catch (err) {
      console.log(err)
      toast.error((err as any).message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadData()
  }, [page])

  return (
    <StyledGalleryPage id="gallery-page">
      {loading && page === 0 && (
        <div className="loading-indicator">
          <Spinner />
        </div>
      )}
      <InfiniteScroll
        scrollThreshold={0.7}
        dataLength={files.length}
        next={() => setPage(page + 1)}
        hasMore={files.length < total}
        loader={null}
        scrollableTarget="gallery-page"
        className="infinite-scroll"
      >
        <Masonry
          className="masonry-grid"
          columnClassName="masonry-grid-column"
          breakpointCols={{
            default: 3
          }}
        >
          {files.map((photo) => {
            const isSelected = !!selected.find((media) => media.url === photo.file.fileHttpLink)

            return (
              <PhotoListItem
                photo={photo}
                isSelected={isSelected}
                isUsable={false}
                onExpand={() => {
                  onSelect({
                    _id: uuid(),
                    url: photo.file?.fileHttpLink || '',
                    type: 'image',
                    uploaded: false,
                    dimensions: {
                      x: photo.file?.imgSize?.width || 0,
                      y: photo.file?.imgSize?.height || 0
                    }
                  })
                }}
                key={photo._id}
              />
            )
          })}
        </Masonry>
      </InfiniteScroll>
    </StyledGalleryPage>
  )
}

export default GalleryPage
