import { FC } from 'react'
import { CreatableProps } from 'react-select/creatable'
import { LightBulbIcon } from '@heroicons/react/24/solid'
import { Input } from '../../'
import { OnboardingSelect } from '../../../views/OnboardingView/OnboardingSelect'
import { StyledPersonaInput } from '.'

type Props = {
  label?: string
  description?: string
  hint?: string
  isCreatable?: boolean
  isInput?: boolean
  forceMenu?: boolean
} & CreatableProps<any, any, any>

const PersonaInput: FC<Props> = ({
  value,
  label,
  description,
  hint,
  isCreatable,
  isInput,
  required,
  onChange,
  ...rest
}) => {
  return (
    <StyledPersonaInput>
      {label && (
        <div className="input-header">
          <div className="input-label">
            {label} {required && <div className="required-marker">*</div>}
          </div>
          {description && <div className="input-description">{description}</div>}
        </div>
      )}
      <div className="input">
        {isInput && (
          <Input
            value={value}
            type="text"
            onChange={(e) => onChange && onChange(e.target.value, e as any)}
          />
        )}
        {!isInput && (
          <OnboardingSelect
            value={value}
            isCreatable={isCreatable}
            required={required}
            onChange={onChange}
            {...rest}
          />
        )}
      </div>
      {hint && (
        <div className="input-hint">
          <LightBulbIcon />
          {hint}
        </div>
      )}
    </StyledPersonaInput>
  )
}

export default PersonaInput
