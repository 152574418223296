import { FC } from 'react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { BroomIcon } from '../../../../assets'
import { setShowChat, setShowChatHistory, useAppDispatch } from '../../../../redux'
import { useChat } from '../../../../utils'
import { StyledChatHeader } from '.'

const ChatHeader: FC = () => {
  const dispatch = useAppDispatch()
  const { clearChat } = useChat()

  return (
    <StyledChatHeader>
      <div className="chat-header-group">
        <button className="history-btn" onClick={() => dispatch(setShowChatHistory(true))}>
          <Bars3Icon />
        </button>
        <div className="chat-bot-details">
          <span className="chat-bot-name">Dottie</span>
          <span className="chat-bot-status">
            <div />
            Online
          </span>
        </div>
      </div>
      <div className="chat-header-group">
        <button onClick={() => clearChat()}>
          <BroomIcon />
        </button>
        <button onClick={() => dispatch(setShowChat(false))}>
          <XMarkIcon />
        </button>
      </div>
    </StyledChatHeader>
  )
}

export default ChatHeader
