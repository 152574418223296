import styled from 'styled-components'

type StyledProps = {
  canExpand?: boolean
  isInline?: boolean
  isSelected?: boolean
}

const StyledPhotoListItem = styled.div<StyledProps>`
  display: flex;
  position: relative;
  user-select: none;
  background-color: ${({ theme }) => theme.color.gray[100]};
  border-radius: 4px;

  ${({ isSelected, theme }) =>
    isSelected &&
    `
    &::before {
      user-select: none;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      box-shadow: inset 0 0 0 2px ${theme.color.brand}, inset 0 0 0 4px ${theme.color.background};
      z-index: 1;
      pointer-events: none;
    }
  `}

  .options-btn {
    position: absolute;
    top: 18px;
    right: 18px;
    width: 28px;
    height: 28px;
    background-color: ${({ theme }) => theme.color.background};
    box-shadow: 0 0 0 1px rgb(0, 0, 0, 0.1);

    svg {
      height: 24px;
    }

    &:hover {
      background-color: ${({ theme }) => theme.color.gray[100]};
    }
  }

  .image-container {
    display: flex;
    cursor: pointer;
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    border-radius: 4px;
    user-select: none;
    pointer-events: none;

    ${({ canExpand }) => canExpand && 'cursor: pointer;'}
  }

  &::after {
    user-select: none;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    box-shadow: inset 0 0 0 1px rgb(0, 0, 0, 0.1);
    z-index: 1;
    pointer-events: none;
  }

  .use-btn {
    position: absolute;
    bottom: 12px;
    left: 12px;
    z-index: 2;
    background-color: ${({ theme }) => theme.color.background};
    box-shadow: 0 0 0 1px rgb(0, 0, 0, 0.1);

    &:hover {
      background-color: ${({ theme }) => theme.color.gray[100]};
    }
  }

  &:not(:last-child) {
    margin-bottom: ${({ isInline }) => (isInline ? 4 : 10)}px;
  }

  ${({ isInline, theme }) =>
    isInline &&
    `

    .options-btn {
      display: none;
    }

    img {
      border-radius: 0;
    }

    &::after {
      border-radius: 0;
      box-shadow: inset 0 0 0 1px ${theme.color.foreground}10;
    }

    .use-btn {
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      box-shadow: none;
      opacity: 0;
      transition: 0.2s;
      background-color: rgb(0, 0, 0, 0.5);
      color: rgb(255, 255, 255, 0.9);
      font-size: 0;

      svg {
        margin: 0;
        height: 30px;
      }

      &:hover {
        opacity: 1;
        color: rgb(255, 255, 255, 0.9);
        background-color: rgb(0, 0, 0, 0.5);
      }
    }
  `}
`

export default StyledPhotoListItem
