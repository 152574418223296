import styled, { keyframes } from 'styled-components'

const popIn = keyframes`
  0% { 
    transform: scale(0.3);
  }
  70% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`

const popOut = keyframes`
  0% { 
    transform: scale(1);
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(0.3);
  }
`

const StyledFab = styled.button`
  height: 60px;
  width: 60px;
  border-radius: 30px;
  position: absolute;
  transition: 0.2s;
  z-index: ${({ theme }) => theme.elevation.fixed};
  background-color: ${({ theme }) => theme.color.brand};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(255, 255, 255, 0.25),
    inset 0px -2px 4px rgba(0, 0, 0, 0.25);
  right: 14px;
  bottom: calc(100% + 14px);
  animation: ${popIn} 0.22s ease-in-out;

  &.leaving {
    animation: ${popOut} 0.15s forwards;
  }

  &.expanded {
    width: calc(100% - 14px - 14px);
    border-radius: 12px;
  }

  .fab-post {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 3px;

    .post-details-container {
      height: 100%;
      border-radius: 9px;
      padding: 3px;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      background-color: ${({ theme }) => theme.color.background};

      .post-preview {
        height: 48px;
        width: 48px;
        flex-shrink: 0;
        border-radius: 6px;
        position: relative;
        background-color: ${({ theme }) => theme.color.gray[200]};
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        svg {
          color: ${({ theme }) => theme.color.gray[400]};
          height: 26px;
        }

        img {
          position: relative !important;
        }

        &::after {
          user-select: none;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          border-radius: 6px;
          box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.foreground}10;
          pointer-events: none;
          z-index: 1;
        }
      }

      .post-details {
        padding: 0 6px;

        .post-title {
          font-weight: 600;
          font-size: 15px;
          color: ${({ theme }) => theme.color.gray[800]};
          display: -webkit-box;
          -webkit-line-clamp: 1;
          overflow: hidden;
          text-align: left;
          -webkit-box-orient: vertical;
        }

        .post-description {
          font-size: 14px;
          color: ${({ theme }) => theme.color.gray[600]};
          display: -webkit-box;
          -webkit-line-clamp: 1;
          overflow: hidden;
          text-align: left;
          -webkit-box-orient: vertical;

          &[data-empty='true'] {
            color: ${({ theme }) => theme.color.gray[400]};
          }
        }
      }
    }

    .close-btn {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      margin: 0 2px 0 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      color: ${({ theme }) => theme.color.background};

      svg {
        height: 36px;
      }
    }
  }

  svg {
    height: 28px;
    stroke-width: 2px;
  }
`

export default StyledFab
