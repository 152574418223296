import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import firebase from 'firebase/app'
import { useApolloClient } from '@apollo/client'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { registerUser } from '../../../graphql'
import { clearCoupon, useAppDispatch, useAppSelector } from '../../../redux'
import { ROUTES, getPlanPrice, useForm } from '../..'
import { getCoupon, getCouponValue } from '.'

declare global {
  interface Window {
    dataLayer: any
  }
}

export const useRegister = () => {
  const stripe = useStripe()
  const elements = useElements()
  const history = useHistory()
  const apollo = useApolloClient()
  const location = useLocation()
  const dispatch = useAppDispatch()

  const [params, setParams] = useState<{ [k: string]: string }>()

  const { coupon } = useAppSelector((state) => state.state)

  const { form, resetForm, updateFormValue } = useForm({
    fullName: '',
    email: '',
    password: '',
    plan: 'year',
    cardReady: false,
    agreedToTerms: false
  })

  const getTotal = () => {
    const subTotal = getPlanPrice(form.plan)
    const discount = getCouponValue(form.plan, coupon)

    return Math.max(subTotal - discount, 0)
  }

  const getPaymentMethod = async () => {
    if (form.plan === 'trial') {
      return undefined
    }

    if (!elements || !stripe) {
      throw new Error('There was an error loading the payment client.')
    }

    const cardElement = elements.getElement(CardElement)

    if (!cardElement) {
      throw new Error('There was an error attaching your card.')
    }

    const { error: paymentError, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement
    })

    if (paymentError) {
      throw new Error(paymentError.message)
    }

    return paymentMethod.id
  }

  const register = async () => {
    // GET PAYMENT METHOD
    const paymentMethod = await getPaymentMethod()

    // REGISTER USER
    await apollo.mutate({
      fetchPolicy: 'no-cache',
      mutation: registerUser,
      variables: {
        ...form,
        coupon: coupon?.discount[form.plan as keyof typeof coupon.discount]?.code,
        paymentMethod
      }
    })

    // SIGN IN USER
    await firebase.auth().signInWithEmailAndPassword(form.email, form.password)

    // SEND EVENT TO GTM
    window.dataLayer?.push({
      event: form.plan === 'trial' ? 'User Started Free Trial' : 'User Signed Up',
      conversionValue: getTotal()
    })

    // CLEAR COUPON
    dispatch(clearCoupon())

    // REDIRECT TO ONBOARDING
    history.push(ROUTES.onboarding, params)
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    const params = Object.fromEntries(urlParams)
    const { fullName, email, plan } = params

    updateFormValue({
      fullName: fullName || '',
      email: email || '',
      plan: plan || 'year'
    })

    setParams({
      ...params,
      ...(location.state as any)
    })
  }, [location.search, location.state])

  return {
    form,
    params,
    updateFormValue,
    register,
    getTotal
  }
}
