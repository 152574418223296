import { useEffect, useState } from 'react'
import { PostType } from '../../types'

export const usePostTodos = (post: Partial<PostType> | undefined) => {
  const TODO_TYPE = [
    'create_post',
    'add_media',
    'add_caption',
    'link_profiles',
    'add_title',
    'add_date'
  ] as const
  const [completedTodos, setCompletedTodos] = useState<(typeof TODO_TYPE)[number][]>([])

  const checkTodos = () => {
    const compList: (typeof TODO_TYPE)[number][] = []

    // Default
    compList.push('create_post')

    if (!post) {
      return setCompletedTodos(compList)
    }

    if (!!post.media?.length) {
      compList.push('add_media')
    }

    if (!!post.captionText?.trim()) {
      compList.push('add_caption')
    }

    if (!!post.socialProfiles?.length) {
      compList.push('link_profiles')
    }

    if (!!post.title?.trim()) {
      compList.push('add_title')
    }

    if (!!post.postDate) {
      compList.push('add_date')
    }

    setCompletedTodos(compList)
  }

  useEffect(() => {
    checkTodos()
  }, [post])

  return {
    todosAmount: TODO_TYPE.length,
    completedTodos
  }
}
