import styled, { keyframes } from 'styled-components'

const logoSpinnerAnim = keyframes`
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(0.75);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
`

const StyledForgotPasswordSheet = styled.form`
  padding: 10px 40px calc(var(--bottom-spacing) + 20px) 40px;
  text-align: center;
  position: relative;

  h1 {
    font-size: 26px;
    font-family: ${({ theme }) => theme.typeface.garnett};
    font-weight: 600;
    color: ${({ theme }) => theme.color.gray[800]};
    position: relative;
    z-index: 1;
  }

  h2 {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin: 10px auto 20px auto;
    color: ${({ theme }) => theme.color.gray[500]};
    position: relative;
    z-index: 1;
  }

  .sent-indicator {
    margin-top: 20px;
    height: 52px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    user-select: none;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    transition: transform 0.2s;
    border: 1px solid ${({ theme }) => theme.color.green[700]};
    color: ${({ theme }) => theme.color.background};
    background-color: ${({ theme }) => theme.color.green[700]};

    svg {
      height: 28px;
      margin-right: 6px;
    }
  }

  button {
    margin-top: 20px;
    width: 100%;
    height: 52px;
    border-radius: 6px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background: linear-gradient(
      ${({ theme }) => theme.color.brand},
      ${({ theme }) => theme.color.brandDark}
    );
    font-family: ${({ theme }) => theme.typeface.garnett};
    position: relative;
    z-index: 1;

    .logo-spinner {
      display: flex;
      height: 26px;
      animation: ${logoSpinnerAnim} 1.5s infinite ease-in-out;
    }
  }
`

export default StyledForgotPasswordSheet
