import styled from 'styled-components'

const StyledCloudMediaPreview = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  user-select: none;

  img {
    position: absolute;
    user-select: none;
    pointer-events: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

export default StyledCloudMediaPreview
