import styled from 'styled-components'

const StyledPhotoListItem = styled.div`
  display: flex;
  position: relative;
  background-color: ${({ theme }) => theme.color.gray[100]};

  img {
    width: 100%;
    pointer-events: none;
  }

  &:not(:last-child) {
    margin-bottom: 4px;
  }
`

export default StyledPhotoListItem
