import styled from 'styled-components'

const StyledUploadDialog = styled.div`
  width: 100vw;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: minmax(0, 1fr) auto;

  .dialog-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    height: 60px;
    position: sticky;
    top: 0;
    z-index: ${({ theme }) => theme.elevation.fixed};
    background-color: ${({ theme }) => theme.color.background};
    border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};

    .dialog-header-details {
      display: flex;
      align-items: center;
      color: ${({ theme }) => theme.color.gray[800]};

      svg {
        height: 24px;
        margin-right: 8px;
      }
    }

    .dialog-title {
      font-weight: 600;
      color: ${({ theme }) => theme.color.gray[800]};
    }

    button {
      border-radius: 50%;
    }
  }

  .dialog-page {
    display: grid;
    height: 100%;
    grid-template-rows: auto minmax(0, 1fr);
  }

  .dialog-content {
    padding: 20px;
  }

  .dialog-actions {
    padding: 20px;
    border-top: 1px solid ${({ theme }) => theme.color.gray[300]};
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    max-width: 375px;
    max-height: 411px;
    height: 100vh;
    overflow-y: auto;
  }
`

export default StyledUploadDialog
