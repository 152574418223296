import styled from 'styled-components'

const StyledPillarsView = styled.div`
  .header {
    padding-bottom: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: end;
    justify-content: space-between;
    border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};

    h1 {
      font-size: 22px;
      font-weight: 500;
      font-family: ${({ theme }) => theme.typeface.garnett};
      color: ${({ theme }) => theme.color.gray[800]};
    }

    h2 {
      margin-top: 2px;
      font-size: 16px;
      font-weight: 400;
      color: ${({ theme }) => theme.color.gray[500]};
      display: flex;
      align-items: center;

      svg {
        height: 20px;
        margin: 0 6px;
      }
    }

    .header-actions {
      display: flex;

      button {
        &:not(:last-child) {
          margin-right: 12px;
        }
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.m}px) {
    padding-bottom: 20px;

    .header {
      padding-top: 20px;
      margin-bottom: 40px;
    }
  }
`

export default StyledPillarsView
