import { gql } from '@apollo/client'

export const contentPillarUpsertMany = gql`
  mutation ContentPillarUpsertMany($contentPillars: [ContentPillarInput]!) {
    contentPillarUpsertMany(contentPillars: $contentPillars) {
      _id
      icon
      title
      content
    }
  }
`
