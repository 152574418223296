import { gql } from '@apollo/client'

export const loadDesignsFromCloud = gql`
  query LoadDesignsFromCloud($search: String, $sort: String, $limit: Int, $page: Int) {
    loadDesignsFromCloud(search: $search, sort: $sort, limit: $limit, page: $page) {
      docs {
        _id
        name
        file {
          url
          preview
          path
          metadata
          type
          ext
          size
          dimensions {
            x
            y
          }
        }
        fileCount
        ref
        parent
        type
        createdAt
        updatedAt
      }
      totalDocs
    }
  }
`
