import { FC, useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import { useApolloClient } from '@apollo/client'
import { Button } from '../../components'
import { ROUTES, userStore, useWorkspace } from '../../utils'
import { WorkspaceType } from '../../types'
import { addUserToWorkspace, fetchWorkspaceInvite } from '../../graphql'
import { useAppDispatch, setWorkspace as setUserWorkspace } from '../../redux'
import { StyledWorkspaceInviteView } from '.'

const WorkspaceInviteView: FC = () => {
  const location = useLocation()
  const history = useHistory()
  const apollo = useApolloClient()
  const dispatch = useAppDispatch()
  const { changeWorkspace } = useWorkspace()
  const { user, refresh } = useContext(userStore)
  const [workspace, setWorkspace] = useState<WorkspaceType>()
  const [inviteStatement, setInviteStatement] = useState("You've been invited")

  const loadInvite = async () => {
    try {
      const urlParams = new URLSearchParams(location.search)
      const wid = urlParams.get('wid')
      const uid = urlParams.get('uid')
      const tid = urlParams.get('tid')

      if (!wid || !uid || !tid) {
        history.push(ROUTES.home)
        return
      }

      const { data } = await apollo.query({
        fetchPolicy: 'no-cache',
        query: fetchWorkspaceInvite,
        variables: {
          wid,
          uid,
          tid
        }
      })

      if (!data.fetchWorkspaceInvite) {
        history.push(ROUTES.home)
        return
      }

      setInviteStatement(`${data.fetchWorkspaceInvite.user.displayName} invited you`)
      setWorkspace(data.fetchWorkspaceInvite.workspace)
    } catch (err) {
      console.error(err)
      toast.error((err as any).message)
    }
  }

  const handleConfirm = async () => {
    if (!user?.id) {
      return history.push(ROUTES.auth.register, { workspace: workspace?._id })
    }

    const { data } = await apollo.mutate({
      fetchPolicy: 'no-cache',
      mutation: addUserToWorkspace,
      variables: {
        workspace: workspace?._id
      }
    })

    if (!data.addUserToWorkspace) {
      return
    }

    await refresh()
    changeWorkspace(workspace?._id)
    history.push(ROUTES.home)
    // history.push(ROUTES.workspaces.workspaceById(workspace?._id || ''))
    toast.success(`Joined ${workspace?.name || 'the workspace'}!`)
  }

  useEffect(() => {
    loadInvite()
  }, [location.search])

  return (
    <StyledWorkspaceInviteView>
      <div className="workspace-invite-content">
        <h1>
          {inviteStatement} to join {workspace?.name || 'a workspace'}
        </h1>
        <Button size="lg" variant="emphasis" isFullWidth onClick={() => handleConfirm()}>
          Join Workspace
        </Button>
      </div>
    </StyledWorkspaceInviteView>
  )
}

export default WorkspaceInviteView
