import firebase from 'firebase/app'
import { createRef, FC, FormEvent, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { Button, Dialog, Input, Spinner } from '../..'
import { StyledUpdatePasswordDialog } from '.'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const UpdatePasswordDialog: FC<Props> = ({ isOpen, onClose }) => {
  const dialogRef = createRef<HTMLDivElement>()
  const [loading, setLoading] = useState(false)
  const [currPassword, setCurrPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')

  const handleClose = () => {
    const dialog = dialogRef.current as any

    if (!dialog) {
      return onClose()
    }

    dialog?.close()
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    try {
      setLoading(true)

      if (!currPassword.trim() || !newPassword.trim()) {
        return
      }

      const profile = firebase.auth().currentUser as firebase.User

      if (!profile?.email) {
        throw new Error(
          'Error with currently logged in user. Please refresh your page and try again.'
        )
      }

      const credential = firebase.auth.EmailAuthProvider.credential(profile.email, currPassword)
      await profile.reauthenticateWithCredential(credential)
      await profile.updatePassword(newPassword)

      toast.success('Updated password!')
      handleClose()
    } catch (err) {
      console.log(err)
      toast.error((err as any).message)
      setLoading(false)
    }
  }

  useEffect(() => {
    setCurrPassword('')
    setNewPassword('')
    setLoading(false)
  }, [isOpen])

  return (
    <Dialog ref={dialogRef} isOpen={isOpen} onClose={() => onClose()}>
      <StyledUpdatePasswordDialog>
        <div className="dialog-header">
          <span className="dialog-title">Change Password</span>
        </div>
        <form onSubmit={e => handleSubmit(e)}>
          <Input
            placeholder="Current Password"
            value={currPassword}
            autoFocus
            onChange={e => setCurrPassword(e.target.value)}
            type="password"
            size="md"
          />
          <Input
            placeholder="New Password"
            value={newPassword}
            onChange={e => setNewPassword(e.target.value)}
            type="password"
            size="md"
          />
          <div className="dialog-actions">
            <Button type="button" onClick={() => handleClose()}>
              Cancel
            </Button>
            <Button
              variant="emphasis"
              isFullWidth
              disabled={loading || !currPassword.trim().length || !newPassword.trim().length}
            >
              {loading ? <Spinner /> : 'Change Password'}
            </Button>
          </div>
        </form>
      </StyledUpdatePasswordDialog>
    </Dialog>
  )
}

export default UpdatePasswordDialog
