import { FC, useState } from 'react'
import Selectable, { StylesConfig } from 'react-select'
import Creatable, { CreatableProps } from 'react-select/creatable'
import { useAppSelector } from '../../../redux'
import { theme as defaultTheme, themeDark } from '../../../utils'

type Props = {
  isCreatable?: boolean
  forceMenu?: boolean
} & CreatableProps<any, any, any>

const OnboardingSelect: FC<Props> = ({
  isCreatable = true,
  isSearchable = true,
  forceMenu,
  ...rest
}) => {
  const [input, setInput] = useState('')
  const { theme: currTheme } = useAppSelector((state) => state.state)
  const theme = currTheme === 'dark' ? themeDark : defaultTheme

  const styles: StylesConfig<any, any, any> = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      border: `1px solid ${state.isFocused ? theme.color.brand : theme.color.gray[300]}`,
      boxShadow: state.isFocused ? `0 0 0 1px ${theme.color.brand}` : '0',
      height: 'unset',
      minHeight: 50,
      borderRadius: '6px',
      backgroundColor: theme.color.background,
      fontSize: '18px',
      color: theme.color.gray[800],
      padding: '4px 4px',
      ':hover': {
        border: `1px solid ${state.isFocused ? theme.color.brand : theme.color.gray[400]}`
      }
    }),
    valueContainer: (baseStyles, state) => ({
      ...baseStyles,
      textAlign: 'left',
      color: theme.color.gray[800],
      ':focus-within .single-value': {
        color: theme.color.gray[400]
      }
    }),
    singleValue: (baseStyles, state) => ({
      ...baseStyles,
      textAlign: 'left',
      color: theme.color.gray[800]
      // backgroundColor: state..focu ? 'red' : 'blue'
    }),
    dropdownIndicator: (baseStyles, state) => ({
      ...baseStyles,
      color: theme.color.gray[400],
      ':hover': {
        cursor: 'pointer',
        backgroundColor: 'transparent',
        color: theme.color.gray[400]
      }
    }),
    menu: (baseStyles, state) => ({
      ...baseStyles,
      zIndex: 99
    }),
    clearIndicator: (baseStyles, state) => ({
      ...baseStyles,
      color: theme.color.gray[400],
      ':hover': {
        cursor: 'pointer',
        backgroundColor: 'transparent',
        color: theme.color.gray[400]
      }
    }),
    placeholder: (baseStyles, state) => ({
      ...baseStyles,
      textAlign: 'left',
      color: theme.color.gray[400]
    }),
    indicatorSeparator: (baseStyles, state) => ({
      ...baseStyles,
      display: 'none'
    }),
    multiValue: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: theme.color.gray[100],
      borderRadius: '50rem',
      padding: '4px 4px 4px 6px'
    }),
    multiValueLabel: (baseStyles, state) => ({
      ...baseStyles,
      color: 'rgb(0, 0, 0, 0.9)'
    }),
    multiValueRemove: (baseStyles, state) => ({
      ...baseStyles,
      color: 'rgb(0, 0, 0, 0.5)',
      borderRadius: '50rem',
      ':hover': {
        cursor: 'pointer',
        backgroundColor: 'transparent',
        color: 'rgb(0, 0, 0, 0.9)'
      }
    })
  }

  const isMenuOpen = () => {
    if (!isSearchable || forceMenu) {
      return undefined
    }

    if (input.length <= 2) {
      return false
    }

    return undefined
  }

  if (!isCreatable) {
    return (
      <Selectable
        {...rest}
        className="onboarding-select"
        classNamePrefix="onboarding-select"
        styles={styles}
        isSearchable={isSearchable}
        menuIsOpen={isMenuOpen()}
        onInputChange={(value) => setInput(value)}
      />
    )
  }

  return (
    <Creatable
      {...rest}
      className="onboarding-select"
      classNamePrefix="onboarding-select"
      styles={styles}
      classNames={{
        singleValue: () => {
          return 'single-value'
        }
      }}
      isSearchable={isSearchable}
      menuIsOpen={isMenuOpen()}
      onInputChange={(value) => setInput(value)}
    />
  )
}

export default OnboardingSelect
