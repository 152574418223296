import { createRef, FC, FormEvent, useEffect } from 'react'
import { StyledEditLinkBlockDialog } from '.'
import { updatePage, useAppDispatch, useAppSelector } from '../../../redux'
import { PageLinkType } from '../../../types'
import { useForm } from '../../../utils'
import { Button, Dialog, Input } from '../../system'

type Props = {
  block: PageLinkType
  isOpen: boolean
  onClose: () => void
}

const EditLinkBlockDialog: FC<Props> = ({ block, isOpen, onClose }) => {
  const dialogRef = createRef<HTMLDivElement>()
  const dispatch = useAppDispatch()
  const { page } = useAppSelector(state => state.page)
  const { form, updateFormValue } = useForm({
    label: block.metadata.label,
    url: block.metadata.url
  })

  const handleConfirm = (e: FormEvent) => {
    e.preventDefault()
    dispatch(
      updatePage({
        links: page?.links!.map(l => {
          if (l._id === block._id) {
            return {
              ...block,
              metadata: {
                ...block.metadata,
                ...form
              }
            }
          }

          return l
        })
      })
    )
    handleClose()
  }

  const handleClose = () => {
    const dialog = dialogRef.current as any

    if (!dialog) {
      return onClose()
    }

    dialog.close()
  }

  useEffect(() => {
    updateFormValue({
      label: block.metadata.label,
      url: block.metadata.url
    })
  }, [block, isOpen])

  return (
    <Dialog ref={dialogRef} isOpen={isOpen} onClose={onClose}>
      <StyledEditLinkBlockDialog>
        <div className="dialog-header">
          <span className="dialog-title">Edit Block</span>
        </div>
        <form onSubmit={e => handleConfirm(e)}>
          <Input
            value={form.label}
            placeholder="Block Label"
            maxLength={120}
            required
            onChange={e => updateFormValue('label', e.target.value)}
            size="md"
          />
          {block.type === 'url' && (
            <Input
              value={form.url}
              placeholder="Block URL"
              type="url"
              required
              onChange={e => updateFormValue('url', e.target.value)}
              size="md"
            />
          )}
          <div className="dialog-actions">
            <Button type="button" onClick={() => handleClose()}>
              Cancel
            </Button>
            <Button variant="emphasis" isFullWidth>
              Save
            </Button>
          </div>
        </form>
      </StyledEditLinkBlockDialog>
    </Dialog>
  )
}

export default EditLinkBlockDialog
