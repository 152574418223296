import { FC, useEffect, useState } from 'react'
import { times } from 'lodash'
import { DateTime } from 'luxon'
import { useAppSelector } from '../../../redux'
import { isMobile, parseDate } from '../../../utils'
import { CalendarWeekRow } from '../..'
import { StyledCalendarWeek } from '.'

const CalendarWeek: FC = () => {
  const now = DateTime.now()
  const { start } = useAppSelector((state) => state.calendar)
  const [thisWeek, setThisWeek] = useState(false)

  useEffect(() => {
    const diff = parseDate(start).diffNow('weeks').weeks

    if (!isMobile()) {
      setThisWeek(diff > -1 && diff < 0)
      return
    }

    const dayDiff = parseDate(start).diffNow('days').days

    setThisWeek(dayDiff > -1 && dayDiff < 1)
  }, [start])

  return (
    <StyledCalendarWeek data-calendar-week>
      <div className="calendar-time-list" />
      <div className="calendar-week">
        {times(24, (hour) => {
          return <CalendarWeekRow hour={hour} now={now} thisWeek={thisWeek} key={hour} />
        })}
      </div>
    </StyledCalendarWeek>
  )
}

export default CalendarWeek
