import { ShepherdOptionsWithType } from 'react-shepherd'
import { waitForElementExists } from '../../../utils'
import { removeEmptySteps } from '..'

export const generateTour = (isMobile: boolean): ShepherdOptionsWithType[] => {
  const steps: ShepherdOptionsWithType[] = [
    {
      title: 'Introducing The Post Editor',
      text: `Creating & publishing Instagram and Facebook posts has never been easier.`,
      buttons: [
        {
          text: 'Take the tour',
          type: 'next'
        },
        {
          text: 'Skip tour',
          type: 'cancel'
        }
      ]
    },
    {
      title: 'Suggested photos as you write',
      text: 'Perfect for some quick inspiration.',
      attachTo: {
        element: '[data-tour-id="post-editor-photos"]',
        on: 'bottom-start'
      },
      canClickTarget: false,
      buttons: [
        {
          text: 'Continue (1/4)',
          type: 'next'
        },
        {
          text: 'Leave tour',
          type: 'cancel'
        }
      ]
    },
    {
      title: 'Bits are the building blocks of your next caption',
      text:
        'Hooks entice your audience to read more. Insights serve your audience with what they need to hear. Call to Actions build lasting relationships (and engagement!).',
      attachTo: {
        element: '[data-tour-id="post-editor-captions"]',
        on: 'top-start'
      },
      canClickTarget: false,
      buttons: [
        {
          text: 'Continue (2/4)',
          type: 'next'
        },
        {
          text: 'Leave tour',
          type: 'cancel'
        }
      ]
    },
    {
      title: 'Attach photos and craft designs',
      text:
        'Hooks entice your audience to read more. Insights serve your audience with what they need to hear. Call to Actions build lasting relationships (and engagement!).',
      attachTo: {
        element: '[data-tour-id="post-editor-media"]',
        on: 'auto'
      },
      canClickTarget: false,
      buttons: [
        {
          text: 'Continue (3/4)',
          type: 'next'
        },
        {
          text: 'Leave tour',
          type: 'cancel'
        }
      ]
    },
    {
      title: 'A powerful editor, at your fingertips',
      text: 'Title your post, attach a tag, schedule it to auto-publish, and so much more.',
      attachTo: {
        element: '[data-tour-id="post-editor"]',
        on: 'auto'
      },
      canClickTarget: false,
      buttons: [
        {
          text: 'Continue (4/4)',
          type: 'next'
        },
        {
          text: 'Leave tour',
          type: 'cancel'
        }
      ]
    },
    {
      title: "And that's just the beginning...",
      text: "Now it's time you discover what the Post Editor has to offer for yourself..",
      buttons: [
        {
          text: 'Jump in',
          type: 'next'
        }
      ]
    }
  ]
  return removeEmptySteps(steps as any)
}
