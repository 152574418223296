import styled from 'styled-components'

type StyledProps = {
  maxWidth?: number
}

const StyledPageSizzle = styled.div<StyledProps>`
  padding: 20px 20px 10px 20px;

  .sizzle {
    max-width: ${({ maxWidth }) => maxWidth}px;
    margin: 0 auto;

    .sizzle-bubble {
      border-radius: 50rem;
      padding: 0 12px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      width: fit-content;
      font-weight: 600;
      letter-spacing: 0.05rem;
      margin-bottom: 20px;
      color: ${({ theme }) => theme.color.gray[600]};
      border: 1px solid ${({ theme }) => theme.color.gray[600]};
    }

    h1 {
      font-size: 30px;
      font-weight: 600;
      font-family: ${({ theme }) => theme.typeface.faktumXCon};
      color: ${({ theme }) => theme.color.gray[800]};
    }

    h2 {
      font-size: 18px;
      font-weight: 400;
      max-width: 480px;
      line-height: 24px;
      margin-top: 8px;
      font-family: ${({ theme }) => theme.typeface.faktum};
      color: ${({ theme }) => theme.color.gray[600]};
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.m}px) {
    padding: 40px 20px 10px 20px;
  }
`

export default StyledPageSizzle
