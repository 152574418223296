import { FC, useEffect } from 'react'
import { uniqBy } from 'lodash'
import { DateTime } from 'luxon'
import { useApolloClient } from '@apollo/client'
import { getDraftsBetweenDates } from '../../../graphql'
import { useAppDispatch, useAppSelector } from '../../../redux'
import { CalendarWeek } from '../../components'
import { setCalendarPosts } from '../../redux'
import { StyledCalendarView } from '.'

const CalendarView: FC = () => {
  const apollo = useApolloClient()
  const dispatch = useAppDispatch()
  const { start, week, posts } = useAppSelector((state) => state.calendarMobile)

  const loadMonth = async () => {
    const startDate = DateTime.fromISO(start)
    const gte = startDate
    const lte = startDate.plus({ days: 6 })

    const { data } = await apollo.query({
      query: getDraftsBetweenDates,
      fetchPolicy: 'no-cache',
      variables: {
        start: gte,
        end: lte
      }
    })

    const result = data?.getDraftsBetweenDates?.posts || []
    dispatch(setCalendarPosts(uniqBy([...posts, ...result], '_id')))
  }

  useEffect(() => {
    loadMonth()
  }, [start])

  return (
    <StyledCalendarView>
      <CalendarWeek />
    </StyledCalendarView>
  )
}

export default CalendarView
