import { FC } from 'react'
import { useAppSelector } from '../../../redux'
import { Dialog, PagePreview } from '../..'
import { StyledLinkPagePreviewDialog } from '.'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const LinkPagePreviewDialog: FC<Props> = ({ isOpen, onClose }) => {
  const { page } = useAppSelector(state => state.page)

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <StyledLinkPagePreviewDialog>
        {page && <PagePreview page={page} />}
      </StyledLinkPagePreviewDialog>
    </Dialog>
  )
}

export default LinkPagePreviewDialog
