import styled from 'styled-components'

const StyledGoProDialog = styled.div`
  max-width: 320px;
  width: 100vw;
  box-sizing: border-box;
  user-select: none;

  .go-pro-header {
    height: 100px;
    padding: 12px;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.color.brand};
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .go-pro-text {
      display: flex;
      align-items: center;
      font-size: 22px;
      font-weight: 500;
      position: relative;
      z-index: 1;
      user-select: none;
      color: ${({ theme }) => theme.color.background};

      svg {
        margin-right: 6px;
        height: 24px;
      }
    }

    &::after {
      user-select: none;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      box-shadow: inset 0 -1px 0 0 rgb(0, 0, 0, 0.1);
      z-index: 1;
    }
  }

  .dialog-screens {
    .dialog-screen {
      max-height: 400px;
      box-sizing: border-box;
      overflow-y: auto;
      padding: 12px;
      font-size: 14px;

      .dialog-body-text {
        margin: 18px 0;
        display: block;
        font-size: 14px;
        line-height: 20px;
        color: ${({ theme }) => theme.color.gray[600]};
      }
    }
  }

  .dialog-breadcrumbs {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: ${({ theme }) => theme.color.gray[600]};
    font-weight: 500;
    user-select: none;

    svg {
      height: 14px;
      margin: 0 4px;
      stroke-width: 2.5px;
    }

    .active {
      color: ${({ theme }) => theme.color.gray[800]};
    }
  }

  .dialog-actions {
    display: flex;
    justify-content: space-between;
    padding: 12px;
    border-top: 1px solid ${({ theme }) => theme.color.gray[200]};

    .continue-btn {
      padding: 0 14px;
      min-width: 90px;
    }

    button {
      svg {
        margin: 0 !important;
      }
    }
  }
`

export default StyledGoProDialog
