import { gql } from '@apollo/client'

export const generateCaptionFeed = gql`
  query GenerateCaptionFeed(
    $search: String = ""
    $issue: String
    $categories: [String] = []
    $liked: Boolean
    $sort: String
    $hideUsed: Boolean
    $items: Int = 10
    $page: Int = 0
    $caption: String
  ) {
    generateCaptionFeed(
      search: $search
      issue: $issue
      sort: $sort
      items: $items
      page: $page
      categories: $categories
      liked: $liked
      hideUsed: $hideUsed
      caption: $caption
    ) {
      docs {
        _id
        category
        caption
        captionPlainText
        title
        category
        issue
        issueTitle

        userCaption {
          _id
          liked
          used
          user
        }
      }
      totalDocs
    }
  }
`
