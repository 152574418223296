import styled from 'styled-components'

const StyledTabs = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;

  .scroll-container {
    overflow-x: auto;
    padding: 0 16px;
    display: flex;
    margin-bottom: -1px;
  }

  .children-container {
    background-color: ${({ theme }) => theme.color.background};
    display: flex;
    align-items: center;
    flex-shrink: 0;
    position: relative;
    padding-right: 16px;

    &::after {
      content: '';
      position: absolute;
      background: linear-gradient(-90deg, ${({ theme }) => theme.color.background}, transparent);
      height: 100%;
      right: 100%;
      width: 30px;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
`

export default StyledTabs
