import styled from 'styled-components'

type StyledProps = {
  hasTemplate?: boolean
}

const StyledChatInput = styled.div<StyledProps>`
  position: relative;

  .input-top-row {
    display: flex;
    align-items: end;
    padding: 0 14px 6px 14px;
  }

  .chat-reply-container {
    padding: 0 14px 6px 14px;

    .chat-reply {
      padding: 0 0px 0 12px;
      border-radius: 50rem;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: ${({ theme }) => theme.color.gray[100]};

      .chat-reply-label {
        display: flex;
        align-items: center;
        color: ${({ theme }) => theme.color.gray[600]};
        font-weight: 500;
        font-size: 14px;

        svg {
          height: 17px;
          position: relative;
          stroke-width: 2.4px;
          top: -1px;
          margin-right: 4px;
        }
      }

      button {
        background-color: transparent;
        height: 36px;
        width: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${({ theme }) => theme.color.gray[400]};

        svg {
          height: 28px;
        }
      }
    }
  }

  .input-container {
    width: 100%;
    border-radius: 18px;
    border: 1px solid ${({ theme }) => theme.color.gray[300]};
    display: flex;
    align-items: end;

    .input {
      padding: 6px 10px;
      width: 100%;
      background-color: transparent;
      resize: none;
      color: ${({ theme }) => theme.color.gray[800]};
    }

    .template-btn {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 2px;
      flex-shrink: 0;
      transition: 0.2s;
      color: ${({ theme }) => theme.color.gray[400]};

      svg {
        height: 20px;
      }

      &[data-active='true'] {
        transform: scale(0.9);
        color: ${({ theme }) => theme.color.gray[300]};
      }
    }
  }

  .send-btn {
    height: 34px;
    width: 34px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.color.brand};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
    flex-shrink: 0;
    color: white;

    svg {
      height: 20px;
      stroke-width: 2px;
    }

    &:disabled {
      background-color: ${({ theme }) => theme.color.gray[100]};
      color: ${({ theme }) => theme.color.gray[400]};
    }
  }

  ${({ hasTemplate, theme }) =>
    hasTemplate &&
    `
    .input-container {
      .template-btn {
        color: ${theme.color.brand};
      }
    }
  `}
`

export default StyledChatInput
