import { FC } from 'react'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { PhotoIcon, VideoCameraIcon } from '@heroicons/react/24/solid'
import { setCloudPreview, useAppDispatch, useAppSelector } from '../../../redux'
import { CloudMediaPreview } from '../../cloud'
import { StyledUploadsPreview } from '.'

const UploadsPreview: FC = () => {
  const dispatch = useAppDispatch()
  const { preview } = useAppSelector((state) => state.cloud)

  const onClose = () => {
    dispatch(setCloudPreview(undefined))
  }

  if (!preview) {
    return null
  }

  return (
    <StyledUploadsPreview>
      <div className="backdrop" onClick={() => onClose()} />
      <div className="header">
        <div className="header-group">
          <button className="back-btn" onClick={() => onClose()}>
            <ArrowLeftIcon />
          </button>
          <div className="file-details">
            {preview.file?.type === 'video' ? <VideoCameraIcon /> : <PhotoIcon />}
            <span className="file-name">{preview.name}</span>
          </div>
        </div>
      </div>
      <div className="preview">
        <CloudMediaPreview
          url={preview.file?.url || ''}
          preview={preview.file?.preview}
          mediaWidth={1000}
          type={preview.file?.type || 'image'}
          isInteractive
        />
      </div>
    </StyledUploadsPreview>
  )
}

export default UploadsPreview
