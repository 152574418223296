import { gql } from '@apollo/client'

export const upsertUserCaption = gql`
  mutation UpsertUserCaption($record: UpsertCaptionInput!) {
    upsertUserCaption(record: $record) {
      _id
      user
      liked
      used
      captionSetId
      addedDate
      editedCaption
      updatedAt
    }
  }
`
