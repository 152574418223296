import { FC, ReactNode, createRef, useEffect } from 'react'
import SwipeableDrawer from '@bit/mui-org.material-ui.swipeable-drawer'
import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import { useKeyboardHeight } from '../../../../utils'
import { StyledShiftDrawer } from '.'

type Props = {
  children: ReactNode
  title: string
  headerBtn?: ReactNode
  isOpen: boolean
  onClose: () => void
}

const ShiftDrawer: FC<Props> = ({ children, title, headerBtn, isOpen, onClose }) => {
  const drawerRef = createRef<HTMLDivElement>()
  const { height } = useKeyboardHeight()

  const handleSwipe = () => {
    const drawer = drawerRef.current?.parentElement
    const root = document.getElementById('root')

    if (!drawer || !root) {
      return
    }

    const transform = parseInt(
      drawer.style.transform.replaceAll('translate(', '').replaceAll(', 0px)', '').replace('px', '')
    )

    const offset = -75
    const windowWidth = window.innerWidth
    const left = offset * (windowWidth - transform) * 0.002

    root.style.transform = `translateX(${left}px)`
  }

  useEffect(() => {
    if (!isOpen) {
      const root = document.getElementById('root')

      if (!root) {
        return
      }

      root.style.transform = `unset`
    }
  }, [isOpen])

  return (
    <SwipeableDrawer
      variant="temporary"
      anchor="right"
      open={isOpen}
      disableDiscovery={true}
      disableSwipeToOpen={true}
      swipeAreaWidth={5}
      ModalProps={{ disableEnforceFocus: true, BackdropProps: { invisible: true } }}
      SwipeAreaProps={{
        allowSwipeInChildren: true
      }}
      PaperProps={{
        elevation: 4
      }}
      onOpen={() => {}}
      onClose={onClose}
    >
      <StyledShiftDrawer keyboardHeight={height} ref={drawerRef} onTouchMove={() => handleSwipe()}>
        <div className="header-container">
          <div className="header">
            <button className="back-btn" onClick={() => onClose()}>
              <ChevronLeftIcon />
            </button>
            <span className="drawer-title">{title}</span>
            {headerBtn}
          </div>
        </div>
        <div className="content">{children}</div>
      </StyledShiftDrawer>
    </SwipeableDrawer>
  )
}

export default ShiftDrawer
