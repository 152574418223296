import styled from 'styled-components'

const StyledActionPlanView = styled.div`
  // .tab-list,
  // .page-layout {
  //   max-width: 800px;
  // }

  position: relative;

  hr {
    width: 100%;
    height: 1px;
    border: 0;
    margin: 0;
    background-color: ${({ theme }) => theme.color.gray[300]};
  }

  .action-plan-heading {
    height: 250px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        ${({ theme }) => theme.color.background}90,
        ${({ theme }) => theme.color.background}
      );
    }
  }

  .action-plan-top {
    position: relative;

    .action-plan-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;

      a {
        text-decoration: none;
      }
    }

    .action-plan-details-container {
      margin-top: 30px;

      h1 {
        font-size: 22px;
        color: ${({ theme }) => theme.color.gray[800]};
      }

      p,
      .action-plan-description {
        line-height: 28px;
        margin-top: 10px;
        color: ${({ theme }) => theme.color.gray[600]};

        a {
          color: ${({ theme }) => theme.color.brand};
        }
      }
    }
  }

  .action-plan-up-next-container {
    margin: 17px 0 20px 0;

    .action-plan-up-next-container-label {
      font-weight: 600;
      color: ${({ theme }) => theme.color.gray[700]};
      padding-bottom: 20px;
      display: block;
    }

    .action-plan-up-next {
      display: grid;
      grid-gap: 20px;
      align-items: start;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    .action-plan-up-next-container {
      margin: 37px 0 40px 0;
    }
  }
`

export default StyledActionPlanView
