import { FC } from 'react'
import { useSwipeable } from 'react-swipeable'
import { times } from 'lodash'
import { DateTime } from 'luxon'
import { DndContext, DragEndEvent, pointerWithin } from '@dnd-kit/core'
import { useAppDispatch, useAppSelector } from '../../../../redux'
import { PostType } from '../../../../types'
import { useHaptics, usePost, useSensors } from '../../../../utils'
import { setCalendarTimeframe } from '../../../redux'
import { CalendarDragOverlay, CalendarWeekHeadingRow, CalendarWeekHourRow } from '..'
import { StyledCalendarWeek } from '.'

const CalendarWeek: FC = () => {
  const sensors = useSensors()
  const dispatch = useAppDispatch()
  const { start } = useAppSelector((state) => state.calendarMobile)
  const { trigger } = useHaptics()
  const { updatePostData } = usePost()

  const next = () => {
    const date = DateTime.fromISO(start).plus({ week: 1 })
    const day = date.weekdayShort
    const temp = day === 'Sun' ? date.plus({ day: 1 }) : date

    dispatch(setCalendarTimeframe(temp))
  }

  const prev = () => {
    const date = DateTime.fromISO(start).minus({ week: 1 })
    const day = date.weekdayShort
    const temp = day === 'Sun' ? date.plus({ day: 1 }) : date

    dispatch(setCalendarTimeframe(temp))
  }

  const handlers = useSwipeable({
    onSwipedRight: () => prev(),
    onSwipedLeft: () => next(),
    preventScrollOnSwipe: true,
    trackMouse: true,
    swipeDuration: 400
  })

  const handleDragEnd = async (e: DragEndEvent) => {
    const { active, over } = e
    const post = active.data.current?.post as PostType
    const date = over?.data.current?.date as string

    if (!date || !post) {
      return
    }

    trigger('impact-light')

    const newPost = {
      ...post,
      postDate: date
    }

    await updatePostData(newPost)
  }

  return (
    <DndContext
      collisionDetection={pointerWithin}
      sensors={sensors}
      autoScroll={{
        threshold: {
          x: 0,
          y: 0.2
        }
      }}
      onDragStart={() => trigger('impact-light')}
      onDragEnd={(event) => handleDragEnd(event)}
    >
      <StyledCalendarWeek {...handlers}>
        <CalendarWeekHeadingRow />
        <div className="rows">
          {times(24, (x) => {
            return <CalendarWeekHourRow hour={x} key={`hour-row-${x}`} />
          })}
        </div>
      </StyledCalendarWeek>
      <CalendarDragOverlay />
    </DndContext>
  )
}

export default CalendarWeek
