import { FC, useState } from 'react'
import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid'
import { CheckIcon } from '@heroicons/react/24/outline'
import { LEVELS, useUserLevel } from '../../../../utils'
import { LevelSheet } from '../..'
import { StyledHomeLevel } from '.'

const HomeLevel: FC = () => {
  const [showLevelSheet, setShowLevelSheet] = useState(false)
  const { level, postsNum } = useUserLevel()

  return (
    <>
      <StyledHomeLevel className="card" onClick={() => setShowLevelSheet(true)}>
        <div className="level-icon">{LEVELS[level.stage - 1].icon}</div>
        <div className="level-details">
          <div className="level-label">
            {level.title} <QuestionMarkCircleIcon />
          </div>
          <div className="level-progress">
            <span className="level-post-count">
              {postsNum} post{postsNum !== 1 && 's'}
            </span>
            <progress max={level.target || 0} value={postsNum}>
              {postsNum}
            </progress>
            <span className="level-post-limit">{level.target || <CheckIcon />}</span>
          </div>
        </div>
      </StyledHomeLevel>
      <LevelSheet isOpen={showLevelSheet} onClose={() => setShowLevelSheet(false)} />
    </>
  )
}

export default HomeLevel
