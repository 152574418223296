import { FC } from 'react'
import {
  ArrowDownTrayIcon,
  HeartIcon,
  MinusCircleIcon,
  PlusCircleIcon
} from '@heroicons/react/24/outline'
import { HeartIcon as SolidHeartIcon } from '@heroicons/react/24/solid'
import { ReactComponent as EditorIcon } from '../../../../assets/editor-icon.svg'
import { PhotoType } from '../../../../types'
import { usePhoto } from '../../../../utils'
import { StyledPhotoViewerToolBar } from '.'

type Props = {
  photo: PhotoType
  scale: number
  onScale: (number: number) => void
  onClose: () => void
}

const PhotoViewerToolBar: FC<Props> = ({ photo, scale, onScale, onClose }) => {
  const { downloadPhoto, addPhotoToPost, togglePhotoFavorited, addPhotoToCollection } = usePhoto()

  const handleUse = () => {
    addPhotoToPost(photo)
    onClose()
  }

  return (
    <StyledPhotoViewerToolBar>
      <div className="button-group">
        <button className="scale-btn" disabled={scale === 1} onClick={() => onScale(scale - 1)}>
          <MinusCircleIcon />
        </button>
        <button className="scale-btn" disabled={scale === 6} onClick={() => onScale(scale + 1)}>
          <PlusCircleIcon />
        </button>
      </div>
      <hr />
      <div className="button-group">
        <button onClick={() => handleUse()}>
          <EditorIcon />
        </button>
        <button onClick={() => togglePhotoFavorited(photo)}>
          {!!photo.liked?._id ? <SolidHeartIcon /> : <HeartIcon />}
        </button>
        {/* <button onClick={() => addPhotoToCollection(photo)}>
          <BookmarkIcon />
        </button> */}
        <button onClick={() => downloadPhoto(photo)}>
          <ArrowDownTrayIcon />
        </button>
      </div>
      {/* <hr />
      <div className="button-group">
        <button onClick={() => downloadPhoto(photo)}>
          <ArrowDownTrayIcon />
        </button>
      </div> */}
    </StyledPhotoViewerToolBar>
  )
}

export default PhotoViewerToolBar
