import styled from 'styled-components'

const StyledPlanPillarStep = styled.div`
  padding: 30px;

  .sparkles-icon {
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 30px auto;
    position: relative;
    color: white;
    border-radius: 50%;
    background: linear-gradient(
      ${({ theme }) => theme.color.brand},
      ${({ theme }) => theme.color.purple[500]},
      ${({ theme }) => theme.color.pink[400]}
    );

    svg {
      height: 32px;
    }
  }

  h1 {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    color: ${({ theme }) => theme.color.gray[900]};
  }

  p {
    font-size: 15px;
    text-align: center;
    margin: 14px 0 56px 0;
    color: ${({ theme }) => theme.color.gray[500]};
  }

  .pillar-container {
    max-width: 840px;
    margin: 0 auto;
  }
`

export default StyledPlanPillarStep
