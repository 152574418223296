import { FC, createRef, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useLongPress } from 'use-long-press'
import { Capacitor } from '@capacitor/core'
import { Haptics, ImpactStyle } from '@capacitor/haptics'
import {
  ArrowDownTrayIcon,
  ArrowsPointingOutIcon,
  CloudIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  EllipsisHorizontalIcon,
  HeartIcon,
  PlusIcon,
  TrashIcon
} from '@heroicons/react/24/outline'
import { HeartIcon as SolidHeartIcon } from '@heroicons/react/24/solid'
import { useAppSelector } from '../../../redux'
import { PhotoType } from '../../../types'
import {
  ROUTES,
  animateAddItemToPost,
  conditionalStage,
  copyToClipboard,
  linkToSizedImage,
  theme,
  usePhoto,
  userStore
} from '../../../utils'
import { AddToCloudDialog, Button, OptionsPopover } from '../..'
import { StyledPhotoListItem } from '.'

type Props = {
  photo: PhotoType
  calculateHeight?: boolean
  isInline?: boolean
  isSelected?: boolean
  isUsable?: boolean
  onRemove?: () => void
  onExpand?: (photo: PhotoType) => void
}

const PhotoListItem: FC<Props> = ({
  photo,
  calculateHeight = true,
  isInline,
  isSelected,
  isUsable = true,
  onRemove,
  onExpand
}) => {
  const location = useLocation()
  const photoSetRef = createRef<HTMLImageElement>()
  const optionsBtnRef = createRef<HTMLButtonElement>()
  const { user } = useContext(userStore)
  const { addPhotoToPost, downloadPhoto, togglePhotoFavorited } = usePhoto()
  const { splits } = useAppSelector((state) => state.splits)
  const [height, setHeight] = useState<number>()
  const [showOptions, setShowOptions] = useState(false)
  const [showCloudDialog, setShowCloudDialog] = useState(false)

  const getCalculatedHeight = () => {
    if (!calculateHeight) {
      return undefined
    }

    const PADDING = 10
    const column = document.querySelector('.masonry-grid-column')
    const columnWidth = column?.getBoundingClientRect().width
    const photoHeight = photo.file.imgSize?.height
    const photoWidth = photo.file.imgSize?.width

    if (!photoHeight || !photoWidth || !columnWidth) {
      return undefined
    }

    const ratio = (columnWidth - PADDING) / photoWidth

    return photoHeight * ratio
  }

  const handleUse = () => {
    if (!!isInline && !!onExpand) {
      return onExpand(photo)
    }

    if (!isInline) {
      animateAddItemToPost(photoSetRef)
    }

    addPhotoToPost(photo)
  }

  const handleExpand = () => {
    if (!onExpand) {
      return
    }

    onExpand(photo)
  }

  const longPressBind = useLongPress(
    () => {
      if (!Capacitor.isNativePlatform()) {
        return
      }
      Haptics.impact({ style: ImpactStyle.Medium })
      setShowOptions(true)
    },
    {
      cancelOnMovement: true
    }
  )

  useEffect(() => {
    setHeight(getCalculatedHeight())
  }, [location.pathname])

  return (
    <>
      <StyledPhotoListItem
        data-photo={photo._id}
        data-tour-id="photo"
        className="gallery-photo"
        canExpand={!!onExpand}
        isInline={isInline}
        isSelected={isSelected}
        {...longPressBind()}
      >
        {isUsable && (
          <Button
            size="sm"
            className={`options-btn options-btn-${photo._id}`}
            isIcon
            ref={optionsBtnRef}
            onClick={() => setShowOptions(!showOptions)}
          >
            <EllipsisHorizontalIcon />
          </Button>
        )}
        <div className="image-container" onClick={() => handleExpand()}>
          <img
            height={height}
            ref={photoSetRef}
            src={linkToSizedImage(photo.file.fileHttpLink, { width: 400 })}
          />
        </div>
        {isUsable && (
          <Button
            data-tour-id="photo-use-btn"
            iconPos="left"
            size="sm"
            className="use-btn"
            onClick={() => handleUse()}
          >
            <PlusIcon /> USE
          </Button>
        )}
      </StyledPhotoListItem>
      <OptionsPopover
        options={[
          {
            icon: <PlusIcon />,
            label: 'Add to post',
            action: () => handleUse()
          },
          ...conditionalStage(onExpand !== undefined, [
            {
              icon: <ArrowsPointingOutIcon />,
              label: 'Expand',
              action: () => onExpand!(photo)
            }
          ]),
          {
            icon: !!photo?.liked?._id ? (
              <SolidHeartIcon fill={theme.color.red[600]} />
            ) : (
              <HeartIcon />
            ),
            label: !!photo.liked?._id ? 'Unfavorite' : 'Favorite',
            action: () => togglePhotoFavorited(photo)
          },
          ...conditionalStage(!onRemove && splits.collections === 'on', [
            {
              icon: <CloudIcon />,
              label: 'Save to Uploads',
              action: () => setShowCloudDialog(true)
            }
          ]),
          {
            icon: <ArrowDownTrayIcon />,
            label: 'Download',
            action: () => downloadPhoto(photo)
          },
          ...conditionalStage(onRemove !== undefined, [
            {
              icon: <TrashIcon />,
              label: 'Remove',
              action: () => onRemove!()
            }
          ]),
          ...conditionalStage(user?.role === 'admin', [
            {
              icon: <DocumentDuplicateIcon />,
              label: 'Copy URL to photo',
              action: () => copyToClipboard(ROUTES.admin.photoById(photo._id))
            },
            {
              icon: <Cog6ToothIcon />,
              label: 'Open in Admin Panel',
              action: () => (window.location.href = ROUTES.admin.editPhotoById(photo._id))
            }
          ])
        ]}
        classToAvoid={`options-btn-${photo._id}`}
        buttonRef={optionsBtnRef}
        isOpen={showOptions}
        onClose={() => setShowOptions(false)}
      />
      <AddToCloudDialog
        photo={photo}
        isOpen={showCloudDialog}
        onClose={() => setShowCloudDialog(false)}
      />
    </>
  )
}

export default PhotoListItem
