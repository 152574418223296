import { FC, ReactNode } from 'react'
import { StyledCreateBarPanel } from '.'

type Props = {
  children: ReactNode
}

const CreateBarPanel: FC<Props> = ({ children }) => {
  return <StyledCreateBarPanel className="create-bar-panel">{children}</StyledCreateBarPanel>
}

export default CreateBarPanel
