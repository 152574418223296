import styled from 'styled-components'

const StyledDesignerToolbarRow = styled.div`
  display: flex;
  align-items: center;

  hr {
    height: 22px;
    width: 1px;
    margin: 0 10px;
    background-color: rgb(0, 0, 0, 0.1);
    border: 0;
  }
`

export default StyledDesignerToolbarRow
