import styled from 'styled-components'

const StyledNewWorkspaceView = styled.div`
  max-width: 440px;
  margin: 0 auto;

  .workspaces-header {
    padding-bottom: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: end;
    justify-content: space-between;
    border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};

    h1 {
      font-size: 22px;
      font-weight: 500;
      color: ${({ theme }) => theme.color.gray[800]};
      display: flex;
      align-items: center;

      .new-flag {
        padding: 3px 6px 4px 8px;
        margin-left: 10px;
        font-size: 13px;
        font-weight: 600;
        position: relative;
        top: 1px;
        letter-spacing: 0.1rem;
        border-radius: 50rem;
        color: ${({ theme }) => theme.color.background};
        font-family: ${({ theme }) => theme.typeface.polySans};
        background: linear-gradient(
          45deg,
          ${({ theme }) => theme.color.brand},
          ${({ theme }) => theme.color.purple[600]}
        );
      }
    }

    h2 {
      margin-top: 6px;
      font-size: 16px;
      font-weight: 400;
      color: ${({ theme }) => theme.color.gray[500]};
    }
  }

  .content-layout {
    display: grid;
    grid-gap: 20px;
    align-items: flex-start;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.m}px) {
    .workspaces-header {
      padding-top: 20px;
    }

    .content-layout {
      // grid-gap: 40px;
      // justify-content: space-between;
      // grid-template-columns: minmax(0, 1fr) 320px;
    }
  }
`

export default StyledNewWorkspaceView
