import styled from 'styled-components'

const StyledAppLayout = styled.div`
  background-color: ${({ theme }) => theme.color.background};

  .layout-root {
    height: calc(100dvh - 47px - var(--top-spacing) - var(--bottom-spacing) - 53px);
  }
`

export default StyledAppLayout
