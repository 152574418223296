import styled from 'styled-components'

const StyledActionPlansViewHeader = styled.div`
  background-color: ${({ theme }) => theme.color.background};
  position: sticky;
  z-index: 9;
  top: 0;

  .action-plans-view-header-top {
    display: grid;
    grid-template-columns: minmax(0, 1fr) 145px;
    grid-gap: 12px;
  }

  .bubbles {
    margin-top: 12px;
  }

  .tabs {
    margin-top: -10px;

    .tab-list {
      max-width: 835px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    .action-plans-view-header-top {
      grid-template-columns: minmax(0, 1fr) 185px;
    }
  }
`

export default StyledActionPlansViewHeader
