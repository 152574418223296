import { FC, useEffect, useState } from 'react'
import { Link, matchPath, useHistory, useLocation } from 'react-router-dom'
import { Browser } from '@capacitor/browser'
import { CommunityIcon, SCMark, UserIcon } from '../../../../assets'
import { MOBILE_ROUTES } from '../../../utils'
import { CalendarMonthButton } from '../..'
import { StyledHeader } from '.'

const Header: FC = () => {
  const location = useLocation()
  const history = useHistory()
  const borderPages: string[] = ['/community', '/account']
  const [showBorder, setShowBorder] = useState(false)

  useEffect(() => {
    setShowBorder(
      !!borderPages.filter((page) => {
        return matchPath(location.pathname, {
          path: page
        })
      }).length
    )
  }, [location.pathname])

  return (
    <StyledHeader showBorder={showBorder}>
      <div className="header">
        <div className="header-group">
          <Link to={MOBILE_ROUTES.home} draggable={false} className="logo-link">
            <SCMark />
          </Link>
          {location.pathname === MOBILE_ROUTES.calendar && <CalendarMonthButton />}
        </div>
        <div className="header-group">
          <button
            className="header-btn"
            onClick={() =>
              Browser.open({
                url: MOBILE_ROUTES.external.community,
                presentationStyle: 'fullscreen'
              })
            }
          >
            <CommunityIcon />
          </button>
          <button
            className="header-btn profile-btn"
            onClick={() => history.push(MOBILE_ROUTES.account.main)}
          >
            <UserIcon />
          </button>
        </div>
      </div>
    </StyledHeader>
  )
}

export default Header
