export const day8 = {
  _id: 'day8',
  title: 'DAY 8',
  tasks: [
    {
      label: 'Get Dottie to create a caption',
      steps: [
        // {
        //   label: 'Navigate to the Photos & Stories using the sidebar'
        // },
        // {
        //   label: 'Use the search bar and/or additional filters to find the photo'
        // }
      ]
    },
    {
      label: 'Request that Dottie makes a change to the caption',
      steps: [
        // {
        //   label: 'Click on the ellipsis (⋯) button on the photo'
        // },
        // {
        //   label: 'In the opened dropdown, select “Save to Cloud”'
        // },
        // {
        //   label: 'Pick a collection (or select “No Folder”)'
        // },
        // {
        //   label: 'Click the “Add to Cloud” button'
        // }
      ]
    },
    {
      label: 'Add the caption to a post',
      steps: [
        // {
        //   label: 'Navigate to the Caption Templates using the sidebar'
        // },
        // {
        //   label: 'Use the search bar to find the caption'
        // }
      ]
    },
    {
      label: 'Add a photo to a post (upload your own, from the gallery or ask Dottie)',
      steps: [
        // {
        //   label: 'Click the “USE” button on the caption'
        // }
      ]
    },
    {
      label: 'Schedule the post for yesterday, at 5:25PM (so that it doesn’t actually post)',
      steps: [
        // {
        //   label: 'Click on the ellipsis (⋯) button on the caption'
        // },
        // {
        //   label: 'In the opened dropdown, select “Mark as used”'
        // }
      ]
    }
  ],
  form: {
    url: 'https://notionforms.io/forms/day-8-feedback',
    keys: {
      FULL_NAME: '025ea641-2802-444a-8725-c96c920a148c',
      EMAIL: '163f1063-53e5-48a6-b520-32d56ff9e0cc',
      SCREEN_RECORDING: '0be5125e-e103-4790-ae59-11a0a69f9d10',
      USER_ID: '02f1da76-f588-452c-b78b-11c08cb1240e'
    }
  }
}
