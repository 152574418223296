import { FC, useContext } from 'react'
import {
  ConnectionOverview,
  ConnectionPlatformList,
  ConnectionSidebar,
  Dialog,
  connectionStore
} from '../..'
import { StyledConnectionOverviewDialog } from '.'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const ConnectionOverviewDialog: FC<Props> = ({ isOpen, onClose }) => {
  const { hasConnections, platformToOverview } = useContext(connectionStore)

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <StyledConnectionOverviewDialog hasConnections={hasConnections}>
        {hasConnections && <ConnectionSidebar />}
        {!platformToOverview && <ConnectionPlatformList />}
        {platformToOverview && <ConnectionOverview />}
      </StyledConnectionOverviewDialog>
    </Dialog>
  )
}

export default ConnectionOverviewDialog
