import { FC, createRef, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import InfiniteScroll from 'react-infinite-scroll-component'
import Masonry from 'react-masonry-css'
import { times, uniqBy, xor } from 'lodash'
import { useApolloClient } from '@apollo/client'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { FunnelIcon } from '@heroicons/react/24/solid'
import { searchGalleryQuery } from '../../../graphql'
import { PhotoType } from '../../../types'
import { debounceFilter } from '../../../utils'
import { Button, EmptyState, Input, OptionsPopover, PhotoListItem, Skeleton } from '../..'
import { StyledCreateBarContentPhotos } from '.'

const CreateBarContentPhotos: FC = () => {
  const apollo = useApolloClient()
  const optionsBtnRef = createRef<HTMLButtonElement>()
  const [search, setSearch] = useState('')
  const [orientation, setOrientation] = useState<'vertical' | 'horizontal'>()
  const [style, setStyle] = useState<('light_airy' | 'dark_moody' | 'vibrant')[]>([])
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(0)
  const [photos, setPhotos] = useState<PhotoType[]>([])
  const [liked, setLiked] = useState(false)
  const [loading, setLoading] = useState(true)
  const [showOptions, setShowOptions] = useState(false)

  const loadPhotos = async () => {
    try {
      setLoading(true)

      const { data } = await apollo.query({
        fetchPolicy: 'no-cache',
        query: searchGalleryQuery,
        variables: {
          search,
          orientation,
          sort: 'custom',
          categories: [],
          style,
          liked,
          colors: [],
          collection: null,
          page,
          items: 10
        }
      })

      const result = data?.searchGallery?.docs || []
      const total = data?.searchGallery?.totalDocs || 0

      setPhotos(uniqBy([...photos, ...result], '_id'))
      setTotal(total)
    } catch (err) {
      console.log(err)
      toast.error((err as any).message)
    } finally {
      setLoading(false)
    }
  }

  const handleSearch = (query: string) => {
    setSearch(query)
    setPage(0)
    setPhotos([])
  }

  const handleOrientation = (input: typeof orientation) => {
    setOrientation(orientation === input ? undefined : input)
    setPage(0)
    setPhotos([])
  }

  const handleStyle = (input: (typeof style)[number]) => {
    setStyle(xor(style, [input]))
    setPage(0)
    setPhotos([])
  }

  const handleLiked = (input: boolean) => {
    setLiked(input)
    setPage(0)
    setPhotos([])
  }

  useEffect(() => {
    loadPhotos()
  }, [search, orientation, liked, style, page])

  return (
    <>
      <StyledCreateBarContentPhotos>
        <div className="create-bar-filter-header">
          <Input
            value={search}
            variant="fill"
            size="lg"
            icon={<MagnifyingGlassIcon />}
            placeholder="Search Photos"
            isDebounced
            onChange={(e) => debounceFilter(() => handleSearch(e.target.value))}
          />
          <Button
            size="lg"
            className="filter-options-btn"
            isIcon
            ref={optionsBtnRef}
            onClick={() => setShowOptions(!showOptions)}
          >
            {(!!style.length || orientation) && (
              <div className="filter-indicator">{style.length + (!!orientation ? 1 : 0)}</div>
            )}
            <FunnelIcon />
          </Button>
        </div>
        <div className="photo-list scrollable" id="content-photo-list">
          {!loading && !photos.length && (
            <EmptyState
              title={`We couldn't find anything${!!search.trim() ? ` for "${search}"` : ''}.`}
              body="How about a different search?"
            />
          )}
          {loading && (
            <Masonry
              className="masonry-grid"
              columnClassName="masonry-grid-column"
              breakpointCols={{
                default: 2
              }}
            >
              {times(10, (num) => {
                return (
                  <Skeleton
                    randHeight={[160, 220]}
                    styles="&:not(:last-child) {
                  margin-bottom: 4px;
                }"
                    key={num}
                  />
                )
              })}
            </Masonry>
          )}
          <InfiniteScroll
            scrollThreshold={0.7}
            dataLength={photos.length}
            next={() => setPage(page + 1)}
            hasMore={photos.length < total}
            scrollableTarget="content-photo-list"
            loader={null}
          >
            <Masonry
              className="masonry-grid"
              columnClassName="masonry-grid-column"
              breakpointCols={{
                default: 2
              }}
            >
              {photos.map((photo) => {
                return <PhotoListItem photo={photo} isInline={true} key={photo._id} />
              })}
            </Masonry>
          </InfiniteScroll>
        </div>
      </StyledCreateBarContentPhotos>
      <OptionsPopover
        options={[
          {
            label: 'Only Favorites',
            isToggle: true,
            isSelected: liked,
            stayOpen: true,
            action: () => handleLiked(!liked)
          },
          {
            label: 'Light & Airy',
            isSelected: style.includes('light_airy'),
            stayOpen: true,
            action: () => handleStyle('light_airy')
          },
          {
            label: 'Dark & Moody',
            isSelected: style.includes('dark_moody'),
            stayOpen: true,
            action: () => handleStyle('dark_moody')
          },
          {
            label: 'Vibrant',
            isSelected: style.includes('vibrant'),
            stayOpen: true,
            action: () => handleStyle('vibrant')
          },
          {
            label: 'Vertical',
            isSelected: orientation === 'vertical',
            stayOpen: true,
            action: () => handleOrientation('vertical')
          },
          {
            label: 'Horizontal',
            isSelected: orientation === 'horizontal',
            stayOpen: true,
            action: () => handleOrientation('horizontal')
          }
        ]}
        labelsBefore={[
          {
            before: 'Light & Airy',
            label: 'Styles'
          },
          {
            before: 'Vertical',
            label: 'Orientations'
          }
        ]}
        divsAfter={['Only Favorites', 'Vibrant']}
        classToAvoid="filter-options-btn"
        buttonRef={optionsBtnRef}
        isOpen={showOptions}
        onClose={() => setShowOptions(false)}
      />
    </>
  )
}

export default CreateBarContentPhotos
