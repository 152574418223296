import { FC, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useApolloClient } from '@apollo/client'
import { chatGetConversations } from '../../../../graphql'
import { ChatConversationType } from '../../../../types'
import { ChatHistoryListItem } from '..'
import { StyledChatHistoryList } from '.'

const ChatHistoryList: FC = () => {
  const apollo = useApolloClient()
  const [loading, setLoading] = useState(true)
  const [history, setHistory] = useState<ChatConversationType[]>([])

  const loadHistory = async () => {
    try {
      setLoading(true)
      const { data } = await apollo.query({
        fetchPolicy: 'no-cache',
        query: chatGetConversations
      })

      setHistory(data?.chatGetConversations || [])
    } catch (err) {
      console.error(err)
      toast.error((err as any)?.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadHistory()
  }, [])

  return (
    <StyledChatHistoryList>
      {history.map((conversation) => {
        return <ChatHistoryListItem conversation={conversation} />
      })}
    </StyledChatHistoryList>
  )
}

export default ChatHistoryList
