import { createGlobalStyle } from 'styled-components'

type StyledProps = {
  keyboardHeight: number
}

const StyledDrawer = createGlobalStyle<StyledProps>`
  
`

export default StyledDrawer
