import styled from 'styled-components'

const StyledCreatePublishSheet = styled.div`
  padding: 0 20px calc(var(--bottom-spacing) + 20px) 20px;
  overflow: hidden;

  .social-profile-select-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    border-radius: 8px;
    box-shadow: inset 0 0 0 1.25px ${({ theme }) => theme.color.gray[300]};
    width: 100%;
    box-sizing: border-box;
    padding: 0 10px 0 14px;
    background-color: transparent;

    .placeholder {
      font-weight: 500;
      color: ${({ theme }) => theme.color.gray[400]};
    }

    svg {
      height: 24px;
      stroke-width: 2px;
      color: ${({ theme }) => theme.color.gray[400]};
    }

    .social-profile-selection {
      display: flex;
      margin-left: -4px;
    }
  }

  .action-row {
    margin-top: 20px;
    display: flex;

    button {
      height: 50px;
      padding: 0 16px;
      box-sizing: border-box;
      border-radius: 12px;
      font-weight: 500;
      color: ${({ theme }) => theme.color.gray[600]};
      background-color: ${({ theme }) => theme.color.gray[200]};

      &.submit-btn {
        width: 100%;
        margin-left: 10px;
        color: white;
        background-color: ${({ theme }) => theme.color.brand};
      }

      &:disabled {
        pointer-events: none;
        background-color: ${({ theme }) => theme.color.gray[100]};
        color: ${({ theme }) => theme.color.gray[400]};
      }
    }
  }
`

export default StyledCreatePublishSheet
