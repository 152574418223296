import { FC, useEffect, useState } from 'react'
import { useActiveObject, useEditor } from '@layerhub-io/react'
import { getSelectedType } from '../../../utils'
import { useAppDispatch } from '../../../redux'
import {
  DesignerImageToolbar,
  DesignerLockTool,
  DesignerTextToolbar,
  DesignerVectorToolbar
} from '..'
import { StyledDesignerToolbar } from '.'

const DesignerToolbar: FC = () => {
  const activeObject = useActiveObject() as any
  const editor = useEditor()
  const dispatch = useAppDispatch()
  const [toolbox, setToolbox] = useState('Canvas')

  const handleSelectionChange = () => {
    const selectionType = getSelectedType(activeObject)

    if (!selectionType) {
      return setToolbox('Canvas')
    }

    if (selectionType.length > 1) {
      return setToolbox('Multiple')
    }

    setToolbox(selectionType[0])
  }

  useEffect(() => {
    handleSelectionChange()

    if (editor) {
      editor.on('history:changed', handleSelectionChange)
    }
    return () => {
      if (editor) {
        editor.off('history:changed', handleSelectionChange)
      }
    }
  }, [editor, activeObject])

  return (
    <StyledDesignerToolbar>
      {['StaticVector'].includes(toolbox) && <DesignerVectorToolbar />}
      {['StaticImage'].includes(toolbox) && <DesignerImageToolbar />}
      {['StaticText'].includes(toolbox) && <DesignerTextToolbar />}
      {!['Canvas', 'Frame'].includes(toolbox) && <DesignerLockTool />}
    </StyledDesignerToolbar>
  )
}

export default DesignerToolbar
