import styled, { keyframes } from 'styled-components'

const pulseAnim = (lightGray: string, gray: string) => keyframes`
  0% {
    background: ${lightGray};
  }
  50% {
    background: ${gray};
  }
  100% {
    background: ${lightGray};
  }
`

type StyledProps = {
  height?: number
  styles?: string
}

const StyledSkeleton = styled.div<StyledProps>`
  width: 100%;
  padding-top: 100%;
  border-radius: 4px;
  animation: ${({ theme }) => pulseAnim(theme.color.gray[100], theme.color.gray[200])} 2s
    cubic-bezier(0.4, 0, 0.6, 1) infinite;
  ${({ styles }) => styles}

  ${({ height }) =>
    !!height &&
    `
    padding: 0;
    height: ${height}px;
  `}
`

export default StyledSkeleton
