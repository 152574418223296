import { useContext } from 'react'
import { toast } from 'react-hot-toast'
import { useApolloClient } from '@apollo/client'
import { Capacitor } from '@capacitor/core'
import { connectTikTok, generateTikTokConnectURL } from '../../../graphql'
import { userStore } from '../../UserContext'
import { useSegment } from '../useSegment'

export const useLoginTikTok = () => {
  const apollo = useApolloClient()
  const native = Capacitor.isNativePlatform()
  const { refresh } = useContext(userStore)
  const { track } = useSegment()

  const loginTikTok = async () => {
    // const redirect = native ? 'https://app.socialcurator.com' : window.location.origin
    const redirect = 'https://app.socialcurator.com/connect'

    const { data } = await apollo.query({
      fetchPolicy: 'no-cache',
      query: generateTikTokConnectURL,
      variables: {
        redirect
      }
    })

    if (!data.generateTikTokConnectURL) {
      return toast.error('Whoops, please try again')
    }

    window.location.href = data.generateTikTokConnectURL
  }

  const connectTikTokProfile = async (code: string) => {
    try {
      await apollo.mutate({
        mutation: connectTikTok,
        variables: { code }
      })
      await refresh()
      track('Connected Social Profile')
      track('Connected TikTok Profile')
      toast.success(`Successfully connected your social profile`)
    } catch (err) {
      console.error(err)
    }
  }

  return {
    loginTikTok,
    connectTikTokProfile
  }
}
