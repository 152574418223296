import styled from 'styled-components'

const StyledConnectionSelect = styled.div`
  .connection-select-header {
    padding: 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};

    span {
      font-size: 22px;
      font-weight: 600;
      font-family: ${({ theme }) => theme.typeface.faktumXCon};
      color: ${({ theme }) => theme.color.gray[800]};
    }
  }

  .connection-select-list {
    padding: 10px;
    display: grid;
    grid-gap: 2px;
  }

  .connection-action-list {
    padding: 0 20px 20px 20px;
  }
`

export default StyledConnectionSelect
