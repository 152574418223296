import styled from 'styled-components'

type StyledProps = {
  step?: number
  keyboardHeight?: number
}

const StyledOnboardingView = styled.div<StyledProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: ${({ keyboardHeight }) => (keyboardHeight ? keyboardHeight : 0)}px;
  width: 100%;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr) auto;
  overflow: hidden;
  transition: 0.2s;

  #gradient-canvas {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.color.gray[100]};
    --gradient-color-1: #ffffff;
    --gradient-color-2: #7dd3fc;
    --gradient-color-3: ${({ theme }) => theme.color.purple[300]};
    --gradient-color-4: #ffffff;
  }

  .onboarding-header {
    width: 100%;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    padding: var(--top-spacing) 20px 16px 20px;
    border-bottom: 1px solid ${({ theme }) => theme.color.foreground}20;
    display: flex;
    align-items: center;

    .chat-avatar {
      height: 42px;
      width: 42px;
      border-radius: 50%;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${({ theme }) => theme.color.brand}20;
      color: ${({ theme }) => theme.color.brand};

      svg {
        height: 32px;
      }
    }

    .back-btn {
      height: 42px;
      width: 42px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      background-color: ${({ theme }) => theme.color.foreground}20;
      color: ${({ theme }) => theme.color.foreground}de;
      transition: 0.2s;
      left: ${({ step }) => (!!step ? '20px' : '-100%')};
      opacity: ${({ step }) => (!!step ? '1' : '0')};

      svg {
        height: 24px;
        stroke-width: 2px;
        position: relative;
        left: -1px;
      }
    }

    .onboarding-header-heading {
      margin-left: ${({ step }) => (!!step ? 'calc(42px + 10px)' : '0px')};
      transition: 0.2s;

      span {
        display: block;
      }

      .title {
        font-size: 18px;
        font-family: ${({ theme }) => theme.typeface.garnett};
        font-weight: 600;
        color: ${({ theme }) => theme.color.foreground}de;
      }

      .helps-info {
        font-size: 14px;
        color: ${({ theme }) => theme.color.foreground}a4;
      }
    }
  }

  .onboarding-steps {
    overflow-y: auto;
    height: 100%;
  }

  .input-label {
    font-weight: 700;
    font-size: 15px;
    margin-bottom: 6px;
    color: ${({ theme }) => theme.color.gray[800]};
    display: flex;
    align-items: center;
  }

  .mobile-custom-select {
    .onboarding-select__control {
      border-radius: 8px;
      border: none;
      box-shadow: 0 0 0 1px #2f40f820, 0 3px 10px #2f40f820;

      .onboarding-select__indicator {
        svg {
          height: 26px;
          width: 26px;
        }
      }
    }
  }

  .action-bar {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    transition: 0.2s;
    z-index: ${({ theme }) => theme.elevation.fixed};
    padding: 0
      ${({ keyboardHeight }) =>
        keyboardHeight ? '6px 6px 6px' : '20px var(--bottom-spacing) 20px'};

    button {
      height: 70px;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      box-sizing: border-box;
      font-size: 18px;
      font-weight: 600;

      &.continue-btn {
        color: white;
        width: 100%;
        background-color: ${({ theme }) => theme.color.brand};
        box-shadow: 0 0 0 1px ${({ theme }) => theme.color.brand}20,
          0 3px 10px ${({ theme }) => theme.color.brand}20;
      }

      &.skip-btn {
        padding: 0 28px;
        margin-right: 10px;
        color: ${({ theme }) => theme.color.foreground}c2 !important;
        background-color: ${({ theme }) => theme.color.foreground}10 !important;
      }

      &:disabled {
        box-shadow: none;
        color: ${({ theme }) => theme.color.foreground}50 !important;
        background-color: ${({ theme }) => theme.color.foreground}10 !important;
      }
    }
  }
`

export default StyledOnboardingView
