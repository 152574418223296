import styled from 'styled-components'

type StyledProps = {
  isDragging?: boolean
}

const StyledHashtagListItem = styled.div<StyledProps>`
  display: inline-block;
  position: relative;
  margin: 0 4px 4px 0;
  border-radius: 4px;
  padding: 4px;
  font-size: 15px;
  background-color: ${({ theme }) => theme.color.gray[100]};
  color: ${({ theme }) => theme.color.gray[800]};
  cursor: grab;

  span {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: left;
  }

  ${({ isDragging }) =>
    isDragging &&
    `
    cursor: grabbing;
    z-index: 9999999;
  `}
`

export default StyledHashtagListItem
