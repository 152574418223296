import { FC, useEffect, useState } from 'react'
import { ShepherdOptionsWithType } from 'react-shepherd'
import { isMobile } from '../../../utils'
import { useAppSelector } from '../../../redux'
import { Tour } from '..'
import { generateTour } from '.'

const UploadManagerTour: FC = () => {
  const { tour } = useAppSelector((state) => state.tour)

  const TOUR_ID = 'upload-manager'
  const [steps, setSteps] = useState<ShepherdOptionsWithType[]>([])

  useEffect(() => {
    setSteps(generateTour(isMobile()))
  }, [])

  return <Tour _id={TOUR_ID} steps={steps} showConfetti={false} canStart={tour === TOUR_ID} />
}

export default UploadManagerTour
