import styled, { keyframes } from 'styled-components'

const logoSpinnerAnim = keyframes`
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(0.75);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
`

type StyledProps = {
  keyboardHeight: number
}

const StyledSplashView = styled.div<StyledProps>`
  padding-top: var(--top-spacing);
  padding-bottom: var(--bottom-spacing);
  height: calc(100dvh - var(--top-spacing) - var(--bottom-spacing));
  display: grid;
  align-content: space-between;
  position: relative;
  transition: 0.15s;
  bottom: 0;

  .splash-top {
    .header-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;

      svg {
        height: 38px;
        color: ${({ theme }) => theme.color.gray[800]};
      }

      button {
        background-color: transparent;
        border: 1px solid ${({ theme }) => theme.color.gray[800]};
        color: ${({ theme }) => theme.color.gray[800]};
        height: 38px;
        border-radius: 50rem;
        padding: 0 16px;
        font-weight: 700;
      }
    }

    h1 {
      font-size: 32px;
      padding: 10px 20px 20px 20px;
      font-weight: 600;
      line-height: 40px;
      font-family: ${({ theme }) => theme.typeface.garnett};
    }
  }

  form {
    padding: 20px;

    .auth-inputs {
      display: grid;
      gap: 35px;

      .input-container {
        position: relative;

        .input-label {
          margin-bottom: 10px;
          font-weight: 700;
          color: ${({ theme }) => theme.color.gray[800]};
          font-size: 16px;
          user-select: none;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .input-label-subtext {
            display: flex;
            align-items: center;
            font-weight: 500;
            user-select: none;
            margin: -2px 0;
            color: ${({ theme }) => theme.color.gray[400]};

            a {
              margin-left: 4px;
              outline: none;
              color: ${({ theme }) => theme.color.gray[400]};

              &:hover {
                color: ${({ theme }) => theme.color.gray[500]};
              }

              &:focus-visible {
                box-shadow: 0 0 0 4px ${({ theme }) => theme.color.blue[200]};
              }
            }

            svg {
              height: 18px;
              margin-left: 4px;
            }
          }

          button {
            display: flex;
            align-items: center;
            font-weight: 600;
            padding: 0;
            margin: -2px 0;
            background-color: transparent;
            color: ${({ theme }) => theme.color.brand};
          }
        }

        .show-password-btn-small {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          position: absolute;
          right: 10px;
          bottom: 10px;
          color: ${({ theme }) => theme.color.gray[500]};
          background-color: ${({ theme }) => theme.color.gray[100]};

          svg {
            height: 22px;
          }

          &:hover {
            color: ${({ theme }) => theme.color.gray[600]};
            background-color: ${({ theme }) => theme.color.gray[200]};
          }
        }

        input[type='text'],
        input[type='password'],
        input[type='email'] {
          height: 60px;
          padding: 0 16px;
          font-size: 18px;
          border-radius: 6px;
          width: 100%;
          box-sizing: border-box;
          border: 1px solid ${({ theme }) => theme.color.gray[400]};
          color: ${({ theme }) => theme.color.gray[800]};

          &::placeholder {
            user-select: none;
            color: ${({ theme }) => theme.color.gray[400]};
          }

          &:hover {
            border: 1px solid ${({ theme }) => theme.color.gray[500]};
          }

          &:focus {
            border: 1px solid ${({ theme }) => theme.color.brand};
            box-shadow: 0 0 0 4px ${({ theme }) => theme.color.blue[200]};
          }

          &:disabled {
            background-color: ${({ theme }) => theme.color.gray[100]};
            border: 1px solid ${({ theme }) => theme.color.gray[100]};
            color: ${({ theme }) => theme.color.gray[400]};
          }
        }
      }
    }

    .submit-btn {
      margin-top: 35px;
      height: 70px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;
      font-size: 18px;
      font-weight: 600;
      transition: transform 0.2s;
      border: 1px solid ${({ theme }) => theme.color.brandDark};
      color: ${({ theme }) => theme.color.background};
      background-color: ${({ theme }) => theme.color.brand};

      &:not(:disabled):hover {
        background-color: ${({ theme }) => theme.color.brandDark};
      }

      &:not(:disabled):focus-visible {
        box-shadow: 0 0 0 4px ${({ theme }) => theme.color.blue[200]};
      }

      &:not(:disabled):active {
        transform: scale(0.98);
      }

      &:disabled {
        cursor: not-allowed;
      }

      &[data-grayed='true'] {
        border-color: ${({ theme }) => theme.color.gray[100]};
        background-color: ${({ theme }) => theme.color.gray[100]};
        color: ${({ theme }) => theme.color.gray[400]};
      }
    }

    .logo-spinner {
      display: flex;
      height: 26px;
      animation: ${logoSpinnerAnim} 1.5s infinite ease-in-out;
    }
  }

  ${({ keyboardHeight }) =>
    !!keyboardHeight &&
    `
    bottom: calc(${keyboardHeight}px - var(--bottom-spacing));
  `}
`

export default StyledSplashView
