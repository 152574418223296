import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type InitialState = {
  tab: 'posts' | 'designs' | 'templates'
}

const initialState: InitialState = {
  tab: 'posts'
}

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setHomeTab: (state, action: PayloadAction<InitialState['tab']>) => {
      state.tab = action.payload
    }
  }
})

export const { setHomeTab } = homeSlice.actions
export default homeSlice.reducer
