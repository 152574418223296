import styled, { createGlobalStyle } from 'styled-components'

type StyledProps = {
  color: string
}

export const Global = createGlobalStyle<StyledProps>`
  .header-container {
    background-color: ${({ color }) => color};
    border-bottom: 1px solid rgb(255, 255, 255, 0.2);

    .header {
      .logo-link {
        color: ${({ theme }) => theme.color.background};
      }

      .header-links {
        button {
          background-color: transparent;
          color: ${({ theme }) => theme.color.background};
          border-color: ${({ theme }) => theme.color.background};

          &.header-register-btn {
            background-color: ${({ theme }) => theme.color.background};
            color: ${({ color }) => color};
            border-color: ${({ theme }) => theme.color.background};
          }
        }
      }
    }
  }
`

const StyledAcademyCoursePreviewView = styled.div<StyledProps>`
  background-color: ${({ theme }) => theme.color.gray[100]};

  .width-controller {
    max-width: 835px;
    margin: 0 auto;
    padding: 0 20px;
  }

  .course-splash-container {
    background-color: ${({ color }) => color};
    padding-top: 61px;
    margin-top: -61px;

    .skeleton {
      opacity: 0.2;
    }

    .course-splash-content {
      padding: 40px 0;

      .course-preview-layout {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 40px;
      }

      .course-header {
        display: grid;
        align-content: space-between;

        a {
          button {
            height: 50px;
            padding: 0;
            padding: 0 18px;
            font-weight: 500;
            color: ${({ theme }) => theme.color.background};
            border: 1px solid ${({ theme }) => theme.color.gray[900]};
            background-color: ${({ theme }) => theme.color.gray[900]};
            transition: 0.2s;
            border-radius: 4px;

            &:hover {
              background-color: ${({ theme }) => theme.color.gray[800]};
            }
          }

          &:not(:last-child) {
            margin-right: 12px;
          }
        }
      }

      .course-actions {
        position: relative;
      }

      h1 {
        font-family: ${({ theme }) => theme.typeface.sansSerif};
        font-size: 30px;
        color: ${({ theme }) => theme.color.background};
      }

      h2 {
        margin-top: 16px;
        font-size: 16px;
        font-weight: 400;
        max-width: 500px;
        line-height: 24px;
        color: ${({ theme }) => theme.color.background}c4;
      }

      .course-pills {
        margin-top: 20px;
        display: flex;

        .course-pill {
          color: rgb(255, 255, 255, 0.9);
          background-color: rgb(255, 255, 255, 0.1);
          font-weight: 400;
          height: 34px;
          padding: 0 14px;
          display: flex;
          align-items: center;
          width: fit-content;
          border-radius: 50rem;

          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }

      .course-preview-video {
        padding-top: 56.25%;
        background-color: black;
        border-radius: 6px;
      }
    }
  }

  .course-details-container {
    background-color: ${({ theme }) => theme.color.gray[100]};
    padding: 40px 0;
    border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};

    .course-details-layout {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: start;
      grid-gap: 40px;

      .course-details {
        list-style-type: none;
        display: grid;
        grid-gap: 20px;
        padding: 0;
        color: ${({ theme }) => theme.color.gray[700]};

        .course-detail-title {
          color: ${({ theme }) => theme.color.gray[600]};
          font-weight: 500;
          font-size: 14px;
        }

        li {
          display: flex;
          font-size: 17px;
          align-items: center;
          color: ${({ theme }) => theme.color.gray[500]};

          svg {
            height: 24px;
            margin-right: 8px;
            stroke-width: 2px;
          }
        }
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    min-height: 100vh;
  }
`

export default StyledAcademyCoursePreviewView
