import { FC, useEffect } from 'react'
import { toast } from 'react-hot-toast'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useApolloClient } from '@apollo/client'
import { searchIssuesQuery } from '../../../../graphql'
import {
  changeActionPlansHasNext,
  changeActionPlansPage,
  setActionPlans,
  useAppDispatch,
  useAppSelector
} from '../../../../redux'
import { ActionPlanListItem } from '..'
import { StyledActionPlanList } from '.'

const ActionPlanList: FC = () => {
  const apollo = useApolloClient()
  const dispatch = useAppDispatch()
  const { loading, actionPlans, hasNext, page, search, categories, platform } = useAppSelector(
    (state) => state.actionPlans
  )

  const loadActionPlans = async () => {
    try {
      const { data } = await apollo.query({
        fetchPolicy: 'no-cache',
        query: searchIssuesQuery,
        variables: {
          page,
          search,
          platform,
          categories
        }
      })

      const actionPlanList = data?.searchIssue?.docs || []
      dispatch(setActionPlans([...actionPlans, ...actionPlanList]))
      dispatch(changeActionPlansHasNext(!!data?.searchIssue?.hasNextPage))
    } catch (err) {
      console.log(err)
      toast.error((err as any).message)
    }
  }

  useEffect(() => {
    if (loading) {
      loadActionPlans()
    }
  }, [loading])

  return (
    <StyledActionPlanList id="action-plans-scroll">
      <InfiniteScroll
        scrollThreshold={0.7}
        dataLength={actionPlans.length}
        next={() => dispatch(changeActionPlansPage(page + 1))}
        hasMore={!!hasNext}
        loader={null}
        scrollableTarget="action-plans-scroll"
      >
        {actionPlans.map((actionPlan) => {
          return <ActionPlanListItem actionPlan={actionPlan} key={actionPlan._id} />
        })}
      </InfiniteScroll>
    </StyledActionPlanList>
  )
}

export default ActionPlanList
