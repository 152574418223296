import { FC, ReactNode } from 'react'
import { StyledDesignerNavItem } from '.'

type Props = {
  children: ReactNode
  isActive?: boolean
  onClick: () => void
}

const DesignerNavItem: FC<Props> = ({ children, isActive, onClick }) => {
  return (
    <StyledDesignerNavItem aria-selected={isActive} onClick={() => onClick()}>
      {children}
    </StyledDesignerNavItem>
  )
}

export default DesignerNavItem
