import { FC, useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import { uniq } from 'lodash'
import {
  ChatOnboarding,
  CreateBar,
  CreateEditor,
  CreateEditorErrors,
  CreateHeader
} from '../../components'
import { updateCreatePost, useAppDispatch, useAppSelector } from '../../redux'
import { hydrateSocialProfiles, theme, useCreate, userStore } from '../../utils'
import { StyledCreateView } from '.'

const CreateView: FC = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const { theme: t } = useAppSelector((state) => state.state)
  const { isSaved, post } = useAppSelector((state) => state.create)
  const { user } = useContext(userStore)
  const { savePost, loadPostFromUrl } = useCreate()

  useEffect(() => {
    const media = post?.media || []
    const profiles = hydrateSocialProfiles(post?.socialProfiles, user)
    const platforms = uniq(profiles.map((profile) => profile.type))
    const hasVideo = !!media.find((m) => m.type === 'video')

    if (hasVideo) {
      dispatch(updateCreatePost({ coverPhotoOffset: 0 }))
    }

    if (platforms.includes('instagram') && hasVideo && media.length > 1) {
      dispatch(updateCreatePost({ instagramPublishType: 'feed' }))
    }
  }, [post?.media?.length, post?.socialProfiles?.length])

  useEffect(() => {
    if (!isSaved) {
      savePost()
    }

    return () => {
      savePost.cancel()
    }
  }, [{ ...post, _id: undefined }])

  useEffect(() => {
    loadPostFromUrl()
    // dispatch(setTour({ tour: 'post_editor_overview', onlyOnce: true }))
  }, [location.search])

  return (
    <StyledCreateView style={{ backgroundColor: t === 'dark' ? '#0c0c0c' : theme.color.gray[100] }}>
      <Helmet title={`${post?.title || 'Untitled Post'} | Social Curator`} />
      <CreateHeader />
      <div className="create-layout">
        <CreateBar />
        <div className="create-editor-layout">
          <CreateEditorErrors />
          <CreateEditor />
        </div>
      </div>
      {/* <ChatOnboarding /> */}
    </StyledCreateView>
  )
}

export default CreateView
