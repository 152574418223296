import { gql } from '@apollo/client'

export const getPlatformStats = gql`
  query GetPlatformStats {
    getPlatformStats {
      createdPosts
      createdPostsThisWeek
      streak
      week
    }
  }
`
