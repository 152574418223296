import { ContextType, FC, MutableRefObject, useContext } from 'react'
import {
  ScrollMenu,
  Props as ScrollProps,
  VisibilityContext
} from 'react-horizontal-scrolling-menu'
import classNames from 'classnames'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { Button } from '..'
import { StyledScrollRow } from '.'

type Props = {
  children: ScrollProps['children']
  arrowOffset?: number
  gap?: number
  padding?: number
  isGray?: boolean
  apiRef?: MutableRefObject<ContextType<typeof VisibilityContext>>
  className?: string
}

const ScrollRow: FC<Props> = ({
  children,
  arrowOffset = 35,
  gap = 12,
  padding,
  isGray,
  apiRef,
  className
}) => {
  const ArrowLeft = () => {
    const { isFirstItemVisible, initComplete, scrollPrev } = useContext(VisibilityContext)

    if (!initComplete || isFirstItemVisible) {
      return <></>
    }

    return (
      <div className="arrow-background arrow-left">
        <Button
          size="sm"
          className="arrow-btn"
          variant="outline"
          type="button"
          isIcon
          onClick={() => scrollPrev()}
        >
          <ChevronLeftIcon />
        </Button>
      </div>
    )
  }

  const ArrowRight = () => {
    const { isLastItemVisible, scrollNext } = useContext(VisibilityContext)

    if (isLastItemVisible) {
      return <></>
    }

    return (
      <div className="arrow-background arrow-right">
        <Button
          size="sm"
          className="arrow-btn"
          variant="outline"
          type="button"
          isIcon
          onClick={() => scrollNext()}
        >
          <ChevronRightIcon />
        </Button>
      </div>
    )
  }

  return (
    <StyledScrollRow
      className={classNames('scroll-row', className)}
      arrowOffset={arrowOffset}
      gap={gap}
      padding={padding}
      isGray={isGray}
    >
      <ScrollMenu
        scrollContainerClassName="scroll-row-overflow"
        apiRef={apiRef}
        LeftArrow={<ArrowLeft />}
        RightArrow={<ArrowRight />}
      >
        {children}
      </ScrollMenu>
    </StyledScrollRow>
  )
}

export default ScrollRow
