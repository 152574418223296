import { FC, createRef, useEffect, useState } from 'react'
import {
  EllipsisVerticalIcon,
  HashtagIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon
} from '@heroicons/react/24/outline'
import { setHashtagFolder, useAppDispatch, useAppSelector } from '../../../redux'
import { HashtagFolderType, HashtagType } from '../../../types'
import { useHashtag } from '../../../utils'
import { ActionDialog, Button, InputDialog, NewHashtagDialog, OptionsPopover } from '../..'
import { StyledHashtagFolderListItem } from '.'

type Props = {
  folder: HashtagFolderType
}

const HashtagFolderListItem: FC<Props> = ({ folder }) => {
  const optionsBtnRef = createRef<HTMLButtonElement>()
  const dispatch = useAppDispatch()
  const { hashtags } = useAppSelector((state) => state.hashtags)
  const { removeHashtagFolder, addHashtagToPost, updateHashtagFolderData } = useHashtag()
  const [newSetName, setNewSetName] = useState(folder.label)
  const [showNewHashtagDialog, setShowNewHashtagDialog] = useState(false)
  const [showRenameDialog, setShowRenameDialog] = useState(false)
  const [showOptions, setShowOptions] = useState(false)
  const [showDeletionConfirmation, setShowDeletionConfirmation] = useState(false)
  const [folderHashtags, setFolderHashtags] = useState<HashtagType[]>([])

  const handleUse = () => {
    folderHashtags.forEach((hashtag) => {
      addHashtagToPost(hashtag)
    })
  }

  useEffect(() => {
    setFolderHashtags(hashtags.filter((hashtag) => hashtag.folder === folder._id))
  }, [hashtags])

  useEffect(() => {
    setNewSetName(folder.label)
  }, [showRenameDialog])

  return (
    <>
      <StyledHashtagFolderListItem
        showOptions={showOptions}
        onClick={() => dispatch(setHashtagFolder(folder._id))}
      >
        <div className="hashtag-folder-details-container">
          <div className="hashtag-folder-header">
            <span className="hashtag-folder-title">{folder.label}</span>
            <span className="hashtag-folder-count">{folderHashtags.length}</span>
          </div>
          <div className="hashtag-folder-preview">
            {folderHashtags.map((hashtag) => {
              return <>#{hashtag.hashtag} </>
            })}
          </div>
        </div>
        <div className="hashtag-folder-actions">
          <Button
            variant="emphasis"
            onClick={(e) => {
              e.stopPropagation()
              handleUse()
            }}
          >
            Use
          </Button>
          <Button
            isIcon
            className="options-btn"
            ref={optionsBtnRef}
            onClick={(e) => {
              e.stopPropagation()
              setShowOptions(!showOptions)
            }}
          >
            <EllipsisVerticalIcon />
          </Button>
        </div>
      </StyledHashtagFolderListItem>
      <NewHashtagDialog
        folder={folder._id === 'uncategorized' ? undefined : folder._id}
        isOpen={showNewHashtagDialog}
        onClose={() => setShowNewHashtagDialog(false)}
      />
      <ActionDialog
        title="Delete hashtag set"
        body={`Are you sure you want ${folder.label}?`}
        type="error"
        confirmButtonText="Delete"
        isOpen={showDeletionConfirmation}
        onClose={() => setShowDeletionConfirmation(false)}
        onConfirm={() => removeHashtagFolder(folder._id)}
      />
      <InputDialog
        isOpen={showRenameDialog}
        title="Rename hashtag set"
        value={newSetName}
        confirmButtonText="Rename"
        onConfirm={() => updateHashtagFolderData({ ...folder, label: newSetName })}
        onChange={(value) => setNewSetName(value)}
        onClose={() => setShowRenameDialog(false)}
      />
      <OptionsPopover
        options={[
          {
            icon: <PencilIcon />,
            label: 'Edit hashtag set',
            action: () => dispatch(setHashtagFolder(folder._id))
          },
          {
            icon: <PlusIcon />,
            label: 'Use hashtags',
            action: () => handleUse()
          },
          {
            icon: <TrashIcon />,
            label: 'Delete',
            action: () => setShowDeletionConfirmation(true)
          }
        ]}
        divsAfter={['Use hashtags']}
        buttonRef={optionsBtnRef}
        classToAvoid="options-btn"
        isOpen={showOptions}
        onClose={() => setShowOptions(false)}
      />
    </>
  )
}

export default HashtagFolderListItem
