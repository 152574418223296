import { gql } from '@apollo/client'

export const dailyActionCreateOne = gql`
  mutation DailyActionCreateOne(
    $action: String!
    $category: String
    $url: String
    $label: String
    $caption: String
    $length: String
    $isUrl: Boolean
    $isActive: Boolean
  ) {
    dailyActionCreateOne(
      record: {
        action: $action
        category: $category
        url: $url
        label: $label
        caption: $caption
        length: $length
        isUrl: $isUrl
        isActive: $isActive
      }
    ) {
      record {
        _id
      }
    }
  }
`
