import { createContext, ReactNode } from 'react'
import { UserType } from '../types'
import { useUser } from '.'

type InitialState = {
  user: UserType | null
  loaded: boolean
  loading: boolean
  logout: () => Promise<void>
  refresh: () => Promise<void>
}

const initialState: InitialState = {
  user: null,
  loaded: false,
  loading: true,
  logout: () => Promise.resolve(),
  refresh: () => Promise.resolve()
}

const userStore = createContext(initialState)
const { Provider } = userStore

const UserProvider = ({ children }: { children: ReactNode }) => {
  const { user, loaded, loading, logout, refresh } = useUser()

  return (
    <Provider
      value={{
        user,
        loaded,
        loading,
        logout,
        refresh
      }}
    >
      {children}
    </Provider>
  )
}

export { userStore, UserProvider, Provider }
