import { FC } from 'react'
import { PlusIcon } from '@heroicons/react/24/outline'
import { setHashtagFolder, useAppDispatch, useAppSelector } from '../../../redux'
import { Button } from '../..'
import { HashtagFolderEdit, HashtagFolderList } from '..'
import { StyledHashtagBank } from '.'

const HashtagBank: FC = () => {
  const dispatch = useAppDispatch()
  const { folder } = useAppSelector((state) => state.hashtags)

  if (folder) {
    return <HashtagFolderEdit />
  }

  return (
    <StyledHashtagBank>
      <div className="hashtag-bank-folders scrollable">
        <HashtagFolderList />
      </div>
      <div className="hashtag-bank-actions">
        <Button
          variant="outline"
          iconPos="left"
          isFullWidth
          onClick={() => dispatch(setHashtagFolder('new'))}
        >
          <PlusIcon />
          Create Hashtag Set
        </Button>
      </div>
    </StyledHashtagBank>
  )
}

export default HashtagBank
