import { SocialProfileType } from '../../../types'

export const getPlatformDescription = (platform: SocialProfileType['type']) => {
  switch (platform) {
    case 'instagram':
      return 'Account'
    case 'facebook':
    // case 'linkedin-page':
    //   return 'Page'
    case 'linkedin':
    // case 'pinterest':
    // case 'tiktok':
    case 'twitter':
      return 'Profile'
  }
}
