import { FC, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useApolloClient } from '@apollo/client'
import { CloudIcon, FolderIcon } from '@heroicons/react/24/outline'
import { loadFoldersFromCloud } from '../../../graphql'
import { CloudContentType, PhotoType } from '../../../types'
import { linkToSizedImage, useCloud } from '../../../utils'
import { Button, Checkbox, Dialog, Spinner } from '../..'
import { StyledAddToCloudDialog } from '.'

type Props = {
  photo: PhotoType
  isOpen: boolean
  onClose: () => void
}

const AddToCloudDialog: FC<Props> = ({ photo, isOpen, onClose }) => {
  const apollo = useApolloClient()
  const { addGalleryPhotoToCloud } = useCloud()
  const [folders, setFolders] = useState<CloudContentType[]>([])
  const [folder, setFolder] = useState<string>()
  const [loading, setLoading] = useState(false)

  const loadFolders = async () => {
    try {
      const { data } = await apollo.query({
        fetchPolicy: 'no-cache',
        query: loadFoldersFromCloud
      })

      setFolders(data?.loadFoldersFromCloud || [])
    } catch (err) {
      console.log(err)
      toast.error((err as any).message)
    }
  }

  const handleSubmit = async () => {
    try {
      setLoading(true)
      await addGalleryPhotoToCloud(photo, folder)
      onClose()
    } catch (err) {
      console.log(err)
      toast.error((err as any).message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setFolders([])
    setFolder(undefined)
    setLoading(false)

    if (isOpen) {
      loadFolders()
    }
  }, [isOpen])

  return (
    <Dialog hasXIcon isOpen={isOpen} onClose={() => onClose()}>
      <StyledAddToCloudDialog>
        <div className="photo-preview">
          <div className="photo-img">
            <img src={linkToSizedImage(photo.file.fileHttpLink, { width: 500 })} alt="" />
          </div>
        </div>
        <div className="folder-list">
          <label className="folder-select-btn">
            <div className="folder-details">
              <CloudIcon />
              <span>No Folder</span>
            </div>
            <Checkbox value={!folder} onChange={() => setFolder(undefined)} />
          </label>
          {folders.map((f) => {
            return (
              <label className="folder-select-btn" key={f._id}>
                <div className="folder-details">
                  <FolderIcon />
                  <span>{f.name}</span>
                </div>
                <Checkbox value={folder === f._id} onChange={() => setFolder(f._id)} />
              </label>
            )
          })}
        </div>
        <div className="actions">
          <Button variant="emphasis" isFullWidth onClick={() => handleSubmit()}>
            {loading ? <Spinner /> : 'Add to Uploads'}
          </Button>
        </div>
      </StyledAddToCloudDialog>
    </Dialog>
  )
}

export default AddToCloudDialog
