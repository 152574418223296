import { FC, ReactNode, RefObject, useEffect, useState } from 'react'
import { CheckIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { isMobile } from '../../../utils'
import { Input, OptionDrawer, Popover } from '../..'
import { StyledOptionsPopover } from '.'

type Props = {
  position?: 'top-left' | 'top-center' | 'bottom-right' | 'bottom-left' | 'top-right'
  options: {
    icon?: ReactNode
    label: string
    render?: ReactNode
    isSelected?: boolean
    isToggle?: boolean
    stayOpen?: boolean
    action: () => void
  }[]
  height?: number
  width?: number
  divsAfter?: string[]
  labelsBefore?: {
    label: string
    before: string
  }[]
  classToAvoid: string
  buttonRef: RefObject<HTMLDivElement | HTMLButtonElement>
  sameParent?: boolean
  isOpen: boolean
  isSearchable?: boolean
  onClose: () => void
}

const OptionsPopover: FC<Props> = ({
  position = 'bottom-right',
  options,
  height = 450,
  width = 200,
  divsAfter,
  labelsBefore,
  isSearchable,
  isOpen,
  onClose,
  ...rest
}) => {
  const [search, setSearch] = useState('')

  const handleAction = (option: Props['options'][number]) => {
    option.action()

    if (!option.stayOpen) {
      onClose()
    }
  }

  useEffect(() => {
    setSearch('')
  }, [isOpen])

  if (isMobile()) {
    return (
      <OptionDrawer
        options={options}
        divsAfter={divsAfter}
        isOpen={isOpen}
        onClose={() => onClose()}
        {...rest}
      />
    )
  }

  return (
    <Popover position={position} isOpen={isOpen} onClose={() => onClose()} {...rest}>
      <StyledOptionsPopover height={height} width={width}>
        {isSearchable && (
          <div className="search-bar">
            <Input
              size="sm"
              icon={<MagnifyingGlassIcon />}
              variant="fill"
              placeholder="Search..."
              type="search"
              onChange={(e) => setSearch(e.target.value?.toLowerCase())}
            />
          </div>
        )}
        <div className="menu-group">
          {options
            .filter((option) => !search.trim() || option.label.toLowerCase().includes(search))
            .map((option, index) => {
              return (
                <>
                  {labelsBefore?.find((l) => l.before === option.label) && (
                    <span className="options-label" key={`${index}-label`}>
                      {labelsBefore?.find((l) => l.before === option.label)?.label}
                    </span>
                  )}
                  <button
                    aria-label={option.label}
                    aria-selected={option.isSelected}
                    onClick={() => handleAction(option)}
                    key={index}
                  >
                    <div className="option-label">
                      {option.icon}
                      {option.render || <span className="option-label-text">{option.label}</span>}
                    </div>
                    {option.isSelected && !option.isToggle && (
                      <div className="selected-icon">
                        <CheckIcon />
                      </div>
                    )}
                    {option.isToggle && (
                      <div className="option-toggle">
                        <div className="option-toggle-dot" />
                      </div>
                    )}
                  </button>
                  {divsAfter?.includes(option.label) && <hr key={`${index}-divider`} />}
                </>
              )
            })}
          {!options.filter(
            (option) => !search.trim() || option.label.toLowerCase().includes(search)
          ).length && <span className="empty-state">Couldn't find any results for "{search}"</span>}
        </div>
      </StyledOptionsPopover>
    </Popover>
  )
}

export default OptionsPopover
