import styled from 'styled-components'

const StyledPhotoList = styled.div`
  padding: 4px 4px 120px 4px;
  box-sizing: border-box;

  .masonry-grid {
    display: flex;
    margin-left: -4px;
    width: auto;

    .masonry-grid-column {
      padding-left: 4px;
      background-clip: padding-box;
    }
  }
`

export default StyledPhotoList
