import { FC } from 'react'
import { useAppSelector } from '../../../redux'
import { HashtagBank } from '../..'
import { CreateBarHashtagsRecommended, CreateBarPanel } from '..'
import { StyledCreateBarHashtags } from '.'

const CreateBarHashtags: FC = () => {
  const { folder } = useAppSelector((state) => state.hashtags)

  return (
    <CreateBarPanel>
      <StyledCreateBarHashtags
        // editingFolder={!!folder}
        editingFolder={true}
      >
        {/* {!folder && <CreateBarHashtagsRecommended />} */}
        <HashtagBank />
      </StyledCreateBarHashtags>
    </CreateBarPanel>
  )
}

export default CreateBarHashtags
