import styled from 'styled-components'

const StyledResizePopover = styled.div`
  width: 400px;

  .search-input,
  .search-input:hover,
  .search-input:focus-within {
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};

    &:hover,
    &:focus-within {
      border-bottom: 1px solid ${({ theme }) => theme.color.gray[400]};
    }
  }

  .resize-option-list {
    max-height: 400px;
    overflow-y: auto;

    .resize-option-label {
      font-size: 14px;
      font-weight: 500;
      display: block;
      cursor: auto;
      margin-bottom: 10px;
      color: ${({ theme }) => theme.color.gray[500]};
    }

    .resize-custom {
      padding: 16px;
      border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};

      .resize-custom-input-container {
        display: flex;
        align-items: end;
        margin-top: 16px;

        .resize-custom-input {
          margin-right: 10px;

          label {
            .resize-custom-input-label {
              font-size: 14px;
              font-weight: 500;
              display: block;
              cursor: auto;
              margin-bottom: 6px;
              color: ${({ theme }) => theme.color.gray[700]};
            }
          }
        }

        .resize-custom-input-metric {
          height: 40px;
          font-size: 14px;
          font-weight: 500;
          color: ${({ theme }) => theme.color.gray[600]};
          display: flex;
          align-items: center;
          justify-content: scenter;
        }
      }
    }

    .resize-template-list {
      display: grid;
      padding: 10px 0;

      .resize-option-label {
        padding: 6px 16px 0 16px;
      }

      .resize-template-list-item {
        display: flex;
        align-items: center;
        background-color: transparent;
        padding: 6px 16px;

        .resize-template-preview {
          width: 30px;
          height: 30px;
          position: relative;
          margin-right: 16px;
          display: flex;
          align-items: center;
          justify-content: center;

          .resize-template-preview-card {
            width: 100%;
            max-height: 100%;
            position: absolute;
            top: 50%;
            border-radius: 4px;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 1px dashed ${({ theme }) => theme.color.gray[300]};
            background-color: ${({ theme }) => theme.color.gray[100]};
          }
        }

        .resize-template-label {
          color: ${({ theme }) => theme.color.gray[700]};
        }

        .resize-template-popular {
          font-size: 12px;
          margin-left: 10px;
          font-weight: 600;
          padding: 4px 6px;
          border-radius: 5px;
          background-color: ${({ theme }) => theme.color.brandLight};
          color: ${({ theme }) => theme.color.brand};
        }

        .resize-template-size {
          font-size: 13px;
          margin-left: 10px;
          opacity: 0;
          color: ${({ theme }) => theme.color.gray[500]};
        }

        &:hover {
          background-color: ${({ theme }) => theme.color.gray[100]};

          .resize-template-size {
            opacity: 1;
          }
        }

        &.active {
          background-color: ${({ theme }) => theme.color.brandLight};

          .resize-template-preview {
            .resize-template-preview-card {
              border: 1px dashed ${({ theme }) => theme.color.brand};
              background-color: ${({ theme }) => theme.color.brand}30;
            }
          }

          .resize-template-label {
            color: ${({ theme }) => theme.color.brand};
          }

          .resize-template-popular {
            background-color: ${({ theme }) => theme.color.background};
          }

          .resize-template-size {
            color: ${({ theme }) => theme.color.gray[500]};
          }
        }
      }
    }
  }

  .resize-actions {
    border-top: 1px solid ${({ theme }) => theme.color.gray[300]};
    padding: 16px;
  }
`

export default StyledResizePopover
