import styled from 'styled-components'

const StyledOptionsSheet = styled.div`
  padding: 0 14px var(--bottom-spacing) 14px;

  .option-group {
    border-radius: 12px;
    overflow: hidden;
    background-color: ${({ theme }) => theme.color.gray[100]};

    button {
      padding: 14px;
      text-align: left;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: ${({ theme }) => theme.color.gray[900]};
      font-weight: 600;
      width: 100%;
      box-sizing: border-box;

      svg {
        height: 24px;
        stroke-width: 1.75px;
      }

      &:not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
      }

      &.destructive {
        background-color: ${({ theme }) => theme.color.red[100]};
        color: ${({ theme }) => theme.color.red[800]};
      }
    }
  }
`

export default StyledOptionsSheet
