import styled from 'styled-components'

type StyledProps = {
  showBorder?: boolean
}

const StyledHeader = styled.header<StyledProps>`
  border-bottom: 1px solid;
  background-color: ${({ theme }) => theme.color.background};
  padding-top: var(--top-spacing);
  padding-bottom: 14px;
  z-index: ${({ theme }) => theme.elevation.fixed};
  ${({ showBorder, theme }) =>
    `border-color: ${showBorder ? theme.color.gray[300] : 'transparent'};`}

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;

    .logo-link {
      display: flex;
      align-items: center;
      color: ${({ theme }) => theme.color.gray[900]};

      svg {
        height: 32px;
      }
    }

    .header-group {
      display: flex;
      align-items: center;

      .header-btn {
        height: 32px;
        width: 32px;
        background: transparent;
        border-radius: 50%;
        color: ${({ theme }) => theme.color.gray[400]};
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          height: 32px;
          width: 32px;
        }

        &.profile-btn {
          margin-left: 12px;
          background-color: ${({ theme }) => theme.color.gray[200]};

          svg {
            height: 24px;
            width: 24px;
          }
        }
      }
    }
  }
`

export default StyledHeader
