import styled from 'styled-components'

const StyledForgotPasswordView = styled.div`
  .sent-indicator {
    margin-top: 35px;
    height: 70px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    user-select: none;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    transition: transform 0.2s;
    border: 1px solid ${({ theme }) => theme.color.green[700]};
    color: ${({ theme }) => theme.color.background};
    background-color: ${({ theme }) => theme.color.green[700]};

    svg {
      height: 28px;
      margin-right: 6px;
    }
  }
`

export default StyledForgotPasswordView
