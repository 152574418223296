import { FC } from 'react'
import { StyledTab } from '.'

type Props = {
  tab: {
    label: string
    isActive?: boolean
    onClick: () => void
  }
}

const Tab: FC<Props> = ({ tab }) => {
  return (
    <StyledTab className="tab" aria-selected={tab.isActive} onClick={() => tab.onClick()}>
      {tab.label}
    </StyledTab>
  )
}

export default Tab
