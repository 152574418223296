import { gql } from '@apollo/client'

export const chatHandleDailyAction = gql`
  query ChatHandleDailyAction($prompt: String!, $conversation: String!) {
    chatHandleDailyAction(prompt: $prompt, conversation: $conversation) {
      _id
      content
      prompt
      type
      conversation
      status
      template
      alt
      metadata
      createdAt
      ref
    }
  }
`
