import { FC, useContext, useEffect } from 'react'
import { toast } from 'react-hot-toast'
import { parse } from 'query-string'
import { App, URLOpenListenerEvent } from '@capacitor/app'
import { Browser } from '@capacitor/browser'
import { Capacitor } from '@capacitor/core'
import { connectionStore } from '../../../components'
import { setShowConnections, useAppDispatch } from '../../../redux'
import { useHandleRoute } from '../../../utils'

const DeepLinkListener: FC = () => {
  const dispatch = useAppDispatch()
  const { handleRoute } = useHandleRoute()
  const { handleConnectAccountsFromState } = useContext(connectionStore)

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) {
      return
    }

    App.addListener('appUrlOpen', async (event: URLOpenListenerEvent) => {
      const url = event?.url
      const params = url?.split('?')[1]
      const parsed = parse(params)

      if (!url) {
        return
      }

      if (url.includes('.com/community')) {
        await Browser.open({ url, presentationStyle: 'fullscreen' })
      }

      if (!!parsed?.mobile) {
        await Browser.close()
      }

      if (!!parsed?.state) {
        await handleConnectAccountsFromState(parsed)
        dispatch(setShowConnections(false))
      }

      if (!!parsed?.auth) {
        toast('Now you can log into your account')
      }

      handleRoute(url, parsed)
    })

    return () => {
      App.removeAllListeners()
    }
  }, [])

  return null
}

export default DeepLinkListener
