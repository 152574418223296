import styled, { keyframes } from 'styled-components'

const popInAnim = keyframes`
  from {
    opacity: 0;
    bottom: 10px;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    bottom: 20px;
    transform: scale(1);
  }
`

const popOutAnim = keyframes`
  from {
    opacity: 1;
    bottom: 20px;
    transform: scale(1);
  }
  to {
    opacity: 0;
    bottom: 10px;
    transform: scale(0.9);
  }
`

type StyledProps = {
  type: 'thumbs' | 'qa' | 'likely'
  showCloseAnim?: boolean
}

const StyledSurvey = styled.div<StyledProps>`
  z-index: ${({ theme }) => theme.elevation.override};
  border-radius: 10px;
  position: fixed;
  bottom: 20px;
  user-select: none;
  right: 20px;
  outline: none;
  max-width: 300px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.color.background};
  margin: 0;
  padding: 40px;
  border: 0;
  box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 0.1), 0 0 0 1px #00000019,
    0 2px 4px 0px #00000036;
  animation: forwards 0.1s ease-in-out
    ${({ showCloseAnim }) => (showCloseAnim ? popOutAnim : popInAnim)};

  .close-survey-btn {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;
    color: rgb(0, 0, 0, 0.4);
    background-color: transparent;

    svg {
      height: 24px;
    }

    &:hover {
      color: rgb(0, 0, 0, 0.5);
    }
  }

  .survey-question {
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    color: ${({ theme }) => theme.color.gray[800]};
  }

  .survey-answers {
    margin-top: 20px;

    ${({ type }) =>
      (type === 'likely' || type === 'thumbs') &&
      `
      display: flex;
      justify-content: center;
    `}

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 46px;
      border-radius: 50%;
      font-size: 16px;
      border: 1px solid ${({ theme }) => theme.color.gray[200]};
      background-color: ${({ theme }) => theme.color.gray[100]};
      color: ${({ theme }) => theme.color.gray[600]};
      transition: 0.2s;
      position: relative;

      ${({ type }) =>
        (type === 'likely' || type === 'thumbs') &&
        `
        font-size: 24px;
        width: 46px;

        &:not(:last-child) {
          margin-right: 16px;
        }
      `}

      &:hover,
      &:focus-visible {
        border: 1px solid ${({ theme }) => theme.color.brand};
        box-shadow: 0 0 0 1px ${({ theme }) => theme.color.brand};

        ${({ type }) =>
          (type === 'likely' || type === 'thumbs') &&
          `
          font-size: 28px;
        `}

        &::after {
          opacity: 1;
        }
      }

      &::after {
        content: attr(aria-label);
        position: absolute;
        top: calc(100% + 5px);
        text-align: center;
        left: 50%;
        transform: translateX(-50%);
        font-size: 13px;
        white-space: nowrap;
        font-weight: 500;
        opacity: 0;
        pointer-events: none;
        transition: 0.2s;
        color: ${({ theme }) => theme.color.gray[500]};
      }
    }
  }
`

export default StyledSurvey
