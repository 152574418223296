import { FC, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { StyledMediaByUrlDialog } from '.'

import { InputDialog } from '../InputDialog'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const MediaByUrlDialog: FC<Props> = ({ isOpen, onClose }) => {
  const [url, setUrl] = useState('')
  const [loading, setLoading] = useState(false)
  // const {
  //   actions: { addMediaByUrl }
  // } = useEditor()

  const handleSubmit = async () => {
    try {
      setLoading(true)
      // await addMediaByUrl(url)
      onClose()
    } catch (err) {
      console.error(err)
      toast.error((err as any)?.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setUrl('')
    setLoading(false)
  }, [isOpen])

  return (
    <InputDialog
      title="Add photo or video by URL"
      value={url}
      inputProps={{
        type: 'url',
        placeholder: 'URL of photo or video'
      }}
      confirmButtonText="Add to post"
      closeOnConfirm={false}
      isOpen={isOpen}
      isLoading={loading}
      onClose={() => onClose()}
      onConfirm={() => handleSubmit()}
      onChange={(value) => setUrl(value)}
    />
  )
}

export default MediaByUrlDialog
