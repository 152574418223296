import { gql } from '@apollo/client'

export const getTodaysAction = gql`
  query GetTodaysAction {
    getTodaysAction {
      _id
      action {
        category
        action
        url
        label
        caption
        length
        isUrl
        isActive
      }
      createdAt
      isCompleted
      streak
    }
  }
`
