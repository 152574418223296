import { FC } from 'react'
import { ChatConversationList, ChatHeader, ChatInput } from '../../../chat'
import { StyledDesignerChatPanel } from '.'

const DesignerChatPanel: FC = () => {
  return (
    <StyledDesignerChatPanel>
      <ChatConversationList />
      <ChatInput />
    </StyledDesignerChatPanel>
  )
}

export default DesignerChatPanel
