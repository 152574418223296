import { FC, useContext, useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import { useApolloClient } from '@apollo/client'
import {
  affiliateEarningFindBetweenDates,
  affiliateEarningFindMany,
  affiliateLinkClickCount
} from '../../../graphql'
import { AffiliateEarningType } from '../../../types'
import { userStore } from '../../../utils'
import { StyledReferralStats } from '.'

const ReferralStats: FC = () => {
  const apollo = useApolloClient()
  const { user } = useContext(userStore)
  const [nextPayout, setNextPayout] = useState(0)
  const [lastPayout, setLastPayout] = useState(0)
  const [totalPayout, setTotalPayout] = useState(0)
  const [totalClicks, setTotalClicks] = useState(0)
  const [totalReferrals, setTotalReferrals] = useState(0)

  const loadNextPayout = async () => {
    const oneMonthAgo = DateTime.utc().startOf('month').toJSDate()
    const twoMonthsAgo = DateTime.utc().minus({ months: 1 }).startOf('month').toJSDate()

    const { data } = await apollo.query({
      fetchPolicy: 'no-cache',
      query: affiliateEarningFindBetweenDates,
      variables: {
        affiliate: user?.id,
        greaterThanDate: twoMonthsAgo,
        lessThanDate: oneMonthAgo
      }
    })

    const affiliateEarnings = data.affiliateEarningFindMany as AffiliateEarningType[]
    const nextPayoutAmount = affiliateEarnings.reduce((total, earning) => {
      return total + earning.amount
    }, 0)

    setNextPayout(nextPayoutAmount)
  }

  const loadLastPayout = async () => {
    const twoMonthsAgo = DateTime.utc().minus({ months: 1 }).startOf('month').toJSDate()
    const threeMonthsAgo = DateTime.utc().minus({ months: 2 }).startOf('month').toJSDate()

    const { data } = await apollo.query({
      fetchPolicy: 'no-cache',
      query: affiliateEarningFindBetweenDates,
      variables: {
        affiliate: user?.id,
        greaterThanDate: threeMonthsAgo,
        lessThanDate: twoMonthsAgo
      }
    })

    const affiliateEarnings = data.affiliateEarningFindMany as AffiliateEarningType[]
    const lastPayoutAmount = affiliateEarnings.reduce((total, earning) => {
      return total + earning.amount
    }, 0)

    setLastPayout(lastPayoutAmount)
  }

  const loadForever = async () => {
    const { data } = await apollo.query({
      fetchPolicy: 'no-cache',
      query: affiliateEarningFindMany,
      variables: { affiliate: user?.id }
    })

    const affiliateEarnings = data.affiliateEarningFindMany as AffiliateEarningType[]
    const totalEarnings = affiliateEarnings.reduce((total, earning) => {
      return total + earning.amount
    }, 0)

    const totalReferrals = affiliateEarnings.length

    setTotalPayout(totalEarnings)
    setTotalReferrals(totalReferrals)
  }

  const loadLinkClicks = async () => {
    const greaterThanDate = DateTime.utc().minus({ years: 10 }).startOf('year').toJSDate()
    const lessThanDate = DateTime.utc().plus({ years: 10 }).startOf('year').toJSDate()

    const { data } = await apollo.query({
      fetchPolicy: 'no-cache',
      query: affiliateLinkClickCount,
      variables: { affiliate: user?.id, greaterThanDate, lessThanDate }
    })

    setTotalClicks(data.affiliateLinkClickCount)
  }

  useEffect(() => {
    loadNextPayout()
    loadLastPayout()
    loadForever()
    loadLinkClicks()
  }, [])

  return (
    <StyledReferralStats>
      <div className="stat-row">
        <b>Clicks</b>
        <span>Number of times your link has been clicked</span>
        <code>{totalClicks}</code>
      </div>
      <div className="stat-row">
        <b>Referrals</b>
        <span>Number of people who signed up for a trial with your link</span>
        <code>{totalReferrals}</code>
      </div>
      <div className="stat-row">
        <b>Next payout</b>
        <span>
          Amount you'll be paid on{' '}
          {DateTime.utc().plus({ months: 1 }).startOf('month').toFormat('MMM. d')} for earnings of{' '}
          {DateTime.utc().minus({ months: 1 }).startOf('month').toFormat('MMM. y')}
        </span>
        <code>${nextPayout.toFixed(2)}</code>
      </div>
      <div className="stat-row">
        <b>Last payout</b>
        <span>
          Amount you were paid on {DateTime.utc().startOf('month').toFormat('MMM. d')} for earnings
          of {DateTime.utc().minus({ months: 2 }).startOf('month').toFormat('MMM. y')}
        </span>
        <code>${lastPayout.toFixed(2)}</code>
      </div>
      <div className="stat-row">
        <b>Paid</b>
        <span>Total amount you've made since becoming an affiliate</span>
        <code>${totalPayout.toFixed(2)}</code>
      </div>
    </StyledReferralStats>
  )
}

export default ReferralStats
