import firebase from 'firebase/app'
import { v4 as uuid } from 'uuid'

export const getDimensions = async (blob: Blob, type: string) => {
  return (await new Promise(async (resolve, reject) => {
    if (type === 'video') {
      const video = document.createElement('video')
      const canvas = document.createElement('canvas')

      video.addEventListener(
        'loadeddata',
        () => {
          video.currentTime = Math.floor(video.duration / 2)
          video.play()
        },
        false
      )

      video.addEventListener(
        'timeupdate',
        async () => {
          video.pause()

          const height = video.videoHeight
          const width = video.videoWidth

          const storageRef = firebase.storage().ref()
          const fileRef = storageRef.child(`user-images/${uuid()}.png`)

          canvas.width = width
          canvas.height = height
          canvas.getContext('2d')?.drawImage(video, 0, 0)

          const previewBlob: Blob | null = await new Promise((resolve) =>
            canvas.toBlob((blob) => resolve(blob))
          )

          if (!previewBlob) {
            return resolve({ x: width, y: height })
          }

          const uploadTask = await fileRef.put(previewBlob)
          const preview = await uploadTask.ref.getDownloadURL()

          resolve({ x: width, y: height, preview: preview })
        },
        false
      )

      video.onerror = reject

      video.src = URL.createObjectURL(blob)
      video.play()
      return
    }

    const img = new Image()
    img.src = window.URL.createObjectURL(blob)

    img.onload = async () => {
      resolve({ x: img.width, y: img.height })
    }

    img.onerror = reject
  })) as { x: number; y: number; preview?: string } | undefined
}
