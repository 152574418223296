export const day5 = {
  _id: 'day5',
  title: 'DAY 5',
  tasks: [
    {
      label: 'Upload an image to the Cloud',
      steps: [
        // {
        //   label: 'Navigate to the Cloud using the sidebar'
        // },
        // {
        //   label: 'Either:'
        // },
        // {
        //   label: 'Drag & drop an image into the “dropzone”',
        //   isSub: true
        // },
        // {
        //   label: 'Click the “dropzone” and select an image',
        //   isSub: true
        // }
      ]
    },
    {
      label: 'Rename your uploaded image',
      steps: [
        // {
        //   label: 'Hover over the image in the “Uploads” area'
        // },
        // {
        //   label: 'Click on the ellipsis (⋯) button'
        // },
        // {
        //   label: 'In the opened dropdown, select “Rename file”'
        // },
        // {
        //   label: 'Enter a new name for the file, and click the “Rename” button'
        // }
      ]
    },
    {
      label: 'Create a collection',
      steps: [
        // {
        //   label: 'Click the “New Collection” button'
        // },
        // {
        //   label: 'Type a name for the collection'
        // },
        // {
        //   label: 'Click the “Create Collection” button'
        // }
      ]
    },
    {
      label: 'Move your uploaded image to the collection',
      steps: [
        // {
        //   label: 'Click & hold anywhere on an uploaded image and drag it'
        // },
        // {
        //   label: '“Drop” it into your newly created collection'
        // }
      ]
    },
    {
      label: 'Delete your uploaded image',
      steps: [
        // {
        //   label: 'Click on the newly created collection'
        // },
        // {
        //   label: 'Hover over the image in the “Uploads” area'
        // },
        // {
        //   label: 'Click on the ellipsis (⋯) button'
        // },
        // {
        //   label: 'In the opened dropdown, select “Delete file”'
        // },
        // {
        //   label: 'Confirm you want to delete the file by clicking the “Delete” button'
        // }
      ]
    }
  ],
  form: {
    url: 'https://notionforms.io/forms/day-5-feedback',
    keys: {
      FULL_NAME: '5c2abff4-ae4a-43ca-9b3b-efa8c6299a2f',
      EMAIL: 'fcf4b572-8f51-423a-b8ef-d0b6c37193d1',
      SCREEN_RECORDING: '7b852a64-1e43-4897-ab39-3abe5d93c5f6',
      USER_ID: '53f3fa75-dbe3-446c-94ab-0d2064c76a8b'
    }
  }
}
