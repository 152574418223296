import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { CouponType, PhotoSetType, StorySetType } from '../../types'
import { LEVELS } from '../../utils'

type InitialState = {
  showChat: boolean
  showCreate: boolean
  showConnections: boolean
  showConnectionsModal: boolean
  showFeedbackModal: boolean
  showMobileSidebar?: boolean
  showDesigner?: boolean
  stepsCollapsed: boolean
  sidebarMoreOpen?: boolean
  sidebarCollapsed: boolean
  surveys: string[]
  hints: string[]
  workspace?: string
  showEducationalOnboarding?: boolean
  contentToView?: {
    content: StorySetType | PhotoSetType
    type: 'story' | 'extra' | 'photo-set'
  }
  contextBubbles: string[]
  theme: 'light' | 'dark'
  referral?: {
    code: string
    date: string
  }
  coupon?: CouponType
  appInfo?: {
    build: string
    version: string
  }
  level: (typeof LEVELS)[number]
  postsNum: number
}

const initialState: InitialState = {
  showChat: false,
  showCreate: false,
  showConnections: false,
  showConnectionsModal: false,
  showFeedbackModal: false,
  stepsCollapsed: false,
  sidebarCollapsed: false,
  showMobileSidebar: false,
  showEducationalOnboarding: true,
  sidebarMoreOpen: false,
  surveys: [],
  hints: [],
  theme: 'light',
  contextBubbles: [],
  level: LEVELS[0],
  postsNum: 0
}

export const stateSlice = createSlice({
  name: 'state',
  initialState,
  reducers: {
    setShowConnectionsModal: (state, action: PayloadAction<boolean>) => {
      state.showConnectionsModal = action.payload
    },
    setShowFeedbackModal: (state, action: PayloadAction<boolean>) => {
      state.showFeedbackModal = action.payload
    },
    setShowMobileSidebar: (state, action: PayloadAction<boolean>) => {
      state.showMobileSidebar = action.payload
    },
    setShowDesignerDialog: (state, action: PayloadAction<boolean>) => {
      state.showDesigner = action.payload
    },
    setStepsCollapsed: (state, action: PayloadAction<boolean>) => {
      state.stepsCollapsed = action.payload
    },
    setContentToView: (state, action: PayloadAction<InitialState['contentToView']>) => {
      state.contentToView = action.payload
    },
    clearContentToView: (state) => {
      state.contentToView = undefined
    },
    setWorkspace: (state, action: PayloadAction<string | undefined>) => {
      state.workspace = action.payload
    },
    setTheme: (state, action: PayloadAction<InitialState['theme']>) => {
      state.theme = action.payload
    },
    setReferral: (state, action: PayloadAction<InitialState['referral']>) => {
      state.referral = action.payload
    },
    clearReferral: (state) => {
      state.referral = undefined
    },
    setCoupon: (state, action: PayloadAction<InitialState['coupon']>) => {
      state.coupon = action.payload
    },
    clearCoupon: (state) => {
      state.coupon = undefined
    },
    setAppInfo: (state, action: PayloadAction<InitialState['appInfo']>) => {
      state.appInfo = action.payload
    },
    setContextBubbleShown: (state, action: PayloadAction<string>) => {
      state.contextBubbles.push(action.payload)
    },
    setShowEducationalOnboarding: (state, action: PayloadAction<boolean>) => {
      state.showEducationalOnboarding = action.payload
    },
    setSidebarMoreOpen: (state, action: PayloadAction<boolean>) => {
      state.sidebarMoreOpen = action.payload
    },
    setHintCompleted: (state, action: PayloadAction<string>) => {
      state.hints.push(action.payload)
    },
    setSidebarCollapsed: (state, action: PayloadAction<boolean>) => {
      state.sidebarCollapsed = action.payload
    },
    setShowChat: (state, action: PayloadAction<InitialState['showChat']>) => {
      state.showChat = action.payload
    },
    setShowCreate: (state, action: PayloadAction<InitialState['showCreate']>) => {
      state.showCreate = action.payload
    },
    setShowConnections: (state, action: PayloadAction<boolean>) => {
      state.showConnections = action.payload
    },
    setUserLevel: (state, action: PayloadAction<InitialState['level']>) => {
      state.level = action.payload
    },
    setPostsNum: (state, action: PayloadAction<InitialState['postsNum']>) => {
      state.postsNum = action.payload
    }
  }
})

export const {
  setShowConnectionsModal,
  setShowFeedbackModal,
  setShowMobileSidebar,
  setShowDesignerDialog,
  setStepsCollapsed,
  setContentToView,
  clearContentToView,
  setWorkspace,
  setTheme,
  setReferral,
  clearReferral,
  setCoupon,
  clearCoupon,
  setAppInfo,
  setContextBubbleShown,
  setShowEducationalOnboarding,
  setSidebarMoreOpen,
  setHintCompleted,
  setSidebarCollapsed,
  setShowChat,
  setShowCreate,
  setShowConnections,
  setUserLevel,
  setPostsNum
} = stateSlice.actions
export default stateSlice.reducer
