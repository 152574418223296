import { FC, useEffect, useState } from 'react'
import { HexColorPicker } from 'react-colorful'
import { throttle, uniq } from 'lodash'
import { DocumentIcon, SwatchIcon } from '@heroicons/react/24/outline'
import { useActiveObject, useEditor } from '@layerhub-io/react'
import { getSelectedType } from '../../../../utils'
import { DesignerPanel } from '../..'
import { StyledDesignerColorPanel } from '.'

const DEFAULT_COLORS = [
  '#171717',
  '#404040',
  '#737373',
  '#d4d4d4',
  '#f5f5f5',
  '#ffffff',
  '#f43f5e',
  '#f87171',
  '#f472b6',
  '#e879f9',
  '#8b5cf6',
  '#6d28d9',
  '#0891b2',
  '#06b6d4',
  '#67e8f9',
  '#38bdf8',
  '#3b82f6',
  '#1d4ed8',
  '#22c55e',
  '#a3e635',
  '#d9f99d',
  '#fde047',
  '#fdba74',
  '#fb923c'
]

const DesignerColorPanel: FC = () => {
  const editor = useEditor()
  const activeObject = useActiveObject() as any
  const [color, setColor] = useState('#000')
  const [colors, setColors] = useState<string[]>([])

  const updateObjectFill = throttle((color: string) => {
    const selectionType = getSelectedType(activeObject) as any

    if (selectionType.includes('Frame')) {
      editor.frame.setBackgroundColor(color)
    }

    if (selectionType.includes('StaticVector')) {
      activeObject.updateLayerColor(Object.keys(activeObject.colorMap)[0], color)
    }

    if (activeObject) {
      editor.objects.update({ fill: color })
    }
  }, 100)

  const getCanvasColors = () => {
    const objects = editor.objects.list()
    const colorList: string[] = []

    for (const object of objects) {
      if (typeof object.fill === 'string') {
        colorList.push(object.fill)
      }
    }

    setColors(uniq(colorList))
  }

  const getActiveObjectColor = () => {
    if (!activeObject) {
      return
    }

    if (activeObject?.colorMap) {
      setColor(Object.values(activeObject.colorMap)[0] as any)
      return
    }

    setColor(activeObject?.fill)
  }

  const handleColors = () => {
    getCanvasColors()
    getActiveObjectColor()
  }

  useEffect(() => {
    handleColors()

    if (editor) {
      editor.on('history:changed', handleColors)
    }
    return () => {
      if (editor) {
        editor.off('history:changed', handleColors)
      }
    }
  }, [editor, activeObject])

  return (
    <DesignerPanel>
      <StyledDesignerColorPanel>
        <HexColorPicker color={color} onChange={updateObjectFill} style={{ width: '100%' }} />
        <div className="color-row">
          <div className="color-label">
            <DocumentIcon />
            Document colors
          </div>
          <div className="color-list">
            {colors.map((c) => {
              return (
                <button
                  data-tooltip={c}
                  aria-selected={c === color}
                  style={{ background: c }}
                  onClick={() => updateObjectFill(c)}
                  key={c}
                />
              )
            })}
          </div>
        </div>
        <div className="color-row">
          <div className="color-label">
            <SwatchIcon />
            Default colors
          </div>
          <div className="color-list">
            {DEFAULT_COLORS.map((color) => {
              return (
                <button
                  data-tooltip={color}
                  style={{ background: color }}
                  onClick={() => updateObjectFill(color)}
                  key={color}
                />
              )
            })}
          </div>
        </div>
      </StyledDesignerColorPanel>
    </DesignerPanel>
  )
}

export default DesignerColorPanel
