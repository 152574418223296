import { FC, useState } from 'react'
import { ArrowPathIcon, CheckIcon, TrashIcon } from '@heroicons/react/24/outline'
import { SocialProfileType } from '../../../types'
import {
  formatUsername,
  getPlatformDescription,
  getPlatformIcon,
  getPlatformTitle,
  useConnection
} from '../../../utils'
import { ActionDialog, Button, Spinner, Toggle } from '../..'
import { ConnectionAvatar } from '..'
import { StyledConnectionListItem } from '.'

type Props = {
  profile: SocialProfileType
  isSelected?: boolean
  isCollapsed?: boolean
  isDisabled?: boolean
  canDelete?: boolean
  onSelect?: () => void
  onReconnect?: () => void
}

const ConnectionListItem: FC<Props> = ({
  profile,
  isSelected,
  isCollapsed,
  isDisabled,
  canDelete,
  onSelect,
  onReconnect
}) => {
  const { disconnectProfile } = useConnection()
  const [loading, setLoading] = useState(false)
  const [showDeletionConfirmation, setShowDeletionConfirmation] = useState(false)

  const handleDisconnect = async () => {
    setLoading(true)
    await disconnectProfile(profile)
    setLoading(false)
  }

  const handleClick = () => {
    if (!!onSelect && !isDisabled) {
      return onSelect()
    }
  }

  return (
    <>
      <StyledConnectionListItem
        role="button"
        canSelect={!!onSelect}
        onClick={() => handleClick()}
        isDisabled={isDisabled}
        isCollapsed={isCollapsed}
        isExpired={profile.status === 'expired'}
      >
        <div className="connection-details-container">
          <div className="avatar-container">
            {isSelected && isCollapsed && (
              <div className="check-icon">
                <CheckIcon />
              </div>
            )}
            <ConnectionAvatar profile={profile} />
          </div>
          <div className="connection-details">
            <span className="connection-username">
              {formatUsername(profile.username, profile.type)}
            </span>
            <div className="connection-plaform">
              {getPlatformIcon(profile.type)}
              {getPlatformTitle(profile.type)}{' '}
              {!isCollapsed && getPlatformDescription(profile.type)}
            </div>
          </div>
        </div>
        {!isCollapsed && (
          <div className="connection-actions-container">
            {onReconnect && (
              <Button
                variant="destructive-solid"
                isIcon
                disabled={loading}
                onClick={() => onReconnect()}
              >
                {loading ? <Spinner /> : <ArrowPathIcon />}
              </Button>
            )}
            {onSelect && <Toggle value={isSelected} onChange={() => onSelect()} />}
            {canDelete && (
              <Button isIcon disabled={loading} onClick={() => setShowDeletionConfirmation(true)}>
                {loading ? <Spinner /> : <TrashIcon />}
              </Button>
            )}
          </div>
        )}
      </StyledConnectionListItem>
      <ActionDialog
        title={`Disconnect Profile`}
        body={`Are you sure you want to disconnect ${formatUsername(
          profile.username,
          profile.type
        )}?`}
        onConfirm={() => handleDisconnect()}
        type="error"
        isOpen={showDeletionConfirmation}
        onClose={() => setShowDeletionConfirmation(false)}
      />
    </>
  )
}

export default ConnectionListItem
