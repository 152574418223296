import { FC } from 'react'
import { Helmet } from 'react-helmet'
import { ActionPlanList, ActionPlansViewHeader, PageLayout, PageSizzle } from '../../components'
import { StyledActionPlansView } from '.'

const ActionPlansView: FC = () => {
  return (
    <StyledActionPlansView>
      <Helmet title="Action Plans | Social Curator" />
      <PageSizzle
        maxWidth={835}
        bubble="ACTION PLANS"
        title="Learn the latest marketing strategies"
        body="Find step-by-step Action Plans below on everything from using direct messages for sales to building your email list."
      />
      <ActionPlansViewHeader />
      <PageLayout maxWidth={835}>
        <ActionPlanList />
      </PageLayout>
    </StyledActionPlansView>
  )
}

export default ActionPlansView
