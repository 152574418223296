import styled from 'styled-components'

const StyledCreateEditorCoverPhotoPicker = styled.div`
  .cover-photo {
    display: flex;
    margin-bottom: 10px;

    .upload-btn {
      height: 100px;
      width: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${({ theme }) => theme.color.gray[100]};
      color: ${({ theme }) => theme.color.gray[400]};
      border: 1px dashed ${({ theme }) => theme.color.gray[200]};
      border-radius: 6px;
      margin-left: 10px;
      transition: 0.2s;
      position: relative;

      .remove-upload-btn {
        position: absolute;
        top: -6px;
        right: -6px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.color.gray[900]};
        color: ${({ theme }) => theme.color.background};
        box-shadow: 0 0 0 3px ${({ theme }) => theme.color.background};
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          height: 14px;
          stroke-width: 3px;
        }
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }

      svg {
        height: 28px;
      }

      &:hover {
        background-color: ${({ theme }) => theme.color.gray[200]};
        color: ${({ theme }) => theme.color.gray[600]};
        border-color: ${({ theme }) => theme.color.gray[400]};
      }
    }
  }

  .video-preview {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: fit-content;
    max-width: 200px;
    pointer-events: none;

    video {
      height: 100%;
    }
  }

  .video-timestamp-container {
    margin-bottom: 16px;
  }

  .video-timestamp-offset-label {
    height: 20px;
    position: absolute;
    top: calc(20px + 4px);
    user-select: none;
    left: 0;
    background-color: ${({ theme }) => theme.color.gray[200]};
    color: ${({ theme }) => theme.color.gray[600]};
    border-radius: 6px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 4px;
    font-size: 13px;
    font-weight: 500;
    white-space: nowrap;
  }
`

export default StyledCreateEditorCoverPhotoPicker
