import { ILayer, IScene, IStaticText } from '@layerhub-io/types'
import { loadFonts } from '.'

const getFontsFromObjects = (objects: Partial<ILayer>[]) => {
  if (!objects) {
    return []
  }

  let fonts: any[] = []
  for (const object of objects) {
    if (object.type === 'StaticText' || object.type === 'DynamicText') {
      fonts.push({
        name: (object as Required<IStaticText>).fontFamily,
        url: (object as Required<IStaticText>).fontURL
      })
    }
    if (object.type === 'Group') {
      // @ts-ignore
      let groupFonts = getFontsFromObjects(object.objects)

      fonts = fonts.concat(groupFonts)
    }
  }
  return fonts
}

export const loadTemplateFonts = async (design: IScene) => {
  const fonts = getFontsFromObjects(design?.layers)
  if (fonts.length > 0) {
    await loadFonts(fonts)
  }
}
