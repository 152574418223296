import { FC } from 'react'
import { SearchContextManager } from '@giphy/react-components'
import { DesignerPanel } from '../..'
import { DesignerStickersSearch, StyledDesignerStickersPanel } from '.'

const DesignerStickersPanel: FC = () => {
  return (
    <DesignerPanel>
      <StyledDesignerStickersPanel>
        <SearchContextManager
          apiKey="sXpGFDGZs0Dv1mmNFvYaGUvYwKX0PWIh"
          options={{
            type: 'stickers',
            rating: 'g'
          }}
          theme={{
            condensedMode: true
          }}
        >
          <DesignerStickersSearch />
        </SearchContextManager>
      </StyledDesignerStickersPanel>
    </DesignerPanel>
  )
}

export default DesignerStickersPanel
