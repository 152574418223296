import { FC, FormEvent, createRef, InputHTMLAttributes } from 'react'
import { Button, Dialog, Input, Spinner } from '../..'
import { StyledInputDialog } from '.'

type Props = {
  value: string
  title: string
  cancelButtonText?: string
  confirmButtonText?: string
  closeOnConfirm?: boolean
  isLoading?: boolean
  isOpen: boolean
  inputProps?: InputHTMLAttributes<HTMLInputElement>
  onChange: (value: string) => void
  onClose: () => void
  onConfirm: () => void
}

const InputDialog: FC<Props> = ({
  value,
  title,
  cancelButtonText = 'Cancel',
  confirmButtonText = 'Save',
  closeOnConfirm = true,
  onChange,
  onConfirm,
  inputProps,
  isLoading,
  ...rest
}) => {
  const dialogRef = createRef<HTMLDivElement>()

  const handleConfirm = (e: FormEvent) => {
    e.preventDefault()

    if (closeOnConfirm) {
      const dialog = dialogRef.current as any
      dialog?.close()
    }

    onConfirm()
  }

  return (
    <Dialog ref={dialogRef} {...rest}>
      <StyledInputDialog>
        <div className="dialog-header">
          <span className="dialog-title">{title}</span>
        </div>
        <form onSubmit={(e) => handleConfirm(e)}>
          <Input
            value={value}
            maxLength={120}
            required
            autoFocus
            disabled={isLoading}
            onChange={(e) => onChange(e.target.value)}
            {...inputProps}
            size="md"
          />
          <div className="dialog-actions">
            <Button
              type="button"
              disabled={isLoading}
              onClick={() => (dialogRef.current as any)?.close()}
            >
              {cancelButtonText}
            </Button>
            <Button variant="emphasis" isFullWidth disabled={isLoading || !value.trim().length}>
              {isLoading ? <Spinner /> : confirmButtonText}
            </Button>
          </div>
        </form>
      </StyledInputDialog>
    </Dialog>
  )
}

export default InputDialog
