import { SocialProfileType } from '../../types'

export const socialProfileByStatus = (
  status: ('active' | 'expired' | 'disconnected')[],
  profiles?: SocialProfileType[]
) => {
  if (!profiles?.length) {
    return []
  }

  return profiles.filter((profile) => status.includes(profile.status as any))
}
