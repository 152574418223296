import { FC } from 'react'
import { CaptionList } from '../../components'
import { StyledCaptionsView } from '.'

const CaptionsView: FC = () => {
  return (
    <StyledCaptionsView id="caption-list-scroll">
      <CaptionList />
    </StyledCaptionsView>
  )
}

export default CaptionsView
