import styled from 'styled-components'

type StyledProps = {
  size: number
  isSquare?: boolean
  inRow?: boolean
}

const StyledAvatar = styled.div<StyledProps>`
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  pointer-events: none;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ isSquare }) => (isSquare ? '6px' : '50%')};
  position: relative;
  font-weight: 500;
  flex-shrink: 0;
  font-size: ${({ size }) => size * 0.45}px;
  color: ${({ theme }) => theme.color.brand};
  background-color: ${({ theme }) => theme.color.brandLight};

  ${({ inRow, theme, size }) =>
    inRow &&
    `
    box-shadow: 0 0 0 3px ${theme.color.background};

    &:not(:first-child) {
      margin-left: -${size * 0.18}px;
    }
  `}

  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: ${({ isSquare }) => (isSquare ? '6px' : '50%')};
    object-fit: cover;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: ${({ isSquare }) => (isSquare ? '6px' : '50%')};
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.foreground}20;
  }
`

export default StyledAvatar
