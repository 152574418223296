import styled from 'styled-components'

type StyledProps = {
  hasConnections?: boolean
}

const StyledConnectionOverviewDialog = styled.div<StyledProps>`
  width: 100vw;

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    max-width: 510px;
    ${({ hasConnections }) =>
      hasConnections &&
      `
      max-width: 750px;
      max-height: 715px;
      height: 100vh;
      display: grid;
      grid-template-columns: 240px minmax(0, 1fr);
    `}
  }
`

export default StyledConnectionOverviewDialog
