import { FC, createRef, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import InfiniteScroll from 'react-infinite-scroll-component'
import { times, uniqBy } from 'lodash'
import { useApolloClient } from '@apollo/client'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { FunnelIcon } from '@heroicons/react/24/solid'
import { generateCaptionFeed } from '../../../graphql'
import { CaptionType } from '../../../types'
import { debounceFilter } from '../../../utils'
import { Button, CaptionListItem, EmptyState, Input, OptionsPopover, Skeleton } from '../..'
import { StyledCreateBarContentCaptions } from '.'

const CreateBarContentCaptions: FC = () => {
  const apollo = useApolloClient()
  const optionsBtnRef = createRef<HTMLButtonElement>()
  const [search, setSearch] = useState('')
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(0)
  const [captions, setCaptions] = useState<CaptionType[]>([])
  const [loading, setLoading] = useState(true)
  const [liked, setLiked] = useState(false)
  const [hideUsed, setHideUsed] = useState(false)
  const [showOptions, setShowOptions] = useState(false)

  const loadCaptions = async () => {
    try {
      setLoading(true)

      const { data } = await apollo.query({
        fetchPolicy: 'no-cache',
        query: generateCaptionFeed,
        variables: {
          page,
          search,
          liked,
          categories: [],
          sort: 'for-you',
          hideUsed,
          items: 10
        }
      })

      const result = data?.generateCaptionFeed?.docs || []
      const total = data?.generateCaptionFeed?.totalDocs || 0

      setCaptions(uniqBy([...captions, ...result], '_id'))
      setTotal(total)
    } catch (err) {
      console.log(err)
      toast.error((err as any).message)
    } finally {
      setLoading(false)
    }
  }

  const handleSearch = (query: string) => {
    setSearch(query)
    setPage(0)
    setCaptions([])
  }

  const handleLiked = (input: boolean) => {
    setLiked(input)
    setPage(0)
    setCaptions([])
  }

  const handleHideUsed = (input: boolean) => {
    setHideUsed(input)
    setPage(0)
    setCaptions([])
  }

  useEffect(() => {
    loadCaptions()
  }, [search, hideUsed, liked, page])

  return (
    <>
      <StyledCreateBarContentCaptions>
        <div className="create-bar-filter-header">
          <Input
            value={search}
            variant="fill"
            size="lg"
            icon={<MagnifyingGlassIcon />}
            placeholder="Search Captions"
            isDebounced
            onChange={(e) => debounceFilter(() => handleSearch(e.target.value))}
          />
          <Button
            size="lg"
            className="filter-options-btn"
            isIcon
            ref={optionsBtnRef}
            onClick={() => setShowOptions(!showOptions)}
          >
            {/* {(!!style.length || orientation) && (
              <div className="filter-indicator">{style.length + (!!orientation ? 1 : 0)}</div>
            )} */}
            <FunnelIcon />
          </Button>
        </div>
        <div className="caption-list scrollable" id="content-caption-list">
          {!loading && !captions.length && (
            <EmptyState
              title={`We couldn't find anything${!!search.trim() ? ` for "${search}"` : ''}.`}
              body="How about a different search?"
            />
          )}
          {loading &&
            times(10, (num) => {
              return (
                <Skeleton
                  randHeight={[280, 480]}
                  styles="&:not(:last-child) {
                      margin-bottom: 4px;
                    }"
                  key={num}
                />
              )
            })}
          <InfiniteScroll
            scrollThreshold={0.7}
            dataLength={captions.length}
            next={() => setPage(page + 1)}
            hasMore={captions.length < total}
            scrollableTarget="content-caption-list"
            loader={null}
          >
            {captions.map((caption) => {
              return <CaptionListItem caption={caption} isInline key={caption._id} />
            })}
          </InfiniteScroll>
        </div>
      </StyledCreateBarContentCaptions>
      <OptionsPopover
        options={[
          {
            label: 'Only Favorites',
            isToggle: true,
            isSelected: liked,
            stayOpen: true,
            action: () => handleLiked(!liked)
          },
          {
            label: 'Hide Used',
            isToggle: true,
            isSelected: hideUsed,
            stayOpen: true,
            action: () => handleHideUsed(!hideUsed)
          }
        ]}
        labelsBefore={[]}
        divsAfter={[]}
        classToAvoid="filter-options-btn"
        buttonRef={optionsBtnRef}
        isOpen={showOptions}
        onClose={() => setShowOptions(false)}
      />
    </>
  )
}

export default CreateBarContentCaptions
