import styled from 'styled-components'

const StyledCreateMedia = styled.div`
  height: 300px;
  width: 100%;
  background-color: ${({ theme }) => theme.color.gray[100]};
  margin-bottom: 14px;
  display: flex;
  overflow: hidden;
  position: relative;

  .swiper {
    height: 100%;
    width: 100%;
  }

  .index-indicator {
    position: absolute;
    padding: 0 6px;
    height: 26px;
    display: flex;
    right: 14px;
    top: 14px;
    align-items: center;
    justify-content: center;
    z-index: 9;
    font-size: 14px;
    font-weight: 600;
    border-radius: 8px;
    color: white;
    background-color: rgb(0, 0, 0, 0.4);
    box-shadow: 0 0 0 1px rgb(255, 255, 255, 0.1);
  }

  .action-btn {
    position: absolute;
    width: 52px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
    border-radius: 50%;
    color: ${({ theme }) => theme.color.gray[800]};
    background-color: ${({ theme }) => theme.color.background};
    box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
      0px 2px 3px rgba(0, 0, 0, 0.05);

    svg {
      height: 28px;
    }

    &.add-btn {
      left: 14px;
      bottom: 14px;
      transition: 0.2s;

      &[data-active='true'] {
        transform: scale(0.9);
        opacity: 0.8;
      }
    }

    &.delete-btn {
      right: 14px;
      bottom: 14px;
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    z-index: 1;
    background-color: ${({ theme }) => theme.color.foreground}10;
  }
`

export default StyledCreateMedia
