import styled from 'styled-components'

const StyledDiagnosticsView = styled.div`
  color: ${({ theme }) => theme.color.gray[800]};
  padding: 20px 0;

  h1 {
    font-size: 22px;
  }

  table {
    margin-top: 20px;
    border: 1px solid ${({ theme }) => theme.color.gray[300]};
    font-size: 13px;
    padding: 0;
    border-spacing: 0;
    border-radius: 2px;

    td {
      padding: 10px;
      width: 100%;
      color: ${({ theme }) => theme.color.gray[800]};

      &:first-child {
        code {
          display: block;
          width: fit-content;
          padding: 4px 6px;
          border-radius: 4px;
          border: 1px solid ${({ theme }) => theme.color.gray[200]};
          background-color: ${({ theme }) => theme.color.gray[100]};
          color: ${({ theme }) => theme.color.gray[800]};
        }
      }

      &:last-child {
        text-align: right;
        white-space: pre-line;
      }
    }

    tr:not(:last-child) {
      td {
        border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
      }
    }
  }

  button {
    margin-top: 20px;
  }
`

export default StyledDiagnosticsView
