import styled from 'styled-components'

const StyledCreateDateInput = styled.div`
  .date-input-btn {
    margin-top: 8px;
    display: flex;
    align-items: center;
    height: 50px;
    border-radius: 8px;
    box-shadow: inset 0 0 0 1.25px ${({ theme }) => theme.color.gray[300]};
    width: 100%;
    box-sizing: border-box;
    padding: 0 11px;
    background-color: transparent;
    font-weight: 500;
    color: ${({ theme }) => theme.color.gray[800]};

    .placeholder {
      color: ${({ theme }) => theme.color.gray[400]};
    }

    svg {
      height: 30px;
      margin-right: 8px;
    }
  }
`

export default StyledCreateDateInput
