import { FC, useContext, useEffect } from 'react'
import { ConnectionConnectDialog, ConnectionOverviewDialog, connectionStore } from '../..'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const ConnectionDialog: FC<Props> = ({ isOpen, ...rest }) => {
  const {
    availableProfiles,
    platformToConnect,
    setPlatformToConnect,
    setAvailableProfiles
  } = useContext(connectionStore)

  useEffect(() => {
    setPlatformToConnect(undefined)
    setAvailableProfiles([])
  }, [isOpen])

  if (availableProfiles.length || platformToConnect) {
    return <ConnectionConnectDialog isOpen={isOpen} {...rest} />
  }

  return <ConnectionOverviewDialog isOpen={isOpen} {...rest} />
}

const ConnectionDialogWrapper: FC<Props> = ({ ...rest }) => {
  return (
    // <ConnectionContext>
    <ConnectionDialog {...rest} />
    // </ConnectionContext>
  )
}

export default ConnectionDialogWrapper
