import styled from 'styled-components'

const StyledDateTimeDrawer = styled.div`
  padding-bottom: env(safe-area-inset-bottom);

  .date-time-drawer-calendar {
    .react-calendar {
      padding: 8px 12px;

      .react-calendar__navigation {
        position: relative;
        height: 38px;
        display: flex;
        justify-content: end;
        align-items: center;
        margin: 6px 10px 14px 10px;

        button {
          border: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          padding: 0;
          font-size: 18px;
          font-weight: 600;
          background-color: ${({ theme }) => theme.color.background};
          color: ${({ theme }) => theme.color.gray[400]};
          transition: 0.2s;

          svg {
            height: 26px;
          }

          &:hover {
            background-color: ${({ theme }) => theme.color.gray[100]};
            color: ${({ theme }) => theme.color.gray[500]};
          }
        }

        .react-calendar__navigation__arrow {
          width: 30px;
          height: 30px;

          &:not(:last-child) {
            margin-right: 6px;
          }
        }

        .react-calendar__navigation__label {
          position: absolute;
          height: 38px;
          left: 0;
          padding: 0 8px;
          pointer-events: none;
          color: ${({ theme }) => theme.color.gray[700]};
          font-size: 18px;
          font-weight: 600;

          &:hover {
            background-color: ${({ theme }) => theme.color.gray[100]};
            color: ${({ theme }) => theme.color.gray[600]};
          }
        }
      }

      .react-calendar__viewContainer {
        .react-calendar__month-view {
          .react-calendar__month-view__weekdays {
            .react-calendar__month-view__weekdays__weekday {
              text-align: center;

              abbr {
                text-decoration: none;
                color: ${({ theme }) => theme.color.gray[400]};
                font-weight: 500;
                font-size: 14px;
              }
            }
          }

          .react-calendar__month-view__days {
            display: grid !important;
            grid-gap: 2px;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
            justify-items: center;
            margin-top: 12px;

            .react-calendar__tile {
              height: 40px;
              width: 40px;
              font-weight: 500;
              font-size: 17px;
              border-radius: 5px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 0;
              background-color: transparent;
              transition: background-color 0.2s;
              color: ${({ theme }) => theme.color.gray[600]};

              &:hover {
                background-color: ${({ theme }) => theme.color.gray[100]};
              }

              &.react-calendar__month-view__days__day--neighboringMonth {
                color: ${({ theme }) => theme.color.gray[400]};

                &:hover {
                  background-color: ${({ theme }) => theme.color.background};
                }
              }

              &.react-calendar__tile--now {
                box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.gray[200]};
              }

              &.react-calendar__tile--active {
                color: ${({ theme }) => theme.color.brand};
                background-color: ${({ theme }) => theme.color.brandLight};
                box-shadow: none;

                &:hover {
                  background-color: ${({ theme }) => theme.color.brandLight};
                }
              }
            }
          }
        }
      }
    }
  }

  .date-time-drawer-time-container {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    position: relative;

    .time-placeholder {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      color: ${({ theme }) => theme.color.gray[400]};
      font-weight: 500;
    }

    .time-label {
      font-weight: 500;
      margin-right: 24px;
      color: ${({ theme }) => theme.color.gray[600]};
    }

    .date-time-drawer-time {
      height: 42px;
      display: flex;
      border-radius: 6px;
      overflow: hidden;
      width: 100%;
      background-color: ${({ theme }) => theme.color.gray[100]};

      input {
        font-size: 17px;
        font-weight: 500;
        color: ${({ theme }) => theme.color.gray[700]};
        background-color: transparent;
        padding: 0 12px;
        width: 100%;
        box-sizing: border-bx;
      }
    }
  }

  .date-time-drawer-actions {
    padding: 28px 24px 0 24px;
    display: flex;
    align-items: center;

    .done-btn {
      width: 100%;
      margin-left: 18px;
    }
  }
`

export default StyledDateTimeDrawer
