import { FC, useContext, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import Sheet from 'react-modal-sheet'
import { useReward } from 'react-rewards'
import { compact, uniq, xor } from 'lodash'
import { DateTime } from 'luxon'
import { useApolloClient } from '@apollo/client'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { sendDraftNow } from '../../../../graphql'
import { updateCreatePost, useAppDispatch, useAppSelector } from '../../../../redux'
import { hydrateSocialProfiles, userStore } from '../../../../utils'
import { SocialProfileAvatar, SocialProfileSheet, Spinner } from '../..'
import { StyledCreatePublishSheet } from '.'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const CreatePublishSheet: FC<Props> = ({ isOpen, onClose }) => {
  const apollo = useApolloClient()
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const [profiles, setProfiles] = useState<string[]>([])
  const [showSocialProfileSelect, setShowSocialProfileSelect] = useState(false)
  const { user } = useContext(userStore)
  const { post } = useAppSelector((state) => state.create)
  const { reward } = useReward('confetti', 'confetti', {
    angle: -90,
    startVelocity: 18,
    spread: 220,
    elementCount: 150
  })

  const handlePostNow = async () => {
    try {
      setLoading(true)

      const { data } = await apollo.mutate({
        mutation: sendDraftNow,
        variables: {
          draft: post?._id,
          profiles
        }
      })

      const failures = data?.sendDraftNow?.failures || []

      if (failures.length) {
        return failures.map((failure: string) => toast.error(failure, { duration: 6000 }))
      }

      dispatch(
        updateCreatePost({
          isUsed: true,
          socialProfiles: uniq([...profiles, ...compact(post?.socialProfiles)]),
          postDate: DateTime.now().minus({ minute: 1 }).toISO()
        })
      )

      toast.success('Published successfully!')
      reward()
      onClose()
    } catch (err) {
      toast.error((err as any).message)
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(false)
    setProfiles([])
  }, [isOpen])

  return (
    <>
      <Sheet detent="content-height" isOpen={isOpen} onClose={onClose}>
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <StyledCreatePublishSheet>
              <button
                className="social-profile-select-btn"
                onClick={() => setShowSocialProfileSelect(true)}
              >
                {!profiles.length && (
                  <span className="placeholder">Select one or more social profiles</span>
                )}
                {!!profiles.length && (
                  <div className="social-profile-selection">
                    {hydrateSocialProfiles(profiles, user).map((profile) => {
                      return (
                        <SocialProfileAvatar profile={profile} size={36} inRow key={profile._id} />
                      )
                    })}
                  </div>
                )}
                <ChevronDownIcon />
              </button>
              <div className="action-row">
                <button onClick={() => onClose()}>Cancel</button>
                <button
                  className="submit-btn"
                  disabled={!profiles.length || loading}
                  onClick={() => handlePostNow()}
                >
                  {loading ? <Spinner /> : 'Publish Post'}
                </button>
              </div>
            </StyledCreatePublishSheet>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => onClose()} />
      </Sheet>
      <SocialProfileSheet
        selectedProfiles={profiles}
        onSelect={(profile) => setProfiles(xor(profiles, [profile]))}
        isOpen={showSocialProfileSelect}
        onClose={() => setShowSocialProfileSelect(false)}
      />
    </>
  )
}

export default CreatePublishSheet
