import { FC, useState } from 'react'
import { Helmet } from 'react-helmet'
import { PageLayout, PhotoList, PhotosViewHeader, PhotoViewer } from '../../components'
import { PhotoType } from '../../types'
import { StyledPhotosView } from '.'

const PhotosView: FC = () => {
  const [expandedPhoto, setExpandedPhoto] = useState<PhotoType>()

  return (
    <PageLayout>
      <Helmet title="Gallery | Social Curator" />
      <PhotoViewer
        isOpen={!!expandedPhoto}
        photo={expandedPhoto?._id}
        onChange={photo => setExpandedPhoto(photo)}
      />
      <StyledPhotosView>
        <PhotosViewHeader />
        <PhotoList onExpand={photo => setExpandedPhoto(photo)} />
      </StyledPhotosView>
    </PageLayout>
  )
}

export default PhotosView
