import { useContext, useEffect } from 'react'
import { setUserLevel, useAppDispatch, useAppSelector } from '../../redux'
import { LEVELS, userStore } from '..'

export const useUserLevel = () => {
  const dispatch = useAppDispatch()
  const { user } = useContext(userStore)
  const { level, postsNum } = useAppSelector((state) => state.state)

  const loadLevel = () => {
    if (postsNum < 1) {
      return dispatch(setUserLevel(LEVELS[0]))
    }
    if (postsNum < 5) {
      return dispatch(setUserLevel(LEVELS[1]))
    }
    if (postsNum < 10) {
      return dispatch(setUserLevel(LEVELS[2]))
    }
    if (postsNum < 20) {
      return dispatch(setUserLevel(LEVELS[3]))
    }
    dispatch(setUserLevel(LEVELS[4]))
  }

  useEffect(() => {
    loadLevel()
  }, [postsNum, user])

  return {
    level,
    postsNum
  }
}
