import styled from 'styled-components'

const StyledEditorSchedule = styled.div`
  .date-picker-btn {
    display: flex;
    align-items: center;
    height: 50px;
    border-radius: 14px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 14px;
    background-color: transparent;
    user-select: none;
    transition: 0.2s;
    color: ${({ theme }) => theme.color.gray[700]};
    border: 1px solid ${({ theme }) => theme.color.gray[200]};

    svg {
      height: 24px;
      stroke-width: 1.75px;
      color: ${({ theme }) => theme.color.gray[400]};
      margin-right: 14px;
    }

    &:hover {
      border-color: ${({ theme }) => theme.color.gray[300]};
    }
  }

  .date-suggestions {
    margin-top: 20px;
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr 1fr;

    .date-suggestion {
      border-radius: 10px;
      padding: 16px;
      text-align: left;
      transition: 0.2s;
      background-color: ${({ theme }) => theme.color.gray[100]};
      color: ${({ theme }) => theme.color.gray[500]};

      b {
        display: block;
        font-weight: 550;
        font-size: 15px;
        color: ${({ theme }) => theme.color.gray[600]};
      }

      span {
        font-size: 14px;
        margin-top: 8px;
        display: block;
      }

      &.pick-btn {
        text-align: center;
      }

      &:hover {
        background-color: ${({ theme }) => theme.color.gray[200]}c4;
      }
    }
  }
`

export default StyledEditorSchedule
