import { FC, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import InfiniteScroll from 'react-infinite-scroll-component'
import { times } from 'lodash'
import { useApolloClient } from '@apollo/client'
import { loadDesignsFromCloud } from '../../../graphql'
import { CloudContentType } from '../../../types'
import { useCreate } from '../../../utils'
import { CloudUploadItem, CloudUploadItem_Skeleton } from '../..'
import { StyledCreateBarDesignsList } from '.'

const CreateBarDesignsList: FC = () => {
  const apollo = useApolloClient()
  const { addMediaToPost } = useCreate()
  const [designs, setDesigns] = useState<CloudContentType[]>([])
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(true)

  const loadDesigns = async () => {
    try {
      setLoading(true)

      const { data } = await apollo.query({
        fetchPolicy: 'no-cache',
        query: loadDesignsFromCloud,
        variables: {
          page,
          sort: 'createdAt',
          limit: 20
        }
      })

      const docs = data?.loadDesignsFromCloud?.docs || []
      setDesigns([...designs, ...docs])
      setTotal(data?.loadDesignsFromCloud?.totalDocs || 0)
    } catch (err) {
      console.log(err)
      toast.error((err as any).message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadDesigns()
  }, [])

  return (
    <StyledCreateBarDesignsList className="scrollable" id="designs-file-list">
      <InfiniteScroll
        scrollThreshold={0.7}
        dataLength={designs.length}
        next={() => setPage(page + 1)}
        hasMore={designs.length < total}
        scrollableTarget="designs-file-list"
        loader={null}
      >
        <div className="designs-file-list">
          {loading &&
            page === 0 &&
            times(10).map((index) => {
              return <CloudUploadItem_Skeleton key={index} />
            })}
          {designs.map((file) => {
            return (
              <>
                <CloudUploadItem
                  content={file}
                  key={file._id}
                  onClick={() => addMediaToPost(file, 'design', true)}
                />
              </>
            )
          })}
        </div>
      </InfiniteScroll>
    </StyledCreateBarDesignsList>
  )
}

export default CreateBarDesignsList
