import styled from 'styled-components'

const StyledMobilePlanDisclaimer = styled.div`
  margin-top: 20px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), 0 2px 4px rgb(0, 0, 0, 0.1);
  color: #fff;
  background: linear-gradient(
    45deg,
    ${({ theme }) => theme.color.brand},
    ${({ theme }) => theme.color.purple[600]}
  );
  font-size: 15px;
  line-height: 22px;

  .disclaimer-header {
    display: flex;
    align-items: center;
    font-weight: 600;
    margin-bottom: 10px;

    .disclaimer-icon {
      height: 32px;
      width: 32px;
      margin-right: 10px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1);
      background: linear-gradient(rgb(255, 255, 255, 0.1), rgb(255, 255, 255, 0.2));
      color: #fff;

      svg {
        height: 24px;
      }
    }
  }

  a {
    text-decoration: none;
  }

  button {
    font-size: 15px;
    font-weight: 600;
    margin-top: 20px;
    background-color: #fff;
    justify-content: space-between;
    padding: 0 10px 0 14px;
    color: ${({ theme }) => theme.color.brand};
    box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), 0 2px 4px rgb(0, 0, 0, 0.1);
  }
`

export default StyledMobilePlanDisclaimer
