import { FC, useState } from 'react'
import { CloudContentType } from '../../../../types'
import { formatFileSize, getRelativeString } from '../../../../utils'
import { CloudMediaPreview, ContentSheet } from '../..'
import { StyledUploadListItem } from '.'

type Props = {
  content: CloudContentType
}

const UploadListItem: FC<Props> = ({ content }) => {
  const [showOptions, setShowOptions] = useState(false)

  const getName = () => {
    if (!content.name.includes('.')) {
      return content.name
    }

    return content.name.substring(0, content.name.lastIndexOf('.'))
  }

  const getExtension = () => {
    if (content.type === 'design') {
      return '.design'
    }

    if (!content.name.includes('.')) {
      return ''
    }

    return content.name.substring(content.name.lastIndexOf('.'), content.name.length)
  }

  const handleClick = () => {
    setShowOptions(true)
  }

  return (
    <>
      <StyledUploadListItem showOptions={showOptions} onClick={() => handleClick()}>
        <div className="item-details-container">
          <div className="item-preview">
            {content.file?.type !== 'video' && <img src={content.file?.url} alt="" />}
            {content.file?.type === 'video' && <img src={content.file.preview} alt="" />}
            {/* <CloudMediaPreview
              url={content.file?.url || ''}
              preview={content.file?.preview}
              mediaWidth={200}
              type={content.file?.type || 'image'}
            /> */}
          </div>
          <div className="item-details">
            <span className="item-name">
              {getName()}
              <span className="item-ext">{getExtension()}</span>
            </span>
            <div className="item-stats">
              {content.type !== 'design' && (
                <>
                  <span>{formatFileSize(content.file?.size || 0)}</span>
                  <div />
                </>
              )}
              <span>{getRelativeString(content.createdAt)}</span>
            </div>
          </div>
        </div>
      </StyledUploadListItem>
      <ContentSheet
        content={content}
        type="cloud"
        isOpen={showOptions}
        onClose={() => setShowOptions(false)}
      />
    </>
  )
}

export default UploadListItem
