import styled from 'styled-components'

type StyledProps = {
  isInteractive?: boolean
}

const StyledCloudMediaPlayer = styled.div<StyledProps>`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  ${({ isInteractive }) => !isInteractive && 'pointer-events: none;'}

  .btn {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    div {
      max-height: 60px;
      max-width: 60px;
      width: 60%;
      height: 60%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.2s;
      border-radius: 50%;

      svg {
        height: 28px;
      }
    }

    &.play-btn {
      div {
        background-color: ${({ theme }) => theme.color.brand};
        color: white;
        box-shadow: 0 2px 6px rgb(0, 0, 0, 0.2);
      }

      &:hover {
        div {
          transform: scale(1.05);
          background-color: ${({ theme }) => theme.color.brandDark};
        }
      }
    }

    &.pause-btn {
      opacity: 0;

      div {
        background-color: rgb(0, 0, 0, 0.8);
        color: rgb(255, 255, 255, 0.8);
        transform: scale(0.95);
        box-shadow: 0 2px 6px rgb(255, 255, 255, 0.2);
      }

      &:hover {
        opacity: 1;

        div {
          transform: scale(1);
        }
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  video {
    height: 100%;
    max-width: 100%;
  }
`

export default StyledCloudMediaPlayer
