import { FC, createRef, useState } from 'react'
import { PauseIcon, PlayIcon } from '@heroicons/react/24/solid'
import { StyledCloudMediaPlayer } from '.'

type Props = {
  url: string
  preview?: string
  isInteractive?: boolean
}

const CloudMediaPlayer: FC<Props> = ({ url, preview, isInteractive }) => {
  const videoRef = createRef<HTMLVideoElement>()
  const [playing, setPlaying] = useState(false)

  const togglePlay = () => {
    if (!isInteractive) {
      return
    }

    const video = videoRef.current
    setPlaying(!playing)

    if (!video) {
      return
    }

    if (playing) {
      return video.pause()
    }

    video.play()
  }

  return (
    <StyledCloudMediaPlayer isInteractive={isInteractive} onClick={() => togglePlay()}>
      {!playing && isInteractive && (
        <div className="btn play-btn">
          <div>
            <PlayIcon />
          </div>
        </div>
      )}
      {!!playing && isInteractive && (
        <div className="btn pause-btn">
          <div>
            <PauseIcon />
          </div>
        </div>
      )}
      {playing && (
        <video
          src={url}
          muted
          playsInline
          autoPlay
          poster={preview || ''}
          controls={false}
          loop
          ref={videoRef}
        />
      )}
      {!playing && <img src={preview} />}
    </StyledCloudMediaPlayer>
  )
}

export default CloudMediaPlayer
