export const CAPTION_CATEGORIES = [
  { label: 'Behind the Scenes', value: 'behind-the-scenes' },
  { label: 'My Why', value: 'my-why' },
  { label: 'Personal Insight', value: 'personal-insight' },
  { label: 'Encouragement', value: 'encouragement' },
  { label: 'About Me', value: 'about-me' },
  { label: 'Benefits', value: 'benefits' },
  { label: 'Showcase Product/Service', value: 'showcase-product-service' },
  { label: 'Holiday', value: 'holiday' }
]

export type CaptionCategoryType = typeof CAPTION_CATEGORIES[number]

export type CaptionType = {
  _id: string
  category: string | null
  caption: string
  captionPlainText: string | null
  title: string | null
  issue: string
  issueTitle: string | null
  userCaption: {
    _id: string
    liked: boolean | null
    used: boolean | null
    user: string
  } | null
}
