import { FC, FormEvent, useContext, useState } from 'react'
import { Helmet } from 'react-helmet'
import toast from 'react-hot-toast'
import { Link, useHistory } from 'react-router-dom'
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid'
import { ReactComponent as SCLogoIcon } from '../../assets/sc-logo-icon.svg'
import { AuthLayout, Spinner } from '../../components'
import { ROUTES, handleEnterToNextInput, useLogin, userStore } from '../../utils'
import { StyledLoginView } from '.'

const LoginView: FC = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const { user } = useContext(userStore)
  const { form, params, updateFormValue, login } = useLogin()

  const handleSubmit = async (e?: FormEvent) => {
    try {
      e?.preventDefault()
      setLoading(true)

      await login()
    } catch (err) {
      console.error(err)
      toast.error((err as any)?.message)
      setLoading(false)
    }
  }

  if (!!user && params?.ref === 'academy') {
    return (
      <div
        style={{
          height: '100dvh',
          width: '100dvw',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Spinner />{' '}
        <span style={{ marginLeft: '10px', opacity: '0.6', fontWeight: 500 }}>Loading</span>
      </div>
    )
  }

  return (
    <AuthLayout>
      <Helmet title="Login | Social Curator" />
      <StyledLoginView>
        <div className="auth-screen" data-screen="login" data-active={true}>
          <div className="auth-header">
            {params?.ref !== 'academy' && (
              <>
                <h1>Continue to Social Curator</h1>
                <h2>More Followers, More Sales, Less Time</h2>
              </>
            )}
            {params?.ref === 'academy' && (
              <>
                <h1>Social Curator Academy</h1>
                <h2>Welcome back, let's get you logged in</h2>
              </>
            )}
          </div>
          <form autoComplete="on" onSubmit={(e) => handleSubmit(e)}>
            <div className="auth-inputs">
              <label htmlFor="email" className="input-container">
                <div className="input-label">Email address</div>
                <input
                  id="email"
                  value={form.email}
                  placeholder="hello@socialcurator.com"
                  required
                  type="email"
                  name="email"
                  enterKeyHint="next"
                  onKeyDown={(e) => handleEnterToNextInput(e)}
                  onChange={(e) => updateFormValue('email', e.target.value)}
                />
              </label>
              <label htmlFor="password" className="input-container">
                <div className="input-label">
                  Password
                  <button
                    className="show-password-btn"
                    type="button"
                    onClick={() => history.push(ROUTES.auth.forgotPassword)}
                  >
                    Forgot password?
                  </button>
                </div>
                <input
                  id="password"
                  value={form.password}
                  required
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  enterKeyHint="go"
                  onChange={(e) => updateFormValue('password', e.target.value)}
                  onKeyDown={(e) => e.key === 'Enter' && handleSubmit()}
                />
                <button
                  className="show-password-btn-small"
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <EyeSlashIcon /> : <EyeIcon />}
                </button>
              </label>
            </div>
            {params?.old && (
              <div className="auth-disclaimer">
                <span className="icon">🔐</span>
                Log in using your existing Social Curator credentials.
              </div>
            )}
            <button type="submit" className="submit-btn" disabled={loading}>
              {!loading && 'Log In'}
              {loading && <SCLogoIcon className="logo-spinner" />}
            </button>
          </form>
          {params?.ref !== 'academy' && (
            <div className="form-subtext">
              New to Social Curator?{' '}
              <Link to={{ pathname: ROUTES.auth.register, state: { ...params } }}>Sign up</Link>
            </div>
          )}
        </div>
      </StyledLoginView>
    </AuthLayout>
  )
}

export default LoginView
