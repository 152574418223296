import { groupBy } from 'lodash'
import { IStaticText } from '@layerhub-io/types'
import { fontList } from '../..'

export const getTextProperties = (object: Required<IStaticText>) => {
  const color = object.fill
  const fontFamily = object.fontFamily
  const fontSize = object.fontSize
  const align = object.textAlign
  const charSpacing = object.charSpacing / 10
  const lineHeight = object.lineHeight * 10

  const selectedFont = fontList.find((font) => font.post_script_name === fontFamily)
  const groupedFonts = groupBy(fontList, 'family')
  const selectedFamily = groupedFonts[selectedFont!.family]
  const hasBold = !!selectedFamily.find((font) => font.post_script_name.includes('-Bold'))
  const hasItalic = !!selectedFamily.find((font) => font.post_script_name.includes('-Italic'))

  const isBold = fontFamily.includes('Bold')
  const isItalic = fontFamily.includes('Italic')
  const isUnderline = object.underline

  return {
    color,
    fontSize,
    align,
    fontFamily: selectedFamily[0].family,
    fontOptions: selectedFamily,
    charSpacing,
    lineHeight,
    hasBold,
    hasItalic,
    isBold,
    isItalic,
    isUnderline
  }
}
