import styled from 'styled-components'

const StyledDesignerChatPanel = styled.div`
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-rows: minmax(0, 1fr) auto;
  background-color: ${({ theme }) => theme.color.background};
  box-shadow: 0px 0px 0px 1px ${({ theme }) => theme.color.foreground}15,
    0px 2px 3px ${({ theme }) => theme.color.foreground}10;
`

export default StyledDesignerChatPanel
