import { FC } from 'react'
import { setContentTab, useAppDispatch, useAppSelector } from '../../../redux'
import { PhotosFilters, Tabs } from '../../components'
import { CaptionsView, PhotosView, UploadsView } from '..'
import { StyledContentView } from '.'

const ContentView: FC = () => {
  const dispatch = useAppDispatch()
  const { tab } = useAppSelector((state) => state.content)

  return (
    <StyledContentView data-has-header={tab === 'photos'}>
      {tab === 'photos' && <PhotosFilters />}
      <Tabs
        tabs={[
          {
            label: 'Uploads',
            isActive: tab === 'uploads',
            onClick: () => dispatch(setContentTab('uploads'))
          },
          {
            label: 'Photos',
            isActive: tab === 'photos',
            onClick: () => dispatch(setContentTab('photos'))
          },
          // {
          //   label: 'Stories',
          //   isActive: tab === 'stories',
          //   onClick: () => dispatch(setContentTab('stories'))
          // },
          {
            label: 'Captions',
            isActive: tab === 'captions',
            onClick: () => dispatch(setContentTab('captions'))
          }
        ]}
      />
      {tab === 'uploads' && <UploadsView />}
      {tab === 'photos' && <PhotosView />}
      {tab === 'captions' && <CaptionsView />}
    </StyledContentView>
  )
}

export default ContentView
