import styled from 'styled-components'

const StyledPostTemplateRow = styled.div`
  .row-heading {
    span {
      display: block;
    }

    .row-title {
      font-weight: 600;
      color: ${({ theme }) => theme.color.gray[800]};
    }

    .row-description {
      margin-top: 6px;
      font-size: 15px;
      color: ${({ theme }) => theme.color.gray[600]};
    }
  }

  .row-list {
    margin-top: 20px;
  }
`

export default StyledPostTemplateRow
