import { createRef, FC, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { disablePageScroll, enablePageScroll } from 'scroll-lock'
import { Provider } from '@layerhub-io/react'
import { setShowDesigner, useAppDispatch, useAppSelector } from '../../../redux'
import { DesignerCanvas, DesignerHeader, DesignerSidebar } from '..'
import { StyledDesigner } from '.'

const Designer: FC = () => {
  const dialogRef = createRef<HTMLDivElement>()
  const dispatch = useAppDispatch()
  const { isOpen } = useAppSelector((state) => state.designer)

  const Element = (
    <Provider>
      <StyledDesigner ref={dialogRef}>
        <div className="designer">
          <DesignerHeader />
          <div className="designer-content">
            <DesignerSidebar />
            <DesignerCanvas />
          </div>
        </div>
        <div className="backdrop" onClick={() => dispatch(setShowDesigner(false))} />
      </StyledDesigner>
    </Provider>
  )

  useEffect(() => {
    const dialog = dialogRef.current

    if (!!dialog && isOpen) {
      dialog.focus()
      return disablePageScroll(dialog)
    }

    enablePageScroll()
  }, [dialogRef.current, isOpen])

  if (!isOpen) {
    return null
  }

  return createPortal(Element, document.getElementById('dialog-root') as HTMLElement)
}

export default Designer
