import styled from 'styled-components'

type StyledProps = {
  isDisabled?: boolean
}

const StyledSocialProfileListItem = styled.div<StyledProps>`
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;

  .social-profile-details-container {
    display: flex;
    align-items: center;

    .social-profile-details {
      margin-left: 10px;

      .social-profile-username {
        font-weight: 600;
        color: ${({ theme }) => theme.color.gray[800]};
      }

      .social-profile-plaform {
        display: flex;
        align-items: center;
        font-weight: 500;
        margin-top: 2px;
        font-size: 14px;
        color: ${({ theme }) => theme.color.gray[500]};

        svg {
          height: 14px;
          margin-right: 4px;
        }
      }
    }
  }

  .social-profile-actions {
    display: flex;
    align-items: center;

    .social-profile-toggle {
      height: 34px;
      width: 60px;
      border-radius: 50rem;
      background-color: transparent;
      position: relative;
      box-shadow: inset 0 0 0 1.5px ${({ theme }) => theme.color.gray[300]};

      div {
        transition: left 0.2s;
        position: absolute;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
        height: 24px;
        width: 24px;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.color.gray[300]};
      }

      &[data-toggled='true'] {
        box-shadow: inset 0 0 0 20px ${({ theme }) => theme.color.brand};

        div {
          left: calc(60px - 24px - 5px);
          background-color: white;
        }
      }
    }

    .disconnect-btn {
      height: 34px;
      width: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      background-color: ${({ theme }) => theme.color.red[100]};
      color: ${({ theme }) => theme.color.red[800]};

      svg {
        height: 24px;
      }
    }
  }

  &:hover {
    cursor: pointer;
  }

  ${({ isDisabled, theme }) =>
    isDisabled &&
    `
    opacity: 0.6;
    filter: grayscale(1);

    .connection-details-container, .connection-actions-container {
      pointer-events: none;
    }

    &:hover {
      cursor: not-allowed;
    }
  `}
`

export default StyledSocialProfileListItem
