import { FC, useContext, useState } from 'react'
import { ArrowLeftIcon, ArrowsRightLeftIcon } from '@heroicons/react/24/outline'
import { getPlatformDescription, getPlatformLogo, getPlatformTitle } from '../../../utils'
import { AuthenticateWithFacebookDialog, Button, Spinner } from '../..'
import { connectionStore } from '..'
import { StyledConnectionPlatformOverview } from '.'

const ConnectionPlatformOverview: FC = () => {
  const { platformToConnect, loading, setPlatformToConnect, connectPlatform } =
    useContext(connectionStore)
  const [showFacebookDisclaimer, setShowFacebookDisclaimer] = useState(false)

  const handleConnectPlatform = () => {
    if (!platformToConnect) {
      return
    }

    if (platformToConnect === 'instagram') {
      return setShowFacebookDisclaimer(true)
    }

    connectPlatform(platformToConnect)
  }

  if (!platformToConnect) {
    return null
  }

  return (
    <>
      <StyledConnectionPlatformOverview>
        <Button
          size="sm"
          className="back-btn"
          iconPos="left"
          onClick={() => setPlatformToConnect(undefined)}
        >
          <ArrowLeftIcon /> Go back
        </Button>
        <div className="connection-indicator">
          <div className="platform-image">
            <img
              src={`/images/logos/${getPlatformLogo(platformToConnect)}`}
              alt={`${getPlatformTitle(platformToConnect)} Logo`}
            />
          </div>
          <div className="connection-icon">
            <ArrowsRightLeftIcon />
          </div>
          <div className="platform-image rounded">
            <img src={'/apple-touch-icon.png'} alt="Social Curator Logo" />
          </div>
        </div>
        <span className="connection-title">
          Connect {getPlatformTitle(platformToConnect)} to Social Curator
        </span>
        <span className="connection-description">
          Connecting your {getPlatformTitle(platformToConnect)}{' '}
          {getPlatformDescription(platformToConnect)} lets you schedule & publish posts to{' '}
          {getPlatformTitle(platformToConnect)}, directly from Social Curator.
        </span>
        <div className="connection-actions">
          <Button
            size="lg"
            variant="emphasis"
            disabled={loading}
            iconPos={loading ? undefined : 'right'}
            onClick={() => handleConnectPlatform()}
          >
            {loading && <Spinner />}
            {!loading && (
              <>
                Connect {getPlatformTitle(platformToConnect)}{' '}
                {getPlatformDescription(platformToConnect)}
                {/* <ArrowUpRightIcon /> */}
              </>
            )}
          </Button>
          {/* <span className="disclaimer">
          Need help? <a href="">Watch how to connect</a>.
        </span> */}
        </div>
      </StyledConnectionPlatformOverview>
      <AuthenticateWithFacebookDialog
        isOpen={showFacebookDisclaimer}
        onClose={() => setShowFacebookDisclaimer(false)}
      />
    </>
  )
}

export default ConnectionPlatformOverview
