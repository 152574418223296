import { FC, useEffect } from 'react'
import { CardElement } from '@stripe/react-stripe-js'
import { StripeCardElementChangeEvent } from '@stripe/stripe-js'
import { theme } from '../../../utils/theme'
import { StyledCardInput } from '.'

type Props = {
  onComplete: () => void
  onError: () => void
}

const CardInput: FC<Props> = ({ onComplete, onError }) => {
  const handleChange = (e: StripeCardElementChangeEvent) => {
    if (e.complete) {
      return onComplete()
    }
    return onError()
  }

  useEffect(() => {
    onError()
  }, [])

  return (
    <StyledCardInput>
      <CardElement
        onReady={(e) => e.focus()}
        onChange={(e) => handleChange(e)}
        options={{
          classes: {
            base: 'card-input',
            focus: 'focus',
            complete: 'complete',
            invalid: 'error'
          },
          style: {
            base: {
              fontSize: '18px',
              fontFamily: `'Public Sans','SF Pro',ui-sans-serif,-apple-system,BlinkMacSystemFont,"Segoe UI","Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol",Helvetica,Arial,sans-serif`,
              color: theme.color.gray[800],
              iconColor: theme.color.gray[600],
              '::placeholder': {
                color: theme.color.gray[400]
              }
            },
            complete: {
              color: theme.color.green[600]
            },
            empty: {
              color: theme.color.gray[300]
            },
            invalid: {
              color: theme.color.red[800],
              iconColor: theme.color.red[800]
            }
          },
          iconStyle: 'default'
        }}
      />
    </StyledCardInput>
  )
}

export default CardInput
