import styled from 'styled-components'

type StyledProps = {
  isDragging?: boolean
  isOverlay?: boolean
}

const StyledUploadsFileListItem = styled.div<StyledProps>`
  width: 100%;
  box-shadow: 0 0 0 1px rgb(0, 0, 0, 0.1);
  transition: box-shadow 0.2s;
  background-color: ${({ theme }) => theme.color.background};
  border-radius: 8px;
  overflow: hidden;
  user-select: none;

  .file-preview-container {
    pointer-events: none;
    height: 150px;
    padding: 10px;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.color.gray[200]}a4;

    .media-preview {
      filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.2));

      .media-player {
        width: unset;
      }
    }
  }

  .file-footer {
    border-top: 1px solid ${({ theme }) => theme.color.gray[300]};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    height: 55px;

    .file-details-container {
      display: flex;
      align-items: center;

      .file-icon {
        height: 34px;
        width: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        border-radius: 6px;
        margin-right: 10px;
        background-color: ${({ theme }) => theme.color.gray[200]}a4;
        color: ${({ theme }) => theme.color.gray[500]};

        svg {
          height: 20px;
        }
      }

      .file-details {
        .file-name {
          font-weight: 500;
          font-size: 15px;
          display: -webkit-box;
          word-break: break-word;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          color: ${({ theme }) => theme.color.gray[800]};

          .file-ext {
            color: ${({ theme }) => theme.color.gray[500]};
          }
        }

        .file-modified {
          color: ${({ theme }) => theme.color.gray[400]};
          font-size: 13px;
          overflow: hidden;
          display: -webkit-box;
          word-break: break-word;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
    }

    .options-btn {
      display: none;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      color: ${({ theme }) => theme.color.gray[500]};
      background-color: ${({ theme }) => theme.color.gray[100]};
      flex-shrink: 0;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-left: 10px;

      svg {
        height: 20px;
        stroke-width: 2px;
      }

      &:hover {
        background-color: ${({ theme }) => theme.color.gray[200]};
      }

      &[data-active='true'] {
        display: flex;
        background-color: ${({ theme }) => theme.color.gray[200]};
      }
    }
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 0 1px rgb(0, 0, 0, 0.1), 0 2px 6px rgb(0, 0, 0, 0.1);

    .file-footer {
      .options-btn {
        display: flex;
      }
    }
  }

  ${({ isDragging, theme }) =>
    isDragging &&
    `
    background-color: ${theme.color.brandLight};
  `}

  ${({ isOverlay, theme }) =>
    isOverlay &&
    `
    position: fixed;
    width: 200px;
    cursor: grabbing !important;

    .file-preview-container {
      display: none;
    }

    .file-footer {
      border: 0;

      .file-details-container {
        .file-details {
          .file-modified {
            display: none;
          }
        }
      }

      .options-btn {
        display: none !important;
      }
    }
  `}
`

export default StyledUploadsFileListItem
