import { FC, useContext } from 'react'
import { ArrowUpRightIcon } from '@heroicons/react/24/outline'
import { BoltIcon, PlayIcon } from '@heroicons/react/24/solid'
import { ActionPlanType } from '../../../types'
import { ROUTES, linkToSizedImage, userStore } from '../../../utils'
import { RenderSlate } from '../..'
import { StyledActionPlanListItem } from '.'

type Props = {
  actionPlan: ActionPlanType
  index?: number
}

const ActionPlanListItem: FC<Props> = ({ actionPlan, index }) => {
  const { user } = useContext(userStore)

  return (
    <StyledActionPlanListItem
      isDisabled={index !== 0 && !!user?.trial}
      to={ROUTES.actionPlanBySlug(actionPlan.slug)}
    >
      <div className="action-plan-img">
        {actionPlan?.headerImg?.fileHttpLink && (
          <img src={linkToSizedImage(actionPlan.headerImg.fileHttpLink, { width: 500 })} />
        )}
        <div className="action-plan-play-indicator">
          <div>
            <PlayIcon />
          </div>
        </div>
        {index !== 0 && !!user?.trial && (
          <div className="action-plan-trial-cta">
            <BoltIcon />
            <span>GO PRO TO ACCESS</span>
          </div>
        )}
      </div>
      <div className="action-plan-details">
        <span className="action-plan-number">
          {actionPlan.type === 'bonus' && 'BONUS'}
          {actionPlan.type !== 'bonus' &&
            !!actionPlan.number &&
            `ACTION PLAN #${actionPlan.number}`}
          {actionPlan.type !== 'bonus' && !actionPlan.number && `ACTION PLAN`}
        </span>
        <span className="action-plan-title">{actionPlan.title}</span>
        {actionPlan.about && (
          <span className="action-plan-preview">
            <RenderSlate html={actionPlan.about} plainText />
          </span>
        )}
        <button>
          Read more <ArrowUpRightIcon />
        </button>
      </div>
    </StyledActionPlanListItem>
  )
}

export default ActionPlanListItem
