import styled from 'styled-components'

const StyledConnectionConnect = styled.div`
  .connection-select-header {
    padding: 0 16px 16px 16px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};

    span {
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      display: block;
      font-family: ${({ theme }) => theme.typeface.garnett};
      color: ${({ theme }) => theme.color.gray[800]};
    }
  }

  .connection-select-list {
    display: grid;
    gap: 16px;
    padding: 16px 0;
  }

  .connection-action-list {
    padding: 10px 16px calc(var(--bottom-spacing) + 10px) 16px;

    button {
      width: 100%;
      height: 60px;
      color: white;
      font-weight: 500;
      background-color: ${({ theme }) => theme.color.brand};
      border-radius: 12px;

      &:disabled {
        color: ${({ theme }) => theme.color.gray[400]};
        background-color: ${({ theme }) => theme.color.gray[200]};
      }
    }
  }
`

export default StyledConnectionConnect
