import styled from 'styled-components'

const StyledCreateEditorMedia = styled.div`
  display: flex;
  position: relative;

  .drag-indicator {
    position: absolute;
    width: 4px;
    height: 100%;
    top: 0;
    bottom: 0;
    border-radius: 50rem;
    background-color: ${({ theme }) => theme.color.brand};
    left: -7px;
  }

  .add-media-btn {
    height: 230px;
    width: 230px;
    color: ${({ theme }) => theme.color.gray[500]};
    background-color: ${({ theme }) => theme.color.gray[100]};
    border: 1px dashed ${({ theme }) => theme.color.gray[300]};
    margin-left: 10px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    transition: 0.2s;

    svg {
      height: 50px;
    }

    &:hover {
      color: ${({ theme }) => theme.color.gray[600]};
      background-color: ${({ theme }) => theme.color.gray[200]};
      border: 1px dashed ${({ theme }) => theme.color.gray[400]};
    }
  }
`

export default StyledCreateEditorMedia
