import { ShepherdOptionsWithType } from 'react-shepherd'
import { waitForElementExists } from '../../../utils'
import { removeEmptySteps } from '..'

export const generateTour = (isMobile: boolean): ShepherdOptionsWithType[] => {
  const steps = [
    {
      text: `Hey there! I’m so happy you’re here. I’m Jasmine Star, and I’m going to take you through creating a post using Social Curator.`,
      // video:
      //   'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/WeAreGoingOnBullrun.mp4',
      buttons: [
        {
          text: "Let's do this!",
          type: 'next'
        },
        {
          text: 'Nevermind',
          type: 'cancel'
        }
      ]
    },
    {
      text:
        'Let’s start with our caption. Click the <b>Captions</b> link in the sidebar to enter the captions library.',
      // video: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/TearsOfSteel.mp4',
      attachTo: {
        element: '#caption-templates-btn',
        on: isMobile ? 'bottom-start' : 'right-start'
      },
      advanceOn: {
        event: 'click',
        selector: '#caption-templates-btn'
      },
      beforeShowPromise: async () => {
        return waitForElementExists('#caption-templates-btn')
      }
    },
    {
      text:
        'Once you find a caption you like, click the <b>USE</b> button (you can also write your own later if you’d like).',
      // video: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4',
      attachTo: {
        element: '[data-tour-id="caption"]:first-child [data-tour-id="caption-use-btn"]',
        on: 'top'
      },
      advanceOn: {
        event: 'click',
        selector: '[data-tour-id="caption"]:first-child [data-tour-id="caption-use-btn"]'
      },
      scrollTo: {
        behavior: 'smooth',
        block: 'center'
      },
      beforeShowPromise: async () => {
        return waitForElementExists(
          '[data-tour-id="caption"]:first-child [data-tour-id="caption-use-btn"]'
        )
      }
    },
    {
      text:
        "Congrats! You've taken your first step to creating a post. Your in-progress post can be previewed and opened from the sidebar.",
      // video: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4',
      attachTo: {
        element: '#sidebar-post-btn',
        on: 'top-start'
      },
      canClickTarget: false,
      buttons: [
        {
          text: 'Got it',
          type: 'next'
        }
      ],
      beforeShowPromise: async () => {
        return waitForElementExists('#sidebar-post-btn')
      }
    },
    {
      text:
        'Now that you’ve added a caption to your post editor, let’s go to the <b>photo gallery</b>.',
      attachTo: {
        element: '#photos-stories-btn',
        on: isMobile ? 'bottom-start' : 'right-start'
      },
      advanceOn: {
        event: 'click',
        selector: '#photos-stories-btn'
      },
      beforeShowPromise: async () => {
        return waitForElementExists('#photos-stories-btn')
      }
    },
    {
      text:
        'Click the <b>USE</b> button of your favorite image (you can also upload your own later).',
      attachTo: {
        element: '[data-tour-id="photo"]:nth-child(1) [data-tour-id="photo-use-btn"]',
        on: 'top'
      },
      advanceOn: {
        event: 'click',
        selector: '[data-tour-id="photo"]:nth-child(1) [data-tour-id="photo-use-btn"]'
      },
      scrollTo: {
        behavior: 'smooth',
        block: 'center'
      },
      beforeShowPromise: async () => {
        return waitForElementExists(
          '[data-tour-id="photo"]:nth-child(1) [data-tour-id="photo-use-btn"]'
        )
      }
    },
    {
      text:
        'Awesome, we’re already done. Now it’s time to finish your post, let’s go to the <b>post editor</b>.',
      attachTo: {
        element: '#post-editor-btn',
        on: 'auto'
      },
      advanceOn: {
        event: 'click',
        selector: '#post-editor-btn'
      },
      beforeShowPromise: async () => {
        return waitForElementExists('#post-editor-btn')
      }
    },
    {
      text:
        'A good tip is to give your post a title (which only shows inside Social Curator). It’ll help you organize & keep track of your posts.',
      attachTo: {
        element: '#post-editor-area-title-input',
        on: 'auto'
      },
      popperOptions: { modifiers: [{ name: 'focusAfterRender', enabled: false }] },
      buttons: [
        {
          text: 'All done',
          type: 'next'
        }
      ],
      beforeShowPromise: async () => {
        return waitForElementExists('#post-editor-area-title-input')
      }
    },
    {
      text:
        'Customize your caption to your liking, by filling in the blanks and adding or removing any sentences you deem fit.',
      attachTo: {
        element: '#post-editor-area-caption-input',
        on: 'auto'
      },
      popperOptions: { modifiers: [{ name: 'focusAfterRender', enabled: false }] },
      buttons: [
        {
          text: "My caption's ready",
          type: 'next'
        }
      ],
      beforeShowPromise: async () => {
        return waitForElementExists('#post-editor-area-caption-input')
      }
    },
    {
      text: `You’re all done! You can click here to start a new post (we won’t make you post this yet 😉).`,
      attachTo: {
        element: '#new-post-btn',
        on: 'right-end'
      },
      buttons: [
        {
          text: "I'm ready!",
          type: 'next'
        }
      ]
    }
  ]
  return removeEmptySteps(steps as any)
}
