import styled from 'styled-components'

const StyledCommunityCta = styled.div`
  padding: 32px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.color.gray[300]};

  .community-cta-avatars {
    display: flex;
    align-items: center;
    justify-content: center;

    .community-cta-plus-avatar {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      margin-left: -7.92px;
      z-index: 1;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 0 0 3px ${({ theme }) => theme.color.background};
      background-color: ${({ theme }) => theme.color.brandLight};
      color: ${({ theme }) => theme.color.brand};

      svg {
        height: 24px;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        box-shadow: inset 0 0 0 1px rgb(0, 0, 0, 0.05);
      }
    }
  }

  .community-cta-details {
    margin: 22px 0 32px 0;

    .community-cta-title {
      display: block;
      font-weight: 500;
      font-size: 20px;
      letter-spacing: -0.02rem;
      color: ${({ theme }) => theme.color.gray[800]};
      text-align: center;
      font-family: ${({ theme }) => theme.typeface.polySans};
    }

    .community-cta-body {
      display: block;
      font-size: 14px;
      line-height: 20px;
      max-width: 250px;
      letter-spacing: -0.01rem;
      color: ${({ theme }) => theme.color.gray[600]};
      text-align: center;
      margin: 12px auto 0 auto;
    }
  }

  a {
    text-decoration: none;

    .btn {
      justify-content: space-between;
      max-width: 300px;
      margin: 0 auto;
    }
  }
`

export default StyledCommunityCta
