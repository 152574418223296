import styled from 'styled-components'

type StyledProps = {
  showOptions?: boolean
  isDragging?: boolean
  isOverlay?: boolean
}

const StyledCloudUploadItem = styled.div<StyledProps>`
  padding: 4px;
  margin: -4px;
  border-radius: 6px;
  transition: box-shadow 0.2s;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  gap: 10px;
  ${({ showOptions, theme }) => showOptions && `box-shadow: 0 0 0 1px ${theme.color.gray[200]};`};

  .item-details-container {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 500px;
    flex-shrink: 0;
    user-select: none;
    margin-right: 20px;

    .item-preview {
      height: 50px;
      width: 75px;
      flex-shrink: 0;
      pointer-events: none;
      border-radius: 5px;
      position: relative;
      margin-right: 12px;
      background-color: ${({ theme }) => theme.color.gray[100]};
      padding: 6px;
      box-sizing: border-box;

      .media-preview {
        filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.2));
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.foreground}10;
      }
    }

    .item-details {
      .item-name {
        text-align: left;
        font-weight: 500;
        font-size: 15px;
        color: ${({ theme }) => theme.color.gray[700]};
        display: -webkit-box;
        word-break: break-word;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .item-ext {
        color: ${({ theme }) => theme.color.gray[400]};
        font-weight: 400;
      }

      .item-stats {
        display: flex;
        align-items: center;

        div {
          width: 3px;
          height: 3px;
          border-radius: 50%;
          margin: 0 6px;
          position: relative;
          top: 1px;
          background-color: ${({ theme }) => theme.color.gray[400]};
        }

        span {
          display: block;
          font-size: 14px;
          margin-top: 2px;
          color: ${({ theme }) => theme.color.gray[500]};
        }
      }
    }
  }

  .item-actions {
    padding-right: 10px;
    transition: 0.2s;
    opacity: ${({ showOptions }) => (showOptions ? 1 : 0)};

    button {
      height: 30px;
      width: 30px;
    }
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 0 1px ${({ theme }) => theme.color.gray[200]};

    .item-actions {
      opacity: 1;
    }
  }

  ${({ isDragging, theme }) =>
    isDragging &&
    `
    opacity: 0.4;
    box-shadow: none;
    background-color: ${theme.color.gray[200]};
  `}

  ${({ isOverlay, theme }) =>
    isOverlay &&
    `
    z-index: 9999;
    width: 300px;
    overflow: hidden;
    cursor: grabbing !important;
    box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.2), 0px 0px 0px 1px rgba(0, 0, 0, 0.075),
      0px 2px 3px rgba(0, 0, 0, 0.1) !important;
    background-color: ${theme.color.background};

    .item-actions {
      display: none;
    }
  `}
`

export default StyledCloudUploadItem
