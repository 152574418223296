import styled from 'styled-components'

const StyledAddToCloudDialog = styled.div`
  width: 100vw;
  box-sizing: border-box;
  user-select: none;

  .photo-preview {
    height: 200px;
    padding: 20px;
    pointer-events: none;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.color.gray[100]};
    border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};

    .photo-img {
      position: relative;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        object-fit: contain;
        filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
      }
    }
  }

  .folder-list {
    max-height: 250px;
    overflow-y: auto;
    display: grid;
    padding: 4px;
    row-gap: 2px;

    .folder-select-btn {
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px;

      .folder-details {
        display: flex;
        align-items: center;
        font-size: 15px;
        color: ${({ theme }) => theme.color.gray[700]};

        svg {
          height: 24px;
          margin-right: 8px;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .actions {
    padding: 4px;
    border-top: 1px solid ${({ theme }) => theme.color.gray[300]};
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    max-width: 390px;
  }
`

export default StyledAddToCloudDialog
