import styled from 'styled-components'

type StyledProps = {
  isDragActive?: boolean
}

const StyledCloudDropzone = styled.div<StyledProps>`
  height: 215px;
  margin: 25px 0 30px 0;
  width: 100%;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.gray[100]};
  border: 1px dashed ${({ theme }) => theme.color.gray[200]};
  transition: 0.2s;

  .dropzone-label-container {
    .dropzone-icon {
      height: 80px;
      width: 80px;
      display: flex;
      margin: 0 auto 24px auto;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: 0.2s;
      background-color: ${({ theme }) => theme.color.gray[200]};
      color: ${({ theme }) => theme.color.gray[500]};

      svg {
        height: 56px;
      }
    }

    .dropzone-label {
      margin-top: 12px;
      text-align: center;
      transition: 0.2s;
      color: ${({ theme }) => theme.color.gray[500]};

      span {
        display: block;
        font-size: 15px;

        &:not(:first-child) {
          margin-top: 4px;
        }
      }
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.gray[200]};
    border: 1px dashed ${({ theme }) => theme.color.gray[300]};
    cursor: pointer;

    .dropzone-label-container {
      .dropzone-icon {
        background-color: ${({ theme }) => theme.color.gray[300]};
        color: ${({ theme }) => theme.color.gray[600]};
      }

      .dropzone-label {
        color: ${({ theme }) => theme.color.gray[600]};
      }
    }
  }

  ${({ isDragActive, theme }) =>
    isDragActive &&
    `
    background-color: ${theme.color.brandLight};
    border: 1px dashed ${theme.color.brand};

    .dropzone-label-container {
      .dropzone-icon {
        background-color: ${theme.color.brand}20;
        color: ${theme.color.brand};
      }

      .dropzone-label {
        color: ${theme.color.brand};
      }
    }
  `}
`

export default StyledCloudDropzone
