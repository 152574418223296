import styled from 'styled-components'

type StyledProps = {
  transform?: any
  isCollapsed?: boolean
  isDragging?: boolean
  isActive: boolean
}

const StyledCalendarPostItem = styled.div<StyledProps>`
  background-color: ${({ theme }) => theme.color.background};
  border-radius: 12px;
  position: relative;
  user-select: none;
  padding: 10px;
  cursor: grab;
  transition: 0.2s color, 0.2s background-color, 0.2s box-shadow;
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.foreground}12;

  ${({ isActive, theme }) =>
    isActive &&
    `
    box-shadow: inset 0 0 0 1px ${theme.color.foreground}12, 0 0 0 3px ${theme.color.background}, 0 0 0 5px ${theme.color.brand} !important;
  `}

  .collapsed-post {
    display: grid;
    grid-template-columns: 35px minmax(0, 1fr);
    grid-gap: 8px;
    align-items: center;

    .post-preview {
      background-color: ${({ theme }) => theme.color.gray[100]};
      height: 35px;
      border-radius: 4px;

      .post-chips,
      .post-media-length {
        display: none;
      }

      .empty-icon {
        height: 22px;
      }

      .media-preview {
        position: absolute;
        top: 5px;
        left: 5px;
        transition: 0.2s;
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        pointer-events: none;
      }

      .post-layout-preview {
        position: absolute;
        transition: 0.2s;
        top: 5px;
        pointer-events: none;
        left: 5px;
        width: calc(100% - 10px);
        bottom: 0;
        height: 100%;
        overflow: hidden;
        background-color: ${({ theme }) => theme.color.background};
        border-radius: 2px 2px 0 0;
        box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
          0px 2px 3px rgba(0, 0, 0, 0.05);

        img {
          width: 100%;
        }

        .post-caption-preview {
          padding: 4px;
          font-size: 8px;
          line-height: 8px;
        }
      }

      &::after {
        border-radius: 4px;
      }

      &:hover {
        background-color: ${({ theme }) => theme.color.gray[200]};
      }
    }

    .post-details {
      span {
        display: block;
      }

      .post-title {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-break: anywhere;
        font-weight: 500;
        color: ${({ theme }) => theme.color.gray[800]};
        font-size: 14px;
      }

      .post-time {
        color: ${({ theme }) => theme.color.gray[600]};
        font-size: 13px;
        padding: 1px 3px;
        border-radius: 4px;
        margin: -1px -3px;
        width: fit-content;
        transition: 0.2s;

        &:hover {
          cursor: pointer;
          color: ${({ theme }) => theme.color.gray[700]};
          background-color: ${({ theme }) => theme.color.gray[200]};
        }
      }
    }
  }

  .post-details-container {
    padding: 10px 0 0 0;

    .post-title {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      line-break: anywhere;
      color: ${({ theme }) => theme.color.gray[700]};
      font-weight: 600;
      font-size: 15px;
    }

    .post-time-container {
      font-weight: 500;
      display: block;
      font-size: 14px;
      margin-top: 2px;

      .post-time {
        padding: 1px 3px;
        border-radius: 4px;
        margin: -1px -3px;
        width: fit-content;
        transition: 0.2s;
        color: ${({ theme }) => theme.color.gray[500]};

        &:hover {
          cursor: pointer;
          color: ${({ theme }) => theme.color.gray[700]};
          background-color: ${({ theme }) => theme.color.gray[200]};
        }
      }
    }
  }

  .post-platform-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    .post-profiles {
      display: flex;
      align-items: center;
    }

    .post-platforms {
      display: flex;
      align-items: center;
      color: ${({ theme }) => theme.color.gray[400]};

      svg {
        height: 18px;

        &:not(:last-child) {
          margin-right: 6px;
        }
      }
    }
  }

  .post-preview {
    background-color: ${({ theme }) => theme.color.gray[100]};
    height: 120px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    user-select: none;
    cursor: pointer;
    transition: 0.2s;

    .post-chips {
      z-index: 1;
      position: absolute;
      top: 10px;
      left: 10px;
      display: flex;

      .post-chip {
        height: 32px;
        transition: 0.2s;
        font-weight: 500;
        background-color: ${({ theme }) => theme.color.background};
        color: ${({ theme }) => theme.color.gray[600]};
        box-shadow: 0 0 0 1px rgb(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50rem;
        padding: 0 8px;
        font-size: 13px;

        svg {
          height: 18px;
          margin-right: 6px;
        }

        &.icon {
          width: 32px;
          padding: 0;

          svg {
            height: 22px;
            margin: 0;
          }
        }

        &.error {
          background-color: ${({ theme }) => theme.color.red[600]};
          color: white;
          border-radius: 50%;
        }

        &:not(:last-child) {
          margin-right: 6px;
        }
      }
    }

    .post-media-length {
      z-index: 1;
      position: absolute;
      bottom: 10px;
      right: 10px;
      height: 32px;
      transition: 0.2s;
      font-weight: 500;
      background-color: rgb(0, 0, 0, 0.5);
      color: rgb(255, 255, 255, 0.9);
      box-shadow: 0 0 0 1px rgb(255, 255, 255, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      padding: 0 8px;
      font-size: 13px;
    }

    .empty-icon {
      height: 46px;
      stroke-width: 1px;
      color: ${({ theme }) => theme.color.gray[400]};
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .options-btn {
      z-index: 2;
      opacity: 0;
      position: absolute;
      right: 10px;
      top: 10px;
      width: 32px;
      height: 32px;
      transition: 0.2s;
      background-color: ${({ theme }) => theme.color.background};
      color: ${({ theme }) => theme.color.gray[600]};
      box-shadow: 0 0 0 1px rgb(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;

      svg {
        height: 24px;
      }

      &:hover {
        background-color: ${({ theme }) => theme.color.gray[100]};
      }
    }

    .media-preview {
      position: absolute;
      top: 15px;
      left: 15px;
      transition: 0.2s;
      width: calc(100% - 30px);
      height: calc(100% - 30px);
      pointer-events: none;

      img,
      video {
        border-radius: 6px;
        max-width: 100%;
        max-height: 100%;
        width: fit-content;
        margin: 0 auto;
        filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.2));
      }
    }

    .post-layout-preview {
      position: absolute;
      transition: 0.2s;
      top: 15px;
      pointer-events: none;
      left: 15px;
      width: calc(100% - 30px);
      bottom: 0;
      height: 100%;
      overflow: hidden;
      background-color: ${({ theme }) => theme.color.background};
      border-radius: 6px 6px 0 0;
      box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
        0px 2px 3px rgba(0, 0, 0, 0.05);

      img {
        width: 100%;
      }

      .post-caption-preview {
        padding: 16px;
        font-size: 13px;
        display: block;
        line-height: 16px;
        color: ${({ theme }) => theme.color.gray[500]};
      }
    }

    &::after {
      user-select: none;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.foreground}10;
      pointer-events: none;
      z-index: 1;
    }

    &:hover {
      background-color: ${({ theme }) => theme.color.gray[200]};
    }
  }

  ${({ isDragging }) =>
    isDragging &&
    `
    z-index: 9999999;
    cursor: grabbing !important;
  `}

  ${({ isDragging }) =>
    !isDragging &&
    `
    &:hover {
      box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.2), 0px 0px 0px 1px rgba(0, 0, 0, 0.075),
        0px 2px 3px rgba(0, 0, 0, 0.1);
    }
  `}
`

export default StyledCalendarPostItem
