import { gql } from '@apollo/client'

export const chatHandlePhoto = gql`
  query ChatHandlePhoto($prompt: String!, $conversation: String!, $ref: String) {
    chatHandlePhoto(prompt: $prompt, conversation: $conversation, ref: $ref) {
      _id
      content
      prompt
      type
      conversation
      status
      template
      alt
      metadata
      createdAt
      ref
    }
  }
`
