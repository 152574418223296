import {
  MouseEvent,
  ReactNode,
  createRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'
import { createPortal } from 'react-dom'
import { disablePageScroll, enablePageScroll } from 'scroll-lock'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { isMobile } from '../../../utils'
import { Button, Drawer } from '..'
import { StyledDialog } from '.'

type Props = {
  children?: ReactNode
  noOverflow?: boolean
  canClose?: boolean
  isOpen?: boolean
  isBlocked?: boolean
  hasXIcon?: boolean
  onClose: () => void
}

const Dialog = forwardRef<HTMLDivElement, Props>(
  (
    { children, noOverflow = true, canClose = true, isOpen = false, isBlocked, hasXIcon, onClose },
    ref
  ) => {
    const [showCloseAnim, setShowCloseAnim] = useState(false)
    const modalRef = createRef<HTMLDivElement>()
    const CLOSE_ANIM_LENGTH = 100

    // Waits for the close animation to finish before
    // un-rendering the component
    const handleClose = (e?: MouseEvent) => {
      e?.stopPropagation()

      if (!canClose) {
        return
      }

      setShowCloseAnim(true)
      setTimeout(() => onClose(), CLOSE_ANIM_LENGTH)
    }

    // This exposes the handleClose logic to any
    // parent component (such as ActionDialog)
    useImperativeHandle(ref, () => ({ close: () => handleClose() } as any))

    useEffect(() => {
      setShowCloseAnim(false)

      const modal = modalRef.current

      if (!!modal && isOpen) {
        modal.focus()
        return disablePageScroll(modal)
      }

      enablePageScroll()
    }, [modalRef.current, isOpen])

    // Ensures the user can always scroll when the modal is hidden
    useEffect(() => {
      return () => {
        enablePageScroll()
      }
    }, [])

    let Element = (
      <StyledDialog ref={ref} noOverflow={noOverflow} isOpen={isOpen} showCloseAnim={showCloseAnim}>
        <dialog open className="modal">
          {hasXIcon && (
            <Button size="sm" className="close-dialog-btn" isIcon onClick={(e) => handleClose(e)}>
              <XMarkIcon />
            </Button>
          )}
          <div ref={modalRef} className="modal-content">
            {children}
          </div>
        </dialog>
        <div tabIndex={-1} className="backdrop" onClick={(e) => handleClose(e)} />
      </StyledDialog>
    )

    if (isMobile()) {
      Element = (
        <Drawer
          open={isOpen}
          isBlocked={isBlocked}
          maxHeight={700}
          expandOnContentDrag
          onDismiss={onClose}
        >
          {children}
        </Drawer>
      )
    }

    if (!isOpen && !isMobile()) {
      return <></>
    }

    // Render the modal outside of the DOM hierarchy of the parent component
    return createPortal(Element, document.getElementById('dialog-root') as HTMLElement)
  }
)

export default Dialog
