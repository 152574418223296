import { FC, ReactNode, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { StyledNavButton } from '.'

type Props = {
  to?: string
  children: ReactNode
  exact?: boolean
  active?: boolean
  onClick?: () => void
}

const NavButton: FC<Props> = ({ to, children, exact, active, onClick }) => {
  const history = useHistory()
  const location = useLocation()
  const [isActive, setIsActive] = useState(false)

  const handleClick = () => {
    if (to) {
      history.push(to)
    }

    if (onClick) {
      onClick()
    }
  }

  useEffect(() => {
    if (!to) {
      return
    }

    if (exact) {
      setIsActive(to === location.pathname)
      return
    }

    setIsActive(location.pathname.includes(to))
  }, [to, exact, location.pathname])

  useEffect(() => {
    if (active === undefined) {
      return
    }

    setIsActive(isActive)
  }, [active])

  return (
    <StyledNavButton isActive={isActive} onClick={() => handleClick()}>
      {children}
    </StyledNavButton>
  )
}

export default NavButton
