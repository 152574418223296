import styled from 'styled-components'

type StyledProps = {
  editingFolder?: boolean
}

const StyledCreateBarHashtags = styled.div<StyledProps>`
  display: grid;
  grid-template-rows: ${({ editingFolder }) => !editingFolder && 'auto'} minmax(0, 1fr);
  height: 100%;
`

export default StyledCreateBarHashtags
