import { FC, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useApolloClient } from '@apollo/client'
import { ArrowUpRightIcon } from '@heroicons/react/24/outline'
import { ConnectionAvatar, PageLayout, Spinner } from '../../components'
import { getAdAccounts } from '../../graphql'
import { StyledAssetsView } from '.'

type AssetType = {
  id: string
  username: string
  profile_pic: string
  page_id: string
}

const AssetsView: FC = () => {
  const apollo = useApolloClient()
  const [loading, setLoading] = useState(false)
  const [assets, setAssets] = useState<AssetType[]>([])

  const loadAssets = async () => {
    try {
      setLoading(true)

      const { data } = await apollo.query({
        fetchPolicy: 'no-cache',
        query: getAdAccounts,
        variables: {}
      })

      const accounts = data.getAdAccounts

      setAssets(accounts)
    } catch (err) {
      toast.error((err as any)?.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadAssets()
  }, [])

  return (
    <PageLayout maxWidth={600}>
      <StyledAssetsView>
        <h1>Instagram Accounts</h1>
        <p>
          The following Instagram account(s) are linked to your connected Facebook account(s). To
          manage them, click the "Open in Business Suite" button.
        </p>
        {loading && <Spinner />}
        {!loading && (
          <div className="asset-list">
            {assets.map((asset) => {
              return (
                <a
                  href={`https://business.facebook.com/latest/home?asset_id=${asset.page_id}`}
                  target="_blank"
                  key={asset.id}
                >
                  <div className="asset-info">
                    <ConnectionAvatar
                      profile={{ avatarUrl: asset.profile_pic, type: 'instagram' } as any}
                    />
                    <span>{asset.username}</span>
                  </div>
                  <button>
                    Open in Business Suite <ArrowUpRightIcon />
                  </button>
                </a>
              )
            })}
          </div>
        )}
      </StyledAssetsView>
    </PageLayout>
  )
}

export default AssetsView
