import { uniqBy } from 'lodash'
import { DateTime } from 'luxon'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { PostType } from '../../../types'

const getStart = (time: DateTime = DateTime.now()) => {
  const year = time.startOf('week')
  const day = year.weekdayShort
  const start = day === 'Sun' ? year : year.startOf('week').minus({ day: 1 })

  return start.toISO()!
}

const getEnd = (time: DateTime = DateTime.now()) => {
  const year = time.endOf('week')
  const day = year.weekdayShort
  const end = day === 'Sun' ? year : year.startOf('week').minus({ day: 1 })

  return end.toISO()!
}

type InitialState = {
  weeks: number
  week: number
  start: string
  end: string
  posts: PostType[]
}

const initialState: InitialState = {
  weeks: 1,
  week: 0,
  start: getStart(),
  end: getEnd(),
  posts: []
}

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setCalendarWeek: (state, action: PayloadAction<number>) => {
      state.week = action.payload
    },
    setCalendarTimeframe: (state, action: PayloadAction<DateTime>) => {
      state.start = getStart(action.payload)
      state.end = getEnd(action.payload)
    },
    setCalendarPosts: (state, action: PayloadAction<PostType[]>) => {
      state.posts = action.payload
    },
    addCalendarPost: (state, action: PayloadAction<PostType>) => {
      state.posts = uniqBy([action.payload].concat(state.posts), '_id')
    },
    updateCalendarPost: (state, action: PayloadAction<PostType>) => {
      state.posts = state.posts.map((post) => {
        if (post._id === action.payload._id) {
          return action.payload
        }

        return post
      })
    },
    removeCalendarPost: (state, action: PayloadAction<string>) => {
      state.posts = state.posts.filter((post) => post._id !== action.payload)
    }
  }
})

export const {
  setCalendarWeek,
  setCalendarTimeframe,
  setCalendarPosts,
  addCalendarPost,
  updateCalendarPost,
  removeCalendarPost
} = calendarSlice.actions
export default calendarSlice.reducer
