import styled from 'styled-components'

type StyledProps = {
  view: 'month' | 'week'
}

const StyledCalendarDays = styled.div<StyledProps>`
  display: grid;
  grid-template-columns: ${({ view }) => view === 'week' && 'auto'} minmax(0, 1fr);
  border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
  background-color: ${({ theme }) => theme.color.background};
  user-select: none;

  .calendar-timezone {
    width: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    font-size: 12px;
    font-family: ${({ theme }) => theme.typeface.monospace};
    color: ${({ theme }) => theme.color.gray[600]};
  }

  .calendar-days {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));

    .calendar-day {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      font-size: 15px;
      color: ${({ theme }) => theme.color.gray[600]};

      .calendar-day-number {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 4px;
      }

      &[data-today='true'] {
        color: ${({ theme }) => theme.color.gray[700]};

        .calendar-day-number {
          background-color: ${({ theme }) => theme.color.brand};
          color: ${({ theme }) => theme.color.background};
          padding: 2px 6px;
          font-weight: 500;
          border-radius: 6px;
        }
      }
    }
  }
`

export default StyledCalendarDays
