import styled from 'styled-components'

const StyledCommunityView = styled.div`
  position: relative;

  iframe {
    position: fixed;
    top: 0;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    border: 0px;
    margin: 0px;
    padding: 0px;
    z-index: 99999;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    height: 100vh;
    overflow: hidden;

    iframe {
      height: 100vh;
      top: 0;
      position: relative;
      z-index: 1;
    }
  }
`

export default StyledCommunityView
