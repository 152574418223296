import styled from 'styled-components'

const StyledReferralStats = styled.div`
  margin-top: 30px;
  border-top: 1px solid ${({ theme }) => theme.color.gray[300]};
  border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};

  .stat-row {
    display: grid;
    grid-template-columns: 100px auto 100px;
    grid-gap: 10px;
    font-size: 15px;
    color: ${({ theme }) => theme.color.gray[600]};
    height: 50px;
    align-items: center;

    b {
      font-weight: 600;
    }

    code {
      text-align: right;
      padding: 4px 6px;
      background-color: ${({ theme }) => theme.color.gray[100]};
      border-radius: 6px;
      display: block;
      width: fit-content;
      margin-left: auto;
    }

    &:not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
    }
  }
`

export default StyledReferralStats
