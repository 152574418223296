import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { compress, trimUndefinedRecursively } from 'compress-json'
import firebase from 'firebase/app'
import { v4 as uuidv4 } from 'uuid'
import { useApolloClient } from '@apollo/client'
import { uploadDesignToCloud } from '../../../graphql'
import {
  addCloudFiles,
  addDesign,
  updateCreatePost,
  updateDesign,
  updateFileStatus,
  useAppDispatch,
  useAppSelector
} from '../../../redux'
import { CloudContentType, UploadDesignType } from '../../../types'
import { getDimensions } from '..'

export const useUploadDesign = (file: UploadDesignType) => {
  const apollo = useApolloClient()
  const dispatch = useAppDispatch()
  const [progress, setProgress] = useState(0)
  const { post } = useAppSelector((state) => state.create)

  const uploadDesign = async () => {
    try {
      const storageRef = firebase.storage().ref()
      const fileRef = storageRef.child(`user-images/${uuidv4()}.png`)
      const fileData = await (await fetch(file.raw.image)).blob()

      setProgress(20)

      const uploadTask = await fileRef.put(fileData)

      setProgress(40)

      const imageUrl = await uploadTask.ref.getDownloadURL()

      let rawDesign = JSON.parse(JSON.stringify(file.raw.design))
      trimUndefinedRecursively(rawDesign)
      const design = JSON.stringify(compress(rawDesign))

      setProgress(60)

      const dimensions = await getDimensions(fileData, 'image')

      const fileItem = {
        id: file.raw.oldMediaId || uuidv4(),
        name: file.raw.name,
        type: 'design',
        file: {
          url: imageUrl,
          path: uploadTask.ref.fullPath,
          metadata: {
            design
          },
          size: 0,
          dimensions
        }
      }

      setProgress(80)

      const { data } = await apollo.mutate({
        fetchPolicy: 'no-cache',
        mutation: uploadDesignToCloud,
        variables: {
          design: fileItem
        }
      })

      dispatch(updateFileStatus({ _id: file._id, status: 'finished' }))
      dispatch(addCloudFiles([data.uploadDesignToCloud]))

      if (file.raw.oldMediaId) {
        dispatch(updateDesign(data.uploadDesignToCloud))
      }

      if (!file.raw.oldMediaId) {
        dispatch(addDesign(data.uploadDesignToCloud))
      }

      if (file.addToPost) {
        const media = post?.media || []
        const content = data.uploadDesignToCloud as CloudContentType

        const formattedData = {
          _id: uuidv4(),
          url: imageUrl,
          design,
          type: 'design',
          uploaded: true,
          ref: content._id,
          dimensions: {
            x: content.file?.dimensions.x || 0,
            y: content.file?.dimensions.y || 0
          }
        } as any

        if (media.find((m) => m.ref === content._id || m._id === content._id)) {
          dispatch(
            updateCreatePost({
              media: media.map((m) => {
                if (m.ref === content._id || m._id === content._id) {
                  return {
                    ...m,
                    url: imageUrl,
                    ref: content._id,
                    design
                  } as any
                }
                return m
              })
            })
          )
        } else {
          dispatch(
            updateCreatePost({
              media: [...media, formattedData]
            })
          )
        }
      }

      setProgress(100)
    } catch (err) {
      console.error(err)
      toast.error(`You've reached your upload limit.`)
    }
  }

  return {
    progress,
    uploadDesign
  }
}
