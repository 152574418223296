import styled from 'styled-components'

const StyledHomeView = styled.div`
  display: grid;
  grid-template-rows: auto auto minmax(0, 1fr);
  overflow: hidden;
  height: 100%;

  .filter-btn {
    height: 42px;
    width: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 -10px;
    border-radius: 50%;
    background-color: transparent;
    color: ${({ theme }) => theme.color.gray[500]};

    svg {
      height: 26px;
      stroke-width: 1.75px;
    }
  }
`

export default StyledHomeView
