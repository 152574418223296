import styled from 'styled-components'

const StyledActionPlanCaptions = styled.div`
  .masonry-grid {
    display: flex;
    margin-left: -14px;
    width: auto;

    .masonry-grid-column {
      padding-left: 14px;
      background-clip: padding-box;
    }
  }

  &:not(:first-child) {
    margin-top: 20px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    &:not(:first-child) {
      margin-top: 40px;
    }
  }
`

export default StyledActionPlanCaptions
