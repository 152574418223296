import { gql } from '@apollo/client'

export const chatSendMessage = gql`
  mutation ChatSendMessage(
    $content: String!
    $conversation: String!
    $forceAlt: String
    $forcePrefix: String
    $template: String
    $persona: String
    $ref: String
    $date: String
  ) {
    chatSendMessage(
      content: $content
      conversation: $conversation
      forceAlt: $forceAlt
      forcePrefix: $forcePrefix
      template: $template
      persona: $persona
      ref: $ref
      date: $date
    ) {
      _id
      content
      prompt
      type
      conversation
      status
      template
      alt
      metadata
      createdAt
      ref
    }
  }
`
