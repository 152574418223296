import styled from 'styled-components'

const StyledPhotoSetListItem = styled.div`
  position: relative;
  height: fit-content;

  .photo-set-list-item-preview {
    padding-top: 100%;
    border-radius: 4px;
    position: relative;

    .use-btn {
      position: absolute;
      bottom: 12px;
      left: 12px;
      z-index: 2;
      background-color: ${({ theme }) => theme.color.background};
      box-shadow: 0 0 0 1px rgb(0, 0, 0, 0.1);

      &:hover {
        background-color: ${({ theme }) => theme.color.gray[100]};
      }
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
      user-select: none;
    }

    &::after {
      user-select: none;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      box-shadow: inset 0 0 0 1px rgb(0, 0, 0, 0.1);
      z-index: 1;
    }
  }

  .photo-set-list-item-details {
    padding-top: 10px;

    span {
      display: block;
    }

    .photo-set-list-item-title {
      color: ${({ theme }) => theme.color.gray[800]};
      font-weight: 600;
      font-size: 15px;
    }

    .photo-set-list-item-category {
      font-size: 15px;
      margin-top: 2px;
      color: ${({ theme }) => theme.color.gray[600]};
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: -8px;
    left: -8px;
    width: calc(100% + 16px);
    height: calc(100% + 16px);
    object-fit: cover;
    background-color: rgb(0, 0, 0, 0.08);
    border-radius: 10px;
    user-select: none;
    transform: scale(0.5);
    opacity: 0;
    transition: 0.15s;
    z-index: -1;
  }

  &:hover {
    cursor: pointer;

    &::after {
      transform: scale(1);
      opacity: 1;
    }
  }
`

export default StyledPhotoSetListItem
