import { FC } from 'react'
import { Range as ReactRange, getTrackBackground } from 'react-range'
import ReactSlider, { ReactSliderProps } from 'react-slider'
import { theme } from '../../../utils/theme'
import { StyledRange } from '.'

type Props = {
  label?: string
  value: number
  min?: number
  max?: number
  step?: number
  onChange: (value: number) => void
} & ReactSliderProps

const Range: FC<Props> = ({ label, value, min = 0, max = 100, step = 1, onChange, ...rest }) => {
  return (
    <StyledRange>
      {label && (
        <label className="range-label-container">
          <span className="range-label">{label}</span>
          <input
            value={value}
            pattern="[0-9]*(.[0-9]+)?"
            type="number"
            inputMode="decimal"
            onChange={(e) => onChange(parseInt(e.target.value))}
          />
        </label>
      )}
      <div className="range">
        <ReactSlider
          step={step}
          min={min}
          max={max}
          thumbClassName="thumb"
          trackClassName="track"
          value={value}
          onChange={(value) => onChange(value)}
          {...rest}
        />
      </div>
    </StyledRange>
  )
}

export default Range
