import firebase from 'firebase/app'
import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { store } from '../redux'
import { conditionalStage, getServerURL } from './scripts'

const buildServerURL = () => {
  return `${getServerURL()}/graphql`
  // if (process.env.NODE_ENV === 'development') {
  //   return `http://${window.location.hostname}:8080/graphql`
  // }
  // return process.env.REACT_APP_SERVER_URL
}

const httpLink = createHttpLink({ uri: buildServerURL() })

const authLink = setContext(async (_, { headers }) => {
  const { workspace } = store.getState().state

  return new Promise((success, fail) => {
    firebase.auth().onAuthStateChanged(async (auth) => {
      const token = await auth?.getIdTokenResult()

      // return the headers to the context so httpLink can read them
      success({
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token.token}` : '',
          ...conditionalStage(!!workspace, { workspace })
        }
      })
    })
  })
})

/**
 * Strip `__typename` from mutation inputs. Apollo needs to fix this one day:
 *  https://github.com/apollographql/apollo-feature-requests/issues/6
 */
const cleanTypeName = new ApolloLink((operation, forward) => {
  // if there are variables being passed to a mutation, deeply remove __typename
  if (operation.variables) {
    const omitTypename = (key: string, value: any) => (key === '__typename' ? undefined : value)
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename)
  }

  // pass the operation to the next piece of middleware
  return forward(operation).map((data) => {
    return data
  })
})

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([cleanTypeName, authLink, httpLink]),
  cache: new InMemoryCache()
})
