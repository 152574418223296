import { FC, useContext } from 'react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import {
  clearActionPlansCategory,
  setActionPlansCategory,
  setActionPlansPlatform,
  setActionPlansQuery,
  useAppDispatch,
  useAppSelector
} from '../../../redux'
import { debounceFilter, userStore } from '../../../utils'
import { Input, PageLayout, Select, Tabs } from '../..'
import { StyledActionPlansViewHeader } from '.'

const ActionPlansViewHeader: FC = () => {
  const dispatch = useAppDispatch()
  const { user } = useContext(userStore)
  const { search, categories, platform } = useAppSelector((state) => state.actionPlans)

  return (
    <StyledActionPlansViewHeader>
      <PageLayout maxWidth={835}>
        <div className="action-plans-view-header-top">
          <Input
            variant="shadow"
            disabled={user?.trial}
            defaultValue={search}
            icon={<MagnifyingGlassIcon />}
            placeholder="Search Action Plans"
            onChange={(e) => debounceFilter(() => dispatch(setActionPlansQuery(e.target.value)))}
          />
          <Select
            variant="shadow"
            value={platform}
            disabled={user?.trial}
            options={[
              { label: 'All Platforms', value: '' },
              { label: 'Instagram', value: 'instagram' },
              { label: 'Facebook', value: 'facebook' },
              { label: 'YouTube', value: 'youtube' },
              { label: 'LinkedIn', value: 'linkedin' }
            ]}
            onChange={(e) => dispatch(setActionPlansPlatform(e.target.value))}
          />
        </div>
      </PageLayout>
      <Tabs
        tabs={[
          {
            label: 'All Action Plans',
            isActive: !categories.length,
            onClick: () => dispatch(clearActionPlansCategory())
          },
          {
            label: 'Sales',
            isActive: categories.includes('sales'),
            isDisabled: !!user?.trial,
            onClick: () => dispatch(setActionPlansCategory('sales'))
          },
          {
            label: 'Planning',
            isActive: categories.includes('planning'),
            isDisabled: !!user?.trial,
            onClick: () => dispatch(setActionPlansCategory('planning'))
          },
          {
            label: 'Engagement',
            isActive: categories.includes('engagement'),
            isDisabled: !!user?.trial,
            onClick: () => dispatch(setActionPlansCategory('engagement'))
          },
          {
            label: 'Captions',
            isActive: categories.includes('captions'),
            isDisabled: !!user?.trial,
            onClick: () => dispatch(setActionPlansCategory('captions'))
          }
        ]}
      />
    </StyledActionPlansViewHeader>
  )
}

export default ActionPlansViewHeader
