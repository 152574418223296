import styled from 'styled-components'

const StyledConnectionOverview = styled.div`
  padding-bottom: var(--bottom-spacing);

  .connection-list {
    padding: 0 0 16px 0;
    display: grid;
    gap: 10px;
  }

  .platform-list {
    display: grid;
    padding: 16px;
    gap: 10px;
    border-top: 1px solid ${({ theme }) => theme.color.gray[300]};

    button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      border-radius: 12px;
      padding: 0 10px;
      text-align: left;
      background-color: ${({ theme }) => theme.color.gray[100]};

      .platform-details-container {
        display: flex;
        align-items: center;
        font-weight: 500;

        .platform-details {
          margin-left: 10px;

          span {
            display: block;
          }

          .platform-title {
            font-weight: 600;
            font-size: 15px;
            color: ${({ theme }) => theme.color.gray[800]};
          }

          .platform-description {
            font-size: 13px;
            margin-top: -2px;
            color: ${({ theme }) => theme.color.gray[500]};
          }
        }

        svg {
          height: 28px;
          color: ${({ theme }) => theme.color.gray[800]};
        }
      }

      .plus-icon {
        height: 24px;
        stroke-width: 2px;
        color: ${({ theme }) => theme.color.gray[800]};
      }
    }
  }
`

export default StyledConnectionOverview
