import styled from 'styled-components'

const StyledBubbles = styled.div`
  position: relative;

  .react-horizontal-scrolling-menu--separator {
    width: 8px;
    flex-shrink: 0;
  }

  .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
  }

  .react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .arrow-background {
    position: absolute;
    z-index: 1;
    width: 100px;
    display: flex;
    pointer-events: none;

    .arrow-btn {
      height: 36px;
      width: 36px;
      border-radius: 50%;
      pointer-events: all;
    }

    &.arrow-left {
      left: 0;
      background: linear-gradient(90deg, ${({ theme }) => theme.color.background}, transparent);
    }

    &.arrow-right {
      right: 0;
      background: linear-gradient(90deg, transparent, ${({ theme }) => theme.color.background});
      flex-direction: row-reverse;
    }
  }
`

export default StyledBubbles
