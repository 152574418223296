import { FC } from 'react'
import { useDropzone } from 'react-dropzone'
import { compact } from 'lodash'
import { v4 as uuid } from 'uuid'
import { CloudArrowUpIcon } from '@heroicons/react/20/solid'
import { PostType } from '../../../../../types'
import { isVideo, useUploadMedia } from '../../../../../utils'
import { StyledComputerPage } from '.'

type Props = {
  loading: boolean
  onConfirm: (media: NonNullable<PostType['media']>) => void
  setLoading: (loading: boolean) => void
}

const ComputerPage: FC<Props> = ({ loading, onConfirm, setLoading }) => {
  const { uploadMedia } = useUploadMedia()

  const handleDrop = async (files: File[]) => {
    setLoading(true)

    const media = await Promise.all(
      files.map(async (file) => {
        const type = isVideo(file.name) ? 'video' : 'image'
        const content = await uploadMedia({
          _id: uuid(),
          raw: file,
          status: 'loading',
          type: 'file'
        })

        return {
          _id: uuid(),
          url: content?.file?.url || '',
          type,
          uploaded: true,
          ref: content?._id,
          dimensions: {
            x: content?.file?.dimensions.x || 0,
            y: content?.file?.dimensions.y || 0
          }
        } as NonNullable<PostType['media']>[number]
      })
    )

    console.log(media)

    onConfirm(compact(media))
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    accept: {
      'image/*': [],
      'video/*': []
    },
    disabled: loading,
    multiple: true
  })

  return (
    <StyledComputerPage $isDragActive={isDragActive} {...getRootProps()}>
      <input {...getInputProps()} />
      <div className="label">
        <CloudArrowUpIcon />
        <span className="label-title">Add photos or videos</span>
        <span className="label-description">Drag & drop or click here to upload</span>
      </div>
    </StyledComputerPage>
  )
}

export default ComputerPage
