import { FC, MouseEvent, createRef, useEffect, useState } from 'react'
import { PhotoSlider } from 'react-photo-view'
import { useDraggable } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import {
  ArrowDownTrayIcon,
  EllipsisHorizontalIcon,
  EyeIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon
} from '@heroicons/react/24/outline'
import { CloudContentType } from '../../../types'
import { formatFileSize, getRelativeString, linkToSizedImage, useCloud } from '../../../utils'
import {
  ActionDialog,
  AddToPostDialog,
  Button,
  CloudMediaPreview,
  InputDialog,
  OptionsPopover,
  Spinner
} from '../..'
import { StyledCloudUploadItem } from '.'

type Props = {
  content: CloudContentType
  isOverlay?: boolean
  onClick?: () => void
}

const CloudUploadItem: FC<Props> = ({ content, isOverlay, onClick }) => {
  const optionsBtnRef = createRef<HTMLButtonElement>()
  const { deleteFromCloud, downloadCloudFile, updateCloudFileData } = useCloud()
  const [loading, setLoading] = useState(false)
  const [showDeletionConfirmation, setShowDeletionConfirmation] = useState(false)
  const [showAddToPostDialog, setShowAddToPostDialog] = useState(false)
  const [showOptions, setShowOptions] = useState(false)
  const [showNameInput, setShowNameInput] = useState(false)
  const [showPhotoViewer, setShowPhotoViewer] = useState(false)
  const [newName, setNewName] = useState('')

  const { attributes, listeners, isDragging, setNodeRef, transform } = useDraggable({
    id: content._id,
    data: {
      content
    }
  })

  const style = {
    transform: CSS.Translate.toString(transform)
  }

  const handleDelete = async () => {
    setLoading(true)
    await deleteFromCloud([content._id])
    setLoading(false)
  }

  const handleDownload = async () => {
    setLoading(true)
    await downloadCloudFile(content)
    setLoading(false)
  }

  const handleOptions = async (e: MouseEvent) => {
    e.stopPropagation()
    setShowOptions(!showOptions)
  }

  const getName = () => {
    if (!content.name.includes('.')) {
      return content.name
    }

    return content.name.substring(0, content.name.lastIndexOf('.'))
  }

  const getExtension = () => {
    if (content.type === 'design') {
      return '.design'
    }

    if (!content.name.includes('.')) {
      return ''
    }

    return content.name.substring(content.name.lastIndexOf('.'), content.name.length)
  }

  const handlePreviewFile = () => {
    if (content.file?.type === 'video') {
      return window.open(content.file.url, '_blank')
    }

    setShowPhotoViewer(true)
  }

  const handleClick = () => {
    if (onClick) {
      return onClick()
    }

    handlePreviewFile()
  }

  useEffect(() => {
    setNewName(content.name)
  }, [])

  return (
    <>
      <StyledCloudUploadItem
        style={isOverlay ? style : undefined}
        showOptions={showOptions}
        isDragging={isDragging}
        isOverlay={isOverlay}
        onClick={() => handleClick()}
        ref={setNodeRef}
        {...attributes}
        {...listeners}
      >
        <div className="item-details-container">
          <div className="item-preview">
            <CloudMediaPreview
              url={content.file?.url || ''}
              preview={content.file?.preview}
              mediaWidth={200}
              type={content.file?.type || 'image'}
            />
          </div>
          <div className="item-details">
            <span className="item-name">
              {getName()}
              <span className="item-ext">{getExtension()}</span>
            </span>
            <div className="item-stats">
              {content.type !== 'design' && (
                <>
                  <span>{formatFileSize(content.file?.size || 0)}</span>
                  <div />
                </>
              )}
              <span>{getRelativeString(content.createdAt)}</span>
            </div>
          </div>
        </div>
        <div className="item-actions">
          <Button
            className={`options-btn-${content._id}`}
            size="sm"
            isIcon
            ref={optionsBtnRef}
            onClick={(e) => handleOptions(e)}
          >
            <EllipsisHorizontalIcon />
          </Button>
        </div>
      </StyledCloudUploadItem>
      <OptionsPopover
        options={[
          {
            icon: <PlusIcon />,
            label: 'Add to post',
            action: () => setShowAddToPostDialog(true)
          },
          {
            icon: <EyeIcon />,
            label: `Preview ${content.type}`,
            action: () => handlePreviewFile()
          },
          {
            icon: <PencilIcon />,
            label: `Rename ${content.type}`,
            action: () => setShowNameInput(true)
          },
          {
            icon: <ArrowDownTrayIcon />,
            label: `Download ${content.type}`,
            action: () => handleDownload()
          },
          {
            icon: <TrashIcon />,
            label: `Delete ${content.type}`,
            action: () => setShowDeletionConfirmation(true)
          }
        ]}
        classToAvoid={`options-btn-${content._id}`}
        buttonRef={optionsBtnRef}
        isOpen={showOptions}
        onClose={() => setShowOptions(false)}
      />
      <ActionDialog
        title={`Delete ${content.name || 'this file'}`}
        body={`Are you sure you want to delete ${content.name || 'this file'}? ${
          content.type !== 'folder' && 'This file will be removed from all posts.'
        } You will not be able to recover it.`}
        type="error"
        confirmButtonText="Delete"
        isOpen={showDeletionConfirmation}
        onClose={() => setShowDeletionConfirmation(false)}
        onConfirm={() => handleDelete()}
      />
      <AddToPostDialog
        photo={content}
        isOpen={showAddToPostDialog}
        onClose={() => setShowAddToPostDialog(false)}
      />
      <InputDialog
        isOpen={showNameInput}
        title={`Rename ${content.type}`}
        value={newName}
        confirmButtonText="Rename"
        onConfirm={() => updateCloudFileData({ ...content, name: newName })}
        onChange={(value) => setNewName(value)}
        onClose={() => setShowNameInput(false)}
      />
      {content.type !== 'folder' && (
        <PhotoSlider
          className="photo-view"
          images={[{ src: content.file?.url || '', key: content._id }]}
          visible={showPhotoViewer}
          onClose={() => setShowPhotoViewer(false)}
          index={0}
          loadingElement={<Spinner isLarge />}
          loop={false}
          maskOpacity={0.8}
        />
      )}
    </>
  )
}

export default CloudUploadItem
