import styled from 'styled-components'

const StyledPersonaInput = styled.label`
  user-select: none;

  .input-header {
    margin-bottom: 6px;

    .input-label {
      font-weight: 700;
      font-size: 15px;
      color: ${({ theme }) => theme.color.gray[800]};
      display: flex;
      align-items: center;

      .required-marker {
        height: 12px;
        width: 12px;
        border-radius: 50%;
        margin-left: 4px;
        padding-top: 5px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${({ theme }) => theme.color.red[800]};
        background-color: ${({ theme }) => theme.color.red[100]};
      }
    }

    .input-description {
      font-weight: 500;
      font-size: 14px;
      color: ${({ theme }) => theme.color.gray[500]};
      margin-top: 2px;
      margin-bottom: 10px;
    }
  }

  .input {
    width: 100%;
    position: relative;

    .list-icon {
      position: absolute;
      height: 24px;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      stroke-width: 1.75px;
      color: ${({ theme }) => theme.color.gray[500]};
      pointer-events: none;
    }
  }

  .input-hint {
    display: flex;
    margin-top: 10px;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    color: ${({ theme }) => theme.color.gray[500]};

    svg {
      height: 16px;
      margin-right: 6px;
    }
  }
`

export default StyledPersonaInput
