import { FC, useContext, useEffect } from 'react'
import Sheet from 'react-modal-sheet'
import { connectionStore } from '../../../../components'
import { setShowConnections, useAppDispatch, useAppSelector } from '../../../../redux'
import { ConnectionConnect, ConnectionOverview } from '..'
import { StyledConnectionSheet } from '.'

const ConnectionSheet: FC = () => {
  const dispatch = useAppDispatch()
  const { showConnections } = useAppSelector((state) => state.state)
  const { availableProfiles, platformToConnect, setPlatformToConnect, setAvailableProfiles } =
    useContext(connectionStore)

  useEffect(() => {
    setPlatformToConnect(undefined)
    setAvailableProfiles([])
  }, [showConnections])

  const ConnectionContent: FC = () => {
    if (availableProfiles.length || platformToConnect) {
      return <ConnectionConnect />
    }

    return <ConnectionOverview />
  }

  return (
    <Sheet
      detent="content-height"
      isOpen={showConnections}
      onClose={() => dispatch(setShowConnections(false))}
    >
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content
        // disableDrag={!availableProfiles.length && !platformToConnect}
        >
          <StyledConnectionSheet>
            <ConnectionContent />
          </StyledConnectionSheet>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={() => dispatch(setShowConnections(false))} />
    </Sheet>
  )
}

export default ConnectionSheet
