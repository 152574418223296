import styled from 'styled-components'

const StyledCancelPlanDialog = styled.div`
  width: 100vw;
  box-sizing: border-box;
  user-select: none;

  .dialog-header {
    padding: 28px;

    h1 {
      font-size: 20px;
      font-weight: 700;
      text-align: center;
      color: ${({ theme }) => theme.color.gray[800]};
    }

    p {
      font-size: 15px;
      margin-top: 8px;
      text-align: center;
      line-height: 22px;
      color: ${({ theme }) => theme.color.gray[700]};

      b {
        font-weight: 500;
      }
    }
  }

  .dialog-content {
    padding: 0 28px;

    .pro-bullets {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        display: flex;
        align-items: start;
        font-size: 15px;
        font-weight: 400;
        line-height: 22px;
        color: ${({ theme }) => theme.color.gray[600]};

        span {
          position: relative;
          margin-top: -4px;
          display: flex;
          align-items: center;
          min-height: 28px;
        }

        svg {
          height: 20px;
          margin-right: 14px;
          flex-shrink: 0;
          color: ${({ theme }) => theme.color.brand};
        }

        &:not(:last-child) {
          margin-bottom: 16px;
        }

        &:last-child {
          color: ${({ theme }) => theme.color.gray[700]};
          font-weight: 500;

          svg {
            stroke-width: 2.2px;
            color: ${({ theme }) => theme.color.gray[700]};
          }
        }
      }
    }

    .pro-sub-text {
      display: block;
      margin-top: 10px;
      font-size: 15px;
      text-align: center;
      line-height: 22px;
      color: ${({ theme }) => theme.color.gray[600]};
    }
  }

  .dialog-actions {
    display: grid;
    grid-gap: 10px;
    padding: 28px;

    .continue-btn {
      background-color: ${({ theme }) => theme.color.red[700]};
      border-color: ${({ theme }) => theme.color.red[700]};
      color: #fff;

      &:hover {
        background-color: ${({ theme }) => theme.color.red[800]};
        border-color: ${({ theme }) => theme.color.red[800]};
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    max-width: 390px;

    .dialog-content {
      .pro-bullets {
        li {
          svg {
            top: 5px;
          }

          &:last-child {
            svg {
              top: 1.35px;
            }
          }
        }
      }
    }
  }
`

export default StyledCancelPlanDialog
