import { DateTime } from 'luxon'
import { SocialProfileType } from '../../types'
import { getProfileListString } from '.'

export const getPublishString = (profiles: SocialProfileType[], date?: string) => {
  const startFut = 'This post will be automatically published to '
  const startPas = 'This post has been automatically published to '
  const profileListString = getProfileListString(profiles, true)

  if (!date) {
    return `Add a date to receive a reminder or auto-publish.`
  }

  if (!profiles.length) {
    return `You will recieve a reminder when it's time to post.`
  }

  const postDate = DateTime.fromISO(date)
  const inPast = postDate.diffNow('seconds').seconds < 0
  const dateString = `${postDate.toFormat(`LLLL d 'at' t`)}`

  return `${inPast ? startPas : startFut}${profileListString} on ${dateString}.`
}
