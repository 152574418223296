import { getFileExt } from '.'

export const isVideo = (name: string) => {
  const ext = getFileExt(name)

  switch (ext.toLowerCase()) {
    case '.mp4':
    case '.mov':
      return true
  }

  return false
}
