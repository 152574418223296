import { uniqBy } from 'lodash'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { CloudContentType } from '../../types'

type InitialState = {
  designs: CloudContentType[]
  search: string
  sort: 'createdAt' | 'updatedAt' | 'a-z'
  loading: boolean
  page: number
  total: number
}

const initialState: InitialState = {
  designs: [],
  search: '',
  sort: 'createdAt',
  loading: true,
  page: 0,
  total: 0
}

export const designsSlice = createSlice({
  name: 'designs',
  initialState,
  reducers: {
    setDesigns: (state, action: PayloadAction<CloudContentType[]>) => {
      state.designs = uniqBy(action.payload, '_id')
      state.loading = false
    },
    addDesign: (state, action: PayloadAction<CloudContentType>) => {
      state.designs = uniqBy([action.payload].concat(state.designs), '_id')
      state.total = state.total + 1
    },
    updateDesign: (state, action: PayloadAction<Partial<CloudContentType>>) => {
      state.designs = state.designs.map((design) => {
        if (design._id === action.payload._id) {
          return {
            ...design,
            ...action.payload
          }
        }

        return design
      })
    },
    removeDesign: (state, action: PayloadAction<string>) => {
      state.designs = state.designs.filter((design) => design._id !== action.payload)
      state.total = state.total - 1
    },
    clearDesigns: (state) => {
      state.designs = []
      state.loading = true
      state.page = 0
    },
    setDesignsSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload
      state.designs = []
      state.loading = true
      state.page = 0
    },
    setDesignsSort: (state, action: PayloadAction<InitialState['sort']>) => {
      state.sort = action.payload
      state.designs = []
      state.loading = true
      state.page = 0
    },
    setDesignsPage: (state, action: PayloadAction<number>) => {
      state.loading = true
      state.page = action.payload
    },
    setDesignsTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload
    }
  }
})

export const {
  setDesigns,
  addDesign,
  updateDesign,
  removeDesign,
  clearDesigns,
  setDesignsSearch,
  setDesignsSort,
  setDesignsPage,
  setDesignsTotal
} = designsSlice.actions
export default designsSlice.reducer
