import { FC } from 'react'
import { Toaster } from 'react-hot-toast'
import { Provider } from 'react-redux'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { ApolloProvider } from '@apollo/client'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { ConnectionContext, PrivateRoute } from '../components'
import { persistor, store } from '../redux'
import { ROUTES, ThemeProvider, UserProvider, apolloClient } from '../utils'
import {
  AcademyView,
  CommunityView,
  ConnectPlatformView,
  RegisterView,
  ResetPasswordView,
  UserPageView
} from '../views'
import { AppLayout, DeepLinkListener, RouteCatcher, StateManager } from './components'
import { Global, MOBILE_ROUTES } from './utils'
import {
  AccountView,
  ActionPlanView,
  ActionPlansView,
  CalendarView,
  ContentView,
  HomeView,
  OnboardingView,
  SplashView
} from './views'
import 'react-infinite-calendar/styles.css'
import 'swiper/css'

const App: FC = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider>
          <Global />
          <ApolloProvider client={apolloClient}>
            <Elements stripe={loadStripe(process.env.REACT_APP_STRIPE_ELEMENTS_KEY as string)}>
              <Router>
                <UserProvider>
                  <ConnectionContext>
                    <Switch>
                      <Route
                        path={[ROUTES.auth.register, ROUTES.old.register]}
                        component={RegisterView}
                      />
                      <Route path={ROUTES.community} component={CommunityView} />
                      <Route path={ROUTES.page.userPage} component={UserPageView} exact />
                      <Route path={ROUTES.connect} component={ConnectPlatformView} exact />
                      <Route path={ROUTES.auth.resetPassword} component={ResetPasswordView} />
                      <Route
                        path={[ROUTES.auth.login, MOBILE_ROUTES.splash]}
                        component={SplashView}
                        exact
                      />
                      <PrivateRoute
                        userRole={['admin', 'basic', 'course']}
                        path={MOBILE_ROUTES.onboarding}
                        component={OnboardingView}
                      />
                      <PrivateRoute
                        userRole={['admin', 'basic', 'course']}
                        path={MOBILE_ROUTES.community}
                        component={CommunityView}
                      />
                      <AppLayout>
                        <Switch>
                          <Route
                            path={[
                              ROUTES.old.courses,
                              ROUTES.old.coursePreview,
                              ROUTES.old.lesson,
                              ROUTES.academy.main,
                              ROUTES.academy.preview,
                              ROUTES.academy.lesson
                            ]}
                            component={AcademyView}
                            exact
                          />
                          <Route path={MOBILE_ROUTES.home} component={HomeView} exact />
                          <Route path={MOBILE_ROUTES.calendar} component={CalendarView} exact />
                          <Route path={MOBILE_ROUTES.content} component={ContentView} exact />
                          <Route
                            path={MOBILE_ROUTES.actionPlans.main}
                            component={ActionPlansView}
                            exact
                          />
                          <Route
                            path={MOBILE_ROUTES.actionPlans.actionPlan}
                            component={ActionPlanView}
                            exact
                          />
                          {/* ACCOUNT */}
                          <Route path={MOBILE_ROUTES.account.main} component={AccountView} />
                          <Route path="*" component={RouteCatcher} />
                        </Switch>
                      </AppLayout>
                    </Switch>
                    <DeepLinkListener />
                    <StateManager />
                    <Toaster
                      position="bottom-center"
                      containerStyle={{
                        zIndex: 99999999999999,
                        bottom: 'var(--bottom-spacing)'
                      }}
                      toastOptions={{
                        style: {
                          zIndex: 99999999999999
                        }
                      }}
                    />
                  </ConnectionContext>
                </UserProvider>
              </Router>
            </Elements>
          </ApolloProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
