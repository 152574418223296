import { typeface } from './typeface'
import { elevation } from './elevation'
import { breakpoint } from './breakpoint'
import { color, colorDark } from './color'

export const theme = {
  color,
  elevation,
  breakpoint,
  typeface
}

export const themeDark = {
  color: colorDark,
  elevation,
  breakpoint,
  typeface
}

export type Theme = typeof theme
