import styled from 'styled-components'

const StyledHeader = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
  padding: max(10px, env(safe-area-inset-top)) 12px 10px 6px;
  display: flex;
  align-items: center;
  user-select: none;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.color.background};
  z-index: ${({ theme }) => theme.elevation.fixed};

  .banner {
    position: absolute;
    font-size: 13px;
    text-align: center;
    left: 0;
    top: -2px;
    right: 0;
    font-weight: 600;
    color: ${({ theme }) => theme.color.red[600]};
  }

  button {
    background-color: transparent;
    border-radius: 6px;
    height: 44px;
    width: 44px;

    svg {
      height: 30px;
      width: 30px;

      path {
        stroke-width: 1.3px;
      }
    }

    &:hover {
      background-color: ${({ theme }) => theme.color.background};
    }

    &:not(:last-child) {
      margin-right: 6px;
    }

    &.help-me-btn {
      background-color: ${({ theme }) => theme.color.gray[100]};

      &:hover {
        background-color: ${({ theme }) => theme.color.gray[100]};
      }
    }
  }

  .header-group {
    display: flex;
    align-items: center;
  }

  .user-icon-btn {
    background-color: ${({ theme }) => theme.color.gray[100]};
    border-radius: 50%;

    svg {
      width: 30px;
      height: 30px;

      path {
        stroke-width: 2.1px;
      }
    }
  }

  .sc-logo {
    color: ${({ theme }) => theme.color.gray[800]};
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    display: none;
  }
`

export default StyledHeader
