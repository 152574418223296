import { ShepherdOptionsWithType } from 'react-shepherd'

// Removes any empty steps (accounts for mobile / desktop differences)

export const removeEmptySteps = (steps: ShepherdOptionsWithType[]) => {
  return steps.filter((step) => {
    if (Object.keys(step).length !== 0) {
      return true
    }

    return false
  })
}
