import styled from 'styled-components'

const StyledPostEditorFeatureReleaseDialog = styled.div`
  max-width: 400px;
  width: 100vw;
  box-sizing: border-box;
  user-select: none;

  .feature-release-video {
    height: 250px;
    position: relative;
    pointer-events: none;
    background-color: ${({ theme }) => theme.color.brandLight};

    video {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }

  .feature-release-details {
    padding: 28px;

    .feature-release-title {
      display: block;
      font-weight: 500;
      font-size: 20px;
      letter-spacing: -0.02rem;
      color: ${({ theme }) => theme.color.gray[800]};
      text-align: center;
      font-family: ${({ theme }) => theme.typeface.polySans};
    }

    .feature-release-body {
      display: block;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.01rem;
      color: ${({ theme }) => theme.color.gray[600]};
      text-align: center;
      max-width: 250px;
      margin: 8px auto 0 auto;
    }

    .continue-btn {
      margin: 28px auto 0 auto;
      border-radius: 50rem;
      padding: 0 20px;
    }
  }
`

export default StyledPostEditorFeatureReleaseDialog
