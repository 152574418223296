import { FC } from 'react'
import { DateTime } from 'luxon'
import { useDroppable } from '@dnd-kit/core'
import { useAppSelector } from '../../../../redux'
import { dateIsToday } from '../../../../utils'
import { CalendarPost } from '..'
import { StyledCalendarWeekHour } from '.'

type Props = {
  day: DateTime
  hour: number
}

const CalendarWeekHour: FC<Props> = ({ day, hour }) => {
  const ID = `hour-${day.day}-${day.month}-${day.year}-${hour}`
  const { posts } = useAppSelector((state) => state.calendarMobile)

  const { isOver, setNodeRef } = useDroppable({
    id: ID,
    data: {
      date: day.toISO()
    }
  })

  const isSameHour = (dateString?: string) => {
    const date = DateTime.fromISO(dateString || '')

    if (!dateString || !date) {
      return false
    }

    const sameHour = date.hour === hour
    const sameDay = dateIsToday(date, day)

    return sameHour && sameDay
  }

  return (
    <StyledCalendarWeekHour isOver={isOver} ref={setNodeRef}>
      <div className="hour-header">{DateTime.now().set({ hour }).toFormat('h a')}</div>
      {posts
        .filter((post) => isSameHour(post.postDate))
        .map((post) => {
          return <CalendarPost post={post} key={post._id} />
        })}
    </StyledCalendarWeekHour>
  )
}

export default CalendarWeekHour
