import { App } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { setAppInfo, useAppDispatch } from '../../redux'

export const useAppInfo = () => {
  const dispatch = useAppDispatch()

  const loadAppInfo = async () => {
    if (!Capacitor.isNativePlatform()) {
      dispatch(setAppInfo(undefined))
      return
    }

    const info = await App.getInfo()

    dispatch(
      setAppInfo({
        build: info.build,
        version: info.version
      })
    )
  }

  return {
    loadAppInfo
  }
}
