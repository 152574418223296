import { ForwardedRef, forwardRef } from 'react'
import Masonry from 'react-masonry-css'
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline'
import { CaptionType } from '../../../types'
import { CaptionListItem } from '../..'
import { StyledActionPlanCaptions } from '.'

type Props = {
  captions: CaptionType[]
}

const ActionPlanCaptions = forwardRef(({ captions }: Props, ref: ForwardedRef<HTMLDivElement>) => {
  if (!captions.length) {
    return null
  }

  return (
    <StyledActionPlanCaptions ref={ref}>
      <div className="action-plan-content-list-header">
        <ChatBubbleBottomCenterTextIcon />
        <span>Recommended Captions</span>
      </div>
      <Masonry
        className="masonry-grid"
        columnClassName="masonry-grid-column"
        breakpointCols={{
          default: 2,
          900: 1
        }}
      >
        {captions.map((caption) => {
          return <CaptionListItem caption={caption} key={caption._id} />
        })}
      </Masonry>
    </StyledActionPlanCaptions>
  )
})

export default ActionPlanCaptions
