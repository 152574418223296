import { gql } from '@apollo/client'

export const getAffiliateActivity = gql`
  query GetAffiliateActivity {
    getAffiliateActivity {
      numAnnual
      numMonth
      numCourse
      numTrial
      total
      earnings
      lastTen {
        _id
        displayName
        affiliate
        amount
        item {
          _id
          cost
          type
        }
        trialStartDate
        userPaidDate
      }
    }
  }
`
