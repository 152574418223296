import styled from 'styled-components'

const StyledDesignerPanel = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;

  .designer-panel-header {
    padding: 20px 20px 0 20px;
    background: linear-gradient(0deg, #ff000000, #252627);
    position: relative;
    z-index: 1;
    box-shadow: 0 0px 50px #252627;
  }

  .designer-panel-scroll {
    position: relative;

    &::-webkit-scrollbar {
      height: 0;
      position: absolute;
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgb(255, 255, 255, 0.1);
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgb(255, 255, 255, 0.3);
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      width: 8px;
    }
  }

  button {
    font-size: 15px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`

export default StyledDesignerPanel
