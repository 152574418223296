import styled from 'styled-components'

type StyledProps = {
  expandedHeight: number
  canShowMore?: boolean
  isExpanded?: boolean
}

const StyledReadMore = styled.div<StyledProps>`
  .read-more-content-container {
    max-height: ${({ isExpanded, expandedHeight }) => (isExpanded ? expandedHeight : 120)}px;
    transition: 0.2s;
    overflow: hidden;
    position: relative;

    .read-more-content {
      display: flex;
    }

    ${({ isExpanded, canShowMore, theme }) =>
      !isExpanded &&
      canShowMore &&
      `
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        rigth: 0;
        width: 100%;
        height: 50px;
        background: linear-gradient(${theme.color.background}00, ${theme.color.background});
      }
    `}
  }

  .expand-btn {
    margin-top: 10px;
  }
`

export default StyledReadMore
