import Draggable from 'react-draggable'
import { createRef, FC, useEffect, useState } from 'react'
import { SpeakerWaveIcon, SpeakerXMarkIcon } from '@heroicons/react/20/solid'
import { Button } from '../..'
import { StyledTourVideoBubble } from '.'

type Props = {
  url: string
}

const TourVideoBubble: FC<Props> = ({ url }) => {
  const videoRef = createRef<HTMLVideoElement>()
  const [isPlaying, setIsPlaying] = useState(false)
  const [isMuted, setIsMuted] = useState(false)

  const handleVideoPlay = () => {
    setIsPlaying(true)
  }

  const handleVideoStop = () => {
    setIsPlaying(false)
  }

  const handleVolume = (e: any) => {
    const video = e.target as HTMLVideoElement

    if (video.volume === 0) {
      return setIsMuted(true)
    }

    setIsMuted(false)
  }

  const toggleMute = () => {
    const video = videoRef.current

    if (!video) {
      return
    }

    if (video.volume === 0) {
      return (video.volume = 1)
    }

    video.volume = 0
  }

  useEffect(() => {
    const video = videoRef.current

    if (!video) {
      return
    }

    video.addEventListener('play', handleVideoPlay)
    video.addEventListener('ended', handleVideoStop)
    video.addEventListener('volumechange', handleVolume)

    return () => {
      video.removeEventListener('play', handleVideoPlay)
      video.removeEventListener('ended', handleVideoStop)
      video.removeEventListener('volumechange', handleVolume)
    }
  }, [videoRef])

  return (
    <Draggable
      bounds={{
        left: 0,
        top: 0,
        bottom: window.innerHeight - 250,
        right: window.innerWidth - 250
      }}
      allowAnyClick
      defaultPosition={{ x: window.innerWidth / 2 - 125, y: window.innerHeight - 250 - 20 }}
    >
      <StyledTourVideoBubble className="floating-video" isPlaying={isPlaying}>
        <Button isIcon className="mute-btn" onClick={() => toggleMute()}>
          {isMuted ? <SpeakerXMarkIcon /> : <SpeakerWaveIcon />}
        </Button>
        <video autoPlay src={url} ref={videoRef} />
      </StyledTourVideoBubble>
    </Draggable>
  )
}

export default TourVideoBubble
