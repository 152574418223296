import { FC, createRef, useState } from 'react'
import emoji from 'react-easy-emoji'
import ReactMarkdown from 'react-markdown'
import { DateTime } from 'luxon'
import rehypeRaw from 'rehype-raw'
import { SparklesIcon } from '@heroicons/react/20/solid'
import {
  AdjustmentsHorizontalIcon,
  ArrowPathIcon,
  ArrowTopRightOnSquareIcon,
  ArrowUturnRightIcon,
  DocumentTextIcon,
  HashtagIcon,
  LightBulbIcon,
  PhotoIcon,
  VideoCameraIcon
} from '@heroicons/react/24/outline'
import { ReactComponent as ChatIcon } from '../../../assets/chat-icon.svg'
import { useAppSelector } from '../../../redux'
import { ChatMessageType } from '../../../types'
import { conditionalStage, useChat, useChatMessage } from '../../../utils'
import { CloudMediaPreview, OptionsPopover } from '../..'
import { ChatBotIcon, ChatTooltip } from '..'
import { StyledChatConversationListItem } from '.'

type Props = {
  message: ChatMessageType
  isSub?: boolean
}

const ChatConversationListItem: FC<Props> = ({ message, isSub }) => {
  const adjustBtnRef = createRef<HTMLButtonElement>()
  const remixBtnRef = createRef<HTMLButtonElement>()
  const messageRef = createRef<HTMLDivElement>()
  const [showAdjustOptions, setShowAdjustOptions] = useState(false)
  const [showRemixOptions, setShowRemixOptions] = useState(false)
  const { getTemplateLabel, scrollToMessage } = useChat()
  const { addToPost, tryAgain, remix } = useChatMessage()
  const { loading } = useAppSelector((state) => state.chat)

  return (
    <>
      <StyledChatConversationListItem
        type={message.type}
        data-message={message._id}
        isSub={isSub}
        ref={messageRef}
      >
        {message.type === 'sent' && message.template && !message.ref && (
          <div className="conversation-item-used-template">
            <SparklesIcon />
            {getTemplateLabel(message.template)}
          </div>
        )}
        {message.type === 'sent' && !!message.ref && (
          <a
            className="conversation-item-used-template reply-link"
            onClick={() => scrollToMessage(message.ref || '')}
          >
            <ArrowUturnRightIcon />
            Reply
          </a>
        )}
        <div className="conversation-item-container">
          {message.type === 'received' && (
            <div className="chat-bot-avatar">
              <ChatBotIcon size={41.5} />
            </div>
          )}
          <div className="conversation-item-bubble">
            <span>
              <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                components={{
                  img({ src, alt, title, height, width }) {
                    return (
                      <div className="image-result" style={{ aspectRatio: `${width}/${height}` }}>
                        <img src={src} alt={alt} />
                      </div>
                    )
                  },
                  video({ src, poster, height, width }) {
                    return (
                      <div className="image-result" style={{ aspectRatio: `${width}/${height}` }}>
                        <CloudMediaPreview
                          url={src || ''}
                          preview={poster}
                          isInteractive
                          type="video"
                        />
                      </div>
                    )
                  },
                  a({ href, children }) {
                    return (
                      <a href={href} target="_blank">
                        {children}
                      </a>
                    )
                  }
                }}
              >
                {message.content}
                {/* {
                  message.content
                    ?.replace(/(<\/?(?:caption|hashtag|suggestion)[^>]*>)/gi, '$1')
                    .replace('<>', '')
                    .split('<s')[0]
                } */}
              </ReactMarkdown>
            </span>
          </div>
        </div>
        {message.type === 'received' && message.status === 'success' && (
          <div className="conversation-item-actions">
            {!['daily-action'].includes(message.template || '') && (
              <button className="use-btn" disabled={loading} onClick={() => addToPost(message)}>
                Add to post
              </button>
            )}
            {message.template && (
              <>
                {!['daily-action', 'post-title', 'photo', 'video'].includes(message.template) && (
                  <button
                    className="adjust-btn"
                    disabled={loading}
                    data-active={showAdjustOptions}
                    ref={adjustBtnRef}
                    onClick={() => setShowAdjustOptions(!showAdjustOptions)}
                  >
                    <AdjustmentsHorizontalIcon /> Adjust
                  </button>
                )}
                {message.template === 'daily-action' && !!message.metadata?.dailyAction?.isUrl && (
                  <button
                    className="open-content-btn dark"
                    disabled={loading}
                    onClick={() => window.open(message.metadata?.dailyAction?.url || '', '_blank')}
                  >
                    Learn More
                    <ArrowTopRightOnSquareIcon />
                  </button>
                )}
                {!['daily-action', 'post-title'].includes(message.template) && (
                  <button
                    className="remix-btn"
                    disabled={loading}
                    data-active={showRemixOptions}
                    ref={remixBtnRef}
                    onClick={() => setShowRemixOptions(!showRemixOptions)}
                  >
                    <SparklesIcon />
                    Remix
                  </button>
                )}
                {['daily-action'].includes(message.template) && (
                  <button className="reply-btn" disabled={loading} onClick={() => remix(message)}>
                    <ArrowUturnRightIcon />
                    Reply
                  </button>
                )}
                <button
                  data-tooltip="Try again"
                  data-tooltip-pos="top-center"
                  disabled={loading}
                  className="icon-btn"
                  onClick={() => tryAgain(message)}
                >
                  <ArrowPathIcon />
                </button>
              </>
            )}
            {/* {['caption', 'blanks'].includes(message.template) && (
            <>
              <button onClick={() => addCaptionToPost()}>Add to post</button>
              <button onClick={() => sendMessage(message.content, 'photo')}>Find photo</button>
              <button onClick={() => sendMessage(message.content, 'hashtags')}>
                Suggest hashtags
              </button>
            </>
          )}
          {message.template === 'post-title' && (
            <>
              <button onClick={() => addTitleToPost()}>Use title</button>
              <button onClick={() => tryAgain()}>Retry</button>
            </>
          )}
          {message.template === 'hashtags' && (
            <button onClick={() => addHashtagToPost()}>Add to post</button>
          )}
          {message.template === 'photo' && (
            <>
              <button onClick={() => addPhotoToPost()}>Add to post</button>
              <button onClick={() => sendMessage('The image you sent', 'caption')}>
                Caption photo
              </button>
              <button onClick={() => sendMessage('The image you sent', 'hashtags')}>
                Suggest hashtags
              </button>
              <button onClick={() => tryAgain()}>Retry</button>
            </>
          )} */}
          </div>
        )}
        <div className="conversation-item-extra">
          {/* <span>FEB 24. 9:05PM</span> */}
          <span>{DateTime.fromISO(message.createdAt).toFormat('LLL. d, h:mma')}</span>
        </div>
        {message.type == 'received' && <ChatTooltip messageRef={messageRef} />}
      </StyledChatConversationListItem>
      <OptionsPopover
        options={[
          {
            icon: <DocumentTextIcon />,
            label: 'Make shorter',
            action: () => remix(message, '', 'shorter')
          },
          {
            icon: <DocumentTextIcon />,
            label: 'Make longer',
            action: () => remix(message, '', 'longer')
          },
          {
            icon: <i>{emoji('💼')}</i>,
            label: 'Professional',
            action: () => remix(message, '', 'professional')
          },
          {
            icon: <i>{emoji('🙂')}</i>,
            label: 'Neutral',
            action: () => remix(message, '', 'neutral')
          },
          {
            icon: <i>{emoji('😃')}</i>,
            label: 'Casual',
            action: () => remix(message, '', 'casual')
          },
          {
            icon: <i>{emoji('😂')}</i>,
            label: 'Funny',
            action: () => remix(message, '', 'funny')
          }
        ]}
        divsAfter={['Make longer']}
        labelsBefore={[
          {
            before: 'Make shorter',
            label: 'Text length'
          },
          {
            before: 'Professional',
            label: 'Writing style'
          }
        ]}
        position="top-left"
        isOpen={showAdjustOptions}
        classToAvoid="adjust-btn"
        buttonRef={adjustBtnRef}
        onClose={() => setShowAdjustOptions(false)}
      />
      <OptionsPopover
        options={[
          ...conditionalStage(['caption', 'blanks'].includes(message.template || ''), [
            {
              icon: <PhotoIcon />,
              label: 'Find related photo',
              action: () => remix(message, 'photo')
            },
            // {
            //   icon: <VideoCameraIcon />,
            //   label: 'Find related video',
            //   action: () => remix(message, 'video')
            // },
            {
              icon: <HashtagIcon />,
              label: 'Suggest hashtags',
              action: () => remix(message, 'hashtags')
            },
            {
              icon: <VideoCameraIcon />,
              label: 'Turn into video script',
              action: () => remix(message, 'video-script')
            },
            {
              icon: <LightBulbIcon />,
              label: 'Find visual ideas',
              action: () => remix(message, 'visual-ideas')
            }
          ]),
          ...conditionalStage(['photo'].includes(message.template || ''), [
            {
              icon: <DocumentTextIcon />,
              label: 'Generate caption',
              action: () => remix(message, 'caption')
            },
            {
              icon: <HashtagIcon />,
              label: 'Suggest hashtags',
              action: () => remix(message, 'hashtags')
            }
          ]),
          {
            icon: <ChatIcon />,
            label: 'Custom reply',
            action: () => remix(message)
          }
        ]}
        position="top-right"
        isOpen={showRemixOptions}
        classToAvoid="remix-btn"
        buttonRef={remixBtnRef}
        onClose={() => setShowRemixOptions(false)}
      />
    </>
  )
}

export default ChatConversationListItem
