import { FC } from 'react'
import { StyledCloudUploadItem_Skeleton } from '.'

const CloudUploadItem_Skeleton: FC = () => {
  return (
    <StyledCloudUploadItem_Skeleton>
      <div className="item-details-container">
        <div className="item-preview" />
        <div className="item-details">
          <span className="item-name"></span>
          <div className="item-stats">
            <span />
          </div>
        </div>
      </div>
    </StyledCloudUploadItem_Skeleton>
  )
}

export default CloudUploadItem_Skeleton
