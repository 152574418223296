import styled from 'styled-components'

const StyledConnectionConnectDialog = styled.div`
  width: 100vw;

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    max-width: 450px;
  }
`

export default StyledConnectionConnectDialog
