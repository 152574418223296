import styled from 'styled-components'

const StyledQuickstartView = styled.div`
  .width-controller {
    max-width: 950px;
    padding: 0 20px;
    margin: 0 auto;
  }

  .quick-start-top,
  .quick-start-content {
    padding: 40px 0;

    &.quick-start-top {
      background-color: ${({ theme }) => theme.color.gray[100]};
    }
  }

  .quick-start-list-container {
    .quick-start-list-title {
      color: ${({ theme }) => theme.color.gray[800]};
      font-family: ${({ theme }) => theme.typeface.faktumXCon};
      font-weight: 600;
      font-size: 28px;
    }

    .quick-start-list {
      margin-top: 20px;
      display: grid;
      row-gap: 12px;
    }

    &:not(:first-child) {
      margin-top: 40px;
    }
  }
`

export default StyledQuickstartView
