import { FC } from 'react'
import { IStaticText } from '@layerhub-io/types'
import { Button } from '../../..'
import { useDesigner } from '../../../../utils'
import { DesignerPanel } from '../..'
import { StyledDesignerTextPanel } from '.'

type TextType = {
  font: string
} & Omit<IStaticText, 'id' | 'type'>

const TEXT_DEFAULT_OPTIONS = [
  {
    preview: {
      fontSize: 28,
      fontWeight: 600,
      label: 'Add a heading'
    },
    text: {
      font: 'OpenSans-Bold',
      fontSize: 120,
      text: 'Add a heading',
      width: 1000
    }
  },
  {
    preview: {
      fontSize: 20,
      fontWeight: 500,
      label: 'Add a subheading'
    },
    text: {
      font: 'OpenSans-Medium',
      fontSize: 90,
      text: 'Add a subheading',
      width: 800
    }
  },
  {
    preview: {
      fontSize: 14,
      label: 'Add a small paragraph'
    },
    text: {
      font: 'OpenSans-Regular',
      fontSize: 50,
      text: 'Add a small paragraph',
      width: 800
    }
  }
]

const TEXT_STYLE_OPTIONS = [
  {
    preview: {
      fontSize: 14,
      label: 'BE BRAVE'
    },
    text: {
      font: 'Bungee-Regular',
      fontSize: 130,
      text: 'BE BRAVE',
      width: 600,
      fill: 'transparent',
      stroke: '#7e22ce',
      strokeWidth: 6,
      lineHeight: 0.8
    } as TextType
  },
  {
    preview: {
      fontSize: 18,
      fontWeight: 500,
      label: 'LevelUp',
      color: '#22d3ee',
      textShadow: '0 1px 3px #67e8f9'
    },
    text: {
      font: 'ZenDots-Regular',
      fontSize: 130,
      text: 'LevelUp',
      width: 600,
      fill: '#22d3ee',
      stroke: '#a5f3fc',
      strokeWidth: 2,
      shadow: {
        color: '#67e8f9',
        blur: 10,
        offsetY: 6,
        offsetX: 0
      }
    } as TextType
  }
]

const DesignerTextPanel: FC = () => {
  const { addText } = useDesigner()

  return (
    <DesignerPanel>
      <StyledDesignerTextPanel>
        <div className="designer-panel-header">
          <Button
            variant="emphasis"
            isFullWidth
            onClick={() =>
              addText({
                font: 'OpenSans-Regular',
                fontSize: 78,
                text: 'Your paragraph text',
                width: 800
              })
            }
          >
            Add a text box
          </Button>
        </div>
        <div className="text-options">
          {TEXT_DEFAULT_OPTIONS.map((option, index) => {
            return (
              <button
                style={{ fontSize: option.preview.fontSize, fontWeight: option.preview.fontWeight }}
                onClick={() => addText(option.text)}
                key={index}
              >
                {option.preview.label}
              </button>
            )
          })}
        </div>
        {/* <div className="text-options">
          {TEXT_STYLE_OPTIONS.map((option, index) => {
            return (
              <button
                style={{ ...option.preview }}
                onClick={() => addText(option.text)}
                key={index}
              >
                {option.preview.label}
              </button>
            )
          })}
        </div> */}
      </StyledDesignerTextPanel>
    </DesignerPanel>
  )
}

export default DesignerTextPanel
