import styled from 'styled-components'

type StyledProps = {
  height?: number
  width?: number
}

const StyledOptionsPopover = styled.div<StyledProps>`
  width: ${({ width }) => width}px;
  box-sizing: border-box;
  max-height: ${({ height }) => height}px;
  overflow-y: auto;

  .search-bar {
    padding: 4px;
    border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
  }

  .empty-state {
    text-align: center;
    font-size: 14px;
    color: ${({ theme }) => theme.color.gray[600]};
  }

  .menu-group {
    display: grid;
    grid-gap: 12px;
    padding: 12px;

    .options-label {
      font-size: 13px;
      font-weight: 500;
      color: ${({ theme }) => theme.color.gray[600]};
      margin-bottom: -2px;
      user-select: none;
    }

    a,
    button {
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-decoration: none;
      background-color: transparent;
      transition: background-color 0.2s, color 0.2s;
      color: ${({ theme }) => theme.color.gray[700]};
      overflow: hidden;
      padding: 0 6px;
      height: 32px;
      margin: -6px;
      font-size: 15px;
      position: relative;

      .option-label {
        display: flex;
        align-items: center;

        .option-label-text {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          overflow: hidden;
          text-align: left;
          -webkit-box-orient: vertical;
          overflow-wrap: anywhere;
        }
      }

      .new-flag {
        padding: 3px 6px;
        margin-left: 6px;
        font-size: 11px;
        font-weight: 600;
        letter-spacing: 0.05rem;
        border-radius: 50rem;
        color: ${({ theme }) => theme.color.background};
        background-color: ${({ theme }) => theme.color.brand};
      }

      svg,
      img {
        color: ${({ theme }) => theme.color.gray[600]};
        margin-right: 10px;
        height: 20px;
        stroke-width: 1.7px;
        transition: color 0.2s;
      }

      i {
        margin-right: 10px;
        display: flex;

        img {
          height: 18px !important;
          width: 18px !important;
        }
      }

      .avatar {
        margin-right: 6px;

        img {
          height: unset;
          margin: unset;
        }
      }

      .option-toggle {
        height: 22px;
        width: 44px;
        background-color: ${({ theme }) => theme.color.gray[200]};
        border-radius: 50rem;
        position: relative;
        flex-shrink: 0;
        margin-left: 8px;

        .option-toggle-dot {
          position: absolute;
          height: 14px;
          width: 14px;
          border-radius: 50%;
          top: 4px;
          left: 4px;
          transition: 0.2s;
          background-color: ${({ theme }) => theme.color.background};
        }
      }

      &:hover {
        color: ${({ theme }) => theme.color.gray[900]};
        background-color: ${({ theme }) => theme.color.gray[100]};

        svg {
          color: ${({ theme }) => theme.color.gray[900]};
        }
      }

      &[aria-selected='true'] {
        color: ${({ theme }) => theme.color.brand};
        // background-color: ${({ theme }) => theme.color.brandLight};

        .option-toggle {
          background-color: ${({ theme }) => theme.color.brand};

          .option-toggle-dot {
            background-color: white;
            left: 26px;
          }
        }

        svg {
          color: ${({ theme }) => theme.color.brand};
        }
      }

      .selected-icon {
        height: 22px;
        width: 22px;
        background-color: ${({ theme }) => theme.color.brand};
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        flex-shrink: 0;
        margin-left: 8px;

        svg {
          height: 16px;
          margin: 0;
          stroke-width: 2.4px;
          color: white;
        }
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
    }

    hr {
      background-color: ${({ theme }) => theme.color.gray[300]};
      width: calc(100% + 24px);
      height: 1px;
      border: 0;
      margin: 0 0 0 -12px;
    }
  }

  .color-option {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    margin-right: 10px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.foreground}20,
        0 1px rgb(0, 0, 0, 0.1);
    }
  }
`

export default StyledOptionsPopover
