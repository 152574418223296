import { FC, useEffect } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { DateTime } from 'luxon'
import { useMutation } from '@apollo/client'
import { affiliateLinkClickCreateOne } from '../../../graphql'
import { setReferral, useAppDispatch } from '../../../redux'
import { ROUTES, useSegment } from '../../../utils'

const ReferralTrack: FC = () => {
  const dispatch = useAppDispatch()
  const { track } = useSegment()
  const { affiliateId }: any = useParams()
  const [AffiliateLinkClickCreateOne] = useMutation(affiliateLinkClickCreateOne)

  const setAffiliate = async (affiliate: string) => {
    dispatch(
      setReferral({
        code: affiliate,
        date: DateTime.now().toISO()
      })
    )
  }

  const trackLinkClick = async (affiliate: string) => {
    await AffiliateLinkClickCreateOne({ variables: { affiliate } })

    track('Clicked Affiliate Link', {
      affiliate,
      clicked_date: new Date()
    })
  }

  useEffect(() => {
    trackLinkClick(affiliateId)
    setAffiliate(affiliateId)
  }, [affiliateId])

  return <Redirect to={{ pathname: ROUTES.auth.register }} />
}

export default ReferralTrack
