import { FC } from 'react'
import { CloudContentType } from '../../../../types'
import { CloudMediaPlayer, StyledCloudMediaPreview } from '.'

type Props = {
  url: string
  type: CloudContentType['type']
  mediaWidth?: number
  preview?: string
  isInteractive?: boolean
}

const CloudMediaPreview: FC<Props> = ({ url, type, preview, mediaWidth, isInteractive }) => {
  return (
    <StyledCloudMediaPreview className="media-preview">
      {type === 'image' && <img src={url} alt="" />}
      {type === 'video' && (
        <CloudMediaPlayer url={url} preview={preview} isInteractive={isInteractive} />
      )}
    </StyledCloudMediaPreview>
  )
}

export default CloudMediaPreview
