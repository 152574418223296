import { FC } from 'react'
import { ReactComponent as PostTemplatesIcon } from '../../../assets/post-templates-icon.svg'
import { PhotoSetType } from '../../../types'
import { useChat, useCreate } from '../../../utils'
import { ChatBotIcon, CloudMediaPreview } from '../..'
import { StyledPostTemplateListItem } from '.'

type Props = {
  template: PhotoSetType
}

const PostTemplateListItem: FC<Props> = ({ template }) => {
  const { newPost, addMediaToPost } = useCreate()
  const { sendMessage } = useChat()

  const handleUseTemplate = () => {
    newPost(true)
    addMediaToPost({ url: template.thumbnail || '' })
    sendMessage(template.captionPlainText || '', 'blanks', undefined, true)
  }

  const fillCaptionBlanks = (caption: string) => {
    const blankPattern = /_\s*/g
    const blankRemovedCaption = caption.replace(blankPattern, '')

    const bracketPattern = /\[[^\[\]]*\]/g
    const bracketRemovedCaption = blankRemovedCaption.replace(
      bracketPattern,
      (match) => match.replace(/[\])}[{(]/g, '').split('/')[0]
    )

    return bracketRemovedCaption
  }

  return (
    <StyledPostTemplateListItem onClick={() => handleUseTemplate()}>
      <div className="img-preview">
        <div className="use-indicator">
          <ChatBotIcon size={24} />
          <span>Use Template</span>
        </div>
        {template.thumbnail && <CloudMediaPreview url={template.thumbnail} type="image" />}
        {!template.thumbnail && <PostTemplatesIcon className="post-template-icon" />}
      </div>
      <div className="template-details">
        <span className="template-title">{template.title}</span>
        <span className="caption-preview">
          {fillCaptionBlanks(template.captionPlainText || '')}
        </span>
      </div>
    </StyledPostTemplateListItem>
  )
}

export default PostTemplateListItem
