import { FC } from 'react'
import App from '../App'
import MobileApp from '../mobile/App'
import { useIsDesktop } from './scripts'

const PlatformSwitcher: FC = () => {
  const { isDesktop } = useIsDesktop()

  if (!isDesktop) {
    return <MobileApp />
  }

  return <App />
}

export default PlatformSwitcher
