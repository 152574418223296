import { FC } from 'react'
import { DateTime } from 'luxon'
import { dateInPast, dateIsToday } from '../../../../utils'
import { StyledCalendarWeekHeading } from '.'

type Props = {
  day: DateTime
}

const CalendarWeekHeading: FC<Props> = ({ day }) => {
  return (
    <StyledCalendarWeekHeading data-today={dateIsToday(day)} data-past={dateInPast(day)}>
      <div className="heading-label">{day.toFormat('ccc').toUpperCase()}</div>
      <div className="heading-day">{day.day}</div>
    </StyledCalendarWeekHeading>
  )
}

export default CalendarWeekHeading
