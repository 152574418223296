import styled from 'styled-components'

const StyledLevelsPopover = styled.div`
  width: 300px;
  user-select: none;
  position: relative;

  #gradient-canvas {
    opacity: 0.4;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 120px;
    --gradient-color-1: ${({ theme }) => theme.color.background};
    --gradient-color-2: #7dd3fc;
    --gradient-color-3: ${({ theme }) => theme.color.purple[300]};
    --gradient-color-4: ${({ theme }) => theme.color.background};
  }

  .levels-header {
    padding: 20px;

    span {
      display: block;
      text-align: center;
    }

    .level-title {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: ${({ theme }) => theme.typeface.faktumXCon};
      color: ${({ theme }) => theme.color.gray[800]};
      font-weight: 600;
      font-size: 24px;

      svg {
        height: 20px;
        margin-right: 8px;
      }
    }

    .level-description {
      font-size: 14px;
      color: ${({ theme }) => theme.color.gray[800]};
      font-weight: 500;
      margin-top: 6px;
    }

    .levels-description {
      font-size: 12px;
      margin-top: 4px;
      color: ${({ theme }) => theme.color.gray[500]};
    }
  }

  .levels-list {
    position: relative;
    border-top: 1px solid ${({ theme }) => theme.color.gray[300]};

    hr {
      position: absolute;
      left: 21px;
      z-index: -1;
      height: 92%;
      top: 0;
      bottom: 0;
      border: 0;
      margin: 0;
      width: 2px;
      background: radial-gradient(
          circle closest-side,
          ${({ theme }) => theme.color.gray[300]} 98%,
          #0000
        )
        0 0/100% 6px;
    }

    .levels-list-header {
      padding: 14px 14px 8px 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: ${({ theme }) => theme.color.gray[800]};
      background-color: ${({ theme }) => theme.color.background};
      font-size: 13px;
      font-weight: 600;
    }

    .levels-list-grid {
      display: flex;
      padding-bottom: 4px;
      flex-direction: column-reverse;

      .level {
        height: 50px;
        padding: 0 9px 0 7px;
        margin: 0 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 6px;
        position: relative;

        .level-details-container {
          display: flex;
          align-items: center;
          position: relative;
          top: -1px;

          .level-icon {
            color: ${({ theme }) => theme.color.gray[500]};
            background-color: ${({ theme }) => theme.color.background};
            height: 22px;
            width: 22px;
            margin-right: 10px;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              width: 14px;
            }
          }

          .level-details {
            flex-direction: column;
            display: flex;

            .level-title {
              font-weight: 500;
              font-size: 14px;
              color: ${({ theme }) => theme.color.gray[500]};
            }

            .level-description {
              display: block;
              margin-top: 1px;
              font-size: 12px;
              color: ${({ theme }) => theme.color.gray[500]};
            }
          }
        }

        .level-posts {
          color: ${({ theme }) => theme.color.gray[500]};
        }

        &[data-active='true'] {
          background-color: ${({ theme }) => theme.color.background};
          box-shadow: inset 0 0 0 1.5px ${({ theme }) => theme.color.brand},
            inset 0 0 8px ${({ theme }) => theme.color.brand}40;

          .level-details-container {
            .level-icon {
              color: ${({ theme }) => theme.color.brand};
            }

            .level-title {
              color: ${({ theme }) => theme.color.gray[800]};
            }
          }

          .level-posts {
            color: ${({ theme }) => theme.color.gray[800]};
          }
        }
      }

      .level-posts {
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 14px;
        color: ${({ theme }) => theme.color.gray[800]};
      }
    }
  }
`

export default StyledLevelsPopover
