import { gql } from '@apollo/client'

export const userHashtagFindMany = gql`
  query UserHashtagFindMany {
    userHashtagFindMany {
      hashtags {
        _id
        hashtag
        folder
      }
      folders {
        _id
        label
      }
    }
  }
`
