import styled from 'styled-components'

const StyledPostErrorsDialog = styled.div`
  position: relative;
  width: 100vw;
  width: 400px;
  user-select: none;

  .dialog-header {
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    font-weight: 500;
    padding: 18px;
    border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
    color: ${({ theme }) => theme.color.gray[800]};
    z-index: ${({ theme }) => theme.elevation.fixed};
    background-color: ${({ theme }) => theme.color.background};

    .dialog-header-icon {
      height: 36px;
      margin-right: 12px;
      width: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.color.red[100]};
      color: ${({ theme }) => theme.color.red[800]};

      svg {
        height: 28px;
      }
    }
  }

  .error-list {
    display: grid;
    max-height: 400px;

    .error-list-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 20px;
      font-size: 15px;
      color: ${({ theme }) => theme.color.gray[800]};
      border-bottom: 1px solid ${({ theme }) => theme.color.gray[200]};

      .btn {
        margin: -4px 0 -4px 20px;
      }
    }
  }
`

export default StyledPostErrorsDialog
