import { FC, useState } from 'react'
import { ClockIcon } from '../../../../assets'
import { updateCreatePost, useAppDispatch, useAppSelector } from '../../../../redux'
import { getRelativeString } from '../../../../utils'
import { DateInputSheet } from '../..'
import { StyledCreateDateInput } from '.'

const CreateDateInput: FC = () => {
  const dispatch = useAppDispatch()
  const { post } = useAppSelector((state) => state.create)
  const [showDateInput, setShowDateInput] = useState(false)

  return (
    <>
      <StyledCreateDateInput>
        <span className="input-label">Publish at:</span>
        <button className="date-input-btn" onClick={() => setShowDateInput(true)}>
          <ClockIcon />
          {!post?.postDate && <span className="placeholder">Select a date & time</span>}
          {!!post?.postDate && <span>{getRelativeString(post.postDate, true)}</span>}
        </button>
      </StyledCreateDateInput>
      <DateInputSheet
        value={post?.postDate}
        isOpen={showDateInput}
        onClose={() => setShowDateInput(false)}
        onConfirm={(postDate) => dispatch(updateCreatePost({ postDate }))}
      />
    </>
  )
}

export default CreateDateInput
