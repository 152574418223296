import styled from 'styled-components'

const StyledDesignerFontFamilyTool = styled.button`
  border-radius: 4px;
  box-sizing: border-box;
  width: 180px;
  background-color: ${({ theme }) => theme.color.background};
  transition: 0.2s;
  color: ${({ theme }) => theme.color.gray[900]};
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
  justify-content: space-between;
  vertical-align: middle;
  overflow: hidden;
  height: 32px;
  width: 32px;
  margin: 0 6px 0 2px;
  position: relative;
  box-shadow: inset 0 0 0 1px rgb(0, 0, 0, 0.1);

  &:hover {
    background-color: ${({ theme }) => theme.color.gray[100]};
  }
`

export default StyledDesignerFontFamilyTool
