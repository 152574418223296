import styled from 'styled-components'

const StyledHomeActivity = styled.div`
  flex-shrink: 0;
  justify-content: space-between;

  .streak-label-container {
    span {
      display: block;
    }

    .streak-label {
      font-weight: 600;
      font-size: 15px;
      color: ${({ theme }) => theme.color.gray[800]};
    }

    .streak-count {
      display: flex;
      align-items: center;
      font-size: 13px;
      font-weight: 500;
      color: ${({ theme }) => theme.color.gray[500]};
    }
  }

  .streak-status {
    display: flex;
    margin-left: 40px;

    .streak-day {
      text-align: center;

      .streak-day-bubble {
        width: 25px;
        height: 25px;
        margin-bottom: 4px;
        flex-shrink: 0;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.gray[400]};

        svg {
          height: 17px;
          stroke-width: 2.3px;
          position: relative;
        }
      }

      .streak-day-label {
        font-size: 11px;
        font-weight: 500;
        color: ${({ theme }) => theme.color.gray[400]};
      }

      &:not(:last-child) {
        margin-right: 6px;
      }

      &[data-past='true'][data-completed='false'] {
        .streak-day-bubble {
          color: white;
          box-shadow: inset 0 0 0 25px ${({ theme }) => theme.color.gray[400]};
        }
      }

      &[data-today='true'] {
        .streak-day-bubble {
          box-shadow: inset 0 0 0 2px ${({ theme }) => theme.color.brand};
        }

        .streak-day-label {
          font-weight: 500;
          color: ${({ theme }) => theme.color.brand};
        }
      }

      &[data-completed='true'] {
        .streak-day-bubble {
          color: white;
          box-shadow: inset 0 0 0 25px ${({ theme }) => theme.color.brand};
        }
      }
    }
  }
`

export default StyledHomeActivity
