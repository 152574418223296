import { ShepherdOptionsWithType } from 'react-shepherd'
import { removeEmptySteps } from '..'

export const generateTour = (isMobile: boolean): ShepherdOptionsWithType[] => {
  const steps: ShepherdOptionsWithType[] = [
    {
      title: 'Images & designs you save to the cloud will upload here.',
      text: `You can visit the cloud to see a complete list of all the files you\'ve uploaded.`,
      canClickTarget: false,
      attachTo: {
        element: '[data-tour-id="upload-manager"]',
        on: 'top-end'
      },
      buttons: [
        {
          text: 'Awesome!',
          type: 'next'
        }
      ]
    }
  ]
  return removeEmptySteps(steps as any)
}
