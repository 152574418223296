import { FC, useState } from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
import { useAppSelector } from '../../../redux'
import { usePostErrors } from '../../../utils'
import { Button, PostErrorsDialog } from '../..'
import { StyledCreateEditorErrors } from '.'

const CreateEditorErrors: FC = () => {
  const { post } = useAppSelector((state) => state.create)
  const { errors } = usePostErrors(post)
  const [showErrorsDialog, setShowErrorsDialog] = useState(false)

  if (!errors.length) {
    return <div />
  }

  return (
    <>
      <StyledCreateEditorErrors>
        <div className="post-editor-errors-group">
          <div className="post-editor-errors-icon">
            <ExclamationCircleIcon />
          </div>
          <span>
            {errors.length > 1 && `This post has ${errors.length} errors`}
            {errors.length === 1 && errors[0].reason}
          </span>
        </div>
        {errors.length > 1 && (
          <Button size="sm" className="error-btn" onClick={() => setShowErrorsDialog(true)}>
            See Errors
          </Button>
        )}
      </StyledCreateEditorErrors>
      <PostErrorsDialog
        errors={errors}
        isOpen={showErrorsDialog}
        onClose={() => setShowErrorsDialog(false)}
      />
    </>
  )
}

export default CreateEditorErrors
