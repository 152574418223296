import styled from 'styled-components'

const StyledCreateTitleInput = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.color.background};
  z-index: ${({ theme }) => theme.elevation.fixed};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: var(--top-spacing) 16px 6px 16px;

  input {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 0 20px;
    text-align: center;
    color: ${({ theme }) => theme.color.gray[800]};
    font-size: 18px;
    background-color: ${({ theme }) => theme.color.gray[200]};

    &::placeholder {
      font-size: 18px;
    }
  }
`

export default StyledCreateTitleInput
