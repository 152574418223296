import { FC, useState } from 'react'
import Sheet from 'react-modal-sheet'
import { setShowChatHistory, useAppDispatch, useAppSelector } from '../../../../redux'
import { ChatHistoryList } from '..'
import { StyledChatHistorySheet } from '.'

const ChatHistorySheet: FC = () => {
  const dispatch = useAppDispatch()
  const [snap, setSnap] = useState(1)
  const { showHistory } = useAppSelector((state) => state.chat)

  return (
    <Sheet
      snapPoints={[0.8, 0.4]}
      initialSnap={snap}
      isOpen={!!showHistory}
      onClose={() => dispatch(setShowChatHistory(false))}
      onSnap={(index) => setSnap(index)}
    >
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content disableDrag={snap !== 1}>
          <StyledChatHistorySheet snap={snap}>
            <ChatHistoryList />
          </StyledChatHistorySheet>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={() => dispatch(setShowChatHistory(false))} />
    </Sheet>
  )
}

export default ChatHistorySheet
