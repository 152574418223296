import styled from 'styled-components'

type StyledProps = {
  isDragActive?: boolean
}

const StyledCreateEditorDropzone = styled.div<StyledProps>`
  height: 230px;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.color.gray[100]};
  border: 1px dashed ${({ theme }) => theme.color.gray[200]};
  transition: 0.2s;
  display: grid;
  grid-template-rows: minmax(0, 1fr) auto;
  user-select: none;

  .dropzone-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .dropzone-label-container {
      .dropzone-icon {
        display: flex;
        margin: 0 auto 24px auto;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transition: 0.2s;

        svg {
          height: 100px;
        }
      }

      .dropzone-label {
        margin-top: 12px;
        text-align: center;
        transition: 0.2s;
        color: ${({ theme }) => theme.color.gray[500]};

        span {
          display: block;
          font-size: 15px;

          &:not(:first-child) {
            margin-top: 4px;
          }
        }

        a {
          text-decoration: underline;

          svg {
            height: 14px;
            margin-right: 4px;
          }
        }
      }
    }
  }

  &:hover {
    border: 1px dashed ${({ theme }) => theme.color.gray[400]};
    cursor: pointer;

    .dropzone-label-container {
      .dropzone-icon {
        color: ${({ theme }) => theme.color.gray[600]};
      }

      .dropzone-label {
        color: ${({ theme }) => theme.color.gray[600]};
      }
    }
  }

  ${({ isDragActive, theme }) =>
    isDragActive &&
    `
    background-color: ${theme.color.brandLight};
    border: 1px dashed ${theme.color.brand};

    .dropzone-container {
      .dropzone-label-container {
        .dropzone-icon {
          color: ${theme.color.brand};
        }

        .dropzone-label {
          color: ${theme.color.brand};
        }
      }
    }
  `}
`

export default StyledCreateEditorDropzone
