import { CapacitorCookies } from '@capacitor/core'

const chooseDomain = (domain: string) => {
  return process.env.NODE_ENV === 'development' ? 'localhost' : domain
}

export const setCookie = async (
  name: string,
  value: string,
  path = '/',
  domain = '.socialcurator.com'
) => {
  const chosenDomain = chooseDomain(domain)
  const expires = new Date(2038, 1, 1).toString()
  document.cookie = `${name}=${value};path=${path};domain=${chosenDomain};expires=${expires};SameSite=None;Secure;`

  // await CapacitorCookies.setCookie({
  //   url: chosenDomain,
  //   path: path,
  //   expires: expires,
  //   key: name,
  //   value: value
  // })
}

export const removeCookie = async (name: string, path = '/', domain = '.socialcurator.com') => {
  const chosenDomain = chooseDomain(domain)
  const expires = new Date(1971, 1, 1).toString()
  document.cookie = `${name}=;path=${path};domain=${chosenDomain};expires=${expires};SameSite=None;Secure;`

  // await CapacitorCookies.deleteCookie({
  //   url: chosenDomain,
  //   key: name
  // })
}
