import styled from 'styled-components'

const StyledUploadsView = styled.div`
  height: 100vh;
  display: grid;
  overflow: hidden;
  grid-template-columns: auto minmax(0, 1fr);

  .uploads-content {
    height: 100vh;
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
  }

  .drag-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.color.brand}c4;
    z-index: 9999999;

    .overlay-icon {
      width: 120px;
      height: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.color.brand};
      color: white;
      margin: 0 auto 20px auto;
      box-shadow: inset 0 -4px 6px 0px #00000030, 0 4px 6px 0px #00000030;

      svg {
        height: 44px;
      }
    }
  }
`

export default StyledUploadsView
