import { FC, useEffect, useState } from 'react'
import Sheet from 'react-modal-sheet'
import { NavLink, useLocation } from 'react-router-dom'
import { ROUTES } from '../../../../utils'
import { MOBILE_ROUTES } from '../../../utils'
import { ComingSoonSheet } from '..'
import { StyledMenuSheet } from '.'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const MenuSheet: FC<Props> = ({ isOpen, onClose }) => {
  const location = useLocation()
  const [anim, setAnim] = useState(false)
  const [showComingSoon, setShowComingSoon] = useState(false)

  const handleClose = () => {
    setAnim(true)
    setShowComingSoon(false)
    setTimeout(() => {
      onClose()
    }, 200)
  }

  useEffect(() => {
    if (isOpen) {
      setAnim(false)
    }
  }, [isOpen])

  useEffect(() => {
    handleClose()
  }, [location.pathname])

  return (
    <>
      <Sheet detent="content-height" isOpen={isOpen} onClose={onClose}>
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <StyledMenuSheet>
              <a draggable={false} onClick={() => setShowComingSoon(true)}>
                <div className="icon">
                  <img src="/images/icons/link-page-icon.png" alt="" />
                </div>
                Link Page
              </a>
              <NavLink to={MOBILE_ROUTES.actionPlans.main} draggable={false}>
                <div className="icon">
                  <img src="/images/icons/action-plans-icon.png" alt="" />
                </div>
                Action Plans
              </NavLink>
              <NavLink to={ROUTES.academy.main} draggable={false}>
                <div className="icon">
                  <img src="/images/icons/academy-icon.png" alt="" />
                </div>
                Academy
              </NavLink>
              <a draggable={false} onClick={() => setShowComingSoon(true)}>
                <div className="icon">
                  <img src="/images/icons/referrals-icon.png" alt="" />
                </div>
                Referrals
              </a>
            </StyledMenuSheet>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => onClose()} />
      </Sheet>
      <ComingSoonSheet isOpen={showComingSoon} onClose={() => setShowComingSoon(false)} />
    </>
  )
}

export default MenuSheet
