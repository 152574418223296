import styled, { css, keyframes } from 'styled-components'
import { theme } from '../../../utils/theme'

const playAnim = keyframes`
  0% {
    box-shadow: 0 0 0 0px ${theme.color.brand}40;
  }
  20% {
    box-shadow: 0 0 0 10px ${theme.color.brand}40;
  }
  32% {
    box-shadow: 0 0 0 14px ${theme.color.brand}40;

  }
  47% {
    box-shadow: 0 0 0 5px ${theme.color.brand}40;

  }
  53% {
    box-shadow: 0 0 0 9px ${theme.color.brand}40;

  }
  68% {
    box-shadow: 0 0 0 2px ${theme.color.brand}40;

  }
  82% {
    box-shadow: 0 0 0 12px ${theme.color.brand}40;

  }
  91% {
    box-shadow: 0 0 0 8px ${theme.color.brand}40;

  }
  100% {
    box-shadow: 0 0 0 0px ${theme.color.brand}40;
  }
`

type StyledProps = {
  isPlaying: boolean
}

const StyledTourVideoBubble = styled.div<StyledProps>`
  position: fixed;
  z-index: 999999;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgb(0, 0, 0, 0.2);
  background-color: ${({ theme }) => theme.color.foreground};

  ${({ isPlaying, theme }) =>
    isPlaying &&
    css`
      &::before {
        user-select: none;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        box-shadow: 0 0 0 0px ${theme.color.brand}40;
        z-index: 1;
        animation: infinite 1.5s ${playAnim} ease-in-out;
        pointer-events: none;
      }
    `}

  video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    pointer-events: none;
  }

  .mute-btn {
    left: 50%;
    transform: translateX(-50%);
    bottom: 12px;
    border-radius: 50%;
    z-index: 1;
    position: absolute;
    background-color: rgb(0, 0, 0, 0.7);
    color: ${({ theme }) => theme.color.background};
  }

  &::after {
    user-select: none;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px rgb(0, 0, 0, 0.2);
    z-index: 1;
    pointer-events: none;
  }
`

export default StyledTourVideoBubble
