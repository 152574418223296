import styled from 'styled-components'

const StyledLinkPageView = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    height: 100vh;
    display: grid;
    grid-template-columns: minmax(0, 650px) minmax(0, 1fr);
  }
`

export default StyledLinkPageView
