import { FC, createRef, useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { toast } from 'react-hot-toast'
import { Prompt, useHistory } from 'react-router-dom'
import firebase from 'firebase/app'
import { Capacitor } from '@capacitor/core'
import { TrashIcon } from '@heroicons/react/24/outline'
import {
  ActionDialog,
  Button,
  CommunityCta,
  Input,
  InputDialog,
  PageLayout,
  Select,
  Spinner,
  UpdatePasswordDialog
} from '../../components'
import { useAppSelector } from '../../redux'
import { ROUTES, isMobile, useAccountDeletion, useForm, userStore } from '../../utils'
import { OnboardingSelect } from '../OnboardingView/OnboardingSelect'
import { ages } from '../OnboardingView/data/ages'
import { occupations } from '../OnboardingView/data/occupations'
import { voices } from '../OnboardingView/data/voices'
import { StyledAccountView } from '.'

const AccountView: FC = () => {
  const updateLoggedInUser = firebase.functions().httpsCallable('updateLoggedInUser')
  const history = useHistory()
  const { user, refresh } = useContext(userStore)
  const {
    showConfirmationDialog,
    actions: { deleteAccount, setShowConfirmationDialog }
  } = useAccountDeletion()
  const { appInfo } = useAppSelector((state) => state.state)
  const { splits } = useAppSelector((state) => state.splits)
  const { form, updateFormValue } = useForm({
    displayName: undefined,
    email: undefined,
    use: 'Personal',
    occupation: undefined,
    company: undefined,
    ages: undefined,
    genders: undefined,
    occupations: undefined,
    voices: undefined,
    interests: undefined
  })
  const [passwordConf, setPasswordConf] = useState('')
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false)
  const [showUpdatePasswordDialog, setShowUpdatePasswordDialog] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [loading, setLoading] = useState(false)
  const editBtnRef = createRef<HTMLButtonElement>()
  const w = window as any

  const handleSave = async () => {
    try {
      setLoading(true)

      if (isSavedDisabled()) {
        return
      }

      const profile = firebase.auth().currentUser
      const nameChanged = user?.displayName !== form.displayName
      const emailChanged = user?.email !== form.email

      // Require password confirmation for email change
      if (emailChanged && !passwordConf?.trim()) {
        return setShowPasswordConfirmation(true)
      }

      if (nameChanged) {
        await profile?.updateProfile({ displayName: form.displayName.trim() })
      }

      if (emailChanged) {
        const credential = firebase.auth.EmailAuthProvider.credential(
          profile?.email as string,
          passwordConf
        )
        await profile?.reauthenticateWithCredential(credential)
        await profile?.updateEmail(form.email)
      }

      await updateLoggedInUser({
        email: form.email,
        displayName: form.displayName,
        aiOnboarding: {
          use: form.use,
          occupation: form.occupation,
          company: form.company,
          ages: form.ages,
          genders: form.genders,
          occupations: form.occupations,
          voices: form.voices,
          interests: form.interests
        }
      })

      w.Intercom('update', {
        name: form.displayName,
        email: form.email,
        aiOnboarding: {
          use: form.use,
          occupation: form.occupation,
          company: form.company,
          ages: form.ages,
          genders: form.genders,
          occupations: form.occupations,
          voices: form.voices,
          interests: form.interests
        }
      })

      toast.success('Updated account')
      refresh()
      setIsEditing(false)
    } catch (err) {
      console.log(err)
      toast.error((err as any).message)
      setPasswordConf('')
    } finally {
      setLoading(false)
    }
  }

  const handleDisabledClick = () => {
    // const editBtn = editBtnRef.current

    // if (isEditing || !editBtn) {
    //   return
    // }

    // editBtn.focus()
    // toast('You need to click "Edit Account" to start editing your account.')

    setIsEditing(true)
  }

  const isSavedDisabled = () => {
    if (loading) {
      return true
    }

    if (!form.displayName.trim().length || !form.email.trim().length) {
      return true
    }

    return false
  }

  useEffect(() => {
    updateFormValue({
      displayName: user?.displayName,
      email: user?.email,
      use: user?.aiOnboarding?.use || undefined,
      occupation: user?.aiOnboarding?.occupation || undefined,
      company: user?.aiOnboarding?.company || undefined,
      ages: user?.aiOnboarding?.ages || undefined,
      genders: user?.aiOnboarding?.genders || undefined,
      occupations: user?.aiOnboarding?.occupations || undefined,
      voices: user?.aiOnboarding?.voices || undefined,
      interests: user?.aiOnboarding?.interests || undefined
    })
  }, [user])

  return (
    <>
      <PageLayout maxWidth={800}>
        <Helmet title="Account | Social Curator" />
        <StyledAccountView>
          <div className="account-view-header">
            <div className="account-view-header-details">
              <h1>Account</h1>
              <h2>Manage your account details</h2>
            </div>
            {isEditing && (
              <Button
                size="sm"
                variant="emphasis"
                disabled={isSavedDisabled()}
                onClick={() => handleSave()}
              >
                {loading ? <Spinner /> : 'Save Changes'}
              </Button>
            )}
            {!isEditing && (
              <Button size="sm" ref={editBtnRef} onClick={() => setIsEditing(true)}>
                Edit Account
              </Button>
            )}
          </div>
          <div className="account-content-layout">
            <div className="account-form">
              <label>
                <span className="input-label">Full name</span>
                <Input
                  value={form.displayName}
                  placeholder="Full Name"
                  disabled={!isEditing}
                  onClick={() => handleDisabledClick()}
                  onChange={(e) => updateFormValue('displayName', e.target.value)}
                />
              </label>
              <label>
                <span className="input-label">Email address</span>
                <Input
                  value={form.email}
                  placeholder="Email Address"
                  disabled={!isEditing}
                  onClick={() => handleDisabledClick()}
                  onChange={(e) => updateFormValue('email', e.target.value)}
                />
              </label>
              <Button onClick={() => setShowUpdatePasswordDialog(true)}>Update Password</Button>
              <hr />
              {splits.personas === 'on' && (
                <Button
                  size="lg"
                  variant="emphasis-tone"
                  onClick={() => history.push(ROUTES.settings.personas)}
                >
                  Update Onboarding Questions
                </Button>
              )}
              {splits.personas !== 'on' && (
                <>
                  <div className="input-container" onClick={() => handleDisabledClick()}>
                    <span className="input-label">
                      What do you call yourself (what is your job title)?
                    </span>
                    <OnboardingSelect
                      options={occupations.map((o) => ({
                        label: o,
                        value: o
                      }))}
                      defaultValue={{
                        label: user?.aiOnboarding?.occupation,
                        value: user?.aiOnboarding?.occupation
                      }}
                      isSearchable={true}
                      isCreatable={true}
                      placeholder="Start typing..."
                      onChange={(value) => updateFormValue('occupation', value.label)}
                    />
                  </div>
                  <div className="input-container" onClick={() => handleDisabledClick()}>
                    <span className="input-label">What is the name of your business/brand?</span>
                    <Input
                      defaultValue={user?.aiOnboarding?.company}
                      placeholder="Ex: Sally Smith Photography"
                      onChange={(e) => {
                        updateFormValue({
                          company: e.target.value,
                          use: !!e.target.value.trim() ? 'Business' : 'Personal'
                        })
                      }}
                    />
                  </div>
                  <div className="input-container" onClick={() => handleDisabledClick()}>
                    <span className="input-label">What is your target audience?</span>
                    <OnboardingSelect
                      options={ages.map((o) => ({
                        label: o,
                        value: o
                      }))}
                      defaultValue={user?.aiOnboarding?.ages?.map((age) => ({
                        label: age,
                        value: age
                      }))}
                      forceMenu
                      isMulti
                      placeholder="Select one or more ages"
                      onChange={(value) =>
                        updateFormValue(
                          'ages',
                          value.map((v: any) => v.label)
                        )
                      }
                    />
                    <OnboardingSelect
                      options={[
                        { label: 'Men', value: 'men' },
                        { label: 'Women', value: 'women' }
                      ]}
                      defaultValue={user?.aiOnboarding?.genders?.map((g) => ({
                        label: g,
                        value: g.toLowerCase()
                      }))}
                      forceMenu
                      isMulti
                      placeholder="Select one or more genders"
                      onChange={(value) =>
                        updateFormValue(
                          'genders',
                          value.map((v: any) => v.label)
                        )
                      }
                    />
                    <OnboardingSelect
                      options={occupations.map((o) => ({
                        label: `${o}s`,
                        value: `${o}s`
                      }))}
                      defaultValue={user?.aiOnboarding?.occupations?.map((o) => ({
                        label: o,
                        value: o
                      }))}
                      isMulti
                      placeholder="How do they refer to themselves?"
                      onChange={(value) =>
                        updateFormValue(
                          'occupations',
                          value.map((v: any) => v.label)
                        )
                      }
                    />
                  </div>
                  <label onClick={() => handleDisabledClick()}>
                    <span className="input-label">What is your voice?</span>
                    <OnboardingSelect
                      options={voices}
                      defaultValue={user?.aiOnboarding?.voices?.map((v) => ({
                        label: v,
                        value: v
                      }))}
                      forceMenu
                      isMulti
                      placeholder="Select one or more voices"
                      onChange={(value) =>
                        updateFormValue(
                          'voices',
                          value.map((v: any) => v.label)
                        )
                      }
                    />
                  </label>
                </>
              )}
              {Capacitor.isNativePlatform() && (
                <>
                  <hr />
                  <Button
                    iconPos="left"
                    variant="destructive"
                    onClick={() => setShowConfirmationDialog(true)}
                  >
                    <TrashIcon />
                    Delete Account
                  </Button>
                </>
              )}
              {!!appInfo && (
                <span className="app-version">
                  Version {appInfo.build} ({appInfo.version})
                </span>
              )}
            </div>
            {!isMobile() && <CommunityCta />}
          </div>
        </StyledAccountView>
      </PageLayout>
      <Prompt
        when={isEditing === true}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <InputDialog
        isOpen={showPasswordConfirmation}
        onConfirm={() => handleSave()}
        title="Confirm Your Password"
        value={passwordConf}
        inputProps={{ type: 'password' }}
        confirmButtonText="Update Account"
        onChange={(value) => setPasswordConf(value)}
        onClose={() => setShowPasswordConfirmation(false)}
      />
      <UpdatePasswordDialog
        isOpen={showUpdatePasswordDialog}
        onClose={() => setShowUpdatePasswordDialog(false)}
      />
      <ActionDialog
        title="Delete your account?"
        body="By clicking confirm, you agree to immediately disable your account and
        have all your account data manually deleted within 30 days. You will
        receive confirmation prior to a member of our team deleting your
        account."
        type="error"
        confirmButtonText="Delete Account"
        onConfirm={() => deleteAccount()}
        isOpen={showConfirmationDialog}
        onClose={() => setShowConfirmationDialog(false)}
      />
    </>
  )
}

export default AccountView
