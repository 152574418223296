import firebase from 'firebase/app'
import { FC, FormEvent, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { toast } from 'react-hot-toast'
import { v4 as uuidv4 } from 'uuid'
import { useApolloClient } from '@apollo/client'
import { Button, ImageInput, Input, PagePreview, Spinner, Textarea } from '../../components'
import { ROUTES, userStore } from '../../utils'
import { getUserPages, pageUpsertOne } from '../../graphql'
import { setPage, useAppDispatch, useAppSelector } from '../../redux'
import { StyledNewPageView } from '.'

const NewPageView: FC = () => {
  const history = useHistory()
  const apollo = useApolloClient()
  const dispatch = useAppDispatch()
  const storageRef = firebase.storage().ref()
  const { page } = useAppSelector((state) => state.page)
  const { user } = useContext(userStore)
  const [loading, setLoading] = useState(false)
  const [username, setUsername] = useState(user?.displayName.toLowerCase().replaceAll(' ', '-'))
  const [name, setName] = useState(
    user?.aiOnboarding?.use === 'Personal' ? user?.displayName : user?.aiOnboarding?.occupation
  )
  const [avatar, setAvatar] = useState<{ file: File | null; preview: string | null }>()
  const [description, setDescription] = useState(
    user?.aiOnboarding?.use === 'Personal'
      ? `Hey there! I'm ${user?.displayName}, a ${user?.aiOnboarding?.occupation}. Check out my links below.`
      : `Find ${user?.aiOnboarding?.company} around the web.`
  )

  const checkPageExists = async () => {
    const { data } = await apollo.query({
      fetchPolicy: 'no-cache',
      query: getUserPages
    })

    if (!data.getUserPages) {
      return
    }

    dispatch(setPage(data.getUserPages))
  }

  const handleSaveAvatar = async () => {
    if (!!avatar?.file) {
      const fileRef = storageRef.child(
        `user-images/pages/${page?.username || 'username'}-${uuidv4()}`
      )

      const uploadTask = await fileRef.put(avatar.file)
      return await uploadTask.ref.getDownloadURL()
    }

    return null
  }

  const handleSubmit = async (e: FormEvent) => {
    try {
      e.preventDefault()
      setLoading(true)

      const avatar = await handleSaveAvatar()

      const { data } = await apollo.mutate({
        fetchPolicy: 'no-cache',
        mutation: pageUpsertOne,
        variables: {
          record: {
            username,
            name,
            description,
            avatar: avatar || null
          },
          isNew: true
        }
      })

      dispatch(setPage(data.pageUpsertOne))
    } catch (err) {
      setLoading(false)
      toast.error((err as any).message)
    }
  }

  useEffect(() => {
    if (page?._id) {
      history.push(ROUTES.linkPage.page)
    }
  }, [page])

  useEffect(() => {
    checkPageExists()
  }, [])

  return (
    <StyledNewPageView>
      <Helmet title="New Link Page | Social Curator" />
      <div className="page-column details-column">
        <h1>
          <div className="new-flag">NEW</div> Create your bio Link Page
        </h1>
        <h2>
          Create a page of links (website, shop, lead magnet, etc.) that can easily be uploaded to
          your social media profile.
        </h2>
        <form onSubmit={(e) => handleSubmit(e)}>
          <label>
            <span className="input-label">Link Page URL</span>
            <Input
              preText="sc.page/"
              value={username}
              onChange={(e) => setUsername(e.target.value.toLowerCase().replaceAll(' ', '-'))}
            />
          </label>
          <div className="form-input-container">
            <span className="input-label">Page photo</span>
            <ImageInput isSquare onChange={(file, preview) => setAvatar({ file, preview })} />
          </div>
          <label>
            <span className="input-label">Page name</span>
            <Input value={name} maxLength={30} onChange={(e) => setName(e.target.value)} />
          </label>
          <label>
            <span className="input-label">Page description</span>
            <Textarea
              value={description}
              maxLength={150}
              onChange={(e) => setDescription(e.target.value)}
            />
          </label>
          <Button variant="emphasis" className="create-btn" disabled={loading}>
            {loading ? <Spinner /> : 'Create Link Page'}
          </Button>
        </form>
      </div>
      <div className="page-column preview-column">
        <PagePreview
          page={{
            name,
            description
          }}
          newAvatar={avatar}
        />
      </div>
    </StyledNewPageView>
  )
}

export default NewPageView
