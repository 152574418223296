import { FC, createRef, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { getIssueAssetsQuery } from '../../../graphql'
import { ActionPlanType } from '../../../types'
import { EmptyState } from '../..'
import {
  ActionPlanCaptions,
  ActionPlanContentHeader,
  ActionPlanExtras,
  ActionPlanPhotoSets,
  ActionPlanStories
} from '..'
import { StyledActionPlanContent } from '.'

type Props = {
  actionPlan: ActionPlanType
}

const ActionPlanContent: FC<Props> = ({ actionPlan }) => {
  const { data, loading } = useQuery(getIssueAssetsQuery, { variables: { issue: actionPlan._id } })

  const storiesRef = createRef<HTMLDivElement>()
  const photosRef = createRef<HTMLDivElement>()
  const captionsRef = createRef<HTMLDivElement>()
  const extrasRef = createRef<HTMLDivElement>()

  const [hasStories, setHasStories] = useState(true)
  const [hasPhotoSets, setHasPhotoSets] = useState(true)
  const [hasCaptions, setHasCaptions] = useState(true)
  const [hasExtras, setHasExtras] = useState(true)

  useEffect(() => {
    if (loading) {
      return
    }

    setHasStories(!!data?.storySetFindMany.length)
    setHasPhotoSets(!!data?.captionSetFindMany.length)
    setHasCaptions(!!data?.captionSetSearch.docs.length)
    setHasExtras(!!data?.extraSetFindMany.length)
  }, [loading, data])

  if (!data) {
    return null
  }

  return (
    <>
      {(hasStories || hasPhotoSets || hasCaptions || hasExtras) && (
        <ActionPlanContentHeader
          hasStories={hasStories}
          hasPhotoSets={hasPhotoSets}
          hasCaptions={hasCaptions}
          hasExtras={hasExtras}
          storiesRef={storiesRef}
          photosRef={photosRef}
          captionsRef={captionsRef}
          extrasRef={extrasRef}
        />
      )}
      <StyledActionPlanContent>
        {!hasStories && !hasPhotoSets && !hasCaptions && (
          <EmptyState
            title="Nothing to see here"
            body="This action plan doesn't have any recommended content."
          />
        )}
        <ActionPlanStories stories={data?.storySetFindMany} ref={storiesRef} />
        <ActionPlanPhotoSets photoSets={data?.captionSetFindMany} ref={photosRef} />
        <ActionPlanCaptions captions={data?.captionSetSearch.docs} ref={captionsRef} />
        <ActionPlanExtras extras={data?.extraSetFindMany} ref={extrasRef} />
      </StyledActionPlanContent>
    </>
  )
}

export default ActionPlanContent
