import styled from 'styled-components'

type StyledProps = {
  loading: boolean
}

const StyledPhotoGallery = styled.div<StyledProps>`
  overflow: hidden;
  display: grid;
  height: 100%;
  grid-template-rows: minmax(0, 1fr) auto;

  .photo-gallery-preview {
    position: relative;
    user-select: none;
    background-color: ${({ theme }) => theme.color.gray[100]};
    border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};

    img {
      filter: drop-shadow(rgba(0, 0, 0, 0.07) 0px 2px 4px);
      top: 20px;
      user-select: none;
      left: 20px;
      height: calc(100% - 40px);
      width: calc(100% - 40px);
      position: absolute;
      pointer-events: none;
      object-fit: contain;
      opacity: ${({ loading }) => (loading ? 0 : 1)};
    }

    .spinner {
      top: 50%;
      left: 50%;
      position: absolute;
      display: flex;
      transform: translate(-50%, -50%);

      svg {
        height: 50px;
        width: 50px;
      }
    }

    .photo-gallery-preview-pagination {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      bottom: 20px;
      padding: 4px 4px;
      border-radius: 50rem;
      align-items: center;
      background-color: rgb(0, 0, 0, 0.6);
      user-select: none;

      button {
        border-radius: 50%;
        height: 28px;
        width: 28px;
        color: rgb(255, 255, 255, 0.6);
        background-color: transparent;

        &:hover {
          color: rgb(255, 255, 255, 0.9);
          background-color: rgb(255, 255, 255, 0.2);
        }
      }

      .photo-gallery-preview-pagination-value {
        margin: 0 4px;
        color: rgb(255, 255, 255, 0.9);
        font-size: 13px;
        font-family: ${({ theme }) => theme.typeface.monospace};
      }
    }
  }

  .photo-gallery-action-bar {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn {
      img {
        height: 20px;
        width: 20px;
        margin-right: 8px;
      }
    }

    .photo-gallery-action-bar-group {
      display: flex;
      align-items: center;

      .canva-link {
        text-decoration: none;
      }

      a,
      .btn:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
`

export default StyledPhotoGallery
