import { DateTime } from 'luxon'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { CalendarEventType } from '../../types'
import { getStartOfWeek } from '../../utils/scripts/getStartOfWeek'

type InitialState = {
  events: CalendarEventType[]
  view: 'week' | 'month'
  monthString: string
  yearString: string
  start: string
  showPostList?: boolean
}

const initialState: InitialState = {
  events: [],
  view: 'week',
  monthString: getStartOfWeek().toFormat('LLLL'),
  yearString: getStartOfWeek().toFormat('yyyy'),
  start: getStartOfWeek().toISO(),
  showPostList: true
}

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setCalendarEvents: (state, action: PayloadAction<CalendarEventType[]>) => {
      state.events = action.payload
    },
    addCalendarEvents: (state, action: PayloadAction<CalendarEventType[]>) => {
      state.events = [...action.payload, ...state.events]
    },
    addCalendarEvent: (state, action: PayloadAction<CalendarEventType>) => {
      state.events = [action.payload, ...state.events]
    },
    updateCalendarEvent: (state, action: PayloadAction<CalendarEventType>) => {
      if (!state.events.find((event) => event._id === action.payload._id)) {
        state.events = [action.payload, ...state.events]
        return
      }

      state.events = state.events.map((event) => {
        if (event._id === action.payload._id) {
          return action.payload
        }

        return event
      })
    },
    removeCalendarEvent: (state, action: PayloadAction<string>) => {
      state.events = state.events.filter((event) => event._id !== action.payload)
    },
    setCalendarView: (state, action: PayloadAction<InitialState['view']>) => {
      state.view = action.payload
    },
    setCalendarStart: (state, action: PayloadAction<DateTime>) => {
      state.start = action.payload.toISO()
      state.monthString = action.payload.toFormat('LLLL')
      state.yearString = action.payload.toFormat('yyyy')
    },
    setShowCalendarPostList: (state, action: PayloadAction<boolean>) => {
      state.showPostList = action.payload
    }
  }
})

export const {
  setCalendarEvents,
  addCalendarEvent,
  updateCalendarEvent,
  addCalendarEvents,
  removeCalendarEvent,
  setCalendarView,
  setCalendarStart,
  setShowCalendarPostList
} = calendarSlice.actions
export default calendarSlice.reducer
