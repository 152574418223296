import { FC } from 'react'
import { useAppSelector } from '../../../../../redux'
import { StyledLinkPageAnalyticsTab } from '.'

const LinkPageAnalyticsTab: FC = () => {
  const { page } = useAppSelector((state) => state.page)

  return (
    <StyledLinkPageAnalyticsTab>
      {page?.links
        ?.filter((link) => link.type === 'url')
        .map((link) => {
          const clickCount = page.clickBreakdown?.find((b) => b._id === link._id)?.count || 0

          return (
            <div key={link._id} className="page-link-analytic">
              <b>{link.metadata.label}</b>
              <span>
                {clickCount} click{clickCount !== 1 && 's'}
              </span>
            </div>
          )
        })}
    </StyledLinkPageAnalyticsTab>
  )
}

export default LinkPageAnalyticsTab
