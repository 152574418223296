import { FC, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { times } from 'lodash'
import { useApolloClient } from '@apollo/client'
import { userHashtagFindMany } from '../../../../graphql'
import { setHashtagFolders, setHashtags, useAppDispatch, useAppSelector } from '../../../../redux'
import { EmptyState } from '../..'
import { HashtagFolderListItem } from '..'
import { StyledHashtagFolderList } from '.'

const HashtagFolderList: FC = () => {
  const apollo = useApolloClient()
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(true)
  const { folders } = useAppSelector((state) => state.hashtags)

  const loadHashtags = async () => {
    try {
      setLoading(true)
      const { data } = await apollo.query({
        fetchPolicy: 'no-cache',
        query: userHashtagFindMany
      })
      dispatch(setHashtags(data?.userHashtagFindMany?.hashtags || []))
      dispatch(setHashtagFolders(data?.userHashtagFindMany?.folders || []))
    } catch (err) {
      console.log(err)
      toast.error((err as any).message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadHashtags()
  }, [])

  return (
    <StyledHashtagFolderList>
      {loading && times(3, (num) => <div className="skeleton" key={num} />)}
      {!loading && !folders.length && (
        <EmptyState
          title="No Hashtag Sets"
          body="Hashtag Sets you create on the web will appear here."
        />
      )}
      {!loading &&
        folders.map((folder) => {
          return <HashtagFolderListItem folder={folder} key={folder._id} />
        })}
    </StyledHashtagFolderList>
  )
}

export default HashtagFolderList
