import { FC, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { ReactComponent as CloudIconsIcon } from '../../../assets/cloud-icons.svg'
import { useUploadManager } from '../../../utils'
import { UploadDialog } from '../..'
import { StyledCreateEditorDropzone } from '.'

const CreateEditorDropzone: FC = () => {
  const { addToUploads } = useUploadManager()
  const [showAddOptions, setShowAddOptions] = useState(false)
  const handleDrop = (files: File[]) => {
    addToUploads(files, true)
  }

  const { getRootProps, inputRef, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    multiple: true
  })

  return (
    <>
      <StyledCreateEditorDropzone
        isDragActive={isDragActive}
        {...getRootProps()}
        onClick={() => setShowAddOptions(!showAddOptions)}
      >
        <div className="dropzone-container">
          <input {...getInputProps()} />
          <div className="dropzone-label-container">
            <div className="dropzone-icon">
              <CloudIconsIcon />
            </div>
            <div className="dropzone-label">
              <span>Click here or drag & drop to add photos/videos</span>
              <span>Maximum file size is 50MB</span>
            </div>
          </div>
        </div>
      </StyledCreateEditorDropzone>
      <UploadDialog
        addToPost={true}
        isOpen={showAddOptions}
        onClose={() => setShowAddOptions(false)}
      />
    </>
  )
}

export default CreateEditorDropzone
