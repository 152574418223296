import { useContext } from 'react'
import { toast } from 'react-hot-toast'
import { useApolloClient } from '@apollo/client'
import { Capacitor } from '@capacitor/core'
import { Haptics } from '@capacitor/haptics'
import { upsertUserCaption } from '../../graphql'
import { setCaptionsLiked, setCaptionsUsed, useAppDispatch } from '../../redux'
import { CaptionType } from '../../types'
import { userStore } from '..'
import { copyToClipboard, useSegment } from '.'

export const useCaption = () => {
  const apollo = useApolloClient()
  const dispatch = useAppDispatch()
  const { track } = useSegment()
  const { user } = useContext(userStore)

  const toggleCaptionUsed = async (caption: CaptionType, isSecret: boolean = false) => {
    try {
      const isUsed = !caption.userCaption?.used

      dispatch(setCaptionsUsed({ _id: caption._id, isUsed }))

      if (isUsed && Capacitor.isNativePlatform()) {
        Haptics.notification()
      }

      await apollo.mutate({
        mutation: upsertUserCaption,
        variables: { record: { captionSetId: caption._id, user: user?.id, used: isUsed } }
      })

      track(`${isUsed ? 'Used' : 'Unused'} Caption`, { ...caption, used_type: 'manual' })

      if (!isSecret) {
        toast.success(`Marked caption as ${isUsed ? 'used' : 'unused'}`)
      }
    } catch (err) {
      toast.error((err as any).message)
    }
  }

  const toggleCaptionFavorited = async (caption: CaptionType) => {
    try {
      const isFavorited = !caption.userCaption?.liked

      dispatch(setCaptionsLiked({ _id: caption._id, isLiked: isFavorited }))

      if (isFavorited && Capacitor.isNativePlatform()) {
        Haptics.notification()
      }

      await apollo.mutate({
        mutation: upsertUserCaption,
        variables: { record: { captionSetId: caption._id, user: user?.id, liked: isFavorited } }
      })

      track(`${isFavorited ? 'Favorited' : 'Unfavorited'} Caption`, caption)

      toast.success(`${isFavorited ? 'Favorited' : 'Unfavorited'} caption`)
    } catch (err) {
      toast.error((err as any).message)
    }
  }

  const copyCaptionToClipboard = async (caption: CaptionType) => {
    await copyToClipboard(caption.captionPlainText)
    track(`Copied Caption To Clipboard`, caption)
  }

  return {
    toggleCaptionUsed,
    toggleCaptionFavorited,
    copyCaptionToClipboard
  }
}
