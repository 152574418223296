import styled, { keyframes } from 'styled-components'

const fadeUp = keyframes`
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`

const sparkleStar1Anim = keyframes`
  0% {
    tranform: translateY(10px);
    opacity: 0;
  }
  90% {
    transform: rotate(8deg) translateY(0);
    opacity: 1;
  }
  100% {
    transform: rotate(8deg) translateY(0);
    opacity: 0;
  }
`

type StyledProps = {
  step?: number
}

const StyledOnboardingView = styled.div<StyledProps>`
  display: flex;
  height: 100vh;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.color.background};

  #gradient-canvas {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.color.gray[100]};
    --gradient-color-1: #ffffff;
    --gradient-color-2: #7dd3fc;
    --gradient-color-3: ${({ theme }) => theme.color.purple[300]};
    --gradient-color-4: #ffffff;
  }

  .onboarding-fluid {
    margin: 20px;
    overflow: hidden;
    position: relative;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    border-radius: 20px;
  }

  .onboarding-question {
    position: relative;
    width: 0px;
    overflow: hidden;
    transition: 0.4s;
    height: 100%;
    flex-shrink: 0;

    ${({ step }) =>
      !!step &&
      step !== 4 &&
      `
        width: 600px;
    `}
  }

  .back-btn {
    position: absolute;
    top: 20px;
    left: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    z-index: 1;
    background-color: rgb(0, 0, 0, 0.65);
    color: rgb(255, 255, 255, 0.95);

    svg {
      height: 24px;
      stroke-width: 2px;
    }
  }

  .sparkle {
    pointer-events: none;
    position: absolute;
    color: rgb(0, 0, 0, 0.8);
    height: 46px;
    bottom: calc(100% + 8px);

    path:nth-of-type(1) {
      transform: translateY(10px);
      opacity: 0;
      animation: infinite 1.5s ease-in-out ${sparkleStar1Anim};
    }

    path:nth-of-type(2) {
      animation-delay: 0.2s;
      transform: translateY(10px);
      opacity: 0;
      animation: infinite 2s ease-in-out ${sparkleStar1Anim};
    }

    path:nth-of-type(3) {
      animation-delay: 0.4s;
      transform: translateY(10px);
      opacity: 0;
      animation: infinite 1.2s ease-in-out ${sparkleStar1Anim};
    }

    &:nth-of-type(1) {
      left: -40px;
      top: 2px;
      opacity: 0.5;
      transform: rotate(-19deg) skewY(357deg);
    }

    &:nth-of-type(2) {
      right: -40px;
      top: 2px;
      opacity: 0.5;
      transform: rotate(8deg) skewY(-357deg) rotateY(180deg);
    }
  }

  .intro-text,
  .question {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20px;
    box-sizing: border-box;
    display: grid;
    align-content: center;
    z-index: 1;
    text-align: center;

    .dottie-icon-intro {
      width: 80px;
      height: 80px;
      margin: 0 auto 20px auto;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgb(255, 255, 255, 0.6);
      box-shadow: inset 0 0 0 1px rgb(255, 255, 255, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
        0px 2px 3px rgba(0, 0, 0, 0.05);

      div {
        background-color: transparent;
      }

      svg {
        height: 55px;
      }
    }

    h1 {
      animation: ${fadeUp} 0.5s ease-in-out forwards;
      font-size: 42px;
      font-weight: 600;
      line-height: 40px;
      color: rgb(0, 0, 0, 0.9);
      font-family: ${({ theme }) => theme.typeface.faktumXCon};
    }

    h2 {
      animation: ${fadeUp} 0.5s ease-in-out forwards;
      transform: translateY(20px);
      opacity: 0;
      animation-delay: 0.2s;
      font-size: 20px;
      max-width: 400px;
      margin: 12px auto 0 auto;
      font-weight: 400;
      letter-spacing: -0.01rem;
      color: rgb(0, 0, 0, 0.75);
      font-family: ${({ theme }) => theme.typeface.faktum};
    }

    p {
      letter-spacing: -0.01rem;
      margin-top: 20px;
      font-size: 20px;
      color: rgb(0, 0, 0, 0.75);
      font-family: ${({ theme }) => theme.typeface.faktum};
    }

    .button-container {
      animation: ${fadeUp} 0.5s ease-in-out forwards;
      transform: translateY(20px);
      opacity: 0;
      animation-delay: 0.4s;
      position: relative;
      margin: 40px auto 0 auto;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 100px;
        border-radius: 6px;
        height: 50px;
        padding: 0 16px;
        background-color: rgb(0, 0, 0, 0.95);
        color: rgb(255, 255, 255, 0.95);
        font-weight: 500;
        font-size: 18px;
        transition: background-color 0.2s;

        &:not(.enter-btn) {
          svg {
            height: 24px;
            stroke-width: 2px;
            margin-left: 10px;
          }
        }

        &.enter-btn {
          svg {
            fill: white;
          }
        }

        &:hover {
          background-color: rgb(0, 0, 0, 0.75);
        }

        &:disabled {
          background-color: rgb(0, 0, 0, 0.25);
          color: rgb(255, 255, 255, 0.85);
          cursor: not-allowed;
        }
      }
    }

    &.question {
      max-width: 500px;

      .question-input {
        width: 100%;

        .question-input-label {
          user-select: none;
          cursor: default;
          font-weight: 500;
          text-align: left;
          display: block;
          margin-bottom: 10px;
          color: ${({ theme }) => theme.color.gray[800]};

          .required {
            color: ${({ theme }) => theme.color.red[800]};
          }
        }

        .question-input-suggestions {
          margin-top: 10px;

          .question-input-suggestion-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            white-space: nowrap;
            height: 32px;
            padding: 0 10px;
            border-radius: 6px;
            font-weight: 500;
            font-size: 14px;
            transition: 0.2s;
            color: ${({ theme }) => theme.color.gray[700]};
            background-color: ${({ theme }) => theme.color.gray[100]};

            &:hover {
              background-color: ${({ theme }) => theme.color.gray[200]};
            }
          }
        }
      }

      .button-container {
        width: 100%;
        display: flex;

        button {
          width: 100%;
          background-color: ${({ theme }) => theme.color.brand};

          &:hover {
            background-color: ${({ theme }) => theme.color.brandDark};
          }

          &:disabled {
            background-color: ${({ theme }) => theme.color.gray[100]};
            color: ${({ theme }) => theme.color.gray[400]};
            cursor: not-allowed;
          }

          &.skip {
            width: fit-content;
            flex-shrink: 0;
            background-color: ${({ theme }) => theme.color.background};
            color: ${({ theme }) => theme.color.gray[600]};
            border: 1px solid ${({ theme }) => theme.color.gray[300]};
            margin-right: 10px;

            &:hover {
              background-color: ${({ theme }) => theme.color.gray[100]};
            }

            &:last-child {
              width: 100%;
              margin: 0;
            }
          }
        }
      }
    }
  }

  .question-content {
    margin-top: 20px;
    animation: ${fadeUp} 0.5s ease-in-out forwards;
    transform: translateY(20px);
    opacity: 0;
    animation-delay: 0.3s;
    display: grid;
    grid-gap: 40px;
    position: relative;
    z-index: 9;

    .input {
      border-radius: 6px;
      height: 50px;
      width: 100%;
      transition: 0.2s;

      input {
        height: 50px;
        font-size: 18px;
        color: ${({ theme }) => theme.color.gray[800]};
      }

      &:focus-within {
        border-color: ${({ theme }) => theme.color.brand};
        box-shadow: 0 0 0 1px ${({ theme }) => theme.color.brand};
      }
    }

    .checkbox-container {
      width: 100%;
    }

    .platform-select {
      display: grid;
      gap: 10px;

      .platform-select-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: transparent;
        white-space: nowrap;
        text-align: left;
        transition: 0.2s;
        box-sizing: border-box;
        padding: 0 10px;
        height: 50px;
        border-radius: 6px;
        border: 1px solid ${({ theme }) => theme.color.gray[300]};

        .platform-details-container {
          display: flex;
          align-items: center;
          margin-right: 20px;

          .platform-image {
            height: 28px;
            width: 28px;
            flex-shrink: 0;
            margin-right: 10px;
            position: relative;

            img {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          .platform-details {
            span {
              display: block;
            }

            .platform-name {
              font-size: 14px;
              font-weight: 500;
              color: ${({ theme }) => theme.color.gray[800]};
            }

            .platform-description {
              color: ${({ theme }) => theme.color.gray[600]};
              font-size: 13px;
              marign-top: 2px;
            }
          }
        }

        .connect-label {
          font-size: 14px;
          font-weight: 500;
          border-radius: 4px;
          transition: 0.2s;
          color: ${({ theme }) => theme.color.brand};
          padding: 8px 10px;
          background-color: ${({ theme }) => theme.color.brandLight};
        }

        &:hover {
          border: 1px solid ${({ theme }) => theme.color.gray[400]};

          .connect-label {
            background-color: ${({ theme }) => theme.color.brand};
            color: ${({ theme }) => theme.color.background};
          }
        }

        &:disabled {
          background-color: ${({ theme }) => theme.color.brandLight};
          border: 1px solid ${({ theme }) => theme.color.brand};

          .platform-details-container {
            .platform-details {
              .platform-name,
              .platform-description {
                color: ${({ theme }) => theme.color.brand};
              }
            }
          }

          .connect-label {
            background-color: ${({ theme }) => theme.color.brand};
            color: ${({ theme }) => theme.color.background};
          }

          &:hover {
            cursor: not-allowed;
          }
        }
      }
    }
  }
`

export default StyledOnboardingView
