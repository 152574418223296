import firebase from 'firebase/app'
import { CapacitorHttp } from '@capacitor/core'
import { getServerURL } from '.'

export const useCommunity = () => {
  const identifyCommunityUser = async () => {
    const serverUrl = getServerURL()

    const tokenResult = await firebase.auth().currentUser?.getIdTokenResult()

    if (!tokenResult?.token) {
      return
    }

    await CapacitorHttp.post({
      url: `${serverUrl}/community/identify`,
      headers: { Authorization: `Bearer ${Buffer.from(tokenResult.token).toString('base64')}` }
    })
  }

  return { actions: { identifyCommunityUser } }
}
