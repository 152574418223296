import styled from 'styled-components'

const StyledCreateBarSwitcher = styled.div`
  background-color: ${({ theme }) => theme.color.gray[200]};
  box-sizing: border-box;
  border-radius: 12px;
  height: 45px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4px;

  .tab {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    height: 100%;
    padding: 0 10px;
    border-radius: 8px;
    transition: 0.2s;
    color: ${({ theme }) => theme.color.gray[500]};
    background-color: ${({ theme }) => theme.color.gray[200]};

    svg {
      transition: transform 0.2s;
      height: 18px;
      width: 0;
      transform: scale(0);

      path {
        stroke-width: 2.2px;
      }
    }

    &:hover {
      background-color: ${({ theme }) => theme.color.gray[300]};
    }

    &:active {
      transform: scale(0.95);
    }

    &[aria-selected='true'] {
      color: ${({ theme }) => theme.color.gray[800]};
      background-color: ${({ theme }) => theme.color.background};

      svg {
        width: 20px;
        transform: scale(1);
        margin-right: 4px;
      }
    }

    &:not(:last-child) {
      margin-right: 5px;
    }
  }
`

export default StyledCreateBarSwitcher
