import styled from 'styled-components'

const StyledConnectionDialog = styled.div`
  .connections-content {
    overflow-y: auto;
    max-height: 600px;
  }
`

export default StyledConnectionDialog
