import styled from 'styled-components'

const StyledCreateBarUploads = styled.div`
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  height: 100%;
  overflow: hidden;

  .uploads-bar-header-top {
    padding: 20px 20px 4px 20px;

    .upload-btn {
      height: 45px;
      border-radius: 50rem;
      transition: 0.2s;
      background-color: ${({ theme }) => theme.color.gray[800]};
      color: ${({ theme }) => theme.color.background};

      &:hover {
        background-color: ${({ theme }) => theme.color.gray[900]};
      }
    }
  }

  .tab-container {
    position: relative;

    .new-folder-btn {
      position: absolute;
      height: 32px;
      width: 32px;
      color: ${({ theme }) => theme.color.gray[600]};
      background-color: ${({ theme }) => theme.color.gray[100]};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      transition: 0.2s;

      svg {
        height: 24px;
      }

      &:hover {
        color: ${({ theme }) => theme.color.gray[800]};
        background-color: ${({ theme }) => theme.color.gray[200]};
      }
    }
  }
`

export default StyledCreateBarUploads
