import { FC } from 'react'
import ReactTextareaAutosize from 'react-textarea-autosize'
import { updateCreatePost, useAppDispatch, useAppSelector } from '../../../../redux'
import { useHaptics } from '../../../../utils'
import { StyledCreateTextarea } from '.'

const CreateTextarea: FC = () => {
  const dispatch = useAppDispatch()
  const { post } = useAppSelector((state) => state.create)
  const { trigger } = useHaptics()

  return (
    <StyledCreateTextarea>
      <ReactTextareaAutosize
        id="create-textarea"
        value={post?.captionText || ''}
        onFocus={() => trigger('selection-changed')}
        onBlur={() => trigger('selection-changed')}
        className="input"
        placeholder="Start typing..."
        spellCheck={true}
        onChange={(e) => dispatch(updateCreatePost({ captionText: e.target.value }))}
      />
    </StyledCreateTextarea>
  )
}

export default CreateTextarea
