import { FC, useEffect, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { PlusIcon } from '@heroicons/react/24/outline'
import { getAffiliateActivity } from '../../../graphql'
import { AffiliateEarningType } from '../../../types'
import { getRelativeString } from '../../../utils'
import { Avatar, EmptyState } from '../..'
import { StyledReferralActivity } from '.'

const ReferralActivity: FC = () => {
  const apollo = useApolloClient()
  const [activity, setActivity] = useState<{
    numAnnual: number
    numCourse: number
    numMonth: number
    numTrial: number
    total: number
    earnings: number
    lastTen: (AffiliateEarningType & { displayName: string })[]
  }>({
    numAnnual: 0,
    numCourse: 0,
    numMonth: 0,
    numTrial: 0,
    total: 0,
    earnings: 0,
    lastTen: []
  })

  const loadActivity = async () => {
    const { data } = await apollo.query({
      fetchPolicy: 'no-cache',
      query: getAffiliateActivity
    })

    if (!data?.getAffiliateActivity) {
      return
    }

    setActivity(data?.getAffiliateActivity)
  }

  const getActivityDescription = (activity: AffiliateEarningType) => {
    if (activity.amount === 49 && activity.item?.type === 'subscription') {
      return `Upgraded to Pro Monthly`
    }
    if (activity.amount === 75 && activity.item?.type === 'subscription') {
      return `Upgraded to Pro Annual`
    }
    if (activity.item?.type === 'course') {
      return `Purchased a Course`
    }
    if (activity.amount === 0) {
      return `Started a Trial`
    }
    return ''
  }

  useEffect(() => {
    loadActivity()
  }, [])

  return (
    <StyledReferralActivity className="section">
      <h2>Recent activity</h2>
      {activity.total === 0 && (
        <EmptyState
          title="Things are looking dry..."
          body="Recently referred users will appear here."
        />
      )}
      {activity.total > 0 && (
        <>
          <p>
            You've earned a total of <b>${activity.earnings} dollars</b> from {activity.total}{' '}
            referrals in the past month.
          </p>
          <p className="small">
            You've referred {activity.numAnnual} annual, {activity.numMonth} monthly,{' '}
            {activity.numTrial} trial and {activity.numCourse} course users.
          </p>
          <div className="active-list">
            {activity.lastTen.map((row) => {
              return (
                <div className="active-row" key={row._id}>
                  <div className="user-details-container">
                    <Avatar name={row.displayName} />
                    <div className="user-details">
                      <span className="user-name">{row.displayName}</span>
                      <span className="description">{getActivityDescription(row)}</span>
                    </div>
                  </div>
                  <span className="timestamp">
                    {getRelativeString(row.userPaidDate || row.trialStartDate)}
                  </span>
                </div>
              )
            })}
            {activity.total > 5 && (
              <div className="more-indicator-container">
                <div className="more-indicator">
                  <PlusIcon />
                  {activity.total - 5} more
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </StyledReferralActivity>
  )
}

export default ReferralActivity
