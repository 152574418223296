import { FC } from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { ReactComponent as CollapseIcon } from '../../../assets/collapse-icon.svg'
import { setShowCalendarPostList, useAppDispatch, useAppSelector } from '../../../redux'
import { useCalendar } from '../../../utils'
import { StyledCalendarHeader } from '.'

const CalendarHeader: FC = () => {
  const dispatch = useAppDispatch()
  const {
    actions: { handleShift, changeView }
  } = useCalendar()
  const { view, monthString, yearString, showPostList } = useAppSelector((state) => state.calendar)

  return (
    <StyledCalendarHeader>
      <div className="header-group">
        <button
          data-tooltip={showPostList ? 'Hide draft list' : 'Show draft list'}
          data-tooltip-pos="bottom-start"
          className="list-btn"
          onClick={() => dispatch(setShowCalendarPostList(!showPostList))}
        >
          <CollapseIcon style={{ transform: showPostList ? `rotate(180deg)` : '' }} />
        </button>
        <span className="calendar-header-date">
          {monthString} <span className="calendar-year">{yearString}</span>
        </span>
      </div>
      <div className="header-group">
        <button
          data-tooltip={`Previous ${view}`}
          className="arrow-btn"
          onClick={() => handleShift('prev')}
        >
          <ChevronLeftIcon />
        </button>
        <button
          data-tooltip={`Next ${view}`}
          className="arrow-btn"
          onClick={() => handleShift('next')}
        >
          <ChevronRightIcon />
        </button>
        <button
          data-tooltip={`Switch to ${view === 'month' ? 'week' : 'month'} view`}
          className="view-btn"
          onClick={() => changeView(view === 'month' ? 'week' : 'month')}
        >
          {view} View
        </button>
        <button
          data-tooltip={`Go to today`}
          data-tooltip-pos="bottom-end"
          className="today-btn"
          onClick={() => handleShift('today')}
        >
          Today
        </button>
      </div>
    </StyledCalendarHeader>
  )
}

export default CalendarHeader
