import styled from 'styled-components'

const StyledOptionDrawer = styled.div`
  user-select: none;

  .option-list {
    display: grid;
    grid-gap: 10px;
    padding: 20px;

    a,
    button {
      border-radius: 12px;
      display: flex;
      align-items: center;
      text-decoration: none;
      background-color: ${({ theme }) => theme.color.gray[100]};
      transition: background-color 0.2s, color 0.2s;
      color: ${({ theme }) => theme.color.gray[700]};
      padding: 12px;
      height: 46px;
      font-weight: 500;
      position: relative;

      .new-flag {
        padding: 3px 6px;
        margin-left: 6px;
        font-size: 11px;
        font-weight: 600;
        letter-spacing: 0.05rem;
        border-radius: 50rem;
        color: ${({ theme }) => theme.color.background};
        background-color: ${({ theme }) => theme.color.brand};
      }

      svg {
        color: ${({ theme }) => theme.color.gray[600]};
        margin-right: 12px;
        height: 22px;
        stroke-width: 1.7px;
        transition: color 0.2s;
      }

      .selected-icon {
        position: absolute;
        margin: 0;
        right: 8px;
      }

      &:hover {
        color: ${({ theme }) => theme.color.gray[900]};
        background-color: ${({ theme }) => theme.color.gray[200]};

        svg {
          color: ${({ theme }) => theme.color.gray[900]};
        }
      }
    }
  }

  hr {
    background-color: ${({ theme }) => theme.color.gray[300]};
    width: 100%;
    height: 1px;
    border: 0;
    margin: 6px 0;
  }
`

export default StyledOptionDrawer
